import { CloseCircleOutlined, CreditCardOutlined } from '@ant-design/icons'
import { Alert, Button } from 'antd'
import React, { FunctionComponent } from 'react'

import { Money } from '../Money/Money'

export interface LowCreditAlertProps {
  balance: number
  totalPrice: number
}

export const LowCreditAlert: FunctionComponent<LowCreditAlertProps> = props => {
  return (
    <Alert
      className="mb-4"
      message={
        <div className="flex justify-between align-middle space-x-4">
          <div>
            <CloseCircleOutlined className="mr-2 text-red-300" />
            Nedostatek kreditu
          </div>
          <Button danger size="small" icon={<CreditCardOutlined />}>
            Dobít kredit
          </Button>
        </div>
      }
      description={
        <div className="flex space-x-2 pt-2 border-t border-red-200 justify-between">
          <div>
            <div>Stav účtu</div>
            <Money
              className="font-semibold"
              amount={props.balance}
              symbol="Kr."
            />
          </div>
          <div>
            <div>Cena akce</div>
            <Money
              className="font-semibold"
              amount={props.totalPrice}
              symbol="Kr."
            />
          </div>
          <div>
            <div>Rozdíl</div>
            <Money
              className="font-semibold"
              amount={props.balance - props.totalPrice}
              symbol="Kr."
            />
          </div>
        </div>
      }
      type="error"
    />
  )
}
