import { Table, TableProps } from 'antd'
import { ColumnsType } from 'antd/es/table/interface'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { CollectionType } from '../../../common/documentCollectionTypes'
import { useCuzkLvTableQuery, UuidFilter } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { checkExistingFile } from '../../utils/checkExistingFile'
import { checkSeznamSestav } from '../../utils/checkSeznamSestav'
import {
  numberSorter,
  stringSorter,
  utcDateSorter,
  utcDescDateSorter,
} from '../../utils/generalTableHelpers'
import { cuzkDocumentCollectionState } from '../../utils/handleResponseStatus'
import { tablePagination } from '../../utils/layoutConst'
import { useConst } from '../../utils/useConst'
import { CuzkDocumentCollectionAction } from '../CuzkDocumentCollectionAction/CuzkDocumentCollectionAction'
import { CuzkDocumentPrice } from '../CuzkDocumentPrice/CuzkDocumentPrice'
import { CuzkDocumentState } from '../CuzkDocumentState/CuzkDocumentState'
import { CuzkLvModalButton } from '../CuzkLvModalButton/CuzkLvModalButton'
import { Day, Min } from '../DateFormat/DateFormat'
import { MapTooltip } from '../MapTooltip/MapTooltip'
import { TableCellLabels } from '../TableCellLabels/TableCellLabels'
import { TableSummaryExport } from '../TableSummaryExport/TableSummaryExport'
export interface LvTableItem {
  id: string
  createdAt: Date
  datumK: Date
  katastralniUzemiKod: string
  katastralniUzemiNazev?: string
  obec?: string
  stav: string
  cena?: number
  lv: string
  pocetstran?: string
  cuzkCollectionId: string
}

export interface CuzkLvTableProps {
  refetchState?: boolean
  onActionRefetch?: () => void
  setRefetchState?: (state: boolean) => void
  isPropertyDetail?: boolean
  idFilter?: UuidFilter
  tableProps?: TableProps<LvTableItem>
}

const tableId = 'cuzkLv-table'

export const CuzkLvTable: FunctionComponent<CuzkLvTableProps> = props => {
  const tenantId = useAppSelector(state => state.myProfile.tenantId)
  const token = useAppSelector(state => state.myProfile.token)
  const oneDayAgo = useConst(new Date(new Date().getTime() - 86400000))

  const [timeoutTime, setTimeoutTime] = useState<number | null>(2)
  const [queryResult, refetch] = useCuzkLvTableQuery({
    variables: {
      accountId: tenantId,
      oneDayAgo: oneDayAgo,
      idFilter: props.idFilter,
    },
  })
  const [dataSource, setDataSource] = useState<LvTableItem[]>([])

  const actionButtonProps = useMemo(
    () => (props.isPropertyDetail ? { size: 'small' as const } : undefined),
    [props.isPropertyDetail]
  )

  const handleActionRefetch = useCallback(() => {
    props.onActionRefetch && props.onActionRefetch()
    refetch()
  }, [props, refetch])

  useEffect(() => {
    if (queryResult?.data?.allCuzkLvs?.nodes.length) {
      setDataSource(queryResult.data.allCuzkLvs.nodes as LvTableItem[])
    }
  }, [queryResult.data?.allCuzkLvs?.nodes])

  useEffect(() => {
    if (props.refetchState) {
      refetch()
      props.setRefetchState && props.setRefetchState(false)
      setTimeoutTime(2)
    }
  }, [props, refetch])

  useEffect(() => {
    let timeoutId = null as NodeJS.Timeout | null

    const checkItems = async () => {
      if (!queryResult.fetching && dataSource.length > 0) {
        const itemsToCheck = dataSource.filter(
          item =>
            item.stav === cuzkDocumentCollectionState.ceka ||
            item.stav === cuzkDocumentCollectionState.podepisujeSe ||
            item.stav === cuzkDocumentCollectionState.vytvariSe
        )
        const filesToCheck = dataSource.filter(
          item => item.stav === cuzkDocumentCollectionState.generujeSePdf
        )

        let shouldRefetch = false
        for (const item of filesToCheck) {
          const checked = await checkExistingFile({
            id: item.id,
            filename: item.id + '.pdf',
            tenantId: tenantId,
            token: token,
            collectionType: CollectionType.LV,
          })

          if (checked) {
            shouldRefetch = true
          }
        }
        for (const item of itemsToCheck) {
          const checked = await checkSeznamSestav({
            cuzkCollectionId: item.cuzkCollectionId,
            id: item.id,
            stav: item.stav,
            tenantId: tenantId,
            collectionType: CollectionType.LV,
          })

          if (checked) {
            shouldRefetch = true
          }
        }

        if (shouldRefetch) {
          refetch()
          setTimeoutTime(prevTime => (prevTime || 2) * 2)
        } else {
          setTimeoutTime(null)
        }
      }
    }

    if (timeoutTime) {
      timeoutId = setTimeout(checkItems, timeoutTime * 1000)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [dataSource, queryResult.fetching, refetch, tenantId, timeoutTime, token])

  const columns = useMemo<ColumnsType<LvTableItem>>(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        responsive: ['' as Breakpoint],
      },
      {
        title: 'Vytvořeno',
        dataIndex: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: utcDateSorter('createdAt'),
        render: createdAt => <Min>{createdAt}</Min>,
      },
      {
        title: 'Štítek',
        dataIndex: 'labels',
        render: (labels, record) => (
          <TableCellLabels
            labels={labels}
            labelEntityType="cuzkLvId"
            recordId={record.id}
            refetch={refetch}
            modalTitle={record.lv}
          />
        ),
      },
      ...(!props.isPropertyDetail
        ? [
            {
              title: 'Obec',
              dataIndex: 'obec',
              sorter: stringSorter('obec'),
              render: (obec: string) => (
                <MapTooltip query={obec}>{obec}</MapTooltip>
              ),
            },
            {
              title: 'LV',
              dataIndex: 'lv',
              key: 'lv',
              sorter: stringSorter('lv'),
            },
            {
              title: 'Katastrální území',
              dataIndex: 'katastralniUzemiNazev',
              key: 'katastralniUzemiNazev',
              sorter: stringSorter('katastralniUzemiNazev'),
            },
          ]
        : []),
      {
        title: 'K datu',
        dataIndex: 'datumK',
        sorter: utcDescDateSorter('datumK'),
        render: datumK => <Day>{datumK}</Day>,
      },
      {
        title: 'ČÚZK cena',
        dataIndex: 'cena',
        className: 'w-44',
        sorter: numberSorter('cena'),
        render: (cena, record) => (
          <CuzkDocumentPrice state={record.stav} price={cena} />
        ),
      },
      {
        title: 'Stav',
        dataIndex: 'stav',
        sorter: stringSorter('stav'),
        render: stav => <CuzkDocumentState state={stav} />,
      },
      {
        title: 'Akce',
        dataIndex: 'actions',
        className: 'w-56',
        render: (_, record) => (
          <CuzkDocumentCollectionAction
            buttonProps={actionButtonProps}
            price={record.cena}
            state={record.stav}
            id={record.id}
            cuzkCollectionId={record.cuzkCollectionId}
            refetch={handleActionRefetch}
            collectionType={CollectionType.LV}
          />
        ),
      },
    ],
    [props.isPropertyDetail, refetch, actionButtonProps, handleActionRefetch]
  )

  const locale = useMemo(
    () => ({
      emptyText: !queryResult.fetching && (
        <div className="my-8">
          <div className="mb-2 text-gray-400">
            Zatím jste nevyhledali žádný list vlastnictví.
          </div>
          <CuzkLvModalButton />
        </div>
      ),
    }),
    [queryResult.fetching]
  )

  const summary = useCallback(
    () => (
      <TableSummaryExport
        total={dataSource?.length || 0}
        colSpan={props.isPropertyDetail ? 5 : 8}
        url="cuzk/lvTableXlsx"
        fileName="LV"
        dataSource={dataSource || []}
        align="text-left"
        icon
      />
    ),
    [dataSource, props.isPropertyDetail]
  )

  return (
    <Table
      id={tableId}
      rowKey="id"
      size="small"
      dataSource={[...dataSource]}
      columns={columns}
      locale={locale}
      loading={queryResult.fetching}
      pagination={tablePagination}
      bordered
      summary={summary}
      {...props.tableProps}
    />
  )
}
