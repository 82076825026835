import { MenuOutlined } from '@ant-design/icons'
import { Button, Dropdown, MenuProps, message, Popconfirm } from 'antd'
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useMemo,
} from 'react'
import { Link } from 'react-router-dom'

import { addHtmlContainer } from '../../../common/postServicePdf'
import { useDeletePostserviceTemplateMutation } from '../../../graphql/generated'
import { clickTrigger } from '../../utils/layoutConst'
import { postServiceLetterPath } from '../../utils/paths'
import { PdfDrawerPreview } from '../PdfDrawerPreview/PdfDrawerPreview'
import { PostServiceLetterCreateModalButton } from '../PostServiceLetterCreateModalButton/PostServiceLetterCreateModalButton'
import { PostServiceLetterDetailPdf } from '../PostServiceLetterDetailPdf/PostServiceLetterDetailPdf'
import { PostServiceRecipientListTableSendDisabled } from '../PostServiceRecipientListTableSendDisabled/PostServiceRecipientListTableSendDisabled'
import { PostServiceTemplateTableItem } from '../PostServiceTemplateTable/PostServiceTemplateTable'

export interface PostServiceTemplateTableActionProps {
  reexecuteQuery: () => void
  setLoadingId?: (id: string[]) => void
  loadingId?: string[]
  checkNow?: (id: string[]) => void
  record: PostServiceTemplateTableItem
  handleExpandRow?: (id: string) => void
}

const menuButtonProps = {
  size: 'middle' as const,
  type: 'link' as const,
  className: 'w-full',
}

export const PostServiceTemplateTableAction: FunctionComponent<PostServiceTemplateTableActionProps> =
  props => {
    const [, deleteRecipientList] = useDeletePostserviceTemplateMutation()

    const handleDelete = useCallback(() => {
      deleteRecipientList({ id: props.record.id, deletedAt: new Date() }).then(
        () => {
          message.success('Šablona byla odstraněna.')
          props.reexecuteQuery()
        }
      )
    }, [deleteRecipientList, props])

    const menuProps = useMemo<MenuProps>(
      () => ({
        items: [
          {
            key: 'addRecipient',
            className: '!p-0',
            label: (
              <Link to={postServiceLetterPath('', props.record.id)}>
                <Button {...menuButtonProps}>Editovat šablonu</Button>
              </Link>
            ),
          },
          {
            key: 'importRecipient',
            className: '!p-0',
            label: (
              <PdfDrawerPreview
                buttonProps={{ type: 'link', className: 'w-full' }}
                buttonText="PDF náhled"
                drawerTitle="Náhled šablony dopisu"
                fileName={props.record.name + '_HROMADNY_DOPIS_REGESTA_DATA'}
              >
                <PostServiceLetterDetailPdf
                  showTemplate
                  letterText={addHtmlContainer(props.record.content)}
                />
              </PdfDrawerPreview>
            ),
          },
          {
            key: 'delete',
            className: '!p-0',
            label: (
              <Popconfirm
                okText="Odebrat"
                cancelText="Zpět"
                title="Opravdu chcete odstranit šablonu dopisu?"
                onConfirm={handleDelete}
              >
                <Button
                  title="Smazat šablonu dopisu"
                  size="middle"
                  type="link"
                  danger
                  className="w-full"
                >
                  Odstranit
                </Button>
              </Popconfirm>
            ),
          },
        ],
      }),
      [handleDelete, props.record.content, props.record.id, props.record.name]
    )

    const actionButtons = useMemo(
      () =>
        // eslint-disable-next-line no-constant-condition
        false ? (
          <PostServiceRecipientListTableSendDisabled
            listName={props.record.name}
            listId={props.record.id}
            reexecuteQuery={props.reexecuteQuery}
          />
        ) : (
          <PostServiceLetterCreateModalButton
            buttonProps={{
              type: 'link',
              size: 'small',
              title: 'Vybrat šablonu a pokračovat k výběru adresátů',
              className: '!pr-1',
            }}
            buttonText="Použít šablonu"
            templateId={props.record.id}
          />
        ),
      [props.record.id, props.record.name, props.reexecuteQuery]
    )

    const buttonsRender = useCallback(
      (buttons: ReactNode[]) => [actionButtons, buttons[1]],
      [actionButtons]
    )

    return (
      <Dropdown.Button
        icon={<MenuOutlined />}
        menu={menuProps}
        arrow
        type="link"
        trigger={clickTrigger}
        className="!flex justify-end items-end space-x-1"
        buttonsRender={buttonsRender}
      />
    )
  }
