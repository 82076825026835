import { Form, Input, Radio, Space } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { BASE_URL } from '../../../../ini.json'
import { useAppSelector } from '../../../redux/hooks'
import { postServiceFormHelp } from '../../utils/cuzkMonitorRcFormHelp'
import { itemRequired } from '../../utils/formHelpers'

interface PostServiceAddRecipientFormPersonFragmentProps {
  showHelp: boolean
}

export const PostServiceAddRecipientFormPersonFragment: FunctionComponent<PostServiceAddRecipientFormPersonFragmentProps> =
  ({ showHelp }) => {
    const token = useAppSelector(state => state.myProfile.token)
    const form = Form.useFormInstance()

    const getCallName = useCallback(
      async (prijmeni: string) => {
        const encodedLastName = encodeURIComponent(prijmeni)
        const url = `${BASE_URL}/api/drm/callname/${encodedLastName}/${token}`

        try {
          const response = await axios.get(url)
          const data: {
            osloveniMuz: string | null
            osloveniZena: string | null
          } = response.data?.[0]
          if (data) return { man: data.osloveniMuz, female: data.osloveniZena }
          return null
        } catch (error) {
          return null
        }
      },
      [token]
    )

    const handleLastnameBlur = useCallback(async () => {
      const lastnameValue = form.getFieldValue(['lastname'])

      if (lastnameValue) {
        const callName = await getCallName(lastnameValue)
        form.setFieldsValue({
          callName: callName?.man || callName?.female || callName,
          gender: callName?.man ? 'm' : callName?.female ? 'f' : 0,
        })
      }
    }, [form, getCallName])

    const gender = Form.useWatch('gender', form)
    const greeting = useMemo(
      () => (
        <span className="text-gray-400">
          {gender === 'm'
            ? 'Vážený pane'
            : gender === 'f'
            ? 'Vážená paní'
            : 'Vážený pane, vážená paní'}
        </span>
      ),
      [gender]
    )

    return (
      <>
        <Form.Item
          label="Adresát"
          required
          className="!mb-0"
          help={showHelp ? postServiceFormHelp.name : undefined}
        >
          <div className="flex space-x-2">
            <Form.Item
              name="lastname"
              rules={itemRequired('Příjmení adresáta je povinné')}
            >
              <Input
                onBlur={handleLastnameBlur}
                placeholder="Příjmení adresáta"
                autoFocus
              />
            </Form.Item>
            <Form.Item
              name="firstname"
              rules={itemRequired('Křestní jméno adresáta je povinné')}
            >
              <Input placeholder="Křestní jméno adresáta" />
            </Form.Item>
          </div>
        </Form.Item>

        <Form.Item
          name="gender"
          label="Pohlaví"
          help={showHelp ? postServiceFormHelp.gender : undefined}
        >
          <Radio.Group>
            <Space direction="horizontal">
              <Radio value={0}>Neuvedeno</Radio>
              <Radio value="m">Muž</Radio>
              <Radio value="f">Žena</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Oslovení"
          name="callName"
          help={showHelp ? postServiceFormHelp.callName : undefined}
        >
          <Input addonBefore={greeting} placeholder="Tvar jména při oslovení" />
        </Form.Item>
      </>
    )
  }
