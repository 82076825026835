import { Button } from 'antd'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { PageLayout } from '../components/PageLayout/PageLayout'
import { PostServiceTemplateTable } from '../components/PostServiceTemplateTable/PostServiceTemplateTable'
import { postServiceLetterPath } from '../utils/paths'

export const PostServiceTemplate: FunctionComponent = () => {
  return (
    <PageLayout
      title="Hromadná pošta - přehled šablon dopisů"
      isNew
      extra={
        <Link to={postServiceLetterPath()}>
          <Button type="primary">Vytvořit novou šablonu dopisu</Button>
        </Link>
      }
    >
      <PostServiceTemplateTable />
    </PageLayout>
  )
}

export default PostServiceTemplate
