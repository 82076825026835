import React, { FunctionComponent, memo } from 'react'

import { Rizeni } from '../../../common/proceedingTypes'
import { useAppSelector } from '../../../redux/hooks'
import { useFormattedProceeding } from '../../utils/useProceedingString'
import { ProceedingDetailModalLink } from '../ProceedingDetailModalLink/ProceedingDetailModalLink'

export interface PropertyTimelineProceedingBadgeProps {
  proceeding: Rizeni
}

export const PropertyTimelineProceedingBadge: FunctionComponent<PropertyTimelineProceedingBadgeProps> =
  memo(({ proceeding }) => {
    const { proceedingTitle } = useFormattedProceeding(proceeding)

    const isCurrentProceeding = useAppSelector(state => {
      return (state.property.drm.data?.plombyRizeni || []).includes(
        proceedingTitle || ''
      )
    })

    return isCurrentProceeding ? (
      <ProceedingDetailModalLink
        className="absolute flex items-center justify-center text-xs text-white hover:text-white rounded-b-lg outline outline-amber-500 bg-amber-500 hover:bg-amber-500/90 outline-1 h-5 w-full left-0 -bottom-4 transition-colors"
        proceeding={proceeding}
      >
        Právě probíhá
      </ProceedingDetailModalLink>
    ) : null
  })
