import { Typography } from 'antd'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { useAppSelector } from '../../../redux/hooks'
import { propertyDetailPath } from '../../utils/paths'
import { usePropertyDetailTitle } from '../../utils/usePropertyDetailTitle'
import { PropertyDetailLabel } from '../PropertyDetailLabel/PropertyDetailLabel'

export interface DetailDrawerPropertyTitleProps {
  propertyId: string
  propertyType: PROPERTY_TYPES
}

const { Title } = Typography
const titleStyle = { marginBottom: 0 }

export const DetailDrawerPropertyTitle: FunctionComponent<DetailDrawerPropertyTitleProps> =
  props => {
    const drmData = useAppSelector(state => state.property.drm.data)
    const propertyName = usePropertyDetailTitle()

    return (
      <div className="flex flex-col">
        <Link
          to={propertyDetailPath(props.propertyType, props.propertyId)}
          target="_blank"
          title="Otevřít detail nemovitosti v novém okně"
        >
          <Title
            level={3}
            style={titleStyle}
            className="hover:!text-primaryHover transition-colors first-letter:uppercase"
          >
            {propertyName}
          </Title>
        </Link>
        {!!drmData && (
          <PropertyDetailLabel
            propertyType={props.propertyType}
            propertyName={propertyName}
            propertyId={props.propertyId}
          />
        )}
      </div>
    )
  }
