import { View } from '@react-pdf/renderer'
import React, { FunctionComponent } from 'react'

import { monitPdfCss } from '../../utils/monitPdfCss'
import { PdfViewerFormItem as Item } from '../PdfViewerFormItem/PdfViewerFormItem'

export interface PriceEstimateFormatedPriceData {
  cenaM2Nab: string
  cenaM2Real: string
  cenaNab: string
  cenaReal: string
}

export interface PriceEstimatePdfPriceBoxFragmentProps {
  priceData: PriceEstimateFormatedPriceData
}

export const PriceEstimatePdfPriceBoxFragment: FunctionComponent<PriceEstimatePdfPriceBoxFragmentProps> =
  props => {
    const data = props.priceData
    return (
      <View style={monitPdfCss.priceEstimate}>
        <View style={monitPdfCss.twoColumns}>
          <View
            style={
              data.cenaM2Real
                ? monitPdfCss.priceEstimateColumns
                : monitPdfCss.priceEstimateColumnsBig
            }
          >
            <Item isLabel data={data.cenaM2Nab} style={monitPdfCss.w50}>
              Cena nabídková m²:
            </Item>
            <Item data={data.cenaM2Nab} suffix=" Kč" />
          </View>
          <View style={monitPdfCss.priceDivider}></View>
          <View
            style={
              data.cenaM2Real
                ? monitPdfCss.priceEstimateColumns
                : monitPdfCss.priceEstimateColumnsBig
            }
          >
            <Item isLabel data={data.cenaNab} style={monitPdfCss.w50}>
              Cena nabídková:
            </Item>
            <Item data={data.cenaNab} suffix=" Kč" />
          </View>
        </View>

        <View style={monitPdfCss.twoColumns}>
          <View style={monitPdfCss.priceEstimateColumnsBig}>
            <Item isLabel data={data.cenaM2Real} style={monitPdfCss.w50}>
              Cena prodejní m²:
            </Item>
            <Item data={data.cenaM2Real} suffix=" Kč" />
          </View>
          <View style={monitPdfCss.priceDivider}></View>
          <View style={monitPdfCss.priceEstimateColumnsBig}>
            <Item isLabel data={data.cenaReal} style={monitPdfCss.w50}>
              Cena prodejní:
            </Item>
            <Item data={data.cenaReal} suffix=" Kč" />
          </View>
        </View>
      </View>
    )
  }
