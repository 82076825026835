import { Document, Font, Page } from '@react-pdf/renderer'
import React, { FunctionComponent, useMemo } from 'react'
import Html from 'react-pdf-html'

import emithaScript from '../../../common/fonts/Emitha-Script.ttf'
import openSansBold from '../../../common/fonts/OpenSans-Bold.ttf'
import openSansItalic from '../../../common/fonts/OpenSans-Italic.ttf'
import openSans from '../../../common/fonts/OpenSans-Regular.ttf'
import { pdfStyles } from '../../../common/pdfHelpers'
import { replacePostVariables } from '../../../common/postServicePdf'
import { RecipientFragment } from '../../../graphql/generated'

export interface PostServiceLetterDetailPdfProps {
  data?: RecipientFragment
  letterText: string
  showTemplate?: boolean
}

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: openSans },
    { src: openSansBold, fontWeight: 800 },
    { src: openSansItalic, fontStyle: 'italic' },
  ],
})

Font.register({
  family: 'emitha-script',
  fonts: [{ src: emithaScript }],
})

export const PostServiceLetterDetailPdf: FunctionComponent<PostServiceLetterDetailPdfProps> =
  props => {
    const letterContent = useMemo(() => {
      if (props.showTemplate) return props.letterText
      if (!props.data) return '>> Nepodařilo se načíst data dokumentu <<'
      const subject = {
        ...props.data,
      }

      return replacePostVariables(props.letterText, subject, true)
    }, [props.data, props.letterText, props.showTemplate])

    return (
      <Document>
        <Page style={pdfStyles.page} size="A4">
          <Html>{letterContent}</Html>
        </Page>
      </Document>
    )
  }
