import React, { FunctionComponent } from 'react'

export interface ExternalLinkProps {
  url?: string
}

export const ExternalLink: FunctionComponent<ExternalLinkProps> = props => {
  if (!props.url) return null
  let withPrefix = props.url
  let withoutPrefix = props.url

  if (!props.url.startsWith('http://') && !props.url.startsWith('https://')) {
    withPrefix = 'http://' + props.url
  } else {
    withoutPrefix = props.url.replace(/(^\w+:|^)\/\//, '')
  }

  return (
    <a
      href={withPrefix}
      target="_blank"
      rel="noreferrer"
      title="Odkaz se otevře v novém panelu"
    >
      {withoutPrefix}
    </a>
  )
}
