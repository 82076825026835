import { RestrictionCheckState } from '../../graphql/generated'
export const subjectPrefixes = new Set([
  'as',
  'art',
  'artd',
  'bc',
  'bca',
  'csc',
  'dis',
  'dr',
  'drsc',
  'dsc',
  'et',
  'icdr',
  'ing',
  'arch',
  'judr',
  'mddr',
  'mga',
  'mgr',
  'msdr',
  'mudr',
  'mvdr',
  'odb',
  'paeddr',
  'ph',
  'pharmdr',
  'phdr',
  'phmr',
  'rcdr',
  'rtdr',
  'rndr',
  'rsdr',
  'thdr',
  'th',
  'thlic',
  'as',
  'ro',
  'vos',
  'ops',
  'ks',
  'zs',
  'zu',
  'ks',
  'sro',
  'sp',
  'spol',
  'inc',
  'ltd',
  'msc',
  'ab',
  'ba',
  'bba',
  'be',
  'beng',
  'bfa',
  'bphil',
  'bmed',
  'bmet',
  'bmin',
  'bmus',
  'bpa',
  'bpharm',
  'bs',
  'bsc',
  'mba',
  'bsc',
  'bss',
  'dd',
  'ds',
  'dsc',
  'dphil',
  'ma',
  'ba',
  'llb',
  'llm',
  'jd',
  'jsd',
  'jcd',
  'jud',
  'phd',
  'edd',
  'thd',
  'md',
  'doc',
  'prof',
  'jr',
  'sr',
])

export const cuzkKatastralniUrady: CadastralOffice[] = [
  {
    kod: 100,
    nazev: 'Hlavní město Praha',
    pracoviste: [
      {
        kod: 101,
        nazev: 'Praha',
      },
    ],
  },
  {
    kod: 300,
    nazev: 'Jihočeský kraj',
    pracoviste: [
      {
        kod: 301,
        nazev: 'České Budějovice',
      },
      {
        kod: 302,
        nazev: 'Český Krumlov',
      },
      {
        kod: 303,
        nazev: 'Jindřichův Hradec',
      },
      {
        kod: 305,
        nazev: 'Písek',
      },
      {
        kod: 306,
        nazev: 'Prachatice',
      },
      {
        kod: 307,
        nazev: 'Strakonice',
      },
      {
        kod: 308,
        nazev: 'Tábor',
      },
    ],
  },
  {
    kod: 700,
    nazev: 'Jihomoravský kraj',
    pracoviste: [
      {
        kod: 701,
        nazev: 'Blansko',
      },
      {
        kod: 731,
        nazev: 'Boskovice',
      },
      {
        kod: 702,
        nazev: 'Brno-město',
      },
      {
        kod: 703,
        nazev: 'Brno-venkov',
      },
      {
        kod: 704,
        nazev: 'Břeclav',
      },
      {
        kod: 706,
        nazev: 'Hodonín',
      },
      {
        kod: 735,
        nazev: 'Hustopeče',
      },
      {
        kod: 738,
        nazev: 'Kyjov',
      },
      {
        kod: 712,
        nazev: 'Vyškov',
      },
      {
        kod: 713,
        nazev: 'Znojmo',
      },
    ],
  },
  {
    kod: 460,
    nazev: 'Karlovarský kraj',
    pracoviste: [
      {
        kod: 402,
        nazev: 'Cheb',
      },
      {
        kod: 403,
        nazev: 'Karlovy Vary',
      },
      {
        kod: 409,
        nazev: 'Sokolov',
      },
    ],
  },
  {
    kod: 660,
    nazev: 'Královéhradecký kraj',
    pracoviste: [
      {
        kod: 602,
        nazev: 'Hradec Králové',
      },
      {
        kod: 604,
        nazev: 'Jičín',
      },
      {
        kod: 605,
        nazev: 'Náchod',
      },
      {
        kod: 607,
        nazev: 'Rychnov nad Kněžnou',
      },
      {
        kod: 610,
        nazev: 'Trutnov',
      },
    ],
  },
  {
    kod: 500,
    nazev: 'Liberecký kraj',
    pracoviste: [
      {
        kod: 501,
        nazev: 'Česká Lípa',
      },
      {
        kod: 532,
        nazev: 'Frýdlant',
      },
      {
        kod: 504,
        nazev: 'Jablonec nad Nisou',
      },
      {
        kod: 505,
        nazev: 'Liberec',
      },
      {
        kod: 608,
        nazev: 'Semily',
      },
    ],
  },
  {
    kod: 800,
    nazev: 'Moravskoslezský kraj',
    pracoviste: [
      {
        kod: 801,
        nazev: 'Bruntál',
      },
      {
        kod: 802,
        nazev: 'Frýdek-Místek',
      },
      {
        kod: 803,
        nazev: 'Karviná',
      },
      {
        kod: 831,
        nazev: 'Krnov',
      },
      {
        kod: 804,
        nazev: 'Nový Jičín',
      },
      {
        kod: 806,
        nazev: 'Opava',
      },
      {
        kod: 807,
        nazev: 'Ostrava',
      },
      {
        kod: 832,
        nazev: 'Třinec',
      },
    ],
  },
  {
    kod: 860,
    nazev: 'Olomoucký kraj',
    pracoviste: [
      {
        kod: 835,
        nazev: 'Hranice',
      },
      {
        kod: 811,
        nazev: 'Jeseník',
      },
      {
        kod: 805,
        nazev: 'Olomouc',
      },
      {
        kod: 709,
        nazev: 'Prostějov',
      },
      {
        kod: 808,
        nazev: 'Přerov',
      },
      {
        kod: 809,
        nazev: 'Šumperk',
      },
    ],
  },
  {
    kod: 600,
    nazev: 'Pardubický kraj',
    pracoviste: [
      {
        kod: 603,
        nazev: 'Chrudim',
      },
      {
        kod: 606,
        nazev: 'Pardubice',
      },
      {
        kod: 609,
        nazev: 'Svitavy',
      },
      {
        kod: 611,
        nazev: 'Ústí nad Orlicí',
      },
    ],
  },
  {
    kod: 400,
    nazev: 'Plzeňský kraj',
    pracoviste: [
      {
        kod: 401,
        nazev: 'Domažlice',
      },
      {
        kod: 404,
        nazev: 'Klatovy',
      },
      {
        kod: 435,
        nazev: 'Kralovice',
      },
      {
        kod: 406,
        nazev: 'Plzeň-jih',
      },
      {
        kod: 405,
        nazev: 'Plzeň-město',
      },
      {
        kod: 407,
        nazev: 'Plzeň-sever',
      },
      {
        kod: 408,
        nazev: 'Rokycany',
      },
      {
        kod: 410,
        nazev: 'Tachov',
      },
    ],
  },
  {
    kod: 200,
    nazev: 'Středočeský kraj',
    pracoviste: [
      {
        kod: 201,
        nazev: 'Benešov',
      },
      {
        kod: 202,
        nazev: 'Beroun',
      },
      {
        kod: 203,
        nazev: 'Kladno',
      },
      {
        kod: 204,
        nazev: 'Kolín',
      },
      {
        kod: 205,
        nazev: 'Kutná Hora',
      },
      {
        kod: 206,
        nazev: 'Mělník',
      },
      {
        kod: 207,
        nazev: 'Mladá Boleslav',
      },
      {
        kod: 208,
        nazev: 'Nymburk',
      },
      {
        kod: 209,
        nazev: 'Praha-východ',
      },
      {
        kod: 210,
        nazev: 'Praha-západ',
      },
      {
        kod: 211,
        nazev: 'Příbram',
      },
      {
        kod: 212,
        nazev: 'Rakovník',
      },
      {
        kod: 231,
        nazev: 'Slaný',
      },
    ],
  },
  {
    kod: 560,
    nazev: 'Ústecký kraj',
    pracoviste: [
      {
        kod: 502,
        nazev: 'Děčín',
      },
      {
        kod: 503,
        nazev: 'Chomutov',
      },
      {
        kod: 506,
        nazev: 'Litoměřice',
      },
      {
        kod: 507,
        nazev: 'Louny',
      },
      {
        kod: 508,
        nazev: 'Most',
      },
      {
        kod: 531,
        nazev: 'Rumburk',
      },
      {
        kod: 509,
        nazev: 'Teplice',
      },
      {
        kod: 510,
        nazev: 'Ústí nad Labem',
      },
      {
        kod: 533,
        nazev: 'Žatec',
      },
    ],
  },
  {
    kod: 760,
    nazev: 'Vysočina',
    pracoviste: [
      {
        kod: 601,
        nazev: 'Havlíčkův Brod',
      },
      {
        kod: 707,
        nazev: 'Jihlava',
      },
      {
        kod: 741,
        nazev: 'Moravské Budějovice',
      },
      {
        kod: 304,
        nazev: 'Pelhřimov',
      },
      {
        kod: 710,
        nazev: 'Třebíč',
      },
      {
        kod: 746,
        nazev: 'Velké Meziříčí',
      },
      {
        kod: 714,
        nazev: 'Žďár nad Sázavou',
      },
    ],
  },
  {
    kod: 770,
    nazev: 'Zlínský kraj',
    pracoviste: [
      {
        kod: 740,
        nazev: 'Holešov',
      },
      {
        kod: 708,
        nazev: 'Kroměříž',
      },
      {
        kod: 711,
        nazev: 'Uherské Hradiště',
      },
      {
        kod: 742,
        nazev: 'Uherský Brod',
      },
      {
        kod: 737,
        nazev: 'Valašské Klobouky',
      },
      {
        kod: 836,
        nazev: 'Valašské Meziříčí',
      },
      {
        kod: 810,
        nazev: 'Vsetín',
      },
      {
        kod: 705,
        nazev: 'Zlín',
      },
    ],
  },
]

export type CadastralWorkplace = {
  kod: number
  nazev: string
  pocetLV?: number | null
  cena?: number | null
  disabled?: boolean | null
  description?: string | null
  state?: RestrictionCheckState
  boughtAt?: string | null
}

export type CadastralOffice = {
  kod: number
  nazev: string
  disabled?: boolean | null
  description?: string | null
  pracoviste: CadastralWorkplace[]
  state?: RestrictionCheckState
}

export type CadastralCheckedValues = {
  [key: number]: number[]
}
