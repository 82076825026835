import React, { FunctionComponent } from 'react'

import { LvApiResponse, LvGeometryApiResponse } from '../../../common/lvTypes'
import { DualPageLayout } from '../../components/DualPageLayout/DualPageLayout'
import { LvDetailOwnershipProperties } from '../LvDetailOwnershipProperties/LvDetailOwnershipProperties'
import { LvDetailOwnershipRights } from '../LvDetailOwnershipRights/LvDetailOwnershipRights'
import { LvDetailSidebar } from '../LvDetailSidebar/LvDetailSidebar'
import { PageLayoutTabContainer } from '../PageLayoutTabContainer/PageLayoutTabContainer'
import { PropertyDetailLvButton } from '../PropertyDetailLvButton/PropertyDetailLvButton'

export interface LvDetailProps {
  lvData: LvApiResponse
  geometryData: LvGeometryApiResponse
  isDrawer?: boolean
}

export const LvDetail: FunctionComponent<LvDetailProps> = props => {
  return (
    <DualPageLayout
      isNew
      isNewTitle="Detail LV 2.0"
      title={`Informace o LV ${props.lvData?.lv} - ${props.lvData?.katastralniUzemi?.nazev} (${props.lvData?.katastralniUzemi?.kod})`}
      sidebarContent={
        <LvDetailSidebar
          lvData={props.lvData}
          geometryData={props.geometryData}
        />
      }
      extra={
        <PropertyDetailLvButton
          lvCode={props.lvData?.katastralniUzemi?.kod}
          lvNumber={props.lvData?.lv}
        />
      }
    >
      <PageLayoutTabContainer className="space-y-14">
        <LvDetailOwnershipRights ownershipRights={props.lvData?.pravniVztahy} />
        <LvDetailOwnershipProperties
          properties={props.lvData?.nemovitosti}
          propertiesGeometry={props.geometryData}
        />
      </PageLayoutTabContainer>
    </DualPageLayout>
  )
}
