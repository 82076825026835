import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { FunctionComponent } from 'react'

import { useAppSelector } from '../../../redux/hooks'
import { Day } from '../DateFormat/DateFormat'

interface PropertyDetailOwnershipRightsTableCellValidFromProps {
  validDateFrom: string | null
}

export const PropertyDetailOwnershipRightsTableCellValidFrom: FunctionComponent<PropertyDetailOwnershipRightsTableCellValidFromProps> =
  ({ validDateFrom }) => {
    const watchedDateFrom = useAppSelector(
      state => state.property.timeline.data?.sledovanoOd
    )

    if (validDateFrom) {
      return <Day>{validDateFrom}</Day>
    }

    return (
      <Tooltip
        className="space-x-2"
        title="Jedná se o nejstarší datum, které ve spojitosti s tímto subjektem evidujeme, ale je pravděpodobné, že záznam vešel v platnost již dříve. Přesné datum si můžete v případě potřeby ověřit stažením listu vlastnictví."
      >
        <Day noData=" ">{watchedDateFrom}</Day>
        <QuestionCircleOutlined className="text-gray-400 cursor-help" />
      </Tooltip>
    )
  }
