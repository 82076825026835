import React, { FunctionComponent, useMemo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { ForclosureMonitoringFilterOnSubmit } from '../../../common/foreclosureMonitoringTypes'
import { useFetchPropertyUseEnums } from '../../utils/useFetchPropertyUseEnums'
import { PropertyMonitoringFilterBuildingUseModalButton } from '../PropertyMonitoringFilterBuildingUseModalButton/PropertyMonitoringFilterBuildingUseModalButton'
import { PropertyMonitoringFilterError } from '../PropertyMonitoringFilterError/PropertyMonitoringFilterError'
import { PropertyMonitoringFilterItem } from '../PropertyMonitoringFilterItem/PropertyMonitoringFilterItem'
import { PropertyMonitoringFilterUnitUseModalButton } from '../PropertyMonitoringFilterUnitUseModalButton/PropertyMonitoringFilterUnitUseModalButton'

export interface PropertyMonitoringFilterPropertyUseProps {
  selected?: number[] | null
  onChange: ForclosureMonitoringFilterOnSubmit
  propertyType: PROPERTY_TYPES
}

export const PropertyMonitoringFilterPropertyUse: FunctionComponent<PropertyMonitoringFilterPropertyUseProps> =
  props => {
    const { error, data } = useFetchPropertyUseEnums(props.propertyType)

    const selectedValues = useMemo(() => {
      if (props.selected) return props.selected
      if (!data?.length) return []
      return data.map(item => item.kod)
    }, [data, props.selected])

    if (error) return <PropertyMonitoringFilterError filterType="propertyUse" />

    if (!data?.length) return null

    return (
      <PropertyMonitoringFilterItem label="Způsob využití">
        {props.propertyType === PROPERTY_TYPES.BUILDING ? (
          <PropertyMonitoringFilterBuildingUseModalButton
            checkboxData={data}
            selectedValues={selectedValues}
            onSubmit={props.onChange}
          />
        ) : (
          <PropertyMonitoringFilterUnitUseModalButton
            checkboxData={data}
            selectedValues={selectedValues}
            onSubmit={props.onChange}
          />
        )}
      </PropertyMonitoringFilterItem>
    )
  }
