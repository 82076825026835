import * as turf from '@turf/turf'
import { FeatureCollection, Point } from 'geojson'
import { useEffect, useState } from 'react'

import { RuianQuery } from '../util/RuianQuery'

export function useParcelPoints(
  where: string,
  shouldFetch: boolean
): [boolean, FeatureCollection<Point>] {
  const [loading, setLoading] = useState(false)
  const [parcels, setParcels] = useState(turf.featureCollection<Point>([]))
  useEffect(() => {
    fetchParcels()
    async function fetchParcels() {
      try {
        setLoading(true)
        if (!shouldFetch) return
        if (!where || where === '') {
          setParcels(turf.featureCollection([]))
          return
        }
        setParcels(
          await RuianQuery.ParcelaDefinicniBod.outFields(['objectid'])
            .where(where)
            .asGeoJson()
        )
      } catch (error) {
        console.error(error)
        setParcels(turf.featureCollection([]))
      } finally {
        setLoading(false)
      }
    }
    // We don't want to refetch the data when the `loading` state changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [where])
  return [loading, parcels]
}
