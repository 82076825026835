import { Table } from 'antd'
import {
  ColumnsType,
  ExpandableConfig,
  SorterResult,
  TableRowSelection,
} from 'antd/es/table/interface'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import { addDays, format } from 'date-fns'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import csLocale from 'date-fns/locale/cs'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { CeeTableItem } from '../../../common/ceeTypes'
import {
  MonitoringCeeFilter,
  useCeeMonitoringTableQuery,
} from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { checkCeeSubject } from '../../utils/checkCeeSubject'
import { CheckFrequency } from '../../utils/checkFrequency'
import { czDateFormatDay } from '../../utils/dateFormat'
import { Identifier, RegisterGroupMap } from '../../utils/dynamicSearchTooltip'
import {
  stringSorter,
  utcDescDateSorter,
} from '../../utils/generalTableHelpers'
import { tablePagination } from '../../utils/layoutConst'
import { ceeMonitorCreatePath } from '../../utils/paths'
import { useHandleExpandRow } from '../../utils/useHandleExpandRow'
import { CeeMonitorTableAction } from '../CeeMonitorTableAction/CeeMonitorTableAction'
import { CeeMonitorTableBulkEdit } from '../CeeMonitorTableBulkEdit/CeeMonitorTableBulkEdit'
import { CeeMonitorTableCuzkStatusCell } from '../CeeMonitorTableCuzkStatusCell/CeeMonitorTableCuzkStatusCell'
import { CeeMonitorTableExpandedRow } from '../CeeMonitorTableExpandedRow/CeeMonitorTableExpandedRow'
import { CeeMonitorTableStatusCell } from '../CeeMonitorTableStatusCell/CeeMonitorTableStatusCell'
import { Min } from '../DateFormat/DateFormat'
import { DynamicSearchTooltip } from '../DynamicSearchTooltip/DynamicSearchTooltip'
import { ExpandColumnTitle } from '../ExpandColumnTitle/ExpandColumnTitle'
import { ExpandIcon } from '../ExpandIcon/ExpandIcon'
import { RcInputError } from '../RcInputError/RcInputError'
import { RcNumberData } from '../RcNumberData/RcNumberData'
import { TableCellLabels } from '../TableCellLabels/TableCellLabels'
import { TableEmptyText } from '../TableEmptyText/TableEmptyText'
import { TableSummaryExport } from '../TableSummaryExport/TableSummaryExport'
export interface CeeMonitorTableProps {
  monitoringCeeFilter?: MonitoringCeeFilter
  monitoring?: boolean
}

const scroll = { x: '1200' }
const tableId = 'cee-table'

export const CeeMonitorTable: FunctionComponent<CeeMonitorTableProps> =
  props => {
    const handleExpandRow = useHandleExpandRow(tableId)

    const { token, tenantId } = useAppSelector(state => state.myProfile)
    const expandedRowKeys = useAppSelector(
      state => state.expandedRow.tables?.[tableId]
    )

    const [, setSortedInfo] = useState<SorterResult<CeeTableItem>>({})
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [selectedRow, setSelectedRow] = useState<CeeTableItem[]>([])
    const [loadingId, setLoadingId] = useState<string[]>([])
    const [dataSource, setDataSource] = useState<CeeTableItem[]>([])

    const [queryResult, refetch] = useCeeMonitoringTableQuery({
      variables: { accountId: tenantId, filter: props.monitoringCeeFilter },
    })

    useEffect(() => {
      if (queryResult?.data?.allMonitoringCees?.nodes) {
        setDataSource(
          queryResult.data.allMonitoringCees.nodes as CeeTableItem[]
        )
        setSortedInfo({
          order: 'ascend',
          columnKey: 'validatedCode',
        })
      }
    }, [queryResult?.data?.allMonitoringCees?.nodes])

    const resetAndRefetch = useCallback(() => {
      setSelectedRowKeys([])
      setSelectedRow([])
      refetch()
    }, [refetch])

    const checkNow = useCallback(
      async (ids: string[]) => {
        setLoadingId(ids)
        await checkCeeSubject(ids, token)
        setLoadingId([])
        return resetAndRefetch()
      },
      [resetAndRefetch, token]
    )

    const columns = useMemo<ColumnsType<CeeTableItem>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Přidáno',
          dataIndex: 'createdAt',
          render: createdAt => <Min>{createdAt}</Min>,
          defaultSortOrder: 'descend',
          sorter: utcDescDateSorter('createdAt'),
        },
        {
          title: 'Štítek',
          dataIndex: 'labels',
          render: (labels, record) => (
            <TableCellLabels
              labels={labels}
              labelEntityType="monitoringCeeId"
              recordId={record.id}
              refetch={refetch}
              modalTitle={record.rc}
            />
          ),
        },
        {
          title: 'Poznámka',
          dataIndex: 'note',
          sorter: stringSorter('note'),
        },
        {
          title: 'RČ / IČO',
          dataIndex: 'rc',
          render: (_, record) =>
            record.rc ? (
              <DynamicSearchTooltip
                identifier={{
                  [Identifier.RC]: record.rc,
                  [Identifier.FIRST_NAME]: record.firstname,
                  [Identifier.LAST_NAME]: record.lastname,
                  [Identifier.DESCRIPTION]: record.note,
                }}
                identifierType={Identifier.RC}
                excludedRegisters={RegisterGroupMap.CEE}
              >
                {record.rc}
              </DynamicSearchTooltip>
            ) : record.ico ? (
              <DynamicSearchTooltip
                identifier={{
                  [Identifier.ICO]: record.ico,
                  [Identifier.FIRST_NAME]: record.firstname,
                  [Identifier.LAST_NAME]: record.lastname,
                  [Identifier.DESCRIPTION]: record.note,
                }}
                identifierType={Identifier.ICO}
                excludedRegisters={RegisterGroupMap.CEE}
              >
                {record.ico}
              </DynamicSearchTooltip>
            ) : (
              <div className="text-gray-400 w-full text-center">---</div>
            ),

          sorter: {
            compare: (a, b) =>
              (a.rc || a.ico || '0')
                .slice(0, 6)
                .localeCompare((b.rc || b.ico || '0').slice(0, 6)),
          },
        },
        {
          title: 'Jméno',
          dataIndex: 'fullname',
          render: (_, record) =>
            record.firstname && record.lastname ? (
              record.firstname + ' ' + record.lastname
            ) : (
              <div className="text-gray-400 w-full text-center">---</div>
            ),
          sorter: stringSorter('lastname'),
        },
        {
          title: 'Datum nar.',
          dataIndex: 'birthDate',
          render: (birthDate, record) =>
            birthDate ? (
              format(new Date(birthDate), czDateFormatDay)
            ) : record.rc ? (
              <RcNumberData
                getBirthdate
                rcNumber={record.rc}
                className="text-gray-700"
              />
            ) : (
              <div className="text-gray-400 w-full text-center">---</div>
            ),
          sorter: {
            compare: (a, b) =>
              a.rc && b.rc ? a.rc.toString().localeCompare(b.rc.toString()) : 0,
          },
        },
        {
          title: 'Validace RČ',
          dataIndex: 'validated',
          render: (_, record) =>
            record.ico ? (
              <div className="text-gray-400 text-center">IČO</div>
            ) : record.validatedCode === 30 ? (
              <RcNumberData
                icon
                rcNumber={record.rc}
                className="text-gray-700"
              />
            ) : (
              <RcInputError error={record.validatedCode || 0} />
            ),
        },
        ...(props.monitoring
          ? [
              {
                title: 'Frekvence kontroly',
                dataIndex: 'checkFrequency',
                render: (checkFrequency: number) => {
                  return typeof checkFrequency === 'number' ? (
                    CheckFrequency[checkFrequency]
                  ) : (
                    <div className="text-gray-400">Pozastaveno</div>
                  )
                },
              },
              {
                title: 'Nadcházející kontrola',
                dataIndex: 'nextCheck',
                render: (_: string, record: CeeTableItem) => {
                  if (
                    typeof record.checkFrequency === 'number' &&
                    !record.lastCheck
                  ) {
                    return <div className="text-gray-400">Do 10 minut</div>
                  }
                  if (record.errorState) {
                    return <div className="text-gray-400">Pozastaveno</div>
                  }
                  if (record.checkFrequency) {
                    return formatDistanceToNow(
                      addDays(
                        new Date(record.lastCheck || record.createdAt),
                        record.checkFrequency
                      ),
                      {
                        locale: csLocale,
                        addSuffix: true,
                      }
                    )
                  }
                  return <div className="text-gray-400">Pozastaveno</div>
                },
              },
              {
                title: 'Poslední kontrola',
                dataIndex: 'lastCheck',
                sorter: utcDescDateSorter('lastCheck'),
                render: (date: string) => {
                  if (!date)
                    return (
                      <span className="text-gray-400">Nezkontrolováno</span>
                    )
                  return formatDistanceToNow(new Date(date), {
                    locale: csLocale,
                    addSuffix: true,
                  })
                },
              },
            ]
          : []),
        {
          title: 'Stav CEE',
          dataIndex: 'found',
          sorter: {
            compare: (a, b) => {
              if (a.found === true && b.found !== true) {
                return 1
              } else if (a.found === false && b.found === undefined) {
                return -1
              } else if (a.found === false && b.found === true) {
                return 1
              } else if (a.found === undefined && b.found !== undefined) {
                return -1
              } else {
                return 0
              }
            },
          },
          render: (state: boolean | undefined, record: CeeTableItem) => (
            <CeeMonitorTableStatusCell
              handleExpandRow={handleExpandRow}
              record={record}
              loadingId={loadingId}
              state={state}
            />
          ),
        },
        {
          title: 'Stav ČÚZK',
          dataIndex: 'monitoringCuzkRcsByMonitoringCeeId',
          render: (state, ceeRecord) => (
            <CeeMonitorTableCuzkStatusCell
              refetch={refetch}
              ceeRecord={ceeRecord}
              record={state?.nodes?.[0]}
              state={state?.nodes?.[0]?.found}
            />
          ),
        },
        {
          title: 'Kód validace',
          dataIndex: 'validatedCode',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Akce',
          dataIndex: 'actions',
          render: (_: string, record: CeeTableItem) => (
            <CeeMonitorTableAction
              checkNow={checkNow}
              loadingId={loadingId}
              record={record}
              reexecuteQuery={refetch}
              setLoadingId={setLoadingId}
              handleExpandRow={handleExpandRow}
              monitoring={props.monitoring}
            />
          ),
        },
      ],
      [checkNow, handleExpandRow, loadingId, props.monitoring, refetch]
    )

    const locale = useMemo(
      () => ({
        emptyText: !queryResult.fetching && (
          <TableEmptyText
            buttonText="Vyhledat subjekt"
            buttonLink={ceeMonitorCreatePath()}
            text="Zatím jste nevyhledali žádné subjekty v Centrální evidenci exekucí."
          />
        ),
      }),
      [queryResult.fetching]
    )

    const expandable = useMemo<ExpandableConfig<CeeTableItem>>(() => {
      return {
        expandedRowKeys,
        expandedRowRender: record => (
          <CeeMonitorTableExpandedRow monitoringCeeId={record.id} />
        ),
        columnTitle: (
          <ExpandColumnTitle
            allKeys={dataSource
              .filter(record => !!record.found)
              .map(record => record.id)}
            tableId={tableId}
          />
        ),
        expandIcon: ({ expanded, record }) => (
          <ExpandIcon
            expanded={expanded}
            recordId={record.id}
            tableId={tableId}
            expandable={!!record.found}
          />
        ),
      }
    }, [dataSource, expandedRowKeys])

    const rowSelection = useMemo<TableRowSelection<CeeTableItem>>(
      () => ({
        selectedRowKeys,
        onChange: (newSelectedRowKeys, selectedRows) => {
          setSelectedRowKeys(newSelectedRowKeys)
          setSelectedRow(selectedRows)
        },
      }),
      [selectedRowKeys]
    )

    const summary = useCallback(
      () => (
        <TableSummaryExport
          total={dataSource?.length || 0}
          colSpan={props.monitoring ? 14 : 11}
          url="cee/ceeXlsx"
          fileName="CEE"
          dataSource={dataSource || []}
        />
      ),
      [dataSource, props.monitoring]
    )

    return (
      <>
        <CeeMonitorTableBulkEdit
          refetch={resetAndRefetch}
          checkNow={checkNow}
          selectedRows={selectedRow}
          monitoring={props.monitoring}
        />
        <Table
          id={tableId}
          rowKey="id"
          rowClassName="animate-fadeIn"
          size="small"
          dataSource={[...dataSource]}
          columns={columns}
          loading={queryResult.fetching}
          expandable={expandable}
          locale={locale}
          rowSelection={rowSelection}
          pagination={tablePagination}
          summary={summary}
          scroll={scroll}
        />
      </>
    )
  }
