import { Skeleton, Typography } from 'antd'
import React, { FunctionComponent, memo } from 'react'

const { Title } = Typography
const titleStyle = { marginBottom: 0 }

export const DetailDrawerPropertySkeleton: FunctionComponent = memo(() => {
  return (
    <div className="space-y-3">
      <Title level={3} style={titleStyle} className="blur-sm animate-pulse">
        Načítání názvu vybrané nemovitosti v mapě...
      </Title>
      <Skeleton.Image active className="!w-full !h-72" />
      <Skeleton active />
    </div>
  )
})
