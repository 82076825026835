import { Text, View } from '@react-pdf/renderer'
import React, { FunctionComponent } from 'react'

import { monitPdfCss } from '../../utils/monitPdfCss'

export interface PriceEstimatePdfPublicNoteFragmentProps {
  note?: string | null
}

export const PriceEstimatePdfPublicNoteFragment: FunctionComponent<PriceEstimatePdfPublicNoteFragmentProps> =
  props => {
    if (!props.note) return null
    return (
      <View wrap={false}>
        <Text style={monitPdfCss.tableHeader}>Doplňující informace</Text>
        <Text>{props.note}</Text>
      </View>
    )
  }
