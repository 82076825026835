import { MenuOutlined } from '@ant-design/icons'
import { Button, Dropdown, MenuProps, message, Tooltip } from 'antd'
import { format, parseISO } from 'date-fns'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { useAppSelector } from '../../../redux/hooks'
import { handleResponseStatus } from '../../utils/handleResponseStatus'
import { clickTrigger } from '../../utils/layoutConst'
import { requestNajdiOsPath } from '../../utils/paths'
import { pricelist } from '../../utils/pricelist'
import { CeeMonitorAddSubjectModal } from '../CeeMonitorAddSubjectModal/CeeMonitorAddSubjectModal'
import {
  CUZK_PERSON_TYPES,
  CuzkEpoModalButton,
} from '../CuzkEpoModalButton/CuzkEpoModalButton'
import { CuzkMonitorRcBulkDelete } from '../CuzkMonitorRcBulkDelete/CuzkMonitorRcBulkDelete'
import { RcTableItem } from '../CuzkMonitorRcTable/CuzkMonitorRcTable'
import { CuzkMonitorRcTableItemEdit } from '../CuzkMonitorRcTableItemEdit/CuzkMonitorRcTableItemEdit'
import { PricePopconfirm } from '../PricePopconfirm/PricePopconfirm'

export interface CuzkMonitorRcTableActionProps {
  reexecuteQuery: () => void
  setLoadingId: (id: string[]) => void
  record: RcTableItem
}

export const CuzkMonitorRcTableAction: FunctionComponent<CuzkMonitorRcTableActionProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)

    const checkNow = useCallback(async () => {
      props.setLoadingId([props.record.id])

      try {
        const response = await fetch(
          requestNajdiOsPath() + token + '/' + [props.record.id]
        )

        if (response.status === 200) {
          const data = await response.json()
          if (Array.isArray(data)) {
            data.includes(props.record.id)
              ? message.success('Subjekt byl nalezen v evidenci ČÚZK')
              : message.warning('Subjekt se nevyskytuje v evidenci ČÚZK')
          }
        } else {
          handleResponseStatus(response.status)
        }
      } catch (error) {
        message.error('Došlo k chybě při zpracování požadavku')
      } finally {
        props.reexecuteQuery()
        props.setLoadingId([])
      }
    }, [props, token])

    const epoButtonProps = useMemo(
      () => ({
        buttonProps: {
          size: 'middle' as const,
          type: 'link' as const,
          title: 'Vyhledat evidenci práv pro osobu',
          className: 'w-full text-center',
        },
        buttonText: 'Vyhledat EPO',
        initialValues: {
          type: props.record.ico
            ? CUZK_PERSON_TYPES.OPO
            : CUZK_PERSON_TYPES.OFO,
          rc: props.record.rc,
          ico: props.record.ico,
          firstname:
            props.record.cuzkOByCuzkOsId?.jmeno || props.record.firstname,
          lastname:
            props.record.cuzkOByCuzkOsId?.prijmeni || props.record.lastname,
          birthDate: props.record?.birthDate
            ? format(parseISO(props.record.birthDate.toString()), 'yyMMdd')
            : props.record?.rc ?? undefined,
        },
        // refetch: setEpoSubmited,
      }),
      [
        props.record.birthDate,
        props.record.cuzkOByCuzkOsId?.jmeno,
        props.record.cuzkOByCuzkOsId?.prijmeni,
        props.record.firstname,
        props.record.ico,
        props.record.lastname,
        props.record.rc,
      ]
    )

    const menuProps = useMemo<MenuProps>(
      () => ({
        items: [
          {
            key: 'edit',
            className: '!p-0',
            label: (
              <CuzkMonitorRcTableItemEdit
                item={props.record}
                onSave={props.reexecuteQuery}
              />
            ),
          },
          {
            key: 'searchCee',
            className: '!p-0',
            label: (
              <CeeMonitorAddSubjectModal
                buttonProps={{
                  size: 'middle',
                  type: 'link',
                  className: 'w-full',
                }}
                buttonText="Hledat v CEE"
                dataSource={[
                  {
                    id: props.record.id,
                    key: 1,
                    description: props.record.description,
                    rc: props.record.rc,
                    ico: props.record.ico,
                    firstname: props.record.firstname,
                    lastname: props.record.lastname,
                    birthDate: props.record.birthDate?.toString(),
                    typed: props.record.typed,
                    validatedCode: props.record.validatedCode,
                  },
                ]}
              />
            ),
          },
          {
            key: 'searchEpo',
            className: '!p-0',
            disabled: !props.record.found,
            label:
              props.record.found === false ? (
                <Tooltip title="Subjekt nebyl nalezen v evidenci ČÚZK, proto již není potřeba kontrolovat EPO.">
                  <Button disabled {...epoButtonProps.buttonProps}>
                    {epoButtonProps.buttonText}
                  </Button>
                </Tooltip>
              ) : (
                <CuzkEpoModalButton {...epoButtonProps} />
              ),
          },
          {
            key: 'delete',
            className: '!p-0',
            label: (
              <CuzkMonitorRcBulkDelete
                items={[props.record]}
                refetch={props.reexecuteQuery}
                buttonProps={{
                  size: 'middle',
                  type: 'link',
                  className: 'w-full',
                }}
              />
            ),
          },
        ],
      }),
      [epoButtonProps, props.record, props.reexecuteQuery]
    )

    return (
      <Dropdown.Button
        icon={<MenuOutlined />}
        menu={menuProps}
        arrow
        trigger={clickTrigger}
        type="link"
        size="small"
        className="!flex justify-end items-end space-x-1"
      >
        <PricePopconfirm
          key="leftButton"
          onConfirm={checkNow}
          itemPrice={pricelist.CUZK}
          message={`Kontrola v evidenci ČÚZK je zpoplatněna ${pricelist.CUZK} Kr.`}
        >
          Zkontrolovat
        </PricePopconfirm>
      </Dropdown.Button>
    )
  }
