import { Button, Tooltip } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { useAppSelector } from '../../../redux/hooks'
import { priceEstimateDetailPath } from '../../utils/paths'

export interface CuzkLvHtmlAreaPriceEstimateButtonProps {
  area?: string
}

export const CuzkLvHtmlAreaPriceEstimateButton: FunctionComponent<CuzkLvHtmlAreaPriceEstimateButtonProps> =
  props => {
    const nemovitosti = useAppSelector(state => state.lvDetail.nemovitosti)
    const parcelaId = useMemo(() => {
      for (const parcela of nemovitosti.parcely) {
        if (parcela.parcelniCislo === props.area?.trim()) {
          return parcela.id
        }
      }
      return undefined
    }, [nemovitosti.parcely, props.area])

    if (!parcelaId)
      return (
        <Tooltip title="Chybí potřebné informace pro vytvoření cenového odhadu.">
          <Button className="w-full" type="primary" disabled>
            Vytvořit cenový odhad
          </Button>
        </Tooltip>
      )

    return (
      <a
        target="_blank"
        href={`${priceEstimateDetailPath('parcela', parcelaId)}`}
        rel="noreferrer"
      >
        <Button className="w-full" type="primary">
          Vytvořit cenový odhad
        </Button>
      </a>
    )
  }
