import { CSSProperties } from 'react'
import { pieChartDefaultProps } from 'react-minimal-pie-chart'

import { formatCurrencyNumber } from '../app/components/Money/Money'

export const propertyChartProps = {
  notSelected: { segment: null, subject: undefined },
  modalProps: {
    okButtonProps: { style: { display: 'none' } },
    cancelText: 'Zavřít',
    width: 800,
    closable: true,
  },
  pieChartProp: {
    viewBoxSize: [200, 120] as [number, number],
    startAngle: 285,
    labelPosition: 112,
    lineWidth: 60,
    paddingAngle: 0.4,
    animate: true,
    labelStyle: {
      opacity: 0.75,
      pointerEvents: 'none',
      whiteSpace: 'pre',
      lineHeight: 'initial',
      fontSize: '.3rem',
    } as CSSProperties,
    style: {
      overflow: 'visible',
    },
  },
}

export const areaChartProps = {
  viewBoxSize: [200, 120] as [number, number],
  center: [100, 60] as [number, number],
  radius: pieChartDefaultProps.radius - 6,
  segmentsStyle: { transition: 'stroke .3s' },
  labelPosition: 112,
  lineWidth: 60,
  animate: true,
  labelStyle: {
    opacity: 0.75,
    pointerEvents: 'none',
    whiteSpace: 'pre',
    lineHeight: 'initial',
    fontSize: '.3rem',
  } as CSSProperties,
  style: {
    overflow: 'visible',
  },
} as const

export const areaChartLabel = ({
  dataEntry: { title, percentage, value },
}: {
  dataEntry: {
    title: string
    percentage: number
    value: number
  }
}) => {
  const formattedPercentage = Math.round(percentage)
  const formattedValue = formatCurrencyNumber(value, 0)
  return `${title}\n${formattedPercentage} % (${formattedValue} m²)`
}
