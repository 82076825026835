import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons'
import { Tag, Tooltip } from 'antd'
import React, { FunctionComponent } from 'react'

import { CeeTableItem } from '../../../common/ceeTypes'
import { Min } from '../DateFormat/DateFormat'

export interface CeeMonitorTableStatusCellProps {
  state: boolean | undefined
  record: CeeTableItem
  loadingId: string[]
  handleExpandRow: (id: string) => void
}

export const CeeMonitorTableStatusCell: FunctionComponent<CeeMonitorTableStatusCellProps> =
  ({ state, record, loadingId, handleExpandRow }) => {
    if (loadingId.includes(record.id)) {
      return (
        <Tooltip
          title={`Záznam se právě kontroluje, výsledek bude dostupný zhruba do ${loadingId.length} sekund.`}
        >
          <Tag color="blue" icon={<SyncOutlined spin />}>
            Ověřuje se
          </Tag>
        </Tooltip>
      )
    }

    if (record.errorState) {
      return (
        <Tooltip
          title={
            <div>
              Při kontrole <Min>{record.editedAt}</Min> se ze serveru CEE
              vrátila chybová odpověď. Chyba byla patrně způsobena dočasným
              výpadkem Centrální evidence exekucí. Kontaktujte prosím technickou
              podporu.
            </div>
          }
        >
          <Tag color="error">Chyba serveru CEE</Tag>
        </Tooltip>
      )
    }

    if (state === true) {
      return (
        <Tooltip title="Kliknutím zobrazíte detail">
          <Tag
            onClick={() => handleExpandRow(record.id)}
            icon={<CheckCircleOutlined />}
            color="green"
            className="hover:underline cursor-pointer"
          >
            Nalezeno
          </Tag>
        </Tooltip>
      )
    }

    if (state === null) {
      return typeof record.checkFrequency === 'number' ? (
        <Tooltip title="Záznam nebyl prozatím zkontrolován, ke kontrole dojde v nejbližším možném termínu (zhruba do 10 minut).">
          <Tag color="blue" icon={<SyncOutlined spin />}>
            Ve frontě
          </Tag>
        </Tooltip>
      ) : (
        <Tooltip title="Kontrola byla pozastavena ještě před ověřením v registru.">
          <Tag className="!opacity-40">Pozastaveno</Tag>
        </Tooltip>
      )
    }

    return (
      <Tooltip
        title={
          <div>
            <span>Při poslední kontrole </span>
            <Min>{record.lastCheck}</Min>
            <span> nebyl subjekt evidován v CEE.</span>
          </div>
        }
      >
        <Tag>Nenalezeno</Tag>
      </Tooltip>
    )
  }
