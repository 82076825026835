import { Image } from 'antd'
import React, { FunctionComponent, useCallback, useState } from 'react'

import { AUCTION_TYPES } from '../../../common/auctionScrapeTypes'
import { imageSkeleton } from '../../utils/layoutConst'
import { useAuctionMapImage } from '../../utils/usePropertyDetailImage'
import { useAuctionImagePreviewProps } from '../../utils/usePropertyImagePreviewProps'
import { MAP_TYPE, MapTypeButton } from '../MapTypeButton/MapTypeButton'

interface AuctionMonitoringTilesItemImageProps {
  auctionId: string
  lat?: number | null
  long?: number | null
  auctionType: AUCTION_TYPES
  className?: string
  photoUrl?: string | null
  auctionUrl: string
}

const wrapperStyle = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  objectFit: 'cover',
} as const

const placeholder = (
  <Image
    preview={false}
    src={imageSkeleton}
    className="absolute inset-0 w-full !h-full object-cover animate-pulse"
    wrapperStyle={wrapperStyle}
  />
)

export const AuctionMonitoringTilesItemImage: FunctionComponent<AuctionMonitoringTilesItemImageProps> =
  props => {
    const [imageState, setImageState] = useState(
      props.photoUrl ? MAP_TYPE.SW : MAP_TYPE.AERIAL
    )
    const [error, setError] = useState(false)

    const handleError = useCallback(() => {
      setError(true)
      setImageState(MAP_TYPE.AERIAL)
    }, [setImageState])

    const mapImage = useAuctionMapImage(
      props.lat,
      props.long,
      props.auctionType,
      props.photoUrl
    )

    const previewProps = useAuctionImagePreviewProps(
      [imageState, setImageState],
      mapImage,
      props.auctionUrl
    )

    return (
      <div
        className={`relative w-full pb-full ${
          props.className ? props.className : 'h-24'
        }`}
      >
        <Image
          loading="lazy"
          placeholder={placeholder}
          fallback={imageSkeleton}
          preview={previewProps}
          onError={handleError}
          src={mapImage[imageState].src}
          alt="Fotografie / mapa nemovitosti"
          className="absolute inset-0 w-full !h-full object-cover"
          wrapperStyle={wrapperStyle}
        />
        <div className="absolute bottom-2 left-2 p-2 flex space-x-2">
          {props.photoUrl && (
            <MapTypeButton
              disabled={error}
              type={MAP_TYPE.SW}
              imageState={[imageState, setImageState]}
            />
          )}
          <MapTypeButton
            type={MAP_TYPE.AERIAL}
            imageState={[imageState, setImageState]}
          />
          <MapTypeButton
            type={MAP_TYPE.MAP}
            imageState={[imageState, setImageState]}
          />
        </div>
      </div>
    )
  }
