import { Button, Divider, Modal, Progress, Tooltip } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { BASE_URL } from '../../../../ini.json'
import { BookmarkDetailItem } from '../../../common/bookmarkTypes'
import { useAppSelector } from '../../../redux/hooks'
import { cadastrePostLetterPath } from '../../utils/paths'
import { useToggleState } from '../../utils/useToggleState'
import { CadastrePostCreateRecipientListForm } from '../CadastrePostCreateRecipientListForm/CadastrePostCreateRecipientListForm'
import { Fade } from '../Fade/Fade'

export interface CreateRecipientListButton {
  name: string
  id: string
  recipientList?: BookmarkDetailItem[]
}

export const CreateRecipientListButton: FunctionComponent<CreateRecipientListButton> =
  props => {
    const history = useHistory()
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const userId = useAppSelector(state => state.myProfile.userId)
    const token = useAppSelector(state => state.myProfile.token)

    const [showHelp, toggleShowHelp] = useToggleState()
    const [open, toggleModal] = useToggleState()
    const [currentItem, setCurrentItem] = useState<
      { percentage: number; name: string; count: number } | undefined
    >()
    const [listId, setListId] = useState<string | undefined>()
    const data = props.recipientList

    const handleRecipients = useCallback(
      async (id: string) => {
        setListId(id)
        if (!data?.length) return console.error('No data')
        for (let i = 0; i < data.length; i++) {
          try {
            await fetch(`${BASE_URL}/api/postService/recipient`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                token,
                tenantId,
                userId,
                listId: id,
                ...data[i],
              }),
            })
            const completionPercentage = Math.ceil(
              ((i + 1) / data.length) * 100
            )
            setCurrentItem({
              percentage: completionPercentage,
              name: data[i].subjekt.nazev || '',
              count: i + 1,
            })
            if (i === data.length - 1) history.push(cadastrePostLetterPath(id))
          } catch (error) {
            console.error(`Error processing recipient ${i}:`, error)
          }
        }
      },
      [data, history, tenantId, token, userId]
    )

    const footer = useMemo(
      () => (
        <div className="flex justify-end">
          <Button disabled>Zrušit</Button>
          <Button loading type="primary">
            Pokračovat
          </Button>
        </div>
      ),
      []
    )

    if (!data?.length)
      return (
        <Tooltip title="Nejsou vybráni žádní adresáti">
          <Button type="primary" disabled className="sm:w-auto w-full">
            Použít seznam adresátů
          </Button>
        </Tooltip>
      )
    return (
      <>
        <Button
          type="primary"
          onClick={toggleModal}
          className="sm:w-auto w-full"
        >
          Použít seznam adresátů
        </Button>
        <Modal
          title="Hromadná korespondence"
          open={open}
          onCancel={toggleModal}
          footer={listId ? footer : null}
          width={600}
        >
          <Fade show={!!listId}>
            <div className="mb-2 mt-6">
              Načítají se detaily adresátů, prosím vyčkejte...{' '}
              <span className="text-gray-400">
                {currentItem?.count} z {data?.length}
              </span>
            </div>
            <div className="flex justify-center mt-8">
              <Progress type="circle" percent={currentItem?.percentage} />
            </div>
            <div className="mt-6 flex justify-center text-gray-400">
              {currentItem?.name}
            </div>

            <Divider />
          </Fade>
          {!listId && (
            <>
              <Fade show={showHelp} className="mb-4 mt-6 text-gray-400">
                Hromadnou korespondenci si můžete pojmenovat a přidat její
                popis. Tyto údaje slouží pouze pro vás v rámci systému a jsou
                neveřejné.
              </Fade>
              <CadastrePostCreateRecipientListForm
                id={props.id}
                dataFetched={!!data}
                hideModal={toggleModal}
                handleRecipients={handleRecipients}
                name={props.name}
                handleHelpToggle={toggleShowHelp}
                showHelp={showHelp}
              />
            </>
          )}
        </Modal>
      </>
    )
  }
