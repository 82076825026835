import { Document } from '@react-pdf/renderer'
import React, { FunctionComponent } from 'react'

import { CeeDetailPdfContent } from '../CeeDetailPdfContent/CeeDetailPdfContent'
import { CeeMonitorItem } from '../CeeMonitorTableExpandedRow/CeeMonitorTableExpandedRow'

export interface CeeDetailPdfProps {
  data?: CeeMonitorItem[] | CeeMonitorItem
}

export const CeeDetailPdf: FunctionComponent<CeeDetailPdfProps> = props => {
  if (!props.data) return <Document />

  const dataSource = Array.isArray(props.data) ? props.data : [props.data]
  return (
    <Document>
      {dataSource.map((page: CeeMonitorItem, index: number) => (
        <CeeDetailPdfContent key={index} data={page} />
      ))}
    </Document>
  )
}
