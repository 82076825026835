import axios from 'axios'

import { BASE_URL } from '../../../ini.json'
import { removeDiacritics } from '../../common/pdfHelpers'

export const fetchRisy = async (
  kodObce: string | number,
  nazevObce?: string
) => {
  const risyCode = `${kodObce}${
    nazevObce ? '-' + removeDiacritics(nazevObce, true, true) : ''
  }`
  try {
    const response = await axios.post(`${BASE_URL}/api/risy/area`, {
      risyCode: encodeURIComponent(risyCode),
    })
    if (response.status === 200) {
      return response.data
    }
    return
  } catch (error) {
    console.error('Risy API call failed: ', error)
  }
}
