import React, { FunctionComponent, useMemo } from 'react'

export interface PropertyDetailSidebarContainerProps {
  children: React.ReactNode
  narrow?: boolean
  isDrawer?: boolean
  className?: string
}

export const PropertyDetailSidebarContainer: FunctionComponent<PropertyDetailSidebarContainerProps> =
  props => {
    const style = useMemo(
      () =>
        (props.className || '') +
        (props.isDrawer
          ? ''
          : ' bg-white rounded-sm ' + (props.narrow ? 'p-2' : 'px-3 py-4')),
      [props.className, props.isDrawer, props.narrow]
    )

    if (!props.children) return null
    return <div className={style}>{props.children}</div>
  }
