import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Alert } from 'antd'
import React, { FunctionComponent, memo } from 'react'

export interface PropertyMonitoringFetchErrorProps {
  type: 'dědictví' | 'exekucí' | 'insolvencí' | 'dražeb'
}

export const PropertyMonitoringFetchError: FunctionComponent<PropertyMonitoringFetchErrorProps> =
  memo(props => {
    return (
      <Alert
        icon={<ExclamationCircleOutlined />}
        showIcon
        type="warning"
        description={
          <span className="text-gray-500">{`Je nám líto, ale nepodařilo se načíst data z monitoringu ${props.type}. Zkuste stránku aktualizovat nebo kontaktujte technickou podporu.`}</span>
        }
        message="Chyba při načítání dat"
      />
    )
  })
