import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Modal } from 'antd'
import { ButtonProps } from 'antd/lib/button/button'
import { formatISO, parse } from 'date-fns'
import isPast from 'date-fns/isPast'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { BASE_URL } from '../../../../ini.json'
import { useAppSelector } from '../../../redux/hooks'
import { cuzkLvFormHelp } from '../../utils/cuzkMonitorRcFormHelp'
import { itemHelp, itemRequired, onlyNumbers } from '../../utils/formHelpers'
import { validateTrigger } from '../../utils/layoutConst'
import { pricelist } from '../../utils/pricelist'
import { useAssignLabels } from '../../utils/useAssignLabels'
import { LabelSelectInput } from '../LabelSelectInput/LabelSelectInput'
import { PricePopconfirm } from '../PricePopconfirm/PricePopconfirm'
import { SearchCadastralArea } from '../SearchCadastralArea/SearchCadastralArea'

export interface CuzkLvModalFormValues {
  lvCislo?: string
  katastralniUzemiKod?: string
  dataDate?: string
}
export interface CuzkLvModalButtonProps {
  initialValues?: CuzkLvModalFormValues
  refetch?: (value: boolean) => void
  buttonProps?: ButtonProps
  buttonText?: JSX.Element | string
  defaultClose?: boolean
}

export const CuzkLvModalButton: FunctionComponent<CuzkLvModalButtonProps> =
  props => {
    const { tenantId, userId, token } = useAppSelector(state => state.myProfile)
    const { assignLabels } = useAssignLabels()
    const [form] = Form.useForm()
    const [isModalVisible, setIsModalVisible] = useState(
      !!(
        props.initialValues?.katastralniUzemiKod &&
        props.initialValues.lvCislo &&
        !props.defaultClose
      )
    )

    const [labelsId, setLabelsId] = useState<string[] | []>([])
    const [showHelp, setShowHelp] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [cadastralData, setCadastralData] = useState<{
      katastralniUzemiKod?: string
      katastralniUzemiNazev?: string
      obecNazev?: string
    }>({
      katastralniUzemiKod: undefined,
      katastralniUzemiNazev: undefined,
      obecNazev: undefined,
    })

    const toggleModalVisibility = useCallback(() => {
      setIsModalVisible(prev => !prev)
    }, [])

    const dateValidation = useCallback((_: unknown, value: string) => {
      if (!value) return Promise.resolve()
      const parts = value ? value.split('.') : []
      if (parts.length !== 3) {
        return Promise.reject(new Error('Neplatný formát data'))
      }

      const day = parseInt(parts[0])
      const month = parseInt(parts[1])
      const year = parseInt(parts[2])
      const date = new Date(year, month - 1, day)

      if (!isPast(date))
        return Promise.reject(new Error('Datum musí být v minulosti'))

      if (day < 1 || day > 31)
        return Promise.reject(new Error('Neplatný den v datu'))

      if (month < 1 || month > 12)
        return Promise.reject(new Error('Neplatný měsíc v datu'))

      if (year < 1910 || year > new Date().getFullYear())
        return Promise.reject(new Error('Neplatný rok'))

      return Promise.resolve()
    }, [])

    const normalizeDateTime = useCallback((value: string) => {
      const parsedDate = parse(value, 'dd.MM.yyyy', new Date())
      parsedDate.setHours(12, 0, 0, 0)

      return formatISO(parsedDate)
    }, [])

    const cuzkDateNormalization = useCallback((value: string, prev: string) => {
      value = value
        .replace(/[-/]/g, '.')
        .replace(/[^\d.]+/g, '')
        .replace(/\.{2,}/g, '.')

      if (value.length === 8 && /^[0-9]+$/.test(value)) {
        return (
          value.slice(0, 2) + '.' + value.slice(2, 4) + '.' + value.slice(4)
        )
      }
      if (value.length > 5 && value.length > prev.length) {
        const parts = value.split('.')
        return parts
          .map((part, index) =>
            index < 2 && part.length === 1 ? '0' + part : part
          )
          .join('.')
      }

      return value
    }, [])

    const handleOnClick = useCallback(async () => {
      const validated = await form.validateFields().catch(() => false)
      setDisabled(!validated)
    }, [form])

    const handleOnConfirm = useCallback(async () => {
      const formValues = form.getFieldsValue()

      setLoading(true)
      try {
        const response = await fetch(`${BASE_URL}/api/cuzk/lv`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token,
            tenantId,
            userId,
            katastrUzemiNazev: cadastralData.katastralniUzemiNazev,
            obec: cadastralData.obecNazev,
            katastrUzemiKod:
              formValues.katastrUzemiKod.value || formValues.katastrUzemiKod,
            lvCislo: formValues.lvCislo,
            datumK: formValues.dataDate
              ? normalizeDateTime(formValues.dataDate)
              : undefined,
          }),
        })

        if (response.status !== 200)
          return message.error('Při vyhledávání LV došlo k chybě.')

        await response.json().then(async data => {
          await assignLabels({
            cuzkLvId: data,
            labelsId,
          })
        })
      } catch (error) {
        message.error('Při vyhledávání došlo k chybě.')
      }

      setLoading(false)
      toggleModalVisibility()
      return props.refetch && props.refetch(true)
    }, [
      form,
      toggleModalVisibility,
      props,
      token,
      tenantId,
      userId,
      cadastralData.katastralniUzemiNazev,
      cadastralData.obecNazev,
      normalizeDateTime,
      assignLabels,
      labelsId,
    ])

    const popconfrimProps = useMemo(() => {
      if (disabled)
        return {
          okButtonProps: {
            disabled: true,
          },
          title: (
            <span className="text-red-500">
              Prosím zadejte všechny povinné údaje.
            </span>
          ),
        }
      if (loading)
        return {
          okButtonProps: {
            loading: true,
          },
        }
      return
    }, [disabled, loading])

    const footer = useMemo(
      () => (
        <div className="flex justify-between">
          <Button
            icon={<InfoCircleOutlined />}
            type="link"
            onClick={() => setShowHelp(!showHelp)}
          >
            {showHelp ? 'Skrýt nápovědu' : 'Zobrazit nápovědu'}
          </Button>
          <div>
            <Button onClick={toggleModalVisibility}>Zrušit</Button>
            <PricePopconfirm
              popconfrimProps={popconfrimProps}
              onConfirm={handleOnConfirm}
              itemPrice={pricelist.EPO}
              message={`Vyhledání subjektu v evidenci práv ČÚZK je zpoplatněno ${pricelist.EPO} Kr.`}
            >
              <Button loading={loading} type="primary" onClick={handleOnClick}>
                Vyhledat
              </Button>
            </PricePopconfirm>
          </div>
        </div>
      ),
      [
        handleOnClick,
        handleOnConfirm,
        loading,
        popconfrimProps,
        showHelp,
        toggleModalVisibility,
      ]
    )

    return (
      <div>
        <Button
          type="primary"
          onClick={toggleModalVisibility}
          {...props.buttonProps}
        >
          {props.buttonText || 'Nové vyhledávání'}
        </Button>
        <Modal
          title="Vyhledat list vlastnictví"
          open={isModalVisible}
          onCancel={toggleModalVisibility}
          footer={footer}
        >
          <Form<CuzkLvModalFormValues>
            layout="vertical"
            className="!mt-8"
            initialValues={{
              katastrUzemiKod: props.initialValues?.katastralniUzemiKod,
              lvCislo: props.initialValues?.lvCislo,
            }}
            form={form}
            validateTrigger={validateTrigger}
          >
            <Form.Item
              label="Katastrální území"
              name="katastrUzemiKod"
              help={itemHelp(showHelp, cuzkLvFormHelp.katUz)}
              rules={[
                {
                  validator: async (_, value) => {
                    if (!value || value.length < 3) {
                      throw new Error('Název musí být delší než 3 znaky.')
                    }
                  },
                  required: true,
                  message: 'Zadejte název či kód katastrálního území.',
                },
              ]}
            >
              <SearchCadastralArea
                initialValue={props.initialValues?.katastralniUzemiKod}
                setCadastralData={setCadastralData}
              />
            </Form.Item>
            <Form.Item
              label="Číslo LV"
              name="lvCislo"
              normalize={onlyNumbers}
              help={itemHelp(showHelp, cuzkLvFormHelp.lv)}
              rules={itemRequired('Zadejte číslo LV.')}
            >
              <Input placeholder="Číslo LV" maxLength={5} />
            </Form.Item>
            <Form.Item
              label="Údaje k datu (nepovinné)"
              name="dataDate"
              normalize={cuzkDateNormalization}
              help={itemHelp(showHelp, cuzkLvFormHelp.datumK)}
              rules={[
                {
                  validator: dateValidation,
                },
                {
                  pattern: /^(\d{2})\.(\d{2})\.(\d{4})$/,
                  message: 'Zadejte datum ve formátu DD.MM.RRRR',
                },
              ]}
            >
              <Input
                allowClear
                maxLength={10}
                placeholder="DD.MM.RRRR (prázdné = aktuální datum)"
              />
            </Form.Item>
          </Form>
          <div className="mb-2 mt-6">Štítek (nepovinné)</div>
          <div className="mb-10">
            <LabelSelectInput disabled={loading} onChange={setLabelsId} />
            <span className="text-gray-400">
              {itemHelp(showHelp, cuzkLvFormHelp.labels)}
            </span>
          </div>
        </Modal>
      </div>
    )
  }
