import { Button, Space } from 'antd'
import type { Map, MapEvent } from 'mapbox-gl'
import React, { FunctionComponent, useMemo } from 'react'

import { Overlay, OverlayProps } from '../CadastreMapOverlay/CadastreMapOverlay'

export interface CadastreMapBasemapControlProps extends OverlayProps {
  onRasterTileSource: (source: string) => void

  radonEnabled: boolean
  onRadonEnabledChange: (enabled: boolean) => void
}

// NOTE: This component fucking sucks sorry
const Control: FunctionComponent<{
  map: Map
  onRasterTileSource: (source: string) => void
  radonEnabled: boolean
  onRadonEnabledChange: (enabled: boolean) => void
}> = ({ map, onRasterTileSource, radonEnabled, onRadonEnabledChange }) => {
  const currentStyle = map && map.style && map.style._loaded && map?.getStyle()
  const sources = useMemo(
    () =>
      currentStyle &&
      currentStyle?.sources &&
      typeof currentStyle.sources === 'object'
        ? currentStyle.sources
        : {},
    [currentStyle]
  )
  const rasterUrl =
    'https://ags.cuzk.cz/arcgis1/rest/services/ORTOFOTO_WM/MapServer/tile/{z}/{y}/{x}'
  const rasterActive = useMemo(() => {
    return (
      Object.values(sources)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .filter((source: any) => source.type === 'raster')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .some((source: any) => source.tiles.includes(rasterUrl))
    )
  }, [sources])

  return (
    <Space.Compact size="small" block>
      <Button
        type={radonEnabled ? 'primary' : 'default'}
        onClick={() => {
          onRadonEnabledChange(!radonEnabled)
        }}
      >
        Radon
      </Button>
      <Button
        type={rasterActive ? 'primary' : 'default'}
        onClick={() => {
          onRasterTileSource(rasterUrl)
        }}
      >
        Orto
      </Button>
    </Space.Compact>
  )
}

const events: Exclude<MapEvent, number | symbol>[] = ['styledata']

export const CadastreMapBasemapControl: FunctionComponent<CadastreMapBasemapControlProps> =
  props => {
    return (
      <Overlay events={events} position={props.position}>
        {({ map }) => (
          <Control
            onRasterTileSource={props.onRasterTileSource}
            radonEnabled={props.radonEnabled}
            onRadonEnabledChange={props.onRadonEnabledChange}
            map={map}
          />
        )}
      </Overlay>
    )
  }
