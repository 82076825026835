import { Button, InputNumber } from 'antd'
import React, { FunctionComponent, useCallback, useState } from 'react'

import { AreaSearchForm } from '../../../common/areaSearchTypes'
import { SearchCadastralArea } from '../SearchCadastralArea/SearchCadastralArea'

export interface AreaSearchBarProps {
  onSubmit: (data: AreaSearchForm) => void
}

export const AreaSearchBar: FunctionComponent<AreaSearchBarProps> = props => {
  const [katastralniUzemiKod, setKatastralniUzemiKod] = useState('')
  const [kmenoveCislo, setKmenoveCislo] = useState<string | null>('')
  const [pododdeleniCisla, setPododdeleniCisla] = useState<string | null>('')

  const handleCadastralArea = useCallback(
    (data: { katastralniUzemiKod?: string }) => {
      setKatastralniUzemiKod(data?.katastralniUzemiKod || '')
    },
    []
  )

  const handleSearch = useCallback(() => {
    props.onSubmit({ katastralniUzemiKod, kmenoveCislo, pododdeleniCisla })
  }, [props, katastralniUzemiKod, kmenoveCislo, pododdeleniCisla])

  return (
    <div className="flex space-x-2">
      <SearchCadastralArea
        initialValue={katastralniUzemiKod}
        setCadastralData={handleCadastralArea}
        size="large"
      />
      <InputNumber
        className="!w-full"
        size="large"
        placeholder="Kmenové číslo"
        max="99999"
        value={kmenoveCislo}
        onChange={setKmenoveCislo}
        onPressEnter={handleSearch}
      />
      <InputNumber
        className="!w-full"
        size="large"
        placeholder="Díl parcely"
        type="number"
        max="999"
        value={pododdeleniCisla}
        onChange={setPododdeleniCisla}
        onPressEnter={handleSearch}
      />
      <Button
        size="large"
        disabled={!katastralniUzemiKod}
        type="primary"
        onClick={handleSearch}
      >
        Vyhledat parcely
      </Button>
    </div>
  )
}
