import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Form, message, Popover } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { useCreatePropertyNoteMutation } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { propertyNoteFormHelp } from '../../utils/cuzkMonitorRcFormHelp'
import {
  PropertyDetailNote,
  PropertyNoteFormValues,
  validateUrl,
} from '../../utils/propertyDetailNoteHelpers'
import { usePropertyDetailTitle } from '../../utils/usePropertyDetailTitle'
import { useToggleState } from '../../utils/useToggleState'
import { Fade } from '../Fade/Fade'
import { NoteIconSettingButton } from '../NoteIconSettingButton/NoteIconSettingButton'
import { PropertyNoteFormBaseFragment } from '../PropertyNoteFormBaseFragment/PropertyNoteFormBaseFragment'

export interface PropertyDetailNoteCreateProps {
  propertyId: string
  propertyType: PROPERTY_TYPES
  propertyLv: string
  propertyCadastralCode: string
  refetch?: () => void
  initialValues?: PropertyDetailNote
}

const trigger = ['hover', 'click']

export const PropertyDetailNoteCreate: FunctionComponent<PropertyDetailNoteCreateProps> =
  props => {
    const accountUserId = useAppSelector(state => state.myProfile.userId)
    const accountId = useAppSelector(state => state.myProfile.tenantId)

    const [, createNote] = useCreatePropertyNoteMutation()

    const [form] = Form.useForm()
    const [showHelp, toggleShowHelp] = useToggleState()
    const [loading, , startLoading, stopLoading] = useToggleState()
    const [isOpen, , , closePopover] = useToggleState(true)

    const propertyName = usePropertyDetailTitle()
    const iconStyle = Form.useWatch('iconKey', form)
    const colorStyle = Form.useWatch('colorKey', form)

    const disabled = useMemo(
      () =>
        !(
          accountId &&
          props.propertyId &&
          props.propertyLv &&
          props.propertyCadastralCode
        ),
      [accountId, props]
    )

    const onStyleChange = useCallback(
      (style: { colorKey?: string; iconKey?: string }) => {
        form.setFieldsValue({
          colorKey: style.colorKey,
          iconKey: style.iconKey,
        })
      },
      [form]
    )

    const onFinish = useCallback(
      async (formValues: PropertyNoteFormValues) => {
        startLoading()
        try {
          const result = await createNote({
            accountId,
            accountUserId,
            propertyLv: props.propertyLv,
            propertyCadastralCode: props.propertyCadastralCode,
            propertyName,
            propertyId: props.propertyId,
            propertyType: props.propertyType,

            colorKey: formValues.colorKey,
            iconKey: formValues.iconKey,
            note: formValues.note,
            links: validateUrl(formValues.links),
          })
          const success = result.data?.createPropertyNote?.propertyNote?.id

          if (!success) {
            stopLoading()
            return message.error('Nepodařilo se vytvořit poznámku')
          }
        } catch (error) {
          message.error('Při vytváření poznámky nastala chyba.')
        } finally {
          message.success('Poznámka vytvořena.')
          form.resetFields()
          props.refetch?.()
          stopLoading()
        }
        return
      },
      [
        accountId,
        accountUserId,
        createNote,
        form,
        propertyName,
        props,
        startLoading,
        stopLoading,
      ]
    )

    return (
      <>
        <div className="w-full max-w-7xl mb-5 flex">
          <div className="relative text-right pr-8 w-32 md:w-1/5">
            <Popover
              open={isOpen}
              onOpenChange={closePopover}
              placement="left"
              trigger={trigger}
              content="Kliknutím můžete upravit ikonu poznámky"
            >
              <div className="absolute -right-8 -top-1 w-16 flex justify-center bg-white">
                <NoteIconSettingButton
                  onStyleChange={onStyleChange}
                  pickedColor={colorStyle}
                  pickedIcon={iconStyle}
                />
              </div>
            </Popover>
          </div>
          <div className="pl-8 w-full">
            <Form<PropertyNoteFormValues>
              onFinish={onFinish}
              layout="vertical"
              form={form}
              className="mt-4"
              initialValues={props.initialValues}
            >
              <PropertyNoteFormBaseFragment
                disabled={disabled}
                submit={form.submit}
              />

              <Form.Item className="!mb-0 flex space-x-2 justify-end">
                <Button
                  icon={<InfoCircleOutlined />}
                  type="link"
                  onClick={toggleShowHelp}
                >
                  {showHelp ? 'Skrýt nápovědu' : 'Zobrazit nápovědu'}
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={disabled}
                >
                  Přidat poznámku
                </Button>
              </Form.Item>
            </Form>

            <Fade show={showHelp} className="mb-4 mt-6 text-gray-400">
              {propertyNoteFormHelp}
            </Fade>
          </div>
        </div>
      </>
    )
  }
