import { InfoCircleOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  Select,
  Typography,
} from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import {
  useAllSenderAddressesQuery,
  useCreateSenderAddressMutation,
  useUpdateInitialRecipientListMutation,
} from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { itemRequired, onlyNumbers } from '../../utils/formHelpers'
import {
  formItemLayout,
  fullItemLayoutProps,
  validateTrigger,
} from '../../utils/layoutConst'
import { useAssignLabels } from '../../utils/useAssignLabels'
import { Fade } from '../Fade/Fade'
import { LabelSelectInput } from '../LabelSelectInput/LabelSelectInput'

export interface CadastrePostCreateRecipientListFormValues {
  name: string
  description?: string
  city: string
  senderName: string
  street: {
    streetName: string
    houseNumber?: string
    orientationNumber?: string
  }
  zipCode: string
  saveAddress: boolean
}

export interface CadastrePostCreateRecipientListFormProps {
  id: string
  name: string
  handleRecipients: (listId: string) => void
  hideModal: () => void
  handleHelpToggle: () => void
  showHelp: boolean
  dataFetched: boolean
}

export const CadastrePostCreateRecipientListForm: FunctionComponent<CadastrePostCreateRecipientListFormProps> =
  props => {
    const { Title } = Typography
    const [form] = Form.useForm()
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const userId = useAppSelector(state => state.myProfile.userId)
    const { assignLabels } = useAssignLabels()

    const [loading, setLoading] = useState(false)
    const [labelsId, setLabelsId] = useState<string[] | []>([])
    const [, createSenderAddress] = useCreateSenderAddressMutation()
    const [, updateRecipientList] = useUpdateInitialRecipientListMutation()
    const [queryResult] = useAllSenderAddressesQuery({
      variables: { accountId: tenantId },
    })

    const savedAddresses = useMemo(() => {
      if (!queryResult.data?.allSenderAddresses?.nodes?.length) return []
      return queryResult.data.allSenderAddresses.nodes.map(address => {
        return {
          id: address?.id,
          senderName: address?.senderName,
          street: {
            streetName: address?.street,
            orientationNumber: address?.orientationNumber,
            houseNumber: address?.houseNumber,
          },
          city: address?.city,
          zipCode: address?.zipCode,
        }
      })
    }, [queryResult.data?.allSenderAddresses?.nodes])

    const handleAddressSelect = (selectedId: string) => {
      const selectedAddress = savedAddresses.find(
        address => address.id === selectedId
      )
      form.setFieldsValue(selectedAddress)
    }

    const onFinish = useCallback(
      async (formValues: CadastrePostCreateRecipientListFormValues) => {
        setLoading(true)
        try {
          const result = await createSenderAddress({
            accountId: tenantId,
            accountUserId: userId,
            senderName: formValues.senderName,
            street: formValues.street.streetName,
            orientationNumber: formValues.street.orientationNumber,
            houseNumber: formValues.street.houseNumber,
            city: formValues.city,
            zipCode: formValues.zipCode,
            isTemplate: formValues.saveAddress,
          })

          const addressId =
            result.data?.createSenderAddress?.senderAddress?.id || ''

          if (!addressId) {
            setLoading(false)
            return message.error('Nepodařilo se vytvořit adresu odesílatele')
          }

          const recipientListResult = await updateRecipientList({
            id: props.id,
            accountId: tenantId,
            accountUserId: userId,
            senderAddressId: addressId,
            nazev: formValues.name,
            description: formValues.description,
            temporaryRecipientsJson: null,
          })

          const listId =
            recipientListResult.data?.updateRecipientListById?.recipientList?.id

          props.handleRecipients(listId || '')

          await assignLabels({
            recipientListId: listId,
            labelsId,
          })
        } catch (error) {
          message.error('Při vytváření hromadné pošty nastala chyba.')
        } finally {
          setLoading(false)
        }
        return
      },
      [
        assignLabels,
        createSenderAddress,
        labelsId,
        props,
        tenantId,
        updateRecipientList,
        userId,
      ]
    )

    const initialValues = useMemo(() => ({ name: props.name }), [props.name])

    return (
      <Form<CadastrePostCreateRecipientListFormValues>
        {...formItemLayout}
        layout="horizontal"
        form={form}
        initialValues={initialValues}
        validateTrigger={validateTrigger}
        onFinish={onFinish}
        className="!mt-4"
      >
        <Form.Item
          label="Název"
          name="name"
          required
          rules={itemRequired('Název je povinný')}
        >
          <Input placeholder="Název hromadného dopisu" />
        </Form.Item>

        <Form.Item label="Popis" name="description">
          <Input.TextArea
            showCount
            maxLength={1000}
            autoFocus
            placeholder="Popis hromadného dopisu"
          />
        </Form.Item>

        <Form.Item name="labels" label="Štítek" colon={false}>
          <LabelSelectInput disabled={loading} onChange={setLabelsId} />
        </Form.Item>

        <Divider />
        <Title level={5}>Odesílatel</Title>

        <Fade show={props.showHelp} className="mb-4 mt-6 text-gray-400">
          {
            'Zadejte jméno a adresu, kterou chcete uvést na obálce jako odesílatele. Pomocí přepínače "Uložit adresu" si můžete údaje zachovat pro opětovné použití.'
          }
        </Fade>
        <Form.Item label="Uložené adresy" name="savedAddresses">
          <Select
            placeholder={
              savedAddresses.length
                ? 'Vyberte uloženou adresu'
                : 'Nemáte žádnou uloženou adresu'
            }
            onChange={handleAddressSelect}
            disabled={savedAddresses.length === 0}
          >
            {savedAddresses.map(address => (
              <Select.Option value={address.id} key={address.id}>
                {address.senderName} - {address.street.streetName}{' '}
                {address.street.houseNumber || address.street.orientationNumber}
                , {address.city}, {address.zipCode}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Název odesílatele"
          name="senderName"
          rules={itemRequired('Název odesílatele je povinný')}
        >
          <Input placeholder="Název odesílatele" />
        </Form.Item>
        <Form.Item label="Ulice" required>
          <div className="flex">
            <Form.Item
              name={['street', 'streetName']}
              noStyle
              rules={itemRequired('Uveďte název ulice')}
            >
              <Input placeholder="Název ulice" style={{ marginRight: '8px' }} />
            </Form.Item>

            <Form.Item
              name={['street', 'houseNumber']}
              noStyle
              rules={[
                {
                  validator: async (_, value) => {
                    const orientationNumber = form.getFieldValue([
                      'street',
                      'orientationNumber',
                    ])
                    if (!value && !orientationNumber) {
                      throw new Error('Zadejte číslo popisné či orientační')
                    }
                  },
                },
              ]}
            >
              <Input placeholder="ČP" style={{ width: '25%' }} />
            </Form.Item>

            <div className="self-center mx-1 text-gray-400">{'/'}</div>

            <Form.Item name={['street', 'orientationNumber']} noStyle>
              <Input placeholder="ČO" style={{ width: '25%' }} />
            </Form.Item>
          </div>
        </Form.Item>

        <Form.Item
          label="Obec"
          name="city"
          rules={itemRequired('Zadejte název obce')}
        >
          <Input placeholder="Obec" />
        </Form.Item>
        <Form.Item
          label="PSČ"
          name="zipCode"
          rules={[
            {
              required: true,
              message: 'PSČ je povinné',
            },
            {
              validator: async (_, value) => {
                if (value.length !== 5) {
                  throw new Error()
                }
              },
              message: 'PSČ musí obsahovat právě 5 číslic',
            },
          ]}
          normalize={onlyNumbers}
        >
          <Input placeholder="PSČ" />
        </Form.Item>

        <Form.Item
          name="saveAddress"
          valuePropName="checked"
          label
          colon={false}
        >
          <Checkbox>Uložit adresu</Checkbox>
        </Form.Item>

        <Divider />

        <Form.Item className="!mb-0" {...fullItemLayoutProps}>
          <div className="flex justify-between">
            <Button
              icon={<InfoCircleOutlined />}
              type="link"
              onClick={props.handleHelpToggle}
            >
              {props.showHelp ? 'Skrýt nápovědu' : 'Zobrazit nápovědu'}
            </Button>
            <div className="space-x-2">
              <Button disabled={loading} onClick={props.hideModal}>
                Zrušit
              </Button>
              <Button
                type="primary"
                disabled={!props.dataFetched}
                loading={loading}
                onClick={form.submit}
              >
                Pokračovat
              </Button>
            </div>
          </div>
        </Form.Item>
      </Form>
    )
  }
