import React, { FunctionComponent } from 'react'

import { CeeTableItem } from '../../../common/ceeTypes'
import { bulkEditTransition } from '../../utils/layoutConst'
import { CeeMonitorBulkCheck } from '../CeeMonitorBulkCheck/CeeMonitorBulkCheck'
import { CeeMonitorBulkEdit } from '../CeeMonitorBulkEdit/CeeMonitorBulkEdit'
import { CeeMonitorDelete } from '../CeeMonitorDelete/CeeMonitorDelete'
import { ExportTableXlsxButton } from '../ExportTableXlsxButton/ExportTableXlsxButton'
import { Fade } from '../Fade/Fade'
import { TableItemsCount } from '../TableItemsCount/TableItemsCount'

export interface CeeMonitorTableBulkEditProps {
  selectedRows: CeeTableItem[]
  refetch: () => void
  checkNow: (id: string[]) => void
  monitoring?: boolean
}

export const CeeMonitorTableBulkEdit: FunctionComponent<CeeMonitorTableBulkEditProps> =
  props => {
    const selectedCount = props.selectedRows?.length || 0

    return (
      <Fade
        transition={bulkEditTransition}
        show={!!selectedCount}
        className="flex space-x-4 items-center mb-4"
      >
        <CeeMonitorBulkCheck
          checkNow={props.checkNow}
          selectedRows={props.selectedRows}
        />
        <CeeMonitorBulkEdit
          refetch={props.refetch}
          items={props.selectedRows}
          monitoring={props.monitoring}
        />
        <ExportTableXlsxButton
          url="cee/ceeXlsx"
          fileName="CEE"
          dataSource={props.selectedRows || []}
        />
        <CeeMonitorDelete
          items={props.selectedRows}
          refetch={props.refetch}
          buttonText="Smazat vybrané"
        />
        <TableItemsCount prefix="Vybráno" separator total={selectedCount} />
      </Fade>
    )
  }
