import { Alert, Button } from 'antd'
import React, { useMemo } from 'react'

import { cadastreMapMaxResultCount } from '../../utils/layoutConst'
import { Money } from '../Money/Money'

interface RuianFilterFormFooterProps {
  counting: boolean
  resultCount: number | null
  isFormTouched: boolean
  onCancel: () => void
  onCalculate: () => void
  onSubmit: () => void
}

export const RuianFilterFormFooter: React.FC<RuianFilterFormFooterProps> = ({
  counting,
  resultCount,
  isFormTouched,
  onCancel,
  onCalculate,
  onSubmit,
}) => {
  const renderMessage = useMemo(() => {
    if (counting)
      return (
        <Alert
          message="Vyhledávání může trvat až několik minut."
          type="info"
          showIcon
          className="!bg-primaryHover/10 !py-1 !w-full"
        />
      )
    if (resultCount === 0)
      return (
        <Alert
          message="Nebyly nalezeny žádné odpovídající nemovitosti."
          type="error"
          showIcon
          className="!py-1 !w-full"
        />
      )
    if (resultCount !== null && resultCount > cadastreMapMaxResultCount) {
      return (
        <Alert
          message={
            <div>
              <span>Překročen max. limit</span>{' '}
              <Money amount={cadastreMapMaxResultCount} decimalNumbers={0} />
              <span>nemovitostí.</span>
            </div>
          }
          type="error"
          showIcon
          className="!py-1 !w-full"
        />
      )
    }
    return null
  }, [counting, resultCount])

  const renderActionButton = useMemo(() => {
    if (counting)
      return (
        <Button disabled loading>
          Vyhledávání…
        </Button>
      )
    if (resultCount === null) {
      return (
        <Button
          onClick={onCalculate}
          type="primary"
          ghost
          disabled={!isFormTouched}
        >
          {isFormTouched ? 'Vyhledat' : 'Vyplňte filtr'}
        </Button>
      )
    }
    if (resultCount === 0 || resultCount > cadastreMapMaxResultCount) {
      return (
        <Button
          disabled
        >{`Zobrazit ${resultCount.toLocaleString()} nemovitostí`}</Button>
      )
    }
    return (
      <Button type="primary" onClick={onSubmit}>
        {`Zobrazit ${resultCount.toLocaleString()} nemovitostí`}
      </Button>
    )
  }, [counting, resultCount, isFormTouched, onCalculate, onSubmit])

  return (
    <div className="flex justify-end items-center space-x-2">
      {renderMessage}
      <Button onClick={onCancel}>Zrušit</Button>
      {renderActionButton}
    </div>
  )
}
