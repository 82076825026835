import { Tooltip } from 'antd'
import React, { FunctionComponent, memo, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { LabelConnection } from '../../../common/propertyDetailTypes'
import {
  ColorPalleteDeafultOption,
  ColorPalleteKey,
  ColorPalleteOptions,
} from '../../utils/collorPallete'
import { propertyDetailPath } from '../../utils/paths'
import { InlineLabelsGroup } from '../InlineLabelsGroup/InlineLabelsGroup'

interface PropertyDetailBuildingUnitsCellProps {
  id: string
  unitNumber: string
  labelConnection: LabelConnection[] | undefined
}

const overlayInnerStyle = {
  backgroundColor: 'white',
  display: 'flex',
  paddingBottom: 8,
}

export const PropertyDetailBuildingUnitsCell: FunctionComponent<PropertyDetailBuildingUnitsCellProps> =
  memo(({ id, unitNumber, labelConnection: labelConnection }) => {
    const tooltip = useMemo(() => {
      const colorKey =
        (labelConnection?.[0]?.labelByLabelId?.colorKey as ColorPalleteKey) ||
        ColorPalleteDeafultOption

      return (
        <Tooltip
          overlayInnerStyle={overlayInnerStyle}
          className={`${ColorPalleteOptions[colorKey]} w-full text-center`}
          title={
            <InlineLabelsGroup
              labels={labelConnection?.map(node => node?.labelByLabelId) || []}
            />
          }
        >
          {unitNumber}
        </Tooltip>
      )
    }, [labelConnection, unitNumber])

    return (
      <Link
        to={propertyDetailPath(PROPERTY_TYPES.UNIT, id)}
        className="relative flex items-center justify-center mt-2 w-8 border-x border-gray-200"
        title={`Zobrazit detail jednotky ${unitNumber}`}
      >
        {labelConnection?.length ? tooltip : unitNumber}
      </Link>
    )
  })
