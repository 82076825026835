import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { FunctionComponent, useMemo } from 'react'

import { SubjectsInPostServiceList } from '../../../common/postServiceTypes'
import { propertyDetailTableProps } from '../../utils/layoutConst'
import { PostServiceRecipientListDeleteButton } from '../PostServiceRecipientListDeleteButton/PostServiceRecipientListDeleteButton'
import { TableCellLabels } from '../TableCellLabels/TableCellLabels'

export interface SubjectTableCellRecipientListTableProps {
  postServiceList: SubjectsInPostServiceList[]
}

const buttonProps = {
  title: 'Opravdu chcete odebrat subjekt z tohoto adresáře?',
  buttonText: 'Odebrat',
  buttonProps: { type: 'link', ghost: false },
} as const

export const SubjectTableCellRecipientListTable: FunctionComponent<SubjectTableCellRecipientListTableProps> =
  props => {
    const columns: ColumnsType<SubjectsInPostServiceList> = useMemo(
      () => [
        {
          dataIndex: 'listName',
          className: 'w-max group-last:!border-b-0 !p-1',
        },
        {
          title: 'Štítek',
          dataIndex: 'labels',
          key: 'labels',
          className: 'w-48',
          render: (labels, record) => (
            <TableCellLabels
              labels={labels}
              labelEntityType="postserviceListId"
              recordId={record.id}
              modalTitle={record.listName}
            />
          ),
        },
        {
          dataIndex: 'description',
          className: 'text-gray-400 group-last:!border-b-0 !p-1',
          ellipsis: true,
        },
        {
          dataIndex: 'id',
          className: 'w-20 !text-right group-last:!border-b-0 !p-1',
          render: (_, record) => (
            <PostServiceRecipientListDeleteButton
              {...buttonProps}
              record={{ id: record.recipientId }}
            />
          ),
        },
      ],
      []
    )

    return (
      <Table
        columns={columns}
        dataSource={props.postServiceList}
        showHeader={false}
        rowClassName="group"
        rowKey="id"
        {...propertyDetailTableProps}
      />
    )
  }
