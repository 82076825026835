import { InputNumber, InputNumberProps, Space } from 'antd'
import React, { FunctionComponent, useCallback, useState } from 'react'

type InputValue = InputNumberProps['value']

export interface InputNumberRangeProps {
  value?: [InputValue, InputValue]
  onChange?: (value: [InputValue, InputValue]) => void
}

export const InputNumberRange: FunctionComponent<InputNumberRangeProps> = ({
  value,
  onChange,
}) => {
  const [minValue, setMinValue] = useState<InputValue>(value?.[0])
  const [maxValue, setMaxValue] = useState<InputValue>(value?.[1])

  const triggerChange = useCallback(
    (changedValue: [InputValue, InputValue]) => {
      onChange?.(changedValue)
    },
    [onChange]
  )

  const parseValue = (value: InputValue): number | undefined => {
    if (value === null || value === undefined || value === '') {
      return undefined
    }
    const numberValue = Number(value)
    return isNaN(numberValue) ? undefined : numberValue
  }

  const onMinBlur = useCallback(() => {
    const minVal = parseValue(minValue)
    const maxVal = parseValue(maxValue)

    let correctedMinValue = minValue
    if (minVal !== undefined && maxVal !== undefined && minVal > maxVal) {
      correctedMinValue = maxValue
      setMinValue(correctedMinValue)
    }
    triggerChange([correctedMinValue, maxValue])
  }, [minValue, maxValue, triggerChange])

  const onMaxBlur = useCallback(() => {
    const minVal = parseValue(minValue)
    const maxVal = parseValue(maxValue)

    let correctedMaxValue = maxValue
    if (minVal !== undefined && maxVal !== undefined && minVal > maxVal) {
      correctedMaxValue = minValue
      setMaxValue(correctedMaxValue)
    }
    triggerChange([minValue, correctedMaxValue])
  }, [minValue, maxValue, triggerChange])

  const onMinValueChange = useCallback((newMinValue: InputValue) => {
    setMinValue(newMinValue)
  }, [])

  const onMaxValueChange = useCallback((newMaxValue: InputValue) => {
    setMaxValue(newMaxValue)
  }, [])

  return (
    <Space>
      <InputNumber
        min={0}
        max={parseValue(maxValue)}
        value={minValue}
        onChange={onMinValueChange}
        onBlur={onMinBlur}
        placeholder="Od"
      />
      <InputNumber
        min={parseValue(minValue) ?? 0}
        value={maxValue}
        onChange={onMaxValueChange}
        onBlur={onMaxBlur}
        placeholder="Do"
      />
    </Space>
  )
}
