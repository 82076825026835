import { Button } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { RestrictionChecType } from '../../../common/epoTypes'
import {
  CadastralCheckedValues,
  CadastralOffice,
} from '../../utils/cuzkHelpers'
import { pricelist } from '../../utils/pricelist'
import { CadastralSelectionModalFooterBuyLien } from '../CadastralSelectionModalFooterBuyLien/CadastralSelectionModalFooterBuyLien'
import { CadastralSelectionModalFooterBuyRestriction } from '../CadastralSelectionModalFooterBuyRestriction/CadastralSelectionModalFooterBuyRestriction'
import { CadastralSelectionModalFooterCounter } from '../CadastralSelectionModalFooterCounter/CadastralSelectionModalFooterCounter'

export interface CadastralSelectionModalFooterProps {
  checkboxData: CadastralOffice[]
  checkedValues: CadastralCheckedValues
  toggleModalVisibility: () => void
  refetch: () => void
  epoId: string
  type: RestrictionChecType
}

export const CadastralSelectionModalFooter: FunctionComponent<CadastralSelectionModalFooterProps> =
  ({
    type,
    epoId,
    checkboxData,
    checkedValues,
    refetch,
    toggleModalVisibility,
  }) => {
    const selectedCounter = useMemo(() => {
      let lvCount = 0
      let workplacesCount = 0
      checkboxData.forEach(office => {
        if (office.pracoviste.length > 0) {
          office.pracoviste.forEach(workplace => {
            if (checkedValues[office.kod]?.includes(workplace.kod)) {
              workplacesCount++
              lvCount = lvCount + (workplace.pocetLV || 0)
            }
          })
        }
      })
      return {
        workplacesCount,
        lvCount,
        price:
          type === RestrictionChecType.restriction
            ? pricelist.EPO_RESTRICTION_CHECK * workplacesCount
            : pricelist.EPO_LV_CHECK * lvCount,
      }
    }, [checkboxData, checkedValues, type])

    return (
      <div className="flex justify-end items-center space-x-2">
        <CadastralSelectionModalFooterCounter selected={selectedCounter} />

        <Button onClick={toggleModalVisibility}>Zrušit</Button>
        {type === RestrictionChecType.lien && (
          <CadastralSelectionModalFooterBuyLien
            checkedValues={checkedValues}
            epoId={epoId}
            selectedCounter={selectedCounter}
            toggleModalVisibility={toggleModalVisibility}
            refetch={refetch}
          />
        )}
        {type === RestrictionChecType.restriction && (
          <CadastralSelectionModalFooterBuyRestriction
            checkedValues={checkedValues}
            epoId={epoId}
            selectedCounter={selectedCounter}
            toggleModalVisibility={toggleModalVisibility}
            refetch={refetch}
          />
        )}
      </div>
    )
  }
