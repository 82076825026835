import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const expandedRowSlice = createSlice({
  name: 'expandedRow',
  initialState: {
    tables: {} as { [key: string]: string[] },
  },
  reducers: {
    setExpandedRow: (
      state: {
        tables: { [key: string]: string[] }
      },
      action: PayloadAction<{
        tables: { [key: string]: string[] }
      }>
    ) => {
      state.tables = { ...state.tables, ...action.payload.tables }
    },
  },
})

export const { setExpandedRow } = expandedRowSlice.actions
export default expandedRowSlice.reducer
