import React, { FunctionComponent, useState } from 'react'

import { DataBoxDetail } from '../components/DataBoxDetail/DataBoxDetail'
import { DataBoxSearchBar } from '../components/DataBoxSearchBar/DataBoxSearchBar'
import { Fade } from '../components/Fade/Fade'
import { PageLayout } from '../components/PageLayout/PageLayout'

export const DataBox: FunctionComponent = () => {
  const [dataBoxId, setDataBoxId] = useState<string | undefined>()
  return (
    <>
      <PageLayout title="Datová schránka" isNew>
        <DataBoxSearchBar setDataBoxId={setDataBoxId} />
      </PageLayout>
      <Fade show={!!dataBoxId}>
        <PageLayout className="py-8">
          <DataBoxDetail dataBoxId={dataBoxId} />
        </PageLayout>
      </Fade>
    </>
  )
}

export default DataBox
