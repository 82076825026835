import { FilterOutlined } from '@ant-design/icons'
import { Button, Form, Modal } from 'antd'
import React, { FunctionComponent, useEffect, useState } from 'react'

import { useToggleState } from '../../utils/useToggleState'
import { RuianFilterForm } from '../CadastreMap/components/CadastreMapRuianFilterForm/CadastreMapRuianFilterForm'
import { RuianInput } from '../CadastreMap/components/CadastreMapRuianInput/CadastreMapRuianInput'
import { useCsvZip } from '../CadastreMap/hooks/useCsvZip'
import { RuianLayer } from '../CadastreMap/types/RuianLayers'

export interface CadastreMapParcelLayerControlFilterModalButtonProps {
  onWhereChange: (where: string) => void
  onConfirm: () => void
}

type EnumRelationship = {
  DRUPOZ_KOD: number
  ZPVYPO_KOD: number
}

const ruianLayerId = RuianLayer.PARCELA

export const CadastreMapParcelLayerControlFilterModalButton: FunctionComponent<CadastreMapParcelLayerControlFilterModalButtonProps> =
  props => {
    const [form] = Form.useForm()
    const druhPozemkuKod = Form.useWatch('druhpozemkukod', form)
    const zpusobyVyuzitiPozemku = Form.useWatch('zpusobyvyuzitipozemku', form)

    const [connectionsIsLoading, connections] = useCsvZip<EnumRelationship>(
      'https://services.cuzk.cz/sestavy/cis/SC_POZEMEK_VYUZITI.zip'
    )

    const [open, toggleOpen] = useToggleState()

    const [validZpusobyVyuzitiPozemku, setValidZpusobyVyuzitiPozemku] =
      useState<number[] | null>(null)
    const [validDruhPozemkuKod, setValidDruhPozemkuKod] = useState<
      number[] | null
    >(null)

    useEffect(() => {
      if (connectionsIsLoading) return
      if (!druhPozemkuKod?.value) setValidZpusobyVyuzitiPozemku(null)
      if (!zpusobyVyuzitiPozemku?.value) setValidDruhPozemkuKod(null)

      if (druhPozemkuKod?.value) {
        const validZpusobyVyuziti = connections
          .filter(conn => conn.DRUPOZ_KOD === druhPozemkuKod.value)
          .map(conn => conn.ZPVYPO_KOD)
        setValidZpusobyVyuzitiPozemku(validZpusobyVyuziti)
      } else if (zpusobyVyuzitiPozemku?.value) {
        const validDruhPozemku = connections
          .filter(conn => conn.ZPVYPO_KOD === zpusobyVyuzitiPozemku.value)
          .map(conn => conn.DRUPOZ_KOD)
        setValidDruhPozemkuKod(validDruhPozemku)
      }
    }, [
      connectionsIsLoading,
      connections,
      druhPozemkuKod,
      zpusobyVyuzitiPozemku,
    ])

    return (
      <div>
        <Button
          className="hover:!bg-white"
          icon={<FilterOutlined />}
          onClick={toggleOpen}
          title="Filtrovat výběr parcel"
        />

        <Modal
          title="Filtr zobrazení parcel"
          open={open}
          onCancel={toggleOpen}
          footer={null}
          width={700}
        >
          <RuianFilterForm
            form={form}
            onWhereChange={props.onWhereChange}
            ruianLayerId={ruianLayerId}
            toggleOpen={toggleOpen}
            onConfirm={props.onConfirm}
          >
            <RuianInput ruianLayerId={ruianLayerId} fieldName="vymeraparcely" />
            <RuianInput
              ruianLayerId={ruianLayerId}
              fieldName="zpusobyvyuzitipozemku"
              forceComponentType="Select"
              validValues={validZpusobyVyuzitiPozemku}
            />
            <RuianInput
              ruianLayerId={ruianLayerId}
              fieldName="druhpozemkukod"
              forceComponentType="Select"
              validValues={validDruhPozemkuKod}
            />
          </RuianFilterForm>
        </Modal>
      </div>
    )
  }
