import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { CeeMonitorTableIdentificationItem as Item } from '../CeeMonitorTableIdentificationItem/CeeMonitorTableIdentificationItem'

export interface CuzkEpoRestrictionCheckInfoPanelItemPositiveLvProps {
  inProgressLength: number
  checkedPercentage: number
  positiveLv: number
  loading: boolean
}

export const CuzkEpoRestrictionCheckInfoPanelItemPositiveLv: FunctionComponent<CuzkEpoRestrictionCheckInfoPanelItemPositiveLvProps> =
  props => {
    const itemContent = useMemo(() => {
      if (!props.inProgressLength || !props.checkedPercentage)
        return <>{'---'}</>
      if (!!props.checkedPercentage && props.checkedPercentage < 100)
        return (
          <Tooltip
            className="cursor-help"
            title="Pro určení přesného počtu LV s právy 3. stran je nutné provést kontrolu všech katastrálních pracovišť."
          >
            <span>
              min. {props.positiveLv}
              <QuestionCircleOutlined className="text-gray-400 ml-2" />
            </span>
          </Tooltip>
        )
      return <>{props.positiveLv}</>
    }, [props.checkedPercentage, props.inProgressLength, props.positiveLv])

    return (
      <Item loading={props.loading} title="LV s právy 3. stran">
        {itemContent}
      </Item>
    )
  }
