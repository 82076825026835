import { Spin } from 'antd'
import React, { FunctionComponent } from 'react'
import { UseQueryState } from 'urql/dist/types/hooks/useQuery'

import {
  AuctionMonitoringFilterState,
  AuctionMonitoringRecord,
} from '../../../common/auctionMonitoringTypes'
import { TABLE_VIEW } from '../../../common/foreclosureMonitoringTypes'
import {
  AuctionListingFilter,
  AuctionMonitoringTabQuery,
  Exact,
} from '../../../graphql/generated'
import { AuctionMonitoringTable } from '../AuctionMonitoringTable/AuctionMonitoringTable'
import { AuctionMonitoringTiles } from '../AuctionMonitoringTiles/AuctionMonitoringTiles'
import { PropertyMonitoringFetchError } from '../PropertyMonitoringFetchError/PropertyMonitoringFetchError'

export interface AuctionMonitoringContentProps {
  viewType: TABLE_VIEW
  dataSource?: UseQueryState<
    AuctionMonitoringTabQuery,
    Exact<{
      filter: AuctionListingFilter
    }>
  >
  filter: AuctionMonitoringFilterState
}

export const AuctionMonitoringContent: FunctionComponent<AuctionMonitoringContentProps> =
  props => {
    if (props.dataSource?.error)
      return <PropertyMonitoringFetchError type="dražeb" />

    return (
      <div className="w-full">
        <Spin spinning={props.dataSource?.fetching}>
          {props.viewType === TABLE_VIEW.TILES ? (
            <AuctionMonitoringTiles
              data={
                props.dataSource?.data?.allAuctionListings
                  ?.nodes as unknown as AuctionMonitoringRecord[]
              }
            />
          ) : (
            <AuctionMonitoringTable
              filter={props.filter}
              data={
                props.dataSource?.data?.allAuctionListings
                  ?.nodes as unknown as AuctionMonitoringRecord[]
              }
            />
          )}
        </Spin>
      </div>
    )
  }
