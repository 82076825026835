import { message } from 'antd'

export const handleResponseStatus = (
  status: number,
  succesText?: string,
  defaultErrorText?: string,
  noSuccessMessage?: boolean
) => {
  switch (status) {
    case 200:
      noSuccessMessage ||
        message.success(succesText || 'Požadavek byl úspěšně proveden.')
      break
    case 401:
      message.error('Kvůli dlouhé neaktivitě jste byli odhlášeni.')
      break
    case 402:
      message.error('Nemáte dostatek kreditu.')
      break
    case 403:
      message.error('Neplatné přihlašovací údaje k WSDP.')
      break
    case 404:
      message.error('Služba je aktuálně nedostupná, zkuste to prosím později.')
      break
    case 500:
      message.error('Došlo k chybě během zpracování požadavku.')
      break

    default:
      message.error(
        defaultErrorText ||
          'Požadavek se nepodařilo provést, zkuste to prosím později.'
      )
  }
  return status
}
export const cuzkDocumentCollectionState = {
  ceka: 'čeká',
  vytvariSe: 'vytváří se',
  podepisujeSe: 'podepisuje se',
  zpracovan: 'zpracován',
  chybaPriVytvareni: 'chyba při vytváření',
  chybaPriZpracovani: 'chyba při zpracování',
  zauctovan: 'zaúčtován',
  zrusen: 'zrušen',
  //custom states for our generated pdf
  generujeSePdf: 'generování PDF',
  kDispozici: 'k dispozici',
  stahujeSe: 'stahuje se',
  //custom state for proceeding document
  info: 'Informace',
  naVyzadani: 'Na vyžádání',
}
