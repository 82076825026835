import React, { FunctionComponent, useMemo } from 'react'

import { useAppSelector } from '../../../redux/hooks'
import { isRuianBuilding, isRuianUnit } from '../../utils/ruianHelpers'
import { AddressLink } from '../AddressLink/AddressLink'
import { MapTooltip } from '../MapTooltip/MapTooltip'

interface PropertyDetailOwnershipRightsTableCellAddressProps {
  address?: string
  addressId?: string | number
}

export const PropertyDetailOwnershipRightsTableCellAddress: FunctionComponent<PropertyDetailOwnershipRightsTableCellAddressProps> =
  ({ address, addressId }) => {
    const ruianDetail = useAppSelector(state => state.property.ruian?.data)

    const renderedAddress = useMemo(() => {
      if (!addressId) {
        return (
          <MapTooltip
            query={address}
            title="K této adrese bohužel neevidujeme žádné bližší informace."
          >
            {address}
          </MapTooltip>
        )
      }

      const isCurrentBuilding =
        (isRuianBuilding(ruianDetail) || isRuianUnit(ruianDetail)) &&
        ruianDetail?.adresniMista?.some(
          adresniMisto => adresniMisto?.kod === addressId
        )

      if (isCurrentBuilding) {
        return (
          <span title="Detail budovy s touto adresou si právě prohlížíte.">
            {address}
          </span>
        )
      }

      return <AddressLink addressId={addressId}>{address}</AddressLink>
    }, [address, addressId, ruianDetail])

    return renderedAddress
  }
