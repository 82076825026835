import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, ButtonProps } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, memo, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { AddressApiResponse } from '../../../common/addressTypes'
import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { PROPERTY_TAB_KEYS } from '../../../common/propertyDetailTypes'
import { useAppSelector } from '../../../redux/hooks'
import { propertyDetailPath, requestAddressPath } from '../../utils/paths'

export interface AddressLinkButtonProps {
  addressId?: number | string
  children?: React.ReactNode
  address?: string
  tabTarget?: PROPERTY_TAB_KEYS
  className?: string
  buttonProps?: ButtonProps
}

export const AddressLinkButton: FunctionComponent<AddressLinkButtonProps> =
  memo(props => {
    const history = useHistory()
    const token = useAppSelector(state => state.myProfile.token)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(!props.addressId && !props.address)

    const fetchAndRedirect = useCallback(
      async (addressId?: string | number | unknown) => {
        try {
          setLoading(true)
          const { data } = (await axios.get(
            requestAddressPath() +
              token +
              '/' +
              (props.addressId || addressId)?.toString()
          )) as { data: AddressApiResponse }

          if (data.budovaId) {
            history.push(
              propertyDetailPath(
                PROPERTY_TYPES.BUILDING,
                data.budovaId,
                props.tabTarget || PROPERTY_TAB_KEYS.OWNERSHIP
              )
            )
          }
        } catch (error) {
          setError(true)
        } finally {
          setLoading(false)
        }
      },
      [token, props.addressId, props.tabTarget, history]
    )

    const findAndFetch = useCallback(async () => {
      try {
        const response = await axios.get(
          'https://data.regesta.cz/DrmApi/api/v1.0/Adresy/Autocomplete',
          {
            params: {
              access_token: token,
              q: props.address,
            },
          }
        )

        if (response.data?.[0]?.kod) {
          await fetchAndRedirect(response.data[0].kod)
        } else {
          setError(true)
        }
      } catch (error) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }, [token, props.address, fetchAndRedirect])

    return (
      <Button
        {...props.buttonProps}
        icon={error ? <CloseCircleOutlined /> : undefined}
        onClick={props.addressId ? fetchAndRedirect : findAndFetch}
        loading={loading}
        disabled={error}
        title={error ? 'Adresu se nepodařilo najít' : 'Zobrazit detail adresy'}
      >
        {props.children || props.address}
      </Button>
    )
  })
