import React, { FunctionComponent, useCallback, useMemo } from 'react'
import {
  CustomMarker,
  GetItemsProps,
  Id,
  ItemContext,
  TimelineItemBase,
} from 'react-calendar-timeline'

import { timelineDateTitle } from '../../utils/propertyTimelineHelpers'

export interface PropertyTimelineProceedingItemProps {
  item: TimelineItemBase<number>
  itemContext: ItemContext
  getItemProps: (props: GetItemsProps) => {
    key: Id
    className: string
    style: React.CSSProperties
  }
}

export const PropertyTimelineProceedingItem: FunctionComponent<PropertyTimelineProceedingItemProps> =
  props => {
    const markerRenderer = useCallback(
      ({ styles }: { styles: React.CSSProperties }) => {
        const newStyles = {
          ...styles,
          height: (props.itemContext.dimensions.top || 0) + 37,
        }
        return (
          <div
            style={newStyles}
            className="w-px !bg-gray-200 before:block before:w-2 before:h-2 before:bg-primary before:rounded-full before:absolute before:-top-1 before:-left-1 after:block after:w-2 after:h-2 after:bg-primary after:rounded-full after:absolute after:-bottom-1 after:-left-1"
          />
        )
      },
      [props.itemContext.dimensions.top]
    )

    const title = useMemo(
      () => timelineDateTitle(props.item.start_time, props.item.end_time, true),
      [props.item.start_time, props.item.end_time]
    )

    const timelineItemProps = useMemo(() => {
      const baseProps = props.getItemProps({
        style: { height: props.itemContext.dimensions.height },
      })
      return {
        ...baseProps,
        style: {
          ...baseProps.style,
          minWidth: 120,
        },
      }
    }, [props])

    return (
      <>
        <div
          {...timelineItemProps}
          title={title}
          className="group flex shadow-none items-center !bg-gray-50 !rounded-lg !border-gray-400 py-1 !text-current !w-[120px] hover:shadow-[14px_0px_15px_9px_rgba(255,255,255)] hover:!w-fit hover:!z-[100] !cursor-default transition-shadow"
        >
          {props.item.title}
        </div>
        <CustomMarker date={props.item.start_time}>
          {markerRenderer}
        </CustomMarker>
      </>
    )
  }
