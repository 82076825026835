import { Button } from 'antd'
import React, { FunctionComponent, useEffect, useMemo } from 'react'

import {
  RecipientFragment,
  useSentLetterPreviewQuery,
} from '../../../graphql/generated'
import { useToggleState } from '../../utils/useToggleState'
import { PdfDrawerPreview } from '../PdfDrawerPreview/PdfDrawerPreview'
import { PostServiceLetterDetailPdf } from '../PostServiceLetterDetailPdf/PostServiceLetterDetailPdf'

export interface PostServiceSentLetterPreviewButtonProps {
  letterId: string
  sentRecipientId: string
  onLoaded?: () => void
}

const buttonProps = { ghost: true, size: 'small', type: 'primary' } as const

export const PostServiceSentLetterPreviewButton: FunctionComponent<PostServiceSentLetterPreviewButtonProps> =
  props => {
    const [shouldFetch, , setShouldFetch] = useToggleState(false)

    const [queryResult] = useSentLetterPreviewQuery({
      pause: !shouldFetch,
      variables: {
        letterId: props.letterId,
        recipientId: props.sentRecipientId,
      },
    })

    const data = useMemo(() => {
      const letter = queryResult.data?.postserviceSentLetterById
      const content = letter?.content || 'Chyba při načítání obsahu dopisu'
      const recipient = (letter?.postserviceSentRecipientsBySentLetterId
        ?.nodes?.[0] || undefined) as unknown as RecipientFragment
      const fatchingData =
        queryResult.fetching || (!letter && !queryResult.error)

      return { content, recipient, fatchingData }
    }, [
      queryResult.data?.postserviceSentLetterById,
      queryResult.error,
      queryResult.fetching,
    ])

    useEffect(() => {
      if (shouldFetch && data.fatchingData) {
        props.onLoaded?.()
      }
    }, [data.fatchingData, props, shouldFetch])

    if (!shouldFetch || data.fatchingData)
      return (
        <Button
          onClick={setShouldFetch}
          loading={queryResult.fetching}
          {...buttonProps}
        >
          Zobrazit dopis
        </Button>
      )

    return (
      <PdfDrawerPreview
        defaultOpen
        buttonProps={buttonProps}
        buttonText="Zobrazit dopis"
        drawerTitle="Náhled šablony dopisu"
        fileName={data.recipient?.lastname + '_HROMADNY_DOPIS_REGESTA'}
      >
        <PostServiceLetterDetailPdf
          data={data.recipient}
          letterText={data.content}
        />
      </PdfDrawerPreview>
    )
  }
