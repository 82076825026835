import { Button, Modal, Tooltip } from 'antd'
import React, { FunctionComponent, useCallback, useState } from 'react'

import { RecipientFragment } from '../../../graphql/generated'
import { PostServiceSendLetterForm } from '../PostServiceSendLetterForm/PostServiceSendLetterForm'

export interface PostServiceSendLetterButtonProps {
  content: string
  recipients: RecipientFragment[]
  listId: string
  templateId?: string
  disabled: boolean
}

export const PostServiceSendLetterButton: FunctionComponent<PostServiceSendLetterButtonProps> =
  props => {
    const [open, setOpen] = useState(false)

    const handleModal = useCallback(() => {
      setOpen(prev => !prev)
    }, [])
    return (
      <>
        <Tooltip
          title="Aktualizujte prvně náhled PDF"
          trigger={props.disabled ? 'hover' : ''}
        >
          <Button
            disabled={props.disabled || open}
            onClick={handleModal}
            type="primary"
          >
            Uložit korespondenci
          </Button>
        </Tooltip>
        <Modal
          title="Uložit korespondenci"
          open={open}
          onCancel={handleModal}
          footer={null}
          width={650}
        >
          <PostServiceSendLetterForm
            content={props.content}
            hideModal={handleModal}
            recipients={props.recipients}
            templateId={props.templateId}
            listId={props.listId}
          />
        </Modal>
      </>
    )
  }
