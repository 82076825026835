import { Icon } from '@iconify/react'
import { Alert, Button } from 'antd'
import React, { FunctionComponent } from 'react'

export interface PropertyDetailTimelineAlertProps {
  filtered: boolean
  toggleFiltered: () => void
}

const AlertProps = {
  className: '!items-center !mb-8',
  type: 'warning',
  showIcon: true,
} as const

export const PropertyDetailTimelineAlert: FunctionComponent<PropertyDetailTimelineAlertProps> =
  props => {
    if (props.filtered)
      return (
        <Alert
          {...AlertProps}
          message="Počet zobrazených záznamů byl omezen"
          description="U této nemovitosti evidujeme více než 1000 událostí v časové ose, zobrazení tak velkého počtu může ztížit orientaci v datech a také zpomalit webový prohlížeč, proto je zobrazení aktuálně omezeno na údaje platné v posledních 4 letech. Kliknutím na tlačítko můžete zobrazit všechna dostupná data."
          icon={<Icon icon="mage:filter" className="!text-5xl !mr-6" />}
          action={
            <Button
              ghost
              onClick={props.toggleFiltered}
              className="!text-black !border-black hover:opacity-60 ml-4"
            >
              Rozumím a zobrazit vše
            </Button>
          }
        />
      )

    return (
      <Alert
        {...AlertProps}
        message="Pro zlepšení výkonu a orientace můžete vyfiltrovat pouze data platná v posledních 4 letech."
        icon={<Icon icon="mage:filter" className="text-base mr-2" />}
        action={
          <Button
            ghost
            onClick={props.toggleFiltered}
            className="!text-black !border-black hover:opacity-60 ml-4"
            size="small"
          >
            Filtrovat data
          </Button>
        }
      />
    )
  }
