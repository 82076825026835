import { Button } from 'antd'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { useAppSelector } from '../../../redux/hooks'
import { propertyDetailPath } from '../../utils/paths'
import { WatchdogButton } from '../WatchdogButton/WatchdogButton'

export const DetailDrawerPropertyHeader: FunctionComponent = () => {
  const ruian = useAppSelector(state => state.property.ruian)

  if (!ruian.data?.type || !ruian.data?.id) {
    return null
  }
  return (
    <div className="flex justify-between items-center">
      <div>Náhled nemovitosti</div>
      <div className="flex space-x-2 items-center">
        <WatchdogButton
          propertyType={ruian.data.type}
          propertyId={ruian.data.id}
          iconSize="text-xl"
        />

        <Link
          to={propertyDetailPath(ruian.data.type, ruian.data.id)}
          target="_blank"
          title="Otevřít detail nemovitosti v novém okně"
        >
          <Button type="primary">Zobrazit detail nemovitosti</Button>
        </Link>
      </div>
    </div>
  )
}
