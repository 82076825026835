import React, { FunctionComponent } from 'react'

import { ImgSrc } from '../../utils/useMapImage'

export interface PriceEstimateMapBoxProps {
  imgSrc?: ImgSrc
}

export const PriceEstimateMapBox: FunctionComponent<PriceEstimateMapBoxProps> =
  props => {
    return (
      <div className="grid grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 xl:gap-8 print:gap-2 print:h-56 print:overflow-hidden animate-fadeIn">
        {props?.imgSrc?.streetView ? (
          <>
            <a target="blank" href={props.imgSrc.mapLink}>
              <img
                src={props.imgSrc.map}
                className="object-cover print:w-full print:h-56"
                alt="Umístění nemovitosti v mapě"
              />
            </a>
            <a target="blank" href={props.imgSrc.streetViewLink}>
              <img
                src={props.imgSrc.streetView}
                className="object-cover print:w-full print:h-56"
                alt="Pohled na nemovitost"
              />
            </a>
          </>
        ) : (
          <>
            <div className="bg-gray-200 animate-pulse aspect-square" />
            <div className="bg-gray-200 animate-pulse aspect-square" />
          </>
        )}
      </div>
    )
  }
