import { Modal, Select } from 'antd'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { useAppSelector } from '../../../redux/hooks'
import { WatchdogAddPropertyModalFooter } from '../WatchdogAddPropertyModalFooter/WatchdogAddPropertyModalFooter'
import { WatchdogChannelModalButton } from '../WatchdogChannelModalButton/WatchdogChannelModalButton'

export interface WatchdogAddPropertyModalProps {
  propertyId: string
  propertyType: PROPERTY_TYPES
  open: boolean
  onClose: () => void
}

const buttonType = { type: 'link' as const }
const title = (
  <div>
    <div>Přidat nemovitost do hlídače katastru </div>
    <div className="pb-3 font-normal text-sm text-gray-400">
      Hlídání změn u nemovitosti je zpoplatněno na základě vašeho tarifu.
    </div>
  </div>
)

export const WatchdogAddPropertyModal: FunctionComponent<WatchdogAddPropertyModalProps> =
  ({ propertyId, propertyType, open, onClose }) => {
    const channelData = useAppSelector(state => state.watchdog.channel)
    const lastChannelId = useAppSelector(state => state.watchdog.lastChannel.id)
    const [channelId, setChannelId] = useState(null)

    useEffect(() => {
      if (lastChannelId && channelId === null) {
        setChannelId(lastChannelId)
      }
    }, [lastChannelId, channelId])

    const options = useMemo(
      () =>
        channelData.data?.map(item => ({ value: item.id, label: item.nazev })),
      [channelData.data]
    )

    return (
      <Modal
        title={title}
        width={600}
        open={open}
        onCancel={onClose}
        footer={null}
      >
        <Select
          className="w-full"
          placeholder="Vyberte kanál hlídače"
          loading={channelData.fetching}
          disabled={channelData.error}
          allowClear
          autoFocus
          value={channelId}
          options={options}
          onChange={setChannelId}
        />
        <div className="mt-2 text-right">
          <WatchdogChannelModalButton
            buttonProps={buttonType}
            buttonText="Vytvořit nový kanál"
          />
        </div>
        <WatchdogAddPropertyModalFooter
          propertyId={propertyId}
          propertyType={propertyType}
          channelId={channelId}
          handleOpen={onClose}
        />
      </Modal>
    )
  }
