import { Tooltip } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import {
  Identifier,
  IdentifierValues,
  Register,
  RegisterGroupMap,
} from '../../utils/dynamicSearchTooltip'

export interface SearchOnWebProps {
  tooltipTitle?: string
  register:
    | Register.IsirIco
    | Register.IsirRc
    | Register.IsirName
    | Register.IsirCompanyName
    | Register.Google
    | Register.AresIco
    | Register.AresName
    | Register.AresCompanyName
  identifierValues: IdentifierValues
  children?: React.ReactNode
}

export const SearchOnWeb: FunctionComponent<SearchOnWebProps> = props => {
  const searchUrl = useMemo(() => {
    const isirBaseUrl = `https://isir.justice.cz/isir/ueu/vysledek_lustrace.do?aktualnost=AKTUALNI_I_UKONCENA&captcha_answer=&spis_znacky_obdobi=14DNI&`
    const aresBaseUrl = `https://ares.gov.cz/ekonomicke-subjekty?`
    return {
      [Register.IsirIco]: `${isirBaseUrl}ic=${
        props.identifierValues?.[Identifier.ICO]
      }`,

      [Register.IsirRc]: `${isirBaseUrl}rc=${
        props.identifierValues?.[Identifier.RC]
      }`,

      [Register.IsirName]: `${isirBaseUrl}nazev_osoby=${
        props.identifierValues?.[Identifier.FIRST_NAME]
      }%20${props.identifierValues?.[Identifier.LAST_NAME]}`,

      [Register.IsirCompanyName]: `${isirBaseUrl}nazev_osoby=${
        props.identifierValues?.[Identifier.COMPANY_NAME]
      }`,

      [Register.Google]: `https://www.google.com/search?q=${props.identifierValues}`,

      [Register.AresIco]: `${aresBaseUrl}ico=${
        props.identifierValues?.[Identifier.ICO]
      }`,

      [Register.AresName]: `${aresBaseUrl}obchodniJmeno=${
        props.identifierValues?.[Identifier.FIRST_NAME]
      }%20${props.identifierValues?.[Identifier.LAST_NAME]}`,

      [Register.AresCompanyName]: `${aresBaseUrl}obchodniJmeno=${
        props.identifierValues?.[Identifier.COMPANY_NAME]
      }`,
      // [Register.CuzkVlastnictvi]: `https://data.regesta.cz/Vyhledavani/Subjekty?nazev=${props.identifierValues}`,
    }
  }, [props.identifierValues])

  const group = Object.keys(RegisterGroupMap).find(group =>
    RegisterGroupMap[group as keyof typeof RegisterGroupMap].includes(
      props.register
    )
  )

  if (props.tooltipTitle)
    return (
      <Tooltip title={props.tooltipTitle}>
        <a target="_blank" href={searchUrl?.[props.register]} rel="noreferrer">
          {props.children}
        </a>
      </Tooltip>
    )

  return (
    <a target="_blank" href={searchUrl?.[props.register]} rel="noreferrer">
      {props.children || group || 'Hledat'}
    </a>
  )
}
