import { Form, Select, Spin } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { AresEkonomickySubjekt } from '../../../common/aresTypes'
import { useAppSelector } from '../../../redux/hooks'
import {
  fetchAresByBusinessName,
  fetchAresByIco,
  fetchRzpByIco,
} from '../../utils/fetchAresByIco'
import { getCallName } from '../../utils/getCallName'

export interface AresSearchBarProps {
  companyOnly?: boolean
  hideIco?: boolean
  showStreetName?: boolean
  rzpDetail?: boolean
  callName?: boolean
}

export const AresSearchBar: FunctionComponent<AresSearchBarProps> = props => {
  const token = useAppSelector(state => state.myProfile.token)
  const form = Form.useFormInstance()
  const [fetchedData, setFetchedData] = useState<
    undefined | AresEkonomickySubjekt[]
  >()
  const [loading, setLoading] = useState(false)

  const getRzpDetail = useCallback(
    async (ico: string) => {
      const response = await fetchRzpByIco(ico)
      const subject = response?.zaznamy?.[0]

      if (subject) {
        subject.typSubjektu === 'P'
          ? form.setFieldsValue({
              firstname: 'Odpovědná',
              lastname: 'Osoba',
              companyName: subject.obchodniJmeno,
            })
          : form.setFieldsValue({
              firstname: subject.osobaPodnikatel?.jmeno,
              lastname: subject.osobaPodnikatel?.prijmeni,
              companyName: undefined,
            })
        if (props.callName) {
          const callName = await getCallName(
            subject.osobaPodnikatel?.prijmeni,
            token
          )

          form.setFieldsValue({
            callName: callName?.man || callName?.female || callName,
            gender: callName?.man ? 'm' : callName?.female ? 'f' : 0,
          })
        }
      }
    },
    [form, props.callName, token]
  )

  const handleSelect = useCallback(
    (data: AresEkonomickySubjekt) => {
      form.setFieldsValue({
        name: data.obchodniJmeno,
        ico: data.ico?.toString(),
        street: {
          streetName: data.sidlo?.nazevUlice || data.sidlo?.nazevCastiObce,
          houseNumber: data.sidlo?.cisloDomovni?.toString(),
          orientationNumber: data.sidlo?.cisloOrientacni?.toString(),
        },
        city: data.sidlo?.nazevObce,
        zipCode: data.sidlo?.psc?.toString(),
        country: data.sidlo?.kodStatu,
      })
      if (props.rzpDetail && data.ico) getRzpDetail(data.ico)
    },
    [form, getRzpDetail, props.rzpDetail]
  )

  const options = useMemo(() => {
    const options: DefaultOptionType[] | undefined = fetchedData?.map(
      (data: AresEkonomickySubjekt) => ({
        label:
          (
            <div
              className="flex justify-between"
              title={data?.sidlo?.textovaAdresa}
              onClick={() => handleSelect(data)}
            >
              <span className="truncate inline-flex">
                {!props.hideIco && (
                  <span className="text-gray-400 mr-2">{data?.ico}</span>
                )}
                <span className="truncate">{data?.obchodniJmeno}</span>
              </span>
              <span className="text-gray-400 cursor-help">
                {data?.sidlo?.nazevObce}
                {props.showStreetName &&
                  (data?.sidlo?.nazevUlice || data?.sidlo?.nazevCastiObce) &&
                  `, ${data?.sidlo?.nazevUlice || data?.sidlo?.nazevCastiObce}`}
              </span>
            </div>
          ) || 'Název není k dispozici',
        value: data?.ico || 'Není k dispozici',
      })
    )

    if (loading)
      return [
        {
          label: (
            <div className="space-x-2 animate-pulse">
              <span className="gray-800">Vyhledávání </span>
              <Spin size="small" />
            </div>
          ),
          value: 'loading',
          disabled: true,
        },
        ...(options || []),
      ]
    return options
  }, [fetchedData, handleSelect, loading, props.hideIco, props.showStreetName])

  const handleInputChange = useCallback(
    async (value: string) => {
      if (!value) return setFetchedData(undefined)
      if (value.length > 3) setLoading(true)
      let sanitizedIco = value.replace(/\s+/g, '')

      if (sanitizedIco.length > 3 && /^[0-9]+$/.test(sanitizedIco)) {
        sanitizedIco = sanitizedIco.padStart(8, '0')
      }

      if (sanitizedIco.length === 8 && /^[0-9]+$/.test(sanitizedIco)) {
        const searchResult = await fetchAresByIco(sanitizedIco)
        setLoading(false)
        setFetchedData(searchResult ? [searchResult] : undefined)
      } else if (value.length > 3) {
        const searchResult = await fetchAresByBusinessName(
          value.replace(/\s+/g, '+'),
          props.companyOnly
        )
        setLoading(false)
        setFetchedData(
          searchResult?.ekonomickeSubjekty
            ? searchResult.ekonomickeSubjekty
            : undefined
        )
      }
    },
    [props.companyOnly]
  )

  return (
    <Select
      showSearch
      allowClear
      loading={loading}
      autoFocus
      placeholder="Zadejte název, nebo IČ"
      filterOption={false}
      onSearch={handleInputChange}
      labelInValue
      options={options}
      className="w-full focus-within:[&>div]:!bg-primaryHover/10"
      defaultActiveFirstOption
    />
  )
}
