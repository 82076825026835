import { BASE_URL } from '../../../ini.json'

export type CreditCheckResult = {
  balance: number
  isEnough: boolean
  checked: boolean
  error?: string
}
export const checkCredit = async (
  creditNeeded: number,
  token: string
): Promise<CreditCheckResult> => {
  const errorResult: CreditCheckResult = {
    balance: 0,
    isEnough: false,
    checked: false,
    error: 'Nepodařilo se ověřit váš kredit.',
  }
  try {
    const response = await fetch(`${BASE_URL}/api/credit/${token}`)
    if (response.status === 401) {
      return {
        ...errorResult,
        error: 'Kvůli dlouhé neaktivitě jste byli odhlášeni.',
      }
    }
    if (!response.ok) {
      return errorResult
    }

    const data = await response.json()
    return {
      balance: data?.zustatek ?? 0,
      isEnough: (data?.zustatek ?? 0) > creditNeeded,
      checked: true,
    }
  } catch (error) {
    console.error('Failed to check credit:', error)
    return errorResult
  }
}
