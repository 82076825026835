import React, { FunctionComponent } from 'react'

import { PravoStavbyType } from '../../../common/cuzkLvTypes'
import { safeMap } from '../../../common/pdfHelpers'

export interface CuzkLvHtmlPravoStavbyIdentFragmentProps {
  pravoStavbyIdent?: PravoStavbyType
  active?: boolean
  breakLine?: boolean
  noLabel?: boolean
}

export const CuzkLvHtmlPravoStavbyIdentFragment: FunctionComponent<CuzkLvHtmlPravoStavbyIdentFragmentProps> =
  props => {
    const pravoStavby = props.pravoStavbyIdent
    return (
      <span className={props.breakLine ? 'breakLine' : ''}>
        {props.noLabel ? '' : 'Právo stavby: '}
        {safeMap(pravoStavby?.parcela, (parcela, index) => (
          <span key={index} className="breakAll">
            {parcela?.zkratka} {parcela?.druh_cis} {parcela?.par_cis}
            {parcela?.podd_cis && '/'}
            {parcela?.podd_cis}
            {', '}
          </span>
        ))}
        {/*
        {pravoStavby?.id}
        {pravoStavby?.cislo_tel}
        {pravoStavby?.ku_nazev}
        {pravoStavby?.tel_id}
         */}
      </span>
    )
  }
