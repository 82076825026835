import { Alert, Spin } from 'antd'
import React, { FunctionComponent } from 'react'

import { FETCH_ERROR_TYPES } from '../PriceEstimateLayout/PriceEstimateLayout'

export interface PriceEstimateLayoutStateAlertProps {
  loading?: boolean
  fetchError?: FETCH_ERROR_TYPES
  children?: React.ReactNode
}

export const PriceEstimateLayoutStateAlert: FunctionComponent<PriceEstimateLayoutStateAlertProps> =
  props => {
    if (props.fetchError === FETCH_ERROR_TYPES.RUIAN)
      return (
        <Alert
          type="error"
          description="Je nám líto, ale vyskytla se chyba, která znemožňuje vytvořit cenový odhad nemovitosti. Opakujte prosím akci později, nebo se obraťte na technickou podporu."
          message="Odhad nelze vytvořit"
        />
      )

    if (props.fetchError === FETCH_ERROR_TYPES.LV)
      return (
        <Alert
          type="warning"
          description="Je nám líto, nepodařilo se načíst data z listu vlastnictví, pokud však údaje o nemovitosti znáte, můžete vyplnit formulář a dokončit cenový odhad."
          message="Nepodařilo se načíst údaje z LV"
        />
      )

    if (props.loading)
      return (
        <div className="w-full h-48 flex items-center justify-center">
          <Spin />
        </div>
      )

    return <>{props.children || null}</>
  }
