import 'react-quill/dist/quill.snow.css'

import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReactQuill, { Quill } from 'react-quill'

import {
  checkAndCleanImages,
  quillProps,
  replacePostVariables,
} from '../../../common/postServicePdf'
import { postServiceTestRecipient } from '../../../common/postServiceTypes'
import { RecipientFragment } from '../../../graphql/generated'
import { PostServiceLetterImageModal } from '../PostServiceLetterImageModal/PostServiceLetterImageModal'
import { PostServicePdfViewer as PdfViewer } from '../PostServicePdfViewer/PostServicePdfViewer'
import { PostServiceSaveTemplateButton as CreateTemplateButton } from '../PostServiceSaveTemplateButton/PostServiceSaveTemplateButton'
import { PostServiceSelectSampleRecipient as SelectSampleRecipient } from '../PostServiceSelectSampleRecipient/PostServiceSelectSampleRecipient'
import { PostServiceSendLetterButton as SendLetterButton } from '../PostServiceSendLetterButton/PostServiceSendLetterButton'
import { PostServiceTemplateButton as TemplateButton } from '../PostServiceTemplateButton/PostServiceTemplateButton'
import { PostServiceUpdateTemplateButton as UpdateTemplateButton } from '../PostServiceUpdateTemplateButton/PostServiceUpdateTemplateButton'
import { PostServiceVariablesDropdown as VariablesDropdown } from '../PostServiceVariablesDropdown/PostServiceVariablesDropdown'

export interface PostServiceLetterCreateProps {
  recipients: RecipientFragment[]
  listId: string
  templateContent?: string
  templateId?: string
  templateName?: string
}

const Image = Quill.import('formats/image')
const Font = Quill.import('formats/font')
Quill.register(Font, true)
Font.whitelist = ['emitha-script']

class CustomImage extends Image {
  static value(domNode: HTMLElement) {
    return {
      src: domNode.getAttribute('src'),
      style: domNode.getAttribute('style'),
    }
  }

  static create(value: string | { src: string; style: string }) {
    const node = super.create(typeof value === 'string' ? value : value.src)
    node.setAttribute(
      'style',
      typeof value === 'string'
        ? 'width: 100%; display: block;'
        : value.style || 'width: 100%; display: block;'
    )
    return node
  }
}
Quill.register(CustomImage, true)

export const PostServiceLetterCreate: FunctionComponent<PostServiceLetterCreateProps> =
  props => {
    const recipients = props.recipients
    const [pdfInactive, setPdfInactive] = useState<boolean>(false)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [recipient, setRecipient] = useState<any>(
      recipients?.[0] || postServiceTestRecipient
    )
    const [responsive, setResponsive] = useState(false)
    const [htmlData, setHtmlData] = useState('')
    const [pdfData, setpdfData] = useState<string>('')

    const quillRef = useRef<ReactQuill>(null)

    const recipientsName = useMemo(
      () =>
        recipients?.map(
          (item: { id?: string; firstname?: string; lastname?: string }) => ({
            value: item?.id,
            label: item?.firstname + ' ' + item?.lastname,
          })
        ),
      [recipients]
    )

    useEffect(() => {
      quillRef.current
        ?.getEditor()
        .getModule('toolbar')
        .addHandler('formula', () => {
          setResponsive(prev => !prev)
        })
    }, [])

    const handleSelectRecipient = useCallback(
      (id: string) => {
        setRecipient(
          recipients.find((item: { id: string }) => item?.id === id) ||
            recipients?.[0]
        )
        setPdfInactive(true)
      },
      [recipients]
    )

    const handleInsertText = useCallback((e: { key: string }) => {
      if (!quillRef.current) return
      const quill = quillRef.current.getEditor()
      quill.focus()
      quill.insertText(quill.getSelection()?.index || 0, e.key)
    }, [])

    const getTemplateData = useCallback(
      () => ({
        sample: quillRef.current?.editor?.getText().trim(),
        html: htmlData,
      }),
      [htmlData]
    )

    const setTemplate = useCallback((content: string) => {
      if (!quillRef.current) return
      return quillRef.current
        .getEditor()
        .clipboard.dangerouslyPasteHTML(content)
    }, [])

    useEffect(() => {
      if (props.templateContent && !htmlData) {
        setTemplate(props.templateContent)
      }
    }, [htmlData, props.templateContent, setTemplate])

    const handlePdfActive = useCallback((html: string) => {
      setPdfInactive(true)
      setHtmlData(html)
    }, [])

    const handleUpdatePdf = useCallback(() => {
      const replacedVariables = replacePostVariables(htmlData, recipient)
      const validatedImages = checkAndCleanImages(replacedVariables)
      setpdfData(validatedImages)
      setPdfInactive(false)
    }, [htmlData, recipient])

    const templateMode = useMemo(
      () => ({
        create: !props.listId && !props.templateId,
        edit: !props.listId && !!props.templateId,
        send: !!props.listId && !!props.templateId,
      }),
      [props.listId, props.templateId]
    )

    return (
      <>
        <div
          className={
            'flex flex-wrap justify-center grow 2xl:flex-nowrap gap-8 ' +
            (responsive ? 'responsive md:flex-nowrap' : '')
          }
        >
          <div
            className={
              'flex flex-col ' + (responsive ? 'w-full md:w-1/2' : 'w-[21cm]')
            }
          >
            <ReactQuill
              className="relative w-full h-full flex flex-col"
              onChange={handlePdfActive}
              ref={quillRef}
              {...quillProps}
            />
            <div className="mt-4 space-x-2 flex ">
              <VariablesDropdown handleInsertText={handleInsertText} />
              <SelectSampleRecipient
                recipients={recipientsName}
                setRecipient={handleSelectRecipient}
              />
              <TemplateButton setContent={setTemplate} />
              {templateMode.send && (
                <CreateTemplateButton
                  data={getTemplateData}
                  disabled={pdfInactive}
                />
              )}
            </div>
          </div>
          <div
            className={
              'flex flex-col relative w-full ' +
              (responsive ? 'md:w-1/2' : '2xl:w-1/2 flex-1 min-w-[340px]')
            }
          >
            <PdfViewer
              pdfData={pdfData}
              isPdfActive={pdfInactive}
              handleUpdatePdf={handleUpdatePdf}
            />
            <div className="mt-4 text-right z-10">
              {templateMode.edit ? (
                <UpdateTemplateButton
                  data={getTemplateData}
                  templateName={props.templateName || ''}
                  templateId={props.templateId || ''}
                  disabled={pdfInactive}
                />
              ) : templateMode.create ? (
                <CreateTemplateButton
                  data={getTemplateData}
                  disabled={pdfInactive}
                />
              ) : (
                <SendLetterButton
                  templateId={props.templateId}
                  listId={props.listId}
                  recipients={recipients}
                  content={htmlData}
                  disabled={pdfInactive}
                />
              )}
            </div>
          </div>
        </div>
        <PostServiceLetterImageModal
          editorRef={quillRef.current?.getEditor()}
          setPdfInactive={setPdfInactive}
        />
      </>
    )
  }
