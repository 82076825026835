import { Spin } from 'antd'
import React, { FunctionComponent } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { useAppSelector } from '../../../redux/hooks'
import { PriceEstimateLayoutLvDataRow as Item } from '../PriceEstimateLayoutLvDataRow/PriceEstimateLayoutLvDataRow'
import { PropertyDetailFetchError } from '../PropertyDetailFetchError/PropertyDetailFetchError'
import { PropertyDetailSidebarContainer } from '../PropertyDetailSidebarContainer/PropertyDetailSidebarContainer'
import { PropertyDetailSidebarRuianArea } from '../PropertyDetailSidebarRuianArea/PropertyDetailSidebarRuianArea'
import { PropertyDetailSidebarRuianBuilding } from '../PropertyDetailSidebarRuianBuilding/PropertyDetailSidebarRuianBuilding'
import { PropertyDetailSidebarRuianUnit } from '../PropertyDetailSidebarRuianUnit/PropertyDetailSidebarRuianUnit'

export interface PropertyDetailSidebarRuianProps {
  isDrawer?: boolean
}

export const PropertyDetailSidebarRuian: FunctionComponent<PropertyDetailSidebarRuianProps> =
  ({ isDrawer }) => {
    const { data: drmDetail } = useAppSelector(state => state.property.drm)
    const {
      data: ruianDetail,
      fetching,
      error,
    } = useAppSelector(state => state.property.ruian)

    if (error)
      return (
        <PropertyDetailFetchError
          description="Je nám líto, ale nepodařilo se dohledat detailní informace o parametrech této nemovitosti."
          message="Bližší informace nenalezeny"
        />
      )
    return (
      <PropertyDetailSidebarContainer isDrawer={isDrawer}>
        <Spin spinning={fetching}>
          <div className="flex flex-col space-y-2 animate-fadeIn">
            {fetching && (
              <Item title="Informace o nemovitosti...">
                <div />
              </Item>
            )}
            {ruianDetail?.type === PROPERTY_TYPES.UNIT && (
              <PropertyDetailSidebarRuianUnit />
            )}

            {ruianDetail?.type === PROPERTY_TYPES.BUILDING && (
              <PropertyDetailSidebarRuianBuilding />
            )}

            {ruianDetail?.type === PROPERTY_TYPES.AREA && (
              <PropertyDetailSidebarRuianArea />
            )}

            <Item title="Způsob ochrany">{drmDetail?.zpusobOchrany}</Item>
          </div>
        </Spin>
      </PropertyDetailSidebarContainer>
    )
  }
