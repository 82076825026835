import { Map, MapEvent } from 'mapbox-gl'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useMap } from 'react-map-gl'

/**
 * Custom hook to watch specified Mapbox events and trigger component re-renders accordingly.
 *
 * @param {ReadonlyArray<Exclude<MapEvent, number | symbol>>} events - An array of Mapbox event names to listen for.
 * @returns {[Map | null | undefined, number]} - A tuple containing the Map instance and an update trigger value.
 *
 * @example
 * // Usage in a functional component
 * const [map, mapUpdateTrigger] = useMapWatch(['moveend']);
 *
 * useEffect(() => {
 *   // Your implementation that needs to run when 'moveend' occurs
 * }, [map, mapUpdateTrigger]); // Include 'mapUpdateTrigger' in dependencies
 *
 * @remarks
 * **Important:** Include `mapUpdateTrigger` in the dependency array of your `useEffect` hooks,
 * not just `map`. The `map` object reference may remain the same even when events occur,
 * so relying solely on `map` will not trigger the effect when the specified events happen.
 * The `mapUpdateTrigger` increments whenever one of the specified events occurs,
 * ensuring your `useEffect` runs in response.
 */
export function useMapWatch(
  events: ReadonlyArray<Exclude<MapEvent, number | symbol>> = []
): [Map | null | undefined, number] {
  const map = useMap().current?.getMap()
  const [forceUpdate, setForceUpdate] = useState(0)
  const rafRef = useRef<number | null>(null)

  useLayoutEffect(
    function waitForMapToLoad() {
      if (!map) return
      const handleStyleLoad = () => {
        setForceUpdate(prev => prev + 1)
      }
      if (map.isStyleLoaded()) handleStyleLoad()
      else map.once('style.load', handleStyleLoad)
      return () => {
        map.off('style.load', handleStyleLoad)
      }
    },
    [map]
  )

  // Memoize events to prevent unnecessary effect triggers
  const eventsDepKey = events.join(',')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedEvents = useMemo(() => events, [eventsDepKey])

  useEffect(
    function setupEventListeners() {
      // Check style is loaded here?
      if (!map) return
      const batchUpdate = () => {
        if (rafRef.current !== null) cancelAnimationFrame(rafRef.current)
        rafRef.current = requestAnimationFrame(() => {
          setForceUpdate(prev => prev + 1)
          rafRef.current = null
        })
      }
      memoizedEvents.forEach(event => map.on(event, batchUpdate))
      return () => {
        memoizedEvents.forEach(event => map.off(event, batchUpdate))
        if (rafRef.current !== null) cancelAnimationFrame(rafRef.current)
      }
    },
    [map, memoizedEvents]
  )

  return [map, forceUpdate]
}
