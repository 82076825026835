import { CloseOutlined } from '@ant-design/icons'
import { Icon } from '@iconify/react'
import { Popover } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { SubjectsInPostServiceList } from '../../../common/postServiceTypes'
import { useToggleState } from '../../utils/useToggleState'
import { SubjectTableCellRecipientIconCount } from '../SubjectTableCellRecipientIconCount/SubjectTableCellRecipientIconCount'
import { SubjectTableCellRecipientListTable } from '../SubjectTableCellRecipientListTable/SubjectTableCellRecipientListTable'

export interface SubjectTableCellRecipientListProps {
  postServiceList?: SubjectsInPostServiceList[]
}

export const SubjectTableCellRecipientList: FunctionComponent<SubjectTableCellRecipientListProps> =
  props => {
    const [open, handleOpen] = useToggleState()

    const title = useMemo(
      () => (
        <div className="flex justify-between items-center">
          <span>{`Subjekt zařazen do ${
            props.postServiceList?.length ? 'adresářů' : 'adresáře'
          } hromadné pošty:`}</span>
          <CloseOutlined
            onClick={handleOpen}
            className="text-gray-400 hover:text-gray-800 p-1 cursor-pointer"
          />
        </div>
      ),
      [handleOpen, props.postServiceList?.length]
    )

    if (!props?.postServiceList?.length) return null

    return (
      <Popover
        overlayClassName="w-full max-w-xl"
        onOpenChange={handleOpen}
        placement="topRight"
        trigger="click"
        title={title}
        open={open}
        content={
          <SubjectTableCellRecipientListTable
            postServiceList={props.postServiceList}
          />
        }
      >
        <div
          className="relative cursor-pointer"
          title="Zobrazit zařazení do seznamu adresátů"
        >
          <SubjectTableCellRecipientIconCount
            count={props.postServiceList.length}
          />
          <Icon
            icon="uiw:mail-o"
            className="w-auto h-5 text-gray-400 hover:text-primaryHover"
          />
        </div>
      </Popover>
    )
  }
