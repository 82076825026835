import { message, Popconfirm, Select, Spin } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { useAllPostserviceTemplatesQuery } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'

export interface PostServiceTemplateSelectProps {
  handleChange: (value: string) => void
}

export const PostServiceTemplateSelect: FunctionComponent<PostServiceTemplateSelectProps> =
  props => {
    const { Option } = Select
    const tenantId = useAppSelector(state => state.myProfile.tenantId)

    const [queryResult] = useAllPostserviceTemplatesQuery({
      variables: { accountId: tenantId },
    })
    const [temporaryContent, setTemporaryContent] = useState<
      string | undefined
    >()

    const templates = useMemo(
      () =>
        queryResult.data?.allPostserviceTemplates?.nodes.map(item => (
          <Option key={item?.id} value={item?.content} label={item?.name}>
            <div>
              <div>{item?.name}</div>
              {item?.description && (
                <div className="flex flex-wrap max-h-20 whitespace-normal font-normal text-gray-400 overflow-ellipsis">
                  {item.description}
                  {item.description.length > 190 && '...'}
                </div>
              )}
            </div>
          </Option>
        )),
      [Option, queryResult.data?.allPostserviceTemplates?.nodes]
    )

    const removeTemporaryContent = useCallback(() => {
      setTemporaryContent(undefined)
    }, [])

    const handleConfirm = useCallback(() => {
      if (typeof temporaryContent !== 'string')
        return message.error('Chyba při načítání šablony')

      props.handleChange(temporaryContent)
      return removeTemporaryContent()
    }, [props, removeTemporaryContent, temporaryContent])

    if (queryResult.fetching)
      return (
        <div className="w-full flex justify-center">
          <Spin />
        </div>
      )

    return (
      <>
        <Popconfirm
          title="Načíst šablonu dopisu?"
          description="Aktuální obsah dopisu bude nahrazen vybranou šablonou."
          open={!!temporaryContent}
          onConfirm={handleConfirm}
          onCancel={removeTemporaryContent}
          okText="Souhlasím"
          cancelText="Zrušit"
          className="w-full"
        />
        <Select
          className="w-full"
          placeholder="Vyberte šablonu dopisu"
          onChange={setTemporaryContent}
          optionLabelProp="label"
          disabled={!!temporaryContent}
        >
          {templates}
        </Select>
      </>
    )
  }
