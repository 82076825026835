import React, { FunctionComponent, useMemo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { usePropertyDetailNoteTimelineQuery } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { PropertyDetailNote as PropertyDetailNoteType } from '../../utils/propertyDetailNoteHelpers'
import { CadastralImportBox } from '../CadastralImportBox/CadastralImportBox'
import { PageLayoutTabContainer } from '../PageLayoutTabContainer/PageLayoutTabContainer'
import { PropertyDetailNoteCreate } from '../PropertyDetailNoteCreate/PropertyDetailNoteCreate'
import { PropertyDetailNoteTimeline } from '../PropertyDetailNoteTimeline/PropertyDetailNoteTimeline'
import { PropertyDetailNoteTimelineSkeleton } from '../PropertyDetailNoteTimelineSkeleton/PropertyDetailNoteTimelineSkeleton'

export interface PropertyDetailNoteProps {
  propertyId: string
}

export const PropertyDetailNote: FunctionComponent<PropertyDetailNoteProps> =
  props => {
    const accountId = useAppSelector(state => state.myProfile.tenantId)
    const propertyLv = useAppSelector(
      state => `${state.property.drm.data?.lv || ''}`
    )
    const propertyType = useAppSelector(
      state => state.property.drm.data?.type as PROPERTY_TYPES
    )
    const propertyCadastralCode = useAppSelector(
      state => `${state.property.drm.data?.katastralniUzemi?.kod || ''}`
    )

    const [queryResult, refetch] = usePropertyDetailNoteTimelineQuery({
      variables: { accountId, propertyCadastralCode, propertyLv },
    })

    const notes = useMemo(
      () =>
        (queryResult.data?.allPropertyNotes?.nodes ||
          []) as PropertyDetailNoteType[],
      [queryResult.data?.allPropertyNotes?.nodes]
    )

    return (
      <PageLayoutTabContainer>
        <div className="w-full max-w-7xl mt-10">
          {queryResult.fetching && !notes.length ? (
            <PropertyDetailNoteTimelineSkeleton />
          ) : (
            <PropertyDetailNoteTimeline notes={notes} refetch={refetch} />
          )}
          <CadastralImportBox
            size="middle"
            entityType="property"
            classNameInner="max-w-7xl mx-auto"
            className="px-8 mt-8"
            message="Pro přidání nové poznámky je zapotřebí mít načtené detailní informace o nemovitosti."
          />
          <PropertyDetailNoteCreate
            propertyId={props.propertyId}
            propertyCadastralCode={propertyCadastralCode}
            propertyLv={propertyLv}
            propertyType={propertyType}
          />
        </div>
      </PageLayoutTabContainer>
    )
  }
