import { Button, message } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useCallback, useState } from 'react'

import { BASE_URL } from '../../../../ini.json'
import { useAppSelector } from '../../../redux/hooks'
import { handleResponseStatus } from '../../utils/handleResponseStatus'
import { pricelist } from '../../utils/pricelist'
import { PricePopconfirm } from '../PricePopconfirm/PricePopconfirm'

export interface CuzkEpoRestrictionCheckPanelInitialCheckProps {
  epoId: string
  refetch: () => void
  isHugeSubject: boolean
}

export const CuzkEpoRestrictionCheckPanelInitialCheck: FunctionComponent<CuzkEpoRestrictionCheckPanelInitialCheckProps> =
  props => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const userId = useAppSelector(state => state.myProfile.userId)
    const token = useAppSelector(state => state.myProfile.token)

    const [loading, setLoading] = useState(false)

    const fetchData = useCallback(async () => {
      setLoading(true)
      try {
        const response = await axios.post(
          `${BASE_URL}/api/cuzk/epo/checkRestriction`,
          {
            tenantId,
            userId,
            id: props.epoId,
            token,
            buy: true,
          }
        )
        const { status } = response
        handleResponseStatus(status)
        if (status === 200) props.refetch()
      } catch (error) {
        message.error(
          'Nepodařilo se provést kontrolu, zkuste to prosím později.'
        )
      } finally {
        setLoading(false)
      }
    }, [props, tenantId, token, userId])
    if (props.isHugeSubject) {
      return (
        <>
          <div className="mb-2 mt-2 text-center text-base font-bold">
            Ověření práv 3. stran v jednotlivých kat. územích
          </div>
          <div className="mb-2 mt-2 text-center text-gray-400">
            Tato funkce umožňuje hromadně ověřit práva 3. stran k jednotlivým
            LV. Lorem ipsum At vero eos et accusamus et iusto odio dignissimos
            ducimus qui blanditiis praesentium voluptatum deleniti atque
            corrupti quos dolores et quas molestias excepturi.
          </div>
          <div>
            <Button loading={loading} onClick={fetchData} type="primary">
              Vyhledat LV s právy 3. stran
            </Button>
          </div>
        </>
      )
    }
    return (
      <>
        <div className="mb-2 mt-2 text-center text-base font-bold">
          Ověření práv 3. stran ke všem dotčeným LV
        </div>
        <div className="mb-2 mt-2 text-center text-gray-400">
          Tato funkce umožňuje hromadně ověřit práva 3. stran k jednotlivým LV.
          Lorem ipsum At vero eos et accusamus et iusto odio dignissimos ducimus
          qui blanditiis praesentium voluptatum deleniti atque corrupti quos
          dolores et quas molestias excepturi.
        </div>
        <div>
          <PricePopconfirm
            onConfirm={fetchData}
            itemPrice={pricelist.EPO_RESTRICTION_CHECK}
            itemCount={1}
            message={`Ověření práv 3. stran pro dotčená LV je zpoplatněno ${pricelist.EPO_RESTRICTION_CHECK} Kr.`}
          >
            <Button loading={loading} type="primary">
              Vyhledat LV s právy 3. stran
            </Button>
          </PricePopconfirm>
        </div>
      </>
    )
  }
