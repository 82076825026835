import React, { FunctionComponent } from 'react'

import { InlineBadge } from '../InlineBadge/InlineBadge'

export enum RestrictionType {
  Execution = 'exekuce',
  ExecutionPartly = 'exekuceCastecne',
  Insolvency = 'insolvence',
  InsolvencyPartly = 'insolvenceCastecne',
  InvoluntaryLien = 'zastavyNedobrovolne',
  InvoluntaryLienPartly = 'zastavyNedobrovolneCastecne',
  VoluntaryLien = 'zastavySmluvni',
  VoluntaryLienPartly = 'zastavySmluvniCastecne',
  PreemptionRight = 'predkupniPravo',
  PreemptionRightPartly = 'predkupniPravoCastecne',
  NoRestriction = 'bezOmezeni',
  NoRestrictionLong = 'bezOmezeniVlastnickychPrav',
  Encumbrance = 'vecneBremeno',
}

export interface RestrictionBadgeProps {
  show?: boolean | null
  type: RestrictionType
  hoverEffect?: boolean
  className?: string
  onClick?: () => void
}

export const restrictionMapping = {
  [RestrictionType.Execution]: {
    label: 'Exekuce',
    style: 'bg-red-500 text-white',
  },
  [RestrictionType.ExecutionPartly]: {
    label: 'Exekuce (částečná)',
    style: 'bg-red-500 text-white',
  },
  [RestrictionType.Insolvency]: {
    label: 'Insolvence',
    style: 'bg-red-500 text-white',
  },
  [RestrictionType.InsolvencyPartly]: {
    label: 'Insolvence (částečná)',
    style: 'bg-red-500 text-white',
  },
  [RestrictionType.InvoluntaryLien]: {
    label: 'Zástavní právo nedobrovolné',
    style: 'bg-red-500 text-white',
  },
  [RestrictionType.InvoluntaryLienPartly]: {
    label: 'Zástava nedobrovolná (částečná)',
    style: 'bg-red-500 text-white',
  },
  [RestrictionType.VoluntaryLien]: {
    label: 'Zástava smluvní',
    style: 'bg-amber-500 text-white',
  },
  [RestrictionType.VoluntaryLienPartly]: {
    label: 'Zástava smluvní (částečná)',
    style: 'bg-amber-500 text-white',
  },
  [RestrictionType.PreemptionRight]: {
    label: 'Předkupní právo',
    style: 'bg-amber-500 text-white',
  },
  [RestrictionType.PreemptionRightPartly]: {
    label: 'Předkupní právo (částečné)',
    style: 'bg-amber-500 text-white',
  },
  [RestrictionType.NoRestriction]: {
    label: 'Bez omezení',
    style: 'border text-gray-400',
  },
  [RestrictionType.NoRestrictionLong]: {
    label: 'Bez omezení vlastnických práv',
    style: 'border text-lime-900 border-lime-400 bg-lime-50',
  },
  [RestrictionType.Encumbrance]: {
    label: 'Věcné břemeno',
    style: 'outline outline-1',
  },
}

export const RestrictionBadge: FunctionComponent<RestrictionBadgeProps> = ({
  show = true,
  type,
  hoverEffect,
  className,
  onClick,
}) => {
  const { label, style = '' } = restrictionMapping[type] || {}
  if (!show || !label) return null

  const labelStyle =
    (className || style) +
    (hoverEffect ? ' hover:opacity-80 cursor-pointer' : '')

  return <InlineBadge label={label} className={labelStyle} onClick={onClick} />
}
