import React, { FunctionComponent } from 'react'

import { SUBJEKT, VLASTNICI_JINI_OPRAVNENI } from '../../../common/cuzkLvTypes'
import { safeFind, safeMap } from '../../../common/pdfHelpers'
import { CuzkLvHtmlIdentifikatorFragment } from '../CuzkLvHtmlIdentifikatorFragment/CuzkLvHtmlIdentifikatorFragment'
import { CuzkLvHtmlOpravSubjektFragment } from '../CuzkLvHtmlOpravSubjektFragment/CuzkLvHtmlOpravSubjektFragment'
import { CuzkLvHtmlPodilFragment } from '../CuzkLvHtmlPodilFragment/CuzkLvHtmlPodilFragment'
import { CuzkLvHtmlVlastniciGrafModalButton } from '../CuzkLvHtmlVlastniciGrafModalButton/CuzkLvHtmlVlastniciGrafModalButton'

export interface CuzkLvHtmlSekceVlastniciFragmentProps {
  vlastniciJiniOpravneni?: VLASTNICI_JINI_OPRAVNENI
  active?: boolean
}

export const CuzkLvHtmlSekceVlastniciFragment: FunctionComponent<CuzkLvHtmlSekceVlastniciFragmentProps> =
  props => {
    return (
      <>
        <div className="row">
          <div className="col-xs-8">
            A <i>Vlastník, jiný oprávněný</i>
          </div>
          <div className="col-xs-2">
            <i>Identifikátor</i>
          </div>
          <div className="col-xs-2 text-right">
            <i>Podíl</i>
          </div>
        </div>
        <hr className="mt-10 mb-0" />
        {safeMap(
          props.vlastniciJiniOpravneni?.TYP_VZTAHU,
          (TYP_VZTAHU, index) => (
            <div key={index} className="mb-40">
              <div className="row">
                &nbsp;&nbsp;
                {props.active &&
                TYP_VZTAHU?.TYP_VZTAHU === 'Vlastnické právo' ? (
                  <CuzkLvHtmlVlastniciGrafModalButton
                    SUBJEKT={TYP_VZTAHU?.SUBJEKTY?.SUBJEKT}
                  />
                ) : (
                  <i>{TYP_VZTAHU?.TYP_VZTAHU}</i>
                )}
              </div>
              {safeMap(
                TYP_VZTAHU?.SUBJEKTY?.SUBJEKT,
                (SUBJEKT: SUBJEKT, index: number) => (
                  <div className="row" key={index}>
                    <div className="col-xs-8 pr-30">
                      <div className="row">
                        <div className="col-xs-1">
                          <strong>
                            {SUBJEKT?.OPSUB_ZKRATKA?.char_os?.zkratka_alv}
                          </strong>
                        </div>
                        <div className="col-xs-11 bold">
                          <span className="pr-50">
                            <CuzkLvHtmlOpravSubjektFragment
                              opravSubjekt={SUBJEKT?.VLA_IDENT?.oprav_subjekt}
                              active={props.active}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-2 bold">
                      <CuzkLvHtmlIdentifikatorFragment
                        opravSubjekt={SUBJEKT?.OPSUB_IDENT}
                        vlaIdent={SUBJEKT?.VLA_IDENT?.oprav_subjekt}
                        active={props.active}
                      />
                    </div>
                    <div className="col-xs-2 bold text-right">
                      <CuzkLvHtmlPodilFragment
                        active={props.active}
                        podil={SUBJEKT?.PODIL}
                      />
                    </div>
                  </div>
                )
              )}
              {safeFind(
                TYP_VZTAHU?.SUBJEKTY?.SUBJEKT,
                SUBJEKT =>
                  SUBJEKT?.OPSUB_ZKRATKA?.char_os?.zkratka_alv === 'SJM'
              ) && (
                <div className="row">
                  <i>SJM = společné jmění manželů</i>
                </div>
              )}
            </div>
          )
        )}
      </>
    )
  }
