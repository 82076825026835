import { Button, Space, Spin } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { useAppSelector } from '../../../redux/hooks'
import { useToggleState } from '../../utils/useToggleState'
import { Day } from '../DateFormat/DateFormat'
import { Fade } from '../Fade/Fade'
import { PropertyDetailFetchError } from '../PropertyDetailFetchError/PropertyDetailFetchError'
import { PropertyDetailSidebarContainer } from '../PropertyDetailSidebarContainer/PropertyDetailSidebarContainer'
import {
  RestrictionBadge,
  RestrictionType,
} from '../RestrictionBadge/RestrictionBadge'

export interface PropertyDetailSidebarOwnershipRestrictionProps {
  propertyId: string
  isDrawer?: boolean
}

export const PropertyDetailSidebarOwnershipRestriction: FunctionComponent<PropertyDetailSidebarOwnershipRestrictionProps> =
  props => {
    const [open, handleOpen] = useToggleState(false)

    const crmDrmData = useAppSelector(state => state.property.crm.data)
    const { drm: drmDetail, lv: lvDetail } = useAppSelector(
      state => state.property
    )

    const omezeniVlPrav = useMemo(
      () =>
        [
          ...(lvDetail?.data?.nemovitosti?.jednotky || []),
          ...(lvDetail?.data?.nemovitosti?.budovy || []),
          ...(lvDetail?.data?.nemovitosti?.parcely || []),
        ].find(nemovitost => nemovitost.id === props.propertyId)?.omezeniVlPrav,
      [lvDetail?.data?.nemovitosti, props.propertyId]
    )

    const [encumbrance, noRestriction] = useMemo(() => {
      const hasEncumbrance = drmDetail?.data?.omezeniVlastnickehoPrava?.some(
        omezeni => omezeni?.omezeni?.startsWith('Věcné břemeno')
      )
      return [
        hasEncumbrance,
        !omezeniVlPrav?.exekuce &&
          !omezeniVlPrav?.insolvence &&
          !omezeniVlPrav?.zastavyNedobrovolne &&
          !omezeniVlPrav?.zastavySmluvni &&
          !omezeniVlPrav?.predkupniPravo &&
          !hasEncumbrance,
      ]
    }, [drmDetail?.data?.omezeniVlastnickehoPrava, omezeniVlPrav])

    if (crmDrmData === false) return null

    if (lvDetail.fetching || drmDetail.fetching)
      return (
        <PropertyDetailSidebarContainer narrow>
          <Spin>Omezení vlastnických práv...</Spin>
        </PropertyDetailSidebarContainer>
      )

    if (
      lvDetail.error ||
      drmDetail.error ||
      (!!drmDetail.data && !omezeniVlPrav)
    )
      return (
        <PropertyDetailFetchError description="Je nám líto, ale nepodařilo se ověřit omezení vlastnických práv." />
      )

    return (
      <PropertyDetailSidebarContainer narrow isDrawer={props.isDrawer}>
        <Space
          title="Kliknutím zobrazíte detaily omezení vlastnických práv."
          className="cursor-pointer flex-wrap"
          onClick={handleOpen}
        >
          <RestrictionBadge
            type={RestrictionType.Execution}
            show={!!omezeniVlPrav?.exekuce}
            hoverEffect
          />
          <RestrictionBadge
            type={RestrictionType.Insolvency}
            show={!!omezeniVlPrav?.insolvence}
            hoverEffect
          />
          <RestrictionBadge
            type={RestrictionType.InvoluntaryLien}
            show={!!omezeniVlPrav?.zastavyNedobrovolne}
            hoverEffect
          />
          <RestrictionBadge
            type={RestrictionType.VoluntaryLien}
            show={!!omezeniVlPrav?.zastavySmluvni}
            hoverEffect
          />
          <RestrictionBadge
            type={RestrictionType.PreemptionRight}
            show={!!omezeniVlPrav?.predkupniPravo}
            hoverEffect
          />
          <RestrictionBadge
            type={RestrictionType.Encumbrance}
            show={!!encumbrance}
            hoverEffect
          />
          <RestrictionBadge
            type={RestrictionType.NoRestrictionLong}
            show={!!noRestriction}
          />
        </Space>

        <Fade show={open} className="mt-2 pl-1 pr-2">
          {drmDetail?.data?.omezeniVlastnickehoPrava?.map((omezeni, index) => (
            <div key={index} className="flex justify-between mt-1">
              <span>{omezeni.omezeni}</span>
              <span className="font-normal text-gray-400 whitespace-nowrap pl-1">
                Platí od: <Day>{omezeni.platiOd}</Day>
              </span>
            </div>
          ))}

          <div className="text-right">
            <Button className="!p-0" type="link" onClick={handleOpen}>
              Skrýt detaily omezení
            </Button>
          </div>
        </Fade>
      </PropertyDetailSidebarContainer>
    )
  }
