import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Alert } from 'antd'
import React, { FunctionComponent, memo } from 'react'

export interface PropertyDetailFetchErrorProps {
  description?: string | React.ReactNode
  message?: string
}

export const PropertyDetailFetchError: FunctionComponent<PropertyDetailFetchErrorProps> =
  memo(props => {
    return (
      <Alert
        icon={<ExclamationCircleOutlined />}
        showIcon
        type="warning"
        description={
          props.description && (
            <span className="text-gray-500">{props.description}</span>
          )
        }
        message={props.message}
      />
    )
  })
