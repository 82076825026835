import { Button, message, Popconfirm } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import React, { FunctionComponent, useCallback } from 'react'

import { useDeletePostserviceRecipientByIdMutation } from '../../../graphql/generated'
import { PostServiceRecipient } from '../PostServiceRecipientListTableExpandedRow/PostServiceRecipientListTableExpandedRow'

export interface PostServiceRecipientListDeleteButtonProps {
  record: Partial<PostServiceRecipient> & { id: string }
  reexecuteQuery?: () => void
  buttonProps?: ButtonProps
  buttonText?: string
  title?: string
}

const okButtonProps = { danger: true }
const overlayInnerStyle = { width: '250px' }

export const PostServiceRecipientListDeleteButton: FunctionComponent<PostServiceRecipientListDeleteButtonProps> =
  props => {
    const [, deleteRecipient] = useDeletePostserviceRecipientByIdMutation()

    const removeRecipient = useCallback(async () => {
      const result = await deleteRecipient({ id: props.record.id })
      if (result.error) {
        message.error('Adresáta se nepodařilo odebrat.')
      } else {
        message.success('Adresát byl odebrán.')
      }
      props.reexecuteQuery?.()
    }, [deleteRecipient, props])

    return (
      <Popconfirm
        title={
          props.title ||
          `Opravdu chcete smazat adresáta ${props.record.firstname || ''} ${
            props.record.lastname || ''
          }?`
        }
        onConfirm={removeRecipient}
        okText="Smazat"
        cancelText="Zrušit"
        okButtonProps={okButtonProps}
        overlayInnerStyle={overlayInnerStyle}
      >
        <Button
          size="small"
          className="w-full"
          danger
          ghost
          {...props.buttonProps}
        >
          {props.buttonText || 'Smazat'}
        </Button>
      </Popconfirm>
    )
  }
