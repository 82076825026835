import { DownOutlined } from '@ant-design/icons'
import { Button, Divider, Dropdown } from 'antd'
import React, { FunctionComponent } from 'react'

import { clickTrigger } from '../../utils/layoutConst'
import { useConst } from '../../utils/useConst'
import { OneLineHelp } from '../OneLineHelp/OneLineHelp'

export interface CadastrePostVariablesDropdownProps {
  handleInsertText: (e: { key: string }) => void
}

const help = (
  <>
    <OneLineHelp
      classNameClose="w-44 max-h-6 overflow-hidden"
      classNameOpen="w-72"
    >
      <>
        Díky proměnným můžete vytvořit univerzální šablonu dopisu pro více
        adresátů. V tištěném dopise se proměnné nahradí skutečnými údaji
        subjektu. Proměnné přebírají i nastavené styly, dbejte však na to,
        abyste styl nastavili vždy na celou proměnnou, a ne pouze na její část.
        <div>
          Správné formátování:{' '}
          <span className="font-bold text-lime-400">[subjekt_jmeno]</span>
        </div>
        <div>
          Špatné formátování: [subj
          <span className="font-bold text-red-300">ekt_jmeno]</span>
        </div>
        V PDF náhledu dopisu si můžete práci s proměnnými vyzkoušet.
      </>
    </OneLineHelp>
    <Divider style={{ margin: '8px 0 0 0' }} />
  </>
)

export const CadastrePostVariablesDropdown: FunctionComponent<CadastrePostVariablesDropdownProps> =
  props => {
    const items = useConst({
      items: [
        {
          label: help,
          key: 'group',
          type: 'group',
          children: [],
        },
        {
          label: 'Subjekt',
          key: '[subjekt]',
          children: [
            {
              key: '[subjekt_uvedeni] ',
              label: 'Vážený/á pane/paní',
              onClick: props.handleInsertText,
            },
            {
              key: '[subjekt_oslovení] ',
              label: 'Oslovení',
              onClick: props.handleInsertText,
            },
            {
              key: '[subjekt_titul_pred] ',
              label: 'Titul před',
              onClick: props.handleInsertText,
            },
            {
              key: '[subjekt_titul_za] ',
              label: 'Titul za',
              onClick: props.handleInsertText,
            },
            {
              key: '[subjekt_jmeno] ',
              label: 'Křestní jméno',
              onClick: props.handleInsertText,
            },
            {
              key: '[subjekt_prijmeni] ',
              label: 'Příjmení',
              onClick: props.handleInsertText,
            },
            {
              key: '[subjekt_nazev] ',
              label: 'Celé jméno / název',
              onClick: props.handleInsertText,
            },
          ],
        },
        {
          label: 'Adresa',
          key: '[adresa]',
          children: [
            {
              key: '[adresa_ulice_nazev] ',
              label: 'Název ulice',
              onClick: props.handleInsertText,
            },
            {
              key: '[adresa_ulice] ',
              label: 'Ulice s číslem',
              onClick: props.handleInsertText,
            },
            {
              key: '[adresa_obec] ',
              label: 'Název obce',
              onClick: props.handleInsertText,
            },
            {
              key: '[adresa_psc] ',
              label: 'PSČ',
              onClick: props.handleInsertText,
            },
            {
              key: '[adresa_okres] ',
              label: 'Okres',
              onClick: props.handleInsertText,
            },
            {
              key: '[adresa_kompletní] ',
              label: 'Celá adresa',
              onClick: props.handleInsertText,
            },
          ],
        },
        {
          label: 'Nemovitost',
          key: '[nemovitost]',
          children: [
            {
              key: '[nemovitost_typ] ',
              label: 'Typ',
              onClick: props.handleInsertText,
            },
            {
              key: '[nemovitost_název] ',
              label: 'Označení',
              onClick: props.handleInsertText,
            },
          ],
        },
      ],
    })

    return (
      <Dropdown menu={items} trigger={clickTrigger}>
        <Button type="primary" ghost>
          Vložit proměnnou
          <DownOutlined className="ml-2" />
        </Button>
      </Dropdown>
    )
  }
