import { Typography } from 'antd'
import React, { FunctionComponent } from 'react'

import { useAppSelector } from '../../../redux/hooks'
import { PageLayoutTabContainer } from '../PageLayoutTabContainer/PageLayoutTabContainer'
import { PropertyDetailFetchError } from '../PropertyDetailFetchError/PropertyDetailFetchError'
import { PropertyDetailRisyTable } from '../PropertyDetailRisyTable/PropertyDetailRisyTable'

const { Title } = Typography

export const PropertyDetailRisy: FunctionComponent = () => {
  const risy = useAppSelector(state => state.property.risy.data)
  const error = useAppSelector(state => state.property.risy.error)

  if (error)
    return (
      <PageLayoutTabContainer>
        <PropertyDetailFetchError
          description="Je nám líto, ale nepodařilo se načíst informace o obci."
          message="Informace o obci nejsou dostupné."
        />
      </PageLayoutTabContainer>
    )

  return (
    <PageLayoutTabContainer>
      <div className="grid grid-cols-2 gap-8">
        <div className="w-full">
          <div className="mb-8">
            <Title level={4}>Informace o obci</Title>
            <PropertyDetailRisyTable dataSource={risy?.city} />
          </div>
          <div>
            <Title level={4}>Technická vybavenost obce</Title>
            <PropertyDetailRisyTable
              showBoolean
              dataSource={risy?.civicAmenities}
            />
          </div>
        </div>
        <div className="w-full">
          <Title level={4}>Vzdělání</Title>
          <PropertyDetailRisyTable dataSource={risy?.education} />
        </div>
        <div className="w-full">
          <Title level={4}>Sociální oblast</Title>
          <PropertyDetailRisyTable dataSource={risy?.socialSector} />
        </div>
      </div>
    </PageLayoutTabContainer>
  )
}
