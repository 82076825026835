import { Switch } from 'antd'
import React, { FunctionComponent } from 'react'

import { useDeclension } from '../../utils/useDeclension'

export interface PropertyDetailSubjectsTableExpandedRowLocaleProps {
  involvedCount: number
  showHistory: boolean
  setLocalHistory: (value: boolean) => void
}

export const PropertyDetailSubjectsTableExpandedRowLocale: FunctionComponent<PropertyDetailSubjectsTableExpandedRowLocaleProps> =
  props => {
    const decline = useDeclension()

    return (
      <div className="my-8">
        <div className="mb-2 text-gray-400">
          K dnešnímu dni již nejsou evidovány žádné platné vazby.
        </div>

        <div className="flex items-center justify-center space-x-2">
          <span>
            Můžete si zobrazit{' '}
            {decline(props.involvedCount, 'historical', true)}{' '}
            {decline(props.involvedCount, 'record')}
            {': '}
          </span>
          <Switch
            size="small"
            checked={props.showHistory}
            onChange={props.setLocalHistory}
          />
        </div>
      </div>
    )
  }
