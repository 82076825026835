import { Button, Input, message } from 'antd'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  useCreateLabelMutation,
  useEditLabelMutation,
} from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import {
  ColorPalleteDeafultOption,
  ColorPalleteKey,
} from '../../utils/collorPallete'
import {
  IconPalleteDeafultOption,
  IconPalleteKey,
} from '../../utils/iconPallete'
import { LabelSettingButton } from '../LabelSettingButton/LabelSettingButton'

export interface AddNewLabelProps {
  refetch?: () => void
  initialLabel?: {
    id: string
    name: string
    description?: string
    iconKey?: IconPalleteKey
    colorKey?: ColorPalleteKey
  }
}

export const AddNewLabel: FunctionComponent<AddNewLabelProps> = props => {
  const tenantId = useAppSelector(state => state.myProfile.tenantId)
  const userId = useAppSelector(state => state.myProfile.userId)
  const [, createLabel] = useCreateLabelMutation()
  const [, editLabel] = useEditLabelMutation()

  const [colorKey, setColorKey] = useState<ColorPalleteKey>(
    props.initialLabel?.colorKey || ColorPalleteDeafultOption
  )
  const [iconKey, setIconKey] = useState<IconPalleteKey>(
    props.initialLabel?.iconKey || IconPalleteDeafultOption
  )
  const [nameValue, setNameValue] = useState<string>(
    props.initialLabel?.name || ''
  )
  const [descriptionValue, setDescriptionValue] = useState<string | undefined>(
    props.initialLabel?.description
  )

  useEffect(() => {
    if (props.initialLabel) {
      setNameValue(props.initialLabel.name)
      setDescriptionValue(props.initialLabel.description)
      setColorKey(props.initialLabel.colorKey as ColorPalleteKey)
      setIconKey(props.initialLabel.iconKey as IconPalleteKey)
    }
  }, [props.initialLabel])

  const onLabelNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNameValue(event.target.value)
    },
    []
  )

  const onLabelDescriptionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDescriptionValue(event.target.value)
    },
    []
  )

  const onLabelStyleChange = useCallback(
    ({
      colorKey,
      iconKey,
    }: {
      colorKey?: ColorPalleteKey
      iconKey?: IconPalleteKey
    }) => {
      setColorKey(colorKey || ColorPalleteDeafultOption)
      setIconKey(iconKey || IconPalleteDeafultOption)
    },
    []
  )

  const createOrUpdateLabel = useCallback(() => {
    if (props.initialLabel) {
      editLabel({
        id: props.initialLabel.id,
        name: nameValue,
        description: descriptionValue,
        colorKey: colorKey,
        iconKey: iconKey,
      }).then(() => {
        message.success('Štítek byl úspěšně aktualizován')
        props.refetch?.()
      })
    } else {
      createLabel({
        input: {
          label: {
            name: nameValue,
            accountId: tenantId,
            accountUserId: userId,
            description: descriptionValue,
            colorKey: colorKey,
            iconKey: iconKey,
          },
        },
      }).then(() => {
        message.success('Štítek byl úspěšně vytvořen')
        props.refetch?.()
      })
    }

    setNameValue('')
    setDescriptionValue(undefined)
    onLabelStyleChange({
      colorKey: ColorPalleteDeafultOption,
      iconKey: IconPalleteDeafultOption,
    })
  }, [
    nameValue,
    tenantId,
    userId,
    descriptionValue,
    colorKey,
    iconKey,
    props,
    onLabelStyleChange,
    editLabel,
    createLabel,
  ])

  const popoverProps = useMemo(
    () => ({
      destroyTooltipOnHide: true,
      getTooltipContainer() {
        return (
          document.getElementById('selectLabelDropDownRender') ?? document.body
        )
      },
    }),
    []
  )

  return (
    <div className="flex flex-auto space-x-2 m-2 mt-4">
      <Input
        placeholder="Název štítku"
        value={nameValue}
        onChange={onLabelNameChange}
        onFocus={console.log}
        autoFocus
        className="focus:w-[200%]"
      />
      <Input
        placeholder="Popis štítku"
        value={descriptionValue}
        onChange={onLabelDescriptionChange}
        className="focus:w-[200%]"
      />
      <LabelSettingButton
        labelName={nameValue}
        labelDescription={descriptionValue}
        pickedColor={colorKey}
        pickedIcon={iconKey}
        popoverProps={popoverProps}
        onStyleChange={onLabelStyleChange}
      />

      <Button
        disabled={!nameValue}
        type="primary"
        ghost
        onClick={createOrUpdateLabel}
      >
        {props.initialLabel ? 'Upravit' : 'Vytvořit'}
      </Button>
    </div>
  )
}
