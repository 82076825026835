export type IconPalleteKey = keyof typeof IconPalleteOptions
export const IconPalleteDeafultOption: IconPalleteKey = 'g0'

export const IconPalleteOptions = {
  g0: '',
  g1: 'teenyicons:bell-outline',
  g2: 'teenyicons:alarm-outline',
  g3: 'teenyicons:history-solid',
  g4: 'teenyicons:anti-clockwise-outline',
  g5: 'teenyicons:appointments-outline',
  g6: 'teenyicons:loop-outline',

  r0: 'teenyicons:archive-outline',
  r1: 'teenyicons:folder-outline',
  r2: 'teenyicons:layers-outline',
  r3: 'teenyicons:text-document-outline',
  r4: 'teenyicons:certificate-outline',
  r5: 'teenyicons:float-left-outline',
  r6: 'teenyicons:align-text-justify-outline',

  y0: 'teenyicons:database-outline',
  y1: 'teenyicons:artboard-outline',
  y2: 'teenyicons:layers-intersect-outline',
  y3: 'teenyicons:area-chart-outline',
  y4: 'teenyicons:bar-chart-outline',
  y5: 'teenyicons:arrow-down-solid',
  y6: 'teenyicons:arrow-up-solid',

  l0: 'teenyicons:attach-solid',
  l1: 'teenyicons:phone-outline',
  l2: 'teenyicons:chat-typing-outline',
  l3: 'teenyicons:envelope-outline',
  l4: 'teenyicons:at-solid',
  l5: 'teenyicons:edit-outline',
  l6: 'teenyicons:scribble-solid',

  b0: 'teenyicons:image-alt-outline',
  b1: 'teenyicons:home-outline',
  b2: 'teenyicons:receipt-outline',
  b3: 'teenyicons:car-outline',
  b4: 'teenyicons:credit-card-outline',
  b5: 'teenyicons:screen-alt-outline',
  b6: 'teenyicons:euro-solid',

  e0: 'teenyicons:lock-outline',
  e1: 'teenyicons:unlock-outline',
  e2: 'teenyicons:key-solid',
  e3: 'teenyicons:thumbtack-outline',
  e4: 'teenyicons:search-outline',
  e5: 'teenyicons:exclamation-circle-outline',
  e6: 'teenyicons:denied-solid',

  c0: 'teenyicons:tick-circle-outline',
  c1: 'teenyicons:x-circle-outline',
  c2: 'teenyicons:minus-circle-outline',
  c3: 'teenyicons:plus-circle-outline',
  c4: 'teenyicons:star-outline',
  c5: 'teenyicons:heart-outline',
  c6: 'teenyicons:anchor-solid',

  p0: 'teenyicons:eye-outline',
  p1: 'teenyicons:user-outline',
  p2: 'teenyicons:users-outline',
  p3: 'teenyicons:alien-solid',
  p4: 'teenyicons:mood-flat-solid',
  p5: 'teenyicons:mood-smile-outline',
  p6: 'teenyicons:mood-sad-outline',
}
