import { Text, View } from '@react-pdf/renderer'
import { format } from 'date-fns'
import React, { FunctionComponent, useMemo } from 'react'

import { monitEnbPopis } from '../../../common/monitTypes'
import { czDateFormatDay } from '../../utils/dateFormat'
import { monitPdfCss } from '../../utils/monitPdfCss'
import { formatCurrencyNumber } from '../Money/Money'
import { PriceEstimatePdfPropertyRow as Row } from '../PriceEstimatePdfPropertyRow/PriceEstimatePdfPropertyRow'

export interface PriceEstimatePdfPropertyFragmentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

export const PriceEstimatePdfPropertyFragment: FunctionComponent<PriceEstimatePdfPropertyFragmentProps> =
  ({ data }) => {
    const enbLabel = monitEnbPopis[data.enbTrida as keyof typeof monitEnbPopis]

    const today = useMemo(
      () =>
        format(
          new Date(data?.datum ? data.datum : new Date()),
          czDateFormatDay
        ),
      [data.datum]
    )
    return (
      <View>
        <Text style={monitPdfCss.tableHeader}>Zadané parametry</Text>

        <Row data={today}>Odhad k datu:</Row>
        <Row data={data.typPozemku}>Typ pozemku:</Row>
        <Row data={data.typObjektu}>Typ objektu:</Row>
        <Row data={formatCurrencyNumber(data.pl_celkova, 0)} suffix=" m²">
          Celková plocha:
        </Row>
        <Row data={formatCurrencyNumber(data.pl_uzitna, 0)} suffix=" m²">
          Plocha užitná:
        </Row>
        <Row data={formatCurrencyNumber(data.pl_pozemku, 0)} suffix=" m²">
          Plocha pozemku:
        </Row>
        <Row data={data.pl_balkonu} suffix=" m²">
          Plocha balkónu:
        </Row>
        <Row data={data.pocet_bytu}>Počet bytů:</Row>
        <Row data={data.nadzemni_podlazi}>Nadzemních podlaží:</Row>
        <Row data={data.enbTrida} suffix={enbLabel && ` (${enbLabel})`}>
          Energetický štítek:
        </Row>
        <Row data={data.reduceStav}>Stav nemovitosti:</Row>
        <Row data={data.zarizeno}>Zařízeno:</Row>
        <Row data={data.objectFlags}>Vybavení:</Row>
        <Row data={data.reducedFlags}>Doplňky:</Row>
        <Row data={data.prislusenstviFl}>Příslušenství:</Row>
        <Row data={data.polohaDomu}>Poloha domu:</Row>
        <Row data={data.druhKonstrukce}>Druh konstrukce:</Row>
        <Row data={data.dispoziceMistnosti}>Dispozice místností:</Row>
        <Row data={data.podlaziUmisteni}>Přízemí:</Row>
        <Row data={data.druhVlastnictvi}>Druh vlastnictví:</Row>
        <Row data={data.umisteniNemovitosti}>Umístění:</Row>
      </View>
    )
  }
