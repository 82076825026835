import { Button, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { ExpandableConfig } from 'antd/es/table/interface'
import React, { FunctionComponent, useMemo } from 'react'

import { SubjectsInPostServiceLetter } from '../../../common/postServiceTypes'
import { useAppSelector } from '../../../redux/hooks'
import { propertyDetailTableProps } from '../../utils/layoutConst'
import { useHandleExpandRow } from '../../utils/useHandleExpandRow'
import { SubjectTableCellRecipientLetterTableExpandedRow } from '../SubjectTableCellRecipientLetterTableExpandedRow/SubjectTableCellRecipientLetterTableExpandedRow'

export interface SubjectTableCellRecipientLetterTableProps {
  postServiceLetter: SubjectsInPostServiceLetter[]
  closeModal: () => void
}

const tableId = 'subject-recipient-letter-table'

export const SubjectTableCellRecipientLetterTable: FunctionComponent<SubjectTableCellRecipientLetterTableProps> =
  props => {
    const handleExpandRow = useHandleExpandRow(tableId)
    const expandedRowKeys = useAppSelector(
      state => state.expandedRow.tables?.[tableId]
    )

    const columns: ColumnsType<SubjectsInPostServiceLetter> = useMemo(
      () => [
        {
          dataIndex: 'name',
          className: 'group-last:!border-b-0 !p-1',
        },
        {
          dataIndex: 'sentAt',
          className:
            'w-24 !text-right text-gray-400 group-last:!border-b-0 !p-1',
          render: sentAt => (sentAt ? 'Odesláno' : 'K odeslání'),
        },
        {
          dataIndex: 'id',
          className: 'w-28 !text-right group-last:!border-b-0 !p-1',
          render: (_, record) => (
            <Button
              id={record.id}
              type="link"
              size="small"
              onClick={() => handleExpandRow(record.id)}
            >
              {expandedRowKeys?.includes(record.id)
                ? 'Skrýt info'
                : 'Zobrazit info'}
            </Button>
          ),
        },
      ],
      [expandedRowKeys, handleExpandRow]
    )

    const expandable = useMemo<
      ExpandableConfig<SubjectsInPostServiceLetter>
    >(() => {
      return {
        expandedRowKeys,
        showExpandColumn: false,
        expandedRowRender: record => (
          <SubjectTableCellRecipientLetterTableExpandedRow
            closeModal={props.closeModal}
            record={record}
          />
        ),
      }
    }, [expandedRowKeys, props.closeModal])

    return (
      <Table
        columns={columns}
        dataSource={props.postServiceLetter}
        showHeader={false}
        rowClassName="group"
        rowKey="id"
        expandable={expandable}
        {...propertyDetailTableProps}
      />
    )
  }
