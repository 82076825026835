import { message } from 'antd'
import axios from 'axios'
import React, { ReactElement } from 'react'

import { AddressApiResponse } from '../../common/addressTypes'
import { requestAddressPath } from './paths'

export type AddressOptions = { value: string; label: ReactElement }[]
type ResponseData = { kod: number; formatovanaAdresa: string }

const shouldFetch = (value: string) => {
  const hasThreeChars = value.length >= 3
  const hasDigit = /\d/.test(value)

  return hasThreeChars && hasDigit
}

const highlightMatch = (text: string, queryWords: string[]) => {
  const regex = new RegExp(`(${queryWords.join('|')})`, 'gi')
  const parts = text.split(regex)

  return parts.map((part, index) =>
    regex.test(part) ? (
      <span key={index} className="font-semibold bg-green-50">
        {part}
      </span>
    ) : (
      <span key={index}>{part}</span>
    )
  )
}

const processAddress = (formatovanaAdresa: string, queryWords: string[]) => {
  const parts = formatovanaAdresa.split(',').map(part => part.trim())
  while (parts.length < 3) {
    parts.push('')
  }
  return parts.slice(0, 3).map(part => highlightMatch(part, queryWords))
}

export const handleSearchAddress = async (
  value: string,
  token: string
): Promise<AddressOptions> => {
  if (!shouldFetch(value)) {
    return []
  }

  try {
    const response = await axios.get(
      'https://data.regesta.cz/DrmApi/api/v1.0/Adresy/Autocomplete',
      {
        params: {
          access_token: token,
          q: value,
        },
      }
    )

    const data: ResponseData[] = response.data
    const queryWords = value
      .toLowerCase()
      .split(' ')
      .filter(word => word)

    return data.map(item => {
      const { kod, formatovanaAdresa } = item
      const [street, city, postalCode] = processAddress(
        formatovanaAdresa,
        queryWords
      )

      return {
        value: kod.toString(),
        label: (
          <div className="flex space-x-2 w-full max-w-xl">
            <div className="w-full">{street}</div>
            <div className="w-full truncate">{city}</div>
            <div className="w-20 text-right">{postalCode}</div>
          </div>
        ),
      }
    })
  } catch (error) {
    message.error('Vyhledávání adresy selhalo.')
    return []
  }
}
export const fetchBuildingId = async (
  addressId: string,
  token: string
): Promise<string | null> => {
  try {
    const response = await axios.get<AddressApiResponse>(
      `${requestAddressPath()}${token}/${addressId}`
    )

    const buildingId = response.data.budovaId
    if (!buildingId) throw new Error('Budova nebyla nalezena')

    return buildingId
  } catch (error) {
    message.error('Nepodařilo se najít budovu s touto adresou.')
    return null
  }
}
