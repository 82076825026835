import { Text, View } from '@react-pdf/renderer'
import React, { FunctionComponent } from 'react'

import { SmsItem, StatisticalDataItem } from '../../../common/risyTypes'
import { monitPdfCss } from '../../utils/monitPdfCss'
import { formatCurrencyNumber } from '../Money/Money'

export interface PriceEstimatePdfTableRowProps {
  dataItem?: Partial<StatisticalDataItem>
  smsItem?: Partial<SmsItem>
  formatedNumber?: boolean
  boolean?: boolean
  suffix?: string
  prefix?: string
}

export const PriceEstimatePdfTableRow: FunctionComponent<PriceEstimatePdfTableRowProps> =
  props => {
    if (!props.dataItem && !props.smsItem) return null
    if (props.dataItem && !props.dataItem?.keyName) return null
    if (props.smsItem && !props.smsItem?.question) return null

    const item = props.dataItem
      ? {
          ...props.dataItem,
          title: props.dataItem?.keyName,
          value: props.formatedNumber
            ? formatCurrencyNumber(props.dataItem?.value, 0)
            : props.boolean
            ? props.dataItem?.value === '1'
              ? 'ANO'
              : 'NE'
            : props.dataItem?.value || '0',
        }
      : {
          ...props.smsItem,
          title: props.smsItem?.question,
          year: '',
          value: props.formatedNumber
            ? formatCurrencyNumber(props.smsItem?.value, 0)
            : props.smsItem?.value || '0',
        }

    return (
      <View style={monitPdfCss.tableRow}>
        <Text style={monitPdfCss.tableCellHeader}>{item.title}</Text>
        <Text style={monitPdfCss.tableCellYear}>
          {item?.year ? 'k r. ' + item?.year : ''}
        </Text>
        <Text style={monitPdfCss.tableCellValue}>
          {props.prefix}
          {item.value}
          {props.suffix}
        </Text>
      </View>
    )
  }
