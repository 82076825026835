import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import React, { FunctionComponent, useMemo } from 'react'

import openSansBold from '../../../common/fonts/OpenSans-Bold.ttf'
import openSansItalic from '../../../common/fonts/OpenSans-Italic.ttf'
import openSans from '../../../common/fonts/OpenSans-Regular.ttf'
import { pdfStyles } from '../../../common/pdfHelpers'
import { day, formatDateInText, second } from '../../utils/dateFormat'
import { CeeDetailPdfItem as Item } from '../CeeDetailPdfItem/CeeDetailPdfItem'
import { CeeMonitorItem } from '../CeeMonitorTableExpandedRow/CeeMonitorTableExpandedRow'

export interface CeeDetailPdfContentProps {
  data?: CeeMonitorItem
}

const emptyCell = <Text style={{ color: 'gray' }}>{'- - -'}</Text>
const styles = StyleSheet.create({
  page: pdfStyles.page,
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  header: {
    fontSize: 8,
    marginBottom: 10,
    textAlign: 'center',
    color: 'grey',
    flexWrap: 'nowrap',
  },
  title: {
    fontSize: 16,
    fontStyle: 'bold',
    marginBottom: 10,
    padding: 10,
    textAlign: 'center',
    backgroundColor: '#53afb0',
    color: 'white',
  },
  sectionTitle: {
    fontSize: 12,
    textAlign: 'center',
    fontStyle: 'bold',
    marginTop: 15,
    textTransform: 'uppercase',
  },
  createDate: {
    fontSize: 10,
    color: 'gray',
    textAlign: 'center',
    marginBottom: 20,
  },

  informationRow: {
    fontSize: 10,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: 10,
    padding: 2,
  },
  amountContainer: {
    fontSize: 10,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: 4,
    gap: 0,
  },
  divider: { height: 15 },
  amountCell: {
    width: '50%',
    fontStyle: 'bold',
    backgroundColor: '#f5f5f5',
    padding: 7,
    paddingVertical: 10,
  },
  amountTitle: {
    width: '50%',
    textAlign: 'right',
    padding: 5,
    paddingVertical: 10,
  },
  leftText: { width: '50%', textAlign: 'right' },
  rightText: { width: '50%', fontStyle: 'bold' },
})

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: openSans },
    { src: openSansBold, fontStyle: 'bold' },
    { src: openSansItalic, fontStyle: 'italic' },
  ],
})

export const CeeDetailPdfContent: FunctionComponent<CeeDetailPdfContentProps> =
  props => {
    const data = props.data
    const detail = data?.ceeDetails?.nodes?.[0]
    const office = data?.ceeOfficeByExecutorCode

    const nonMonetaryObligation = detail?.nepenezitaPovinost ? (
      <Text>{detail?.nepenezitaPovinost}</Text>
    ) : (
      emptyCell
    )

    const regularPayment = useMemo(() => {
      if (!detail?.pravidelnaSplatka) return emptyCell
      const parseSemicolon = detail.pravidelnaSplatka.split('; ')
      const parseSpaceComa = detail.pravidelnaSplatka.split(', ')
      const parsedText =
        parseSemicolon.length > 2
          ? parseSemicolon
          : parseSpaceComa.length > 2
          ? parseSpaceComa
          : [detail.pravidelnaSplatka]

      return parsedText.map((item: string, index: number) => (
        <Text
          key={index}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 4,
          }}
        >
          {item.trim()}
        </Text>
      ))
    }, [detail?.pravidelnaSplatka])

    if (!props.data) return <Document />
    return (
      <Page size="A4" style={styles.page}>
        <Text style={styles.header} fixed>
          Vytvořeno v REGESTA DATA 2.0
        </Text>
        <Text style={styles.title}>Centrální evidence exekucí</Text>

        <Text style={styles.sectionTitle}>
          {formatDateInText(detail?.titul, 'DOKUMENT NAŘÍZENÍ EXEKUCE')}
        </Text>
        <Text style={styles.createDate}>
          Výpis ke dni {second(data?.createdAt)}
        </Text>
        <Item title="Jméno povinné osoby">{data?.fullname}</Item>
        <Item title="Datum narození" conditional>
          {day(data?.birthDate)}
        </Item>
        <Item title="Rodné číslo" conditional>
          {data?.rc}
        </Item>
        <Item title="IČO" conditional>
          {data?.ico}
        </Item>
        <Item title="Adresa">{data?.address}</Item>

        <View style={styles.divider} />

        <Item title="Spisová značka exekutorského úřadu">{data?.spzn}</Item>
        <Item title="Jméno úřadu">{detail?.exekutorskyUrad}</Item>
        <Item title="Adresa úřadu">{office?.address}</Item>
        <Item title="Telefon úřadu" conditional>
          {office?.phone}
        </Item>
        <Item title="E-mail úřadu" conditional>
          {office?.email}
        </Item>
        <Item title="Web úřadu" conditional>
          {office?.web}
        </Item>
        <Item title="Datová schránka úřadu" conditional>
          {office?.databox}
        </Item>

        <View style={styles.divider} />

        <Item title="Označení soudu, který nařídil exekuci">
          {detail?.soud}
        </Item>
        <Item title="Spisová značka soudu, který nařídil exekuci">
          {detail?.spznSoudu}
        </Item>
        {/*     
        <Item title="Datum vydání usnesení">2017-08-21</Item>
        <Item title="Datum vložení">2017-10-07</Item> */}
        <Item title="Datum založení záznamu">{detail?.datum}</Item>

        <View style={styles.divider} />

        <View style={styles.informationRow}>
          <Text style={styles.leftText} fixed>
            Pravidelná splátka
          </Text>
          <View style={styles.rightText}>{regularPayment}</View>
        </View>
        <Item title="Nepeněžitá povinnost">{nonMonetaryObligation}</Item>
        <View style={styles.amountContainer}>
          <Text style={styles.amountTitle}>Peněžitá povinnost</Text>
          <Text style={styles.amountCell}>{detail?.castka || 0} Kč</Text>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    )
  }
