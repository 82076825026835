import { Tooltip } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { Kanal } from '../../../common/watchdogTypes'

export interface WatchdogPropertyTableChannelTooltipProps {
  channel?: Kanal
}

export const WatchdogPropertyTableChannelTooltip: FunctionComponent<WatchdogPropertyTableChannelTooltipProps> =
  ({ channel }) => {
    const title = useMemo(
      () => (
        <div className="grid grid-cols-2 gap-x-2">
          <div>Název kanálu:</div>
          <div className="text-right">{channel?.nazev || ''}</div>

          <div>E-mail:</div>
          <div className="text-right">{channel?.emaily || ''}</div>

          <div>Frekvence notifikací:</div>
          <div className="text-right">{channel?.intervalObesilani} dní</div>

          <div>Prázdné notifikace:</div>
          <div className="text-right">
            {channel?.prazdnyEmail ? 'Zasílat' : 'Nezasílat'}
          </div>
        </div>
      ),
      [channel]
    )

    if (!channel) return <span className="text-gray-400">- nepřiřazen -</span>

    return (
      <Tooltip
        overlayInnerStyle={{ width: 'max-content' }}
        title={title}
        className="border-b border-dashed border-gray-800 cursor-help"
      >
        {channel?.nazev || ''}
      </Tooltip>
    )
  }
