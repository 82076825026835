import { Image } from 'antd'
import React, { FunctionComponent, useState } from 'react'

import { imageSkeleton } from '../../utils/layoutConst'
import { usePropertyDetailImage } from '../../utils/usePropertyDetailImage'
import { usePropertyImagePreviewProps } from '../../utils/usePropertyImagePreviewProps'
import { MAP_TYPE, MapTypeButton } from '../MapTypeButton/MapTypeButton'
import { PropertyDetailSidebarContainer } from '../PropertyDetailSidebarContainer/PropertyDetailSidebarContainer'

interface PropertyDetailSidebarImageProps {
  propertyId: string
}

export const PropertyDetailSidebarImage: FunctionComponent<PropertyDetailSidebarImageProps> =
  props => {
    const imageState = useState(MAP_TYPE.SW)
    const mapImage = usePropertyDetailImage(props.propertyId)
    const previewProps = usePropertyImagePreviewProps(imageState, mapImage)

    return (
      <PropertyDetailSidebarContainer narrow className="relative flex">
        <Image
          preview={previewProps}
          fallback={imageSkeleton}
          src={mapImage[imageState[0]].src || mapImage[MAP_TYPE.AERIAL].src}
          alt="Fotografie / mapa nemovitosti"
          className="rounded-sm object-cover !h-72 lg:!h-auto"
          wrapperClassName="w-full"
        />
        <div className="absolute bottom-2 left-2 p-2 flex space-x-2">
          <MapTypeButton
            disabled={!mapImage[MAP_TYPE.SW].src}
            type={MAP_TYPE.SW}
            imageState={imageState}
          />
          <MapTypeButton type={MAP_TYPE.AERIAL} imageState={imageState} />
          <MapTypeButton type={MAP_TYPE.MAP} imageState={imageState} />
        </div>
      </PropertyDetailSidebarContainer>
    )
  }
