import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Tour, TourProps } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { MonitoringTabType } from '../../../common/cuzkMonitorTypes'
import step2Src from '../../images/firefox_crlipsyek5.gif'
import step3Src from '../../images/firefox_jm1qgbkd6g.gif'
import step1Src from '../../images/firefox_otge2n9m2v.gif'
import { monitoringInputLimit } from '../../utils/layoutConst'
import { formatCurrencyNumber } from '../Money/Money'

export interface CuzkMonitorRcCreateTourProps {
  elementRef: { [key: string]: React.RefObject<null> }
  currentTab: MonitoringTabType
}

export const CuzkMonitorRcCreateTour: FunctionComponent<CuzkMonitorRcCreateTourProps> =
  props => {
    const [open, setOpen] = useState<boolean>(false)

    const handleOpen = useCallback(() => setOpen(prevOpen => !prevOpen), [])

    const steps = useMemo<TourProps['steps']>(
      () => [
        {
          title: (
            <div className="mt-2 pt-2 border-t">
              Hromadný import rodných čísel
            </div>
          ),
          description: (
            <div className="mb-2 pb-2 border-b">
              <div>
                Do tohoto pole můžete vykopírovat seznam rodných čísel, které se
                pokusíme automaticky naformátovat do příslušného tvaru.
              </div>
              <div>
                Hromadný import je omezen na
                {formatCurrencyNumber(monitoringInputLimit, 0)} znaků, to
                odpovídá zhruba{' '}
                {formatCurrencyNumber(monitoringInputLimit / 10, 0)} RČ bez
                přidaných poznámek.
              </div>
            </div>
          ),
          target:
            props.currentTab === MonitoringTabType.Rc
              ? props.elementRef.rcInput.current
              : props.elementRef.icoInput.current,
          nextButtonProps: { children: 'Formát a validace »' },
          cover: <img src={step1Src} />,
        },
        {
          title: (
            <div className="mt-2 pt-2 border-t">Formát a validace dat</div>
          ),
          description: (
            <div className="mb-2 pb-2 border-b">
              <div>
                Rodná čísla mohou být oddělena mezerou, čárkou či středníkem a
                mohou obsahovat lomítko.
              </div>
              <div>
                Všechna vložená rodná čísla prochází automatickou validací,
                pokud RČ neodpovídá stanoveným pravidlům, můžete jej ze seznamu
                odebrat ještě před samotným ověřením.
              </div>
              <div className="font-semibold mt-2">Př. hromadného vložení:</div>
              <div>123456/1234, 234567/2345, 987654/3210, ...</div>
              <div>123456/1234; 234567/2345; 987654/3210; ...</div>
              <div>1234561234 2345672345 9876543210 ...</div>
            </div>
          ),
          target:
            props.currentTab === MonitoringTabType.Rc
              ? props.elementRef.rcInput.current
              : props.elementRef.icoInput.current,
          cover: <img src={step2Src} />,
          prevButtonProps: { children: '« Import RČ' },
          nextButtonProps: { children: 'Vkládání poznámek »' },
        },
        {
          title: <div className="mt-2 pt-2 border-t">Vkládání poznámek</div>,
          description: (
            <div className="mb-2 pb-2 border-b">
              <div>
                Ke každému záznamu můžete přidat poznámku, která vám usnadní
                orientaci mezi mnoha záznamy. Poznámku oddělte od rodného čísla
                dvojtečkou (v poznámce dvojtečku již nelze použít).
              </div>
              <div className="font-semibold mt-2">Příklad:</div>
              <div>
                Petr Doubrava: 123456/1234, Jan Rittenburg: 234567/2345, ...
              </div>
              <div>
                Petr Doubrava: 1234561234; Jan Rittenburg: 2345672345; ...
              </div>
            </div>
          ),
          target:
            props.currentTab === MonitoringTabType.Rc
              ? props.elementRef.rcInput.current
              : props.elementRef.icoInput.current,
          cover: <img src={step3Src} />,
          prevButtonProps: { children: '« Formát a validace' },
          nextButtonProps: { children: 'Hromadný import IČ »' },
        },
        {
          title: <div>Hromadný import IČ</div>,
          description: (
            <div className="mb-2 pb-2 border-b">
              <div>
                Hromadné vkládání IČ (včetně poznámek) funguje obdobně jako u
                rodných čísel.
              </div>
            </div>
          ),
          target: props.elementRef.icoTab.current,
          prevButtonProps: { children: '« Vkládání poznámek' },
          nextButtonProps: { children: 'Import jmen a narození »' },
        },
        {
          title: <div>Hromadný import jmen a narození</div>,
          description: (
            <div className="mb-2 pb-2 border-b">
              <div>
                Pro tento typ vyhledávání bohužel není v tuto chvíli možné
                použít hromadný import.
              </div>
            </div>
          ),
          target: props.elementRef.nameTab.current,
          prevButtonProps: { children: '« Import jmen a narození' },
          nextButtonProps: { children: 'Ověření a monitoring »' },
        },
        {
          title: <div>Ověření a monitoring</div>,
          description: (
            <div className="mb-2 pb-2 border-b">
              <div>
                Před samotnou kontrolou si můžete zvolit, zda chcete ověřit RČ
                pouze jednorázově, nebo zda chcete subjekt pravidelně
                kontrolovat. Periodická kontrola se ukončí, pokud se subjekt
                vyskytne v evidenci ČÚZK, nebo kontrolu ručně vypnete.
              </div>
            </div>
          ),
          target:
            props.currentTab === MonitoringTabType.Rc
              ? props.elementRef.rcSubmit.current
              : props.elementRef.icoSubmit.current,
          prevButtonProps: { children: '« Vkládání poznámek' },
          nextButtonProps: { children: 'Zavřít' },
        },
      ],
      [
        props.currentTab,
        props.elementRef.icoInput,
        props.elementRef.icoSubmit,
        props.elementRef.icoTab,
        props.elementRef.nameTab,
        props.elementRef.rcInput,
        props.elementRef.rcSubmit,
      ]
    )

    return (
      <>
        <Button
          icon={<InfoCircleOutlined />}
          type="primary"
          ghost
          onClick={handleOpen}
        >
          Hromadný import
        </Button>
        <Tour open={open} onClose={handleOpen} steps={steps} />
      </>
    )
  }
