import { Breakpoint, Spin, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { Jednotka } from '../../../common/lvTypes'
import { stringSorter } from '../../utils/generalTableHelpers'
import { propertyDetailTableProps } from '../../utils/layoutConst'
import { useDeclension } from '../../utils/useDeclension'
import { usePropertiesLabels } from '../../utils/usePropertiesLabels'
import { watchdogUnitColumn } from '../../utils/watchdogHelpers'
import { PropertyDetailLink } from '../PropertyDetailLink/PropertyDetailLink'
import { PropertyDetailOwnershipRestrictionCell as RestrictionCell } from '../PropertyDetailOwnershipRestrictionCell/PropertyDetailOwnershipRestrictionCell'
import { PropertyTableItemLabel } from '../PropertyTableItemLabel/PropertyTableItemLabel'

export interface PropertyDetailLvUnitTableProps {
  jednotky: Jednotka[]
  propertyId: string
  showCity?: boolean
  hideMetrage?: boolean
  drawerTarget?: boolean
  target?: React.HTMLAttributeAnchorTarget
}

export const PropertyDetailLvUnitTable: FunctionComponent<PropertyDetailLvUnitTableProps> =
  props => {
    const decline = useDeclension()

    const unitsIds = useMemo(
      () => props.jednotky.map(jednotka => jednotka.id),
      [props.jednotky]
    )

    const {
      propertyLabels,
      refetch,
      loading: labelsLoading,
    } = usePropertiesLabels(unitsIds, PROPERTY_TYPES.UNIT)

    const unitRenderer = useCallback(
      (unit: string, record: Jednotka) => (
        <PropertyDetailLink
          text={unit}
          propertyId={record.id}
          propertyType={PROPERTY_TYPES.UNIT}
          isActive={record.id === props.propertyId}
          drawerTarget={props.drawerTarget}
          target={props.target}
        />
      ),
      [props.drawerTarget, props.propertyId, props.target]
    )

    const buildingRenderer = useCallback(
      (cislaDomovni: string, record: Jednotka) => {
        if (!cislaDomovni) {
          return null
        }
        return (
          <PropertyDetailLink
            text={cislaDomovni}
            propertyId={record?.budova?.id}
            propertyType={PROPERTY_TYPES.BUILDING}
            isActive={record?.budova?.id === props.propertyId}
            drawerTarget={props.drawerTarget}
            target={props.target}
          />
        )
      },
      [props.drawerTarget, props.propertyId, props.target]
    )

    const labelSorter = useCallback(
      (a: Jednotka, b: Jednotka) =>
        (
          propertyLabels?.[b.id]?.labelConnectionsByPropertyLabelId?.nodes?.[0]
            ?.labelByLabelId?.name || ''
        ).localeCompare(
          propertyLabels?.[a.id]?.labelConnectionsByPropertyLabelId?.nodes?.[0]
            ?.labelByLabelId?.name || ''
        ),
      [propertyLabels]
    )

    const labelRenderer = useCallback(
      (propertyId: string) =>
        labelsLoading ? (
          <Spin size="small" />
        ) : (
          <PropertyTableItemLabel
            propertyId={propertyId}
            propertyType={PROPERTY_TYPES.UNIT}
            refetch={refetch}
            propertyLabel={propertyLabels?.[propertyId]}
          />
        ),
      [labelsLoading, propertyLabels, refetch]
    )

    const columns: ColumnsType<Jednotka> = useMemo(
      () => [
        {
          title: 'Jednotka',
          dataIndex: 'zpusobVyuziti',
          className: 'max-w-xs',
          sorter: stringSorter('zpusobVyuziti'),
          render: unitRenderer,
        },
        {
          title: 'Štítky',
          dataIndex: 'id',
          className: 'max-w-xs',
          key: 'labels',
          sorter: labelSorter,
          render: labelRenderer,
        },
        {
          title: 'Část obce',
          dataIndex: ['budova', 'castObce'],
          className: 'w-32',
          responsive: props.showCity ? undefined : ['' as Breakpoint],
          sorter: (a, b) =>
            (a?.budova?.castObce?.nazev || '').localeCompare(
              b?.budova?.castObce?.nazev || ''
            ),
          render: castObce => castObce?.nazev || '',
        },
        {
          title: 'Číslo jednotky',
          dataIndex: 'cisloJednotky',
          ellipsis: true,
          className: 'w-32',
          render: cisloJednotky => cisloJednotky || 'Bez č.j.',
        },
        {
          title: 'V budově',
          dataIndex: ['budova', 'cislaDomovni'],
          className: 'w-32',
          ellipsis: true,
          render: buildingRenderer,
        },
        {
          title: 'Omezení vl. práv',
          dataIndex: 'omezeniVlPrav',
          render: omezeni => <RestrictionCell restriction={omezeni} />,
        },
        {
          dataIndex: 'placeholder',
          className: 'w-28',
          responsive: props.hideMetrage ? ['' as Breakpoint] : undefined,
        },
        watchdogUnitColumn,
      ],
      [
        unitRenderer,
        labelSorter,
        labelRenderer,
        props.showCity,
        props.hideMetrage,
        buildingRenderer,
      ]
    )

    const summary = useCallback(
      () =>
        props.jednotky?.length > 1 && (
          <Table.Summary.Row>
            <Table.Summary.Cell
              className="text-gray-400 !border-b-0 border-gray-100 border-t"
              index={1}
              colSpan={2}
            >
              Celkem: {decline(props.jednotky.length, 'unit', true)}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        ),
      [props.jednotky?.length, decline]
    )

    const rowClassName = useCallback(
      (record: Jednotka) =>
        record.id === props.propertyId ? 'text-gray-400' : '',
      [props.propertyId]
    )

    return (
      <Table
        dataSource={props.jednotky}
        columns={columns}
        rowClassName={rowClassName}
        rowKey="id"
        summary={summary}
        {...propertyDetailTableProps}
      />
    )
  }
