import { Alert } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { IncreasingNumber } from '../IncreasingNumber/IncreasingNumber'

export interface PropertyUseSelectionFooterCounterProps {
  selectedCount?: number
  totalCount?: number
  text?: string
}

export const PropertyUseSelectionFooterCounter: FunctionComponent<PropertyUseSelectionFooterCounterProps> =
  props => {
    const message = useMemo(
      () => (
        <div className={props.selectedCount ? '' : 'text-gray-500'}>
          <span className="mr-2">
            {props.text || 'Vybraných způsobů využití'}
            {': '}
            <IncreasingNumber
              className="font-bold"
              finalNumber={props.selectedCount || 0}
              decimal={0}
            />
            {' z '}
            {props.totalCount || 0}
          </span>
        </div>
      ),
      [props.selectedCount, props.text, props.totalCount]
    )

    return (
      <Alert
        className={
          props.selectedCount ? '!px-2 !py-1' : '!px-2 !py-1 grayscale'
        }
        message={message}
        type="success"
        showIcon={false}
      />
    )
  }
