import { ManOutlined, WomanOutlined } from '@ant-design/icons'
import React, { FunctionComponent } from 'react'

import { GenderType } from '../../../common/cuzkMonitorTypes'
import { useRcNumberData } from '../../utils/useRcNumberData'

export interface RcNumberDataProps {
  rcNumber?: string
  icon?: boolean
  className?: string
  getBirthdate?: boolean
}

export const RcNumberData: FunctionComponent<RcNumberDataProps> = props => {
  const { birthDate, age, gender } = useRcNumberData(props.rcNumber)

  if (!props.rcNumber) {
    return <div className="text-gray-400 w-full text-center">---</div>
  }

  if (props.getBirthdate) {
    return <div className={props.className || ''}>{birthDate}</div>
  }

  return (
    <div
      className={props.className || 'text-green-500'}
      title={`${gender}, ${age} let`}
    >
      {props.icon &&
        (gender === GenderType.W ? (
          <WomanOutlined className="mr-1 xl:mr-2" />
        ) : (
          <ManOutlined className="mr-1 xl:mr-2" />
        ))}
      <span className="hidden 2xl:inline">{gender}, </span>
      <span className="whitespace-nowrap">
        {age}
        <span className="hidden 2xl:inline"> let</span>
      </span>
    </div>
  )
}
