import React, { FunctionComponent } from 'react'

import { copyAddressButton } from '../../utils/propertyDetailHelper'
import { CopyButton } from '../CopyButton/CopyButton'
import { PriceEstimateLayoutLvDataRowProps } from '../PriceEstimateLayoutLvDataRow/PriceEstimateLayoutLvDataRow'
import { PriceEstimateLayoutLvDataRow as Item } from '../PriceEstimateLayoutLvDataRow/PriceEstimateLayoutLvDataRow'

export const PropertyDetailSidebarRowCopyableItem: FunctionComponent<
  PriceEstimateLayoutLvDataRowProps & { copyText?: string }
> = props => {
  return (
    <Item {...props}>
      <div className="flex justify-between items-center group">
        <span className="mr-2">{props.children}</span>
        <CopyButton
          copyText={
            typeof props.children === 'string'
              ? props.children
              : props.copyText || ''
          }
          {...copyAddressButton}
        />
      </div>
    </Item>
  )
}
