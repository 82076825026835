import { Button, message } from 'antd'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { Proceeding as ProceedingType } from '../../common/proceedingTypes'
import { Fade } from '../components/Fade/Fade'
import { PageLayout } from '../components/PageLayout/PageLayout'
import { ProceedingDetail } from '../components/ProceedingDetail/ProceedingDetail'
import { ProceedingSearchBar } from '../components/ProceedingSearchBar/ProceedingSearchBar'
import { proceedingDetailPath } from '../utils/paths'
import { getProceedingKey } from '../utils/proceedingDetailHelpers'
import { parseProceedingUrlQuery } from '../utils/proceedingSearchHelpers'
import { useConst } from '../utils/useConst'
import { useInitialFetchWatchdog } from '../utils/useFetchWatchdog'
import { useFormattedProceeding } from '../utils/useProceedingString'

export const Proceeding: FunctionComponent = () => {
  useInitialFetchWatchdog()
  const history = useHistory()
  const {
    params: { urlQuery },
  } = useRouteMatch<{ urlQuery: string }>()

  const [proceeding, setProceeding] = useState<ProceedingType | undefined>()
  const { proceedingTitle, proceedingKey } = useFormattedProceeding(proceeding)

  useEffect(() => {
    if (!urlQuery) {
      if (proceedingKey) history.push(proceedingDetailPath(proceedingKey))
      return
    }
    if (urlQuery === proceedingKey) return

    const parsed = parseProceedingUrlQuery(urlQuery)
    if (!parsed?.pracovisteKod) {
      message.error('Špatný formát čísla řízení')
      history.push(proceedingDetailPath())
      return
    }

    setProceeding(parsed)
  }, [history, proceedingKey, urlQuery])

  const handleSetProceeding = useCallback(
    (proceeding?: ProceedingType) => {
      setProceeding(proceeding)
      history.push(
        proceedingDetailPath(
          proceeding ? getProceedingKey(proceeding) : undefined
        )
      )
    },
    [history]
  )

  const extra = useConst(
    <Button onClick={() => handleSetProceeding(undefined)} type="primary">
      Vyhledat nové řízení
    </Button>
  )

  return (
    <>
      {!proceeding && (
        <PageLayout
          title="Vyhledat řízení"
          className="pb-0"
          isNew
          isNewTitle="Vyhledávání katastrálních řízení"
        >
          <ProceedingSearchBar
            initialValues={urlQuery}
            setProceeding={handleSetProceeding}
          />
        </PageLayout>
      )}
      <Fade show={!!proceeding}>
        <PageLayout
          title={`Detail řízení ${proceedingTitle || ''}`}
          className="py-8"
          extra={extra}
          isNew
          isNewTitle="Vyhledávání katastrálních řízení"
        >
          <ProceedingDetail
            setProceeding={handleSetProceeding}
            proceeding={proceeding}
          />
        </PageLayout>
      </Fade>
    </>
  )
}

export default Proceeding
