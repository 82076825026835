import React, { FunctionComponent, useEffect, useRef, useState } from 'react'

import { formatCurrencyNumber } from '../Money/Money'

export interface IncreasingNumberProps {
  finalNumber: number
  className?: string
  decimal?: number
}

export const IncreasingNumber: FunctionComponent<IncreasingNumberProps> =
  props => {
    const intervalRef = useRef<NodeJS.Timeout | null>(null)
    const numRef = useRef(0)

    const [, forceRender] = useState(false)

    useEffect(() => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }

      const diff = Math.abs(props.finalNumber - numRef.current)
      let increment =
        Math.floor(diff / 60) * (props.finalNumber > numRef.current ? 1 : -1)
      if (Math.abs(increment) < 1)
        increment = props.finalNumber > numRef.current ? 1 : -1
      const delay = 500 / 60

      intervalRef.current = setInterval(() => {
        numRef.current += increment

        if (
          (increment > 0 && numRef.current >= props.finalNumber) ||
          (increment < 0 && numRef.current <= props.finalNumber)
        ) {
          numRef.current = props.finalNumber
          if (intervalRef.current) {
            clearInterval(intervalRef.current)
            intervalRef.current = null
          }
        }

        forceRender(n => !n)
      }, delay)

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current)
          intervalRef.current = null
        }
      }
    }, [props.finalNumber])

    return (
      <span className={props.className}>
        {formatCurrencyNumber(numRef.current, props.decimal)}
      </span>
    )
  }
