import { DoubleLeftOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Alert, Button, Typography } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { usePostserviceListNameQuery } from '../../graphql/generated'
import { NoResultBox } from '../components/NoResultBox/NoResultBox'
import { PageLayout } from '../components/PageLayout/PageLayout'
import { PageSkeleton } from '../components/PageSkeleton/PageSkeleton'
import { PostServiceSentTable } from '../components/PostServiceSentTable/PostServiceSentTable'
import { alertWarningStyle } from '../utils/layoutConst'

export const PostServiceSentByList: FunctionComponent = () => {
  const { params } = useRouteMatch<{ id: string }>()

  const [queryResult] = usePostserviceListNameQuery({
    pause: !params.id,
    variables: { id: params.id },
  })

  const pageLayoutTitle = useMemo(
    () => (
      <div className="flex items-center">
        <Button
          type="link"
          icon={<DoubleLeftOutlined />}
          onClick={() => history.back()}
        >
          Zpět na adresáty
        </Button>
        <Typography.Title style={{ marginBottom: 0 }} level={3}>
          Pošta obsahující adresáty:{' '}
          {queryResult.data?.postserviceListById?.listName}
        </Typography.Title>
      </div>
    ),
    [queryResult.data?.postserviceListById?.listName]
  )

  if (queryResult.fetching) {
    return <PageSkeleton />
  }

  return (
    <PageLayout title={pageLayoutTitle}>
      {!queryResult.data?.postserviceListById?.listName ? (
        <NoResultBox />
      ) : (
        <>
          <Alert
            style={alertWarningStyle}
            message={
              <span>
                <InfoCircleOutlined className="mr-2" />
                <span className="text-gray-500">
                  Adresáti v rozeslané korespondenci jsou evidovaní vždy k datu
                  vytvoření korespondence, proto se data mohou lišit od údajů v
                  aktuálním seznamu.
                </span>
              </span>
            }
            type="warning"
          />
          <PostServiceSentTable idFilter={{ equalTo: params.id }} />
        </>
      )}
    </PageLayout>
  )
}

export default PostServiceSentByList
