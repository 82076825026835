import { InputNumber, Space } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import {
  ForclosureMonitoringFilterOnSubmit,
  ForclosureMonitoringFilterState,
} from '../../../common/foreclosureMonitoringTypes'
import { foreclosureMonitoringFilterLabels } from '../../utils/propertyMonitoringHelpers'
import { useConst } from '../../utils/useConst'
import { PropertyMonitoringFilterItem } from '../PropertyMonitoringFilterItem/PropertyMonitoringFilterItem'
import { PropertyMonitoringFilterResetButton } from '../PropertyMonitoringFilterResetButton/PropertyMonitoringFilterResetButton'

export interface ForeclosureMonitoringFilterRangeProps {
  valueFrom?: number | null
  valueTo?: number | null
  onChange: ForclosureMonitoringFilterOnSubmit
  filterKeyFrom: keyof ForclosureMonitoringFilterState
  filterKeyTo: keyof ForclosureMonitoringFilterState
}

const defaultStyle = 'w-full '
const validStyle = defaultStyle + 'bg-green-50'
const invalidStyle = defaultStyle + 'bg-red-50'

export const ForeclosureMonitoringFilterRange: FunctionComponent<ForeclosureMonitoringFilterRangeProps> =
  props => {
    const maxLimit = useConst(
      props.filterKeyTo === 'vymeraDo' ? 99999999 : 9999
    )

    const handleValueFromChange = useCallback(
      (value: number | null) => {
        props.onChange({ [props.filterKeyFrom]: value })
      },
      [props]
    )

    const handleValueToChange = useCallback(
      (value: number | null) => {
        props.onChange({ [props.filterKeyTo]: value })
      },
      [props]
    )

    const handleReset = useCallback(() => {
      props.onChange({ [props.filterKeyFrom]: null, [props.filterKeyTo]: null })
    }, [props])

    const className = useMemo(() => {
      if ((props.valueFrom || 1) > (props.valueTo || maxLimit))
        return invalidStyle

      if (props.valueTo) return validStyle

      return defaultStyle
    }, [props.valueFrom, props.valueTo, maxLimit])

    return (
      <PropertyMonitoringFilterItem
        label={foreclosureMonitoringFilterLabels[props.filterKeyFrom]}
        className={`col-span-2 sm:col-span-1 ${
          props.filterKeyTo === 'vymeraDo' ? 'md:w-64' : 'md:w-56'
        }`}
      >
        <Space.Compact>
          <InputNumber
            controls={props.filterKeyFrom === 'vlastnikuOd'}
            value={props.valueFrom}
            onChange={handleValueFromChange}
            className={props.valueFrom ? validStyle : defaultStyle}
            addonBefore="Od"
            placeholder="0"
            min={1}
            max={maxLimit}
          />
          <InputNumber
            rootClassName="addonBeforeCompactBorder"
            controls={props.filterKeyFrom === 'vlastnikuOd'}
            value={props.valueTo}
            onChange={handleValueToChange}
            className={className}
            addonBefore="Do"
            placeholder="∞"
            min={1}
            max={maxLimit}
          />
          {(!!props.valueFrom || !!props.valueTo) && (
            <PropertyMonitoringFilterResetButton onClick={handleReset} />
          )}
        </Space.Compact>
      </PropertyMonitoringFilterItem>
    )
  }
