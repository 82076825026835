import { Icon } from '@iconify/react'
import { Button, message, Tooltip } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { BASE_URL } from '../../../../ini.json'
import { useAppSelector } from '../../../redux/hooks'
import { checkExistingFile } from '../../utils/checkExistingFile'

export interface CuzkEpoXlsxButtonProps {
  id: string
  buttonText?: string
  buttonProps?: ButtonProps
  icon?: React.ReactNode | null
  isHugeSubject?: boolean
}

export const CuzkEpoXlsxButton: FunctionComponent<CuzkEpoXlsxButtonProps> =
  props => {
    const { icon, buttonText = 'EXCEL' } = props

    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const token = useAppSelector(state => state.myProfile.token)
    const [loading, setLoading] = useState(false)
    const [downloadReady, setDownloadReady] = useState<undefined | boolean>()

    const buttonTitle = downloadReady
      ? 'Stáhnout XLSX soubor'
      : 'Generovat XLSX soubor'

    const buttonIcon =
      icon !== null &&
      (icon ||
        (downloadReady ? (
          <Icon className="inline mr-2" icon="bi:file-earmark-excel" />
        ) : (
          <Icon className="inline mr-2" icon="bi:file-earmark-excel-fill" />
        )))

    useEffect(() => {
      const checkFileExistance = async () => {
        if (downloadReady === undefined) {
          const fileSize = await checkExistingFile({
            filename: `${props.id}.xlsx`,
            tenantId: tenantId,
            token: token,
          })

          setDownloadReady(!!fileSize)
        }
      }
      checkFileExistance()
    }, [downloadReady, props.id, tenantId, token])

    const handleDownloadXlsx = useCallback(async () => {
      try {
        setLoading(true)
        const response = await fetch(`${BASE_URL}/api/cuzk/epoXlsx`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token,
            tenantId,
            epoId: props.id,
          }),
        })
        if (!response.ok) {
          message.error('Nepodařilo se vygenerovat XLSX soubor.')
        }
        if (response.ok) {
          window.open(
            `${BASE_URL}/download/${tenantId}/${props.id}.xlsx`,
            '_blank'
          )
        }
      } catch (error) {
        message.error('Nepodařilo se vygenerovat XLSX soubor.')
      } finally {
        setLoading(false)
      }
    }, [token, tenantId, props.id])

    return (
      <Tooltip
        title={`Generuje se soubor v xlsx formátu. V závislosti na rozsahu dat může tato akce trvat až ${
          props.isHugeSubject ? 'několik minut' : 'minutu'
        }.`}
        open={loading}
      >
        <Button
          type="primary"
          ghost
          onClick={handleDownloadXlsx}
          loading={loading}
          title={buttonTitle}
          icon={buttonIcon}
          {...props.buttonProps}
        >
          {buttonText}
        </Button>
      </Tooltip>
    )
  }
