import { message } from 'antd'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'

import { BASE_URL } from '../../../ini.json'
import { EpoRestrictionItem } from '../../common/epoTypes'
import { useAppSelector } from '../../redux/hooks'
import { handleResponseStatus } from './handleResponseStatus'

export interface EpoRestrictionTableData {
  data:
    | {
        lvTotalCount: number
        hasBeenChecked: boolean
        positiveLiens: EpoRestrictionItem[]
      }
    | undefined
  loading: boolean
  error: boolean
  refetch: () => Promise<void>
}
export function useEpoRestrictionTableData(epoId: string) {
  const tenantId = useAppSelector(state => state.myProfile.tenantId)
  const userId = useAppSelector(state => state.myProfile.userId)
  const token = useAppSelector(state => state.myProfile.token)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const [dataSource, setDataSource] = useState<{
    lvTotalCount: number
    hasBeenChecked: boolean
    positiveLiens: EpoRestrictionItem[]
  }>()

  const refetch = useCallback(async () => {
    try {
      setLoading(true)
      const response = await axios.post(
        `${BASE_URL}/api/cuzk/epo/checkRestriction`,
        {
          tenantId,
          userId,
          id: epoId,
          token,
        }
      )

      const { status, data } = response
      if (status === 200) return setDataSource(data)
      handleResponseStatus(status)
    } catch (error) {
      message.error('Nepodařilo se provést kontrolu, zkuste to prosím později.')
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [epoId, tenantId, token, userId])

  useEffect(() => {
    if (dataSource) return
    refetch()
  }, [dataSource, refetch])

  return { data: dataSource, loading, error, refetch }
}
