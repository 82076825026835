import { Skeleton } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { Identifier, RegisterGroupMap } from '../../utils/dynamicSearchTooltip'
import { tooltipHelpUnderline } from '../../utils/layoutConst'
import { CeeMonitorTableAmountSummary } from '../CeeMonitorTableAmountSummary/CeeMonitorTableAmountSummary'
import { CeeMonitorItem } from '../CeeMonitorTableExpandedRow/CeeMonitorTableExpandedRow'
import { CeeMonitorTableIdentificationItem as Item } from '../CeeMonitorTableIdentificationItem/CeeMonitorTableIdentificationItem'
import { CeeSummaryPdf } from '../CeeSummaryPdf/CeeSummaryPdf'
import { Day, Sec } from '../DateFormat/DateFormat'
import { DynamicSearchTooltip } from '../DynamicSearchTooltip/DynamicSearchTooltip'
import { PdfDrawerPreview } from '../PdfDrawerPreview/PdfDrawerPreview'
import { SimpleAddressTooltip } from '../SimpleAddressTooltip/SimpleAddressTooltip'

export interface CeeMonitorTableIdentificationPanelProps {
  ceeData?: CeeMonitorItem[]
  loading?: boolean
}

export const CeeMonitorTableIdentificationPanel: FunctionComponent<CeeMonitorTableIdentificationPanelProps> =
  props => {
    const person = props.ceeData?.[0]
    const buttonProps = useMemo(
      () => ({ loading: props?.loading, ghost: true }),
      [props?.loading]
    )
    const skeleton = useMemo(
      () => (
        <Skeleton
          active
          title={false}
          paragraph={{
            rows: 1,
            className: 'flex justify-center mt-2',
          }}
          loading={!!props.loading}
        />
      ),
      [props.loading]
    )

    return (
      <div className="p-4 mb-4 mt-1 text-center rounded border border-gray-300 flex bg-gray-100 divide-x divide-gray-300">
        <Item title="Jméno">
          {skeleton}
          {person?.fullname}
        </Item>

        <Item title="Adresa">
          {skeleton}
          <SimpleAddressTooltip
            className={tooltipHelpUnderline}
            address={person?.address}
          />
        </Item>

        {!!person?.birthDate && (
          <Item title="Dat. narození">
            {skeleton}
            <Day noData>{person?.birthDate}</Day>
          </Item>
        )}

        {!!person?.rc && (
          <Item title="Rodné číslo">
            {skeleton}
            <DynamicSearchTooltip
              identifier={{
                [Identifier.RC]: person.rc,
                [Identifier.DESCRIPTION]: person.fullname,
              }}
              identifierType={Identifier.RC}
              excludedRegisters={RegisterGroupMap.CEE}
            >
              {person.ico}
            </DynamicSearchTooltip>
          </Item>
        )}

        {person?.ico && (
          <Item title="IČ">
            {skeleton}
            <DynamicSearchTooltip
              identifier={{
                [Identifier.ICO]: person.ico,
                [Identifier.DESCRIPTION]: person.fullname,
              }}
              identifierType={Identifier.ICO}
              excludedRegisters={RegisterGroupMap.CEE}
            >
              {person.ico}
            </DynamicSearchTooltip>
          </Item>
        )}

        <Item title="Počet exekucí">
          {skeleton}
          {props.ceeData?.length}
        </Item>

        <Item title="Výše exekucí">
          {skeleton}
          {!props?.loading && (
            <CeeMonitorTableAmountSummary ceeData={props.ceeData} />
          )}
        </Item>

        <Item title="Čas lustrace">
          {skeleton}
          <Sec noData>{person?.createdAt}</Sec>
        </Item>

        <div className="self-center w-1/6">
          <PdfDrawerPreview
            buttonProps={buttonProps}
            drawerTitle="Náhled PDF - výsledky vyhledávání v CEE"
            fileName={person?.fullname || person?.rc + ' CEE_REGESTA_DATA'}
          >
            <CeeSummaryPdf data={props.ceeData} />
          </PdfDrawerPreview>
        </div>
      </div>
    )
  }
