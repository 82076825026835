import { FilterOutlined } from '@ant-design/icons'
import { Button, Form, Modal } from 'antd'
import React, { FunctionComponent } from 'react'

import { CadastreMapDebtFilterFormOutput } from '../../../common/cadastreMapDebtTypes'
import { useToggleState } from '../../utils/useToggleState'
import { CadastreMapDebtFilterForm } from '../CadastreMap/components/CadastreMapDebtFilterForm/CadastreMapDebtFilterForm'

export interface CadastreMapDebtLayerControlModalButtonProps {
  onFilterParams: (where: CadastreMapDebtFilterFormOutput) => void
}

const style = { paddingTop: 20 }

export const CadastreMapDebtLayerControlModalButton: FunctionComponent<CadastreMapDebtLayerControlModalButtonProps> =
  props => {
    const [form] = Form.useForm()
    const [open, toggleOpen] = useToggleState()

    return (
      <div>
        <Button
          className="hover:!bg-white"
          icon={<FilterOutlined />}
          onClick={toggleOpen}
          title="Filtrovat nemovitostí s exekucí/insolvencí"
        />

        <Modal
          title="Filtr zobrazení nemovitostí"
          open={open}
          onCancel={toggleOpen}
          footer={null}
          width={750}
          bodyStyle={style}
        >
          <CadastreMapDebtFilterForm
            form={form}
            onSubmit={props.onFilterParams}
            onClose={toggleOpen}
          />
        </Modal>
      </div>
    )
  }
