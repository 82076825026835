import { Checkbox } from 'antd'
import React, { FunctionComponent, memo } from 'react'

import { CadastralWorkplace } from '../../utils/cuzkHelpers'
import { selectionSearchBarNormalizer } from '../../utils/propertyMonitoringHelpers'

export interface PropertyUseSelectionFilterCheckboxProps {
  useType: CadastralWorkplace
  searchTerm?: string
  children?: React.ReactNode
}

export const PropertyUseSelectionFilterCheckbox: FunctionComponent<PropertyUseSelectionFilterCheckboxProps> =
  memo(props => {
    return (
      <Checkbox
        key={props.useType.kod}
        value={props.useType.kod}
        rootClassName="!grid grid-cols-[auto_1fr_auto] w-full !pb-1 group odd:bg-gray-50 overflow-hidden hover:bg-gray-100"
        disabled={!!props.useType.disabled}
        defaultChecked
        className={
          props.searchTerm?.length &&
          selectionSearchBarNormalizer(props.useType.nazev).includes(
            selectionSearchBarNormalizer(props.searchTerm)
          )
            ? `!bg-green-100 !ml-0`
            : '!ml-0'
        }
      >
        {props.children || (
          <span className="truncate">{props.useType.nazev}</span>
        )}
      </Checkbox>
    )
  })
