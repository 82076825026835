import { Icon } from '@iconify/react'
import React, { FunctionComponent, useMemo } from 'react'

import { ColorPalleteKey, ColorPalleteOptions } from '../../utils/collorPallete'
import {
  IconPalleteDeafultOption,
  IconPalleteKey,
  IconPalleteOptions,
} from '../../utils/iconPallete'

export interface NoteIconProps {
  colorKey?: ColorPalleteKey
  iconKey?: IconPalleteKey
  className?: string
}
export const NoteIcon: FunctionComponent<NoteIconProps> = props => {
  const { style, icon } = useMemo(
    () => ({
      style:
        'p-2.5 text-base outline outline-4 outline-white rounded-full transition-colors ' +
          ColorPalleteOptions[props.colorKey || 'g0'] +
          ' ' +
          props.className || '',
      icon:
        props.iconKey && props.iconKey !== IconPalleteDeafultOption
          ? IconPalleteOptions[props.iconKey]
          : 'teenyicons:stop-circle-solid',
    }),
    [props]
  )
  return (
    <span className={style}>
      <Icon icon={icon} width={14} height={14} />
    </span>
  )
}
