import { DownOutlined } from '@ant-design/icons'
import { Button, Divider, Dropdown } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { recipientVariableName } from '../../../common/postServiceTypes'
import { clickTrigger } from '../../utils/layoutConst'
import { OneLineHelp } from '../OneLineHelp/OneLineHelp'

export interface PostServiceVariablesDropdownProps {
  handleInsertText: (e: { key: string }) => void
}

const help = (
  <>
    <OneLineHelp
      classNameClose="w-44 max-h-6 overflow-hidden"
      classNameOpen="w-72"
    >
      <>
        Díky proměnným můžete vytvořit univerzální šablonu dopisu pro více
        adresátů. V tištěném dopise se proměnné nahradí skutečnými údaji
        subjektu. Proměnné přebírají i nastavené styly, dbejte však na to,
        abyste styl nastavili vždy na celou proměnnou, a ne pouze na její část.
        <div>
          Správné formátování:{' '}
          <span className="font-bold text-lime-400">[subjekt_jmeno]</span>
        </div>
        <div>
          Špatné formátování: [subj
          <span className="font-bold text-red-300">ekt_jmeno]</span>
        </div>
        V PDF náhledu dopisu si můžete práci s proměnnými vyzkoušet.
      </>
    </OneLineHelp>
    <Divider style={{ margin: '8px 0 0 0' }} />
  </>
)

export const PostServiceVariablesDropdown: FunctionComponent<PostServiceVariablesDropdownProps> =
  props => {
    const items = useMemo(() => {
      return {
        items: [
          {
            label: help,
            key: 'help',
            type: 'group',
            children: [],
          },
          ...Object.keys(recipientVariableName).map(key => {
            return {
              label: key,
              key: key,
              children: Object.keys(recipientVariableName[key]).map(subKey => {
                return {
                  key: subKey + ' ',
                  label: recipientVariableName[key][subKey],
                  onClick: props.handleInsertText,
                }
              }),
            }
          }),
        ],
      }
    }, [props.handleInsertText])

    return (
      <Dropdown menu={items} trigger={clickTrigger}>
        <Button type="primary" ghost>
          Vložit proměnnou
          <DownOutlined className="ml-2" />
        </Button>
      </Dropdown>
    )
  }
