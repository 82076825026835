import React, { FunctionComponent, useMemo } from 'react'

import { safeJsonParsing } from '../../../common/safeJsonParsing'
import { PropertyNoteLink } from '../../utils/propertyDetailNoteHelpers'

export interface PropertyDetailNoteLinksProps {
  links?: string | null
}

export const PropertyDetailNoteLinks: FunctionComponent<PropertyDetailNoteLinksProps> =
  props => {
    const parsed = useMemo(
      () => props.links && safeJsonParsing<PropertyNoteLink[]>(props.links, []),
      [props.links]
    )

    if (!parsed || !parsed.length) return null

    return (
      <div className="flex flex-wrap">
        {parsed.map((link, index) => (
          <div
            key={index}
            className="pr-2 mr-2 border-r border-gray-300 max-w-xs truncate last-of-type:border-none"
          >
            <a href={link.url} target="_blank" rel="noreferrer">
              {link.title || link.url}
            </a>
          </div>
        ))}
      </div>
    )
  }
