import { Form, Select } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { useAllPostserviceTemplatesQuery } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'

export interface PostServiceTemplateSelectFormFragmentProps {
  handleChange?: (value: string) => void
  initialId?: string
  isFormFragment?: boolean
}

export const PostServiceTemplateSelectFormFragment: FunctionComponent<PostServiceTemplateSelectFormFragmentProps> =
  props => {
    const { Option } = Select
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const [queryResult] = useAllPostserviceTemplatesQuery({
      variables: { accountId: tenantId },
    })

    const templates = useMemo(
      () =>
        queryResult.data?.allPostserviceTemplates?.nodes.map(item => (
          <Option key={item?.id} value={item?.id} label={item?.name}>
            <div>
              <div>{item?.name}</div>
              {item?.description && (
                <div className="flex flex-wrap max-h-20 whitespace-normal font-normal text-gray-400 overflow-ellipsis">
                  {item.description}
                  {item.description.length > 190 && '...'}
                </div>
              )}
            </div>
          </Option>
        )),
      [Option, queryResult.data?.allPostserviceTemplates?.nodes]
    )

    return (
      <Form.Item name="templateId" label="Šablona dopisu">
        <Select
          className="w-full"
          placeholder="Výchozí obsah dopisu"
          optionLabelProp="label"
          defaultValue={props.initialId}
          allowClear
          disabled={!!props.initialId}
          loading={queryResult.fetching}
        >
          {templates}
        </Select>
      </Form.Item>
    )
  }
