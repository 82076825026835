import { EyeOutlined } from '@ant-design/icons'
import { Tag, Tooltip } from 'antd'
import { isToday } from 'date-fns'
import React, { FunctionComponent, memo, useMemo } from 'react'

import { UserActivityLog } from '../../../common/userActivityLogTypes'
import { UserActivityActionType } from '../../../graphql/generated'
import { CustomDate, Day } from '../DateFormat/DateFormat'

export interface UserActivityLogBadgeProps {
  userActivityLog?: UserActivityLog | null
  alertDateTo?: string | null
}

export const UserActivityLogBadge: FunctionComponent<UserActivityLogBadgeProps> =
  memo(({ userActivityLog: activityLog, alertDateTo }) => {
    const date = useMemo(() => {
      if (
        !activityLog ||
        activityLog.actionType !== UserActivityActionType['SeenDetail']
      )
        return null

      const asDate = new Date(activityLog.editedAt)

      return {
        today: isToday(asDate) && 'Dnes',
        deprecated: alertDateTo ? new Date(alertDateTo) > asDate : false,
        asDate,
        asString: activityLog.editedAt,
      }
    }, [activityLog, alertDateTo])

    if (!date) return null
    if (date.deprecated)
      return (
        <Tooltip
          title={
            <span>
              Od vašeho posledního náhledu detailu (<Day>{date.asString}</Day>)
              proběhla změna.
            </span>
          }
        >
          <Tag icon={<EyeOutlined />} color="warning">
            {date.today || (
              <CustomDate format="dd.MM.yy">{date.asString}</CustomDate>
            )}
          </Tag>
        </Tooltip>
      )

    return (
      <Tooltip
        title={
          <span>
            Váš poslední náhled detailu: <Day>{date.asString}</Day>
          </span>
        }
      >
        <Tag icon={<EyeOutlined />} color="default" className="!opacity-60">
          {date.today || (
            <CustomDate format="dd.MM.yy">{date.asString}</CustomDate>
          )}
        </Tag>
      </Tooltip>
    )
  })
