import { Tooltip } from 'antd'
import React, { FunctionComponent } from 'react'

import { AddressLinkButton } from '../AddressLinkButton/AddressLinkButton'

export interface SimpleAddressTooltipProps {
  address?: string
  addressCode?: string
  children?: React.ReactNode
  className?: string
}

const buttonProps = {
  type: 'primary',
  className: 'mb-2 mr-2 w-full !text-white',
} as const

export const SimpleAddressTooltip: FunctionComponent<SimpleAddressTooltipProps> =
  props => {
    return (
      <Tooltip
        trigger="click"
        overlayClassName="min-w-min"
        className={props.className}
        title={
          <div className="p-1">
            <div>
              <AddressLinkButton
                addressId={props.addressCode}
                address={props.address}
                buttonProps={buttonProps}
              >
                Zobrazit detail adresy
              </AddressLinkButton>
            </div>

            <div className="space-x-2 whitespace-nowrap">
              <span>Zobrazit v</span>
              <a
                target="_blank"
                href={`https://mapy.cz/zakladni?q=${props.address?.replace(
                  /\//g,
                  '+'
                )}`}
                rel="noreferrer"
              >
                mapy.cz
              </a>
              <span className="text-gray-500">|</span>
              <a
                target="_blank"
                href={`https://www.google.com/maps/place/${props.address?.replace(
                  /\//g,
                  '+'
                )}`}
                rel="noreferrer"
              >
                google maps
              </a>
            </div>
          </div>
        }
      >
        {props.children || props.address}
      </Tooltip>
    )
  }
