import { CloseOutlined } from '@ant-design/icons'
import { Icon } from '@iconify/react'
import { Popover } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { SubjectsInPostService } from '../../../common/postServiceTypes'
import { useToggleState } from '../../utils/useToggleState'
import { SubjectTableCellRecipientIconCount } from '../SubjectTableCellRecipientIconCount/SubjectTableCellRecipientIconCount'
import { SubjectTableCellRecipientLetterTable } from '../SubjectTableCellRecipientLetterTable/SubjectTableCellRecipientLetterTable'

export interface SubjectTableCellRecipientLetterProps {
  postServiceLetter?: SubjectsInPostService['letter']
}

export const SubjectTableCellRecipientLetter: FunctionComponent<SubjectTableCellRecipientLetterProps> =
  props => {
    const [open, handleOpen, , closeModal] = useToggleState()

    const title = useMemo(
      () => (
        <div className="flex justify-between items-center">
          <span>Přehled dopisů adresovaných vybranému subjektu:</span>
          <CloseOutlined
            onClick={handleOpen}
            className="text-gray-400 hover:text-gray-800 p-1 cursor-pointer"
          />
        </div>
      ),
      [handleOpen]
    )

    if (!props?.postServiceLetter?.length) return null

    return (
      <Popover
        overlayClassName="w-full max-w-xl"
        placement="topRight"
        trigger="click"
        onOpenChange={handleOpen}
        title={title}
        open={open}
        content={
          <SubjectTableCellRecipientLetterTable
            closeModal={closeModal}
            postServiceLetter={props.postServiceLetter}
          />
        }
      >
        <div
          className="relative cursor-pointer"
          title="Zobrazit přiřazené dopisy"
        >
          <SubjectTableCellRecipientIconCount
            count={props.postServiceLetter.length}
          />
          <Icon
            icon="ri:mail-send-line"
            className="w-auto h-5 text-gray-400 hover:text-primaryHover"
          />
        </div>
      </Popover>
    )
  }
