import { Icon } from '@iconify/react'
import { Popover } from 'antd'
import React, { FunctionComponent } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { WatchdogIconSize } from '../../../common/watchdogTypes'
import { useToggleState } from '../../utils/useToggleState'
import { WatchdogAddPropertyModal } from '../WatchdogAddPropertyModal/WatchdogAddPropertyModal'

export interface WatchdogRemovedPropertyModalButtonProps {
  propertyId: string
  propertyType: PROPERTY_TYPES
  iconSize?: WatchdogIconSize
}

const maxWidth = { maxWidth: '320px', textAlign: 'justify' as const }
export const WatchdogRemovedPropertyModalButton: FunctionComponent<WatchdogRemovedPropertyModalButtonProps> =
  props => {
    const [open, handleOpen] = useToggleState(false)

    return (
      <>
        <Popover
          content={`Tato ${props.propertyType} byla již odebrána z hlídače, ale abychom předešli posouvání řádků v tabulce, zůstane tento záznam viditelný do příštího obnovení stránky. Kliknutím na ikonu můžete nemovitost přidat zpět do hlídače.`}
          title="Odebráno z hlídače"
          overlayInnerStyle={maxWidth}
          placement="left"
        >
          <div
            className="flex justify-center items-center text-amber-600 hover:text-amber-500 cursor-pointer pt-1.5 pb-1"
            onClick={handleOpen}
          >
            <Icon
              icon="material-symbols:edit-notifications-outline"
              className={props.iconSize || 'text-lg'}
            />
          </div>
        </Popover>

        {open && (
          <WatchdogAddPropertyModal
            propertyId={props.propertyId}
            propertyType={props.propertyType}
            open={open}
            onClose={handleOpen}
          />
        )}
      </>
    )
  }
