import { Icon } from '@iconify/react'
import React, { FunctionComponent, useCallback } from 'react'

export interface PropertyDetailCollapseButtonProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const PropertyDetailCollapseButton: FunctionComponent<PropertyDetailCollapseButtonProps> =
  props => {
    const handleOpen = useCallback(() => {
      props.setIsOpen(prev => !prev)
    }, [props])

    return (
      <div
        title={
          props.isOpen ? 'Skrýt informační panel' : 'Zobrazit informační panel'
        }
        className="flex items-center justify-center bg-[#e7ebf0] mr-1.5 border border-gray-300/60 border-b-0 rounded-t h-10 w-11 cursor-pointer hover:text-primary transition-colors"
        onClick={handleOpen}
      >
        {props.isOpen ? (
          <Icon
            icon="carbon:side-panel-close"
            width="18"
            height="18"
            className="rotate-90 lg:rotate-0"
          />
        ) : (
          <Icon
            icon="carbon:side-panel-open"
            width="18"
            height="18"
            className="rotate-90 lg:rotate-0"
          />
        )}
      </div>
    )
  }
