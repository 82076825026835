import React, { FunctionComponent } from 'react'

import { PageLayout } from '../components/PageLayout/PageLayout'
import { WatchdogChannelModalButton } from '../components/WatchdogChannelModalButton/WatchdogChannelModalButton'
import { WatchdogChannelTable } from '../components/WatchdogChannelTable/WatchdogChannelTable'
import { useInitialFetchWatchdog } from '../utils/useFetchWatchdog'

const buttonProps = { type: 'primary' as const }

export const WatchdogChannel: FunctionComponent = () => {
  useInitialFetchWatchdog()

  return (
    <PageLayout
      title="Hlídač katastru - Kanály"
      className="pb-0"
      isNew
      isNewTitle="Kanály hlídače 2.0"
      extra={<WatchdogChannelModalButton buttonProps={buttonProps} />}
    >
      <WatchdogChannelTable />
    </PageLayout>
  )
}
export default WatchdogChannel
