import { Typography } from 'antd'
import { Maybe } from 'graphql/jsutils/Maybe'
import React from 'react'

import { Label as LabelPreview } from '../../components/Label/Label'
import {
  ColorPalleteDeafultOption,
  ColorPalleteKey,
} from '../../utils/collorPallete'
import {
  IconPalleteDeafultOption,
  IconPalleteKey,
} from '../../utils/iconPallete'

interface LabelDetailPageTitleProps {
  label: Maybe<{
    name?: string | null
    colorKey?: string | null
    iconKey?: string | null
    description?: string | null
  }>
}
const style = { marginBottom: 0 }

export const LabelDetailPageTitle: React.FC<LabelDetailPageTitleProps> = ({
  label,
}) => (
  <div className="flex space-x-4 items-center">
    <Typography.Title style={style} level={3}>
      Detail štítku
    </Typography.Title>
    <div className="scale-125">
      <LabelPreview
        colorKey={
          (label?.colorKey as ColorPalleteKey) || ColorPalleteDeafultOption
        }
        iconKey={(label?.iconKey as IconPalleteKey) || IconPalleteDeafultOption}
        name={label?.name || ''}
        description={label?.description || ''}
      />
    </div>
  </div>
)
