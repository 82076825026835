import { Form, InputNumber, Radio, Select } from 'antd'
import React, { FunctionComponent } from 'react'

import { MultiCheckboxSelect } from '../MultiCheckboxSelect/MultiCheckboxSelect'

export const PriceEstimateUnitFragment: FunctionComponent = () => {
  const { Option } = Select
  return (
    <>
      <Form.Item
        name="pl_uzitna"
        label="Užitná plocha"
        rules={[
          {
            required: true,
            message: 'Užitná plocha musí být vyplněna',
          },
        ]}
      >
        <InputNumber className="w-full" addonAfter="m²" min={1} />
      </Form.Item>
      <Form.Item name="pl_balkonu" label="Plocha balkónu">
        <InputNumber className="w-full" addonAfter="m²" min={0} />
      </Form.Item>
      <Form.Item
        name="druh_vlastnictvi"
        label="Druh vlastnictví"
        required
        className="print:!mb-0"
      >
        <Radio.Group>
          <Radio value={0}>Osobní</Radio>
          <Radio value={1}>Družstevní</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="druh_konstrukce"
        label="Konstrukce"
        required
        className="print:!mb-0"
      >
        <Radio.Group>
          <Radio value={1}>Panelová</Radio>
          <Radio value={0}>Jiná</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="podlazi_umisteni"
        label="V přízemí"
        required
        className="print:!mb-0"
      >
        <Radio.Group>
          <Radio value={2}>Ne</Radio>
          <Radio value={1}>Ano</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="zarizeno"
        label="Zařízeno"
        required
        className="print:!mb-0"
      >
        <Radio.Group>
          <Radio value={2}>Ne</Radio>
          <Radio value={0}>Ano</Radio>
          <Radio value={1}>Částečně</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="umisteni_nemovitosti"
        label="Umístění nemovitosti"
        required
      >
        <Select className="w-full">
          <Option value={0}>Rušná část obce</Option>
          <Option value={1}>Centrum obce</Option>
          <Option value={2}>Klidná část obce</Option>
          <Option value={3}>Sídliště</Option>
          <Option value={4}>Okraj obce</Option>
          <Option value={5}>Polosamota</Option>
          <Option value={6}>Samota</Option>
          <Option value={7}>Venkov</Option>
          <Option value={8}>Průmyslová zóna</Option>
        </Select>
      </Form.Item>
      <Form.Item name="dispozice_mistnosti" label="Dispozice" required>
        <Select className="w-full">
          <Option value={1}>1+kk</Option>
          <Option value={2}>1+1</Option>
          <Option value={3}>2+kk</Option>
          <Option value={4}>2+1</Option>
          <Option value={5}>3+kk</Option>
          <Option value={6}>3+1</Option>
          <Option value={7}>4+kk</Option>
          <Option value={8}>4+1</Option>
          <Option value={9}>5+kk</Option>
          <Option value={10}>5+1</Option>
          <Option value={11}>5+2</Option>
          <Option value={14}>6+kk</Option>
          <Option value={15}>6+1</Option>
          <Option value={16}>6+2</Option>
          <Option value={17}>7+kk</Option>
          <Option value={18}>7+1</Option>
        </Select>
      </Form.Item>
      <Form.Item name="reduced_stav" label="Stav" required>
        <Select className="w-full">
          <Option value={0}>Výborný</Option>
          <Option value={1}>Velmi dobrý</Option>
          <Option value={2}>Dobrý</Option>
          <Option value={3}>Před rekonstrukcí</Option>
          <Option value={4}>Špatný</Option>
        </Select>
      </Form.Item>
      <Form.Item name="object_flags" label="Parkování">
        <Radio.Group>
          <Radio value={undefined}>Ne</Radio>
          <Radio value={0x2000}>Parkovací stání</Radio>
          <Radio value={0x4000}>Garážové stání</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="prislusenstvi_fl" label="Příslušenství">
        <MultiCheckboxSelect
          options={[
            { label: 'Terasa', value: 0x0004 },
            { label: 'výtah', value: 0x0200 },
          ]}
          name={'prislusenstvi_fl'}
        />
      </Form.Item>
    </>
  )
}
