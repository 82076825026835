import { Document, Page } from '@react-pdf/renderer'
import { Button, message } from 'antd'
import { BaseButtonProps } from 'antd/lib/button/button'
import React, { FunctionComponent, useMemo } from 'react'

import { usePriceEstimatePdfQuery } from '../../../graphql/generated'
import { monitPdfCss } from '../../utils/monitPdfCss'
import { ImgSrc, useMapImage } from '../../utils/useMapImage'
import { PdfDrawerPreview } from '../PdfDrawerPreview/PdfDrawerPreview'
import { PriceEstimatePdf } from '../PriceEstimatePdf/PriceEstimatePdf'

export interface PriceEstimatePdfButtonProps {
  buttonProps?: BaseButtonProps
  id: string
  imgSrc?: ImgSrc
}

export const PriceEstimatePdfButton: FunctionComponent<PriceEstimatePdfButtonProps> =
  React.memo(props => {
    const [pdfDataResponse, refetch] = usePriceEstimatePdfQuery({
      variables: { id: props.id },
      pause: true,
    })

    const imgSrc = useMapImage(
      !props.imgSrc ? pdfDataResponse.data?.monitById?.lat : undefined,
      !props.imgSrc ? pdfDataResponse.data?.monitById?.lon : undefined,
      !props.imgSrc ? pdfDataResponse.data?.monitById?.propertyType : undefined,
      !props.imgSrc ? pdfDataResponse.data?.monitById?.polygon : undefined
    )

    const mapImages = props.imgSrc || imgSrc

    const sanitizedFileName = useMemo(
      () =>
        pdfDataResponse.data?.monitById?.title
          ? pdfDataResponse.data.monitById.title
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(/[^a-zA-Z0-9-]/g, '_')
              .replace(/__|_-_/g, '_')
              .slice(0, 45) + '.pdf'
          : 'REGESTA DATA - Cenový odhad.pdf',
      [pdfDataResponse?.data?.monitById?.title]
    )

    if (pdfDataResponse.error) {
      message.error(
        'Nepodařilo se načíst historická data předešlého cenového odhadu'
      )
      return (
        <Button
          title="Nepodařilo se načíst historická data"
          type="primary"
          disabled
          {...props.buttonProps}
        >
          zobrazit PDF
        </Button>
      )
    }
    if (!pdfDataResponse.data && !pdfDataResponse.fetching)
      return (
        <Button type="primary" onMouseEnter={refetch} {...props.buttonProps}>
          zobrazit PDF
        </Button>
      )

    return (
      <PdfDrawerPreview
        buttonProps={props.buttonProps}
        buttonText="zobrazit PDF"
        drawerTitle="PDF náhled cenového odhadu"
        fileName={sanitizedFileName}
      >
        {!!pdfDataResponse.data?.monitById &&
        mapImages.map &&
        mapImages.streetView ? (
          <PriceEstimatePdf
            imgSrc={mapImages}
            data={pdfDataResponse.data?.monitById}
          />
        ) : (
          <Document>
            <Page size="A4" style={monitPdfCss.page} />
          </Document>
        )}
      </PdfDrawerPreview>
    )
  })
