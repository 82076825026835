import { message, Tabs } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'

import { CeeStatisticsTable } from '../components/CeeStatisticsTable/CeeStatisticsTable'
import { MarketplaceStatisticsTable } from '../components/MarketplaceStatisticsTable/MarketplaceStatisticsTable'
import { MonitStatisticsTable } from '../components/MonitStatisticsTable/MonitStatisticsTable'
import { PageLayout } from '../components/PageLayout/PageLayout'
import { PostServiceStatisticsTable } from '../components/PostServiceStatisticsTable/PostServiceStatisticsTable'
import { StatisticCompany, SystemCompany } from '../utils/statisticHelpers'

export const Statistics: FunctionComponent = () => {
  const [activeKey, setActiveKey] = useState('CEE')
  const [companies, setCompanies] = useState<StatisticCompany>()

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get<SystemCompany[]>('/api/firmy', {
          withCredentials: true,
        })

        const companies = Array.isArray(response.data)
          ? response.data.reduce((acc: StatisticCompany, company) => {
              acc[company.id] = company.nazev
              return acc
            }, {})
          : {}

        setCompanies(companies)
      } catch {
        message.error('Nepodařilo se načíst názvy firem.')
        setCompanies({})
      }
    }

    if (!companies) fetchCompanies()
  }, [companies])

  const tabItems = useMemo(
    () => [
      {
        key: 'CEE',
        label: 'CEE',
        children: (
          <div className="bg-white py-2">
            <CeeStatisticsTable companies={companies} />
          </div>
        ),
      },
      {
        key: 'Cenové odhady',
        label: 'Cenové odhady',
        children: (
          <div className="bg-white py-2">
            <MonitStatisticsTable companies={companies} />
          </div>
        ),
      },
      {
        key: 'Hromadná pošta',
        label: 'Pošta',
        children: (
          <div className="bg-white py-2">
            <PostServiceStatisticsTable companies={companies} />
          </div>
        ),
      },
      {
        key: 'Marketplace',
        label: 'Marketplace',
        children: (
          <div className="bg-white py-2">
            <MarketplaceStatisticsTable companies={companies} />
          </div>
        ),
      },
    ],
    [companies]
  )

  return (
    <PageLayout title={'Statistiky využití - ' + activeKey}>
      <Tabs
        size="large"
        type="card"
        tabBarGutter={6}
        activeKey={activeKey}
        onChange={setActiveKey}
        items={tabItems}
      />
    </PageLayout>
  )
}

export default Statistics
