import React, { FunctionComponent } from 'react'

import { PageLayout } from '../components/PageLayout/PageLayout'
import imgSrc from '../images/404.webp'

export const NotFound: FunctionComponent = () => {
  return (
    <PageLayout title="Požadovaná stránka nebyla nalezena">
      <div className="w-full flex justify-center py-20">
        <img src={imgSrc} />
      </div>
    </PageLayout>
  )
}

export default NotFound
