import { Typography } from 'antd'
import React from 'react'
export type cuzkMonitorRcFormHelpKey = keyof typeof cuzkMonitorRcFormHelp

export const cuzkMonitorRcFormHelp = {
  frequency: (
    <div className="mb-6">
      Vyberte z uvedených možností, jak často chcete, aby se prováděly
      automatické kontroly. V případě, že dojde k pozitivnímu nálezu, bude
      periodická kontrola sama ukončena. <br />
      Každá provedená kontrola je účtována standardně dle aktuálního ceníku.
    </div>
  ),

  note: (
    <div className="mb-6">
      Klíč <span className="italic">[puvodni_poznamka]</span> zastupuje text
      původní poznámky, můžete tak libovolně rozšířit stávající hodnoty
      poznámek. Pokud necháte pole zcela prázdé, původní poznámky budou smazány.
    </div>
  ),

  labelEditType: (
    <div className="mb-6">
      <span className="font-semibold">Zachovat nastavení: </span>
      Zachovají se původní štítky.
      <br />
      <span className="font-semibold">Přidat vybrané: </span>Vybrané štítky
      budou přidány navíc ke stávajícím.
      <br />
      <span className="font-semibold">Nahradit za vybrané: </span>K subjektům
      budou přiřazeny pouze vybrané štítky, ostatní budou odebrány.
      <br />
      <span className="font-semibold">Odebrat pouze vybrané: </span>
      Vybrané štítky budou odebrány, všechny ostatí zůstanou zachovány beze
      změny.
      <br />
      <span className="font-semibold">Odebrat vše: </span>U vybraných subjektů
      budou odebrány všechny štítky.
    </div>
  ),
}

export const cuzkEpoFormHelp = {
  datumNarozeni: (
    <div className="mb-2">
      Zadejte datum narození ve tvaru RRMMDD (např.: 2.9.2001 = 010902), nebo
      rodné číslo bez lomítka.
    </div>
  ),
  aresSearch: (
    <div className="mb-2">
      Vyhledávání v registru ekonomických subjektů podle IČO nebo názvu (min. 4
      znaky). Vyhledávání vám pomůže najít přesný oficiální název subjektu.
    </div>
  ),
  ico: (
    <div className="mb-2">Zadejte IČO včetně případných nul na začátku.</div>
  ),
  nazev: (
    <div className="mb-2">
      Pokud zadáte nejednoznačný název, může dojít ke shodě jmen u více subjektů
      a v prvním vyhledávání nebudou zobrazena kompletní informace o evidenci
      práv.
    </div>
  ),
}

export const cuzkLvFormHelp = {
  datumK: (
    <div className="mb-2">
      Toto pole vyplňte pouze v případě, že chcete stáhnout list vlastnictví k
      určitému datu v minulosti. Pokud pole ponecháte prázdné, bude stáhnut list
      vlastnictví s aktuálními informacemi. Formát DD.MM.RRRR znamená dny i
      měsíce včetně případných nul na začátku, např: 01.01.2021.
    </div>
  ),
  katUz: (
    <div className="mb-2">
      Pro potřeby identifikace LV je zapotřebí znát kód katastrálního území
      (6místné číslo). Pokud do tohoto pole vložíte číselný kód, zobrazí se
      název katastrálního území a název obce pro snadnější identifikaci. Pokud
      začnete psát, zobrazí se odpovídající názvy katastrálních území.
    </div>
  ),
  lv: <div className="mb-2">Pořadové číslo listu vlastnictví.</div>,
  labels: (
    <div className="mb-2">
      Štítky slouží ke kategorizaci a propojení údajů v rámci aplikace. Štítek
      můžete přiřadit k libovolnému počtu nejrůznějších záznamů napříč aplikací
      a propojit tak informace, týkající se stejného obchodního případu.
    </div>
  ),
}

export const propertyNoteFormHelp = (
  <ul className="list-disc">
    <li>
      Poznámka se zobrazí v detailech všech nemovitostí v rámci stejného listu
      vlastnictví, pod textem však bude uveden název konkrétní nemovitosti,
      kterou jste si prohlíželi během přidávání poznámky.
    </li>
    <li>Poznámku uvidí všichni uživatelé vašeho zákaznického účtu.</li>
    <li>Přidanou poznámku můžete zpětně editovat či smazat.</li>
    <li>
      Kliknutím na tlačítko &quot;Přidat URL odkaz&quot; můžete vkládat odkazy
      na externí weby. Pokud uvedete jeho název, zobrazí se u komentáře jako
      proklikávací odkaz, v opačném případě se propíše celé znění URL adresy.
    </li>
    <li>
      Kliknutím na ikonu můžete nastavit její vzhled, ikona se zobrazí na
      vertikální ose vedle poznámky.
    </li>
    <li>
      Při psaní poznámky můžete využít klávesové zkratky{' '}
      <Typography.Text className="text-xs" keyboard>
        Ctrl
      </Typography.Text>
      +
      <Typography.Text className="text-xs" keyboard>
        Enter
      </Typography.Text>{' '}
      pro odeslání formuláře.
    </li>
    <li>
      Maximální délka poznámky je 5 000 znaků, maximální délka názvu odkazu 200
      znaků a maximální počet odkazů 50.
    </li>
  </ul>
)

export const postServiceFormHelp = {
  aresSearch: (
    <div className="mb-2">
      Automatické doplnění údajů pomocí vyhledávání v registru ekonomických
      subjektů podle IČO nebo názvu (min. 4 znaky).
    </div>
  ),
  companyName: (
    <div className="mb-2">
      Název společnosti, které adresujete dopis, případně upřesňující informace,
      které pomohou doručovateli s identifikací poštovní schránky.
    </div>
  ),
  name: (
    <div className="mb-2 -mt-6">
      Česká pošta vyžaduje vyplnění křestního jména i příjmení, pokud však
      posíláte dopis společnosti, u které přesného adresáta neznáte, můžete
      zadat jméno např. &quot;Odpovědná osoba&quot;, &quot;Účetní
      oddělení&quot;, &quot;Vedoucí zásobování&quot; apod., následně prosím
      zkontrolujte, zda nebylo automaticky doplněno pole oslovení a pohlaví.
    </div>
  ),
  addressSearch: (
    <div className="mb-2">
      Vyhledávání v registru adresních míst (min. 4 znaky).
    </div>
  ),
  street: (
    <div className="mb-2">
      Pokud se jedná o malou obec bez názvů ulic, uveďte název obce.
    </div>
  ),
  zipCode: (
    <div className="mb-2">
      PSČ pro Českou a Slovenskou republiku musí mít právě 5 číslic bez mezer.
    </div>
  ),
  country: (
    <div className="mb-2">
      Pokud rozesíláte dopisy do zahraničí, nezapomeňte při následném podání
      pošty zvolit adekvátní mezinárodní způsob doručení, jinak pošta zásilku
      nedoručí. Ze stejného důvodu nekombinujte zahraniční a tuzemské příjemce
      do jednoho seznamu adresátů. Do jiných států než je uvedeno v rozbalovacím
      seznamu, nelze poštu odeslat.
    </div>
  ),
  callName: (
    <div className="mb-2">
      Oslovení není povinné, ani nebude vytištěno na obálce. Používá se jako
      proměnná při vytváření personalizovaného textu v dopise.
    </div>
  ),
  gender: (
    <div className="mb-2 -mt-1">
      Výběr pohlaví slouží pro správné nastavení proměnné oslovení v textu
      dopisu. Pro muže &quot;Vážený pane&quot;, pro ženy &quot;Vážená
      paní&quot;, jinak &quot;Vážený pane, vážená paní&quot;.
    </div>
  ),
  variables: (
    <div className="mb-2">
      Můžete nadefinovat vlastní proměnné a následně je vkládat do textu při
      tvorbě šablony dopisu. Díky tomu budou v každém dopise dynamicky doplněny
      adekvátní údaje pro daného adresáta.
    </div>
  ),
}
