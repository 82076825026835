export interface MonitEstimateResponse {
  model_version: string
  cena_m2_nab: number
  cena_nab: number
  cena_m2_real: number
  cena_real: number
  time_series_graph: string
  decomp_real: { [key: string]: string }[]
  decomp_nab: { [key: string]: string }[]
  id?: string
}

export interface MonitEstimateInput {
  monit_object: {
    datum: string
    typ_nemovitosti: 0 | 1 | 2
    geo_loc: { lat: number; lon: number }
    kod_obce: number
    dispozice_mistnosti: number
    druh_konstrukce: number
    druh_vlastnictvi: number
    object_flags: number
    pl_balkonu?: number
    pl_uzitna: number
    podlazi_umisteni: number
    prislusenstvi_fl?: number
    reduced_flags: number
    reduced_stav: number
    umisteni_nemovitosti: number
    zarizeno: number
  }
  note?: string
  result_quantile: number
}

export interface MonitDecompData {
  tag?: string
  value: number
  mask?: number
}

export const monitTypObjektu = {
  0: 'Rodinný dům',
  2: 'Nájemní dům',
  3: 'Vila',
  4: 'Chalupa',
  5: 'Chata',
  255: 'Ostatní domy',
}

export const monitTypPozemku = {
  0: 'Stavební',
  1: 'Orná půda',
  4: 'Zahrada',
}

export const monitDruhVlastnictvi = {
  0: 'Osobní',
  1: 'Družstevní',
}

export const monitZarizeno = {
  0: 'Ano',
  1: 'Částečně',
  2: 'Ne',
}

export const monitUmisteniNemovitosti = {
  0: 'Rušná část obce',
  1: 'Centrum obce',
  2: 'Klidná část obce',
  3: 'Sídliště',
  4: 'Okraj obce',
  5: 'Polosamota',
  6: 'Samota',
  7: 'Venkov',
  8: 'Průmyslová zóna',
}

export const monitEnbTrida = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E',
  5: 'F',
  6: 'G',
}

export const monitEnbPopis = {
  A: 'mimořádně úsporné',
  B: 'velmi úsporné',
  C: 'úsporné',
  D: 'méně úsporné',
  E: 'nehospodárné',
  F: 'velmi nehospodárné',
  G: 'mimořádně nehospodárné',
}

export const monitDruhKonstrukce = {
  0: 'Cihlová',
  1: 'Panelová',
  2: 'Skeletová',
  3: 'Smíšená',
  4: 'Dřevěná',
  5: 'Kamenná',
  6: 'Montovaná',
}

export const monitDruhKonstrukceByt = {
  0: 'Jiná',
  1: 'Panelová',
}

export const monitReduceStav = {
  0: 'Výborný',
  1: 'Velmi dobrý',
  2: 'Dobrý',
  3: 'Před rekonstrukcí',
  4: 'Špatný',
}

export const monitUnitTitleName = {
  0: 'jednotky', // custom
  1: 'bytu',
  2: 'ateliéru',
  3: 'garáže',
  4: 'dílny/provozovny',
  5: 'jiného nebytového prostoru',
  6: 'rozestavěné jednotky',
  7: 'skupiny bytů',
  8: 'skupiny nebytových prostorů',
  9: 'skupiny bytů a nebytových prostorů',
}

export const monitAreaTitleName = {
  0: 'pozemku', // custom
  2: 'orné půdy',
  3: 'chmelnice',
  4: 'vinice',
  5: 'zahrady',
  6: 'ovocného sadu',
  7: 'trvalého travního porostu',
  10: 'lesního pozemku',
  11: 'vodní plochy',
  13: 'zastavěné plochy a nádvoří',
  14: 'ostatní plochy',
}

export const monitDispoziceMistnosti = {
  1: '1+kk',
  2: '1+1',
  3: '2+kk',
  4: '2+1',
  5: '3+kk',
  6: '3+1',
  7: '4+kk',
  8: '4+1',
  9: '5+kk',
  10: '5+1',
  11: '5+2',
  14: '6+kk',
  15: '6+1',
  16: '6+2',
  17: '7+kk',
  18: '7+1',
}

export const monitPolohaDomu = {
  0: 'Samostatný',
  1: 'V bloku',
  2: 'Rohový',
  3: 'Řadový',
}

export const RuianToMonitDruhKonstrukce = {
  1: 3, // Cihly, tvárnice, cihlové bloky =>
  2: 5, // Kámen => kamenná
  4: 1, // Stěnové panely => panelová
  5: 0, // Nepálené cihly => cihlová
  6: 4, // Dřevo => dřevěná
  7: 3, // Jiné materiály a kombinace => smíšená
  10: 3, // Kámen, cihly, tvárnice vč. kombinací => smíšená
  default: 3, // else => smíšená
}

export const RuianToMonitTypObjektu = {
  6: 2, // bytový dům => Nájemní dům
  7: 0, // rodinný dům => Rodinný dům
  3: 4, // objekt k bydlení => Chalupa
  8: 5, // stavba pro rodinnou rekreaci => Chata
  // others not matching
  18: 255, // garáž
  1: 255, // průmyslový objekt
  2: 255, // zemědělská usedlost
  4: 255, // objekt lesního hospodářství
  5: 255, // objekt občanské vybavenosti
  9: 255, // stavba pro shromažďování většího počtu osob
  10: 255, // stavba pro obchod
  11: 255, // stavba ubytovacího zařízení
  12: 255, // stavba pro výrobu a skladování
  13: 255, // zemědělská stavba
  14: 255, // stavba pro administrativu
  15: 255, // stavba občanského vybavení
  16: 255, // stavba technického vybavení
  17: 255, // stavba pro dopravu
  19: 255, // jiná stavba
  20: 255, // víceúčelová stavba
  21: 255, // skleník
  22: 255, // přehrada
  23: 255, // hráz přehrazující vodní tok nebo údolí
  24: 255, // hráz k ochraně nemovitostí před zaplavením při povodni
  25: 255, // hráz ohrazující umělou vodní nádrž
  26: 255, // jez
  27: 255, // stavba k plaveb.účelům v korytech nebo na březích vod.toků
  28: 255, // stavba k využití vodní energie (vodní elektrárna)
  29: 255, // stavba odkaliště
  30: 255, // stavba odkaliště
  default: 255, // else => Ostatní domy
}

export const RuianToMonitFlagsGully = {
  1: 0x00000800, // Kanalizační síť => veřejná kanalizace
  2: 0x00000400, // Vlastní čistička odpadních vod => čistička odpadních vod
  3: 0x00000200, // Žumpa, jímka, septi => septik
  default: 0,
}

export const RuianToMonitFlagsHeating = {
  1: 0x08000000, // Centrální dálkové (kotel mimo stavbu) => Lokální vytápění
  2: 0x01000000, // Centrální domovní (kotel ve stavbě) => Ústřední vytápění
  default: 0,
}

export const prislusenstvi_fl_values = {
  0x00000008: 'sklep',
  0x00000020: 'dvojgaráž',
  0x00000004: 'terasa',
  0x00000010: 'garáž',
  0x0200: 'výtah',
} as const

export const reduced_flags_values = {
  0x0001: 'přípojka vodovodu',
  0x0002: 'přípojka elektřiny',
  0x0004: 'přípojka plynu',
  0x0008: 'veřejné kanalizace',
  0x0010: 'samota/polosamota',
  0x0020: 'balkón, lodžie nebo terasa',
  // 0x0080: 'požaduje se prodejní cena',
  // 0x0100: 'požaduje se nabídková cena',
} as const

export const object_flags_values = {
  0x00000002: 'voda - místní zdroj',
  0x00000010: 'elektřina 400 V',
  0x00000200: 'septik',
  0x00000400: 'čistička odpadních vod',
  0x00000800: 'veřejná kanalizace',
  0x00400000: 'bazén',
  0x01000000: 'topení ústřední',
  0x02000000: 'topení plynové',
  0x04000000: 'topení elektrické',
  0x08000000: 'topení tuhá paliva',
  0x2000: 'parkovací stání',
  0x4000: 'garážové stání',
} as const

export const other_flags = {
  zarizeno: 'zařízeno',
  druh_konstrukce: 'druh konstrukce',
  nadzemni_podlazi: 'nadzemní podlaží',
  podlazi_umisteni: 'umístění v podlaží',
  pocet_bytu: 'počet bytů',
  umisteni_nemovitosti: 'umístění nemovitosti',
  enb_trida: 'ENB třída',
  reduced_stav: 'stav nemovitosti',
  poloha_domu: 'poloha domu',
  value: 'základní cena',
} as const
