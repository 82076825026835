import React, { FunctionComponent, useMemo } from 'react'
import Timeline, {
  DateHeader,
  ReactCalendarItemRendererProps,
  TimelineHeaders,
  TimelineItemBase,
} from 'react-calendar-timeline'

import { VlastnickePravo } from '../../../common/lvTypes'
import { RuianBuildingResponse } from '../../../common/ruianTypes'
import { useAppSelector } from '../../../redux/hooks'
import {
  getTimelineDatePoint,
  propertyTimelineEnd,
  propertyTimelineGroups,
  propertyTimelinePrimaryHeader,
  propertyTimelineProps,
  propertyTimelineSecondaryHeader,
  yearInMilliseconds,
} from '../../utils/propertyTimelineHelpers'
import { PropertyDetailTimelineMarkers } from '../PropertyDetailTimelineMarkers/PropertyDetailTimelineMarkers'
import { PropertyDetailTimelineTitle } from '../PropertyDetailTimelineTitle/PropertyDetailTimelineTitle'
import { PropertyTimelineItem } from '../PropertyTimelineItem/PropertyTimelineItem'
import { PropertyTimelineOwnershipTitle } from '../PropertyTimelineOwnershipTitle/PropertyTimelineOwnershipTitle'

export interface PropertyDetailTimelineOwnershipProps {
  showAll: boolean
  visibleTimeStart: number
  visibleTimeEnd: number
  onTimeChange: (start: number, end: number) => void
}

const propertyTimelineItemRender = (
  item: ReactCalendarItemRendererProps<TimelineItemBase<number>>
) => <PropertyTimelineItem getItemProps={item.getItemProps} item={item.item} />

export const PropertyDetailTimelineOwnership: FunctionComponent<PropertyDetailTimelineOwnershipProps> =
  props => {
    const timelineData = useAppSelector(state => state.property.timeline.data)
    const ruianData = useAppSelector(
      state => state.property.ruian.data as RuianBuildingResponse
    )

    const addressIds = useMemo(
      () => ruianData?.adresniMista?.map(adresa => adresa.kod) || [],
      [ruianData?.adresniMista]
    )

    const ownershipItems = useMemo(() => {
      if (!timelineData?.vlastnictvi?.length) return []

      const filtered = props.showAll
        ? timelineData.vlastnictvi
        : timelineData.vlastnictvi.filter(
            right =>
              getTimelineDatePoint(right?.platiDo) >
              propertyTimelineEnd - yearInMilliseconds * 7.5
          )

      const sorted = [...filtered].sort(
        (a: VlastnickePravo, b: VlastnickePravo) =>
          (a.subjekt.jmeno || '').localeCompare(b.subjekt.jmeno || '')
      )

      return sorted.map((vlastnictvi: VlastnickePravo, index: number) => ({
        id: index,
        group: 'ownership',
        end_time: getTimelineDatePoint(vlastnictvi.platiDo),
        start_time: getTimelineDatePoint(
          vlastnictvi.platiOd || timelineData?.sledovanoOd
        ),
        className: vlastnictvi.sjmPartner?.id
          ? 'hover:!h-[52px] !bg-gray-50'
          : '!bg-gray-50',
        title: (
          <PropertyTimelineOwnershipTitle
            addressIds={addressIds}
            vlastnictvi={vlastnictvi}
          />
        ),
      }))
    }, [
      addressIds,
      props.showAll,
      timelineData?.sledovanoOd,
      timelineData?.vlastnictvi,
    ])

    return (
      <>
        <PropertyDetailTimelineTitle title="Vlastnictví" />
        <Timeline
          {...propertyTimelineProps}
          groups={propertyTimelineGroups.ownership}
          items={ownershipItems}
          visibleTimeStart={props.visibleTimeStart}
          visibleTimeEnd={props.visibleTimeEnd}
          onTimeChange={props.onTimeChange}
          itemRenderer={propertyTimelineItemRender}
        >
          <TimelineHeaders>
            <DateHeader {...propertyTimelineSecondaryHeader} />
            <DateHeader {...propertyTimelinePrimaryHeader} />
          </TimelineHeaders>
          <PropertyDetailTimelineMarkers />
        </Timeline>
      </>
    )
  }
