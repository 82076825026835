import { Icon } from '@iconify/react'
import { Segmented } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import {
  ForclosureMonitoringFilterOnSubmit,
  ForclosureMonitoringFilterState,
} from '../../../common/foreclosureMonitoringTypes'
import { foreclosureMonitoringFilterLabels } from '../../utils/propertyMonitoringHelpers'
import { PropertyMonitoringFilterItem } from '../PropertyMonitoringFilterItem/PropertyMonitoringFilterItem'

export interface ForeclosureMonitoringFilterSegmentedProps {
  value: string | null
  filterKey: keyof ForclosureMonitoringFilterState
  handleChange: ForclosureMonitoringFilterOnSubmit
  className?: string
}

export const PropertyMonitoringFilterSegmented: FunctionComponent<ForeclosureMonitoringFilterSegmentedProps> =
  props => {
    const booleanOptions = useMemo(
      () => [
        {
          label: 'Ano',
          value: 'true',
        },
        {
          label: 'Ne',
          value: 'false',
        },
        {
          value: '',
          className: !props.value
            ? '!bg-white'
            : 'ant-segmented-item !basis-auto !grow-0 group',
          icon: (
            <Icon
              className="text-gray-600 group-hover:!text-red-500"
              icon="material-symbols-light:filter-alt-off-outline"
              width="18"
              height="28"
            />
          ),
        },
      ],
      [props.value]
    )

    const handleOnChange = useCallback(
      (value: string | number) =>
        props.handleChange({ [props.filterKey]: value }),
      [props]
    )

    return (
      <PropertyMonitoringFilterItem
        label={foreclosureMonitoringFilterLabels[props.filterKey]}
      >
        <Segmented
          block
          className={`min-w-[140px] ${props.className || ''}`}
          options={booleanOptions}
          value={props.value || ''}
          onChange={handleOnChange}
        />
      </PropertyMonitoringFilterItem>
    )
  }
