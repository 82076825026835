import React, { FunctionComponent } from 'react'

import { E_NABYVACI_TITULY } from '../../../common/cuzkLvTypes'
import { appendSpace, notEmpty, safeMap } from '../../../common/pdfHelpers'
import { CuzkLvHtmlBezZapisuFragment } from '../CuzkLvHtmlBezZapisuFragment/CuzkLvHtmlBezZapisuFragment'
import { CuzkLvHtmlIdentifikatorFragment } from '../CuzkLvHtmlIdentifikatorFragment/CuzkLvHtmlIdentifikatorFragment'
import { CuzkLvHtmlOpravSubjektFragment } from '../CuzkLvHtmlOpravSubjektFragment/CuzkLvHtmlOpravSubjektFragment'
import { CuzkLvHtmlRizeniFragment } from '../CuzkLvHtmlRizeniFragment/CuzkLvHtmlRizeniFragment'

export interface CuzkLvHtmlSekceEFragmentProps {
  eNabyvaciTituly?: E_NABYVACI_TITULY
  active?: boolean
}

export const CuzkLvHtmlSekceEFragment: FunctionComponent<CuzkLvHtmlSekceEFragmentProps> =
  props => {
    const E_NABYVACI_TITULY = props.eNabyvaciTituly
    const isEmpty = !notEmpty(E_NABYVACI_TITULY?.E_NABYVACI_TITUL)

    return (
      <div className="row">
        <div className="col col-xs-12">
          E nabývací tituly a jiné podklady zápisu
          {isEmpty && <CuzkLvHtmlBezZapisuFragment />}
        </div>
        {!isEmpty && (
          <>
            <div className="row mt-50">
              <i>Listina</i>
            </div>
            <div className="row">
              <ul className="-mt-50 pl-80 circleList">
                {safeMap(
                  E_NABYVACI_TITULY?.E_NABYVACI_TITUL,
                  (TITUL, index) => (
                    <li className="flexWrap mt-50" key={index}>
                      <div className="col-xs-12 bold">
                        {appendSpace(TITUL?.NAZEV_LIST_E?.tlist)}
                        {appendSpace(TITUL?.NAZEV_LIST_E?.dalsi_udaje)}
                        {appendSpace(TITUL?.NAZEV_LIST_E?.por_cis_zhot)}
                        {appendSpace(TITUL?.NAZEV_LIST_E?.popis)}
                        {appendSpace(TITUL?.NAZEV_LIST_E?.vystav_txt)}
                        {appendSpace(TITUL?.NAZEV_LIST_E?.prav_moc_txt)}
                        {appendSpace(TITUL?.NAZEV_LIST_E?.podani_txt)}
                        {appendSpace(TITUL?.NAZEV_LIST_E?.vykonatelnost_txt)}
                        {appendSpace(TITUL?.NAZEV_LIST_E?.podani_z_txt)}
                        {appendSpace(TITUL?.NAZEV_LIST_E?.zplatneni_txt)}
                        {appendSpace(TITUL?.NAZEV_LIST_E?.prares_nazev)}
                      </div>
                      <div className="row">
                        <div className="col-xs-5 col"></div>
                        <div className="col-xs-3 bold">{TITUL?.POLVZ}</div>
                        <div className="col-xs-4 bold">
                          <CuzkLvHtmlRizeniFragment
                            rizeni={TITUL?.IDENT_RIZENI}
                            active={props.active}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-8 col">
                          <i>Pro:</i>
                        </div>
                        <div className="col-xs-4 col">
                          <i>RČ/IČO:</i>
                        </div>
                      </div>
                      {safeMap(
                        TITUL?.NT_PRO?.OPRAV_SUBJEKT,
                        (OPRAV_SUBJEKT, index) => (
                          <div className="row" key={index}>
                            <div className="col-xs-8 col">
                              <strong>
                                <CuzkLvHtmlOpravSubjektFragment
                                  opravSubjekt={OPRAV_SUBJEKT?.oprav_subjekt}
                                  active={props.active}
                                />
                              </strong>
                            </div>
                            <div className="col-xs-4 col">
                              <strong>
                                <CuzkLvHtmlIdentifikatorFragment
                                  opravSubjekt={OPRAV_SUBJEKT?.oprav_subjekt}
                                  active={props.active}
                                />
                              </strong>
                            </div>
                          </div>
                        )
                      )}
                    </li>
                  )
                )}
              </ul>
            </div>
          </>
        )}
      </div>
    )
  }
