import 'react-quill/dist/quill.snow.css'

import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReactQuill from 'react-quill'

import { quillProps, replaceVariables } from '../../../common/postServicePdf'
import { RecipientListFragmentFragment } from '../../../graphql/generated'
import { CadastrePostSendLetter } from '../CadastrePostSendLetter/CadastrePostSendLetter'
import { CadastrePostVariablesDropdown } from '../CadastrePostVariablesDropdown/CadastrePostVariablesDropdown'
import { PostServicePdfViewer } from '../PostServicePdfViewer/PostServicePdfViewer'
import { PostServiceSaveTemplateButton } from '../PostServiceSaveTemplateButton/PostServiceSaveTemplateButton'
import { PostServiceSelectSampleRecipient } from '../PostServiceSelectSampleRecipient/PostServiceSelectSampleRecipient'
import { PostServiceTemplateButton } from '../PostServiceTemplateButton/PostServiceTemplateButton'

export interface CadastrePostLetterCreateProps {
  recipients: RecipientListFragmentFragment
  listId: string
}

export const CadastrePostLetterCreate: FunctionComponent<CadastrePostLetterCreateProps> =
  props => {
    const recipients =
      props.recipients.recipientListRecipientsByRecipientListId.nodes
    const [pdfInactive, setPdfInactive] = useState<boolean>(false)
    const [htmlData, setHtmlData] = useState('')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [recipient, setRecipient] = useState<any>(recipients?.[0])
    const [pdfData, setpdfData] = useState<string>('')
    const quillRef = useRef<ReactQuill>(null)

    const recipientsName = useMemo(
      () =>
        props.recipients.recipientListRecipientsByRecipientListId.nodes.map(
          item => ({
            value: item?.id,
            label: item?.recipientByRecipientId?.nazev,
          })
        ),
      [props.recipients?.recipientListRecipientsByRecipientListId?.nodes]
    )

    const handleSelectRecipient = useCallback(
      (id: string) => {
        setRecipient(
          recipients.find(item => item?.id === id) || recipients?.[0]
        )
        setPdfInactive(true)
      },
      [recipients]
    )

    const handleInsertText = useCallback((e: { key: string }) => {
      if (!quillRef.current) return
      const quill = quillRef.current.getEditor()
      quill.focus()
      quill.insertText(quill.getSelection()?.index || 0, e.key)
    }, [])

    const getTemplateData = useCallback(
      () => ({
        sample: quillRef.current?.editor?.getText().trim(),
        html: htmlData,
      }),
      [htmlData]
    )

    const setTemplate = useCallback((content: string) => {
      if (!quillRef.current) return

      return quillRef.current
        .getEditor()
        .clipboard.dangerouslyPasteHTML(content)
    }, [])

    const handlePdfActive = useCallback((html: string) => {
      setPdfInactive(true)
      setHtmlData(html)
    }, [])

    const handleUpdatePdf = useCallback(() => {
      const replaced = replaceVariables(htmlData, recipient)
      setpdfData(replaced)
      setPdfInactive(false)
    }, [htmlData, recipient])

    return (
      <div className="flex flex-wrap grow lg:flex-nowrap gap-8">
        <div className="w-full lg:w-1/2 flex flex-col">
          <ReactQuill
            className="h-full flex flex-col"
            ref={quillRef}
            onChange={handlePdfActive}
            {...quillProps}
          />
          <div className="mt-4 space-x-2 flex ">
            <CadastrePostVariablesDropdown
              handleInsertText={handleInsertText}
            />
            <PostServiceSelectSampleRecipient
              recipients={recipientsName}
              setRecipient={handleSelectRecipient}
            />
            <PostServiceTemplateButton setContent={setTemplate} />
            <PostServiceSaveTemplateButton
              data={getTemplateData}
              disabled={pdfInactive}
            />
          </div>
        </div>
        <div className="w-full lg:w-1/2 flex flex-col relative">
          <PostServicePdfViewer
            pdfData={pdfData}
            isPdfActive={pdfInactive}
            handleUpdatePdf={handleUpdatePdf}
          />
          <div className="mt-4 text-right z-10">
            <CadastrePostSendLetter
              listId={props.listId}
              data={htmlData}
              disabled={pdfInactive}
            />
          </div>
        </div>
      </div>
    )
  }
