import { InputNumber, Space, Tooltip } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import {
  AuctionMonitoringFilterOnSubmit,
  AuctionMonitoringFilterState,
} from '../../../common/auctionMonitoringTypes'
import { auctionMonitoringFilterLabels } from '../../utils/auctionMonitoringHelpers'
import { useConst } from '../../utils/useConst'
import { PropertyMonitoringFilterItem } from '../PropertyMonitoringFilterItem/PropertyMonitoringFilterItem'
import { PropertyMonitoringFilterResetButton } from '../PropertyMonitoringFilterResetButton/PropertyMonitoringFilterResetButton'

export interface AuctionMonitoringFilterRangeProps {
  valueFrom?: number | null
  valueTo?: number | null
  onChange: AuctionMonitoringFilterOnSubmit
  filterKeyFrom: keyof AuctionMonitoringFilterState
  filterKeyTo: keyof AuctionMonitoringFilterState
  disabled?: boolean
}

const defaultStyle = 'w-full '
const validStyle = defaultStyle + 'bg-green-50'
const invalidStyle = defaultStyle + 'bg-red-50'

export const AuctionMonitoringFilterRange: FunctionComponent<AuctionMonitoringFilterRangeProps> =
  props => {
    const maxLimit = useConst(
      props.filterKeyTo === 'priceTo' ? 9999999999 : 9999
    )

    const handleValueFromChange = useCallback(
      (value: number | null) => {
        props.onChange({ [props.filterKeyFrom]: value })
      },
      [props]
    )

    const handleValueToChange = useCallback(
      (value: number | null) => {
        props.onChange({ [props.filterKeyTo]: value })
      },
      [props]
    )

    const handleReset = useCallback(() => {
      props.onChange({ [props.filterKeyFrom]: null, [props.filterKeyTo]: null })
    }, [props])

    const className = useMemo(() => {
      if ((props.valueFrom || 1) > (props.valueTo || maxLimit))
        return invalidStyle

      if (props.valueTo) return validStyle

      return defaultStyle
    }, [props.valueFrom, props.valueTo, maxLimit])

    return (
      <PropertyMonitoringFilterItem
        label={auctionMonitoringFilterLabels.price}
        className={`col-span-2 sm:col-span-1 ${
          props.filterKeyTo === 'priceTo' ? 'md:w-64' : 'md:w-56'
        }`}
      >
        <Tooltip
          title="Cenu lze filtrovat pouze pokud je zahrnut stav 'Ukončené'"
          trigger={props.disabled ? 'hover' : ''}
        >
          <Space.Compact>
            <InputNumber
              controls={false}
              value={props.valueFrom}
              onChange={handleValueFromChange}
              className={props.valueFrom ? validStyle : defaultStyle}
              addonBefore="Od"
              placeholder="0"
              min={1}
              max={maxLimit}
              disabled={props.disabled}
            />
            <InputNumber
              rootClassName="addonBeforeCompactBorder"
              controls={false}
              value={props.valueTo}
              onChange={handleValueToChange}
              className={className}
              addonBefore="Do"
              placeholder="∞"
              min={1}
              max={maxLimit}
              disabled={props.disabled}
            />
            {(!!props.valueFrom || !!props.valueTo) && (
              <PropertyMonitoringFilterResetButton onClick={handleReset} />
            )}
          </Space.Compact>
        </Tooltip>
      </PropertyMonitoringFilterItem>
    )
  }
