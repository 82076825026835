import { Image } from 'antd'
import React, { FunctionComponent, useState } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { imageSkeleton } from '../../utils/layoutConst'
import { useDetailDrawerPropertyImage } from '../../utils/usePropertyDetailImage'
import { usePropertyImagePreviewProps } from '../../utils/usePropertyImagePreviewProps'
import { MAP_TYPE, MapTypeButton } from '../MapTypeButton/MapTypeButton'

export interface DetailDrawerPropertyImageProps {
  propertyType: PROPERTY_TYPES
  propertyId: string
  rings?: number[][][]
}

export const DetailDrawerPropertyImage: FunctionComponent<DetailDrawerPropertyImageProps> =
  ({ propertyType, rings, propertyId }) => {
    const imageState = useState(MAP_TYPE.SW)
    const mapImage = useDetailDrawerPropertyImage(
      propertyId,
      propertyType,
      rings
    )
    const previewProps = usePropertyImagePreviewProps(imageState, mapImage)

    return (
      <div className="relative flex my-3">
        <Image
          preview={previewProps}
          fallback={imageSkeleton}
          src={mapImage[imageState[0]].src || mapImage[MAP_TYPE.AERIAL].src}
          alt="Fotografie / mapa nemovitosti"
          className="rounded-sm object-cover !min-h-[450px]"
          wrapperClassName="w-full"
        />
        <div className="absolute bottom-2 left-2 p-2 flex space-x-2">
          <MapTypeButton
            disabled={!mapImage[MAP_TYPE.SW].src}
            type={MAP_TYPE.SW}
            imageState={imageState}
          />
          <MapTypeButton type={MAP_TYPE.AERIAL} imageState={imageState} />
          <MapTypeButton type={MAP_TYPE.MAP} imageState={imageState} />
        </div>
      </div>
    )
  }
