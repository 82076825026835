import { Alert, Button, notification, Spin } from 'antd'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import { BASE_URL } from '../../../ini.json'
import { useAppSelector } from '../../redux/hooks'
import { CuzkDocumentCollectionTable } from '../components/CuzkDocumentCollectionTable/CuzkDocumentCollectionTable'
import { Fade } from '../components/Fade/Fade'
import { PageLayout } from '../components/PageLayout/PageLayout'
import { proceedingDetailPath, proceedingPath } from '../utils/paths'

export const CuzkDocumentCollection: FunctionComponent = () => {
  const tenantId = useAppSelector(state => state.myProfile.tenantId)
  const userId = useAppSelector(state => state.myProfile.userId)
  const token = useAppSelector(state => state.myProfile.token)
  const [documentCollectionId, setDocumentCollectionId] = useState()
  const [loading, setLoading] = useState(false)
  const {
    params: { id, proceeding },
  } = useRouteMatch<{ id: string; proceeding: string }>()

  const [api, contextHolder] = notification.useNotification()

  const openNotification = useCallback(
    (text: string) => {
      api.open({
        message: 'ČÚZK nevrací dokument',
        description: (
          <>
            <div>{text}</div>
            <div>
              <Link to={proceedingDetailPath(proceeding)}>
                <Button type="primary" ghost className="mt-4" size="small">
                  Zpět na detail řízení
                </Button>
              </Link>
            </div>
          </>
        ),
        duration: 0,
        type: 'error',
        placement: 'top',
      })
    },
    [api, proceeding]
  )

  useEffect(() => {
    if (documentCollectionId || !id || !proceeding) return
    setLoading(true)
    const getDocumentCollectionId = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/cuzk/sbirkaListin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token,
            tenantId,
            userId,
            cuzkDocumentId: id,
            proceeding: proceeding,
          }),
        })
        const data = await response.json()
        if (response.status === 404) return openNotification(data)
        setDocumentCollectionId(data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    getDocumentCollectionId()
  }, [
    documentCollectionId,
    id,
    openNotification,
    proceeding,
    tenantId,
    token,
    userId,
  ])

  return (
    <PageLayout
      title="Sbírka listin"
      extra={
        <Link to={proceedingPath()}>
          <Button type="primary">Vyhledat sbírku listin</Button>
        </Link>
      }
    >
      {contextHolder}
      <Fade show={loading} className="mb-4">
        <Spin spinning>
          <Alert
            message="Načítání údajů z ČÚZK"
            description="Probíhá načítání údajů z ČÚZK. Prosím, počkejte, než bude proces dokončen."
            type="info"
          />
        </Spin>
      </Fade>
      <CuzkDocumentCollectionTable
        documentCollectionId={documentCollectionId}
      />
    </PageLayout>
  )
}

export default CuzkDocumentCollection
