import React, { FunctionComponent } from 'react'

import { pricelist } from '../../utils/pricelist'

interface CeeMonitorAddSubjectModalDescriptionProps {
  checkFrequency: number | null
}

export const CeeMonitorAddSubjectModalDescription: FunctionComponent<CeeMonitorAddSubjectModalDescriptionProps> =
  ({ checkFrequency }) => {
    return (
      <div className="text-gray-400">
        Služba je zpoplatněna {pricelist.CEE} Kr. za každé ověření subjektu v
        Centrální evidenci exekucí. Ke stržení kreditu dochází vždy před
        zahájením samotné kontroly.{' '}
        {!!checkFrequency && checkFrequency > 0 && (
          <span>
            <br />
            Pokud je daný subjekt v CEE nalezen, automaticky se pozastaví další
            pravidelné kontroly. Stejně tak můžete frekvenci kontrol kdykoli
            změnit sami.
          </span>
        )}
      </div>
    )
  }
