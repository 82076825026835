import { Button, Divider, Modal } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ExecutionResult } from 'urql'

import { useCreateMonitoringCuzkRcMutation } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { checkCuzkSubject } from '../../utils/checkCuzkSubject'
import { cuzkOsInstantCheckLimit } from '../../utils/layoutConst'
import { parseCzechDate } from '../../utils/parseCzechDate'
import { cuzkMonitorRcPath } from '../../utils/paths'
import { pricelist } from '../../utils/pricelist'
import { useAssignLabels } from '../../utils/useAssignLabels'
import { useDeclension } from '../../utils/useDeclension'
import { useToggleState } from '../../utils/useToggleState'
import { MonitorAddSubjectModal } from '../CeeMonitorAddSubjectModal/CeeMonitorAddSubjectModal'
import { CheckFrequencySelectInput } from '../CheckFrequencySelectInput/CheckFrequencySelectInput'
import { LabelSelectInput } from '../LabelSelectInput/LabelSelectInput'
import { RcTableItemConcept } from '../RcInput/RcInput'

export const CuzkMonitorAddSubjectModal: FunctionComponent<MonitorAddSubjectModal> =
  props => {
    const history = useHistory()
    const token = useAppSelector(state => state.myProfile.token)
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const userId = useAppSelector(state => state.myProfile.userId)
    const decline = useDeclension()

    const [, addMonitoredSubject] = useCreateMonitoringCuzkRcMutation()
    const { assignLabels } = useAssignLabels()

    const [open, handleModal] = useToggleState(false)
    const [uploading, setUploading] = useState(false)
    const [checkFrequency, setCheckFrequency] = useState<number | null>(0)
    const [labelsId, setLabelsId] = useState<string[] | []>([])

    const typedCount = props.dataSource.length
    const subjectCount = useMemo(() => {
      let valid = 0
      let invalid = 0
      let notValidated = 0
      let forCheck = 0

      typedCount &&
        props.dataSource.forEach(item => {
          item.validatedCode === 30
            ? valid++
            : item.validatedCode && item.validatedCode < 10
            ? invalid++
            : notValidated++
        })
      forCheck = valid + notValidated
      const counts = { valid, invalid, notValidated, forCheck }
      return counts
    }, [props.dataSource, typedCount])

    const submitItems = useCallback(() => {
      setUploading(true)
      const createdRecordsIds: string[] = []
      const promises = props.dataSource.map(async item => {
        if (item.validatedCode && item.validatedCode > 10) {
          try {
            await addMonitoredSubject({
              input: {
                monitoringCuzkRc: {
                  rc: item.rc,
                  ico: item.ico,
                  birthDate: parseCzechDate(item.birthDate || ''),
                  firstname: item.firstname,
                  lastname: item.lastname,
                  validatedCode: item.validatedCode,
                  checkFrequency: checkFrequency,
                  accountId: tenantId,
                  accountUserId: userId,
                  description: item.description || '',
                  monitoringCeeId: item.monitoringCeeId,
                },
              },
            }).then(async (result: ExecutionResult) => {
              const monitoringCuzkRcId =
                result.data?.createMonitoringCuzkRc?.monitoringCuzkRc?.id

              monitoringCuzkRcId && createdRecordsIds.push(monitoringCuzkRcId)

              await assignLabels({
                monitoringCuzkRcId,
                labelsId,
              })
            })
          } catch (error) {
            // TODO: handle error
          }
        }
      })

      Promise.all(promises).then(async () => {
        if (createdRecordsIds.length <= cuzkOsInstantCheckLimit) {
          await checkCuzkSubject(createdRecordsIds, token)
        }
        props.noRedirect ? handleModal() : history.push(cuzkMonitorRcPath())
        props.refetch?.()
      })
      // .catch(error => {
      //     TODO: handle error
      // })
    }, [
      props,
      addMonitoredSubject,
      checkFrequency,
      tenantId,
      userId,
      assignLabels,
      labelsId,
      handleModal,
      history,
      token,
    ])

    const buttonProps = useMemo(
      () => ({
        ok: { disabled: uploading, loading: uploading },
        cancel: { disabled: uploading },
      }),
      [uploading]
    )

    const formatSubject = useCallback((subject: RcTableItemConcept) => {
      return (
        (subject.firstname && `${subject.firstname} ${subject.lastname}`) ||
        subject.description ||
        (subject.rc && `RČ: ${subject.rc}`) ||
        (subject.ico && `IČO: ${subject.ico}`)
      )
    }, [])

    const modalTitle = useMemo(
      () => (
        <div>
          <div>
            Ověření {subjectCount.forCheck !== 1 ? 'subjektů' : 'subjektu'} v
            evidenci ČÚZK
          </div>
          <div className="text-gray-400">
            {props.dataSource
              .filter(subject => subject.validatedCode)
              .map(formatSubject)
              .slice(0, 10)
              .join(', ')}
            {subjectCount.forCheck > 10 ? ', ...' : ''}
          </div>
        </div>
      ),
      [formatSubject, props.dataSource, subjectCount.forCheck]
    )

    const validationText = useMemo(
      () => (
        <>
          {decline(subjectCount.forCheck, 'willCheck')}{' '}
          <span className="font-bold">{subjectCount.forCheck}</span>{' '}
          {decline(subjectCount.forCheck, 'subject')}.
          {!!subjectCount.invalid && (
            <span className="text-gray-500">
              <br /> {subjectCount.invalid}{' '}
              {decline(subjectCount.invalid, 'notValidOut')}.
            </span>
          )}
        </>
      ),

      [decline, subjectCount.forCheck, subjectCount.invalid]
    )

    return (
      <>
        <Button
          size="large"
          type="primary"
          onClick={handleModal}
          disabled={!subjectCount.forCheck}
          className="sm:w-auto w-full"
          title="Ověřit výskyt v evidenci katastrálního úřadu"
          {...props.buttonProps}
        >
          {props.buttonText || 'Ověřit zadané subjekty v ČÚZK'}
        </Button>
        <Modal
          title={modalTitle}
          open={open}
          onOk={submitItems}
          onCancel={handleModal}
          okText="Souhlasím a dokončit"
          cancelText="Zrušit"
          okButtonProps={buttonProps.ok}
          cancelButtonProps={buttonProps.cancel}
        >
          <div>
            <p className="mb-2 mt-6">
              Vyberte, jak často chcete vybrané subjekty kontrolovat v evidenci
              katastrálního úřadu.
            </p>
            <CheckFrequencySelectInput
              disabled={uploading}
              onChange={setCheckFrequency}
            />

            <p className="mb-2 mt-6">
              Štítkem můžete označit celou skupinu právě vkládaných záznamů.
            </p>
            <LabelSelectInput disabled={uploading} onChange={setLabelsId} />

            <div className="my-4">
              {validationText}
              <div className="pt-4 mt-4 border-t">
                Služba je zpoplatněna {pricelist.CUZK} Kr. za každé ověření
                subjektu v evidenci osob ČÚZK. Ke stržení kreditu dochází vždy
                před zahájením samotné kontroly.{' '}
                {!!checkFrequency && checkFrequency > 0 && (
                  <span>
                    <br />
                    Pokud je daný subjekt nalezen v evidenci ČÚZK, automaticky
                    se pozastaví další pravidelné kontroly. Stejně tak můžete
                    frekvenci kontrol kdykoli změnit sami.
                  </span>
                )}
              </div>
            </div>
            <Divider />
          </div>
        </Modal>
      </>
    )
  }
