import { Typography } from 'antd'
import React, { FunctionComponent, memo, useCallback } from 'react'

import { AuctionMonitoringRecord } from '../../../common/auctionMonitoringTypes'
import {
  AUCTION_STATES,
  AUCTION_TYPES,
} from '../../../common/auctionScrapeTypes'
import { setAuctionDrawer } from '../../../redux/drawerSlice'
import { useAppDispatch } from '../../../redux/hooks'
import { AuctionMonitoringTilesItemFooter } from '../AuctionMonitoringTilesItemFooter/AuctionMonitoringTilesItemFooter'
import { AuctionMonitoringTilesItemImage } from '../AuctionMonitoringTilesItemImage/AuctionMonitoringTilesItemImage'
import { Min } from '../DateFormat/DateFormat'
import { Money } from '../Money/Money'
import { PropertyMonitoringTilesItemRibbon } from '../PropertyMonitoringTilesItemRibbon/PropertyMonitoringTilesItemRibbon'
import { PropertyMonitoringTilesItemRow } from '../PropertyMonitoringTilesItemRow/PropertyMonitoringTilesItemRow'

export type AuctionMonitoringTilesItemProps = AuctionMonitoringRecord

export const AuctionMonitoringTilesItem: FunctionComponent<AuctionMonitoringTilesItemProps> =
  memo(props => {
    const dispatch = useAppDispatch()
    const setDrawer = useCallback(
      () =>
        dispatch(setAuctionDrawer({ id: props.id, auctionType: props.type })),
      [dispatch, props.id, props.type]
    )

    return (
      <PropertyMonitoringTilesItemRibbon
        alertDateTo={props.updatedAt || props.createdAt}
        userActivityLog={props.activityLogs?.nodes?.[0]}
      >
        <div
          className={`flex flex-col justify-between group rounded-lg border overflow-hidden shadow-sm hover:shadow-lg h-full ${
            props.activityLogs?.nodes?.[0]
              ? 'opacity-70 hover:opacity-100 transition-all'
              : ''
          }`}
        >
          <div className="relative w-full pb-full">
            <AuctionMonitoringTilesItemImage
              className="h-80 lg:h-96 2xl:h-80 4xl:h-96"
              lat={props.lat}
              long={props.long}
              auctionType={props.type}
              auctionId={props.id}
              photoUrl={props.photoUrl}
              auctionUrl={props.detailUrl}
            />
          </div>
          <div className="flex flex-col justify-between grow">
            <div className="p-5 pb-0 transition-all text-gray-400">
              <Typography.Title
                onClick={setDrawer}
                level={4}
                className="!mb-4 text-gray-800 first-letter:uppercase cursor-pointer hover:!text-primary"
                title="Zobrazit detail dražby"
              >
                {props.title}
              </Typography.Title>

              <PropertyMonitoringTilesItemRow
                label="Stav"
                value={
                  props.state === AUCTION_STATES.UPCOMING
                    ? 'Připravovaná'
                    : 'Ukončená'
                }
              />
              <PropertyMonitoringTilesItemRow
                label="Dražební vyhláška"
                value={
                  props.auctionDecreeUrl ? (
                    <a
                      href={props.auctionDecreeUrl}
                      target="_blank"
                      rel="noreferrer"
                      title="Zobrazit dražební vyhlášku v novém okně"
                    >
                      <span>Stáhnout</span>
                    </a>
                  ) : (
                    '---'
                  )
                }
              />
              <PropertyMonitoringTilesItemRow
                label="Datum zahájení"
                value={props.startDate && <Min>{props.startDate}</Min>}
              />
              {props.endDate && (
                <PropertyMonitoringTilesItemRow
                  label="Datum ukončení"
                  value={<Min>{props.endDate}</Min>}
                />
              )}

              <PropertyMonitoringTilesItemRow
                label="Cena"
                value={
                  props.price && props.price > 0 ? (
                    <Money
                      grayscale
                      amount={props.price}
                      decimalNumbers={0}
                      symbol="Kč"
                    />
                  ) : (
                    '---'
                  )
                }
              />
            </div>
          </div>
          <AuctionMonitoringTilesItemFooter
            id={props.id}
            auctionType={props.type?.[0] as AUCTION_TYPES}
            auctionUrl={props.detailUrl}
          />
        </div>
      </PropertyMonitoringTilesItemRibbon>
    )
  })
