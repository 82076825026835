import { Table } from 'antd'
import { ColumnsType, ExpandableConfig } from 'antd/es/table/interface'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Link } from 'react-router-dom'

import { usePostServiceRecipientListTableQuery } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { tablePagination } from '../../utils/layoutConst'
import { postServiceSentListPath } from '../../utils/paths'
import { ExpandColumnTitle } from '../ExpandColumnTitle/ExpandColumnTitle'
import { ExpandIcon } from '../ExpandIcon/ExpandIcon'
import { PostServiceCreateRecipientListModalButton } from '../PostServiceCreateRecipientListModalButton/PostServiceCreateRecipientListModalButton'
import { PostServiceRecipientListTableAction } from '../PostServiceRecipientListTableAction/PostServiceRecipientListTableAction'
import { PostServiceRecipientListTableExpandedRow } from '../PostServiceRecipientListTableExpandedRow/PostServiceRecipientListTableExpandedRow'
import { TableCellLabels } from '../TableCellLabels/TableCellLabels'
import { TableSummaryExport } from '../TableSummaryExport/TableSummaryExport'

export interface PostServiceRecipientListItem {
  createdAt: Date
  description?: string
  listName: string
  id: string
  recipients: {
    totalCount: number
  }
  letters: {
    totalCount: number
  }
}

const tableId = 'postServiceRecipientList-table'

export const PostServiceRecipientListTable: FunctionComponent = () => {
  const tenantId = useAppSelector(state => state.myProfile.tenantId)

  const [dataSource, setDataSource] = useState<PostServiceRecipientListItem[]>(
    []
  )
  const expandedRowKeys = useAppSelector(
    state => state.expandedRow.tables?.[tableId]
  )

  const [queryResult, refetch] = usePostServiceRecipientListTableQuery({
    variables: { accountId: tenantId },
  })

  useEffect(() => {
    if (queryResult.data?.allPostserviceLists?.nodes?.[0]?.id) {
      setDataSource(
        queryResult.data?.allPostserviceLists
          ?.nodes as PostServiceRecipientListItem[]
      )
    }
  }, [queryResult.data?.allPostserviceLists?.nodes])

  const columns = useMemo<ColumnsType<PostServiceRecipientListItem>>(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        responsive: ['' as Breakpoint],
      },
      {
        title: 'Adresáti',
        dataIndex: 'recipientsCount',
        className: 'w-28 text-right',
        sorter: {
          compare: (a, b) => a.recipients.totalCount - b.recipients.totalCount,
        },
        render: (_, record) => record.recipients.totalCount,
      },
      {
        title: 'Štítek',
        dataIndex: 'labels',
        className: 'w-48',
        render: (labels, record) => (
          <TableCellLabels
            labels={labels}
            labelEntityType="postserviceListId"
            recordId={record.id}
            refetch={refetch}
            modalTitle={record.listName}
          />
        ),
      },
      {
        title: 'Název',
        dataIndex: 'listName',
        sorter: {
          compare: (a, b) => a.listName.localeCompare(b.listName),
        },
      },
      {
        title: 'Popis',
        dataIndex: 'description',
        sorter: {
          compare: (a, b) =>
            a.description && b.description
              ? a.description.localeCompare(b.description)
              : -1,
        },
      },
      {
        title: 'Obesláno',
        dataIndex: 'letter',
        className: 'w-20',
        render: (_, record) =>
          record.letters.totalCount === 0 ? (
            <span className="text-gray-400">NE</span>
          ) : (
            <Link
              to={postServiceSentListPath(record.id)}
              title="Zobrazit korespondenci obsahující seznam adresátů"
            >
              {record.letters.totalCount === 1
                ? 'ANO'
                : `ANO - ${record.letters.totalCount}x`}
            </Link>
          ),
        sorter: {
          compare: (a, b) => a.letters.totalCount - b.letters.totalCount,
        },
      },
      {
        title: 'Akce',
        dataIndex: 'action',
        className: 'w-48',

        render: (_, record) => (
          <PostServiceRecipientListTableAction
            reexecuteQuery={refetch}
            record={record}
          />
        ),
      },
    ],
    [refetch]
  )

  const expandable = useMemo<
    ExpandableConfig<PostServiceRecipientListItem>
  >(() => {
    return {
      expandedRowKeys,
      rowExpandable: record => record.recipients.totalCount > 0,
      expandedRowRender: record => (
        <PostServiceRecipientListTableExpandedRow
          listId={record.id}
          listName={record.listName}
          refetch={refetch}
        />
      ),
      columnTitle: (
        <ExpandColumnTitle
          allKeys={dataSource.map(record => record.id.toString())}
          tableId={tableId}
        />
      ),
      expandIcon: ({ expanded, record }) =>
        record.recipients.totalCount > 0 ? (
          <ExpandIcon
            expanded={expanded}
            recordId={record.id.toString()}
            tableId={tableId}
            expandable={!!record.id}
          />
        ) : null,
    }
  }, [dataSource, expandedRowKeys, refetch])

  const locale = useMemo(
    () => ({
      emptyText: !queryResult.fetching && (
        <div className="my-8">
          <div className="mb-2 text-gray-400">
            Zatím nemáte žádný seznam adresátů pro hromadnou poštu.
          </div>
          <PostServiceCreateRecipientListModalButton />
        </div>
      ),
    }),
    [queryResult.fetching]
  )

  const summary = useCallback(
    () => (
      <TableSummaryExport
        total={dataSource?.length || 0}
        colSpan={6}
        url="postService/sentXlsx"
        fileName="odeslana-posta"
        dataSource={dataSource || []}
        icon
      />
    ),
    [dataSource]
  )

  return (
    <Table
      locale={locale}
      id={tableId}
      rowKey="id"
      rowClassName="animate-fadeIn"
      size="small"
      dataSource={dataSource}
      columns={columns}
      loading={queryResult.fetching}
      expandable={expandable}
      summary={summary}
      pagination={tablePagination}
    />
  )
}
