import { QuestionCircleOutlined } from '@ant-design/icons'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

export interface OneLineHelpProps {
  children: React.ReactNode
  titleClose?: string
  className?: string
  classNameOpen?: string
  classNameClose?: string
}

export const OneLineHelp: FunctionComponent<OneLineHelpProps> = props => {
  const [expanded, setExpanded] = useState(false)

  const handleExpand = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])

  const content = useMemo(() => {
    if (expanded) {
      return (
        <span>
          {props.children}
          <button
            onClick={handleExpand}
            className="text-primary hover:text-primaryHover focus:outline-none ml-2"
          >
            skrýt
          </button>
        </span>
      )
    }

    return (
      <>
        <span className="inline-block w-full overflow-hidden whitespace-nowrap overflow-ellipsis text-gray-400">
          {props.titleClose || props.children}
        </span>
        <button
          onClick={handleExpand}
          className="text-primary hover:text-primaryHover focus:outline-none"
        >
          více
        </button>
      </>
    )
  }, [expanded, props.titleClose, props.children, handleExpand])

  const classNameProps = useMemo(() => {
    return `${props.className ? props.className : ''} ${
      props.classNameOpen && expanded ? props.classNameOpen : ''
    } ${props.classNameClose && !expanded ? props.classNameClose : ''}`
  }, [expanded, props.className, props.classNameClose, props.classNameOpen])

  return (
    <span className={`flex items-baseline ${classNameProps}`}>
      <QuestionCircleOutlined className="mr-2 mt-1 self-start text-gray-400" />
      {content}
    </span>
  )
}
