import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { CadastralImportEntity } from '../../../common/CadastralImportTypes'
import { useToggleState } from '../../utils/useToggleState'
import { CadastralAutoImportControllButton } from '../CadastralAutoImportControllButton/CadastralAutoImportControllButton'

export interface CadastralImportBoxHelpModalButtonProps {
  entityType: CadastralImportEntity
}

const entityName: Record<CadastralImportEntity, string> = {
  property: 'nemovitosti',
  proceeding: 'řízení',
  lv: 'listu vlastnictví',
}

export const CadastralImportBoxHelpModalButton: FunctionComponent<CadastralImportBoxHelpModalButtonProps> =
  props => {
    const [isOpen, toggleOpen] = useToggleState(false)

    const footer = useMemo(() => {
      return (
        <div className="flex justify-between">
          <CadastralAutoImportControllButton />
          <Button type="default" onClick={toggleOpen}>
            Zavřít nápovědu
          </Button>
        </div>
      )
    }, [toggleOpen])

    return (
      <>
        <span
          className="ml-2 whitespace-nowrap text-primary hover:text-primaryHover cursor-pointer"
          onClick={toggleOpen}
        >
          <QuestionCircleOutlined /> <span>Zobrazit nápovědu</span>
        </span>

        {isOpen && (
          <Modal
            title={
              <span>
                <QuestionCircleOutlined className="mr-2" />
                Import údajů o {entityName[props.entityType]}
              </span>
            }
            onCancel={toggleOpen}
            open={isOpen}
            footer={footer}
          >
            <div className="mb-2 mt-6">
              <div className="font-semibold">První náhled</div>
              <div>
                Pokud přistupujete k detailu {entityName[props.entityType]}{' '}
                poprvé, je nutné naimportovat si nejdříve údaje pomocí našeho
                rozšíření do prohlížeče. Díky této akci máte okamžitě k
                dispozici aktuální data zapsaná v katastru nemovitostí.
              </div>
            </div>

            <div className="mb-2">
              <div className="font-semibold">Opětovný náhled</div>
              <div>
                Při dalším zobrazení stejného detailu{' '}
                {entityName[props.entityType]} jsou zobrazovány údaje z
                posledního importu. V případě potřeby si tato data můžete stejně
                jednoduše aktualizovat. Datum poslední aktualizace najdete vždy
                v detailu.
              </div>
            </div>

            <div className="mb-8">
              <div className="font-semibold">Automatický import</div>
              <div>
                Chápeme, že ustavičné klikání na tlačítko &quot;Importovat
                údaje&quot; může být nekomfortní, a tak jsme vytvořili funkci
                automatického importu, která po aktivaci udělá všechnu práci za
                Vás a informace o {entityName[props.entityType]} se načtou
                automaticky. Toto nastavení lze kdykoliv změnit.
              </div>
            </div>
          </Modal>
        )}
      </>
    )
  }
