import { Tooltip } from 'antd'
import React, { FunctionComponent, memo } from 'react'

export interface InlineBadgeProps {
  label?: React.ReactNode
  className?: string
  tooltip?: React.ReactNode
  onClick?: () => void
}

export const InlineBadge: FunctionComponent<InlineBadgeProps> = memo(props => {
  if (!props.label) return null

  const style =
    (props.className || '') +
    ' text-xs rounded px-2 py-0.5 whitespace-nowrap inline-block'

  if (props.tooltip)
    return (
      <Tooltip className="cursor-help" title={props.tooltip}>
        <div className={style}>{props.label}</div>
      </Tooltip>
    )

  return (
    <div onClick={props.onClick} className={style}>
      {props.label}
    </div>
  )
})
