import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { FunctionComponent, memo, useMemo } from 'react'

import { AreaFilterUse } from '../../../common/foreclosureMonitoringTypes'
import { useAreaUseRelations } from '../../utils/useAreaTypeRelations'

export interface AreaUseSelectionFilterCheckboxTooltipProps {
  areaUseCode: number
  checkboxData: AreaFilterUse
}

export const AreaUseSelectionFilterCheckboxTooltip: FunctionComponent<AreaUseSelectionFilterCheckboxTooltipProps> =
  memo(props => {
    const { areaUseName, linkedAreaTypeNames } = useAreaUseRelations(
      props.areaUseCode,
      props.checkboxData
    )

    const TooltipContent = useMemo(
      () => (
        <div className="space-y-2">
          <div>
            <span className="font-bold">{areaUseName}</span> se vyskytuje pouze
            u
            {linkedAreaTypeNames.length !== 1
              ? ' následujících druhů '
              : ' následujícího druhu '}
            pozemků:
          </div>
          <div>
            {linkedAreaTypeNames.map((useName, index) => (
              <div key={index}>- {useName}</div>
            ))}
          </div>
        </div>
      ),
      [areaUseName, linkedAreaTypeNames]
    )

    return (
      <div className="flex flex-wrap justify-between">
        <span className="truncate flex-1">{areaUseName}</span>
        <Tooltip title={TooltipContent}>
          <QuestionCircleOutlined className="text-gray-400 cursor-help px-2" />
        </Tooltip>
      </div>
    )
  })
