import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
} from '@ant-design/icons'
import { Icon } from '@iconify/react'
import { InputNumber, Radio, RadioChangeEvent, Space, Switch } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import {
  LetterImageProps,
  letterImageRadioValue,
} from '../../../common/postServiceTypes'

export interface PostServiceLetterImageOptionsProps {
  handleOk: () => void
  imageProps: LetterImageProps
  setImageProps: React.Dispatch<React.SetStateAction<LetterImageProps>>
}

const addonBefore = (
  <div className="flex items-center">
    <span className="pl-5 pr-7">Šířka</span>
    <span className="flex items-center pl-1 -mr-2 border-l h-[30px]">
      <Icon
        icon="octicon:arrow-both-16"
        width="13px"
        height="13px"
        className="text-gray-400"
      />
    </span>
  </div>
)

const options = [
  { label: <AlignLeftOutlined />, value: letterImageRadioValue.left },
  { label: <AlignCenterOutlined />, value: letterImageRadioValue.center },
  { label: <AlignRightOutlined />, value: letterImageRadioValue.right },
]

export const PostServiceLetterImageOptions: FunctionComponent<PostServiceLetterImageOptionsProps> =
  props => {
    const handleChangeImage = useCallback(
      (value: number | boolean | null, key: keyof LetterImageProps) => {
        if (typeof value === typeof props.imageProps[key]) {
          props.setImageProps(prev => ({
            ...prev,
            [key]: value,
          }))
        }
      },
      [props]
    )

    const handleChangeWidth = (value: number | null) =>
      handleChangeImage(value, 'width')
    const handleChangeSwitch = (value: boolean) =>
      handleChangeImage(value, 'background')
    const handleChangeAlign = (value: RadioChangeEvent) =>
      handleChangeImage(value.target.value, 'align')

    return (
      <Space className="my-4" align="center" size={16}>
        <InputNumber
          autoFocus
          className="pr-0"
          addonAfter="%"
          min={1}
          max={100}
          addonBefore={addonBefore}
          value={props.imageProps.width}
          onChange={handleChangeWidth}
          onPressEnter={props.handleOk}
        />
        <Radio.Group
          value={props.imageProps.align}
          className="whitespace-nowrap"
          onChange={handleChangeAlign}
          options={options}
          optionType="button"
          disabled={props.imageProps.background}
        />

        <Switch
          checkedChildren="Pozadí"
          unCheckedChildren="Pozadí"
          onChange={handleChangeSwitch}
          defaultChecked={props.imageProps.background}
        />
      </Space>
    )
  }
