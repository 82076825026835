import { Tabs } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { PROPERTY_TYPES } from '../../common/drmTypes'
import { TABLE_VIEW } from '../../common/foreclosureMonitoringTypes'
import { InsolvencyMonitoringTab } from '../components/InsolvencyMonitoringTab/InsolvencyMonitoringTab'
import { PageLayout } from '../components/PageLayout/PageLayout'
import { PropertyDetailDrawer } from '../components/PropertyDetailDrawer/PropertyDetailDrawer'
import { PropertyMonitoringViewType } from '../components/PropertyMonitoringViewType/PropertyMonitoringViewType'
import { TabLabel } from '../components/TabLabel/TabLabel'
import { insolvencyMonitoringPath } from '../utils/paths'
import { useInitialFetchWatchdog } from '../utils/useFetchWatchdog'

const tabBarStyle = { marginBottom: 0 }
const labelClassName = 'text-base text-center block w-28'

export const InsolvencyMonitoring: FunctionComponent = () => {
  const { params } = useRouteMatch<{ tab: PROPERTY_TYPES }>()
  const history = useHistory()
  useInitialFetchWatchdog()

  const [viewType, setViewType] = useState(TABLE_VIEW.TABLE)

  const handleOnTabClick = useCallback(
    (key: string) => {
      history.push(insolvencyMonitoringPath(key as PROPERTY_TYPES))
    },
    [history]
  )

  const tabItems = useMemo(
    () => [
      {
        key: PROPERTY_TYPES.BUILDING,
        label: <TabLabel title="Budovy" className={labelClassName} />,
        children: (
          <InsolvencyMonitoringTab
            viewType={viewType}
            propertyType={PROPERTY_TYPES.BUILDING}
          />
        ),
      },
      {
        key: PROPERTY_TYPES.AREA,
        label: <TabLabel title="Pozemky" className={labelClassName} />,
        children: (
          <InsolvencyMonitoringTab
            viewType={viewType}
            propertyType={PROPERTY_TYPES.AREA}
          />
        ),
      },
      {
        key: PROPERTY_TYPES.UNIT,
        label: <TabLabel title="Jednotky" className={labelClassName} />,
        children: (
          <InsolvencyMonitoringTab
            viewType={viewType}
            propertyType={PROPERTY_TYPES.UNIT}
          />
        ),
      },
    ],
    [viewType]
  )

  return (
    <>
      <PageLayout
        title="Monitoring insolvencí - výpis nemovitostí"
        isNew
        isContainer
      >
        <Tabs
          type="card"
          size="large"
          tabBarGutter={6}
          items={tabItems}
          activeKey={params.tab || PROPERTY_TYPES.BUILDING}
          tabBarStyle={tabBarStyle}
          onTabClick={handleOnTabClick}
          tabBarExtraContent={
            <PropertyMonitoringViewType withCompact setViewType={setViewType} />
          }
        />
      </PageLayout>
      <PropertyDetailDrawer />
    </>
  )
}

export default InsolvencyMonitoring
