import React, { FunctionComponent } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import {
  MonitoringExekuciFetchResult,
  TABLE_VIEW,
} from '../../../common/foreclosureMonitoringTypes'
import { useFetchForeclosureMonitoring } from '../../utils/useFetchForeclosureMonitoring'
import { useForeclosureMonitoringFilter } from '../../utils/useForeclosureMonitoringFilter'
import { ForeclosureMonitoringContent } from '../ForeclosureMonitoringContent/ForeclosureMonitoringContent'
import { ForeclosureMonitoringFilter } from '../ForeclosureMonitoringFilter/ForeclosureMonitoringFilter'
import { PageLayoutTabContainer } from '../PageLayoutTabContainer/PageLayoutTabContainer'
import { PropertyMonitoringTablePagination } from '../PropertyMonitoringTablePagination/PropertyMonitoringTablePagination'

export interface ForeclosureMonitoringTabProps {
  viewType: TABLE_VIEW
  propertyType: PROPERTY_TYPES
}

export const ForeclosureMonitoringTab: FunctionComponent<ForeclosureMonitoringTabProps> =
  props => {
    const { filter, filterQuery, handleChange } =
      useForeclosureMonitoringFilter(props.propertyType)

    const dataSource = useFetchForeclosureMonitoring(
      filterQuery
    ) as MonitoringExekuciFetchResult

    return (
      <PageLayoutTabContainer>
        <ForeclosureMonitoringFilter
          propertyType={props.propertyType}
          filter={filter}
          handleChange={handleChange}
        />

        <ForeclosureMonitoringContent
          dataSource={dataSource}
          viewType={props.viewType}
          propertyType={props.propertyType}
        />

        <PropertyMonitoringTablePagination
          changePage={handleChange}
          currentPage={filter.stranka}
          hasNextPage={!!dataSource.data?.dalsiStranka}
        />
      </PageLayoutTabContainer>
    )
  }
