import { Alert } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import {
  RestrictionCheckboxData,
  RestrictionChecType,
} from '../../../common/epoTypes'
import { RestrictionCheckState } from '../../../graphql/generated'
import { CadastralSelectionModalButton } from '../CadastralSelectionModalButton/CadastralSelectionModalButton'
import { CuzkEpoRestrictionCheckAlertIsChecking } from '../CuzkEpoRestrictionCheckAlertIsChecking/CuzkEpoRestrictionCheckAlertIsChecking'

export interface CuzkEpoRestrictionCheckInProgressProps {
  epoId: string
  refetch: () => void
  checkboxData: RestrictionCheckboxData
}

export const CuzkEpoRestrictionCheckInProgress: FunctionComponent<CuzkEpoRestrictionCheckInProgressProps> =
  props => {
    const isSomeBuyAvailable = useMemo(
      () =>
        !!props.checkboxData.data?.length &&
        props.checkboxData.data.some(item =>
          item.pracoviste.some(workplace => !workplace.disabled)
        ),
      [props.checkboxData.data]
    )

    const checkedWorkplaces = useMemo(
      () =>
        props.checkboxData.inProgress
          ?.map(
            item =>
              item.state === RestrictionCheckState.Checked &&
              item.katastralniPracovisteNazev
          )
          ?.filter(Boolean)
          .join(', '),
      [props.checkboxData.inProgress]
    )

    if (
      props.checkboxData.isAllCreated ||
      (!props.checkboxData.isChecking && !props.checkboxData.data.length)
    )
      return null

    return (
      <>
        {props.checkboxData.isChecking && (
          <CuzkEpoRestrictionCheckAlertIsChecking
            inProgress={props.checkboxData.inProgress}
          />
        )}
        {!props.checkboxData.positiveLv &&
          props.checkboxData.isSomeChecked &&
          !props.checkboxData.isAllCheckedBy && (
            <Alert
              closable
              message={
                <>
                  <span>
                    Při kontrole vybraných katastrálních pracovišť nebyly
                    nalezeny žádné LV s právy 3. stran.{' '}
                  </span>
                  <span className="text-gray-400">
                    Zkontrolováno: {checkedWorkplaces}
                  </span>
                </>
              }
              showIcon
              type="warning"
              className="!mb-4"
            />
          )}
        {!props.checkboxData.positiveLv &&
          props.checkboxData.isAllCheckedBy && (
            <Alert
              message="Nebyla nalezena žádná oprávnění 3. stran"
              description={`Při kontrole nebyly nalezeny žádné LV s právy 3. stran. Byly zkontrolovány listy vlastnictví v těchto katastrálních pracovištích: ${checkedWorkplaces}`}
              showIcon
              type="warning"
              className="!mb-4"
            />
          )}

        {!!props.checkboxData.positiveLv && isSomeBuyAvailable && (
          <Alert
            message="Ve vybraných katastrálních pracovištích byly nalezeny LV s právy 3. stran"
            showIcon
            type="success"
            className="!mb-4"
            action={
              <CadastralSelectionModalButton
                epoId={props.epoId}
                checkboxData={props.checkboxData.data}
                refetch={props.refetch}
                type={RestrictionChecType.lien}
              />
            }
          />
        )}
      </>
    )
  }
