import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Divider, Form, Input, message } from 'antd'
import React, { FunctionComponent, useCallback, useState } from 'react'

import {
  useCreatePostserviceListMutation,
  useEditPostserviceListMutation,
} from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { itemRequired } from '../../utils/formHelpers'
import {
  formItemLayout,
  fullItemLayoutProps,
  validateTrigger,
} from '../../utils/layoutConst'
import { useAssignLabels } from '../../utils/useAssignLabels'
import { Fade } from '../Fade/Fade'
import { LabelSelectInput } from '../LabelSelectInput/LabelSelectInput'

export interface PostServiceCreateRecipientListFormValues {
  listName: string
  description?: string
}

export interface PostServiceCreateRecipientListFormProps {
  id?: string
  initialValues?: PostServiceCreateRecipientListFormValues
  hideModal: () => void
  handleHelpToggle: () => void
  showHelp: boolean
  reexecuteQuery: () => void
}

export const PostServiceCreateRecipientListForm: FunctionComponent<PostServiceCreateRecipientListFormProps> =
  props => {
    const [form] = Form.useForm()
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const userId = useAppSelector(state => state.myProfile.userId)
    const { assignLabels } = useAssignLabels()

    const [loading, setLoading] = useState(false)
    const [labelsId, setLabelsId] = useState<string[]>([])
    const [, createPostserviceList] = useCreatePostserviceListMutation()
    const [, editPostserviceList] = useEditPostserviceListMutation()

    const createList = useCallback(
      async (
        formValues: PostServiceCreateRecipientListFormValues
      ): Promise<string> => {
        const result = await createPostserviceList({
          accountId: tenantId,
          accountUserId: userId,
          listName: formValues.listName,
          description: formValues.description,
        })
        return result.data?.createPostserviceList?.postserviceList?.id || ''
      },
      [tenantId, userId, createPostserviceList]
    )

    const editList = useCallback(
      async (
        formValues: PostServiceCreateRecipientListFormValues
      ): Promise<string> => {
        const result = await editPostserviceList({
          listName: formValues.listName,
          description: formValues.description,
          id: props.id || '',
        })
        return result.data?.updatePostserviceListById?.clientMutationId || ''
      },
      [editPostserviceList, props.id]
    )

    const onFinish = useCallback(
      async (formValues: PostServiceCreateRecipientListFormValues) => {
        setLoading(true)
        if (props.id) {
          try {
            await editList(formValues)
            return message.success('Upraven seznam ' + formValues.listName)
          } catch (error) {
            return message.error('Při úpravě seznamu adresátů nastala chyba.')
          } finally {
            props.reexecuteQuery()
            setLoading(false)
            props.hideModal()
          }
        } else {
          try {
            const listId = await createList(formValues)
            if (!listId) {
              return message.error('Nepodařilo se vytvořit seznam adresátů.')
            }
            await assignLabels({
              postserviceListId: listId,
              labelsId,
            })
            return props.reexecuteQuery()
          } catch (error) {
            return message.error(
              'Při vytváření seznamu adresátů nastala chyba.'
            )
          } finally {
            setLoading(false)
            props.hideModal()
            props.reexecuteQuery()
            message.success('Vytvořen seznam ' + formValues.listName)
          }
        }
      },
      [assignLabels, createList, editList, labelsId, props]
    )

    return (
      <Form<PostServiceCreateRecipientListFormValues>
        {...formItemLayout}
        layout="horizontal"
        form={form}
        initialValues={props.initialValues}
        validateTrigger={validateTrigger}
        onFinish={onFinish}
        className="!mt-4"
      >
        <Fade show={props.showHelp} className="mb-4 mt-6 text-gray-400">
          Seznam adresátů si můžete pojmenovat a přidat jeho popis. Tyto údaje
          slouží pouze pro vás v rámci systému a jsou neveřejné.
        </Fade>
        <Form.Item
          label="Název"
          name="listName"
          required
          rules={itemRequired('Název je povinný')}
        >
          <Input placeholder="Název seznamu adresátů" />
        </Form.Item>

        <Form.Item label="Popis" name="description">
          <Input.TextArea
            showCount
            maxLength={1000}
            autoFocus
            placeholder="Popis seznamu adresátů"
          />
        </Form.Item>

        <Form.Item name="labels" label="Štítek" colon={false}>
          <LabelSelectInput disabled={loading} onChange={setLabelsId} />
        </Form.Item>

        <Divider />

        <Form.Item className="!mb-0" {...fullItemLayoutProps}>
          <div className="flex justify-between">
            <Button
              icon={<InfoCircleOutlined />}
              type="link"
              onClick={props.handleHelpToggle}
            >
              {props.showHelp ? 'Skrýt nápovědu' : 'Zobrazit nápovědu'}
            </Button>
            <div className="space-x-2">
              <Button disabled={loading} onClick={props.hideModal}>
                Zrušit
              </Button>

              <Button type="primary" loading={loading} onClick={form.submit}>
                {props.id ? 'Uložit' : 'Vytvořit'}
              </Button>
            </div>
          </div>
        </Form.Item>
      </Form>
    )
  }
