import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, message, Popconfirm } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useCallback, useState } from 'react'

import { BASE_URL } from '../../../../ini.json'
import { CollectionType } from '../../../common/documentCollectionTypes'
import { useAppSelector } from '../../../redux/hooks'
import { cuzkDocumentCollectionState as state } from '../../utils/handleResponseStatus'
import { proceedingDetailRequestButtonDescription } from '../../utils/proceedingDetailHelpers'
import { CuzkDocumentCollectionAction } from '../CuzkDocumentCollectionAction/CuzkDocumentCollectionAction'

export interface ProceedingDetailDocumentCollectionActionProps {
  record: {
    stav: string
    cena?: number
    id: string
    cuzkCollectionId?: string
    url?: string | null
  }
  refetch: () => void
  proceedingString: string
}

const buttonProps = { size: 'small' as const }

export const ProceedingDetailDocumentCollectionAction: FunctionComponent<ProceedingDetailDocumentCollectionActionProps> =
  props => {
    const { tenantId, token, userId } = useAppSelector(state => state.myProfile)

    const [loading, setLoading] = useState(false)

    const getDocumentCollection = useCallback(async () => {
      setLoading(true)
      try {
        const response = await axios.post(`${BASE_URL}/api/cuzk/sbirkaListin`, {
          token,
          tenantId,
          userId,
          cuzkDocumentId: props.record.id,
          proceeding: props.proceedingString,
        })

        const { status } = response
        if (status === 200) props.refetch()
      } catch (error) {
        message.error('Nepodařilo se načíst listinu ze Sbírky listin ČÚZK')
        setLoading(false)
      }
    }, [props, tenantId, token, userId])

    const handleCuzkDocumentRequest = useCallback(() => {
      props.record?.url &&
        window.open(props.record.url, '_blank', 'noopener,noreferrer')
    }, [props.record?.url])

    if (props.record.stav === state.info)
      return (
        <Button
          size="small"
          type="primary"
          loading={loading}
          onClick={getDocumentCollection}
          title="Načíst bližší informace o listině ze Sbírky listin ČÚZK - bez poplatku"
        >
          Načíst listinu
        </Button>
      )

    if (props.record.stav === state.naVyzadani)
      return (
        <Popconfirm
          title="Vznést požadavek na listinu?"
          onConfirm={handleCuzkDocumentRequest}
          placement="left"
          okText="Souhlasím"
          description={proceedingDetailRequestButtonDescription}
          icon={<QuestionCircleOutlined className="text-yellow-700" />}
        >
          <Button size="small" type="primary">
            Zažádat o listinu
          </Button>
        </Popconfirm>
      )

    if (!props.record.cuzkCollectionId) return null
    return (
      <CuzkDocumentCollectionAction
        price={props.record.cena}
        state={props.record.stav}
        id={props.record.id}
        cuzkCollectionId={props.record.cuzkCollectionId}
        refetch={props.refetch}
        collectionType={CollectionType.SL}
        buttonProps={buttonProps}
      />
    )
  }
