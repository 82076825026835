import { Checkbox } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import {
  CadastralCheckedValues,
  CadastralOffice,
} from '../../utils/cuzkHelpers'
import { CadastralWorkplaceSelectionFilterOfficeCheckbox } from '../CadastralWorkplaceSelectionFilterOfficeCheckbox/CadastralWorkplaceSelectionFilterOfficeCheckbox'
import { CadastreSelectionWorkplaceCheckbox } from '../CadastreSelectionWorkplaceCheckbox/CadastreSelectionWorkplaceCheckbox'
import { NoResultBox } from '../NoResultBox/NoResultBox'

export interface CadastralWorkplaceSelectionFilterProps {
  filteredRegions: CadastralOffice[]
  disabledVisible?: boolean
  setCheckedValues: (
    value: React.SetStateAction<CadastralCheckedValues>
  ) => void
  checkedValues: CadastralCheckedValues
  searchTerm: string
}

export const CadastralWorkplaceSelectionFilter: FunctionComponent<CadastralWorkplaceSelectionFilterProps> =
  props => {
    const filteredOffices = useMemo(
      () =>
        props.filteredRegions.filter(
          office => props.disabledVisible || !office.disabled
        ),
      [props.filteredRegions, props.disabledVisible]
    )

    const filteredWorkplaces = useMemo(
      () =>
        filteredOffices.map(office => ({
          ...office,
          pracoviste: office.pracoviste.filter(
            workplace => props.disabledVisible || !workplace.disabled
          ),
        })),
      [filteredOffices, props.disabledVisible]
    )

    if (!filteredOffices.length) {
      return <NoResultBox />
    }

    return (
      <div className="grid gap-x-6 gap-y-12 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
        {filteredWorkplaces.map(office => (
          <div key={office.kod} className="animate-fadeIn">
            <CadastralWorkplaceSelectionFilterOfficeCheckbox
              checkboxData={filteredOffices}
              checkedValues={props.checkedValues}
              office={office}
              setCheckedValues={props.setCheckedValues}
            />
            <Checkbox.Group
              className="w-full flex flex-col"
              value={props.checkedValues[office.kod]}
              onChange={checkedValues =>
                props.setCheckedValues(
                  (prevState: CadastralCheckedValues) =>
                    ({
                      ...prevState,
                      [office.kod]: checkedValues,
                    } as CadastralCheckedValues)
                )
              }
            >
              {office.pracoviste.map(workplace => (
                <CadastreSelectionWorkplaceCheckbox
                  workplace={workplace}
                  searchTerm={props.searchTerm}
                  key={workplace.kod}
                />
              ))}
            </Checkbox.Group>
          </div>
        ))}
      </div>
    )
  }
