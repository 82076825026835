import { Text, View } from '@react-pdf/renderer'
import React, { FunctionComponent } from 'react'

export interface CeeDetailPdfItemProps {
  title: string
  conditional?: boolean
  children: React.ReactNode
}

export const CeeDetailPdfItem: FunctionComponent<CeeDetailPdfItemProps> =
  props => {
    if (props.conditional && !props?.children) return null
    return (
      <View
        style={{
          fontSize: 10,
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          gap: 10,
          padding: 2,
        }}
      >
        <Text style={{ width: '50%', textAlign: 'right' }}>{props.title}</Text>
        <Text style={{ width: '50%', fontStyle: 'bold' }}>
          {props?.children}
        </Text>
      </View>
    )
  }
