import React, { FunctionComponent } from 'react'
import { Layer } from 'react-map-gl'

export interface CadastreMapDebtLayersProps {
  source: string
  enabled: boolean
}

export const CadastreMapDebtLayers: FunctionComponent<CadastreMapDebtLayersProps> =
  ({ source, enabled }) => {
    return (
      <>
        <Layer
          id="debt-circle"
          source={source}
          type="circle"
          layout={{ visibility: enabled ? 'visible' : 'none' }}
          paint={{
            'circle-color': [
              'case',
              ['boolean', ['get', 'exekuce'], false],
              '#E0909C',
              ['boolean', ['get', 'insolvence'], false],
              '#3B8F54',
              '#ff6f61',
            ],
            'circle-radius': 20,
            'circle-stroke-width': [
              'case',
              ['boolean', ['get', 'highlight'], false],
              4,
              0,
            ],
            'circle-stroke-color': '#04e762',
          }}
        />
        <Layer
          id="debt-icon-or-counttext"
          source={source}
          type="symbol"
          layout={{
            visibility: enabled ? 'visible' : 'none',
            'icon-image': [
              'case',
              ['has', 'iconName'],
              ['get', 'iconName'],
              '',
            ],
            'text-field': [
              'case',
              ['==', ['get', 'pocet'], 1],
              '',
              ['has', 'typ'],
              '',
              ['get', 'pocet'],
            ],
            'text-font': ['Open Sans Bold'],
            'text-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              0,
              1,
              10,
              10,
              16,
              22,
              24,
            ],
          }}
          paint={{
            'text-halo-color': '#fff',
            'text-halo-width': 1,
            'text-color': '#000',
            'icon-color': [
              'case',
              ['boolean', ['get', 'exekuce'], false],
              '#000',
              ['boolean', ['get', 'insolvence'], false],
              '#fff',
              '#000',
            ],
          }}
        />
      </>
    )
  }
