import React, { FunctionComponent } from 'react'

import {
  AuctionMonitoringFilterOnSubmit,
  AuctionMonitoringFilterState,
} from '../../../common/auctionMonitoringTypes'
import { AUCTION_STATES } from '../../../common/auctionScrapeTypes'
import { AuctionMonitoringFilterRange } from '../AuctionMonitoringFilterRange/AuctionMonitoringFilterRange'
import { AuctionMonitoringFilterSelect } from '../AuctionMonitoringFilterSelect/AuctionMonitoringFilterSelect'

export interface AuctionMonitoringFilterProps {
  filter: AuctionMonitoringFilterState
  handleChange: AuctionMonitoringFilterOnSubmit
}

export const AuctionMonitoringFilter: FunctionComponent<AuctionMonitoringFilterProps> =
  ({ filter, handleChange }) => {
    return (
      <div
        id="monitoringFilter"
        className="flex gap-x-8 gap-y-4 mb-8 flex-wrap"
      >
        <div className="grid grid-cols-2 w-full md:flex md:w-auto gap-x-8 gap-y-4">
          <AuctionMonitoringFilterSelect
            filterKey="state"
            value={filter.state}
            handleChange={handleChange}
          />

          <AuctionMonitoringFilterRange
            disabled={!filter.state?.includes(AUCTION_STATES.ENDED)}
            valueFrom={filter.priceFrom}
            valueTo={filter.priceTo}
            filterKeyFrom="priceFrom"
            filterKeyTo="priceTo"
            onChange={handleChange}
          />
        </div>
      </div>
    )
  }
