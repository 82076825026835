import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { LvApiResponse } from '../common/lvTypes'

export const lvDetailSlice = createSlice({
  name: 'lvDetail',
  initialState: {
    katastralniUzemi: {
      kod: 0,
      nazev: '',
    },
    lv: 0,
    pravniVztahy: [],
    nemovitosti: {
      budovy: [],
      parcely: [],
      jednotky: [],
    },
  } as LvApiResponse,
  reducers: {
    setLvDetail: (state, action: PayloadAction<LvApiResponse>) => {
      state.katastralniUzemi = action.payload.katastralniUzemi
      state.lv = action.payload.lv
      state.pravniVztahy = action.payload.pravniVztahy
      state.nemovitosti = action.payload.nemovitosti
    },
  },
})

export const { setLvDetail } = lvDetailSlice.actions

export default lvDetailSlice.reducer
