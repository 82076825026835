import { Button, message } from 'antd'
import React, { FunctionComponent, useCallback, useState } from 'react'

import { useCreatePostserviceRecipientMutation } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { IncreasingNumber } from '../IncreasingNumber/IncreasingNumber'
import { PostServiceRecipient } from '../PostServiceImportRecipientModalButton/PostServiceImportRecipientModalButton'

export interface PostServiceImportRecipientSubmitButtonProps {
  errorCount: number
  parsedData: PostServiceRecipient[]
  handleModal: () => void
  listId: string
  reexecuteQuery?: () => void
}

export const PostServiceImportRecipientSubmitButton: FunctionComponent<PostServiceImportRecipientSubmitButtonProps> =
  props => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const userId = useAppSelector(state => state.myProfile.userId)
    const [loading, setLoading] = useState(false)
    const [uploadedCount, setUploadedCount] = useState(0)

    const [, createPostserviceRecipient] =
      useCreatePostserviceRecipientMutation()

    const handleRecipientCreate = useCallback(async () => {
      setLoading(true)
      let successCount = 0
      let failedCount = 0

      for (const recipient of props.parsedData) {
        try {
          const result = await createPostserviceRecipient({
            ...recipient,
            accountId: tenantId,
            accountUserId: userId,
            postserviceListId: props.listId,
          })

          if (!result.error) {
            successCount++
            setUploadedCount(prevCount => prevCount + 1)
          } else {
            failedCount++
          }
        } catch (error) {
          failedCount++
        }
      }

      setLoading(false)

      if (successCount === props.parsedData.length) {
        message.success(
          'Všichni noví adresáti byli úspěšně zařazeni do seznamu.'
        )
      } else if (failedCount === props.parsedData.length) {
        message.error('Nebylo možné zařadit žádné adresáty do seznamu.')
      } else {
        message.warning(
          `${successCount} adresátů bylo úspěšně přidáno, ale ${failedCount} se nepodařilo zařadit.`
        )
      }

      props.reexecuteQuery?.()
      props.handleModal()
    }, [createPostserviceRecipient, tenantId, userId, props])

    return (
      <Button
        type="primary"
        onClick={handleRecipientCreate}
        loading={loading}
        disabled={!props.parsedData.length || !!props.errorCount}
      >
        {loading ? (
          <>
            Nahrávání{' '}
            <IncreasingNumber
              finalNumber={(uploadedCount / props.parsedData.length || 1) * 100}
            />{' '}
            %
          </>
        ) : (
          'Nahrát adresáty'
        )}
      </Button>
    )
  }
