import { Tooltip } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { STAVBY } from '../../../common/cuzkLvTypes'
import {
  notEmpty,
  safeFind,
  safeJoin,
  safeMap,
} from '../../../common/pdfHelpers'
import { useAppSelector } from '../../../redux/hooks'
import { lvDetailPath } from '../../utils/paths'
import { CuzkLvHtmlIdentifikatorFragment } from '../CuzkLvHtmlIdentifikatorFragment/CuzkLvHtmlIdentifikatorFragment'
import { CuzkLvHtmlJednotkaIdentFragment } from '../CuzkLvHtmlJednotkaIdentFragment/CuzkLvHtmlJednotkaIdentFragment'
import { CuzkLvHtmlOpravSubjektFragment } from '../CuzkLvHtmlOpravSubjektFragment/CuzkLvHtmlOpravSubjektFragment'
import { CuzkLvHtmlParcelaIdentFragment } from '../CuzkLvHtmlParcelaIdentFragment/CuzkLvHtmlParcelaIdentFragment'
import { CuzkLvHtmlPodilFragment } from '../CuzkLvHtmlPodilFragment/CuzkLvHtmlPodilFragment'
import { CuzkLvHtmlStavbaIdentFragment } from '../CuzkLvHtmlStavbaIdentFragment/CuzkLvHtmlStavbaIdentFragment'

export interface CuzkLvHtmlSekceStavbyFragmentProps {
  stavby?: STAVBY
  active?: boolean
}

export const CuzkLvHtmlSekceStavbyFragment: FunctionComponent<CuzkLvHtmlSekceStavbyFragmentProps> =
  props => {
    const katastralniUzemiKod = useAppSelector(
      state => state.lvDetail.katastralniUzemi.kod
    )
    const naLvTitle = useCallback(
      (lvNumber: string | number) => {
        if (props.active) {
          return (
            <Tooltip title="Zobrazit detail LV v novém okně">
              <Link
                target="_blank"
                to={lvDetailPath(katastralniUzemiKod, lvNumber)}
              >
                {lvNumber}
              </Link>
            </Tooltip>
          )
        }

        return lvNumber
      },
      [katastralniUzemiKod, props.active]
    )

    if (!props.stavby || !notEmpty(props.stavby.BUDOVA)) {
      return null
    }
    return (
      <>
        <div className="mb-50"></div>
        <div className="row">
          <div className="col-xs-12">
            <i>Stavby</i>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <i>&nbsp;Typ stavby</i>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-3">
            <i>&nbsp;Část obce, č. budovy</i>
          </div>
          <div className="col-xs-3">
            <i className="pr-30">Způsob využití</i>
          </div>
          <div className="col-xs-3">
            <i>Na parcele</i>
          </div>
          <div className="col-xs-3">
            <i>Způsob ochrany</i>
          </div>
        </div>
        <hr className="mt-0 mb-0" />
        {safeMap(props.stavby?.BUDOVA, (BUDOVA, index) => (
          <div key={index}>
            <div className="row bold">
              <div className="col-xs-3">
                {BUDOVA?.PLOMBA_BUDOVY ? BUDOVA.PLOMBA_BUDOVY + ' ' : ''}
                <strong>
                  <CuzkLvHtmlStavbaIdentFragment
                    active={props.active}
                    noLabel
                    stavbaIdent={BUDOVA.CISLO_DOMOVNI?.stavba}
                  />
                </strong>
              </div>
              <div className="col-xs-3 pr-30">
                <span className="pr-30">{BUDOVA?.ZP_VYUZITI}</span>
              </div>
              <div className="col-xs-3">
                {safeMap(BUDOVA?.PARCELY?.PAR_IDENT, (PAR_IDENT, index) => (
                  <div key={index} className="bold">
                    <CuzkLvHtmlParcelaIdentFragment
                      active={props.active}
                      noLabel
                      parcelaIdent={PAR_IDENT?.parcela}
                    />
                  </div>
                ))}
              </div>
              <div className="col-xs-3 breakAll">
                {safeJoin(BUDOVA?.ZP_OCHRANY?.zpochr, ', ')}
              </div>
            </div>
          </div>
        ))}
        {safeFind(props.stavby?.BUDOVA, BUDOVA => !!BUDOVA?.PLOMBA_BUDOVY) && (
          <div className="row">
            <i>P = právní vztahy jsou dotčeny změnou</i>
          </div>
        )}
        {notEmpty(
          safeFind(props.stavby?.BUDOVA, BUDOVA => !!BUDOVA?.JEDNOTKY?.JEDNOTKA)
        ) && (
          <>
            <div className="mb-50"></div>
            <div className="row">
              <div className="col-xs-2">
                <i>
                  Č.p./
                  <br />
                  Č.jednotky
                </i>
              </div>
              <div className="col-xs-2 verticalBottom">
                <i>Způsob využití</i>
              </div>
              <div className="col-xs-1 verticalBottom">
                <i>na LV</i>
              </div>
              <div className="col-xs-2 verticalBottom">
                <i>Typ jednotky</i>
              </div>
              <div className="col-xs-3 verticalBottom text-right">
                <i>Podíl na společných částech domu a pozemku</i>
              </div>
              <div className="col-xs-2 text-right flex-right verticalBottom">
                <i>Podíl na jednotce</i>
              </div>
            </div>
            <hr className="mt-0 mb-0" />
          </>
        )}
        {safeMap(props.stavby?.BUDOVA, (BUDOVA, index) => (
          <div key={index}>
            {safeMap(BUDOVA?.JEDNOTKY?.JEDNOTKA, (JEDNOTKA, index) => (
              <div key={index}>
                <div key={index} className="row bold">
                  <div className="col-xs-2">
                    <CuzkLvHtmlJednotkaIdentFragment
                      noLabel
                      jednotkaIdent={JEDNOTKA.CISLO_JEDNOTKY.jednotka}
                    />
                  </div>
                  <div className="col-xs-2">{JEDNOTKA?.JED_ZP_VYUZITI}</div>
                  <div className="col-xs-1">{naLvTitle(JEDNOTKA?.NA_LV)}</div>
                  <div className="col-xs-2">{JEDNOTKA.TYP_JEDNOTKY}</div>
                  <div className="col-xs-3 text-right">
                    <CuzkLvHtmlPodilFragment
                      active={props.active}
                      podil={JEDNOTKA.PODIL_BA}
                    />
                  </div>
                  <div className="col-xs-2 text-right"></div>
                </div>
                <div className="row">
                  <div className="col-xs-3 text-right">
                    <i>Spoluvlastníci</i>
                  </div>
                  <div className="col-xs-9 pl-50">
                    {safeMap(JEDNOTKA.VLASTNICTVI, (VLASTNICTVI, index) => (
                      <div className="row bold" key={index}>
                        <div className="col-xs-9">
                          <CuzkLvHtmlIdentifikatorFragment
                            active={props.active}
                            key={index}
                            opravSubjekt={VLASTNICTVI.OPSUB_IDENT}
                          />
                          {VLASTNICTVI.OPSUB_IDENT ? '; ' : ''}
                          <CuzkLvHtmlOpravSubjektFragment
                            active={props.active}
                            opravSubjekt={VLASTNICTVI.OPSUB_NAZEV}
                          />
                        </div>
                        <div className="col-xs-3 text-right">
                          <CuzkLvHtmlPodilFragment
                            active={props.active}
                            key={index}
                            podil={VLASTNICTVI.PODIL}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </>
    )
  }
