import { AutoComplete, Spin } from 'antd'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { useAppSelector } from '../../../redux/hooks'
import {
  AddressOptions,
  fetchBuildingId,
  handleSearchAddress,
} from '../../utils/addressSearchHelpers'
import { addressPath, propertyDetailPath } from '../../utils/paths'

export const AddressSearchBar: FunctionComponent = () => {
  const history = useHistory()
  const token = useAppSelector(state => state.myProfile.token)
  const {
    params: { urlQuery },
  } = useRouteMatch<{ urlQuery?: string }>()

  const [options, setOptions] = useState<AddressOptions>([])

  const onSearch = useCallback(
    async (value: string) => {
      const results = await handleSearchAddress(value, token)
      setOptions(results)
    },
    [token]
  )

  const handleSelect = useCallback(
    async (value: string) => {
      const buildingId = await fetchBuildingId(value, token)

      if (buildingId) {
        const redirectPath = propertyDetailPath(
          PROPERTY_TYPES.BUILDING,
          buildingId
        )
        urlQuery ? history.replace(redirectPath) : history.push(redirectPath)
      } else {
        history.replace(addressPath())
      }
    },
    [history, token, urlQuery]
  )

  useEffect(() => {
    if (urlQuery) handleSelect(urlQuery)
  }, [handleSelect, urlQuery])

  if (urlQuery)
    return (
      <Spin spinning className="!flex h-9 w-full items-center justify-center" />
    )

  return (
    <AutoComplete
      id="addressSearch" // addressSearch_list in DOM; custom CSS id
      placeholder="Začněte psát pro vyhledání adresy"
      className="w-full"
      size="large"
      onSearch={onSearch}
      onSelect={handleSelect}
      filterOption={false}
      options={options}
      autoFocus
      defaultActiveFirstOption
    />
  )
}
