import { FileTextOutlined } from '@ant-design/icons'
import { Alert, Spin } from 'antd'
import { Tabs, Typography } from 'antd'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { BASE_URL } from '../../../ini.json'
import { CuzkLvXmlType } from '../../common/cuzkLvTypes'
import { useAppSelector } from '../../redux/hooks'
import { CuzkEpoDetailTabLabel } from '../components/CuzkEpoDetailTabLabel/CuzkEpoDetailTabLabel'
import { CuzkLvDetailTabBarExtraContent } from '../components/CuzkLvDetailTabBarExtraContent/CuzkLvDetailTabBarExtraContent'
import { CuzkLvHtmlLayout } from '../components/CuzkLvHtmlLayout/CuzkLvHtmlLayout'

const titleStyle = { marginBottom: 0 }
const tabBarStyle = { marginBottom: 0, marginTop: 12 }

export const CuzkLvDetail: FunctionComponent = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>()

  const tenantId = useAppSelector(state => state.myProfile.tenantId)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState<CuzkLvXmlType | undefined>()

  useEffect(() => {
    if (dataSource) return
    setLoading(true)
    fetch(`${BASE_URL}/download/${tenantId}/${id}.json`)
      .then(response => response.json())
      .then(data => {
        setLoading(false)
        setDataSource(data)
      })
      .catch(() => {
        setLoading(false)
        setError(true)
      })
  }, [dataSource, id, tenantId])

  const tabItems = useMemo(
    () => [
      {
        key: 'documentView',
        label: (
          <CuzkEpoDetailTabLabel
            icon={<FileTextOutlined />}
            title="Zobrazení v listině"
          />
        ),
        children: (
          <div className="flex justify-center bg-white p-4 pt-8">
            {loading && <Spin size="large" />}
            {!!dataSource && (
              <div className="border shadow-lg p-8 w-[21cm]">
                <CuzkLvHtmlLayout active data={dataSource} />
              </div>
            )}
          </div>
        ),
      },
    ],
    [dataSource, loading]
  )

  if (error)
    return (
      <Alert
        message="Chyba dat"
        description="Nepodařilo se načíst data z ČÚZK."
        type="error"
        className="my-8"
        showIcon
      />
    )
  if (loading)
    return (
      <div className="my-8 flex flex-col justify-center align-middle bg-white p-4 pt-8">
        <Spin spinning />
        <div className="mb-2 mt-2 text-center text-gray-400">
          Načítají se data z ČÚZK...
        </div>
      </div>
    )
  return (
    <>
      <Typography.Title style={titleStyle} level={3}>
        Detail výpisu z katastru nemovitostí
      </Typography.Title>
      <Tabs
        defaultActiveKey="documentView"
        type="card"
        tabBarExtraContent={<CuzkLvDetailTabBarExtraContent id={id} />}
        tabBarStyle={tabBarStyle}
        tabBarGutter={6}
        items={tabItems}
      />
    </>
  )
}

export default CuzkLvDetail
