import { useMemo } from 'react'

import { AreaFilterUse } from '../../common/foreclosureMonitoringTypes'

// inserted from the official ČÚZK .csv file named SC_POZEMEK_VYUZITI.csv
// 14;24 (ostatní plocha, dobývací prostor) is not in the official CSV. It was added manually.

const CSV_DATA = `
DRUPOZ_KOD;ZPVYPO_KOD
2;1
2;2
2;3
2;29
2;30
3;1
3;29
3;30
4;1
4;29
4;30
5;1
5;29
5;30
6;1
6;29
6;30
7;1
7;2
7;3
7;29
7;30
8;1
8;2
8;3
10;1
10;2
10;3
10;4
10;5
10;17
10;20
10;29
10;30
11;6
11;7
11;8
11;9
11;10
11;11
11;28
11;29
11;30
13;12
13;13
13;29
14;3
14;11
14;14
14;15
14;16
14;17
14;18
14;19
14;20
14;21
14;22
14;23
14;24
14;25
14;26
14;27
14;29
14;30
`

const csvMap: Map<number, number[]> = (() => {
  const lines = CSV_DATA.trim().split('\n')
  const result = new Map<number, number[]>()
  for (let i = 1; i < lines.length; i++) {
    const [areaTypeCode, areaUseCode] = lines[i].split(';').map(Number)
    if (!result.has(areaTypeCode)) {
      result.set(areaTypeCode, [])
    }
    result.get(areaTypeCode)?.push(areaUseCode)
  }
  return result
})()

export function useAreaUseRelations(
  areaUseCode: number,
  checkboxData: AreaFilterUse
) {
  const areaHint = useMemo(() => {
    const linkedAreaTypeCodes = Array.from(csvMap.entries())
      .filter(([, areaCodes]) => areaCodes.includes(areaUseCode))
      .map(([areaTypeCode]) => areaTypeCode)

    const areaUse = checkboxData.areaUse.find(
      areaUse => areaUse.kod === areaUseCode
    )
    const linkedAreaTypes = checkboxData.areaType.filter(areaType =>
      linkedAreaTypeCodes.includes(areaType.kod)
    )

    return {
      areaUseName: areaUse?.nazev || '- Název nenalezen -',
      linkedAreaTypeNames: linkedAreaTypes.map(areaType => areaType.nazev),
      linkedAreaTypeCodes: linkedAreaTypeCodes,
    }
  }, [areaUseCode, checkboxData])

  return areaHint
}

export function useAreaTypeRelations(
  areaTypeCode: number,
  checkboxData: AreaFilterUse
) {
  const areaHint = useMemo(() => {
    const linkedAreaUseCodes = csvMap.get(areaTypeCode) || []
    const areaType = checkboxData.areaType.find(
      areaType => areaType.kod === areaTypeCode
    )
    const linkedAreaUses = checkboxData.areaUse.filter(areaUse =>
      linkedAreaUseCodes.includes(areaUse.kod)
    )

    return {
      areaTypeName: areaType?.nazev || '- Název nenalezen -',
      linkedAreaUseNames: linkedAreaUses.map(areaUse => areaUse.nazev),
      linkedAreaUseCodes: linkedAreaUseCodes,
    }
  }, [areaTypeCode, checkboxData])

  return areaHint
}

export function useMultipleAreaTypeRelations(areaTypeCodes: number[]) {
  const multipleAreaHint = useMemo(() => {
    const allLinkedAreaUseCodes = new Set<number>()
    areaTypeCodes.forEach(code => {
      const linkedCodes = csvMap.get(code) || []
      linkedCodes.forEach(useCode => allLinkedAreaUseCodes.add(useCode))
    })

    return Array.from(allLinkedAreaUseCodes)
  }, [areaTypeCodes])

  return multipleAreaHint
}
