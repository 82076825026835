import { WarningOutlined } from '@ant-design/icons'
import { format, isValid, parseISO } from 'date-fns'
import React, { FunctionComponent, useMemo } from 'react'

import {
  czDateFormatDay,
  czDateFormatMinute,
  czDateFormatMonth,
  czDateFormatSecond,
  czDateFormatYear,
} from '../../utils/dateFormat'

export interface DateFormatProps {
  children?: string | Date | null
  noData?: true | JSX.Element | string
  error?: JSX.Element | string
  className?: string
  prefix?: JSX.Element | string
}
export interface CustomDateFormatProps extends DateFormatProps {
  format: string
}

export const fetchDataInlineError = (
  <div className="text-sm flex items-center text-gray-500 font-normal border-dashed border-red-600 border py-1 px-2">
    <WarningOutlined className="mr-2 text-red-500" /> Chyba při načítání dat
  </div>
)

const dateValid = (
  value: undefined | string | Date | null,
  dateFormat: string,
  noData?: true | JSX.Element | string,
  error?: true | JSX.Element | string
) => {
  if (!value && !noData) return error || fetchDataInlineError
  if (!value) return noData
  if (isValid(parseISO(value as string)))
    return format(parseISO(value as string), dateFormat)
  if (isValid(parseISO(value?.toString())))
    return format(parseISO(value?.toString()), dateFormat)
  return fetchDataInlineError
}

export const Sec: FunctionComponent<DateFormatProps> = props => {
  const date = useMemo(
    () =>
      dateValid(props.children, czDateFormatSecond, props.noData, props.error),
    [props.children, props.noData, props.error]
  )
  return (
    <span className={props.className}>
      {props.children && props.prefix}
      {date}
    </span>
  )
}

export const Min: FunctionComponent<DateFormatProps> = props => {
  const date = useMemo(
    () =>
      dateValid(props.children, czDateFormatMinute, props.noData, props.error),
    [props.children, props.noData, props.error]
  )
  return (
    <span className={props.className}>
      {props.children && props.prefix}
      {date}
    </span>
  )
}

export const Day: FunctionComponent<DateFormatProps> = props => {
  const date = useMemo(
    () => dateValid(props.children, czDateFormatDay, props.noData, props.error),
    [props.children, props.noData, props.error]
  )
  return (
    <span className={props.className}>
      {props.children && props.prefix}
      {date}
    </span>
  )
}

export const Month: FunctionComponent<DateFormatProps> = props => {
  const date = useMemo(
    () =>
      dateValid(props.children, czDateFormatMonth, props.noData, props.error),
    [props.children, props.noData, props.error]
  )
  return (
    <span className={props.className}>
      {props.children && props.prefix}
      {date}
    </span>
  )
}

export const Year: FunctionComponent<DateFormatProps> = props => {
  const date = useMemo(
    () =>
      dateValid(props.children, czDateFormatYear, props.noData, props.error),
    [props.children, props.noData, props.error]
  )
  return (
    <span className={props.className}>
      {props.children && props.prefix}
      {date}
    </span>
  )
}

export const CustomDate: FunctionComponent<CustomDateFormatProps> = props => {
  const date = useMemo(
    () => dateValid(props.children, props.format, props.noData, props.error),
    [props.children, props.format, props.noData, props.error]
  )
  return (
    <span className={props.className}>
      {props.children && props.prefix}
      {date}
    </span>
  )
}
