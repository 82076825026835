import { Alert, message, Popconfirm, PopconfirmProps } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { useAppSelector } from '../../../redux/hooks'
import { checkCredit, CreditCheckResult } from '../../utils/checkCredit'
import { Fade } from '../Fade/Fade'
import { LowCreditAlert } from '../LowCreditAlert/LowCreditAlert'
import { PricePopconfirmTitle } from '../PricePopconfirmTitle/PricePopconfirmTitle'

export interface PricePopconfirmProps {
  children: React.ReactNode
  popconfrimProps?: Partial<PopconfirmProps>
  onConfirm: () => void
  totalPrice?: number
  itemCount?: number
  itemPrice: number
  message?: string | React.ReactNode
}

export const PricePopconfirm: FunctionComponent<PricePopconfirmProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)
    const [credits, setCredits] = useState<CreditCheckResult>({
      balance: 0,
      isEnough: false,
      checked: false,
      error: undefined,
    })

    const handleCredit = useCallback(
      async (open: boolean) => {
        if (!open) return
        const check = await checkCredit(
          props.totalPrice || (props.itemCount || 1) * props.itemPrice,
          token
        )
        if (check && !!check.error) {
          message.error(check.error)
        }
        setCredits(check || { balance: 0, isEnough: false, checked: true })
      },
      [props, token]
    )

    const title = useMemo(
      () => (
        <div>
          <Fade className="mb-4" show={credits.checked && !credits.isEnough}>
            <LowCreditAlert
              balance={credits.balance}
              totalPrice={
                props.totalPrice || (props.itemCount || 1) * props.itemPrice
              }
            />
          </Fade>
          <Fade className="mb-4" show={!!credits.error}>
            <Alert
              showIcon
              className="mb-4"
              description={
                <div className="flex space-x-2 pt-1 justify-between">
                  <div>Nepodařilo se ověřit stav vašeho kreditového účtu.</div>
                </div>
              }
              type="error"
            />
          </Fade>
          <PricePopconfirmTitle
            message={props.message}
            itemCount={props.itemCount || 1}
            itemPrice={props.itemPrice}
            totalPrice={props.totalPrice}
          />
        </div>
      ),
      [credits, props]
    )

    const popconfirmProps = useMemo(() => {
      return {
        title,
        onOpenChange: handleCredit,
        icon:
          (!credits.isEnough && credits.checked) || credits.error
            ? null
            : undefined,
        onConfirm: props.onConfirm,
        okText: 'Souhlasím',
        cancelText: 'Zavřít',
        ...props.popconfrimProps,
        okButtonProps: {
          disabled:
            !credits.isEnough || props.popconfrimProps?.okButtonProps?.disabled,
          loading:
            !credits.checked || props.popconfrimProps?.okButtonProps?.loading,
        },
      }
    }, [
      credits.checked,
      credits.error,
      credits.isEnough,
      handleCredit,
      props.onConfirm,
      props.popconfrimProps,
      title,
    ])

    return <Popconfirm {...popconfirmProps}>{props.children}</Popconfirm>
  }
