import { createSlice } from '@reduxjs/toolkit'

import { LvApiResponse } from '../common/lvTypes'
import handleAsyncActions, {
  AsyncState,
  initialFetchState,
  PropertyDataTypes,
} from './fetchAction'

const lvSlice = createSlice({
  name: PropertyDataTypes.LV,
  initialState: initialFetchState as AsyncState<LvApiResponse>,
  reducers: {},
  extraReducers: builder => {
    handleAsyncActions<LvApiResponse>(builder, PropertyDataTypes.LV)
  },
})

export default lvSlice.reducer
