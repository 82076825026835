import React, { FunctionComponent } from 'react'

import { PropertyDetailSubjectsTableItem } from '../../../common/propertyDetailTypes'
import { bulkEditTransition } from '../../utils/layoutConst'
import { Fade } from '../Fade/Fade'
import { SubjectsTableBulkPostService } from '../SubjectsTableBulkPostService/SubjectsTableBulkPostService'
import { TableItemsCount } from '../TableItemsCount/TableItemsCount'

export interface PropertyDetailSubjectsTableBulkEditProps {
  selectedRows: PropertyDetailSubjectsTableItem[]
  refetch?: () => void
}

export const PropertyDetailSubjectsTableBulkEdit: FunctionComponent<PropertyDetailSubjectsTableBulkEditProps> =
  props => {
    return (
      <Fade
        transition={bulkEditTransition}
        show={!!props.selectedRows?.length}
        className="flex space-x-4 items-center mb-4"
      >
        <SubjectsTableBulkPostService selectedRows={props.selectedRows} />
        <TableItemsCount
          prefix="Vybráno"
          separator
          total={props.selectedRows?.length || 0}
        />
      </Fade>
    )
  }
