import React, { FunctionComponent, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { DrmJednotkaResponse, PROPERTY_TYPES } from '../../../common/drmTypes'
import { LvApiResponse } from '../../../common/lvTypes'
import { propertyDetailPath } from '../../utils/paths'
import { Money } from '../Money/Money'
import { PriceEstimateLayoutLvDataRow as Item } from '../PriceEstimateLayoutLvDataRow/PriceEstimateLayoutLvDataRow'
import { ExtendedUnitRuian } from '../PriceEstimateUnit/PriceEstimateUnit'

export interface PriceEstimateLayoutUnitLvProps {
  ruianData?: ExtendedUnitRuian
  lvData?: LvApiResponse & DrmJednotkaResponse
  propertyId: string
}

export const PriceEstimateLayoutUnitLv: FunctionComponent<PriceEstimateLayoutUnitLvProps> =
  props => {
    const unitPart = useMemo(() => {
      if (!props.lvData?.podilJednotkyNaSpolecnychCastech) return 0
      const parts = props.lvData?.podilJednotkyNaSpolecnychCastech
        ?.replace(/\s+/g, '')
        .split('/')
      return (parseFloat(parts[0]) / parseFloat(parts[1] || '1')) * 100
    }, [props.lvData?.podilJednotkyNaSpolecnychCastech])

    return (
      <>
        <Item title="Jednotka">
          <Link
            target="_blank"
            title="Zobrazit detail jednotky v novém okně"
            to={propertyDetailPath(PROPERTY_TYPES.UNIT, props.propertyId)}
          >
            {props.ruianData?.nazev}
          </Link>
        </Item>

        <Item
          condition={!!props.ruianData?.vBudove?.cislaDomovni}
          title="V budově"
        >
          <Link
            target="_blank"
            title="Zobrazit detail budovy v novém okně"
            to={propertyDetailPath(
              PROPERTY_TYPES.BUILDING,
              props.ruianData?.vBudove?.id
            )}
          >
            {props.ruianData?.vBudove?.cislaDomovni}
          </Link>
        </Item>

        <Item condition={!!unitPart} title="Podíl jednotky na spol. částech">
          <Money
            symbol="%"
            decimalNumbers={unitPart < 0.01 ? 3 : 2}
            amount={unitPart}
          />
        </Item>

        <Item title="Druh konstrukce">{props.ruianData?.druhKonstrukce}</Item>
        <Item title="Způsob vytápění">{props.ruianData?.zpusobVytapeni}</Item>
      </>
    )
  }
