import { AresAddress } from './aresTypes'

export interface RzpResponse {
  seznamNeniKompletni: boolean
  subjekty: Subjekt[]
}

export interface RzpFilterByAddressInput {
  addressId: string | number
  onlyValid?: boolean
  token: string
  tenantId: string
}
interface Subjekt {
  ico: string
  nazev: string
  roleSubjektu: string
  sidlo: string
  typ: string
  icoNeprideleno: boolean
  visible: boolean
  adresaTyp: string[]
  roleOsoby: null | string
  vzdalenost: number | null
  nalezenaAdresa: NalezenaAdresa | null
  ssarzp: string | null
}

export interface NalezenaAdresa {
  text: string
  adresaTyp: string[]
  vzdalenost: number | null
  platnostDo: string | null
  platnostOd?: string | null
}

// {"zdrojCiselniku": "rzp", "kodCiselniku": "TypSubjektu"}
export const rzpSubjectType = {
  F: { short: 'Fyzická osoba', long: 'Fyzická osoba tuzemská' },
  O: { short: 'Fyzická osoba', long: 'Fyzická osoba s organizační složkou' },
  P: { short: 'Právnická osoba', long: 'Právnická osoba tuzemská' },
  S: {
    short: 'Zahraniční právnická osoba',
    long: 'Zahraniční právnická osoba v roli statutárního orgánu',
  },
  X: {
    short: 'Zahraniční právnická osoba',
    long: 'Zahraniční právnická osoba',
  },
  Z: { short: 'Zahraniční fyzická osoba', long: 'Zahraniční fyzická osoba' },
}

export type RzpSubjectTypeKey = keyof typeof rzpSubjectType

export interface AresApiVrResponse {
  pocetCelkem: number
  ekonomickeSubjekty: EkonomickySubjekt[]
}

export interface EkonomickySubjekt {
  icoId: string
  zaznamy: RzpZaznam[]
}

export interface RzpZaznam {
  ico: string
  obchodniJmeno: string
  sidlo: AresAddress
  pravniForma: string
  financniUrad: string
  datumVzniku: string
  datumZaniku?: string
  datumAktualizace: string
  dic?: string
  insolvencniRizeni?: {
    datumZapisu: string
    platnostOd: string
  }
  datumDoruceniVypisu: string
  adresySubjektu: AdresaSubjektu[]
  typSubjektu: string
  zivnostenskyUrad: string
  organizacniSlozka?: string
  zivnostiStav: ZivnostiStav
  datumZapisuVr?: string
  provozovnyStav: ProvozovnyStav
  primarniZaznam: boolean
  kodStatu: string
  //souvisejici subjekty
  osobaPodnikatel: AngazovanaOsoba
  angazovaneOsoby: AngazovanaOsoba[]
  zivnosti: Zivnost[]
}
export interface AdresaSubjektu {
  kodStatu: string
  nazevStatu: string
  kodKraje: number
  nazevKraje: string
  kodOkresu: number
  nazevOkresu: string
  kodObce: number
  nazevObce: string
  kodUlice: number
  nazevUlice: string
  cisloDomovni: number
  kodCastiObce: number
  cisloOrientacni: number
  nazevCastiObce: string
  kodAdresnihoMista: number
  psc: number
  textovaAdresa: string
  typCisloDomovni: string
  standardizaceAdresy: boolean
  platnostOd: string
  platnostDo?: string
  typAdresy: string
}
export interface ZivnostiStav {
  pocetAktivnich: number
  pocetZaniklych: number
  pocetPozastavenych: number
  pocetPrerusenych: number
  pocetCelkem: number
}
export interface ProvozovnyStav {
  pocetCelkem: number
  pocetZaniklych: number
  pocetAktivnich: number
  pocetPozastavenych: number
}
export type AngazovanaOsoba = {
  jmeno: string
  prijmeni: string
  datumNarozeni: string
  platnostOd: string
  platnostDo?: string
  statniObcanstvi: string
  typAngazma: string
  titulPredJmenem?: string
  titulZaJmenem?: string
}
export type Zivnost = {
  datumVzniku: string
  datumZaniku?: string
  platnostDo?: string
  predmetPodnikani: string
  pozastaveniZivnosti: {
    platnostOd: string
    platnostDo?: string
    rozsahPozastaveni: string
  }[]
  podminkyProvozovaniZivnosti: {
    platnostOd: string
    platnostDo?: string
    podminka: string
  }[]
  preruseniZivnosti: {
    platnostOd: string
    platnostDo?: string
  }[]
  zivnostBezOz: {
    platnostOd: string
    platnostDo?: string
  }[]
  datumAktualizace: string
  druhZivnosti: string
  oboryCinnosti?: OborCinnosti[]
  odpovedniZastupci?: AngazovanaOsoba[]
  //provozovny: Provozovna[]
}
interface OborCinnosti {
  platnostOd: string
  platnostDo?: string
  oborNazev: string
}
