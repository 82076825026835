import React, { FunctionComponent } from 'react'

import { CreatePriceEstimateButton } from '../components/CreatePriceEstimateButton/CreatePriceEstimateButton'
import { PageLayout } from '../components/PageLayout/PageLayout'
import { PriceEstimateTable } from '../components/PriceEstimateTable/PriceEstimateTable'

export const PriceEstimate: FunctionComponent = () => {
  return (
    <PageLayout
      title="Cenové odhady nemovitostí"
      extra={<CreatePriceEstimateButton />}
    >
      <PriceEstimateTable />
    </PageLayout>
  )
}

export default PriceEstimate
