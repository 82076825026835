import { MaybeArray } from './cuzkLvTypes'

type Zprava = { attributes: { kod: string; uroven: string }; $value: string }
type Listina = {
  listinaId: string
  oznaceniListiny: string
  datumVyhotoveni?: Date | null
}
export type Report = {
  id: string
  nazev: string
  datumVytvoreni?: Date
  datumPozadavku: Date
  datumSpusteni: Date
  stav: string
  format: string
  casoveRaziko?: string
  cena?: number
  elZnacka?: string
  pocetJednotek?: string
  pocetStran?: string
  souborSestavy?: string // base64 encoded string
  /**
   * Položka je vyplněna v případě, kdy je sestava přidružená k nějaké sestavě.
   * Například u XML sestavy, které byla vygenerována společně s PDF sestavou.
   */
  idNadrizeneSestavy?: string
  /**
   * Položka je vyplněna v případě, kdy má sestava přidruženou nějakou sestavu.
   * Například u PDF sestavy je vrácen identifikátor XML sestavy.
   */
  idPodrizeneSestavy?: MaybeArray<string>
}

export enum CollectionType {
  SL = 'sbirkaListin',
  EPO = 'epo',
  LV = 'lv',
}

export type SestavaOutput = {
  vysledek: {
    zprava: MaybeArray<Zprava>
  }
  reportList: {
    report: MaybeArray<Report>
  }
} | null

export type ListinaOutput = {
  vysledek: {
    zprava: MaybeArray<Zprava>
  }
  ListinaList: {
    listina: MaybeArray<Listina>
  }
} | null

export interface UpdateDocumentCollectionRequest {
  id: string
  cuzkCollectionId: string
  stav: string
  tenantId: string
  collectionType: CollectionType
}

export interface CheckDocumentCollectionRequest {
  id?: string
  cuzkCollectionId?: string
  stav?: string
  cuzkDocumentId: string
  token: string
  proceeding: string
  userId: string
  tenantId: string
  collectionType: CollectionType
}

export interface CreateDocumentCollectionInput {
  accountId: string
  accountUserId: string
  cuzkDocumentId: string
  cuzkCollectionId: string
  rizeni: string
  nazev: string
  datumpozadavku: Date
  datumspusteni: Date
  stav: string
  format: string
}

export interface DeleteDocumentCollectionInput {
  id: string
  tenantId: string
  collectionType?: CollectionType
}

export interface UpdateDocumentCollectionInput {
  id: string
  stav: string
  datumvytvoreni?: Date
  casoveraziko?: string
  cena?: number
  elznacka?: string
  pocetjednotek?: number
  pocetstran?: number
}

export interface DocumentCollectionItem extends CreateDocumentCollectionInput {
  id: string
  datumvytvoreni?: Date
  casoveraziko?: string
  cena?: number
  elznacka?: string
  pocetjednotek?: number
  pocetstran?: number
  createdAt: Date
  selected?: boolean
}

export interface CreateLvInput {
  accountId: string
  accountUserId: string
  cuzkCollectionId: string
  lv: string
  katastralniUzemiKod: string
  katastralniUzemiNazev?: string
  datumK?: Date
  obec?: string
  datumpozadavku: Date
  datumspusteni: Date
  stav: string
  format: string
}

export interface UpdateLvInput {
  id: string
  stav: string
  datumvytvoreni?: Date
  cena?: number
  elznacka?: string
  pocetjednotek?: number
  pocetstran?: number
}

export interface CreateEpoInput {
  accountId: string
  accountUserId: string
  cuzkCollectionId: string
  typ: string
  nazev: string
  rcIco: string
  datumpozadavku: Date
  datumspusteni: Date
  stav: string
  format: string
}

export interface UpdateEpoInput {
  id: string
  stav: string
  datumvytvoreni?: Date
  cena?: number
  elznacka?: string
  pocetjednotek?: number
  pocetstran?: number
  pocetLv?: number
  adresnimistokod?: string
}

export interface CheckEpoRequest {
  id?: string
  cuzkCollectionId?: string
  typ: string
  nazev: string
  rcIco: string
  token: string
  userId: string
  tenantId: string
}

export interface CheckLvRequest {
  id?: string
  cuzkCollectionId?: string
  katastrUzemiKod: string
  katastrUzemiNazev?: string
  lvCislo: string
  datumK?: Date
  obec?: string
  token: string
  userId: string
  tenantId: string
}
