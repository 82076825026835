import { InfoCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { PROPERTY_TAB_KEYS } from '../../../common/propertyDetailTypes'
import { RuianUnitResponse } from '../../../common/ruianTypes'
import { useAppSelector } from '../../../redux/hooks'
import { propertyDetailPath } from '../../utils/paths'

export const TabLabelSubjectTooltip: FunctionComponent = () => {
  const ruianData = useAppSelector(
    state => state.property.ruian.data
  ) as RuianUnitResponse

  if (ruianData?.vBudove) {
    return (
      <div>
        <div className="text-gray-400 mb-2">
          <InfoCircleOutlined className="mr-2" />
          Jednotlivé adresy subjektů nelze rozlišit s přesností na bytovou
          jednotku, pouze na adresní místo budovy. Kliknutím na tlačítko můžete
          přejít na detail nadřazené budovy.
        </div>

        <Link
          to={propertyDetailPath(
            PROPERTY_TYPES.BUILDING,
            ruianData.vBudove.id,
            PROPERTY_TAB_KEYS.SUBJECT
          )}
        >
          <Button type="primary" size="small" className="w-full">
            <div className="truncate">
              Budova {ruianData.vBudove.cislaDomovni}
            </div>
          </Button>
        </Link>
      </div>
    )
  }
  return <>Tato nemovitost není adresní místo</>
}
