import React, { FunctionComponent } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import {
  ForclosureMonitoringFilterOnSubmit,
  ForclosureMonitoringFilterState,
} from '../../../common/foreclosureMonitoringTypes'
import { ForeclosureMonitoringFilterRange } from '../ForeclosureMonitoringFilterRange/ForeclosureMonitoringFilterRange'
import { PropertyMonitoringFilterAreaUse } from '../PropertyMonitoringFilterAreaUse/PropertyMonitoringFilterAreaUse'
import { PropertyMonitoringFilterCadastre } from '../PropertyMonitoringFilterCadastre/PropertyMonitoringFilterCadastre'
import { PropertyMonitoringFilterPropertyUse } from '../PropertyMonitoringFilterPropertyUse/PropertyMonitoringFilterPropertyUse'
import { PropertyMonitoringFilterSegmented } from '../PropertyMonitoringFilterSegmented/PropertyMonitoringFilterSegmented'

export interface ForeclosureMonitoringFilterProps {
  propertyType: PROPERTY_TYPES
  filter: ForclosureMonitoringFilterState
  handleChange: ForclosureMonitoringFilterOnSubmit
}

export const ForeclosureMonitoringFilter: FunctionComponent<ForeclosureMonitoringFilterProps> =
  ({ propertyType, filter, handleChange }) => {
    return (
      <div
        id="monitoringFilter"
        className="flex gap-x-8 gap-y-4 mb-8 flex-wrap"
      >
        <div className="grid grid-cols-2 w-full md:flex md:w-auto gap-x-8 gap-y-4">
          <PropertyMonitoringFilterSegmented
            filterKey="exekuce"
            value={filter.exekuce}
            handleChange={handleChange}
          />

          <PropertyMonitoringFilterSegmented
            filterKey="insolvence"
            value={filter.insolvence}
            handleChange={handleChange}
          />

          <PropertyMonitoringFilterSegmented
            filterKey="zastavySmluvni"
            value={filter.zastavySmluvni}
            handleChange={handleChange}
          />

          <PropertyMonitoringFilterSegmented
            filterKey="zastavyNedobrovolne"
            value={filter.zastavyNedobrovolne}
            handleChange={handleChange}
          />
        </div>
        <div className="grid grid-cols-2 w-full md:flex md:flex-wrap md:w-auto gap-x-8 gap-y-4">
          <ForeclosureMonitoringFilterRange
            valueFrom={filter.vlastnikuOd}
            valueTo={filter.vlastnikuDo}
            filterKeyFrom="vlastnikuOd"
            filterKeyTo="vlastnikuDo"
            onChange={handleChange}
          />

          {propertyType === PROPERTY_TYPES.AREA && (
            <>
              <ForeclosureMonitoringFilterRange
                valueFrom={filter.vymeraOd}
                valueTo={filter.vymeraDo}
                filterKeyFrom="vymeraOd"
                filterKeyTo="vymeraDo"
                onChange={handleChange}
              />
              <PropertyMonitoringFilterAreaUse
                selectedAreaUse={filter.zpusobyVyuzitiPozemku}
                selectedAreaType={filter.druhyPozemku}
                onChange={handleChange}
              />
            </>
          )}

          {propertyType === PROPERTY_TYPES.BUILDING && (
            <PropertyMonitoringFilterPropertyUse
              selected={filter.zpusobyVyuzitiBudov}
              propertyType={PROPERTY_TYPES.BUILDING}
              onChange={handleChange}
            />
          )}

          {propertyType === PROPERTY_TYPES.UNIT && (
            <PropertyMonitoringFilterPropertyUse
              selected={filter.zpusobyVyuzitiJednotek}
              propertyType={PROPERTY_TYPES.UNIT}
              onChange={handleChange}
            />
          )}

          <PropertyMonitoringFilterCadastre
            selected={filter.pracoviste}
            onChange={handleChange}
          />
        </div>
      </div>
    )
  }
