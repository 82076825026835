import React, { FunctionComponent } from 'react'

import { MarketplaceItem as MarketplaceItemInterface } from '../../../common/marketplaceTypes'

export interface MarketplaceItemContactProps {
  content: string | number
  contactData?: MarketplaceItemInterface['contact']
  title: string
}

export const MarketplaceItemContact: FunctionComponent<MarketplaceItemContactProps> =
  props => {
    return (
      <div
        className={`${
          props.content === 'contact'
            ? 'p-5 mb-4 mt-2 transition-all right-0 opacity-100'
            : 'p-5 mb-4 mt-2 absolute transition-all right-96 opacity-0'
        }`}
      >
        <h2 className="font-bold text-xl mb-2">{props.title}</h2>
        <h3 className="font-bold text-gray-500 mb-2">
          Kontaktní informace k nabídce
        </h3>

        <p className="text-justify">{props.contactData?.description}</p>
        <div className="pb-1 mb-2 border-b border-solid border-gray-200 flex justify-between mt-6">
          <div>Jméno</div>
          <div>{props.contactData?.name}</div>
        </div>
        <div className="pb-1 mb-2 border-b border-solid border-gray-200  flex justify-between">
          <div>Telefon</div>
          <div>{props.contactData?.phone}</div>
        </div>
        <div className="pb-1 mb-2 flex justify-between">
          <div>E-mail </div>
          <div>{props.contactData?.email}</div>
        </div>
      </div>
    )
  }
