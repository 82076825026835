import { Tooltip } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { PodilType } from '../../../common/cuzkLvTypes'
import { tooltipHelpUnderline } from '../../utils/layoutConst'
import { Money } from '../Money/Money'

export interface CuzkLvHtmlPodilFragmentProps {
  podil?: PodilType
  active?: boolean
}

export const CuzkLvHtmlPodilFragment: FunctionComponent<CuzkLvHtmlPodilFragmentProps> =
  ({ podil, active }) => {
    const amount = useMemo(() => {
      if (podil?.citatel && podil?.jmenovatel) {
        return (Number(podil.citatel) / Number(podil.jmenovatel)) * 100
      }
      return 0
    }, [podil])

    if (podil?.citatel && podil?.jmenovatel) {
      if (active) {
        return (
          <Tooltip
            title={<Money amount={amount} symbol="%" />}
            className={'breakLine ' + tooltipHelpUnderline}
          >
            {`${podil.citatel}/${podil.jmenovatel}`}
          </Tooltip>
        )
      }
      return (
        <span className="breakLine">{`${podil.citatel}/${podil.jmenovatel}`}</span>
      )
    }

    return null
  }
