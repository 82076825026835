import { InboxOutlined } from '@ant-design/icons'
import { Form, Upload } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import { BASE_URL } from '../../../../ini.json'
import { useAppSelector } from '../../../redux/hooks'
const { Dragger } = Upload

export const MarketplaceFormUploadFragment: FunctionComponent = () => {
  const token = useAppSelector(state => state.myProfile.token)
  const form = Form.useFormInstance()

  const getImageName = useCallback(
    (e: { file?: { status?: string }; fileList: unknown }) =>
      e?.file?.status !== 'uploading' &&
      form.setFieldValue('images', e?.fileList),
    [form]
  )

  return (
    <Dragger
      name="image"
      multiple={true}
      listType="picture"
      action={`${BASE_URL}/api/marketplace/images/${token}/${form.getFieldValue(
        'id'
      )}`}
      defaultFileList={form.getFieldValue('images')}
      accept=".jpg,.png,.gif,.jpeg,.bmp,.webp"
      onChange={getImageName}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text px-4">
        Klikněte nebo přetáhněte fotografie do tohoto pole
      </p>
      <p className="text-gray-400">Nahrávat lze pouze obrázky</p>
    </Dragger>
  )
}
