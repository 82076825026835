import { Icon } from '@iconify/react'
import { Tooltip } from 'antd'
import React, { FunctionComponent, memo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { WatchdogIconSize } from '../../../common/watchdogTypes'
import { useAppSelector } from '../../../redux/hooks'
import { WatchdogAddPropertyModalButton } from '../WatchdogAddPropertyModalButton/WatchdogAddPropertyModalButton'
import { WatchdogRemovedPropertyModalButton } from '../WatchdogRemovedPropertyModalButton/WatchdogRemovedPropertyModalButton'
import { WatchdogRemovePropertyButton } from '../WatchdogRemovePropertyButton/WatchdogRemovePropertyButton'

export interface WatchdogButtonProps {
  propertyId?: string
  propertyType: PROPERTY_TYPES
  iconSize?: WatchdogIconSize
  inWatchdogTable?: boolean
}

export const WatchdogButton: FunctionComponent<WatchdogButtonProps> = memo(
  props => {
    const property = useAppSelector(state => state.watchdog.property)
    const channel = useAppSelector(state => state.watchdog.channel)
    const iconSize = props.iconSize || 'text-lg'

    if (!props.propertyId || channel.error || property.error)
      return (
        <Tooltip
          title={
            !props.propertyId
              ? 'Nepodařilo se identifikovat nemovitost'
              : 'Nepodařilo se načíst údaje z hlídače katastru'
          }
          placement="left"
        >
          <div className="flex justify-center items-center text-gray-400 pt-1.5 pb-1">
            <Icon
              icon="material-symbols:notifications-off-outline"
              className={iconSize}
            />
          </div>
        </Tooltip>
      )

    if (!property.data && (property.fetching || channel.fetching))
      return (
        <div
          title="Načítání údajů z hlídače katastru"
          className="flex justify-center items-center text-gray-400 pt-1.5 pb-1"
        >
          <Icon
            icon="material-symbols:notification-add-outline"
            className={iconSize}
          />
        </div>
      )

    if (props.propertyId && property.data?.[props.propertyId])
      return (
        <WatchdogRemovePropertyButton
          propertyId={props.propertyId}
          propertyType={props.propertyType}
          iconSize={iconSize}
          inWatchdogTable={props.inWatchdogTable}
        />
      )

    if (props.inWatchdogTable)
      return (
        <WatchdogRemovedPropertyModalButton
          propertyId={props.propertyId}
          propertyType={props.propertyType}
          iconSize={iconSize}
        />
      )

    return (
      <WatchdogAddPropertyModalButton
        propertyId={props.propertyId}
        propertyType={props.propertyType}
        iconSize={iconSize}
      />
    )
  }
)
