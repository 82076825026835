import { DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { FunctionComponent } from 'react'

export interface PostServiceLetterImageModalFooterProps {
  handleCancel: () => void
  handleOk: () => void
  handleRemove: () => void
}

export const PostServiceLetterImageModalFooter: FunctionComponent<PostServiceLetterImageModalFooterProps> =
  props => {
    return (
      <div className="flex justify-between border-t pt-4 mt-6">
        <Button
          icon={<DeleteOutlined />}
          type="link"
          className="!pl-0"
          danger
          onClick={props.handleRemove}
        >
          Smazat obrázek
        </Button>
        <div className="space-x-2">
          <Button type="primary" ghost onClick={props.handleCancel}>
            Zavřít
          </Button>
          <Button type="primary" onClick={props.handleOk}>
            Uložit
          </Button>
        </div>
      </div>
    )
  }
