import { Icon } from '@iconify/react'
import { Skeleton } from 'antd'
import React from 'react'

import { Proceeding } from '../../common/proceedingTypes'

const skeletonLabel = (
  <div className="mr-2 flex justify-end">
    <Skeleton active paragraph={false} className="pt-1 pb-0.5 !w-20" />
  </div>
)

const skeletonValue = (
  <div className="mr-2 w-24">
    <Skeleton active paragraph={false} className="pt-1 pb-0.5" />
  </div>
)

export const parseProceedingString = (
  proceeding: string
): Proceeding | null => {
  const regex = /^(\p{L}{1,3})-(\d{1,8})\/(\d{4})-(\d{2,3})$/u
  const match = proceeding.match(regex)

  if (!match) return null

  const [, typ, cislo, rok, pracovisteKod] = match

  return {
    rok: parseInt(rok, 10),
    cislo: parseInt(cislo, 10),
    typ,
    pracovisteKod: parseInt(pracovisteKod, 10),
  }
}

export const getProceedingShortTitle = (proceeding: Proceeding) =>
  `${proceeding.typ}-${proceeding.cislo}/${proceeding.rok}`

export const getProceedingTitle = (proceeding: Proceeding) =>
  `${proceeding.typ}-${proceeding.cislo}/${proceeding.rok}-${proceeding.pracovisteKod}`

export const getProceedingKey = (proceeding: Proceeding) =>
  `${proceeding.typ}-${proceeding.cislo}-${proceeding.rok}-${proceeding.pracovisteKod}`

export const getProceedingUrlPath = (proceeding: Proceeding) =>
  `${proceeding.pracovisteKod}/${proceeding.rok}/${proceeding.typ}/${proceeding.cislo}`

export const proceedingDetailTimelineSkeletonItems = [
  {
    label: skeletonLabel,
    children: <span>Datum přijetí</span>,
    dot: <Icon icon="simple-line-icons:envelope-open" width="16" height="16" />,
  },
  { label: skeletonLabel, children: skeletonValue },
  { label: skeletonLabel, children: skeletonValue },
  {
    label: <span className="mr-2">Aktuální stav řízení</span>,
    children: skeletonValue,
    dot: <Icon icon="ri:progress-8-line" width="16" height="16" />,
  },
]

export const proceedingDetailTimelineNoDetails = [
  {
    label: <span className="mr-2">Úkony v řízení</span>,
    children: 'Detaily nezjištěny',
    dot: <Icon icon="radix-icons:dots-vertical" width="16" height="16" />,
    color: 'gray',
  },
]

export const proceedingDetailRequestButtonDescription = (
  <div className="w-64 space-y-2">
    <div>
      Touto akcí vznesete požadavek na příslušný katastrální úřad o zpřístupnění
      listiny ze Sbírky listin ČÚZK. Požadavek bude zpracován a v případě
      úspěšného zpracování bude listina dostupná k načtení.
    </div>
    <div className="text-gray-400 mb-2">
      V novém okně budete přesměrováni na stránku ČÚZK, kde budou zobrazeny
      dodatečné informace k žádosti.
    </div>
  </div>
)
