import { Tooltip } from 'antd'
import React, { FunctionComponent } from 'react'

import { isHugeSubjectLimit } from '../../utils/layoutConst'
import { formatCurrencyNumber } from '../Money/Money'

export interface CuzkEpoDetailTabLabelProps {
  title: string
  isHugeSubject?: boolean // Use this prop only if isHugeSubject sensitive
  icon: React.ReactNode
}

export const CuzkEpoDetailTabLabel: FunctionComponent<CuzkEpoDetailTabLabelProps> =
  props => {
    return (
      <Tooltip
        className="px-6 py-1"
        trigger={props.isHugeSubject ? 'hover' : []}
        title={`Pro subjekty, jejichž práva se vztahují k více než ${formatCurrencyNumber(
          isHugeSubjectLimit,
          0
        )} LV není tato možnost zobrazení k dispozici. Zobrazení takového množství dat by vedlo k nestabilitě webového prohlížeče.`}
      >
        {props.icon}
        <span>{props.title}</span>
      </Tooltip>
    )
  }
