import { Tag } from 'antd'
import React, { FunctionComponent, memo, useMemo } from 'react'
import { Link } from 'react-router-dom'

export interface MainMenuItemTitleProps {
  title: string
  link?: string
  ext?: boolean // external link
  new?: boolean // new badge
  arch?: boolean // archived badge
  dev?: boolean // development badge
  sub?: boolean // dropdown sub item
}

export const MainMenuItemTitle: FunctionComponent<MainMenuItemTitleProps> =
  memo(props => {
    const innerContent = useMemo(
      () => (
        <div className="flex items-center justify-between pr-1.5">
          {props.sub ? (
            <span>
              <span className="pr-5">-</span>
              {props.title}
            </span>
          ) : (
            props.title
          )}
          {props.new && (
            <Tag color="success" className="!text-xs !px-1">
              new
            </Tag>
          )}
          {props.dev && (
            <Tag color="blue" className="!text-xs !px-1 !bg-teal-50">
              dev
            </Tag>
          )}
          {props.arch && (
            <Tag className="!text-xs !px-1 !text-gray-400">archiv</Tag>
          )}
        </div>
      ),
      [props.arch, props.dev, props.new, props.sub, props.title]
    )

    if (props.link && props.ext) return <a href={props.link}>{innerContent}</a>
    if (props.link) return <Link to={props.link}>{innerContent}</Link>
    return innerContent
  })
