import { Alert, Tabs } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { CadastrePostSentTable } from '../CadastrePostSentTable/CadastrePostSentTable'
import { CeeMonitorTable } from '../CeeMonitorTable/CeeMonitorTable'
import { CuzkDocumentCollectionTable } from '../CuzkDocumentCollectionTable/CuzkDocumentCollectionTable'
import { CuzkEpoTable } from '../CuzkEpoTable/CuzkEpoTable'
import { CuzkLvTable } from '../CuzkLvTable/CuzkLvTable'
import { CuzkMonitorRcTable } from '../CuzkMonitorRcTable/CuzkMonitorRcTable'
import { PageSkeleton } from '../PageSkeleton/PageSkeleton'
import { PriceEstimateTable } from '../PriceEstimateTable/PriceEstimateTable'
import { PropertyLabelTable } from '../PropertyLabelTable/PropertyLabelTable'

export interface LabelOverviewProps {
  labelId: string
  connections: {
    [key: string]: string[]
  }
  loading: boolean
}

const tabBarStyle = { marginBottom: 0, marginTop: 12 }

export const LabelOverview: FunctionComponent<LabelOverviewProps> = props => {
  const defaultActiveKey = useMemo(() => {
    const keysToCheck = [
      'propertyLabelId',
      'monitoringCuzkRcId',
      'monitoringCeeId',
      'recipientListId',
      'monitId',
      'cuzkEpoId',
      'cuzkLvId',
      'cuzkDocumentCollectionId',
    ]

    for (const key of keysToCheck) {
      if (props.connections[key]?.length) {
        return key
      }
    }

    return 'unassigned'
  }, [props.connections])

  const tabItems = useMemo(() => {
    const items = [
      {
        key: 'propertyLabelId',
        label: 'Nemovitosti',
        disabled: !props.connections.propertyLabelId?.length,
        children: (
          <div className="bg-white py-2">
            <PropertyLabelTable labelId={props.labelId} />
          </div>
        ),
      },
      {
        key: 'monitoringCuzkRcId',
        label: 'ČÚZK Osoby',
        disabled: !props.connections.monitoringCuzkRcId?.length,
        children: (
          <div className="bg-white py-2">
            <CuzkMonitorRcTable
              MonitoringCuzkRcFilter={{
                id: { in: props.connections?.monitoringCuzkRcId },
              }}
            />
          </div>
        ),
      },
      {
        key: 'monitoringCeeId',
        label: 'CEE',
        disabled: !props.connections.monitoringCeeId?.length,
        children: (
          <div className="bg-white py-2">
            <CeeMonitorTable
              monitoringCeeFilter={{
                id: { in: props.connections.monitoringCeeId },
              }}
            />
          </div>
        ),
      },
      {
        key: 'recipientListId',
        label: 'Hromadná pošta',
        disabled: !props.connections.recipientListId?.length,
        children: (
          <div className="bg-white py-2">
            <CadastrePostSentTable
              idFilter={{ in: props.connections.recipientListId }}
            />
          </div>
        ),
      },
      {
        key: 'monitId',
        label: 'Cenové odhady',
        disabled: !props.connections.monitId?.length,
        children: (
          <div className="bg-white py-2">
            <PriceEstimateTable
              monitFilter={{ id: { in: props.connections.monitId } }}
            />
          </div>
        ),
      },
      {
        key: 'cuzkEpoId',
        label: 'Evidence práv pro osobu',
        disabled: !props.connections.cuzkEpoId?.length,
        children: (
          <div className="bg-white py-2">
            <CuzkEpoTable
              refetchState={false}
              setRefetchState={console.log}
              idFilter={{ in: props.connections.cuzkEpoId }}
            />
          </div>
        ),
      },
      {
        key: 'cuzkLvId',
        label: 'Listy vlastnictví',
        disabled: !props.connections.cuzkLvId?.length,
        children: (
          <div className="bg-white py-2">
            <CuzkLvTable
              refetchState={false}
              setRefetchState={console.log}
              idFilter={{ in: props.connections.cuzkLvId }}
            />
          </div>
        ),
      },
      {
        key: 'cuzkDocumentCollectionId',
        label: 'Sbírka listin',
        disabled: !props.connections.cuzkDocumentCollectionId?.length,
        children: (
          <div className="bg-white py-2">
            <CuzkDocumentCollectionTable
              idFilter={{ in: props.connections.cuzkDocumentCollectionId }}
            />
          </div>
        ),
      },
    ]
    if (defaultActiveKey === 'unassigned') {
      items.push({
        key: 'unassigned',
        label: 'Nepřiřazeno',
        disabled: false,
        children: (
          <div className="bg-white py-2">
            <Alert
              type="warning"
              message="Prozatím jste štítek nepřiřadili k žádnému záznamu."
            ></Alert>
          </div>
        ),
      })
    }

    return items
  }, [
    props.connections.propertyLabelId?.length,
    props.connections.monitoringCuzkRcId,
    props.connections.monitoringCeeId,
    props.connections.recipientListId,
    props.connections.monitId,
    props.connections.cuzkEpoId,
    props.connections.cuzkLvId,
    props.connections.cuzkDocumentCollectionId,
    props.labelId,
    defaultActiveKey,
  ])

  if (props.loading) {
    return <PageSkeleton />
  }
  return (
    <Tabs
      tabBarStyle={tabBarStyle}
      tabBarGutter={6}
      items={tabItems}
      defaultActiveKey={defaultActiveKey}
      type="card"
    />
  )
}
