import { Button } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { useAppSelector } from '../../../redux/hooks'
import { checkCeeDetail } from '../../utils/checkCeeDetail'
import { bulkEditTransition } from '../../utils/layoutConst'
import { pricelist } from '../../utils/pricelist'
import { CeeDetailPdf } from '../CeeDetailPdf/CeeDetailPdf'
import { CeeMonitorItem } from '../CeeMonitorTableExpandedRow/CeeMonitorTableExpandedRow'
import { Fade } from '../Fade/Fade'
import { PdfDrawerPreview } from '../PdfDrawerPreview/PdfDrawerPreview'
import { PricePopconfirm } from '../PricePopconfirm/PricePopconfirm'
import { TableItemsCount } from '../TableItemsCount/TableItemsCount'

type EnabledActions = {
  print: CeeMonitorItem[]
  fetch: CeeMonitorItem[]
}

export interface CeeExecutionTableBulkEditProps {
  selectedRows: CeeMonitorItem[]
  refetch: () => void
}

export const CeeExecutionTableBulkEdit: FunctionComponent<CeeExecutionTableBulkEditProps> =
  props => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const userId = useAppSelector(state => state.myProfile.userId)
    const selectedCount = props.selectedRows?.length || 0

    const enabled = useMemo(
      () =>
        props.selectedRows.reduce<EnabledActions>(
          (acc, item) => {
            const hasDetail = !!item?.ceeDetails?.nodes?.length
            hasDetail ? acc.print.push(item) : acc.fetch.push(item)
            return acc
          },
          { print: [], fetch: [] }
        ),
      [props.selectedRows]
    )

    const pdfDrawerProps = useMemo(
      () => ({
        buttonProps: {
          ghost: true,
          disabled: !enabled.print.length,
        },
        drawerTitle: 'Náhled PDF - detaily exekucí',
        fileName: 'detaily_CEE_REGESTA_DATA',
        buttonText: `Zobrazit PDF (${enabled.print.length})`,
      }),
      [enabled.print.length]
    )

    const handleCheck = useCallback(async () => {
      const check = await checkCeeDetail(enabled.fetch, tenantId, userId)
      if (check) return props.refetch()
      return
    }, [enabled.fetch, props, tenantId, userId])

    return (
      <Fade
        transition={bulkEditTransition}
        show={!!selectedCount}
        className="flex space-x-4 items-center mb-4"
      >
        <PricePopconfirm
          onConfirm={handleCheck}
          itemPrice={pricelist.CEE}
          itemCount={enabled.fetch.length}
          message={`Zobrazení částky a detailu exekuce je zpoplatněno ${pricelist.CEE} Kr.`}
        >
          <Button type="primary" disabled={!enabled.fetch.length}>
            Zobrazit částky ({enabled.fetch.length})
          </Button>
        </PricePopconfirm>

        <PdfDrawerPreview {...pdfDrawerProps}>
          <CeeDetailPdf data={enabled.print} />
        </PdfDrawerPreview>

        <TableItemsCount prefix="Vybráno" separator total={selectedCount} />
      </Fade>
    )
  }
