import { Button, Modal } from 'antd'
import React, { FunctionComponent, useCallback, useState } from 'react'

import { RestrictionChecType } from '../../../common/epoTypes'
import {
  CadastralCheckedValues,
  CadastralOffice,
} from '../../utils/cuzkHelpers'
import { CadastralSelectionModalContent } from '../CadastralSelectionModalContent/CadastralSelectionModalContent'

export interface CadastralSelectionFormValues {
  cadastreAreas?: CadastralCheckedValues
}

export interface CadastralSelectionModalButtonProps {
  checkboxData: CadastralOffice[]
  epoId: string
  refetch: () => void
  type: RestrictionChecType
}

export const CadastralSelectionModalButton: FunctionComponent<CadastralSelectionModalButtonProps> =
  ({ refetch, epoId, checkboxData, type }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const title =
      type === RestrictionChecType.lien
        ? 'Výběr LV, pro které chcete získat detaily omezení'
        : 'Výběr katastrálních pracovišť, pro které chcete získat informace o právech 3. osob'

    const buttonText =
      type === RestrictionChecType.lien ? 'Vybrat LV' : 'Vybrat kat. pracoviště'

    const toggleModalVisibility = useCallback(() => {
      setIsModalVisible(prev => !prev)
    }, [])

    return (
      <div>
        <Button type="primary" onClick={toggleModalVisibility}>
          {buttonText}
        </Button>
        <Modal
          title={title}
          open={isModalVisible}
          onCancel={toggleModalVisibility}
          footer={null}
          width="80%"
        >
          <CadastralSelectionModalContent
            type={type}
            checkboxData={checkboxData}
            toggleModalVisibility={toggleModalVisibility}
            refetch={refetch}
            epoId={epoId}
          />
        </Modal>
      </div>
    )
  }
