import { CloseCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { Button, ButtonProps, Spin } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, memo, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { AddressApiResponse } from '../../../common/addressTypes'
import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { PROPERTY_TAB_KEYS } from '../../../common/propertyDetailTypes'
import { useAppSelector } from '../../../redux/hooks'
import { propertyDetailPath, requestAddressPath } from '../../utils/paths'
import { MapTooltip } from '../MapTooltip/MapTooltip'

export interface AddressLinkProps {
  addressId?: number | string
  children?: React.ReactNode
  address?: string
  tabTarget?: PROPERTY_TAB_KEYS
  className?: string
  buttonProps?: ButtonProps
}

export const AddressLink: FunctionComponent<AddressLinkProps> = memo(props => {
  const history = useHistory()
  const token = useAppSelector(state => state.myProfile.token)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const fetchAndRedirect = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = (await axios.get(
        requestAddressPath() + token + '/' + props.addressId?.toString()
      )) as { data: AddressApiResponse }

      if (data.budovaId) {
        history.push(
          propertyDetailPath(
            PROPERTY_TYPES.BUILDING,
            data.budovaId,
            props.tabTarget || PROPERTY_TAB_KEYS.OWNERSHIP
          )
        )
      }
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [token, props.addressId, props.tabTarget, history])

  if (props.buttonProps) {
    return (
      <Button
        {...props.buttonProps}
        icon={error ? <CloseCircleOutlined /> : undefined}
        onClick={fetchAndRedirect}
        loading={loading}
        disabled={error}
        title={error ? 'Adresu se nepodařilo najít' : 'Zobrazit detail adresy'}
      >
        {props.children || props.address}
      </Button>
    )
  }

  if (error) {
    return (
      <MapTooltip
        query={
          typeof props.children === 'string'
            ? props.children
            : props.address || ''
        }
        title="Je nám líto, ale nepodařilo se najít informace k této adrese."
      >
        {props.children || props.address}{' '}
        <WarningOutlined className="text-red-500 ml-2" />
      </MapTooltip>
    )
  }

  return (
    <Spin size="small" wrapperClassName="inline" spinning={loading}>
      <span
        onClick={fetchAndRedirect}
        className={
          'text-primary hover:text-primaryHover cursor-pointer ' +
          (props.className || '')
        }
        title="Zobrazit detail budovy"
      >
        {props.children || props.address}
      </span>
    </Spin>
  )
})
