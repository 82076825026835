import { InfoCircleOutlined } from '@ant-design/icons'
import { Alert, Spin } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { RestrictionCheckboxData } from '../../../common/epoTypes'
import { RestrictionCheckState } from '../../../graphql/generated'

export interface CuzkEpoRestrictionCheckAlertIsCheckingProps {
  inProgress: RestrictionCheckboxData['inProgress']
}

export const CuzkEpoRestrictionCheckAlertIsChecking: FunctionComponent<CuzkEpoRestrictionCheckAlertIsCheckingProps> =
  props => {
    const description = useMemo(() => {
      const isProcessing =
        props.inProgress?.filter(
          item => item.state === RestrictionCheckState.Processing
        ) || []

      return (
        <div className="flex gap-x-2 flex-wrap">
          {isProcessing?.map(
            item =>
              item.state === RestrictionCheckState.Processing && (
                <div className="pr-2 border-r border-solid" key={item.id}>
                  {item.katastralniPracovisteNazev}
                  {' ('}
                  {item.lvKeKontrolePocet}
                  {' LV )'}
                </div>
              )
          )}
          <div
            className={
              isProcessing.length > 8
                ? 'w-full border-t border-dashed mt-3 pt-2 border-gray-500'
                : ''
            }
          >
            <InfoCircleOutlined className="mr-2" /> Během kontroly můžete dále
            pracovat napříč aplikací.
          </div>
        </div>
      )
    }, [props.inProgress])

    return (
      <Spin spinning>
        <Alert
          message="Probíhá ověřování práv 3. stran pro LV v katastrálním pracovišti:"
          description={description}
          className="!mb-4"
          type="info"
        />
      </Spin>
    )
  }
