import React, { FunctionComponent } from 'react'

export interface ForeclosureMonitoringFilterItemProps {
  children: React.ReactNode
  label: React.ReactNode
  className?: string
}

export const PropertyMonitoringFilterItem: FunctionComponent<ForeclosureMonitoringFilterItemProps> =
  props => {
    return (
      <div className={`${props.className || ''} flex flex-col gap-y-1.5`}>
        <div className="whitespace-nowrap">{props.label}</div>
        {props.children}
      </div>
    )
  }
