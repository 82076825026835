import { Button, Popconfirm, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { BookmarkDetailItem } from '../../../common/bookmarkTypes'
import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { defaultPagination } from '../../utils/layoutConst'
import { addressDetailPath, propertyDetailPath } from '../../utils/paths'
import { Min } from '../DateFormat/DateFormat'

export interface PostServiceTableProps {
  data: BookmarkDetailItem[]
  setRecipientList: (value: BookmarkDetailItem[]) => void
}

export const PostServiceTable: FunctionComponent<PostServiceTableProps> =
  props => {
    const data = props.data

    const removeRecipient = useCallback(
      (idToRemove: string) => {
        props.setRecipientList(
          props.data.filter(
            (item: BookmarkDetailItem) => item.subjekt.id !== idToRemove
          )
        )
      },
      [props]
    )

    const columns = useMemo<ColumnsType<BookmarkDetailItem>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          responsive: ['' as Breakpoint],
          render: (_, record) => <>{record?.subjekt?.id}</>,
        },
        {
          title: 'Jméno / název',
          dataIndex: 'subject',
          key: 'subject',
          sorter: {
            compare: (a, b) => a.subjekt?.nazev.localeCompare(b.subjekt?.nazev),
          },
          render: (_, record) => (
            <a
              href={`https://data.regesta.cz/Vyhledavani/Subjekt/${record?.subjekt?.id}`}
            >
              {record?.subjekt?.nazev}
            </a>
          ),
        },
        {
          title: 'Adresa',
          dataIndex: 'address',
          key: 'address',
          sorter: {
            compare: (a, b) =>
              a.adresniMisto?.formatovanaAdresa.localeCompare(
                b.adresniMisto?.formatovanaAdresa
              ),
          },
          render: (_, record) => {
            return (
              <Link to={addressDetailPath(record.adresniMisto.kod)}>
                {record?.adresniMisto?.formatovanaAdresa}
              </Link>
            )
          },
        },
        {
          title: 'Typ nemovitosti',
          dataIndex: 'typ',
          key: 'typ',
          sorter: {
            compare: (a, b) =>
              a.nemovitost?.typ.localeCompare(b.nemovitost?.typ),
          },
          render: (_, record) => <>{record.nemovitost?.typ}</>,
        },
        {
          title: 'Nemovitost',
          dataIndex: 'property',
          key: 'property',
          sorter: {
            compare: (a, b) =>
              a.nemovitost?.nazev.localeCompare(b.nemovitost?.nazev),
          },
          render: (_, record) => (
            <Link
              to={propertyDetailPath(
                record.nemovitost?.typ as PROPERTY_TYPES,
                record.nemovitost?.id
              )}
            >
              {record?.nemovitost?.nazev}
            </Link>
          ),
        },
        {
          title: 'Zařadil',
          dataIndex: 'zalozil',
          key: 'zalozil',
          sorter: {
            compare: (a, b) => a.zalozil?.jmeno.localeCompare(b.zalozil?.jmeno),
          },
          render: (_, record) => (
            <>
              {record?.zalozil?.jmeno} {record?.zalozil?.prijmeni}
            </>
          ),
        },
        {
          title: 'Zařazeno dne',
          dataIndex: 'zalozeno',
          key: 'zalozeno',
          sorter: {
            compare: (a, b) => a.zalozeno.localeCompare(b.zalozeno),
          },
          render: zalozeno => <Min>{zalozeno}</Min>,
        },
        {
          title: 'Akce',
          dataIndex: 'action',
          key: 'action',
          render: (_, record) => (
            <Popconfirm
              title="Opravdu chcete odebrat vybraného adresáta?"
              onConfirm={() => removeRecipient(record.subjekt.id)}
              okText="Odebrat"
              cancelText="Zrušit"
            >
              <Button type="link" danger>
                Odebrat
              </Button>
            </Popconfirm>
          ),
        },
      ],
      [removeRecipient]
    )

    const rowKey = useCallback(
      (record: BookmarkDetailItem) => record.subjekt.id,
      []
    )

    return (
      <Table
        pagination={defaultPagination}
        dataSource={data}
        columns={columns}
        rowKey={rowKey}
        size="small"
        className="mb-6"
      />
    )
  }
