import { Tooltip } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { RizeniType } from '../../../common/cuzkLvTypes'
import { Rizeni } from '../../../common/proceedingTypes'
import { parseProceedingString } from '../../utils/proceedingDetailHelpers'
import { ProceedingDetailModalLink } from '../ProceedingDetailModalLink/ProceedingDetailModalLink'

export interface CuzkLvHtmlRizeniFragmentProps {
  rizeni?: RizeniType
  active?: boolean
}

export const CuzkLvHtmlRizeniFragment: FunctionComponent<CuzkLvHtmlRizeniFragmentProps> =
  props => {
    const rizeniTitle =
      props.rizeni?.typriz_kod +
      '-' +
      props.rizeni?.poradove_cislo +
      '/' +
      props.rizeni?.rok +
      '-' +
      props.rizeni?.prares_kod

    const rizeniLink = useMemo(
      () => (
        <Tooltip title="Zobrazit detail řízení">
          <ProceedingDetailModalLink
            proceeding={parseProceedingString(rizeniTitle) as Rizeni}
          >
            {rizeniTitle}
          </ProceedingDetailModalLink>
        </Tooltip>
      ),
      [rizeniTitle]
    )

    if (props.active) return rizeniLink
    return <>{rizeniTitle}</>
  }
