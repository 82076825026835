import { Breakpoint, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { Parcela } from '../../../common/lvTypes'
import {
  priceEstimateSummaryStyle,
  priceEstimateTableProps,
} from '../../utils/layoutConst'
import { Money } from '../Money/Money'

export interface PriceEstimateLayoutAreaTableProps {
  parcely: Parcela[]
  propertyId: string
}

export const PriceEstimateLayoutAreaTable: FunctionComponent<PriceEstimateLayoutAreaTableProps> =
  props => {
    const areaColumns: ColumnsType<Parcela> = useMemo(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Pozemek',
          dataIndex: 'druhPozemku',
          key: 'druhPozemku',
          className: 'w-2/6',
          sorter: (a, b) =>
            (a?.druhPozemku || '').localeCompare(b?.druhPozemku || ''),
        },
        {
          title: 'Parcelní číslo',
          dataIndex: 'parcelniCislo',
          key: 'parcelniCislo',
          className: 'w-2/6',
        },
        {
          title: 'Výměra m²',
          dataIndex: 'vymera',
          key: 'vymera',
          className: 'w-1/6 !text-right',
        },
        {
          dataIndex: 'note',
          key: 'note',
          className: 'w-1/6 text-right',
          render: (_, record) =>
            record.id === props.propertyId && 'Tento pozemek',
        },
      ],
      [props.propertyId]
    )

    const summary = useCallback(
      () =>
        props.parcely.length > 1 ? (
          <Table.Summary.Row>
            <Table.Summary.Cell
              className={priceEstimateSummaryStyle}
              index={1}
              colSpan={2}
            />
            <Table.Summary.Cell className={priceEstimateSummaryStyle} index={2}>
              Celkem:{' '}
              <Money
                decimalNumbers={0}
                amount={props.parcely?.reduce(
                  (a, b) => a + Number(b.vymera),
                  0
                )}
              />
            </Table.Summary.Cell>
            <Table.Summary.Cell
              className={priceEstimateSummaryStyle}
              index={3}
            />
          </Table.Summary.Row>
        ) : null,
      [props.parcely]
    )

    const rowClassName = useCallback(
      (record: Parcela, a: number) => {
        const color = record.id === props.propertyId ? 'text-gray-400' : ''
        const borderBottom = a === props.parcely.length - 1 ? 'border-b' : ''
        return `${color} ${borderBottom}`
      },
      [props.parcely.length, props.propertyId]
    )

    return (
      <Table
        dataSource={props?.parcely}
        columns={areaColumns}
        rowClassName={rowClassName}
        summary={summary}
        rowKey="id"
        {...priceEstimateTableProps}
      />
    )
  }
