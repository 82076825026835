import { message } from 'antd'
import axios from 'axios'

export const setAutoImport = async (value: boolean) => {
  try {
    await axios.post(
      `/api/browserExtension/`,
      { autoImport: value },
      { withCredentials: true }
    )

    !value && message.success('Automatický import vypnut.')
    return true
  } catch (error) {
    message.error('Nepodařilo se uložit nastavení.')
    return false
  }
}

export const ImportBoxStyle = {
  large: {
    icon: 'flex items-center text-primary text-6xl',
    headline: 'text-xl mb-1.5 mt-4',
    button: 'flex items-center mt-8 mb-2',
  },
  middle: {
    icon: 'p-4 mr-4 flex items-center text-primary text-5xl',
    headline: 'text-xl mb-1.5',
    button: 'flex flex-col items-start justify-center space-y-2',
  },
  small: {
    icon: 'flex items-center text-primary text-4xl',
    headline: 'text-base my-2',
    button: 'flex items-center mt-6 mb-2',
  },
}

export const ImportButtonStyle = {
  large: {
    icon: 'flex items-center text-primary text-6xl',
    headline: 'text-xl',
    button: 'flex items-center mt-8',
  },
  middle: {
    icon: 'flex items-center text-primary text-6xl',
    headline: 'text-xl',
    button: 'flex items-center mt-8',
  },
  small: {
    icon: 'flex items-center text-primary text-4xl',
    headline: 'text-base my-2',
    button: 'flex items-center mt-4',
  },
}
