import React, { FunctionComponent, useMemo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { usePropertyDetailNoteTimelineQuery } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { PropertyDetailNote as PropertyDetailNoteType } from '../../utils/propertyDetailNoteHelpers'
import { PropertyDetailNoteCreate } from '../PropertyDetailNoteCreate/PropertyDetailNoteCreate'
import { PropertyDetailNoteTimeline } from '../PropertyDetailNoteTimeline/PropertyDetailNoteTimeline'
import { PropertyDetailNoteTimelineSkeleton } from '../PropertyDetailNoteTimelineSkeleton/PropertyDetailNoteTimelineSkeleton'

export interface PropertyNoteModalContentProps {
  propertyId: string
  propertyLv: string
  propertyCadastralCode: string
  propertyType: PROPERTY_TYPES
  target?: React.HTMLAttributeAnchorTarget
}

export const PropertyNoteModalContent: FunctionComponent<PropertyNoteModalContentProps> =
  props => {
    const accountId = useAppSelector(state => state.myProfile.tenantId)

    const [queryResult, refetch] = usePropertyDetailNoteTimelineQuery({
      variables: {
        accountId,
        propertyCadastralCode: props?.propertyCadastralCode || '',
        propertyLv: props?.propertyLv || '',
      },
    })

    const notes = useMemo(
      () =>
        (queryResult.data?.allPropertyNotes?.nodes ||
          []) as PropertyDetailNoteType[],
      [queryResult.data?.allPropertyNotes?.nodes]
    )

    return (
      <div className="w-full max-w-7xl mt-10">
        {queryResult.fetching && !notes.length ? (
          <PropertyDetailNoteTimelineSkeleton />
        ) : (
          <PropertyDetailNoteTimeline
            notes={notes}
            refetch={refetch}
            target={props.target}
          />
        )}
        <PropertyDetailNoteCreate
          propertyId={props.propertyId}
          propertyCadastralCode={props.propertyCadastralCode}
          propertyLv={props.propertyLv}
          propertyType={props.propertyType}
        />
      </div>
    )
  }
