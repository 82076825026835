import { Button, Divider, Modal, Radio, RadioChangeEvent, Space } from 'antd'
import { BaseButtonProps } from 'antd/es/button/button'
import React, { FunctionComponent, useCallback, useState } from 'react'

import { PostserviceLetterType } from '../../../graphql/generated'
import { DeliveryMethodDescription } from '../DeliveryMethodDescription/DeliveryMethodDescription'
import { DeliveryMethodRadioItem } from '../DeliveryMethodRadioItem/DeliveryMethodRadioItem'
import { PostServiceLetterCreateSteps } from '../PostServiceLetterCreateSteps/PostServiceLetterCreateSteps'
import { PostServiceSendModalFooter } from '../PostServiceSendModalFooter/PostServiceSendModalFooter'

export interface PostServiceSendModalButtonProps {
  refetch: () => void
  recipientsCount: number
  letterId: string
  buttonProps?: BaseButtonProps
  buttonText?: string
  allowAbroad?: boolean
}

export const PostServiceSendModalButton: FunctionComponent<PostServiceSendModalButtonProps> =
  props => {
    const [open, setOpen] = useState(false)
    const [deliveryMethod, setDeliveryMethod] = useState(
      PostserviceLetterType.OrdinaryLetter
    )

    const handleModal = useCallback(() => {
      setOpen(prev => !prev)
    }, [])

    const handleOnChange = useCallback((e: RadioChangeEvent) => {
      setDeliveryMethod(e.target.value)
    }, [])

    return (
      <>
        <Button
          type="primary"
          onClick={handleModal}
          className="sm:w-auto w-full"
          {...props.buttonProps}
        >
          {props.buttonText || 'Rozeslat poštu'}
        </Button>

        <Modal
          title={`Rozeslat hromadnou poštu (${props.recipientsCount} ks)`}
          width={700}
          open={open}
          onCancel={handleModal}
          footer={null}
        >
          <div>
            <DeliveryMethodDescription deliveryMethod={deliveryMethod} />
            <div className="flex space-x-4 my-10 items-baseline justify-center">
              <span>
                <span className="text-red-500 mr-1">*</span>Způsob podání:
              </span>
              <Radio.Group
                onChange={handleOnChange}
                defaultValue={PostserviceLetterType.OrdinaryLetter}
              >
                <Space direction="vertical">
                  <DeliveryMethodRadioItem
                    type={PostserviceLetterType.OrdinaryLetter}
                  />
                  <DeliveryMethodRadioItem
                    type={PostserviceLetterType.Registered}
                  />
                  {!props.allowAbroad && (
                    <>
                      <DeliveryMethodRadioItem
                        type={PostserviceLetterType.OrdinaryLetterInternational}
                      />
                      <DeliveryMethodRadioItem
                        type={PostserviceLetterType.RegisteredInternational}
                      />
                    </>
                  )}
                </Space>
              </Radio.Group>
            </div>

            <Divider />
            <PostServiceLetterCreateSteps
              percent={100}
              current={3}
              status3="finish"
              status2="finish"
              status1="finish"
              className="!mt-8"
            />
            <Divider />
            <PostServiceSendModalFooter
              deliveryMethod={deliveryMethod}
              handleModal={handleModal}
              letterId={props.letterId}
              refetch={props.refetch}
              recipientsCount={props.recipientsCount}
            />
          </div>
        </Modal>
      </>
    )
  }
