import { createSlice } from '@reduxjs/toolkit'

import { WatchdogChannelApiResponse } from '../common/watchdogTypes'
import handleAsyncActions, {
  AsyncState,
  initialFetchState,
  WatchDogDataTypes,
} from './fetchAction'

const watchdogChannelSlice = createSlice({
  name: WatchDogDataTypes.CHANNEL,
  initialState: initialFetchState as AsyncState<WatchdogChannelApiResponse>,
  reducers: {},
  extraReducers: builder => {
    handleAsyncActions<WatchdogChannelApiResponse>(
      builder,
      WatchDogDataTypes.CHANNEL
    )
  },
})

export default watchdogChannelSlice.reducer
