import React, { FunctionComponent } from 'react'

import {
  HeritageMonitoringFilterOnSubmit,
  HeritageMonitoringFilterState,
} from '../../../common/heritageMonitoringTypes'
import { PropertyMonitoringFilterCadastre } from '../PropertyMonitoringFilterCadastre/PropertyMonitoringFilterCadastre'

export interface HeritageMonitoringFilterProps {
  filter: HeritageMonitoringFilterState
  handleChange: HeritageMonitoringFilterOnSubmit
}

export const HeritageMonitoringFilter: FunctionComponent<HeritageMonitoringFilterProps> =
  ({ filter, handleChange }) => {
    return (
      <div
        id="monitoringFilter"
        className="flex gap-x-8 gap-y-4 mb-8 flex-wrap"
      >
        <PropertyMonitoringFilterCadastre
          selected={filter.pracoviste}
          onChange={handleChange}
          className="sm:flex-row sm:items-center sm:space-x-2"
        />
      </div>
    )
  }
