import { EyeOutlined } from '@ant-design/icons'
import { Badge, Tag, Tooltip } from 'antd'
import React, { FunctionComponent, memo, useMemo } from 'react'

import { UserActivityLog } from '../../../common/userActivityLogTypes'
import { Day } from '../DateFormat/DateFormat'

export interface PropertyMonitoringTilesItemRibbonProps {
  alertDateTo?: string | null
  userActivityLog?: UserActivityLog | null
  children: React.ReactNode
}

export const PropertyMonitoringTilesItemRibbon: FunctionComponent<PropertyMonitoringTilesItemRibbonProps> =
  memo(props => {
    const alertDateTo = useMemo(() => {
      if (!props.alertDateTo || !props.userActivityLog) return null
      return (
        new Date(props.alertDateTo) > new Date(props.userActivityLog.editedAt)
      )
    }, [props.alertDateTo, props.userActivityLog])

    const ribbonText = useMemo(
      () => (
        <Tooltip
          title={
            alertDateTo ? (
              <span>
                Od vašeho posledního náhledu detailu (
                <Day>{props.userActivityLog?.editedAt}</Day>) proběhla změna.
              </span>
            ) : (
              <span>
                Váš poslední náhled detailu:{' '}
                <Day>{props.userActivityLog?.editedAt}</Day>
              </span>
            )
          }
        >
          <Tag
            icon={<EyeOutlined />}
            color={alertDateTo ? 'warning' : 'default'}
            className="!border-0"
          >
            <Day>{props.userActivityLog?.editedAt}</Day>
          </Tag>
        </Tooltip>
      ),
      [alertDateTo, props.userActivityLog?.editedAt]
    )

    return (
      <Badge.Ribbon
        text={ribbonText}
        placement="end"
        className={
          props.userActivityLog ? '!h-auto !py-1 !shadow-sm' : 'hidden'
        }
        color={alertDateTo ? '#fffbe6' : 'white'}
      >
        {props.children}
      </Badge.Ribbon>
    )
  })
