import { Icon } from '@iconify/react'
import { Modal, Tooltip } from 'antd'
import React, {
  CSSProperties,
  FunctionComponent,
  useMemo,
  useState,
} from 'react'
import { PieChart, pieChartDefaultProps } from 'react-minimal-pie-chart'

import { POZEMKY } from '../../../common/cuzkLvTypes'
import { notEmpty } from '../../../common/pdfHelpers'
import { PieChartColorPalette } from '../../utils/collorPallete'
import { Money } from '../Money/Money'

export interface CuzkLvHtmlPozemkyGrafModalButtonProps {
  pozemky?: POZEMKY
}

const okButtonProps = { style: { display: 'none' } }

export const CuzkLvHtmlPozemkyGrafModalButton: FunctionComponent<CuzkLvHtmlPozemkyGrafModalButtonProps> =
  props => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const handleModal = () => {
      setIsModalOpen(open => !open)
    }

    const prepareParcelData = useMemo(() => {
      const parcely = Array.isArray(props.pozemky?.PARCELA)
        ? props.pozemky?.PARCELA
        : [props.pozemky?.PARCELA]

      let totalArea = 0
      const druhPozSums: { [key: string]: number } = {}
      const totalCount = parcely?.length || 0

      parcely?.forEach(parcela => {
        const area = parseFloat(parcela?.VYMERA_PARCELY || '0')
        totalArea += area
        if (parcela?.DRUH_POZ) {
          if (parcela.DRUH_POZ in druhPozSums) {
            druhPozSums[parcela.DRUH_POZ] += area
          } else {
            druhPozSums[parcela.DRUH_POZ] = area
          }
        }
      })

      const modalTitle = (
        <div>
          Celkem {totalCount} parcel o výměře{' '}
          <Money amount={totalArea} decimalNumbers={0} symbol={'m2'} />
        </div>
      )
      const pieChartData = Object.keys(druhPozSums).map((key, index) => ({
        title: key,
        value: druhPozSums[key],
        color: PieChartColorPalette[index],
      }))

      return { modalTitle, pieChartData }
    }, [props.pozemky?.PARCELA])

    const pieChartProp = useMemo(
      () => ({
        viewBoxSize: [200, 120] as [number, number],
        center: [100, 60] as [number, number],
        data: prepareParcelData.pieChartData,
        radius: pieChartDefaultProps.radius - 6,
        segmentsStyle: { transition: 'stroke .3s' },
        labelPosition: 112,
        lineWidth: 60,
        animate: true,
        labelStyle: {
          opacity: 0.75,
          pointerEvents: 'none',
          whiteSpace: 'pre',
          lineHeight: 'initial',
          fontSize: '.3rem',
        } as CSSProperties,
        style: {
          overflow: 'visible',
        },
        label: ({
          dataEntry: { title, percentage, value },
        }: {
          dataEntry: { title: string; percentage: number; value: number }
        }) => `${title}\n${Math.round(percentage)} % (${Math.round(value)} m2)`,
      }),
      [prepareParcelData.pieChartData]
    )

    if (!props.pozemky || !notEmpty(props.pozemky.PARCELA)) {
      return null
    }
    return (
      <>
        <Tooltip title="Zobrazit přehled pozemků v grafu">
          <span
            className="text-primary cursor-pointer relative"
            onClick={handleModal}
          >
            <span className="absolute -left-4 pr-1">
              <Icon icon="ion:bar-chart" width="12" />
            </span>
            Výměra [m2]
          </span>
        </Tooltip>
        <Modal
          title={prepareParcelData.modalTitle}
          open={isModalOpen}
          okButtonProps={okButtonProps}
          cancelText="Zavřít"
          onCancel={handleModal}
          width={600}
          closable
        >
          <div className="border-y">
            <PieChart {...pieChartProp} />
          </div>
        </Modal>
      </>
    )
  }
