import { Map, MapEvent } from 'mapbox-gl'
import * as React from 'react'
import {
  cloneElement,
  FunctionComponent,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import type { ControlPosition, IControl, MapInstance } from 'react-map-gl'
import { useControl } from 'react-map-gl'

type Event = Exclude<MapEvent, number | symbol>

class OverlayControl implements IControl {
  _map: MapInstance | null = null
  _container!: HTMLElement
  _redraw: () => void
  _events: Array<Event>

  constructor(redraw: () => void, events: Array<Event>) {
    this._redraw = redraw
    this._events = events
  }

  onAdd(map: MapInstance) {
    this._map = map
    for (const event of this._events) {
      this._map.on(event, this._redraw)
    }
    this._container = document.createElement('div')
    this._container.className = 'mapboxgl-ctrl'
    this._redraw()
    return this._container
  }

  onRemove() {
    this._container.remove()
    if (!this._map) return
    for (const event of this._events) {
      this._map.off(event, this._redraw)
    }
    this._map = null
  }

  getMap() {
    return this._map as Map
  }

  getElement() {
    return this._container
  }
}

interface CustomOverlayProps {
  children?: ReactNode | ((props: { map: Map }) => ReactNode)
  position?: ControlPosition
  events?: Array<Event>
  render?: (props: { map: Map }) => ReactNode
}

const CustomOverlay: FunctionComponent<CustomOverlayProps> = props => {
  const [, setVersion] = useState(0)
  const forceUpdateRef = useRef(() => {
    return
  })
  const isUpdatingRef = useRef(false)

  const debouncedForceUpdate = useCallback(() => {
    if (!isUpdatingRef.current) {
      isUpdatingRef.current = true
      requestAnimationFrame(() => {
        setVersion(v => v + 1)
        isUpdatingRef.current = false
      })
    }
  }, [])

  useLayoutEffect(() => {
    forceUpdateRef.current = debouncedForceUpdate
  })

  const ctrl = useControl<OverlayControl>(
    () => {
      return new OverlayControl(
        () => forceUpdateRef.current(),
        props?.events ?? []
      )
    },
    { position: props?.position ?? 'top-left' }
  )

  const map = ctrl.getMap()

  const renderChildren = (): ReactNode => {
    if (!map) return null
    if (typeof props.children === 'function') {
      return props.children({ map })
    }
    if (props.children) {
      return React.Children.map(props.children, child =>
        React.isValidElement<{ map: Map }>(child)
          ? cloneElement(child, { map })
          : child
      )
    }
    if (props.render) {
      return props.render({ map })
    }
    return null
  }

  return map && createPortal(renderChildren(), ctrl.getElement())
}

export interface OverlayProps {
  position?: ControlPosition
}

export const Overlay = React.memo(CustomOverlay)
