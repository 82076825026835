import dayjs from 'dayjs'

import { PROPERTY_TYPES } from '../../common/drmTypes'
import { LvGeometryApiResponse } from '../../common/lvTypes'
import {
  monitUnitTitleName,
  RuianToMonitDruhKonstrukce,
  RuianToMonitFlagsGully,
  RuianToMonitFlagsHeating,
  RuianToMonitTypObjektu,
} from '../../common/monitTypes'
import {
  RuianAreaResponse,
  RuianBuildingResponse,
  RuianDetailResponse,
  RuianUnitResponse,
} from '../../common/ruianTypes'
import {
  areaPriceEstimateTitlePrefix,
  buildingPriceEstimateTitlePrefix,
} from './layoutConst'
import { getMonitValue } from './monitHelpers'
import { formatPolygon } from './polygonHelpers'

export const getPriceEstimateInitialValues = (
  ruianData?: RuianDetailResponse | null,
  lvGeometryData?: LvGeometryApiResponse | null
) => {
  switch (ruianData?.type) {
    case PROPERTY_TYPES.BUILDING:
      return buildingEstimateFormInitial(
        ruianData as RuianBuildingResponse,
        lvGeometryData
      )
    case PROPERTY_TYPES.AREA:
      return areaEstimateFormInitial(
        ruianData as RuianAreaResponse,
        lvGeometryData
      )
    case PROPERTY_TYPES.UNIT:
      return unitEstimateFormInitial(ruianData as RuianUnitResponse)

    default:
      return undefined
  }
}

const buildingEstimateFormInitial = (
  ruianData: RuianBuildingResponse,
  lvGeometryData?: LvGeometryApiResponse | null
) => {
  const findPolygon = lvGeometryData?.budovy?.find(
    budova => budova.id === ruianData.id
  )?.originalniHranice?.polygons?.[0]?.exterior

  const polygon = formatPolygon(findPolygon)

  return {
    datum: dayjs(),
    kod_obce: ruianData?.obec?.kod,
    typ_nemovitosti: 1,
    typ_objektu: getMonitValue(
      RuianToMonitTypObjektu,
      (ruianData?.zpusobVyuziti?.kod ||
        'default') as keyof typeof RuianToMonitTypObjektu
    ),
    druh_konstrukce: getMonitValue(
      RuianToMonitDruhKonstrukce,
      (ruianData?.druhKonstrukce?.kod ||
        'default') as keyof typeof RuianToMonitDruhKonstrukce
    ),
    lat: ruianData?.definicniBod?.lat,
    lon: ruianData?.definicniBod?.long,
    polygon: polygon,
    title: `${buildingPriceEstimateTitlePrefix}${ruianData?.nazev}`,
    nadzemni_podlazi: ruianData?.pocetNadzemnichPodlazi?.do,
    pocet_bytu: ruianData?.pocetBytu,
    object_flags_gully: getMonitValue(
      RuianToMonitFlagsGully,
      (ruianData?.odpady?.kod ||
        'default') as keyof typeof RuianToMonitFlagsGully
    ),
    object_flags_heating: getMonitValue(
      RuianToMonitFlagsHeating,
      (ruianData?.zpusobVytapeni?.kod ||
        'default') as keyof typeof RuianToMonitFlagsHeating
    ),
    ruian: ruianData,
    pl_pozemku:
      lvGeometryData?.parcely?.reduce(
        (acc, parcela) => acc + Number(parcela.vymera),
        0
      ) ?? 0,
    pl_uzitna: ruianData?.podlahovaPlocha,
  }
}

const typPozemku = (druhPozemku: number) => {
  switch (druhPozemku) {
    case 2:
      return 1
    case 5:
      return 4
    case 13:
      return 0
    default:
      return 4
  }
}

const areaEstimateFormInitial = (
  ruianData: RuianAreaResponse,
  lvGeometryData?: LvGeometryApiResponse | null
) => {
  const findPolygon = lvGeometryData?.parcely?.find(
    parcela => parcela.id === ruianData.id
  )?.originalniHranice?.polygons?.[0]?.exterior

  const polygon = formatPolygon(findPolygon)

  return {
    datum: dayjs(),
    kod_obce: ruianData.obec.kod,
    typ_nemovitosti: 0,
    typ_pozemku: typPozemku(ruianData.druhPozemku.kod),
    lat: ruianData.definicniBod.lat,
    lon: ruianData.definicniBod.long,
    polygon: polygon,
    pl_celkova: ruianData?.vymera,
    title: `${areaPriceEstimateTitlePrefix}${ruianData.nazev}`,
    ruian: ruianData,
  }
}

const getUnitNumber = (cisloJednotky: string) => {
  const slashIndex = cisloJednotky.indexOf('/')
  return slashIndex !== -1
    ? parseInt(cisloJednotky.substring(slashIndex + 1))
    : undefined
}

const unitEstimateFormInitial = (ruianData: RuianUnitResponse) => {
  const unitNumber = getUnitNumber(ruianData?.cisloJednotky || '')
  const unitTitle = `${
    ruianData.adresniMista?.[0]?.formatovanaAdresa
      ? monitUnitTitleName[
          (ruianData?.zpusobVyuziti?.kod ||
            0) as keyof typeof monitUnitTitleName
        ] +
        (unitNumber ? ' č.' + unitNumber + ' - ' : '') +
        ruianData.adresniMista[0].formatovanaAdresa
      : ruianData.nazev
  }`

  return {
    datum: dayjs(),
    kod_obce: ruianData.obec.kod,
    typ_nemovitosti: 2,
    title: `Cenový odhad ${unitTitle}`,
    druh_konstrukce: ruianData?.druhKonstrukce?.kod === 4 ? 1 : 0, // 1 = panel, 0 = ostatni
    podlazi_umisteni: (unitNumber || 0) > 3 ? 2 : 1, // 1 = přízemí, 2 = není přízemí
    lat: ruianData.definicniBod.lat,
    lon: ruianData.definicniBod.long,
    nazev: ruianData.nazev,
    zpusobVytapeni: ruianData.zpusobVytapeni?.nazev,
    druhKonstrukce: ruianData.druhKonstrukce?.nazev,
    vBudove: ruianData.vBudove,
  }
}
