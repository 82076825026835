import { Spin, Typography } from 'antd'
import React, { FunctionComponent } from 'react'

import { PageLayout } from '../PageLayout/PageLayout'

const titleStyle = { marginBottom: 0 }

export const PageSkeleton: FunctionComponent = () => {
  return (
    <PageLayout
      title={
        <Typography.Title style={titleStyle} level={3}>
          <span className="blur-sm animate-pulse">
            Načítání nadpisu stránky
          </span>
        </Typography.Title>
      }
    >
      <div className="w-full h-48 flex items-center justify-center">
        <Spin />
      </div>
    </PageLayout>
  )
}
