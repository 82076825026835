import { IcoErrorDescription } from '../../common/cuzkMonitorTypes'

export const icoErrorDescription: { [key: number]: IcoErrorDescription } = {
  1: {
    errorCodeType: 'notNumber',
    title: 'Nejedná se o číslo',
    description: 'Zadaná hodnota obsahuje znaky, které nejsou číslice.',
    message: 'Zadané IČO nebude zpracováno, neboť není validní.',
    type: 'error',
  },
  2: {
    errorCodeType: 'wrongLength',
    title: 'IČO nemá správnou délku',
    description: 'IČO musí obsahovat právě 8 číslic.',
    message: 'Zadané IČO nebude zpracováno, neboť není validní.',
    type: 'error',
  },
  3: {
    errorCodeType: 'notValid',
    title: 'IČO není validní',
    description: 'Zadané IČO nevyhovuje kontrolnímu výpočtu.',
    message: 'Zadané IČO nebude zpracováno, neboť není validní.',
    type: 'error',
  },
  5: {
    errorCodeType: 'duplicate',
    title: 'Duplicitní záznam',
    description: 'Toto IČ jste zadali vícekrát, zpracováno bude pouze jednou.',
    message: 'Zadané IČO nebude zpracováno, neboť se jedná o duplicitu.',
    type: 'error',
  },
  30: {
    errorCodeType: 'unknown',
    title: 'Neznámá chyba',
    description: 'Vyskytla se neznámá chyba při ověření platnosti IČO.',
    message: 'Zadané IČO nebude zpracováno kvůli neznámé chybě.',
    type: 'error',
  },
}
