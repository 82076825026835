import { Button, Modal } from 'antd'
import React, { FunctionComponent, useCallback, useState } from 'react'

import { AddNewLabel as EditLabel } from '../AddNewLabel/AddNewLabel'
import { LabelTableItem } from '../LabelTable/LabelTable'

export interface EditLabelButtonProps {
  refetch?: () => void
  initialLabel?: LabelTableItem
  link?: boolean
}

export const EditLabelButton: FunctionComponent<EditLabelButtonProps> =
  props => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const toggleModalVisibility = useCallback(() => {
      setIsModalVisible(prev => !prev)
    }, [])

    const handleEditLabel = useCallback(() => {
      toggleModalVisibility()
      props.refetch?.()
    }, [props, toggleModalVisibility])

    return (
      <div>
        <Button
          type={props.link ? 'link' : 'primary'}
          ghost={!props.link}
          onClick={toggleModalVisibility}
        >
          Editovat štítek
        </Button>
        <Modal
          title="Editace štítku"
          open={isModalVisible}
          onCancel={toggleModalVisibility}
          footer={false}
        >
          <EditLabel
            refetch={handleEditLabel}
            initialLabel={props.initialLabel}
          />
        </Modal>
      </div>
    )
  }
