import { Button, Divider, Form, Modal } from 'antd'
import { BaseButtonProps } from 'antd/es/button/button'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { formItemLayout, validateTrigger } from '../../utils/layoutConst'
import { postServiceLetterPath } from '../../utils/paths'
import { PostServiceLetterCreateSteps } from '../PostServiceLetterCreateSteps/PostServiceLetterCreateSteps'
import { PostServiceRecipientListSelect } from '../PostServiceRecipientListSelect/PostServiceRecipientListSelect'
import { PostServiceTemplateSelectFormFragment } from '../PostServiceTemplateSelectFormFragment/PostServiceTemplateSelectFormFragment'

export interface PostServiceLetterCreateFormValues {
  recipientListId: string
  templateId?: string
}

export interface PostServiceLetterCreateModalButtonProps {
  open?: boolean
  buttonProps?: BaseButtonProps & { title: string }
  buttonText?: string
  recipientListId?: string
  templateId?: string
}

export const PostServiceLetterCreateModalButton: FunctionComponent<PostServiceLetterCreateModalButtonProps> =
  props => {
    const [open, setOpen] = useState(props.open)

    const [percent, setPercent] = useState(0)

    const onChange = useCallback(
      (_: string, formValues: PostServiceLetterCreateFormValues) => {
        if (formValues.recipientListId && formValues.templateId) {
          setPercent(100)
        } else if (formValues.recipientListId && !formValues.templateId) {
          setPercent(77)
        } else if (formValues.recipientListId || formValues.templateId) {
          setPercent(44)
        } else {
          setPercent(0)
        }
      },
      []
    )

    const handleModal = useCallback(() => {
      setOpen(prev => !prev)
    }, [])

    const history = useHistory()
    // const { assignLabels } = useAssignLabels()

    const [form] = Form.useForm()

    const initialValues = useMemo(
      () => ({
        recipientListId: props.recipientListId,
        templateId: props.templateId,
      }),
      [props.recipientListId, props.templateId]
    )

    const footer = useMemo(
      () => (
        <div className="flex justify-end">
          <Button onClick={handleModal} className="sm:w-auto w-full">
            Zavřít
          </Button>
          <Button
            type="primary"
            onClick={form.submit}
            className="sm:w-auto w-full"
            disabled={percent < 70}
          >
            Pokračovat
          </Button>
        </div>
      ),

      [form, handleModal, percent]
    )

    const onFinish = useCallback(
      async (formValues: PostServiceLetterCreateFormValues) => {
        history.push(
          postServiceLetterPath(
            formValues.recipientListId,
            formValues.templateId
          )
        )
      },
      [history]
    )

    return (
      <>
        <Button
          type="primary"
          onClick={handleModal}
          className="sm:w-auto w-full"
          {...props.buttonProps}
        >
          {props.buttonText || 'Rozeslat poštu'}
        </Button>
        <Modal
          title={`Nová hromadná korespondence`}
          open={open}
          onCancel={handleModal}
          okText="Pokračovat"
          cancelText="Zrušit"
          footer={footer}
          width={650}
        >
          <div className="mb-8 mt-12 space-y-14">
            <Form<PostServiceLetterCreateFormValues>
              {...formItemLayout}
              form={form}
              initialValues={initialValues}
              validateTrigger={validateTrigger}
              onFinish={onFinish}
              onValuesChange={onChange}
              scrollToFirstError
            >
              <PostServiceRecipientListSelect
                initialId={props?.recipientListId}
                isFormFragment
              />

              <PostServiceTemplateSelectFormFragment
                initialId={props?.templateId}
              />

              {/*  <Form.Item name="label" label="Štítek">
                <LabelSelectInput disabled={loading} onChange={setLabelsId} />
              </Form.Item> */}
            </Form>
            <PostServiceLetterCreateSteps current={1} percent={percent} />
          </div>
          <Divider />
        </Modal>
      </>
    )
  }
