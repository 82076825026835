import React from 'react'

import { PROPERTY_TYPES } from '../../common/drmTypes'
import { HeritageMonitoringRecord } from '../../common/heritageMonitoringTypes'

const sortTooltip = (type: string) => ({
  title: (
    <div>
      <div>Řadit dle {type}</div>
      <div className="text-gray-400">
        Můžete řadit v kombinaci s dalšími počty nemovitostí.
      </div>
    </div>
  ),
})

const sortParticipantTooltip = {
  title: 'Řazení není abecední, ale dle počtu účastníků',
}

const renderCount = (count: number) =>
  count > 0 ? (
    <span className="font-semibold">{count}</span>
  ) : (
    <span className="text-gray-400">{count}</span>
  )

export const propertyCountColumn = {
  [PROPERTY_TYPES.BUILDING]: {
    title: 'BUD',
    dataIndex: 'pocetBudov',
    width: 60,
    className: 'text-center',
    render: renderCount,
    showSorterTooltip: sortTooltip('počtu budov'),
    sorter: {
      multiple: 3,
      compare: (a: HeritageMonitoringRecord, b: HeritageMonitoringRecord) =>
        b.pocetBudov - a.pocetBudov,
    },
  },

  [PROPERTY_TYPES.UNIT]: {
    title: 'JED',
    dataIndex: 'pocetJednotek',
    width: 60,
    className: 'text-center',
    render: renderCount,
    showSorterTooltip: sortTooltip('počtu jednotek'),
    sorter: {
      multiple: 2,
      compare: (a: HeritageMonitoringRecord, b: HeritageMonitoringRecord) =>
        b.pocetJednotek - a.pocetJednotek,
    },
  },
  [PROPERTY_TYPES.AREA]: {
    title: 'PAR',
    dataIndex: 'pocetParcel',
    width: 60,
    className: 'text-center',
    render: renderCount,
    showSorterTooltip: sortTooltip('počtu parcel'),
    sorter: {
      multiple: 1,
      compare: (a: HeritageMonitoringRecord, b: HeritageMonitoringRecord) =>
        b.pocetParcel - a.pocetParcel,
    },
  },
}

const renderParticipants = (
  ucastnici: { jmeno: string; typ: string }[],
  targetType: string
) => (
  <>
    {ucastnici
      .filter(u => u.typ === targetType)
      .map((ucastnik, index) => (
        <div key={index}>{ucastnik.jmeno}</div>
      ))}
  </>
)

const countParticipants = (
  ucastnici: { jmeno: string; typ: string }[],
  targetType: string
) => ucastnici.filter(u => u.typ === targetType).length

const sortParticipantsByCount = (
  a: HeritageMonitoringRecord,
  b: HeritageMonitoringRecord,
  targetType: string
) => {
  const aCount = countParticipants(a.ucastnici, targetType)
  const bCount = countParticipants(b.ucastnici, targetType)
  return bCount - aCount
}

export const participantColumns = {
  prevodce: {
    title: 'Převodce',
    dataIndex: 'ucastnici',
    key: 'prevodce',
    render: (ucastnici: { jmeno: string; typ: string }[]) =>
      renderParticipants(ucastnici, 'Převodce'),
    showSorterTooltip: sortParticipantTooltip,
    sorter: (a: HeritageMonitoringRecord, b: HeritageMonitoringRecord) =>
      sortParticipantsByCount(a, b, 'Převodce'),
  },
  nabyvatel: {
    title: 'Nabyvatel',
    dataIndex: 'ucastnici',
    key: 'nabyvatel',
    render: (ucastnici: { jmeno: string; typ: string }[]) =>
      renderParticipants(ucastnici, 'Nabyvatel'),
    showSorterTooltip: sortParticipantTooltip,
    sorter: (a: HeritageMonitoringRecord, b: HeritageMonitoringRecord) =>
      sortParticipantsByCount(a, b, 'Nabyvatel'),
  },
  ostatniTyp: {
    title: 'Typ',
    dataIndex: 'ucastnici',
    key: 'ucastniciOstatniTyp',
    className: '!text-right text-gray-400',
    render: (ucastnici: { jmeno: string; typ: string }[]) =>
      ucastnici
        .filter(u => u.typ !== 'Převodce' && u.typ !== 'Nabyvatel')
        .map((ucastnik, index) => (
          <div className="truncate" key={index}>
            {ucastnik.typ}
          </div>
        )),
    sorter: (a: HeritageMonitoringRecord, b: HeritageMonitoringRecord) => {
      const aTypes = a.ucastnici
        .filter(u => u.typ !== 'Převodce' && u.typ !== 'Nabyvatel')
        .map(u => u.typ)
        .join(',')
      const bTypes = b.ucastnici
        .filter(u => u.typ !== 'Převodce' && u.typ !== 'Nabyvatel')
        .map(u => u.typ)
        .join(',')
      return aTypes.localeCompare(bTypes)
    },
  },
  ostatniJmeno: {
    title: 'Jméno/název',
    dataIndex: 'ucastnici',
    key: 'ucastniciOstatni',
    className: 'w-fit',
    render: (ucastnici: { jmeno: string; typ: string }[]) =>
      ucastnici
        .filter(u => u.typ !== 'Převodce' && u.typ !== 'Nabyvatel')
        .map((ucastnik, index) => (
          <div
            className="truncate max-w-[200px]"
            title={ucastnik.jmeno}
            key={index}
          >
            {ucastnik.jmeno}
          </div>
        )),
    showSorterTooltip: sortParticipantTooltip,
    sorter: (a: HeritageMonitoringRecord, b: HeritageMonitoringRecord) => {
      const aCount = a.ucastnici.filter(
        u => u.typ !== 'Převodce' && u.typ !== 'Nabyvatel'
      ).length
      const bCount = b.ucastnici.filter(
        u => u.typ !== 'Převodce' && u.typ !== 'Nabyvatel'
      ).length
      return bCount - aCount
    },
  },
}
