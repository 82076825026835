import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { compareDesc } from 'date-fns'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { BASE_URL } from '../../../../ini.json'
import { useAppSelector } from '../../../redux/hooks'
import { Min } from '../../components/DateFormat/DateFormat'
import { stringSorter } from '../../utils/generalTableHelpers'
import { statisticPagination } from '../../utils/layoutConst'
import { StatisticCompany } from '../../utils/statisticHelpers'

interface MarkeplaceStatisticsItem {
  accountId: string
  accountUserId: string
  contact: {
    name: string
    accountId: string
  }
  createdAt: string
  date: string
  title: string
  sellerId: string
}

export interface MarketplaceStatisticsTableProps {
  companies?: StatisticCompany
}

export const MarketplaceStatisticsTable: FunctionComponent<MarketplaceStatisticsTableProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)
    const [loading, setLoading] = useState<boolean | undefined>()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [contactData, setContactData] = useState<any>()

    const fetchData = useCallback(async () => {
      setLoading(true)

      fetch(`${BASE_URL}/api/marketplace/admin/statistics/${token}`)
        .then(response => response.json())
        .then(data => {
          setContactData(data)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
          setContactData([])
        })
    }, [token])

    useEffect(() => {
      if (contactData !== undefined) return
      fetchData()
    }, [contactData, fetchData])

    const columns: ColumnsType<MarkeplaceStatisticsItem> = useMemo(
      () => [
        {
          title: '#',
          key: 'index',
          className: 'w-8',
          render: (_, __, index) => index + 1,
        },
        {
          title: 'id',
          dataIndex: 'id',
          ellipsis: { showTitle: true },
          className: 'w-14 cursor-help',
        },
        {
          title: 'Datum nákupu',
          dataIndex: 'date',
          className: 'w-40',
          defaultSortOrder: 'ascend',
          sorter: (a, b) =>
            a.createdAt && b.createdAt
              ? compareDesc(new Date(a.createdAt), new Date(b.createdAt))
              : 0,
          render: (_, record) => <Min>{record.createdAt}</Min>,
        },
        {
          title: 'Kupující',
          dataIndex: 'accountId',
          ellipsis: { showTitle: true },
          sorter: stringSorter('accountId'),
          showSorterTooltip: { title: 'Řadí podle ID, ne názvu' },
          render: id => (
            <a
              className="!text-primary"
              href={`https://data.regesta.cz/PortalAdmin/DetailFirmy/${id}`}
              target="_blank"
              rel="noreferrer"
            >
              {props.companies?.[id] || id}
            </a>
          ),
        },
        {
          title: 'ID uživ. kupujícího',
          dataIndex: 'accountUserId',
          ellipsis: { showTitle: true },
          className: 'w-36',
        },
        {
          title: 'Kontakt. os.',
          dataIndex: 'contact',
          render: (_, record) => record.contact.name,
        },
        {
          title: 'Název nabídky',
          dataIndex: 'title',
        },
        {
          title: 'Typ',
          dataIndex: 'offerType',
        },
        {
          title: 'Prodejce',
          dataIndex: 'sellerId',
          ellipsis: { showTitle: true },
          className: 'w-36',
          sorter: stringSorter('sellerId'),
          showSorterTooltip: { title: 'Řadí podle ID, ne názvu' },
          render: (_, record) => (
            <a
              className="!text-primary"
              href={`https://data.regesta.cz/PortalAdmin/DetailFirmy/${record.contact.accountId}`}
              target="_blank"
              rel="noreferrer"
            >
              {props.companies?.[record.contact.accountId] ||
                record.contact.accountId}
            </a>
          ),
        },
      ],
      [props.companies]
    )

    return (
      <Table
        pagination={statisticPagination}
        dataSource={contactData}
        columns={columns}
        loading={loading}
        rowKey="id"
        size="small"
      />
    )
  }
