import React, { FunctionComponent, useMemo } from 'react'

import { RcTableItemConcept } from '../RcInput/RcInput'

interface CeeMonitorAddSubjectModalTitleProps {
  dataSource: RcTableItemConcept[]
  forCheckCount: number
}

const defaultTitle = <div>Ověření subjektů v CEE</div>

export const CeeMonitorAddSubjectModalTitle: FunctionComponent<CeeMonitorAddSubjectModalTitleProps> =
  ({ dataSource, forCheckCount }) => {
    const title = useMemo(() => {
      if (forCheckCount !== 1) return defaultTitle

      const subject = dataSource.find(
        item =>
          item.validatedCode === 30 ||
          !item.validatedCode ||
          item.validatedCode >= 10
      )

      if (!subject) return defaultTitle

      const subjectInfo =
        subject.description ||
        (subject.firstname && `${subject.firstname} ${subject.lastname}`) ||
        (subject.rc && `RČ: ${subject.rc}`) ||
        (subject.ico && `IČO: ${subject.ico}`) ||
        'Neznámý subjekt'

      return (
        <div className="flex space-x-2">
          {defaultTitle}
          <div className="text-gray-400 !text-base font-normal">
            {subjectInfo}
          </div>
        </div>
      )
    }, [dataSource, forCheckCount])

    return title
  }
