import { createSlice } from '@reduxjs/toolkit'

import { DrmSubjectsResponse } from '../common/drmSubjectsTypes'
import handleAsyncActions, {
  AsyncState,
  initialFetchState,
  PropertyDataTypes,
} from './fetchAction'

export const drmSubjectsSlice = createSlice({
  name: PropertyDataTypes.DRM_SUBJECTS,
  initialState: initialFetchState as AsyncState<DrmSubjectsResponse>,
  reducers: {},
  extraReducers: builder => {
    handleAsyncActions<DrmSubjectsResponse>(
      builder,
      PropertyDataTypes.DRM_SUBJECTS
    )
  },
})

export default drmSubjectsSlice.reducer
