import { message, Skeleton } from 'antd'
import { Maybe } from 'graphql/jsutils/Maybe'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'

import { AddressApiResponse } from '../../../common/addressTypes'
import { useAppSelector } from '../../../redux/hooks'
import { Identifier, RegisterGroupMap } from '../../utils/dynamicSearchTooltip'
import { requestAddressPath } from '../../utils/paths'
import { useRcNumberData } from '../../utils/useRcNumberData'
import { AddressTooltip } from '../AddressTooltip/AddressTooltip'
import { CeeMonitorTableIdentificationItem as Item } from '../CeeMonitorTableIdentificationItem/CeeMonitorTableIdentificationItem'
import {
  CUZK_PERSON_TYPES,
  CuzkEpoModalFormValues,
} from '../CuzkEpoModalButton/CuzkEpoModalButton'
import { Sec } from '../DateFormat/DateFormat'
import { DynamicSearchTooltip } from '../DynamicSearchTooltip/DynamicSearchTooltip'

export interface CuzkEpoIdentificationPanelProps {
  subjectData?: {
    createdAt: Date
    nazev: string
    pocetLv?: Maybe<number>
    rcIco: string
    adresnimistokod?: Maybe<string>
    typ: CuzkEpoModalFormValues['type'] | string
  } | null
  loading?: boolean
}

export const CuzkEpoIdentificationPanel: FunctionComponent<CuzkEpoIdentificationPanelProps> =
  props => {
    const person = props.subjectData

    const token = useAppSelector(state => state.myProfile.token)
    const { birthDate } = useRcNumberData(person?.rcIco)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [ruianData, setRuianData] = useState<any>(0)
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(
            requestAddressPath() +
              token +
              '/' +
              props.subjectData?.adresnimistokod
          )
          const data: AddressApiResponse = await response?.json()

          setRuianData({
            ...data,
            lat: data.definicniBod.lat,
            lon: data.definicniBod.long,
            address: data.formatovanaAdresa,
            street: data.ulice,
            city: data.obec,
            townHall: data.radnice,
            id: data.budovaId,
          })
        } catch (error) {
          message.error('Nepodařilo se načíst detaily adresy.')
        }
      }
      if (ruianData === 0 && props.subjectData?.adresnimistokod) fetchData()
    }, [props.subjectData?.adresnimistokod, ruianData, token])

    const OFO = person?.typ === CUZK_PERSON_TYPES.OFO
    const OPO = person?.typ === CUZK_PERSON_TYPES.OPO
    const skeleton = useMemo(
      () => (
        <Skeleton
          active
          title={false}
          paragraph={{
            rows: 1,
            className: 'flex justify-center mt-2',
          }}
          loading={!!props.loading}
        />
      ),
      [props.loading]
    )

    const OFOContent = useMemo(() => {
      return (
        OFO && (
          <>
            <Item title="Dat. narození">
              {skeleton}
              {birthDate}
            </Item>
            <Item title="Rodné číslo">
              {skeleton}
              <DynamicSearchTooltip
                identifier={{
                  [Identifier.RC]: person.rcIco,
                  [Identifier.DESCRIPTION]: person.nazev,
                }}
                identifierType={Identifier.RC}
                excludedRegisters={[
                  ...RegisterGroupMap.EPO,
                  ...RegisterGroupMap.ČÚZK,
                ]}
              >
                {person.rcIco}
              </DynamicSearchTooltip>
            </Item>
          </>
        )
      )
    }, [OFO, person, skeleton, birthDate])

    const OPOContent = useMemo(() => {
      return (
        OPO && (
          <Item title="IČO">
            {skeleton}
            <DynamicSearchTooltip
              identifier={{
                [Identifier.ICO]: person.rcIco,
                [Identifier.DESCRIPTION]: person.nazev,
              }}
              identifierType={Identifier.ICO}
              excludedRegisters={[
                ...RegisterGroupMap.EPO,
                ...RegisterGroupMap.ČÚZK,
              ]}
            >
              {person.rcIco}
            </DynamicSearchTooltip>
          </Item>
        )
      )
    }, [OPO, person, skeleton])

    return (
      <div className="p-4 mb-4 mt-1 text-center rounded border border-gray-300 flex bg-gray-100 divide-x divide-gray-300">
        <Item title={OFO ? 'Jméno' : 'Název'}>
          {skeleton}
          {person?.nazev}
        </Item>

        <Item title="Adresa">
          {skeleton}
          {ruianData?.address ? (
            <AddressTooltip
              addressData={ruianData}
              gpsData={{
                latitude: ruianData?.lat,
                longitude: ruianData?.lon,
              }}
            />
          ) : (
            '---'
          )}
        </Item>
        {OFOContent}
        {OPOContent}

        <Item title="Dotčených LV">
          {skeleton}
          {person?.pocetLv}
        </Item>

        <Item title="Údaje k datu">
          {skeleton}
          <Sec noData>{person?.createdAt}</Sec>
        </Item>
      </div>
    )
  }
