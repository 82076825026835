import { useCallback, useState } from 'react'

/**
 * Custom hook for managing boolean toggle states.
 * Provides methods to toggle, set true, set false, and set to any boolean value.
 *
 * @param {boolean} [initialValue=false] - Initial value of the toggle state.
 *
 * @returns {readonly [boolean, () => void, () => void, () => void, (value: boolean) => void]}
 * - An array containing:
 *   - value: The current boolean state.
 *   - toggleValue: A function to toggle the state.
 *   - setTrue: A function to set the state to true.
 *   - setFalse: A function to set the state to false.
 *   - setValue: A function to set the state to a specific boolean value.
 */
export function useToggleState(
  initialValue = false
): readonly [
  boolean,
  () => void,
  () => void,
  () => void,
  (value: boolean) => void
] {
  const [value, setValue] = useState(initialValue)

  const toggleValue = useCallback(() => setValue(prev => !prev), [])
  const setTrue = useCallback(() => setValue(true), [])
  const setFalse = useCallback(() => setValue(false), [])

  return [value, toggleValue, setTrue, setFalse, setValue] as const
}
