import { Text, View } from '@react-pdf/renderer'
import React, { FunctionComponent, useMemo } from 'react'

import { monitPdfCss } from '../../utils/monitPdfCss'
import { formatCurrencyNumber } from '../Money/Money'
import { PriceEstimatePdfPropertyRow as Row } from '../PriceEstimatePdfPropertyRow/PriceEstimatePdfPropertyRow'

export interface PriceEstimatePdfLvFragmentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lvData?: any
}

const countStyle = { fontWeight: 'normal' as const }
export const PriceEstimatePdfLvFragment: FunctionComponent<PriceEstimatePdfLvFragmentProps> =
  props => {
    /* 
    const omezeni = useMemo(() => {
      if (props.lvData?.omezeniVlastnickehoPrava?.length)
        return props.lvData.omezeniVlastnickehoPrava.map(
          (item: any, index: number) => <Item key={index} data={item.omezeni} />
        )
    }, [props.lvData.omezeniVlastnickehoPrava]) */

    const lv = useMemo(
      () =>
        (props.lvData?.lv || '') +
        ' - ' +
        (props.lvData?.katastralniUzemi?.nazev || ''),
      [props.lvData?.lv, props.lvData?.katastralniUzemi?.nazev]
    )

    const cadastre = useMemo(
      () =>
        props.lvData?.katastralniPracoviste?.nazev?.replace(
          /Katastrální/g,
          'Kat.'
        ),
      [props.lvData?.katastralniPracoviste?.nazev]
    )

    const ownership = useMemo(() => {
      const parts: string[] = []

      props.lvData?.pravniVztahy?.forEach(
        (pravniVztah: {
          vlastnickaPrava: { podilCitatel: number; podilJmenovatel: number }[]
        }) => {
          pravniVztah.vlastnickaPrava.forEach(vlastnickePravo => {
            const { podilCitatel, podilJmenovatel } = vlastnickePravo
            const podil = (podilCitatel / (podilJmenovatel || 1)) * 100
            parts.push(
              `${podil < 100 ? formatCurrencyNumber(podil, 2) : podil}%`
            )
          })
        }
      )

      return { count: parts.length, parts: ` (${parts.join(' + ')})` }
    }, [props.lvData?.pravniVztahy])

    if (!props.lvData) return <View></View>

    return (
      <View wrap={false} style={monitPdfCss.mb5}>
        <Text style={monitPdfCss.tableHeader}>
          Informace o listu vlastnictví
        </Text>
        <Row data={lv}>List vlastnictví:</Row>
        <Row data={cadastre}>Kat. pracoviště:</Row>

        {!!ownership.count && (
          <View style={monitPdfCss?.tableRow}>
            <Text style={monitPdfCss?.propertyCellHeader}>
              Vlastnické podíly:
            </Text>
            <Text style={monitPdfCss?.propertyCellValue}>
              <Text>{ownership.count}</Text>
              {ownership.count < 6 && (
                <Text style={countStyle}>{ownership.parts}</Text>
              )}
            </Text>
          </View>
        )}

        <Row data={props.lvData?.zpusobOchrany}>Způsob ochrany:</Row>
        <Row data={props.lvData?.jineZapisy}>Jiné zápisy:</Row>

        {/*      
        <View style={styles.twoColumns}>
          <View style={styles.w25}>
            <Item isLabel data={props.lvData?.omezeniVlastnickehoPrava}>
              Omezení vlastnických práv:
            </Item>
          </View>
          <View style={styles.w75}>{omezeni}</View>
        </View>
         */}
      </View>
    )
  }
