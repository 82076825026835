import { PROPERTY_TYPES } from '../../common/drmTypes'
import { UserActivityEntityType } from '../../graphql/generated'

export const seenRowClassName = (
  editedAt: string | Date | undefined,
  alertDateTo: string | null | Date
) =>
  !!editedAt && alertDateTo && new Date(alertDateTo) < new Date(editedAt)
    ? ''
    : 'bg-[#e1f0ee]/50'

export const activityEntityType = {
  [PROPERTY_TYPES.AREA]: UserActivityEntityType.Area,
  [PROPERTY_TYPES.BUILDING]: UserActivityEntityType.Building,
  [PROPERTY_TYPES.UNIT]: UserActivityEntityType.Unit,
  proceeding: UserActivityEntityType.Proceeding,
}
