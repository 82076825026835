import React, { FunctionComponent, memo, useMemo } from 'react'

import { Subjekt } from '../../../common/lvTypes'
import { AddressLink } from '../AddressLink/AddressLink'

export interface PropertyTimelineOwnershipTitleAddressProps {
  subjekt: Subjekt
  sjmPartner?: Subjekt | null
  addressIds: number[]
}

export const PropertyTimelineOwnershipTitleAddress: FunctionComponent<PropertyTimelineOwnershipTitleAddressProps> =
  memo(({ subjekt, sjmPartner, addressIds }) => {
    const subjektOnAddress = useMemo(
      () => addressIds.includes(subjekt.adresniMistoKod),
      [addressIds, subjekt.adresniMistoKod]
    )

    const sjmPartnerOnAddress = useMemo(
      () => sjmPartner && addressIds.includes(sjmPartner.adresniMistoKod),
      [addressIds, sjmPartner]
    )

    return (
      <div className="flex flex-col justify-center">
        <span className="flex space-x-2">
          <span>-</span>
          {subjektOnAddress ? (
            <span
              title="Detail budovy si právě prohlížíte"
              className="text-gray-400"
            >
              {subjekt.adresa}
            </span>
          ) : (
            <AddressLink
              className="opacity-60"
              addressId={subjekt.adresniMistoKod}
            >
              {subjekt.adresa}
            </AddressLink>
          )}
        </span>
        {sjmPartner && sjmPartner.adresniMistoKod !== subjekt.adresniMistoKod && (
          <span className="hidden group-hover:flex space-x-2">
            <span>-</span>
            {sjmPartnerOnAddress ? (
              <span title="Detail budovy si právě prohlížíte">
                {sjmPartner.adresa}
              </span>
            ) : (
              <AddressLink
                className="opacity-60"
                addressId={sjmPartner.adresniMistoKod}
              >
                {sjmPartner.adresa}
              </AddressLink>
            )}
          </span>
        )}
      </div>
    )
  })
