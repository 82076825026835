import { Maybe } from 'graphql/jsutils/Maybe'
import React, { FunctionComponent, memo } from 'react'
import { Link } from 'react-router-dom'

import { Label as LabelType } from '../../../graphql/generated'
import { ColorPalleteKey } from '../../utils/collorPallete'
import { IconPalleteKey } from '../../utils/iconPallete'
import { labelDetailPath } from '../../utils/paths'
import { Label } from '../Label/Label'

interface InlineLabelsGroupProps {
  labels: Maybe<Partial<LabelType>>[] | null | undefined
}

export const InlineLabelsGroup: FunctionComponent<InlineLabelsGroupProps> =
  memo(props => {
    if (!props.labels || props.labels.length === 0) return null

    return (
      <div className="flex flex-wrap content-center flex-1 gap-2">
        {props.labels.map(label => (
          <Link to={labelDetailPath(label?.id)} key={label?.id}>
            <Label
              colorKey={label?.colorKey as ColorPalleteKey}
              iconKey={label?.iconKey as IconPalleteKey}
              name={label?.name || ''}
              description={label?.description || ''}
            />
          </Link>
        ))}
      </div>
    )
  })
