import { Button, message, Popconfirm, Segmented } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { BASE_URL } from '../../../../ini.json'
import { useAppSelector } from '../../../redux/hooks'
import { handleResponseStatus } from '../../utils/handleResponseStatus'
import { pricelist } from '../../utils/pricelist'
import { PricePopconfirm } from '../PricePopconfirm/PricePopconfirm'

export interface MarketplaceItemFooterProps {
  setContent: (value: string | number) => void
  refetch: () => void
  id: string
  bought: boolean
  owner: boolean
  state: string
}

const segmentedOptions = [
  {
    value: 'info',
    label: 'Inzerát',
  },
  {
    value: 'contact',
    label: 'Kontakty',
  },
]

export const MarketplaceItemFooter: FunctionComponent<MarketplaceItemFooterProps> =
  props => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const userId = useAppSelector(state => state.myProfile.userId)
    const token = useAppSelector(state => state.myProfile.token)

    const [loading, setLoading] = useState<boolean | undefined>()

    const buyContact = useCallback(async () => {
      setLoading(true)

      try {
        const response = await fetch(
          `${BASE_URL}/api/marketplace/buyItem/${token}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id: props.id,
              tenantId,
              userId,
            }),
          }
        )
        if (response.status === 200) {
          props.refetch()
          props.setContent('contact')
        }
        handleResponseStatus(
          response.status,
          'Kontaktní informace zakoupeny.',
          'Nepodařilo se zakoupit kontaktní informace.'
        )
      } catch (error) {
        message.error(
          'Požadavek se nepodařilo provést, zkuste to prosím později.'
        )
      }
      return setLoading(false)
    }, [props, tenantId, token, userId])

    const cancelItem = useCallback(async () => {
      setLoading(true)

      try {
        const response = await fetch(
          `${BASE_URL}/api/marketplace/cancelItem/${token}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id: props.id,
              tenantId,
            }),
          }
        )
        if (response.status === 200) {
          props.refetch()
        }
        handleResponseStatus(
          response.status,
          'Nabídka byla stažena z Marketplace.',
          'Nepodařilo se zrušit nabídku.'
        )
      } catch (error) {
        message.error(
          'Požadavek se nepodařilo provést, zkuste to prosím později.'
        )
      }
      return setLoading(false)
    }, [props, tenantId, token])

    const popConfirmProps = useMemo(
      () => ({
        onConfirm: cancelItem,
        title: 'Opravdu chcete zrušit vaši nabídku?',
        okButtonProps: { danger: true },
        okText: 'Zrušit nabídku',
        description: (
          <div className="max-w-sm">
            Uživatelé nadále neuvidí vaši nabídku, avšak všichni, kteří si ji
            stihli zakoupit, k ní budou mít stále přístup.
          </div>
        ),
      }),
      [cancelItem]
    )

    if (props.owner && props.state !== 'ACTIVE') return null

    if (props.owner)
      return (
        <Popconfirm {...popConfirmProps}>
          <Button
            danger
            type="primary"
            size="large"
            className="!w-full"
            loading={loading}
          >
            Zrušit nabídku
          </Button>
        </Popconfirm>
      )

    return (
      <>
        {props.bought ? (
          <Segmented
            block
            id="marketplaceSegmented"
            size="large"
            defaultValue="contact"
            onChange={props.setContent}
            options={segmentedOptions}
          />
        ) : (
          <PricePopconfirm
            itemPrice={pricelist.marketplaceContact}
            onConfirm={buyContact}
            message={`Zakoupení kontaktních informací je zpoplatněno ${pricelist.marketplaceContact} Kr.`}
          >
            <Button
              disabled={!!props.bought}
              type="primary"
              size="large"
              className="!w-full"
              loading={loading}
            >
              Koupit kontakt - {pricelist.marketplaceContact} Kr.
            </Button>
          </PricePopconfirm>
        )}
      </>
    )
  }
