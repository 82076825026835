import React, { FunctionComponent } from 'react'

import { AuctionMonitoringRecord } from '../../../common/auctionMonitoringTypes'
import { AuctionMonitoringTilesItem } from '../AuctionMonitoringTilesItem/AuctionMonitoringTilesItem'

export interface AuctionMonitoringTilesProps {
  data?: AuctionMonitoringRecord[] | null
}

export const AuctionMonitoringTiles: FunctionComponent<AuctionMonitoringTilesProps> =
  ({ data }) => {
    return (
      <div className="mt-2 grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 gap-y-8 gap-x-6">
        {data?.map(item => (
          <AuctionMonitoringTilesItem key={item.id} {...item} />
        ))}
      </div>
    )
  }
