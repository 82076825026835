import { Spin } from 'antd'
import React, { FunctionComponent } from 'react'

export const CadastreMapSpin: FunctionComponent = () => {
  return (
    <div className="absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center">
      <Spin size="large" tip="Načítání mapy..." className="z-10" />
      <div className="absolute top-0 right-0 left-0 bottom-0 bg-white/75"></div>
    </div>
  )
}
