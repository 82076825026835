import { useCallback, useMemo, useState } from 'react'

import { PROPERTY_TYPES } from '../../common/drmTypes'
import {
  InsolvencyMonitoringFilterOnSubmit,
  InsolvencyMonitoringFilterState,
} from '../../common/insolvencyMonitoringTypes'

export const useInsolvencyMonitoringFilter = (propertyType: PROPERTY_TYPES) => {
  const [filter, setFilter] = useState<InsolvencyMonitoringFilterState>({
    zpusobyVyuzitiBudov: null,
    zpusobyVyuzitiJednotek: null,
    zpusobyVyuzitiPozemku: null,
    druhyPozemku: null,
    zastavySmluvni: null,
    zastavyNedobrovolne: null,
    podilOd: null,
    pracoviste: null,
    stavyRizeni: [7, 11, 12],
    stranka: 1,
  })

  const filterQuery = useMemo(() => {
    const stranka = `&stranka=${filter.stranka}`
    const druhyPozemku = filter.druhyPozemku
      ? `&druhyPozemku=${filter.druhyPozemku}`
      : ''
    const zpusobyVyuzitiBudov = filter.zpusobyVyuzitiBudov
      ? `&zpusobyVyuzitiBudov=${filter.zpusobyVyuzitiBudov}`
      : ''
    const zpusobyVyuzitiJednotek = filter.zpusobyVyuzitiJednotek
      ? `&zpusobyVyuzitiJednotek=${filter.zpusobyVyuzitiJednotek}`
      : ''
    const zpusobyVyuzitiPozemku = filter.zpusobyVyuzitiPozemku
      ? `&zpusobyVyuzitiPozemku=${filter.zpusobyVyuzitiPozemku}`
      : ''
    const zastavySmluvni = filter.zastavySmluvni
      ? `&zastavySmluvni=${filter.zastavySmluvni}`
      : ''
    const zastavyNedobrovolne = filter.zastavyNedobrovolne
      ? `&zastavyNedobrovolne=${filter.zastavyNedobrovolne}`
      : ''
    const stavyRizeni = filter.stavyRizeni
      ? `&stavyRizeni=${filter.stavyRizeni}`
      : ''
    const podilOd = filter.podilOd ? `&podilOd=${filter.podilOd / 100}` : ''
    const pracoviste = filter.pracoviste
      ? `&katastralniPracoviste=${filter.pracoviste}`
      : ''
    const typNemovitosti =
      propertyType === PROPERTY_TYPES.BUILDING
        ? 'Budovy?'
        : propertyType === PROPERTY_TYPES.UNIT
        ? 'Jednotky?'
        : 'Parcely?'

    const filterQuery = `${typNemovitosti}${zastavySmluvni}${zastavyNedobrovolne}${podilOd}${pracoviste}${zpusobyVyuzitiBudov}${zpusobyVyuzitiJednotek}${zpusobyVyuzitiPozemku}${druhyPozemku}${stavyRizeni}${stranka}`

    return filterQuery
  }, [
    filter.stranka,
    filter.druhyPozemku,
    filter.zpusobyVyuzitiBudov,
    filter.zpusobyVyuzitiJednotek,
    filter.zpusobyVyuzitiPozemku,
    filter.zastavySmluvni,
    filter.zastavyNedobrovolne,
    filter.stavyRizeni,
    filter.podilOd,
    filter.pracoviste,
    propertyType,
  ])

  const handleChange = useCallback<InsolvencyMonitoringFilterOnSubmit>(
    updates => {
      setFilter(prevFilter => ({ ...prevFilter, stranka: 1, ...updates }))
    },
    []
  )

  return {
    filter,
    filterQuery,
    handleChange,
  }
}
