import { Text, View } from '@react-pdf/renderer'
import React, { FunctionComponent } from 'react'

import { monitPdfCss } from '../../utils/monitPdfCss'
import { PdfViewerFormItem as Item } from '../PdfViewerFormItem/PdfViewerFormItem'

export interface PriceEstimatePdfDecompFragmentProps {
  decompData?: {
    decompReal: Record<string, string> | null | undefined
    decompNab: Record<string, string> | null | undefined
    decompRealTotal: number | undefined
    decompNabTotal: number | undefined
  }
  priceData: {
    cenaM2Real?: string
    cenaM2Nab?: string
  }
}

export const PriceEstimatePdfDecompFragment: FunctionComponent<PriceEstimatePdfDecompFragmentProps> =
  props => {
    if (!props.decompData?.decompNab) return null
    return (
      <View>
        <Text style={monitPdfCss.tableHeader}>
          Korekční faktory cenového odhadu
        </Text>

        <Text style={monitPdfCss.decompInfo}>
          Parametry nemovitosti a jejich cenový koeficient zpřesňující výslednou
          částku cenového odhadu pro konkrétní nemovitost oproti referenční
          hodnotě.
        </Text>
        <View style={monitPdfCss?.tableRowTitle}>
          <View style={monitPdfCss.w33}>
            <Text>Cenové faktory</Text>
          </View>
          <View style={monitPdfCss.decompPriceValue}>
            <Item isLabel data={props?.decompData?.decompRealTotal}>
              korekce prodejní ceny
            </Item>
          </View>
          <View style={monitPdfCss.decompPriceValue}>
            <Text>korekce nabídkové ceny</Text>
          </View>
        </View>
        {Object.entries(props.decompData?.decompNab).map(([key, value]) => {
          if (key === 'základní cena') return null
          return (
            <View key={key} style={monitPdfCss?.tableRow}>
              <View style={monitPdfCss.w33}>
                <Item isLabel data={value}>
                  {key}:
                </Item>
              </View>
              <View style={monitPdfCss.decompPriceValue}>
                <Item prefix="× " data={props?.decompData?.decompReal?.[key]} />
              </View>
              <View style={monitPdfCss.decompPriceValue}>
                <Item prefix="× " data={value} />
              </View>
            </View>
          )
        })}
        <View style={monitPdfCss.mt10}>
          <View style={monitPdfCss?.tableRowTitle}>
            <View style={monitPdfCss.w33}>
              <Text>Výpočet ceny</Text>
            </View>
            <View style={monitPdfCss.decompPriceValue}>
              <Item isLabel data={props?.decompData?.decompRealTotal}>
                prodejní cena
              </Item>
            </View>
            <View style={monitPdfCss.decompPriceValue}>
              <Text>nabídková cena</Text>
            </View>
          </View>

          <View style={monitPdfCss?.tableRow}>
            <View style={monitPdfCss.w33}>
              <Item data="Výchozí referenční cena (m²)" />
            </View>
            <View style={monitPdfCss.decompPriceValue}>
              <Item
                data={props?.decompData.decompReal?.['základní cena']}
                suffix=" Kč"
              />
            </View>
            <View style={monitPdfCss.decompPriceValue}>
              <Item
                data={props?.decompData.decompNab?.['základní cena']}
                suffix=" Kč"
              />
            </View>
          </View>

          <View style={monitPdfCss?.tableRow}>
            <View style={monitPdfCss.w33}>
              <Item data="Výsledný cenový koeficient" />
            </View>
            <View style={monitPdfCss.decompPriceValue}>
              <Item
                prefix="× "
                data={props?.decompData.decompRealTotal?.toFixed(4)}
              />
            </View>
            <View style={monitPdfCss.decompPriceValue}>
              <Item
                prefix="× "
                data={props?.decompData.decompNabTotal?.toFixed(4)}
              />
            </View>
          </View>

          <View style={monitPdfCss?.tableRow}>
            <View style={monitPdfCss.w33}>
              <Item data="Finální cenový odhad (m²)" />
            </View>
            <View style={monitPdfCss.decompPriceValue}>
              <Item suffix=" Kč" data={props.priceData?.cenaM2Real} />
            </View>
            <View style={monitPdfCss.decompPriceValue}>
              <Item suffix=" Kč" data={props.priceData?.cenaM2Nab} />
            </View>
          </View>
        </View>
      </View>
    )
  }
