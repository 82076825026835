import React from 'react'

export const typeInfo = {
  exe: {
    id: 1,
    span: (
      <span title="Exekuce" className="text-red-500">
        EXE
      </span>
    ),
    bg: '!bg-red-50',
  },
  ins: {
    id: 2,
    span: (
      <span title="Insolvence" className="text-red-500">
        INS
      </span>
    ),
    bg: '!bg-red-50',
  },
  draž: {
    id: 3,
    span: (
      <span title="Dražba" className="text-red-500">
        DRŽ
      </span>
    ),
    bg: '!bg-red-50',
  },
  zást: {
    id: 4,
    span: (
      <span title="Zástavní právo" className="text-orange-400">
        ZÁS
      </span>
    ),
    bg: '!bg-orange-50',
  },
  zákaz: {
    id: 4,
    span: (
      <span title="Zástavní právo" className="text-orange-400">
        ZÁS
      </span>
    ),
    bg: '!bg-orange-50',
  },
  břemen: {
    id: 5,
    span: <span title="Věcné břemeno">VBŘ</span>,
    bg: '!bg-gray-50',
  },
  společenství: {
    id: 6,
    span: <span title="Společenství vlastníků jednotek">SVJ</span>,
    bg: '!bg-gray-50',
  },
  plomba: {
    id: 7,
    span: (
      <span title="Probíhající řízení" className="text-red-500">
        ŘÍZ
      </span>
    ),
    bg: '!bg-red-50',
  },
  předkupn: {
    id: 8,
    span: (
      <span title="Předkupní právo" className="text-orange-400">
        PŘP
      </span>
    ),
    bg: '!bg-orange-50',
  },
  default: { id: 0, span: <span></span>, bg: '!bg-gray-50' },
}

export const getTypeInfo = (nazev: string) => {
  const key = Object.keys(typeInfo).find(k => nazev?.toLowerCase().includes(k))
  return typeInfo[key as keyof typeof typeInfo] || typeInfo['default']
}

export const spanMap = Object.values(typeInfo).reduce<{
  [key: number]: JSX.Element
}>((acc, { id, span }) => {
  acc[id] = span
  return acc
}, {})
