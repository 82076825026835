import { SaveOutlined } from '@ant-design/icons'
import { Button, Input, message, Popconfirm, Tooltip } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { useUpdatePostserviceTemplateMutation } from '../../../graphql/generated'

export interface PostServiceUpdateTemplateButtonProps {
  data: () => { sample?: string; html: string }
  disabled: boolean
  templateName: string
  templateId: string
  buttonProps?: ButtonProps
}

const styles = { width: 300 }

export const PostServiceUpdateTemplateButton: FunctionComponent<PostServiceUpdateTemplateButtonProps> =
  props => {
    const [name, setName] = useState<string | undefined>(props.templateName)
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [, updatePostserviceTemplate] = useUpdatePostserviceTemplateMutation()

    const updateTemplate = useCallback(async () => {
      setLoading(true)
      const data = props.data()
      if (!data.sample) return message.error('Nepodařilo se uložit změny')

      const content = data.html
      const description = data.sample.substring(0, 200)

      try {
        await updatePostserviceTemplate({
          name: name || 'šablona',
          description: description,
          content: content,
          editedAt: new Date(),
          id: props.templateId,
        })
        message.success('Šablona byla upravena')
      } catch (error) {
        message.error('Nepodařilo se uložit změny')
      } finally {
        setLoading(false)
      }
      return
    }, [updatePostserviceTemplate, name, props])

    const handleChange = useCallback(
      (event: { target: { value?: string } }) => {
        setName(event.target.value)
      },
      []
    )

    const onOpenChange = useCallback(
      (open: boolean) => {
        const data = props.data()
        const notEmpty = !!data?.sample?.length
        setName(props.templateName)
        if (open && notEmpty) setDisabled(false)
        else setDisabled(true)
      },
      [props]
    )

    const title = useMemo(
      () => (
        <span>
          Opravdu chcete uložit změny v šabloně{' '}
          <span className="italic">{props.templateName}?</span>
        </span>
      ),
      [props.templateName]
    )

    const content = useMemo(
      () => (
        <div className="-ml-4">
          <div className="mb-1">Upravit název šablony</div>
          <Input
            onChange={handleChange}
            autoFocus
            placeholder="Název šablony"
            disabled={disabled}
            defaultValue={props.templateName}
          />
          {disabled ? (
            <div className="text-red-500">Nelze uložit šablonu bez obsahu</div>
          ) : (
            !name && (
              <div className="text-red-500 animate-fadeIn">
                Nelze uložit šablonu bez názvu
              </div>
            )
          )}
        </div>
      ),
      [disabled, handleChange, name, props.templateName]
    )

    const okButtonProps = useMemo(
      () => ({
        loading: loading,
        disabled: !name || disabled,
      }),
      [loading, disabled, name]
    )

    return (
      <Tooltip
        title="Aktualizujte prvně náhled PDF"
        trigger={props.disabled ? 'hover' : ''}
      >
        <Popconfirm
          title={title}
          description={content}
          onConfirm={updateTemplate}
          onOpenChange={onOpenChange}
          okButtonProps={okButtonProps}
          icon={null}
          overlayInnerStyle={styles}
          okText="Uložit"
          disabled={props.disabled}
        >
          <Button
            disabled={props.disabled}
            type="primary"
            ghost
            loading={loading}
            {...props.buttonProps}
          >
            Uložit změny v šabloně
            <SaveOutlined className="ml-2" />
          </Button>
        </Popconfirm>
      </Tooltip>
    )
  }
