import { Breakpoint, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { Jednotka } from '../../../common/lvTypes'
import { priceEstimateTableProps } from '../../utils/layoutConst'
import { propertyDetailPath } from '../../utils/paths'

export interface PriceEstimateLayoutUnitTableProps {
  jednotky: Jednotka[]
  propertyId: string
}

export const PriceEstimateLayoutUnitTable: FunctionComponent<PriceEstimateLayoutUnitTableProps> =
  props => {
    const buildingColumns: ColumnsType<Jednotka> = useMemo(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Jednotka',
          dataIndex: 'zpusobVyuziti',
          key: 'zpusobVyuziti',
          className: 'w-2/6',
          sorter: (a, b) =>
            (a?.zpusobVyuziti || '').localeCompare(b?.zpusobVyuziti || ''),
        },
        {
          title: 'Číslo jednotky',
          dataIndex: 'zpusobVyuziti',
          key: 'zpusobVyuziti',
          className: 'w-3/12',
          render: (cisloJednotky, record) => (
            <Link
              target="_blank"
              title="Zobrazit detail jednotky v novém okně"
              to={propertyDetailPath(PROPERTY_TYPES.UNIT, record.id)}
            >
              {cisloJednotky}
            </Link>
          ),
        },
        {
          title: 'Zastavěná plocha m²',
          dataIndex: 'zastavenaPlocha',
          key: 'zastavenaPlocha',
          className: 'w-3/12 !text-right',
        },
        {
          dataIndex: 'note',
          key: 'note',
          className: 'w-1/6 text-right',
          render: (_, record) =>
            record.id === props.propertyId && 'Tato jednotka',
        },
      ],
      [props.propertyId]
    )

    const rowClassName = useCallback(
      (record: Jednotka) =>
        record.id === props.propertyId ? 'text-gray-400' : '',
      [props.propertyId]
    )

    return (
      <Table
        dataSource={props.jednotky}
        columns={buildingColumns}
        rowClassName={rowClassName}
        rowKey="id"
        {...priceEstimateTableProps}
      />
    )
  }
