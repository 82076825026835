import { Spin } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import {
  ProceedingDetailResponse,
  Rizeni,
} from '../../../common/proceedingTypes'
import { useFormattedProceeding } from '../../utils/useProceedingString'
import { Day } from '../DateFormat/DateFormat'
import { KuWorkplaceTooltip } from '../KuWorkplaceTooltip/KuWorkplaceTooltip'
import { PropertyDetailProceedingTableExpandedRowItem as Item } from '../PropertyDetailProceedingTableExpandedRowItem/PropertyDetailProceedingTableExpandedRowItem'

export interface ProceedingDetailBaseInfoProps {
  record?: Rizeni
  data: ProceedingDetailResponse | null
  loading: boolean
}

export const ProceedingDetailBaseInfo: FunctionComponent<ProceedingDetailBaseInfoProps> =
  ({ record, data, loading }) => {
    const { proceedingTitle } = useFormattedProceeding(record)

    const predmety = useMemo(
      () => record?.predmety?.join(', ') || data?.predmety?.join(', '),
      [data?.predmety, record?.predmety]
    )
    const uzemi = useMemo(
      () =>
        data?.katastralniUzemi?.map(ku => `${ku.nazev} (${ku.kod})`).join(', '),
      [data?.katastralniUzemi]
    )

    return (
      <div className="lg:flex-1 flex-auto w-full">
        <div className="divide-y divide-gray-200">
          <Item label="Informace o řízení" isTitle>
            Hodnota
          </Item>
          <Spin spinning={loading}>
            <div className="divide-y divide-gray-200">
              <Item label="Číslo řízení">
                {proceedingTitle || data?.cisloRizeni}
              </Item>
              <Item label="Pracoviště">
                <KuWorkplaceTooltip kod={data?.pracoviste?.kod?.toString()}>
                  {data?.pracoviste?.nazev} ({data?.pracoviste?.kod})
                </KuWorkplaceTooltip>
              </Item>
              <Item label="Datum přijetí">
                <Day noData=" ">
                  {record?.datumPrijeti || data?.datumPrijeti}
                </Day>
              </Item>
              <Item label="Stav řízení">{data?.stav}</Item>
              <Item label="Řízení se týká nemovitostí v k.ú.">{uzemi}</Item>
              <Item label="Typ předmětu řízení">{predmety}</Item>
              <Item label="Správní poplatek" conditional>
                {data?.spravniPoplatek}
              </Item>
            </div>
          </Spin>
        </div>
      </div>
    )
  }
