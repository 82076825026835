import { Icon } from '@iconify/react'
import { Tooltip } from 'antd'
import React, { FunctionComponent } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { WatchdogIconSize } from '../../../common/watchdogTypes'
import { useToggleState } from '../../utils/useToggleState'
import { WatchdogAddPropertyModal } from '../WatchdogAddPropertyModal/WatchdogAddPropertyModal'

export interface WatchdogAddPropertyModalButtonProps {
  propertyId: string
  propertyType: PROPERTY_TYPES
  iconSize?: WatchdogIconSize
}

export const WatchdogAddPropertyModalButton: FunctionComponent<WatchdogAddPropertyModalButtonProps> =
  props => {
    const [open, handleOpen] = useToggleState(false)

    return (
      <>
        <Tooltip title="Přidat do hlídače katastru" placement="left">
          <div
            className="flex justify-center items-center text-primary hover:text-primaryHover cursor-pointer pt-1.5 pb-1"
            onClick={handleOpen}
          >
            <Icon
              icon="material-symbols:notification-add-outline"
              className={props.iconSize || 'text-lg'}
            />
          </div>
        </Tooltip>

        {open && (
          <WatchdogAddPropertyModal
            propertyId={props.propertyId}
            propertyType={props.propertyType}
            open={open}
            onClose={handleOpen}
          />
        )}
      </>
    )
  }
