import React, { FunctionComponent, useMemo } from 'react'

import {
  Identifier,
  IdentifierValues,
  Register,
  RegisterGroup,
  RegisterGroupMap,
} from '../../utils/dynamicSearchTooltip'
import { CeeMonitorAddSubjectModal } from '../CeeMonitorAddSubjectModal/CeeMonitorAddSubjectModal'
import { CuzkMonitorAddSubjectModal } from '../CuzkMonitorAddSubjectModal/CuzkMonitorAddSubjectModal'

export interface DynamicSearchTooltipMonitorSubjectProps {
  register: Register
  identifier: IdentifierValues
}

const monitorModalProps = {
  button: {
    size: 'middle' as const,
    type: 'link' as const,
    className: '!p-0',
  },
  baseData: {
    id: '1',
    key: 1,
    validatedCode: 30,
  },
}

export const DynamicSearchTooltipMonitorSubject: FunctionComponent<DynamicSearchTooltipMonitorSubjectProps> =
  props => {
    const monitorDataProps = useMemo(() => {
      const group = Object.keys(RegisterGroupMap).find(group =>
        RegisterGroupMap[group as keyof typeof RegisterGroupMap].includes(
          props.register
        )
      )

      let dataSource: {
        ico?: string
        rc?: string
        birthDate?: string
        firstName?: string
        lastName?: string
        description?: string
      } = {}

      if (!group) return { group, dataSource }

      switch (props.register) {
        case Register.CuzkOsIco:
        case Register.CeeIco:
          dataSource.ico = props.identifier?.[Identifier.ICO]
          dataSource.description = props.identifier?.[Identifier.DESCRIPTION]
          dataSource.firstName = props.identifier?.[Identifier.FIRST_NAME]
          dataSource.lastName = props.identifier?.[Identifier.LAST_NAME]

          break

        case Register.CuzkOsRc:
        case Register.CeeRc:
          dataSource.rc = props.identifier?.[Identifier.RC]
          dataSource.description = props.identifier?.[Identifier.DESCRIPTION]
          dataSource.firstName = props.identifier?.[Identifier.FIRST_NAME]
          dataSource.lastName = props.identifier?.[Identifier.LAST_NAME]
          break

        case Register.CuzkOsBirthDate:
        case Register.CeeBirthDate:
          if (
            typeof props.identifier === 'object' &&
            props.identifier !== null
          ) {
            dataSource = {
              birthDate: props.identifier?.[Identifier.BIRTH_DATE],
              firstName: props.identifier?.[Identifier.FIRST_NAME],
              lastName: props.identifier?.[Identifier.LAST_NAME],
              description: props.identifier?.[Identifier.DESCRIPTION],
            }
          }
          break
      }

      return { group, dataSource }
    }, [props.identifier, props.register])

    if (monitorDataProps?.group === RegisterGroup.CUZK) {
      return (
        <CuzkMonitorAddSubjectModal
          {...{
            buttonText: monitorDataProps?.group,
            buttonProps: { ...monitorModalProps.button },
            dataSource: [
              {
                ...monitorModalProps.baseData,
                ...monitorDataProps?.dataSource,
              },
            ],
          }}
        />
      )
    }
    if (monitorDataProps?.group === RegisterGroup.CEE) {
      return (
        <CeeMonitorAddSubjectModal
          {...{
            buttonText: monitorDataProps?.group,
            buttonProps: { ...monitorModalProps.button },
            dataSource: [
              {
                ...monitorModalProps.baseData,
                ...monitorDataProps?.dataSource,
              },
            ],
          }}
        />
      )
    }
    return null
  }
