import { Form, Input } from 'antd'
import React, { FunctionComponent } from 'react'

import { cuzkEpoFormHelp } from '../../utils/cuzkMonitorRcFormHelp'
import { onlyNumbers } from '../../utils/formHelpers'
import { AresSearchBar } from '../AresSearchBar/AresSearchBar'

export interface CuzkEpoFormOpoFragmentProps {
  showHelp?: boolean
}

export const CuzkEpoFormOpoFragment: FunctionComponent<CuzkEpoFormOpoFragmentProps> =
  props => {
    return (
      <>
        <Form.Item
          className="w-full"
          label="Vyhledat subjekt"
          name="aresSearch"
          help={(props.showHelp && cuzkEpoFormHelp.aresSearch) || undefined}
        >
          <AresSearchBar companyOnly />
        </Form.Item>

        <Form.Item
          label="IČO"
          name="ico"
          help={(props.showHelp && cuzkEpoFormHelp.ico) || undefined}
          normalize={onlyNumbers}
          rules={[
            {
              required: true,
              message: 'IČO je povinný údaj.',
            },
            {
              validator: async (_, value) => {
                if (value.length !== 8) {
                  throw new Error()
                }
              },
              message: 'Zadejte prosím platné IČO. IČO musí mít 8 číslic.',
            },
          ]}
        >
          <Input placeholder="IČO" maxLength={8} />
        </Form.Item>
        <Form.Item
          label="Název"
          name="name"
          help={(props.showHelp && cuzkEpoFormHelp.nazev) || undefined}
          rules={[
            {
              validator: async (_, value) => {
                if (!value || value.length < 3) {
                  throw new Error('Název musí být delší než 3 znaky.')
                }
              },
              required: true,
              message: 'Zadejte název právnické osoby.',
            },
          ]}
        >
          <Input placeholder="Název právnické osoby" />
        </Form.Item>
      </>
    )
  }
