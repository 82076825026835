import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, message, Popconfirm } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useCallback } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { setWatchdogLastChannel } from '../../../redux/watchdogLastChannelSlice'
import { drmPropertyType } from '../../utils/ruianHelpers'
import { useFetchWatchdogProperty } from '../../utils/useFetchWatchdog'
import { useToggleState } from '../../utils/useToggleState'
import { Fade } from '../Fade/Fade'

export interface WatchdogAddPropertyModalFooterProps {
  handleOpen: () => void
  propertyType: PROPERTY_TYPES
  propertyId: string
  channelId?: string | null
}

export const WatchdogAddPropertyModalFooter: FunctionComponent<WatchdogAddPropertyModalFooterProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)
    const dispatch = useAppDispatch()
    const refetch = useFetchWatchdogProperty()

    const [showHelp, toggleShowHelp] = useToggleState(false)

    const handleSubmit = useCallback(async () => {
      const type = drmPropertyType[props.propertyType]

      try {
        await axios.post(
          `https://data.regesta.cz/DrmApi/api/v1.0/Hlidani/Nemovitosti/${type}?access_token=${token}`,
          {
            nemovitostId: props.propertyId,
            kanal: props.channelId || 'null',
          }
        )
        dispatch(
          setWatchdogLastChannel({ id: props.channelId || null, name: null })
        )
        refetch()
        message.success('Přidáno do hlídače.')
      } catch (error) {
        message.error('Nepodařilo se přidat nemovitost do hlídače.')
      } finally {
        props.handleOpen()
      }
    }, [dispatch, props, refetch, token])

    return (
      <>
        <Fade show={showHelp} className="text-gray-400 pt-2">
          <InfoCircleOutlined className="inline mr-2" />
          Hlídač katastru slouží k automatického sledování změn u vámi vybraných
          nemovitostí. Pomocí &quot;Kanálů&quot; si můžete hlídané nemovitosti
          rozdělit, kategorizovat a individuálně nastavit e-mailové notifikace.
        </Fade>

        <div className="flex justify-between pt-4 mt-4 border-t border-gray-200">
          <Button
            icon={<InfoCircleOutlined />}
            type="link"
            className="!pl-0"
            onClick={toggleShowHelp}
          >
            {showHelp ? 'Skrýt nápovědu' : 'Zobrazit nápovědu'}
          </Button>

          <div className="space-x-2">
            <Button onClick={props.handleOpen}>Zrušit</Button>
            <Popconfirm
              title="Není vybrán kanál hlídače"
              description={`Bez přiřazení kanálu, nebudete dostávat e-mailové notifikace.`}
              onConfirm={handleSubmit}
              okText="Rozumím a pokračovat"
              cancelText="Zrušit"
              disabled={!!props.channelId}
            >
              <Button
                type="primary"
                htmlType="submit"
                onClick={props.channelId ? handleSubmit : undefined}
              >
                Přidat do hlídače
              </Button>
            </Popconfirm>
          </div>
        </div>
      </>
    )
  }
