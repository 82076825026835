import { Icon } from '@iconify/react'
import { Alert } from 'antd'
import React, { FunctionComponent } from 'react'

import { IncreasingNumber } from '../IncreasingNumber/IncreasingNumber'

export interface CadastralSelectionModalFooterCounterProps {
  selected: {
    workplacesCount: number
    lvCount: number
    price: number
  }
}

export const CadastralSelectionModalFooterCounter: FunctionComponent<CadastralSelectionModalFooterCounterProps> =
  ({ selected }) => {
    return (
      <Alert
        className={`!px-2 !py-1 ${selected.workplacesCount ? '' : 'grayscale'}`}
        message={
          <div className={`${selected.workplacesCount ? '' : 'text-gray-500'}`}>
            <span className="mr-2">
              Vybraných pracovišť:{' '}
              <IncreasingNumber
                className="font-bold"
                finalNumber={selected.workplacesCount}
                decimal={0}
              />
            </span>
            <span className="mr-2">
              Celkem obsahuje LV:{' '}
              <IncreasingNumber
                className="font-bold"
                finalNumber={selected.lvCount}
                decimal={0}
              />
            </span>
            <span className="mr-2">
              Celková cena:{' '}
              <IncreasingNumber
                className="font-bold"
                finalNumber={selected.price}
              />{' '}
              Kr.
            </span>
          </div>
        }
        type="success"
        showIcon
        icon={<Icon icon="ph:shopping-cart" width="18" />}
      />
    )
  }
