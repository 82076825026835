import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { propertyDetailPath } from '../../utils/paths'
import { Year } from '../DateFormat/DateFormat'
import { ExtendedBuildingRuian } from '../PriceEstimateBuilding/PriceEstimateBuilding'
import { PriceEstimateLayoutLvDataRow as Item } from '../PriceEstimateLayoutLvDataRow/PriceEstimateLayoutLvDataRow'

export interface PriceEstimateLayoutBuildingLvProps {
  ruianData?: ExtendedBuildingRuian
  propertyId: string
}

export const PriceEstimateLayoutBuildingLv: FunctionComponent<PriceEstimateLayoutBuildingLvProps> =
  props => {
    const ruian = props.ruianData?.ruian

    return (
      <>
        <Item title="Budova">
          <Link
            target="_blank"
            title="Zobrazit detail budovy v novém okně"
            to={propertyDetailPath(PROPERTY_TYPES.BUILDING, ruian?.id)}
          >
            {ruian?.nazev}
          </Link>
        </Item>

        <Item title="Počet nadzemních podlaží">
          {ruian?.pocetNadzemnichPodlazi?.text}
        </Item>

        <Item title="Počet bytů">{ruian?.pocetBytu}</Item>

        <Item title="Rok dokončení" condition={!!ruian?.dokonceni}>
          <Year>{ruian?.dokonceni}</Year>
        </Item>

        <Item title="Zastavěná plocha" suffix=" m²">
          {ruian?.zastavenaPlocha}
        </Item>

        <Item condition={!ruian?.zastavenaPlocha} title="Zastavěná plocha">
          {ruian?.plochaPolygonu?.toFixed(0)}
          {' m² '}
          <span className="font-normal text-gray-400">
            (Vypočteno z polygonu)
          </span>
        </Item>

        <Item title="Podlahová plocha" suffix=" m²">
          {ruian?.podlahovaPlocha}
        </Item>

        <Item title="Obestavěný prostor" suffix=" m³">
          {ruian?.obestavenyProstor}
        </Item>
        <Item title="Druh konstrukce">{ruian?.druhKonstrukce?.nazev}</Item>
        <Item title="Vodovod">{ruian?.vodovod?.nazev}</Item>
        <Item title="Odpady">{ruian?.odpady?.nazev}</Item>
        <Item title="Plyn">{ruian?.plyn?.nazev}</Item>
        <Item title="Způsob vytápění">{ruian?.zpusobVytapeni?.nazev}</Item>
      </>
    )
  }
