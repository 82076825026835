import React, { FunctionComponent } from 'react'
import { Layer } from 'react-map-gl'

export interface CadastreMapSelectedFeatureDisplayProps {
  source: string
}

export const CadastreMapSelectedFeatureDisplay: FunctionComponent<CadastreMapSelectedFeatureDisplayProps> =
  ({ source }) => {
    return (
      <>
        <Layer
          source={source}
          type="fill"
          filter={['==', ['get', 'layerName'], 'Parcela']}
          paint={{
            'fill-color': '#39898a',
            'fill-opacity': 0.66,
          }}
          minzoom={15}
        />
        <Layer
          source={source}
          type="fill"
          filter={['==', ['get', 'layerName'], 'StavebniObjekt']}
          paint={{
            'fill-color': '#73c5c6',
          }}
          minzoom={15}
        />
        <Layer
          source={source}
          type="line"
          paint={{
            'line-color': '#38404b',
            'line-width': 2,
          }}
          minzoom={15}
        />
        <Layer
          source={source}
          type="line"
          filter={['!', ['has', 'layerName']]}
          paint={{
            'line-color': '#43c28f',
            'line-width': 2,
          }}
          minzoom={15}
        />
      </>
    )
  }
