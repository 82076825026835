import { message } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useEffect, useState } from 'react'

import {
  ProceedingDetailResponse,
  Rizeni,
} from '../../../common/proceedingTypes'
import {
  UserActivityActionType,
  UserActivityEntityType,
} from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { requestProceedingDetailPath } from '../../utils/paths'
import { useFormattedProceeding } from '../../utils/useProceedingString'
import { useUpdateUserActivityLog } from '../../utils/useUpdateUserActivityLog'
import { ProceedingDetailDocumentCollectionTable } from '../ProceedingDetailDocumentCollectionTable/ProceedingDetailDocumentCollectionTable'
import { ProceedingDetailInformation } from '../ProceedingDetailInformation/ProceedingDetailInformation'
import { PropertyDetailLvAreaTable } from '../PropertyDetailLvAreaTable/PropertyDetailLvAreaTable'
import { PropertyDetailLvBuildingTable } from '../PropertyDetailLvBuildingTable/PropertyDetailLvBuildingTable'
import { PropertyDetailLvUnitTable } from '../PropertyDetailLvUnitTable/PropertyDetailLvUnitTable'

interface FetchState {
  data: ProceedingDetailResponse | null
  fetching: boolean
  error: boolean
}

export interface ProceedingDetailModalContentProps {
  proceeding: Rizeni
}

export const ProceedingDetailModalContent: FunctionComponent<ProceedingDetailModalContentProps> =
  ({ proceeding }) => {
    const token = useAppSelector(state => state.myProfile.token)
    const updateUserActivityLog = useUpdateUserActivityLog()

    const { proceedingKey, proceedingUrlPath, proceedingTitle } =
      useFormattedProceeding(proceeding)

    const [{ data, fetching, error }, setData] = useState<FetchState>({
      data: null,
      fetching: false,
      error: false,
    })

    useEffect(() => {
      const fetchProceedingDetail = async () => {
        setData(prev => ({ ...prev, fetching: true }))
        try {
          const response = await axios.get(
            requestProceedingDetailPath() + token + '/' + proceedingUrlPath
          )
          setData({ data: response.data, fetching: false, error: false })
          proceedingTitle &&
            updateUserActivityLog(
              proceedingTitle,
              UserActivityEntityType.Proceeding,
              UserActivityActionType.SeenDetail
            )
        } catch (error) {
          setData({ data: null, fetching: false, error: true })
          message.error('Nepodařilo se načíst detail řízení.')
        }
      }
      if (!error && !data && !fetching) fetchProceedingDetail()
    }, [
      data,
      error,
      fetching,
      proceedingTitle,
      proceedingUrlPath,
      token,
      updateUserActivityLog,
    ])

    return (
      <div className="border-gray-300 border-solid border-t pt-6 mt-4">
        <ProceedingDetailDocumentCollectionTable
          proceedingString={proceedingKey || ''}
        />
        <ProceedingDetailInformation
          record={proceeding}
          data={data}
          loading={fetching}
          error={error}
        />
        <div className="w-full space-y-4 mt-8">
          {!!data?.nemovitosti?.jednotky.length && (
            <PropertyDetailLvUnitTable
              showCity
              hideMetrage
              target="_blank"
              propertyId=""
              jednotky={data.nemovitosti.jednotky}
            />
          )}
          {!!data?.nemovitosti?.budovy.length && (
            <PropertyDetailLvBuildingTable
              showCity
              hideMetrage
              target="_blank"
              propertyId=""
              budovy={data.nemovitosti.budovy}
              parcely={data.nemovitosti.parcely}
            />
          )}
          {!!data?.nemovitosti?.parcely.length && (
            <PropertyDetailLvAreaTable
              showCadastralArea
              hideMetrage
              target="_blank"
              propertyId=""
              parcely={data.nemovitosti.parcely}
              budovy={data.nemovitosti.budovy}
            />
          )}
        </div>
      </div>
    )
  }
