import React, { FunctionComponent } from 'react'

import { RestrictionChecType } from '../../../common/epoTypes'
import { CadastralOffice } from '../../utils/cuzkHelpers'
import { CadastralSelectionModalButton } from '../CadastralSelectionModalButton/CadastralSelectionModalButton'

export interface CuzkEpoRestrictionCheckPanelSearchRestrictionProps {
  epoId: string
  refetch: () => void
  checkboxData: CadastralOffice[]
}

export const CuzkEpoRestrictionCheckPanelSearchRestriction: FunctionComponent<CuzkEpoRestrictionCheckPanelSearchRestrictionProps> =
  props => {
    return (
      <>
        <div className="mb-2 mt-2 text-center text-base font-bold">
          Ověření práv 3. stran v jednotlivých katastrálních pracovištích
        </div>
        <div className="mb-2 mt-2 text-center text-gray-400">
          Vyberte katastrální pracoviště, u nichž chcete ověřit práva 3. stran.
        </div>
        <CadastralSelectionModalButton
          epoId={props.epoId}
          checkboxData={props.checkboxData}
          refetch={props.refetch}
          type={RestrictionChecType.restriction}
        />
      </>
    )
  }
