import { useMemo } from 'react'

import { BASE_URL, GOOGLE_MAPS_KEY } from '../../../ini.json'
import { AUCTION_TYPES } from '../../common/auctionScrapeTypes'
import { PROPERTY_TYPES } from '../../common/drmTypes'
import { LvGeometryApiResponse, OriginalniHranice } from '../../common/lvTypes'
import { useAppSelector } from '../../redux/hooks'
import { MAP_TYPE } from '../components/MapTypeButton/MapTypeButton'
import buildingIconSrc from '../images/ikona_budova.png?url'
import unitIconSrc from '../images/ikona_jednotka.png?url'
import unitSecondIconSrc from '../images/ikona_jednotka_second.png?url'
import areaIconSrc from '../images/ikona_pozemek.png?url'
import { formatAndCompressPolygon } from './polygonHelpers'
import { usePropertyDetailImageStreetView } from './usePropertyDetailImageStreetView'

const fillActive = {
  [PROPERTY_TYPES.UNIT]: '0xDC262688',
  [PROPERTY_TYPES.BUILDING]: '0xDC262688',
  [PROPERTY_TYPES.AREA]: '0xBEF26488',
}
const fillInactive = {
  [PROPERTY_TYPES.UNIT]: '0xDC262633',
  [PROPERTY_TYPES.BUILDING]: '0xDC262633',
  [PROPERTY_TYPES.AREA]: '0xBEF26433',
}
const strokeColor = {
  [PROPERTY_TYPES.UNIT]: '0x991B1B',
  [PROPERTY_TYPES.BUILDING]: '0x991B1B',
  [PROPERTY_TYPES.AREA]: '0x4D7C0F',
}
const icon = {
  [PROPERTY_TYPES.BUILDING]: `&markers=icon:${BASE_URL + buildingIconSrc}`,
  [PROPERTY_TYPES.AREA]: `&markers=icon:${BASE_URL + areaIconSrc}`,
  [PROPERTY_TYPES.UNIT]: `&markers=icon:${BASE_URL + unitIconSrc}`,
  INACTIVE_UNIT: `&markers=icon:${BASE_URL + unitSecondIconSrc}`,
}

const GOOGLE_LINK = 'https://www.google.com/maps/?q='
const GOOGLE_STATIC = `https://maps.googleapis.com/maps/api/staticmap?size=1000x1000&scale=2&style=feature:poi|visibility:off&key=${GOOGLE_MAPS_KEY}`
const GOOGLE_SW_STATIC = `https://maps.googleapis.com/maps/api/streetview?return_error_code=true&size=2000x2000&fov=100&pitch=20&source=outdoor&key=${GOOGLE_MAPS_KEY}&location=` // + ${lat},${lon}

const createPolygonPath = (
  geometry: OriginalniHranice,
  fillcolor: string,
  color: string
) =>
  geometry?.polygons
    .map(
      polygon =>
        `&path=color:${color}|weight:1|fillcolor:${fillcolor}|enc:${formatAndCompressPolygon(
          polygon.exterior
        )}`
    )
    .join('')

const createEsriPolygonPath = (
  fillcolor: string,
  color: string,
  rings?: number[][][]
) => {
  if (!rings || rings.length === 0) return ''

  const formatRing = (ring: number[][]) =>
    ring.map(coord => `${coord[1]},${coord[0]}`).join('|')

  const outerRing = formatRing(rings[0])
  const innerRings = rings.slice(1).map(ring => formatRing(ring))

  const path = [outerRing, ...innerRings].join('||')

  return `&path=color:${color}|weight:1|fillcolor:${fillcolor}|${path}`
}

export const usePropertyDetailImage = (propertyId: string) => {
  const lvGeometry = useAppSelector(state => state.property.lvGeometry)
  const ruianId = useAppSelector(state => state.property.ruian.data?.id)
  const { lat, long: lng } = useAppSelector(
    state => state.property.ruian.data?.definicniBod || { lat: 0, long: 0 }
  )
  const streetView = usePropertyDetailImageStreetView(propertyId)

  const isTooManyPaths = useMemo(
    () =>
      (lvGeometry?.data?.jednotky?.length || 0) +
        (lvGeometry?.data?.budovy?.length || 0) +
        (lvGeometry?.data?.parcely?.length || 0) >
      150,
    [
      lvGeometry?.data?.jednotky,
      lvGeometry?.data?.budovy,
      lvGeometry?.data?.parcely,
    ]
  )

  const areaPaths = useMemo(
    () =>
      lvGeometry?.data?.parcely
        ?.filter(parcela => !isTooManyPaths || parcela.id === ruianId)
        ?.map(parcela =>
          parcela.id === ruianId
            ? createPolygonPath(
                parcela.originalniHranice,
                fillActive[PROPERTY_TYPES.AREA],
                strokeColor[PROPERTY_TYPES.AREA]
              ) +
              `${icon[PROPERTY_TYPES.AREA]}|${parcela.definicniBod?.lat},${
                parcela.definicniBod?.long
              }`
            : createPolygonPath(
                parcela.originalniHranice,
                fillInactive[PROPERTY_TYPES.AREA],
                strokeColor[PROPERTY_TYPES.AREA]
              )
        ) || [],
    [lvGeometry?.data?.parcely, ruianId, isTooManyPaths]
  )

  const buildingPaths = useMemo(
    () =>
      lvGeometry?.data?.budovy
        ?.filter(budova => !isTooManyPaths || budova.id === ruianId)
        ?.map(budova =>
          budova.id === ruianId
            ? createPolygonPath(
                budova.originalniHranice,
                fillActive[PROPERTY_TYPES.BUILDING],
                strokeColor[PROPERTY_TYPES.BUILDING]
              ) +
              `${icon[PROPERTY_TYPES.BUILDING]}|${budova.definicniBod?.lat},${
                budova.definicniBod?.long
              }`
            : createPolygonPath(
                budova.originalniHranice,
                fillInactive[PROPERTY_TYPES.BUILDING],
                strokeColor[PROPERTY_TYPES.BUILDING]
              )
        ) || [],
    [isTooManyPaths, lvGeometry?.data?.budovy, ruianId]
  )

  const unitMarkers = useMemo(
    () =>
      lvGeometry?.data?.jednotky
        ?.filter(jednotka => !isTooManyPaths || jednotka.id === ruianId)
        ?.map(jednotka =>
          jednotka.id === ruianId
            ? `${icon[PROPERTY_TYPES.UNIT]}|${jednotka.definicniBod?.lat},${
                jednotka.definicniBod?.long
              }`
            : `${icon.INACTIVE_UNIT}|${jednotka.definicniBod?.lat},${jednotka.definicniBod?.long}`
        ) || [],
    [isTooManyPaths, lvGeometry?.data?.jednotky, ruianId]
  )

  const allPaths = useMemo(
    () => [...areaPaths, ...buildingPaths, ...unitMarkers].join(''),
    [areaPaths, buildingPaths, unitMarkers]
  )

  const mapSourceData = useMemo(
    () => ({
      [MAP_TYPE.SW]: streetView,
      [MAP_TYPE.MAP]: {
        link: `${GOOGLE_LINK}${lat},${lng}`,
        src: `${GOOGLE_STATIC}${allPaths}`,
      },
      [MAP_TYPE.AERIAL]: {
        link: `${GOOGLE_LINK}${lat},${lng}&ll=${lat},${lng}&z=17&t=h`,
        src: `${GOOGLE_STATIC}${allPaths}&maptype=satellite`,
      },
    }),
    [allPaths, lat, lng, streetView]
  )
  return !lvGeometry?.data
    ? {
        [MAP_TYPE.SW]: streetView,
        [MAP_TYPE.MAP]: { src: '', link: '' },
        [MAP_TYPE.AERIAL]: { src: '', link: '' },
      }
    : mapSourceData
}

export const useLvDetailImage = (lvGeometry: LvGeometryApiResponse) => {
  const { lat, long: lng } = useAppSelector(
    state => state.property.ruian.data?.definicniBod || { lat: 0, long: 0 }
  )

  const isTooManyPaths = useMemo(
    () =>
      (lvGeometry?.jednotky?.length || 0) +
        (lvGeometry?.budovy?.length || 0) +
        (lvGeometry?.parcely?.length || 0) >
      150,
    [lvGeometry?.jednotky, lvGeometry?.budovy, lvGeometry?.parcely]
  )

  const areaPaths = useMemo(() => {
    if (isTooManyPaths) {
      const locations = lvGeometry?.parcely
        ?.map(parcela =>
          parcela.definicniBod?.lat && parcela.definicniBod?.long
            ? `${parcela.definicniBod.lat},${parcela.definicniBod.long}`
            : null
        )
        .filter(Boolean)

      if (locations && locations.length > 0) {
        return [
          `&markers=icon:${encodeURIComponent(
            icon[PROPERTY_TYPES.AREA]
          )}|${locations.join('|')}`,
        ]
      } else {
        return []
      }
    } else {
      return (
        lvGeometry?.parcely?.map(parcela =>
          createPolygonPath(
            parcela.originalniHranice,
            fillInactive[PROPERTY_TYPES.AREA],
            strokeColor[PROPERTY_TYPES.AREA]
          )
        ) || []
      )
    }
  }, [isTooManyPaths, lvGeometry?.parcely])

  const buildingPaths = useMemo(() => {
    if (isTooManyPaths) {
      const locations = lvGeometry?.budovy
        ?.map(budova =>
          budova.definicniBod?.lat && budova.definicniBod?.long
            ? `${budova.definicniBod.lat},${budova.definicniBod.long}`
            : null
        )
        .filter(Boolean)

      if (locations && locations.length > 0) {
        return [
          `&markers=icon:${encodeURIComponent(
            icon[PROPERTY_TYPES.BUILDING]
          )}|${locations.join('|')}`,
        ]
      } else {
        return []
      }
    } else {
      return (
        lvGeometry?.budovy?.map(budova =>
          createPolygonPath(
            budova.originalniHranice,
            fillInactive[PROPERTY_TYPES.BUILDING],
            strokeColor[PROPERTY_TYPES.BUILDING]
          )
        ) || []
      )
    }
  }, [isTooManyPaths, lvGeometry?.budovy])

  const unitMarkers = useMemo(() => {
    const locations = lvGeometry?.jednotky
      ?.map(jednotka =>
        jednotka.definicniBod?.lat && jednotka.definicniBod?.long
          ? `${jednotka.definicniBod.lat},${jednotka.definicniBod.long}`
          : null
      )
      .filter(Boolean)

    if (locations && locations.length > 0) {
      return [
        `&markers=icon:${encodeURIComponent(
          icon.INACTIVE_UNIT
        )}|${locations.join('|')}`,
      ]
    }
    return []
  }, [lvGeometry?.jednotky])

  const allPaths = useMemo(
    () => [...areaPaths, ...buildingPaths, ...unitMarkers].join(''),
    [areaPaths, buildingPaths, unitMarkers]
  )

  const mapSourceData = useMemo(
    () => ({
      [MAP_TYPE.SW]: { src: '', link: '' },
      [MAP_TYPE.MAP]: {
        link: `${GOOGLE_LINK}${lat},${lng}`,
        src: `${GOOGLE_STATIC}${allPaths}`,
      },
      [MAP_TYPE.AERIAL]: {
        link: `${GOOGLE_LINK}${lat},${lng}&ll=${lat},${lng}&z=17&t=h`,
        src: `${GOOGLE_STATIC}${allPaths}&maptype=satellite`,
      },
    }),
    [allPaths, lat, lng /* , streetView */]
  )
  return !lvGeometry
    ? {
        [MAP_TYPE.SW]: { src: '', link: '' },
        [MAP_TYPE.MAP]: { src: '', link: '' },
        [MAP_TYPE.AERIAL]: { src: '', link: '' },
      }
    : mapSourceData
}

export const useDetailDrawerPropertyImage = (
  propertyId: string,
  propertyType: PROPERTY_TYPES,
  rings?: number[][][]
) => {
  const { lat, long: lng } = useAppSelector(
    state => state.property.ruian.data?.definicniBod || { lat: 0, long: 0 }
  )
  const streetView = usePropertyDetailImageStreetView(propertyId)

  const polygonPath = useMemo(() => {
    return createEsriPolygonPath(
      fillActive[propertyType],
      strokeColor[propertyType],
      rings
    )
  }, [propertyType, rings])

  const mapSourceData = useMemo(() => {
    const marker = `${icon[propertyType]}|${lat},${lng}`

    return {
      [MAP_TYPE.SW]: streetView,
      [MAP_TYPE.MAP]: {
        link: `${GOOGLE_LINK}${lat},${lng}`,
        src: `${GOOGLE_STATIC}${polygonPath}${marker}`,
      },
      [MAP_TYPE.AERIAL]: {
        link: `${GOOGLE_LINK}${lat},${lng}&ll=${lat},${lng}&z=17&t=h`,
        src: `${GOOGLE_STATIC}${polygonPath}${marker}&maptype=satellite`,
      },
    }
  }, [lat, lng, polygonPath, propertyType, streetView])

  return mapSourceData
}
/**
 * Simple hook without streetview availability check.
 * Suitable for property listings.
 * PropertyType Unit is converted to Building type
 * Primarily for use with Regesta data source
 *
 *
 * @returns {Object} Object with image src from google static api and links to google maps
 *
 */
export function useMapImageByCoordinations(
  lat?: number,
  lng?: number,
  propertyType?: PROPERTY_TYPES,
  polygon?: OriginalniHranice | null
) {
  const noUnitType =
    propertyType === PROPERTY_TYPES.AREA
      ? PROPERTY_TYPES.AREA
      : PROPERTY_TYPES.BUILDING

  const marker = `${icon[noUnitType]}|${lat},${lng}`
  const polygonPath = polygon
    ? createPolygonPath(
        polygon,
        fillActive[noUnitType],
        strokeColor[noUnitType]
      )
    : ''

  const mapZoom = !polygon ? '&zoom=12' : '&zoom=15'
  const buildingZoom =
    propertyType !== PROPERTY_TYPES.AREA && !polygon ? '&zoom=19' : ''

  return useMemo(() => {
    if (!(lat && lng) && !polygon)
      return {
        [MAP_TYPE.SW]: { src: '', link: '' },
        [MAP_TYPE.MAP]: { src: '', link: '' },
        [MAP_TYPE.AERIAL]: { src: '', link: '' },
      }

    return {
      [MAP_TYPE.SW]: {
        link: `${GOOGLE_LINK}&layer=c&cbll=${lat},${lng}`,
        src: `${GOOGLE_SW_STATIC}${lat},${lng}`,
      },
      [MAP_TYPE.MAP]: {
        link: `${GOOGLE_LINK}${lat},${lng}`,
        src: `${GOOGLE_STATIC}${polygonPath}${marker}${mapZoom}`,
      },
      [MAP_TYPE.AERIAL]: {
        link: `${GOOGLE_LINK}${lat},${lng}&ll=${lat},${lng}&z=17&t=h`,
        src: `${GOOGLE_STATIC}${polygonPath}${marker}${buildingZoom}&maptype=satellite`,
      },
    }
  }, [lat, lng, polygon, polygonPath, marker, mapZoom, buildingZoom])
}

export function useAuctionMapImage(
  lat?: number | null,
  lng?: number | null,
  auctionType?: AUCTION_TYPES,
  imageUrl?: string | null
) {
  const propertyType =
    auctionType === AUCTION_TYPES.AREA
      ? PROPERTY_TYPES.AREA
      : auctionType === AUCTION_TYPES.UNIT
      ? PROPERTY_TYPES.UNIT
      : PROPERTY_TYPES.BUILDING

  const marker = `${icon[propertyType]}|${lat},${lng}`

  const buildingZoom = propertyType !== PROPERTY_TYPES.AREA ? '&zoom=19' : ''

  return useMemo(() => {
    if (!(lat && lng))
      return {
        [MAP_TYPE.SW]: { src: '', link: '' },
        [MAP_TYPE.MAP]: { src: '', link: '' },
        [MAP_TYPE.AERIAL]: { src: '', link: '' },
      }

    return {
      [MAP_TYPE.SW]: {
        link: '',
        src: imageUrl || '',
      },
      [MAP_TYPE.MAP]: {
        link: `${GOOGLE_LINK}${lat},${lng}`,
        src: `${GOOGLE_STATIC}${marker}&zoom=12`,
      },
      [MAP_TYPE.AERIAL]: {
        link: `${GOOGLE_LINK}${lat},${lng}&ll=${lat},${lng}&z=17&t=h`,
        src: `${GOOGLE_STATIC}${marker}${buildingZoom}&maptype=satellite`,
      },
    }
  }, [lat, lng, imageUrl, marker, buildingZoom])
}
