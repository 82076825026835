import { FilterOutlined, InfoCircleOutlined } from '@ant-design/icons'
import {
  Button,
  Divider,
  Form,
  FormProps,
  InputNumber,
  Modal,
  Select,
  Space,
} from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import {
  AuctionListingState,
  AuctionListingType,
} from '../../../common/auctionScrapeTypes'
import {
  auctionFormHelp,
  auctionStateOptions,
  auctionTypeOptions,
} from '../../utils/auctionMonitoringHelpers'
import { formItemLayout } from '../../utils/layoutConst'
import { useToggleState } from '../../utils/useToggleState'

interface FormValues {
  type: AuctionListingType[]
  state: AuctionListingState[]
}

export interface CadastreMapAuctionLayerControlFilterModalButtonProps {
  filter: FormValues
  onFilterChange: React.Dispatch<React.SetStateAction<FormValues>>
}

export const CadastreMapAuctionLayerControlFilterModalButton: FunctionComponent<CadastreMapAuctionLayerControlFilterModalButtonProps> =
  props => {
    const [open, toggleOpen, , closeModal] = useToggleState()
    const [showHelp, toggleShowHelp] = useToggleState()

    const handleOnFinish = useCallback<
      NonNullable<FormProps<FormValues>['onFinish']>
    >(
      values => {
        props.onFilterChange(values)
        closeModal()
      },
      [props, closeModal]
    )

    return (
      <div>
        <Button
          className="hover:!bg-white"
          icon={<FilterOutlined />}
          onClick={toggleOpen}
          title="Filtrovat výběr dražeb"
        />

        <Modal
          title="Filtr zobrazení dražeb"
          open={open}
          onCancel={toggleOpen}
          footer={null}
          width={750}
        >
          <Form<FormValues>
            {...formItemLayout}
            initialValues={props.filter}
            onFinish={handleOnFinish}
            className="!pt-5"
          >
            <Form.Item
              label="Typ nemovitosti"
              name="type"
              help={(showHelp && auctionFormHelp.type) || undefined}
            >
              <Select mode="multiple" options={auctionTypeOptions} />
            </Form.Item>

            <Form.Item
              label="Stav dražby"
              name="state"
              help={(showHelp && auctionFormHelp.state) || undefined}
            >
              <Select mode="multiple" options={auctionStateOptions} />
            </Form.Item>

            <Form.Item
              label="Cena"
              className="!mb-0"
              help={(showHelp && auctionFormHelp.price) || undefined}
            >
              <Space>
                <Form.Item name="priceFrom" className="inline-block">
                  <InputNumber
                    addonBefore="Od"
                    placeholder="0"
                    min={0}
                    className="w-full"
                  />
                </Form.Item>
                <Form.Item name="priceTo" className="inline-block">
                  <InputNumber
                    min={0}
                    addonBefore="Do"
                    placeholder="∞"
                    className="w-full"
                  />
                </Form.Item>
              </Space>
            </Form.Item>

            <Divider />

            <Form.Item noStyle>
              <div className="flex justify-between">
                <Button
                  icon={<InfoCircleOutlined />}
                  type="link"
                  onClick={toggleShowHelp}
                >
                  {showHelp ? 'Skrýt nápovědu' : 'Zobrazit nápovědu'}
                </Button>

                <div className="space-x-2">
                  <Button onClick={closeModal}>Zrušit</Button>

                  <Button type="primary" htmlType="submit">
                    Zobrazit dražby
                  </Button>
                </div>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
