import { Button, Typography } from 'antd'
import React, { FunctionComponent, useMemo, useState } from 'react'

import { MarketplaceItem } from '../../../common/marketplaceTypes'
import { Day } from '../DateFormat/DateFormat'
import { Money } from '../Money/Money'

export interface MarketplaceItemInfoProps {
  content: string | number
  data: MarketplaceItem
}

export const MarketplaceItemInfo: FunctionComponent<MarketplaceItemInfoProps> =
  props => {
    const { Paragraph } = Typography
    const [ellipsis, setEllipsis] = useState(true)
    const ltv =
      (Number(props.data.investmentAmount) /
        Number(props.data.propertyValue || 1)) *
      100

    const showMore = useMemo(
      () =>
        props.data?.description && props.data?.description?.length > 130 ? (
          <Button
            size="small"
            type="link"
            onClick={() => setEllipsis(!ellipsis)}
          >
            {ellipsis ? 'Zobrazit více' : 'Skrýt'}
          </Button>
        ) : null,
      [ellipsis, props.data?.description]
    )

    const paragraphProps = useMemo(
      () => ({
        style: { marginBottom: 0 },
        ellipsis: ellipsis
          ? {
              rows: 4,
            }
          : {
              rows: 50,
            },
      }),
      [ellipsis]
    )

    return (
      <div
        className={`${
          props.content === 'info'
            ? 'p-5 mb-4 mt-2 transition-all left-0 opacity-100'
            : 'p-5 mb-4 mt-2 absolute h-full transition-all left-96 opacity-0'
        }`}
      >
        <h2 className="font-bold text-xl mb-2">{props.data.title}</h2>
        <h3 className="font-bold text-gray-500 mb-2">
          {props.data.offerLocation}
        </h3>
        <Paragraph {...paragraphProps}>{props.data?.description}</Paragraph>
        {showMore}

        <div className="pb-1 mb-2 border-b border-solid border-gray-200 flex justify-between mt-6">
          <div>Typ investice</div>
          <div>{props.data.offerType}</div>
        </div>
        <div className="pb-1 mb-2 border-b border-solid border-gray-200  flex justify-between">
          <div>Výnos p.a.</div>
          <Money amount={props.data.interestAmount} symbol="%" />
        </div>
        <div className="pb-1 mb-2 border-b border-solid border-gray-200  flex justify-between">
          <div>Měsíční splátka / nájem </div>
          <Money amount={props.data.monthlyPayment} symbol="Kč" />
        </div>
        <div className="pb-1 mb-2 border-b border-solid border-gray-200  flex justify-between">
          <div>Hodnota nemovitosti - odhad</div>
          <Money amount={props.data.propertyValue} symbol="Kč" />
        </div>
        <div className="pb-1 mb-2 border-b border-solid border-gray-200  flex justify-between font-bold ">
          <div>Výše investice vč. odměny</div>
          <Money amount={props.data.investmentAmount} symbol="Kč" />
        </div>
        <div className="pb-1 mb-2 border-b border-solid border-gray-200  flex justify-between">
          <div>LTV</div>
          <Money amount={ltv} symbol="%" />
        </div>
        <div className="pb-1 flex justify-between">
          <div>Platnost do</div>
          <Day>{props.data.expirationDate}</Day>
        </div>
      </div>
    )
  }
