import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Icon } from '@iconify/react'
import { Button, Input } from 'antd'
import React, { FunctionComponent, useCallback, useState } from 'react'

export interface TabbleCellEditNoteModalProps {
  onSave: (id: number, note?: string) => void
  recordId: number
  initialNote?: string
}

export const TabbleCellEditNoteModal: FunctionComponent<TabbleCellEditNoteModalProps> =
  props => {
    const [active, setActive] = useState(false)

    const handleOnClick = useCallback(() => {
      setActive(true)
    }, [])

    const handleOnSave = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (e: any) => {
        props.onSave(props.recordId, e?.target?.value)
        setActive(false)
      },
      [props]
    )

    return (
      <div
        className="flex space-x-2 cursor-text hover:transition-all hover:shadow-sm hover:shadow-primaryHover/20 hover:bg-slate-50"
        onClick={handleOnClick}
      >
        <Button
          type="link"
          size="small"
          className="opacity-40 hover:opacity-100 inline-block"
          title="Editovat popis"
          icon={
            active ? (
              <Icon
                icon="fa6-solid:arrow-turn-down"
                rotate={1}
                className="mt-0.5 mx-auto w-3"
              />
            ) : props.initialNote ? (
              <EditOutlined />
            ) : (
              <PlusOutlined className="!text-xs" />
            )
          }
        />

        {active ? (
          <Input
            size="small"
            autoFocus
            bordered={false}
            className="w-full inline-block"
            allowClear
            onBlur={handleOnSave}
            onPressEnter={handleOnSave}
            defaultValue={props.initialNote}
          />
        ) : (
          props.initialNote
        )}
      </div>
    )
  }
