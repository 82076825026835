import { Button, Modal } from 'antd'
import React, { FunctionComponent } from 'react'

import { PropertyDetailNote } from '../../utils/propertyDetailNoteHelpers'
import { useToggleState } from '../../utils/useToggleState'
import { PropertyDetailNoteEdit } from '../PropertyDetailNoteEdit/PropertyDetailNoteEdit'

export interface PropertyDetailNoteEditButtonProps {
  note: PropertyDetailNote
  refetch?: () => void
}

const buttonProps = { type: 'link' as const, size: 'small' as const }

export const PropertyDetailNoteEditButton: FunctionComponent<PropertyDetailNoteEditButtonProps> =
  props => {
    const [open, toggleOpen] = useToggleState()

    return (
      <div>
        <Button onClick={toggleOpen} {...buttonProps}>
          Editovat
        </Button>
        <Modal
          title="Editovat poznámku"
          open={open}
          onCancel={toggleOpen}
          footer={null}
          width={600}
          destroyOnClose
        >
          <div className="p-4">
            <PropertyDetailNoteEdit
              onCancel={toggleOpen}
              refetch={props.refetch}
              initialValues={props.note}
            />
          </div>
        </Modal>
      </div>
    )
  }
