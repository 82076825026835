import { Divider } from 'antd'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { PropertyUse } from '../../../common/foreclosureMonitoringTypes'
import { PropertyUseSelectionFilter } from '../PropertyUseSelectionFilter/PropertyUseSelectionFilter'
import { PropertyUseSelectionFooter } from '../PropertyUseSelectionFooter/PropertyUseSelectionFooter'
import { PropertyUseSelectionSearchBar } from '../PropertyUseSelectionSearchBar/PropertyUseSelectionSearchBar'
import { PropertyUseSelectionSelectAllButon } from '../PropertyUseSelectionSelectAllButon/PropertyUseSelectionSelectAllButon'

export interface PropertyUseSelectionProps {
  checkboxData: PropertyUse[]
  selectedValues: number[] | null
  toggleModalVisibility: () => void
  onSubmit: (selected: number[] | null) => void
}

export const PropertyUseSelection: FunctionComponent<PropertyUseSelectionProps> =
  ({ toggleModalVisibility, checkboxData, selectedValues, onSubmit }) => {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [filteredUseTypes, setFilteredUseTypes] = useState(checkboxData)
    const [checkedValues, setCheckedValues] = useState<number[]>(
      selectedValues || []
    )

    useEffect(() => {
      setCheckedValues(selectedValues || [])
    }, [selectedValues])

    const findUseType = useCallback(
      (codes: number[]) =>
        checkboxData.filter(useType => codes.includes(useType.kod)),
      [checkboxData]
    )

    const handleMatching = useCallback(
      (matchCodes: number[]) => {
        setFilteredUseTypes(findUseType(matchCodes))
      },
      [findUseType]
    )

    const checkFilteredCodes = useCallback(
      (filteredCodes: number[]) => {
        setCheckedValues(prevValues => {
          const newValues = [...prevValues]
          filteredCodes.forEach(code => {
            const index = newValues.indexOf(code)
            if (index === -1) {
              newValues.push(code)
            } else {
              newValues.splice(index, 1)
            }
          })
          return newValues
        })
        setSearchTerm('')
        setFilteredUseTypes(checkboxData)
      },
      [checkboxData]
    )

    return (
      <>
        <div className="flex space-x-4 mb-8">
          <PropertyUseSelectionSearchBar
            handleCheckCodes={checkFilteredCodes}
            checkboxData={checkboxData}
            handleMatching={handleMatching}
            setSearchTerm={setSearchTerm}
          />
          <PropertyUseSelectionSelectAllButon
            checkedValues={checkedValues}
            filteredUseTypes={filteredUseTypes}
            setCheckedValues={setCheckedValues}
          />
        </div>
        <Divider />
        <PropertyUseSelectionFilter
          filteredUseTypes={filteredUseTypes}
          setCheckedValues={setCheckedValues}
          checkedValues={checkedValues}
          searchTerm={searchTerm}
        />
        <Divider />
        <PropertyUseSelectionFooter
          totalCount={checkboxData.length}
          checkedValues={checkedValues}
          toggleModalVisibility={toggleModalVisibility}
          onSubmit={onSubmit}
        />
      </>
    )
  }
