import axios from 'axios'
import { useCallback } from 'react'

import { PROPERTY_TYPES } from '../../common/drmTypes'
import { PropertyDataTypes } from '../../redux/fetchAction'
import { useAppSelector } from '../../redux/hooks'
import { requestPropertyCrmDrmPath } from './paths'
import { useDispatchFetch } from './useDispatchFetch'

export const useFetchPropertyCrmDrm = () => {
  const tenantId = useAppSelector(state => state.myProfile.tenantId)
  const { dispatchError, dispatchFetching, dispatchSuccess } =
    useDispatchFetch()

  const setError = useCallback(() => {
    dispatchError(PropertyDataTypes.CRM)
    // DRM, LV and Timeline is directly dependent on the CRM response
    dispatchError(PropertyDataTypes.DRM)
    dispatchError(PropertyDataTypes.LV)
    dispatchError(PropertyDataTypes.LV_GEOMETRY)
    dispatchError(PropertyDataTypes.TIMELINE)
  }, [dispatchError])

  const fetchCrmDrm = useCallback(
    async (propertyId: string, propertyType?: PROPERTY_TYPES) => {
      if (!tenantId || !propertyId || !propertyType) return setError()

      const requestPath = requestPropertyCrmDrmPath(
        propertyType,
        tenantId,
        propertyId
      )

      dispatchFetching(PropertyDataTypes.CRM)
      dispatchFetching(PropertyDataTypes.DRM)
      const response = await axios.get(requestPath)
      if (response.status !== 200) return setError()

      const data = await response.data
      dispatchSuccess(PropertyDataTypes.DRM, { ...data, type: propertyType })
      dispatchSuccess(PropertyDataTypes.CRM, !!data)

      return {
        lv: data?.lv,
        cadastralCode: data?.katastralniUzemi?.kod,
        updateDate: data?.utcAktualizace,
      }
    },
    [dispatchFetching, dispatchSuccess, setError, tenantId]
  )

  return fetchCrmDrm
}
