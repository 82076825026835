import { Select } from 'antd'
import React, { FunctionComponent } from 'react'

import { LabelBulkEditOptions } from '../../utils/labelsTypes'

export interface LabelEditTypeSelectInputProps {
  initialValue?: LabelBulkEditOptions
  onChange?: (value: LabelBulkEditOptions) => void
}

export const LabelEditTypeSelectInput: FunctionComponent<LabelEditTypeSelectInputProps> =
  props => {
    const initialValue =
      props.initialValue !== undefined
        ? props.initialValue
        : LabelBulkEditOptions.Inherit
    const { Option } = Select

    return (
      <Select
        className="w-full"
        defaultValue={initialValue}
        onChange={props.onChange}
      >
        <Option value={LabelBulkEditOptions.Inherit}>Zachovat nastavení</Option>
        <Option value={LabelBulkEditOptions.Add}>Přidat vybrané</Option>
        <Option value={LabelBulkEditOptions.ReplaceBy}>
          Nahradit za vybrané
        </Option>
        <Option value={LabelBulkEditOptions.RemoveSelected}>
          Odebrat pouze vybrané
        </Option>
        <Option value={LabelBulkEditOptions.RemoveAll}>Odebrat vše</Option>
      </Select>
    )
  }
