import * as turf from '@turf/turf'
import { Feature, Point, Position } from 'geojson'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { GeoJSONSource, Layer, MapProps, Source } from 'react-map-gl'

import { AuctionMapRecord } from '../../../common/auctionMonitoringTypes'
import {
  AuctionListingFilter,
  useCadastreMapAuctionQuery,
} from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { CadastreMapAuctionClusterPopup } from '../CadastreMap/CadastreMapAuctionClusterPopup'
import { CadastreMapAuctionLayerControl } from '../CadastreMap/controlls/CadastreMapAuctionLayerControl/CadastreMapAuctionLayerControl'
import { useCadastreMapTheme } from '../CadastreMap/hooks/useCadastreMapTheme'
import { useMapWatch } from '../CadastreMap/hooks/useMapWatch'

export interface CadastreMapAuctionDisplayProps {
  initialViewState: Required<
    Pick<
      NonNullable<MapProps['initialViewState']>,
      'zoom' | 'latitude' | 'longitude'
    >
  >
  popupZoomThreshold?: number
}

export const CadastreMapAuctionDisplay: FunctionComponent<CadastreMapAuctionDisplayProps> =
  props => {
    const userId = useAppSelector(state => state.myProfile.userId)

    const popupZoomThreshold = props.popupZoomThreshold ?? 13
    const [theme] = useCadastreMapTheme()

    const [filter, setFilter] = useState({
      type: ['area', 'building', 'unit', 'unknown'],
      state: ['upcoming'],
      priceFrom: 0,
      priceTo: undefined,
    })

    const queryFilter = useMemo<AuctionListingFilter>(() => {
      const filterFormConditions: AuctionListingFilter['and'] = [
        { type: { in: filter.type } },
        { state: { in: filter.state } },
      ]
      if (typeof filter.priceFrom === 'number') {
        filterFormConditions.push({
          price: { greaterThanOrEqualTo: filter.priceFrom },
        })
      }
      if (typeof filter.priceTo === 'number') {
        filterFormConditions.push({
          price: { lessThanOrEqualTo: filter.priceTo },
        })
      }
      return { and: filterFormConditions }
    }, [filter])

    const [geojson] = useCadastreMapAuctionQuery({
      variables: { filter: queryFilter, accountUserId: userId },
    })
    const [enabled, setEnabled] = useState(true)

    const auctionsFeatureCollection = useMemo(() => {
      const nodes = (geojson.data?.allAuctionListings?.nodes ?? []) as any[]
      const features = nodes
        .filter(node => node.lat && node.long)
        .map(node => {
          const feature = turf.point(
            [Number.parseFloat(node.long), Number.parseFloat(node.lat)],
            node
          )
          return feature
        })
      return turf.featureCollection(features)
    }, [geojson])

    type Popup = {
      clusterId: number
      anchor: Position
      features: Feature<Point, AuctionMapRecord>[]
    }
    const [popups, setPopups] = useState<Popup[]>([])

    const [map, mapUpdateTrigger] = useMapWatch(['moveend', 'zoomend'])

    useEffect(() => {
      if (!map) return
      if (map.getZoom() < popupZoomThreshold) {
        setPopups([])
        return
      }
      const bounds = map.getBounds()
      if (!bounds) return
      const source = map.getSource<GeoJSONSource>('auctions')
      if (!source) return

      const getClusterLeaves = (clusterId: number) =>
        new Promise<Feature<Point, AuctionMapRecord>[]>((resolve, reject) =>
          source.getClusterLeaves(clusterId, 10, 0, (error, features) => {
            if (error) reject(error)
            else if (!features) resolve([])
            else resolve(features as Feature<Point, AuctionMapRecord>[])
          })
        )

      const fetchPopups = async () => {
        try {
          const auctionClusters = map.queryRenderedFeatures(undefined, {
            layers: ['auctions-cluster-circle'],
          }) as unknown as Feature<
            Point,
            AuctionMapRecord & { cluster_id: number }
          >[]
          const points = map.queryRenderedFeatures(undefined, {
            layers: ['auctions-non-clustered-point'],
          }) as unknown as Feature<Point, AuctionMapRecord & { id: number }>[]
          const clusterPopups = await Promise.all(
            auctionClusters
              .filter(cluster => cluster.properties?.cluster_id)
              .map(async cluster => {
                const clusterId = cluster.properties?.cluster_id
                const anchor = cluster.geometry?.coordinates
                const features = await getClusterLeaves(clusterId)
                return { clusterId, anchor, features }
              })
          )
          const pointPopups = points.map((pointFeature, index) => ({
            clusterId: pointFeature.properties?.id ?? index,
            anchor: pointFeature.geometry?.coordinates,
            features: [pointFeature],
          }))
          const popups = [...clusterPopups, ...pointPopups].reduce(
            (acc, popup) => {
              if (acc.some(p => p.clusterId === popup.clusterId)) return acc
              return [...acc, popup]
            },
            [] as Popup[]
          )
          setPopups(popups)
        } catch (error) {
          console.error('Error fetching cluster leaves:', error)
        }
      }

      fetchPopups()
    }, [map, mapUpdateTrigger, popupZoomThreshold])

    return (
      <>
        <CadastreMapAuctionLayerControl
          enabled={enabled}
          onEnabledChange={setEnabled}
          filter={filter}
          onFilterChange={newFilter => {
            map?.setCenter([
              props.initialViewState.longitude,
              props.initialViewState.latitude,
            ])
            map?.setZoom(props.initialViewState.zoom)
            setFilter(newFilter)
          }}
        />

        {enabled &&
          popups.map(popup => (
            <CadastreMapAuctionClusterPopup
              key={popup.clusterId}
              longitude={popup.anchor[0]}
              latitude={popup.anchor[1]}
              features={popup.features}
            />
          ))}

        <Source
          id="auctions"
          type="geojson"
          cluster
          data={auctionsFeatureCollection}
        />

        {/* Cluster circle */}
        <Layer
          id="auctions-cluster-circle"
          source="auctions"
          type="circle"
          filter={['has', 'point_count']}
          layout={{
            visibility: enabled ? 'visible' : 'none',
          }}
          paint={{
            'circle-color': '#FFC300',
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              20,
              100,
              30,
              750,
              40,
            ],
            'circle-stroke-width': 2,
            'circle-stroke-color': '#8f750e',
          }}
        />
        {/* Point circle */}
        <Layer
          id="auctions-non-clustered-point"
          source="auctions"
          type="circle"
          filter={['!', ['has', 'point_count']]}
          layout={{
            visibility: enabled ? 'visible' : 'none',
          }}
          paint={{
            'circle-color': '#FFC300',
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              10,
              10,
              6,
            ],
            'circle-stroke-width': 2,
            'circle-stroke-color': '#8f750e',
          }}
        />
        {/* Cluster text */}
        <Layer
          id="auctions-cluster-count-text"
          source="auctions"
          type="symbol"
          filter={['has', 'point_count']}
          layout={{
            visibility: enabled ? 'visible' : 'none',
            'text-field': '{point_count_abbreviated}',
            'text-size': 14,
            'text-allow-overlap': true,
          }}
          paint={{
            'text-color': theme.parcelClusterText,
          }}
        />
      </>
    )
  }
