import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import React, { FunctionComponent, useMemo, useRef, useState } from 'react'

import { useToggleState } from '../../utils/useToggleState'
import { CuzkMonitorRcBulkEditForm } from '../CuzkMonitorRcBulkEditForm/CuzkMonitorRcBulkEditForm'
import { RcTableItem } from '../CuzkMonitorRcTable/CuzkMonitorRcTable'

export interface CuzkMonitorRcBulkEditProps {
  items: RcTableItem[]
  refetch: () => void
}

export const CuzkMonitorRcBulkEdit: FunctionComponent<CuzkMonitorRcBulkEditProps> =
  props => {
    const [showHelp, toggleHelp] = useToggleState(false)
    const [loading, setLoading] = useState(false)
    const [open, toggleModal] = useToggleState(false)
    const approvalAttachmentTableRef = useRef<{ submit: () => void }>(null)

    const footer = useMemo(
      () => (
        <div className="flex justify-between">
          <Button
            icon={<InfoCircleOutlined />}
            type="link"
            onClick={toggleHelp}
          >
            {showHelp ? 'Skrýt nápovědu' : 'Zobrazit nápovědu'}
          </Button>
          <div>
            <Button onClick={toggleModal}>Zrušit</Button>
            <Button
              type="primary"
              onClick={() => approvalAttachmentTableRef.current?.submit()}
              loading={loading}
            >
              Uložit
            </Button>
          </div>
        </div>
      ),
      [toggleModal, toggleHelp, loading, showHelp]
    )

    return (
      <>
        <Button type="link" onClick={toggleModal}>
          Hromadná editace
        </Button>
        <Modal
          title={'Hromadná editace ' + props.items.length + ' záznamů'}
          open={open}
          onCancel={toggleModal}
          footer={footer}
        >
          <div className="mt-4">
            <CuzkMonitorRcBulkEditForm
              ref={approvalAttachmentTableRef}
              hideModal={toggleModal}
              refetch={props.refetch}
              selectedItems={props.items}
              setLoading={setLoading}
              showHelp={showHelp}
            />
          </div>
        </Modal>
      </>
    )
  }
