import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, FloatButton } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { propertyTimelineProps } from '../../utils/propertyTimelineHelpers'
import { PropertyDetailTimelineHelpButton } from '../PropertyDetailTimelineHelpButton/PropertyDetailTimelineHelpButton'

export interface PropertyDetailTimelineControllPanelProps {
  visibleTimeStart: number
  visibleTimeEnd: number
  setVisibleTime: React.Dispatch<React.SetStateAction<[number, number]>>
}

export const PropertyDetailTimelineControllPanel: FunctionComponent<PropertyDetailTimelineControllPanelProps> =
  props => {
    const zoom = useMemo(() => {
      const diff = (props.visibleTimeEnd - props.visibleTimeStart) / 8
      const outDisabled = propertyTimelineProps.maxZoom <= 12 * diff
      const inDisabled = propertyTimelineProps.minZoom >= 4 * diff
      return { inDisabled, outDisabled }
    }, [props.visibleTimeEnd, props.visibleTimeStart])

    const handleZoomOut = useCallback(() => {
      props.setVisibleTime(([prevStart, prevEnd]) => {
        const diff = (prevEnd - prevStart) / 8
        return propertyTimelineProps.maxZoom <= 12 * diff
          ? [prevStart, prevEnd]
          : [prevStart - diff, prevEnd + diff]
      })
    }, [props])

    const handleZoomIn = useCallback(() => {
      props.setVisibleTime(([prevStart, prevEnd]) => {
        const diff = (prevEnd - prevStart) / 8
        return propertyTimelineProps.minZoom >= 4 * diff
          ? [prevStart, prevEnd]
          : [prevStart + diff, prevEnd - diff]
      })
    }, [props])

    return (
      <FloatButton.Group
        shape="square"
        className="!flex !w-auto right-12 bottom-12 bg-white !z-[200]"
      >
        <PropertyDetailTimelineHelpButton />
        <Button
          size="large"
          type="link"
          icon={<MinusCircleOutlined />}
          disabled={zoom.outDisabled}
          onClick={handleZoomOut}
        />
        <Button
          size="large"
          type="link"
          icon={<PlusCircleOutlined />}
          disabled={zoom.inDisabled}
          onClick={handleZoomIn}
        />
      </FloatButton.Group>
    )
  }
