import { ColorPalleteKey } from '../../app/utils/collorPallete'
import { IconPalleteKey } from '../../app/utils/iconPallete'

export enum LabelBulkEditOptions {
  Inherit = 'inherit',
  Add = 'add',
  ReplaceBy = 'replaceBy',
  RemoveSelected = 'removeSelected',
  RemoveAll = 'removeAll',
}

export interface LabelType {
  id: string
  name: string
  description?: string
  colorKey?: ColorPalleteKey
  iconKey?: IconPalleteKey
}
