import { EyeOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { propertyDetailPath } from '../../utils/paths'
import { useSetPropertyDrawer } from '../../utils/useSetPropertyDrawer'

interface PropertyDetailLinkProps {
  text: React.ReactNode
  propertyId: string
  propertyType: PROPERTY_TYPES
  isActive?: boolean
  target?: React.HTMLAttributeAnchorTarget
  drawerTarget?: boolean
}

const label = {
  [PROPERTY_TYPES.AREA]: 'parcely',
  [PROPERTY_TYPES.BUILDING]: 'budovy',
  [PROPERTY_TYPES.UNIT]: 'jednotky',
}

export const PropertyDetailLink: React.FC<PropertyDetailLinkProps> = props => {
  const setDrawer = useSetPropertyDrawer()

  if (props.isActive) {
    return (
      <Tooltip
        className="text-gray-400"
        title={`Detail této ${label[props.propertyType]} si právě prohlížíte`}
      >
        <EyeOutlined /> {props.text}
      </Tooltip>
    )
  }

  if (props.drawerTarget) {
    return (
      <a
        href="#"
        onClick={() => setDrawer(props.propertyId, props.propertyType)}
        title={`Zobrazit detail ${
          label[props.propertyType]
        } v postranním panelu`}
      >
        {props.text}
      </a>
    )
  }

  return (
    <Link
      title={`Zobrazit detail ${label[props.propertyType]}`}
      to={propertyDetailPath(props.propertyType, props.propertyId)}
      target={props.target}
    >
      {props.text}
    </Link>
  )
}
