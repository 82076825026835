import { Button } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import { PropertyUseSelectionFooterCounter } from '../PropertyUseSelectionFooterCounter/PropertyUseSelectionFooterCounter'

export interface PropertyUseSelectionFooterProps {
  totalCount: number
  checkedValues: number[]
  toggleModalVisibility: () => void
  onSubmit: (selected: number[] | null) => void
}

export const PropertyUseSelectionFooter: FunctionComponent<PropertyUseSelectionFooterProps> =
  ({ totalCount, checkedValues, onSubmit, toggleModalVisibility }) => {
    const handleSubmit = useCallback(() => {
      onSubmit(totalCount === checkedValues.length ? null : checkedValues)
      toggleModalVisibility()
    }, [totalCount, onSubmit, checkedValues, toggleModalVisibility])

    return (
      <div className="flex justify-end items-center space-x-2">
        <PropertyUseSelectionFooterCounter
          selectedCount={checkedValues?.length || 0}
          totalCount={totalCount}
        />

        <Button onClick={toggleModalVisibility}>Zrušit</Button>
        <Button
          onClick={handleSubmit}
          type="primary"
          disabled={!checkedValues.length}
        >
          Uložit nastavení
        </Button>
      </div>
    )
  }
