import { Button } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { PropertyUse } from '../../../common/foreclosureMonitoringTypes'

export interface PropertyUseSelectionSelectAllButonProps {
  setCheckedValues: (value: number[]) => void
  checkedValues: number[]
  filteredUseTypes: PropertyUse[]
}

export const PropertyUseSelectionSelectAllButon: FunctionComponent<PropertyUseSelectionSelectAllButonProps> =
  props => {
    const { areAllChecked, areAllDisabled } = useMemo(() => {
      const enabledUseTypes = props.filteredUseTypes.filter(
        useType => !useType.disabled
      )
      const areAllChecked =
        props.checkedValues?.length === enabledUseTypes.length

      const areAllDisabled = props.filteredUseTypes.every(
        useType => !!useType.disabled
      )

      return { areAllChecked, areAllDisabled }
    }, [props.checkedValues, props.filteredUseTypes])

    const handleSelectAll = useCallback(() => {
      const newCheckedValues: number[] = []
      props.filteredUseTypes.forEach(region => {
        !region.disabled && newCheckedValues.push(region.kod)
      })
      props.setCheckedValues(newCheckedValues)
    }, [props])

    const handleDeselectAll = useCallback(() => {
      props.setCheckedValues([])
    }, [props])

    return (
      <Button
        type="primary"
        onClick={areAllChecked ? handleDeselectAll : handleSelectAll}
        ghost={areAllChecked}
        disabled={areAllDisabled}
      >
        {areAllChecked ? 'Odebrat všechna využití' : 'Vybrat všechna využití'}
      </Button>
    )
  }
