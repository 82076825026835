import { MenuOutlined } from '@ant-design/icons'
import { Button, Dropdown, MenuProps, message, Popconfirm } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { useDeleteLabelMutation } from '../../../graphql/generated'
import { clickTrigger } from '../../utils/layoutConst'
import { labelDetailPath } from '../../utils/paths'
import { EditLabelButton } from '../EditLabelButton/EditLabelButton'
import { LabelTableItem } from '../LabelTable/LabelTable'

export interface LabelTableActionProps {
  refetch?: () => void
  record: LabelTableItem
}

export const LabelTableAction: FunctionComponent<LabelTableActionProps> =
  props => {
    const [, deleteLabelById] = useDeleteLabelMutation()

    const handleDeleteLabel = useCallback(() => {
      deleteLabelById({ id: props.record.id })
        .then(() => {
          message.success('Štítek byl smazán.')
        })
        .catch(() => {
          message.error('Štítek se nepodařilo smazat.')
        })
    }, [deleteLabelById, props.record.id])

    const menuProps = useMemo<MenuProps>(
      () => ({
        items: [
          {
            key: 'edit',
            className: '!p-0',
            label: (
              <EditLabelButton
                initialLabel={props.record}
                refetch={props.refetch}
                link
              />
            ),
          },
          {
            key: 'delete',
            className: '!p-0',
            label: (
              <Popconfirm
                title={`Opravdu chcete smazat štítek "${props.record.name}"?`}
                onConfirm={handleDeleteLabel}
                okText="Smazat štítek"
                okButtonProps={{ danger: true }}
                cancelText="Zrušit"
              >
                <Button type="link" danger className="w-full !text-left">
                  Smazat
                </Button>
              </Popconfirm>
            ),
          },
        ],
      }),
      [handleDeleteLabel, props.record, props.refetch]
    )

    return (
      <Dropdown.Button
        icon={<MenuOutlined />}
        menu={menuProps}
        arrow
        type="link"
        trigger={clickTrigger}
      >
        <Link to={labelDetailPath(props.record.id)}>Zobrazit detail</Link>
      </Dropdown.Button>
    )
  }
