import { QuestionCircleOutlined } from '@ant-design/icons'
import { Icon } from '@iconify/react'
import { Tooltip } from 'antd'
import React from 'react'

import { AUCTION_STATES, AUCTION_TYPES } from '../../common/auctionScrapeTypes'

export const auctionTypeOptions = [
  {
    value: AUCTION_TYPES.AREA,
    label: (
      <div className="flex items-center align-middle space-x-2">
        <Icon icon="mdi:land-plots-marker" />
        <span>Pozemky</span>
      </div>
    ),
  },
  {
    value: AUCTION_TYPES.BUILDING,
    label: (
      <div className="flex items-center align-middle space-x-2">
        <Icon icon="fluent:building-32-filled" />
        <span>Budovy</span>
      </div>
    ),
  },
  {
    value: AUCTION_TYPES.UNIT,
    label: (
      <div className="flex items-center align-middle space-x-2">
        <Icon icon="bi:building-fill-add" />
        <span>Jednotky</span>
      </div>
    ),
  },
  {
    value: AUCTION_TYPES.UNKNOWN,
    label: (
      <div className="flex items-center align-middle space-x-2">
        <Icon icon="ri:question-line" />
        <span>Ostatní</span>
      </div>
    ),
  },
]

export const auctionStateOptions = [
  {
    value: AUCTION_STATES.UPCOMING,
    label: 'Připravované',
  },
  {
    value: AUCTION_STATES.ENDED,
    label: 'Ukončené',
  },
]

const tooltipProps = {
  overlayInnerStyle: { width: '350px' },
  trigger: 'click',
  className: 'cursor-help hover:opacity-70',
}

const helpIcon = <QuestionCircleOutlined className="text-gray-400" />

export const auctionFormHelp = {
  type: (
    <div className="mb-2">
      <div>
        &quot;Ostatní&quot; = předměty dražby, u kterých se nepodařilo zjistit
        typ nemovitosti nebo se nedaly jednoznačně kategorizovat.
      </div>
    </div>
  ),

  state: (
    <div className="mb-2">
      <div>
        &quot;Připravované&quot; = vystavené dražby s uvedeným datem zahájení.
      </div>
      <div>
        &quot;Ukončené&quot; = proběhlé dražby (nemuselo nutně dojít k prodeji).
      </div>
      <div>Je nutné vybrat alespoň jeden stav. </div>
    </div>
  ),

  price: (
    <div className="mb-2">
      <div>Nastavení rozsahu vyvolávací/dosažené ceny.</div>
      <div>
        Lze filtrovat jednotlivě, v kombinaci &quot;Od - Do&quot; či vůbec
      </div>
      <div>
        Záznamy, které nemají evidovanou cenu, se při nastavení hodnoty
        &quot;Od&quot; nezobrazí.
      </div>
    </div>
  ),
}

export const auctionMonitoringFilterLabels = {
  state: (
    <Tooltip {...tooltipProps} title={auctionFormHelp.state}>
      Stav {helpIcon}
    </Tooltip>
  ),
  price: (
    <Tooltip {...tooltipProps} title={auctionFormHelp.price}>
      Cena {helpIcon}
    </Tooltip>
  ),
}
