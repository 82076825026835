import React, { FunctionComponent } from 'react'

import { MAPBOX_ACCESS_TOKEN } from '../../../ini.json'
import { AuctionDetailDrawer } from '../components/AuctionDetailDrawer/AuctionDetailDrawer'
import { CadastreMap } from '../components/CadastreMap/CadastreMap'
import { CadastreMapProvider } from '../components/CadastreMap/CadastreMapProvider'

export const AuctionMap: FunctionComponent = () => {
  return (
    <>
      <div className="relative flex flex-1 flex-col -m-4">
        <CadastreMapProvider>
          <CadastreMap showAuctions mapboxAccessToken={MAPBOX_ACCESS_TOKEN} />
        </CadastreMapProvider>
      </div>
      <AuctionDetailDrawer />
    </>
  )
}
