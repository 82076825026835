import { useContext } from 'react'

import {
  CadastreMapContext,
  CadastreMapContextThemeStateType,
} from '../CadastreMapProvider'

export const useCadastreMapTheme = (): [
  CadastreMapContextThemeStateType['theme'],
  CadastreMapContextThemeStateType['setTheme']
] => {
  const context = useContext(CadastreMapContext)
  if (context === undefined) {
    throw new Error(
      'useCadastreMapTheme must be used within a CadastreMapProvider'
    )
  }
  return [context.theme, context.setTheme]
}
