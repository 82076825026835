export enum RegisterGroup {
  CEE = 'CEE',
  CUZK = 'ČÚZK',
  EPO = 'EPO',
  LV = 'LV',
  ARES = 'ARES',
  ISIR = 'ISIR',
  GOOGLE = 'Google',
}

export enum Register {
  CeeIco = 'CEE pomocí IČ',
  CeeRc = 'CEE pomocí rodného čísla',
  CeeBirthDate = 'CEE pomocí jména a data narození',
  CuzkOsIco = 'ČÚZK subjekt pomocí IČ',
  CuzkOsRc = 'ČÚZK subjekt pomocí rodného čísla',
  CuzkOsBirthDate = 'ČÚZK subjekt pomocí jména a data narození',
  EpoIco = 'Evidence práv osoby pomocí IČ',
  EpoRc = 'Evidence práv osoby pomocí rodného čísla',
  EpoBirthDate = 'Evidence práv osoby pomocí jména a data narození',
  LvCode = 'List vlastnictví pomocí čísla LV a katastrálního území',
  AresIco = 'ARES pomocí IČ',
  AresName = 'ARES pomocí jména a příjmení',
  AresCompanyName = 'ARES pomocí názvu firmy',
  IsirIco = 'ISIR pomocí IČ',
  IsirRc = 'ISIR pomocí rodného čísla',
  IsirName = 'ISIR pomocí jména a příjmení',
  IsirCompanyName = 'ISIR pomocí názvu firmy',
  Google = 'Vyhledat na Google.com',
}

export enum Identifier {
  ICO = 'IČ',
  RC = 'Rodné číslo',
  DESCRIPTION = 'Popis',
  COMPANY_NAME = 'Název firmy',
  FIRST_NAME = 'Křestní jméno',
  LAST_NAME = 'Příjmení',
  FULL_NAME = 'Celé jméno',
  BIRTH_DATE = 'Datum narození',
  TEXT_SEARCH = 'Vyhledat text',
  DATABOX_ID = 'Kód datové schránky',
  EMAIL = 'E-mail',
  PHONE = 'Telefon',
  KATUZE_KOD = 'Kat. území kód',
  LV_CISLO = 'Číslo LV',
  CEE_BITRH_DATE = 'CEE_BITRH_DATE',
  CEE_ICO = 'CEE_ICO',
  CEE_RC = 'CEE_RC',
  CUZKOS_BITRH_DATE = 'CUZKOS_BITRH_DATE',
  CUZKOS_ICO = 'CUZKOS_ICO',
  CUZKOS_RC = 'CUZKOS_RC',
  EPO_ICO = 'EPO_ICO',
  EPO_RC = 'EPO_RC',
  EPO_BITRH_DATE = 'EPO_BITRH_DATE',
  LV_CODE = 'LV_CODE',
  ARES_NAME = 'ARES_NAME',
  ARES_COMPANY_NAME = 'ARES_COMPANY_NAME',
  ARES_ICO = 'ARES_ICO',
  ISIR_RC = 'ISIR_RC',
  ISIR_ICO = 'ISIR_ICO',
  ISIR_COMPANY_NAME = 'ISIR_COMPANY_NAME',
  ISIR_NAME = 'ISIR_NAME',
}

export type IdentifierValues = Partial<{
  [Identifier.ICO]: string
  [Identifier.RC]: string
  [Identifier.DESCRIPTION]: string
  [Identifier.COMPANY_NAME]: string
  [Identifier.FIRST_NAME]: string
  [Identifier.LAST_NAME]: string
  [Identifier.FULL_NAME]: string
  [Identifier.BIRTH_DATE]: string
  [Identifier.TEXT_SEARCH]: string
  [Identifier.DATABOX_ID]: string
  [Identifier.EMAIL]: string
  [Identifier.PHONE]: string
  [Identifier.KATUZE_KOD]: string
  [Identifier.LV_CISLO]: string
}>

export const RegisterIdentifierMap: Record<Register, Identifier> = {
  [Register.CeeIco]: Identifier.ICO,
  [Register.CeeRc]: Identifier.RC,
  [Register.CeeBirthDate]: Identifier.CEE_BITRH_DATE,
  [Register.CuzkOsIco]: Identifier.ICO,
  [Register.CuzkOsRc]: Identifier.RC,
  [Register.CuzkOsBirthDate]: Identifier.CUZKOS_BITRH_DATE,
  [Register.EpoIco]: Identifier.ICO,
  [Register.EpoRc]: Identifier.RC,
  [Register.EpoBirthDate]: Identifier.EPO_BITRH_DATE,
  [Register.LvCode]: Identifier.LV_CODE,
  [Register.AresIco]: Identifier.ICO,
  [Register.AresName]: Identifier.ARES_NAME,
  [Register.AresCompanyName]: Identifier.COMPANY_NAME,
  [Register.IsirIco]: Identifier.ICO,
  [Register.IsirRc]: Identifier.RC,
  [Register.IsirName]: Identifier.ISIR_NAME,
  [Register.IsirCompanyName]: Identifier.COMPANY_NAME,
  [Register.Google]: Identifier.TEXT_SEARCH,
}

export const RegisterGroupMap: Record<RegisterGroup, Register[]> = {
  [RegisterGroup.CEE]: [Register.CeeIco, Register.CeeRc, Register.CeeBirthDate],
  [RegisterGroup.CUZK]: [
    Register.CuzkOsIco,
    Register.CuzkOsRc,
    Register.CuzkOsBirthDate,
  ],
  [RegisterGroup.EPO]: [Register.EpoIco, Register.EpoRc, Register.EpoBirthDate],
  [RegisterGroup.LV]: [Register.LvCode],
  [RegisterGroup.ARES]: [
    Register.AresIco,
    Register.AresName,
    Register.AresCompanyName,
  ],
  [RegisterGroup.ISIR]: [
    Register.IsirIco,
    Register.IsirRc,
    Register.IsirName,
    Register.IsirCompanyName,
  ],
  [RegisterGroup.GOOGLE]: [Register.Google],
}
