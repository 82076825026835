import { Tooltip } from 'antd'
import React, { FunctionComponent } from 'react'

import { PropertyDetailSubjectsTableItem as SubjectItem } from '../../../common/propertyDetailTypes'
import { Day } from '../DateFormat/DateFormat'

export interface PropertyDetailSubjectsTableCellEngagementProps {
  engagement: SubjectItem['angazma']
}

export const PropertyDetailSubjectsTableCellEngagement: FunctionComponent<PropertyDetailSubjectsTableCellEngagementProps> =
  props => {
    const title = (
      <div>
        {props.engagement?.map((engagement, index) => (
          <div
            key={index}
            className={
              engagement.datumDo ? 'table-row text-gray-400' : 'table-row'
            }
          >
            <span className="table-cell whitespace-nowrap">
              {engagement.nazevAngazma}
            </span>

            <Day
              prefix="Od: "
              className="table-cell whitespace-nowrap pl-2"
              noData
            >
              {engagement.datumOd}
            </Day>
            <Day
              prefix="Do: "
              className="table-cell whitespace-nowrap pl-2"
              noData
            >
              {engagement.datumDo}
            </Day>
          </div>
        ))}
      </div>
    )

    const uniqueAngazma = new Map()
    props.engagement?.forEach(engagement => {
      uniqueAngazma.set(engagement.nazevAngazma, engagement)
    })

    return (
      <Tooltip
        title={title}
        overlayClassName="!max-w-none"
        className="flex space-x-2"
      >
        {Array.from(uniqueAngazma.values()).map(engagement =>
          engagement.datumDo ? (
            <span
              key={engagement.nazevAngazma}
              className="text-gray-400 line-through"
            >
              {engagement.nazevAngazma}
            </span>
          ) : (
            <span key={engagement.nazevAngazma}>{engagement.nazevAngazma}</span>
          )
        )}
      </Tooltip>
    )
  }
