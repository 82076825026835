import { Icon } from '@iconify/react'
import { Tooltip } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { CeeOffice } from '../../../graphql/generated'
import { CopyButton } from '../CopyButton/CopyButton'
import { ExternalLink } from '../ExternalLink/ExternalLink'

export interface CeeOfficeTooltipProps {
  user: Partial<CeeOffice> | undefined | null
  text?: string
  thumbnail?: boolean
}

export const CeeOfficeTooltip: FunctionComponent<CeeOfficeTooltipProps> =
  props => {
    const CopyButtonProps = useMemo(
      () => ({
        copyText: `${props.user?.name || ''}\n${
          props.user?.address + '\n' || ''
        }\n${props.user?.ico ? 'IČ: ' + props.user?.ico + '\n' : ''}${
          props.user?.phone ? 'Tel: ' + props.user?.phone + '\n' : ''
        }${props.user?.email ? 'E-mail: ' + props.user?.email + '\n' : ''}${
          props.user?.web ? 'Web: ' + props.user?.web + '\n' : ''
        }${
          props.user?.databox
            ? 'Dat. Schránka: ' + props.user?.databox + '\n'
            : ''
        }`,
        buttonsProps: {
          size: 'small' as const,
          className: 'mt-2 w-full',
        },
        copyButtonText: 'Kopírovat údaje',
      }),
      [props.user]
    )

    return (
      <Tooltip
        destroyTooltipOnHide
        placement="top"
        overlayClassName="py-4 min-w-min !max-w-none"
        trigger={props.thumbnail ? 'click' : 'hover'}
        zIndex={10}
        title={
          props.user && (
            <div className="p-3">
              <div className="flex space-x-4">
                <div className="flex flex-col">
                  <div className="mb-2">
                    <div className="font-semibold pb-2 mb-1 border-b border-dashed border-gray-500">
                      {props.user?.name}
                    </div>
                    {props.user?.address && (
                      <div>
                        {props.user.address}
                        <a
                          href={
                            'https://www.google.com/maps/search/' +
                            props.user.address
                          }
                          target="_blank"
                          rel="noreferrer"
                          title="Otevřít mapu v novém panelu"
                        >
                          <Icon
                            className="ml-1 inline"
                            icon="tabler:map-pin"
                            width="13"
                          />
                        </a>
                      </div>
                    )}
                    {props.user?.ico && <div>IČ: {props.user.ico}</div>}
                    {props.user?.dic && <div>DIČ: {props.user.dic}</div>}
                  </div>
                  <div>
                    {props.user?.phone && <div>Tel: {props.user.phone}</div>}
                    {props.user?.email && <div>E-mail: {props.user.email}</div>}
                    {props.user?.web && (
                      <div>
                        Web: <ExternalLink url={props.user.web} />
                      </div>
                    )}
                    {props.user?.databox && (
                      <div>Dat. schránka: {props.user.databox}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="text-center mt-1 w-full relative">
                <CopyButton {...CopyButtonProps} />
              </div>
            </div>
          )
        }
        className={`underline decoration-dashed decoration-gray-500 underline-offset-4 ${
          props.thumbnail &&
          'hover:opacity-80 hover:decoration-gray-500/70 transition-opacity cursor-help'
        }`}
      >
        {props.text || props.user?.name}
      </Tooltip>
    )
  }
