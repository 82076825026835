import { Form, InputNumber, Radio, Select } from 'antd'
import React, { FunctionComponent, useState } from 'react'

import { MultiCheckboxSelect } from '../MultiCheckboxSelect/MultiCheckboxSelect'

export enum BUILDING_AREA_TYPE {
  CELKOVA = 'pl_celkova',
  UZITNA = 'pl_uzitna',
}

export interface PriceEstimateBuildingFragmentProps {
  buildingAreaType?: BUILDING_AREA_TYPE.CELKOVA | BUILDING_AREA_TYPE.UZITNA
}

export const PriceEstimateBuildingFragment: FunctionComponent<PriceEstimateBuildingFragmentProps> =
  props => {
    const { Option } = Select
    const [plochaBudovy, setPlochaBudovy] = useState(
      props.buildingAreaType || BUILDING_AREA_TYPE.CELKOVA
    )

    const selectBefore = (
      <Select defaultValue={plochaBudovy} onSelect={setPlochaBudovy}>
        <Option value={BUILDING_AREA_TYPE.CELKOVA}>Celková</Option>
        <Option value={BUILDING_AREA_TYPE.UZITNA}>Užitná</Option>
      </Select>
    )
    return (
      <>
        <Form.Item
          name="typ_objektu"
          label="Typ objektu"
          rules={[
            {
              required: true,
              message: 'Musíte vybrat typ objektu',
            },
          ]}
        >
          <Select className="w-full">
            <Option value={0}>Rodinný dům</Option>
            <Option value={2}>Nájemní dům</Option>
            <Option value={3}>Vila</Option>
            <Option value={4}>Chalupa</Option>
            <Option value={5}>Chata</Option>
            <Option value={255}>Jiné</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name={plochaBudovy}
          label="Plocha budovy"
          rules={[
            {
              required: true,
              message: 'Musíte zadat užitnou, nebo celkovou plochu.',
            },
          ]}
        >
          <InputNumber
            className="w-full"
            addonBefore={selectBefore}
            addonAfter="m²"
            min={1}
          />
        </Form.Item>

        <Form.Item
          name="pl_pozemku"
          label="Plocha pozemku"
          rules={[
            {
              required: true,
              message: 'Plocha pozemku musí být vyplněna',
            },
          ]}
        >
          <InputNumber className="w-full" addonAfter="m²" min={1} />
        </Form.Item>

        <Form.Item name="nadzemni_podlazi" label="Počet nadzemních podlaží">
          <InputNumber className="w-full" />
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) => prev.typ_objektu !== curr.typ_objektu}
        >
          {({ getFieldValue }) => {
            if (
              getFieldValue('typ_objektu') === 2 ||
              getFieldValue('typ_objektu') === 255
            ) {
              return (
                <Form.Item name="pocet_bytu" label="Počet bytových jednotek">
                  <InputNumber className="w-full" />
                </Form.Item>
              )
            }
            return
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) => prev.typ_objektu !== curr.typ_objektu}
        >
          {({ getFieldValue }) => {
            if (
              getFieldValue('typ_objektu') === 0 ||
              getFieldValue('typ_objektu') === 3
            ) {
              return (
                <Form.Item
                  name="poloha_domu"
                  label="Umístění domu"
                  rules={[
                    {
                      required: true,
                      message: 'Vyberte umístění domu',
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={0}>Samostatný</Radio>
                    <Radio value={1}>V bloku</Radio>
                    <Radio value={2}>Rohový</Radio>
                    <Radio value={2}>Řadový</Radio>
                  </Radio.Group>
                </Form.Item>
              )
            }
            return
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) => prev.typ_objektu !== curr.typ_objektu}
        >
          {({ getFieldValue }) => {
            if (
              getFieldValue('typ_objektu') === 4 ||
              getFieldValue('typ_objektu') === 5
            ) {
              return (
                <Form.Item name="druh_konstrukce" label="Konstrukce">
                  <Select className="w-full">
                    <Option value={0}>Cihlová</Option>
                    <Option value={1}>Panelová</Option>
                    <Option value={2}>Skeletová</Option>
                    <Option value={3}>Smíšená</Option>
                    <Option value={4}>Dřevěná</Option>
                    <Option value={5}>Kamenná</Option>
                    <Option value={6}>Montovaná</Option>
                  </Select>
                </Form.Item>
              )
            }
            return
          }}
        </Form.Item>

        <Form.Item
          name="enb_trida"
          label="ENB třída"
          rules={[
            {
              required: true,
              message: 'Vyberte štítek energetické náročnosti',
            },
          ]}
        >
          <Select className="w-full">
            <Option value={0}>A</Option>
            <Option value={1}>B</Option>
            <Option value={2}>C</Option>
            <Option value={3}>D</Option>
            <Option value={4}>E</Option>
            <Option value={5}>F</Option>
            <Option value={6}>G</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="reduced_stav"
          label="Stav nemovitosti"
          rules={[
            {
              required: true,
              message: 'Vyberte stav nemovitosti',
            },
          ]}
        >
          <Select className="w-full">
            <Option value={0}>Výborný</Option>
            <Option value={1}>Velmi dobrý</Option>
            <Option value={2}>Dobrý</Option>
            <Option value={3}>Před rekonstrukcí</Option>
            <Option value={4}>Špatný</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="zarizeno"
          label="Zařízeno"
          rules={[
            {
              required: true,
              message: 'Musíte uvést, zda je objekt zařízen',
            },
          ]}
        >
          <Radio.Group>
            <Radio value={2}>Ne</Radio>
            <Radio value={0}>Ano</Radio>
            <Radio value={1}>Částečně</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) => prev.typ_objektu !== curr.typ_objektu}
        >
          {({ getFieldValue }) => {
            return (
              <Form.Item
                name="object_flags_gully"
                label="Kanalizace"
                rules={[
                  {
                    required: true,
                    message: 'Vyberte typ kanalizace',
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={0}>Neuvedeno</Radio>
                  <Radio value={0x00000800}>Veřejná</Radio>
                  <Radio value={0x00000400}>ČOV</Radio>

                  {(getFieldValue('typ_objektu') === 4 ||
                    getFieldValue('typ_objektu') === 5) && (
                    <Radio value={0x00000200}>Septik/žumpa</Radio>
                  )}
                </Radio.Group>
              </Form.Item>
            )
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) => prev.typ_objektu !== curr.typ_objektu}
        >
          {({ getFieldValue }) => {
            return (
              <Form.Item
                name="object_flags_heating"
                label="Vytápění"
                rules={[
                  {
                    required: true,
                    message: 'Vyberte způsob vytápění',
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={0}>Neuvedeno</Radio>
                  <Radio value={0x01000000}>Ústřední</Radio>
                  {getFieldValue('typ_objektu') !== 4 &&
                    getFieldValue('typ_objektu') !== 5 && (
                      <Radio value={0x02000000}>Plynové</Radio>
                    )}
                  <Radio value={0x04000000}>Elektrické</Radio>
                  <Radio value={0x08000000}>Tuhá paliva</Radio>
                </Radio.Group>
              </Form.Item>
            )
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) => prev.typ_objektu !== curr.typ_objektu}
        >
          {({ getFieldValue }) => {
            const options = [{ label: 'Bazén', value: 0x00400000 }]
            if (
              getFieldValue('typ_objektu') === 4 ||
              getFieldValue('typ_objektu') === 5
            ) {
              options.push({
                label: 'Voda - místní zdroj',
                value: 0x00000002,
              })
              options.push({ label: 'Elektřina - 400 V', value: 0x00000010 })
            }

            return (
              <MultiCheckboxSelect
                inline
                options={options}
                label="Doplňky"
                name={'object_flags'}
              />
            )
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) => prev.typ_objektu !== curr.typ_objektu}
        >
          {({ getFieldValue }) => {
            if (
              getFieldValue('typ_objektu') === 4 ||
              getFieldValue('typ_objektu') === 5
            ) {
              return (
                <Form.Item name="prislusenstvi_fl" label="Příslušenství">
                  <MultiCheckboxSelect
                    options={[
                      { label: 'Terasa', value: 0x00000004 },
                      { label: 'Garáž', value: 0x00000010 },
                    ]}
                    name={'prislusenstvi_fl'}
                  />
                </Form.Item>
              )
            }
            return
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) => prev.typ_objektu !== curr.typ_objektu}
        >
          {({ getFieldValue }) => {
            if (
              getFieldValue('typ_objektu') !== 4 &&
              getFieldValue('typ_objektu') !== 5
            ) {
              return (
                <Form.Item name="reduced_flags" label="Příslušenství">
                  <MultiCheckboxSelect
                    inline
                    options={[
                      { label: 'Balkón, lodžie nebo terasa', value: 0x0020 },
                    ]}
                    name={'reduced_flags'}
                  />
                </Form.Item>
              )
            }
            return
          }}
        </Form.Item>
      </>
    )
  }
