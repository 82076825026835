import { Icon } from '@iconify/react'
import { Tooltip } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import {
  ColorPalleteDeafultOption,
  ColorPalleteKey,
  ColorPalleteOptions,
} from '../../utils/collorPallete'
import {
  IconPalleteDeafultOption,
  IconPalleteKey,
  IconPalleteOptions,
} from '../../utils/iconPallete'

export interface LabelProps {
  name?: string
  description?: string
  colorKey?: ColorPalleteKey
  iconKey?: IconPalleteKey
  disableTooltip?: boolean
}

export const Label: FunctionComponent<LabelProps> = props => {
  const hasTooltip = props.name && props.description && !props.disableTooltip

  const tooltipTitle = useMemo(
    () => (
      <div>
        <div>{props.name}</div>
        <div className="text-gray-400">{props.description}</div>
      </div>
    ),
    [props.name, props.description]
  )

  const label = useMemo(
    () => (
      <span
        className={
          ColorPalleteOptions[props.colorKey || ColorPalleteDeafultOption] +
          ' border rounded-sm px-2 py-[2px] inline text-xs font-normal max-w-[120px] truncate'
        }
      >
        <Icon
          icon={IconPalleteOptions[props.iconKey || IconPalleteDeafultOption]}
          width="12"
          className="inline-block mr-2 min-w-[12px] mb-[-2px]"
        />
        <span className="text-ellipsis overflow-hidden">
          {props.name || 'Ukázka štítku'}
        </span>
      </span>
    ),
    [props.colorKey, props.iconKey, props.name]
  )
  if (hasTooltip) return <Tooltip title={tooltipTitle}>{label}</Tooltip>
  return label
}
