import { Button, message, Popconfirm } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import { useDeletePostserviceSentLetterByIdMutation } from '../../../graphql/generated'
import { PostServiceSendModalButton } from '../PostServiceSendModalButton/PostServiceSendModalButton'

export interface PostServiceSentTableActionProps {
  recipientsCount: number
  letterId: string
  refetch: () => void
}

export const PostServiceSentTableAction: FunctionComponent<PostServiceSentTableActionProps> =
  props => {
    const [, deleteLetter] = useDeletePostserviceSentLetterByIdMutation()

    const setDeleteRecipientList = useCallback(() => {
      deleteLetter({ id: props.letterId, deletedAt: new Date() })
        .then(() => {
          message.success('Korespondence byla odstraněna')
          props.refetch()
        })
        .catch(() => {
          message.error('Korespondenci se nepodařilo odstranit')
        })
    }, [deleteLetter, props])

    return (
      <div className="flex space-x-2">
        <PostServiceSendModalButton
          recipientsCount={props.recipientsCount}
          refetch={props.refetch}
          letterId={props.letterId}
        />
        <Popconfirm
          okText="Odebrat"
          cancelText="Zpět"
          title="Opravdu chcete odstranit korespondenci připravenou k odeslání?"
          onConfirm={setDeleteRecipientList}
        >
          <Button type="primary" danger ghost>
            Odstranit
          </Button>
        </Popconfirm>
      </div>
    )
  }
