import { Input, InputRef, Tooltip } from 'antd'
import React, {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { PropertyUse } from '../../../common/foreclosureMonitoringTypes'

export interface PropertyUseSelectionSearchBarProps {
  checkboxData: PropertyUse[]
  handleCheckCodes: (matchCodes: number[]) => void
  handleMatching: (matchCodes: number[]) => void
  setSearchTerm: (value: string) => void
}

const findMatches = (
  data: PropertyUse[],
  searchTerm: string
): PropertyUse[] => {
  const searchTermLowerCase = searchTerm.toLowerCase()
  return data.filter(
    item =>
      !item.disabled && item.nazev.toLowerCase().includes(searchTermLowerCase)
  )
}

export const PropertyUseSelectionSearchBar: FunctionComponent<PropertyUseSelectionSearchBarProps> =
  memo(({ checkboxData, handleCheckCodes, handleMatching, setSearchTerm }) => {
    const [tooltipContent, setTooltipContent] = useState<string>('')
    const [value, setValue] = useState<string>('')
    const inputRef = useRef<InputRef>(null)

    const handleSearch = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value
        const matchingItems = findMatches(checkboxData, searchValue)

        if (matchingItems.length === 1) {
          setTooltipContent(`Enterem vyberete ${matchingItems[0].nazev}`)
        } else {
          setTooltipContent('')
        }

        handleMatching(matchingItems.map(item => item.kod))
        setSearchTerm(searchValue)
        setValue(searchValue)
      },
      [checkboxData, handleMatching, setSearchTerm]
    )

    const handleFocus = useCallback(() => {
      inputRef.current?.select()
    }, [])

    useEffect(() => {
      if (inputRef.current && !value) {
        inputRef.current.select()
      }
    }, [value])

    const handleEnterKeyDown = useCallback(() => {
      const matchingItems = findMatches(checkboxData, value)

      if (matchingItems.length === 1) {
        handleCheckCodes([matchingItems[0].kod])
        setSearchTerm('')
        setValue('')
      }
    }, [checkboxData, handleCheckCodes, setSearchTerm, value])

    return (
      <Tooltip
        placement="topLeft"
        overlayClassName="!max-w-xl"
        title={tooltipContent}
        open={!!tooltipContent && !!value}
      >
        <Input
          ref={inputRef}
          allowClear
          placeholder="Vyhledat způsob využití"
          onChange={handleSearch}
          onFocus={handleFocus}
          onPressEnter={handleEnterKeyDown}
          value={value}
        />
      </Tooltip>
    )
  })
