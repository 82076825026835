import { Icon } from '@iconify/react'
import { Badge } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import {
  ColorPalleteDeafultOption,
  ColorPalleteKey,
  ColorPalleteOptions,
} from '../../utils/collorPallete'
import {
  IconPalleteDeafultOption,
  IconPalleteKey,
  IconPalleteOptions,
} from '../../utils/iconPallete'

export interface LabelSettingButtonBadgeProps {
  colorKey?: ColorPalleteKey
  iconKey?: IconPalleteKey
  children: React.ReactNode
}

export const LabelSettingButtonBadge: FunctionComponent<LabelSettingButtonBadgeProps> =
  props => {
    const initialIcon = props.iconKey || IconPalleteDeafultOption
    const initialColor = props.colorKey || ColorPalleteDeafultOption

    const badgeContent = useMemo(() => {
      return initialColor !== ColorPalleteDeafultOption ||
        initialIcon !== IconPalleteDeafultOption ? (
        <div
          className={
            ColorPalleteOptions[initialColor] +
            ' border w-5 h-5 leading-[17px] justify-center flex items-center rounded-full'
          }
        >
          {initialIcon === IconPalleteDeafultOption ? (
            '◉'
          ) : (
            <Icon
              width={10}
              icon={
                IconPalleteOptions[initialIcon || ColorPalleteDeafultOption]
              }
            />
          )}
        </div>
      ) : (
        0
      )
    }, [initialColor, initialIcon])

    return <Badge count={badgeContent}>{props.children}</Badge>
  }
