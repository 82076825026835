import { Spin } from 'antd'
import React, { FunctionComponent, memo } from 'react'

import { useAppSelector } from '../../../redux/hooks'
import { imageSkeleton } from '../../utils/layoutConst'
import { CadastralImportBox } from '../CadastralImportBox/CadastralImportBox'
import { PropertyDetailLastNote } from '../PropertyDetailLastNote/PropertyDetailLastNote'
import { PropertyDetailSidebarContainer } from '../PropertyDetailSidebarContainer/PropertyDetailSidebarContainer'
import { PropertyDetailSidebarDataValidity } from '../PropertyDetailSidebarDataValidity/PropertyDetailSidebarDataValidity'
import { PropertyDetailSidebarImage } from '../PropertyDetailSidebarImage/PropertyDetailSidebarImage'
import { PropertyDetailSidebarLv } from '../PropertyDetailSidebarLv/PropertyDetailSidebarLv'
import { PropertyDetailSidebarOwnershipRestriction } from '../PropertyDetailSidebarOwnershipRestriction/PropertyDetailSidebarOwnershipRestriction'
import { PropertyDetailSidebarRuian } from '../PropertyDetailSidebarRuian/PropertyDetailSidebarRuian'

export interface PropertyDetailSidebarProps {
  propertyId: string
  notFound: boolean
}

export const PropertyDetailSidebar: FunctionComponent<PropertyDetailSidebarProps> =
  memo(props => {
    const lvGeometryLoading = useAppSelector(
      state => state.property.lvGeometry.fetching
    )
    if (props.notFound)
      return (
        <PropertyDetailSidebarContainer narrow className="hidden lg:flex">
          <img
            className="rounded-sm w-full"
            src={imageSkeleton}
            alt="Nemovitost nenalezena"
          />
        </PropertyDetailSidebarContainer>
      )

    return (
      <div className="flex flex-col space-y-3 mb-4">
        <Spin
          className="h-full !max-h-full"
          wrapperClassName="h-full"
          spinning={lvGeometryLoading}
        >
          <PropertyDetailSidebarImage propertyId={props.propertyId} />
        </Spin>

        <CadastralImportBox
          className="p-3 rounded-sm bg-white"
          entityType="property"
          autoOpenAvailable
        />

        <PropertyDetailSidebarOwnershipRestriction
          propertyId={props.propertyId}
        />
        <PropertyDetailLastNote tabLink />
        <PropertyDetailSidebarLv />
        <PropertyDetailSidebarRuian />
        <PropertyDetailSidebarDataValidity />
      </div>
    )
  })
