import { Typography } from 'antd'
import React, { FunctionComponent, useState } from 'react'

import { PravniVztah, VlastnickePravo } from '../../../common/lvTypes'
import { PropertyDetailOwnershipRightsChart } from '../PropertyDetailOwnershipRightsChart/PropertyDetailOwnershipRightsChart'
import { PropertyDetailOwnershipRightsTable } from '../PropertyDetailOwnershipRightsTable/PropertyDetailOwnershipRightsTable'
import { ShowHistorySwitch } from '../ShowHistorySwitch/ShowHistorySwitch'

export interface LvDetailOwnershipRightsProps {
  ownershipRights: PravniVztah[]
  isDrawer?: boolean
}

const { Title } = Typography

const chartSource = (vlastnickaPrava: VlastnickePravo[]) =>
  vlastnickaPrava.filter(right => !right.platiDo)

const hasHistory = (vlastnickaPrava: VlastnickePravo[]) =>
  !vlastnickaPrava.some(right => !!right.platiDo)

export const LvDetailOwnershipRights: FunctionComponent<LvDetailOwnershipRightsProps> =
  props => {
    const [showHistory, setShowHistory] = useState(false)

    return (
      <div className="space-y-8">
        {props.ownershipRights.map((right, index) => (
          <div className="w-full" key={index}>
            <div className="flex justify-between items-baseline">
              <div className="flex justify-between items-baseline space-x-8">
                <Title level={4}>{right.nazev}</Title>
                {right.nazev === 'Vlastnické právo' && (
                  <PropertyDetailOwnershipRightsChart
                    rights={chartSource(right.vlastnickaPrava)}
                  />
                )}
              </div>
              {!props.isDrawer && (
                <ShowHistorySwitch
                  disabled={hasHistory(right.vlastnickaPrava)}
                  setShowHistory={setShowHistory}
                  showHistory={showHistory}
                />
              )}
            </div>

            <PropertyDetailOwnershipRightsTable
              key={right.nazev}
              rights={right.vlastnickaPrava}
              showHistory={showHistory}
              setShowHistory={setShowHistory}
            />
          </div>
        ))}
      </div>
    )
  }
