import React, { FunctionComponent, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { CuzkLvModalButton } from '../components/CuzkLvModalButton/CuzkLvModalButton'
import { CuzkLvTable } from '../components/CuzkLvTable/CuzkLvTable'
import { PageLayout } from '../components/PageLayout/PageLayout'

export const CuzkLv: FunctionComponent = () => {
  const [refetchState, setRefetchState] = useState(false)
  const {
    params: { katuze, lv },
  } = useRouteMatch<{ katuze: string; lv: string }>()

  return (
    <PageLayout
      isNew
      title="List vlastnictví"
      extra={
        <CuzkLvModalButton
          initialValues={{ katastralniUzemiKod: katuze, lvCislo: lv }}
          refetch={setRefetchState}
        />
      }
    >
      <CuzkLvTable
        refetchState={refetchState}
        setRefetchState={setRefetchState}
      />
    </PageLayout>
  )
}

export default CuzkLv
