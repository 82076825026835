import React, { FunctionComponent, useState } from 'react'

import { PageLayout } from '../components/PageLayout/PageLayout'
import { PropertyDetailDrawer } from '../components/PropertyDetailDrawer/PropertyDetailDrawer'
import { WatchdogPropertyFilter } from '../components/WatchdogPropertyFilter/WatchdogPropertyFilter'
import { WatchdogPropertyTable } from '../components/WatchdogPropertyTable/WatchdogPropertyTable'
import { useInitialFetchWatchdog } from '../utils/useFetchWatchdog'
import { watchdogFilterDefaultValues } from '../utils/watchdogHelpers'

export const WatchdogProperty: FunctionComponent = () => {
  useInitialFetchWatchdog()
  const [filter, setFilter] = useState(watchdogFilterDefaultValues)

  return (
    <>
      <PageLayout
        title="Hlídač katastru - přehled nemovitostí"
        className="pb-0"
        isNew
        isNewTitle="Hlídač nemovitostí 2.0"
      >
        <WatchdogPropertyFilter setFilter={setFilter} filter={filter} />
        <WatchdogPropertyTable filter={filter} />
      </PageLayout>
      <PropertyDetailDrawer />
    </>
  )
}
export default WatchdogProperty
