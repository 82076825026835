import { EyeOutlined } from '@ant-design/icons'
import { Image } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import noImageSrc from '../../images/noimage.png'

export interface MarketplaceItemImageProps {
  setImage: (index: number) => void
  index: number
  src: string
  height?: string
  grayscale?: boolean
}

const wrapperStyle = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  objectFit: 'cover',
} as const

const previewProps = {
  visible: false,
  mask: (
    <div className="items-center flex flex-col">
      <EyeOutlined />
      náhled
    </div>
  ),
}

export const MarketplaceItemImage: FunctionComponent<MarketplaceItemImageProps> =
  props => {
    const handleClick = useCallback(() => {
      props.setImage(props.index)
    }, [props])

    return (
      <div
        className={`relative w-full pb-full ${
          props.height ? props.height : 'h-24'
        }`}
      >
        <Image
          wrapperStyle={wrapperStyle}
          preview={previewProps}
          onClick={handleClick}
          className={`absolute inset-0 w-full !h-full object-cover ${
            props.grayscale ? 'grayscale opacity-70' : ''
          }`}
          src={props.src}
          fallback={noImageSrc}
        />
      </div>
    )
  }
