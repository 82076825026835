import { Drawer } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import { useAuctionDetailDrawerQuery } from '../../../graphql/generated'
import { DRAWER_TYPE, resetAuctionDrawer } from '../../../redux/drawerSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { propertyDrawerWrapperStyle } from '../../utils/layoutConst'
import { useAuctionActivityLog } from '../../utils/useAuctionActivityLog'
import { AuctionDetailDrawerBody } from '../AuctionDetailDrawerBody/AuctionDetailDrawerBody'
import { AuctionDrawerPropertyHeader } from '../AuctionDrawerPropertyHeader/AuctionDrawerPropertyHeader'

export const AuctionDetailDrawer: FunctionComponent = () => {
  const drawer = useAppSelector(state => state.drawer?.[DRAWER_TYPE.AUCTION])
  const userId = useAppSelector(state => state.myProfile.userId)
  const dispatch = useAppDispatch()

  const handleClose = useCallback(
    () => dispatch(resetAuctionDrawer()),
    [dispatch]
  )

  const [dataSource] = useAuctionDetailDrawerQuery({
    variables: { id: drawer?.id || '', accountUserId: userId },
    pause: !drawer?.id,
  })

  useAuctionActivityLog(dataSource.data?.auctionListingById)

  return (
    <Drawer
      contentWrapperStyle={propertyDrawerWrapperStyle}
      title={
        <AuctionDrawerPropertyHeader
          auctionUrl={dataSource.data?.auctionListingById?.detailUrl}
        />
      }
      open={!!drawer?.id}
      onClose={handleClose}
      className="space-y-8 mb-4"
    >
      <AuctionDetailDrawerBody dataSource={dataSource} />
    </Drawer>
  )
}
