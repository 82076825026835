import { createSlice } from '@reduxjs/toolkit'

import { DrmDetailResponse } from '../common/drmTypes'
import handleAsyncActions, {
  AsyncState,
  initialFetchState,
  PropertyDataTypes,
} from './fetchAction'

export const drmSlice = createSlice({
  name: PropertyDataTypes.DRM,
  initialState: initialFetchState as AsyncState<DrmDetailResponse>,
  reducers: {},
  extraReducers: builder => {
    handleAsyncActions<DrmDetailResponse>(builder, PropertyDataTypes.DRM)
  },
})

export default drmSlice.reducer
