import React, { FunctionComponent } from 'react'

import { PageLayout } from '../components/PageLayout/PageLayout'
import { PropertyDetailDrawer } from '../components/PropertyDetailDrawer/PropertyDetailDrawer'
import { PropertyNoteTable } from '../components/PropertyNoteTable/PropertyNoteTable'

export const PropertyNote: FunctionComponent = () => {
  return (
    <>
      <PageLayout title="Poznámky k nemovitostem">
        <PropertyNoteTable />
      </PageLayout>
      <PropertyDetailDrawer />
    </>
  )
}

export default PropertyNote
