import { Button, message, Popconfirm } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'
import { OperationContext } from 'urql'

import { useDeletePropertyNoteByIdMutation } from '../../../graphql/generated'

export interface PropertyDetailNoteDeleteButtonProps {
  noteId: string
  refetch: (opts?: Partial<OperationContext> | undefined) => void
}

const okButtonProps = { danger: true }

export const PropertyDetailNoteDeleteButton: FunctionComponent<PropertyDetailNoteDeleteButtonProps> =
  props => {
    const [, deleteNote] = useDeletePropertyNoteByIdMutation()

    const removeNote = useCallback(async () => {
      const result = await deleteNote({ id: props.noteId })
      if (result.error) {
        message.error('Poznámku se nepodařilo odebrat.')
      } else {
        message.success('Poznámka byla odebrána.')
      }
      props.refetch({ requestPolicy: 'network-only' })
    }, [deleteNote, props])

    return (
      <Popconfirm
        title="Opravdu chcete smazat vaši poznámku?"
        onConfirm={removeNote}
        okText="Smazat"
        cancelText="Zrušit"
        okButtonProps={okButtonProps}
      >
        <Button size="small" type="link" danger>
          Smazat
        </Button>
      </Popconfirm>
    )
  }
