import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'

import { SentRecipient } from '../../../common/postServiceTypes'
import {
  RecipientFragment,
  usePostserviceSentLetterByIdQuery,
} from '../../../graphql/generated'
import { letterRecipientsPagination } from '../../utils/layoutConst'
import { AddressLink } from '../AddressLink/AddressLink'
import { PdfDrawerPreview } from '../PdfDrawerPreview/PdfDrawerPreview'
import { PostServiceLetterDetailPdf } from '../PostServiceLetterDetailPdf/PostServiceLetterDetailPdf'
import { PostServiceSentTableTrackingNumber } from '../PostServiceSentTableTrackingNumber/PostServiceSentTableTrackingNumber'

export interface PostServiceSentTableExpandedRow {
  letterId: string
}

const addressRenderer = (_: string, record: SentRecipient) => {
  if (!record) return null

  const streetParts = [
    record.street,
    record.houseNumber,
    record.orientationNumber ? '/' + record.orientationNumber : '',
  ]
  const street = streetParts.join(' ').trim()
  const fullAddress =
    street + ' ' + record?.city + ' ' + record?.zipCode + ' ' + record?.country

  return record.addressId ? (
    <AddressLink address={fullAddress} addressId={record.addressId} />
  ) : (
    fullAddress
  )
}

export const PostServiceSentTableExpandedRow: FunctionComponent<PostServiceSentTableExpandedRow> =
  props => {
    const [data, setData] = useState<SentRecipient[] | undefined>([])

    const [queryResult] = usePostserviceSentLetterByIdQuery({
      variables: { id: props.letterId },
    })

    useEffect(() => {
      if (
        queryResult.data?.postserviceSentLetterById?.recipients?.nodes?.length
      ) {
        setData(
          queryResult.data?.postserviceSentLetterById?.recipients
            ?.nodes as SentRecipient[]
        )
      }
    }, [queryResult.data?.postserviceSentLetterById?.recipients?.nodes])

    const showVariableValues = useMemo(
      () =>
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => {
          const variableKey = `variable${num}` as keyof SentRecipient
          return {
            title: `Prom #${num}`,
            dataIndex: variableKey,
            responsive:
              data?.find(item => item[variableKey]) || num === 1
                ? undefined
                : ['' as Breakpoint],
            sorter: (a: SentRecipient, b: SentRecipient) =>
              a[variableKey] && b[variableKey]
                ? (a[variableKey] as string).localeCompare(
                    b[variableKey] as string
                  )
                : -1,
          }
        }),
      [data]
    )

    const columns = useMemo<ColumnsType<SentRecipient>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Podací číslo',
          dataIndex: 'postserviceTrackingNumber',
          className: 'w-36',
          render: (trackingNumber, record) => (
            <PostServiceSentTableTrackingNumber
              postserviceId={record.postserviceId}
              trackingNumber={trackingNumber}
              sentAt={queryResult.data?.postserviceSentLetterById?.sentAt}
            />
          ),
        },
        {
          title: 'Společnost',
          dataIndex: 'companyName',
          sorter: (a, b) =>
            a.companyName && b.companyName
              ? a.companyName.localeCompare(b.companyName)
              : -1,
        },
        {
          title: 'Jméno',
          dataIndex: 'recipientName',
          sorter: (a, b) => a.lastname.localeCompare(b.lastname),
          render: (_, record) => record?.firstname + ' ' + record?.lastname,
        },
        {
          title: 'Adresa',
          dataIndex: 'address',
          sorter: (a, b) => a.street.localeCompare(b.street),
          render: addressRenderer,
        },
        ...showVariableValues,
        {
          title: 'PDF',
          dataIndex: 'action',
          className: 'w-36',
          render: (_, record) => (
            <PdfDrawerPreview
              buttonProps={{ ghost: true, size: 'small' }}
              buttonText="Zobrazit dopis"
              drawerTitle="Náhled dopisu"
              fileName={record.lastname + '_HROMADNY_DOPIS_REGESTA_DATA'}
            >
              <PostServiceLetterDetailPdf
                data={record as RecipientFragment}
                letterText={
                  queryResult.data?.postserviceSentLetterById?.content || ''
                }
              />
            </PdfDrawerPreview>
          ),
        },
      ],
      [queryResult.data, showVariableValues]
    )

    return (
      <Table
        pagination={letterRecipientsPagination}
        dataSource={data}
        columns={columns}
        rowKey="id"
        size="small"
        rowClassName="bg-[#fafafa]"
        className="mb-6"
      />
    )
  }
