import { PROPERTY_TYPES } from '../../common/drmTypes'

export type PropertyNoteLink = { title?: string; url: string }

export interface PropertyDetailNote {
  accountId: string
  propertyName: string
  propertyType: string
  accountUserId: string
  propertyLv: string
  propertyId: string
  note: string
  propertyCadastralCode: string
  id: string
  links?: string
  iconKey?: string
  editedAt?: string | null
  createdAt: Date
  colorKey?: string
}

export type PropertyNoteFormValues = PropertyDetailNote & {
  links?: PropertyNoteLink[]
}

export const validateUrl = (links?: PropertyNoteLink[] | null) => {
  if (!links) return null

  return JSON.stringify(
    links.map(link => {
      if (!/^(http:\/\/|https:\/\/|ftp:\/\/)/i.test(link.url)) {
        link.url = `https://${link.url}`
      }
      return link
    })
  )
}

export interface PropertyTableNoteItem {
  note: string
  propertyId: string
  propertyLv: string
  propertyCadastralCode: string
  propertyType: PROPERTY_TYPES
  colorKey?: string | null
  iconKey?: string | null
}

export type PropertyTableNotes = {
  [id: string]: PropertyTableNoteItem | null
} | null
