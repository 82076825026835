import { Divider, Input, Select, Tooltip } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { OneLineHelp } from '../OneLineHelp/OneLineHelp'

export interface PostServiceSelectSampleRecipientProps {
  recipients?: { value?: string | null; label?: string | null }[]
  setRecipient: (id: string) => void
}

const styles = { margin: '8px 0' }

const filterOption = (
  input: string,
  option?: { value?: string | null; label?: string | null }
) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

const dropdownRender = (menu: React.ReactNode) => (
  <>
    <OneLineHelp className="px-2">
      Ze seznamu si můžete vybrat libovolného adresáta, jehož údaje budou
      doplněny namísto proměnných v náhledu PDF, abyste si udělali lepší
      představu o výsledné formě dopisu. V seznamu můžete vyhledávat, stačí
      zadat část jména nebo příjmení.
    </OneLineHelp>

    <Divider style={styles} />
    {menu}
  </>
)

export const PostServiceSelectSampleRecipient: FunctionComponent<PostServiceSelectSampleRecipientProps> =
  props => {
    const handleChange = useCallback(
      (value: string) => {
        props.setRecipient(value)
      },
      [props]
    )

    const defaultValue = useMemo(
      () => props.recipients?.[0]?.value || 'Ukázkový adresát pro náhled',
      [props.recipients]
    )

    if (!props.recipients?.length)
      return (
        <Tooltip
          title="Při tvorbě této šablony nemáte k dispozici žádné přiřazené adresáty. Proto jsou během tvorby proměnné v náhledu nahrazeny za ukázková data."
          className="w-full"
        >
          <span>
            {/* span is needed to make tooltip work */}
            <Input value="Ukázkový adresát pro náhled" disabled />
          </span>
        </Tooltip>
      )

    return (
      <Select
        showSearch
        optionFilterProp="children"
        optionLabelProp="label"
        filterOption={filterOption}
        className="w-full"
        placeholder="Vyberte ukázkového adresáta"
        onChange={handleChange}
        defaultValue={defaultValue}
        dropdownRender={dropdownRender}
        options={props.recipients}
      />
    )
  }
