import React, { FunctionComponent } from 'react'

import {
  ArcGisSourceProps,
  CadastreMapArcGisSource,
} from './CadastreMapArcGisSource'

export interface CadastreMapRuianArcGisSourceProps
  extends Omit<ArcGisSourceProps, 'url'> {
  ruianLayerId: string
}

export const CadastreMapRuianArcGisSource: FunctionComponent<CadastreMapRuianArcGisSourceProps> =
  ({ ruianLayerId, ...sourceProps }) => {
    const url = `https://ags.cuzk.cz/arcgis/rest/services/RUIAN/Prohlizeci_sluzba_nad_daty_RUIAN/MapServer/${ruianLayerId}`
    const projectionEndpoint = `https://ags.cuzk.cz/arcgis/rest/services/Utilities/Geometry/GeometryServer/project`
    return (
      <CadastreMapArcGisSource
        url={url}
        projectionEndpoint={projectionEndpoint}
        {...sourceProps}
      />
    )
  }
