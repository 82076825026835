import { Maybe } from 'graphql/jsutils/Maybe'
import React, { FunctionComponent } from 'react'

import { Min } from '../../components/DateFormat/DateFormat'

interface LabelDetailBaseInfoProps {
  label: Maybe<{
    createdAt?: Date | null
    description?: string | null
    labelConnectionsByLabelId?: {
      totalCount?: number
    }
  }>
}

export const LabelDetailBaseInfo: FunctionComponent<LabelDetailBaseInfoProps> =
  ({ label }) => (
    <div className="flex space-x-4 flex-wrap">
      <div>
        <span className="text-gray-400">Vytvořen:</span>{' '}
        <Min noData="---">{label?.createdAt}</Min>
      </div>
      <div>
        <span className="text-gray-400">Počet přiřazení:</span>{' '}
        {label?.labelConnectionsByLabelId?.totalCount || '---'}
      </div>
      <div>
        <span className="text-gray-400">Popis štítku:</span>{' '}
        {label?.description || <span className="text-gray-400">---</span>}
      </div>
    </div>
  )
