import { Button, message } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { BASE_URL } from '../../../../ini.json'
import { useAppSelector } from '../../../redux/hooks'
import { CadastralCheckedValues } from '../../utils/cuzkHelpers'
import { handleResponseStatus } from '../../utils/handleResponseStatus'
import { pricelist } from '../../utils/pricelist'
import { PricePopconfirm } from '../PricePopconfirm/PricePopconfirm'

export interface CadastralSelectionModalFooterBuyRestrictionProps {
  checkedValues: CadastralCheckedValues
  toggleModalVisibility: () => void
  refetch: () => void
  epoId: string
  selectedCounter: {
    workplacesCount: number
    lvCount: number
    price: number
  }
}

export const CadastralSelectionModalFooterBuyRestriction: FunctionComponent<CadastralSelectionModalFooterBuyRestrictionProps> =
  ({
    epoId,
    selectedCounter,
    checkedValues,
    refetch,
    toggleModalVisibility,
  }) => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const userId = useAppSelector(state => state.myProfile.userId)
    const token = useAppSelector(state => state.myProfile.token)
    const [loading, setLoading] = useState(false)

    const okButtonProps = useMemo(
      () => ({ okButtonProps: { loading } }),
      [loading]
    )
    const buySelected = useCallback(async () => {
      try {
        setLoading(true)

        const codes = Object.values(checkedValues).flat().map(String)
        if (!codes.length)
          return message.error('Nebyly vybrány žádné katastrální pracoviště.')

        const response = await axios.post(
          `${BASE_URL}/api/cuzk/epo/restrictionBuyRestrictions`,
          {
            tenantId,
            userId,
            id: epoId,
            token,
            codes,
          }
        )

        if (response.status === 200) {
          message.success('Kontrola byla úspěšně provedena.')
          return
        }
        handleResponseStatus(response.status)
      } catch (error) {
        message.error(
          'Nepodařilo se provést kontrolu, zkuste to prosím později.'
        )
      } finally {
        setLoading(false)
        refetch()
        toggleModalVisibility()
      }
      return
    }, [
      checkedValues,
      epoId,
      refetch,
      tenantId,
      toggleModalVisibility,
      token,
      userId,
    ])

    return (
      <PricePopconfirm
        onConfirm={buySelected}
        itemPrice={pricelist.EPO_RESTRICTION_CHECK}
        itemCount={selectedCounter.workplacesCount}
        popconfrimProps={okButtonProps}
        message={`Ověření práv 3. stran pro katastrální pracoviště je zpoplatněno ${pricelist.EPO_RESTRICTION_CHECK} Kr.`}
      >
        <Button type="primary" disabled={!selectedCounter.workplacesCount}>
          Ověřit práva 3. stran
        </Button>
      </PricePopconfirm>
    )
  }
