import { Maybe } from 'graphql/jsutils/Maybe'
import React, { FunctionComponent } from 'react'

import { RestrictionCheckboxData } from '../../../common/epoTypes'
import { Identifier, RegisterGroupMap } from '../../utils/dynamicSearchTooltip'
import { useRcNumberData } from '../../utils/useRcNumberData'
import { CeeMonitorTableIdentificationItem as Item } from '../CeeMonitorTableIdentificationItem/CeeMonitorTableIdentificationItem'
import {
  CUZK_PERSON_TYPES,
  CuzkEpoModalFormValues,
} from '../CuzkEpoModalButton/CuzkEpoModalButton'
import { CuzkEpoRestrictionCheckInfoPanelItemActions } from '../CuzkEpoRestrictionCheckInfoPanelItemActions/CuzkEpoRestrictionCheckInfoPanelItemActions'
import { CuzkEpoRestrictionCheckInfoPanelItemAddress } from '../CuzkEpoRestrictionCheckInfoPanelItemAddress/CuzkEpoRestrictionCheckInfoPanelItemAddress'
import { CuzkEpoRestrictionCheckInfoPanelItemPositiveLv } from '../CuzkEpoRestrictionCheckInfoPanelItemPositiveLv/CuzkEpoRestrictionCheckInfoPanelItemPositiveLv'
import { DynamicSearchTooltip } from '../DynamicSearchTooltip/DynamicSearchTooltip'
import { Money } from '../Money/Money'

export interface CuzkEpoRestrictionCheckInfoPanelProps {
  subjectData?: {
    createdAt: Date
    nazev: string
    pocetLv?: Maybe<number>
    rcIco: string
    adresnimistokod?: Maybe<string>
    typ: CuzkEpoModalFormValues['type'] | string
  } | null
  epoId: string
  checkboxHugeData?: RestrictionCheckboxData
  checkboxData?: RestrictionCheckboxData
  refetch: () => void
}

export const CuzkEpoRestrictionCheckInfoPanel: FunctionComponent<CuzkEpoRestrictionCheckInfoPanelProps> =
  props => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    const person = props.subjectData
    const { birthDate } = useRcNumberData(person?.rcIco)

    const OFO = person?.typ === CUZK_PERSON_TYPES.OFO
    const OPO = person?.typ === CUZK_PERSON_TYPES.OPO
    const allCheckedAndBought =
      props.checkboxData?.allLvBought && props.checkboxData?.isAllCheckedBy

    return (
      <div className="p-4 mb-4 mt-1 text-center rounded border border-gray-300 flex bg-gray-100 divide-x divide-gray-300">
        <Item title={OFO ? 'Jméno' : 'Název'}>{person?.nazev}</Item>

        <CuzkEpoRestrictionCheckInfoPanelItemAddress
          adresnimistokod={person?.adresnimistokod}
        />

        {OFO && <Item title="Dat. narození">{birthDate}</Item>}
        {OFO && (
          <Item title="Rodné číslo">
            <DynamicSearchTooltip
              identifier={{
                [Identifier.RC]: person.rcIco,
                [Identifier.DESCRIPTION]: person.nazev,
              }}
              identifierType={Identifier.RC}
              excludedRegisters={[
                ...RegisterGroupMap.EPO,
                ...RegisterGroupMap.ČÚZK,
              ]}
            >
              {person.rcIco}
            </DynamicSearchTooltip>
          </Item>
        )}
        {OPO && (
          <Item title="IČO">
            <DynamicSearchTooltip
              identifier={{
                [Identifier.ICO]: person.rcIco,
                [Identifier.DESCRIPTION]: person.nazev,
              }}
              identifierType={Identifier.ICO}
              excludedRegisters={[
                ...RegisterGroupMap.EPO,
                ...RegisterGroupMap.ČÚZK,
              ]}
            >
              {person.rcIco}
            </DynamicSearchTooltip>
          </Item>
        )}

        <Item title="Dotčených LV">{person?.pocetLv}</Item>

        <CuzkEpoRestrictionCheckInfoPanelItemPositiveLv
          loading={!!props.checkboxData?.loading}
          inProgressLength={props.checkboxData?.inProgress?.length || 0}
          checkedPercentage={props.checkboxData?.checkedPercentage || 0}
          positiveLv={props.checkboxData?.positiveLv || 0}
        />

        <Item loading={props.checkboxData?.loading} title="Ověřeno LV">
          <Money amount={props.checkboxData?.checkedPercentage} symbol="%" />
        </Item>

        {allCheckedAndBought ? (
          <Item loading={props.checkboxData?.loading} title="Zakoupeno Detailů">
            {props.checkboxData?.inProgress?.length ? '100,00 %' : '---'}
          </Item>
        ) : (
          <CuzkEpoRestrictionCheckInfoPanelItemActions
            epoId={props.epoId}
            refetch={props.refetch}
            checkboxData={props.checkboxData}
            checkboxHugeData={props.checkboxHugeData}
          />
        )}
      </div>
    )
  }
