import { Button } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import {
  CadastralCheckedValues,
  CadastralOffice,
} from '../../utils/cuzkHelpers'

export interface CadastralSelectionSelectAllButonProps {
  setCheckedValues: (value: CadastralCheckedValues) => void
  checkedValues: CadastralCheckedValues
  filteredRegions: CadastralOffice[]
}

export const CadastralSelectionSelectAllButon: FunctionComponent<CadastralSelectionSelectAllButonProps> =
  props => {
    const { areAllChecked, areAllDisabled } = useMemo(() => {
      let areAllChecked = true
      let areAllDisabled = true

      for (const region of props.filteredRegions) {
        const enabledWorkplaces = region.pracoviste.filter(
          workplace => !workplace.disabled
        )
        areAllDisabled = areAllDisabled && enabledWorkplaces.length === 0
        areAllChecked =
          areAllChecked &&
          props.checkedValues[region.kod]?.length === enabledWorkplaces.length
      }

      return { areAllChecked, areAllDisabled }
    }, [props.checkedValues, props.filteredRegions])

    const handleSelectAll = useCallback(() => {
      const newCheckedValues: CadastralCheckedValues = {}
      props.filteredRegions.forEach(region => {
        const regionWorkplacesCodes = region.pracoviste
          .filter(workplace => !region.disabled && !workplace.disabled)
          .map(workplace => workplace.kod)
        newCheckedValues[region.kod] = regionWorkplacesCodes
      })
      props.setCheckedValues(newCheckedValues)
    }, [props])

    const handleDeselectAll = useCallback(() => {
      props.setCheckedValues({})
    }, [props])

    return (
      <Button
        type="primary"
        onClick={areAllChecked ? handleDeselectAll : handleSelectAll}
        ghost={areAllChecked}
        disabled={areAllDisabled}
      >
        {areAllChecked
          ? 'Odebrat všechna pracoviště'
          : 'Vybrat všechna pracoviště'}
      </Button>
    )
  }
