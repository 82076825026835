import { format } from 'date-fns'
import React, { FunctionComponent } from 'react'
import {
  CursorMarker,
  CustomMarker,
  TimelineMarkers,
} from 'react-calendar-timeline'

import { czDateFormatDay } from '../../utils/dateFormat'

const nowTime = new Date().getTime()

const todayMarkerRenderer = ({ styles }: { styles: React.CSSProperties }) => (
  <div style={styles} className="relative !bg-red-200 !w-px">
    <div className="absolute left-2 bottom-4 px-2 py-1 bg-white text-gray-400">
      Dnes
    </div>
  </div>
)

const hoverDateRenderer = ({
  styles,
  date,
}: {
  styles: React.CSSProperties
  date: number
}) => (
  <div style={styles} className="relative !bg-gray-400 !w-px">
    <div className="absolute left-2 bottom-4 px-2 py-1 bg-white text-gray-400">
      {format(date, czDateFormatDay)}
    </div>
  </div>
)

export const PropertyDetailTimelineMarkers: FunctionComponent = () => {
  return (
    <TimelineMarkers>
      <CustomMarker date={nowTime}>{todayMarkerRenderer}</CustomMarker>
      <CursorMarker>{hoverDateRenderer}</CursorMarker>
    </TimelineMarkers>
  )
}
