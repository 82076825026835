import axios from 'axios'
import { useEffect, useState } from 'react'

import { ParcelaDruhFetchResult } from '../../common/foreclosureMonitoringTypes'
import { useAppSelector } from '../../redux/hooks'

export const useFetchAreaTypeEnums = () => {
  const token = useAppSelector(state => state.myProfile.token)

  const [result, setResult] = useState<ParcelaDruhFetchResult>({
    fetching: true,
    error: false,
    data: null,
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        setResult(prev => ({ ...prev, fetching: true }))

        const response = await axios.get(
          `https://data.regesta.cz/DrmApi/api/v1.0/Ciselniky/Nemovitosti/Parcely/DruhPozemku?access_token=${token}`
        )

        setResult({
          fetching: false,
          error: false,
          data: response.data,
        })
      } catch (error) {
        setResult({
          fetching: false,
          error: true,
          data: null,
        })
      }
    }

    fetchData()
  }, [token])

  return result
}
