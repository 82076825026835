import { Button } from 'antd'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { MAP_TYPE } from '../components/MapTypeButton/MapTypeButton'

const handleLinkClick = (
  e:
    | React.MouseEvent<HTMLAnchorElement, MouseEvent>
    | React.MouseEvent<HTMLButtonElement, MouseEvent>
) => e.stopPropagation()

type MapImage = {
  [key in MAP_TYPE]: {
    src: string
    link: string
  }
}

export const usePropertyImagePreviewProps = (
  imageState: [MAP_TYPE, React.Dispatch<React.SetStateAction<MAP_TYPE>>],
  mapImage: MapImage,
  propertyDetailPath?: string
) => {
  return useMemo(
    () => ({
      maskClassName: '!cursor-default',
      mask: (
        <div className="gap-2 flex flex-wrap justify-center">
          <Button ghost>Zvětšit obrázek</Button>
          <Button
            ghost
            target="_blank"
            href={mapImage[imageState[0]].link}
            onClick={handleLinkClick}
          >
            {imageState[0] === MAP_TYPE.SW && mapImage[imageState[0]].src
              ? 'Přejít na Street View'
              : 'Přejít na mapu'}
          </Button>
          {!!propertyDetailPath && (
            <Link
              to={propertyDetailPath}
              onClick={handleLinkClick}
              target="_blank"
              className="w-full text-center"
            >
              <Button ghost>Přejít na detail nemovitosti</Button>
            </Link>
          )}
        </div>
      ),
    }),
    [imageState, mapImage, propertyDetailPath]
  )
}

export const useAuctionImagePreviewProps = (
  imageState: [MAP_TYPE, React.Dispatch<React.SetStateAction<MAP_TYPE>>],
  mapImage: MapImage,
  auctionUrl: string
) => {
  return useMemo(
    () => ({
      maskClassName: '!cursor-default',
      mask: (
        <div className="gap-2 flex flex-wrap justify-center">
          <Button ghost>Zvětšit obrázek</Button>
          {imageState[0] !== MAP_TYPE.SW && (
            <Button
              ghost
              target="_blank"
              href={mapImage[imageState[0]].link}
              onClick={handleLinkClick}
            >
              Přejít na mapu
            </Button>
          )}
          <a
            href={auctionUrl}
            onClick={handleLinkClick}
            target="_blank"
            className="w-full text-center"
            rel="noreferrer"
          >
            <Button ghost>Přejít do dražby</Button>
          </a>
        </div>
      ),
    }),
    [imageState, mapImage, auctionUrl]
  )
}
