import { QuestionCircleOutlined } from '@ant-design/icons'
import { Icon } from '@iconify/react'
import { Tooltip } from 'antd'
import { compareAsc, parseISO } from 'date-fns'
import React, { ReactElement } from 'react'

import { PROPERTY_TYPES } from '../../common/drmTypes'
import { ForclosureMonitoringFilterState } from '../../common/foreclosureMonitoringTypes'
import { booleanSorter } from './generalTableHelpers'

export const forecloserMonitoringInitialFilterQuery = {
  [PROPERTY_TYPES.BUILDING]: 'Budovy?exekuce=true',
  [PROPERTY_TYPES.AREA]: 'Parcely?exekuce=true',
  [PROPERTY_TYPES.UNIT]: 'Jednotky?exekuce=true',
}

const tooltipProps = {
  overlayInnerStyle: { width: '350px' },
  trigger: 'click',
  className: 'cursor-help hover:opacity-70',
}

const helpIcon = <QuestionCircleOutlined className="text-gray-400" />

const filterIcon = (
  <div className="inline-block w-11 text-right">
    <Icon
      icon="material-symbols-light:filter-alt-off-outline"
      width="20"
      height="24"
      className="inline-block -mb-1"
    />{' '}
    =
  </div>
)

const forecloser = (
  <div>
    <div>
      <div className="inline-block w-11 text-right">ANO =</div> pouze
      nemovitosti zatížené exekucí
    </div>
    <div>
      <div className="inline-block w-11 text-right">NE =</div> pouze nemovitosti
      bez exekuce
    </div>
    <div className="flex">
      {filterIcon}{' '}
      <div className="inline-block w-72 ml-1">
        všechny nemovitosti bez ohledu zda jsou zatíženy exekucí či nikoliv
      </div>
    </div>
  </div>
)

const insolvency = (
  <div>
    <div>
      <div className="inline-block w-11 text-right">ANO =</div> pouze
      nemovitosti zatížené insolvencí
    </div>
    <div>
      <div className="inline-block w-11 text-right">NE =</div> pouze nemovitosti
      bez insolvence
    </div>
    <div className="flex">
      {filterIcon}{' '}
      <div className="inline-block w-72 ml-1">
        všechny nemovitosti bez ohledu zda jsou zatíženy insolvencí či nikoliv
      </div>
    </div>
  </div>
)

const involuntaryLien = (
  <div>
    <div>
      <div className="inline-block w-11 text-right">ANO =</div> pouze
      nemovitosti s nedobrovolnou zástavou
    </div>
    <div>
      <div className="inline-block w-11 text-right">NE =</div> pouze nemovitosti
      bez nedobrovolné zástavy
    </div>
    <div className="flex">
      {filterIcon}{' '}
      <div className="inline-block w-72 ml-1">
        všechny nemovitosti bez ohledu zda jsou zatíženy nedobrovolným zástavním
        právem či nikoliv
      </div>
    </div>
  </div>
)

const contractualLien = (
  <div>
    <div>
      <div className="inline-block w-11 text-right">ANO =</div> pouze
      nemovitosti se smluvní zástavou
    </div>
    <div>
      <div className="inline-block w-11 text-right">NE =</div> pouze nemovitosti
      bez smluvní zástavy
    </div>
    <div className="flex">
      {filterIcon}{' '}
      <div className="inline-block w-72 ml-1">
        všechny nemovitosti bez ohledu zda jsou zatíženy smluvním zástavním
        právem či nikoliv
      </div>
    </div>
  </div>
)

const area = (
  <div>
    <div>Od = Minimální plocha pozemku v m²</div>
    <div>Do = Maximální plocha pozemku v m²</div>
    <div className="mt-1">Lze filtrovat jednotlivě, v kombinaci či vůbec</div>
  </div>
)

const ownership = (
  <div>
    <div>Od = Minimální počet vlastnických podílů</div>
    <div>Do = Maximální počet vlastnických podílů</div>
    <div className="mt-1">Lze filtrovat jednotlivě, v kombinaci či vůbec</div>
    <div>Manželé v SJM se považují za jednoho vlastníka</div>
    <div>
      Pokud např. chcete filtrovat nemovitosti právě s 2 majiteli, zadejte od: 2
      do: 2
    </div>
  </div>
)

const shareSize = (
  <div>
    <div>Od = Minimální vlastnický podíl dlužníka</div>
    <div>Do = Maximální vlastnický podíl dlužníka</div>
    <div className="mt-1">Lze filtrovat jednotlivě, v kombinaci či vůbec</div>
    <div>
      Pokud chcete filtrovat nemovitosti se 100% podílem dlužníka, zadejte od:
      100
    </div>
  </div>
)

const sortTooltip = (type: string) => ({
  title: (
    <div>
      <div>Řadit dle {type}</div>
      <div className="text-gray-400">
        V tabulce můžete řadit dle více omezení vl. práv naráz.
      </div>
    </div>
  ),
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dateSorter = (key: any) => (a: any, b: any) => {
  if (!a?.[key] && !b?.[key]) return 0
  if (!a?.[key]) return 1
  if (!b?.[key]) return -1
  return compareAsc(parseISO(a), parseISO(b))
}

export const renderBooleanIcon = (value: boolean | null) => {
  if (value === null)
    return (
      <Tooltip
        title="Vyskytuje se jen u části nemovitostí"
        className="text-gray-300"
      >
        <Icon icon="fa:check" width="13" className="mx-auto" />
      </Tooltip>
    )
  return value && <Icon icon="fa:check-circle" width="13" className="mx-auto" />
}
export const restrictionColumn = {
  exekuce: {
    title: 'Exe.',
    dataIndex: 'exekuce',
    className: 'w-10',
    render: renderBooleanIcon,
    showSorterTooltip: sortTooltip('Exekuce'),
    sorter: {
      multiple: 4,
      compare: booleanSorter('exekuce'),
    },
  },
  insolvence: {
    title: 'Ins.',
    dataIndex: 'insolvence',
    className: 'w-10',
    render: renderBooleanIcon,
    showSorterTooltip: sortTooltip('Insolvence'),
    sorter: {
      multiple: 4,
      compare: booleanSorter('insolvence'),
    },
  },
  zastavyNedobrovolne: {
    title: 'Ned.',
    dataIndex: 'zastavyNedobrovolne',
    className: 'w-10',
    render: renderBooleanIcon,
    showSorterTooltip: sortTooltip('Nedobrovolného zástavního práva'),
    sorter: {
      multiple: 4,
      compare: booleanSorter('zastavyNedobrovolne'),
    },
  },
  zastavySmluvni: {
    title: 'Sml.',
    dataIndex: 'zastavySmluvni',
    className: 'w-10',
    render: renderBooleanIcon,
    showSorterTooltip: sortTooltip('Smluvního zástavního práva'),
    sorter: {
      multiple: 4,
      compare: booleanSorter('zastavySmluvni'),
    },
  },
  predkupniPravo: {
    title: 'PP',
    dataIndex: 'predkupniPravo',
    className: 'w-10',
    render: renderBooleanIcon,
    showSorterTooltip: sortTooltip('Předkupního práva'),
    sorter: {
      multiple: 4,
      compare: booleanSorter('predkupniPravo'),
    },
  },
}

export const foreclosureMonitoringFilterLabels: Record<
  keyof ForclosureMonitoringFilterState,
  ReactElement | null
> = {
  exekuce: (
    <Tooltip {...tooltipProps} title={forecloser}>
      Exekuce {helpIcon}
    </Tooltip>
  ),
  insolvence: (
    <Tooltip {...tooltipProps} title={insolvency}>
      Insolvence {helpIcon}
    </Tooltip>
  ),
  zastavySmluvni: (
    <Tooltip {...tooltipProps} title={contractualLien}>
      Zástava smluvní {helpIcon}
    </Tooltip>
  ),
  zastavyNedobrovolne: (
    <Tooltip {...tooltipProps} title={involuntaryLien}>
      Zástava nedobrovolná {helpIcon}
    </Tooltip>
  ),
  vymeraOd: (
    <Tooltip {...tooltipProps} title={area}>
      Výměra parcely (m²) {helpIcon}
    </Tooltip>
  ),
  vymeraDo: (
    <Tooltip {...tooltipProps} title={area}>
      Výměra parcely (m²) {helpIcon}
    </Tooltip>
  ),
  vlastnikuOd: (
    <Tooltip {...tooltipProps} title={ownership}>
      Počet vlastníků {helpIcon}
    </Tooltip>
  ),
  vlastnikuDo: (
    <Tooltip {...tooltipProps} title={ownership}>
      Počet vlastníků {helpIcon}
    </Tooltip>
  ),
  pracoviste: null,
  stranka: null,
  druhyPozemku: null,
  zpusobyVyuzitiBudov: null,
  zpusobyVyuzitiJednotek: null,
  zpusobyVyuzitiPozemku: null,
}

export const insolvencyMonitoringFilterLabels: Record<
  'podilOd',
  ReactElement | null
> = {
  podilOd: (
    <Tooltip {...tooltipProps} title={shareSize}>
      Velikost podílu (%) {helpIcon}
    </Tooltip>
  ),
}

export const selectionSearchBarNormalizer = (value?: string | null): string => {
  if (!value) return ''
  return value
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9\s+]/g, '')
}

export const insolvencyUrl = (insolvencyNumber?: string) => {
  if (!insolvencyNumber) return ''
  const [, number, year] = insolvencyNumber.match(/INS (\d+)\/(\d{4})/) || []
  return number && year
    ? `https://isir.justice.cz/isir/ueu/vysledek_lustrace.do?&bc_vec=${number}&rocnik=${year}&aktualnost=AKTUALNI_I_UKONCENA&rowsAtOnce=50&spis_znacky_obdobi=14DNI`
    : ''
}

export const propertyTypeText = {
  [PROPERTY_TYPES.BUILDING]: 'Budovy',
  [PROPERTY_TYPES.AREA]: 'Parcely',
  [PROPERTY_TYPES.UNIT]: 'Jednotky',
}
