import { WarningOutlined } from '@ant-design/icons'
import React, { FunctionComponent } from 'react'

import { CeeExecutionDetail, Maybe } from '../../../graphql/generated'
import { formatDateInText } from '../../utils/dateFormat'
import { CeeMonitorTableIdentificationItem as Item } from '../CeeMonitorTableIdentificationItem/CeeMonitorTableIdentificationItem'
import { Money } from '../Money/Money'
import { RegularPaymentCollapse } from '../RegularPaymentCollapse/RegularPaymentCollapse'

export interface CeeMonitorTableDetailExpandedProps {
  ceeDetail?: Maybe<Partial<CeeExecutionDetail>>
}

export const CeeMonitorTableDetailExpanded: FunctionComponent<CeeMonitorTableDetailExpandedProps> =
  ({ ceeDetail }) => {
    if (!ceeDetail)
      return (
        <div className="py-2 px-4 -mt-2 -mx-2 mb-4 rounded border-b border-b-gray-300 bg-red-50 text-center text-red-600">
          <WarningOutlined /> Nastala chyba při načítání dat, opakujte prosím
          akci později
        </div>
      )
    return (
      <div className="py-2 px-4 -mt-2 -mx-2 mb-4 rounded border-b border-b-gray-300 flex bg-orange-50">
        <Item title="SpZn Exekutora">{ceeDetail?.spznExekutora}</Item>
        <Item title="SpZn Soudu">{ceeDetail?.spznSoudu}</Item>
        <Item title="Soud">{ceeDetail?.soud}</Item>
        <Item title="Titul">{formatDateInText(ceeDetail?.titul)}</Item>
        <Item title="Datum exekuce">{ceeDetail?.datum && ceeDetail.datum}</Item>
        <Item title="Pravidelná splátka">
          <RegularPaymentCollapse text={ceeDetail?.pravidelnaSplatka} />
        </Item>
        <Item title="Částka" className="text-right">
          <Money amount={ceeDetail?.castka} symbol="Kč" />
        </Item>
      </div>
    )
  }
