import { Button, message, Tooltip } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { BASE_URL } from '../../../../ini.json'
import { addHtmlContainer } from '../../../common/postServicePdf'
import { useAppSelector } from '../../../redux/hooks'
import { cadastrePostSentPath } from '../../utils/paths'

export interface CadastrePostSendLetterProps {
  data: string
  listId: string
  disabled: boolean
}

export const CadastrePostSendLetter: FunctionComponent<CadastrePostSendLetterProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const history = useHistory()

    const handleComposeLetter = useCallback(async () => {
      if (props.data.length < 8) {
        // 8 is the length of the initial content
        return message.error('Nelze odeslat prázdnou korespondenci.')
      }

      try {
        const response = await fetch(
          `${BASE_URL}/api/postService/composeLetter/${token}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              tenantId: tenantId,
              listId: props.listId,
              letterData: addHtmlContainer(props.data),
            }),
          }
        )

        if (response.status === 200) {
          message.success('Hromadná korespondence byla vytvořena.')
          history.push(cadastrePostSentPath())
        }
        if (response.status === 401) {
          message.success('Nejste přihlášen.')
        }
      } catch (error) {
        message.error('Nepodařilo se vytvořit korespondenci.')
      }
      return false
    }, [history, props.data, props.listId, tenantId, token])

    return (
      <Tooltip
        title="Aktualizujte prvně náhled PDF"
        trigger={props.disabled ? 'hover' : ''}
      >
        <Button
          disabled={props.disabled}
          onClick={handleComposeLetter}
          type="primary"
        >
          Uložit hromadnou korespondenci
        </Button>
      </Tooltip>
    )
  }
