import React, { FunctionComponent } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import {
  InsolvencyMonitoringFilterOnSubmit,
  InsolvencyMonitoringFilterState,
} from '../../../common/insolvencyMonitoringTypes'
import { InsolvencyMonitoringFilterMinimalShare } from '../InsolvencyMonitoringFilterMinimalShare/InsolvencyMonitoringFilterMinimalShare'
import { InsolvencyMonitoringFilterProceedingState } from '../InsolvencyMonitoringFilterProceedingState/InsolvencyMonitoringFilterProceedingState'
import { PropertyMonitoringFilterAreaUse } from '../PropertyMonitoringFilterAreaUse/PropertyMonitoringFilterAreaUse'
import { PropertyMonitoringFilterCadastre } from '../PropertyMonitoringFilterCadastre/PropertyMonitoringFilterCadastre'
import { PropertyMonitoringFilterPropertyUse } from '../PropertyMonitoringFilterPropertyUse/PropertyMonitoringFilterPropertyUse'
import { PropertyMonitoringFilterSegmented } from '../PropertyMonitoringFilterSegmented/PropertyMonitoringFilterSegmented'

export interface InsolvencyMonitoringFilterProps {
  propertyType: PROPERTY_TYPES
  filter: InsolvencyMonitoringFilterState
  handleChange: InsolvencyMonitoringFilterOnSubmit
}

export const InsolvencyMonitoringFilter: FunctionComponent<InsolvencyMonitoringFilterProps> =
  ({ propertyType, filter, handleChange }) => {
    return (
      <div
        id="monitoringFilter"
        className="flex gap-x-8 gap-y-4 mb-8 flex-wrap"
      >
        <div className="grid grid-cols-2 w-full md:flex md:w-auto gap-x-8 gap-y-4">
          <PropertyMonitoringFilterSegmented
            filterKey="zastavySmluvni"
            value={filter.zastavySmluvni}
            handleChange={handleChange}
          />

          <PropertyMonitoringFilterSegmented
            filterKey="zastavyNedobrovolne"
            value={filter.zastavyNedobrovolne}
            handleChange={handleChange}
          />
        </div>
        <div className="grid grid-cols-2 w-full md:flex md:flex-wrap md:w-auto gap-x-8 gap-y-4">
          <InsolvencyMonitoringFilterMinimalShare
            value={filter.podilOd}
            onChange={handleChange}
          />

          <InsolvencyMonitoringFilterProceedingState
            selected={filter.stavyRizeni}
            onChange={handleChange}
          />

          {propertyType === PROPERTY_TYPES.AREA && (
            <PropertyMonitoringFilterAreaUse
              selectedAreaUse={filter.zpusobyVyuzitiPozemku}
              selectedAreaType={filter.druhyPozemku}
              onChange={handleChange}
            />
          )}

          {propertyType === PROPERTY_TYPES.BUILDING && (
            <PropertyMonitoringFilterPropertyUse
              selected={filter.zpusobyVyuzitiBudov}
              propertyType={PROPERTY_TYPES.BUILDING}
              onChange={handleChange}
            />
          )}

          {propertyType === PROPERTY_TYPES.UNIT && (
            <PropertyMonitoringFilterPropertyUse
              selected={filter.zpusobyVyuzitiJednotek}
              propertyType={PROPERTY_TYPES.UNIT}
              onChange={handleChange}
            />
          )}

          <PropertyMonitoringFilterCadastre
            selected={filter.pracoviste}
            onChange={handleChange}
          />
        </div>
      </div>
    )
  }
