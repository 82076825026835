import { Icon } from '@iconify/react'
import React, { FunctionComponent } from 'react'

import { SUBJEKT } from '../../../common/cuzkLvTypes'
import { getOpsubShortName } from '../../../common/pdfHelpers'

export interface CuzkLvHtmlSingleOwnerInfoProps {
  subject?: SUBJEKT
}

export const CuzkLvHtmlSingleOwnerInfo: FunctionComponent<CuzkLvHtmlSingleOwnerInfoProps> =
  props => {
    if (!props.subject) return null
    return (
      <div className="relative">
        <div className="absolute flex flex-col h-full w-full justify-center items-center">
          <span className="text-2xl uppercase">100% podíl</span>
          <span className="text-2xl">{getOpsubShortName(props.subject)}</span>
          <span className="text-md">jediný subjekt s vlastnickými právy</span>
        </div>
        <div className="blur-md opacity-70">
          <Icon
            icon="line-md:circle"
            className="text-[250px] mx-auto my-3 text-primaryHover"
            rotate={2}
          />
        </div>
      </div>
    )
  }
