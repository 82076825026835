import { Table } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { ExportTableXlsxButton } from '../ExportTableXlsxButton/ExportTableXlsxButton'
import { TableItemsCount } from '../TableItemsCount/TableItemsCount'

export interface TableSummaryExportProps {
  dataSource: unknown[]
  total: number
  colSpan: number
  colSpanAction?: number
  align?: 'text-left' | 'text-right' | 'text-center'
  buttonText?: string
  icon?: boolean
  url: string
  fileName: string
}

export const TableSummaryExport: FunctionComponent<TableSummaryExportProps> =
  props => {
    const style = useMemo(
      () => (props.align ? props.align : 'text-right') + ' !py-2',
      [props.align]
    )
    return (
      <Table.Summary.Row className="bg-[#fafafa]">
        <Table.Summary.Cell colSpan={props.colSpan} index={0} className="!py-2">
          <TableItemsCount total={props.total} />
        </Table.Summary.Cell>
        <Table.Summary.Cell
          colSpan={props.colSpanAction}
          className={style}
          index={1}
        >
          <ExportTableXlsxButton {...props} />
        </Table.Summary.Cell>
      </Table.Summary.Row>
    )
  }
