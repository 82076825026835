import { Tooltip } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { JEDNOTKY } from '../../../common/cuzkLvTypes'
import {
  notEmpty,
  safeFind,
  safeJoin,
  safeMap,
} from '../../../common/pdfHelpers'
import { useAppSelector } from '../../../redux/hooks'
import { lvDetailPath } from '../../utils/paths'
import { CuzkLvHtmlJednotkaIdentFragment } from '../CuzkLvHtmlJednotkaIdentFragment/CuzkLvHtmlJednotkaIdentFragment'
import { CuzkLvHtmlParcelaIdentFragment } from '../CuzkLvHtmlParcelaIdentFragment/CuzkLvHtmlParcelaIdentFragment'
import { CuzkLvHtmlPodilFragment } from '../CuzkLvHtmlPodilFragment/CuzkLvHtmlPodilFragment'
import { CuzkLvHtmlPravoStavbyIdentFragment } from '../CuzkLvHtmlPravoStavbyIdentFragment/CuzkLvHtmlPravoStavbyIdentFragment'

export interface CuzkLvHtmlSekceJednotkyFragmentProps {
  jednotky?: JEDNOTKY
  active?: boolean
}

export const CuzkLvHtmlSekceJednotkyFragment: FunctionComponent<CuzkLvHtmlSekceJednotkyFragmentProps> =
  props => {
    const katastralniUzemiKod = useAppSelector(
      state => state.lvDetail.katastralniUzemi.kod
    )
    const naLvTitle = useCallback(
      (lvNumber?: string | number) => {
        if (props.active && lvNumber) {
          return (
            <Tooltip title="Zobrazit detail LV v novém okně">
              <Link
                target="_blank"
                to={lvDetailPath(katastralniUzemiKod, lvNumber)}
              >
                {lvNumber}
              </Link>
            </Tooltip>
          )
        }

        return lvNumber
      },
      [katastralniUzemiKod, props.active]
    )

    if (!props.jednotky || !notEmpty(props.jednotky.JEDNOTKA)) {
      return null
    }
    return (
      <>
        <div className="row">Jednotky</div>
        <div className="row">
          <div className="col-xs-2">
            <i>
              Č.p./
              <br />
              Č.jednotky
            </i>
          </div>
          <div className="col-xs-2 verticalBottom">
            <i>Způsob využití</i>
          </div>
          <div className="col-xs-4 verticalBottom">
            <i>Způsob ochrany</i>
          </div>
          <div className="col-xs-1">
            <i>Typ jednotky</i>
          </div>
          <div className="col-xs-3 text-right">
            <i>Podíl na společných částech domu a pozemku</i>
          </div>
        </div>
        <hr className="mt-0 mb-0" />
        {safeMap(props?.jednotky?.JEDNOTKA, (JEDNOTKA, index) => (
          <div key={index}>
            <div className="row bold">
              <div className="col-xs-2">
                {JEDNOTKA.PLOMBA_JEDNOTKY ? JEDNOTKA.PLOMBA_JEDNOTKY + ' ' : ''}
                <CuzkLvHtmlJednotkaIdentFragment
                  noLabel
                  jednotkaIdent={JEDNOTKA.CISLO_JEDNOTKY.jednotka}
                  active={props.active}
                />
              </div>
              <div className="col-xs-2">{JEDNOTKA.ZP_VYUZITI?._value}</div>
              <div className="col-xs-4 breakAll">
                {safeJoin(JEDNOTKA.ZP_OCHRANY?.zpochr, ', ')}
              </div>

              <div className="col-xs-1">{JEDNOTKA.TYP_JEDNOTKY._value}</div>
              <div className="col-xs-3 text-right">
                <CuzkLvHtmlPodilFragment
                  podil={JEDNOTKA.PODIL_B}
                  active={props.active}
                />
              </div>
            </div>
            {notEmpty(JEDNOTKA.BUDOVY?.BUDOVA) && (
              <div className="row mt-20">
                <div className="col-xs-12">
                  <strong>Vymezeno v:</strong>
                </div>
              </div>
            )}
            {safeMap(JEDNOTKA.BUDOVY?.BUDOVA, (BUDOVA, index) => (
              <div key={index}>
                <div className="row">
                  <div className="col-xs-1">&nbsp;</div>
                  <div className="col-xs-1">
                    <i>Budova</i>
                  </div>
                  <div className="col-xs-9">
                    {safeMap(BUDOVA?.JED_OPSUB, (JED_OPSUB, index) => (
                      <div key={index}>
                        <strong>
                          {JED_OPSUB?.stavba?.caobce},
                          {JED_OPSUB?.stavba?.typbud_zkr}
                          {JED_OPSUB?.stavba?.cisla_domovni},
                          {JED_OPSUB?.stavba?.vyuziti_zkr}
                          {JED_OPSUB?.stavba?.bez_lv}
                          {JED_OPSUB?.stavba?.cislo_tel && ', LV '}
                          {naLvTitle(JED_OPSUB?.stavba?.cislo_tel)}
                          {JED_OPSUB?.stavba?.ku_nazev}
                          {JED_OPSUB?.stavba?.docasna}
                        </strong>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-2">&nbsp;</div>
                  <div className="col-xs-2">
                    <i>na parcele</i>
                  </div>
                  <div className="col">
                    {safeMap(BUDOVA?.PARCELY?.PAR_IDENT, (PAR_IDENT, index) => (
                      <div key={index}>
                        <strong>
                          <CuzkLvHtmlParcelaIdentFragment
                            noLabel
                            parcelaIdent={PAR_IDENT?.parcela}
                            active={props.active}
                          />
                          {', LV '}
                          {naLvTitle(PAR_IDENT?.parcela?.cislo_tel)}
                        </strong>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
            {safeMap(JEDNOTKA.PARCELY?.PARCELA, (PARCELA, index) => (
              <div key={index}>
                <div className="row">
                  <div className="col-xs-1">&nbsp;</div>
                  <div className="col-xs-1">
                    <i>Parcela</i>
                  </div>
                  <div className="col-xs-10 bold">
                    <div className="row">
                      <div className="col-xs-2">
                        <CuzkLvHtmlParcelaIdentFragment
                          noLabel
                          parcelaIdent={PARCELA?.PAR_IDENT?.parcela}
                          active={props.active}
                        />
                      </div>
                      <div className="col-xs-5">{PARCELA.DRUH_POZ}</div>
                      <div className="col-xs-3">{PARCELA.ZP_VYUZITI}</div>
                      <div className="col-xs-2 text-right">
                        {PARCELA.VYMERA_PARCELY}
                      </div>
                    </div>
                  </div>
                </div>
                {safeMap(PARCELA?.SOUCASTI_STAVBA?.stavba, (stavba, index) => (
                  <div key={index}>
                    <div className="row">
                      <div className="col-xs-1">&nbsp;</div>
                      <div className="col-xs-3 pl-50">
                        <i>Součástí je stavba:</i>
                      </div>
                      <div className="col-xs-8">
                        <strong>
                          {stavba?.caobce},{stavba?.typbud_zkr}
                          {stavba?.cisla_domovni},{stavba?.vyuziti_zkr}
                          {stavba?.bez_lv}
                          {stavba?.cislo_tel && ', LV'}
                          {naLvTitle(stavba?.cislo_tel)}
                          {stavba?.ku_nazev}
                          {stavba?.docasna}
                        </strong>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
            {safeMap(
              JEDNOTKA.PRAVA_STAVBY?.PRAVO_STAVBY,
              (PRAVO_STAVBY, index) => (
                <div key={index}>
                  <div className="row">
                    <div className="col-xs-1">&nbsp;</div>
                    <div className="col-xs-2">
                      <i>Právo stavby</i>
                    </div>
                    <div className="col-xs-9 bold">
                      <CuzkLvHtmlPravoStavbyIdentFragment
                        noLabel
                        pravoStavbyIdent={PRAVO_STAVBY.PS_IDENT.pravo_stavby}
                        active={props.active}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-1">&nbsp;</div>
                    <div className="col-xs-2 pl-50">
                      <i>Platnost do</i>
                    </div>
                    <div className="col-xs-8 bold">
                      {PRAVO_STAVBY.PLATNOST_DO}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        ))}
        {safeFind(
          props.jednotky?.JEDNOTKA,
          JEDNOTKA => !!JEDNOTKA?.PLOMBA_JEDNOTKY
        ) && (
          <div className="row">
            <i>P = právní vztahy jsou dotčeny změnou</i>
          </div>
        )}
      </>
    )
  }
