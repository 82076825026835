import { createSlice } from '@reduxjs/toolkit'

import { RzpZaznam } from '../common/rzpTypes'
import { VrZaznam } from '../common/vrTypes'
import handleAsyncActions, {
  AsyncState,
  initialFetchState,
  PropertyDataTypes,
} from './fetchAction'

interface AddressAresSubjects {
  rzp: { [key: string]: RzpZaznam }
  vr: { [key: string]: VrZaznam }
}
export const aresSubjectSlice = createSlice({
  name: PropertyDataTypes.ARES,
  initialState: initialFetchState as AsyncState<AddressAresSubjects>,
  reducers: {},
  extraReducers: builder => {
    handleAsyncActions<AddressAresSubjects>(builder, PropertyDataTypes.ARES)
  },
})

export default aresSubjectSlice.reducer
