import { Button, ButtonProps, Divider, Modal, Typography } from 'antd'
import React, { FunctionComponent, useMemo, useState } from 'react'

import { useToggleState } from '../../utils/useToggleState'
import { Fade } from '../Fade/Fade'
import { PostServiceImportRecipientAlert } from '../PostServiceImportRecipientAlert/PostServiceImportRecipientAlert'
import { PostServiceImportRecipientFormUploadFragment } from '../PostServiceImportRecipientFormUploadFragment/PostServiceImportRecipientFormUploadFragment'
import { PostServiceImportRecipientPreviewTable } from '../PostServiceImportRecipientPreviewTable/PostServiceImportRecipientPreviewTable'
import { PostServiceImportRecipientSampleCsv } from '../PostServiceImportRecipientSampleCsv/PostServiceImportRecipientSampleCsv'
import { PostServiceImportRecipientSubmitButton } from '../PostServiceImportRecipientSubmitButton/PostServiceImportRecipientSubmitButton'

export interface PostServiceRecipient {
  error?: string[]
  id?: string | number
  companyName?: string
  firstname: string
  lastname: string
  callName?: string
  gender?: 'f' | 'm'
  street: string
  houseNumber: string
  orientationNumber?: string
  city: string
  country: string
  zipCode: string
  variable1?: string
  variable2?: string
  variable3?: string
  variable4?: string
  variable5?: string
  variable6?: string
  variable7?: string
  variable8?: string
  variable9?: string
  variable10?: string
}

export interface PostServiceImportRecipientModalButton {
  listName?: string
  listId: string
  reexecuteQuery?: () => void
  buttonText?: string
  buttonProps?: ButtonProps
}

const { Title } = Typography

export const PostServiceImportRecipientModalButton: FunctionComponent<PostServiceImportRecipientModalButton> =
  props => {
    const [open, toggleModal] = useToggleState()
    const [parsedData, setParsedData] = useState<PostServiceRecipient[]>([])

    const modalTitle = useMemo(
      () => (
        <span>
          Hromadný import adresátů do{' '}
          <span className="italic">{props.listName}</span>
        </span>
      ),
      [props.listName]
    )

    const errorCount = useMemo(
      () => parsedData.filter(item => !!item?.error?.length).length,
      [parsedData]
    )

    const footer = useMemo(
      () => (
        <div className="flex justify-end">
          <Button onClick={toggleModal}>Zrušit</Button>
          <PostServiceImportRecipientSubmitButton
            errorCount={errorCount}
            handleModal={toggleModal}
            listId={props.listId}
            parsedData={parsedData}
          />
        </div>
      ),
      [errorCount, toggleModal, parsedData, props.listId]
    )

    return (
      <>
        <Button
          onClick={toggleModal}
          size="small"
          className="sm:w-auto w-full"
          {...props.buttonProps}
        >
          {props.buttonText || 'Hromadný import adresátů'}
        </Button>
        <Modal
          title={modalTitle}
          open={open}
          onCancel={toggleModal}
          width={700}
          footer={footer}
        >
          <>
            <PostServiceImportRecipientSampleCsv />

            <PostServiceImportRecipientFormUploadFragment
              setParsedData={setParsedData}
              hiddenDropArea={parsedData.length > 0}
            />

            <Fade show={parsedData.length > 0}>
              <Title level={5} className="mt-4">
                Náhled importovaných adresátů
              </Title>
              <PostServiceImportRecipientPreviewTable data={parsedData} />
            </Fade>

            <PostServiceImportRecipientAlert errorCount={errorCount} />
            <Divider className="mt-4" />
          </>
        </Modal>
      </>
    )
  }
