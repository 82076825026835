import { createSlice } from '@reduxjs/toolkit'

import { LvGeometryApiResponse } from '../common/lvTypes'
import handleAsyncActions, {
  AsyncState,
  initialFetchState,
  PropertyDataTypes,
} from './fetchAction'

const lvGeometrySlice = createSlice({
  name: PropertyDataTypes.LV_GEOMETRY,
  initialState: initialFetchState as AsyncState<LvGeometryApiResponse>,
  reducers: {},
  extraReducers: builder => {
    handleAsyncActions<LvGeometryApiResponse>(
      builder,
      PropertyDataTypes.LV_GEOMETRY
    )
  },
})

export default lvGeometrySlice.reducer
