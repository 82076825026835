import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer'
import { format } from 'date-fns'
import React, { FunctionComponent, useMemo } from 'react'

import openSansBold from '../../../common/fonts/OpenSans-Bold.ttf'
import openSansItalic from '../../../common/fonts/OpenSans-Italic.ttf'
import openSans from '../../../common/fonts/OpenSans-Regular.ttf'
import openSansSemiBold from '../../../common/fonts/OpenSans-SemiBold.ttf'
import { Maybe, Monit } from '../../../graphql/generated'
import { czDateFormatDay } from '../../utils/dateFormat'
import { monitPdfCss } from '../../utils/monitPdfCss'
import { ImgSrc } from '../../utils/useMapImage'
import { useMonitDecompData } from '../../utils/useMonitDecompData'
import { useMonitPdfData } from '../../utils/useMonitPdfData'
import { formatCurrencyNumber } from '../Money/Money'
import { PriceEstimatePdfDecompFragment } from '../PriceEstimatePdfDecompFragment/PriceEstimatePdfDecompFragment'
import { PriceEstimatePdfLvFragment } from '../PriceEstimatePdfLvFragment/PriceEstimatePdfLvFragment'
import {
  PriceEstimateFormatedPriceData,
  PriceEstimatePdfPriceBoxFragment,
} from '../PriceEstimatePdfPriceBoxFragment/PriceEstimatePdfPriceBoxFragment'
import { PriceEstimatePdfPriceGraphFragment } from '../PriceEstimatePdfPriceGraphFragment/PriceEstimatePdfPriceGraphFragment'
import { PriceEstimatePdfPropertyFragment } from '../PriceEstimatePdfPropertyFragment/PriceEstimatePdfPropertyFragment'
import { PriceEstimatePdfPublicNoteFragment } from '../PriceEstimatePdfPublicNoteFragment/PriceEstimatePdfPublicNoteFragment'
import { PriceEstimatePdfRisyFragment } from '../PriceEstimatePdfRisyFragment/PriceEstimatePdfRisyFragment'

export interface PriceEstimatePdfProps {
  data?: Maybe<Partial<Monit>>
  imgSrc?: ImgSrc
}

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: openSans },
    { src: openSansBold, fontStyle: 'bold' },
    { src: openSansItalic, fontStyle: 'italic' },
    { src: openSansSemiBold, fontWeight: 600 },
  ],
})

export const PriceEstimatePdf: FunctionComponent<PriceEstimatePdfProps> =
  React.memo(props => {
    const data = useMonitPdfData(props.data)
    const decompData = useMonitDecompData(
      props.data?.decompNab,
      props.data?.decompReal
    )

    const priceData = useMemo<PriceEstimateFormatedPriceData>(
      () => ({
        cenaReal: formatCurrencyNumber(props.data?.cenaReal, 0),
        cenaNab: formatCurrencyNumber(props.data?.cenaNab, 0),
        cenaM2Real: formatCurrencyNumber(props.data?.cenaM2Real, 0),
        cenaM2Nab: formatCurrencyNumber(props.data?.cenaM2Nab, 0),
      }),
      [
        props.data?.cenaReal,
        props.data?.cenaNab,
        props.data?.cenaM2Real,
        props.data?.cenaM2Nab,
      ]
    )

    const metaData = useMemo(() => {
      const estimateToDate = format(
        props.data?.estimateToDate
          ? new Date(props.data?.estimateToDate)
          : new Date(),
        czDateFormatDay
      )
      const createdAt = format(
        props.data?.createdAt ? new Date(props.data?.createdAt) : new Date(),
        czDateFormatDay
      )
      const printedAt = format(new Date(), czDateFormatDay)
      const id = props.data?.id?.slice(0, 8)
      const model = props.data?.modelVersion
      const text = `Odhad k datu: ${estimateToDate}   |   Vytvořeno: ${createdAt}   |   Vytištěno: ${printedAt}   |   Model v. ${model}   |   ID odhadu: ${id}`
      return text
    }, [
      props.data?.createdAt,
      props.data?.estimateToDate,
      props.data?.id,
      props.data?.modelVersion,
    ])

    const risyData = useMemo(() => {
      try {
        if (props.data?.risyData) return JSON.parse(props.data.risyData)
      } catch (error) {
        return null
      }
      return null
    }, [props.data?.risyData])

    const lvData = useMemo(() => {
      try {
        if (props.data?.lvData) return JSON.parse(props.data.lvData)
      } catch (error) {
        return null
      }
      return null
    }, [props.data?.lvData])

    return (
      <Document>
        <Page size="A4" style={monitPdfCss.page}>
          <View wrap={false}>
            <Text style={monitPdfCss.header} fixed>
              Vytvořeno v REGESTA DATA 2.0 - regesta.cz
            </Text>
            <Text style={monitPdfCss.title}>{data?.title}</Text>

            <View style={monitPdfCss.imgSection}>
              {props.imgSrc?.map && <Image src={props.imgSrc.map} />}
              <Text style={monitPdfCss.w10p} />
              {props.imgSrc?.streetView && (
                <Image src={props.imgSrc.streetView} />
              )}
            </View>

            <PriceEstimatePdfLvFragment lvData={lvData} />
          </View>
          <PriceEstimatePdfPropertyFragment data={data} />
          <PriceEstimatePdfPublicNoteFragment note={props.data?.publicNote} />

          <View style={monitPdfCss.bottomInfoContainer}>
            <Text style={monitPdfCss.metaDataInfo}>{metaData}</Text>
          </View>
        </Page>

        <Page size="A4" style={monitPdfCss.page}>
          <View wrap={false}>
            <Text style={monitPdfCss.header} fixed>
              Vytvořeno v REGESTA DATA 2.0 - regesta.cz
            </Text>
            <View style={monitPdfCss.pageTitle}>
              <Text>INFORMACE O CENĚ</Text>
            </View>
            <PriceEstimatePdfPriceBoxFragment priceData={priceData} />
            <PriceEstimatePdfPriceGraphFragment
              graphImageString={props.data?.graphImageString}
              realPrice={!!priceData?.cenaReal}
            />
          </View>
          <PriceEstimatePdfDecompFragment
            priceData={priceData}
            decompData={decompData}
          />
          <View style={monitPdfCss.bottomInfoContainer}>
            <Text style={monitPdfCss.priceInfo}>
              Odhad ceny je založen na matematickém modelu MoniT. Model je
              výsledkem analýzy rozsáhlé databáze cen- nemovitostí pomocí
              pokročilých statistických metod. Výsledek modelu tedy představuje
              nejpravděpodobnějšícenu nemovitosti daných vlastností s
              přihlédnutím ke všem dostupným informacím o trhu a stavu
              posuzované nemovi- tosti. Pro podrobnosti viz
              https://diotima.cz/monit
            </Text>
          </View>
        </Page>

        <PriceEstimatePdfRisyFragment risyData={risyData} />
      </Document>
    )
  })
