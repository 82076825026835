import { Skeleton } from 'antd'
import React, { FunctionComponent } from 'react'

export interface CeeMonitorTableIdentificationItemProps {
  children: React.ReactNode
  title: string
  className?: string
  loading?: boolean
}

const skeletonProps = {
  active: true,
  title: false,
  paragraph: {
    rows: 1,
    className: 'flex justify-center mt-2',
  },
  loading: true,
}

export const CeeMonitorTableIdentificationItem: FunctionComponent<CeeMonitorTableIdentificationItemProps> =
  props => {
    return (
      <div className={'flex-grow ' + (props.className ? props.className : '')}>
        <div className="font-semibold">{props.title}</div>
        <div>
          {props.loading ? <Skeleton {...skeletonProps} /> : props.children}
        </div>
      </div>
    )
  }
