import { Checkbox, Typography } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import {
  AreaFilterUse,
  AreaFilterUseCodes,
} from '../../../common/foreclosureMonitoringTypes'
import { useMultipleAreaTypeRelations } from '../../utils/useAreaTypeRelations'
import { AreaTypeSelectionFilterCheckboxTooltip } from '../AreaTypeSelectionFilterCheckboxTooltip/AreaTypeSelectionFilterCheckboxTooltip'
import { AreaUseSelectionFilterCheckboxTooltip } from '../AreaUseSelectionFilterCheckboxTooltip/AreaUseSelectionFilterCheckboxTooltip'
import { NoResultBox } from '../NoResultBox/NoResultBox'
import { PropertyUseSelectionFilterCheckbox } from '../PropertyUseSelectionFilterCheckbox/PropertyUseSelectionFilterCheckbox'

export interface AreaUseSelectionFilterProps {
  checkboxData: AreaFilterUse
  filteredValues: AreaFilterUse
  disabledVisible?: boolean
  setCheckedValues: (value: React.SetStateAction<AreaFilterUseCodes>) => void
  checkedValues: AreaFilterUseCodes
  searchTerm: string
}

const Title = Typography.Title

export const AreaUseSelectionFilter: FunctionComponent<AreaUseSelectionFilterProps> =
  props => {
    const areaUseRelation = useMultipleAreaTypeRelations(
      props.checkedValues.areaType
    )

    const dataSource = useMemo(
      () =>
        props.filteredValues.areaUse.map(useType => ({
          ...useType,
          disabled: !areaUseRelation.includes(useType.kod),
        })),
      [props.filteredValues.areaUse, areaUseRelation]
    )

    return (
      <div
        id="monitoringAreaFilter"
        className="flex flex-wrap md:flex-nowrap gap-8"
      >
        <div className="w-full md:w-1/3 lg:w-1/4 md:pr-8 md:border-dashed md:border-r border-gray-300">
          <Title level={5} className="!mb-2">
            Druh parcely
          </Title>
          {!props.filteredValues?.areaType?.length ? (
            <NoResultBox />
          ) : (
            <Checkbox.Group
              className="!block columns-1 gap-8"
              value={props.checkedValues.areaType}
              onChange={checkedValues =>
                props.setCheckedValues(prev => {
                  return { ...prev, areaType: checkedValues as number[] } //
                })
              }
            >
              {props.filteredValues.areaType.map(useType => (
                <PropertyUseSelectionFilterCheckbox
                  useType={useType}
                  searchTerm={props.searchTerm}
                  key={useType.kod}
                >
                  <AreaTypeSelectionFilterCheckboxTooltip
                    areaTypeCode={useType.kod}
                    checkboxData={props.checkboxData}
                  />
                </PropertyUseSelectionFilterCheckbox>
              ))}
            </Checkbox.Group>
          )}
        </div>

        <div className="w-full md:w-2/3 lg:w-3/4">
          <Title level={5} className="!mb-2">
            Způsob využití
          </Title>
          {!dataSource?.length ? (
            <NoResultBox />
          ) : (
            <Checkbox.Group
              className="!block columns-1 md:columns-2 2xl:columns-3 gap-8"
              value={props.checkedValues.areaUse}
              onChange={checkedValues =>
                props.setCheckedValues(prev => {
                  return { ...prev, areaUse: checkedValues as number[] }
                })
              }
            >
              {dataSource.map(useType => (
                <PropertyUseSelectionFilterCheckbox
                  useType={useType}
                  searchTerm={props.searchTerm}
                  key={useType.kod}
                >
                  {useType.disabled && (
                    <AreaUseSelectionFilterCheckboxTooltip
                      areaUseCode={useType.kod}
                      checkboxData={props.checkboxData}
                    />
                  )}
                </PropertyUseSelectionFilterCheckbox>
              ))}
            </Checkbox.Group>
          )}
        </div>
      </div>
    )
  }
