import React, { FunctionComponent } from 'react'

import { OsNemIdentType } from '../../../common/cuzkLvTypes'
import {
  appendComa,
  appendSpace,
  notEmpty,
  safeMap,
} from '../../../common/pdfHelpers'
import { Identifier, RegisterGroupMap } from '../../utils/dynamicSearchTooltip'
import { CuzkLvHtmlJednotkaIdentFragment } from '../CuzkLvHtmlJednotkaIdentFragment/CuzkLvHtmlJednotkaIdentFragment'
import { CuzkLvHtmlOpravSubjektFragment } from '../CuzkLvHtmlOpravSubjektFragment/CuzkLvHtmlOpravSubjektFragment'
import { CuzkLvHtmlParcelaIdentFragment } from '../CuzkLvHtmlParcelaIdentFragment/CuzkLvHtmlParcelaIdentFragment'
import { CuzkLvHtmlPravoStavbyIdentFragment } from '../CuzkLvHtmlPravoStavbyIdentFragment/CuzkLvHtmlPravoStavbyIdentFragment'
import { CuzkLvHtmlStavbaIdentFragment } from '../CuzkLvHtmlStavbaIdentFragment/CuzkLvHtmlStavbaIdentFragment'
import { DynamicSearchTooltip } from '../DynamicSearchTooltip/DynamicSearchTooltip'

export interface CuzkLvHtmlOsNemIdentFragmentProps {
  osNemIdent?: OsNemIdentType
  active?: boolean
}

export const CuzkLvHtmlOsNemIdentFragment: FunctionComponent<CuzkLvHtmlOsNemIdentFragmentProps> =
  props => {
    const ident = props.osNemIdent

    if (notEmpty(ident?.oprav_subjekt?.opravnena_po)) {
      return (
        <>
          {safeMap(ident?.oprav_subjekt?.opravnena_po, (opravneny, index) => (
            <span key={index} style={{ wordBreak: 'break-all' }}>
              {appendSpace(opravneny?.nazev)}
              {appendSpace(opravneny?.bsm1_pred)}
              {appendSpace(opravneny?.bsm1_prijmeni)}
              {appendComa(opravneny?.bsm1_jmeno)}
              {opravneny?.bsm1_za}
              {opravneny?.bsm2_pred}
              {opravneny?.bsm2_prijmeni}
              {appendComa(opravneny?.bsm2_jmeno)}
              {opravneny?.bsm2_za}
              {opravneny?.titul_pred}
              {opravneny?.prijmeni}
              {appendComa(opravneny?.jmeno)}
              {opravneny?.titul_za}
              {opravneny?.titul_za ? '/' : ''}
              {appendSpace(opravneny?.adresa?.nazev_ulice)}
              {opravneny?.adresa?.cislo_domovni}
              {opravneny?.adresa?.cislo_orientacni ? '/' : ''}
              {appendComa(opravneny?.adresa?.cislo_orientacni)}
              {appendComa(opravneny?.adresa?.cast_obce)}
              {appendComa(opravneny?.adresa?.psc)}
              {opravneny?.adresa?.mestsky_obvod}
              {appendComa(opravneny?.adresa?.stat)}
              RČ/IČO: {opravneny?.rc6}
              {opravneny?.rc7 ? '/' : ''}
              {opravneny?.rc7}
              {opravneny?.ico_doplnek}
              {opravneny?.ico_doplnek ? '-' : ''}
              {opravneny?.ico}
              {', '}
            </span>
          ))}
        </>
      )
    }

    if (ident?.oprav_subjekt?.id) {
      return (
        <span>
          <CuzkLvHtmlOpravSubjektFragment
            opravSubjekt={ident?.oprav_subjekt}
            active={props.active}
          />
          {/* {appendSpace(ident?.oprav_subjekt?.nazev)}
          {appendSpace(ident?.oprav_subjekt?.titul_pred)}
          {appendSpace(ident?.oprav_subjekt?.prijmeni)}
          {appendComa(ident?.oprav_subjekt?.jmeno)}
          {ident?.oprav_subjekt?.titul_za}
          {ident?.oprav_subjekt?.titul_za ? '/' : ''}
          {safeMap(ident?.oprav_subjekt?.adresa, (adresa, index) => (
            <span key={index}>
              {appendSpace(adresa?.nazev_ulice)}
              {adresa?.cislo_domovni}
              {adresa?.cislo_orientacni ? '/' : ''}
              {appendComa(adresa?.cislo_orientacni)}
              {appendComa(adresa?.cast_obce)}
              {appendComa(adresa?.psc)}
              {adresa?.mestsky_obvod}
              {appendComa(adresa?.stat)}{' '}
            </span> 
          ))}*/}
          RČ/IČO:{' '}
          {props.active ? (
            <>
              <DynamicSearchTooltip
                identifier={{
                  [Identifier.RC]: `${
                    ident?.oprav_subjekt?.rc6 || '' + ident?.oprav_subjekt?.rc7
                  }`,
                }}
                identifierType={Identifier.RC}
                excludedRegisters={RegisterGroupMap.ČÚZK}
              >
                {ident?.oprav_subjekt?.rc6}
                {ident?.oprav_subjekt?.rc7 ? '/' : ''}
                {ident?.oprav_subjekt?.rc7}
              </DynamicSearchTooltip>
              <DynamicSearchTooltip
                identifier={{
                  [Identifier.ICO]: ident?.oprav_subjekt?.ico?.toString(),
                  [Identifier.COMPANY_NAME]:
                    ident?.oprav_subjekt?.nazev?.toString(),
                }}
                identifierType={Identifier.ICO}
                excludedRegisters={RegisterGroupMap.ČÚZK}
              >
                {ident?.oprav_subjekt?.ico_doplnek}
                {ident?.oprav_subjekt?.ico_doplnek ? '-' : ''}
                {ident?.oprav_subjekt?.ico}
              </DynamicSearchTooltip>
            </>
          ) : (
            <>
              {ident?.oprav_subjekt?.rc6}
              {ident?.oprav_subjekt?.rc7 ? '/' : ''}
              {ident?.oprav_subjekt?.rc7}
              {ident?.oprav_subjekt?.ico_doplnek}
              {ident?.oprav_subjekt?.ico_doplnek ? '-' : ''}
              {ident?.oprav_subjekt?.ico}
            </>
          )}
          {', '}
        </span>
      )
    }

    if (ident?.parcela?.id) {
      return (
        <CuzkLvHtmlParcelaIdentFragment
          active={props.active}
          breakLine
          parcelaIdent={ident?.parcela}
        />
      )
    }

    if (ident?.jednotka?.id) {
      return (
        <CuzkLvHtmlJednotkaIdentFragment
          jednotkaIdent={ident?.jednotka}
          breakLine
          active={props.active}
        />
      )
    }

    if (ident?.stavba?.id) {
      return (
        <CuzkLvHtmlStavbaIdentFragment
          active={props.active}
          stavbaIdent={ident?.stavba}
          breakLine
        />
      )
    }

    if (ident?.pravo_stavby?.id) {
      return (
        <CuzkLvHtmlPravoStavbyIdentFragment
          pravoStavbyIdent={ident?.pravo_stavby}
          breakLine
        />
      )
    } else {
      return null
    }
  }
