import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import React, { FunctionComponent } from 'react'

import {
  RestrictionCheckboxData,
  RestrictionChecType,
} from '../../../common/epoTypes'
import { CadastralSelectionModalButton } from '../CadastralSelectionModalButton/CadastralSelectionModalButton'

export interface CuzkEpoRestrictionCheckInfoPanelItemActionsHugeSubjectProps {
  epoId: string
  checkboxHugeData: RestrictionCheckboxData
  checkboxData?: RestrictionCheckboxData
  refetch: () => void
}

export const CuzkEpoRestrictionCheckInfoPanelItemActionsHugeSubject: FunctionComponent<CuzkEpoRestrictionCheckInfoPanelItemActionsHugeSubjectProps> =
  props => {
    return (
      <>
        {props.checkboxData?.checkedPercentage !== 100 ? (
          <CadastralSelectionModalButton
            epoId={props.epoId}
            checkboxData={props.checkboxHugeData.data}
            refetch={props.refetch}
            type={RestrictionChecType.restriction}
          />
        ) : (
          <Tooltip
            className="cursor-help"
            title="Ověřili jste výskyt práv 3. stran ve všech katastrálních pracovištích. Nyní si můžete vybrat LV, pro které chcete stáhnout detailní informace."
          >
            <QuestionCircleOutlined className="text-gray-400 mr-2" />
            <Button disabled>Vybrat kat. pracoviště</Button>
          </Tooltip>
        )}

        {!!props.checkboxData?.checkedPercentage &&
        !props.checkboxData?.allLvBought ? (
          <CadastralSelectionModalButton
            epoId={props.epoId}
            checkboxData={props.checkboxData.data}
            refetch={props.refetch}
            type={RestrictionChecType.lien}
          />
        ) : (
          <Tooltip
            className="cursor-help"
            title={
              !props.checkboxData?.checkedPercentage
                ? 'V prvním kroku je zapotřebí ověřit výskyt práv 3. stran ve vybraném katastrálním pracovišti, poté budete moct vybrat LV pro zobrazení detailu.'
                : `V doposud ověřených katastrálních pracovištích nebyly nalezeny žádné ${
                    props.checkboxData?.positiveLv ? 'další' : ''
                  } LV s právy 3. stran. Můžete ověřit ostatní katastrální pracoviště, abyste získali informace o zbylých LV.`
            }
          >
            <Button disabled>Vybrat LV</Button>
            <QuestionCircleOutlined className="text-gray-400 ml-2" />
          </Tooltip>
        )}
      </>
    )
  }
