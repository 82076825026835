import { useEffect, useMemo, useState } from 'react'

import { BASE_URL, GOOGLE_MAPS_KEY } from '../../../ini.json'
import buildingIconSrc from '../images/ikona_budova.png?url'
import unitIconSrc from '../images/ikona_jednotka.png?url'
import areaIconSrc from '../images/ikona_pozemek.png?url'

export interface ImgSrc {
  map: string
  streetView: string
  mapLink: string
  streetViewLink: string
}

export function useMapImage(
  lat?: number,
  lon?: number,
  type?: number,
  polygon?: string | null,
  reloadStreetView?: boolean
) {
  const [imgSrc, setImgSrc] = useState('')
  const color = type === 0 ? 'green' : 'red'
  const icon =
    type === 0
      ? BASE_URL + areaIconSrc
      : type === 2
      ? BASE_URL + unitIconSrc
      : BASE_URL + buildingIconSrc
  const path = polygon
    ? `&path=color:${color}|weight:3|fillcolor:${color}|${polygon}`
    : ''

  const mapImg = `https://maps.googleapis.com/maps/api/staticmap?size=1000x1000&scale=2&zoom=17&center=${lat},${lon}${path}&style=feature:poi.business|visibility:off&markers=icon:${icon}|${lat},${lon}&key=${GOOGLE_MAPS_KEY}`
  const streetViewImg = `https://maps.googleapis.com/maps/api/streetview?return_error_code=true&size=2000x2000&location=${lat},${lon}&fov=100&pitch=20&source=outdoor&key=${GOOGLE_MAPS_KEY}`
  const mapImgLink = `https://www.google.com/maps/?q=${lat},${lon}`
  const streetViewImgLink = `http://maps.google.com/maps?q=&layer=c&cbll=${lat},${lon}`

  useEffect(() => {
    if (!lat || !lon || (!reloadStreetView && imgSrc)) return

    async function getImage() {
      try {
        const response = await fetch(streetViewImg)
        if (response.status === 200) {
          setImgSrc(streetViewImg)
        } else
          setImgSrc(mapImg.replace('zoom=17', 'zoom=18') + '&maptype=satellite')
      } catch (error) {
        return console.error(
          'Chyba při načítání obrázku ze Street View:',
          error
        )
      }
    }
    getImage()
  }, [imgSrc, lat, lon, mapImg, reloadStreetView, streetViewImg])

  return useMemo(
    () => ({
      map: mapImg,
      streetView: imgSrc,
      mapLink: mapImgLink,
      streetViewLink: streetViewImgLink,
    }),
    [imgSrc, mapImg, mapImgLink, streetViewImgLink]
  )
}
