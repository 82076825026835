import { Button, Modal } from 'antd'
import React, { FunctionComponent } from 'react'

import { tablePaginationHiding } from '../../utils/layoutConst'
import { CuzkLvTable } from '../CuzkLvTable/CuzkLvTable'

const tableProps = {
  size: 'small' as const,
  bordered: false,
  pagination: tablePaginationHiding,
  summary: undefined,
  className: 'mb-2 mt-8',
}

export interface PropertyDetailLvDownloadedModalButtonProps {
  linkStyle?: boolean
  buttonText?: string
  idFilter: { in: string[] }
  refetch: () => void
  open?: boolean
  setOpen: () => void
}

export const PropertyDetailLvDownloadedModalButton: FunctionComponent<PropertyDetailLvDownloadedModalButtonProps> =
  props => {
    return (
      <div>
        <Button
          type={props.linkStyle ? 'link' : 'primary'}
          size={props.linkStyle ? 'small' : 'middle'}
          className={props.linkStyle ? 'hover:bg-gray-50' : ''}
          onClick={props.setOpen}
        >
          {props.buttonText || 'Stažené LV'}
        </Button>
        <Modal
          title="Stažené listy vlastnictví k této nemovitosti"
          open={props.open}
          onCancel={props.setOpen}
          footer={null}
          className="!max-w-fit !w-full"
        >
          <CuzkLvTable
            onActionRefetch={props.refetch}
            tableProps={tableProps}
            isPropertyDetail
            idFilter={props.idFilter}
          />
        </Modal>
      </div>
    )
  }
