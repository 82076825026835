import { DownloadOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useCallback } from 'react'

import { BASE_URL } from '../../../../ini.json'
import { useAppSelector } from '../../../redux/hooks'

export interface ExportTableXlsxButtonProps {
  dataSource: unknown[]
  url: string
  fileName: string
  buttonText?: string
  icon?: boolean
}

export const ExportTableXlsxButton: FunctionComponent<ExportTableXlsxButtonProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)
    const tenantId = useAppSelector(state => state.myProfile.tenantId)

    const exportToXlsx = useCallback(async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/api/${props.url}`,
          {
            tenantId,
            token,
            tableData: props.dataSource,
          },
          { responseType: 'blob' }
        )

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `REGESTA-DATA-export-${props.fileName}.xlsx`
        )
        document.body.appendChild(link)
        link.click()

        link?.parentNode?.removeChild(link)
        window.URL.revokeObjectURL(url)
      } catch (error) {
        message.error('Chyba při stahování souboru')
      }
    }, [props.dataSource, props.fileName, props.url, tenantId, token])

    return (
      <Button
        icon={props.icon && <DownloadOutlined />}
        type="link"
        size="small"
        title="Exportovat tabulku do .xlsx (excel)"
        onClick={exportToXlsx}
        download
        disabled={!props.dataSource.length}
      >
        {props.buttonText || 'Exportovat do XLSX'}
      </Button>
    )
  }
