import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import React, { FunctionComponent, useCallback } from 'react'

import { useAppSelector } from '../../../redux/hooks'
import {
  isRuianArea,
  isRuianBuilding,
  isRuianUnit,
} from '../../utils/ruianHelpers'

export interface CadastralViewButtonProps {
  buttonProps?: ButtonProps
}

export const CadastralViewButton: FunctionComponent<CadastralViewButtonProps> =
  props => {
    const ruianData = useAppSelector(state => state.property.ruian.data)

    const handleOnClick = useCallback(() => {
      const url = new URL('https://nahlizenidokn.cuzk.cz/ZobrazObjekt.aspx')
      url.searchParams.set('rr', 'true') //parameter for manual opening detection

      if (isRuianBuilding(ruianData)) {
        url.searchParams.set('typ', 'budova')
        url.searchParams.set('id', `${ruianData?.isknBudovaId}`)
      } else if (isRuianArea(ruianData)) {
        url.searchParams.set('typ', 'parcela')
        url.searchParams.set('id', `${ruianData?.kod}`)
      } else if (isRuianUnit(ruianData)) {
        url.searchParams.set('typ', 'budova')
        url.searchParams.set('id', `${ruianData?.vBudove?.isknBudovaId}`)
        url.searchParams.set('otevritJednotku', `${ruianData?.cisloJednotky}`)
      }

      const drawerWidth = Math.min(500, window.innerWidth * 0.75)
      const leftPosition = window.innerWidth - drawerWidth - 800

      window.open(
        url,
        'oknonahlizeni',
        `width=800,height=${window.outerHeight},left=${leftPosition},top=0`
      )
    }, [ruianData])

    return (
      <Button
        disabled={!ruianData?.type}
        type="link"
        {...props.buttonProps}
        onClick={handleOnClick}
      >
        Náhled v KN
      </Button>
    )
  }
