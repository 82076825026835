import React, { FunctionComponent } from 'react'

import { OneLineHelp } from '../OneLineHelp/OneLineHelp'

export const CuzkLvHtmlTooManyOwnersInfo: FunctionComponent = () => {
  return (
    <div className="text-gray-400">
      <OneLineHelp>
        Pro zachování větší přehlednosti vykreslených dat zobrazujeme v grafu
        pouze názvy prvních 10 subjektů s největšími podíly. Informace o
        ostatních subjektech získáte po najetí myší na výseč v koláčovém grafu.
      </OneLineHelp>
    </div>
  )
}
