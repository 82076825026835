import React, { FunctionComponent } from 'react'

import { RestrictionCheckboxData } from '../../../common/epoTypes'
import { CuzkEpoRestrictionCheckPanelInitialCheck as InitialCheck } from '../CuzkEpoRestrictionCheckPanelInitialCheck/CuzkEpoRestrictionCheckPanelInitialCheck'
import { CuzkEpoRestrictionCheckPanelNotFound as NotFound } from '../CuzkEpoRestrictionCheckPanelNotFound/CuzkEpoRestrictionCheckPanelNotFound'
import { CuzkEpoRestrictionCheckPanelSearchLien as SearchLien } from '../CuzkEpoRestrictionCheckPanelSearchLien/CuzkEpoRestrictionCheckPanelSearchLien'
import { CuzkEpoRestrictionCheckPanelSearchRestriction as SearchRestriction } from '../CuzkEpoRestrictionCheckPanelSearchRestriction/CuzkEpoRestrictionCheckPanelSearchRestriction'

export interface CuzkEpoRestrictionCheckPanelProps {
  epoId: string
  isHugeSubject: boolean
  hasBeenChecked: boolean
  refetch: () => void
  checkboxData: RestrictionCheckboxData
}

export const CuzkEpoRestrictionCheckPanel: FunctionComponent<CuzkEpoRestrictionCheckPanelProps> =
  props => {
    return (
      <div className="my-8 mx-auto flex flex-col max-w-lg justify-center align-middle bg-white">
        <div className="mb-2 mt-2 text-center flex flex-col justify-center space-x-2">
          {!!props.checkboxData?.inProgress?.length &&
          props.hasBeenChecked &&
          !props.isHugeSubject ? (
            <SearchLien
              epoId={props.epoId}
              checkboxData={props.checkboxData.data}
              refetch={props.refetch}
            />
          ) : !!props.checkboxData?.inProgress?.length &&
            props.hasBeenChecked ? (
            <SearchRestriction
              epoId={props.epoId}
              checkboxData={props.checkboxData.data}
              refetch={props.refetch}
            />
          ) : !props.hasBeenChecked ? (
            <InitialCheck
              isHugeSubject={props.isHugeSubject}
              epoId={props.epoId}
              refetch={props.refetch}
            />
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    )
  }
