import { CheckCircleOutlined } from '@ant-design/icons'
import { Alert, Modal, Spin, Tag } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { useCuzkOByIdQuery } from '../../../graphql/generated'
import { useToggleState } from '../../utils/useToggleState'
import { CuzkMonitorRcTableExpandedRow } from '../CuzkMonitorRcTableExpandedRow/CuzkMonitorRcTableExpandedRow'

export interface CeeMonitorTableCuzkStatusFoundModalProps {
  id?: string
}

export const CeeMonitorTableCuzkStatusFoundModal: FunctionComponent<CeeMonitorTableCuzkStatusFoundModalProps> =
  props => {
    const [open, toggleOpen] = useToggleState()

    const [queryResult, fetchQuery] = useCuzkOByIdQuery({
      pause: true,
      variables: { id: props?.id || '' },
    })

    const handleOnClick = useCallback(() => {
      toggleOpen()
      if (!!props?.id && !queryResult.data?.cuzkOById?.id) {
        fetchQuery()
      }
    }, [toggleOpen, props?.id, queryResult.data?.cuzkOById?.id, fetchQuery])

    const modalContent = useMemo(() => {
      if (!open || !props.id) return null
      if (queryResult.data?.cuzkOById)
        return (
          <CuzkMonitorRcTableExpandedRow
            cuzkOs={queryResult.data.cuzkOById}
            className="my-6"
          />
        )
      if (queryResult.fetching) return <Spin />
      return (
        <Alert
          message="Je nám líto, ale nepodařilo se získat detailní informace z registru ČÚZK."
          type="error"
        />
      )
    }, [open, props.id, queryResult.data?.cuzkOById, queryResult.fetching])

    return (
      <>
        <Tag
          onClick={handleOnClick}
          icon={<CheckCircleOutlined />}
          color="green"
          className="hover:underline cursor-pointer"
        >
          Nalezeno
        </Tag>
        <Modal
          title="Výběr způsobu využití"
          open={open}
          onCancel={toggleOpen}
          footer={null}
          width={1200}
          className="!max-w-fit"
        >
          {modalContent}
        </Modal>
      </>
    )
  }
