import { InfoCircleOutlined } from '@ant-design/icons'
import { Alert, Table } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import {
  DrmBudovaResponse,
  Jednotka,
  PROPERTY_TYPES,
} from '../../../common/drmTypes'
import { useAppSelector } from '../../../redux/hooks'
import { densePagination } from '../../utils/layoutConst'
import { usePropertiesLabels } from '../../utils/usePropertiesLabels'
import { PropertyDetailBuildingUnitsColumn } from '../PropertyDetailBuildingUnitsColumn/PropertyDetailBuildingUnitsColumn'

export const PropertyDetailBuildingUnitsTable: FunctionComponent = () => {
  const drmDetail = useAppSelector(
    state => state.property.drm.data as DrmBudovaResponse
  )

  const dataSource = useMemo(() => {
    if (!drmDetail?.jednotky?.length) return []

    const grouped = drmDetail.jednotky.reduce<Record<string, Jednotka[]>>(
      (acc, { cisloJednotky, id }) => {
        const [houseNumber, unitNumber] = (cisloJednotky || '').split('/')
        if (!acc[houseNumber]) {
          acc[houseNumber] = []
        }
        acc[houseNumber].push({ id, cisloJednotky: unitNumber })
        return acc
      },
      {}
    )

    return Object.entries(grouped).map(([houseNumber, units], index) => ({
      key: index.toString(),
      houseNumber: houseNumber,
      units: units.sort(
        (a, b) =>
          (a.cisloJednotky ? Number(a.cisloJednotky) : 0) -
          (b.cisloJednotky ? Number(b.cisloJednotky) : 0)
      ),
    }))
  }, [drmDetail?.jednotky])

  const unitsIds = useMemo(
    () => dataSource.flatMap(building => building.units.map(unit => unit.id)),
    [dataSource]
  )

  const { propertyLabels } = usePropertiesLabels(unitsIds, PROPERTY_TYPES.UNIT)

  const renderUnits = useCallback(
    (units: Jednotka[]) => (
      <PropertyDetailBuildingUnitsColumn
        propertyLabels={propertyLabels}
        units={units}
      />
    ),
    [propertyLabels]
  )

  const columns = useMemo(
    () => [
      {
        title: 'Č.p.',
        dataIndex: 'houseNumber',
        key: 'houseNumber',
      },
      {
        title: <div className="mt-2">Číslo jednotky</div>,
        dataIndex: 'units',
        key: 'units',
        className: '!pt-0',
        render: renderUnits,
      },
    ],
    [renderUnits]
  )

  if (!dataSource.length)
    return (
      <Alert
        className="grayscale !text-gray-500"
        message="V budově se nachází více bytů, které však nejsou evidovány jako samostatné jednotky v KN."
        type="success"
        showIcon
        icon={<InfoCircleOutlined />}
      />
    )

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={densePagination}
      size="small"
    />
  )
}
