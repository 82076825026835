import { Breakpoint, Table } from 'antd'
import { ColumnsType } from 'antd/es/table/interface'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { ColorPalleteKey } from '../../utils/collorPallete'
import { stringSorter, utcDateSorter } from '../../utils/generalTableHelpers'
import { IconPalleteKey } from '../../utils/iconPallete'
import { tablePagination } from '../../utils/layoutConst'
import { labelDetailPath } from '../../utils/paths'
import { AddNewLabelButton } from '../AddNewLabelButton/AddNewLabelButton'
import { Min } from '../DateFormat/DateFormat'
import { Label } from '../Label/Label'
import { LabelTableAction } from '../LabelTableAction/LabelTableAction'

export interface LabelTableItem {
  id: string
  name: string
  description?: string
  iconKey?: IconPalleteKey
  colorKey?: ColorPalleteKey
  editedAt?: Date
  createdAt: Date
  labelConnectionsByLabelId: { totalCount: number }
}

export interface LabelTableProps {
  refetch?: () => void
  data?: LabelTableItem[]
}

const tableId = 'lable-table'

export const LabelTable: FunctionComponent<LabelTableProps> = props => {
  const [dataSource, setDataSource] = useState<LabelTableItem[]>([])

  useEffect(() => {
    if (props.data?.length) {
      setDataSource(props.data as LabelTableItem[])
    }
  }, [props.data])

  const columns = useMemo<ColumnsType<LabelTableItem>>(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        responsive: ['' as Breakpoint],
      },
      {
        title: 'Vytvořeno',
        dataIndex: 'createdAt',
        className: 'w-40',
        defaultSortOrder: 'descend',
        sorter: utcDateSorter('createdAt'),
        render: createdAt => <Min>{createdAt}</Min>,
      },
      {
        title: 'Štítek',
        dataIndex: 'name',
        sorter: stringSorter('name'),
        render: (_, record) => (
          <Link to={labelDetailPath(record.id)}>
            <Label
              disableTooltip
              colorKey={record.colorKey || undefined}
              iconKey={record.iconKey || undefined}
              description={record?.description || undefined}
              name={record.name || undefined}
            />
          </Link>
        ),
      },
      {
        title: 'Popis',
        dataIndex: 'description',
        sorter: stringSorter('description'),
      },
      {
        title: 'Počet přiřazení',
        dataIndex: 'labelConnections',
        className: 'w-36 text-right pr-2',
        sorter: (a, b) =>
          a.labelConnectionsByLabelId.totalCount -
          b.labelConnectionsByLabelId.totalCount,
        render: (_, record) => record.labelConnectionsByLabelId.totalCount || 0,
      },
      {
        title: 'Akce',
        dataIndex: 'actions',
        className: 'w-48',
        render: (_, record) => (
          <LabelTableAction record={record} refetch={props.refetch} />
        ),
      },
    ],
    [props.refetch]
  )

  const locale = useMemo(
    () => ({
      emptyText: !false && (
        <div className="my-8">
          <div className="mb-2 text-gray-400">
            Zatím jste nevytvořili žádný štítek.
          </div>
          <AddNewLabelButton refetch={props.refetch} />
        </div>
      ),
    }),
    [props.refetch]
  )

  return (
    <Table
      id={tableId}
      rowKey="id"
      dataSource={[...dataSource]}
      columns={columns}
      locale={locale}
      pagination={tablePagination}
      size="small"
    />
  )
}
