import { InfoCircleOutlined } from '@ant-design/icons'
import { Alert, Button, Divider } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { useDeclension } from '../../utils/useDeclension'

interface CeeMonitorAddSubjectModalFooterProps {
  onClose: () => void
  onSubmit: () => void
  toggleHelp: () => void
  showHelp: boolean
  uploading: boolean
  subjectCount: {
    forCheck: number
    invalid: number
  }
}

export const CeeMonitorAddSubjectModalFooter: FunctionComponent<CeeMonitorAddSubjectModalFooterProps> =
  ({ onClose, onSubmit, toggleHelp, showHelp, uploading, subjectCount }) => {
    const decline = useDeclension()

    const validationText = useMemo(
      () => (
        <>
          {decline(subjectCount.forCheck, 'willCheck')}{' '}
          <span className="font-bold">{subjectCount.forCheck}</span>{' '}
          {decline(subjectCount.forCheck, 'subject')}.
          {!!subjectCount.invalid && (
            <span className="text-gray-500">
              {' '}
              {subjectCount.invalid}{' '}
              {decline(subjectCount.invalid, 'notValidOut')}.
            </span>
          )}
        </>
      ),

      [decline, subjectCount.forCheck, subjectCount.invalid]
    )

    return (
      <div className="flex flex-col items-end">
        <Alert
          className={`!px-2 !py-1 !text-left !w-full`}
          message={validationText}
          type="success"
        />
        <Divider className="!my-5" />
        <div className="flex space-x-2">
          <Button
            icon={<InfoCircleOutlined />}
            type="link"
            onClick={toggleHelp}
          >
            {showHelp ? 'Skrýt nápovědu' : 'Zobrazit nápovědu'}
          </Button>
          <Button
            onClick={onClose}
            className="sm:w-auto w-full"
            disabled={uploading}
          >
            Zavřít
          </Button>
          <Button
            onClick={onSubmit}
            className="sm:w-auto w-full"
            type="primary"
            disabled={uploading}
            loading={uploading}
          >
            Souhlasím a dokončit
          </Button>
        </div>
      </div>
    )
  }
