import * as Sentry from '@sentry/react'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { createClient, Provider } from 'urql'

import { BASE_URL_SERVER, SENTRY_DSN } from '../../ini.json'
import { AUCTION_TYPES } from '../common/auctionScrapeTypes'
import { PROPERTY_TYPES } from '../common/drmTypes'
import { AdminLayout } from './components/AdminLayout/AdminLayout'
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute'
import Address from './pages/Address'
import Area from './pages/Area'
import { AuctionMap } from './pages/AuctionMap'
import AuctionMonitoring from './pages/AuctionMonitoring'
import CadastrePostLetter from './pages/CadastrePostLetter'
import CadastrePostSent from './pages/CadastrePostSent'
import CeeCheck from './pages/CeeCheck'
import CeeMonitor from './pages/CeeMonitor'
import CeeMonitorCreate from './pages/CeeMonitorCreate'
import CuzkDocumentCollection from './pages/CuzkDocumentCollection'
import CuzkEpo from './pages/CuzkEpo'
import CuzkEpoDetail from './pages/CuzkEpoDetail'
import CuzkLv from './pages/CuzkLv'
import CuzkLvDetail from './pages/CuzkLvDetail'
import CuzkMonitorRc from './pages/CuzkMonitorRc'
import CuzkMonitorRcCreate from './pages/CuzkMonitorRcCreate'
import Dashboard from './pages/Dashboard'
import DataBox from './pages/DataBox'
import { ForeclosureMap } from './pages/ForeclosureMap'
import ForeclosureMonitoring from './pages/ForeclosureMonitoring'
import HeritageMonitoring from './pages/HeritageMonitoring'
import { InsolvencyMap } from './pages/InsolvencyMap'
import InsolvencyMonitoring from './pages/InsolvencyMonitoring'
import Label from './pages/Label'
import LabelDetail from './pages/LabelDetail'
import Lv from './pages/Lv'
import Marketplace from './pages/Marketplace'
import MarketplaceStatistics from './pages/MarketplaceStatistics'
import NotFound from './pages/NotFound'
import PostService from './pages/PostService'
import PostServiceLetter from './pages/PostServiceLetter'
import PostServiceRecipientList from './pages/PostServiceRecipientList'
import PostServiceSent from './pages/PostServiceSent'
import PostServiceSentByList from './pages/PostServiceSentByList'
import PostServiceTemplate from './pages/PostServiceTemplate'
import PriceEstimate from './pages/PriceEstimate'
import PriceEstimateDetail from './pages/PriceEstimateDetail'
import Proceeding from './pages/Proceeding'
import PropertyDetail from './pages/PropertyDetail'
import PropertyNote from './pages/PropertyNote'
import { RuianMap } from './pages/RuianMap'
import Statistics from './pages/Statistics'
import WatchdogChannel from './pages/WatchdogChannel'
import WatchdogProperty from './pages/WatchdogProperty'
import {
  addressDetailPath,
  areaPath,
  auctionMapPath,
  auctionMonitoringPath,
  cadastreMapPath,
  cadastrePostLetterPath,
  cadastrePostSentPath,
  ceeCheckPath,
  ceeMonitorCreatePath,
  ceeMonitorPath,
  cuzkDocumentCollectionFetchPath,
  cuzkDocumentCollectionPath,
  cuzkEpoDetailPath,
  cuzkEpoPath,
  cuzkLvDetailPath,
  cuzkLvDownloadPath,
  cuzkLvPath,
  cuzkMonitorRcCreatePath,
  cuzkMonitorRcPath,
  dataBoxDetailPath,
  dataBoxPath,
  foreclosureMapPath,
  foreclosureMonitoringPath,
  heritageMonitoringPath,
  homePath,
  insolvencyMapPath,
  insolvencyMonitoringPath,
  labelDetailPath,
  labelPath,
  lvDetailPath,
  marketplacePath,
  marketplaceStatisticsPath,
  postServiceCreatePath,
  postServiceLetterPath,
  postServiceRecipientListPath,
  postServiceRecipientPath,
  postServiceSentListPath,
  postServiceSentPath,
  postServiceTemplatesPath,
  priceEstimateDetailPath,
  priceEstimatePath,
  proceedingDetailPath,
  propertyDetailPath,
  propertyNotePath,
  statisticsPath,
  watchdogChannelPath,
  watchdogPropertyPath,
} from './utils/paths'

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/data2\.regesta\.cz/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const client = createClient({
  url: `${BASE_URL_SERVER}/graphql`,
  suspense: false,
  requestPolicy: 'cache-and-network',
})

function App(): JSX.Element {
  return (
    <Provider value={client}>
      <AdminLayout>
        <Route>
          <Switch>
            <PrivateRoute exact path={homePath()} component={Dashboard} />
            <PrivateRoute
              exact
              path={propertyDetailPath(
                ':propertyType' as PROPERTY_TYPES,
                ':id'
              )}
              component={PropertyDetail}
            />
            <PrivateRoute
              exact
              path={lvDetailPath(':katuze?', ':lv?')}
              component={Lv}
            />
            <PrivateRoute
              exact
              path={ceeMonitorPath()}
              component={CeeMonitor}
            />
            <PrivateRoute exact path={ceeCheckPath()} component={CeeCheck} />
            <PrivateRoute
              path={ceeMonitorCreatePath(':monitoring?')}
              component={CeeMonitorCreate}
            />
            <PrivateRoute
              exact
              path={cuzkMonitorRcPath()}
              component={CuzkMonitorRc}
            />
            <PrivateRoute
              path={cuzkMonitorRcCreatePath()}
              component={CuzkMonitorRcCreate}
            />
            <PrivateRoute
              path={postServiceRecipientPath(':id')}
              component={PostService}
            />
            <PrivateRoute
              path={cadastrePostLetterPath(':id')}
              component={CadastrePostLetter}
            />
            <PrivateRoute
              exact
              path={cadastrePostSentPath()}
              component={CadastrePostSent}
            />
            <PrivateRoute
              exact
              path={postServiceLetterPath(':recipientListId?', ':templateId?')}
              component={PostServiceLetter}
            />

            <PrivateRoute
              exact
              path={postServiceTemplatesPath()}
              component={PostServiceTemplate}
            />
            <PrivateRoute
              exact
              path={postServiceSentPath()}
              component={PostServiceSent}
            />
            <PrivateRoute
              exact
              path={postServiceCreatePath(':create?')}
              component={PostServiceSent}
            />
            <PrivateRoute
              exact
              path={postServiceSentListPath(':id')}
              component={PostServiceSentByList}
            />
            <PrivateRoute
              exact
              path={postServiceRecipientListPath()}
              component={PostServiceRecipientList}
            />
            <PrivateRoute
              exact
              path={marketplacePath()}
              component={Marketplace}
            />

            <PrivateRoute
              exact
              hideBreadcrumb
              path={cadastreMapPath()}
              component={RuianMap}
            />
            <PrivateRoute
              exact
              hideBreadcrumb
              path={insolvencyMapPath()}
              component={InsolvencyMap}
            />
            <PrivateRoute
              exact
              hideBreadcrumb
              path={foreclosureMapPath()}
              component={ForeclosureMap}
            />
            <PrivateRoute
              exact
              hideBreadcrumb
              path={auctionMapPath()}
              component={AuctionMap}
            />

            <PrivateRoute
              exact
              path={auctionMonitoringPath(':tab?' as AUCTION_TYPES)}
              component={AuctionMonitoring}
            />
            <PrivateRoute
              exact
              path={foreclosureMonitoringPath(':tab?' as PROPERTY_TYPES)}
              component={ForeclosureMonitoring}
            />
            <PrivateRoute
              exact
              path={insolvencyMonitoringPath(':tab?' as PROPERTY_TYPES)}
              component={InsolvencyMonitoring}
            />
            <PrivateRoute
              exact
              path={heritageMonitoringPath()}
              component={HeritageMonitoring}
            />
            <PrivateRoute
              exact
              path={marketplaceStatisticsPath()}
              component={MarketplaceStatistics}
            />

            <PrivateRoute
              exact
              path={statisticsPath()}
              component={Statistics}
            />
            <PrivateRoute exact path={dataBoxPath()} component={DataBox} />
            <PrivateRoute
              exact
              path={dataBoxDetailPath(':urlQuery?')}
              component={DataBox}
            />
            <PrivateRoute
              exact
              path={proceedingDetailPath(':urlQuery?')}
              component={Proceeding}
            />
            <PrivateRoute
              exact
              path={addressDetailPath(':urlQuery?')}
              component={Address}
            />
            <PrivateRoute exact path={areaPath()} component={Area} />
            <PrivateRoute exact path={cuzkEpoPath()} component={CuzkEpo} />
            <PrivateRoute
              exact
              path={cuzkEpoDetailPath(':id')}
              component={CuzkEpoDetail}
            />
            <PrivateRoute exact path={cuzkLvPath()} component={CuzkLv} />
            <PrivateRoute
              exact
              path={cuzkLvDownloadPath(':katuze', ':lv')}
              component={CuzkLv}
            />
            <PrivateRoute
              exact
              path={cuzkLvDetailPath(':id')}
              component={CuzkLvDetail}
            />
            <PrivateRoute
              exact
              path={labelDetailPath(':id')}
              component={LabelDetail}
            />
            <PrivateRoute exact path={labelPath()} component={Label} />
            <PrivateRoute
              exact
              path={propertyNotePath()}
              component={PropertyNote}
            />
            <PrivateRoute
              exact
              path={watchdogChannelPath()}
              component={WatchdogChannel}
            />
            <PrivateRoute
              exact
              path={watchdogPropertyPath(':channelId?')}
              component={WatchdogProperty}
            />
            <PrivateRoute
              exact
              path={priceEstimateDetailPath(':type', ':id')}
              component={PriceEstimateDetail}
            />
            <PrivateRoute
              exact
              path={priceEstimatePath()}
              component={PriceEstimate}
            />
            <PrivateRoute
              exact
              path={cuzkDocumentCollectionPath()}
              component={CuzkDocumentCollection}
            />
            <PrivateRoute
              path={cuzkDocumentCollectionFetchPath(':proceeding', ':id')}
              component={CuzkDocumentCollection}
            />
            <PrivateRoute component={NotFound} />
          </Switch>
        </Route>
      </AdminLayout>
    </Provider>
  )
}

export default App
