import { Checkbox } from 'antd'
import React, { FunctionComponent } from 'react'

import { CadastralWorkplace } from '../../utils/cuzkHelpers'
import { Money } from '../Money/Money'

export interface CadastreSelectionWorkplaceCheckboxProps {
  workplace: CadastralWorkplace
  searchTerm?: string
}

export const CadastreSelectionWorkplaceCheckbox: FunctionComponent<CadastreSelectionWorkplaceCheckboxProps> =
  props => {
    return (
      <Checkbox
        key={props.workplace.kod}
        value={props.workplace.kod}
        rootClassName="[&_span:nth-child(2)]:w-full group odd:bg-gray-50"
        disabled={!!props.workplace.disabled}
        className={
          props.searchTerm?.length &&
          props.workplace.nazev
            .toLowerCase()
            .includes(props.searchTerm.toLowerCase())
            ? `!bg-green-100 !ml-0`
            : '!ml-0'
        }
      >
        <div className="flex justify-between">
          <div>{props.workplace.nazev}</div>
          {props.workplace.pocetLV &&
            (props.workplace.disabled ? (
              <div>{props.workplace.description}</div>
            ) : (
              <div className="flex justify-between space-x-3">
                <div className="hidden group-hover:block animate-fadeIn text-gray-400">
                  <Money
                    amount={props.workplace.cena}
                    symbol="Kr."
                    decimalNumbers={0}
                  />
                </div>
                <div>{props.workplace.pocetLV} LV</div>
              </div>
            ))}
        </div>
      </Checkbox>
    )
  }
