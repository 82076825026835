import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { cuzkDocumentCollectionState as state } from '../../utils/handleResponseStatus'
import { Money } from '../Money/Money'

export interface CuzkDocumentPriceProps {
  state: string
  price?: number
}

export const CuzkDocumentPrice: FunctionComponent<CuzkDocumentPriceProps> =
  props => {
    const stateTag = useMemo(() => {
      switch (props.state) {
        case state.ceka:
          return (
            <Tooltip
              className="text-gray-400 cursor-help"
              title="ČÚZK zpracovává požadavek, čas zpracování je závislý na vytížení ČÚZK a velikosti souboru. Po dokončení akce bude cena zobrazena."
            >
              <span>
                Vypočítává se
                <QuestionCircleOutlined className="ml-2" />
              </span>
            </Tooltip>
          )
        case state.vytvariSe:
        case state.podepisujeSe:
          return (
            <Tooltip title="ČÚZK zpracovává požadovaný dokument, čas zpracování je závislý na vytížení ČÚZK a velikosti souboru.">
              <span>
                Vypočítává se
                <QuestionCircleOutlined className="ml-2" />
              </span>
            </Tooltip>
          )
        case state.zpracovan:
          return (
            <Tooltip
              className="cursor-help"
              title={`Stažení dokumentu je zpoplatněno ${props.price} Kč, cena je stanovena dle aktuálního ceníku ČÚZK. Částka vám bude účtována přímo ve vašem WSDP účtu.`}
            >
              <Money decimalNumbers={0} amount={props.price} symbol="Kč" />
              <div className="text-gray-400 flex space-x-2">
                Nezaúčtováno
                <QuestionCircleOutlined className="ml-2" />
              </div>
            </Tooltip>
          )
        case state.kDispozici:
        case state.zauctovan:
          return (
            <Tooltip
              className="text-gray-400 cursor-help"
              title="Poplatek vám ČÚZK již zaúčtovali ve vašem WSDP účtě."
            >
              <Money decimalNumbers={0} amount={props.price} symbol="Kč" />
              <div className="text-gray-400 flex space-x-2">
                Zaúčtováno ČÚZK
                <QuestionCircleOutlined className="ml-2" />
              </div>
            </Tooltip>
          )
        case state.info:
        case state.naVyzadani:
          return (
            <Tooltip
              className="text-gray-400 cursor-help"
              title="Pro zjištění ceny je nejprve potřeba načíst detailní informace o listině."
            >
              <div className="text-gray-400 flex space-x-2">
                Nezjištěno
                <QuestionCircleOutlined className="ml-2" />
              </div>
            </Tooltip>
          )

        case state.chybaPriVytvareni:
        case state.chybaPriZpracovani:
          return (
            <Tooltip
              className="text-gray-400 cursor-help"
              title="Došlo k chybě na straně ČÚZK."
            >
              <div className="text-gray-400 flex space-x-2">
                ---
                <QuestionCircleOutlined className="ml-2" />
              </div>
            </Tooltip>
          )

        case state.zrusen:
          return (
            <Tooltip title="Sestava byla ve vašem ČÚZK účtě označena za smazanou.">
              <div className="text-gray-400 flex space-x-2">
                ---
                <QuestionCircleOutlined className="ml-2" />
              </div>
            </Tooltip>
          )

        default:
          return <div className="text-gray-400 flex space-x-2">---</div>
      }
    }, [props.price, props.state])

    return <>{stateTag}</>
  }
