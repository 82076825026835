import React, { FunctionComponent } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { useFetchPropertyDetail } from '../../utils/useFetchPropertyDetail'
import { CadastreMapDetailDrawerFetchError } from '../CadastreMapDetailDrawerFetchError/CadastreMapDetailDrawerFetchError'
import { DetailDrawerPropertyContent } from '../DetailDrawerPropertyContent/DetailDrawerPropertyContent'
import { DetailDrawerPropertyImage } from '../DetailDrawerPropertyImage/DetailDrawerPropertyImage'
import { DetailDrawerPropertySkeleton } from '../DetailDrawerPropertySkeleton/DetailDrawerPropertySkeleton'
import { DetailDrawerPropertyTitle } from '../DetailDrawerPropertyTitle/DetailDrawerPropertyTitle'

export interface PropertyTableDetailDrawerProps {
  propertyId: string | null
  propertyType: PROPERTY_TYPES
}

export const PropertyTableDetailDrawer: FunctionComponent<PropertyTableDetailDrawerProps> =
  props => {
    const [propertyFetched] = useFetchPropertyDetail(
      props.propertyId || '',
      props.propertyType
    )
    if (!props.propertyId) return <CadastreMapDetailDrawerFetchError />
    if (!propertyFetched) return <DetailDrawerPropertySkeleton />

    return (
      <>
        <DetailDrawerPropertyTitle
          propertyId={props.propertyId}
          propertyType={props.propertyType}
        />

        <DetailDrawerPropertyImage
          propertyId={props.propertyId}
          propertyType={props.propertyType}
        />

        <DetailDrawerPropertyContent propertyId={props.propertyId} />
      </>
    )
  }
