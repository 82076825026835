import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { OperationContext } from 'urql'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { useAppSelector } from '../../../redux/hooks'
import { propertyDetailPath } from '../../utils/paths'
import { PropertyDetailNote } from '../../utils/propertyDetailNoteHelpers'
import { PropertyDetailNoteDeleteButton } from '../PropertyDetailNoteDeleteButton/PropertyDetailNoteDeleteButton'
import { PropertyDetailNoteEditButton } from '../PropertyDetailNoteEditButton/PropertyDetailNoteEditButton'

export interface PropertyDetailNoteFooterProps {
  note: PropertyDetailNote
  refetch: (opts?: Partial<OperationContext> | undefined) => void
  target?: React.HTMLAttributeAnchorTarget
}

export const PropertyDetailNoteFooter: FunctionComponent<PropertyDetailNoteFooterProps> =
  props => {
    const userId = useAppSelector(state => state.myProfile.userId)

    return (
      <div className="flex justify-between text-gray-400 border-gray-400 border-dashed border-t mt-1 pt-1">
        <div>
          Přidal uživatel #{props.note.accountUserId?.slice(-6)} při prohlížení{' '}
          {/* TODO: note author name */}
          <Link
            className="!text-gray-400 hover:!text-primaryHover"
            to={propertyDetailPath(
              props.note.propertyType as PROPERTY_TYPES,
              props.note.propertyId
            )}
            target={props.target}
          >
            {props.note.propertyName}
          </Link>
        </div>
        {userId === props.note.accountUserId && (
          <div className="flex justify-end space-x-2">
            <PropertyDetailNoteEditButton
              note={props.note}
              refetch={props.refetch}
            />
            <PropertyDetailNoteDeleteButton
              noteId={props.note.id}
              refetch={props.refetch}
            />
          </div>
        )}
      </div>
    )
  }
