import { combineReducers, configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'

import addressAresSliceReducer from './addressAresSlice'
import drawerSliceReducer from './drawerSlice'
import drmSubjectsSliceReducer from './drmSubjectsSlice'
import expandedRowSliceReducer from './expandedRowSlice'
import { PropertyDataTypes, WatchDogDataTypes } from './fetchAction'
import lvDetailSliceReducer from './lvDetailSlice'
import myProfileSliceReducer from './myProfileSlice'
import propertyCrmDrmSliceReducer from './propertyCrmDrmSlice'
import propertyDrmDetailSliceReducer from './propertyDrmDetailSlice'
import propertyLvDetailSliceReducer from './propertyLvDetailSlice'
import propertyLvGeometrySliceReducer from './propertyLvGeometrySlice'
import propertyRisySliceReducer from './propertyRisyDetailSlice'
import propertyRuianDetailSliceReducer from './propertyRuianDetailSlice'
import propertyTimelineSliceReducer from './propertyTimelineSlice'
import rzpSubjectsSliceReducer from './rzpSubjectsSlice'
import watchdogChannelSliceReducer from './watchdogChannelSlice'
import watchdogLastChannelSliceReducer from './watchdogLastChannelSlice'
import watchdogPropertySliceReducer from './watchdogPropertySlice'

const sentryReduxEnhancer = Sentry.createReduxEnhancer()

const propertyReducer = combineReducers({
  [PropertyDataTypes.ARES]: addressAresSliceReducer,
  [PropertyDataTypes.RISY]: propertyRisySliceReducer,
  [PropertyDataTypes.LV]: propertyLvDetailSliceReducer,
  [PropertyDataTypes.CRM]: propertyCrmDrmSliceReducer,
  [PropertyDataTypes.DRM]: propertyDrmDetailSliceReducer,
  [PropertyDataTypes.DRM_SUBJECTS]: drmSubjectsSliceReducer,
  [PropertyDataTypes.RZP_SUBJECTS]: rzpSubjectsSliceReducer,
  [PropertyDataTypes.TIMELINE]: propertyTimelineSliceReducer,
  [PropertyDataTypes.RUIAN]: propertyRuianDetailSliceReducer,
  [PropertyDataTypes.LV_GEOMETRY]: propertyLvGeometrySliceReducer,
})

const watchdogReducer = combineReducers({
  [WatchDogDataTypes.PROPERTY]: watchdogPropertySliceReducer,
  [WatchDogDataTypes.CHANNEL]: watchdogChannelSliceReducer,
  [WatchDogDataTypes.LAST_CHANNEL]: watchdogLastChannelSliceReducer,
})

const store = configureStore({
  reducer: {
    property: propertyReducer,
    drawer: drawerSliceReducer,
    lvDetail: lvDetailSliceReducer,
    myProfile: myProfileSliceReducer,
    expandedRow: expandedRowSliceReducer,
    watchdog: watchdogReducer,
  },
  enhancers: defaultEnhancers => {
    return defaultEnhancers.concat(sentryReduxEnhancer)
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export default store
