export const europeCountries = [
  { iso: 'AL', cs: 'Albánie', en: 'Albania' },
  { iso: 'AD', cs: 'Andorra', en: 'Andorra' },
  { iso: 'BE', cs: 'Belgie', en: 'Belgium' },
  { iso: 'BY', cs: 'Bělorusko', en: 'Belarus' },
  { iso: 'BA', cs: 'Bosna a Hercegovina', en: 'Bosnia and Herzegovina' },
  { iso: 'BG', cs: 'Bulharsko', en: 'Bulgaria' },
  { iso: 'ME', cs: 'Černá Hora', en: 'Montenegro' },
  { iso: 'CZ', cs: 'Česká republika', en: 'Czechia' },
  { iso: 'DK', cs: 'Dánsko', en: 'Denmark' },
  { iso: 'EE', cs: 'Estonsko', en: 'Estonia' },
  { iso: 'FO', cs: 'Faerské ostrovy', en: 'Faroe Islands' },
  { iso: 'FI', cs: 'Finsko', en: 'Finland' },
  { iso: 'FR', cs: 'Francie', en: 'France' },
  { iso: 'HR', cs: 'Chorvatsko', en: 'Croatia' },
  { iso: 'IE', cs: 'Irsko', en: 'Ireland' },
  { iso: 'IS', cs: 'Island', en: 'Iceland' },
  { iso: 'IT', cs: 'Itálie', en: 'Italy' },
  { iso: 'XK', cs: 'Kosovo', en: 'Kosovo' },
  { iso: 'CY', cs: 'Kypr', en: 'Cyprus' },
  { iso: 'LI', cs: 'Lichtenštejnsko', en: 'Liechtenstein' },
  { iso: 'LT', cs: 'Litva', en: 'Lithuania' },
  { iso: 'LV', cs: 'Lotyšsko', en: 'Latvia' },
  { iso: 'LU', cs: 'Lucembursko', en: 'Luxembourg' },
  { iso: 'HU', cs: 'Maďarsko', en: 'Hungary' },
  { iso: 'MT', cs: 'Malta', en: 'Malta' },
  { iso: 'MD', cs: 'Moldavsko', en: 'Moldova' },
  { iso: 'DE', cs: 'Německo', en: 'Germany' },
  { iso: 'NL', cs: 'Nizozemsko', en: 'Netherlands' },
  { iso: 'NO', cs: 'Norsko', en: 'Norway' },
  { iso: 'PL', cs: 'Polsko', en: 'Poland' },
  { iso: 'PT', cs: 'Portugalsko', en: 'Portugal' },
  { iso: 'AT', cs: 'Rakousko', en: 'Austria' },
  { iso: 'RO', cs: 'Rumunsko', en: 'Romania' },
  { iso: 'RU', cs: 'Rusko', en: 'Russian Federation' },
  { iso: 'GR', cs: 'Řecko', en: 'Greece' },
  { iso: 'SM', cs: 'San Marino', en: 'San Marino' },
  { iso: 'MK', cs: 'Severní Makedonie', en: 'North Macedonia' },
  { iso: 'SK', cs: 'Slovensko', en: 'Slovakia' },
  { iso: 'SI', cs: 'Slovinsko', en: 'Slovenia' },
  { iso: 'RS', cs: 'Srbsko', en: 'Serbia' },
  { iso: 'ES', cs: 'Španělsko', en: 'Spain' },
  { iso: 'SJ', cs: 'Špicberky a Jan Mayen', en: 'Svalbard and Jan Mayen' },
  { iso: 'SE', cs: 'Švédsko', en: 'Sweden' },
  { iso: 'CH', cs: 'Švýcarsko', en: 'Switzerland' },
  { iso: 'UA', cs: 'Ukrajina', en: 'Ukraine' },
  { iso: 'VA', cs: 'Vatikán', en: 'Holy See' },
  { iso: 'GB', cs: 'Velká Británie', en: 'United Kingdom of Great Britain' },
]
