import { Button } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import {
  CadastralCheckedValues,
  CadastralOffice,
} from '../../utils/cuzkHelpers'
import { CadastralWorkplaceSelectionFooterCounter } from '../CadastralWorkplaceSelectionFooterCounter/CadastralWorkplaceSelectionFooterCounter'

export interface CadastralWorkplaceSelectionFooterProps {
  checkboxData: CadastralOffice[]
  checkedValues: CadastralCheckedValues
  toggleModalVisibility: () => void
  onSubmit: (selected: number[] | null) => void
  unitPrice?: number
}

export const CadastralWorkplaceSelectionFooter: FunctionComponent<CadastralWorkplaceSelectionFooterProps> =
  ({
    checkboxData,
    checkedValues,
    unitPrice,
    onSubmit,
    toggleModalVisibility,
  }) => {
    const selectedWorkplacesKod = useMemo(() => {
      const result: number[] = []

      Object.values(checkedValues).forEach(workplaces => {
        result.push(...workplaces)
      })

      return result
    }, [checkedValues])

    const selectedCounter = useMemo(() => {
      let workplacesCount = 0
      let outOfTotal = 0
      checkboxData.forEach(office => {
        if (office.pracoviste.length > 0) {
          office.pracoviste.forEach(workplace => {
            if (checkedValues[office.kod]?.includes(workplace.kod)) {
              workplacesCount++
            }
            outOfTotal++
          })
        }
      })
      return {
        workplacesCount,
        outOfTotal,
        price: unitPrice || 0 * workplacesCount,
      }
    }, [checkboxData, checkedValues, unitPrice])

    const handleSubmit = useCallback(() => {
      const isSelectedAll =
        selectedCounter.workplacesCount === selectedCounter.outOfTotal
      onSubmit(isSelectedAll ? null : selectedWorkplacesKod)
      toggleModalVisibility()
    }, [
      onSubmit,
      selectedCounter.outOfTotal,
      selectedCounter.workplacesCount,
      selectedWorkplacesKod,
      toggleModalVisibility,
    ])

    return (
      <div className="flex justify-end items-center space-x-2">
        <CadastralWorkplaceSelectionFooterCounter selected={selectedCounter} />

        <Button onClick={toggleModalVisibility}>Zrušit</Button>
        <Button
          onClick={handleSubmit}
          type="primary"
          disabled={!selectedCounter.workplacesCount}
        >
          Uložit nastavení
        </Button>
      </div>
    )
  }
