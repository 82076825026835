import {
  AppstoreOutlined,
  BarsOutlined,
  MergeCellsOutlined,
} from '@ant-design/icons'
import { Segmented } from 'antd'
import React, { FunctionComponent, memo, useCallback, useMemo } from 'react'

import { TABLE_VIEW } from '../../../common/foreclosureMonitoringTypes'

export interface ForeclosureMonitoringViewTypeProps {
  setViewType: (viewType: TABLE_VIEW) => void
  withCompact?: boolean
}

export const PropertyMonitoringViewType: FunctionComponent<ForeclosureMonitoringViewTypeProps> =
  memo(props => {
    const options = useMemo(
      () => [
        {
          label: <span className="hidden md:inline">Tabulka</span>,
          value: TABLE_VIEW.TABLE,
          icon: <BarsOutlined />,
        },
        ...(props.withCompact
          ? [
              {
                label: <span className="hidden md:inline">Přehled</span>,
                value: TABLE_VIEW.COMPACT_TABLE,
                icon: <MergeCellsOutlined rotate={90} />,
              },
            ]
          : []),
        {
          label: <span className="hidden md:inline">Náhledy</span>,
          value: TABLE_VIEW.TILES,
          icon: <AppstoreOutlined />,
        },
      ],
      [props.withCompact]
    )

    const handleChange = useCallback(
      (value: string | number) => props.setViewType(value as TABLE_VIEW),
      [props]
    )

    return (
      <Segmented
        size="large"
        className="!-mb-1"
        onChange={handleChange}
        options={options}
      />
    )
  })
