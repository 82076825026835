import { StepProps, Steps } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

export interface PostServiceLetterCreateStepsProps {
  percent?: number
  status1?: 'wait' | 'process' | 'finish' | 'error'
  status2?: 'wait' | 'process' | 'finish' | 'error'
  status3?: 'wait' | 'process' | 'finish' | 'error'
  current: number
  className?: string
}

export const PostServiceLetterCreateSteps: FunctionComponent<PostServiceLetterCreateStepsProps> =
  props => {
    const items = useMemo<StepProps[]>(
      () => [
        {
          subTitle: 'KOMU?',
          title: 'KROK',
          status: props.status1,
          icon:
            props.status1 === 'finish' ||
            (props.current === 1 && props.percent === 100) ? null : (
              <div
                className={
                  'flex h-6 w-6 relative text-sm items-center ' +
                  (props.current === 1
                    ? 'justify-center text-gray-700'
                    : 'justify-end text-gray-400')
                }
              >
                1.
              </div>
            ),
          description: (
            <span className="font-normal text-gray-400 max-w-full">
              V prvním kroku vytváříte a vybíráte seznam adresátů, které chcete
              obeslat.
            </span>
          ),
        },
        {
          subTitle: 'CO?',
          title: 'KROK',
          status: props.status2,
          icon:
            props.status2 === 'finish' ||
            (props.current === 2 && props.percent === 100) ? null : (
              <div
                className={
                  'flex h-6 w-6 relative text-sm items-center ' +
                  (props.current === 2
                    ? 'justify-center text-gray-700'
                    : 'justify-end text-gray-400')
                }
              >
                2.
              </div>
            ),
          description: (
            <span className="font-normal text-gray-400 max-w-full break-all">
              V druhém kroku máte k dispozici interaktivní editor pro
              personifikovaní dopisů.
            </span>
          ),
        },
        {
          subTitle: 'JAK?',
          title: 'KROK',
          status: props.status3,
          icon:
            props.status3 === 'finish' ||
            (props.current === 3 && props.percent === 100) ? null : (
              <div
                className={
                  'flex h-6 w-6 relative text-sm items-center ' +
                  (props.current === 3
                    ? 'justify-center text-gray-700'
                    : 'justify-end text-gray-400')
                }
              >
                3.
              </div>
            ),
          description: (
            <span className="font-normal text-gray-400">
              Nakonec vybíráte typ doručení. Nejpozději další prac. den se pošta
              rozesílá.
            </span>
          ),
        },
      ],
      [props]
    )

    return (
      <Steps
        status="process"
        size="small"
        className={'font-bold text-gray-700 ' + (props.className || '')}
        current={props.current - 1}
        percent={props.percent}
        items={items}
      />
    )
  }
