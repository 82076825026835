import { Tabs } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { AUCTION_TYPES } from '../../common/auctionScrapeTypes'
import { TABLE_VIEW } from '../../common/foreclosureMonitoringTypes'
import { AuctionDetailDrawer } from '../components/AuctionDetailDrawer/AuctionDetailDrawer'
import { AuctionMonitoringTab } from '../components/AuctionMonitoringTab/AuctionMonitoringTab'
import { PageLayout } from '../components/PageLayout/PageLayout'
import { PropertyMonitoringViewType } from '../components/PropertyMonitoringViewType/PropertyMonitoringViewType'
import { TabLabel } from '../components/TabLabel/TabLabel'
import { auctionMonitoringPath } from '../utils/paths'

const tabBarStyle = { marginBottom: 0 }
const labelClassName = 'text-base text-center block w-28'

export const AuctionMonitoring: FunctionComponent = () => {
  const { params } = useRouteMatch<{ tab: AUCTION_TYPES }>()
  const history = useHistory()

  const [viewType, setViewType] = useState(TABLE_VIEW.TABLE)

  const handleOnTabClick = useCallback(
    (key: string) => {
      history.push(auctionMonitoringPath(key as AUCTION_TYPES))
    },
    [history]
  )

  const tabItems = useMemo(
    () => [
      {
        key: AUCTION_TYPES.BUILDING,
        label: <TabLabel title="Budovy" className={labelClassName} />,
        children: (
          <AuctionMonitoringTab
            viewType={viewType}
            auctionType={AUCTION_TYPES.BUILDING}
          />
        ),
      },
      {
        key: AUCTION_TYPES.AREA,
        label: <TabLabel title="Pozemky" className={labelClassName} />,
        children: (
          <AuctionMonitoringTab
            viewType={viewType}
            auctionType={AUCTION_TYPES.AREA}
          />
        ),
      },
      {
        key: AUCTION_TYPES.UNIT,
        label: <TabLabel title="Jednotky" className={labelClassName} />,
        children: (
          <AuctionMonitoringTab
            viewType={viewType}
            auctionType={AUCTION_TYPES.UNIT}
          />
        ),
      },
      {
        key: AUCTION_TYPES.UNKNOWN,
        label: <TabLabel title="Ostatní" className={labelClassName} />,
        children: (
          <AuctionMonitoringTab
            viewType={viewType}
            auctionType={AUCTION_TYPES.UNKNOWN}
          />
        ),
      },
    ],
    [viewType]
  )

  return (
    <>
      <PageLayout title="Monitoring dražeb - výpis" isNew isContainer>
        <Tabs
          type="card"
          size="large"
          tabBarGutter={6}
          items={tabItems}
          activeKey={params.tab || AUCTION_TYPES.BUILDING}
          tabBarStyle={tabBarStyle}
          onTabClick={handleOnTabClick}
          tabBarExtraContent={
            <PropertyMonitoringViewType setViewType={setViewType} />
          }
        />
      </PageLayout>
      <AuctionDetailDrawer />
    </>
  )
}

export default AuctionMonitoring
