import React, { FunctionComponent } from 'react'

import { PARCELY_ZE } from '../../../common/cuzkLvTypes'
import { notEmpty, safeMap } from '../../../common/pdfHelpers'
import { CuzkLvHtmlParcelaIdentFragment } from '../CuzkLvHtmlParcelaIdentFragment/CuzkLvHtmlParcelaIdentFragment'

export interface CuzkLvHtmlSekceParcelyZEFragmentProps {
  parcely?: PARCELY_ZE
  active?: boolean
}

export const CuzkLvHtmlSekceParcelyZEFragment: FunctionComponent<CuzkLvHtmlSekceParcelyZEFragmentProps> =
  props => {
    if (!notEmpty(props.parcely?.ZDROJ_PARCEL_ZE)) {
      return null
    }
    return (
      <>
        {safeMap(props.parcely?.ZDROJ_PARCEL_ZE, (ZDROJ, index) => (
          <div key={index}>
            <div className="row">
              <i>
                &nbsp;Pozemky ve zjednodušené evidenci - parcely původ{' '}
                {ZDROJ.ZDROJ_ZE.pozemek_nazev} {ZDROJ.ZDROJ_ZE.pozemek_zkratka}
              </i>
            </div>
            <div className="row">
              <div className="col-xs-2">
                <i>Parcela</i>
              </div>
              <div className="col-xs-1">
                <i>Díl</i>
              </div>
              <div className="col-xs-1">
                <i>Typ</i>
              </div>
              <div className="col-xs-2 text-right">
                <i className="pr-30">Výměra [m2]</i>
              </div>
              <div className="col-xs-6">
                <i>Původní kat. území</i>
              </div>
            </div>
            <hr className="mt-0 mb-0" />
            {safeMap(
              ZDROJ?.PARCELY_ZE_A_PK?.PARCELA_ZE,
              (PARCELA_ZE, index) => (
                <div key={index} className="row bold mt-10">
                  <div className="col-xs-2">
                    {PARCELA_ZE?.PLOMBA_PARCELY_1
                      ? PARCELA_ZE?.PLOMBA_PARCELY_1 + ' '
                      : ''}
                    <CuzkLvHtmlParcelaIdentFragment
                      active={props.active}
                      parcelaIdent={PARCELA_ZE?.PARCELNI_CISLO_ZE?.parcela}
                      noLabel
                    />
                  </div>
                  <div className="col-xs-1 text-right pr-30">
                    {PARCELA_ZE.VYMERA_PARCELY_ZE}
                  </div>
                  <div className="col-xs-1">
                    {PARCELA_ZE?.DIL_PARCELY_ZE?.toString()}
                  </div>
                  <div className="col-xs-6">
                    {PARCELA_ZE?.PUV_NAZEV_KU?.nazev}
                  </div>
                </div>
              )
            )}
          </div>
        ))}
      </>
    )
  }
