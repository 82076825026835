import { QuestionCircleOutlined, SyncOutlined } from '@ant-design/icons'
import { Button, ButtonProps, message, Popconfirm, Tag, Tooltip } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { BASE_URL } from '../../../../ini.json'
import { CollectionType } from '../../../common/documentCollectionTypes'
import { useAppSelector } from '../../../redux/hooks'
import { cuzkDocumentCollectionState as state } from '../../utils/handleResponseStatus'
import { isHugeSubjectLimit } from '../../utils/layoutConst'
import { cuzkEpoDetailPath, cuzkLvDetailPath } from '../../utils/paths'
import { pricelist } from '../../utils/pricelist'
import { useConst } from '../../utils/useConst'
import { CuzkDocumentCollectionCancelButton } from '../CuzkDocumentCollectionCancelButton/CuzkDocumentCollectionCancelButton'
import { CuzkEpoXlsxButton } from '../CuzkEpoXlsxButton/CuzkEpoXlsxButton'
import { LocalSavedPdf } from '../LocalSavedPdf/LocalSavedPdf'
import { LocalSavedPdfDrawerPreview } from '../LocalSavedPdfDrawerPreview/LocalSavedPdfDrawerPreview'
import { formatCurrencyNumber } from '../Money/Money'

export interface CuzkDocumentCollectionActionProps {
  state: string
  id: string
  cuzkCollectionId: string
  price?: number
  collectionType?: CollectionType
  disablePdf?: boolean
  disableDetail?: boolean
  buttonProps?: ButtonProps
  refetch: () => void
}

export const CuzkDocumentCollectionAction: FunctionComponent<CuzkDocumentCollectionActionProps> =
  props => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const [loading, setLoading] = useState(false)

    const getDocument = useCallback(async () => {
      setLoading(true)
      const fetchUrl =
        props.collectionType === CollectionType.LV
          ? `${BASE_URL}/api/cuzk/vratSestavuLv`
          : `${BASE_URL}/api/cuzk/vratSestavu`

      try {
        const response = await fetch(fetchUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            tenantId: tenantId,
            cuzkCollectionId: props.cuzkCollectionId,
            collectionType: props.collectionType,
            id: props.id,
          }),
        })

        if (response.status === 200) {
          return props.refetch()
        }
        return false
      } catch (error) {
        message.error('Nepodařilo se stáhnout listinu.')
      } finally {
        setLoading(false)
      }
      return false
    }, [props, tenantId])

    const pdfUrl = useMemo(
      () => `${BASE_URL}/download/${tenantId}/${props.id}.pdf`,
      [props.id, tenantId]
    )
    const buttonGhost = useConst({ ghost: true, ...props.buttonProps })

    const DetailButton = useMemo(() => {
      if (props.collectionType === CollectionType.LV)
        return (
          <Link to={cuzkLvDetailPath(props.id)}>
            <Button type="primary" {...props.buttonProps}>
              Zobrazit detail
            </Button>
          </Link>
        )

      if (props.collectionType === CollectionType.EPO)
        return (
          <Link to={cuzkEpoDetailPath(props.id)}>
            <Button
              ghost={props.disableDetail}
              type="primary"
              {...props.buttonProps}
            >
              Zobrazit detail
            </Button>
          </Link>
        )

      return null
    }, [props.buttonProps, props.collectionType, props.disableDetail, props.id])

    const XlsxButton = useMemo(() => {
      const TooltipContent = `Pro subjekty, jejichž práva se vztahují k více než ${formatCurrencyNumber(
        isHugeSubjectLimit,
        0
      )} LV, negenerujeme PDF dokumenty, ale exportujeme data ve formátu xlsx (excelovská tabulka). Tento formát je dostupný pro všechny subjekty po rozkliknutí detailu.`

      return (
        <>
          <CuzkEpoXlsxButton
            id={props.id}
            buttonText="Stáhnout XLSX"
            icon={null}
          />
          <Tooltip title={TooltipContent}>
            <QuestionCircleOutlined className="text-gray-400 cursor-help" />
          </Tooltip>
        </>
      )
    }, [props.id])

    const PdfDrawerPreview = useMemo(() => {
      return (
        <LocalSavedPdfDrawerPreview
          buttonProps={buttonGhost}
          drawerTitle="Náhled PDF - ČÚZK dokument"
          fileName={props.cuzkCollectionId + ' CUZK_DOKUMENT_REGESTA_DATA'}
          path={pdfUrl}
        >
          <LocalSavedPdf path={pdfUrl} />
        </LocalSavedPdfDrawerPreview>
      )
    }, [props.cuzkCollectionId, pdfUrl, buttonGhost])

    const stateTag = useMemo(() => {
      switch (props.state) {
        case state.ceka:
        case state.chybaPriVytvareni:
        case state.chybaPriZpracovani:
          return (
            <div className="flex space-x-2 items-center">
              <Button disabled type="primary" {...props.buttonProps}>
                Stáhnout
              </Button>

              <CuzkDocumentCollectionCancelButton
                id={props.id}
                refetch={props.refetch}
                collectionType={props.collectionType}
                buttonProps={props.buttonProps}
              />
            </div>
          )
        case state.stahujeSe:
        case state.generujeSePdf:
        case state.vytvariSe:
        case state.podepisujeSe:
          return (
            <Tooltip title="ČÚZK zpracovává požadovaný dokument, čas zpracování je závislý na vytížení ČÚZK a velikosti souboru.">
              <Tag color="blue" icon={<SyncOutlined spin />}>
                {props.state}
              </Tag>
            </Tooltip>
          )
        case state.zpracovan:
          return (
            <div className="flex space-x-2 items-center">
              <Popconfirm
                okText="Souhlasím"
                title={
                  <>
                    {props.collectionType !== CollectionType.EPO &&
                      props.collectionType !== CollectionType.LV && (
                        <div>
                          Stažení dokumentu je zpoplatněno{' '}
                          {pricelist.CUZK_LISTINA} Kr.
                        </div>
                      )}
                    <div>
                      Katastrální poplatek je dle aktuálního ceníku ČÚZK{' '}
                      {props.price} Kč.
                    </div>
                    <div>
                      Částka vám bude účtována přímo ve vašem WSDP účtu.
                    </div>
                  </>
                }
                onConfirm={getDocument}
              >
                <Button type="primary" {...props.buttonProps}>
                  Stáhnout
                </Button>
              </Popconfirm>
              <CuzkDocumentCollectionCancelButton
                refetch={props.refetch}
                id={props.id}
                collectionType={props.collectionType}
                buttonProps={props.buttonProps}
              />
            </div>
          )

        case state.kDispozici:
          return (
            <div className="flex space-x-2 items-center">
              {DetailButton}
              {props.disablePdf ? XlsxButton : PdfDrawerPreview}
            </div>
          )

        case state.zauctovan:
          return props.collectionType === CollectionType.LV ? (
            <Button
              type="primary"
              loading={loading}
              onClick={getDocument}
              {...props.buttonProps}
            >
              Stáhnout
            </Button>
          ) : (
            <LocalSavedPdfDrawerPreview
              buttonProps={buttonGhost}
              drawerTitle="Náhled PDF - listina v řízení"
              fileName={props.cuzkCollectionId + ' CUZK_LISTINA_REGESTA_DATA'}
              path={pdfUrl}
            >
              <LocalSavedPdf path={pdfUrl} />
            </LocalSavedPdfDrawerPreview>
          )

        case state.zrusen:
          return (
            <Tooltip title="Sestava byla ve vašem ČÚZK účtě označena za smazanou.">
              <Tag color="warning">{props.state}</Tag>
            </Tooltip>
          )

        default:
          return <Tag color="default">{props.state}</Tag>
      }
    }, [
      DetailButton,
      PdfDrawerPreview,
      XlsxButton,
      buttonGhost,
      getDocument,
      loading,
      pdfUrl,
      props.buttonProps,
      props.collectionType,
      props.cuzkCollectionId,
      props.disablePdf,
      props.id,
      props.price,
      props.refetch,
      props.state,
    ])

    return <>{stateTag}</>
  }
