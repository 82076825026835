import { createSlice } from '@reduxjs/toolkit'

import { RisyData } from '../common/risyTypes'
import handleAsyncActions, {
  AsyncState,
  initialFetchState,
  PropertyDataTypes,
} from './fetchAction'

export const risySlice = createSlice({
  name: PropertyDataTypes.RISY,
  initialState: initialFetchState as AsyncState<RisyData>,
  reducers: {},
  extraReducers: builder => {
    handleAsyncActions<RisyData>(builder, PropertyDataTypes.RISY)
  },
})

export default risySlice.reducer
