import axios from 'axios'
import { useCallback } from 'react'

import { PropertyDataTypes } from '../../redux/fetchAction'
import { useAppSelector } from '../../redux/hooks'
import { requestLvGeometryPath } from './paths'
import { useDispatchFetch } from './useDispatchFetch'

// TODO: remove after fix invalid JSON response from API
export function fixInvalidGeometryJson(jsonString: string): string {
  let formattedJson = jsonString.replace(/\{/g, '{ ').replace(/\}/g, ' }')
  formattedJson = formattedJson.replace(/,\s*\}/g, '}')
  formattedJson = formattedJson.replace(/,\s*\]/g, ']')
  return formattedJson
}

export const useFetchPropertyLvGeometry = () => {
  const { token, tenantId } = useAppSelector(state => state.myProfile)
  const { dispatchError, dispatchFetching, dispatchSuccess } =
    useDispatchFetch()

  const useFetchPropertyLvGeometry = useCallback(
    async (katastralniUzemiKod?: string | number, lv?: string | number) => {
      if (!token || !tenantId || !katastralniUzemiKod || !lv)
        return dispatchError(PropertyDataTypes.LV_GEOMETRY)
      dispatchFetching(PropertyDataTypes.LV_GEOMETRY)

      try {
        const response = await axios.get(
          `${requestLvGeometryPath()}${tenantId}/${token}/${katastralniUzemiKod}/${lv}`
        )
        // TODO: remove after fix invalid JSON response from API
        const data =
          typeof response.data === 'string'
            ? JSON.parse(fixInvalidGeometryJson(response.data))
            : response.data

        dispatchSuccess(PropertyDataTypes.LV_GEOMETRY, data)
      } catch (error) {
        dispatchError(PropertyDataTypes.LV_GEOMETRY)
      }
    },
    [dispatchError, dispatchFetching, dispatchSuccess, tenantId, token]
  )

  return useFetchPropertyLvGeometry
}
