import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { FunctionComponent } from 'react'

import { BASE_URL } from '../../../../ini.json'
export interface CsvHeader {
  Společnost: string
  'Křestní jméno': string
  Příjmení: string
  Pohlaví: 'm' | 'f'
  Oslovení: string
  'Název Ulice': string
  'Číslo popisné': string
  'Číslo orientační': string
  PSČ: string
  Obec: string
  'Kód státu': string
  'Proměnná 1': string
  'Proměnná 2': string
  'Proměnná 3': string
  'Proměnná 4': string
  'Proměnná 5': string
  'Proměnná 6': string
  'Proměnná 7': string
  'Proměnná 8': string
  'Proměnná 9': string
  'Proměnná 10': string
}

export const PostServiceImportRecipientSampleCsv: FunctionComponent = () => {
  return (
    <div className="my-4">
      Pro hromadné nahrání adresátů je zapotřebí dodržet strukturu a formátování
      vzorového CSV dokumentu.
      <a
        href={`${BASE_URL}/download/REGESTA_ukazka_import_adresati.csv`}
        download
      >
        <Button
          type="primary"
          ghost
          icon={<DownloadOutlined />}
          className="mt-2 w-full"
        >
          Vzorový CSV dokument
        </Button>
      </a>
    </div>
  )
}
