import { arcgisToGeoJSON } from '@terraformer/arcgis'
import * as turf from '@turf/turf'
import arcgisPbfDecode from 'arcgis-pbf-parser'
import {
  FeatureCollection,
  Geometry,
  MultiPolygon,
  Point,
  Polygon,
} from 'geojson'

import { EsriFieldDomain } from '../types/esri'
import { RuianLayer } from '../types/RuianLayers'
import { RuianParcela } from '../types/RuianParcela'
import { RuianParcelaDefinicniBod } from '../types/RuianParcelaDefinicniBod'
import { RuianStavebnihoObjektuDefinicniBod } from '../types/RuianStavebnihoObjektuDefinicniBod'
import { RuianStavebniObjekt } from '../types/RuianStavebniObjekt'

export class RuianQuery<T, G extends Geometry = Geometry> {
  private url: URL
  private searchParams: URLSearchParams

  constructor(layerId: string) {
    this.url = new URL('https://ags.cuzk.cz')
    this.url.pathname = `/arcgis/rest/services/RUIAN/Prohlizeci_sluzba_nad_daty_RUIAN/MapServer`
    this.url.pathname += `/${layerId}/query`

    this.searchParams = new URLSearchParams()

    this.where('1=1')
      .returnGeometry(true)
      .outFields('*')
      .format('pbf')
      .inSR(4326)
      .outSR(4326)
  }

  where(condition: string) {
    this.searchParams.set('where', condition)
    return this
  }

  outFields(fields: Array<keyof T> | keyof T | '*') {
    const outFields: string =
      fields === '*'
        ? '*'
        : Array.isArray(fields)
        ? fields.join(',')
        : fields.toString()
    this.searchParams.set('outFields', outFields)
    return this
  }

  resultRecordCount(count: number) {
    this.searchParams.set('resultRecordCount', count.toString())
    return this
  }

  resultOffset(offset: number) {
    this.searchParams.set('resultOffset', offset.toString())
    return this
  }

  returnGeometry(flag: boolean) {
    this.searchParams.set('returnGeometry', flag.toString())
    return this
  }

  returnIdsOnly(flag: boolean) {
    this.searchParams.set('returnIdsOnly', flag.toString())
    return this
  }

  returnCountOnly(flag: boolean) {
    this.searchParams.set('returnCountOnly', flag.toString())
    return this
  }

  geometry(geometry: string, geometryType = 'esriGeometryEnvelope') {
    this.searchParams.set('geometry', geometry)
    this.searchParams.set('geometryType', geometryType)
    return this
  }

  outSR(sr: number) {
    this.searchParams.set('outSR', sr.toString())
    return this
  }

  inSR(sr: number) {
    this.searchParams.set('inSR', sr.toString())
    return this
  }

  format(format: string) {
    this.searchParams.set('f', format)
    return this
  }

  async count(): Promise<number> {
    this.returnCountOnly(true)
    this.format('json')
    const response = await fetch(this.url, {
      method: 'POST',
      cache: 'default',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: this.searchParams.toString(),
    })
    const { count } = await response.json()
    return count
  }

  async asGeoJson(): Promise<FeatureCollection<G, T>> {
    const response = await fetch(this.url, {
      method: 'POST',
      cache: 'default',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: this.searchParams.toString(),
    })
    switch (this.searchParams.get('f')) {
      case 'geojson':
        return response.json()
      case 'pbf':
        return this.decodePbf(response)
      default:
        throw new Error('Unsupported format')
    }
  }

  static async exportMap(
    layerId: string,
    bbox: { west: number; south: number; east: number; north: number },
    size: number,
    where?: string,
    dpi = 96
  ) {
    const { west, south, east, north } = bbox
    const url = `https://ags.cuzk.cz/arcgis/rest/services/RUIAN/Prohlizeci_sluzba_nad_daty_RUIAN/MapServer/export`
    const body = new URLSearchParams({
      bbox: `${west},${south},${east},${north}`,
      f: 'image',
      size: `${size},${size}`,
      dpi: dpi.toString(),
      format: 'png32',
      layers: `show:${layerId}`,
      transparent: 'true',
      bboxSR: '4326',
      imageSR: '3857',
      layerDefs: JSON.stringify({
        [layerId]: where,
      }),
    })
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: body.toString(),
    })
    const blob = await response.blob()
    return createImageBitmap(blob)
  }

  static async identify(
    layerIds: string[],
    mapExtent: [number, number, number, number],
    geometry: [number, number],
    imageDisplay: [number, number],
    tolerance = 2,
    which: 'top' | 'visible' | 'all' = 'top'
  ) {
    const dpi = 96
    const url = new URL(`https://ags.cuzk.cz`)
    url.pathname =
      '/arcgis/rest/services/RUIAN/Prohlizeci_sluzba_nad_daty_RUIAN/MapServer/identify'
    const body = new URLSearchParams({
      sr: '4326',
      f: 'json',
      mapExtent: mapExtent.join(','),
      imageDisplay: `${imageDisplay[0]},${imageDisplay[1]},${dpi}`,
      layers: `${which}:${layerIds.join(',')}`,
      tolerance: tolerance.toString(),
      geometry: `${geometry[0]},${geometry[1]}`,
      geometryType: 'esriGeometryPoint',
      returnGeometry: 'true',
      returnUnformattedValues: 'true',
      returnFieldName: 'true',
    })
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: body.toString(),
    })
      .then(response => response.json())
      .then(response => {
        return turf.featureCollection(
          response.results
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((result: any) => ({
              ...result,
              attributes: {
                ...result.attributes,
                layerId: result.layerId.toString(),
                layerName: result.layerName,
              },
            }))
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((result: any) => arcgisToGeoJSON(result))
        )
      })
  }

  static async queryRelatedRecords(
    layerId: string,
    objectIds: string[],
    relationshipId: string
  ) {
    const url = new URL(`https://ags.cuzk.cz`)
    url.pathname = `/arcgis/rest/services/RUIAN/Prohlizeci_sluzba_nad_daty_RUIAN/MapServer/${layerId}/queryRelatedRecords`
    const body = new URLSearchParams({
      f: 'json',
      objectIds: objectIds.join(','),
      relationshipId,
      outFields: '*',
      returnGeometry: 'true',
    })
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: body.toString(),
    }).then(response => response.json())
  }

  static async queryDomains(
    layerId: string
  ): Promise<{ domains: EsriFieldDomain[] }> {
    const url = new URL(
      `https://ags.cuzk.cz/arcgis/rest/services/RUIAN/Prohlizeci_sluzba_nad_daty_RUIAN/MapServer/queryDomains`
    )
    const params = new URLSearchParams({
      f: 'pjson',
      layers: `[${layerId}]`,
    })
    url.search = params.toString()

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then(response => response.json())
  }

  static get ParcelaDefinicniBod() {
    return new RuianQuery<RuianParcelaDefinicniBod, Point>(
      RuianLayer.PARCELA_DEFINICNI_BOD
    )
  }
  static get StavebnihoObjektuDefinicniBod() {
    return new RuianQuery<RuianStavebnihoObjektuDefinicniBod, Point>(
      RuianLayer.STAVEBNIHO_OBJEKTU_DEFINICNI_BOD
    )
  }
  static get StavebniObjekt() {
    return new RuianQuery<RuianStavebniObjekt, Polygon | MultiPolygon>(
      RuianLayer.STAVEBNI_OBJEKT
    )
  }
  static get Parcela() {
    return new RuianQuery<RuianParcela, Polygon | MultiPolygon>(
      RuianLayer.PARCELA
    )
  }

  private async decodePbf(
    response: Response
  ): Promise<FeatureCollection<G, T>> {
    const buffer = await response.arrayBuffer()
    const decoded = arcgisPbfDecode(new Uint8Array(buffer))
    return decoded.featureCollection as FeatureCollection<G, T>
  }
}
