import { message } from 'antd'

import { handleResponseStatus } from './handleResponseStatus'
import { requestNajdiOsPath } from './paths'

export const checkCuzkSubject = async (ids: string[], token: string) => {
  try {
    const response = await fetch(
      requestNajdiOsPath() + token + '/' + ids.join(',')
    )

    if (response.status === 200) {
      const data = await response.json()
      const findedCount = data?.length ? data.length : 0

      if (!!findedCount && ids.length > 1)
        return message.success(
          `${findedCount} z ${ids.length} vybraných subjektů bylo nalezeno v evidenci ČÚZK`
        )

      if (findedCount === ids.length && ids.length > 1)
        return message.success(
          'Všechny z vybraných subjektů byly nalezeny v evidenci ČÚZK'
        )

      if (findedCount === ids.length)
        return message.success('Subjekt byl nalezen v evidenci ČÚZK')

      if (!findedCount && ids.length > 1)
        return message.warning(
          `Žádný vybraných subjektů nebyl nalezen v evidenci ČÚZK`
        )

      return message.warning('Subjekt nebyl nalezen v evidenci ČÚZK')
    }
    return handleResponseStatus(response.status)
  } catch (error) {
    message.error('Požadavek se nepodařilo provést, zkuste to prosím později.')
  }
  return false
}
