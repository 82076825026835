import { message } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useEffect, useState } from 'react'

import { Maybe } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { requestAddressPath } from '../../utils/paths'
import { AddressTooltip } from '../AddressTooltip/AddressTooltip'
import { CeeMonitorTableIdentificationItem as Item } from '../CeeMonitorTableIdentificationItem/CeeMonitorTableIdentificationItem'

export interface CuzkEpoRestrictionCheckInfoPanelItemAddressProps {
  adresnimistokod?: Maybe<string>
}

export const CuzkEpoRestrictionCheckInfoPanelItemAddress: FunctionComponent<CuzkEpoRestrictionCheckInfoPanelItemAddressProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [ruianData, setRuianData] = useState<any>()
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(!props.adresnimistokod)

    useEffect(() => {
      const fetchRuianData = async () => {
        setIsLoading(true)
        setIsError(false)
        try {
          const response = await axios.get(
            requestAddressPath() + token + '/' + props.adresnimistokod
          )
          const data = response.data
          setRuianData({
            ...data,
            lat: data.definicniBod.lat,
            lon: data.definicniBod.long,
            address: data.formatovanaAdresa,
            street: data.ulice,
            city: data.obec,
            townHall: data.radnice,
            id: data.budovaId,
          })
          setIsLoading(false)
        } catch (error) {
          setIsError(true)
          setIsLoading(false)
          message.error('Nepodařilo se načíst detail adresy subjektu.')
        }
      }

      if (!!props.adresnimistokod && !ruianData) {
        fetchRuianData()
      }
    }, [token, props.adresnimistokod, ruianData])

    if (isError) {
      return <Item title="Adresa">---</Item>
    }

    return (
      <Item loading={isLoading} title="Adresa">
        {ruianData?.address && (
          <AddressTooltip
            addressData={ruianData}
            gpsData={{
              latitude: ruianData?.lat,
              longitude: ruianData?.lon,
            }}
          />
        )}
      </Item>
    )
  }
