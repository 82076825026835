import { Radio } from 'antd'
import React, { FunctionComponent } from 'react'

import { deliveryMethodList } from '../../../common/postServiceTypes'
import { PostserviceLetterType } from '../../../graphql/generated'
import { pricelist } from '../../utils/pricelist'
import { Money } from '../Money/Money'

export interface DeliveryMethodRadioItemProps {
  type: PostserviceLetterType
  label?: string
}

export const DeliveryMethodRadioItem: FunctionComponent<DeliveryMethodRadioItemProps> =
  props => {
    return (
      <Radio value={props.type}>
        <div className="w-72 flex justify-between">
          <span>{props.label || deliveryMethodList[props.type].name}</span>
          <span>
            <Money
              decimalNumbers={1}
              amount={pricelist.postService[props.type]}
            />{' '}
            Kr/ks
          </span>
        </div>
      </Radio>
    )
  }
