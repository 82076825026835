import { Drawer } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { DRAWER_TYPE, resetPropertyDrawer } from '../../../redux/drawerSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { propertyDrawerWrapperStyle } from '../../utils/layoutConst'
import { DetailDrawerPropertyHeader } from '../DetailDrawerPropertyHeader/DetailDrawerPropertyHeader'
import { PropertyTableDetailDrawer } from '../PropertyTableDetailDrawer/PropertyTableDetailDrawer'

export const PropertyDetailDrawer: FunctionComponent = () => {
  const drawer = useAppSelector(state => state.drawer?.[DRAWER_TYPE.PROPERTY])
  const dispatch = useAppDispatch()

  const handleClose = useCallback(
    () => dispatch(resetPropertyDrawer()),
    [dispatch]
  )

  return (
    <Drawer
      contentWrapperStyle={propertyDrawerWrapperStyle}
      title={<DetailDrawerPropertyHeader />}
      open={!!drawer?.id}
      onClose={handleClose}
    >
      <PropertyTableDetailDrawer
        propertyId={drawer?.id || null}
        propertyType={drawer?.propertyType || PROPERTY_TYPES.BUILDING}
      />
    </Drawer>
  )
}
