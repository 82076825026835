import {
  FileProtectOutlined,
  FileTextOutlined,
  TableOutlined,
} from '@ant-design/icons'
import { Tabs, Typography } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { RestrictionChecType } from '../../common/epoTypes'
import { useCuzkEpoDetailQuery } from '../../graphql/generated'
import { CuzkEpoDetailTabBarExtraContent } from '../components/CuzkEpoDetailTabBarExtraContent/CuzkEpoDetailTabBarExtraContent'
import { CuzkEpoDetailTabLabel } from '../components/CuzkEpoDetailTabLabel/CuzkEpoDetailTabLabel'
import { CuzkEpoDetailTable } from '../components/CuzkEpoDetailTable/CuzkEpoDetailTable'
import { CuzkEpoHtmlDocumentTab } from '../components/CuzkEpoHtmlDocumentTab/CuzkEpoHtmlDocumentTab'
import { CuzkEpoIdentificationPanel } from '../components/CuzkEpoIdentificationPanel/CuzkEpoIdentificationPanel'
import { CuzkEpoRestrictionCheckInfoPanel } from '../components/CuzkEpoRestrictionCheckInfoPanel/CuzkEpoRestrictionCheckInfoPanel'
import { CuzkEpoRestrictionCheckInProgress } from '../components/CuzkEpoRestrictionCheckInProgress/CuzkEpoRestrictionCheckInProgress'
import { CuzkEpoRestrictionTable } from '../components/CuzkEpoRestrictionTable/CuzkEpoRestrictionTable'
import { PageSkeleton } from '../components/PageSkeleton/PageSkeleton'
import { isHugeSubjectLimit } from '../utils/layoutConst'
import { useEpoRestrictionCheckboxData } from '../utils/useEpoRestrictionCheckboxData'
import { useEpoRestrictionTableData } from '../utils/useEpoRestrictionTableData'

const titleStyle = { marginBottom: 0 }
const tabBarStyle = { marginBottom: 0, marginTop: 12 }

export const CuzkEpoDetail: FunctionComponent = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>()

  const [queryResult] = useCuzkEpoDetailQuery({
    variables: {
      id,
    },
  })
  const epoData = queryResult.data?.cuzkEpoById
  const isHugeSubject = !!(
    epoData?.pocetLv && epoData.pocetLv > isHugeSubjectLimit
  )
  const checkboxData = useEpoRestrictionCheckboxData(
    id,
    RestrictionChecType.lien
  )
  const checkboxHugeData = useEpoRestrictionCheckboxData(
    id,
    RestrictionChecType.restriction,
    !isHugeSubject
  )
  const tableData = useEpoRestrictionTableData(id)

  const refetchData = useCallback(() => {
    checkboxData.refetch()
    checkboxHugeData.refetch()
    tableData.refetch()
  }, [checkboxData, checkboxHugeData, tableData])

  const tabItems = useMemo(
    () => [
      {
        key: 'tableView',
        label: (
          <CuzkEpoDetailTabLabel
            icon={<TableOutlined />}
            title="Zobrazení v tabulce"
          />
        ),
        disabled: !epoData?.pocetLv,
        children: (
          <div className="bg-white p-6">
            <CuzkEpoIdentificationPanel
              loading={queryResult.fetching}
              subjectData={epoData}
            />
            <CuzkEpoDetailTable
              epoId={id}
              lvCount={epoData?.pocetLv}
              isHugeSubject={isHugeSubject}
            />
          </div>
        ),
      },
      {
        key: 'documentView',
        label: (
          <CuzkEpoDetailTabLabel
            icon={<FileTextOutlined />}
            title="Zobrazení v listině"
            isHugeSubject={isHugeSubject}
          />
        ),
        children: <CuzkEpoHtmlDocumentTab epoId={id} />,
        disabled: isHugeSubject,
      },

      {
        key: 'restrictionView',
        label: (
          <CuzkEpoDetailTabLabel
            icon={<FileProtectOutlined />}
            title="Zobrazení práv 3. stran (exekuce, insolvence...)"
          />
        ),
        disabled: !epoData?.pocetLv,
        children: (
          <div className="bg-white p-6  ">
            <CuzkEpoRestrictionCheckInfoPanel
              epoId={id}
              subjectData={epoData}
              refetch={refetchData}
              checkboxHugeData={checkboxHugeData}
              checkboxData={checkboxData}
            />
            <CuzkEpoRestrictionCheckInProgress
              epoId={id}
              refetch={refetchData}
              checkboxData={checkboxData}
            />
            <CuzkEpoRestrictionTable
              epoId={id}
              refetch={refetchData}
              isHugeSubject={isHugeSubject}
              dataSource={tableData}
              checkboxData={isHugeSubject ? checkboxHugeData : checkboxData}
            />
          </div>
        ),
      },
    ],
    [
      queryResult.fetching,
      epoData,
      id,
      isHugeSubject,
      refetchData,
      checkboxHugeData,
      checkboxData,
      tableData,
    ]
  )

  if (queryResult.fetching && !epoData) {
    return <PageSkeleton />
  }
  return (
    <>
      <Typography.Title style={titleStyle} level={3}>
        Přehled práv osoby
      </Typography.Title>
      <Tabs
        defaultActiveKey={epoData?.pocetLv ? 'tableView' : 'documentView'}
        type="card"
        tabBarExtraContent={
          <CuzkEpoDetailTabBarExtraContent
            id={id}
            isHugeSubject={isHugeSubject}
          />
        }
        tabBarStyle={tabBarStyle}
        tabBarGutter={6}
        items={tabItems}
      />
    </>
  )
}

export default CuzkEpoDetail
