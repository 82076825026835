import React, { FunctionComponent } from 'react'

export interface CuzkLvHtmlFooterFragmentProps {
  upozorneni1?: string
  upozorneni2?: string
  vyhotoveno: string
  kp?: string
}

export const CuzkLvHtmlFooterFragment: FunctionComponent<CuzkLvHtmlFooterFragmentProps> =
  props => {
    return (
      <>
        {props.upozorneni1 && (
          <div className="row mb-30 bold">{props.upozorneni1}</div>
        )}
        {props.upozorneni2 && (
          <div className="row mb-30 bold">{props.upozorneni2}</div>
        )}
        <div className="row">
          <i>
            Nemovitosti jsou v územním obvodu, ve kterém vykonává státní správu
            katastru nemovitostí ČR: <strong>{props.kp}</strong>
          </i>
        </div>
        <div className="row italic mt-50">
          <div className="col-xs-6">
            Vyhotovil:
            <br />
            Český úřad zeměměřický a katastrální - SCD
            <br />
            Vyhotoveno:
            <br />Z dat XML dálkového přístupu katastru nemovitostí
          </div>
          <div className="col-xs-6">Vyhotoveno: {props.vyhotoveno}</div>
        </div>
        <div className="row italic mt-200">
          <div className="col-xs-6">
            Podpis, razítko:
            <br />
          </div>
          <div className="col-xs-6">Řízení PÚ: .................</div>
        </div>{' '}
      </>
    )
  }
