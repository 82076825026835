import { SourceSpecification } from 'mapbox-gl'
import { useEffect, useMemo, useState } from 'react'
import { useMap } from 'react-map-gl'

let sourceCounter = 0

export const useMapSource = (
  idProp: string | null | undefined,
  sourceSpec: SourceSpecification
) => {
  const map = useMap().current?.getMap()
  const [, setStyleLoaded] = useState(0)
  // id needs to be stable across renders
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const id = useMemo(() => idProp || `jsx-source-${sourceCounter++}`, [])

  useEffect(() => {
    if (!map) return

    const forceUpdate = () =>
      requestAnimationFrame(() => setStyleLoaded(version => version + 1))
    map.on('styledata', forceUpdate)
    forceUpdate()

    return () => {
      map.off('styledata', forceUpdate)
      if (map.style && map.style._loaded && map.getSource(id)) {
        const allLayers = map.getStyle()?.layers
        if (allLayers) {
          for (const layer of allLayers) {
            if (layer.source === id) {
              map.removeLayer(layer.id)
            }
          }
        }
        map.removeSource(id)
      }
    }
  }, [map, id])

  if (map && map.style && map.style._loaded && !map.getSource(id)) {
    map.addSource(id, sourceSpec)
  }

  return id
}
