import { Button } from 'antd'
import React, { FunctionComponent } from 'react'

import { useDeclension } from '../../utils/useDeclension'
import { RestrictionType } from '../RestrictionBadge/RestrictionBadge'

export interface LvDetailSidebarRestrictionDetailProps {
  totalCount: number
  restrictions: {
    type: RestrictionType
    show: boolean
    restrictedCount: number
    name: string
  }[]
  handleOpen: () => void
}

export const LvDetailSidebarRestrictionDetail: FunctionComponent<LvDetailSidebarRestrictionDetailProps> =
  props => {
    const decline = useDeclension()

    return (
      <>
        <div className="my-2 text-gray-500 text-justify">
          Omezení vlastnických práv není totožné pro{' '}
          {decline(props.totalCount, 'all')} {props.totalCount} nemovitostí na
          LV.
        </div>
        {props.restrictions.map((omezeni, index) => (
          <div
            key={index}
            className={`flex justify-between mt-1 ${
              omezeni.show || omezeni.type === RestrictionType.NoRestrictionLong
                ? ''
                : 'hidden'
            }`}
          >
            <span>{omezeni.name}</span>
            <span className="pl-1">
              {omezeni.restrictedCount}/{props.totalCount}
            </span>
          </div>
        ))}
        <div className="text-right mt-2">
          <Button className="!p-0" type="link" onClick={props.handleOpen}>
            Skrýt detaily omezení
          </Button>
        </div>
      </>
    )
  }
