import { Typography } from 'antd'
import React, { FunctionComponent, useMemo, useState } from 'react'

import { RuianBuildingResponse } from '../../../common/ruianTypes'
import { useAppSelector } from '../../../redux/hooks'
import { PropertyDetailBuildingUnitsTable } from '../PropertyDetailBuildingUnitsTable/PropertyDetailBuildingUnitsTable'
import { PropertyDetailOwnershipRestrictionTable } from '../PropertyDetailOwnershipRestrictionTable/PropertyDetailOwnershipRestrictionTable'
import { ShowHistorySwitch } from '../ShowHistorySwitch/ShowHistorySwitch'

const { Title } = Typography

export const PropertyDetailOwnershipRestriction: FunctionComponent = () => {
  const [showHistory, setShowHistory] = useState(false)

  const restrictions = useAppSelector(
    state => state.property.timeline.data?.ovp
  )
  const ruianDetail = useAppSelector(
    state => state.property.ruian.data as RuianBuildingResponse
  )

  const hasHistory = useMemo(
    () => !restrictions?.some(restriction => !!restriction.datumDo),
    [restrictions]
  )

  return (
    <div className="flex space-y-10 2xl:space-y-0 2xl:space-x-10 flex-wrap">
      <div className="flex-1">
        <div className="flex justify-between items-baseline">
          <Title level={4}>Omezení vlastnických práv a jiné zápisy</Title>
          <ShowHistorySwitch
            disabled={hasHistory}
            setShowHistory={setShowHistory}
            showHistory={showHistory}
          />
        </div>
        <PropertyDetailOwnershipRestrictionTable
          setShowHistory={setShowHistory}
          showHistory={showHistory}
        />
      </div>
      {!!ruianDetail?.pocetBytu && ruianDetail.pocetBytu > 1 && (
        <div className="min-w-[33%] 2xl:max-w-[50%]">
          <Title level={4}>Jednotky v budově</Title>
          <PropertyDetailBuildingUnitsTable />
        </div>
      )}
    </div>
  )
}
