import { Tooltip } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { AdresaType, OpsubIdentType } from '../../../common/cuzkLvTypes'
import { appendComa, appendSpace, safeMap } from '../../../common/pdfHelpers'
import { useAppSelector } from '../../../redux/hooks'
import { tooltipHelpUnderline } from '../../utils/layoutConst'
import { SimpleAddressTooltip } from '../SimpleAddressTooltip/SimpleAddressTooltip'

export interface CuzkLvHtmlOpravSubjektFragmentProps {
  opravSubjekt?: OpsubIdentType
  active?: boolean
  doubleLine?: boolean
}

export const CuzkLvHtmlOpravSubjektFragment: FunctionComponent<CuzkLvHtmlOpravSubjektFragmentProps> =
  props => {
    const opsub = props.opravSubjekt

    const pravniVztahy = useAppSelector(state => state.lvDetail.pravniVztahy)
    const findSubjectIdByName = useCallback(
      (name: string): string | undefined => {
        for (const pravniVztah of pravniVztahy) {
          for (const vlastnickePravo of pravniVztah.vlastnickaPrava) {
            if (vlastnickePravo.subjekt.jmeno.includes(name.trim())) {
              return vlastnickePravo.subjekt.id
            }
          }
        }
        return undefined
      },
      [pravniVztahy]
    )

    const subjectTitle = useMemo(
      () =>
        [
          appendSpace(opsub?.nazev),
          appendSpace(opsub?.titul_pred),
          appendSpace(opsub?.prijmeni),
          appendComa(opsub?.jmeno),
          appendComa(opsub?.titul_za),
        ]
          .filter(Boolean)
          .join(''),
      [opsub]
    )

    const subjectName = useMemo(
      () =>
        [
          appendSpace(opsub?.nazev),
          appendSpace(opsub?.prijmeni),
          appendSpace(opsub?.jmeno),
          appendSpace(opsub?.titul_pred),
          appendSpace(opsub?.titul_za),
        ]
          .filter(Boolean)
          .join(''),
      [opsub]
    )

    const bsm1Title = useMemo(
      () =>
        [
          appendSpace(opsub?.bsm1_pred),
          appendSpace(opsub?.bsm1_prijmeni),
          opsub?.bsm1_za
            ? appendComa(opsub?.bsm1_jmeno)
            : appendSpace(opsub?.bsm1_jmeno),
          appendComa(opsub?.bsm1_za),
        ]
          .filter(Boolean)
          .join(''),
      [opsub]
    )

    const bsm1Name = useMemo(
      () =>
        [
          appendSpace(opsub?.bsm1_prijmeni),
          appendSpace(opsub?.bsm1_jmeno),
          appendSpace(opsub?.bsm1_pred),
          appendSpace(opsub?.bsm1_za),
        ]
          .filter(Boolean)
          .join(''),
      [opsub]
    )

    const bsm2Title = useMemo(
      () =>
        [
          appendSpace(opsub?.bsm2_pred),
          appendSpace(opsub?.bsm2_prijmeni),
          appendSpace(opsub?.bsm2_jmeno),
          appendSpace(opsub?.bsm2_za),
        ]
          .filter(Boolean)
          .join(''),
      [opsub]
    )

    const bsm2Name = useMemo(
      () =>
        [
          appendSpace(opsub?.bsm2_prijmeni),
          appendSpace(opsub?.bsm2_jmeno),
          appendSpace(opsub?.bsm2_pred),
          appendSpace(opsub?.bsm2_za),
        ]
          .filter(Boolean)
          .join(''),
      [opsub]
    )
    const regestaSubjectUrl = useCallback(
      (type: string, nameString: string) => {
        if (!props.active) return type
        const subjectId = findSubjectIdByName(nameString)
        if (subjectId) {
          return (
            <Tooltip title="Zobrazit detail subjektu v novém okně">
              <a
                target="_blank"
                href={`https://data.regesta.cz/Vyhledavani/Subjekt/${subjectId}`}
                rel="noreferrer"
              >
                {type}
              </a>
            </Tooltip>
          )
        }

        const addressString =
          Array.isArray(opsub?.adresa) && opsub?.adresa?.[0]?.obec
            ? opsub?.adresa?.[0]?.obec
            : !Array.isArray(opsub?.adresa) && opsub?.adresa?.obec
            ? opsub?.adresa?.obec
            : ''
        return (
          <Tooltip title="Vyhledat subjekt v novém okně">
            <a
              target="_blank"
              href={`https://data.regesta.cz/Vyhledavani/Subjekty?nazev=${type}&adresa=${addressString}&vcetneHistorie=false`}
              rel="noreferrer"
            >
              {type}
            </a>
          </Tooltip>
        )
      },
      [findSubjectIdByName, opsub?.adresa, props.active]
    )

    const addressText = useCallback(
      (adresa: AdresaType) =>
        [
          appendComa(adresa?.nazev_ulice),
          adresa?.cp_ce === '1' && 'č.p. ',
          adresa?.cp_ce === '2' && 'č.e. ',
          adresa?.cislo_domovni,
          adresa?.cislo_orientacni
            ? '/' + appendComa(adresa?.cislo_orientacni)
            : ', ',
          appendSpace(adresa?.cast_obce),
          // appendSpace(adresa?.obec),
          // appendSpace(adresa?.mestska_cast),
          appendSpace(adresa?.psc),
          appendSpace(adresa?.mestsky_obvod),
          // appendSpace(adresa?.dod_posta),
          appendSpace(adresa?.stat),
        ]
          .filter(Boolean)
          .join(''),
      []
    )

    if (!opsub) {
      return null
    }

    return (
      <>
        {opsub.bsm2_jmeno && (
          <>
            {regestaSubjectUrl(bsm1Title, bsm1Name)}
            {'a '}
            {regestaSubjectUrl(bsm2Title, bsm2Name)}
          </>
        )}
        {regestaSubjectUrl(subjectTitle, subjectName)}
        {props.doubleLine && <br />}
        {safeMap(opsub?.adresa, (adresa, index) =>
          props.active ? (
            <SimpleAddressTooltip
              address={
                appendSpace(adresa?.nazev_ulice) +
                appendSpace(adresa?.cislo_domovni) +
                appendSpace(adresa?.obec) +
                appendSpace(adresa?.psc)
              }
              addressCode={adresa?.adresni_misto?.toString()}
              key={index}
            >
              <span className={tooltipHelpUnderline}>
                {addressText(adresa)}
              </span>
            </SimpleAddressTooltip>
          ) : (
            addressText(adresa)
          )
        )}
      </>
    )
  }
