import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import { useHandleExpandRow } from '../../utils/useHandleExpandRow'

export interface ExpandIconProps {
  expanded: boolean
  expandable: boolean
  tableId: string
  recordId: string
}

export const ExpandIcon: FunctionComponent<ExpandIconProps> = props => {
  const handleExpandRow = useHandleExpandRow(props.tableId)

  const changeExpanded = useCallback(
    () => handleExpandRow(props.recordId),
    [handleExpandRow, props.recordId]
  )

  if (props.expandable && props.expanded)
    return (
      <MinusSquareOutlined
        className="text-base text-primary hover:text-primaryHover"
        onClick={changeExpanded}
      />
    )
  if (props.expandable)
    return (
      <Tooltip
        title="Kliknutím zobrazíte detail"
        placement="left"
        mouseEnterDelay={0.7}
      >
        <PlusSquareOutlined
          className="text-base text-primary hover:text-primaryHover"
          onClick={changeExpanded}
        />
      </Tooltip>
    )
  return null
}
