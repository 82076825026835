/**
 * Function is primarily used for PostService purposes and identifying duplicate
 * records from various API sources across the application. It can also be used
 * in places where using a person's UUID is not appropriate.
 *
 * @param {string} fullName - The full name or title of the person, including academic titles or legal form abbreviations.
 * @param {string|number} [addressId] - The ID of the person's address according to ČÚZK.
 * @param {string|number} [alternativeId] - An alternative string as a backup when the address ID doesn't exist. By convention, the address in text form is used.
 * @returns {string} A unique key for the person in the format "id_normalizedName" or "00alternativeId_normalizedName".
 *
 * @example
 * getUniquePersonKey("Ing. Petr Kocián, CSc.", "123456")
 * // Returns "123456_petr_kocian"
 *
 * @example
 * getUniquePersonKey("Regesta DATA, s.r.o.", null, "Street 321, London, E16 1ZE")
 * // Returns "00street3_data_regesta"
 *
 * @example
 * getUniquePersonKey("John Doe Smith", null, undefined)
 * // Returns "000000000_doe_john_smith"
 */

export const getUniquePersonKey = (
  fullName: string,
  addressId?: string | number | null,
  alternativeId?: string | number | null
) => {
  const normalized = normalizeName(fullName)

  if (addressId) return `${addressId}_${normalized}`

  if (alternativeId) {
    const processedId = processAlternativeId(alternativeId.toString())
    return `${processedId}_${normalized}`
  }

  return `000000000_${normalized}`
}

const processAlternativeId = (id: string): string => {
  const cleaned = id.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()
  return cleaned.slice(0, 7).padStart(9, '0')
}

const normalizeName = (name?: string) => {
  if (!name) return ''

  const parts = name
    .normalize('NFD')
    .toLowerCase()
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9\s+]/g, '')
    .split(/\s+/)

  const filteredParts = parts.filter(part => {
    return !(subjectPrefixes.has(part) || part.length < 2)
  })

  return filteredParts.sort().join('_')
}

const subjectPrefixes = new Set([
  'as',
  'art',
  'artd',
  'bc',
  'bca',
  'csc',
  'dis',
  'dr',
  'drsc',
  'et',
  'icdr',
  'ing',
  'arch',
  'judr',
  'mddr',
  'mga',
  'mgr',
  'msdr',
  'mudr',
  'mvdr',
  'odb',
  'paedr',
  'paeddr',
  'ph',
  'pharmdr',
  'phdr',
  'phmr',
  'rcdr',
  'rtdr',
  'rndr',
  'rsdr',
  'thdr',
  'th',
  'thlic',
  'ro',
  'vos',
  'ops',
  'zs',
  'zu',
  'ks',
  'sro',
  'sp',
  'spol',
  'inc',
  'ltd',
  'msc',
  'ab',
  'ba',
  'bba',
  'be',
  'beng',
  'bfa',
  'bphil',
  'bmed',
  'bmet',
  'bmin',
  'bmus',
  'bpa',
  'bpharm',
  'bs',
  'bsc',
  'mba',
  'bss',
  'dd',
  'ds',
  'dsc',
  'dphil',
  'ma',
  'llb',
  'llm',
  'jd',
  'jsd',
  'jcd',
  'jud',
  'phd',
  'edd',
  'thd',
  'md',
  'doc',
  'prof',
  'jr',
  'sr',
])
