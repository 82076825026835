import { AUCTION_TYPES } from '../../common/auctionScrapeTypes'
import { PROPERTY_TYPES } from '../../common/drmTypes'
import { PROPERTY_TAB_KEYS } from '../../common/propertyDetailTypes'

export const homePath = () => `/`
export const requestPath = (path?: string) => `/api/${path || ''}`

export const requestRisyPath = () => `/api/risy/area`
export const requestAresPath = () => `/api/ares/`

export const requestRzpPath = () => `/api/rzp/`
export const requestRzpSubjectsPath = () => `/api/rzp/address`

export const requestDrmSubjectsPath = () => `/api/drmSubjects/`
export const requestSubjectPath = () => `/api/subject/`

export const requestLvPath = () => `/api/lv/`
export const requestLvGeometryPath = () => `/api/lvGeometry/`

export const requestProceedingDetailPath = () => `/api/proceeding/detail/`

export const requestPropertyRuianPath = (
  propertyType: PROPERTY_TYPES,
  token: string,
  propertyId: string
) => `/api/property/ruian/${propertyType}/${token}/${propertyId}/`

export const requestPropertyDrmPath = (
  propertyType: PROPERTY_TYPES,
  token: string,
  propertyId: string
) => `/api/property/drm/${propertyType}/${token}/${propertyId}/`

export const requestPropertyTimelinePath = (
  propertyType: PROPERTY_TYPES,
  token: string,
  propertyId: string
) => `/api/property/timeline/${propertyType}/${token}/${propertyId}/`

export const requestPropertyPolygonPath = (
  propertyType: PROPERTY_TYPES,
  token: string,
  propertyId: string
) => `/api/property/polygon/${propertyType}/${token}/${propertyId}/`

export const requestPropertyCrmDrmPath = (
  propertyType: PROPERTY_TYPES,
  tenantId: string,
  propertyId: string
) => `/api/property/crmDrm/${propertyType}/${tenantId}/${propertyId}/`

export const requestPropertyCrmTimelinePath = (
  propertyType: PROPERTY_TYPES,
  tenantId: string,
  propertyId: string
) => `/api/property/crmTimeline/${propertyType}/${tenantId}/${propertyId}/`

export const requestAddressPath = () => `/api/address/`

export const requestGetParcelaIdPath = () => `/api/getPropertyId/parcela/`

export const requestCeePath = () => `/api/cee/`
export const requestCeeSubjectPath = () => `/api/cee/subject/`

export const requestCuzkPath = () => `/api/cuzk/`
export const requestNajdiOsPath = () => `/api/cuzk/najdiOs/`
export const requestnajdiListinuRizeniPath = () =>
  `/api/cuzk/proceeding/document/`

export const cuzkWsdpPath = () => `/dalkovy-pristup`
export const cuzkDocumentCollectionPath = () => `/dalkovy-pristup/sbirka-listin`
export const cuzkDocumentCollectionFetchPath = (
  proceeding: string,
  id: string
) => `/dalkovy-pristup/sbirka-listin/${proceeding}/${id}`
export const cuzkEpoPath = () => `/dalkovy-pristup/evidence-prav-pro-osobu`
export const cuzkEpoDetailPath = (id?: string) =>
  `/dalkovy-pristup/evidence-prav-pro-osobu${id ? `/${id}` : ''}`

export const cuzkLvPath = () => `/dalkovy-pristup/list-vlastnictvi`
export const cuzkLvDownloadPath = (katuze: string, lv: string) =>
  `/dalkovy-pristup/list-vlastnictvi/${katuze}/${lv}`
export const cuzkLvDetailPath = (id?: string) =>
  `/dalkovy-pristup/list-vlastnictvi${id ? `/${id}` : ''}`

export const overwievPath = () => `/prehledy`

export const propertyNotePath = () => `/prehledy/poznamky`
export const labelPath = () => `/prehledy/stitky`
export const labelDetailPath = (id?: string) =>
  `/prehledy/stitky/detail${id ? `/${id}` : ''}`

export const propertyPath = () => `/nemovitosti`
export const propertyDetailPath = (
  propertyType: PROPERTY_TYPES,
  id?: string,
  tab?: PROPERTY_TAB_KEYS
) => `/nemovitosti/${propertyType}/${id || ''}${tab ? '/?tab=' + tab : ''}`

export const lvPath = () => `/vyhledavani/list-vlastnictvi`
export const lvDetailPath = (katuze: string | number, lv: string | number) =>
  `/vyhledavani/list-vlastnictvi/${katuze}/${lv}`

export const searchPath = () => `/vyhledavani`

export const dataBoxPath = () => `/vyhledavani/datova-schranka`
export const dataBoxDetailPath = (urlQuery?: string) =>
  `/vyhledavani/datova-schranka${urlQuery ? `/${urlQuery}` : ''}`

export const proceedingPath = () => `/vyhledavani/rizeni`
export const proceedingDetailPath = (urlQuery?: string) =>
  `/vyhledavani/rizeni${urlQuery ? `/${urlQuery}` : ''}`

export const addressPath = () => `/vyhledavani/adresa`
export const addressDetailPath = (urlQuery?: string | number) =>
  `/vyhledavani/adresa${urlQuery ? `/${urlQuery}` : ''}`

export const areaPath = () => `/vyhledavani/parcela`

export const marketplacePath = () => `/marketplace`
export const marketplaceStatisticsPath = () => `/marketplaceStatistiky`

export const cadastreMapPath = () => `/katastralni-mapy`

export const priceEstimatePath = () => `/cenovy-odhad`
export const priceEstimateDetailPath = (type: string, id?: string) =>
  `/cenovy-odhad/${type}/${id ? `${id}` : ''}`

export const ceeCheckPath = () => `/vyhledavani-subjektu-CEE`
export const statisticsPath = () => `/statistikyVyuziti`

export const ceeMonitorPath = () => `/hlidac-subjektu-CEE`
export const ceeMonitorCreatePath = (monitoring?: string) =>
  `/hlidac-subjektu-CEE/pridat-do-hlidace${monitoring ? `/${monitoring}` : '/'}`

export const cuzkMonitorRcPath = () => `/hlidac-subjektu-CUZK`
export const cuzkMonitorRcCreatePath = () =>
  `/hlidac-subjektu-CUZK/pridat-do-hlidace`

export const cadastrePostPath = () => `/katastralni-posta`
export const cadastrePostSentPath = () => `/katastralni-posta/prehled`
export const cadastrePostRecipientsPath = (id?: string) =>
  `/katastralni-posta/seznam-adresatu${id ? `/${id}` : ''}`
export const cadastrePostLetterPath = (id?: string) =>
  `/katastralni-posta/tvorba-dopisu${id ? `/${id}` : ''}`

export const postServicePath = () => `/hromadna-posta`
export const postServiceSentPath = () => `/hromadna-posta/prehled`
export const postServiceCreatePath = (create?: string) =>
  `/hromadna-posta/dopis${create ? `/${create}` : ''}`
export const postServiceTemplatesPath = () => `/hromadna-posta/sablony-dopisu`
export const postServiceRecipientListPath = () => `/hromadna-posta/adresati`

export const postServiceSentListPath = (id: string) =>
  `/hromadna-posta/adresati/prehled/${id}`
// because Bartak
export const postServiceRecipientPath = (id?: string) =>
  `/hromadna-posta/seznam-adresatu${id ? `/${id}` : ''}`

export const foreclosureMapPath = () => `/monitoring/mapa/exekuce`
export const insolvencyMapPath = () => `/monitoring/mapa/insolvence`
export const auctionMapPath = () => `/monitoring/mapa/drazby`

export const monitoringPath = () => `/monitoring`

export const auctionMonitoringPath = (tab?: AUCTION_TYPES) =>
  `/monitoring/drazby${tab ? `/${tab}` : ''}`
export const foreclosureMonitoringPath = (tab?: PROPERTY_TYPES) =>
  `/monitoring/exekuce${tab ? `/${tab}` : ''}`
export const insolvencyMonitoringPath = (tab?: PROPERTY_TYPES) =>
  `/monitoring/insolvence${tab ? `/${tab}` : ''}`

export const watchdogChannelPath = () => `/monitoring/nemovitosti/kanaly`
export const watchdogPropertyPath = (channelId?: string) =>
  `/monitoring/nemovitosti/prehled${channelId ? `/${channelId}` : ''}`
export const heritageMonitoringPath = () => `/monitoring/dedictvi`

export const postServiceLetterPath = (
  recipientListId?: string,
  templateId?: string
) =>
  `/hromadna-posta/tvorba-dopisu${
    recipientListId ? `/${recipientListId}` : ''
  }${templateId ? `/sablona/${templateId}` : '/sablona'}`

export interface Path {
  name: string
  title?: string
  path: string
  disabled?: boolean
  guid?: boolean
}
export const paths: Path[] = [
  { name: 'Nástěnka', path: homePath() },
  { name: 'Přehledy', path: overwievPath(), disabled: true },

  {
    name: 'Štítky',
    title: 'Štítky',
    path: labelPath(),
  },
  {
    name: 'Detail štítku',
    title: 'Detail štítku',
    path: labelDetailPath(),
  },
  {
    name: 'Poznámky k nemovitostem',
    title: 'Poznámky k nemovitostem',
    path: propertyNotePath(),
  },

  {
    name: 'Vyhledávání',
    title: 'Vyhledávání',
    path: searchPath(),
    disabled: true,
  },
  {
    name: 'Řízení',
    title: 'Vyhledávání - Řízení',
    path: proceedingPath(),
  },
  {
    name: 'Detail řízení',
    title: 'Detail řízení',
    path: proceedingDetailPath(':urlQuery'),
    guid: true,
  },
  {
    name: 'Adresa',
    title: 'Vyhledávání - Adresa',
    path: addressPath(),
  },
  {
    name: 'Detail adresy',
    title: 'Detail adresy',
    path: addressDetailPath(':urlQuery'),
    guid: true,
  },
  {
    name: 'Parcela',
    title: 'Vyhledávání - Parcela',
    path: areaPath(),
  },
  {
    name: 'List vlastnictví',
    title: 'Vyhledávání - List vlastnictví',
    path: lvPath(),
  },
  {
    name: 'Datová schránka',
    title: 'Vyhledávání - Datová schránka',
    path: dataBoxPath(),
  },
  {
    name: 'Informace',
    title: 'Datová schránka',
    path: dataBoxDetailPath(),
    guid: true,
  },

  {
    name: 'Cenové odhady',
    title: 'Cenové odhady',
    path: priceEstimatePath(),
  },
  {
    name: 'Cenový odhad detail',
    title: 'Cenový odhad detail',
    path: priceEstimateDetailPath(':type', ':id'),
    guid: true,
  },

  {
    name: 'Hromadná pošta',
    title: 'Hromadné rozesílání pošty',
    path: postServicePath(),
  },
  {
    name: 'Adresáti',
    title: 'Adresáti hromadné pošty',
    path: postServiceRecipientListPath(),
  },
  {
    name: 'Tvorba dopisu',
    title: 'Tvorba dopisu',
    path: postServiceLetterPath(':recipientListId'),
  },
  {
    name: 'Šablony dopisů',
    title: 'Přehled šablon dopisů',
    path: postServiceTemplatesPath(),
  },
  {
    name: 'Přehled hromadné pošty',
    title: 'Přehled hromadné pošty',
    path: postServiceSentPath(),
  },
  {
    name: 'V odeslané poště',
    title: 'Přehled adresátů hromadné pošty',
    path: postServiceSentListPath(':id'),
  },

  {
    name: 'Monitoring',
    title: 'Monitoring',
    path: monitoringPath(),
    disabled: true,
  },
  {
    name: 'Katastrální hlídač',
    title: 'Hlídač katastru - přehled nemovitostí',
    path: watchdogPropertyPath(),
  },
  {
    name: 'Kanály',
    title: 'Hlídač katastru - Kanály',
    path: watchdogChannelPath(),
  },
  {
    name: 'Nemovitosti v dražbě',
    title: 'Monitoring dražeb - přehled nemovitostí',
    path: auctionMonitoringPath(),
  },
  {
    name: 'Nemovitosti v exekuci',
    title: 'Monitoring exekucí - přehled nemovitostí',
    path: foreclosureMonitoringPath(),
  },
  {
    name: 'Nemovitosti v insolvenci',
    title: 'Monitoring insolvencí - přehled nemovitostí',
    path: insolvencyMonitoringPath(),
  },
  {
    name: 'Nemovitosti v dědickém řízení',
    title: 'Monitoring dědictví - přehled řízení',
    path: heritageMonitoringPath(),
  },
  {
    name: 'Hlídač CEE',
    title: 'Hlídání subjektů v CEE',
    path: ceeMonitorPath(),
  },
  {
    name: 'Vyhledávání v CEE',
    title: 'Vyhledávání subjektů v CEE',
    path: ceeCheckPath(),
  },
  {
    name: 'Vyhledávání v ČÚZK',
    title: 'Vyhledávání subjektů v ČÚZK',
    path: cuzkMonitorRcPath(),
  },
  {
    name: 'Marketplace',
    title: 'bid&click Marketplace',
    path: marketplacePath(),
  },

  {
    name: 'Dálkový přístup ČÚZK',
    title: 'Dálkový přístup ČÚZK',
    path: cuzkWsdpPath(),
    disabled: true,
  },
  {
    name: 'Evidence práv pro osobu',
    title: 'Evidence práv pro osobu',
    path: cuzkEpoPath(),
  },
  {
    name: 'Detail evidence práv',
    title: 'EPO - Detail subjektu',
    path: cuzkEpoDetailPath(),
    guid: true,
  },
  {
    name: 'Nemovitosti',
    title: 'Detail nemovitosti',
    path: propertyPath(),
    disabled: true,
  },
  {
    name: 'Detail',
    title: 'Detail nemovitosti',
    path: propertyDetailPath(':propertyType' as PROPERTY_TYPES, ':id'),
    guid: true,
  },
  {
    name: 'Listy vlastnictví',
    title: 'Listy vlastnictví',
    path: cuzkLvPath(),
  },
  {
    name: 'Detail listu vlastnictví',
    title: 'Detail listu vlastnictví',
    path: cuzkLvDetailPath(),
    guid: true,
  },
  {
    name: 'Detail evidence práv',
    title: 'EPO - Detail subjektu',
    path: cuzkEpoDetailPath(),
    guid: true,
  },
  {
    name: 'Sbírka listin',
    title: 'Sbírka listin ČÚZK',
    path: cuzkDocumentCollectionPath(),
  },
  {
    name: 'Vyhledat subjekt',
    title: 'Vyhledat subjekt',
    path: cuzkMonitorRcCreatePath(),
  },
  {
    name: 'Vyhledat subjekt',
    title: 'Vyhledat subjekt',
    path: ceeMonitorCreatePath(),
  },
  {
    name: 'Katastrální mapa',
    title: 'Katastrální mapa',
    path: cadastreMapPath(),
  },
  {
    name: 'Mapa exekucí',
    title: 'Mapa exekucí',
    path: foreclosureMapPath(),
  },
  {
    name: 'Mapa insolvencí',
    title: 'Mapa insolvencí',
    path: insolvencyMapPath(),
  },
  {
    name: 'Mapa dražeb',
    title: 'Mapa dražeb',
    path: auctionMapPath(),
  },
]
