import React, { FunctionComponent, useMemo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { TransformedPropertyLabelData } from '../../../common/propertyDetailTypes'
import { InlineLabelsGroup } from '../InlineLabelsGroup/InlineLabelsGroup'
import { PropertyDetailEditLabelModal } from '../PropertyDetailEditLabelModal/PropertyDetailEditLabelModal'

export interface PropertyTableItemLabelProps {
  propertyLabel?: TransformedPropertyLabelData['propertyLabel']
  propertyType: PROPERTY_TYPES
  propertyId: string
  refetch: () => void
}

export const PropertyTableItemLabel: FunctionComponent<PropertyTableItemLabelProps> =
  props => {
    const labelConnections = useMemo(
      () => props.propertyLabel?.labelConnectionsByPropertyLabelId?.nodes || [],
      [props.propertyLabel?.labelConnectionsByPropertyLabelId?.nodes]
    )

    const labels = useMemo(
      () => labelConnections?.map(label => label?.labelByLabelId) || [],
      [labelConnections]
    )

    return (
      <div className="flex space-x-2 items-center">
        <PropertyDetailEditLabelModal
          labelConnections={labelConnections}
          refetch={props.refetch}
          propertyData={props.propertyLabel?.propertyData}
          propertyType={props.propertyType}
          propertyId={props.propertyId}
        />
        <InlineLabelsGroup labels={labels} />
      </div>
    )
  }
