import { useMemo } from 'react'

import { UserActivityLogsResult } from '../../common/userActivityLogTypes'
import { useAllUserActivityLogsQuery } from '../../graphql/generated'
import { useAppSelector } from '../../redux/hooks'

export const useUserActivityLogCheck = (ids: string[]) => {
  const userId = useAppSelector(state => state.myProfile.userId)

  const [{ data }] = useAllUserActivityLogsQuery({
    pause: !userId || !ids.length,
    variables: { accountUserId: userId, entityIds: ids },
  })

  const result = useMemo(() => {
    if (!ids.length || !data) return null

    const logsMap: UserActivityLogsResult = {}

    ids.forEach(id => {
      logsMap[id] = null
    })

    if (data && data.allUserActivityLogs && data.allUserActivityLogs.nodes) {
      data.allUserActivityLogs.nodes.forEach(node => {
        if (!node?.id) return

        logsMap[node.entityId] = node
      })
    }
    return logsMap
  }, [data, ids])

  return result
}
