import { format, isToday, parseISO } from 'date-fns'
import { cs } from 'date-fns/locale'
import { Moment } from 'moment'
import React from 'react'
import { Unit } from 'react-calendar-timeline'

import { Ovp } from '../../common/proceedingTypes'
import { czDateFormatDay } from './dateFormat'

const now = new Date()

export const yearInMilliseconds = 365 * 24 * 60 * 60 * 1000
export const propertyTimelineStart = parseISO('2009-01-01').getTime()
export const propertyTimelineEnd = now.getTime() + yearInMilliseconds * 1.5

export const propertyTimelineGroups = {
  ownership: [
    { id: 'before', title: '', height: 30 },
    { id: 'ownership', title: 'Vlastnictví' },
    { id: 'after', title: '', height: 50 },
  ],
  proceeding: [
    { id: 'before', title: '', height: 30 },
    { id: 'proceeding', title: 'Řízení' },
    { id: 'after', title: '', height: 50 },
  ],
  restriction: [
    { id: 'before', title: '', height: 30 },
    { id: 'restriction', title: 'Omezení a jiné zápisy' },
    { id: 'after', title: '', height: 50 },
  ],
}

export const propertyTimelineProps = {
  canMove: false,
  canChangeGroup: false,
  canResize: false,
  stackItems: true,
  sidebarWidth: 0,
  lineHeight: 40,
  itemHeightRatio: 0.8,
  verticalLineClassNamesForTime: () => ['!border-dotted'],
  minZoom: 14 * 24 * 60 * 60 * 1000,
  maxZoom: yearInMilliseconds * 20,
  className: 'border-bottom-gray-400 border-bottom-solid border-b',
}

export const getTimelineDatePoint = (date?: string | null) =>
  date ? parseISO(date).getTime() : now.getTime()

export const getTimelineHeightClass = (shareValue: number) => {
  if (shareValue < 5) return 'h-[5%]'
  if (shareValue < 11) return 'h-[10%]'
  if (shareValue < 18) return 'h-1/6'
  if (shareValue < 22) return 'h-1/5'
  if (shareValue < 28) return 'h-1/4'
  if (shareValue < 35) return 'h-1/3'
  if (shareValue < 43) return 'h-2/5'
  if (shareValue < 55) return 'h-1/2'
  if (shareValue < 63) return 'h-3/5'
  if (shareValue < 73) return 'h-2/3'
  if (shareValue < 83) return 'h-4/5'
  if (shareValue < 93) return 'h-5/6'
  if (shareValue < 100) return 'h-[95%]'
  return 'h-full'
}

export const timelineDateTitle = (
  fromDate?: number | null,
  toDate?: number | null,
  point?: boolean
) => {
  const from = fromDate ? format(fromDate, czDateFormatDay) : ''
  const to =
    toDate && !isToday(toDate)
      ? format(toDate, czDateFormatDay)
      : 'Stále platné'
  return point ? `Datum: ${from}` : `Od: ${from} \nDo: ${to}`
}

export const propertyTimelineRestrictionTitle = (ovp: Ovp) => (
  <div className="group h-full flex items-center text-sm">
    <div className="max-w-[100px] flex group-hover:max-w-screen-sm transition-width duration-500 px-2">
      {ovp.nazev} {ovp.osoba ? `- ${ovp.osoba}` : ''}
    </div>
  </div>
)

interface HeaderProps {
  height: number
  unit?: 'primaryHeader' | Unit
  labelFormat:
    | string
    | ((
        [startTime, endTime]: [Moment, Moment],
        unit: Unit,
        labelWidth: number
      ) => string)
    | undefined
}

export const propertyTimelineSecondaryHeader: HeaderProps = {
  height: 18,
  unit: 'primaryHeader',
  labelFormat: (dateRange, unit) => {
    const startDate = dateRange[0].toDate()
    const formatStringByUnit = {
      year: 'yyyy',
      month: 'LLLL yyyy',
      day: 'dd LLLL yyyy',
      week: 'ww',
      isoWeek: 'ww',
      second: 'ss',
      minute: 'mm',
      hour: 'HH',
    }
    return format(startDate, formatStringByUnit[unit], { locale: cs })
  },
}

export const propertyTimelinePrimaryHeader: HeaderProps = {
  height: 16,
  labelFormat: (dateRange, unit, labelWidth) => {
    const startDate = dateRange[0].toDate()
    const formatStringByUnit = {
      year: 'yyyy',
      month:
        labelWidth > 120
          ? 'LLLL yyyy'
          : labelWidth > 80
          ? 'LLLL'
          : labelWidth > 40
          ? 'LLL'
          : 'L',
      day: 'd.',
      week: 'ww',
      isoWeek: 'ww',
      second: 'ss',
      minute: 'mm',
      hour: 'HH',
    }
    return format(startDate, formatStringByUnit[unit] || '', { locale: cs })
  },
}
