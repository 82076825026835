import { message } from 'antd'
import axios from 'axios'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { LvApiResponse, LvGeometryApiResponse } from '../../common/lvTypes'
import { useAppSelector } from '../../redux/hooks'
import { Fade } from '../components/Fade/Fade'
import { LvDetail } from '../components/LvDetail/LvDetail'
import { LvSearchBar } from '../components/LvSearchBar/LvSearchBar'
import { PageLayout } from '../components/PageLayout/PageLayout'
import { PropertyDetailFetchError } from '../components/PropertyDetailFetchError/PropertyDetailFetchError'
import { lvDetailPath } from '../utils/paths'
import { fixInvalidGeometryJson } from '../utils/useFetchPropertyLvGeometry'
import { useInitialFetchWatchdog } from '../utils/useFetchWatchdog'

export const Lv: FunctionComponent = () => {
  const [loading, setLoading] = useState(false)
  const [lvData, setLvData] = useState<LvApiResponse>()
  const [geometryData, setGeometryData] = useState<LvGeometryApiResponse>()
  useInitialFetchWatchdog()
  const token = useAppSelector(state => state.myProfile.token)
  const history = useHistory()
  const {
    params: { katuze, lv },
  } = useRouteMatch<{ katuze?: string; lv?: string }>()

  const ignoreUrlChange = useRef(false)

  const handleSearch = useCallback(
    async (params: { lvCode: string; lvNumber: string }) => {
      setLoading(true)
      const apiUrlLv = `https://data.regesta.cz/DrmApi/api/v1.0/LV/${params.lvCode}/${params.lvNumber}?access_token=${token}&vcetneHistorie=true`
      const apiUrlGeometry = `https://data.regesta.cz/DrmApi/api/v1.0/LV/${params.lvCode}/${params.lvNumber}/Nemovitosti/Geometrie?access_token=${token}`
      try {
        const [responseLv, responseGeometry] = await Promise.all([
          axios.get(apiUrlLv),
          axios.get(apiUrlGeometry),
        ])

        const lvResponse: LvApiResponse = responseLv.data
        const geometry: LvGeometryApiResponse =
          typeof responseGeometry.data === 'string'
            ? JSON.parse(fixInvalidGeometryJson(responseGeometry.data))
            : responseGeometry.data

        setLvData(lvResponse)
        setGeometryData(geometry)

        if (params.lvCode !== katuze || params.lvNumber !== lv) {
          ignoreUrlChange.current = true
          history.push(`${lvDetailPath(params.lvCode, params.lvNumber)}`)
        }
      } catch {
        message.error('Nepodařilo se načíst informace o LV.')
      } finally {
        setLoading(false)
      }
    },
    [token, history, katuze, lv]
  )

  useEffect(() => {
    if (ignoreUrlChange.current) {
      ignoreUrlChange.current = false
      return
    }
    if (katuze && lv) {
      const isValid = /^\d{1,6}$/.test(katuze) && /^\d{1,6}$/.test(lv)
      if (isValid) {
        handleSearch({ lvCode: katuze, lvNumber: lv })
      } else {
        message.error(`${katuze}-${lv} není platný formát LV.`)
      }
    }
  }, [katuze, lv, handleSearch])

  const founded = useMemo(
    () =>
      !!lvData?.nemovitosti?.budovy?.length ||
      !!lvData?.nemovitosti?.parcely?.length ||
      !!lvData?.nemovitosti?.jednotky?.length,
    [lvData]
  )

  return (
    <>
      <PageLayout title="Vyhledat list vlastnictví" className="!py-0 mb-6">
        <LvSearchBar loading={loading} onSubmit={handleSearch} />
      </PageLayout>
      <Fade show={!!lvData && !!geometryData}>
        {founded && lvData && geometryData ? (
          <LvDetail geometryData={geometryData} lvData={lvData} />
        ) : (
          <PropertyDetailFetchError
            message={`LV ${lvData?.lv} - ${lvData?.katastralniUzemi?.nazev} (${lvData?.katastralniUzemi?.kod}) nenalezeno`}
            description="Je nám líto, ale dle zadaných údajů se nám nepodařilo najít žádný list vlastnictví."
          />
        )}
      </Fade>
    </>
  )
}

export default Lv
