import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Alert } from 'antd'
import React, { FunctionComponent } from 'react'

import {
  ProceedingDetailResponse,
  Rizeni,
} from '../../../common/proceedingTypes'
import { ProceedingDetailBaseInfo } from '../ProceedingDetailBaseInfo/ProceedingDetailBaseInfo'
import { ProceedingDetailParticipants } from '../ProceedingDetailParticipants/ProceedingDetailParticipants'
import { ProceedingDetailTimeline } from '../ProceedingDetailTimeline/ProceedingDetailTimeline'

export interface ProceedingDetailInformationProps {
  data: ProceedingDetailResponse | null
  loading: boolean
  error?: boolean
  record: Rizeni
}

const alertProps = {
  type: 'error',
  showIcon: true,
  icon: <ExclamationCircleOutlined />,
  className: 'font-normal',
} as const

const errorAlert = (
  <div className="grid grid-cols-3 gap-x-8 gap-y-2 mt-8 font-semibold">
    <div className="p-2 bg-[#fafafa]">Informace o řízení</div>
    <div className="p-2 bg-[#fafafa]">Účastníci řízení</div>
    <div className="p-2 bg-[#fafafa]">Průběh řízení</div>
    <Alert message="Informace o řízení nenalezeny" {...alertProps} />
    <Alert message="Účastníci řízení nenalezeni" {...alertProps} />
    <Alert message="Průběh řízení nenalezen" {...alertProps} />
  </div>
)

export const ProceedingDetailInformation: FunctionComponent<ProceedingDetailInformationProps> =
  ({ record, data, loading, error }) => {
    if (error) return errorAlert

    return (
      <div className="flex gap-8 flex-wrap mt-8">
        <ProceedingDetailBaseInfo
          record={record}
          data={data}
          loading={loading}
        />
        <ProceedingDetailParticipants
          record={record}
          data={data}
          loading={loading}
        />
        <ProceedingDetailTimeline data={data} loading={loading} />
      </div>
    )
  }
