import type { Dispatch, SetStateAction } from 'react'
import React, {
  createContext,
  FunctionComponent,
  ReactNode,
  useMemo,
  useState,
} from 'react'

export interface CadastreMapThemeState {
  buildingFill: string
  buildingOutline: string
  buildingPointFill: string
  buildingPointOutline: string
  buildingClusterCircleFill: string
  buildingClusterCircleOutline: string
  buildingClusterText: string

  parcelFill: string
  parcelOutline: string
  parcelPointFill: string
  parcelPointOutline: string
  parcelClusterCircleFill: string
  parcelClusterCircleOutline: string
  parcelClusterText: string
}
export interface CadastreMapContextThemeStateType {
  theme: CadastreMapThemeState
  setTheme: Dispatch<SetStateAction<CadastreMapThemeState>>
}

export type CadastreMapRootState = CadastreMapContextThemeStateType

export const CadastreMapContext = createContext<
  CadastreMapRootState | undefined
>(undefined)

export const CadastreMapProvider: FunctionComponent<{
  children: ReactNode
}> = ({ children }) => {
  const [theme, setTheme] = useState<CadastreMapThemeState>({
    buildingFill: '#90EE90',
    buildingOutline: '#228B22',
    buildingPointFill: '#32CD32',
    buildingPointOutline: '#006400',
    buildingClusterCircleFill: '#32CD32',
    buildingClusterCircleOutline: '#006400',
    buildingClusterText: '#000000',

    parcelFill: '#ADD8E6',
    parcelOutline: '#4682B4',
    parcelPointFill: '#87CEFA',
    parcelPointOutline: '#000080',
    parcelClusterCircleFill: '#87CEFA',
    parcelClusterCircleOutline: '#000080',
    parcelClusterText: '#000000',
  })

  const value = useMemo(() => ({ theme, setTheme }), [theme, setTheme])

  return (
    <CadastreMapContext.Provider value={value}>
      {children}
    </CadastreMapContext.Provider>
  )
}
