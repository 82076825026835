import { Button, Checkbox } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import { CadastreMapDebtFilterFormOutput } from '../../../../../common/cadastreMapDebtTypes'
import { CadastreMapDebtLayerControlModalButton } from '../../../CadastreMapDebtLayerControlModalButton/CadastreMapDebtLayerControlModalButton'
import { Overlay, OverlayProps } from '../CadastreMapOverlay/CadastreMapOverlay'

export interface CadastreMapDebtLayerControlProps extends OverlayProps {
  enabled: boolean
  onEnabledChange: React.Dispatch<React.SetStateAction<boolean>>
  onFilterParams: (where: CadastreMapDebtFilterFormOutput) => void
}

export const CadastreMapDebtLayerControl: FunctionComponent<CadastreMapDebtLayerControlProps> =
  props => {
    const toggleEnabled = useCallback(
      () => props.onEnabledChange(prev => !prev),
      [props]
    )

    return (
      <Overlay position={props.position}>
        <div className="flex space-x-2">
          <Button
            onClick={toggleEnabled}
            className="hover:!bg-white"
            title="Zobrazit vybrané budovy"
            icon={<Checkbox checked={props.enabled} rootClassName="!mr-2" />}
          >
            Exekuce/Insolvence
          </Button>

          <CadastreMapDebtLayerControlModalButton
            onFilterParams={props.onFilterParams}
          />
        </div>
      </Overlay>
    )
  }
