import { LoadingOutlined } from '@ant-design/icons'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import { Button, Drawer, Spin } from 'antd'
import { BaseButtonProps } from 'antd/lib/button/button'
import React, { FunctionComponent, ReactElement, useMemo } from 'react'

import { useToggleState } from '../../utils/useToggleState'

export interface PdfDrawerPreviewProps {
  buttonProps?: BaseButtonProps
  buttonText?: React.ReactNode
  children: ReactElement
  fileName?: string
  drawerTitle?: string
  defaultOpen?: boolean
}

const bodyStyle = {
  display: 'flex',
  padding: 0,
  position: 'relative' as const,
}
const loader = <LoadingOutlined style={{ fontSize: 34, color: 'white' }} spin />

export const PdfDrawerPreview: FunctionComponent<PdfDrawerPreviewProps> =
  props => {
    const [isDrawerOpen, toggleDrawerOpen] = useToggleState(props.defaultOpen)

    const extra = useMemo(
      () => (
        <div className="space-x-2">
          <Button onClick={toggleDrawerOpen}>Zavřít</Button>
          <PDFDownloadLink
            document={props.children}
            fileName={props.fileName || 'PDF-REGESTA-DATA'}
          >
            <Button type="primary" onClick={toggleDrawerOpen}>
              Stáhnout PDF
            </Button>
          </PDFDownloadLink>
        </div>
      ),
      [toggleDrawerOpen, props.children, props.fileName]
    )

    return (
      <>
        <Button
          type="primary"
          onClick={toggleDrawerOpen}
          {...props.buttonProps}
        >
          {props.buttonText || 'Zobrazit PDF'}
        </Button>

        <Drawer
          title={props.drawerTitle || 'Náhled PDF dokumentu'}
          placement="right"
          size="large"
          onClose={toggleDrawerOpen}
          open={isDrawerOpen}
          bodyStyle={bodyStyle}
          extra={extra}
          destroyOnClose
        >
          <div className="flex flex-col w-full h-full bg-gray-400 animate-pulse absolute items-center justify-center">
            <Spin indicator={loader} />
          </div>
          <PDFViewer className="z-10" width="100%">
            {props.children}
          </PDFViewer>
        </Drawer>
      </>
    )
  }
