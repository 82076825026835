import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { WatchdogLastChannel } from '../common/watchdogTypes'
import { WatchDogDataTypes } from './fetchAction'

const watchdogLastChannelSlice = createSlice({
  name: WatchDogDataTypes.LAST_CHANNEL,
  initialState: {
    id: null,
    name: null,
  },
  reducers: {
    setWatchdogLastChannel: (
      state: WatchdogLastChannel,
      action: PayloadAction<WatchdogLastChannel>
    ) => {
      state.id = action.payload.id
      state.name = action.payload.name
    },
  },
})

export const { setWatchdogLastChannel } = watchdogLastChannelSlice.actions
export default watchdogLastChannelSlice.reducer
