import axios from 'axios'
import { useEffect, useState } from 'react'

import { PROPERTY_TYPES } from '../../common/drmTypes'
import { BudovaZpusobVyuzitiFetchResult } from '../../common/foreclosureMonitoringTypes'
import { useAppSelector } from '../../redux/hooks'
import { propertyTypeText } from './propertyMonitoringHelpers'

export const useFetchPropertyUseEnums = (propertyType: PROPERTY_TYPES) => {
  const token = useAppSelector(state => state.myProfile.token)

  const [result, setResult] = useState<BudovaZpusobVyuzitiFetchResult>({
    fetching: true,
    error: false,
    data: null,
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        setResult(prev => ({ ...prev, fetching: true }))

        const response = await axios.get(
          `https://data.regesta.cz/DrmApi/api/v1.0/Ciselniky/Nemovitosti/${propertyTypeText[propertyType]}/ZpusobVyuziti?access_token=${token}`
        )

        setResult({
          fetching: false,
          error: false,
          data: response.data,
        })
      } catch (error) {
        setResult({
          fetching: false,
          error: true,
          data: null,
        })
      }
    }

    fetchData()
  }, [propertyType, token])

  return result
}
