import React, { FunctionComponent } from 'react'

import { useKuWorkplaceByAreaCodeQuery } from '../../../graphql/generated'
import { KuWorkplaceTooltip } from '../KuWorkplaceTooltip/KuWorkplaceTooltip'

export interface LvDetailSidebarLvWorkplaceProps {
  cadastralAreaCode?: number | string
}

export const LvDetailSidebarLvWorkplace: FunctionComponent<LvDetailSidebarLvWorkplaceProps> =
  props => {
    const [queryResult] = useKuWorkplaceByAreaCodeQuery({
      pause: !props.cadastralAreaCode,
      variables: { kuKod: `${props.cadastralAreaCode || ''}` },
      requestPolicy: 'cache-first',
    })

    const workplace =
      queryResult.data?.allKuAreas?.nodes?.[0]?.kuWorkplaceByPraresKod

    if (queryResult.fetching) return <div>...</div>
    if (!workplace) return <div className="text-gray-400">Nenalezeno</div>

    return (
      <KuWorkplaceTooltip workplace={workplace}>
        {workplace?.nazev}
      </KuWorkplaceTooltip>
    )
  }
