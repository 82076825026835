import React, { FunctionComponent } from 'react'

import {
  RestrictionCheckboxData,
  RestrictionChecType,
} from '../../../common/epoTypes'
import { CadastralSelectionModalButton } from '../CadastralSelectionModalButton/CadastralSelectionModalButton'
import { CeeMonitorTableIdentificationItem as Item } from '../CeeMonitorTableIdentificationItem/CeeMonitorTableIdentificationItem'
import { CuzkEpoRestrictionCheckInfoPanelItemActionsHugeSubject } from '../CuzkEpoRestrictionCheckInfoPanelItemActionsHugeSubject/CuzkEpoRestrictionCheckInfoPanelItemActionsHugeSubject'

export interface CuzkEpoRestrictionCheckInfoPanelItemActionsProps {
  epoId: string
  checkboxHugeData?: RestrictionCheckboxData
  checkboxData?: RestrictionCheckboxData
  refetch: () => void
}

export const CuzkEpoRestrictionCheckInfoPanelItemActions: FunctionComponent<CuzkEpoRestrictionCheckInfoPanelItemActionsProps> =
  props => {
    if (!props.checkboxData) return null
    return (
      <Item
        loading={props.checkboxData?.loading}
        title=""
        className="flex justify-center items-center"
      >
        <div className="flex justify-center items-center space-x-2">
          {props.checkboxHugeData?.data.length ? (
            <CuzkEpoRestrictionCheckInfoPanelItemActionsHugeSubject
              epoId={props.epoId}
              checkboxData={props.checkboxData}
              checkboxHugeData={props.checkboxHugeData}
              refetch={props.refetch}
            />
          ) : (
            <CadastralSelectionModalButton
              epoId={props.epoId}
              checkboxData={props.checkboxData.data}
              refetch={props.refetch}
              type={RestrictionChecType.lien}
            />
          )}
        </div>
      </Item>
    )
  }
