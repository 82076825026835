import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { BASE_URL } from '../../../../ini.json'
import { useAppSelector } from '../../../redux/hooks'
import { stringSorter } from '../../utils/generalTableHelpers'
import { statisticPagination } from '../../utils/layoutConst'
import { StatisticCompany } from '../../utils/statisticHelpers'
import { Min } from '../DateFormat/DateFormat'

interface CeeStatisticsTableItem {
  createdAt: string
  accountId: string
  accountUserId: string
  executionCount?: string
  firstname?: string
  lastname?: string
  monitoring?: string
  rc?: string
  ico?: string
  birthDate?: string
  lastCheck?: string
  detailCode?: string
}
export interface CeeStatisticsTableProps {
  companies?: StatisticCompany
}

export const CeeStatisticsTable: FunctionComponent<CeeStatisticsTableProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)
    const [loading, setLoading] = useState<boolean | undefined>()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [tableData, setTableData] = useState<any>()

    const fetchData = useCallback(async () => {
      setLoading(true)

      fetch(`${BASE_URL}/api/cee/admin/statistics/${token}`)
        .then(response => response.json())
        .then(data => {
          setTableData(data)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
          setTableData([])
        })
    }, [token])

    useEffect(() => {
      if (tableData !== undefined) return
      fetchData()
    }, [tableData, fetchData])

    const columns: ColumnsType<CeeStatisticsTableItem> = [
      {
        title: '#',
        key: 'index',
        className: 'w-8',
        render: (_, __, index) => index + 1,
      },
      {
        title: 'id',
        dataIndex: 'id',
        ellipsis: { showTitle: true },
        className: 'w-14 cursor-help',
      },
      {
        title: 'Datum lustrace',
        dataIndex: 'lastCheck',
        className: 'w-40',
        defaultSortOrder: 'descend',
        sorter: {
          compare: (a, b) =>
            (a.lastCheck || a.createdAt) && (b.lastCheck || b.createdAt)
              ? (a.lastCheck || a.createdAt)
                  .toString()
                  .localeCompare((b.lastCheck || b.createdAt).toString())
              : -1,
        },

        render: (_, record) =>
          record.lastCheck || record.createdAt ? (
            <Min>{record.lastCheck || record.createdAt}</Min>
          ) : (
            ''
          ),
      },
      {
        title: 'Klient',
        dataIndex: 'accountId',
        ellipsis: { showTitle: true },
        sorter: stringSorter('accountId'),
        showSorterTooltip: { title: 'Řadí podle ID, ne názvu' },
        render: id => (
          <a
            className="!text-primary"
            href={`https://data.regesta.cz/PortalAdmin/DetailFirmy/${id}`}
            target="_blank"
            rel="noreferrer"
          >
            {props.companies?.[id] || id}
          </a>
        ),
      },
      {
        title: 'ID uživ. klienta',
        dataIndex: 'accountUserId',
        ellipsis: { showTitle: true },
        className: 'w-36',
      },
      {
        title: 'Vyhledávané údaje',
        dataIndex: 'title',
        render: (_, record) => (
          <div>
            {record.rc && 'RČ: ' + record.rc}
            {record.ico && 'IČO: ' + record.ico}
            {record.firstname} {record.lastname} {record.birthDate}
          </div>
        ),
      },
      {
        title: 'Nalezeno exekucí / kód detailu',
        dataIndex: 'executionCount',
        render: (_, record) =>
          record.detailCode || (record.lastCheck ? record.executionCount : '-'),
      },
    ]

    return (
      <Table
        pagination={statisticPagination}
        dataSource={tableData}
        columns={columns}
        loading={loading}
        rowKey="id"
        size="small"
      />
    )
  }
