import { Button, message, Modal } from 'antd'
import { BaseButtonProps } from 'antd/es/button/button'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useAppSelector } from '../../../redux/hooks'
import { checkCuzkSubject } from '../../utils/checkCuzkSubject'
import { cuzkOsInstantCheckLimit } from '../../utils/layoutConst'
import { ceeCheckPath, ceeMonitorPath } from '../../utils/paths'
import { useCreateCeeMonitoringHandler } from '../../utils/useCreateCeeMonitoringHandler'
import { useToggleState } from '../../utils/useToggleState'
import { CeeMonitorAddSubjectModalDescription } from '../CeeMonitorAddSubjectModalDescription/CeeMonitorAddSubjectModalDescription'
import { CeeMonitorAddSubjectModalFooter } from '../CeeMonitorAddSubjectModalFooter/CeeMonitorAddSubjectModalFooter'
import { CeeMonitorAddSubjectModalOptions } from '../CeeMonitorAddSubjectModalOptions/CeeMonitorAddSubjectModalOptions'
import { CeeMonitorAddSubjectModalTitle } from '../CeeMonitorAddSubjectModalTitle/CeeMonitorAddSubjectModalTitle'
import { RcTableItemConcept } from '../RcInput/RcInput'

export interface MonitorAddSubjectModal {
  dataSource: RcTableItemConcept[]
  buttonProps?: BaseButtonProps
  buttonText?: string
  monitoring?: boolean
  noRedirect?: boolean
  refetch?: () => void
}

export const CeeMonitorAddSubjectModal: FunctionComponent<MonitorAddSubjectModal> =
  props => {
    const history = useHistory()
    const token = useAppSelector(state => state.myProfile.token)

    const [checkCuzk, toggleCheckCuzk] = useToggleState(false)
    const [open, toggleOpen] = useToggleState()
    const [showHelp, toggleShowHelp] = useToggleState()
    const [uploading, setUploading] = useState(false)
    const [checkFrequency, setCheckFrequency] = useState<number | null>(0)
    const [labelsId, setLabelsId] = useState<string[] | []>([])

    const { processSubject } = useCreateCeeMonitoringHandler()

    const subjectCount = useMemo(() => {
      const counts = props.dataSource.reduce(
        (acc, item) => {
          if (item.validatedCode === 30) {
            acc.valid++
          } else if (item.validatedCode && item.validatedCode < 10) {
            acc.invalid++
          } else {
            acc.notValidated++
          }
          return acc
        },
        { valid: 0, invalid: 0, notValidated: 0, forCheck: 0 }
      )
      counts.forCheck = counts.valid + counts.notValidated

      return counts
    }, [props.dataSource])

    const onSubmit = useCallback(async () => {
      setUploading(true)
      const ceeIds: string[] = []
      const cuzkIds: string[] = []

      try {
        for (const item of props.dataSource) {
          const { ceeId, cuzkId } = await processSubject(
            item,
            checkFrequency,
            checkCuzk,
            labelsId
          )

          if (ceeId) ceeIds.push(ceeId)
          if (cuzkId) cuzkIds.push(cuzkId)
        }

        if (checkCuzk && cuzkIds.length <= cuzkOsInstantCheckLimit) {
          await checkCuzkSubject(cuzkIds, token)
        }

        props.noRedirect
          ? toggleOpen()
          : history.push(props.monitoring ? ceeMonitorPath() : ceeCheckPath())
      } catch (error) {
        message.error('Nepodařilo se zařadit subjekt ke kontrole')
      } finally {
        setUploading(false)
      }
    }, [
      checkCuzk,
      props.noRedirect,
      props.monitoring,
      props.dataSource,
      toggleOpen,
      history,
      processSubject,
      checkFrequency,
      labelsId,
      token,
    ])

    return (
      <>
        <Button
          size="large"
          type="primary"
          onClick={toggleOpen}
          disabled={!subjectCount.forCheck}
          className="sm:w-auto w-full"
          {...props.buttonProps}
        >
          {props.buttonText || 'Ověřit zadané subjekty v CEE'}
        </Button>
        <Modal
          title={
            <CeeMonitorAddSubjectModalTitle
              dataSource={props.dataSource}
              forCheckCount={subjectCount.forCheck}
            />
          }
          open={open}
          onCancel={toggleOpen}
          width={600}
          footer={
            <CeeMonitorAddSubjectModalFooter
              onClose={toggleOpen}
              onSubmit={onSubmit}
              toggleHelp={toggleShowHelp}
              showHelp={showHelp}
              uploading={uploading}
              subjectCount={subjectCount}
            />
          }
        >
          <div>
            <CeeMonitorAddSubjectModalOptions
              monitoring={!!props.monitoring}
              showHelp={showHelp}
              checkCuzk={checkCuzk}
              onCheckFrequencyChange={setCheckFrequency}
              onLabelsChange={setLabelsId}
              onCheckCuzkChange={toggleCheckCuzk}
            />

            <CeeMonitorAddSubjectModalDescription
              checkFrequency={checkFrequency}
            />
          </div>
        </Modal>
      </>
    )
  }
