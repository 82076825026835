import { Icon } from '@iconify/react'
import React, { FunctionComponent } from 'react'
import { UseQueryState } from 'urql'

import {
  AUCTION_STATES,
  AUCTION_TYPES,
} from '../../../common/auctionScrapeTypes'
import { AuctionDetailDrawerQuery, Exact } from '../../../graphql/generated'
import { AuctionDetailDrawerTitle } from '../AuctionDetailDrawerTitle/AuctionDetailDrawerTitle'
import { AuctionMonitoringTilesItemImage } from '../AuctionMonitoringTilesItemImage/AuctionMonitoringTilesItemImage'
import { CadastreMapDetailDrawerFetchError } from '../CadastreMapDetailDrawerFetchError/CadastreMapDetailDrawerFetchError'
import { Min } from '../DateFormat/DateFormat'
import { DetailDrawerPropertySkeleton } from '../DetailDrawerPropertySkeleton/DetailDrawerPropertySkeleton'
import { Money } from '../Money/Money'
import { PriceEstimateLayoutLvDataRow as Item } from '../PriceEstimateLayoutLvDataRow/PriceEstimateLayoutLvDataRow'
import { PropertyDetailSidebarContainer } from '../PropertyDetailSidebarContainer/PropertyDetailSidebarContainer'

export interface AuctionDetailDrawerBodyProps {
  dataSource: UseQueryState<
    AuctionDetailDrawerQuery,
    Exact<{
      id: string
    }>
  >
}

export const AuctionDetailDrawerBody: FunctionComponent<AuctionDetailDrawerBodyProps> =
  ({ dataSource }) => {
    const auction = dataSource.data?.auctionListingById

    if (dataSource.fetching && !auction) return <DetailDrawerPropertySkeleton />
    if (dataSource.error || !auction)
      return <CadastreMapDetailDrawerFetchError />

    return (
      <div className="flex flex-col space-y-3 mb-4">
        <AuctionDetailDrawerTitle
          title={auction.title}
          detailUrl={auction.detailUrl}
        />

        <AuctionMonitoringTilesItemImage
          auctionId={auction.id}
          auctionType={auction.type as AUCTION_TYPES}
          lat={auction.lat}
          long={auction.long}
          photoUrl={auction.photoUrl}
          className="min-h-[450px]"
          auctionUrl={auction.detailUrl}
        />

        <PropertyDetailSidebarContainer isDrawer>
          <div className="flex flex-col space-y-2 animate-fadeIn">
            <Item title="Stav">
              {auction.state === AUCTION_STATES.UPCOMING
                ? 'Připravovaná'
                : 'Ukončená'}
            </Item>

            <Item title="Místo konání">
              {
                <a
                  className="flex items-center space-x-1"
                  href={auction.detailUrl}
                  target="_blank"
                  rel="noreferrer"
                  title="Zobrazit detail dražby v novém okně"
                >
                  <Icon
                    icon="quill:link-out"
                    className="-mb-0.5"
                    width="12"
                    height="12"
                  />
                  <span>{auction.auctionedOn}</span>
                </a>
              }
            </Item>

            <Item title="Datum zahájení">
              {auction.startDate ? (
                <Min>{auction.startDate}</Min>
              ) : (
                <span className="text-gray-400">Neuvedeno</span>
              )}
            </Item>

            <Item title="Datum ukončení">
              {auction.endDate ? (
                <Min>{auction.endDate}</Min>
              ) : (
                <span className="text-gray-400">Neuvedeno</span>
              )}
            </Item>

            <Item title="Cena">
              {auction.price > 0 ? (
                <Money
                  amount={auction.price}
                  decimalNumbers={0}
                  grayscale
                  symbol="Kč"
                />
              ) : (
                <span className="text-gray-400">Neuvedeno</span>
              )}
            </Item>

            <Item title="Dražební vyhláška">
              {auction.auctionDecreeUrl ? (
                <a
                  href={auction.auctionDecreeUrl}
                  target="_blank"
                  rel="noreferrer"
                  title="Zobrazit dražební vyhlášku v novém okně"
                >
                  Stáhnout
                </a>
              ) : (
                <span className="text-gray-400">Neuvedeno</span>
              )}
            </Item>

            {!auction.description.length || auction.description.length < 300 ? (
              <Item title="Popis dražby">
                {auction.description || (
                  <span className="text-gray-400">Neuvedeno</span>
                )}
              </Item>
            ) : (
              <div className="font-semibold text-justify">
                <div className="inline-block h-8 w-1/3 float-left font-normal">
                  Popis dražby:
                </div>
                <div>{auction.description}</div>
              </div>
            )}
          </div>
        </PropertyDetailSidebarContainer>
      </div>
    )
  }
