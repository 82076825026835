import { Icon } from '@iconify/react'
import { message, Spin, Tooltip } from 'antd'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'

import { AddressApiResponse } from '../../../common/addressTypes'
import { DataBoxSubjectType } from '../../../common/dataBoxTypes'
import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { PropertyPolygon } from '../../../common/ruianTypes'
import { useDataBoxDetailQuery } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { Identifier } from '../../utils/dynamicSearchTooltip'
import {
  dataBoxDetailPath,
  requestAddressPath,
  requestPropertyPolygonPath,
} from '../../utils/paths'
import { formatPolygon } from '../../utils/polygonHelpers'
import { useMapImage } from '../../utils/useMapImage'
import { AddressTooltip } from '../AddressTooltip/AddressTooltip'
import { ColumnGridItem } from '../ColumnGridItem/ColumnGridItem'
import { DataBoxDetailTable } from '../DataBoxDetailTable/DataBoxDetailTable'
import { DataBoxDetailVatPayer } from '../DataBoxDetailVatPayer/DataBoxDetailVatPayer'
import { DynamicSearchTooltip } from '../DynamicSearchTooltip/DynamicSearchTooltip'
import { PriceEstimateMapBox } from '../PriceEstimateMapBox/PriceEstimateMapBox'

export interface DataBoxDetailProps {
  dataBoxId?: string
}

export const DataBoxDetail: FunctionComponent<DataBoxDetailProps> = props => {
  const [queryResult] = useDataBoxDetailQuery({
    variables: { id: props.dataBoxId || '' },
    pause: !props.dataBoxId,
  })

  const token = useAppSelector(state => state.myProfile.token)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [ruianData, setRuianData] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const subjectData = queryResult.data?.databoxById

  const history = useHistory()

  const fetchData = useCallback(async () => {
    try {
      setLoading(true)
      const response = await fetch(
        requestAddressPath() + token + '/' + subjectData?.adresnimistokod
      )
      const data: AddressApiResponse = await response?.json()

      const polygonResponse = await fetch(
        requestPropertyPolygonPath(
          PROPERTY_TYPES.BUILDING,
          token,
          data?.budovaId
        )
      )
      const polygonData: PropertyPolygon = await polygonResponse?.json()
      const polygon = formatPolygon(polygonData?.polygons?.[0]?.exterior)

      setRuianData({
        ...data,
        lat: data.definicniBod.lat,
        lon: data.definicniBod.long,
        polygon: polygon,
        address: data.formatovanaAdresa,
        street: data.ulice,
        city: data.obec,
        townHall: data.radnice,
        id: data.budovaId,
      })
    } catch (error) {
      message.error('Nepodařilo se načíst detaily adresy.')
    } finally {
      setLoading(false)
      subjectData?.databoxid &&
        history.push(dataBoxDetailPath(subjectData?.databoxid))
    }
  }, [history, subjectData?.adresnimistokod, subjectData?.databoxid, token])

  useEffect(() => {
    if (props.dataBoxId && subjectData?.adresnimistokod) {
      fetchData()
    }
  }, [fetchData, subjectData?.adresnimistokod, props.dataBoxId])

  const cuzkQuery = useMemo(
    () =>
      `${subjectData?.businessname || subjectData?.fullname}&adresa=${
        subjectData?.businessname
          ? ''
          : ruianData?.street + ' ' + ruianData?.city
      }&vcetneHistorie=false`,
    [
      subjectData?.businessname,
      subjectData?.fullname,
      ruianData?.city,
      ruianData?.street,
    ]
  )
  const nameValue = useMemo(
    () => (
      <Tooltip title="Vyhledat v ČÚZK">
        <a
          target="_blank"
          href={`https://data.regesta.cz/Vyhledavani/Subjekty?nazev=${cuzkQuery}`}
          rel="noreferrer"
        >
          {subjectData?.businessname || subjectData?.fullname}
        </a>
      </Tooltip>
    ),
    [cuzkQuery, subjectData?.businessname, subjectData?.fullname]
  )

  const icoValue = useMemo(
    () =>
      subjectData?.ico ? (
        <DynamicSearchTooltip
          identifier={{
            [Identifier.ICO]: subjectData.ico,
            [Identifier.COMPANY_NAME]: subjectData?.businessname || undefined,
          }}
          identifierType={Identifier.ICO}
        >
          {subjectData.ico}
        </DynamicSearchTooltip>
      ) : (
        <span className="text-gray-400 font-normal">nepřiděleno</span>
      ),
    [subjectData?.businessname, subjectData?.ico]
  )

  const imgSrc = useMapImage(
    ruianData?.lat,
    ruianData?.lon,
    ruianData?.typ_nemovitosti,
    ruianData?.polygon
  )

  const addressValue = useMemo(() => {
    if (loading) {
      return <Spin size="small" />
    } else if (subjectData?.adresnimistokod) {
      return (
        <div className="flex items-center">
          <AddressTooltip
            gpsData={{
              latitude: ruianData?.lat,
              longitude: ruianData?.lon,
            }}
            addressData={ruianData}
          />
          {ruianData?.townHall && (
            <Tooltip
              className="ml-2 -mt-1"
              title={`Shoda s adresou radnice ${ruianData.townHall}`}
            >
              <Icon icon="fa:fort-awesome" inline width="13" />
            </Tooltip>
          )}
        </div>
      )
    } else if (subjectData?.fulladdress) {
      return subjectData?.fulladdress
    } else {
      return <span className="text-gray-400 font-normal">neuvedeno</span>
    }
  }, [
    subjectData?.adresnimistokod,
    subjectData?.fulladdress,
    loading,
    ruianData,
  ])

  return (
    <>
      <div className="grid md:grid-cols-2 gap-8 print:gap-2">
        <div className="order-2 md:order-1 col-span-2 md:col-span-1 print:col-span-2 print:order-2">
          <ColumnGridItem label="Název / jméno" value={nameValue} />
          <ColumnGridItem
            label="Typ subjektu"
            value={
              DataBoxSubjectType[
                subjectData?.type as keyof typeof DataBoxSubjectType
              ]
            }
          />
          <ColumnGridItem
            label="Příjem poštovních dat. zpráv"
            value={subjectData?.pdz ? 'Ano' : 'Ne'}
          />
          <ColumnGridItem label="Adresa" value={addressValue} />
          <ColumnGridItem
            label="Datová schránka"
            value={subjectData?.databoxid}
          />
          <ColumnGridItem
            label="ID hlavní datové schránky"
            value={subjectData?.masterid}
            conditional
          />
          <ColumnGridItem label="IČO" value={icoValue} />
          <DataBoxDetailVatPayer
            businessname={subjectData?.businessname || ''}
            ico={subjectData?.ico || ''}
          />
          <DataBoxDetailTable
            databoxid={subjectData?.ismaster ? subjectData?.databoxid : ''}
          />
        </div>
        <div className="order-1 xl:order-2 space-y-8 print:col-span-2 print:space-y-2 print:order-1">
          {subjectData?.adresnimistokod ? (
            <PriceEstimateMapBox imgSrc={imgSrc} />
          ) : (
            <div className="flex bg-gray-100 h-full items-center justify-center text-gray-500">
              Mapu nelze zobrazit, neboť u subjektu není evidována adresa.
            </div>
          )}
        </div>
      </div>
    </>
  )
}
