import { useCallback } from 'react'

import { useCreateMonitoringCeeMutation } from '../../graphql/generated'
import { useAppSelector } from '../../redux/hooks'
import { RcTableItemConcept } from '../components/RcInput/RcInput'
import { parseCzechDate } from './parseCzechDate'

export const useCreateCeeMonitoring = () => {
  const tenantId = useAppSelector(state => state.myProfile.tenantId)
  const userId = useAppSelector(state => state.myProfile.userId)
  const [, addMonitoredSubject] = useCreateMonitoringCeeMutation()

  const createCeeMonitoring = useCallback(
    async (
      item: RcTableItemConcept,
      checkFrequency: number | null
    ): Promise<string | null> => {
      const result = await addMonitoredSubject({
        input: {
          monitoringCee: {
            rc: item.rc,
            ico: item.ico,
            firstname: item.firstname,
            lastname: item.lastname,
            birthDate: parseCzechDate(item.birthDate || ''),
            validatedCode: item.validatedCode,
            checkFrequency: checkFrequency,
            monitoring:
              typeof checkFrequency === 'number' && checkFrequency !== 0,
            accountId: tenantId,
            accountUserId: userId,
            note: item.description,
          },
        },
      })

      return result.data?.createMonitoringCee?.monitoringCee?.id || null
    },
    [addMonitoredSubject, tenantId, userId]
  )

  return { createCeeMonitoring }
}
