import ExclamationCircleFilled from '@ant-design/icons/lib/icons/ExclamationCircleFilled'
import InfoCircleOutlined from '@ant-design/icons/lib/icons/InfoCircleOutlined'
import { Alert, Popover } from 'antd'
import React, { FunctionComponent } from 'react'

import { icoErrorDescription } from '../../utils/icoErrorDescription'

export interface IcoInputErrorProps {
  error: number
  children?: React.ReactNode
}

export const IcoInputError: FunctionComponent<IcoInputErrorProps> = props => {
  const errorCode = props.error || 30
  const content = (
    <div>
      <div className="mb-4">{icoErrorDescription[errorCode].description}</div>
      <Alert
        message={icoErrorDescription[errorCode].message}
        type={icoErrorDescription[errorCode].type}
        showIcon
      />
    </div>
  )
  if (props.error)
    return (
      <Popover
        overlayStyle={{ maxWidth: '300px' }}
        title={icoErrorDescription[errorCode].title}
        content={content}
        className="cursor-help"
      >
        {props.children ? (
          props.children
        ) : (
          <span
            className={
              icoErrorDescription[errorCode].type === 'warning'
                ? 'text-orange-300'
                : 'text-red-500'
            }
          >
            {icoErrorDescription[errorCode].type === 'warning' ? (
              <InfoCircleOutlined className="mr-2" />
            ) : (
              <ExclamationCircleFilled className="mr-2" />
            )}
            <span className="underline underline-offset-2 decoration-dashed">
              {icoErrorDescription[errorCode].title}
            </span>
          </span>
        )}
      </Popover>
    )
  return <>{props.children}</>
}
