import { Divider, Form, Input, message } from 'antd'
import React, { forwardRef, useCallback, useImperativeHandle } from 'react'
import { gql, useMutation } from 'urql'

import {
  useCreateLabelConnectionMutation,
  useDeleteLabelConnectionMutation,
} from '../../../graphql/generated'
import { cuzkMonitorRcFormHelp } from '../../utils/cuzkMonitorRcFormHelp'
import { LabelBulkEditOptions } from '../../utils/labelsTypes'
import { formItemLayout } from '../../utils/layoutConst'
import { useConst } from '../../utils/useConst'
import { CheckFrequencySelectInput } from '../CheckFrequencySelectInput/CheckFrequencySelectInput'
import { RcTableItem } from '../CuzkMonitorRcTable/CuzkMonitorRcTable'
import { LabelEditTypeSelectInput } from '../LabelEditTypeSelectInput/LabelEditTypeSelectInput'
import { LabelSelectInput } from '../LabelSelectInput/LabelSelectInput'

export interface CuzkMonitorRcBulkEditFormProps {
  selectedItems: RcTableItem[]
  showHelp: boolean
  setLoading: (loading: boolean) => void
  refetch: () => void
  hideModal: () => void
}

export const CuzkMonitorRcBulkEditForm = forwardRef<
  unknown,
  CuzkMonitorRcBulkEditFormProps
>((props, ref) => {
  const updateMonitoredSubjectQuery = gql`
    mutation ($description: String, $checkFrequency: Int, $id: UUID!) {
      updateMonitoringCuzkRcById(
        input: {
          monitoringCuzkRcPatch: {
            description: $description
            checkFrequency: $checkFrequency
          }
          id: $id
        }
      ) {
        clientMutationId
      }
    }
  `
  const [form] = Form.useForm()
  useImperativeHandle(ref, () => ({ submit: form.submit }))

  const [, addLabelConnection] = useCreateLabelConnectionMutation()
  const [, deleteLabelConnection] = useDeleteLabelConnectionMutation()
  const [, updateMonitoredSubject] = useMutation(updateMonitoredSubjectQuery)

  const initialValues = useConst({
    description: '[puvodni_poznamka]',
    labelEditType: 'inherit',
    checkFrequency: -1,
  })
  const labelEditType = Form.useWatch('labelEditType', form)

  const onFinish = useCallback(
    (newValues: {
      description: string
      labelEditType: string
      checkFrequency: number
      label?: string[]
    }) => {
      props.setLoading(true)

      const promises = props.selectedItems.map(async item => {
        const values = {
          id: item.id,
          description: newValues?.description?.replace(
            '[puvodni_poznamka]',
            item?.description || ''
          ),
          checkFrequency:
            newValues.checkFrequency === -1
              ? item.checkFrequency
              : newValues.checkFrequency,
        }
        if (item.validatedCode && item.validatedCode > 10) {
          try {
            await updateMonitoredSubject(values).then(() => {
              const initialLabelsIds = item.labels?.nodes?.map(
                label => label?.labelByLabelId?.id
              )
              const initialLabels = item.labels?.nodes?.map(label => ({
                connectionId: label?.id,
                id: label?.labelByLabelId?.id,
              }))

              if (newValues.labelEditType === LabelBulkEditOptions.Add) {
                newValues?.label?.forEach(
                  labelId =>
                    !initialLabelsIds?.includes(labelId) &&
                    addLabelConnection({
                      monitoringCuzkRcId: values.id,
                      labelId: labelId,
                    })
                )
              }

              if (
                newValues.labelEditType === LabelBulkEditOptions.RemoveSelected
              ) {
                newValues?.label?.forEach(labelId =>
                  initialLabels?.forEach(
                    initialLabel =>
                      initialLabel.id === labelId &&
                      deleteLabelConnection({
                        id: initialLabel.connectionId,
                      })
                  )
                )
              }

              if (newValues.labelEditType === LabelBulkEditOptions.RemoveAll) {
                initialLabels?.forEach(initialLabel =>
                  deleteLabelConnection({
                    id: initialLabel.connectionId,
                  })
                )
              }

              if (newValues.labelEditType === LabelBulkEditOptions.ReplaceBy) {
                newValues?.label?.forEach(labelId =>
                  initialLabels?.forEach(
                    initialLabel =>
                      initialLabel.id !== labelId &&
                      deleteLabelConnection({
                        id: initialLabel.connectionId,
                      })
                  )
                )
                newValues?.label?.forEach(
                  labelId =>
                    !initialLabelsIds?.includes(labelId) &&
                    addLabelConnection({
                      monitoringCuzkRcId: values.id,
                      labelId: labelId,
                    })
                )
              }
            })
          } catch (error) {
            message.error('Chyba při editaci štítků')
          }
        }
      })

      Promise.all(promises)
        .then(() => {
          props.setLoading(false)
          props.refetch()
          props.hideModal()
          message.success('Záznamy byly uloženy.')
        })
        .catch(() => {
          message.error('Záznamy se nepodařilo uložit')
        })
    },
    [props, updateMonitoredSubject, addLabelConnection, deleteLabelConnection]
  )
  return (
    <Form
      {...formItemLayout}
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        name="description"
        label="Poznámka"
        help={props.showHelp && cuzkMonitorRcFormHelp.note}
      >
        <Input.TextArea placeholder="Napište poznámku k vybraným subjektům" />
      </Form.Item>

      <Form.Item
        name="checkFrequency"
        label="Frekvence kontroly"
        help={props.showHelp && cuzkMonitorRcFormHelp.frequency}
      >
        <CheckFrequencySelectInput initialValue={-1} canPause canPreserve />
      </Form.Item>

      <Form.Item
        name="labelEditType"
        label="Štítky"
        help={props.showHelp && cuzkMonitorRcFormHelp.labelEditType}
      >
        <LabelEditTypeSelectInput />
      </Form.Item>

      <Form.Item
        name="label"
        label="Vybrané štítky"
        hidden={
          labelEditType === LabelBulkEditOptions.Inherit ||
          labelEditType === LabelBulkEditOptions.RemoveAll
        }
      >
        <LabelSelectInput />
      </Form.Item>

      <Divider />
    </Form>
  )
})
