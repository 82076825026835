import React, { FunctionComponent } from 'react'

import { DrmJednotkaResponse } from '../../../common/drmTypes'
import { LvApiResponse } from '../../../common/lvTypes'
import { PriceEstimateLayoutAreaTable } from '../PriceEstimateLayoutAreaTable/PriceEstimateLayoutAreaTable'
import { PriceEstimateLayoutBuildingTable } from '../PriceEstimateLayoutBuildingTable/PriceEstimateLayoutBuildingTable'
import { PriceEstimateLayoutUnitTable } from '../PriceEstimateLayoutUnitTable/PriceEstimateLayoutUnitTable'

export interface PriceEstimateLayoutLvPropertyProps {
  lvData?: LvApiResponse & DrmJednotkaResponse
  propertyId: string
}

export const PriceEstimateLayoutLvProperty: FunctionComponent<PriceEstimateLayoutLvPropertyProps> =
  props => {
    const nemovitosti = props.lvData?.nemovitosti
    if (
      (nemovitosti?.jednotky.length || 0) +
        (nemovitosti?.budovy.length || 0) +
        (nemovitosti?.parcely.length || 0) ===
      1
    )
      return (
        <div className="text-center text-gray-400">
          Na listu vlastnictví nejsou zápsány žádné další nemovitosti
        </div>
      )
    return (
      <div className="space-y-4">
        {!!nemovitosti?.jednotky.length && (
          <PriceEstimateLayoutUnitTable
            propertyId={props.propertyId}
            jednotky={nemovitosti.jednotky}
          />
        )}
        {!!nemovitosti?.budovy.length && (
          <PriceEstimateLayoutBuildingTable
            propertyId={props.propertyId}
            budovy={nemovitosti.budovy}
          />
        )}
        {!!nemovitosti?.parcely.length && (
          <PriceEstimateLayoutAreaTable
            propertyId={props.propertyId}
            parcely={nemovitosti?.parcely}
          />
        )}
      </div>
    )
  }
