import { Button } from 'antd'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

export interface TableEmptyTextProps {
  text?: string
  buttonText?: string
  buttonLink?: string
}

export const TableEmptyText: FunctionComponent<TableEmptyTextProps> = props => {
  return (
    <div className="my-8">
      <div className="mb-2 text-gray-400">{props.text}</div>
      {props.buttonLink && (
        <Link to={props.buttonLink}>
          <Button type="primary">{props.buttonText}</Button>
        </Link>
      )}
    </div>
  )
}
