import { useMemo } from 'react'

import {
  monitDispoziceMistnosti,
  monitDruhKonstrukce,
  monitDruhKonstrukceByt,
  monitDruhVlastnictvi,
  monitEnbTrida,
  monitPolohaDomu,
  monitReduceStav,
  monitTypObjektu,
  monitTypPozemku,
  monitUmisteniNemovitosti,
  monitZarizeno,
  object_flags_values,
  prislusenstvi_fl_values,
  reduced_flags_values,
} from '../../common/monitTypes'
import { safeJsonParsing } from '../../common/safeJsonParsing'
import { Maybe, Monit } from '../../graphql/generated'
import { getMonitFlags, getMonitValue } from './monitHelpers'

export function useMonitPdfData(queryData?: Maybe<Partial<Monit>>) {
  const data = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      safeJsonParsing(queryData?.requestData, null as any)?.monit_object,
    [queryData]
  )

  const isFlat = data?.typ_nemovitosti === 2
  const isArea = data?.typ_nemovitosti === 0

  const typPozemku = isArea
    ? getMonitValue(monitTypPozemku, data?.typ_pozemku)
    : undefined

  const druhVlastnictvi = getMonitValue(
    monitDruhVlastnictvi,
    data?.druh_vlastnictvi
  )
  const umisteniNemovitosti = getMonitValue(
    monitUmisteniNemovitosti,
    data?.umisteni_nemovitosti
  )
  const dispoziceMistnosti = getMonitValue(
    monitDispoziceMistnosti,
    data?.dispozice_mistnosti
  )

  const druhKonstrukce = isFlat
    ? getMonitValue(monitDruhKonstrukceByt, data?.druh_konstrukce)
    : getMonitValue(monitDruhKonstrukce, data?.druh_konstrukce)

  const typObjektu = getMonitValue(monitTypObjektu, data?.typ_objektu)
  const zarizeno = getMonitValue(monitZarizeno, data?.zarizeno)
  const polohaDomu = getMonitValue(monitPolohaDomu, data?.poloha_domu)
  const enbTrida = getMonitValue(monitEnbTrida, data?.enb_trida)
  const reduceStav = getMonitValue(monitReduceStav, data?.reduced_stav)

  const reducedFlags = getMonitFlags(data?.reduced_flags, reduced_flags_values)
  const objectFlags = getMonitFlags(data?.object_flags, object_flags_values)
  const prislusenstviFl = getMonitFlags(
    data?.prislusenstvi_fl,
    prislusenstvi_fl_values
  )

  const podlaziUmisteni = isFlat
    ? data?.podlazi_umisteni === 1
      ? 'Ano'
      : 'Ne'
    : undefined

  return useMemo(() => {
    return {
      typ_nemovitosti: data?.typ_nemovitosti,
      nadzemni_podlazi: data?.nadzemni_podlazi,
      pl_celkova: data?.pl_celkova,
      pl_pozemku: data?.pl_pozemku,
      pocet_bytu: data?.pocet_bytu,
      pl_uzitna: data?.pl_uzitna,
      pl_balkonu: data?.pl_balkonu,
      datum: data?.datum,
      note: data?.note,
      typObjektu,
      typPozemku,
      druhVlastnictvi,
      zarizeno,
      polohaDomu,
      podlaziUmisteni,
      umisteniNemovitosti,
      enbTrida,
      druhKonstrukce,
      reduceStav,
      dispoziceMistnosti,
      prislusenstviFl,
      reducedFlags,
      objectFlags,
      title: queryData?.title,
      lat: queryData?.lat,
      lon: queryData?.lon,
      polygon: queryData?.polygon,
    }
  }, [
    data?.typ_nemovitosti,
    data?.nadzemni_podlazi,
    data?.pl_celkova,
    data?.pl_pozemku,
    data?.pocet_bytu,
    data?.pl_uzitna,
    data?.pl_balkonu,
    data?.datum,
    data?.note,
    typObjektu,
    typPozemku,
    druhVlastnictvi,
    zarizeno,
    polohaDomu,
    podlaziUmisteni,
    umisteniNemovitosti,
    enbTrida,
    druhKonstrukce,
    reduceStav,
    dispoziceMistnosti,
    prislusenstviFl,
    reducedFlags,
    objectFlags,
    queryData?.title,
    queryData?.lat,
    queryData?.lon,
    queryData?.polygon,
  ])
}
