import './mapbox-gl-geocoder.css'

import MapboxGeocoder, { GeocoderOptions } from '@mapbox/mapbox-gl-geocoder'
import { FunctionComponent } from 'react'
import { ControlPosition, MarkerProps, useControl } from 'react-map-gl'

import { MAPBOX_ACCESS_TOKEN } from '../../../../ini.json'

type GeocoderControlProps = Omit<
  GeocoderOptions,
  'accessToken' | 'mapboxgl' | 'marker'
> & {
  marker?: boolean | Omit<MarkerProps, 'longitude' | 'latitude'>

  position: ControlPosition

  onLoading?: (e: object) => void
  onResults?: (e: object) => void
  onResult?: (e: object) => void
  onError?: (e: object) => void
}

/* eslint-disable complexity,max-statements */
export const GeocoderControl: FunctionComponent<GeocoderControlProps> =
  props => {
    // @ts-expect-error Copy pasted code dunno
    const geocoder = useControl<MapboxGeocoder>(
      () => {
        const ctrl = new MapboxGeocoder({
          ...props,
          marker: false,
          accessToken: MAPBOX_ACCESS_TOKEN,
        })
        // @ts-expect-error Copy pasted code dunno
        ctrl.on('loading', props.onLoading)
        // @ts-expect-error Copy pasted code dunno
        ctrl.on('results', props.onResults)
        // @ts-expect-error Copy pasted code dunno
        ctrl.on('result', props.onResult)
        // @ts-expect-error Copy pasted code dunno
        ctrl.on('error', props.onError)
        return ctrl
      },
      {
        position: props.position,
      }
    )

    // @ts-expect-error Copy pasted code dunno
    if (geocoder._map) {
      if (
        geocoder.getProximity() !== props.proximity &&
        props.proximity !== undefined
      ) {
        geocoder.setProximity(props.proximity)
      }
      if (
        geocoder.getRenderFunction() !== props.render &&
        props.render !== undefined
      ) {
        geocoder.setRenderFunction(props.render)
      }
      if (
        geocoder.getLanguage() !== props.language &&
        props.language !== undefined
      ) {
        geocoder.setLanguage(props.language)
      }
      if (geocoder.getZoom() !== props.zoom && props.zoom !== undefined) {
        geocoder.setZoom(props.zoom)
      }
      if (geocoder.getFlyTo() !== props.flyTo && props.flyTo !== undefined) {
        geocoder.setFlyTo(props.flyTo)
      }
      if (
        geocoder.getPlaceholder() !== props.placeholder &&
        props.placeholder !== undefined
      ) {
        geocoder.setPlaceholder(props.placeholder)
      }
      if (
        geocoder.getCountries() !== props.countries &&
        props.countries !== undefined
      ) {
        geocoder.setCountries(props.countries)
      }
      if (geocoder.getTypes() !== props.types && props.types !== undefined) {
        geocoder.setTypes(props.types)
      }
      if (
        geocoder.getMinLength() !== props.minLength &&
        props.minLength !== undefined
      ) {
        geocoder.setMinLength(props.minLength)
      }
      if (geocoder.getLimit() !== props.limit && props.limit !== undefined) {
        geocoder.setLimit(props.limit)
      }
      if (geocoder.getFilter() !== props.filter && props.filter !== undefined) {
        geocoder.setFilter(props.filter)
      }
      if (geocoder.getOrigin() !== props.origin && props.origin !== undefined) {
        geocoder.setOrigin(props.origin)
      }
    }
    return null
  }

/* eslint-disable */
const noop = () => {}

GeocoderControl.defaultProps = {
  marker: true,
  onLoading: noop,
  onResults: noop,
  onResult: noop,
  onError: noop,
}
