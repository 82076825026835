import { Button, message, Popconfirm } from 'antd'
import { Maybe } from 'graphql/jsutils/Maybe'
import React, { FunctionComponent, useCallback } from 'react'

import { useDeleteLabelMutation } from '../../../graphql/generated'
import { EditLabelButton } from '../../components/EditLabelButton/EditLabelButton'
import { LabelTableItem } from '../../components/LabelTable/LabelTable'

interface LabelDetailPageExtraProps {
  label: Maybe<{
    id?: string | null
    name?: string | null
    colorKey?: string | null
    iconKey?: string | null
    description?: string | null
  }>
  refetch: () => void
}
const style = { width: '230px' }

export const LabelDetailPageExtra: FunctionComponent<LabelDetailPageExtraProps> =
  ({ label, refetch }) => {
    const [, deleteLabelById] = useDeleteLabelMutation()

    const handleDeleteLabel = useCallback(() => {
      deleteLabelById({ id: label?.id || '' })
        .then(() => {
          message.success('Štítek byl smazán.')
        })
        .catch(() => {
          message.error('Štítek se nepodařilo smazat.')
        })
    }, [deleteLabelById, label?.id])

    if (!label?.id) return null

    return (
      <div className="flex space-x-2">
        <EditLabelButton
          initialLabel={label as LabelTableItem}
          refetch={refetch}
        />
        <Popconfirm
          title={`Opravdu chcete smazat štítek "${label?.name}"?`}
          onConfirm={handleDeleteLabel}
          okText="Smazat štítek"
          cancelText="Zrušit"
          overlayInnerStyle={style}
        >
          <Button ghost danger className="w-full">
            Smazat
          </Button>
        </Popconfirm>
      </div>
    )
  }
