import { Spin } from 'antd'
import React, { FunctionComponent } from 'react'

import { useAppSelector } from '../../../redux/hooks'
import { KuWorkplaceTooltip } from '../KuWorkplaceTooltip/KuWorkplaceTooltip'
import { PriceEstimateLayoutLvDataRow as Item } from '../PriceEstimateLayoutLvDataRow/PriceEstimateLayoutLvDataRow'
import { PropertyDetailFetchError } from '../PropertyDetailFetchError/PropertyDetailFetchError'
import { PropertyDetailLvButton } from '../PropertyDetailLvButton/PropertyDetailLvButton'
import { PropertyDetailSidebarContainer } from '../PropertyDetailSidebarContainer/PropertyDetailSidebarContainer'

export interface PropertyDetailSidebarLvProps {
  isDrawer?: boolean
}

export const PropertyDetailSidebarLv: FunctionComponent<PropertyDetailSidebarLvProps> =
  ({ isDrawer }) => {
    const {
      data: drmDetail,
      error,
      fetching,
    } = useAppSelector(state => state.property.drm)

    const crmDrmData = useAppSelector(state => state.property.crm.data)

    if (error)
      return (
        <PropertyDetailFetchError
          description="Je nám líto, ale nepodařilo se dohledat detailní informace o listu vlastnictví pro tuto nemovitost."
          message="Informace o LV nenalezeny"
        />
      )

    if (crmDrmData === false) return null

    return (
      <PropertyDetailSidebarContainer isDrawer={isDrawer}>
        <Spin spinning={fetching}>
          <div className="flex flex-col space-y-2 animate-fadeIn">
            <Item title="List vlastnictví">
              <div className="flex justify-between">
                {drmDetail?.lv}
                <PropertyDetailLvButton linkStyle />
              </div>
            </Item>

            <Item title="Katastralní území">
              {drmDetail?.katastralniUzemi?.nazev} (
              {drmDetail?.katastralniUzemi?.kod})
            </Item>

            <Item title="Katastralní pracoviště">
              <KuWorkplaceTooltip
                kod={drmDetail?.katastralniPracoviste?.kod.toString()}
              >
                {drmDetail?.katastralniPracoviste?.nazev}
              </KuWorkplaceTooltip>
            </Item>
          </div>
        </Spin>
      </PropertyDetailSidebarContainer>
    )
  }
