import { Select } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import {
  AuctionMonitoringFilterOnSubmit,
  AuctionMonitoringFilterState,
} from '../../../common/auctionMonitoringTypes'
import { AUCTION_STATES } from '../../../common/auctionScrapeTypes'
import {
  auctionMonitoringFilterLabels,
  auctionStateOptions,
} from '../../utils/auctionMonitoringHelpers'
import { PropertyMonitoringFilterItem } from '../PropertyMonitoringFilterItem/PropertyMonitoringFilterItem'

export interface AuctionMonitoringFilterSelectProps {
  value: AUCTION_STATES[]
  filterKey: keyof AuctionMonitoringFilterState
  handleChange: AuctionMonitoringFilterOnSubmit
  className?: string
}

export const AuctionMonitoringFilterSelect: FunctionComponent<AuctionMonitoringFilterSelectProps> =
  props => {
    const handleOnChange = useCallback(
      (value: AUCTION_STATES[]) =>
        props.handleChange({ [props.filterKey]: value }),
      [props]
    )

    return (
      <PropertyMonitoringFilterItem
        className="min-w-[230px]"
        label={auctionMonitoringFilterLabels.state}
      >
        <Select
          mode="multiple"
          value={props.value}
          options={auctionStateOptions}
          onChange={handleOnChange}
          placeholder="Vyberte stav dražby"
          status={!props.value?.length ? 'error' : undefined}
        />
      </PropertyMonitoringFilterItem>
    )
  }
