export const getRcValidateCode = (rc: string) => {
  let validatedCode = 30
  if (!/^\d+$/.test(rc)) {
    validatedCode = 1
  } else if (
    !(
      rc.length === 10 ||
      (rc.length === 9 && parseInt(rc.slice(0, 2), 10) < 54)
    )
  ) {
    validatedCode = 2
  } else if (
    parseInt(rc.slice(4, 6), 10) > 31 ||
    parseInt(rc.slice(4, 6), 10) === 0
  ) {
    validatedCode = 4
  } else if (
    rc[2] === '4' ||
    rc[2] === '9' ||
    parseInt(rc.slice(2, 4), 10) === 50
  ) {
    validatedCode = 3
  } else if (rc[2] === '2' || rc[2] === '3' || rc[2] === '7' || rc[2] === '8') {
    validatedCode = 11
  } else if (rc.length === 10 && parseInt(rc, 10) % 11 !== 0) {
    validatedCode = 10
  }
  return validatedCode
}
