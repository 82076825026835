import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, ButtonProps, Modal } from 'antd'
import React, { FunctionComponent, useMemo, useRef, useState } from 'react'

import { CeeTableItem } from '../../../common/ceeTypes'
import { useToggleState } from '../../utils/useToggleState'
import { CeeMonitorBulkEditForm } from '../CeeMonitorBulkEditForm/CeeMonitorBulkEditForm'

export interface CeeMonitorBulkEditProps {
  items: CeeTableItem[]
  monitoring?: boolean
  refetch: () => void
  buttonText?: string
  buttonProps?: ButtonProps
  initialValues?: { note?: string; checkFrequency?: number }
}

export const CeeMonitorBulkEdit: FunctionComponent<CeeMonitorBulkEditProps> =
  props => {
    const [loading, setLoading] = useState(false)
    const [showHelp, toggleShowHelp] = useToggleState()
    const [open, toggleModal] = useToggleState()
    const approvalAttachmentTableRef = useRef<{ submit: () => void }>(null)

    const modalTitle = useMemo(
      () =>
        props.items.length > 1
          ? 'Hromadná editace ' + props.items.length + ' záznamů'
          : 'Editace záznamu',
      [props.items.length]
    )

    const footer = useMemo(
      () => (
        <div className="flex justify-between">
          <Button
            icon={<InfoCircleOutlined />}
            type="link"
            onClick={toggleShowHelp}
          >
            {showHelp ? 'Skrýt nápovědu' : 'Zobrazit nápovědu'}
          </Button>
          <div>
            <Button onClick={toggleModal}>Zrušit</Button>
            <Button
              type="primary"
              onClick={() => approvalAttachmentTableRef.current?.submit()}
              loading={loading}
            >
              Uložit
            </Button>
          </div>
        </div>
      ),
      [toggleShowHelp, showHelp, toggleModal, loading]
    )

    return (
      <>
        <Button type="link" onClick={toggleModal} {...props.buttonProps}>
          {props.buttonText ? props.buttonText : 'Hromadná editace'}
        </Button>
        <Modal
          title={modalTitle}
          open={open}
          onCancel={toggleModal}
          footer={footer}
        >
          <div className="mt-4">
            <CeeMonitorBulkEditForm
              ref={approvalAttachmentTableRef}
              hideModal={toggleModal}
              refetch={props.refetch}
              selectedItems={props.items}
              setLoading={setLoading}
              showHelp={showHelp}
              monitoring={props.monitoring}
              initialValues={props.initialValues}
            />
          </div>
        </Modal>
      </>
    )
  }
