import { Tooltip } from 'antd'
import { differenceInDays, parseISO } from 'date-fns'
import React, { FunctionComponent, memo, useMemo } from 'react'

import { useAppSelector } from '../../../redux/hooks'
import { CadastralUpdateButton } from '../CadastralUpdateButton/CadastralUpdateButton'
import { Day } from '../DateFormat/DateFormat'
import { PriceEstimateLayoutLvDataRow as Item } from '../PriceEstimateLayoutLvDataRow/PriceEstimateLayoutLvDataRow'
import { PropertyDetailSidebarContainer } from '../PropertyDetailSidebarContainer/PropertyDetailSidebarContainer'

export interface PropertyDetailSidebarDataValidityProps {
  isDrawer?: boolean
}

export const PropertyDetailSidebarDataValidity: FunctionComponent<PropertyDetailSidebarDataValidityProps> =
  memo(({ isDrawer }) => {
    const drmDetail = useAppSelector(state => state.property.drm.data)

    const updateDate = useMemo(() => {
      if (!drmDetail?.utcAktualizace) return null
      const date = parseISO(drmDetail.utcAktualizace)
      const difference = differenceInDays(new Date(), date)

      if (difference < 5)
        return (
          <div title="Datum platnosti katastrálních údajů dle ČÚZK">
            <Day>{drmDetail?.utcAktualizace}</Day>
          </div>
        )
      return (
        <Tooltip
          title={`Zobrazovaná data jsou ${difference} dní stará. Doporučujeme údaje aktualizovat.`}
        >
          <div>
            <Day className="text-amber-600">{drmDetail?.utcAktualizace}</Day>
          </div>
        </Tooltip>
      )
    }, [drmDetail])

    if (!drmDetail?.utcAktualizace) return null

    return (
      <PropertyDetailSidebarContainer isDrawer={isDrawer}>
        <div className="flex flex-col space-y-2 animate-fadeIn">
          <Item title="Údaje k datu">
            <div className="flex justify-between">
              {updateDate}
              <CadastralUpdateButton entityType="property" />
            </div>
          </Item>

          <Item title="Aktualizoval">{drmDetail?.aktualizoval}</Item>
        </div>
      </PropertyDetailSidebarContainer>
    )
  })
