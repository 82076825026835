import { Proceeding } from '../../common/proceedingTypes'

export const filterOption = (
  input: string | number,
  option?: { value: string | number; label?: string }
) =>
  option?.label?.toLowerCase().includes(input.toString().toLowerCase()) ||
  option?.value.toString().includes(input.toString()) ||
  false

const currentYear = new Date().getFullYear()

const validateYear = (_: unknown, value: string) => {
  if (value && value.length === 4) {
    const yearNum = parseInt(value)
    if (isNaN(yearNum) || yearNum < 1989 || yearNum > currentYear) {
      return Promise.reject(
        new Error(`Rok musí být v rozmezí 1989 až ${currentYear}`)
      )
    }
  } else if (value && value.length > 0) {
    return Promise.reject(new Error('Rok musí mít 4 číslice'))
  }
  return Promise.resolve()
}

const isTooOld = (_: unknown, value: string) => {
  if (value && value.length === 4) {
    const yearNum = parseInt(value)
    if (isNaN(yearNum) || yearNum < 2012) {
      return Promise.reject(
        new Error(
          'Pro řízení starší než z roku 2011 nemusí být údaje zcela kompletní.'
        )
      )
    }
  }
  return Promise.resolve()
}

const validateType = (_: unknown, value: string) => {
  if (value && value !== 'V' && value !== 'Z') {
    return Promise.reject(
      new Error(
        'K dispozici jsou náhledy pouze katastrálních řízení typu Vklad či Zápis.'
      )
    )
  }
  return Promise.resolve()
}

export const yearOptions = Array.from({ length: 31 }, (_, i) => ({
  value: (currentYear - i).toString(),
}))

export const typeOptions = [
  { value: 'V', label: 'V - Vklad' },
  { value: 'Z', label: 'Z - Zápis' },
]

export const typeRules = [
  { required: true, message: 'Vyberte prosím typ řízení' },
  { validator: validateType },
]
export const serialNumberRules = [
  { required: true, message: 'Zadejte prosím pořadové číslo' },
  { message: 'Musí být číslo', pattern: /^[0-9]+$/ },
]
export const yearRules = [
  { required: true, message: 'Vyberte prosím rok' },
  { validator: validateYear },
  {
    validator: isTooOld,
    warningOnly: true,
  },
]
export const officeCodeRules = [
  {
    required: true,
    message: 'Vyberte prosím katastrální pracoviště',
  },
  {
    message: 'Kód pracoviště je vždy 2ciferné, nebo 3ciferné číslo',
    pattern: /^[0-9]{2,3}$/,
  },
]
export const codeRules = [
  {
    pattern: /^\p{L}{1,3}-[0-9]{1,8}\/[0-9]{4}-[0-9]{2,3}$/u,
    message: 'Neplatný formát kódu řízení (např. V-12345678/2024-101)',
    warningOnly: true,
  },
]

export const handleUnderscoreClick = ({
  currentTarget: input,
}: React.MouseEvent<HTMLInputElement>) => {
  const pos = input.selectionStart
  const { value } = input

  if (!pos || (!value[pos]?.includes('_') && !value[pos - 1]?.includes('_')))
    return

  const findUnderscores = (start: number, step: -1 | 1) => {
    let pos = start
    while (pos >= 0 && pos < value.length && value[pos] === '_') {
      pos += step
    }
    return pos - step
  }

  const start = findUnderscores(value[pos] === '_' ? pos : pos - 1, -1)
  const end = findUnderscores(start, 1) + 1

  if (start !== end) {
    input.setSelectionRange(start, end)
  }
}

export const parseProceedingUrlQuery = (
  proceeding: string
): Proceeding | null => {
  const match = proceeding.split('-')

  if (!match) return null

  const [typ, cislo, rok, pracovisteKod] = match

  return {
    rok: parseInt(rok, 10),
    cislo: parseInt(cislo, 10),
    typ,
    pracovisteKod: parseInt(pracovisteKod, 10),
  }
}
