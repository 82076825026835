import { useCallback } from 'react'

import { PropertyDataTypes } from '../../redux/fetchAction'
import { fetchRisy as fetchRisyData } from './fetchRisy'
import { useDispatchFetch } from './useDispatchFetch'

export const useFetchPropertyRisy = () => {
  const { dispatchError, dispatchFetching, dispatchSuccess } =
    useDispatchFetch()

  const fetchRisy = useCallback(
    async (kodObce?: string | number, nazevObce?: string) => {
      if (!nazevObce || !kodObce) return dispatchError(PropertyDataTypes.RISY)
      dispatchFetching(PropertyDataTypes.RISY)

      const risyResponse = await fetchRisyData(kodObce, nazevObce)
      if (!risyResponse) return dispatchError(PropertyDataTypes.RISY)

      dispatchSuccess(PropertyDataTypes.RISY, risyResponse)
    },
    [dispatchError, dispatchFetching, dispatchSuccess]
  )

  return fetchRisy
}
