import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table/interface'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import React, { FunctionComponent, useMemo } from 'react'

import { useSubDataBoxDetailQuery } from '../../../graphql/generated'
import { defaultPagination } from '../../utils/layoutConst'
import { Fade } from '../Fade/Fade'

export interface DataBoxDetailTable {
  id: string
  databoxid: string
  propertyType: number
  fullname: string
  type: string
}
export interface DataBoxDetailTableProps {
  databoxid?: string
}

export const DataBoxDetailTable: FunctionComponent<DataBoxDetailTableProps> =
  props => {
    const tableId = 'subDataBox-table'

    const [querySubDataBoxResult] = useSubDataBoxDetailQuery({
      variables: { masterid: props.databoxid || '' },
      pause: !props.databoxid,
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataSource = querySubDataBoxResult.data?.allDataboxes?.nodes as any

    const columns = useMemo<ColumnsType<DataBoxDetailTable>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Podřazené datové schránky',
          dataIndex: 'databoxid',
          key: 'databoxid',
        },

        {
          title: 'Název subjektu',
          dataIndex: 'fullname',
          key: 'fullname',
          sorter: {
            compare: (a, b) => a.fullname.localeCompare(b.fullname),
          },
        },

        {
          title: 'Typ',
          dataIndex: 'type',
          key: 'type',
          sorter: {
            compare: (a, b) => a.type.localeCompare(b.type),
          },
        },
      ],
      []
    )

    return (
      <Fade
        show={
          !!props.databoxid &&
          !!querySubDataBoxResult.data?.allDataboxes?.nodes.length
        }
        className="mt-8 mb-4"
      >
        <Table
          id={tableId}
          rowKey="id"
          size="small"
          rowClassName="animate-fadeIn"
          pagination={defaultPagination}
          dataSource={dataSource}
          columns={columns}
          loading={querySubDataBoxResult.fetching}
        />
      </Fade>
    )
  }
