/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from 'antd'
import dayjs from 'dayjs'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { DrmJednotkaResponse, PROPERTY_TYPES } from '../../../common/drmTypes'
import { LvApiResponse } from '../../../common/lvTypes'
import {
  RuianToMonitDruhKonstrukce,
  RuianToMonitFlagsGully,
  RuianToMonitFlagsHeating,
  RuianToMonitTypObjektu,
} from '../../../common/monitTypes'
import { RuianBuildingResponse } from '../../../common/ruianTypes'
import { safeJsonParsing } from '../../../common/safeJsonParsing'
import { useAppSelector } from '../../../redux/hooks'
import { buildingPriceEstimateTitlePrefix as prefix } from '../../utils/layoutConst'
import { getMonitValue } from '../../utils/monitHelpers'
import {
  requestPath,
  requestPropertyDrmPath,
  requestPropertyPolygonPath,
  requestPropertyRuianPath,
} from '../../utils/paths'
import { formatPolygon } from '../../utils/polygonHelpers'
import { PriceEstimateFormValues } from '../PriceEstimateForm/PriceEstimateForm'
import {
  FETCH_ERROR_TYPES,
  PriceEstimateLayout,
} from '../PriceEstimateLayout/PriceEstimateLayout'

export interface PriceEstimateBuildingProps {
  setTitle: (title: string) => void
  propertyId: string
}

export interface ExtendedBuildingRuian extends PriceEstimateFormValues {
  ruian?: RuianBuildingResponse
  totalLandArea?: string
}

export const PriceEstimateBuilding: FunctionComponent<PriceEstimateBuildingProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const [ruianData, setRuianData] = useState<ExtendedBuildingRuian>()
    const [lvData, setLvData] = useState<LvApiResponse & DrmJednotkaResponse>()
    const [fetchError, setFetchError] = useState<FETCH_ERROR_TYPES>()

    const fetchRuianBuilding = useCallback(async () => {
      const response = await fetch(
        requestPropertyRuianPath(
          PROPERTY_TYPES.BUILDING,
          token,
          props.propertyId
        )
      )
      return response.json()
    }, [props.propertyId, token])

    const fetchPolygon = useCallback(async () => {
      const response = await fetch(
        requestPropertyPolygonPath(
          PROPERTY_TYPES.BUILDING,
          token,
          props.propertyId
        )
      )
      return response.json()
    }, [props.propertyId, token])

    const fetchDrm = useCallback(async () => {
      const response = await fetch(
        requestPropertyDrmPath(PROPERTY_TYPES.BUILDING, token, props.propertyId)
      )
      if (!response.ok) {
        message.error('Došlo k chybě při zpracování požadavku.')
        setFetchError(FETCH_ERROR_TYPES.LV)
        return {}
      }
      return response.json()
    }, [props.propertyId, token])

    const fetchLv = useCallback(
      async (katastralniUzemiKod: string, lv: string) => {
        const lvResponse = await fetch(
          `${requestPath()}/lv/${tenantId}/${token}/${katastralniUzemiKod}/${lv}`
        )
        const lvData: any = await lvResponse.json()
        const lvGeometryResponse = await fetch(
          `${requestPath()}/lvGeometry/${tenantId}/${token}/${katastralniUzemiKod}/${lv}`
        )
        const lvGeometryTextResponse = await lvGeometryResponse.text()
        const lvGeometryData = safeJsonParsing<any | undefined>(
          lvGeometryTextResponse,
          undefined
        )

        return { lvData, lvGeometryData }
      },
      [tenantId, token]
    )

    const fetchData = useCallback(async () => {
      try {
        const [data, polygonResponse, drmResponse] = await Promise.all([
          fetchRuianBuilding(),
          fetchPolygon(),
          fetchDrm(),
        ])

        const polygon = formatPolygon(polygonResponse.polygons?.[0]?.exterior)

        let totalLandArea = 0

        if (drmResponse.lv && drmResponse.katastralniUzemi.kod) {
          const { lvData, lvGeometryData } = await fetchLv(
            drmResponse.katastralniUzemi.kod,
            drmResponse.lv
          )

          if (lvData && lvData.nemovitosti && lvGeometryData) {
            Object.keys(lvData.nemovitosti).forEach(key => {
              lvData.nemovitosti[key] = lvData.nemovitosti[key].map(
                (nemovitost: any) => {
                  const geometry = lvGeometryData[key].find(
                    (item: any) => item.id === nemovitost.id
                  )
                  return geometry ? { ...nemovitost, ...geometry } : nemovitost
                }
              )
            })

            totalLandArea = lvGeometryData.parcely.reduce(
              (acc: any, parcela: any) => acc + Number(parcela.vymera),
              0
            )

            setLvData({ ...lvData, ...drmResponse })
          }
        }

        if (data && data.length > 0) {
          const firstBuilding = data[0]
          const buildingDetails = {
            datum: dayjs(),
            kod_obce: firstBuilding.obec?.kod,
            typ_nemovitosti: 1,
            typ_objektu: getMonitValue(
              RuianToMonitTypObjektu,
              firstBuilding.zpusobVyuziti?.kod
            ),
            druh_konstrukce: getMonitValue(
              RuianToMonitDruhKonstrukce,
              firstBuilding.druhKonstrukce?.kod
            ),
            lat: firstBuilding.definicniBod?.lat,
            lon: firstBuilding.definicniBod?.long,
            polygon: polygon,
            title: `${prefix}${firstBuilding.nazev}`,
            nadzemni_podlazi: firstBuilding.pocetNadzemnichPodlazi?.do,
            pocet_bytu: firstBuilding.pocetBytu,
            object_flags_gully: getMonitValue(
              RuianToMonitFlagsGully,
              firstBuilding.odpady?.kod
            ),
            object_flags_heating: getMonitValue(
              RuianToMonitFlagsHeating,
              firstBuilding.zpusobVytapeni?.kod
            ),
            ruian: firstBuilding,
            pl_pozemku: totalLandArea,
            pl_uzitna: firstBuilding.podlahovaPlocha,
          }
          setRuianData(buildingDetails)
        }

        props.setTitle(` pro ${data[0].nazev}`)
      } catch (error) {
        message.error('Došlo k chybě při zpracování požadavku.')
      }
    }, [fetchDrm, fetchLv, fetchPolygon, fetchRuianBuilding, props])

    useEffect(() => {
      if (!ruianData) {
        fetchData()
      }
    }, [ruianData, fetchData])

    return (
      <PriceEstimateLayout
        ruianData={ruianData}
        lvData={lvData}
        propertyId={props.propertyId}
        propertyType={PROPERTY_TYPES.BUILDING}
        fetchError={fetchError}
      />
    )
  }
