import { Tabs, Typography } from 'antd'
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  MonitoringInputType,
  MonitoringTabType,
} from '../../common/cuzkMonitorTypes'
import { CuzkMonitorRcCreateTour } from '../components/CuzkMonitorRcCreateTour/CuzkMonitorRcCreateTour'
import { IcoInput } from '../components/IcoInput/IcoInput'
import { NameBirthdateInput } from '../components/NameBirthdateInput/NameBirthdateInput'
import { RcInput } from '../components/RcInput/RcInput'

const titleStyle = { marginBottom: 0 }
const tabBarStyle = { marginBottom: 0, marginTop: 12 }

export const CuzkMonitorRcCreate: FunctionComponent = () => {
  const [currentTab, setCurrentTab] = useState(MonitoringTabType.Rc)

  const elementRef = {
    rcInput: useRef(null),
    rcSubmit: useRef(null),
    icoInput: useRef(null),
    icoSubmit: useRef(null),
    icoTab: useRef(null),
    nameTab: useRef(null),
  }

  const handleTabChange = useCallback((key: unknown) => {
    setCurrentTab(key as MonitoringTabType)
  }, [])

  const tabItems = useMemo(
    () => [
      {
        key: MonitoringTabType.Rc,
        label: 'Podle rodného čísla',
        children: (
          <div className="bg-white p-6">
            <RcInput
              type={MonitoringInputType.CuzkRc}
              inputRef={elementRef.rcInput}
              buttonRef={elementRef.rcSubmit}
            />
          </div>
        ),
      },
      {
        key: MonitoringTabType.Ico,
        label: <div ref={elementRef.icoTab}>Podle IČO</div>,
        children: (
          <div className="bg-white p-6">
            <IcoInput
              inputRef={elementRef.icoInput}
              buttonRef={elementRef.icoSubmit}
              type={MonitoringInputType.CuzkIco}
            />
          </div>
        ),
      },
      {
        key: MonitoringTabType.Name,
        label: <span ref={elementRef.nameTab}>Podle jména a narození</span>,
        children: (
          <div className="bg-white p-6">
            <NameBirthdateInput
              inputRef={elementRef.icoInput}
              buttonRef={elementRef.icoSubmit}
              type={MonitoringInputType.CuzkName}
            />
          </div>
        ),
      },
    ],
    [
      elementRef.icoInput,
      elementRef.icoSubmit,
      elementRef.icoTab,
      elementRef.nameTab,
      elementRef.rcInput,
      elementRef.rcSubmit,
    ]
  )

  return (
    <>
      <Typography.Title style={titleStyle} level={3}>
        Vyhledávání subjektů v evidenci ČÚZK
      </Typography.Title>
      <Tabs
        defaultActiveKey="tableView"
        type="card"
        tabBarExtraContent={
          <CuzkMonitorRcCreateTour
            currentTab={currentTab}
            elementRef={elementRef}
          />
        }
        tabBarStyle={tabBarStyle}
        tabBarGutter={6}
        items={tabItems}
        onChange={handleTabChange}
      />
    </>
  )
}

export default CuzkMonitorRcCreate
