import { Breakpoint, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { Budova } from '../../../common/lvTypes'
import {
  priceEstimateSummaryStyle,
  priceEstimateTableProps,
} from '../../utils/layoutConst'
import { propertyDetailPath } from '../../utils/paths'
import { Money } from '../Money/Money'

export interface PriceEstimateLayoutBuildingTableProps {
  budovy: Budova[]
  propertyId: string
}

export const PriceEstimateLayoutBuildingTable: FunctionComponent<PriceEstimateLayoutBuildingTableProps> =
  props => {
    const buildingColumns: ColumnsType<Budova> = useMemo(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Budova',
          dataIndex: 'zpusobVyuziti',
          key: 'zpusobVyuziti',
          className: 'w-4/12',
          sorter: (a, b) =>
            (a?.zpusobVyuziti || '').localeCompare(b?.zpusobVyuziti || ''),
        },
        {
          title: 'Číslo budovy',
          dataIndex: 'cislaDomovni',
          key: 'cislaDomovni',
          className: 'w-3/12',
          render: (cislaDomovni, record) => (
            <Link
              target="_blank"
              title="Zobrazit detail budovy v novém okně"
              to={propertyDetailPath(PROPERTY_TYPES.BUILDING, record?.id)}
            >
              {cislaDomovni || 'Bez č.p.'}
            </Link>
          ),
        },
        {
          title: 'Zastavená plocha m²',
          dataIndex: 'zastavenaPlocha',
          key: 'zastavenaPlocha',
          className: 'w-3/12 !text-right',
        },
        {
          dataIndex: 'note',
          key: 'note',
          className: 'w-2/12 text-right',
          render: (_, record) =>
            record.id === props.propertyId && 'Tato budova',
        },
      ],
      [props.propertyId]
    )

    const summary = useCallback(
      () =>
        props.budovy?.length > 1 && (
          <Table.Summary.Row key="buildingSummary">
            <Table.Summary.Cell
              className={priceEstimateSummaryStyle}
              index={1}
              colSpan={2}
            />
            <Table.Summary.Cell className={priceEstimateSummaryStyle} index={2}>
              Celkem:{' '}
              <Money
                decimalNumbers={0}
                amount={props.budovy?.reduce(
                  (a, b) => a + Number(b.zastavenaPlocha),
                  0
                )}
              />
            </Table.Summary.Cell>
            <Table.Summary.Cell
              className={priceEstimateSummaryStyle}
              index={3}
            />
          </Table.Summary.Row>
        ),
      [props.budovy]
    )

    const rowClassName = useCallback(
      (record: Budova, a: number) => {
        const color = record.id === props.propertyId ? 'text-gray-400' : ''
        const borderBottom = a === props.budovy.length - 1 ? 'border-b' : ''
        return `${color} ${borderBottom}`
      },
      [props.budovy.length, props.propertyId]
    )

    return (
      <Table
        dataSource={props?.budovy}
        columns={buildingColumns}
        rowClassName={rowClassName}
        rowKey="id"
        summary={summary}
        {...priceEstimateTableProps}
      />
    )
  }
