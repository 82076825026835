import { Spin } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import {
  ProceedingDetailResponse,
  Rizeni,
  Ucastnici,
} from '../../../common/proceedingTypes'
import { useAllCeeOfficeQuery } from '../../../graphql/generated'
import { CeeOfficeTooltip } from '../CeeOfficeTooltip/CeeOfficeTooltip'
import { PropertyDetailProceedingTableExpandedRowItem as Item } from '../PropertyDetailProceedingTableExpandedRowItem/PropertyDetailProceedingTableExpandedRowItem'

export interface ProceedingDetailParticipantsProps {
  data: ProceedingDetailResponse | null
  loading: boolean
  record?: Rizeni
}

export const ProceedingDetailParticipants: FunctionComponent<ProceedingDetailParticipantsProps> =
  props => {
    const [queryResult, fetchCeeOffice] = useAllCeeOfficeQuery({
      requestPolicy: 'cache-first',
      pause: true,
    })

    const ceeOffices = useMemo(
      () =>
        queryResult.data?.allCeeOffices?.nodes?.map(office => ({
          ...office,
          matchName: office?.name.split(',')[0],
        })) || [],
      [queryResult.data?.allCeeOffices?.nodes]
    )

    const checkCeeOffice = useCallback(
      (ucastnik: Ucastnici) => {
        if (!ucastnik?.typ?.includes('exek')) return false
        !ceeOffices.length && fetchCeeOffice()

        return (
          ceeOffices.find(
            office =>
              office.matchName &&
              ucastnik?.jmeno
                ?.replace(/\s+/g, '')
                .includes(office.matchName.replace(/\s+/g, ''))
          ) || false
        )
      },
      [ceeOffices, fetchCeeOffice]
    )

    const ucastnici = useMemo(() => {
      if (props.loading) {
        const itemsCount =
          (props.record?.ucastniciPZP?.length || 1) +
          (props.record?.ucastniciOVN?.length || 2)
        return Array.from({ length: itemsCount }, (_, index) => (
          <Item key={index} />
        ))
      }

      return (
        props.data?.ucastnici?.map((ucastnik, index) => {
          if (!ucastnik.jmeno) return '- Název neznámý -'
          const ceeOfficeMatch = checkCeeOffice(ucastnik)
          return (
            <Item
              key={index}
              label={
                ceeOfficeMatch ? (
                  <CeeOfficeTooltip
                    text={ucastnik.jmeno}
                    user={ceeOfficeMatch}
                    thumbnail
                  />
                ) : (
                  ucastnik.jmeno
                )
              }
            >
              {ucastnik.typ}
            </Item>
          )
        }) || null
      )
    }, [
      checkCeeOffice,
      props.data?.ucastnici,
      props.loading,
      props.record?.ucastniciOVN?.length,
      props.record?.ucastniciPZP?.length,
    ])

    return (
      <div className="lg:flex-1 flex-auto w-full">
        <div className="divide-y divide-gray-200">
          <Item label="Účastníci řízení" isTitle>
            Typ
          </Item>
          <Spin spinning={props.loading}>
            <div className="divide-y divide-gray-200">{ucastnici}</div>
          </Spin>
        </div>
      </div>
    )
  }
