import React, { FunctionComponent } from 'react'

import { POZEMKY } from '../../../common/cuzkLvTypes'
import { notEmpty, safeFind, safeMap } from '../../../common/pdfHelpers'
import { CuzkLvHtmlParcelaInlineFragment } from '../CuzkLvHtmlParcelaInlineFragment/CuzkLvHtmlParcelaInlineFragment'
import { CuzkLvHtmlPozemkyGrafModalButton } from '../CuzkLvHtmlPozemkyGrafModalButton/CuzkLvHtmlPozemkyGrafModalButton'

export interface CuzkLvHtmlSekcePozemkyFragmentProps {
  pozemky?: POZEMKY
  active?: boolean
}

export const CuzkLvHtmlSekcePozemkyFragment: FunctionComponent<CuzkLvHtmlSekcePozemkyFragmentProps> =
  props => {
    if (!props.pozemky || !notEmpty(props.pozemky.PARCELA)) {
      return null
    }
    return (
      <>
        <div className="row">
          <i>&nbsp;Pozemky</i>
        </div>
        <div className="row">
          <div className="col-xs-1">
            <i>Parcela</i>
          </div>
          <div className="col-xs-2 text-right">
            <i className="pr-30">
              {props.active ? (
                <CuzkLvHtmlPozemkyGrafModalButton pozemky={props.pozemky} />
              ) : (
                'Výměra[m2]'
              )}
            </i>
          </div>
          <div className="col-xs-3">
            <i>Druh pozemku</i>
          </div>
          <div className="col-xs-3">
            <i>Způsob využití</i>
          </div>
          <div className="col-xs-3">
            <i>Způsob ochrany</i>
          </div>
        </div>

        <hr className="mt-0 mb-0" />
        {safeMap(props.pozemky?.PARCELA, (PARCELA, index) => (
          <CuzkLvHtmlParcelaInlineFragment
            active={props.active}
            parcela={PARCELA}
            key={index}
          />
        ))}
        {safeFind(
          props.pozemky?.PARCELA,
          PARCELA => !!PARCELA?.PLOMBA_PARCELY
        ) && (
          <div className="row">
            <i>P = právní vztahy jsou dotčeny změnou</i>
          </div>
        )}
      </>
    )
  }
