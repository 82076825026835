import { Tabs } from 'antd'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { PROPERTY_TYPES } from '../../common/drmTypes'
import { PROPERTY_TAB_KEYS as KEY } from '../../common/propertyDetailTypes'
import { RuianBuildingResponse } from '../../common/ruianTypes'
import { useAppSelector } from '../../redux/hooks'
import { DualPageLayout } from '../components/DualPageLayout/DualPageLayout'
import { PropertyDetailCollapseButton as CollapseButton } from '../components/PropertyDetailCollapseButton/PropertyDetailCollapseButton'
import { PropertyDetailNote } from '../components/PropertyDetailNote/PropertyDetailNote'
import { PropertyDetailOwnership } from '../components/PropertyDetailOwnership/PropertyDetailOwnership'
import { PropertyDetailPageTitle } from '../components/PropertyDetailPageTitle/PropertyDetailPageTitle'
import { PropertyDetailPriceEstimate } from '../components/PropertyDetailPriceEstimate/PropertyDetailPriceEstimate'
import { PropertyDetailProceeding } from '../components/PropertyDetailProceeding/PropertyDetailProceeding'
import { PropertyDetailRisy } from '../components/PropertyDetailRisy/PropertyDetailRisy'
import { PropertyDetailSidebar } from '../components/PropertyDetailSidebar/PropertyDetailSidebar'
import { PropertyDetailSubjects } from '../components/PropertyDetailSubjects/PropertyDetailSubjects'
import { PropertyDetailTimeline } from '../components/PropertyDetailTimeline/PropertyDetailTimeline'
import { PropertyDetailWatchdog } from '../components/PropertyDetailWatchdog/PropertyDetailWatchdog'
import { TabLabel } from '../components/TabLabel/TabLabel'
import { TabLabelProceeding } from '../components/TabLabelProceeding/TabLabelProceeding'
import { TabLabelSubject } from '../components/TabLabelSubject/TabLabelSubject'
import { useFetchPropertyDetail } from '../utils/useFetchPropertyDetail'

const tabBarStyle = { marginBottom: 0 }

export const PropertyDetail: FunctionComponent = () => {
  const {
    params: { propertyType, id },
  } = useRouteMatch<{ propertyType: PROPERTY_TYPES; id: string; tab: KEY }>()
  useFetchPropertyDetail(id, propertyType)

  const [isOpen, setIsOpen] = useState(true)
  const [activeTabKey, setActiveTabKey] = useState(KEY.OWNERSHIP)

  const location = useLocation()
  const history = useHistory()

  const drmError = useAppSelector(state => state.property.drm.error)
  const ruianError = useAppSelector(state => state.property.ruian.error)
  const ruianFetching = useAppSelector(state => state.property.ruian.fetching)
  const noTimeline = !useAppSelector(state => state.property.timeline.data)
  const noProceeding = !useAppSelector(
    state => state.property.timeline.data?.rizeni?.length
  )
  const isWatched = !!useAppSelector(
    state => state.watchdog.property.data?.[id]
  )

  const notFound = drmError && ruianError
  const notBuilding = propertyType !== PROPERTY_TYPES.BUILDING

  const addressId = useAppSelector(
    state => state.property.ruian?.data as RuianBuildingResponse
  )?.adresniMista?.[0]?.kod

  const handleOnTabClick = useCallback(
    (key: string) => {
      history.push(`${location.pathname}?tab=${key}`)
    },
    [history, location.pathname]
  )

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const tabKey = queryParams.get('tab')
    if (tabKey) setActiveTabKey(tabKey as KEY)
  }, [location])

  const tabItems = useMemo(
    () => [
      {
        key: KEY.OWNERSHIP,
        label: <TabLabel title="Vlastnická práva" />,
        children: (
          <PropertyDetailOwnership propertyId={id} notFound={notFound} />
        ),
      },
      {
        key: KEY.TIMELINE,
        label: <TabLabel title="Časová osa" />,
        disabled: noTimeline,
        children: <PropertyDetailTimeline />,
      },
      {
        key: KEY.SUBJECT,
        label: <TabLabelSubject addressId={addressId} />,
        disabled: !addressId || notBuilding || noTimeline,
        children: <PropertyDetailSubjects addressId={addressId} />,
      },
      {
        key: KEY.PROCEEDING,
        label: <TabLabelProceeding />,
        disabled: noProceeding,
        children: <PropertyDetailProceeding />,
      },
      {
        key: KEY.PRICE_ESTIMATE,
        label: <TabLabel title="Cenový odhad" />,
        children: <PropertyDetailPriceEstimate propertyId={id} />,
      },
      {
        key: KEY.RISY,
        label: <TabLabel loading={ruianFetching} title="Detaily obce" />,
        children: <PropertyDetailRisy />,
      },
      {
        key: KEY.NOTE,
        label: <TabLabel loading={ruianFetching} title="Poznámky" />,
        children: <PropertyDetailNote propertyId={id} />,
      },
      ...(isWatched
        ? [
            {
              key: KEY.WATCHDOG,
              label: <TabLabel title="Hlídač" />,
              children: <PropertyDetailWatchdog propertyId={id} />,
            },
          ]
        : []),
    ],
    [
      id,
      notFound,
      noTimeline,
      addressId,
      notBuilding,
      noProceeding,
      ruianFetching,
      isWatched,
    ]
  )

  const tabBarExtraContent = useMemo(
    () => ({
      left: <CollapseButton isOpen={isOpen} setIsOpen={setIsOpen} />,
    }),
    [isOpen]
  )

  return (
    <DualPageLayout
      isNew
      isNewTitle="Detail nemovitosti 2.0"
      isContainer
      isOpen={isOpen}
      title={
        <PropertyDetailPageTitle propertyId={id} propertyType={propertyType} />
      }
      sidebarContent={
        <PropertyDetailSidebar propertyId={id} notFound={notFound} />
      }
    >
      <Tabs
        type="card"
        tabBarGutter={6}
        items={tabItems}
        activeKey={notFound ? KEY.OWNERSHIP : activeTabKey}
        tabBarStyle={tabBarStyle}
        onTabClick={handleOnTabClick}
        tabBarExtraContent={tabBarExtraContent}
      />
    </DualPageLayout>
  )
}

export default PropertyDetail
