import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { FunctionComponent, useMemo } from 'react'

import { AreaSearchApiDataItem } from '../../../common/areaSearchTypes'
import {
  areaNumberSorter,
  nestedStringSorter,
  numberSorter,
} from '../../utils/generalTableHelpers'
import { useUserActivityLogCheck } from '../../utils/useCheckUserActivityLog'
import { usePropertyTableNoteCheck } from '../../utils/usePropertyTableNoteCheck'
import { useSetAreaDrawer } from '../../utils/useSetPropertyDrawer'
import { watchdogAreaColumn } from '../../utils/watchdogHelpers'
import { PropertyNoteModalButton } from '../PropertyNoteModalButton/PropertyNoteModalButton'
import { UserActivityLogBadge } from '../UserActivityLogBadge/UserActivityLogBadge'

export interface AreaSearchTableProps {
  loading: boolean
  data: AreaSearchApiDataItem[]
}

const sorterTitle = {
  title: 'Řazení je aplikováno pouze na aktuální stránku výpisu.',
}

export const AreaSearchTable: FunctionComponent<AreaSearchTableProps> =
  props => {
    const setDrawer = useSetAreaDrawer()

    const propertyIds = useMemo(
      () => props.data?.map(item => item.id) || [],
      [props.data]
    )
    const propertyNotes = usePropertyTableNoteCheck(propertyIds)
    const propertiesActivity = useUserActivityLogCheck(propertyIds)

    const columns = useMemo<ColumnsType<AreaSearchApiDataItem>>(
      () => [
        {
          title: 'Parcelní číslo',
          dataIndex: 'parcelniCislo',
          sorter: areaNumberSorter('parcelniCislo'),
          showSorterTooltip: sorterTitle,
        },
        {
          title: 'Druh pozemku',
          dataIndex: ['druhPozemku', 'nazev'],
          sorter: nestedStringSorter('druhPozemku', 'nazev'),
          showSorterTooltip: sorterTitle,
          render: (druhPozemku: string, record) =>
            druhPozemku ? (
              <a
                onClick={() => setDrawer(record.id)}
                title="Zobrazit náhled nemovitosti"
              >
                {druhPozemku}
              </a>
            ) : (
              <span
                className="text-gray-400"
                title="ČÚZK u této nemovitosti neeviduje způsob využití"
              >
                ---
              </span>
            ),
        },
        {
          title: 'Výměra (m²)',
          dataIndex: 'vymera',
          sorter: numberSorter('vymera'),
          showSorterTooltip: sorterTitle,
        },
        {
          title: 'Poznámka',
          dataIndex: 'id',
          key: 'note',
          width: 84,
          render: id => <PropertyNoteModalButton note={propertyNotes?.[id]} />,
        },
        {
          title: 'Info',
          dataIndex: 'id',
          key: 'info',
          width: 110,
          render: id => (
            <UserActivityLogBadge userActivityLog={propertiesActivity?.[id]} />
          ),
        },
        watchdogAreaColumn,
      ],
      [propertiesActivity, propertyNotes, setDrawer]
    )

    return (
      <Table
        rowKey="id"
        size="small"
        columns={columns}
        dataSource={props.data}
        loading={props.loading}
        pagination={false}
      />
    )
  }
