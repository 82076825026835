import { Table } from 'antd'
import { ColumnsType, ExpandableConfig } from 'antd/es/table/interface'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import { compareDesc } from 'date-fns'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'

import {
  deliveryMethodList,
  SenderAddress,
} from '../../../common/postServiceTypes'
import {
  Maybe,
  PostserviceLetterType,
  usePostserviceSentLettersQuery,
  UuidFilter,
} from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { tablePagination } from '../../utils/layoutConst'
import { Min } from '../DateFormat/DateFormat'
import { ExpandColumnTitle } from '../ExpandColumnTitle/ExpandColumnTitle'
import { ExpandIcon } from '../ExpandIcon/ExpandIcon'
import { PdfDrawerPreview } from '../PdfDrawerPreview/PdfDrawerPreview'
import { PostServiceLetterCreateModalButton } from '../PostServiceLetterCreateModalButton/PostServiceLetterCreateModalButton'
import { PostServiceLetterDetailPdf } from '../PostServiceLetterDetailPdf/PostServiceLetterDetailPdf'
import { PostServiceSentTableAction } from '../PostServiceSentTableAction/PostServiceSentTableAction'
import { PostServiceSentTableExpandedRow } from '../PostServiceSentTableExpandedRow/PostServiceSentTableExpandedRow'

export interface PostServiceSentTableProps {
  idFilter?: UuidFilter
}

export interface SentTableItem {
  createdAt: Date
  name: string
  description?: string
  sentAt?: Maybe<Date>
  id: string
  content: string
  recipients: { totalCount: number }
  senderAddress?: SenderAddress
  postserviceType?: PostserviceLetterType
}

export const PostServiceSentTable: FunctionComponent<PostServiceSentTableProps> =
  props => {
    const tableId = 'postServiceSentLetter-table'
    const tenantId = useAppSelector(state => state.myProfile.tenantId)

    const [dataSource, setDataSource] = useState<SentTableItem[]>([])
    const expandedRowKeys = useAppSelector(
      state => state.expandedRow.tables?.[tableId]
    )

    const [queryResult, refetch] = usePostserviceSentLettersQuery({
      variables: { accountId: tenantId, originalListId: props.idFilter },
    })

    useEffect(() => {
      if (queryResult.data?.allPostserviceSentLetters?.nodes?.[0]?.id) {
        setDataSource(
          queryResult.data?.allPostserviceSentLetters?.nodes as SentTableItem[]
        )
      }
    }, [queryResult.data?.allPostserviceSentLetters?.nodes])

    const columns = useMemo<ColumnsType<SentTableItem>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Počet adresátů',
          dataIndex: 'recipientsCount',
          key: 'recipientsCount',
          className: 'w-32',
          sorter: {
            compare: (a, b) =>
              a.recipients.totalCount - b.recipients.totalCount,
          },
          render: (_, record) => record.recipients.totalCount || 0,
        },
        /*         {
          title: 'Štítek',
          dataIndex: 'labels',
          key: 'labels',
          render: (labels, record) => (
            <TableCellLabels
              labels={labels}
              labelEntityType="postserviceListId"
              recordId={record.id}
              refetch={refetch}
              modalTitle={record.nazev}
            />
          ),
        }, */
        {
          title: 'Název',
          dataIndex: 'name',
          key: 'name',
          sorter: {
            compare: (a, b) => a.name.localeCompare(b.name),
          },
        },
        {
          title: 'Popis',
          dataIndex: 'description',
          key: 'description',
          sorter: {
            compare: (a, b) =>
              a.description && b.description
                ? a.description.localeCompare(b.description)
                : -1,
          },
        },
        {
          title: 'Odesílatel',
          dataIndex: 'senderAddress',
          key: 'senderAddress',
          sorter: {
            compare: (a, b) =>
              a.senderAddress?.senderName && b.senderAddress?.senderName
                ? a.senderAddress.senderName.localeCompare(
                    b.senderAddress.senderName
                  )
                : -1,
          },
          render: (_, record) =>
            record.senderAddress?.senderName ? (
              <>
                {record.senderAddress?.senderName} -{' '}
                {record.senderAddress?.street}{' '}
                {record.senderAddress?.houseNumber}
                {record.senderAddress?.houseNumber &&
                  record.senderAddress?.orientationNumber &&
                  '/'}
                {record.senderAddress?.orientationNumber},{' '}
                {record.senderAddress?.city}, {record.senderAddress?.zipCode}
              </>
            ) : (
              '---'
            ),
        },
        {
          title: 'Odesláno',
          dataIndex: 'sentAt',
          key: 'sentAt',
          defaultSortOrder: 'ascend',
          className: 'text-right w-48',
          sorter: (a, b) => {
            const dateA = a.sentAt ? new Date(a.sentAt) : new Date()
            const dateB = b.sentAt ? new Date(b.sentAt) : new Date()
            return compareDesc(dateA, dateB)
          },
          render: (sentAt, record) => {
            if (sentAt)
              return (
                <span className="whitespace-nowrap">
                  <Min>{sentAt}</Min> -{' '}
                  {record.postserviceType &&
                    deliveryMethodList[record.postserviceType].name}
                </span>
              )
            return (
              <PostServiceSentTableAction
                recipientsCount={record.recipients.totalCount}
                letterId={record.id}
                refetch={refetch}
              />
            )
          },
        },
        {
          title: 'PDF',
          dataIndex: 'actions',
          key: 'actions',
          className: 'w-36',
          render: (_, record) => (
            <PdfDrawerPreview
              buttonProps={{ ghost: true }}
              buttonText="Šablona dopisu"
              drawerTitle="Náhled šablony dopisu"
              fileName={record.name + '_HROMADNY_DOPIS_REGESTA_DATA'}
            >
              <PostServiceLetterDetailPdf
                showTemplate
                letterText={record.content}
              />
            </PdfDrawerPreview>
          ),
        },
      ],
      [refetch]
    )

    const expandable = useMemo<ExpandableConfig<SentTableItem>>(() => {
      return {
        expandedRowKeys,
        expandedRowRender: record => (
          <PostServiceSentTableExpandedRow letterId={record.id} />
        ),
        columnTitle: (
          <ExpandColumnTitle
            allKeys={dataSource.map(record => record.id.toString())}
            tableId={tableId}
          />
        ),
        expandIcon: ({ expanded, record }) => (
          <ExpandIcon
            expanded={expanded}
            recordId={record.id.toString()}
            tableId={tableId}
            expandable={!!record.id}
          />
        ),
      }
    }, [dataSource, expandedRowKeys])

    const locale = useMemo(
      () => ({
        emptyText: !queryResult.fetching && (
          <div className="my-8">
            <div className="mb-2 text-gray-400">
              Zatím jste nerozeslali žádnou hromadnou poštu.
            </div>
            <PostServiceLetterCreateModalButton buttonText="Vytvořit první hromadnou korespondenci" />
          </div>
        ),
      }),
      [queryResult.fetching]
    )

    return (
      <Table
        rowKey="id"
        rowClassName="animate-fadeIn"
        locale={locale}
        id={tableId}
        dataSource={dataSource}
        columns={columns}
        loading={queryResult.fetching}
        expandable={expandable}
        bordered
        pagination={tablePagination}
      />
    )
  }
