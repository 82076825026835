import React, { FunctionComponent, memo, useMemo } from 'react'

import { VlastnickePravo } from '../../../common/lvTypes'
import { sharePercentage } from '../../utils/ownershipRightsTableHelpers'
import { getTimelineHeightClass } from '../../utils/propertyTimelineHelpers'
import { PropertyTimelineOwnershipTitleAddress } from '../PropertyTimelineOwnershipTitleAddress/PropertyTimelineOwnershipTitleAddress'
import { PropertyTimelineOwnershipTitleIcon } from '../PropertyTimelineOwnershipTitleIcon/PropertyTimelineOwnershipTitleIcon'
import { SubjectLink } from '../SubjectLink/SubjectLink'

export interface PropertyTimelineOwnershipTitleProps {
  vlastnictvi: VlastnickePravo
  addressIds: number[]
}

export const PropertyTimelineOwnershipTitle: FunctionComponent<PropertyTimelineOwnershipTitleProps> =
  memo(({ vlastnictvi, addressIds }) => {
    const share = useMemo(() => {
      const value = sharePercentage(
        vlastnictvi.podilCitatel,
        vlastnictvi.podilJmenovatel
      ) as number
      const valueString = `${value.toFixed(2)} %`

      return {
        value: valueString,
        indicator: {
          title: 'Podíl: ' + valueString,
          className:
            'bg-primary w-1 opacity-40 self-end ' +
            getTimelineHeightClass(value),
        },
      }
    }, [vlastnictvi])

    return (
      <div className="group h-full flex items-center text-sm after:block after:bg-gray-50 after:w-2 after:h-full after:absolute after:right-0">
        <div {...share.indicator} />
        <div className="max-w-[100px] flex group-hover:max-w-screen-sm transition-width duration-500 ease-in-out px-2">
          <PropertyTimelineOwnershipTitleIcon
            subjectName={vlastnictvi.subjekt.jmeno}
            sjmPartner={!!vlastnictvi.sjmPartner}
          />

          <div className="flex flex-col mr-2">
            <SubjectLink subjectId={vlastnictvi.subjekt.id}>
              {vlastnictvi.subjekt.jmeno}
            </SubjectLink>
            <SubjectLink
              subjectId={vlastnictvi.sjmPartner?.id}
              className="hidden group-hover:block"
            >
              {vlastnictvi.sjmPartner?.jmeno}
            </SubjectLink>
          </div>

          <PropertyTimelineOwnershipTitleAddress
            addressIds={addressIds}
            subjekt={vlastnictvi.subjekt}
            sjmPartner={vlastnictvi.sjmPartner}
          />

          <div className="mx-2 self-center" title="Vlastnický podíl">
            {share.value}
          </div>
        </div>
      </div>
    )
  })
