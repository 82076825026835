import { QuestionCircleOutlined } from '@ant-design/icons'
import { Table, Tooltip } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { VlastnickePravo } from '../../../common/lvTypes'
import { useDeclension } from '../../utils/useDeclension'

interface PropertyDetailOwnershipRightsTableSummaryProps {
  rights: VlastnickePravo[]
  dataSourceLength: number
  isDrawer?: boolean
}

export const PropertyDetailOwnershipRightsTableSummary: FunctionComponent<PropertyDetailOwnershipRightsTableSummaryProps> =
  ({ rights, dataSourceLength, isDrawer }) => {
    const decline = useDeclension()

    const { historyCount, sjmExists, totalCount } = useMemo(() => {
      let historyCount = 0
      let sjmExists = false

      for (const right of rights) {
        if (right.platiDo) historyCount++
        if (right.sjmPartner) sjmExists = true
      }

      return {
        historyCount,
        sjmExists,
        totalCount: rights.length,
      }
    }, [rights])

    if (totalCount < 2 && !historyCount) return null

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell
          colSpan={isDrawer ? 0 : 2}
          index={0}
          className="!border-b-0"
        />
        <Table.Summary.Cell colSpan={10} index={1} className="!border-b-0">
          <div className="text-gray-400">
            <span>Celkem: {decline(totalCount, 'subject', true)}</span>
            {sjmExists && (
              <Tooltip title="Počet řádků v tabulce může být vyšší než celkový počet oprávněných subjektů, jelikož každou osobu v SJM zobrazujeme na řádku samostatně.">
                {' '}
                <QuestionCircleOutlined />
              </Tooltip>
            )}
            {totalCount !== dataSourceLength && (
              <>
                <span className="text-gray-300 mx-3">|</span>
                <span>Aktuální: {totalCount - historyCount}</span>
                <span className="text-gray-300 mx-3">|</span>
                <span>Archivní: {historyCount}</span>
              </>
            )}
          </div>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    )
  }
