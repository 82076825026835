import React, { FunctionComponent } from 'react'

import { useDeclension } from '../../utils/useDeclension'
import { Money } from '../Money/Money'

export interface PricePopconfirmTitleProps {
  itemCount: number
  itemPrice: number
  totalPrice?: number
  message?: string | React.ReactNode
}

export const PricePopconfirmTitle: FunctionComponent<PricePopconfirmTitleProps> =
  props => {
    const decline = useDeclension()

    return (
      <div className="max-w-sm space-y-1 mb-2">
        <div>
          {props.message || (
            <>
              Tato akce je zpoplatněna{' '}
              <Money symbol="Kr" amount={props.itemPrice} />.
            </>
          )}
        </div>
        {props.itemCount && props.itemCount > 1 && (
          <div>
            Chystáte se provést hromadnou akci pro{' '}
            {decline(props.itemCount, 'selectedItems')} celkem za{' '}
            <Money
              symbol="Kr"
              amount={props.totalPrice || props.itemCount * props.itemPrice}
            />
            .
          </div>
        )}
      </div>
    )
  }
