import { Button, ButtonProps, Modal } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { BookmarkDetailItem } from '../../../common/bookmarkTypes'
import { useToggleState } from '../../utils/useToggleState'
import { Fade } from '../Fade/Fade'
import {
  PostServiceCreateRecipientListForm,
  PostServiceCreateRecipientListFormValues,
} from '../PostServiceCreateRecipientListForm/PostServiceCreateRecipientListForm'

export interface PostServiceCreateRecipientListModalButton {
  id?: string
  recipientList?: BookmarkDetailItem[]
  buttonProps?: ButtonProps
  buttonText?: string
  initialValues?: PostServiceCreateRecipientListFormValues
  reexecuteQuery?: () => void
}

export const PostServiceCreateRecipientListModalButton: FunctionComponent<PostServiceCreateRecipientListModalButton> =
  props => {
    const [showHelp, toggleShowHelp] = useToggleState()
    const [open, toggleModal] = useToggleState()

    const reexecuteQuery = useCallback(() => {
      props.reexecuteQuery ? props.reexecuteQuery() : null
    }, [props])

    const modalTitle = useMemo(
      () =>
        props.initialValues?.listName ? (
          <span>
            Editovat seznam{' '}
            <span className="italic">{props.initialValues?.listName}</span>
          </span>
        ) : (
          'Vytvořit nový seznam adresátů'
        ),
      [props.initialValues?.listName]
    )

    return (
      <>
        <Button
          type="primary"
          onClick={toggleModal}
          className="sm:w-auto w-full"
          {...props.buttonProps}
        >
          {props.buttonText || 'Vytvořit nový seznam adresátů'}
        </Button>
        <Modal
          title={modalTitle}
          open={open}
          onCancel={toggleModal}
          footer={null}
          width={600}
        >
          <>
            <Fade show={showHelp} className="mb-4 mt-6 text-gray-400">
              Seznam adresátů si můžete pojmenovat a přidat jeho popis. Tyto
              údaje slouží pouze pro vás v rámci systému a jsou neveřejné.
            </Fade>
            <PostServiceCreateRecipientListForm
              id={props?.id}
              hideModal={toggleModal}
              handleHelpToggle={toggleShowHelp}
              showHelp={showHelp}
              initialValues={props.initialValues}
              reexecuteQuery={reexecuteQuery}
            />
          </>
        </Modal>
      </>
    )
  }
