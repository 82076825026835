import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { ExpandableConfig } from 'antd/es/table/interface'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import {
  InsolvencyMonitoringCompactTableItem,
  InsolvencyMonitoringRecord,
} from '../../../common/insolvencyMonitoringTypes'
import { UserActivityLogsResult } from '../../../common/userActivityLogTypes'
import { useAppSelector } from '../../../redux/hooks'
import {
  nestedStringSorter,
  numberSorter,
} from '../../utils/generalTableHelpers'
import { PropertyTableNotes } from '../../utils/propertyDetailNoteHelpers'
import {
  insolvencyUrl,
  restrictionColumn,
} from '../../utils/propertyMonitoringHelpers'
import { isCompanyName } from '../../utils/subjectHelpers'
import { useInsolvencyMonitoringCompactData } from '../../utils/useInsolvencyMonitoringCompactData'
import { Day } from '../DateFormat/DateFormat'
import { ExpandColumnTitle } from '../ExpandColumnTitle/ExpandColumnTitle'
import { ExpandIcon } from '../ExpandIcon/ExpandIcon'
import { InsolvencyMonitoringTable } from '../InsolvencyMonitoringTable/InsolvencyMonitoringTable'
import { Money } from '../Money/Money'
import { PropertyTimelineOwnershipTitleIcon } from '../PropertyTimelineOwnershipTitleIcon/PropertyTimelineOwnershipTitleIcon'
import { SubjectLink } from '../SubjectLink/SubjectLink'

export interface InsolvencyMonitoringCompactTableProps {
  data?: InsolvencyMonitoringRecord[] | null
  propertyType: PROPERTY_TYPES
  propertiesActivity?: UserActivityLogsResult
  propertyNotes?: PropertyTableNotes
}

const tableId = 'compact-insolvency-monitoring'
const scrollX = { x: 'max-content' }

const shareRenderer = (
  podil: InsolvencyMonitoringCompactTableItem['podil']
) => {
  if (!podil.od || !podil.do) return '---'
  if (podil.od === podil.do)
    return <Money amount={podil.do * 100} symbol="%" grayscale />
  return (
    <div className="flex flex-col space-x-0 items-end xl:items-center xl:space-x-2 xl:flex-row justify-end">
      <div className="flex w-20 justify-between">
        <span className="text-gray-400">Od:</span>
        <Money amount={podil.od * 100} symbol="%" grayscale />
      </div>
      <span className="flex w-20 justify-between">
        <span className="text-gray-400">Do:</span>
        <Money amount={podil.do * 100} symbol="%" grayscale />
      </span>
    </div>
  )
}

export const InsolvencyMonitoringCompactTable: FunctionComponent<InsolvencyMonitoringCompactTableProps> =
  ({ data, propertyType, propertiesActivity, propertyNotes }) => {
    const expandedRowKeys = useAppSelector(
      state => state.expandedRow.tables?.[tableId] || []
    )

    const dataSource = useInsolvencyMonitoringCompactData(data)

    const columns: ColumnsType<InsolvencyMonitoringCompactTableItem> = useMemo(
      () => [
        {
          title: 'Poslední vklad/zápis',
          dataIndex: ['insVec', 'posledniZmenaStavu'],
          width: 105,
          sorter: nestedStringSorter('insVec', 'posledniZmenaStavu'),
          render: (posledniZmenaStavu: string) =>
            posledniZmenaStavu && <Day>{posledniZmenaStavu}</Day>,
        },
        {
          title:
            propertyType === PROPERTY_TYPES.AREA
              ? 'Počet pozemků'
              : propertyType === PROPERTY_TYPES.BUILDING
              ? 'Počet budov'
              : 'Počet jednotek',
          dataIndex: 'pocetNemovitosti',
          width: 30,
          className: '!text-center',
          sorter: numberSorter('pocetNemovitosti'),
        },
        {
          title: 'Katastrální pracovistě',
          dataIndex: ['pracoviste', 'nazev'],
          ellipsis: true,
          sorter: nestedStringSorter('pracoviste', 'nazev'),
        },
        {
          title: 'Spisová zn.',
          dataIndex: ['insVec', 'spisovaZnacka'],
          className: 'w-32',
          sorter: nestedStringSorter('insVec', 'spisovaZnacka'),
          render: (spisovaZnacka, record) => (
            <a
              href={insolvencyUrl(record.insVec?.spisovaZnacka)}
              target="_blank"
              rel="noreferrer"
            >
              {spisovaZnacka}
            </a>
          ),
        },
        {
          title: 'Stav ins.',
          dataIndex: ['insVec', 'stavRizeni', 'nazev'],
          className: 'w-32',
          sorter: (a, b) =>
            (a.insVec?.stavRizeni?.nazev || '').localeCompare(
              b.insVec?.stavRizeni?.nazev || ''
            ),
          render: stavRizeni => stavRizeni,
        },
        {
          title: 'Typ',
          width: 45,
          dataIndex: ['subjekt', 'nazev'],
          key: 'subjectType',
          className: '!text-right',
          sorter: (a, b) =>
            (isCompanyName(a.subjekt?.nazev) ? 1 : 0) -
            (isCompanyName(b.subjekt?.nazev) ? 1 : 0),
          render: subjectName => (
            <PropertyTimelineOwnershipTitleIcon subjectName={subjectName} />
          ),
        },
        {
          title: 'Dlužník',
          dataIndex: ['subjekt', 'nazev'],
          className: 'w-32',
          ellipsis: true,
          sorter: nestedStringSorter('subjekt', 'nazev'),
          render: (subjectName, record) => (
            <SubjectLink
              newTab
              subjectId={record.subjekt?.id}
              className="block truncate"
            >
              {subjectName}
            </SubjectLink>
          ),
        },

        restrictionColumn.zastavySmluvni,
        restrictionColumn.zastavyNedobrovolne,

        {
          title: 'Podíly',
          dataIndex: 'podil',
          className: '!text-right',
          sorter: (a, b) => (a.podil?.od || 0) - (b.podil?.od || 0),
          render: shareRenderer,
        },
      ],
      [propertyType]
    )

    const expandable = useMemo<
      ExpandableConfig<InsolvencyMonitoringCompactTableItem>
    >(
      () => ({
        expandedRowKeys,
        columnTitle: (
          <ExpandColumnTitle
            allKeys={dataSource
              .filter(record => record?.items?.length)
              .map(record => record.id)}
            tableId={tableId}
          />
        ),
        expandedRowRender: record => (
          <div className="mb-8">
            <InsolvencyMonitoringTable
              data={record.items}
              propertyType={propertyType}
              userActivityLogs={propertiesActivity}
              propertyNotes={propertyNotes}
            />
          </div>
        ),
        expandIcon: ({ expanded, record }) => (
          <div className="w-7 pr-2 inline-block">
            <ExpandIcon
              expanded={expanded}
              recordId={record.id}
              tableId={tableId}
              expandable={!!record?.items?.length}
            />
          </div>
        ),
      }),
      [
        expandedRowKeys,
        dataSource,
        propertyType,
        propertiesActivity,
        propertyNotes,
      ]
    )

    const expandedClassName = useCallback(
      (record: InsolvencyMonitoringCompactTableItem) =>
        expandedRowKeys.includes(record.id) ? 'rowExpanded fade-in' : '',
      [expandedRowKeys]
    )

    return (
      <Table
        rowKey="id"
        size="small"
        id={tableId}
        columns={columns}
        dataSource={dataSource || []}
        pagination={false}
        expandable={expandable}
        rowClassName={expandedClassName}
        bordered
        scroll={scrollX}
      />
    )
  }
