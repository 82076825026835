import { Icon } from '@iconify/react'
import React, { FunctionComponent } from 'react'

export interface NoResultBoxProps {
  title?: string
  description?: string
}

export const NoResultBox: FunctionComponent<NoResultBoxProps> = ({
  title = 'Nenalezeno',
  description = 'Žádný záznam neodpovídá vašemu hledání',
}) => {
  return (
    <div className="flex flex-col text-center space-y-2 bg-white w-full p-8">
      <Icon
        width="70"
        className="text-gray-400 mx-auto"
        icon="healthicons:stock-out-outline"
      />
      <div className="text-lg">{title}</div>
      <div className="text-gray-400">{description}</div>
    </div>
  )
}
