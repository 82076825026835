import { LineLayerSpecification, SymbolLayerSpecification } from 'mapbox-gl'
import React, { FunctionComponent } from 'react'
import { Layer, Source } from 'react-map-gl'

import { MAPBOX_ACCESS_TOKEN } from '../../../../ini.json'

const lineLayer: LineLayerSpecification = {
  id: 'pracoviste',
  source: 'pracoviste',
  'source-layer': 'pracoviste',
  type: 'line',
  paint: {
    'line-color': '#54afb0',
    'line-width': 1,
  },
}
const symbolLayer: SymbolLayerSpecification = {
  id: 'pracoviste-points',
  source: 'pracoviste-points',
  type: 'symbol',
  layout: {
    'text-field': '{name}',
    'text-size': 12,
  },
  paint: {
    'text-halo-color': '#fff',
    'text-halo-width': 1,
    'text-color': '#000',
  },
}

export const CadastreMapAdministrationDisplay: FunctionComponent = () => {
  return (
    <>
      <Source id="pracoviste" url="mapbox://kolpav.0g5cbtfj" type="vector">
        <Layer {...lineLayer} beforeId="continent-label" />
      </Source>
      <Source
        id="pracoviste-points"
        data={`https://api.mapbox.com/datasets/v1/kolpav/clx8zsbdjx24g1uoa9jlf1yye/features?access_token=${MAPBOX_ACCESS_TOKEN}`}
        type="geojson"
      >
        <Layer {...symbolLayer} beforeId="continent-label" />
      </Source>
    </>
  )
}
