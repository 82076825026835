import { Icon } from '@iconify/react'
import { Tooltip } from 'antd'
import { Feature, Point } from 'geojson'
import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { Popup, PopupProps } from 'react-map-gl'

import { AuctionMapRecord } from '../../../common/auctionMonitoringTypes'
import { AUCTION_TYPES } from '../../../common/auctionScrapeTypes'
import { safeJsonParsing } from '../../../common/safeJsonParsing'
import { UserActivityLog } from '../../../graphql/generated'
import { setAuctionDrawer } from '../../../redux/drawerSlice'
import { useAppDispatch } from '../../../redux/hooks'
import { UserActivityLogBadge } from '../UserActivityLogBadge/UserActivityLogBadge'

export interface CadastreMapAuctionClusterPopupProps extends PopupProps {
  features: Feature<Point, AuctionMapRecord>[]
}

function toIconName(auction: AuctionMapRecord) {
  switch (auction.type) {
    case 'area':
      return 'mdi:land-plots-marker'
    case 'building':
      return 'fluent:building-32-filled'
    case 'unit':
      return 'bi:building-fill-add'
    default:
      return 'ri:question-line'
  }
}

const now = new Date()

export const CadastreMapAuctionClusterPopup: FunctionComponent<CadastreMapAuctionClusterPopupProps> =
  ({ features, ...popupProps }) => {
    const dispatch = useAppDispatch()

    const setDrawer = useCallback(
      (id: string, type: AUCTION_TYPES) =>
        dispatch(setAuctionDrawer({ id: id, auctionType: type })),
      [dispatch]
    )

    const auctions = useMemo(
      () =>
        features
          .map(feature => feature.properties)
          .map(auction => {
            return {
              id: auction.id,
              createdAt: auction.createdAt,
              updatedAt: auction.updatedAt,
              type: auction.type as AUCTION_TYPES,
              icon: toIconName(auction),
              title: auction.title,
              activityLogs: safeJsonParsing(
                auction.activityLogs as unknown as string,
                { nodes: [] }
              ),
              isNew: (() => {
                const createdAt = new Date(auction?.createdAt ?? '')
                if (isNaN(createdAt.getTime())) return false
                const daysThreshold = 3
                if (
                  now.getTime() - createdAt.getTime() <
                  daysThreshold * 24 * 60 * 60 * 1000
                ) {
                  return true
                }
                return false
              })(),
              state: (() => {
                const startDate = new Date(auction?.startDate ?? '')
                if (isNaN(startDate.getTime())) {
                  console.log('Invalid startDate', auction)
                  return
                }
                const endDate = new Date(auction?.endDate ?? '')
                if (now < startDate) {
                  return 'upcoming'
                } else if (startDate <= now && now <= endDate) {
                  return 'ongoing'
                } else {
                  return 'ended'
                }
              })(),
            }
          }),
      [features]
    )

    return (
      <Popup
        {...popupProps}
        closeOnClick={false}
        closeButton={false}
        anchor="bottom-right"
        maxWidth={undefined}
      >
        <div className="space-y-1 -mb-1.5">
          {auctions.map((auction, index) => {
            return (
              <div
                key={index}
                className="flex justify-end items-center space-x-2 text-gray-800 text-sm"
              >
                <UserActivityLogBadge
                  userActivityLog={
                    auction.activityLogs?.nodes?.[0] as UserActivityLog
                  }
                  alertDateTo={auction.updatedAt || auction.createdAt}
                />
                {auction.isNew && (
                  <Tooltip title="nová">
                    <Icon className="text-yellow-500" icon="entypo:new" />
                  </Tooltip>
                )}
                {auction.state === 'upcoming' && (
                  <span className="text-yellow-500">připravovaná</span>
                )}
                {auction.state === 'ongoing' && (
                  <span className="text-green-500">probíhající</span>
                )}
                {auction.state === 'ended' && (
                  <span className="text-gray-500">ukončená</span>
                )}
                <Icon icon={auction.icon} />
                <a
                  onClick={() => setDrawer(auction.id, auction.type)}
                  title="Zobrazit detail dražby"
                  className="block w-52 truncate"
                >
                  {auction.title}
                </a>
              </div>
            )
          })}
        </div>
      </Popup>
    )
  }
