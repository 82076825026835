import { Tag, Typography } from 'antd'
import React, { FunctionComponent, memo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { InsolvencyMonitoringRecord } from '../../../common/insolvencyMonitoringTypes'
import { UserActivityLog } from '../../../common/userActivityLogTypes'
import { PropertyTableNoteItem } from '../../utils/propertyDetailNoteHelpers'
import { insolvencyUrl } from '../../utils/propertyMonitoringHelpers'
import { Day } from '../DateFormat/DateFormat'
import { Money } from '../Money/Money'
import { PropertyMonitoringTilesItemFooter } from '../PropertyMonitoringTilesItemFooter/PropertyMonitoringTilesItemFooter'
import { PropertyMonitoringTilesItemImage } from '../PropertyMonitoringTilesItemImage/PropertyMonitoringTilesItemImage'
import { PropertyMonitoringTilesItemRibbon } from '../PropertyMonitoringTilesItemRibbon/PropertyMonitoringTilesItemRibbon'
import { PropertyMonitoringTilesItemRow } from '../PropertyMonitoringTilesItemRow/PropertyMonitoringTilesItemRow'
import { PropertyNoteModalButton } from '../PropertyNoteModalButton/PropertyNoteModalButton'
import { SubjectLink } from '../SubjectLink/SubjectLink'

export interface InsolvencyMonitoringTilesItemProps
  extends InsolvencyMonitoringRecord {
  propertyType: PROPERTY_TYPES
  userActivityLog?: UserActivityLog | null
  propertyNote?: PropertyTableNoteItem | null
}

export const InsolvencyMonitoringTilesItem: FunctionComponent<InsolvencyMonitoringTilesItemProps> =
  memo(props => {
    return (
      <PropertyMonitoringTilesItemRibbon
        alertDateTo={props.insVec?.posledniZmenaStavu}
        userActivityLog={props.userActivityLog}
      >
        <div
          className={`flex flex-col justify-between group rounded-lg border overflow-hidden shadow-sm hover:shadow-lg h-full ${
            props.userActivityLog
              ? 'opacity-70 hover:opacity-100 transition-all'
              : ''
          }`}
        >
          <div className="relative w-full pb-full">
            <PropertyMonitoringTilesItemImage
              className="h-80 lg:h-96 2xl:h-80 4xl:h-96"
              propertyId={props.id}
              coordinates={props.souradnice}
              propertyType={props.propertyType}
            />
          </div>
          <div className="flex flex-col justify-between grow">
            <div className="p-5 pb-0 transition-all text-gray-400">
              <Typography.Title
                level={4}
                className="!mb-4 text-gray-800 first-letter:uppercase"
              >
                {props.zpusobVyuziti?.nazev ||
                  props.druhPozemku?.nazev ||
                  '- neuvedeno -'}
              </Typography.Title>

              <PropertyMonitoringTilesItemRow
                label="Kat. pracoviště"
                value={props.pracoviste.nazev}
              />
              <PropertyMonitoringTilesItemRow
                label="Podíl dlužníka"
                value={<Money amount={props.podil * 100} symbol="%" />}
              />
              <PropertyMonitoringTilesItemRow
                label="Dlužník"
                value={
                  <SubjectLink
                    newTab
                    subjectId={props.subjekt?.id}
                    className="!text-primaryHover hover:!text-primary"
                  >
                    {props.subjekt?.nazev}
                  </SubjectLink>
                }
              />
              <PropertyMonitoringTilesItemRow
                label="Spisová značka"
                value={
                  <a
                    href={insolvencyUrl(props.insVec?.spisovaZnacka)}
                    target="_blank"
                    rel="noreferrer"
                    className="!text-primaryHover hover:!text-primary"
                  >
                    {props.insVec?.spisovaZnacka}
                  </a>
                }
              />
              <PropertyMonitoringTilesItemRow
                label="Stav insolvence"
                value={props.insVec.stavRizeni.nazev}
              />
              <PropertyMonitoringTilesItemRow
                label="Poslední změna"
                value={<Day>{props.insVec.posledniZmenaStavu}</Day>}
              />
              <PropertyMonitoringTilesItemRow
                label="Poznámka"
                condition={!!props.propertyNote}
                value={
                  <PropertyNoteModalButton
                    className="truncate max-w-[220px] text-gray-800"
                    note={props.propertyNote}
                  />
                }
              />

              <div className="pt-2 mb-2 flex gap-1">
                <Tag
                  color="error"
                  title="Insolvence"
                  className="!mr-0 truncate"
                >
                  Insolvence
                </Tag>

                {props.zastavyNedobrovolne && (
                  <Tag
                    color="error"
                    title="Zástavní právo nedobrovolné"
                    className="!mr-0 truncate"
                  >
                    Nedobrovolná zástava
                  </Tag>
                )}
                {props.zastavySmluvni && (
                  <Tag
                    color="orange"
                    title="Zástavní právo smluvní"
                    className="!mr-0 truncate"
                  >
                    Smluvní zástava
                  </Tag>
                )}
              </div>
            </div>
          </div>
          <PropertyMonitoringTilesItemFooter
            id={props.id}
            propertyType={props.propertyType}
          />
        </div>
      </PropertyMonitoringTilesItemRibbon>
    )
  })
