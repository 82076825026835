import { Icon } from '@iconify/react'
import { Button } from 'antd'
import React, { FunctionComponent } from 'react'

export interface MonitoringFilterResetButtonProps {
  onClick: () => void
}

const resetIcon = (
  <Icon
    icon="material-symbols-light:filter-alt-off-outline"
    width="18"
    height="24"
    className="inline-block -mb-1"
  />
)

export const PropertyMonitoringFilterResetButton: FunctionComponent<MonitoringFilterResetButtonProps> =
  props => {
    return (
      <Button
        className="hover:!text-red-500 hover:!border-red-500"
        title="Resetovat tento filtr"
        onClick={props.onClick}
        icon={resetIcon}
      />
    )
  }
