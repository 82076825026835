import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { PROPERTY_TAB_KEYS } from '../../../common/propertyDetailTypes'

export interface PropertyTabLinkProps {
  children: React.ReactNode
  to: PROPERTY_TAB_KEYS
  title?: string
}

export const PropertyTabLink: FunctionComponent<PropertyTabLinkProps> =
  props => {
    return (
      <Link title={props.title} to={`?tab=${props.to}`}>
        {props.children}
      </Link>
    )
  }
