import { message } from 'antd'

import { handleResponseStatus } from './handleResponseStatus'
import { requestCeeSubjectPath } from './paths'

export const checkCeeSubject = async (ids: string[], token: string) => {
  try {
    const response = await fetch(
      requestCeeSubjectPath() + token + '/' + ids.join(',')
    )

    if (response.status === 200) {
      const data = await response.json()
      const findedCount = data?.length ? data.length : 0

      if (!!findedCount && ids.length > 1)
        return message.success(
          `${findedCount} z ${ids.length} vybraných subjektů bylo nalezeno v CEE`
        )

      if (findedCount === ids.length && ids.length > 1)
        return message.success(
          'Všechny z vybraných subjektů byly nalezeny v CEE'
        )

      if (findedCount === ids.length)
        return message.success('Subjekt byl zkontrolován v CEE')

      if (!findedCount && ids.length > 1)
        return message.success(`Žádný vybraných subjektů nebyl nalezen v CEE`)

      return message.warning('Subjekt nebyl nalezen v CEE')
    }
    return handleResponseStatus(response.status)
  } catch (error) {
    message.error('Požadavek se nepodařilo provést, zkuste to prosím později.')
  }
  return false
}
