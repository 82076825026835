import React, { FunctionComponent, memo, useMemo } from 'react'

import { Rizeni } from '../../../common/proceedingTypes'
import { ProceedingDetailModalLink } from '../ProceedingDetailModalLink/ProceedingDetailModalLink'
import { PropertyTimelineProceedingBadge } from '../PropertyTimelineProceedingBadge/PropertyTimelineProceedingBadge'

export interface PropertyTimelineProceedingTitleProps {
  rizeni: Rizeni
}

export const PropertyTimelineProceedingTitle: FunctionComponent<PropertyTimelineProceedingTitleProps> =
  memo(({ rizeni }) => {
    const { ucastniciPZP, ucastniciOVN, predmety } = useMemo(
      () => ({
        ucastniciPZP: rizeni.ucastniciPZP.join(', '),
        ucastniciOVN: rizeni.ucastniciOVN.join(', '),
        predmety: rizeni.predmety.join(', '),
      }),
      [rizeni.predmety, rizeni.ucastniciOVN, rizeni.ucastniciPZP]
    )

    return (
      <div className="flex space-x-2 max-w-[120px] group-hover:max-w-screen-sm transition-width duration-500 p-2 pr-1 overflow-hidden">
        <PropertyTimelineProceedingBadge proceeding={rizeni} />
        <div className="leading-5 text-sm truncate">
          <ProceedingDetailModalLink proceeding={rizeni} />

          <div>
            <span>{predmety} </span>
            {!!ucastniciPZP && (
              <span className="leading-5 hidden group-hover:inline pl-2 border-l-gray-400">
                <span className="text-gray-400">PZP: </span>
                {ucastniciPZP}
              </span>
            )}
          </div>

          {!!ucastniciOVN && (
            <div className="text-gray-400 truncate">{ucastniciOVN}</div>
          )}
        </div>
      </div>
    )
  })
