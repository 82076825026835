import React, { FunctionComponent } from 'react'

export interface LocalSavedPdfProps {
  path?: string
}

export const LocalSavedPdf: FunctionComponent<LocalSavedPdfProps> = props => {
  if (!props.path) return null

  return <iframe src={props.path} className="h-full w-full" />
}
