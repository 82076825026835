import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { FunctionComponent, memo } from 'react'

import { PropertyMonitoringFilterItem } from '../PropertyMonitoringFilterItem/PropertyMonitoringFilterItem'

export interface PropertyMonitoringFilterErrorProps {
  filterType: 'propertyUse' | 'cadastralWorkplace'
}

const errorMessages = {
  propertyUse: 'způsobů využití',
  cadastralWorkplace: 'katastrálních pracovišť',
}
const errorLabels = {
  propertyUse: 'Způsob využití',
  cadastralWorkplace: 'Katastrální pracoviště',
}

export const PropertyMonitoringFilterError: FunctionComponent<PropertyMonitoringFilterErrorProps> =
  memo(props => {
    const title = `Je nám líto, ale nastala chyba při načítání ${
      errorMessages[props.filterType]
    }. Zkuste stránku aktualizovat, případně se obraťte na technickou podporu.`

    return (
      <PropertyMonitoringFilterItem label={errorLabels[props.filterType]}>
        <Tooltip
          title={title}
          className="text-red-500 flex items-center space-x-2 flex-1"
        >
          <ExclamationCircleOutlined /> <span>Chyba dat</span>
        </Tooltip>
      </PropertyMonitoringFilterItem>
    )
  })
