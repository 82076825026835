import axios from 'axios'
import { useCallback, useEffect, useRef } from 'react'

import { BASE_URL } from '../../../ini.json'
import {
  WatchdogApiResponse,
  WatchdogChannelApiResponse,
} from '../../common/watchdogTypes'
import { WatchDogDataTypes } from '../../redux/fetchAction'
import { useAppSelector } from '../../redux/hooks'
import { useDispatchFetch } from './useDispatchFetch'

export const useFetchWatchdogProperty = () => {
  const { token, tenantId } = useAppSelector(state => state.myProfile)
  const { dispatchError, dispatchFetching, dispatchSuccess } =
    useDispatchFetch()

  const fetchWatchdogProperty = useCallback(async () => {
    if (!token || !tenantId) return dispatchError(WatchDogDataTypes.PROPERTY)

    dispatchFetching(WatchDogDataTypes.PROPERTY)
    dispatchFetching(WatchDogDataTypes.CHANNEL)

    try {
      const propertyResponse = await axios.get<WatchdogApiResponse>(
        `${BASE_URL}/api/watchdog/property/${token}`
      )
      dispatchSuccess(WatchDogDataTypes.PROPERTY, propertyResponse.data)
    } catch (error) {
      dispatchError(WatchDogDataTypes.PROPERTY)
    }

    try {
      const channelResponse = await axios.get<WatchdogChannelApiResponse>(
        `${BASE_URL}/api/watchdog/channel/${tenantId}`,
        { withCredentials: true }
      )

      dispatchSuccess(WatchDogDataTypes.CHANNEL, channelResponse.data)
    } catch (error) {
      dispatchError(WatchDogDataTypes.CHANNEL)
    }
  }, [dispatchError, dispatchFetching, dispatchSuccess, tenantId, token])

  return fetchWatchdogProperty
}

export const useInitialFetchWatchdog = () => {
  const hasFetched = useRef(false)
  const fetchWatchdogProperty = useFetchWatchdogProperty()

  useEffect(() => {
    if (!hasFetched.current) {
      fetchWatchdogProperty()
      hasFetched.current = true
    }
  }, [fetchWatchdogProperty])
}
