import { Icon } from '@iconify/react'
import { Button, Form, Input, message, Table, Tooltip } from 'antd'
import { ColumnsType, SorterResult, SortOrder } from 'antd/es/table/interface'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import { FieldData } from 'rc-field-form/lib/interface'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { MonitoringInputType } from '../../../common/cuzkMonitorTypes'
import { validateTrigger } from '../../utils/layoutConst'
import { CeeMonitorAddSubjectModal } from '../CeeMonitorAddSubjectModal/CeeMonitorAddSubjectModal'
import { CuzkMonitorAddSubjectModal } from '../CuzkMonitorAddSubjectModal/CuzkMonitorAddSubjectModal'
import { RcTableItem } from '../CuzkMonitorRcTable/CuzkMonitorRcTable'
import { Fade } from '../Fade/Fade'
import { TabbleCellEditNoteModal } from '../TabbleCellEditNoteModal/TabbleCellEditNoteModal'

export interface NameBirthdateTableItemConcept
  extends Omit<RcTableItem, 'createdAt' | 'rc'> {
  key: number
  birthDate: string
  firstname: string
  lastname: string
}

export interface NameBirthdateInputFormValues {
  birthDate: string
  firstname: string
  lastname: string
  note?: string
}

export interface NameBirthdateInputProps {
  type: MonitoringInputType
  buttonRef: React.MutableRefObject<null>
  inputRef: React.MutableRefObject<null>
  monitoring?: boolean
}

const locale = {
  emptyText: 'Zadejte nebo vložte údaje do formuláře.',
}
export const NameBirthdateInput: FunctionComponent<NameBirthdateInputProps> =
  props => {
    const [disabledSubmit, setDisabledSubmit] = useState(true)
    const [form] = Form.useForm()
    const [, setSortedInfo] = useState<
      SorterResult<NameBirthdateTableItemConcept>
    >({})
    const [dataSource, setDataSource] = useState<
      NameBirthdateTableItemConcept[]
    >([])

    const handleDisableSubmit = useCallback(
      (_: FieldData[], data: FieldData[]) => {
        data.every((a: FieldData) => {
          const NamePathArray = a.name as string[]
          return (
            !a?.errors?.length && (a.touched || NamePathArray?.[0] === 'note')
          )
        })
          ? setDisabledSubmit(false)
          : setDisabledSubmit(true)
      },
      []
    )

    const firstNameValidation = useCallback(async () => {
      const value = form.getFieldValue('firstname')
      if (
        !value &&
        !Object.values(form.getFieldsValue()).every(value => !value)
      ) {
        throw new Error('Zadejte křestní jméno')
      }
    }, [form])

    const birthDateValidation = useCallback((_: unknown, value: string) => {
      const parts = value ? value.split('.') : []
      if (parts.length !== 3) {
        return Promise.reject(new Error('Neplatný formát data'))
      }
      const day = parseInt(parts[0])
      const month = parseInt(parts[1])
      const year = parseInt(parts[2])

      if (day < 1 || day > 31)
        return Promise.reject(new Error('Neplatný den v datu'))

      if (month < 1 || month > 12)
        return Promise.reject(new Error('Neplatný měsíc v datu'))

      if (year < 1910 || year > new Date().getFullYear())
        return Promise.reject(new Error('Neplatný rok narození'))

      return Promise.resolve()
    }, [])

    const cuzkBirthDateNormalization = useCallback(
      (value: string, prev: string) => {
        value = value
          .replace(/[-/]/g, '.')
          .replace(/[^\d.]+/g, '')
          .replace(/\.{2,}/g, '.')

        if (value.length === 8 && /^[0-9]+$/.test(value)) {
          return (
            value.slice(0, 2) + '.' + value.slice(2, 4) + '.' + value.slice(4)
          )
        }
        if (value.length > 5 && value.length > prev.length) {
          const parts = value.split('.')
          return parts
            .map((part, index) =>
              index < 2 && part.length === 1 ? '0' + part : part
            )
            .join('.')
        }

        return value
      },
      []
    )

    const ceeBirthDateNormalization = useCallback(
      (value: string, prev: string) => {
        value = value
          .replace(/[-/]/g, '.')
          .replace(/[^\d.]+/g, '')
          .replace(/\.{2,}/g, '.')

        if (value.length === 8 && /^[0-9]+$/.test(value)) {
          return (
            value.slice(0, 1) + '.' + value.slice(1, 2) + '.' + value.slice(2)
          )
        }
        if (value.length > 5 && value.length > prev.length) {
          const parts = value.split('.')
          return parts
            .map((part, index) => {
              if (index < 2 && part.startsWith('0')) {
                return part.substring(1)
              }
              return part
            })
            .join('.')
        }

        return value
      },
      []
    )

    const deleteItem = useCallback((key: number) => {
      setDataSource(prevDataSource =>
        prevDataSource.filter(obj => obj.key !== key)
      )
      message.success('Položka byla odebrána.')
    }, [])

    const editNote = useCallback(
      (id: number, note?: string) => {
        setDataSource(prevDataSource => {
          const index = prevDataSource.findIndex(item => item.key === id)
          if (index === -1) return prevDataSource
          const newDataSource = [...prevDataSource]
          newDataSource[index] = { ...newDataSource[index], description: note }
          return newDataSource
        })
      },
      [setDataSource]
    )

    const handlePaste = useCallback(() => {
      setTimeout(() => {
        const firstnameValue = form.getFieldValue('firstname').trim()

        const match = firstnameValue.match(/^(\S+)?\s*(\S+)?\s*(\S+)?\s*(.*)?$/)
        if (match) {
          const [, firstname, lastname, birthDate, note] = match
          form.setFieldsValue({
            firstname,
            lastname,
            birthDate:
              birthDate && props.type === MonitoringInputType.CuzkName
                ? cuzkBirthDateNormalization(birthDate, '')
                : birthDate && props.type === MonitoringInputType.CeeName
                ? ceeBirthDateNormalization(birthDate, '')
                : '',
            note: note,
          })
          form.validateFields(['firstname'])
        }
      }, 0)
    }, [
      form,
      props.type,
      cuzkBirthDateNormalization,
      ceeBirthDateNormalization,
    ])

    const onFinish = useCallback(
      ({
        firstname,
        lastname,
        birthDate,
        note,
      }: NameBirthdateInputFormValues) => {
        const newKey = dataSource.length
          ? (dataSource[dataSource.length - 1]?.key || 0) + 1
          : 1
        const newItem = {
          id: firstname + lastname + birthDate,
          key: newKey,
          birthDate,
          description: note,
          firstname,
          lastname,
          validatedCode: 30,
        }
        setDataSource(prevDataSource => [...prevDataSource, newItem])
        setSortedInfo({
          order: 'ascend',
          columnKey: 'validatedCode',
        })
        form.resetFields()
        message.success('Záznam přidán')
      },
      [dataSource, form]
    )

    const columns = useMemo<ColumnsType<NameBirthdateTableItemConcept>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'key',
          key: 'key',
          sorter: {
            compare: (a: { key: number }, b: { key: number }) => a.key - b.key,
            multiple: 1,
          },
          defaultSortOrder: 'descend' as SortOrder,
        },
        {
          title: 'Poznámka',
          dataIndex: 'description',
          key: 'description',
          render: (text: string, record) => (
            <TabbleCellEditNoteModal
              onSave={editNote}
              initialNote={text}
              recordId={record.key}
            />
          ),
        },
        {
          title: 'Jméno',
          dataIndex: 'fullName',
          key: 'fullName',
          render: (_, record) =>
            (record.firstname ? record.firstname : '') +
            ' ' +
            (record.lastname ? record.lastname : ''),
        },
        {
          title: 'Datum narození',
          dataIndex: 'birthDate',
          key: 'birthDate',
        },
        {
          title: 'Kód validace',
          dataIndex: 'validatedCode',
          key: 'validatedCode',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Akce',
          dataIndex: 'actions',
          key: 'actions',
          render: (_, record) => (
            <Button type="link" danger onClick={() => deleteItem(record.key)}>
              Odebrat
            </Button>
          ),
        },
      ],
      [deleteItem, editNote]
    )

    return (
      <>
        <div className="flex space-y-2 lg:space-y-0 flex-wrap lg:flex-nowrap justify-end">
          <Form<NameBirthdateInputFormValues>
            onFinish={onFinish}
            validateTrigger={validateTrigger}
            onFieldsChange={handleDisableSubmit}
            layout="inline"
            size="large"
            className="w-full flex"
            form={form}
          >
            <Form.Item
              name="firstname"
              className="!flex-1 before:content-['*'] before:absolute before:-ml-2 before:-mt-2 before:text-red-400"
              rules={[
                {
                  validator: firstNameValidation,
                },
              ]}
            >
              <Input
                name="firstname"
                autoFocus
                placeholder="Křestní jméno"
                onPaste={handlePaste}
              />
            </Form.Item>

            <Form.Item
              name="lastname"
              className="!flex-1 before:content-['*'] before:absolute before:-ml-2 before:-mt-2 before:text-red-400"
              rules={[{ required: true, message: 'Zadejte příjmení' }]}
            >
              <Input placeholder="Příjmení" />
            </Form.Item>

            {props.type === MonitoringInputType.CuzkName && (
              <Form.Item
                name="birthDate"
                className="!flex-1 before:content-['*'] before:absolute before:-ml-2 before:-mt-2 before:text-red-400"
                normalize={cuzkBirthDateNormalization}
                rules={[
                  {
                    validator: birthDateValidation,
                  },
                  {
                    pattern: /^(\d{2})\.(\d{2})\.(\d{4})$/,
                    message: 'Zadejte datum narození ve formátu DD.MM.RRRR',
                  },
                  { required: true, message: 'Zadejte datum narození' },
                ]}
              >
                <Input
                  allowClear
                  maxLength={10}
                  placeholder="Datum narození (DD.MM.RRRR)"
                />
              </Form.Item>
            )}

            {props.type === MonitoringInputType.CeeName && (
              <Form.Item
                name="birthDate"
                className="!flex-1 before:content-['*'] before:absolute before:-ml-2 before:-mt-2 before:text-red-400"
                normalize={ceeBirthDateNormalization}
                rules={[
                  {
                    validator: birthDateValidation,
                  },
                  {
                    pattern: /^(\d{1,2})\.(\d{1,2})\.(\d{4})$/,
                    message: 'Zadejte datum narození ve formátu D.M.RRRR',
                  },
                  { required: true, message: 'Zadejte datum narození' },
                ]}
              >
                <Input
                  allowClear
                  maxLength={10}
                  placeholder="Datum narození (D.M.RRRR)"
                />
              </Form.Item>
            )}

            <Form.Item name="note" className="!flex-1">
              <Input allowClear maxLength={1000} placeholder="Poznámka" />
            </Form.Item>
            <Form.Item>
              <Tooltip
                title={
                  disabledSubmit
                    ? 'Pro zařazení subjektu ke kontrole musíte vyplnit všechna povinná pole. (Stisknutím Enteru simulujete kliknutí na tlačítko).'
                    : 'Přidat ke kontrole (Enter)'
                }
              >
                <Button
                  type="primary"
                  ghost
                  title="Přidat ke kontrole (Enter)"
                  icon={
                    <Icon
                      icon="fa6-solid:arrow-turn-down"
                      rotate={1}
                      className="mx-auto mt-1"
                      fontSize={12}
                    />
                  }
                  htmlType="submit"
                  disabled={disabledSubmit}
                />
              </Tooltip>
            </Form.Item>
          </Form>

          <div ref={props.buttonRef}>
            {props.type === MonitoringInputType.CuzkName ? (
              <CuzkMonitorAddSubjectModal dataSource={dataSource} />
            ) : (
              <CeeMonitorAddSubjectModal dataSource={dataSource} />
            )}
          </div>
        </div>
        <Fade show={dataSource.length > 0}>
          <Table
            className="mt-4"
            dataSource={[...dataSource]}
            columns={columns}
            bordered
            size="small"
            rowClassName="animate-fadeIn"
            locale={locale}
          />
        </Fade>
      </>
    )
  }
