import { Button, Collapse } from 'antd'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { InformaceOPlatciRozsirene } from '../../../common/vatPayerTypes'
import { checkVatPAyer } from '../../utils/checkVatPayer'
import { ColumnGridItem } from '../ColumnGridItem/ColumnGridItem'
import { DataBoxDetailAccountRow } from '../DataBoxDetailAccountRow/DataBoxDetailAccountRow'

export interface DataBoxDetailVatPayerProps {
  ico?: string
  businessname?: string
}

export const DataBoxDetailVatPayer: FunctionComponent<DataBoxDetailVatPayerProps> =
  props => {
    const { Panel } = Collapse
    const [open, setOpen] = useState(false)
    const handleOpen = useCallback(() => setOpen(!open), [open])
    const [vatPayer, setVatPayer] = useState<
      undefined | InformaceOPlatciRozsirene
    >()

    useEffect(() => {
      const fetchData = async () => {
        if (props?.ico) {
          const vatPayerData = await checkVatPAyer(
            props?.ico,
            props?.businessname || ''
          )
          if (vatPayerData) {
            setVatPayer(vatPayerData)
          } else {
            setVatPayer(undefined)
          }
        } else {
          setVatPayer(undefined)
        }
      }
      fetchData()
    }, [props?.ico, props?.businessname])

    if (!vatPayer) return null

    return (
      <>
        <ColumnGridItem
          label="DIČ"
          value={vatPayer?.attributes?.dic && 'CZ' + vatPayer?.attributes?.dic}
          conditional
        />
        <ColumnGridItem
          label="Plátce DPH"
          value={
            vatPayer?.attributes?.dic &&
            vatPayer?.attributes?.nespolehlivyPlatce === 'NE' ? (
              'Spolehlivý plátce'
            ) : (
              <span className="text-red-700">nespolehlivý plátce</span>
            )
          }
          conditional
        />
        <ColumnGridItem
          label="Číslo účtu"
          value={
            !!vatPayer?.zverejneneUcty?.ucet.length &&
            vatPayer?.zverejneneUcty?.ucet.length > 1 ? (
              <Collapse ghost size="small" onChange={handleOpen}>
                <Panel
                  showArrow={false}
                  className="-my-2.5 -mx-3.5"
                  header={
                    <div className="flex items-center">
                      <DataBoxDetailAccountRow
                        account={vatPayer?.zverejneneUcty?.ucet?.[0]}
                      />
                      <Button type="link" size="small">
                        {open ? 'Skrýt' : 'Zobrazit vše'}
                      </Button>
                    </div>
                  }
                  key="collapsible"
                >
                  {vatPayer?.zverejneneUcty?.ucet?.map((account, index) => {
                    if (index === 0)
                      return <div key="firstVisible" className="-mt-4" />
                    return (
                      <div key={index} className="flex mb-1">
                        <DataBoxDetailAccountRow account={account} />
                      </div>
                    )
                  })}
                </Panel>
              </Collapse>
            ) : (
              <DataBoxDetailAccountRow
                account={vatPayer?.zverejneneUcty?.ucet?.[0]}
              />
            )
          }
          conditional
        />
      </>
    )
  }
