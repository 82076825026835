import { Button, Table } from 'antd'
import { ColumnsType } from 'antd/es/table/interface'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Link } from 'react-router-dom'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { PROPERTY_TAB_KEYS } from '../../../common/propertyDetailTypes'
import {
  MonitFilter,
  usePriceEstimateTableQuery,
} from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import {
  numberSorter,
  stringSorter,
  utcDateSorter,
} from '../../utils/generalTableHelpers'
import {
  areaPriceEstimateTitlePrefix,
  buildingPriceEstimateTitlePrefix,
  tablePagination,
  unitPriceEstimateTitlePrefix,
} from '../../utils/layoutConst'
import { propertyDetailPath } from '../../utils/paths'
import { Day, Min } from '../DateFormat/DateFormat'
import { Money } from '../Money/Money'
import { PriceEstimatePdfButton } from '../PriceEstimatePdfButton/PriceEstimatePdfButton'
import { TableCellLabels } from '../TableCellLabels/TableCellLabels'
import { TableSummaryExport } from '../TableSummaryExport/TableSummaryExport'

export interface PriceEstimateTable {
  cenaM2Nab: string | number
  cenaM2Real: string | number
  cenaNab: string | number
  cenaReal: string | number
  createdAt: Date
  estimateToDate: Date
  id: string
  propertyId: string
  propertyType: number
  title: string
}

export interface PriceEstimateTableProps {
  monitFilter?: MonitFilter
}

const getType = (propertyType: number) => {
  return propertyType === 0
    ? PROPERTY_TYPES.AREA
    : propertyType === 1
    ? PROPERTY_TYPES.BUILDING
    : PROPERTY_TYPES.UNIT
}

const tableId = 'priceEstimate-table'

export const PriceEstimateTable: FunctionComponent<PriceEstimateTableProps> =
  props => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)

    const [dataSource, setDataSource] = useState<PriceEstimateTable[]>([])

    const [queryResult, refetch] = usePriceEstimateTableQuery({
      variables: { accountId: tenantId, filter: props.monitFilter },
    })

    useEffect(() => {
      if (queryResult.data?.allMonits?.nodes !== null) {
        setDataSource(
          queryResult.data?.allMonits?.nodes as PriceEstimateTable[]
        )
      }
    }, [queryResult.data?.allMonits?.nodes])

    const columns = useMemo<ColumnsType<PriceEstimateTable>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Vytvořeno',
          dataIndex: 'createdAt',
          defaultSortOrder: 'descend',
          sorter: utcDateSorter('createdAt'),
          render: createdAt => <Min>{createdAt}</Min>,
        },
        {
          title: 'Odhad k datu',
          dataIndex: 'estimateToDate',
          sorter: utcDateSorter('estimateToDate'),
          render: estimateToDate => <Day>{estimateToDate}</Day>,
        },
        {
          title: 'Štítek',
          dataIndex: 'labels',
          render: (labels, record) => (
            <TableCellLabels
              labels={labels}
              labelEntityType="monitId"
              recordId={record.id}
              refetch={refetch}
              modalTitle={record.title}
            />
          ),
        },
        {
          title: 'Název',
          dataIndex: 'title',
          sorter: stringSorter('title'),
          render: (title, record) => {
            const text = title
              ?.replace(buildingPriceEstimateTitlePrefix, '')
              .replace(areaPriceEstimateTitlePrefix, '')
              .replace(unitPriceEstimateTitlePrefix, '')

            return (
              <Link
                to={propertyDetailPath(
                  getType(record.propertyType),
                  record.propertyId,
                  PROPERTY_TAB_KEYS.PRICE_ESTIMATE
                )}
              >
                {text}
              </Link>
            )
          },
        },
        {
          title: 'Typ',
          dataIndex: 'propertyType',
          sorter: numberSorter('propertyType'),
          render: propertyType => getType(propertyType),
        },
        {
          title: (
            <span>
              Nabídková cena m<sup>2</sup>
            </span>
          ),
          dataIndex: 'cenaM2Nab',
          className: 'text-right',
          sorter: {
            compare: (a, b) => Number(a.cenaM2Nab) - Number(b.cenaM2Nab),
          },
          render: cenaM2Nab => (
            <Money amount={cenaM2Nab} symbol={'Kč'} decimalNumbers={0} />
          ),
        },
        {
          title: (
            <span>
              Prodejní cena m<sup>2</sup>
            </span>
          ),
          dataIndex: 'cenaM2Real',
          className: 'text-right',
          sorter: {
            compare: (a, b) => Number(a.cenaM2Real) - Number(b.cenaM2Real),
          },
          render: cenaM2Real => (
            <Money
              noData="---"
              amount={cenaM2Real}
              symbol={'Kč'}
              decimalNumbers={0}
            />
          ),
        },
        {
          title: 'Nabídková cena',
          dataIndex: 'cenaNab',
          className: 'text-right',
          sorter: {
            compare: (a, b) => Number(a.cenaNab) - Number(b.cenaNab),
          },
          render: cenaNab => (
            <Money amount={cenaNab} symbol={'Kč'} decimalNumbers={0} />
          ),
        },
        {
          title: 'Prodejní cena',
          dataIndex: 'cenaReal',
          className: 'text-right',
          sorter: {
            compare: (a, b) => Number(a.cenaReal) - Number(b.cenaReal),
          },
          render: cenaReal => (
            <Money
              noData="---"
              amount={cenaReal}
              symbol={'Kč'}
              decimalNumbers={0}
            />
          ),
        },
        {
          title: 'Akce',
          dataIndex: 'actions',
          className: 'w-80',
          render: (_, record) => (
            <div className="space-x-2 text-right">
              <Link
                to={propertyDetailPath(
                  getType(record.propertyType),
                  record.propertyId,
                  PROPERTY_TAB_KEYS.PRICE_ESTIMATE
                )}
              >
                <Button size="small" type="link">
                  zobrazit detail
                </Button>
              </Link>
              <PriceEstimatePdfButton
                buttonProps={{ ghost: true, size: 'small' }}
                id={record.id}
              />
            </div>
          ),
        },
      ],
      [refetch]
    )

    const summary = useCallback(
      () => (
        <TableSummaryExport
          total={dataSource?.length || 0}
          colSpan={9}
          url="monit/monitXlsx"
          fileName="Cenove-odhady"
          dataSource={dataSource || []}
        />
      ),
      [dataSource]
    )

    return (
      <Table
        id={tableId}
        rowKey="id"
        rowClassName="animate-fadeIn"
        size="small"
        dataSource={dataSource}
        columns={columns}
        loading={queryResult.fetching}
        summary={summary}
        pagination={tablePagination}
      />
    )
  }
