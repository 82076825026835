import { Tooltip } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { JednotkaType } from '../../../common/cuzkLvTypes'
import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { useAppSelector } from '../../../redux/hooks'
import { propertyDetailPath } from '../../utils/paths'

export interface CuzkLvHtmlJednotkaIdentFragmentProps {
  jednotkaIdent?: JednotkaType
  active?: boolean
  breakLine?: boolean
  noLabel?: boolean
}

export const CuzkLvHtmlJednotkaIdentFragment: FunctionComponent<CuzkLvHtmlJednotkaIdentFragmentProps> =
  props => {
    const jednotka = props.jednotkaIdent

    const nemovitosti = useAppSelector(state => state.lvDetail.nemovitosti)
    const findUnit = useCallback(
      (unit: string) => {
        if (props.active) {
          for (const jednotka of nemovitosti.jednotky) {
            if (jednotka.cisloJednotky === unit.trim()) {
              return (
                <Tooltip title="Zobrazit detail jednotky v novém okně">
                  <Link
                    target="_blank"
                    to={propertyDetailPath(PROPERTY_TYPES.UNIT, jednotka.id)}
                  >
                    {unit}
                  </Link>
                </Tooltip>
              )
            }
          }
        }
        return unit
      },
      [nemovitosti.jednotky, props.active]
    )

    return (
      <span className={props.breakLine ? 'breakLine' : ''}>
        {props.noLabel ? '' : 'Jednotka: '}
        {findUnit(
          `${jednotka?.cp_ident}${
            jednotka?.jed_ident ? '/' + jednotka?.jed_ident : ''
          }`
        )}
        {/*
        {jednotka?.id}
        {jednotka?.caobce}
        {jednotka?.cislo_tel}
        {jednotka?.ku_nazev}
        {jednotka?.tel_id}
        */}
        {', '}
      </span>
    )
  }
