import { ArcGISOptions } from 'mapbox-gl-arcgis-featureserver'
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react'
import { cloneElement } from 'react'
import { SourceProps, useMap } from 'react-map-gl'

import { useConst } from '../../utils/useConst'
import { CadastreMapFeatureService } from './util/CadastreMapFeatureService'

export type ArcGisSourceProps = ArcGISOptions &
  Required<Pick<SourceProps, 'id'>>

let sourceCounter = 0

export const CadastreMapArcGisSource: FunctionComponent<
  PropsWithChildren<ArcGisSourceProps>
> = ({ id, children, ...options }) => {
  const map = useMap().current?.getMap()
  const optionsRef = useRef(options)
  const [, setStyleLoaded] = useState(0)
  const serviceRef = useRef<CadastreMapFeatureService | null>(null)

  const uniqueId = useConst(id || `arcgis-source-${sourceCounter++}`)

  useEffect(() => {
    if (map) {
      const forceUpdate = () =>
        setTimeout(() => setStyleLoaded(version => version + 1), 0)
      map.on('styledata', forceUpdate)
      forceUpdate()

      return () => {
        map.off('styledata', forceUpdate)
        if (map.style && map.style._loaded && map.getSource(id)) {
          const allLayers = map.getStyle()?.layers
          if (allLayers) {
            for (const layer of allLayers) {
              if (layer.source === uniqueId) {
                map.removeLayer(layer.id)
              }
            }
          }
          serviceRef.current?.destroySource()
        }
      }
    }
    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  const source = map && map.style && map.getSource(uniqueId)
  if (source) {
    serviceRef.current?.enableRequests()
    if (options !== optionsRef.current) {
      if (options.where && options.where !== optionsRef.current.where) {
        serviceRef.current?.setWhere(options.where)
      } else if (!options.where && optionsRef.current.where) {
        serviceRef.current?.clearWhere()
      }
    }
  } else {
    if (map && map.style && map.style._loaded) {
      serviceRef.current = new CadastreMapFeatureService(uniqueId, map, options)
    } else {
      serviceRef.current = null
    }
  }
  optionsRef.current = options

  return children ? (
    <>
      {React.Children.map(
        children,
        child =>
          child &&
          cloneElement(child as React.ReactElement, { source: uniqueId })
      )}
    </>
  ) : (
    <></>
  )
}
