import { CalculatorOutlined } from '@ant-design/icons'
import { Alert } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { MonitEstimateResponse } from '../../../common/monitTypes'
import { EstimatePricesBox } from '../EstimatePricesBox/EstimatePricesBox'
import { Fade } from '../Fade/Fade'
import { PriceEstimateGraph } from '../PriceEstimateGraph/PriceEstimateGraph'
import { PriceEstimatePdfButton } from '../PriceEstimatePdfButton/PriceEstimatePdfButton'

export interface PriceEstimateResponseProps {
  estimateResponse: MonitEstimateResponse | null
  imgSrc: {
    map: string
    streetView: string
    mapLink: string
    streetViewLink: string
  }
}

export const PriceEstimateResponse: FunctionComponent<PriceEstimateResponseProps> =
  props => {
    const message = useMemo(
      () => (
        <div className="flex justify-between">
          <div className="text-lg">
            <CalculatorOutlined className="mr-2" />
            <span>Odhadní ceny</span>
          </div>
          <div>
            {props.estimateResponse?.id && (
              <PriceEstimatePdfButton
                imgSrc={props.imgSrc}
                id={props.estimateResponse.id}
              />
            )}
          </div>
        </div>
      ),
      [props.estimateResponse?.id, props.imgSrc]
    )
    const description = useMemo(
      () => (
        <>
          <EstimatePricesBox estimateResponse={props.estimateResponse} />
          <PriceEstimateGraph
            realPrice={!!props.estimateResponse?.cena_real}
            timeSeriesGraph={props.estimateResponse?.time_series_graph}
            className="mt-4"
          />
        </>
      ),
      [props.estimateResponse]
    )

    return (
      <Fade show={!!props.estimateResponse}>
        <Alert
          className="w-full"
          message={message}
          description={description}
          type="success"
        />
      </Fade>
    )
  }
