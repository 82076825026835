import { Select, SelectProps, Spin } from 'antd'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useSearchCadastralAreaQuery } from '../../../graphql/generated'

export interface SearchCadastralAreaProps extends SelectProps {
  setCadastralData?: (data: {
    katastralniUzemiKod?: string
    katastralniUzemiNazev?: string
    obecNazev?: string
  }) => void
  initialValue?: string
}

export const SearchCadastralArea: FunctionComponent<SearchCadastralAreaProps> =
  ({ setCadastralData, initialValue, ...selectProps }) => {
    const [query, setQuery] = useState(initialValue || '727') // 727 is staring value for Prague cadastre areas
    const [autoSelected, setAutoSelected] = useState(false)
    const [areaResponse] = useSearchCadastralAreaQuery({
      variables: { query },
    })
    const allAreas = areaResponse.data?.allKuAreas?.nodes

    const options = useMemo(
      () =>
        allAreas?.map(area => {
          return {
            label: (
              <div className="flex justify-between">
                <span>{area?.kuNazev}</span>
                <span>(obec {area?.obecNazev})</span>
              </div>
            ),
            value: area?.kuKod || '',
            obecNazev: area?.obecNazev || '',
            katastralniUzemiKod: area?.kuKod || '',
            katastralniUzemiNazev: area?.kuNazev || '',
          }
        }),
      [allAreas]
    )

    const fetching =
      !areaResponse.error && areaResponse.data && areaResponse.fetching

    const debounceFetcher = useCallback((query: string) => {
      setQuery(query)
    }, [])

    const onSelect = useCallback(
      (
        _: string,
        value: {
          obecNazev?: string
          katastralniUzemiNazev?: string
          katastralniUzemiKod?: string
        }
      ) => {
        setCadastralData && setCadastralData(value)
      },
      [setCadastralData]
    )

    useEffect(() => {
      if (
        initialValue &&
        setCadastralData &&
        allAreas?.length === 1 &&
        autoSelected === false
      ) {
        setCadastralData({
          obecNazev: allAreas[0]?.obecNazev || '',
          katastralniUzemiKod: allAreas[0]?.kuKod || '',
          katastralniUzemiNazev: allAreas[0]?.kuNazev || '',
        })
        setAutoSelected(true)
      }
    }, [allAreas, autoSelected, initialValue, setCadastralData])

    return (
      // TODO: fix any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <Select<any, any>
        showSearch
        allowClear
        autoFocus
        placeholder="Název či kód katastrálního území"
        filterOption={false}
        onSearch={debounceFetcher}
        onSelect={onSelect}
        notFoundContent={fetching ? <Spin size="small" /> : undefined}
        labelInValue
        className="w-full"
        {...selectProps}
        options={options}
      />
    )
  }
