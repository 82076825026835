import { useCallback } from 'react'

import { useCreateMonitoringCuzkRcMutation } from '../../graphql/generated'
import { useAppSelector } from '../../redux/hooks'
import { RcTableItemConcept } from '../components/RcInput/RcInput'
import { parseCzechDate } from './/parseCzechDate'

export const useCreateCuzkMonitoring = () => {
  const tenantId = useAppSelector(state => state.myProfile.tenantId)
  const userId = useAppSelector(state => state.myProfile.userId)
  const [, addMonitoredCuzkSubject] = useCreateMonitoringCuzkRcMutation()

  const createCuzkMonitoring = useCallback(
    async (
      item: RcTableItemConcept,
      checkFrequency: number | null,
      monitoringCeeId?: string
    ): Promise<string | null> => {
      const result = await addMonitoredCuzkSubject({
        input: {
          monitoringCuzkRc: {
            rc: item.rc,
            ico: item.ico,
            birthDate: parseCzechDate(item.birthDate || ''),
            firstname: item.firstname,
            lastname: item.lastname,
            validatedCode: item.validatedCode || 30,
            checkFrequency: checkFrequency,
            accountId: tenantId,
            accountUserId: userId,
            description: item.description || '',
            monitoringCeeId: monitoringCeeId,
          },
        },
      })

      return result.data?.createMonitoringCuzkRc?.monitoringCuzkRc?.id || null
    },
    [addMonitoredCuzkSubject, tenantId, userId]
  )

  return { createCuzkMonitoring }
}
