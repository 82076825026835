import { Alert, Spin, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { compareAsc } from 'date-fns'
import React, { FunctionComponent, useMemo } from 'react'
import { v4 } from 'uuid'

import { Ovp, Rizeni } from '../../../common/proceedingTypes'
import { useAppSelector } from '../../../redux/hooks'
import { tablePaginationHiding } from '../../utils/layoutConst'
import { getProceedingTitle } from '../../utils/proceedingDetailHelpers'
import {
  getTypeInfo,
  spanMap,
  typeInfo,
} from '../../utils/propertyRestrictionHelpers'
import { Day } from '../DateFormat/DateFormat'
import { ProceedingDetailModalLink } from '../ProceedingDetailModalLink/ProceedingDetailModalLink'
import { PropertyDetailFetchError } from '../PropertyDetailFetchError/PropertyDetailFetchError'

export interface PropertyDetailOwnershipRestrictionTableProps {
  showHistory: boolean
  setShowHistory: (showHistory: boolean) => void
}

export interface RestrictionItem extends Ovp {
  typ: number
  key: string
  rizeni?: Rizeni | null
}

const tableId = 'property-restrictions'

const rowClassName = (record: RestrictionItem) =>
  record.datumDo ? 'opacity-40' : ''

const titleRenderer = (title: string, record: RestrictionItem) =>
  record.rizeni ? (
    <span>
      {title}
      <ProceedingDetailModalLink className="ml-2" proceeding={record.rizeni} />
    </span>
  ) : (
    title
  )

export const PropertyDetailOwnershipRestrictionTable: FunctionComponent<PropertyDetailOwnershipRestrictionTableProps> =
  props => {
    const { error, data } = useAppSelector(state => state.property.timeline)
    const seals = useAppSelector(
      state => state.property.drm?.data?.plombyRizeni
    )
    const proceedings = useAppSelector(
      state => state.property.timeline.data?.rizeni
    )
    const restrictions = useAppSelector(
      state => state.property.timeline.data?.ovp
    )

    const processedRestrictions = useMemo(
      () =>
        (
          restrictions?.filter(
            restriction => props.showHistory || !restriction.datumDo
          ) || []
        ).map(restriction => ({
          ...restriction,
          key: v4(),
          typ: getTypeInfo(restriction.nazev || '').id,
          nazev: restriction.nazev.startsWith('Vlastnictví jednotek')
            ? 'Upozornění: Omezení a jiné zápisy vztahující se ke spoluvlastníkům se zobrazují u příslušných jednotek.'
            : restriction.nazev,
        })),
      [props.showHistory, restrictions]
    )

    const processedSeals = useMemo(() => {
      const data: RestrictionItem[] = []

      seals?.forEach(seal => {
        const proceedingMatch = proceedings?.find(
          proceeding => getProceedingTitle(proceeding) === seal
        )
        if (proceedingMatch) {
          data.push({
            datumOd: proceedingMatch.datumPrijeti,
            datumDo: proceedingMatch.datumUkonceni,
            nazev: 'Plomba řízení',
            osoba: proceedingMatch.ucastniciPZP?.join(', '),
            key: `seal-${seal}`,
            typ: 7,
            rizeni: proceedingMatch,
          })
        }
      })
      return data
    }, [seals, proceedings])

    const dataSource = useMemo(
      () => [...processedRestrictions, ...processedSeals],
      [processedRestrictions, processedSeals]
    )

    const columns: ColumnsType<RestrictionItem> = useMemo(
      () => [
        {
          title: 'Typ',
          dataIndex: 'typ',
          className: 'w-16',
          sorter: (a, b) => a.typ - b.typ,
          render: typ => spanMap[typ] || typeInfo['default'].span,
        },
        {
          title: 'Platné od',
          dataIndex: 'datumOd',
          defaultSortOrder: 'descend',
          className: 'w-24',
          sorter: (a, b) =>
            compareAsc(new Date(a.datumOd || 0), new Date(b.datumOd || 0)),
          render: datumOd => datumOd && <Day>{datumOd}</Day>,
        },
        {
          title: 'Platné do',
          dataIndex: 'datumDo',
          hidden: !props.showHistory,
          responsive: props.showHistory ? undefined : [],
          className: 'w-24',
          sorter: (a, b) =>
            compareAsc(new Date(a.datumDo || 0), new Date(b.datumDo || 0)),
          render: datumDo => datumDo && <Day>{datumDo}</Day>,
        },
        {
          title: 'Popis',
          dataIndex: 'nazev',
          className: 'whitespace-pre-line',
          sorter: (a, b) => a.nazev?.localeCompare(b.nazev || '') || 0,
          render: titleRenderer,
        },
        {
          title: 'Subjekt',
          dataIndex: 'osoba',
          className: 'whitespace-pre-line',
          sorter: (a, b) => a.osoba?.localeCompare(b.osoba || '') || 0,
        },
      ],
      [props.showHistory]
    )

    if (error)
      return (
        <PropertyDetailFetchError
          description="Je nám líto, ale nepodařilo se dohledat informace o omezeních vlastnických práv a jiných zápisech."
          message="Údaje se nepodařilo ověřit"
        />
      )

    if (data === null)
      return (
        <Spin
          className="!text-gray-400 w-full animate-pulse"
          tip="Omezení a jiné zápisy..."
        />
      )

    if (dataSource?.length === 0)
      return (
        <Alert
          message="Neevidujeme žádné zápisy ani omezení"
          type="success"
          showIcon
        />
      )

    return (
      <Table
        size="small"
        id={tableId}
        columns={columns}
        dataSource={dataSource}
        pagination={tablePaginationHiding}
        rowClassName={rowClassName}
      />
    )
  }
