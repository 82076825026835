import React, { FunctionComponent, useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useRecipientListByIdQuery } from '../../graphql/generated'
import { CadastrePostLetterCreate } from '../components/CadastrePostLetterCreate/CadastrePostLetterCreate'
import { PageLayout } from '../components/PageLayout/PageLayout'
import { PageSkeleton } from '../components/PageSkeleton/PageSkeleton'

export const CadastrePostLetter: FunctionComponent = () => {
  const { params } = useRouteMatch<{ id: string }>()

  const [queryResult] = useRecipientListByIdQuery({
    variables: { id: params.id },
  })

  const content = useMemo(() => {
    if (queryResult.error) return <PageSkeleton />
    if (
      queryResult.data?.recipientListById
        ?.recipientListRecipientsByRecipientListId?.nodes?.length
    )
      return (
        <CadastrePostLetterCreate
          recipients={queryResult.data.recipientListById}
          listId={params.id}
        />
      )
    return <PageSkeleton />
  }, [params.id, queryResult.data?.recipientListById, queryResult.error])

  return (
    <PageLayout className="flex grow" title="Obsah hromadného dopisu">
      {content}
    </PageLayout>
  )
}

export default CadastrePostLetter
