import { InfoCircleOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Divider,
  Form,
  FormInstance,
  FormProps,
  message,
  Segmented,
  Select,
} from 'antd'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import {
  CadastreMapInsolvencyFilterFormOutput,
  InsolvencyFilterFormValues,
} from '../../../../../common/cadastreMapInsolvencyTypes'
import {
  getInsolvencyFilterOutput,
  insolvencyBooleanOptions,
  insolvencyFormHelp,
  insolvencyHighlightOptions,
  insolvencyUnitUsageOptions,
} from '../../../../utils/cadastreMapInsolvencyHelpers'
import { formItemLayout } from '../../../../utils/layoutConst'
import { useToggleState } from '../../../../utils/useToggleState'
import { CadastreMapInsolvencyFilterFormRangeFragment } from '../../../CadastreMapInsolvencyFilterFormRangeFragment/CadastreMapInsolvencyFilterFormRangeFragment'
import { Fade } from '../../../Fade/Fade'
import { RuianLayer } from '../../types/RuianLayers'
import { RuianQuery } from '../../util/RuianQuery'

export interface CadastreMapInsolvencyFilterFormProps {
  form: FormInstance<InsolvencyFilterFormValues>
  onSubmit: (values: CadastreMapInsolvencyFilterFormOutput) => void
  onClose?: () => void
}

const initialValues: Partial<InsolvencyFilterFormValues> = {
  voluntaryLien: '',
  involuntaryLien: '',
  building: { enabled: true, usage: [] },
  housingUnit: { enabled: true, usage: [] },
  highlight: 0,
  parcel: {
    enabled: false,
    usage: [],
  },
}

export const CadastreMapInsolvencyFilterForm: FunctionComponent<CadastreMapInsolvencyFilterFormProps> =
  props => {
    const [showHelp, toggleShowHelp] = useToggleState()
    const [buildingUsageOptions, setBuildingUsageOptions] = useState<
      { value: string; label: string }[]
    >([])
    useEffect(() => {
      fetchLandUsageOptions()
      async function fetchLandUsageOptions() {
        try {
          const { domains } = await RuianQuery.queryDomains(
            RuianLayer.STAVEBNI_OBJEKT
          )
          const buildingUsageDomain = domains.find(
            domain => domain.name === 'CEM_ZPUSOB_VYUZITI_OBJEKTU'
          )
          setBuildingUsageOptions(
            buildingUsageDomain?.codedValues.map(codedValue => ({
              value: codedValue.code.toString(),
              label: codedValue.name,
            })) ?? []
          )
        } catch (error) {
          message.error(
            'Nepodařilo se načíst způsoby využití budov pro formulář.'
          )
          setBuildingUsageOptions([])
        }
      }
    }, [])

    const [parcelUsageOptions, setParcelUsageOptions] = useState<
      { value: string; label: string }[]
    >([])

    useEffect(() => {
      fetchLandUsageOptions()
      async function fetchLandUsageOptions() {
        try {
          const { domains } = await RuianQuery.queryDomains(RuianLayer.PARCELA)
          const landUsageDomain = domains.find(
            domain => domain.name === 'SC_ZP_VYUZITI_POZ'
          )
          setParcelUsageOptions(
            landUsageDomain?.codedValues.map(codedValue => ({
              value: codedValue.code.toString(),
              label: codedValue.name,
            })) ?? []
          )
        } catch (error) {
          message.error(
            'Nepodařilo se načíst způsoby využití pozemku pro formulář.'
          )
          setParcelUsageOptions([])
        }
      }
    }, [])

    const onFinish = useCallback<
      NonNullable<FormProps<InsolvencyFilterFormValues>['onFinish']>
    >(
      values => {
        const output = getInsolvencyFilterOutput(values)
        props.onSubmit(output as CadastreMapInsolvencyFilterFormOutput)
      },
      [props]
    )

    const buildingEnabled = Form.useWatch(['building', 'enabled'], props.form)
    const parcelEnabled = Form.useWatch(['parcel', 'enabled'], props.form)
    const housingUnitEnabled = Form.useWatch(
      ['housingUnit', 'enabled'],
      props.form
    )

    return (
      <Form<InsolvencyFilterFormValues>
        {...formItemLayout}
        className="mt-4"
        form={props.form}
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <Form.Item
          label="Zástavy smluvní"
          name="voluntaryLien"
          help={(showHelp && insolvencyFormHelp.voluntaryLien) || undefined}
        >
          <Segmented block options={insolvencyBooleanOptions} />
        </Form.Item>

        <Form.Item
          label="Zástavy nedobrovolné"
          name="involuntaryLien"
          help={(showHelp && insolvencyFormHelp.involuntaryLien) || undefined}
        >
          <Segmented block options={insolvencyBooleanOptions} />
        </Form.Item>

        <Form.Item
          label="Zvýraznit změny za poslední"
          name="highlight"
          help={(showHelp && insolvencyFormHelp.highlight) || undefined}
        >
          <Segmented block options={insolvencyHighlightOptions} />
        </Form.Item>

        <Form.Item
          label="Počet vlastníků"
          help={(showHelp && insolvencyFormHelp.ownership) || undefined}
          className="!mb-10"
        >
          <CadastreMapInsolvencyFilterFormRangeFragment type="ownership" />
        </Form.Item>

        <Form.Item
          name={['building', 'enabled']}
          label="Budovy"
          valuePropName="checked"
          help={(showHelp && insolvencyFormHelp.building) || undefined}
          className="!mb-1"
        >
          <Checkbox rootClassName="w-full">Zobrazit</Checkbox>
        </Form.Item>

        <Fade show={buildingEnabled}>
          <Form.Item
            name={['building', 'usage']}
            label="Způsoby využití budov"
            help={(showHelp && insolvencyFormHelp.buildingUse) || undefined}
          >
            <Select
              mode="multiple"
              options={buildingUsageOptions}
              placeholder="Způsoby využití"
            />
          </Form.Item>
        </Fade>

        <Form.Item
          name={['housingUnit', 'enabled']}
          label="Jednotky"
          valuePropName="checked"
          help={(showHelp && insolvencyFormHelp.housingUnit) || undefined}
          className="!mb-1"
        >
          <Checkbox rootClassName="w-full">Zobrazit</Checkbox>
        </Form.Item>

        <Fade show={housingUnitEnabled}>
          <Form.Item
            name={['housingUnit', 'usage']}
            label="Způsoby využití jednotek"
            help={(showHelp && insolvencyFormHelp.unitUse) || undefined}
          >
            <Select
              mode="multiple"
              options={insolvencyUnitUsageOptions}
              placeholder="Způsoby využití"
            />
          </Form.Item>
        </Fade>

        <Form.Item
          name={['parcel', 'enabled']}
          label="Parcely"
          valuePropName="checked"
          help={(showHelp && insolvencyFormHelp.parcel) || undefined}
          className="!mb-1"
        >
          <Checkbox rootClassName="w-full">Zobrazit</Checkbox>
        </Form.Item>

        <Fade show={parcelEnabled}>
          <Form.Item
            name={['parcel', 'usage']}
            label="Způsoby využití parcel"
            help={(showHelp && insolvencyFormHelp.parcelUse) || undefined}
          >
            <Select
              mode="multiple"
              options={parcelUsageOptions}
              placeholder="Způsoby využití"
            />
          </Form.Item>
        </Fade>

        <Divider />

        <Form.Item noStyle>
          <div className="flex justify-between">
            <Button
              icon={<InfoCircleOutlined />}
              type="link"
              onClick={toggleShowHelp}
            >
              {showHelp ? 'Skrýt nápovědu' : 'Zobrazit nápovědu'}
            </Button>

            <div className="space-x-2">
              <Button type="primary" ghost danger htmlType="reset">
                Resetovat filtr
              </Button>

              <Button onClick={props.onClose}>Zrušit</Button>

              <Button type="primary" htmlType="submit" onClick={props.onClose}>
                Zobrazit nemovitosti
              </Button>
            </div>
          </div>
        </Form.Item>
      </Form>
    )
  }
