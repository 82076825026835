import { Icon } from '@iconify/react'
import { Button, ConfigProvider, Spin, Tabs, Typography } from 'antd'
import { TitleProps } from 'antd/es/typography/Title'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { BASE_URL } from '../../../ini.json'
import { MarketplaceItem as MarketplaceItemInterface } from '../../common/marketplaceTypes'
import { useAppSelector } from '../../redux/hooks'
import { MarketplaceCreateButton } from '../components/MarketplaceCreateButton/MarketplaceCreateButton'
import { MARKETPLACE_OFFER_TYPES as TYPE } from '../components/MarketplaceFormDescriptionFragment/MarketplaceFormDescriptionFragment'
import { MarketplaceItem } from '../components/MarketplaceItem/MarketplaceItem'
import { PageLayout } from '../components/PageLayout/PageLayout'
import logoSrc from '../images/logo-bid-and-click-blue.png'

const theme = {
  token: {
    colorPrimary: '#5a74be',
    colorInfo: '#38404b',
    sizeStep: 4,
    borderRadius: 3,
    wireframe: false,
  },
}

const titleProps: TitleProps = {
  style: {
    marginBottom: 0,
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  level: 3,
}

const Marketplace: FunctionComponent = () => {
  const tenantId = useAppSelector(state => state.myProfile.tenantId)
  const token = useAppSelector(state => state.myProfile.token)
  const [loading, setLoading] = useState<boolean | undefined>()
  const [contactData, setContactData] = useState<
    { [key: string]: MarketplaceItemInterface[] | [] } | undefined
  >()

  const fetchData = useCallback(async () => {
    setLoading(true)

    fetch(`${BASE_URL}/api/marketplace/items/${token}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tenantId,
      }),
    })
      .then(response => response.json())
      .then((data: MarketplaceItemInterface[] | []) => {
        const result: {
          [key: string]: MarketplaceItemInterface[]
        } = {
          [TYPE.zapujcka]: [],
          [TYPE.vykup]: [],
          [TYPE.pohledavka]: [],
          myItems: [],
          bought: [],
          all: [],
        }

        for (const item of data) {
          if (item.owner) {
            result.myItems.push(item)
          } else if (item.state === 'ACTIVE') {
            result.all.push(item)

            if (item.offerType === TYPE.zapujcka) {
              result[TYPE.zapujcka].push(item)
            } else if (item.offerType === TYPE.vykup) {
              result[TYPE.vykup].push(item)
            } else if (item.offerType === TYPE.pohledavka) {
              result[TYPE.pohledavka].push(item)
            }
          }

          if (item.contact && item.contact.name) {
            result.bought.push(item)
          }
        }

        setContactData(result)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setContactData({
          [TYPE.zapujcka]: [],
          [TYPE.vykup]: [],
          [TYPE.pohledavka]: [],
          myItems: [],
          bought: [],
          all: [],
        })
      })
  }, [tenantId, token])

  useEffect(() => {
    if (contactData !== undefined) return
    fetchData()
  }, [contactData, fetchData])

  const tabContent = useCallback(
    (type: string) => {
      const noGrayscale = type === 'bought'
      if (contactData?.[type]?.length === 0)
        return (
          <div className="flex flex-col text-center space-y-4 bg-white p-8">
            <Icon
              width="90"
              className="text-gray-400 mx-auto"
              icon="healthicons:stock-out-outline"
            />
            <div className="text-lg">Žádné nabídky</div>
            <div className="text-gray-400">
              Je nám líto, ale momentálně se v Marketplace nenachází žádná
              požadovaná nabídka.
            </div>
          </div>
        )

      return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-12 grid-flow-dense">
          {contactData?.[type]?.map(item => (
            <MarketplaceItem
              key={item?.id}
              data={item}
              refetch={fetchData}
              noGrayscale={noGrayscale}
            />
          ))}
        </div>
      )
    },
    [contactData, fetchData]
  )

  const tabs = useMemo(
    () => [
      {
        label: `Všechny nabídky (${contactData?.all?.length})`,
        key: 'all',
        children: loading ? (
          <div className="flex flex-col space-y-4 items-center justify-center my-8 text-gray-400">
            <span>Načítají se nabídky z Marketplace...</span>
            <Spin />
          </div>
        ) : (
          tabContent('all')
        ),
      },
      {
        label: `Zápůjčky (${contactData?.[TYPE.zapujcka]?.length})`,
        key: 'zapujcky',
        children: tabContent(TYPE.zapujcka),
        disabled: !contactData?.[TYPE.zapujcka]?.length,
      },
      {
        label: `Výkupy (${contactData?.[TYPE.vykup]?.length})`,
        key: 'vykupy',
        children: tabContent(TYPE.vykup),
        disabled: !contactData?.[TYPE.vykup]?.length,
      },
      {
        label: `Pohledávky (${contactData?.[TYPE.pohledavka]?.length})`,
        key: 'pohledavky',
        children: tabContent(TYPE.pohledavka),
        disabled: !contactData?.[TYPE.pohledavka]?.length,
      },
      {
        label: <div className="cursor-default mx-8"> | </div>,
        key: 'divider',
        disabled: true,
      },
      {
        label: (
          <Button disabled={!contactData?.bought?.length} type="primary" ghost>
            Zakoupené nabídky ({contactData?.bought?.length})
          </Button>
        ),
        key: 'bought',
        children: tabContent('bought'),
        disabled: !contactData?.bought?.length,
      },
      {
        label: (
          <Button disabled={!contactData?.myItems?.length} type="primary" ghost>
            Moje nabídky ({contactData?.myItems?.length})
          </Button>
        ),
        key: 'myItems',
        children: tabContent('myItems'),
        disabled: !contactData?.myItems?.length,
      },
    ],
    [contactData, loading, tabContent]
  )
  return (
    <>
      <ConfigProvider theme={theme}>
        <PageLayout
          className="!bg-transparent"
          title={
            <Typography.Title {...titleProps}>
              <img className="inline-block h-7 mr-2" src={logoSrc} alt="logo" />
              <span>Marketplace</span>
            </Typography.Title>
          }
          extra={<MarketplaceCreateButton refetch={fetchData} />}
        >
          <Tabs defaultActiveKey="all" items={tabs} />
        </PageLayout>
      </ConfigProvider>
    </>
  )
}

export default Marketplace
