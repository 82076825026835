import { Modal } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { ForclosureMonitoringFilterOnSubmit } from '../../../common/foreclosureMonitoringTypes'
import {
  CadastralCheckedValues,
  CadastralOffice,
} from '../../utils/cuzkHelpers'
import { useToggleState } from '../../utils/useToggleState'
import { CadastralWorkplaceSelection } from '../CadastralWorkplaceSelection/CadastralWorkplaceSelection'
import { PropertyMonitoringFilterSelectionButton } from '../PropertyMonitoringFilterSelectionButton/PropertyMonitoringFilterSelectionButton'

export interface PropertyMonitoringFilterCadastralModalButtonProps {
  checkboxData: CadastralOffice[]
  selectedValues: CadastralCheckedValues
  selectedCount?: number
  onSubmit: ForclosureMonitoringFilterOnSubmit
}

export const PropertyMonitoringFilterCadastralModalButton: FunctionComponent<PropertyMonitoringFilterCadastralModalButtonProps> =
  props => {
    const [open, toggleOpen] = useToggleState(false)

    const handleOnSubmit = useCallback(
      (selected: number[] | null) => {
        props.onSubmit({ pracoviste: selected })
      },
      [props]
    )

    const handleReset = useCallback(() => {
      handleOnSubmit(null)
    }, [handleOnSubmit])

    const totalCount = useMemo(
      () =>
        props.checkboxData.reduce(
          (total, office) => total + office.pracoviste.length,
          0
        ),
      [props.checkboxData]
    )
    return (
      <div>
        <PropertyMonitoringFilterSelectionButton
          toggleOpen={toggleOpen}
          handleReset={handleReset}
          selectedCount={props.selectedCount || totalCount}
          totalCount={totalCount}
        />
        <Modal
          title="Výběr katastrálních pracovišť"
          open={open}
          onCancel={toggleOpen}
          footer={null}
          width={1600}
        >
          <CadastralWorkplaceSelection
            checkboxData={props.checkboxData}
            selectedValues={props.selectedValues}
            toggleModalVisibility={toggleOpen}
            onSubmit={handleOnSubmit}
          />
        </Modal>
      </div>
    )
  }
