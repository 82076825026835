import React, { FunctionComponent } from 'react'

import { ZverejnenyUcet } from '../../../common/vatPayerTypes'
import { Day } from '../DateFormat/DateFormat'

export interface DataBoxDetailAccountRowProps {
  account?: ZverejnenyUcet
}

export const DataBoxDetailAccountRow: FunctionComponent<DataBoxDetailAccountRowProps> =
  props => {
    if (!props.account) return null
    return (
      <>
        {props.account?.standardniUcet?.attributes ? (
          <div className="w-56">
            {props.account?.standardniUcet?.attributes?.predcisli ||
              '' +
                ' ' +
                props.account?.standardniUcet?.attributes?.cislo +
                '/' +
                props.account?.standardniUcet?.attributes?.kodBanky}
          </div>
        ) : (
          <div className="w-56">
            {props.account?.nestandardniUcet?.attributes?.cislo}
          </div>
        )}
        <div className="ml-4 text-gray-400 font-normal w-28">
          od: <Day>{props.account?.attributes?.datumZverejneni}</Day>
        </div>
      </>
    )
  }
