import { WarningOutlined } from '@ant-design/icons'
import { Alert } from 'antd'
import React, { FunctionComponent } from 'react'

import { useDeclension } from '../../utils/useDeclension'
import { Fade } from '../Fade/Fade'

export interface PostServiceImportRecipientAlertProps {
  errorCount: number
}

export const PostServiceImportRecipientAlert: FunctionComponent<PostServiceImportRecipientAlertProps> =
  props => {
    const decline = useDeclension()

    return (
      <Fade show={!!props.errorCount}>
        <Alert
          message={
            <>
              <WarningOutlined className="text-red-500" /> Seznam adresátů nelze
              importovat. {props.errorCount}{' '}
              {decline(props.errorCount, 'record')} obsahuje chybu. Opravte
              prosím vstupní data a nahrajte csv soubor znova.
            </>
          }
          type="error"
        />
      </Fade>
    )
  }
