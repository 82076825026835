import { Divider } from 'antd'
import React, { FunctionComponent, useState } from 'react'

import { PostServiceAddRecipientFormValues } from '../../../common/postServiceTypes'
import { useToggleState } from '../../utils/useToggleState'
import { Fade } from '../Fade/Fade'
import { PostServiceAddRecipientForm } from '../PostServiceAddRecipientForm/PostServiceAddRecipientForm'
import { PostServiceCreateRecipientListModalButton } from '../PostServiceCreateRecipientListModalButton/PostServiceCreateRecipientListModalButton'
import { PostServiceRecipientListSelect } from '../PostServiceRecipientListSelect/PostServiceRecipientListSelect'

export interface AddRecipientModalButtonContentProps {
  message: React.ReactNode
  toggleOpen: () => void
  initialValues?: Partial<PostServiceAddRecipientFormValues & { id: string }>
  refetch?: () => void
}

const buttonProps = { type: 'link' as const }

export const AddRecipientModalButtonContent: FunctionComponent<AddRecipientModalButtonContentProps> =
  props => {
    const [showHelp, toggleHelp] = useToggleState()
    const [listId, setListId] = useState('')

    return (
      <>
        <div
          className={
            listId ? 'opacity-50 hover:opacity-100 mb-4 transition-opacity' : ''
          }
        >
          <PostServiceRecipientListSelect showEmpty handleChange={setListId} />
          <div className="text-right">
            <PostServiceCreateRecipientListModalButton
              buttonProps={buttonProps}
            />
          </div>
        </div>

        <Fade show={!!listId}>
          <Divider plain>
            <span className="text-gray-400">Zkontrolujte údaje adresáta</span>
          </Divider>

          {props.message}

          <PostServiceAddRecipientForm
            listId={listId}
            listName=""
            hideModal={props.toggleOpen}
            handleHelpToggle={toggleHelp}
            showHelp={showHelp}
            initialValues={props.initialValues}
            hideAddAnother
            hideAresSearch
            hideAddressSearch
            reexecuteQuery={props.refetch}
          />
        </Fade>
      </>
    )
  }
