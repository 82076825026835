import Button from 'antd/es/button'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { CeeMonitorTable } from '../components/CeeMonitorTable/CeeMonitorTable'
import { PageLayout } from '../components/PageLayout/PageLayout'
import { ceeMonitorCreatePath } from '../utils/paths'

const newMonitoring = (
  <Link to={ceeMonitorCreatePath('monitoring')}>
    <Button type="primary">Přidat subjekt do hlídače</Button>
  </Link>
)

const monitoringCeeFilter = {
  monitoring: { equalTo: true },
}

export const CeeMonitor: FunctionComponent = () => {
  return (
    <PageLayout
      title="Hlídání subjektů v Centrální evidenci exekucí"
      extra={newMonitoring}
    >
      <CeeMonitorTable monitoring monitoringCeeFilter={monitoringCeeFilter} />
    </PageLayout>
  )
}

export default CeeMonitor
