import axios from 'axios'
import { useCallback } from 'react'

import { PROPERTY_TYPES } from '../../common/drmTypes'
import { PropertyDataTypes } from '../../redux/fetchAction'
import { useAppSelector } from '../../redux/hooks'
import { requestPropertyRuianPath } from './paths'
import { useDispatchFetch } from './useDispatchFetch'

export const useFetchPropertyRuian = () => {
  const token = useAppSelector(state => state.myProfile.token)
  const { dispatchError, dispatchFetching, dispatchSuccess } =
    useDispatchFetch()

  const fetchRuian = useCallback(
    async (propertyId: string, propertyType?: PROPERTY_TYPES) => {
      if (!token || !propertyId || !propertyType)
        return dispatchError(PropertyDataTypes.RUIAN)

      const requestPath = requestPropertyRuianPath(
        propertyType,
        token,
        propertyId
      )

      dispatchFetching(PropertyDataTypes.RUIAN)

      try {
        const response = await axios.get(requestPath)
        const data =
          propertyType === PROPERTY_TYPES.BUILDING
            ? response.data?.[0] || null
            : response.data

        if (!data) throw new Error()

        dispatchSuccess(PropertyDataTypes.RUIAN, {
          ...data,
          type: propertyType,
        })
      } catch (error) {
        dispatchError(PropertyDataTypes.RUIAN)
      }
    },
    [dispatchError, dispatchFetching, dispatchSuccess, token]
  )

  return fetchRuian
}
