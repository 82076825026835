import { useMemo } from 'react'

import { PROPERTY_TYPES } from '../../common/drmTypes'
import {
  LabelConnection,
  TransformedPropertyLabelData,
} from '../../common/propertyDetailTypes'
import {
  PropertyDetailsLabelsQuery,
  usePropertyDetailsLabelsQuery,
} from '../../graphql/generated'
import { useAppSelector } from '../../redux/hooks'

const transformData = (
  data: PropertyDetailsLabelsQuery | undefined
): TransformedPropertyLabelData => {
  if (!data?.allPropertyLabels?.nodes) {
    return {}
  }

  return data.allPropertyLabels.nodes.reduce((acc, node) => {
    if (node) {
      acc[node.propertyId] = {
        labelConnectionsByPropertyLabelId: {
          totalCount: node?.labelConnectionsByPropertyLabelId?.totalCount || 0,
          nodes: (node?.labelConnectionsByPropertyLabelId?.nodes ||
            []) as LabelConnection[],
        },
        propertyData: {
          propertyLabelId: node.id,
          propertyId: node.propertyId,
          propertyLv: node.propertyLv,
          propertyName: node.propertyName,
          propertyType: node.propertyType as PROPERTY_TYPES,
          propertyCadastralName: node.propertyCadastralName,
          propertyCadastralCode: node.propertyCadastralCode,
          createdAt: node.createdAt,
        },
      }
    }
    return acc
  }, {} as TransformedPropertyLabelData)
}

export const usePropertiesLabels = (
  propertyIds: string[],
  propertyType: PROPERTY_TYPES
) => {
  const accountId = useAppSelector(state => state.myProfile.tenantId)

  const [queryResult, refetch] = usePropertyDetailsLabelsQuery({
    pause: !propertyIds.length,
    requestPolicy: 'cache-first',
    variables: {
      accountId,
      propertyIds,
      propertyType,
    },
  })

  const propertyLabels = useMemo(
    () => (queryResult.data ? transformData(queryResult.data) : null),
    [queryResult.data]
  )

  return {
    propertyLabels,
    refetch,
    loading: queryResult.fetching,
    error: queryResult.error,
  }
}
