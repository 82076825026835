import { Tooltip } from 'antd'
import React, { FunctionComponent } from 'react'

import { tooltipHelpUnderline } from '../../utils/layoutConst'
import { OneLineHelp } from '../OneLineHelp/OneLineHelp'

export interface MapTooltipProps {
  query?: string
  title?: string | React.ReactNode
  children?: React.ReactNode
  risy?: string
}

export const MapTooltip: FunctionComponent<MapTooltipProps> = props => {
  if (!props.query?.trim()) return null

  return (
    <Tooltip
      trigger="click"
      overlayClassName="min-w-min"
      title={
        <div className="p-1">
          <div className="flex text-gray-400">
            {props.title || (
              <OneLineHelp className="w-52">
                {`V mapě se nezobrazí přesná adresa, ale bude vyhledána správní
                  jednotka ${props.query}.`}
              </OneLineHelp>
            )}
          </div>
          <div className="space-x-2 whitespace-nowrap py-2 mt-2 border-t border-dashed border-gray-500">
            <span>Vyhledat v</span>
            <a
              target="_blank"
              href={`https://mapy.cz/zakladni?q=${props.query.replace(
                /\//g,
                '+'
              )}`}
              rel="noreferrer"
            >
              mapy.cz
            </a>
            <span className="text-gray-500">|</span>
            <a
              target="_blank"
              href={`https://www.google.com/maps/place/${props.query.replace(
                /\//g,
                '+'
              )}`}
              rel="noreferrer"
            >
              google maps
            </a>
            {props.risy && (
              <>
                <span className="text-gray-500">|</span>
                <a
                  target="_blank"
                  href={`https://www.risy.cz/cs/vyhledavace/uzemi/${props.risy}`}
                  rel="noreferrer"
                >
                  RISY.cz
                </a>
              </>
            )}
          </div>
        </div>
      }
    >
      <span className={tooltipHelpUnderline}>{props.children}</span>
    </Tooltip>
  )
}
