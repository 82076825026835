export const parseCzechDate = (input: string): Date | null => {
  const parts = input.split('.')
  if (parts.length !== 3) {
    return null
  }

  const day = parseInt(parts[0], 10)
  const month = parseInt(parts[1], 10) - 1
  const year = parseInt(parts[2], 10)

  // Vytváříme datum s časem 12:00, aby jsme zabránili problémům s časovými zónami
  const parsedDate = new Date(Date.UTC(year, month, day, 12, 0, 0))

  if (parsedDate.getUTCDate() === day) {
    return parsedDate
  } else {
    return null
  }
}
