import { Icon } from '@iconify/react'
import { Modal } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'
import { PieChart } from 'react-minimal-pie-chart'

import { areaChartLabel, areaChartProps } from '../../../common/chartHelpers'
import { Parcela } from '../../../common/lvTypes'
import { useAppSelector } from '../../../redux/hooks'
import { PieChartColorPalette } from '../../utils/collorPallete'
import { useDeclension } from '../../utils/useDeclension'
import { useToggleState } from '../../utils/useToggleState'
import { Money } from '../Money/Money'

export interface PropertyDetailAreaChartModalButtonProps {
  parcely: Parcela[]
  geometryIncluded?: boolean
}

const okButtonProps = { style: { display: 'none' } }

export const PropertyDetailAreaChartModalButton: FunctionComponent<PropertyDetailAreaChartModalButtonProps> =
  props => {
    const decline = useDeclension()

    const [isModalOpen, toggleModalOpen] = useToggleState(false)

    const areasGeometry = useAppSelector(
      state => state.property.lvGeometry.data?.parcely
    )

    const dataSource = useMemo(() => {
      if (props.geometryIncluded) return props.parcely
      if (!areasGeometry || !props.parcely?.length) return []
      const lvParcelyMap = new Map(
        areasGeometry.map(parcela => [parcela.id, parcela])
      )

      return props.parcely.map(parcela => {
        const lvParcela = lvParcelyMap.get(parcela.id)
        return {
          ...parcela,
          vymera: lvParcela?.vymera ?? parcela.vymera,
        }
      })
    }, [areasGeometry, props.geometryIncluded, props.parcely])

    const prepareAreaData = useMemo(() => {
      let totalArea = 0
      const totalCount = dataSource?.length || 0
      const areaType: { [key: string]: number } = {}

      dataSource.forEach(parcela => {
        const area = Number(parcela?.vymera || '0')
        totalArea += area
        if (parcela?.druhPozemku) {
          if (parcela.druhPozemku in areaType) {
            areaType[parcela.druhPozemku] += area
          } else {
            areaType[parcela.druhPozemku] = area
          }
        }
      })
      const modalTitle = (
        <div>
          Celkem {decline(totalCount, 'area', true)} o výměře{' '}
          <Money amount={totalArea} decimalNumbers={0} symbol="m²" />
        </div>
      )
      const pieChartData = Object.keys(areaType).map((key, index) => ({
        title: key,
        value: areaType[key],
        color: PieChartColorPalette[index],
      }))

      return { modalTitle, pieChartData }
    }, [dataSource, decline])

    const pieChartProp = useMemo(
      () => ({
        ...areaChartProps,
        data: prepareAreaData.pieChartData,
        label: areaChartLabel,
      }),
      [prepareAreaData.pieChartData]
    )

    if (dataSource.length < 2) {
      return null
    }
    return (
      <>
        <span
          className="text-primary cursor-pointer flex items-center"
          onClick={toggleModalOpen}
        >
          <Icon icon="ion:bar-chart" width="12" />
          <span className="ml-2">Parcely v grafu</span>
        </span>

        <Modal
          title={prepareAreaData.modalTitle}
          open={isModalOpen}
          okButtonProps={okButtonProps}
          cancelText="Zavřít"
          onCancel={toggleModalOpen}
          width={600}
          closable
        >
          <div className="border-y">
            <PieChart {...pieChartProp} />
          </div>
        </Modal>
      </>
    )
  }
