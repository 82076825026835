import { Select } from 'antd'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { WatchdogPropertyFilter as WatchdogPropertyFilterType } from '../../../common/watchdogTypes'
import { useAppSelector } from '../../../redux/hooks'
import { watchdogPropertyPath } from '../../utils/paths'
import {
  watchdogBaseChannel,
  watchdogFilterDefaultValues,
  watchdogFilterTypeOptions,
} from '../../utils/watchdogHelpers'
import { PropertyMonitoringFilterItem } from '../PropertyMonitoringFilterItem/PropertyMonitoringFilterItem'

export interface WatchdogPropertyFilterProps {
  setFilter: React.Dispatch<React.SetStateAction<WatchdogPropertyFilterType>>
  filter: WatchdogPropertyFilterType
}

export const WatchdogPropertyFilter: FunctionComponent<WatchdogPropertyFilterProps> =
  props => {
    const channelData = useAppSelector(state => state.watchdog.channel)
    const history = useHistory()

    const {
      params: { channelId },
    } = useRouteMatch<{ channelId?: string }>()

    useEffect(() => {
      if (channelId) {
        props.setFilter(prev => ({
          ...prev,
          channelId,
        }))

        history.replace(watchdogPropertyPath())
      }
    }, [channelId, props.setFilter, history, props])

    const options = useMemo(() => {
      const fetched =
        channelData.data?.map(item => ({
          value: item.id,
          label: item.nazev,
        })) || []

      return [
        {
          value: watchdogBaseChannel.allChannelId,
          label: '- Zobrazit všechny kanály -',
        },
        ...fetched,
        {
          value: watchdogBaseChannel.noChannelId,
          label: '- Bez přiřazeného kanálu -',
        },
      ]
    }, [channelData.data])

    const isFiltered = useMemo(() => {
      const propertyType = props.filter.propertyType?.length !== 3
      const channelId =
        props.filter.channelId &&
        props.filter.channelId !== watchdogBaseChannel.allChannelId

      return {
        propertyType: propertyType
          ? 'border border-primary rounded'
          : undefined,
        channelId: channelId ? 'border border-primary rounded' : undefined,
      }
    }, [props.filter])

    const handleChannelChange = useCallback(
      (value?: string) => {
        props.setFilter(prev => ({
          ...prev,
          channelId: value || watchdogBaseChannel.allChannelId,
        }))
      },
      [props]
    )

    const handleTypeChange = useCallback(
      (value: PROPERTY_TYPES[]) => {
        props.setFilter(prev => ({ ...prev, propertyType: value }))
      },
      [props]
    )

    return (
      <div
        id="monitoringFilter"
        className="flex gap-x-8 gap-y-4 mb-8 flex-wrap"
      >
        <div className="grid grid-cols-2 w-full md:flex md:w-auto gap-x-8 gap-y-4">
          <PropertyMonitoringFilterItem
            className="min-w-[260px]"
            label="Typ nemovitosti"
          >
            <Select
              mode="multiple"
              className={isFiltered.propertyType}
              defaultValue={watchdogFilterDefaultValues.propertyType}
              options={watchdogFilterTypeOptions}
              onChange={handleTypeChange}
              placeholder="Vyberte typ nemovitosti"
            />
          </PropertyMonitoringFilterItem>

          <PropertyMonitoringFilterItem
            className="min-w-[230px]"
            label="Kanál hlídače"
          >
            <Select
              className={isFiltered.channelId}
              placeholder="Zobrazit všechny kanály"
              loading={channelData.fetching}
              disabled={channelData.error}
              defaultValue={channelId}
              allowClear
              onClear={handleChannelChange}
              options={options}
              onChange={handleChannelChange}
            />
          </PropertyMonitoringFilterItem>
        </div>
      </div>
    )
  }
