/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { FetchStatus } from '../../redux/fetchAction'
import { AppDispatch } from '../../redux/store'

export function useDispatchFetch() {
  const dispatch = useDispatch<AppDispatch>()

  const dispatchFetchStatus = useCallback(
    (name: string, status: FetchStatus, payload?: any) => {
      dispatch({ type: name + '/' + status, payload })
    },
    [dispatch]
  )

  return useMemo(
    () => ({
      dispatchError: (name: string) =>
        dispatchFetchStatus(name, FetchStatus.ERROR),
      dispatchFetching: (name: string) =>
        dispatchFetchStatus(name, FetchStatus.FETCHING),
      dispatchSuccess: (name: string, payload?: any) =>
        dispatchFetchStatus(name, FetchStatus.SUCCESS, payload),
    }),
    [dispatchFetchStatus]
  )
}
