import { Alert } from 'antd'
import React, { FunctionComponent, memo } from 'react'

export interface AddRecipientModalButtonDataAlertProps {
  subjectName?: string | boolean
  fullName?: string
  fullAddress?: string
  addressId?: string | number
}

export const AddRecipientModalButtonDataAlert: FunctionComponent<AddRecipientModalButtonDataAlertProps> =
  memo(props => {
    return !!props?.subjectName && !!props?.addressId ? null : (
      <Alert
        description={
          <div>
            <div>
              Je nám líto, ale nepodařilo se nám rozpoznat{' '}
              {!props?.subjectName && 'jméno'}
              {!props?.subjectName && !props?.addressId && ' ani '}
              {!props?.addressId && 'adresu'} subjektu:
            </div>
            <div className="font-semibold">
              {!props?.subjectName && props?.fullName}
              {!props?.subjectName && !props?.addressId && ', '}
              {!props?.addressId && props?.fullAddress}
            </div>
          </div>
        }
        type="warning"
        showIcon
      />
    )
  })
