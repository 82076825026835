import React, { FunctionComponent } from 'react'
import { Provider } from 'react-redux'

import { CuzkLvXmlType } from '../../../common/cuzkLvTypes'
import { safeFilter, safeMap } from '../../../common/pdfHelpers'
import store from '../../../redux/store'
import { CuzkLvHtmlFooterFragment } from '../CuzkLvHtmlFooterFragment/CuzkLvHtmlFooterFragment'
import { CuzkLvHtmlHeaderFragment } from '../CuzkLvHtmlHeaderFragment/CuzkLvHtmlHeaderFragment'
import { CuzkLvHtmlSekceD1Fragment } from '../CuzkLvHtmlSekceD1Fragment/CuzkLvHtmlSekceD1Fragment'
import { CuzkLvHtmlSekceEFragment } from '../CuzkLvHtmlSekceEFragment/CuzkLvHtmlSekceEFragment'
import { CuzkLvHtmlSekceFFragment } from '../CuzkLvHtmlSekceFFragment/CuzkLvHtmlSekceFFragment'
import { CuzkLvHtmlSekceJednotkyFragment } from '../CuzkLvHtmlSekceJednotkyFragment/CuzkLvHtmlSekceJednotkyFragment'
import { CuzkLvHtmlSekceListFragment } from '../CuzkLvHtmlSekceListFragment/CuzkLvHtmlSekceListFragment'
import { CuzkLvHtmlSekceParcelyZEFragment } from '../CuzkLvHtmlSekceParcelyZEFragment/CuzkLvHtmlSekceParcelyZEFragment'
import { CuzkLvHtmlSekcePozemkyFragment } from '../CuzkLvHtmlSekcePozemkyFragment/CuzkLvHtmlSekcePozemkyFragment'
import { CuzkLvHtmlSekcePravaStavbyFragment } from '../CuzkLvHtmlSekcePravaStavbyFragment/CuzkLvHtmlSekcePravaStavbyFragment'
import { CuzkLvHtmlSekceStavbyFragment } from '../CuzkLvHtmlSekceStavbyFragment/CuzkLvHtmlSekceStavbyFragment'
import { CuzkLvHtmlSekceVlastniciFragment } from '../CuzkLvHtmlSekceVlastniciFragment/CuzkLvHtmlSekceVlastniciFragment'
import { LvDetailReduxSetter } from '../LvDetailReduxSetter/LvDetailReduxSetter'

export interface CuzkLvHtmlBodyProps {
  data: CuzkLvXmlType['VypisZKatastruNemovitosti']
  active?: boolean
  isPdf?: boolean
}

export const CuzkLvHtmlBody: FunctionComponent<CuzkLvHtmlBodyProps> = props => {
  const lvData = props.data

  return (
    <div className="lvBody">
      <Provider store={store}>
        {safeMap(lvData?.LIST_TEXT?.TEXTY, (TEXTY, index) => (
          <div key={index}>
            {props.active && (
              <>
                <LvDetailReduxSetter
                  lv={TEXTY?.ZAHLAVI_LV?.CISLO_LV}
                  katastrUzemiKod={TEXTY?.ZAHLAVI_LV?.KATASTR_UZEMI?.kod}
                />
              </>
            )}
            {!props.isPdf && (
              <CuzkLvHtmlHeaderFragment
                active={props.active}
                platnost={lvData?.PLATNOST}
                zahlaviLv={TEXTY?.ZAHLAVI_LV}
              />
            )}

            <hr className="mt-10 mb-0" />
            <CuzkLvHtmlSekceVlastniciFragment
              active={props.active}
              vlastniciJiniOpravneni={TEXTY?.VLASTNICI_JINI_OPRAVNENI}
            />
            <hr className="mt-20 mb-0" />
            <hr className="mt-10 mb-0" />
            <div className="row">
              B&nbsp;<i>Nemovitosti</i>
            </div>
            <CuzkLvHtmlSekcePozemkyFragment
              active={props.active}
              pozemky={TEXTY?.POZEMKY}
            />
            <CuzkLvHtmlSekceStavbyFragment
              active={props.active}
              stavby={TEXTY?.STAVBY}
            />
            <CuzkLvHtmlSekceJednotkyFragment
              active={props.active}
              jednotky={TEXTY?.JEDNOTKY}
            />
            <CuzkLvHtmlSekcePravaStavbyFragment
              pravaStavby={TEXTY?.PRAVA_STAVBY}
            />
            <CuzkLvHtmlSekceParcelyZEFragment
              active={props.active}
              parcely={TEXTY?.PARCELY_ZE}
            />
            <hr className="mt-20 mb-0" />
            <hr className="mt-10 mb-0" />
            <CuzkLvHtmlSekceListFragment
              active={props.active}
              title="B1 Věcná práva sloužící ve prospěch nemovitosti v části B"
              sekceList={safeFilter(
                TEXTY?.JINA_PRAVA?.SEKCE_LIST,
                sekce => sekce['@SEKCE'] === 'B1'
              )}
            />
            <hr className="mt-20 mb-0" />
            <hr className="mt-10 mb-0" />
            <CuzkLvHtmlSekceListFragment
              active={props.active}
              title="C Věcná práva zatěžující nemovitosti v části B včetně souvisejících údajů"
              sekceList={safeFilter(
                TEXTY?.JINA_PRAVA?.SEKCE_LIST,
                sekce => sekce['@SEKCE'] === 'C'
              )}
            />
            <hr className="mt-20 mb-0" />
            <hr className="mt-10 mb-0" />
            <CuzkLvHtmlSekceListFragment
              active={props.active}
              title="D poznámky a další obdobné údaje"
              sekceList={safeFilter(
                TEXTY?.JINA_PRAVA?.SEKCE_LIST,
                sekce => sekce['@SEKCE'] === 'D'
              )}
            />
            <hr className="mt-20 mb-0" />
            <hr className="mt-10 mb-0" />
            <CuzkLvHtmlSekceD1Fragment
              active={props.active}
              d1Upozorneni={TEXTY?.D1_UPOZORNENI}
            />
            <hr className="mt-20 mb-0" />
            <hr className="mt-10 mb-0" />
            <CuzkLvHtmlSekceEFragment
              active={props.active}
              eNabyvaciTituly={TEXTY?.E_NABYVACI_TITULY}
            />
            <hr className="mt-20 mb-0" />
            <hr className="mt-10 mb-0" />
            <CuzkLvHtmlSekceFFragment
              active={props.active}
              fBonity={TEXTY?.F_BONITY}
            />
            <hr className="mt-30 mb-0" />
            <hr className="mt-10 mb-3" />
            <CuzkLvHtmlFooterFragment
              kp={lvData?.LIST_ZAPATI_LV?.ZAPATI_LV?.KP}
              vyhotoveno={lvData?.VYHOTOVENO}
              upozorneni1={TEXTY?.UPOZORNENI1}
              upozorneni2={TEXTY?.UPOZORNENI2}
            />
          </div>
        ))}
      </Provider>
    </div>
  )
}
