import { Icon } from '@iconify/react'
import { Col, Row } from 'antd'
import React, { FunctionComponent } from 'react'

import { TopBarLogo } from '../TopBarLogo/TopBarLogo'

export interface TopBarProps {
  collapsed: boolean
  onCollapse: () => void
}

export const TopBar: FunctionComponent<TopBarProps> = props => {
  return (
    <Row className="items-center leading-normal">
      <Col className="w-[230px] text-center">
        <TopBarLogo />
      </Col>
      <Col className="border-x px-4" onClick={props.onCollapse}>
        <span className="cursor-pointer hover:text-primaryHover transition-colors">
          <Icon icon="fa:bars" width={12} className="inline-block mr-2" />
          {props.collapsed ? 'Zobrazit menu' : 'Skrýt menu'}
        </span>
      </Col>
      <Col className="text-right static" flex="auto">
        {/* <TopBarMenu /> */}
      </Col>
    </Row>
  )
}
