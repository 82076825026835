import React, { FunctionComponent, memo } from 'react'

export interface PropertyDetailTimelineTitleProps {
  title?: string
}

export const PropertyDetailTimelineTitle: FunctionComponent<PropertyDetailTimelineTitleProps> =
  memo(({ title }) => {
    return (
      <div className="absolute py-3 pr-3 bg-white font-semibold z-10">
        {title}
      </div>
    )
  })
