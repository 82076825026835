import { Spin, Typography } from 'antd'
import React, { FunctionComponent, useState } from 'react'

import { VlastnickePravo } from '../../../common/lvTypes'
import { useAppSelector } from '../../../redux/hooks'
import { PropertyDetailFetchError } from '../PropertyDetailFetchError/PropertyDetailFetchError'
import { PropertyDetailOwnershipRightsChart } from '../PropertyDetailOwnershipRightsChart/PropertyDetailOwnershipRightsChart'
import { PropertyDetailOwnershipRightsTable } from '../PropertyDetailOwnershipRightsTable/PropertyDetailOwnershipRightsTable'
import { ShowHistorySwitch } from '../ShowHistorySwitch/ShowHistorySwitch'

interface PropertyDetailOwnershipRightsProps {
  isDrawer?: boolean
}

const { Title } = Typography

const chartSource = (vlastnickaPrava: VlastnickePravo[]) =>
  vlastnickaPrava.filter(right => !right.platiDo)

const hasHistory = (vlastnickaPrava: VlastnickePravo[]) =>
  !vlastnickaPrava.some(right => !!right.platiDo)

export const PropertyDetailOwnershipRights: FunctionComponent<PropertyDetailOwnershipRightsProps> =
  ({ isDrawer }) => {
    const lvRights = useAppSelector(
      state => state.property.lv.data?.pravniVztahy
    )
    const crmDrmData = useAppSelector(state => state.property.crm.data)
    const { error, data } = useAppSelector(state => state.property.lv)

    const [showHistory, setShowHistory] = useState(false)

    if (crmDrmData === false) return null

    if (!error && data === null)
      return (
        <div>
          <Title level={4}>Vlastnické právo</Title>
          <Spin
            className="!text-gray-400 w-full animate-pulse"
            tip="Majetková práva..."
          />
        </div>
      )

    if (!lvRights?.length)
      return (
        <PropertyDetailFetchError
          description="Je nám líto, ale nepodařilo se dohledat detailní informace o majetkových právech k nemovitosti."
          message="Informace o vlastnictví nenalezeny"
        />
      )

    return (
      <div className="space-y-8">
        {lvRights.map((right, index) => (
          <div className="w-full" key={index}>
            <div className="flex justify-between items-baseline">
              <div className="flex justify-between items-baseline space-x-8">
                <Title level={4}>{right.nazev}</Title>
                {right.nazev === 'Vlastnické právo' && (
                  <PropertyDetailOwnershipRightsChart
                    rights={chartSource(right.vlastnickaPrava)}
                  />
                )}
              </div>
              {!isDrawer && (
                <ShowHistorySwitch
                  disabled={hasHistory(right.vlastnickaPrava)}
                  setShowHistory={setShowHistory}
                  showHistory={showHistory}
                />
              )}
            </div>
            <PropertyDetailOwnershipRightsTable
              key={right.nazev}
              rights={right.vlastnickaPrava}
              showHistory={showHistory}
              setShowHistory={setShowHistory}
              isDrawer={isDrawer}
            />
          </div>
        ))}
      </div>
    )
  }
