import { ConfigProvider, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { TitleProps } from 'antd/es/typography/Title'
import { compareDesc } from 'date-fns'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { BASE_URL } from '../../../ini.json'
import { useAppSelector } from '../../redux/hooks'
import { Min } from '../components/DateFormat/DateFormat'
import { PageLayout } from '../components/PageLayout/PageLayout'
import logoSrc from '../images/logo-bid-and-click-blue.png'
import { defaultPagination } from '../utils/layoutConst'
import { useConst } from '../utils/useConst'

interface MarkeplaceStatisticsItem {
  accountId: string
  accountUserId: string
  contact: {
    name: string
    accountId: string
  }
  createdAt: string
  date: string
  title: string
}

const Marketplace: FunctionComponent = () => {
  const token = useAppSelector(state => state.myProfile.token)
  const [loading, setLoading] = useState<boolean | undefined>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [contactData, setContactData] = useState<any>()

  const fetchData = useCallback(async () => {
    setLoading(true)

    fetch(`${BASE_URL}/api/marketplace/admin/statistics/${token}`)
      .then(response => response.json())
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((data: any) => {
        setContactData(data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setContactData([])
      })
  }, [token])

  useEffect(() => {
    if (contactData !== undefined) return
    fetchData()
  }, [contactData, fetchData])

  const theme = useConst({
    token: {
      colorPrimary: '#5a74be',
      colorInfo: '#38404b',
      sizeStep: 4,
      borderRadius: 3,
      wireframe: false,
    },
  })

  const columns: ColumnsType<MarkeplaceStatisticsItem> = [
    {
      title: 'Datum nákupu',
      dataIndex: 'date',
      key: 'date',
      className: 'w-40',
      defaultSortOrder: 'ascend',
      sorter: (a, b) =>
        a.createdAt && b.createdAt
          ? compareDesc(new Date(a.createdAt), new Date(b.createdAt))
          : 0,
      render: (_, record) => <Min>{record.createdAt}</Min>,
    },
    {
      title: 'ID kupujícího',
      dataIndex: 'accountId',
      key: 'accountId',
      render: (_, record) => (
        <a
          className="!text-primary"
          href={`https://data.regesta.cz/PortalAdmin/DetailFirmy/${record.accountId}`}
        >
          {record.accountId}
        </a>
      ),
    },
    {
      title: 'ID uživ. kupujícího',
      dataIndex: 'accountUserId',
      key: 'accountUserId',
    },
    {
      title: 'Kontakt. os.',
      dataIndex: 'contact',
      key: 'contact',
      render: (_, record) => record.contact.name,
    },
    {
      title: 'Název nabídky',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Typ',
      dataIndex: 'offerType',
      key: 'offerType',
    },
    {
      title: 'ID prodejce',
      dataIndex: 'sellerId',
      key: 'sellerId',
      render: (_, record) => (
        <a
          className="!text-primary"
          href={`https://data.regesta.cz/PortalAdmin/DetailFirmy/${record.contact.accountId}`}
        >
          {record.contact.accountId}
        </a>
      ),
    },
  ]

  const titleProps = useConst<TitleProps>({
    style: {
      marginBottom: 0,
      marginTop: '8px',
      display: 'flex',
      alignItems: 'center',
    },
    level: 3,
  })

  return (
    <>
      <ConfigProvider theme={theme}>
        <PageLayout
          title={
            <Typography.Title {...titleProps}>
              <img className="inline-block h-7 mr-2" src={logoSrc} alt="logo" />
              <span>Marketplace statistiky</span>
            </Typography.Title>
          }
        >
          <Table
            pagination={defaultPagination}
            dataSource={contactData}
            columns={columns}
            loading={loading}
            size="small"
          />
        </PageLayout>
      </ConfigProvider>
    </>
  )
}

export default Marketplace
