import { Button, InputNumber } from 'antd'
import React, { FunctionComponent, useCallback, useRef, useState } from 'react'

import { SearchCadastralArea } from '../SearchCadastralArea/SearchCadastralArea'

export interface LvSearchBarProps {
  loading?: boolean
  onSubmit: (params: { lvCode: string; lvNumber: string }) => void
}

export const LvSearchBar: FunctionComponent<LvSearchBarProps> = props => {
  const [lvCode, setLvCode] = useState('')
  const [lvNumber, setLvNumber] = useState('')
  const inputNumberRef = useRef<HTMLInputElement>(null)

  const handleLvNumber = (data: string | null) => setLvNumber(data || '')

  const handleLvCode = useCallback((data: { katastralniUzemiKod?: string }) => {
    setLvCode(data?.katastralniUzemiKod || '')
    setTimeout(() => {
      inputNumberRef.current?.focus()
    }, 0)
  }, [])

  const handleSearch = useCallback(() => {
    props.onSubmit({ lvCode, lvNumber })
  }, [props, lvCode, lvNumber])

  return (
    <div className="flex space-x-2 py-6">
      <SearchCadastralArea
        disabled={props.loading}
        initialValue={lvCode}
        setCadastralData={handleLvCode}
        size="large"
        autoFocus
      />
      <InputNumber
        ref={inputNumberRef}
        disabled={props.loading}
        className="!w-full"
        size="large"
        placeholder="Číslo listu vlastnictví"
        max="999999"
        value={lvNumber}
        onChange={handleLvNumber}
        onPressEnter={handleSearch}
      />
      <Button
        loading={props.loading}
        size="large"
        disabled={!lvCode || !lvNumber}
        type="primary"
        onClick={handleSearch}
      >
        Vyhledat list vlastnictví
      </Button>
    </div>
  )
}
