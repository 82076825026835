import { Icon } from '@iconify/react'
import { message, Popconfirm, Tooltip } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { BASE_URL } from '../../../../ini.json'
import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { WatchdogIconSize } from '../../../common/watchdogTypes'
import { useAppSelector } from '../../../redux/hooks'
import { drmPropertyType } from '../../utils/ruianHelpers'
import { useFetchWatchdogProperty } from '../../utils/useFetchWatchdog'

export interface WatchdogRemovePropertyButtonProps {
  propertyId: string
  propertyType: PROPERTY_TYPES
  disableTooltip?: boolean
  iconSize?: WatchdogIconSize
  inWatchdogTable?: boolean
}

export const WatchdogRemovePropertyButton: FunctionComponent<WatchdogRemovePropertyButtonProps> =
  props => {
    const channelData = useAppSelector(state => state.watchdog.channel.data)
    const propertyData = useAppSelector(state => state.watchdog.property.data)
    const token = useAppSelector(state => state.myProfile.token)
    const refetch = useFetchWatchdogProperty()

    const channel = useMemo(() => {
      const channelId = propertyData?.[props?.propertyId || ''].channelId
      return {
        id: channelId,
        name: channelData?.find(item => item.id === channelId)?.nazev,
      }
    }, [channelData, propertyData, props.propertyId])

    const handleSubmit = useCallback(async () => {
      const type = drmPropertyType[props.propertyType]

      try {
        await axios.post(`${BASE_URL}/api/watchdog/remove/${token}`, {
          channelId: channel.id,
          propertyId: props.propertyId,
          propertyType: type,
        })
        refetch()
        message.success('Odebráno z hlídače.')
      } catch (error) {
        message.error('Nepodařilo se odebrat nemovitost do hlídače.')
      }
    }, [channel.id, props.propertyId, props.propertyType, refetch, token])

    if (props.inWatchdogTable) {
      return (
        <div
          title="Odebrat z hlídače"
          onClick={handleSubmit}
          className="flex justify-center items-center text-primary hover:text-red-400 cursor-pointer pt-1.5 pb-1"
        >
          <Icon
            icon="material-symbols:notifications-active"
            className={props.iconSize || 'text-lg'}
          />
        </div>
      )
    }

    return (
      <Tooltip
        placement="left"
        trigger={props.disableTooltip ? '' : undefined}
        title={
          <div>
            <div>Odebrat z hlídače</div>
            <div>
              <span className="text-gray-400">
                Kanál: {!channel.name && '-- nepřiřazen --'}
              </span>
              <span>{channel.name}</span>
            </div>
          </div>
        }
      >
        <Popconfirm
          placement="leftTop"
          title="Opravdu chcete odebrat nemovitost z hlídače?"
          okText="Odebrat"
          onConfirm={handleSubmit}
          cancelText="Zrušit"
          zIndex={10000}
        >
          <div className="flex justify-center items-center text-primary hover:text-red-400 cursor-pointer pt-1.5 pb-1">
            <Icon
              icon="material-symbols:notifications-active"
              className={props.iconSize || 'text-lg'}
            />
          </div>
        </Popconfirm>
      </Tooltip>
    )
  }
