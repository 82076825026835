import { useCallback } from 'react'

import { RcTableItemConcept } from '../components/RcInput/RcInput'
import { useAssignLabels } from './useAssignLabels'
import { useCreateCeeMonitoring } from './useCreateCeeMonitoring'
import { useCreateCuzkMonitoring } from './useCreateCuzkMonitoring'

export const useCreateCeeMonitoringHandler = () => {
  const { createCeeMonitoring } = useCreateCeeMonitoring()
  const { createCuzkMonitoring } = useCreateCuzkMonitoring()
  const { assignLabels } = useAssignLabels()

  const processSubject = useCallback(
    async (
      item: RcTableItemConcept,
      checkFrequency: number | null,
      checkCuzk: boolean,
      labelsId: string[]
    ): Promise<{ ceeId: string | null; cuzkId: string | null }> => {
      if (item.validatedCode && item.validatedCode > 10) {
        const ceeId = await createCeeMonitoring(item, checkFrequency)

        if (ceeId) {
          await assignLabels({ monitoringCeeId: ceeId, labelsId })

          if (checkCuzk) {
            const cuzkId = await createCuzkMonitoring(
              item,
              checkFrequency,
              ceeId
            )
            if (cuzkId) {
              await assignLabels({ monitoringCuzkRcId: cuzkId, labelsId })
              return { ceeId, cuzkId }
            }
          }
          return { ceeId, cuzkId: null }
        }
      }
      return { ceeId: null, cuzkId: null }
    },
    [createCeeMonitoring, createCuzkMonitoring, assignLabels]
  )

  return { processSubject }
}
