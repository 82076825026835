import { UserOutlined } from '@ant-design/icons'
import { Icon } from '@iconify/react'
import { Tooltip } from 'antd'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Subjekt, VlastnickePravo } from '../../common/lvTypes'
import { CUZK_PERSON_TYPES as TYPE } from '../components/CuzkEpoModalButton/CuzkEpoModalButton'
import { Money } from '../components/Money/Money'
import { getUniquePersonKey } from './getUniquePersonKey'
import { isCompanyName } from './subjectHelpers'

export interface OwnershipRightItem {
  key: string
  id: string
  type: keyof typeof TYPE
  validFrom: string | null
  validTo: string | null
  name: string
  address: string
  addressId: number
  uniquePersonKey: string
  share: { numerator: number; denominator: number; percentage: number }
  sjmPartner: Subjekt | null
  sjmParent: Subjekt | null
}

export const typeIconMap = {
  [TYPE.SJM]: (
    <div
      className="flex justify-center"
      title="SJM - Společné vlastnictví manželů"
    >
      <Icon icon="la:male" className="text-xl" />
      <Icon icon="la:female" className="text-xl -ml-3" />
    </div>
  ),
  [TYPE.OFO]: <UserOutlined title="Fyzická osoba" />,
  [TYPE.OPO]: (
    <span title="Právnická osoba">
      <Icon icon="fluent:building-factory-16-regular" className="inline" />
    </span>
  ),
}

export function sharePercentage(
  numerator: number | null | undefined,
  denominator: number | null | undefined
): number
export function sharePercentage(
  numerator: number | null | undefined,
  denominator: number | null | undefined,
  formatted: boolean
): number | string
export function sharePercentage(
  numerator: number | null | undefined,
  denominator: number | null | undefined,
  formatted?: boolean
): number | string {
  if (!numerator || !denominator || denominator === 0) return 0
  const percentage = (numerator / denominator) * 100
  return formatted ? `${percentage.toFixed(2)} %` : percentage
}

export const shareRender = (share: OwnershipRightItem['share']) => (
  <Tooltip
    title={
      <>
        <div>{share.percentage} %</div>
        <div className="text-gray-400">
          V KN uváděno jako {share?.numerator} / {share?.denominator}
        </div>
      </>
    }
  >
    <span>
      <Money amount={share.percentage} symbol="%" />
    </span>
  </Tooltip>
)

export const createOwnershipRecord = (
  right: VlastnickePravo,
  subjekt: Subjekt,
  sjmPartner: Subjekt | null = null,
  sjmParent: Subjekt | null = null
) => ({
  key: uuidv4(),
  id: subjekt.id,
  type:
    sjmPartner || sjmParent
      ? TYPE.SJM
      : isCompanyName(subjekt.jmeno)
      ? TYPE.OPO
      : TYPE.OFO,
  name: subjekt.jmeno,
  address: subjekt.adresa,
  addressId: subjekt.adresniMistoKod,
  uniquePersonKey: getUniquePersonKey(
    subjekt.jmeno,
    subjekt.adresniMistoKod,
    subjekt.adresa
  ),
  share: {
    percentage: sharePercentage(right.podilCitatel, right.podilJmenovatel),
    numerator:
      right.podilCitatel && right.podilCitatel <= right.podilJmenovatel
        ? right.podilCitatel
        : 0,
    denominator: right.podilJmenovatel || 1,
  },
  validFrom: right.platiOd,
  validTo: right.platiDo,
  sjmPartner,
  sjmParent,
})

export const ownershipHistoryClassName = (record: OwnershipRightItem) =>
  record.validTo ? 'opacity-40' : ''
