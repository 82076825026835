import { Icon } from '@iconify/react'
import React, { FunctionComponent } from 'react'

export const CuzkEpoRestrictionCheckPanelNotFound: FunctionComponent = () => {
  return (
    <div className="flex flex-col text-center space-y-2 bg-white w-full p-8">
      <Icon
        width="70"
        className="text-gray-400 mx-auto"
        icon="healthicons:stock-out-outline"
      />
      <div className="text-base font-bold">Zkontrolováno</div>
      <div className="text-gray-400">Nebyla nalezena žádná práva 3. stran.</div>
    </div>
  )
}
