import { Checkbox, Col, Row } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { RestrictionCheckDescription } from '../../../common/epoTypes'
import {
  CadastralCheckedValues,
  CadastralOffice,
} from '../../utils/cuzkHelpers'
import { CadastreSelectionOfficeCheckbox } from '../CadastreSelectionOfficeCheckbox/CadastreSelectionOfficeCheckbox'
import { CadastreSelectionWorkplaceCheckbox } from '../CadastreSelectionWorkplaceCheckbox/CadastreSelectionWorkplaceCheckbox'
import { NoResultBox } from '../NoResultBox/NoResultBox'

export interface CadastralSelectionModalFilterProps {
  filteredRegions: CadastralOffice[]
  boughtVisible: boolean
  setCheckedValues: (
    value: React.SetStateAction<CadastralCheckedValues>
  ) => void
  checkedValues: CadastralCheckedValues
  searchTerm: string
}

const rowStyle: [number, number] = [48, 24]

export const CadastralSelectionModalFilter: FunctionComponent<CadastralSelectionModalFilterProps> =
  props => {
    const filteredOffices = useMemo(
      () =>
        props.filteredRegions.filter(
          office =>
            (props.boughtVisible || !office.disabled) &&
            office.pracoviste.some(workplace => workplace.pocetLV)
        ),
      [props.filteredRegions, props.boughtVisible]
    )

    const filteredWorkplaces = useMemo(
      () =>
        filteredOffices.map(office => ({
          ...office,
          pracoviste: office.pracoviste.filter(
            workplace =>
              (props.boughtVisible ||
                !workplace.disabled ||
                workplace.description ===
                  RestrictionCheckDescription.Processing) &&
              !!workplace.pocetLV
          ),
        })),
      [filteredOffices, props.boughtVisible]
    )

    if (!filteredOffices.length) {
      return <NoResultBox />
    }

    return (
      <Row className="w-full" gutter={rowStyle}>
        {filteredWorkplaces.map(office => (
          <Col key={office.kod} span={6} className="px-8 animate-fadeIn">
            <CadastreSelectionOfficeCheckbox
              checkboxData={filteredOffices}
              checkedValues={props.checkedValues}
              office={office}
              setCheckedValues={props.setCheckedValues}
            />
            <Checkbox.Group
              className="w-full flex flex-col"
              value={props.checkedValues[office.kod]}
              onChange={checkedValues =>
                props.setCheckedValues(
                  (prevState: CadastralCheckedValues) =>
                    ({
                      ...prevState,
                      [office.kod]: checkedValues,
                    } as CadastralCheckedValues)
                )
              }
            >
              {office.pracoviste.map(workplace => (
                <CadastreSelectionWorkplaceCheckbox
                  workplace={workplace}
                  searchTerm={props.searchTerm}
                  key={workplace.kod}
                />
              ))}
            </Checkbox.Group>
          </Col>
        ))}
      </Row>
    )
  }
