import { Icon } from '@iconify/react'
import { Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Link } from 'react-router-dom'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import {
  Maybe,
  usePostServiceSentRecipientsQuery,
} from '../../../graphql/generated'
import { defaultPagination } from '../../utils/layoutConst'
import { propertyDetailPath } from '../../utils/paths'
import { CadastrePostLetterDetailPdf } from '../CadastrePostLetterDetailPdf/CadastrePostLetterDetailPdf'
import { PdfDrawerPreview } from '../PdfDrawerPreview/PdfDrawerPreview'

export interface CadastrePostSentRecipient {
  id: string
  propertyName: string
  propertyType: string
  propertyId: string
  recipient: {
    id: string
    nazev: string
    krestnijmeno?: string
    osloveni?: string
    pohlavi?: string
    prijmeni: string
    titulpred?: string
    titulza?: string
    address: {
      budovaregestaid: string
      cislodomovni: number
      cisloorientacni: Maybe<string>
      cisloorientacnipismeno: Maybe<string>
      id: string
      obec: string
      ulice: string
      psc: string
      radnice: Maybe<string>
    }
  }
}

export interface CadastrePostSentTableExpandedRow {
  listId: string
}

export const CadastrePostSentTableExpandedRow: FunctionComponent<CadastrePostSentTableExpandedRow> =
  props => {
    const [data, setData] = useState<CadastrePostSentRecipient[] | undefined>()
    const [letterText, setLetterText] = useState<string>('')

    const [queryResult] = usePostServiceSentRecipientsQuery({
      variables: { id: props.listId },
    })

    useEffect(() => {
      if (
        queryResult.data?.recipientListById
          ?.recipientListRecipientsByRecipientListId?.nodes?.length
      ) {
        setData(
          queryResult.data?.recipientListById
            ?.recipientListRecipientsByRecipientListId
            ?.nodes as CadastrePostSentRecipient[]
        )
        setLetterText(queryResult.data?.recipientListById?.letterText || '')
      }
    }, [
      queryResult.data?.recipientListById?.letterText,
      queryResult.data?.recipientListById
        ?.recipientListRecipientsByRecipientListId?.nodes,
    ])

    const columns = useMemo<ColumnsType<CadastrePostSentRecipient>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          responsive: ['' as Breakpoint],
          render: (_, record) => <>{record?.recipient?.id}</>,
        },
        {
          title: 'Jméno / název',
          dataIndex: 'subject',
          key: 'subject',
          sorter: {
            compare: (a, b) =>
              a.recipient.nazev.localeCompare(b.recipient.nazev),
          },
          render: (_, record) => (
            <a
              href={`https://data.regesta.cz/Vyhledavani/Subjekt/${record.recipient.id}`}
            >
              {record.recipient.nazev}
            </a>
          ),
        },
        {
          title: 'Adresa',
          dataIndex: 'address',
          key: 'address',
          sorter: {
            compare: (a, b) =>
              a.recipient.address?.ulice.localeCompare(
                b.recipient.address?.ulice
              ),
          },
          render: (_, record) => {
            const streetComponents = [
              record.recipient.address?.ulice,
              record.recipient.address?.cislodomovni,
              record.recipient.address?.cisloorientacni
                ? '/' + record.recipient.address?.cisloorientacni
                : '',
              record.recipient.address?.cisloorientacnipismeno || '',
            ]
            const street = streetComponents.join(' ').trim()
            const fullAddress =
              street +
              ' ' +
              record.recipient.address?.obec +
              ' ' +
              record.recipient.address?.psc
            return (
              <Link
                className="flex space-x-2 items-center"
                to={propertyDetailPath(
                  PROPERTY_TYPES.BUILDING,
                  record.recipient.address.budovaregestaid
                )}
              >
                <span>{fullAddress}</span>
                {record.recipient.address?.radnice && (
                  <Tooltip
                    title={`Shoda s adresou radnice ${record.recipient.address?.radnice}`}
                  >
                    <Icon icon="fa:fort-awesome" inline width="13" />
                  </Tooltip>
                )}
              </Link>
            )
          },
        },
        {
          title: 'Typ nemovitosti',
          dataIndex: 'typ',
          key: 'typ',
          sorter: {
            compare: (a, b) => a.propertyType.localeCompare(b.propertyType),
          },
          render: (_, record) => <>{record?.propertyType}</>,
        },
        {
          title: 'Nemovitost',
          dataIndex: 'property',
          key: 'property',
          sorter: {
            compare: (a, b) => a.propertyName.localeCompare(b.propertyName),
          },
          render: (_, record) => (
            <Link
              to={propertyDetailPath(
                record?.propertyType as PROPERTY_TYPES,
                record.propertyId
              )}
            >
              {record?.propertyName}
            </Link>
          ),
        },
        {
          title: 'PDF',
          dataIndex: 'action',
          key: 'action',
          className: 'w-36',
          render: (_, record) => (
            <PdfDrawerPreview
              buttonProps={{ ghost: true, size: 'small' }}
              buttonText="Náhled dopisu"
              drawerTitle="Náhled odeslaného dopisu"
              fileName={record.recipient.nazev + '_HROMADNY_DOPIS_REGESTA_DATA'}
            >
              <CadastrePostLetterDetailPdf
                data={record}
                letterText={letterText}
              />
            </PdfDrawerPreview>
          ),
        },
      ],
      [letterText]
    )

    const rowKey = useCallback(
      (record: CadastrePostSentRecipient) => record.recipient.id,
      []
    )

    return (
      <Table
        pagination={defaultPagination}
        dataSource={data}
        columns={columns}
        rowKey={rowKey}
        size="small"
        rowClassName="bg-[#fafafa]"
        className="mb-6"
      />
    )
  }
