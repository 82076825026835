import { Table } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { SmsItem, StatisticalDataItem } from '../../../common/risyTypes'
import { useAppSelector } from '../../../redux/hooks'
import { formatCurrencyNumber } from '../Money/Money'

export interface PropertyDetailRisyTableProps {
  dataSource?: (StatisticalDataItem | SmsItem)[]
  showBoolean?: boolean
}

export const PropertyDetailRisyTable: FunctionComponent<PropertyDetailRisyTableProps> =
  props => {
    const loading = useAppSelector(state => state.property.risy.fetching)

    const columns = useMemo(
      () => [
        { title: 'Kategorie', dataIndex: 'keyName' },
        {
          title: 'K datu',
          dataIndex: 'year',
          className: '!text-right w-20 text-gray-400',
        },
        {
          title: 'Hodnota',
          dataIndex: 'value',
          className: '!text-right w-20 whitespace-nowrap',
          render: (value: string, record: StatisticalDataItem | SmsItem) => {
            if ('key' in record) {
              if (record.key === '10100')
                return `roku ${formatCurrencyNumber(value, 0)}`
              if (record.key === '10200')
                return `${formatCurrencyNumber(value, 0)} m.n.m.`
              if (record.key === '10300')
                return `${formatCurrencyNumber(value, 0)} ha`
              if (record.key === '110900')
                return `${formatCurrencyNumber(value, 0)}`
              if (record.key === '600501')
                return `${formatCurrencyNumber(value, 0)} Km`
            }
            if (props.showBoolean) return value ? 'Ano' : 'Ne'
            return value
          },
        },
      ],
      [props.showBoolean]
    )

    return (
      <Table
        loading={loading}
        columns={columns}
        dataSource={props.dataSource || []}
        pagination={false}
        size="small"
        className="mb-4"
      />
    )
  }
