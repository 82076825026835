import { useMemo } from 'react'

import { usePropertyNotesByPropertyIdsQuery } from '../../graphql/generated'
import { useAppSelector } from '../../redux/hooks'
import { PropertyTableNoteItem } from './propertyDetailNoteHelpers'

export const usePropertyTableNoteCheck = (ids: string[]) => {
  const tenantId = useAppSelector(state => state.myProfile.tenantId)

  const [{ data }] = usePropertyNotesByPropertyIdsQuery({
    pause: !tenantId || !ids.length,
    variables: { accountId: tenantId, propertyIds: ids },
  })

  const result = useMemo(() => {
    if (!ids.length || !data) return null

    const notesMap: { [id: string]: PropertyTableNoteItem | null } = {}

    ids.forEach(id => {
      notesMap[id] = null
    })

    if (data && data.allPropertyNotes && data.allPropertyNotes.nodes) {
      data.allPropertyNotes.nodes.forEach(node => {
        if (!node?.propertyId) return

        notesMap[node.propertyId] = node as PropertyTableNoteItem
      })
    }
    return notesMap
  }, [data, ids])

  return result
}
