import { Modal } from 'antd'
import Quill from 'quill'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { imagePropsInitial } from '../../../common/postServiceTypes'
import { Fade } from '../Fade/Fade'
import { PostServiceLetterImageHelp } from '../PostServiceLetterImageHelp/PostServiceLetterImageHelp'
import { PostServiceLetterImageModalFooter } from '../PostServiceLetterImageModalFooter/PostServiceLetterImageModalFooter'
import { PostServiceLetterImageOptions } from '../PostServiceLetterImageOptions/PostServiceLetterImageOptions'
import { PostServiceLetterImagePosition } from '../PostServiceLetterImagePosition/PostServiceLetterImagePosition'

export interface PostServiceLetterImageModalProps {
  editorRef?: Quill | null
  setPdfInactive: (value: boolean) => void
}

export const PostServiceLetterImageModal: FunctionComponent<PostServiceLetterImageModalProps> =
  props => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [currentImage, setCurrentImage] = useState<EventTarget | null>(null)
    const [imageProps, setImageProps] = useState(imagePropsInitial)

    useEffect(() => {
      const handleImageClick = () => {
        props.editorRef?.root.addEventListener('click', function (e) {
          if (e.target && (e.target as HTMLElement).tagName === 'IMG') {
            setCurrentImage(e.target)
            setIsModalVisible(true)
          }
        })
      }
      handleImageClick()

      // Cleanup after unmount
      return () => {
        if (props.editorRef && props.editorRef.root) {
          props.editorRef.root.removeEventListener('click', handleImageClick)
        }
      }
    }, [props.editorRef])

    const handleOk = useCallback(() => {
      if (currentImage && imageProps.width) {
        const imgNode = currentImage as HTMLImageElement

        const i = imageProps
        imgNode.setAttribute(
          'style',
          `width: ${i.width}%; ` +
            `${i.align ? `${i.align};` : ''} ` +
            `${i.background ? `position: absolute;` : ''} ` +
            `${i.background && i.left ? `left: ${i.left}cm;` : ''} ` +
            `${i.background && i.right ? `right: ${i.right}cm;` : ''} ` +
            `${i.background && i.bottom ? `bottom: ${i.bottom}cm;` : ''} ` +
            `${i.background && i.top ? `top: ${i.top}cm;` : ''} `
        )
        imgNode.parentElement?.setAttribute(
          'class',
          `${i.background ? `ql-image-parent` : ''}`
        )
      }
      setIsModalVisible(false)
      props.setPdfInactive(true)
      props.editorRef?.insertText(0, ' ') //
      props.editorRef?.deleteText(0, 1) // invoke editor onChange event
    }, [currentImage, imageProps, props])

    const handleCancel = useCallback(() => {
      setIsModalVisible(false)
    }, [])

    const handleRemove = useCallback(() => {
      const index = props.editorRef?.getSelection()?.index
      typeof index === 'number' && props.editorRef?.deleteText(index, 1)
      setIsModalVisible(false)
    }, [props.editorRef])

    return (
      <Modal
        title="Nastavení stylu obrázku"
        onCancel={handleCancel}
        open={isModalVisible}
        width={600}
        footer={
          <PostServiceLetterImageModalFooter
            handleCancel={handleCancel}
            handleOk={handleOk}
            handleRemove={handleRemove}
          />
        }
      >
        <PostServiceLetterImageOptions
          handleOk={handleOk}
          imageProps={imageProps}
          setImageProps={setImageProps}
        />
        <Fade show={imageProps.background}>
          <PostServiceLetterImagePosition
            handleOk={handleOk}
            imageProps={imageProps}
            setImageProps={setImageProps}
          />
        </Fade>
        <PostServiceLetterImageHelp />
      </Modal>
    )
  }
