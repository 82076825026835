import React, { FunctionComponent } from 'react'

import { RuianBuildingResponse } from '../../../common/ruianTypes'
import { useAppSelector } from '../../../redux/hooks'
import { copyAddressButton } from '../../utils/propertyDetailHelper'
import { CopyButton } from '../CopyButton/CopyButton'
import { Year } from '../DateFormat/DateFormat'
import { Money } from '../Money/Money'
import { PriceEstimateLayoutLvDataRow as Item } from '../PriceEstimateLayoutLvDataRow/PriceEstimateLayoutLvDataRow'
import { PropertyDetailSidebarRowCopyableItem as CopyItem } from '../PropertyDetailSidebarRowCopyableItem/PropertyDetailSidebarRowCopyableItem'

export const PropertyDetailSidebarRuianBuilding: FunctionComponent = () => {
  const ruian = useAppSelector(
    state => state.property.ruian.data
  ) as RuianBuildingResponse

  return (
    <>
      <Item title="Budova">{ruian?.nazev}</Item>
      <CopyItem title="GPS souřadnice">
        {`${ruian?.definicniBod?.lat.toFixed(
          5
        )}, ${ruian?.definicniBod?.long.toFixed(5)}`}
      </CopyItem>
      <Item
        title={ruian?.adresniMista?.length === 1 ? 'Adresa' : 'Adresy v budově'}
        condition={!!ruian?.adresniMista?.length}
      >
        {ruian?.adresniMista?.map((adresa, index) => (
          <div key={index} className="flex justify-between group">
            {adresa.formatovanaAdresa}
            <CopyButton
              copyText={adresa.formatovanaAdresa}
              {...copyAddressButton}
            />
          </div>
        ))}
      </Item>

      <Item title="Počet nadzemních podlaží">
        {ruian?.pocetNadzemnichPodlazi?.text}
      </Item>

      <Item title="Počet bytů">{ruian?.pocetBytu}</Item>

      <Item title="Rok dokončení" condition={!!ruian?.dokonceni}>
        <Year>{ruian?.dokonceni}</Year>
      </Item>

      <Item title="Zastavěná plocha" condition={!!ruian?.zastavenaPlocha}>
        <Money amount={ruian.zastavenaPlocha} decimalNumbers={0} symbol="m²" />
      </Item>

      <Item title="Zastavěná plocha" condition={!ruian?.zastavenaPlocha}>
        <Money amount={ruian?.plochaPolygonu} decimalNumbers={0} symbol="m²" />
        <span className="font-normal text-gray-400 ml-2">
          (Vypočteno z polygonu)
        </span>
      </Item>

      <Item title="Podlahová plocha" condition={!!ruian?.podlahovaPlocha}>
        <Money amount={ruian.podlahovaPlocha} decimalNumbers={0} symbol="m²" />
      </Item>

      <Item title="Obestavěný prostor" condition={!!ruian?.obestavenyProstor}>
        <Money
          amount={ruian.obestavenyProstor}
          decimalNumbers={0}
          symbol="m³"
        />
      </Item>
      <Item title="Druh konstrukce">{ruian?.druhKonstrukce?.nazev}</Item>
      <Item title="Vodovod">{ruian?.vodovod?.nazev}</Item>
      <Item title="Odpady">{ruian?.odpady?.nazev}</Item>
      <Item title="Plyn">{ruian?.plyn?.nazev}</Item>
      <Item title="Způsob vytápění">{ruian?.zpusobVytapeni?.nazev}</Item>
    </>
  )
}
