import { useMemo } from 'react'

import { Proceeding } from '../../common/proceedingTypes'
import {
  getProceedingKey,
  getProceedingTitle,
  getProceedingUrlPath,
} from './proceedingDetailHelpers'

/**
 * Custom hook for formatting proceeding string.
 * @proceedingUrlPath code/year/type/number - NNN/NNNN/LL/NNNNNN
 * @proceedingKey type-number-year-code - LL-NNNNNN-NNNN-NNN
 * @proceedingTitle type-number/year-code - LL-NNNNNN/NNNN-NNN
 */
export const useFormattedProceeding = (proceeding?: Proceeding | null) => {
  const { proceedingUrlPath, proceedingKey, proceedingTitle } = useMemo(() => {
    if (
      proceeding &&
      proceeding.rok &&
      proceeding.cislo &&
      proceeding.typ &&
      proceeding.pracovisteKod
    ) {
      return {
        proceedingUrlPath: getProceedingUrlPath(proceeding),
        proceedingKey: getProceedingKey(proceeding),
        proceedingTitle: getProceedingTitle(proceeding),
      }
    } else {
      return {
        proceedingUrlPath: null,
        proceedingKey: null,
        proceedingTitle: null,
      }
    }
  }, [proceeding])

  return { proceedingUrlPath, proceedingKey, proceedingTitle }
}
