import { Checkbox } from 'antd'
import React, { FunctionComponent } from 'react'

import { pricelist } from '../../utils/pricelist'
import { CheckFrequencySelectInput } from '../CheckFrequencySelectInput/CheckFrequencySelectInput'
import { Fade } from '../Fade/Fade'
import { LabelSelectInput } from '../LabelSelectInput/LabelSelectInput'

interface CeeMonitorAddSubjectModalOptionsProps {
  monitoring: boolean
  showHelp: boolean
  checkCuzk: boolean
  onCheckFrequencyChange: (frequency: number | null) => void
  onLabelsChange: (labelsId: string[]) => void
  onCheckCuzkChange: () => void
}

export const CeeMonitorAddSubjectModalOptions: FunctionComponent<CeeMonitorAddSubjectModalOptionsProps> =
  ({
    monitoring,
    showHelp,
    checkCuzk,
    onCheckFrequencyChange,
    onLabelsChange,
    onCheckCuzkChange,
  }) => {
    return (
      <div className="my-12">
        {monitoring && (
          <>
            <p className="mb-1 mt-4">Frekvence kontroly</p>
            <CheckFrequencySelectInput onChange={onCheckFrequencyChange} />
            <Fade show={showHelp}>
              <p className="mb-2 text-gray-400">
                Vyberte, jak často chcete vybrané subjekty kontrolovat v
                centrální evidenci exekucí. Pokud je subjekt nalezen,
                automaticky se pozastaví další pravidelné kontroly. Frekvenci
                kontrol můžete kdykoli změnit.
              </p>
            </Fade>
          </>
        )}

        <p className="mb-1 mt-4">Štítek (nepovinné)</p>
        <LabelSelectInput onChange={onLabelsChange} />
        <Fade show={showHelp}>
          <p className="mb-2 text-gray-400">
            Štítkem můžete označit celou skupinu vkládaných záznamů naráz.
          </p>
        </Fade>

        <p className="mb-1 mt-4">ČÚZK</p>
        <Checkbox checked={checkCuzk} onChange={onCheckCuzkChange}>
          Ověřit rovněž výskyt v evidenci ČÚZK{' '}
          <span className="font-normal text-gray-400">
            + {pricelist.CUZK} Kr./subj.
          </span>
        </Checkbox>
        <Fade show={showHelp}>
          <p className="mb-2 text-gray-400">
            Funkce umožňuje zároveň ověřit, zda se subjekt vyskytuje v evidenci
            katastrálního úřadu.
          </p>
        </Fade>
      </div>
    )
  }
