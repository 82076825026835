import { Text, View } from '@react-pdf/renderer'
import React, { FunctionComponent } from 'react'

import { monitPdfCss } from '../../utils/monitPdfCss'

export interface PriceEstimatePdfPropertyRowProps {
  data?: string | number | null
  title?: string
  children?: React.ReactNode
  suffix?: React.ReactNode
}

export const PriceEstimatePdfPropertyRow: FunctionComponent<PriceEstimatePdfPropertyRowProps> =
  props => {
    if (!props.data) return null
    return (
      <View style={monitPdfCss?.tableRow}>
        <Text style={monitPdfCss?.propertyCellHeader}>
          {props.children || props.title}
        </Text>
        <Text style={monitPdfCss?.propertyCellValue}>
          {props.data}
          {props.suffix}
        </Text>
      </View>
    )
  }
