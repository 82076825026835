import axios from 'axios'

import { ARES_SERVICE, AresFilterInput } from '../../common/aresTypes'
const pravnickaOsobaKody = [
  '111',
  '112',
  '113',
  '117',
  '118',
  '121',
  '141',
  '145',
  '151',
  '152',
  '161',
  '205',
  '261',
  '301',
  '302',
  '313',
  '325',
  '326',
  '331',
  '332',
  '333',
  '352',
  '353',
  '361',
  '362',
  '381',
  '382',
  '391',
  '392',
  '411',
  '421',
  '422',
  '423',
  '424',
  '425',
  '441',
  '501',
  '521',
  '601',
  '641',
  '661',
  '671',
  '701',
  '703',
  '704',
  '706',
  '711',
  '721',
  '722',
  '723',
  '731',
  '733',
  '734',
  '736',
  '741',
  '745',
  '751',
  '761',
  '771',
  '801',
  '804',
  '805',
  '811',
  '906',
  '907',
  '908',
  '921',
  '922',
  '931',
  '932',
  '933',
  '936',
  '937',
  '938',
  '941',
  '961',
  '962',
  '998',
]
const aresApiUrl =
  'https://ares.gov.cz/ekonomicke-subjekty-v-be/rest/ekonomicke-subjekty'

const handleFetch = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input: any,
  service: ARES_SERVICE,
  isFilter?: boolean
) => {
  try {
    const url = `${aresApiUrl}${service ? '-' : ''}${service}/${
      isFilter ? 'vyhledat/' : input
    }`
    const response = isFilter
      ? await axios.post(url, input)
      : await axios.get(url)
    if (response.data && response.status === 200) {
      return response.data
    } else {
      throw new Error('Data nebyla nalezena')
    }
  } catch (error) {
    console.error('Chyba při načítání výsledků vyhledávání:', error)
  }
}

export const fetchAresByIco = async (ico: string) =>
  handleFetch(ico, ARES_SERVICE.default)

export const fetchAresByFilter = async (
  filter: AresFilterInput,
  service: ARES_SERVICE
) => handleFetch(filter, service, true)

export const fetchAresByBusinessName = async (
  businessName: string,
  companyOnly?: boolean
) => {
  const requestBody = {
    obchodniJmeno: businessName,
    pravniForma: companyOnly ? pravnickaOsobaKody : null,
  }
  return handleFetch(requestBody, ARES_SERVICE.default, true)
}

export const fetchRzpByIco = async (ico: string) =>
  handleFetch(ico, ARES_SERVICE.rzp)

export const fetchRzpByFilter = async (ico: string[]) =>
  handleFetch({ ico }, ARES_SERVICE.rzp, true)

export const fetchVrByIco = async (ico: string) =>
  handleFetch(ico, ARES_SERVICE.vr)

export const fetchVrByFilter = async (ico: string[]) =>
  handleFetch({ ico }, ARES_SERVICE.vr, true)
