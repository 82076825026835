import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { propertyDetailPath } from '../../utils/paths'
import { BpejTooltip } from '../BpejTooltip/BpejTooltip'
import { Money } from '../Money/Money'
import { ExtendedAreaRuian } from '../PriceEstimateArea/PriceEstimateArea'
import { PriceEstimateLayoutLvDataRow as Item } from '../PriceEstimateLayoutLvDataRow/PriceEstimateLayoutLvDataRow'

export interface PriceEstimateLayoutAreaLvProps {
  ruianData?: ExtendedAreaRuian
  propertyId: string
}

export const PriceEstimateLayoutAreaLv: FunctionComponent<PriceEstimateLayoutAreaLvProps> =
  props => {
    const ruian = props.ruianData?.ruian

    return (
      <>
        <Item condition={!!ruian?.nazev} title="Parcela">
          <Link
            target="_blank"
            title="Zobrazit detail pozemku v novém okně"
            to={propertyDetailPath(PROPERTY_TYPES.AREA, ruian?.id)}
          >
            {ruian?.nazev}
          </Link>
        </Item>

        <Item title="Způsob využití">{ruian?.zpusobVyuziti?.nazev}</Item>

        <Item title="Výměra" condition={!!ruian?.vymera}>
          <Money amount={ruian?.vymera} symbol="m²" decimalNumbers={0} />
        </Item>

        <Item title="BPEJ" condition={!!ruian?.bonitovaneJednotky?.length}>
          {ruian?.bonitovaneJednotky?.map((jednotka, index) => (
            <div key={index}>
              <BpejTooltip
                code={jednotka.kod.toString()}
                areaSize={jednotka.vymera}
              >
                {jednotka.kod}
              </BpejTooltip>
            </div>
          ))}
        </Item>
      </>
    )
  }
