import { WarningOutlined } from '@ant-design/icons'
import { Divider, Form, Input, message } from 'antd'
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
} from 'react'
import { gql, useMutation } from 'urql'

import { CeeTableItem } from '../../../common/ceeTypes'
import {
  useCreateLabelConnectionMutation,
  useDeleteLabelConnectionMutation,
} from '../../../graphql/generated'
import { cuzkMonitorRcFormHelp } from '../../utils/cuzkMonitorRcFormHelp'
import { LabelBulkEditOptions } from '../../utils/labelsTypes'
import { formItemLayout } from '../../utils/layoutConst'
import { useConst } from '../../utils/useConst'
import { useDeclension } from '../../utils/useDeclension'
import { CheckFrequencySelectInput } from '../CheckFrequencySelectInput/CheckFrequencySelectInput'
import { LabelEditTypeSelectInput } from '../LabelEditTypeSelectInput/LabelEditTypeSelectInput'
import { LabelSelectInput } from '../LabelSelectInput/LabelSelectInput'

export interface CeeMonitorBulkEditFormProps {
  selectedItems: CeeTableItem[]
  showHelp: boolean
  setLoading: (loading: boolean) => void
  refetch: () => void
  hideModal: () => void
  monitoring?: boolean
  initialValues?: { note?: string; checkFrequency?: number }
}

export const CeeMonitorBulkEditForm = forwardRef<
  unknown,
  CeeMonitorBulkEditFormProps
>((props, ref) => {
  const updateMonitoredSubjectQuery = gql`
    mutation ($note: String, $checkFrequency: Int, $id: UUID!) {
      updateMonitoringCeeById(
        input: {
          monitoringCeePatch: { note: $note, checkFrequency: $checkFrequency }
          id: $id
        }
      ) {
        clientMutationId
      }
    }
  `
  const [form] = Form.useForm()
  useImperativeHandle(ref, () => ({ submit: form.submit }))

  const [, addLabelConnection] = useCreateLabelConnectionMutation()
  const [, deleteLabelConnection] = useDeleteLabelConnectionMutation()
  const [, updateMonitoredSubject] = useMutation(updateMonitoredSubjectQuery)
  const decline = useDeclension()

  const initialValues = useConst({
    note: '[puvodni_poznamka]',
    labelEditType: 'inherit',
    checkFrequency: -1,
    ...props.initialValues,
  })

  const labelEditType = Form.useWatch('labelEditType', form)
  const checkFrequency = Form.useWatch('checkFrequency', form)

  const { warning, frequencyDisabled } = useMemo(() => {
    const notFoundItemsId = props.selectedItems
      .filter(item => !item.found)
      .map(item => item.id)
    const foundItemsCount = props.selectedItems.length - notFoundItemsId.length
    const show =
      checkFrequency !== -1 && checkFrequency !== null && !!foundItemsCount
    const allFound = !notFoundItemsId.length

    const warning = allFound ? (
      <div className="text-yellow-500 mb-2">
        <WarningOutlined /> U subjektů, které byly v CEE nalezeny, již nelze
        opětovně nastavit periodickou kontrolu.
        <br /> Pokud chcete znova ověřit některý z již nalezených subjektů, je
        zapotřebí provést novou jednorázovou kontrolu.
      </div>
    ) : show ? (
      <div className="text-yellow-500 mb-2">
        <div>
          <WarningOutlined /> Nově vybraná frekvence kontroly bude nastavena
          pouze pro subjekty, které doposud nebyly při předešlé kontrole
          evidovány v CEE.
        </div>
        <div className="mt-2">
          {foundItemsCount} z vámi vybraných záznamů již{' '}
          {decline(foundItemsCount, 'beenFound')} v registru Centrální Evidenci
          Exekucí, u evidovaných subjektů byla periodická kontrola definitivně
          pozastavena ihned po nalezení v registru. <br />
          <br /> Pokud chcete opětovně zkontrolovat některý z již nalezených
          subjektů, je zapotřebí provést novou jednorázovou kontrolu, která vám
          umožní získat aktuální data o evidovaných exekucích.
        </div>
      </div>
    ) : null

    return { warning, frequencyDisabled: allFound }
  }, [checkFrequency, decline, props.selectedItems])

  const onFinish = useCallback(
    (newValues: {
      note: string
      labelEditType: string
      checkFrequency: number
      label?: string[]
    }) => {
      props.setLoading(true)

      const promises = props.selectedItems.map(async initialValues => {
        const checkFrequency =
          !props.monitoring ||
          newValues.checkFrequency === -1 ||
          frequencyDisabled ||
          (initialValues.found && newValues.checkFrequency !== 0)
            ? undefined
            : newValues.checkFrequency
        const values = {
          id: initialValues.id,
          note: newValues?.note?.replace(
            '[puvodni_poznamka]',
            initialValues?.note || ''
          ),
          checkFrequency: checkFrequency,
        }
        if (initialValues.validatedCode && initialValues.validatedCode > 10) {
          try {
            await updateMonitoredSubject(values).then(() => {
              const initialLabelsIds = initialValues.labels?.nodes?.map(
                label => label?.labelByLabelId?.id
              )
              const initialLabels = initialValues.labels?.nodes?.map(label => ({
                connectionId: label?.id,
                id: label?.labelByLabelId?.id,
              }))

              if (newValues.labelEditType === LabelBulkEditOptions.Add) {
                newValues?.label?.forEach(
                  labelId =>
                    !initialLabelsIds?.includes(labelId) &&
                    addLabelConnection({
                      monitoringCeeId: values.id,
                      labelId: labelId,
                    })
                )
              }

              if (
                newValues.labelEditType === LabelBulkEditOptions.RemoveSelected
              ) {
                newValues?.label?.forEach(labelId =>
                  initialLabels?.forEach(
                    initialLabel =>
                      initialLabel.id === labelId &&
                      deleteLabelConnection({
                        id: initialLabel.connectionId,
                      })
                  )
                )
              }

              if (newValues.labelEditType === LabelBulkEditOptions.RemoveAll) {
                initialLabels?.forEach(initialLabel =>
                  deleteLabelConnection({
                    id: initialLabel.connectionId,
                  })
                )
              }

              if (newValues.labelEditType === LabelBulkEditOptions.ReplaceBy) {
                newValues?.label?.forEach(labelId =>
                  initialLabels?.forEach(
                    initialLabel =>
                      initialLabel.id !== labelId &&
                      deleteLabelConnection({
                        id: initialLabel.connectionId,
                      })
                  )
                )
                newValues?.label?.forEach(
                  labelId =>
                    !initialLabelsIds?.includes(labelId) &&
                    addLabelConnection({
                      monitoringCeeId: values.id,
                      labelId: labelId,
                    })
                )
              }
            })
          } catch (error) {
            message.error('Chyba při editaci štítků.')
          }
        }
      })

      Promise.all(promises)
        .then(() => {
          props.setLoading(false)
          props.refetch()
          props.hideModal()
          message.success('Záznamy byly uloženy.')
        })
        .catch(() => {
          message.error('Nepodařilo se uložit všechny záznamy.')
        })
    },
    [
      props,
      frequencyDisabled,
      updateMonitoredSubject,
      addLabelConnection,
      deleteLabelConnection,
    ]
  )
  return (
    <Form
      {...formItemLayout}
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        name="note"
        label="Poznámka"
        help={props.showHelp && cuzkMonitorRcFormHelp.note}
      >
        <Input.TextArea placeholder="Napište poznámku k vybraným subjektům" />
      </Form.Item>

      {props.monitoring && (
        <Form.Item
          help={props.showHelp && cuzkMonitorRcFormHelp.frequency}
          name="checkFrequency"
          label="Frekvence kontroly"
          extra={warning}
        >
          <CheckFrequencySelectInput
            initialValue={-1}
            canPause
            canPreserve
            disabled={frequencyDisabled}
          />
        </Form.Item>
      )}

      <Form.Item
        name="labelEditType"
        label="Štítky"
        help={props.showHelp && cuzkMonitorRcFormHelp.labelEditType}
      >
        <LabelEditTypeSelectInput />
      </Form.Item>

      <Form.Item
        name="label"
        label="Vybrané štítky"
        hidden={
          labelEditType === LabelBulkEditOptions.Inherit ||
          labelEditType === LabelBulkEditOptions.RemoveAll
        }
      >
        <LabelSelectInput />
      </Form.Item>

      <Divider />
    </Form>
  )
})
