import { useEffect, useState } from 'react'

import { GOOGLE_MAPS_KEY } from '../../../ini.json'
import { useAppSelector } from '../../redux/hooks'

export const usePropertyDetailImageStreetView = (propertyId: string) => {
  const { lat, long } = useAppSelector(
    state => state.property.ruian.data?.definicniBod
  ) || { lat: null, long: null }

  const ruianId = useAppSelector(state => state.property.ruian.data?.id)

  const [processedId, setProcessedId] = useState<string>()
  const [streetView, setStreetView] = useState({
    src: '',
    link: '',
  })

  useEffect(() => {
    if (propertyId === ruianId && propertyId !== processedId && lat && long) {
      const fetchStreetView = async () => {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/streetview?return_error_code=true&size=2000x2000&location=${lat},${long}&fov=100&pitch=20&source=outdoor&key=${GOOGLE_MAPS_KEY}`
        )
        setStreetView({
          src: response.status === 200 ? response.url : '',
          link:
            response.status === 200
              ? `http://maps.google.com/maps?q=&layer=c&cbll=${lat},${long}`
              : `https://www.google.com/maps?q=${lat},${long}&ll=${lat},${long}&z=17&t=h`,
        })
      }
      setProcessedId(propertyId)
      fetchStreetView()
    }
  }, [lat, long, processedId, propertyId, ruianId])

  return streetView
}
