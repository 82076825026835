import { Spin } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import {
  MonitoringExekuciFetchResult,
  TABLE_VIEW,
} from '../../../common/foreclosureMonitoringTypes'
import { useUserActivityLogCheck } from '../../utils/useCheckUserActivityLog'
import { usePropertyTableNoteCheck } from '../../utils/usePropertyTableNoteCheck'
import { ForeclosureMonitoringTable } from '../ForeclosureMonitoringTable/ForeclosureMonitoringTable'
import { ForeclosureMonitoringTiles } from '../ForeclosureMonitoringTiles/ForeclosureMonitoringTiles'
import { PropertyMonitoringFetchError } from '../PropertyMonitoringFetchError/PropertyMonitoringFetchError'

export interface ForeclosureMonitoringContentProps {
  viewType: string | number
  propertyType: PROPERTY_TYPES
  dataSource?: MonitoringExekuciFetchResult
}

export const ForeclosureMonitoringContent: FunctionComponent<ForeclosureMonitoringContentProps> =
  props => {
    const propertyIds = useMemo(
      () => props.dataSource?.data?.data?.map(item => item.id) || [],
      [props.dataSource]
    )
    const propertiesActivity = useUserActivityLogCheck(propertyIds)
    const propertyNotes = usePropertyTableNoteCheck(propertyIds)

    if (props.dataSource?.error)
      return <PropertyMonitoringFetchError type="exekucí" />

    return (
      <div className="w-full">
        <Spin spinning={props.dataSource?.fetching}>
          {props.viewType === TABLE_VIEW.TILES ? (
            <ForeclosureMonitoringTiles
              data={props.dataSource?.data?.data}
              propertyType={props.propertyType}
              userActivityLogs={propertiesActivity}
              propertyNotes={propertyNotes}
            />
          ) : (
            <ForeclosureMonitoringTable
              data={props.dataSource?.data?.data}
              propertyType={props.propertyType}
              userActivityLogs={propertiesActivity}
              propertyNotes={propertyNotes}
            />
          )}
        </Spin>
      </div>
    )
  }
