import { message } from 'antd'
import dayjs from 'dayjs'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { DrmJednotkaResponse, PROPERTY_TYPES } from '../../../common/drmTypes'
import { LvApiResponse } from '../../../common/lvTypes'
import { PropertyPolygon, RuianAreaResponse } from '../../../common/ruianTypes'
import { safeJsonParsing } from '../../../common/safeJsonParsing'
import { useAppSelector } from '../../../redux/hooks'
import { areaPriceEstimateTitlePrefix as prefix } from '../../utils/layoutConst'
import {
  requestPath,
  requestPropertyDrmPath,
  requestPropertyPolygonPath,
  requestPropertyRuianPath,
} from '../../utils/paths'
import { formatPolygon } from '../../utils/polygonHelpers'
import { PriceEstimateFormValues } from '../PriceEstimateForm/PriceEstimateForm'
import {
  FETCH_ERROR_TYPES,
  PriceEstimateLayout,
} from '../PriceEstimateLayout/PriceEstimateLayout'
import { PriceEstimateLayoutStateAlert } from '../PriceEstimateLayoutStateAlert/PriceEstimateLayoutStateAlert'

export interface PriceEstimateAreaProps {
  setTitle: (title: string) => void
  propertyId: string
}

export interface ExtendedAreaRuian extends PriceEstimateFormValues {
  ruian?: RuianAreaResponse
  totalLandArea?: string
}

export const PriceEstimateArea: FunctionComponent<PriceEstimateAreaProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const [ruianData, setRuianData] = useState<ExtendedAreaRuian>()
    const [lvData, setLvData] = useState<LvApiResponse & DrmJednotkaResponse>()
    const [fetchError, setFetchError] = useState<FETCH_ERROR_TYPES>()

    const typPozemku = useCallback((druhPozemku: number) => {
      switch (druhPozemku) {
        case 2:
          return 1
        case 5:
          return 4
        case 13:
          return 0
        default:
          return 4
      }
    }, [])

    const fetchData = useCallback(async () => {
      try {
        const response = await fetch(
          requestPropertyRuianPath(PROPERTY_TYPES.AREA, token, props.propertyId)
        )
        const polygonResponse = await fetch(
          requestPropertyPolygonPath(
            PROPERTY_TYPES.AREA,
            token,
            props.propertyId
          )
        )

        const data: RuianAreaResponse = await response?.json()
        const polygonData: PropertyPolygon = await polygonResponse?.json()
        const polygon = formatPolygon(polygonData?.polygons?.[0]?.exterior)

        if (
          data?.obec?.kod &&
          data?.definicniBod?.lat &&
          data?.definicniBod?.long
        )
          setRuianData({
            datum: dayjs(),
            kod_obce: data.obec.kod,
            typ_nemovitosti: 0,
            typ_pozemku: typPozemku(data.druhPozemku?.kod),
            lat: data.definicniBod.lat,
            lon: data.definicniBod.long,
            polygon: polygon,
            pl_celkova: data?.vymera,
            title: `${prefix}${data.nazev}`,
            ruian: data,
          })
        props.setTitle(`: ${data.nazev}`)
      } catch (error) {
        message.error('Došlo k chybě při zpracování požadavku.')
        return setFetchError(FETCH_ERROR_TYPES.RUIAN)
      }

      try {
        const drmResponse = await fetch(
          requestPropertyDrmPath(PROPERTY_TYPES.AREA, token, props.propertyId)
        )

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const drmData: any = await drmResponse?.json()
        const lv = drmData?.lv
        const katastralniUzemiKod = drmData?.katastralniUzemi?.kod

        const lvResponse = await fetch(
          `${requestPath()}/lv/${tenantId}/${token}/${katastralniUzemiKod}/${lv}`
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const lvData: any = await lvResponse?.json()

        const lvGeometryResponse = await fetch(
          `${requestPath()}/lvGeometry/${tenantId}/${token}/${katastralniUzemiKod}/${lv}`
        )
        const lvGeometryTextResponse = await lvGeometryResponse.text()
        const lvGeometryData =
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          safeJsonParsing<any>(lvGeometryTextResponse, []) ||
          lvGeometryTextResponse

        Object.keys(lvData?.nemovitosti).forEach(key => {
          if (lvGeometryData[key]) {
            lvData.nemovitosti[key] = lvData?.nemovitosti[key].map(
              (nemovitost: { id: string }) => {
                const vymera = lvGeometryData[key].find(
                  (item: { id: string }) => item.id === nemovitost.id
                )
                return vymera ? { ...nemovitost, ...vymera } : nemovitost
              }
            )
          }
        })

        setLvData({ ...lvData, ...drmData, ...lvGeometryData })
      } catch (error) {
        setFetchError(FETCH_ERROR_TYPES.LV)
        message.error('Došlo k chybě při načítání dat.')
      }
    }, [props, tenantId, token, typPozemku])

    useEffect(() => {
      if (!ruianData) {
        fetchData()
      }
    }, [ruianData, fetchData])

    if (fetchError === FETCH_ERROR_TYPES.RUIAN)
      return (
        <PriceEstimateLayoutStateAlert
          loading={!ruianData}
          fetchError={fetchError}
        />
      )
    return (
      <PriceEstimateLayout
        ruianData={ruianData}
        lvData={lvData}
        propertyId={props.propertyId}
        propertyType={PROPERTY_TYPES.AREA}
        fetchError={fetchError}
      />
    )
  }
