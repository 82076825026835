export enum EsriFieldType {
  Integer = 'esriFieldTypeInteger',
  SmallInteger = 'esriFieldTypeSmallInteger',
  Double = 'esriFieldTypeDouble',
  Single = 'esriFieldTypeSingle',
  String = 'esriFieldTypeString',
  Date = 'esriFieldTypeDate',
  Geometry = 'esriFieldTypeGeometry',
  OID = 'esriFieldTypeOID',
  Blob = 'esriFieldTypeBlob',
  GlobalID = 'esriFieldTypeGlobalID',
  Raster = 'esriFieldTypeRaster',
  GUID = 'esriFieldTypeGUID',
  XML = 'esriFieldTypeXML',
}

export enum EsriMergePolicy {
  DefaultValue = 'esriMPTDefaultValue',
  AreaWeighted = 'esriMPTAreaWeighted',
  SumValues = 'esriMPTSumValues',
  Manual = 'esriMPTManual',
}

export enum EsriSplitPolicy {
  DefaultValue = 'esriSPTDefaultValue',
  AreaWeighted = 'esriSPTAreaWeighted',
  Geography = 'esriSPTGeography',
  Manual = 'esriSPTManual',
}

export interface EsriFieldDomain {
  type: string
  name: string
  description: string
  codedValues: Array<{ name: string; code: number }>
  mergePolicy: EsriMergePolicy
  splitPolicy: EsriSplitPolicy
}

export interface EsriField {
  name: string
  type: EsriFieldType
  alias: string
  length?: number
  domain?: EsriFieldDomain
}
