import { Maybe } from 'graphql/jsutils/Maybe'
import React, { FunctionComponent } from 'react'

export interface PriceEstimateGraphProps {
  timeSeriesGraph: Maybe<string>
  className?: string
  realPrice?: boolean
}

export const PriceEstimateGraph: FunctionComponent<PriceEstimateGraphProps> =
  props => {
    if (!props.timeSeriesGraph) {
      return null
    }
    const priceType = props.realPrice ? 'prodejní' : 'nabídkové'

    return (
      <>
        <div
          className={
            'w-full border-b border-gray-200 text-gray-400 -mb-2 ' +
            props.className
          }
        >
          Graf vývoje {priceType} ceny (Kč/m²)
        </div>
        <svg
          className="priceEstimateHistorySvg overflow-visible"
          viewBox="0 0 1200 600"
          dangerouslySetInnerHTML={{
            __html: props.timeSeriesGraph || '',
          }}
        />
      </>
    )
  }
