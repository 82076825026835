import React from 'react'

import { AddressApiResponse } from '../../common/addressTypes'
import { CopyButton } from '../components/CopyButton/CopyButton'

export const formatAddress = (addressData?: Partial<AddressApiResponse>) => {
  const address = {
    street:
      (addressData?.ulice || addressData?.obec) +
      ' ' +
      addressData?.cisloDomovni +
      ' ' +
      (addressData?.cisloOrientacni
        ? '/' + addressData?.cisloOrientacni
        : '' + (addressData?.cisloOrientacniPismeno || '')),
    city: addressData?.obec,
    zipCode: addressData?.psc,
    state: addressData?.kraj,
    country: 'Česká republika',
  }

  const string = `${address?.street ? address?.street + ', ' : ''}${
    address?.city ? address?.city + ' ' : ''
  }${address?.zipCode ? ', ' + address?.zipCode : ''}`

  /* ${
    address?.state ? address?.state + ', ' : ''
  }${address?.country ? address?.country : ''}` */

  const object = (
    <div>
      <div>{address?.street ? address.street : ''}</div>
      <div>
        {address?.city ? address.city + ', ' : ''}
        {address?.zipCode ? address.zipCode : ''}
      </div>
      {/*       <div>
        {address?.state ? address.state + ', ' : ''}
        {address?.country ? address.country : ''}
      </div> */}
    </div>
  )
  const googleMapUrl = `https://www.google.com/maps/place/${string.replace(
    '/',
    '+'
  )}`
  const mapyCzUrl = `https://mapy.cz/zakladni?q=${string.replace('/', '+')}`

  if (!address) return null
  return {
    string,
    object,
    googleMapUrl,
    mapyCzUrl,
    copyButton: <CopyButton copyText={string} />,
    street: address?.street,
    city: address?.city,
    zipCode: address.zipCode,
    state: address?.state,
    country: address?.country,
  }
}
