import React, { FunctionComponent } from 'react'

export interface SubjectLinkProps {
  subjectId?: number | string | null
  children?: React.ReactNode
  className?: string
  newTab?: boolean
}
const urlBase = 'https://data.regesta.cz/Vyhledavani/Subjekt/'

export const SubjectLink: FunctionComponent<SubjectLinkProps> = props => {
  if (!props.subjectId && !props.children) return null
  if (!props.subjectId) return <span>{props.children}</span>
  return (
    <a
      href={urlBase + props.subjectId}
      title="Zobrazit detail subjektu"
      className={props.className}
      target={props.newTab ? '_blank' : '_self'}
      rel="noreferrer"
    >
      {props.children || 'Detail subjektu'}
    </a>
  )
}
