import { createSlice } from '@reduxjs/toolkit'

import { CrmDrmDataCheck } from '../common/drmTypes'
import handleAsyncActions, {
  AsyncState,
  initialFetchState,
  PropertyDataTypes,
} from './fetchAction'

export const crmDrmSlice = createSlice({
  name: PropertyDataTypes.CRM,
  initialState: initialFetchState as AsyncState<CrmDrmDataCheck>,
  reducers: {},
  extraReducers: builder => {
    handleAsyncActions<CrmDrmDataCheck>(builder, PropertyDataTypes.CRM)
  },
})

export default crmDrmSlice.reducer
