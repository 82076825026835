import isToday from 'date-fns/isToday'
import React, { FunctionComponent, useMemo } from 'react'
import { GetItemsProps, Id, TimelineItemBase } from 'react-calendar-timeline'

import { timelineDateTitle } from '../../utils/propertyTimelineHelpers'

export interface PropertyTimelineItemProps {
  item: TimelineItemBase<number>
  getItemProps: (props: GetItemsProps) => {
    key: Id
    className: string
    style: React.CSSProperties
  }
}

export const PropertyTimelineItem: FunctionComponent<PropertyTimelineItemProps> =
  props => {
    const { isCurrent, title } = useMemo(
      () => ({
        isCurrent: isToday(props.item.end_time),
        title: timelineDateTitle(props.item.start_time, props.item.end_time),
      }),
      [props.item.end_time, props.item.start_time]
    )

    const timelineItemProps = useMemo(() => {
      const baseProps = props.getItemProps({ style: { height: 40 } })
      const dynamicStyles = {
        minWidth: baseProps.style.width,
        maxWidth: baseProps.style.width,
        height: baseProps.style.height,
      }

      return {
        ...baseProps,
        style: {
          ...baseProps.style,
          ...dynamicStyles,
        },

        className: `${
          baseProps.className
        } h-10 transition-height group truncate flex items-center ${
          isCurrent ? '!rounded-l-lg !border-r-0' : '!rounded-lg'
        } !border-gray-400 !text-current w-${
          baseProps.style.width
        } hover:!z-[100] hover:!w-screen hover:!max-w-fit hover:shadow-md hover:shadow-white !cursor-default`,
      }
    }, [isCurrent, props])

    return (
      <div {...timelineItemProps} title={title}>
        {props.item.title}
      </div>
    )
  }
