import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons'
import { Button, Tag, Tooltip } from 'antd'
import React, { FunctionComponent } from 'react'

import { Min } from '../DateFormat/DateFormat'

interface CuzkMonitorRcTableStatusCellProps {
  state: boolean | undefined
  record?: {
    id?: string
    checkFrequency?: number
    lastCheck?: string
    found?: boolean
  }
  loadingId: string[]
  handleExpandRow: (id: string) => void
}

export const CuzkMonitorRcTableStatusCell: FunctionComponent<CuzkMonitorRcTableStatusCellProps> =
  ({ state, record, loadingId, handleExpandRow }) => {
    if (!record?.id)
      return (
        <Button
          size="small"
          type="link"
          title="Ověřit, zda se tento subjekt vyskytuje v evidenci katastrálního úřadu."
          className="!text-xs"
        >
          Ověřit v ČÚZK
        </Button>
      )

    switch (true) {
      case loadingId.includes(record.id):
        return (
          <Tooltip
            title={`Záznam se právě kontroluje, výsledek bude dostupný zhruba do ${loadingId.length} sekund.`}
          >
            <Tag color="blue" icon={<SyncOutlined spin />}>
              Ověřuje se
            </Tag>
          </Tooltip>
        )
      case state:
        return (
          <Tooltip title="Kliknutím zobrazíte detail">
            <Tag
              onClick={() => handleExpandRow(record.id || '')}
              icon={<CheckCircleOutlined />}
              color="green"
              className="hover:underline cursor-pointer"
            >
              Nalezeno
            </Tag>
          </Tooltip>
        )

      case state === null:
        return typeof record.checkFrequency === 'number' ? (
          <Tooltip title="Záznam nebyl prozatím zkontrolován, ke kontrole dojde v nejbližším možném termínu (zhruba do 10 minut).">
            <Tag color="blue" icon={<SyncOutlined spin />}>
              Ve frontě
            </Tag>
          </Tooltip>
        ) : (
          <Tooltip title="Kontrola byla pozastavena ještě před ověřením v registru.">
            <Tag className="!opacity-40">Pozastaveno</Tag>
          </Tooltip>
        )

      default:
        return (
          <Tooltip
            title={
              <div>
                <span>Při poslední kontrole </span>
                <Min>{record.lastCheck}</Min>
                <span> nebylo RČ evidováno v ČÚZK.</span>
              </div>
            }
          >
            <Tag>Nenalezeno</Tag>
          </Tooltip>
        )
    }
  }
