import { Icon } from '@iconify/react'
import { Button, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { ExpandableConfig } from 'antd/es/table/interface'
import { compareAsc } from 'date-fns'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { Rizeni } from '../../../common/proceedingTypes'
import { useAppSelector } from '../../../redux/hooks'
import { tablePagination } from '../../utils/layoutConst'
import {
  getProceedingKey,
  getProceedingShortTitle,
} from '../../utils/proceedingDetailHelpers'
import { useHandleExpandRow } from '../../utils/useHandleExpandRow'
import { Day } from '../DateFormat/DateFormat'
import { ExpandColumnTitle } from '../ExpandColumnTitle/ExpandColumnTitle'
import { ExpandIcon } from '../ExpandIcon/ExpandIcon'
import { PropertyDetailProceedingTableExpandedRow } from '../PropertyDetailProceedingTableExpandedRow/PropertyDetailProceedingTableExpandedRow'

export type PropertyDetailProceedingTableItem = Rizeni & {
  key: string
  hasDocument?: boolean
}

export interface PropertyDetailProceedingTableProps {
  withDocument?: string[]
}

const tableId = 'property-proceeding'

export const PropertyDetailProceedingTable: FunctionComponent<PropertyDetailProceedingTableProps> =
  props => {
    const timeline = useAppSelector(state => state.property.timeline)

    const handleExpandRow = useHandleExpandRow(tableId)
    const expandedRowKeys = useAppSelector(
      state => state.expandedRow.tables?.[tableId] || []
    )

    const dataSource = useMemo(
      () =>
        timeline.data?.rizeni?.map(rizeni => ({
          ...rizeni,
          hasDocument: props.withDocument?.includes(getProceedingKey(rizeni)),
          key: getProceedingShortTitle(rizeni),
        })),
      [props.withDocument, timeline.data?.rizeni]
    )

    const columns: ColumnsType<PropertyDetailProceedingTableItem> = useMemo(
      () => [
        {
          title: 'Č. řízení',
          dataIndex: 'key',
          sorter: (a, b) =>
            (b.typ + '-' + b.rok + '-' + b.cislo).localeCompare(
              a.typ + '-' + a.rok + '-' + a.cislo
            ),
        },
        {
          title: 'Zahájení',
          dataIndex: 'datumPrijeti',
          defaultSortOrder: 'descend',
          sorter: (a, b) => {
            const dateA = a.datumPrijeti ? new Date(a.datumPrijeti) : new Date()
            const dateB = b.datumPrijeti ? new Date(b.datumPrijeti) : new Date()
            return compareAsc(dateA, dateB)
          },
          render: datumPrijeti => datumPrijeti && <Day>{datumPrijeti}</Day>,
        },
        {
          title: 'Ukončení',
          dataIndex: 'datumUkonceni',
          sorter: (a, b) => {
            const dateA = a.datumUkonceni
              ? new Date(a.datumUkonceni)
              : new Date()
            const dateB = b.datumUkonceni
              ? new Date(b.datumUkonceni)
              : new Date()
            return compareAsc(dateA, dateB)
          },
          render: datumUkonceni => datumUkonceni && <Day>{datumUkonceni}</Day>,
        },
        {
          title: 'Předmět',
          dataIndex: 'predmety',
          className: 'whitespace-pre-line',
          sorter: (a, b) => a.predmety.join().localeCompare(b.predmety.join()),
          render: predmety => predmety.join(`,\n`),
        },
        {
          title: 'Účastníci OVN',
          dataIndex: 'ucastniciOVN',
          className: 'whitespace-pre-line',
          sorter: (a, b) =>
            a.ucastniciOVN.join().localeCompare(b.ucastniciOVN.join()),
          render: ucastnici => ucastnici.join(`,\n`),
        },
        {
          title: 'Účastníci PZP',
          dataIndex: 'ucastniciPZP',
          className: 'whitespace-pre-line',
          sorter: (a, b) =>
            a.ucastniciPZP.join().localeCompare(b.ucastniciPZP.join()),
          render: ucastnici => ucastnici.join(`,\n`),
        },
        {
          dataIndex: 'hasDocument',
          showSorterTooltip: {
            title: 'Seřadit podle stažených listin',
          },
          className: 'w-4',
          sorter: (a, b) =>
            (b.hasDocument ? 'y' : 'n').localeCompare(
              a.hasDocument ? 'y' : 'n'
            ),
          render: (hasDocument, record) =>
            hasDocument && (
              <Tooltip title="U řízení jste stahovali listinu">
                <div>
                  <Icon
                    onClick={() => handleExpandRow(record.key)}
                    className="cursor-pointer hover:text-primary transition-colors"
                    icon="material-symbols-light:sim-card-download-outline-sharp"
                    width="18"
                    height="18"
                  />
                </div>
              </Tooltip>
            ),
        },
        {
          title: 'Akce',
          dataIndex: 'action',
          className: '!text-right w-10',
          render: (_, record) => (
            <Button
              id={record.key}
              type="link"
              size="small"
              onClick={() => handleExpandRow(record.key)}
            >
              Detail řízení
            </Button>
          ),
        },
      ],
      [handleExpandRow]
    )

    const expandable = useMemo<
      ExpandableConfig<PropertyDetailProceedingTableItem>
    >(() => {
      return {
        expandedRowKeys,
        expandedRowRender: record => (
          <PropertyDetailProceedingTableExpandedRow record={record} />
        ),
        columnTitle: (
          <ExpandColumnTitle
            allKeys={dataSource?.map(record => record.key) || []}
            tableId={tableId}
          />
        ),
        expandIcon: ({ expanded, record }) => (
          <ExpandIcon
            expanded={expanded}
            recordId={record.key}
            tableId={tableId}
            expandable
          />
        ),
      }
    }, [dataSource, expandedRowKeys])

    const expandedClassName = useCallback(
      (record: PropertyDetailProceedingTableItem) =>
        expandedRowKeys.includes(record.key) ? 'rowExpanded fade-in' : '',
      [expandedRowKeys]
    )

    return (
      <Table
        size="small"
        id={tableId}
        expandable={expandable}
        columns={columns}
        rowClassName={expandedClassName}
        dataSource={dataSource}
        loading={timeline.fetching}
        pagination={tablePagination}
      />
    )
  }
