import { Icon } from '@iconify/react'
import { Alert, Modal, Tooltip } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { PieChart, pieChartDefaultProps } from 'react-minimal-pie-chart'

import { propertyChartProps } from '../../../common/chartHelpers'
import { MaybeArray, SUBJEKT } from '../../../common/cuzkLvTypes'
import { getOpsubShortName, notEmpty } from '../../../common/pdfHelpers'
import { PieChartColorPalette } from '../../utils/collorPallete'
import { CuzkLvHtmlOpravSubjektFragment } from '../CuzkLvHtmlOpravSubjektFragment/CuzkLvHtmlOpravSubjektFragment'
import { CuzkLvHtmlSingleOwnerInfo } from '../CuzkLvHtmlSingleOwnerInfo/CuzkLvHtmlSingleOwnerInfo'
import { CuzkLvHtmlTooManyOwnersInfo } from '../CuzkLvHtmlTooManyOwnersInfo/CuzkLvHtmlTooManyOwnersInfo'
import { Money } from '../Money/Money'

type SegmentData = {
  index: number
  title: string
  value: number
  color?: string
}
type SortedData = {
  title: string
  value: number
  color: string
  subject?: SUBJEKT
}
type SelectedData = {
  segment: SegmentData | null
  subject: SUBJEKT | undefined
}

export interface CuzkLvHtmlVlastniciGrafModalButtonProps {
  SUBJEKT?: MaybeArray<SUBJEKT>
}

export const CuzkLvHtmlVlastniciGrafModalButton: FunctionComponent<CuzkLvHtmlVlastniciGrafModalButtonProps> =
  ({ SUBJEKT }) => {
    const subjects = useMemo(
      () => (Array.isArray(SUBJEKT) ? SUBJEKT : [SUBJEKT]),
      [SUBJEKT]
    )

    const [isModalOpen, setIsModalOpen] = useState(false)
    const handleModal = () => setIsModalOpen(open => !open)

    const tooManyOwners = subjects.length > 10
    const singleOwner = subjects.length === 1 && subjects[0]

    const [selected, setSelected] = useState<SelectedData>(
      singleOwner
        ? { segment: { value: 100, index: 0, title: '' }, subject: singleOwner }
        : propertyChartProps.notSelected
    )

    const prepareOwnerData = useMemo(() => {
      const sortedData: SortedData[] =
        subjects
          ?.map((subj, index) => {
            const share =
              parseFloat(subj?.PODIL?.citatel?.toString() || '1') /
              parseFloat(subj?.PODIL?.jmenovatel?.toString() || '1')

            const name = getOpsubShortName(subj) || `Neznámý ${index + 1}`

            return {
              title: name,
              value: share * 100,
              color: '',
              subject: subj,
            }
          })
          .sort((a, b) => b.value - a.value) || []

      let othersSum = 0
      if (tooManyOwners) {
        othersSum = sortedData
          .slice(10)
          .reduce((acc, curr) => acc + curr.value, 0)
      }

      const pieChartData = [...sortedData]

      pieChartData.forEach((data, index) => {
        if (index >= 10) {
          data.title =
            index === Math.round(sortedData.length / 2)
              ? `Ostatní (${othersSum.toFixed(2)}%)`
              : ''
          data.color = '#ddd'
        } else {
          data.color = PieChartColorPalette[index % PieChartColorPalette.length]
        }
      })

      return {
        modalTitle: `Celkem ${subjects.length} vlastnických podílů`,
        pieChartData,
      }
    }, [subjects, tooManyOwners])

    const onMouseOverHandler = useCallback(
      (_: unknown, index: number) => {
        const hoveredData = prepareOwnerData.pieChartData[index]
        setSelected({
          segment: { ...hoveredData, index },
          subject: hoveredData.subject,
        })
      },
      [prepareOwnerData.pieChartData]
    )

    const pieChartProp = useMemo(
      () => ({
        center: (tooManyOwners ? [75, 60] : [100, 60]) as [number, number],
        data: prepareOwnerData.pieChartData,
        radius: pieChartDefaultProps.radius - 6,
        segmentsStyle: (index: number) => ({
          transition: 'stroke .5s',
          cursor: 'pointer',
          opacity: index === selected.segment?.index ? 0.5 : 1,
        }),
        onMouseOver: onMouseOverHandler,
        label: ({
          dataEntry: { title, value },
        }: {
          dataEntry: {
            title: string
            percentage: number
            value: number
          }
        }) =>
          title.startsWith('Ostatní')
            ? title
            : title
            ? `${title} (${value.toFixed(2)} %)`
            : '',
        ...propertyChartProps.pieChartProp,
      }),
      [
        onMouseOverHandler,
        prepareOwnerData.pieChartData,
        selected.segment?.index,
        tooManyOwners,
      ]
    )

    const footer = useMemo(
      () =>
        selected.subject?.OPSUB_IDENT?.id ? (
          <Alert
            message={
              <div className="text-left">
                <Money
                  className="font-bold mr-2"
                  amount={selected.segment?.value}
                  symbol="%"
                />
                <CuzkLvHtmlOpravSubjektFragment
                  opravSubjekt={selected.subject?.VLA_IDENT?.oprav_subjekt}
                  active
                  doubleLine
                />
              </div>
            }
            type="success"
            onClose={() => setSelected(propertyChartProps.notSelected)}
            closable
          />
        ) : undefined,
      [
        selected.segment?.value,
        selected.subject?.OPSUB_IDENT?.id,
        selected.subject?.VLA_IDENT?.oprav_subjekt,
      ]
    )

    if (!SUBJEKT || !notEmpty(SUBJEKT)) {
      return null
    }
    return (
      <>
        <Tooltip title="Zobrazit přehled vlastníků v grafu">
          <span
            className="text-primary cursor-pointer relative"
            onClick={handleModal}
          >
            <span className="absolute -left-4">
              <Icon icon="ion:bar-chart" width="12" />
            </span>
            Vlastnické právo
          </span>
        </Tooltip>
        <Modal
          title={prepareOwnerData.modalTitle}
          open={isModalOpen}
          onCancel={handleModal}
          footer={footer}
          {...propertyChartProps.modalProps}
        >
          <div className="border-y">
            {tooManyOwners && <CuzkLvHtmlTooManyOwnersInfo />}
            {singleOwner ? (
              <CuzkLvHtmlSingleOwnerInfo subject={singleOwner} />
            ) : (
              <PieChart {...pieChartProp} />
            )}
          </div>
        </Modal>
      </>
    )
  }
