import { Image, Text, View } from '@react-pdf/renderer'
import React, { FunctionComponent } from 'react'

import { monitPdfCss } from '../../utils/monitPdfCss'

export interface PriceEstimatePdfPriceGraphFragmentProps {
  graphImageString?: string | null
  realPrice?: boolean
}

export const PriceEstimatePdfPriceGraphFragment: FunctionComponent<PriceEstimatePdfPriceGraphFragmentProps> =
  props => {
    if (!props.graphImageString) return null
    const priceType = props.realPrice ? 'prodejní' : 'nabídkové'

    return (
      <View style={monitPdfCss.relative} wrap={false}>
        <Image src={props.graphImageString} />
        <View style={monitPdfCss.graphTitleContainer}>
          <Text style={monitPdfCss.graphTitle}>
            Vývoj {priceType} ceny (Kč/m²) v průběhu let.
          </Text>
        </View>
      </View>
    )
  }
