import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table/interface'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { safeJsonParsing } from '../../../common/safeJsonParsing'
import {
  PropertyNoteFilter,
  usePropertyNoteTableQuery,
} from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { ColorPalleteKey } from '../../utils/collorPallete'
import { stringSorter } from '../../utils/generalTableHelpers'
import { IconPalleteKey } from '../../utils/iconPallete'
import { sparsePagination } from '../../utils/layoutConst'
import {
  PropertyDetailNote,
  PropertyNoteLink,
} from '../../utils/propertyDetailNoteHelpers'
import { useSetPropertyDrawer } from '../../utils/useSetPropertyDrawer'
import { Min } from '../DateFormat/DateFormat'
import { NoteIcon } from '../NoteIcon/NoteIcon'
import { PropertyDetailNoteDeleteButton } from '../PropertyDetailNoteDeleteButton/PropertyDetailNoteDeleteButton'
import { PropertyDetailNoteEditButton } from '../PropertyDetailNoteEditButton/PropertyDetailNoteEditButton'
import { PropertyDetailNoteLinks } from '../PropertyDetailNoteLinks/PropertyDetailNoteLinks'
import { TableSummaryExport } from '../TableSummaryExport/TableSummaryExport'

export interface PropertyNoteTableProps {
  noteFilter?: PropertyNoteFilter
}

const tableId = 'propertyNote-table'

const exportData = (dataSource: PropertyDetailNote[]) => {
  return dataSource.map(item => ({
    ...item,
    links: item.links && safeJsonParsing<PropertyNoteLink[]>(item.links, []),
  }))
}

export const PropertyNoteTable: FunctionComponent<PropertyNoteTableProps> =
  () => {
    const setDrawer = useSetPropertyDrawer()
    const { tenantId, userId } = useAppSelector(state => state.myProfile)

    const [dataSource, setDataSource] = useState<PropertyDetailNote[]>([])

    const [queryResult, refetch] = usePropertyNoteTableQuery({
      variables: { accountId: tenantId },
    })

    useEffect(() => {
      if (queryResult?.data?.allPropertyNotes?.nodes) {
        setDataSource(
          queryResult.data.allPropertyNotes.nodes as PropertyDetailNote[]
        )
      }
    }, [queryResult?.data?.allPropertyNotes?.nodes])

    const columns = useMemo<ColumnsType<PropertyDetailNote>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Vytvořeno',
          dataIndex: 'createdAt',
          defaultSortOrder: 'descend',
          className: 'w-32',
          sorter: {
            compare: (a, b) =>
              a.createdAt.toString().localeCompare(b.createdAt.toString()),
          },
          render: createdAt => <Min>{createdAt}</Min>,
        },
        {
          title: 'Typ',
          dataIndex: 'propertyType',
          className: 'w-20 first-letter:uppercase',
          sorter: stringSorter('propertyType'),
        },
        {
          title: 'Nemovitost',
          dataIndex: 'propertyName',
          className: 'w-52 first-letter:uppercase',
          sorter: stringSorter('propertyName'),
          render: (propertyName, record) => (
            <a
              onClick={() =>
                setDrawer(
                  record.propertyId,
                  record.propertyType as PROPERTY_TYPES
                )
              }
              title="Zobrazit náhled nemovitosti"
              className="whitespace-nowrap first-letter:uppercase"
            >
              {propertyName}
            </a>
          ),
        },
        {
          title: 'Ikona',
          dataIndex: 'icon',
          className: 'w-12 !py-0',
          sorter: stringSorter('iconKey'),
          render: (_, record) => (
            <div className="flex justify-center scale-75">
              <NoteIcon
                colorKey={record.colorKey as ColorPalleteKey}
                iconKey={record.iconKey as IconPalleteKey}
              />
            </div>
          ),
        },
        {
          title: 'Poznámka',
          dataIndex: 'note',
          sorter: stringSorter('note'),
          render: (_, record) => (
            <div>
              {record.note}
              <PropertyDetailNoteLinks links={record.links} />
            </div>
          ),
        },
        {
          title: 'Akce',
          dataIndex: 'actions',
          key: 'actions',
          className: 'w-24 !text-right',
          render: (_, record) =>
            userId === record.accountUserId ? (
              <div className="flex space-x-2 text-right">
                <PropertyDetailNoteEditButton note={record} refetch={refetch} />
                <PropertyDetailNoteDeleteButton
                  noteId={record.id}
                  refetch={refetch}
                />
              </div>
            ) : null,
        },
      ],
      [refetch, setDrawer, userId]
    )

    const summary = useCallback(
      () => (
        <TableSummaryExport
          total={dataSource?.length || 0}
          colSpan={columns.length - 2}
          url="propertyNote/noteXlsx"
          fileName="Poznamky"
          dataSource={exportData(dataSource) || []}
        />
      ),
      [columns.length, dataSource]
    )

    return (
      <Table
        id={tableId}
        rowKey="id"
        size="small"
        rowClassName="animate-fadeIn"
        dataSource={dataSource}
        columns={columns}
        loading={queryResult.fetching}
        summary={summary}
        pagination={sparsePagination}
      />
    )
  }
