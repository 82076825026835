import { Typography } from 'antd'
import React, { FunctionComponent, memo } from 'react'

export interface AuctionDetailDrawerTitleProps {
  detailUrl: string
  title: string
}

const { Title } = Typography
const titleStyle = { marginBottom: 0 }

export const AuctionDetailDrawerTitle: FunctionComponent<AuctionDetailDrawerTitleProps> =
  memo(props => {
    return (
      <div className="flex flex-col">
        <a
          href={props.detailUrl}
          target="_blank"
          rel="noreferrer"
          title="Přejít na dražbu v novém okně"
        >
          <Title
            level={3}
            style={titleStyle}
            className="hover:!text-primaryHover transition-colors first-letter:uppercase"
          >
            {props.title}
          </Title>
        </a>
      </div>
    )
  })
