import { Tooltip } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { ParcelaType } from '../../../common/cuzkLvTypes'
import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { appendSpace } from '../../../common/pdfHelpers'
import { useAppSelector } from '../../../redux/hooks'
import { propertyDetailPath } from '../../utils/paths'

export interface CuzkLvHtmlParcelaIdentFragmentProps {
  parcelaIdent?: ParcelaType
  active?: boolean
  breakLine?: boolean
  noLabel?: boolean
}

export const CuzkLvHtmlParcelaIdentFragment: FunctionComponent<CuzkLvHtmlParcelaIdentFragmentProps> =
  props => {
    const parcela = props.parcelaIdent

    const nemovitosti = useAppSelector(state => state.lvDetail.nemovitosti)
    const findArea = useCallback(
      (area: string) => {
        if (props.active) {
          for (const parcela of nemovitosti.parcely) {
            if (parcela.parcelniCislo === area.trim()) {
              return (
                <Tooltip title="Zobrazit detail parcely v novém okně">
                  <Link
                    target="_blank"
                    to={propertyDetailPath(PROPERTY_TYPES.AREA, parcela.id)}
                  >
                    {area}
                  </Link>
                </Tooltip>
              )
            }
          }
        }
        return area
      },
      [nemovitosti.parcely, props.active]
    )

    return (
      <span className={props.breakLine ? 'breakLine' : ''}>
        {props.noLabel ? '' : 'Parcela: '}
        {appendSpace(parcela?.zkratka)}
        {/* {parcela?.id} 
        {parcela?.cislo_tel} 
        {parcela?.druh_cis} 
        {parcela?.dil_parcely} 
        {parcela?.ku_nazev} 
        {parcela?.nazev_puv} 
        {parcela?.tel_id} */}
        {findArea(
          `${parcela?.par_cis}${
            parcela?.podd_cis ? '/' + parcela?.podd_cis : ''
          }`
        )}
      </span>
    )
  }
