import { Spin, Switch, Table, Typography } from 'antd'
import {
  ColumnsType,
  ExpandableConfig,
  TableRowSelection,
} from 'antd/es/table/interface'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { PropertyDetailSubjectsTableItem as SubjectItem } from '../../../common/propertyDetailTypes'
import { useAppSelector } from '../../../redux/hooks'
import { propertyDetailTableProps } from '../../utils/layoutConst'
import {
  PropertyDetailSubjectsTableAddressChanged as AddressChanged,
  PropertyDetailSubjectsTableEmptyText as EmptyText,
  PropertyDetailSubjectsTableKey as TableKey,
  PropertyDetailSubjectsTableTitle as TableTitle,
} from '../../utils/propertyDetailSubjectsHelper'
import { useDeclension } from '../../utils/useDeclension'
import { useSubjectsInPostService } from '../../utils/useSubjectsInPostService'
import { useToggleState } from '../../utils/useToggleState'
import { AddressDistance } from '../AddressDistance/AddressDistance'
import { ExpandColumnTitle } from '../ExpandColumnTitle/ExpandColumnTitle'
import { ExpandIcon } from '../ExpandIcon/ExpandIcon'
import { PropertyDetailSubjectsTableBulkEdit } from '../PropertyDetailSubjectsTableBulkEdit/PropertyDetailSubjectsTableBulkEdit'
import { PropertyDetailSubjectsTableExpandedRow } from '../PropertyDetailSubjectsTableExpandedRow/PropertyDetailSubjectsTableExpandedRow'
import { PropertyDetailSubjectsTableSummary } from '../PropertyDetailSubjectsTableSummary/PropertyDetailSubjectsTableSummary'
import { PropertyDetailSubjectTypeTableCell } from '../PropertyDetailSubjectTypeTableCell/PropertyDetailSubjectTypeTableCell'
import { ShowHistorySwitch } from '../ShowHistorySwitch/ShowHistorySwitch'
import { SubjectTableCellPostService } from '../SubjectTableCellPostService/SubjectTableCellPostService'

export interface PropertyDetailSubjectsTableProps {
  tableKey: TableKey
  dataSource: SubjectItem[]
  addressId: number
}

const { Title } = Typography
const historyClassName = (record: SubjectItem) =>
  record.aktualni ? '' : 'opacity-40'

export const PropertyDetailSubjectsTable: FunctionComponent<PropertyDetailSubjectsTableProps> =
  props => {
    const decline = useDeclension()

    const rzpSubjects = useAppSelector(state => state.property.rzpSubjects)
    const lvDetail = useAppSelector(state => state.property.lv)
    const expandedRowKeys = useAppSelector(
      state => state.expandedRow.tables?.[props.tableKey]
    )

    const [showHistory, setShowHistory] = useToggleState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [selectedRow, setSelectedRow] = useState<SubjectItem[]>([])

    const expandableKeys = useMemo(
      () =>
        props.dataSource.reduce((keys: string[], record) => {
          if (record.angazovaneOsoby?.length) {
            keys.push(record.key)
          }
          return keys
        }, []),
      [props.dataSource]
    )

    const dataSource = useMemo(
      () =>
        showHistory
          ? props.dataSource
          : props.dataSource.filter(subject => subject.aktualni),
      [showHistory, props.dataSource]
    )
    const uniquePersonKeys = useMemo(
      () => dataSource.map(subject => subject.uniquePersonKey)?.filter(Boolean),
      [dataSource]
    )

    const {
      subjectPostService,
      refetch,
      loading: labelsLoading,
    } = useSubjectsInPostService(uniquePersonKeys)

    const postServiceRenderer = useCallback(
      (_: string, record: SubjectItem) =>
        labelsLoading ? (
          <Spin size="small" />
        ) : (
          <SubjectTableCellPostService
            uniquePersonKey={record.uniquePersonKey}
            addressId={record.kodAdresnihoMista || undefined}
            subjectId={record.id}
            postServiceData={subjectPostService?.[record.uniquePersonKey]}
            fullAddress={record.adresa || ''}
            fullName={record.nazev}
            companyName={
              ['F', 'O', 'Z'].includes(record.typ) ? undefined : record.nazev
            }
            refetch={refetch}
          />
        ),
      [labelsLoading, refetch, subjectPostService]
    )

    const columns: ColumnsType<SubjectItem> = useMemo(
      () => [
        {
          dataIndex: 'typ',
          className: 'w-4',
          sorter: {
            compare: (a, b) => a.typ.localeCompare(b.typ),
          },
          render: (_, record) => (
            <PropertyDetailSubjectTypeTableCell record={record} />
          ),
        },
        {
          title: 'Jméno / název',
          dataIndex: 'nazev',
          sorter: {
            compare: (a, b) => a.nazev.localeCompare(b.nazev),
          },
          render: (nazev, record) =>
            record.id ? (
              <a
                href={`https://data.regesta.cz/Vyhledavani/Subjekt/${record.id}`}
              >
                {nazev}
              </a>
            ) : (
              nazev
            ),
        },

        ...(props.tableKey === TableKey.WP ||
        (props.tableKey === TableKey.HQ && showHistory)
          ? [
              {
                dataIndex: 'vzdalenost',
                className: 'text-right w-20',
                render: (vzdalenost: number | null, record: SubjectItem) => (
                  <AddressDistance
                    distance={
                      record.aktualni || (!record.aktualni && vzdalenost)
                        ? vzdalenost
                        : null
                    }
                    name={record.nazev}
                  />
                ),
              },

              {
                title: 'Aktuální sídlo',
                dataIndex: 'sidlo',
                render: (sidlo: string) =>
                  !sidlo ? (
                    <span className="text-gray-400">Činnost ukončena</span>
                  ) : (
                    sidlo
                  ),
              },
            ]
          : []),

        ...(props.tableKey === TableKey.HQ || props.tableKey === TableKey.WP
          ? [
              {
                title: 'IČO',
                dataIndex: 'ico',
                className: 'w-28',
                render: (ico: string) => (
                  <a href={`https://data.regesta.cz/Subjekty/${ico}`}>{ico}</a>
                ),
              },
            ]
          : []),

        {
          title: 'Pošta',
          dataIndex: 'posta',
          className: 'w-40',
          render: postServiceRenderer,
        },

        ...(showHistory
          ? [
              {
                title: AddressChanged[props.tableKey],
                dataIndex: 'aktualni',
                className: 'w-28 !text-right',
                render: (aktualni: boolean, record: SubjectItem) =>
                  !aktualni && record.nalezenaAdresa?.platnostDo,
              },
            ]
          : []),
      ],
      [props.tableKey, showHistory, postServiceRenderer]
    )

    const locale = useMemo(
      () => ({
        emptyText: !rzpSubjects.fetching && !lvDetail.fetching && (
          <div className="my-4">
            <div className="mb-2 text-gray-400">
              {EmptyText[props.tableKey]}
            </div>
            {!!props.dataSource.length && (
              <div className="flex items-center justify-center space-x-2">
                <span>
                  Můžete si zobrazit{' '}
                  {decline(props.dataSource.length, 'historical', true)}{' '}
                  {decline(props.dataSource.length, 'record')}
                  {':'}
                </span>
                <Switch
                  size="small"
                  checked={showHistory}
                  onChange={setShowHistory}
                />
              </div>
            )}
          </div>
        ),
      }),
      [
        decline,
        lvDetail.fetching,
        props.dataSource.length,
        setShowHistory,
        showHistory,
        props.tableKey,
        rzpSubjects.fetching,
      ]
    )

    const rowSelection = useMemo<TableRowSelection<SubjectItem>>(
      () => ({
        selectedRowKeys,
        onChange: (newSelectedRowKeys, selectedRows) => {
          setSelectedRowKeys(newSelectedRowKeys)
          setSelectedRow(selectedRows)
        },
      }),
      [selectedRowKeys]
    )

    const expandable = useMemo<ExpandableConfig<SubjectItem>>(() => {
      return {
        expandedRowKeys,
        expandedRowRender: record => (
          <PropertyDetailSubjectsTableExpandedRow
            involvedPersons={record.angazovaneOsoby}
            subjectType={record.typ}
            ico={record.key}
            showHistory={showHistory}
          />
        ),
        columnTitle: (
          <ExpandColumnTitle
            allKeys={expandableKeys || []}
            tableId={props.tableKey}
          />
        ),
        expandIcon: ({ expanded, record }) => (
          <ExpandIcon
            expanded={expanded}
            recordId={record.key}
            tableId={props.tableKey}
            expandable={!!record?.angazovaneOsoby?.length}
          />
        ),
      }
    }, [expandableKeys, expandedRowKeys, showHistory, props.tableKey])

    const summary = useCallback(
      () => (
        <PropertyDetailSubjectsTableSummary
          allSubjectsCount={props.dataSource.length}
          currentSubjectsCount={dataSource.length}
        />
      ),
      [dataSource.length, props.dataSource.length]
    )

    return (
      <div>
        <div className="flex justify-between items-baseline">
          <Title level={4}>{TableTitle[props.tableKey]}</Title>
          <ShowHistorySwitch
            showHistory={showHistory}
            setShowHistory={setShowHistory}
            disabled={!props.dataSource.length}
          />
        </div>
        <PropertyDetailSubjectsTableBulkEdit selectedRows={selectedRow} />
        <Table
          loading={rzpSubjects.fetching || lvDetail.fetching}
          expandable={expandableKeys && expandable}
          rowSelection={selectedRowKeys?.length ? rowSelection : undefined} // TODO: change to rowSelection when bulk edit is implemented
          dataSource={dataSource}
          columns={columns}
          locale={locale}
          rowKey="key"
          summary={summary}
          id={props.tableKey}
          rowClassName={historyClassName}
          {...propertyDetailTableProps}
        />
      </div>
    )
  }
