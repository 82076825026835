import { Form, InputNumber } from 'antd'
import React, { FunctionComponent } from 'react'

export const MarketplaceFormFinanceFragment: FunctionComponent = () => {
  return (
    <>
      <Form.Item
        label="Úroková sazba p.a."
        name="interestAmount"
        rules={[
          {
            required: true,
            message: 'Zadejte roční úrokovou sazbu',
          },
        ]}
      >
        <InputNumber
          className="w-full"
          addonAfter="%"
          decimalSeparator=","
          autoFocus
          placeholder="Roční úroková sazba"
        />
      </Form.Item>
      <Form.Item
        label="Měsíční splátka / nájem"
        name="monthlyPayment"
        rules={[
          {
            required: true,
            message: 'Zadejte měsíční splátku / nájem',
          },
        ]}
      >
        <InputNumber
          className="w-full"
          addonAfter="Kč"
          decimalSeparator=","
          placeholder="Měsíční platba"
        />
      </Form.Item>
      <Form.Item
        label="Hodnota nemovitosti - odhad"
        name="propertyValue"
        rules={[
          {
            required: true,
            message: 'Zadejte odhadovanou hodnotu nemovitosti',
          },
        ]}
      >
        <InputNumber
          className="w-full"
          addonAfter="Kč"
          decimalSeparator=","
          placeholder="Odhad hodnoty nemovitosti"
        />
      </Form.Item>
      <Form.Item
        label="Výše investice vč. odměny"
        name="investmentAmount"
        rules={[
          {
            required: true,
            message: 'Zadejte výši investice včetně odměny',
          },
        ]}
      >
        <InputNumber
          className="w-full"
          addonAfter="Kč"
          decimalSeparator=","
          placeholder="Výše investice vč. odměny"
        />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prev, curr) =>
          prev.investmentAmount !== curr.investmentAmount ||
          prev.propertyValue !== curr.propertyValue
        }
      >
        {({ getFieldValue }) => {
          const investmentAmount = getFieldValue('investmentAmount')
          const propertyValue = getFieldValue('propertyValue')
          const ltv =
            investmentAmount && propertyValue
              ? ((investmentAmount / propertyValue) * 100).toFixed(2)
              : ''

          return (
            <Form.Item label="LTV (Loan to value)">
              <InputNumber
                className="w-full"
                addonAfter="%"
                decimalSeparator=","
                value={ltv}
                disabled
                placeholder="LTV - poměr mezi výší investice a hodnotou nemovitosti"
              />
            </Form.Item>
          )
        }}
      </Form.Item>
    </>
  )
}
