import { Button, message, Popconfirm } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { BASE_URL } from '../../../../ini.json'
import { useDeleteRecipientListMutation } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { pricelist } from '../../utils/pricelist'
import { Money } from '../Money/Money'
import { PricePopconfirm } from '../PricePopconfirm/PricePopconfirm'

export interface CadastrePostSentTableActionProps {
  recipientsCount: number
  listId: string
  refetch: () => void
}

export const CadastrePostSentTableAction: FunctionComponent<CadastrePostSentTableActionProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const [, deleteRecipientList] = useDeleteRecipientListMutation()
    const [loading, setLoading] = useState(false)

    const setDeleteRecipientList = useCallback(() => {
      setLoading(true)
      deleteRecipientList({ id: props.listId, deletedAt: new Date() }).then(
        () => {
          message.success('Seznam byl odstraněn.')
          props.refetch()
          setLoading(false)
        }
      )
    }, [deleteRecipientList, props])

    const handleComposeLetter = useCallback(async () => {
      setLoading(true)
      try {
        const response = await fetch(
          `${BASE_URL}/api/cadastrePost/sendLetter/${token}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              tenantId: tenantId,
              listId: props.listId,
            }),
          }
        )

        if (response.status === 200) {
          message.success('Hromadná pošta byla odeslána.')
          props.refetch()
        }
        if (response.status === 401) {
          message.success('Nejste přihlášen.')
        }
        if (response.status === 402) {
          message.success('Nemáte dostatek kreditu.')
        }
      } catch (error) {
        message.error('Nepodařilo se odeslat hromadnou poštu.')
      }
      return setLoading(false)
    }, [props, tenantId, token])

    const popconfrimProps = useMemo(() => {
      const bigCount = props.recipientsCount > 80
      const time = bigCount ? props.recipientsCount / 60 : props.recipientsCount
      const unit = bigCount ? 'minut' : 'sekund'

      if (loading && props.recipientsCount > 4)
        return {
          okButtonProps: { loading: loading },
          message: '',
          title: (
            <span>
              Hromadná pošta se právě odesílá,
              <br /> celý proces zabere zhruba{' '}
              <Money
                amount={time}
                symbol={unit}
                decimalNumbers={bigCount ? 1 : 0}
              />
              .
              <p className="pt-2 font-semibold">
                Toto okno můžete zavřít a pokračovat
                <br /> v práci napříč systémem.
              </p>
            </span>
          ),
        }
      return
    }, [loading, props.recipientsCount])

    return (
      <div className="flex space-x-2">
        <PricePopconfirm
          popconfrimProps={popconfrimProps}
          onConfirm={handleComposeLetter}
          itemPrice={pricelist.postService.ORDINARY_LETTER}
          itemCount={props.recipientsCount}
          message={`Cena za odeslání jednoho dopisu je ${pricelist.postService.ORDINARY_LETTER} Kr.`}
        >
          <Button loading={loading} type="primary">
            Rozeslat poštu
          </Button>
        </PricePopconfirm>
        <Popconfirm
          okText="Odebrat"
          cancelText="Zpět"
          title="Opravdu chcete odstranit seznam adresátů?"
          onConfirm={setDeleteRecipientList}
        >
          <Button type="primary" danger ghost>
            Odstranit
          </Button>
        </Popconfirm>
      </div>
    )
  }
