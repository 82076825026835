// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import tilebelt from '@mapbox/tilebelt'
import tileDecode from 'arcgis-pbf-parser'

export class CadastreMapFeatureService {
  public constructor(sourceId, map, arcgisOptions, geojsonSourceOptions = {}) {
    if (!sourceId || !map || !arcgisOptions)
      throw new Error('Source id, map, and arcgisOptions must be supplied.')
    if (!arcgisOptions.url)
      throw new Error(
        'A url must be supplied as part of the esriServiceOptions object.'
      )

    this.sourceId = sourceId
    this._map = map

    this.initializeIndices()
    this.initializeFeatureCollections()

    this.esriServiceOptions = {
      useStaticZoomLevel: false,
      minZoom: arcgisOptions.useStaticZoomLevel ? 7 : 2,
      simplifyFactor: 0.3,
      precision: 8,
      where: '1=1',
      outFields: '*',
      f: 'pbf',
      useSeviceBounds: true,
      projectionEndpoint: `${
        arcgisOptions.url.split('rest/services')[0]
      }rest/services/Geometry/GeometryServer/project`,
      fetchOptions: null,
      ...arcgisOptions,
    }
    this.serviceMetadata = null
    this._maxExtent = [-Infinity, Infinity, -Infinity, Infinity]

    this._map.addSource(this.sourceId, {
      ...geojsonSourceOptions,
      type: 'geojson',
      data: this.getEmptyFeatureCollection(),
      dynamic: true,
    })

    this._getServiceMetadata().then(() => {
      this._handleServiceMetadata()
    })
  }

  public destroySource() {
    this.disableRequests()
    this._map.removeSource(this.sourceId)
  }
  public setWhere(newWhere) {
    this.esriServiceOptions.where = newWhere
    this.clearAndRefreshTiles()
  }
  public clearWhere() {
    this.setWhere('1=1')
  }
  public disableRequests() {
    this._map.off('moveend', this._boundEvent)
  }
  public enableRequests() {
    this._boundEvent = this.findAndMapData.bind(this)
    this._map.on('moveend', this._boundEvent)
  }

  getEmptyFeatureCollection() {
    return { type: 'FeatureCollection', features: [] }
  }

  async _getServiceMetadata() {
    if (this.serviceMetadata !== null) return this.serviceMetadata
    try {
      const url = new URL(this.esriServiceOptions.url)
      url.search = new URLSearchParams({ f: 'json' }).toString()
      const data = await fetch(url).then(response => response.json())
      this.serviceMetadata = data
      return this.serviceMetadata
    } catch (error) {
      console.error('Error fetching service metadata:', error)
      throw error
    }
  }

  async _handleServiceMetadata() {
    const supportsPbf =
      this.serviceMetadata.supportedQueryFormats.includes('geoJSON')
    const supportsGeojson =
      this.serviceMetadata.supportedQueryFormats.includes('PBF')
    if (!supportsPbf) {
      if (!supportsGeojson) {
        this._map.removeSource(this.sourceId)
        throw new Error('Server does not support PBF or GeoJSON query formats.')
      }
      this.esriServiceOptions.f = 'geojson'
    }

    if (this.esriServiceOptions.useSeviceBounds) {
      this._setBoundsFromServiceMetadata()
    }
    if (this.esriServiceOptions.outFields !== '*') {
      this.esriServiceOptions.outFields += `,${this.serviceMetadata.uniqueIdField.name}`
    }

    this.enableRequests()
    this.clearAndRefreshTiles()
  }

  _setBoundsFromServiceMetadata() {
    const serviceExtent = this.serviceMetadata.extent
    if (serviceExtent.spatialReference.wkid === 4326) {
      this._maxExtent = [
        serviceExtent.xmin,
        serviceExtent.ymin,
        serviceExtent.xmax,
        serviceExtent.ymax,
      ]
    } else {
      this.projectBounds()
    }
  }

  async findAndMapData() {
    const pitch = this._map.getPitch()
    if (pitch !== 0) return

    const z = this._map.getZoom()
    if (z < this.esriServiceOptions.minZoom) return

    const bounds = this._map.getBounds().toArray()

    if (
      this.esriServiceOptions.useSeviceBounds &&
      this._maxExtent[0] !== -Infinity &&
      !this.doesTileOverlapBbox(this._maxExtent, bounds)
    )
      return

    const tolerance = this.calculateTolerance(bounds)
    const zoomLevel = this.calculateZoomLevel(z)

    const zoomLevelIndex = this.tileIndices.get(zoomLevel)
    const featureIdIndex = this.featureIndices.get(zoomLevel)
    const fc = this.featureCollections.get(zoomLevel)

    const visibleTiles = this.getVisibleTiles(bounds, zoomLevel)
    const tilesToRequest = this.filterVisibleTiles(visibleTiles, zoomLevelIndex)

    if (tilesToRequest.length === 0) {
      // NOTE: This sucks this.updateFcOnMap(fc)
      return
    }

    await this.loadAndProcessTiles(
      tilesToRequest,
      tolerance,
      featureIdIndex,
      fc
    )
    // NOTE: This sucks
    this.updateFcOnMap(fc)
  }

  getVisibleTiles(bbox, zoom) {
    const [[minLng, minLat], [maxLng, maxLat]] = bbox

    const minTile = tilebelt.pointToTile(minLng, minLat, zoom)
    const maxTile = tilebelt.pointToTile(maxLng, maxLat, zoom)

    const minX = minTile[0]
    const maxX = maxTile[0]

    const minY = Math.min(minTile[1], maxTile[1])
    const maxY = Math.max(minTile[1], maxTile[1])

    const tiles = []
    for (let x = minX; x <= maxX; x++) {
      for (let y = minY; y <= maxY; y++) {
        tiles.push([x, y, zoom])
      }
    }
    return tiles
  }

  filterVisibleTiles(tilesToRequest, zoomLevelIndex) {
    return tilesToRequest.filter(tile => {
      const quadKey = tilebelt.tileToQuadkey(tile)
      if (zoomLevelIndex.has(quadKey)) {
        return false
      } else {
        zoomLevelIndex.set(quadKey, true)
        return true
      }
    })
  }

  async loadAndProcessTiles(tilesToRequest, tolerance, featureIdIndex, fc) {
    try {
      const featureCollections = await Promise.all(
        tilesToRequest.map(tile => this.getTile(tile, tolerance))
      )
      featureCollections.forEach(tileFc => {
        tileFc.features.forEach(feature => {
          const featureId = feature.id
          if (!featureIdIndex.has(featureId)) {
            // NOTE: This sucks, mutating data reference which map is holding
            fc.features.push(feature)
            featureIdIndex.set(featureId)
          }
        })
      })
    } catch (error) {
      console.error('Error loading tiles:', error)
    }
  }

  calculateZoomLevel(z) {
    return this.esriServiceOptions.useStaticZoomLevel
      ? this.esriServiceOptions.minZoom
      : 2 * Math.floor(z / 2)
  }

  calculateTolerance(bounds) {
    const mapWidth = Math.abs(bounds[1][0] - bounds[0][0])
    return (
      (mapWidth / this._map.getCanvas().width) *
      this.esriServiceOptions.simplifyFactor
    )
  }

  async getTile(tile, tolerance) {
    try {
      const tileBounds = tilebelt.tileToBBOX(tile)
      const extent = {
        spatialReference: { latestWkid: 4326, wkid: 4326 },
        xmin: tileBounds[0],
        ymin: tileBounds[1],
        xmax: tileBounds[2],
        ymax: tileBounds[3],
      }
      const url = new URL(`${this.esriServiceOptions.url}/query`)
      url.search = new URLSearchParams({
        f: this.esriServiceOptions.f,
        where: this.esriServiceOptions.where,
        outFields: this.esriServiceOptions.outFields,
        outSR: '4326',
        precision: this.esriServiceOptions.precision,
        spatialRel: 'esriSpatialRelIntersects',
        geometryType: 'esriGeometryEnvelope',
        inSR: '4326',
        geometry: JSON.stringify(extent),
        quantizationParameters: JSON.stringify({
          extent,
          tolerance,
          mode: 'view',
        }),
        resultType: 'tile',
      }).toString()

      const data = await fetch(url).then(response =>
        this.esriServiceOptions.f === 'pbf'
          ? response.arrayBuffer()
          : response.json()
      )
      return this.esriServiceOptions.f === 'pbf'
        ? tileDecode(new Uint8Array(data)).featureCollection
        : data
    } catch (error) {
      console.error('Error fetching tile data:', error)
      throw error
    }
  }

  updateFcOnMap(fc) {
    this._map.getSource(this.sourceId).setData(fc)
  }

  doesTileOverlapBbox(tile, bbox) {
    const tileBounds = tile.length === 4 ? tile : tilebelt.tileToBBOX(tile)
    if (tileBounds[2] < bbox[0][0]) return false
    if (tileBounds[0] > bbox[1][0]) return false
    if (tileBounds[3] < bbox[0][1]) return false
    if (tileBounds[1] > bbox[1][1]) return false
    return true
  }

  async projectBounds() {
    try {
      const url = new URL(this.esriServiceOptions.projectionEndpoint)
      url.search = new URLSearchParams({
        geometries: JSON.stringify({
          geometryType: 'esriGeometryEnvelope',
          geometries: [this.serviceMetadata.extent],
        }),
        inSR: this.serviceMetadata.extent.spatialReference.wkid,
        outSR: 4326,
        f: 'json',
      }).toString()
      const data = await fetch(url).then(response => response.json())
      const extent = data.geometries[0]
      this._maxExtent = [extent.xmin, extent.ymin, extent.xmax, extent.ymax]
    } catch (error) {
      console.error(
        `Could not project bounds. ${error}`,
        `${this.esriServiceOptions.projectionEndpoint}?${params}`
      )
      throw error
    }
  }

  initializeIndices() {
    this.tileIndices = new Map(
      Array.from({ length: 22 }, (_, zoom) => [zoom, new Map()])
    )
    this.featureIndices = new Map(
      Array.from({ length: 22 }, (_, zoom) => [zoom, new Map()])
    )
  }

  initializeFeatureCollections() {
    this.featureCollections = new Map(
      Array.from({ length: 22 }, (_, zoom) => [
        zoom,
        this.getEmptyFeatureCollection(),
      ])
    )
  }

  clearAndRefreshTiles() {
    this.initializeIndices()
    this.initializeFeatureCollections()
    this.findAndMapData()
  }
}
