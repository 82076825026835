import { Button, Space } from 'antd'
import React, { FunctionComponent } from 'react'

import {
  letterImageButtonStyles as style,
  LetterImageProps,
} from '../../../common/postServiceTypes'
import { PostServiceLetterImagePositionInput } from '../PostServiceLetterImagePositionInput/PostServiceLetterImagePositionInput'

export interface PostServiceLetterImagePositionProps {
  handleOk: () => void
  imageProps: LetterImageProps
  setImageProps: React.Dispatch<React.SetStateAction<LetterImageProps>>
}

export const PostServiceLetterImagePosition: FunctionComponent<PostServiceLetterImagePositionProps> =
  props => {
    return (
      <Space.Compact className="max-w-full mb-4 pt-4 border-t border-dashed border-gray-400">
        <Button disabled style={style.label}>
          Odsazení
        </Button>
        <PostServiceLetterImagePositionInput {...props} type="top" />
        <PostServiceLetterImagePositionInput {...props} type="right" />
        <PostServiceLetterImagePositionInput {...props} type="bottom" />
        <PostServiceLetterImagePositionInput {...props} type="left" />

        <Button style={style.symbol} disabled>
          cm
        </Button>
      </Space.Compact>
    )
  }
