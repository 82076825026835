import { message } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useEffect, useState } from 'react'

import { ProceedingDetailResponse } from '../../../common/proceedingTypes'
import { useAppSelector } from '../../../redux/hooks'
import { requestProceedingDetailPath } from '../../utils/paths'
import { useFormattedProceeding } from '../../utils/useProceedingString'
import { ProceedingDetailDocumentCollectionTable } from '../ProceedingDetailDocumentCollectionTable/ProceedingDetailDocumentCollectionTable'
import { ProceedingDetailInformation } from '../ProceedingDetailInformation/ProceedingDetailInformation'
import { PropertyDetailLvAreaTable } from '../PropertyDetailLvAreaTable/PropertyDetailLvAreaTable'
import { PropertyDetailLvBuildingTable } from '../PropertyDetailLvBuildingTable/PropertyDetailLvBuildingTable'
import { PropertyDetailLvUnitTable } from '../PropertyDetailLvUnitTable/PropertyDetailLvUnitTable'
import { PropertyDetailProceedingTableItem } from '../PropertyDetailProceedingTable/PropertyDetailProceedingTable'

interface FetchState {
  data: ProceedingDetailResponse | null
  fetching: boolean
  error: boolean
}

export interface PropertyDetailProceedingTableExpandedRowProps {
  record: PropertyDetailProceedingTableItem
}

export const PropertyDetailProceedingTableExpandedRow: FunctionComponent<PropertyDetailProceedingTableExpandedRowProps> =
  ({ record }) => {
    const { proceedingUrlPath, proceedingKey } = useFormattedProceeding(record)
    const token = useAppSelector(state => state.myProfile.token)
    const propertyId = useAppSelector(
      state => state.property.drm.data?.id || ''
    )

    const [{ data, fetching, error }, setData] = useState<FetchState>({
      data: null,
      fetching: true,
      error: false,
    })

    useEffect(() => {
      const fetchProceedingDetail = async () => {
        setData(prev => ({ ...prev, fetching: true }))
        try {
          const response = await axios.get(
            requestProceedingDetailPath() + token + '/' + proceedingUrlPath
          )
          setData({ data: response.data, fetching: false, error: false })
        } catch (error) {
          setData({ data: null, fetching: false, error: true })
          message.error('Nepodařilo se načíst detail řízení.')
        }
      }
      if (!error && !data) fetchProceedingDetail()
    }, [data, error, fetching, proceedingUrlPath, token])

    return (
      <div className="-mt-2 -mx-2 -mb-1 p-4 pt-8 bg-white">
        <ProceedingDetailDocumentCollectionTable
          proceedingString={proceedingKey || ''}
        />
        <ProceedingDetailInformation
          record={record}
          data={data}
          loading={fetching}
        />
        <div className="w-full space-y-4 mt-8">
          {!!data?.nemovitosti?.jednotky.length && (
            <PropertyDetailLvUnitTable
              showCity
              propertyId={propertyId}
              jednotky={data.nemovitosti.jednotky}
            />
          )}
          {!!data?.nemovitosti?.budovy.length && (
            <PropertyDetailLvBuildingTable
              showCity
              propertyId={propertyId}
              budovy={data.nemovitosti.budovy}
              parcely={data.nemovitosti.parcely}
            />
          )}
          {!!data?.nemovitosti?.parcely.length && (
            <PropertyDetailLvAreaTable
              showCadastralArea
              propertyId={propertyId}
              parcely={data.nemovitosti.parcely}
              budovy={data.nemovitosti.budovy}
            />
          )}
        </div>
      </div>
    )
  }
