import axios from 'axios'

import { AresAddress } from '../../common/aresTypes'

const aresApiUrl =
  'https://ares.gov.cz/ekonomicke-subjekty-v-be/rest/standardizovane-adresy/vyhledat'

const handleFetch = async (input: {
  [key: string]: string | number
}): Promise<AresAddress | undefined> => {
  try {
    const response = await axios.post(aresApiUrl, input)

    if (response.data && response.status === 200) {
      return response.data.standardizovaneAdresy?.[0]
    } else {
      throw new Error('Data nebyla nalezena')
    }
  } catch (error) {
    console.error('Chyba při načítání výsledků vyhledávání:', error)
    return
  }
}

const handleSearch = async (input: {
  [key: string]: string | number
}): Promise<AresAddress[] | undefined> => {
  try {
    const response = await axios.post(aresApiUrl, input)

    if (response.data && response.status === 200) {
      return response.data.standardizovaneAdresy
    } else {
      throw new Error('Adresa nebyla nalezena')
    }
  } catch (error) {
    console.error('Chyba při načítání výsledků vyhledávání:', error)
    return
  }
}

export const fetchAddressById = async (id?: string | number | null) => {
  if (!id) return null
  return handleFetch({
    kodAdresnihoMista: id,
    typStandardizaceAdresy: 'UPLNA_STANDARDIZACE',
  })
}

export const fetchAddressByName = async (name?: string | null) => {
  if (!name) return null
  return handleFetch({
    textovaAdresa: name,
    typStandardizaceAdresy: 'UPLNA_STANDARDIZACE',
  })
}

export const searchAddressByName = async (name?: string | null) => {
  if (!name) return null
  return handleSearch({
    textovaAdresa: name,
    typStandardizaceAdresy: 'VYHOVUJICI_ADRESY',
  })
}
