import { Col, Row } from 'antd'
import React, { FunctionComponent } from 'react'

import { PageLayout } from '../PageLayout/PageLayout'

export interface DualPageLayoutProps {
  sidebarContent: React.ReactNode
  title?: React.ReactNode | string
  extra?: React.ReactNode
  children?: React.ReactNode
  isNew?: boolean
  isNewTitle?: string
  className?: string
  isContainer?: boolean
  isOpen?: boolean
}

const gutter: [number, number] = [12, 12]

export const DualPageLayout: FunctionComponent<DualPageLayoutProps> = ({
  isOpen = true,
  ...props
}) => {
  return (
    <PageLayout
      isNew={props.isNew}
      isNewTitle={props.isNewTitle}
      title={props.title}
      isContainer
      extra={props.extra}
    >
      <Row gutter={gutter}>
        <Col xs={isOpen ? 24 : 0} lg={isOpen ? 8 : 0} xl={isOpen ? 6 : 0}>
          <div className="flex flex-col space-y-6 justify-center">
            {props.sidebarContent}
          </div>
        </Col>
        <Col xs={24} lg={isOpen ? 16 : 24} xl={isOpen ? 18 : 24}>
          <PageLayout isContainer>{props.children}</PageLayout>
        </Col>
      </Row>
    </PageLayout>
  )
}
