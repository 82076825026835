import { CrownFilled, LinkOutlined, UserOutlined } from '@ant-design/icons'
import { Icon } from '@iconify/react'
import { Badge } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { PropertyDetailSubjectsTableItem } from '../../../common/propertyDetailTypes'
import { rzpSubjectType } from '../../../common/rzpTypes'

export interface PropertyDetailSubjectTypeTableCellProps {
  record: PropertyDetailSubjectsTableItem
}

export const PropertyDetailSubjectTypeTableCell: FunctionComponent<PropertyDetailSubjectTypeTableCellProps> =
  ({ record }) => {
    const { personIcon, personTitle, badgeIcon } = useMemo(() => {
      const person = rzpSubjectType[record.typ].short
      const isOwner = record.match === 'owner'
      const isPreviousOwner = record.match === 'previousOwner'
      const isOwnerLink = record.angazovaneOsoby?.some(
        ({ match }) => match === 'owner'
      )
      const isPreviousOwnerLink = record.angazovaneOsoby?.some(
        ({ match }) => match === 'previousOwner'
      )

      let title = person
      let badgeIcon = null

      if (isOwner) {
        title += ' s vlastnickými právy k budově'
        badgeIcon = <CrownFilled className="text-sm bg-white text-green-500" />
      } else if (isPreviousOwner) {
        title += ' s historickými vlastnickými právy k budově'
        badgeIcon = <CrownFilled className="text-sm bg-white text-gray-400" />
      } else if (isOwnerLink) {
        title += ' s vazbou na vlastníka'
        badgeIcon = <LinkOutlined className="text-sm bg-white text-green-500" />
      } else if (isPreviousOwnerLink) {
        title += ' s vazbou na historického vlastníka'
        badgeIcon = <LinkOutlined className="text-sm bg-white text-gray-400" />
      }

      const personIcon = ['F', 'O', 'Z'].includes(record.typ) ? (
        <UserOutlined className="text-lg" />
      ) : (
        <Icon icon="fluent:building-factory-16-regular" className="text-lg" />
      )

      return { personIcon, personTitle: title, badgeIcon }
    }, [record.typ, record.match, record.angazovaneOsoby])

    return (
      <div title={personTitle}>
        <Badge count={<div>{badgeIcon}</div>}>{personIcon}</Badge>
      </div>
    )
  }
