import { useCallback, useMemo } from 'react'

import { safeJsonParsing } from '../../common/safeJsonParsing'
import { Maybe } from '../../graphql/generated'
import { multipliersList, priceCoefficient } from './monitHelpers'

export function useMonitDecompData(
  decompNab?: Maybe<string>,
  decompReal?: Maybe<string>
) {
  const parseAndCreateDecompObject = useCallback(
    (decompString?: Maybe<string>) => {
      const data = safeJsonParsing(decompString, null as any)
      if (!data) return null

      const coefficient = priceCoefficient(data)
      const multipliers = multipliersList(data)
      return { multipliers, coefficient }
    },
    []
  )

  const decompNabParsed = useMemo(
    () => parseAndCreateDecompObject(decompNab),
    [parseAndCreateDecompObject, decompNab]
  )

  const decompRealParsed = useMemo(
    () => parseAndCreateDecompObject(decompReal),
    [parseAndCreateDecompObject, decompReal]
  )

  return useMemo(
    () => ({
      decompReal: decompRealParsed?.multipliers,
      decompNab: decompNabParsed?.multipliers,
      decompRealTotal: decompRealParsed?.coefficient,
      decompNabTotal: decompNabParsed?.coefficient,
    }),
    [decompRealParsed, decompNabParsed]
  )
}
