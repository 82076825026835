import { PlusOutlined } from '@ant-design/icons'
import { Icon } from '@iconify/react'
import { Button, Divider, Select, Tag } from 'antd'
import { CustomTagProps } from 'rc-select/lib/BaseSelect.js'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { useAllLabelsQuery } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import {
  ColorPalleteDeafultOption,
  ColorPalleteKey,
  ColorPalleteOptions,
} from '../../utils/collorPallete'
import { IconPalleteKey, IconPalleteOptions } from '../../utils/iconPallete'
import { AddNewLabel } from '../AddNewLabel/AddNewLabel'
import { Fade } from '../Fade/Fade'
import { Label } from '../Label/Label'

export interface LabelSelectInputProps {
  onChange?: (value: string[] | []) => void
  initialValue?: string[] | []
  disabled?: boolean
}

const dropdownStyle = { overflow: 'visible' }

export const LabelSelectInput: FunctionComponent<LabelSelectInputProps> = ({
  initialValue,
  ...props
}) => {
  const tenantId = useAppSelector(state => state.myProfile.tenantId)
  const [allLabels] = useAllLabelsQuery({
    variables: { accountId: tenantId },
  })
  const [addingNewLabel, setAddingNewLabel] = useState(false)
  const [open, setOpen] = useState<undefined | boolean>(undefined)

  const onDropdownVisibleChange = useCallback(
    (open: boolean) => !open && setAddingNewLabel(false),
    []
  )

  const handleClose = useCallback(() => {
    // hackaround: force close Select and then set to default, to preserve native Select behavior
    setOpen(false)
    setTimeout(() => {
      setOpen(undefined)
    }, 1000)
    if (open === false) setOpen(undefined)
  }, [open])

  const tagRender = useCallback(
    (customProps: CustomTagProps) => {
      const findLabel = allLabels.data?.allLabels?.nodes.find(
        label => label?.id === customProps.value
      )
      return (
        <Tag
          style={{ padding: 0, paddingRight: 7, borderLeft: 0 }}
          {...customProps}
        >
          <Label
            colorKey={(findLabel?.colorKey as ColorPalleteKey) || undefined}
            iconKey={(findLabel?.iconKey as IconPalleteKey) || undefined}
            description={findLabel?.description || undefined}
            name={findLabel?.name || undefined}
          />
        </Tag>
      )
    },
    [allLabels.data?.allLabels?.nodes]
  )

  const dropdownRender = useCallback(
    (menu: React.ReactElement) => (
      <div id="selectLabelDropDownRender">
        {menu}
        <Divider style={{ margin: '8px 0' }} />
        {!addingNewLabel && (
          <div className="flex space-x-2">
            <Button
              type="link"
              onClick={() => setAddingNewLabel(true)}
              className="w-full pb-2"
            >
              <PlusOutlined /> Vytvořit nový štítek
            </Button>
            <Button
              onClick={handleClose}
              type="primary"
              ghost
              className="border border-l"
            >
              OK
            </Button>
          </div>
        )}
        <Fade show={addingNewLabel}>{addingNewLabel && <AddNewLabel />}</Fade>
      </div>
    ),
    [addingNewLabel, handleClose]
  )

  const options = useMemo(
    () =>
      allLabels.data?.allLabels?.nodes.map(label => ({
        name: label?.name,
        value: label?.id,
        label: (
          <div className="flex items-center">
            <div
              className={
                'w-5 h-5 border rounded-sm mr-2 flex items-center justify-center ' +
                (!!label?.colorKey &&
                  label.colorKey !== ColorPalleteDeafultOption &&
                  ColorPalleteOptions[label.colorKey as ColorPalleteKey])
              }
            >
              {!!label?.iconKey && (
                <Icon
                  width={10}
                  icon={IconPalleteOptions[label?.iconKey as IconPalleteKey]}
                />
              )}
            </div>
            <div className="truncate w-full">
              {label?.name}
              {label?.description && (
                <span className="text-gray-400 pl-2">{label.description}</span>
              )}
            </div>
          </div>
        ),
      })),
    [allLabels.data?.allLabels?.nodes]
  )

  return (
    <Select
      defaultValue={initialValue}
      className="w-full"
      showSearch
      placeholder="Vyberte štítek"
      optionFilterProp="name"
      mode="multiple"
      dropdownStyle={dropdownStyle}
      onDropdownVisibleChange={onDropdownVisibleChange}
      tagRender={tagRender}
      dropdownRender={dropdownRender}
      open={open}
      options={options}
      popupClassName="!w-[480px] !min-w-0"
      dropdownMatchSelectWidth={false}
      {...props}
    />
  )
}
