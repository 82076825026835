import { Tooltip } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { ZAHLAVI_LV } from '../../../common/cuzkLvTypes'
import { removeDiacritics } from '../../../common/pdfHelpers'
import { lvDetailPath } from '../../utils/paths'
import { MapTooltip } from '../MapTooltip/MapTooltip'

export interface CuzkLvHtmlHeaderFragmentProps {
  zahlaviLv?: ZAHLAVI_LV
  platnost?: string
  active?: boolean
}

export const CuzkLvHtmlHeaderFragment: FunctionComponent<CuzkLvHtmlHeaderFragmentProps> =
  props => {
    const LV = props.zahlaviLv
    const obecKodNazev = useMemo(
      () => (LV?.OBEC?.kod || '') + ' ' + (LV?.OBEC?.nazev || ''),
      [LV?.OBEC?.kod, LV?.OBEC?.nazev]
    )
    const cisloLvTitle = useMemo(() => {
      if (props.active) {
        return (
          <Tooltip title="Zobrazit detail LV v novém okně">
            <Link
              target="_blank"
              to={lvDetailPath(
                LV?.KATASTR_UZEMI?.kod || '',
                LV?.CISLO_LV || ''
              )}
            >
              {LV?.CISLO_LV}
            </Link>
          </Tooltip>
        )
      }

      return LV?.CISLO_LV
    }, [props.active, LV?.CISLO_LV, LV?.KATASTR_UZEMI?.kod])

    return (
      <div className="header">
        <h1>Výpis z katastru nemovitostí</h1>
        <h2 className="text-center mb-20">
          <strong>prokazující stav evidovaný k datu</strong>
          &nbsp;<i>{props.platnost}</i>
        </h2>

        <div className="row">
          <div className="col col-xs-2 text-right">Okres:&nbsp;</div>
          <div className="col col-xs-4">
            {LV?.OKRES?.nuts4 && (
              <strong>
                {props.active ? (
                  <MapTooltip
                    risy={removeDiacritics(
                      `?search=${LV?.OKRES?.nuts4}`,
                      true,
                      true
                    )}
                    query={'okres+' + LV?.OKRES?.nazev}
                  >
                    {LV?.OKRES?.nuts4}&nbsp;
                    {LV?.OKRES?.nazev}
                  </MapTooltip>
                ) : (
                  <>
                    {LV?.OKRES?.nuts4}&nbsp;
                    {LV?.OKRES?.nazev}
                  </>
                )}
              </strong>
            )}
          </div>
          <div
            className={
              'col text-right ' + (obecKodNazev.length > 30)
                ? 'col-xs-1'
                : 'col-xs-3'
            }
          >
            Obec:&nbsp;
          </div>
          <div
            className={
              'col ' + (obecKodNazev.length > 30) ? 'col-xs-5' : 'col-xs-3'
            }
          >
            <strong>
              {props.active ? (
                <MapTooltip
                  risy={removeDiacritics(
                    `${LV?.OBEC?.kod}-${removeDiacritics(
                      LV?.OBEC?.nazev,
                      true,
                      true
                    )}#city-pills-tab`,
                    true
                  )}
                  query={LV?.OBEC?.nazev}
                >
                  {obecKodNazev}
                </MapTooltip>
              ) : (
                <>{obecKodNazev}</>
              )}
            </strong>
          </div>
        </div>
        <div className="row">
          <div className="col col-xs-2 text-right">Kat.území:&nbsp;</div>
          <div className="col col-xs-4">
            <strong>
              {props.active ? (
                <MapTooltip
                  risy={removeDiacritics(
                    `${LV?.OBEC?.kod}-${LV?.OBEC?.nazev}/${LV?.KATASTR_UZEMI?.kod}-${LV?.KATASTR_UZEMI?.nazev}#city-pills-tab`,
                    true,
                    true
                  )}
                  query={LV?.KATASTR_UZEMI?.nazev}
                >
                  {LV?.KATASTR_UZEMI?.kod}&nbsp;
                  {LV?.KATASTR_UZEMI?.nazev}
                </MapTooltip>
              ) : (
                <>
                  {LV?.KATASTR_UZEMI?.kod}&nbsp;
                  {LV?.KATASTR_UZEMI?.nazev}
                </>
              )}
            </strong>
          </div>
          <div className="col col-xs-3 text-right">List vlastnictví:&nbsp;</div>
          <div className="col col-xs">
            <strong>{cisloLvTitle}</strong>
          </div>
        </div>
        <div className="bold text-center">{LV?.CIS_RADA}</div>
      </div>
    )
  }
