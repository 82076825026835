import { Button, Tooltip } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { AddressApiResponse } from '../../../common/addressTypes'
import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { formatAddress } from '../../utils/addressFormat'
import { propertyDetailPath } from '../../utils/paths'

export interface AddressTooltipProps {
  addressData: Partial<AddressApiResponse> & {
    id: string
  }
  gpsData: { longitude: number | string; latitude: number | string }
  address?: string
}

export const AddressTooltip: FunctionComponent<AddressTooltipProps> = props => {
  const gpsData = props.gpsData || ''
  const formatedAddress = useMemo(
    () => formatAddress(props.addressData),
    [props.addressData]
  )

  const ikatastrUrl = `https://ikatastr.cz/#kde=${gpsData?.latitude || ''},${
    gpsData?.longitude || ''
  },19&info=${gpsData?.latitude || ''},${gpsData?.longitude || ''}`

  const addressLink = useCallback(
    (text: React.ReactNode) => (
      <Link
        to={propertyDetailPath(PROPERTY_TYPES.BUILDING, props.addressData?.id)}
        target="_blank"
        className="text-gray-500"
      >
        {text}
      </Link>
    ),
    [props.addressData]
  )

  if (!formatedAddress?.string.trim()) return null
  return (
    <Tooltip
      overlayClassName="min-w-min"
      title={
        <div className="p-1">
          <div className="flex">
            {addressLink(
              <Button type="primary" className="mb-2 mr-2">
                Zobrazit detail adresy
              </Button>
            )}

            <span className="inline-block">{formatedAddress.copyButton}</span>
          </div>
          <div className="space-x-2 whitespace-nowrap">
            <span>Zobrazit v</span>
            <a
              target="_blank"
              href={formatedAddress.mapyCzUrl}
              rel="noreferrer"
            >
              mapy.cz
            </a>
            <span className="text-gray-500">|</span>
            <a
              target="_blank"
              href={formatedAddress.googleMapUrl}
              rel="noreferrer"
            >
              google maps
            </a>
            <span className="text-gray-500">|</span>
            <a target="_blank" href={ikatastrUrl} rel="noreferrer">
              ikatastr
            </a>
          </div>
        </div>
      }
    >
      {addressLink(formatedAddress.string)}
    </Tooltip>
  )
}
