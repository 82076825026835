import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { PropertyDetailSubjectsTableItem as SubjectItem } from '../../../common/propertyDetailTypes'
import { RzpSubjectTypeKey } from '../../../common/rzpTypes'
import { propertyDetailTableProps } from '../../utils/layoutConst'
import { PropertyDetailSubjectsTableCellEngagement } from '../PropertyDetailSubjectsTableCellEngagement/PropertyDetailSubjectsTableCellEngagement'
import { PropertyDetailSubjectsTableExpandedRowLocale } from '../PropertyDetailSubjectsTableExpandedRowLocale/PropertyDetailSubjectsTableExpandedRowLocale'
import { PropertyDetailSubjectsTableExpandedRowSummary } from '../PropertyDetailSubjectsTableExpandedRowSummary/PropertyDetailSubjectsTableExpandedRowSummary'
import { PropertyDetailSubjectTypeTableCell } from '../PropertyDetailSubjectTypeTableCell/PropertyDetailSubjectTypeTableCell'

export interface PropertyDetailSubjectsTableExpandedRowProps {
  ico: string
  involvedPersons?: SubjectItem[]
  subjectType: RzpSubjectTypeKey
  showHistory?: boolean
}

export const PropertyDetailSubjectsTableExpandedRow: FunctionComponent<PropertyDetailSubjectsTableExpandedRowProps> =
  props => {
    const [localHistory, setLocalHistory] = useState<boolean>(false)

    const dataSource = useMemo<SubjectItem[]>(() => {
      if (!props.involvedPersons?.length) return []
      if (props.showHistory || localHistory) return props.involvedPersons
      return props.involvedPersons.filter(subject => subject.aktualni)
    }, [props.involvedPersons, props.showHistory, localHistory])

    const columns: ColumnsType<SubjectItem> = useMemo(
      () => [
        {
          dataIndex: 'typ',
          className: 'w-4',
          sorter: (a, b) => a.typ.localeCompare(b.typ),
          render: (_, record) => (
            <PropertyDetailSubjectTypeTableCell record={record} />
          ),
        },
        {
          title: 'Jméno / název',
          dataIndex: 'nazev',
          sorter: (a, b) => a.nazev.localeCompare(b.nazev),
        },
        {
          title: 'Funkce',
          dataIndex: 'angazma',
          sorter: (a, b) =>
            (a.angazma?.[0]?.nazevAngazma || '').localeCompare(
              b.angazma?.[0]?.nazevAngazma || ''
            ),
          render: engagement => (
            <PropertyDetailSubjectsTableCellEngagement
              engagement={engagement}
            />
          ),
        },
        ...(props.subjectType === 'P'
          ? [
              {
                title: 'Bydliště',
                dataIndex: 'nalezenaAdresa',
                render: (_: string, record: SubjectItem) =>
                  record.nalezenaAdresa?.text ||
                  record.sidlo || (
                    <span className="text-gray-400">Neuvedeno</span>
                  ),
              },
              {
                title: 'Podíl na společnosti',
                dataIndex: 'podil',
                render: (_: string, record: SubjectItem) =>
                  record.angazma?.map(angazma => {
                    return angazma.datumDo ? (
                      <span className="mr-2 text-gray-400 line-through">
                        {record.velikostPodilu || ''}
                      </span>
                    ) : (
                      <span className="mr-2">
                        {record.velikostPodilu || ''}
                      </span>
                    )
                  }),
              },
            ]
          : [
              {
                title: 'Předmět podnikání',
                dataIndex: 'predmetPodnikani',
                render: (_: string, record: SubjectItem) =>
                  record.angazma?.map(angazma => {
                    return angazma.datumDo ? (
                      <span className="mr-2 text-gray-400 line-through">
                        {angazma.predmetPodnikani}
                      </span>
                    ) : (
                      <span className="mr-2">{angazma.predmetPodnikani}</span>
                    )
                  }),
              },
            ]),
      ],
      [props.subjectType]
    )

    const locale = useMemo(() => {
      return {
        emptyText: props.involvedPersons?.length ? (
          <PropertyDetailSubjectsTableExpandedRowLocale
            involvedCount={props.involvedPersons?.length || 0}
            showHistory={props.showHistory || localHistory}
            setLocalHistory={setLocalHistory}
          />
        ) : undefined,
      }
    }, [localHistory, props.involvedPersons?.length, props.showHistory])

    const summary = useCallback(
      () => (
        <PropertyDetailSubjectsTableExpandedRowSummary
          currentCount={dataSource.length}
          involvedCount={props.involvedPersons?.length || 0}
          setLocalHistory={setLocalHistory}
          showHistory={props.showHistory || localHistory}
        />
      ),
      [
        localHistory,
        dataSource.length,
        props.showHistory,
        props.involvedPersons?.length,
      ]
    )

    return (
      <Table
        dataSource={dataSource}
        summary={summary}
        columns={columns}
        rowKey="key"
        className="mb-8"
        locale={locale}
        {...propertyDetailTableProps}
      />
    )
  }
