import { Document, Font, Page } from '@react-pdf/renderer'
import React, { FunctionComponent, useMemo } from 'react'
import Html from 'react-pdf-html'

import openSansBold from '../../../common/fonts/OpenSans-Bold.ttf'
import openSansItalic from '../../../common/fonts/OpenSans-Italic.ttf'
import openSans from '../../../common/fonts/OpenSans-Regular.ttf'
import { pdfStyles } from '../../../common/pdfHelpers'
import { replaceVariables } from '../../../common/postServicePdf'
import { CadastrePostSentRecipient } from '../CadastrePostSentTableExpandedRow/CadastrePostSentTableExpandedRow'

export interface CadastrePostLetterDetailPdfProps {
  data?: CadastrePostSentRecipient
  letterText: string
  showTemplate?: boolean
}

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: openSans },
    { src: openSansBold, fontWeight: 800 },
    { src: openSansItalic, fontStyle: 'italic' },
  ],
})

export const CadastrePostLetterDetailPdf: FunctionComponent<CadastrePostLetterDetailPdfProps> =
  props => {
    const letterContent = useMemo(() => {
      if (props.showTemplate) return props.letterText
      if (!props.data) return ''
      const subject = {
        ...props.data,
        recipientByRecipientId: {
          ...props.data.recipient,
          addressByAdresnimistokod: props.data.recipient.address,
        },
      }
      return replaceVariables(props.letterText, subject, true)
    }, [props.data, props.letterText, props.showTemplate])

    return (
      <Document>
        <Page style={pdfStyles.page} size="A4">
          <Html>{letterContent}</Html>
        </Page>
      </Document>
    )
  }
