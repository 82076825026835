import { Input, InputRef, Tooltip } from 'antd'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  AreaFilterUse,
  AreaFilterUseCodes,
} from '../../../common/foreclosureMonitoringTypes'
import { selectionSearchBarNormalizer } from '../../utils/propertyMonitoringHelpers'

export interface AreaUseSelectionSearchBarProps {
  checkboxData: AreaFilterUse
  handleCheckCodes: (matchCodes: AreaFilterUseCodes) => void
  handleMatching: (matchCodes: AreaFilterUseCodes) => void
  setSearchTerm: (value: string) => void
}

const findMatches = (data: AreaFilterUse, searchTerm: string) => {
  const searchTermLowerCase = selectionSearchBarNormalizer(searchTerm)

  return {
    areaUse: data.areaUse.filter(
      item =>
        !item.disabled &&
        selectionSearchBarNormalizer(item.nazev).includes(searchTermLowerCase)
    ),
    areaType: data.areaType.filter(
      item =>
        !item.disabled &&
        selectionSearchBarNormalizer(item.nazev).includes(searchTermLowerCase)
    ),
  }
}

export const AreaUseSelectionSearchBar: FunctionComponent<AreaUseSelectionSearchBarProps> =
  ({ checkboxData, handleCheckCodes, handleMatching, setSearchTerm }) => {
    const [tooltipContent, setTooltipContent] = useState<string>('')
    const [value, setValue] = useState<string>('')
    const inputRef = useRef<InputRef>(null)

    const handleSearch = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value
        const matchingItems = findMatches(checkboxData, searchValue)

        if (
          matchingItems.areaType.length + matchingItems.areaUse.length ===
          1
        ) {
          setTooltipContent(
            `Enterem vyberete ${
              matchingItems.areaUse?.[0]?.nazev ||
              matchingItems.areaType?.[0]?.nazev
            }`
          )
        } else {
          setTooltipContent('')
        }

        handleMatching({
          areaUse: matchingItems.areaUse?.map(item => item?.kod),
          areaType: matchingItems.areaType?.map(item => item?.kod),
        })
        setSearchTerm(searchValue)
        setValue(searchValue)
      },
      [checkboxData, handleMatching, setSearchTerm]
    )

    const handleFocus = useCallback(() => {
      inputRef.current?.select()
    }, [])

    useEffect(() => {
      if (inputRef.current && !value) {
        inputRef.current.select()
      }
    }, [value])

    const handleEnterKeyDown = useCallback(() => {
      const matchingItems = findMatches(checkboxData, value)

      if (matchingItems.areaType.length === 1) {
        handleCheckCodes({
          areaUse: matchingItems.areaUse?.map(item => item?.kod),
          areaType: matchingItems.areaType?.map(item => item?.kod),
        })
        setSearchTerm('')
        setValue('')
      }
      if (matchingItems.areaUse.length === 1) {
        handleCheckCodes({
          areaUse: matchingItems.areaUse?.map(item => item?.kod),
          areaType: matchingItems.areaType?.map(item => item?.kod),
        })
        setSearchTerm('')
        setValue('')
      }
    }, [checkboxData, handleCheckCodes, setSearchTerm, value])

    return (
      <Tooltip
        placement="topLeft"
        overlayClassName="!max-w-xl"
        title={tooltipContent}
        open={!!tooltipContent && !!value}
      >
        <Input
          ref={inputRef}
          allowClear
          placeholder="Vyhledat způsob využití"
          onChange={handleSearch}
          onFocus={handleFocus}
          onPressEnter={handleEnterKeyDown}
          value={value}
        />
      </Tooltip>
    )
  }
