import { StyleProvider } from '@ant-design/cssinjs'
import { ConfigProvider, Layout } from 'antd'
import locale from 'antd/lib/locale/cs_CZ'
import React, { FunctionComponent, Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import { useToggleState } from '../../utils/useToggleState'
import { MainMenu } from '../MainMenu/MainMenu'
import { TopBar } from '../TopBar/TopBar'

export interface AdminLayoutProps {
  children?: React.ReactNode
}

const siderStyle = {
  background: '#ffffff',
}
const headerStyle = {
  background: '#ffffff',
  height: '50px',
  paddingInline: '0px',
}
const theme = {
  token: {
    colorPrimary: '#53afb0',
    colorInfo: '#4a9899',
    sizeStep: 4,
    borderRadius: 3,
    wireframe: false,
  },
}

export const AdminLayout: FunctionComponent<AdminLayoutProps> = props => {
  const [collapsed, toggleCollapsed, , , setCollapse] = useToggleState(false)
  /*   const [note, contextHolder] = notification.useNotification()

  const openNotificationWithIcon = useCallback(() => {
    note.warning({
      duration: 0,
      placement: 'bottomRight',
      message: 'Aktualizace',
      description:
        'Od úterý 22.10. do středy 23.10. probíhá aktualizace dílčích částí aplikace. Některé funkce mohou být dočasně omezeny. Děkujeme za pochopení.',
    })
  }, [note])

  useEffect(() => {
    openNotificationWithIcon()
  }) */

  return (
    <StyleProvider hashPriority="high">
      <ConfigProvider locale={locale} theme={theme}>
        <Router>
          <Suspense fallback="Loading...">
            <Layout className="flex-1 !min-h-screen">
              <Layout.Header
                className="shadow-md z-10 flex items-center print:hidden"
                style={headerStyle}
              >
                <TopBar collapsed={collapsed} onCollapse={toggleCollapsed} />
              </Layout.Header>
              <Layout className="min-h-inherit">
                <Layout.Sider
                  style={siderStyle}
                  collapsed={collapsed}
                  breakpoint="xxl"
                  width={230}
                  collapsedWidth={50}
                  onBreakpoint={setCollapse}
                  className="site-layout-background print:hidden"
                >
                  <MainMenu />
                </Layout.Sider>
                <Layout.Content className="p-4 flex bg-[#ecf0f5]">
                  {/* {contextHolder} */}
                  {props.children}
                </Layout.Content>
              </Layout>
            </Layout>
          </Suspense>
        </Router>
      </ConfigProvider>
    </StyleProvider>
  )
}
