import axios from 'axios'

import { BASE_URL } from '../../../ini.json'

export type CallName = {
  man: string | null
  female: string | null
} | null

export const getCallName = async (lastName?: string, token?: string) => {
  if (!lastName || !token) return null
  const encodedLastName = encodeURIComponent(lastName)
  const url = `${BASE_URL}/api/drm/callname/${encodedLastName}/${token}`

  try {
    const response = await axios.get(url)
    const data: {
      osloveniMuz: string | null
      osloveniZena: string | null
    } = response.data?.[0]
    if (data) return { man: data.osloveniMuz, female: data.osloveniZena }
    return null
  } catch (error) {
    console.log('nepodařilo se získat oslovení')
    return null
  }
}
