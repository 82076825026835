import { Switch, Table } from 'antd'
import React, { FunctionComponent } from 'react'

import { useDeclension } from '../../utils/useDeclension'

export interface PropertyDetailSubjectsTableExpandedRowSummaryProps {
  involvedCount: number
  currentCount: number
  showHistory: boolean
  setLocalHistory: (value: boolean) => void
}

export const PropertyDetailSubjectsTableExpandedRowSummary: FunctionComponent<PropertyDetailSubjectsTableExpandedRowSummaryProps> =
  props => {
    const decline = useDeclension()

    return (
      <Table.Summary.Row className="bg-white">
        <Table.Summary.Cell
          colSpan={1}
          index={0}
          className="!py-2 border-solid border-t"
        />
        <Table.Summary.Cell
          colSpan={4}
          index={1}
          className="border-solid border-t"
        >
          <div className="flex text-gray-400">
            <span>
              Celkem: {decline(props.involvedCount || 0, 'record', true)}
            </span>
            {props.involvedCount !== props.currentCount && (
              <div className="flex items-center">
                <span className="text-gray-300 mx-3">|</span>
                <span>Aktuální: {props.currentCount}</span>
                <span className="text-gray-300 mx-3">|</span>
                <span className="mr-3">
                  Archivní: {(props.involvedCount || 0) - props.currentCount}
                </span>
                <Switch
                  title="Zobrazit historické záznamy"
                  size="small"
                  checked={props.showHistory}
                  onChange={props.setLocalHistory}
                />
              </div>
            )}
          </div>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    )
  }
