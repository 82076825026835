import { Button, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { ExpandableConfig } from 'antd/es/table/interface'
import axios from 'axios'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import {
  Kanal,
  WatchdogApiResponse,
  WatchdogPropertyFilter,
  WatchdogPropertyTableItem,
} from '../../../common/watchdogTypes'
import { useAppSelector } from '../../../redux/hooks'
import {
  areaNumberSorter,
  stringSorter,
  utcDateSorter,
  utcDescDateSorter,
} from '../../utils/generalTableHelpers'
import { sparsePagination } from '../../utils/layoutConst'
import { useUserActivityLogCheck } from '../../utils/useCheckUserActivityLog'
import { useHandleExpandRow } from '../../utils/useHandleExpandRow'
import { usePropertyTableNoteCheck } from '../../utils/usePropertyTableNoteCheck'
import { seenRowClassName } from '../../utils/userActivityLogHelpers'
import { useSetPropertyDrawer } from '../../utils/useSetPropertyDrawer'
import { watchdogBaseChannel } from '../../utils/watchdogHelpers'
import { Day } from '../DateFormat/DateFormat'
import { ExpandIcon } from '../ExpandIcon/ExpandIcon'
import { PropertyDetailFetchError } from '../PropertyDetailFetchError/PropertyDetailFetchError'
import { PropertyNoteModalButton } from '../PropertyNoteModalButton/PropertyNoteModalButton'
import { WatchdogButton } from '../WatchdogButton/WatchdogButton'
import { WatchdogPropertyTableChannelTooltip } from '../WatchdogPropertyTableChannelTooltip/WatchdogPropertyTableChannelTooltip'
import { WatchdogPropertyTableExpandedRow } from '../WatchdogPropertyTableExpandedRow/WatchdogPropertyTableExpandedRow'

const scrollX = { x: 'max-content' }
const tableId = 'watchdog-property'

export interface WatchdogPropertyTableProps {
  filter: WatchdogPropertyFilter
}

export const WatchdogPropertyTable: FunctionComponent<WatchdogPropertyTableProps> =
  props => {
    const setDrawer = useSetPropertyDrawer()
    const handleExpandRow = useHandleExpandRow(tableId)

    const token = useAppSelector(state => state.myProfile.token)
    const channel = useAppSelector(state => state.watchdog.channel)
    const propertyData = useAppSelector(state => state.watchdog.property.data)
    const expandedRowKeys = useAppSelector(
      state => state.expandedRow.tables?.[tableId] || []
    )

    const [rawData, setRawData] = useState<WatchdogApiResponse | null>(null)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const isFetched = useRef<boolean>(false)

    const currentIds = useRef<string[]>([])
    const propertyNotes = usePropertyTableNoteCheck(currentIds.current)
    const propertiesActivity = useUserActivityLogCheck(currentIds.current)

    useEffect(() => {
      const fetchData = async () => {
        if (!isFetched.current) {
          isFetched.current = true
          try {
            const response = await axios.get(
              `https://data.regesta.cz/DrmApi/api/v1.0/Hlidani/Nemovitosti?access_token=${token}`
            )
            setRawData(response.data)
          } catch (err) {
            setError(true)
          } finally {
            setLoading(false)
          }
        }
      }

      fetchData()
    }, [token])

    const channelData = useMemo(
      () =>
        channel.data?.reduce((result, channel) => {
          result[channel.id] = channel
          return result
        }, {} as { [id: string]: Kanal }) || {},
      [channel.data]
    )

    const dataSource = useMemo(() => {
      if (!rawData) return []

      const jednotky = props.filter.propertyType?.includes(PROPERTY_TYPES.UNIT)
        ? rawData.jednotky.map(item => ({
            propertyId: item.nemovitostId,
            useType: item.zpusobVyuziti?.nazev,
            channelId: item.kanal,
            notificationDate: item.posledniOznameni,
            location: item.castObce?.nazev,
            identifier: item.cisloJednotky,
            type: PROPERTY_TYPES.UNIT,
          }))
        : []

      const budovy = props.filter.propertyType?.includes(
        PROPERTY_TYPES.BUILDING
      )
        ? rawData.budovy.map(item => ({
            propertyId: item.nemovitostId,
            useType: item.zpusobVyuziti?.nazev,
            channelId: item.kanal,
            notificationDate: item.posledniOznameni,
            location: item.castObce?.nazev,
            identifier: item.cislaDomovni || 'Bez č.p.',
            type: PROPERTY_TYPES.BUILDING,
          }))
        : []

      const parcely = props.filter.propertyType?.includes(PROPERTY_TYPES.AREA)
        ? rawData.parcely.map(item => ({
            propertyId: item.nemovitostId,
            useType: item.druhPozemku?.nazev,
            channelId: item.kanal,
            notificationDate: item.posledniOznameni,
            location: item.katastralniUzemi?.nazev,
            identifier: item.parcelniCislo,
            type: PROPERTY_TYPES.AREA,
          }))
        : []

      let combinedData = [...jednotky, ...budovy, ...parcely]

      if (props.filter.channelId !== watchdogBaseChannel.allChannelId) {
        if (props.filter.channelId === watchdogBaseChannel.noChannelId) {
          combinedData = combinedData.filter(item => item.channelId === null)
        } else {
          combinedData = combinedData.filter(
            item => item.channelId === props.filter.channelId
          )
        }
      }

      const sorted = combinedData.sort(utcDescDateSorter('notificationDate'))
      currentIds.current = sorted.slice(0, 50).map(item => item.propertyId)
      return sorted
    }, [props.filter.channelId, props.filter.propertyType, rawData])

    const actionColumnRender = useCallback(
      (propertyId: string, record: WatchdogPropertyTableItem) => (
        <div className="flex space-x-2 items-center justify-end">
          <Button
            type="link"
            size="small"
            onClick={() => handleExpandRow(record.propertyId)}
          >
            Zobrazit změny
          </Button>
          <WatchdogButton
            propertyType={record.type}
            propertyId={propertyId}
            inWatchdogTable
          />
        </div>
      ),
      [handleExpandRow]
    )

    const columns = useMemo<ColumnsType<WatchdogPropertyTableItem>>(
      () => [
        {
          title: 'Poslední Oznámení',
          dataIndex: 'notificationDate',
          className: 'w-24',
          sorter: utcDateSorter('notificationDate'),
          render: notificationDate => <Day>{notificationDate}</Day>,
        },
        {
          title: 'Typ',
          dataIndex: 'type',
          className: 'w-36 first-letter:uppercase',
          sorter: stringSorter('type'),
        },

        {
          title: 'Způsob využití / Druh pozemku',
          dataIndex: 'useType',
          className: 'first-letter:uppercase',
          sorter: stringSorter('useType'),
          render: (useType, record) => (
            <span
              className="text-primary hover:text-primaryHover cursor-pointer"
              title="Zobrazit detail nemovitosti"
              onClick={() => setDrawer(record.propertyId, record.type)}
            >
              {useType}
            </span>
          ),
        },
        {
          title: 'Číslo',
          dataIndex: 'identifier',
          sorter: areaNumberSorter('identifier'),
        },
        {
          title: 'Lokalita',
          dataIndex: 'location',
          sorter: stringSorter('location'),
        },
        {
          title: 'Kanál',
          dataIndex: 'channelId',
          sorter: (a, b) =>
            (a.channelId
              ? channelData[a.channelId]?.nazev || ''
              : ''
            ).localeCompare(
              b.channelId ? channelData[b.channelId]?.nazev || '' : ''
            ),
          render: channelId => (
            <WatchdogPropertyTableChannelTooltip
              channel={channelData[channelId]}
            />
          ),
        },
        {
          title: 'Poznámka',
          dataIndex: 'propertyId',
          key: 'note',
          width: 84,
          render: propertyId => (
            <PropertyNoteModalButton note={propertyNotes?.[propertyId]} />
          ),
        },
        {
          title: 'Akce',
          dataIndex: 'propertyId',
          key: 'actions',
          className: '!text-right w-40 !py-0',
          render: actionColumnRender,
        },
      ],
      [actionColumnRender, channelData, propertyNotes, setDrawer]
    )

    const summary = useCallback(() => {
      if (dataSource.length > 1) {
        return (
          <Table.Summary.Row>
            <Table.Summary.Cell
              className="text-gray-400 !border-b-0 border-gray-100 border-t"
              index={1}
              colSpan={columns.length}
            >
              Celkem: {dataSource.length} položek
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )
      }
      return null
    }, [dataSource.length, columns.length])

    const expandable = useMemo<
      ExpandableConfig<WatchdogPropertyTableItem>
    >(() => {
      return {
        expandedRowKeys,
        expandedRowRender: record => (
          <WatchdogPropertyTableExpandedRow
            propertyId={record.propertyId}
            propertyType={record.type}
          />
        ),
        expandIcon: ({ expanded, record }) => (
          <ExpandIcon
            expanded={expanded}
            recordId={record.propertyId}
            tableId={tableId}
            expandable
          />
        ),
      }
    }, [expandedRowKeys])

    const rowClassName = useCallback(
      (record: WatchdogPropertyTableItem) => {
        const isRemoved = !propertyData?.[record.propertyId] ? 'opacity-50' : ''
        const isExpanded = expandedRowKeys.includes(record.propertyId)
          ? 'rowExpanded fade-in'
          : ''
        const isSeen = seenRowClassName(
          propertiesActivity?.[record.propertyId]?.editedAt,
          record.notificationDate
        )
        return [isRemoved, isExpanded, isSeen].join(' ')
      },
      [expandedRowKeys, propertiesActivity, propertyData]
    )

    const handlePagination = useCallback(
      (page: number, pageSize: number) => {
        const startIndex = (page - 1) * pageSize
        const endIndex = startIndex + pageSize
        currentIds.current = dataSource
          .slice(startIndex, endIndex)
          .map(item => item.propertyId)
      },
      [dataSource]
    )

    const pagination = useMemo(
      () => ({ ...sparsePagination, onChange: handlePagination }),
      [handlePagination]
    )

    if (error)
      return (
        <PropertyDetailFetchError
          description="Je nám líto, ale nepodařilo se načíst údaje z hlídače katastru. Opakujte prosím akci později."
          message="Chyba při načítání dat"
        />
      )

    return (
      <Table
        id={tableId}
        rowKey="propertyId"
        size="small"
        className="border-t border-gray-200"
        rowClassName={rowClassName}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        expandable={expandable}
        pagination={pagination}
        summary={summary}
        scroll={scrollX}
      />
    )
  }
