import { Button, Checkbox, message } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import React, { FunctionComponent, useCallback } from 'react'

import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { setCrmAutoImport } from '../../../redux/myProfileSlice'
import { setAutoImport } from '../../utils/cadastralImportHelpers'
import { requestPath } from '../../utils/paths'

export interface CadastralAutoImportControllButtonProps {
  elementType?: 'button' | 'checkbox'
  className?: string
  buttonProps?: ButtonProps
}

export const testPopupWindow = async () => {
  return new Promise<boolean>(resolve => {
    const popupWindow = window.open(
      requestPath('browserPopupTest'),
      'popUpTest',
      'width=400,height=400'
    )

    if (popupWindow === null) {
      message.warning(
        'Pro použití automatického importu prosím povolte vyskakovací okna pro naši aplikaci.',
        5
      )
      resolve(false)
      return
    }

    popupWindow.close()
    message.success('Automatický import nastaven.')
    resolve(true)
  })
}

export const CadastralAutoImportControllButton: FunctionComponent<CadastralAutoImportControllButtonProps> =
  props => {
    const dispatch = useAppDispatch()
    const crmAutoImport = useAppSelector(state => state.myProfile.autoImport)

    const handleCheckboxChange = useCallback(async () => {
      const response = await setAutoImport(!crmAutoImport)

      if (response) {
        if (!crmAutoImport === true) {
          await testPopupWindow()
        }
        dispatch(
          setCrmAutoImport({
            autoImport: !crmAutoImport,
          })
        )
      }
    }, [crmAutoImport, dispatch])

    if (props.elementType === 'checkbox')
      return (
        <Checkbox
          checked={crmAutoImport}
          className={props.className}
          onChange={handleCheckboxChange}
          rootClassName="cadastral-auto-import-checkbox"
        >
          Importovat automaticky
        </Checkbox>
      )

    return (
      <Button
        className={props.className}
        type="primary"
        onClick={handleCheckboxChange}
        ghost={crmAutoImport}
        danger={crmAutoImport}
        {...props.buttonProps}
      >
        {crmAutoImport
          ? 'Vypnout automatický import'
          : 'Zapnout automatický import'}
      </Button>
    )
  }
