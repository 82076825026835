import {
  PlusCircleOutlined,
  RocketOutlined,
  StarFilled,
  ToolOutlined,
} from '@ant-design/icons'
import { Icon } from '@iconify/react'
import React from 'react'

export const timelineData = [
  {
    date: 'Listopad 2024',
    title: 'Rozšíření administrace uživatelského účtu',
    dot: <StarFilled className="text-gray-400" />,
    description:
      'Chystáme novou správu uživatelského účtu rozšiřující dosavadní možnosti nastavení. Společně s tím také nové a přehlednější uživatelské prostředí.',
  },
  {
    date: 'Listopad 2024',
    title: 'Nevyžádané odhlašování',
    dot: <ToolOutlined />,
    description:
      'Odstranili jsme problém s častým automatickým odhlašováním z aplikace, který způsoboval přesměrování na přihlašovací stránku, popřípadě mohl vyvolávat zobrazení chybového oznámení "Problém při stahování dat". Přestože jsme z bezpečnostních důvodů funkci automatického odhlášení v systému ponechali, je nyní platnost přihlášení přímo závislá na poslední aktivitě uživatele a při každé akci se prodlouží.',
  },
  {
    date: 'Listopad 2024',
    title: 'Hldač katastru nemovitostí',
    module: true,
    description:
      'Funkce umožňující hlídat změny na nemovitostech a dostávat e-mailem oznámení o nových zápisech v katastru nemovitostí. Nejen, že dostanete upozornění ihned při začátku řízení, dostatečně dlouho před jeho ukončením, ale budete informováni o všech dílčích událostech během jeho průběhu. Správu katastrálního hlídače jsme upravili tak, abyste mohli pohodlněji kontrolovat a procházet poslední změny u nemovitostí. Jednoduše přidávat a odebírat nemovitosti z hlídače a měli lepší přehled o kanálech a jejich nastavení.',
  },
  {
    date: 'Listopad 2024',
    title: 'Poznámky v přehledech nemovitostí',
    description: `V tabulkových přehledech (monitoring exekucí, insolvencí...) máte nyní k dispozici sloupec ${'"'}Poznámka${'"'}, kde se zobrazuje poslední přidaná poznámka u nemovitosti. Po jejím rozkliknutí se zobrazí také ostatní poznámky ze stejného listu vlastnictví a možnost přidání nové poznámky přímo z přehledu.`,
  },
  {
    date: 'Listopad 2024',
    title: 'Náhledy listů vlastnictví',
    module: true,
    description:
      'Vyhledávání LV je efektivní nástroj, jak získat informace o listu vlastnictví, aniž byste museli stahovat dálkovým přístupem placený výpis v podobě listiny. Není tomu tak dávno, co prošel kompletní restrukturalizací detail nemovitosti, abychom zachovali jednotný vzhled a funkčnost aplikace, sjednotili jsme po vizuální stránce také detail LV a doplnili jej o řadu nových vylepšení, které rozšířily možnosti, jak efektivně pracovat s dostupnými daty.',
  },
  {
    date: 'Listopad 2024',
    title: 'Vyhledávání budov dle adresy',
    description:
      'Tento modul prošel primárně úpravou uživatelského prostředí, abychom jeho užívání udělali pohodlnější a vzhled přehlednější.',
  },
  {
    date: 'Listopad 2024',
    title: 'Vyhledávání katastrálních řízení',
    module: true,
    description:
      'Podle čísla řízení si můžete okamžitě vyhledat jeho detail s přehledem listin, informacemi o průběhu, figurujících subjektech a výpisem nemovitostí dotčených v rámci daného řízení. Samozřejmostí je pokročilá správa dokumentů a nemovitostí stejně jako v ostatních částech aplikace.',
  },
  {
    date: 'Listopad 2024',
    title: 'Vyhledávání pozemků',
    module: true,
    description:
      'Vyhledávat můžete konkrétní pozemky dle jejich parcelního čísla, stejně jako všechny pozemky v daném katastrálním území. Modul prošel výraznou změnou a zrychlením, ve výpisu si můžete zobrazovat rychlé detaily parcel v postranním panelu, přidávat do hlídače jedním kliknutím a zároveň vidíte časové razítko vašeho posledního náhledu.',
  },
  {
    date: 'Listopad 2024',
    title: 'Přehled všech poznámek',
    module: true,
    description: `V hlavní nabídce pod kartou ${'"'}Přehledy${'"'} můžete najít nový výpis poznámek. V tabulce jsou zobrazeny všechny poznámky v chronologickém pořadí, které jste kdy Vy, nebo členové vašeho týmu k nemovitostem přidali. Pokud jste autorem poznámky, můžete ji upravit i smazat.`,
  },
  {
    date: 'Říjen 2024',
    title: 'Hlídání nemovitostí',
    description: (
      <span>
        <span>
          Napříč aplikací jsme k nejrůznějším výpisům nemovitostí i do jejich
          detailů přidali ikonu zvonečku{' '}
        </span>
        <Icon
          icon="material-symbols:notifications-outline"
          className="inline"
        />
        <span>
          , díky které můžete aktivovat/deaktivovat hlídání změn vždy dle
          potřeby a zároveň slouží k lepší orientaci v tabulkách s výpisem
          nemovitostí.
        </span>
      </span>
    ),
  },
  {
    date: 'Říjen 2024',
    title: 'Tabulkový výpis nemovitostí v dražbě',
    module: true,
    description:
      'K již existující mapové vrstvě přibyl také tabulkový přehled včetně dlaždicového zobrazení. V tabulce máte možnost stažení dražební vyhlášky, přechod do detailu dražby či zobrazení rychlého náhledu bez nutnosti přesměrování. Stejně jako u ostatních monitoringů i zde můžete výsledky filtrovat dle vybraných parametrů a řadit podle libovolného sloupce. U každé položky v tabulce vidíte, zda jste si již danou dražbu zobrazili.',
  },
  {
    date: 'Říjen 2024',
    title: 'Nasazení nových modulů a rozšíření',
    dot: <Icon icon="iconoir:three-stars-solid" width="16" height="16" />,
    description:
      'Změny, které jsme pro vás v posledních měsících horlivě připravovali, byly natolik komplexní a vzájemně provázané, že jsme jejich nasazení do produkční verze aplikace nemohli provádět průběžně během vývoje, nýbrž bylo potřeba připravit celý balík změn. Úpravy jsou nyní nasazeny a dostupné pro všechny uživatele na základě jejich tarifů. Jedná se o nové moduly a aktualizace, které rozšiřují funkcionalitu aplikace a přináší nové možnosti pro efektivnější práci s nemovitostmi a subjekty.',
  },
  {
    date: 'Říjen 2024',
    title: 'Nemovitosti v dražbě',
    dot: <StarFilled className="text-gray-300" />,
    description:
      'Nový modul monitorující nemovitosti v dražbách napříč republikou. Jako první jsme uveřejnili mapové rozhraní včetně filtrace dle typu nemovitosti, ceny a stavu dražby (připravované/ukončené). Můžete si tak snadno ověřit detailní informace o nemovitosti, které se často na dražebních portálech nevyskytují.',
  },
  {
    date: 'Září 2024',
    title: 'Nemovitosti v dědictví',
    dot: <StarFilled className="text-gray-300" />,
    description:
      'Monitoring dědických řízení prošel renovací a tabulkový přehled nyní nabízí lépe strukturovaná data včetně snadnějšího přístupu k detailním informacím o nemovitostech, listinách a samotném průběhu řízení.',
  },
  {
    date: 'Září 2024',
    title: 'Nemovitosti v insolvenci',
    dot: <StarFilled className="text-gray-300" />,
    description: (
      <div>
        Nový modul nemovitostí v insolvenci umožňující řazení zobrazených
        výsledků, přehlednější nastavení filtrů včetně filtrace dle velikosti
        podílu vlastnických práv dotčeného subjektu a rychlý náhled nemovitosti
        bez nutnosti přechodu na novou stránku. K dispozici máte aktuálně 4
        různé způsoby zobrazení.
        <br />
        Klasický tabulkový přehled, na který jste byli zvyklí.
        <br />
        Tabulku shlukující nemovitosti na základě totožného čísla insolvence,
        kdy na řádku vidíte souhrn údajů o všech dotčených nemovitostech a
        jednotlivé budovy/parcely/jednotky až po rozbalení záznamu.
        <br />
        Dlaždicové zobrazení, u něhož je krom informací rovnou vykreslena také
        fotografie a mapa s polohou nemovitosti.
        <br />
        Poslední způsob, jak procházet všechny nemovitosti v insolvenci napříč
        republikou je mapová vrstva, která značně usnadňuje vyhledávání v
        konkrétních lokalitách a zároveň umožňuje také zobrazení informací o
        přilehlých nemovitostech.
      </div>
    ),
  },
  {
    date: 'Září 2024',
    title: 'Nemovitosti v exekuci',
    dot: <StarFilled className="text-gray-300" />,
    description: (
      <div>
        Nový modul nemovitostí v exekuci umožňující řazení zobrazených výsledků,
        přehlednější filtraci včetně nastavení rozsahu pro počet subjektů s
        vlastnickým podílem a rychlým náhledem nemovitosti bez nutnosti přechodu
        na novou stránku. K dispozici jsou 3 různé způsoby zobrazení.
        <br />
        Klasický tabulkový přehled se základním přehledem informací.
        <br />
        Dlaždicové zobrazení, u něhož je krom informací rovnou vykreslena také
        fotografie a mapa s polohou nemovitosti.
        <br />
        Mapová vrstva se zakreslenou polohou všech nemovitostí v exekuci napříč
        republikou, která výrazně ulehčuje vyhledávání v konkrétních lokalitách
        a zároveň umožňuje také zobrazení informací o přilehlých nemovitostech.
      </div>
    ),
  },
  {
    date: 'Srpen 2024',
    title: 'Katastrální mapa',
    dot: <StarFilled className="text-gray-300" />,
    description: (
      <div>
        Katastrální mapa s pokročilou filtrací, která na základě vybraných
        parametrů zobrazí v mapě všechny nemovitosti splňující zadaná kritéria,
        u nichž si následně můžete procházet detailní informace.
        <br />
        Pro budovy je dostupná filtrace na základě 13 různých parametrů jako
        způsob využití, zastavěná/podlahová plocha, počet podlaží, počet bytů v
        budově, připojení na vodovod/kanalizaci/plyn, způsob vytápění a další.
        <br />U pozemků můžete výsledky filtrovat dle druhu a způsobu využití a
        rozlohy v rozsahu od/do v m².
      </div>
    ),
  },
  {
    date: 'Srpen 2024',
    title: 'Vyhledávání v CEE + ČÚZK',
    dot: <PlusCircleOutlined className="text-gray-300" />,
    description:
      'Při vyhledávání subjektů v Centrální Evidenci Exekucí můžete nyní jedním kliknutím zařadit subjekty také ke kontrole v evidenci ČÚZK.',
  },
  {
    date: 'Srpen 2024',
    title: 'Rychlý náhled nemovitosti',
    dot: <StarFilled className="text-gray-300" />,
    description:
      'Pro efektivnější práci s nemovitostmi jsme vytvořili Rychlý náhled, který umožňuje prohlédnout si základní údaje o nemovitosti v pravém vyjížděcím panelu (podobně jako doposud např. PDF dokumenty) bez přesměrování na stránku detailu. Tato forma zobrazení usnadňuje procházení nemovitostí především v hromadných tabulkových přehledech. V rychlém náhledu máte okamžitý přístup k fotografii/mapě, omezení vlastnických práv, poznámkám, vlastnickým podílům, základním údajům o budově/parcele/jednotce a listu vlastnictví. Pokud potřebujete získat konkrétnější data, vždy můžete z náhledu jednoduše přejít na detail nemovitosti.',
  },
  {
    date: 'Červenec 2024',
    title: 'Nový detail nemovitosti',
    dot: <StarFilled className="text-gray-300" />,
    description:
      'Od základu jsme přepracovali detail nemovitosti, který byl doplněn o nové karty přinášející nepřebernou škálu funkcionalit a evidovaných údajů. Výraznou změnou oproti předešlé verzi je způsob zobrazení dat. Levý sloupec je nyní zobrazen staticky nezávisle na vybrané kartě (v případě potřeby jej můžete minimalizovat), obsahuje fotografii a mapu, základní informace o budově/parcele/jednotce, identifikační údaje listu vlastnictví + případně stažené výpisy a poslední poznámku, pravý sloupec slouží pro zobrazení informací z vybrané karty.',
  },
  {
    date: 'Červenec 2024',
    title: 'Karta informací o obci',
    dot: <PlusCircleOutlined className="text-gray-300" />,
    description:
      'Na nové kartě máte k dispozici strukturované informace o obci, pod kterou daná nemovitost spadá. Jedná se o základní informace o obci (velikost, obyvatelé, rozloha...), technickou vybavenost obce (kanalizace, vodovod, plyn, cyklostezky... ), občanskou vybavenost (hřiště, kina, koupaliště, restaurace, bankomaty...), vzdělávací instituce (mateřské, základní, střední školy a jejich dílčí rozdělení...), sociální oblast (nemocnice, LDN, polikliniky, azylové domy, domovy důchodců...).',
  },
  {
    date: 'Červenec 2024',
    title: 'Karta cenových odhadů',
    dot: <PlusCircleOutlined className="text-gray-300" />,
    description:
      'Cenové odhady nyní můžete vytvářet přímo v detailu nemovitosti a spravovat zde také všechny odhady již historicky vytvořené.',
  },
  {
    date: 'Červenec 2024',
    title: 'Karta katastrálních řízení',
    dot: <PlusCircleOutlined className="text-gray-300" />,
    description:
      'Všechna katastrální řízení vedená u vybrané nemovitosti jsou zobrazena v tabulce umožňující jejich řazení na základě evidovaných údajů. Nový typ zobrazení usnadňuje vyhledávání potřebných informací a správu přiložených listin.',
  },
  {
    date: 'Červen 2024',
    title: 'Karta subjektů na adrese',
    dot: <PlusCircleOutlined className="text-gray-300" />,
    description:
      'Subjekty na adrese jsou nyní rozděleny do 3 samostatných tabulek podle toho, zda se na adrese nachází jejich trvalé bydliště, sídlo, nebo provozovna. Přibyly nástroje pro správu hromadné pošty.',
  },
  {
    date: 'Červen 2024',
    title: 'Karta časové osy',
    dot: <PlusCircleOutlined className="text-gray-300" />,
    description:
      'Časová osa v detailu nemovitosti prošla komplexní úpravou, nyní jsou data řazena do 3 samostatných kategorií: Vlastnictví, Omezení a jiné zápisy, Řízení. U jednotlivých položek byly přidány doplňující údaje, omezení jsou rozlišovány barevně dle kategorie a řízení podporují zobrazení detailu v rychlém náhledu bez nutnosti opustit časovou osu.',
  },
  {
    date: 'Červen 2024',
    title: 'Karta vlastnickcýh práv',
    dot: <PlusCircleOutlined className="text-gray-300" />,
    description: (
      <div>
        Na této kartě v detailu nemovitosti nyní uvidíte přehled omezení,
        veškerá vlastnická práva, další nemovitosti spadající pod stejný list
        vlastnictví a případně výpis evidovaných jednotek v budově.
        <br />
        Omezení vlastnických práv a jiné zápisy jsou nyní kategorizovány a
        zobrazeny v tabulce. K dispozici máte také historii již neplatných
        zápisů včetně data vzniku/zániku.
        <br />
        Přehled všech typů práv a jejich držitelů je zobrazován v tabulce
        zobrazující právní formu subjektu, jméno, adresu, procentuální podíl,
        vzdálenost sídla/bydliště od dané nemovitosti a nástroje pro práci s
        hromadnou poštou. K dispozici máte také graf znázorňující rozdělení
        vlastnických práv.
        <br />
        Ostatní nemovitosti spadající pod stejný list vlastnictví jsou zobrazeny
        také v tabulce a doplněny o jejich výměru, způsob využití a případná
        omezení vlastnických práv. U budov je vždy uvedena parcela, na které
        leží a naopak. K dispozici je také graf znázorňující souhrn všech parcel
        dle jejich kategorizace a součtu výměr.
        <br />
        Výpis samostatně evidovaných jednotek v budově prošel obměnou a krom
        přehlednějšího zobrazení je nyní doplněn o štítky přiřazené k jednotkám.
      </div>
    ),
  },
  {
    date: 'Květen 2024',
    title: 'Štítky k nemovitostem',
    dot: <StarFilled className="text-gray-300" />,
    description:
      'U každé nemovitosti nově přibyla funkce pro správu štítků, nyní tak můžete propojit konkrétní nemovitost s jinými údaji napříč aplikací a lépe organizovat jednotlivé obchodní případy.',
  },
  {
    date: 'Květen 2024',
    title: 'Poznámky k nemovitostem',
    dot: <StarFilled className="text-gray-300" />,
    description:
      'U nemovitostí máte nyní k dispozici pokročilou správu poznámek umožňující přidávání libovolného počtu zpráv. Každou poznámku můžete pro lepší kategorizaci doplnit nastylovanou ikonou a v případě potřeby také URL odkazem na externí zdroje. Zobrazení zpráv je seřazeno chronologicky v časové ose pro zjednodušení komunikace v rámci týmu, navíc je daná komunikace viditelná u všech nemovitostí spadajících pod stejný list vlastnictví. Vaše komentáře můžete zpětně editovat i mazat.',
  },
  {
    date: 'Duben 2024',
    title: 'Nový detail řízení',
    dot: <StarFilled className="text-gray-300" />,
    description:
      'Nový detail katastrálního řízení přinesl krom přehlednějšího zobrazení také rozšířené možnosti při práci s listinami. Nově máte k dispozici veškeré listiny přímo v detailu, což umožňuje snadnější práci se staženými dokumenty a zároveň můžete jedním kliknutím zažádat příslušný katastrální úřad o doplnění chybějící listiny, která nebyla dosud zpřístupněna on-line. V přehledu vidíte veškeré údaje o řízení včetně účastníků a časové osy jednotlivých úkonů. Inovací prošla také tabulka dotčených nemovitostí. Napříč aplikací se nyní detail řízení vykresluje ve vyskakovacím okně, díky čemuž si můžete řízení prohlížet odkudkoli bez zbytečného přesměrování.',
  },
  {
    date: 'Duben 2024',
    title: 'Ekonomické subjekty',
    description:
      'Nová funkce, která umožňuje vyhledávání ekonomických subjektů a zpřístupňuje jejich detailní informace. Společně s tím se napříč systémem usnadňuje zadávání dat při vyhledávání v nejrůznějších evidencích.',
  },
  {
    date: 'Únor 2024',
    title: 'Hromadná pošta',
    module: true,
    description:
      'Došlo k rozdělení modulu hromadné pošty na Hromadnou a Katastrální. Zatímco modul Katastrální pošta (dříve nazývaný Hromadná p.) slouží k obesílání vlastníků nemovitostí zařazených v Záložkách, nový modul Hromadné pošty umožňuje vytvářet vlastní seznamy adresátů (včetně hromadného importu v CSV), zasílat korespondenci do zahraničí a odesílát dopisy jako doporučenou zásilku, což přináší možnost sledovat průběh doručení zásilek. Zároveň s tím jsme rozšířili funkce editoru šablon, který nyní umožňuje vytvářet vlastní proměnné a vkládat do šablon obrázky a podpis. ',
  },
  {
    date: 'Leden 2024',
    title: 'Rozšíření cenových odhadů',
    description:
      'Do cenových odhadů byl přdán graf historického vývoje ceny nemovitosti a výčet faktorů, které mají výrazný vliv na cenu nemovitosti. V PDF výstupu je nyní zobrazen i počet vlastníků a velikost jejich podílů.',
  },
  {
    date: 'Prosinec 2023',
    title: 'Hromadná editace v CEE',
    description:
      'Nyní lze hromadně editovat záznamy v modulu CEE. Můžete tak skupinově upravovat štítky, poznámky, mazat, exportovat, naplánovat či spustit novou kontrolu.',
  },
  {
    date: 'Prosinec 2023',
    title: 'Export tabulek do Excelu',
    description:
      'Většina tabulkových přehledů napříč aplikací lze nyní exportovat do Excelu.',
  },
  {
    date: 'Prosinec 2023',
    title: 'Rozdělení CEE na lustrace a monitoring',
    module: true,
    description:
      'Došlo k rozdělení modulu CEE na dvě dílčí části - lustrace a monitoring. Lustrace slouží k jednorázovému vyhledávání a ověření informací o exekucích pro daný subjekt, zatímco monitoring slouží k periodické kontrole subjektu, což umožňuje okamžitě odhalit nové exekuce.',
  },
  {
    date: 'Listopad 2023',
    title: 'Rozšíření cenových odhadů',
    description:
      'Do PDF výstupu jsme zahrnuli informace o listu vlastnictví včetně informací o exekucích, zástavách a jiných omezení, dále přibyl přehled základních informací o obci (velikost, obyvatelé, rozloha...), technická vybavenost obce (kanalizace, vodovod, plyn, cyklostezky... ), občanská vybavenost (hřiště, kina, koupaliště, restaurace, bankomaty...), vzdělání (mateřské, základní, střední školy a jejich dílčí rozdělení...), sociální oblast (nemocnice, LDN, polikliniky, azylové domy, domovy důchodců...).',
  },
  {
    date: 'Listopad 2023',
    title: 'Interaktivní List vlastnictví',
    module: true,
    description:
      'Jakmile si stáhnete list vlastnictví z dálkového přístupu ČÚZK, můžete si zobrazit také interaktivní verzi, kde máte možnost pohodlně přistupovat k podrobnějším informacím - grafické znázornění rozdělení podílů a pozemků, detaily o jednotlivých subjektech, nemovitostech, BPEJ, nabývacích titulech a jiných řízeních uvedených v LV, jednoduché stahování listin, kontrola exekucí či dalších vlastnických práv subjektů. LV můžete stáhnout v PDF, XML, JSON a HTML formátu.',
  },
  {
    date: 'Říjen 2023',
    title: 'Stahování LV',
    module: true,
    description:
      'Nový modul, který umožňuje stahovat listy vlastnictví v PDF prostřednictvím dálkového přístupu ČÚZK.',
  },
  {
    date: 'Říjen 2023',
    title: 'Rozšíření CEE o jméno a IČ',
    description:
      'Vyhledávání subjektů v evidenci CEE bylo rozšířeno o možnost vyhledávání podle IČ a jména subjektu.',
  },
  {
    date: 'Říjen 2023',
    title: 'Hromadná editace ČÚZK subjektů',
    description:
      'Nyní lze hromadně editovat záznamy v modulu pro vyhledávání osob v ČÚZK. Můžete tak skupinově upravovat štítky, poznámky, mazat, exportovat, nastavit interval či spustit novou kontrolu.',
  },
  {
    date: 'Září 2023',
    title: 'Rozšíření vyhledání ČÚZK subjektů',
    description:
      'Vyhledávání a monitoring subjektů v ČÚZK bylo rozšířeno o možnost vyhledávání také podle IČ a jména subjektu.',
  },
  {
    date: 'Září 2023',
    title: 'Štítky',
    module: true,
    description:
      'Vytvořen nový modul štítků - funkce, která napříč aplikací umožňuje lepší organizaci, propojení, popisování a kategorizaci různorodých záznamů prostřednictvím libovolně nastavitelných štítků.',
  },
  {
    date: 'Srpen 2023',
    title: 'Zástavy',
    module: true,
    description:
      'Rozšíření modulu EPO o možnost vyhledávání zástavních práv. K dispozici máte nově možnost ověření práv 3. stran, čímž získáte přehled nemovitostí daného subjektu, které jsou zatíženy předkupním právem, exekucí či insolvencí.',
  },
  {
    date: 'Srpen 2023',
    title: 'Evidence práv pro osobu',
    module: true,
    description:
      'Nový modul pro vyhledávání evidence práv osob (EPO), který umožňuje získat informace o vlastnictví nemovitostí, podílech na nemovitostech, zástavních právech, sveřenských fondech atd. Data jsou zobrazována v přehledných tabulkách umožňujících proklik na detail listu vlastnictví či export do Excelu, XML, json a HTML.',
  },
  {
    date: 'Červenec 2023',
    title: 'Hromadná pošta',
    module: true,
    description:
      'Nový modul hromadné pošty, který přináší možnost rozesílat dopisy prostřednictvím české pošty přímo z aplikace. Za pomocí záložek si vytváříte seznamy adresátů, které následně můžete obeslat dopisem. K dispozici máte také grafický editor pro vytváření šablon dopisů, které pracují s proměnnými, což vám umožní vytvářet personalizované dopisy obsahující informace týkající se vždy daného adresáta včetně automatického skloňování přijmení pro oslovení.',
  },
  {
    date: 'Květen 2023',
    title: 'Sbírka listin ČÚZK',
    module: true,
    description:
      'Nový modul, který umožňuje stahovat listiny v PDF vedené jako přílohy v ČÚZK řízení.',
  },
  {
    date: 'Květen 2023',
    title: 'Lustrace CEE',
    module: true,
    description:
      'Nový modul, který umožňuje vyhledávat subjekt v databázi Centrální evidence exekucí a získávat detailní informace o exekucích pro daný subjekt.',
  },
  {
    date: 'Duben 2023',
    title: 'Cenový odhad',
    module: true,
    description:
      'Nový modul, který umožňuje rychle a pohodlně získat cenový odhad nemovitosti na základě námi evidovaných informací a zadaných doplňujících údajů. Výstupem je přehledné PDF s informacemi o nemovitosti a její ceně.',
  },
  {
    date: 'Březen 2023',
    title: 'Vyhledávání osob v ČÚZK',
    module: true,
    description:
      'První modul ve verzi 2.0 umožňuje vyhledávat subjekty v databázi Českého úřadu zeměměřického a katastrálního, můžete tak získat detailnější informace o subjektu, jeho majetkových právech a zobrazit si detailní informace o nemovitostech, které subjekt vlastní. Modul umožňuje také periodické monitorování subjektu, což vám umožní okamžitě odhalit nově nabitá vlastnická práva.',
  },
  {
    date: 'Rok 2023',
    title: 'REGESTA 2.0',
    dot: <RocketOutlined rotate={45} className="text-xl" />,
    description:
      'Započali jsme intenzivní vývoj nové verze aplikace, během něhož budeme zdokonalovat dosavadní funkce systému a vytvářet nespočet nových modulů, které rozšíří dosavadní aplikaci o nové možnosti a nástroje, čímž vám budeme schopni dodat ještě komplexnější a robustnější nástroj pro zvýšení efektivity vaší práce a zpřístupnění nových možnosti na poli vašeho podnikání. Přestože jsme zvolili pracovní označení REGESTA 2.0, nejedná se o oddělenou aplikaci od té, kterou doposud znáte. Ačkoliv pro vývoj používáme nové a modernější technologie, rozhodli jsme se k postupnému nasazování nových funkcionalit, které budou kontinuálně doplňovat a rozširovat stávající aplikaci. Aktuálně máme vypracovaný plán vývoje do roku 2025.',
  },
]
