import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { ForclosureMonitoringRecord } from '../../../common/foreclosureMonitoringTypes'
import { UserActivityLogsResult } from '../../../common/userActivityLogTypes'
import {
  nestedStringSorter,
  numberSorter,
} from '../../utils/generalTableHelpers'
import { PropertyTableNotes } from '../../utils/propertyDetailNoteHelpers'
import {
  dateSorter,
  restrictionColumn,
} from '../../utils/propertyMonitoringHelpers'
import { seenRowClassName } from '../../utils/userActivityLogHelpers'
import { useSetPropertyDrawer } from '../../utils/useSetPropertyDrawer'
import {
  watchdogAreaColumn,
  watchdogBuildingColumn,
  watchdogUnitColumn,
} from '../../utils/watchdogHelpers'
import { Day } from '../DateFormat/DateFormat'
import { Money } from '../Money/Money'
import { PropertyNoteModalButton } from '../PropertyNoteModalButton/PropertyNoteModalButton'
import { UserActivityLogBadge } from '../UserActivityLogBadge/UserActivityLogBadge'

export interface ForeclosureMonitoringTableProps {
  data?: ForclosureMonitoringRecord[] | null
  propertyType: PROPERTY_TYPES
  userActivityLogs?: UserActivityLogsResult
  propertyNotes?: PropertyTableNotes
}

const tableId = 'foreclosure-monitoring'
const scrollX = { x: 'max-content' }

export const ForeclosureMonitoringTable: FunctionComponent<ForeclosureMonitoringTableProps> =
  ({
    propertyType,
    data,
    userActivityLogs: propertiesActivity,
    propertyNotes,
  }) => {
    const setDrawer = useSetPropertyDrawer()

    const columns = useMemo<ColumnsType<ForclosureMonitoringRecord>>(
      () => [
        {
          title: 'Poslední vklad/zápis',
          dataIndex: 'posledniVkladZapis',
          className: 'w-32',
          sorter: dateSorter('posledniVkladZapis'),
          render: (posledniVkladZapis: string) =>
            posledniVkladZapis && <Day>{posledniVkladZapis}</Day>,
        },
        {
          title: 'Katastrální pracovistě',
          dataIndex: ['pracoviste', 'nazev'],
          sorter: nestedStringSorter('pracoviste', 'nazev'),
        },
        {
          title: 'Způsob využití',
          dataIndex: ['zpusobVyuziti', 'nazev'],
          sorter: nestedStringSorter('zpusobVyuziti', 'nazev'),
          render: (zpusobVyuziti: string, record) =>
            zpusobVyuziti ? (
              <a
                onClick={() => setDrawer(record.id, propertyType)}
                title="Zobrazit náhled nemovitosti"
              >
                {zpusobVyuziti}
              </a>
            ) : (
              <span
                className="text-gray-400"
                title="ČÚZK u této nemovitosti neeviduje způsob využití"
              >
                ---
              </span>
            ),
        },
        ...(propertyType === PROPERTY_TYPES.AREA
          ? ([
              {
                title: 'Druh pozemku',
                dataIndex: ['druhPozemku', 'nazev'],
                render: (druhPozemku, record) => (
                  <a
                    onClick={() => setDrawer(record.id, propertyType)}
                    title="Zobrazit náhled nemovitosti"
                  >
                    {druhPozemku || '- Druh nezjištěn -'}
                  </a>
                ),
                sorter: nestedStringSorter('druhPozemku', 'nazev'),
              },
              {
                title: 'Výměra (m²)',
                dataIndex: 'vymera',
                render: vymera => (
                  <Money
                    amount={vymera}
                    decimalNumbers={0}
                    className="block max-w-[70px] text-right"
                  />
                ),
                sorter: numberSorter('vymera'),
              },
            ] as ColumnsType<ForclosureMonitoringRecord>)
          : []),

        restrictionColumn.exekuce,
        restrictionColumn.insolvence,
        restrictionColumn.zastavySmluvni,
        restrictionColumn.zastavyNedobrovolne,

        {
          title: 'Počet vlastníků',
          dataIndex: 'pocetVlastniku',
          className: 'w-28',
          sorter: numberSorter('pocetVlastniku'),
          render: pocetVlastniku => (
            <div className="text-right max-w-[40px]">{pocetVlastniku}</div>
          ),
        },
        {
          title: 'Poznámka',
          dataIndex: 'id',
          key: 'note',
          width: 84,
          render: id => <PropertyNoteModalButton note={propertyNotes?.[id]} />,
        },
        {
          title: 'Info',
          dataIndex: 'id',
          key: 'info',
          width: 110,
          render: (id, record) => (
            <UserActivityLogBadge
              userActivityLog={propertiesActivity?.[id]}
              alertDateTo={record.posledniVkladZapis}
            />
          ),
        },
        propertyType === PROPERTY_TYPES.AREA
          ? watchdogAreaColumn
          : propertyType === PROPERTY_TYPES.BUILDING
          ? watchdogBuildingColumn
          : watchdogUnitColumn,
      ],
      [propertiesActivity, propertyNotes, propertyType, setDrawer]
    )

    const rowClassName = useCallback(
      (record: ForclosureMonitoringRecord) =>
        seenRowClassName(
          propertiesActivity?.[record.id]?.editedAt,
          record.posledniVkladZapis
        ),
      [propertiesActivity]
    )

    return (
      <Table
        rowKey="id"
        rowClassName={rowClassName}
        size="small"
        id={tableId}
        columns={columns}
        dataSource={data || []}
        pagination={false}
        bordered
        scroll={scrollX}
      />
    )
  }
