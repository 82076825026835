import { Button } from 'antd'
import React, { FunctionComponent } from 'react'

import { useAppSelector } from '../../../redux/hooks'
import { useCadastralWindow } from '../../utils/useCadastralWindow'

export interface CadastralUpdateButtonProps {
  entityType: 'property' | 'proceeding' | 'lv'
}

export const CadastralUpdateButton: FunctionComponent<CadastralUpdateButtonProps> =
  () => {
    const ruianData = useAppSelector(state => state.property.ruian.data)
    const cadastreWindow = useCadastralWindow(ruianData)

    return (
      <Button
        size="small"
        type="link"
        className="truncate"
        loading={cadastreWindow.isOpen}
        onClick={cadastreWindow.openWindow}
      >
        <div className="truncate">Aktualizovat údaje</div>
      </Button>
    )
  }
