import React, { FunctionComponent } from 'react'

export interface PropertyDetailTabContainerProps {
  children: React.ReactNode
  className?: string
  id?: string
}

export const PageLayoutTabContainer: FunctionComponent<PropertyDetailTabContainerProps> =
  props => (
    <div
      id={props.id}
      className={`flex flex-col justify-center flex-wrap bg-white p-4 pt-8 ${
        props.className || ''
      }`}
    >
      {props.children}
    </div>
  )
