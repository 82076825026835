import { MapRef } from 'react-map-gl'

export function addIcons(
  map: MapRef,
  size: number,
  icons: Array<{ name: string; path: string }>
) {
  for (const { name, path } of icons) {
    if (map.hasImage(name)) continue
    const img = new Image(size, size)
    img.crossOrigin = 'anonymous'
    img.onload = () => map.addImage(name, img, { sdf: true })
    img.onerror = console.error
    img.src = `https://api.iconify.design/${path}.svg`
  }
}
