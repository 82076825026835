import { Alert, Spin, Table } from 'antd'
import { ColumnsType } from 'antd/es/table/interface'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import {
  EpoRestrictionItem,
  RestrictionCheckboxData,
} from '../../../common/epoTypes'
import { Rizeni } from '../../../common/proceedingTypes'
import { numberSorter, stringSorter } from '../../utils/generalTableHelpers'
import { tablePagination } from '../../utils/layoutConst'
import { lvDetailPath } from '../../utils/paths'
import { parseProceedingString } from '../../utils/proceedingDetailHelpers'
import { restrictionColumn } from '../../utils/propertyMonitoringHelpers'
import { EpoRestrictionTableData } from '../../utils/useEpoRestrictionTableData'
import { CuzkEpoRestrictionCheckPanel } from '../CuzkEpoRestrictionCheckPanel/CuzkEpoRestrictionCheckPanel'
import { MapTooltip } from '../MapTooltip/MapTooltip'
import { ProceedingDetailModalLink } from '../ProceedingDetailModalLink/ProceedingDetailModalLink'
import { TableSummaryExport } from '../TableSummaryExport/TableSummaryExport'

export interface CuzkEpoRestrictionTableProps {
  epoId: string
  isHugeSubject: boolean
  checkboxData: RestrictionCheckboxData
  dataSource: EpoRestrictionTableData
  refetch: () => void
}

export const CuzkEpoRestrictionTable: FunctionComponent<CuzkEpoRestrictionTableProps> =
  props => {
    const tableId = 'epoRestriction-table'
    const dataSource = props.dataSource.data

    const columns = useMemo<ColumnsType<EpoRestrictionItem>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Kat. úřad',
          dataIndex: 'katastralniUradNazev',
          sorter: stringSorter('katastralniUradNazev'),
          render: katastralniUradNazev => (
            <MapTooltip query={katastralniUradNazev}>
              {katastralniUradNazev}
            </MapTooltip>
          ),
        },
        {
          title: 'Kat. pracoviště',
          dataIndex: 'katastralniPracovisteNazev',
          sorter: stringSorter('katastralniPracovisteNazev'),
          render: katastralniPracovisteNazev => (
            <MapTooltip query={katastralniPracovisteNazev}>
              {katastralniPracovisteNazev}
            </MapTooltip>
          ),
        },
        {
          title: 'Obec',
          dataIndex: 'obec',
          sorter: stringSorter('obec'),
        },
        {
          title: 'Kat. území',
          dataIndex: 'katastralniUzemiNazev',
          sorter: stringSorter('katastralniUzemiNazev'),
          render: (katastralniUzemiNazev, record) => (
            <MapTooltip query={record.obec + ' ' + katastralniUzemiNazev}>
              {katastralniUzemiNazev}
            </MapTooltip>
          ),
        },

        {
          title: 'LV',
          dataIndex: 'lv',
          render: (lv, record) =>
            lv && (
              <Link
                title="Přejít na detail LV v novém okně"
                target="_blank"
                to={lvDetailPath(record.katastralniUzemiKod, lv)}
              >
                {lv}
              </Link>
            ),
        },
        {
          title: 'Bud.',
          dataIndex: 'budovyPocet',
          className: 'w-10',
          showSorterTooltip: { title: 'Seřadit dle počtu budov na LV' },
          sorter: numberSorter('budovyPocet'),
          render: budovyPocet => (
            <span className={budovyPocet ? '' : 'text-gray-400'}>
              {budovyPocet}
            </span>
          ),
        },
        {
          title: 'Par.',
          dataIndex: 'parcelyPocet',
          className: 'w-10',
          showSorterTooltip: { title: 'Seřadit dle počtu parcel na LV' },
          sorter: numberSorter('parcelyPocet'),
          render: parcelyPocet => (
            <span className={parcelyPocet ? '' : 'text-gray-400'}>
              {parcelyPocet}
            </span>
          ),
        },
        {
          title: 'Jed.',
          dataIndex: 'jednotkyPocet',
          className: 'w-10',
          showSorterTooltip: { title: 'Seřadit dle počtu jednotek na LV' },
          sorter: numberSorter('jednotkyPocet'),
          render: jednotkyPocet => (
            <span className={jednotkyPocet ? '' : 'text-gray-400'}>
              {jednotkyPocet}
            </span>
          ),
        },
        /* {
          title: (
            <Tooltip
              trigger="click"
              className="cursor-help hover:opacity-70"
              title="Počet vlastníků"
            >
              Vlastníci
            </Tooltip>
          ),
          className: 'w-10 text-right',
          dataIndex: 'vlastniciPocet',
        }, */
        {
          title: 'Řízení',
          dataIndex: 'rizeni',
          render: rizeni =>
            rizeni?.map((item: string, index: number) => (
              <ProceedingDetailModalLink
                key={index}
                className="block"
                proceeding={parseProceedingString(item) as Rizeni}
              >
                {item.replace(/-\d+$/, '')}
              </ProceedingDetailModalLink>
            )),
        },
        restrictionColumn.exekuce,
        restrictionColumn.insolvence,
        restrictionColumn.zastavyNedobrovolne,
        restrictionColumn.predkupniPravo,
      ],
      []
    )

    const locale = useMemo(
      () => ({
        emptyText: !props.dataSource.loading && (
          <div className="my-8">
            <div className="mb-2 text-gray-400">
              Pro vyhledaný subjekt nebyly nalezeny v evidenci práv ČÚZK žádné
              záznamy.
            </div>
          </div>
        ),
      }),
      [props.dataSource.loading]
    )

    const summary = useCallback(
      () => (
        <TableSummaryExport
          total={dataSource?.positiveLiens?.length || 0}
          colSpan={9}
          colSpanAction={4}
          url="cuzk/epoLiensXlsx"
          fileName="prava-cizich-subjektu"
          dataSource={dataSource?.positiveLiens || []}
          icon
        />
      ),
      [dataSource]
    )

    if (props.dataSource.error)
      return (
        <Alert
          message="Chyba dat"
          description="Nepodařilo se načíst data z ČÚZK."
          type="error"
          className="my-8"
          showIcon
        />
      )

    if (props.dataSource.loading && !dataSource?.positiveLiens?.length)
      return (
        <div className="my-8 flex flex-col justify-center align-middle bg-white">
          <Spin spinning />
          <div className="mb-2 mt-2 text-center text-gray-400">
            Načítají se data z ČÚZK...
          </div>
        </div>
      )

    if (!dataSource?.positiveLiens?.length && !!dataSource?.lvTotalCount)
      return (
        <CuzkEpoRestrictionCheckPanel
          isHugeSubject={props.isHugeSubject}
          epoId={props.epoId}
          refetch={props.refetch}
          hasBeenChecked={!!dataSource?.hasBeenChecked}
          checkboxData={props.checkboxData}
        />
      )

    if (!dataSource || !dataSource?.positiveLiens) return null

    return (
      <Table
        id={tableId}
        rowKey="id"
        size="small"
        dataSource={[...dataSource.positiveLiens]}
        columns={columns}
        loading={props.dataSource.loading}
        locale={locale}
        summary={summary}
        pagination={tablePagination}
      />
    )
  }
