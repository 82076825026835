import { Modal } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import {
  ForclosureMonitoringFilterOnSubmit,
  PropertyUse,
} from '../../../common/foreclosureMonitoringTypes'
import { useToggleState } from '../../utils/useToggleState'
import { PropertyMonitoringFilterSelectionButton } from '../PropertyMonitoringFilterSelectionButton/PropertyMonitoringFilterSelectionButton'
import { PropertyUseSelection } from '../PropertyUseSelection/PropertyUseSelection'

export interface PropertyMonitoringFilterUnitUseModalButtonProps {
  checkboxData: PropertyUse[]
  selectedValues: number[] | null
  onSubmit: ForclosureMonitoringFilterOnSubmit
}

export const PropertyMonitoringFilterUnitUseModalButton: FunctionComponent<PropertyMonitoringFilterUnitUseModalButtonProps> =
  props => {
    const [open, toggleOpen] = useToggleState(false)

    const handleOnSubmit = useCallback(
      (selected: number[] | null) => {
        props.onSubmit({ zpusobyVyuzitiJednotek: selected })
      },
      [props]
    )

    const handleReset = useCallback(() => {
      handleOnSubmit(null)
    }, [handleOnSubmit])

    return (
      <div>
        <PropertyMonitoringFilterSelectionButton
          toggleOpen={toggleOpen}
          handleReset={handleReset}
          selectedCount={props.selectedValues?.length || 0}
          totalCount={props.checkboxData?.length || 0}
        />
        <Modal
          title="Výběr způsobu využití"
          open={open}
          onCancel={toggleOpen}
          footer={null}
          width={1000}
        >
          <PropertyUseSelection
            checkboxData={props.checkboxData}
            selectedValues={props.selectedValues}
            toggleModalVisibility={toggleOpen}
            onSubmit={handleOnSubmit}
          />
        </Modal>
      </div>
    )
  }
