import React, { FunctionComponent } from 'react'

import { Money } from '../Money/Money'

export interface EstimatePricesBoxProps {
  estimateResponse: {
    cena_m2_nab: number
    cena_nab: number
    cena_m2_real: number
    cena_real: number
  } | null
}

export const EstimatePricesBox: FunctionComponent<EstimatePricesBoxProps> =
  props => {
    if (!props.estimateResponse) return null
    return (
      <div className="print:block print:columns-2 gap-8">
        <div className="mb-2 flex flex-wrap">
          <div className="w-1/2">Cena nabídková m²:</div>
          <div className="w-1/2 font-semibold text-right">
            <Money amount={props.estimateResponse.cena_m2_nab} symbol="Kč" />
          </div>
          <div className="w-1/2">Cena nabídková:</div>
          <div className="w-1/2 font-semibold text-right">
            <Money amount={props.estimateResponse.cena_nab} symbol="Kč" />
          </div>
        </div>
        {props.estimateResponse.cena_m2_real ? (
          <div className="flex flex-wrap">
            <div className="w-1/2">Cena prodejní m²:</div>
            <div className="w-1/2 font-semibold text-right">
              <Money
                noData="---"
                amount={props.estimateResponse.cena_m2_real}
                symbol="Kč"
              />
            </div>
            <div className="w-1/2">Cena prodejní:</div>
            <div className="w-1/2 font-semibold text-right">
              <Money
                noData="---"
                amount={props.estimateResponse.cena_real}
                symbol="Kč"
              />
            </div>
          </div>
        ) : (
          <div className="w-full text-gray-400">
            *V tuto chvíli nemáme k dispozici dostatek dat pro odhad prodejní
            ceny.
          </div>
        )}
      </div>
    )
  }
