import { InfoCircleOutlined } from '@ant-design/icons'
import React, { FunctionComponent } from 'react'

import { deliveryMethodList } from '../../../common/postServiceTypes'
import { PostserviceLetterType } from '../../../graphql/generated'

export interface DeliveryMethodDescriptionProps {
  deliveryMethod?: PostserviceLetterType
}

const deliveryMethodDescriptions = {
  [PostserviceLetterType.OrdinaryLetter]:
    ' - česká pošta nestvrzuje dodání zásilky. Nelze sledovat průběh doručení. Doručení zpravidla nejpozději do třetího pracovního dne po dni podání.',
  [PostserviceLetterType.Registered]:
    ' - stvrzení podání a přiřazení podacího čísla, které umožňuje sledovat proces doručení. Dodání příjemci za podmínky, že převzetí zásilky stvrdí. Doručení zpravidla následující pracovní den po dni podání.',
  [PostserviceLetterType.OrdinaryLetterInternational]:
    ' - česká pošta nestvrzuje dodání zásilky. Nelze sledovat průběh doručení.',
  [PostserviceLetterType.RegisteredInternational]:
    ' - stvrzení podání a přiřazení podacího čísla, které umožňuje sledovat proces doručení. Zásilku dodá zahraniční pošta jen za podmínky, že příjemce převzetí potvrdí.',
}

export const DeliveryMethodDescription: FunctionComponent<DeliveryMethodDescriptionProps> =
  ({ deliveryMethod = PostserviceLetterType.OrdinaryLetter }) => {
    return (
      <div className="mt-4 h-12 text-gray-500">
        <InfoCircleOutlined className="mr-2" />
        <span className="font-bold">
          {deliveryMethodList[deliveryMethod].name}
        </span>
        {deliveryMethodDescriptions[deliveryMethod]}
      </div>
    )
  }
