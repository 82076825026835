import { Form, Select, Spin } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { AresAddress } from '../../../common/aresTypes'
import { postServiceFormHelp } from '../../utils/cuzkMonitorRcFormHelp'
import { searchAddressByName } from '../../utils/fetchAresAddress'

export interface PostServiceAddRecipientFormSearchAddressFragmentProps {
  showHelp?: boolean
}

const initialOptions: DefaultOptionType[] = [
  {
    label: 'Zadejte minimálně 4 znaky',
    value: 'initial',
    disabled: true,
  },
]

const loadingOption: DefaultOptionType = {
  label: (
    <div className="space-x-2 animate-pulse">
      <span className="gray-800">Vyhledávání </span>
      <Spin size="small" />
    </div>
  ),
  value: 'loading',
  disabled: true,
}

export const PostServiceAddRecipientFormSearchAddressFragment: FunctionComponent<PostServiceAddRecipientFormSearchAddressFragmentProps> =
  ({ showHelp }) => {
    const form = Form.useFormInstance()
    const [fetchedData, setFetchedData] = useState<undefined | AresAddress[]>()
    const [loading, setLoading] = useState(false)

    const options: DefaultOptionType[] = useMemo(() => {
      if (!fetchedData) return initialOptions
      const options = fetchedData?.map((data: AresAddress) => ({
        key: data.kodAdresnihoMista,
        label: data.textovaAdresa || 'Adresa není k dispozici',
        value: data.textovaAdresa || '-1',
        disabled: !data.textovaAdresa,
      }))

      if (loading) return [loadingOption, ...(options || [])]
      return options
    }, [fetchedData, loading])

    const handleSetFieldsValue = useCallback(
      (data: AresAddress) => {
        form.setFieldsValue({
          street: {
            streetName: data?.nazevUlice || data?.nazevCastiObce,
            houseNumber: data?.cisloDomovni?.toString(),
            orientationNumber: data?.cisloOrientacni?.toString(),
          },
          city: data?.nazevObce,
          zipCode: data?.psc?.toString(),
          addressId: data?.kodAdresnihoMista,
        })
        form.getFieldInstance('variable1')?.focus()
      },
      [form]
    )

    const handleSelect = useCallback(
      (data: AresAddress & { key: number }) => {
        const selected = fetchedData?.find(
          option => option.kodAdresnihoMista === data?.key
        )
        selected && handleSetFieldsValue(selected)
      },
      [fetchedData, handleSetFieldsValue]
    )

    const handleInputChange = useCallback(
      async (value: string) => {
        if (!value) return setFetchedData(undefined)
        if (value.length > 3) {
          setLoading(true)

          const searchResult = await searchAddressByName(value)
          setFetchedData(searchResult || [])
          setLoading(false)

          if (searchResult?.length === 1) {
            handleSetFieldsValue(searchResult[0])
          }
        }
      },
      [handleSetFieldsValue]
    )

    return (
      <Form.Item
        label={<span className="font-semibold">Vyhledat adresu</span>}
        help={(showHelp && postServiceFormHelp.addressSearch) || undefined}
      >
        <Select
          showSearch
          allowClear
          loading={loading}
          autoFocus
          placeholder="Začněte psát adresu"
          filterOption={false}
          onSearch={handleInputChange}
          onSelect={handleSelect}
          labelInValue
          options={options}
          className="w-full focus-within:[&>div]:!bg-primaryHover/10"
          defaultActiveFirstOption
        />
      </Form.Item>
    )
  }
