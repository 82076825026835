import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Form, message, Modal, Popconfirm } from 'antd'
import { ButtonProps } from 'antd/lib/button/button'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useCreateMarketplaceOfferMutation } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { validateTrigger } from '../../utils/layoutConst'
import { useConst } from '../../utils/useConst'
import { MarketplaceFormContactFragment } from '../MarketplaceFormContactFragment/MarketplaceFormContactFragment'
import {
  MARKETPLACE_OFFER_TYPES,
  MarketplaceFormDescriptionFragment,
} from '../MarketplaceFormDescriptionFragment/MarketplaceFormDescriptionFragment'
import { MarketplaceFormFinanceFragment } from '../MarketplaceFormFinanceFragment/MarketplaceFormFinanceFragment'
import { MarketplaceFormUploadFragment } from '../MarketplaceFormUploadFragment/MarketplaceFormUploadFragment'

export interface MarketplaceCreateFormValues {
  id: string
  type?: string
  title?: string
  location?: string
  description?: string
  interestAmount?: number
  monthlyPayment?: number
  propertyValue?: number
  investmentAmount?: number
  ltv?: string
  contactName?: string
  contactEmail?: string
  contactPhone?: string
  contactDescription?: string
  images?: string[]
}
export interface MarketplaceCreateButtonProps {
  initialValues?: MarketplaceCreateFormValues
  refetch?: (value: boolean) => void
  buttonProps?: ButtonProps
  buttonText?: string
}

export const MarketplaceCreateButton: FunctionComponent<MarketplaceCreateButtonProps> =
  props => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const userId = useAppSelector(state => state.myProfile.userId)
    const [form] = Form.useForm()
    const [, createOffer] = useCreateMarketplaceOfferMutation()

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [current, setCurrent] = useState(0)

    const toggleModalVisibility = useCallback(() => {
      setIsModalVisible(prev => !prev)
    }, [])

    const handleOnClick = useCallback(async () => {
      const validated = await form.validateFields().catch(() => false)
      setDisabled(!validated)
    }, [form])

    const next = useCallback(async () => {
      const validated = await form.validateFields().catch(() => false)
      if (!validated) return
      setCurrent(current + 1)
    }, [current, form])

    const prev = useCallback(() => {
      setCurrent(current - 1)
    }, [current])

    const steps = useConst([
      {
        title: 'Popis',
        content: <MarketplaceFormDescriptionFragment />,
      },
      {
        title: 'Fotografie',
        content: <MarketplaceFormUploadFragment />,
      },
      {
        title: 'Finance',
        content: <MarketplaceFormFinanceFragment />,
      },
      {
        title: 'Kontakty',
        content: <MarketplaceFormContactFragment />,
      },
    ])

    const handleOnConfirm = useCallback(async () => {
      const formValues = form.getFieldsValue(true)
      setLoading(true)
      const imagesNames = formValues.images.map(
        (image: { response: string }) => image.response
      )

      await createOffer({
        id: formValues.id,
        offerType: formValues.type,
        title: formValues.title,
        description: formValues.description,
        accountId: tenantId,
        accountUserId: userId,
        contactDescription: formValues.contactDescription,
        contactEmail: formValues.contactEmail,
        contactName: formValues.contactName,
        contactPhone: formValues.contactPhone,
        expirationDate: formValues.expirationDate.format('YYYY-MM-DD'),
        images: imagesNames,
        interestAmount: formValues.interestAmount,
        investmentAmount: formValues.investmentAmount,
        monthlyPayment: formValues.monthlyPayment,
        offerLocation: formValues.location,
        propertyValue: formValues.propertyValue,
      })

      setLoading(false)
      toggleModalVisibility()
      message.success('Nabídka úspěšně vystavena')
      props.refetch && props.refetch(true)
    }, [createOffer, form, props, tenantId, toggleModalVisibility, userId])

    const popconfrimProps = useMemo(() => {
      if (disabled)
        return {
          okButtonProps: {
            disabled: true,
          },
          title: (
            <span className="text-red-500">
              Prosím zadejte všechny povinné údaje.
            </span>
          ),
        }
      return
    }, [disabled])

    const footer = useMemo(
      () => (
        <div className="flex justify-between pt-8">
          <Button
            icon={<InfoCircleOutlined />}
            type="link"
            onClick={() => setShowHelp(!showHelp)}
          >
            {showHelp ? 'Skrýt nápovědu' : 'Zobrazit nápovědu'}
          </Button>
          <div>
            <Button onClick={toggleModalVisibility}>Zrušit</Button>
            {current > 0 && <Button onClick={prev}>Zpět</Button>}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={next}>
                Pokračovat
              </Button>
            )}
            {current === steps.length - 1 && (
              /*               <PricePopconfirm
                popconfrimProps={popconfrimProps}
                onConfirm={handleOnConfirm}
                itemPrice={pricelist.marketplaceContact}
                message={`Vystavení nabídky v marketplace je zpoplatněno ${pricelist.marketplaceContact} Kr.`}
              >
                <Button
                  loading={loading}
                  type="primary"
                  onClick={handleOnClick}
                >
                  Vystavit
                </Button>
              </PricePopconfirm> */
              <Popconfirm
                {...popconfrimProps}
                title="Opravdu chcete vystavit nabídku s uvedenými údaji?"
                onConfirm={handleOnConfirm}
                okText="Ano"
              >
                <Button
                  loading={loading}
                  type="primary"
                  onClick={handleOnClick}
                >
                  Vystavit
                </Button>
              </Popconfirm>
            )}
          </div>
        </div>
      ),
      [
        current,
        handleOnClick,
        handleOnConfirm,
        loading,
        next,
        popconfrimProps,
        prev,
        showHelp,
        steps.length,
        toggleModalVisibility,
      ]
    )
    const initialValues = useConst({
      id: uuidv4(),
      type: MARKETPLACE_OFFER_TYPES.zapujcka,
    })

    return (
      <div>
        <Button
          type="primary"
          onClick={toggleModalVisibility}
          {...props.buttonProps}
          className="mt-2"
        >
          Vystavit novou nabídku
        </Button>
        <Modal
          title="Vystavit novou nabídku v marketplace"
          open={isModalVisible}
          onCancel={toggleModalVisibility}
          footer={footer}
        >
          <Form<MarketplaceCreateFormValues>
            layout="vertical"
            className="!mt-8"
            preserve
            initialValues={props.initialValues || initialValues}
            form={form}
            validateTrigger={validateTrigger}
          >
            <div>{steps[current].content}</div>
          </Form>
        </Modal>
      </div>
    )
  }
