import { Button, Tooltip } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { CeeTableItem } from '../../../common/ceeTypes'
import { pricelist } from '../../utils/pricelist'
import { useDeclension } from '../../utils/useDeclension'
import { PricePopconfirm } from '../PricePopconfirm/PricePopconfirm'

export interface CeeMonitorBulkCheckProps {
  selectedRows: CeeTableItem[]
  checkNow: (id: string[]) => void
}

export const CeeMonitorBulkCheck: FunctionComponent<CeeMonitorBulkCheckProps> =
  props => {
    const decline = useDeclension()

    const notFoundItemsId = useMemo(
      () =>
        props.selectedRows
          .filter(item => !item.found && !item.errorState)
          .map(item => item.id),
      [props.selectedRows]
    )

    const message = useMemo(() => {
      const foundItemsCount = props.selectedRows.length - notFoundItemsId.length
      return (
        <div>
          <div>
            Vyhledání subjektu v centrální evidenci exekucí je zpoplatněno{' '}
            {pricelist.CEE} Kr.
          </div>
          {!!foundItemsCount && (
            <div className="mt-2">
              {foundItemsCount} z vybraných záznamů již{' '}
              {decline(foundItemsCount, 'beenFound')} v CEE a{' '}
              {decline(foundItemsCount, 'willNotCheck')} znova.
            </div>
          )}
        </div>
      )
    }, [decline, notFoundItemsId.length, props.selectedRows.length])

    const handleCheck = useCallback(
      () => props.checkNow(notFoundItemsId),
      [notFoundItemsId, props]
    )

    if (!notFoundItemsId.length)
      return (
        <Tooltip title="Všechny vybrané záznamy již byly v CEE nalezeny.">
          <Button disabled type="primary">
            Zkontrolovat vybrané záznamy
          </Button>
        </Tooltip>
      )

    return (
      <PricePopconfirm
        itemCount={notFoundItemsId.length}
        onConfirm={handleCheck}
        itemPrice={pricelist.CEE}
        message={message}
      >
        <Button type="primary">Zkontrolovat vybrané záznamy</Button>
      </PricePopconfirm>
    )
  }
