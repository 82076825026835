import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import React, { FunctionComponent } from 'react'

import openSansBold from '../../../common/fonts/OpenSans-Bold.ttf'
import openSansItalic from '../../../common/fonts/OpenSans-Italic.ttf'
import openSans from '../../../common/fonts/OpenSans-Regular.ttf'
import { pdfStyles } from '../../../common/pdfHelpers'
import { day, second } from '../../utils/dateFormat'
import { CeeDetailPdfItem } from '../CeeDetailPdfItem/CeeDetailPdfItem'
import { CeeMonitorItem } from '../CeeMonitorTableExpandedRow/CeeMonitorTableExpandedRow'

export interface CeeSummaryPdfProps {
  data?: Partial<CeeMonitorItem>[]
}

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: openSans },
    { src: openSansBold, fontStyle: 'bold' },
    { src: openSansItalic, fontStyle: 'italic' },
  ],
})

const styles = StyleSheet.create({
  page: pdfStyles.page,
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  header: {
    fontSize: 8,
    marginBottom: 10,
    textAlign: 'center',
    color: 'grey',
    flexWrap: 'nowrap',
  },
  title: {
    fontSize: 16,
    fontStyle: 'bold',
    marginBottom: 10,
    padding: 10,
    textAlign: 'center',
    backgroundColor: '#53afb0',
    color: 'white',
  },
  sectionTitle: {
    fontSize: 12,
    textAlign: 'center',
    fontStyle: 'bold',
    marginTop: 15,
  },
  datumKauzy: {
    width: '13%',
    padding: 5,
  },
  povinnaOsoba: {
    width: '20%',
    padding: 5,
  },
  spznColumn: {
    width: '18%',
    padding: 5,
  },
  addressColumn: {
    padding: 5,
  },
  tableHeader: {
    backgroundColor: '#eee',
    fontStyle: 'bold',
    marginTop: 30,
    borderBottom: 1,
    borderBottomColor: '#ddd',
    fontSize: 10,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    width: '100%',
    columnGap: 5,
  },
  notFound: {
    border: 1,
    fontStyle: 'bold',
    marginTop: 30,
    fontSize: 12,
    padding: 10,
    textAlign: 'center',
    marginBottom: 10,
    width: '100%',
  },
  createDate: {
    fontSize: 10,
    color: 'gray',
    textAlign: 'center',
    marginBottom: 20,
  },
})

export const CeeSummaryPdf: FunctionComponent<CeeSummaryPdfProps> = props => {
  if (!props.data) return <Document />
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.header} fixed>
          Vytvořeno v REGESTA DATA 2.0
        </Text>
        <Text style={styles.title}>Centrální evidence exekucí</Text>
        <Text style={styles.sectionTitle}>VYHLEDÁVÁNÍ EXEKUČNÍCH TITULŮ</Text>
        <Text style={styles.createDate}>
          Výpis ke dni{' '}
          {props.data[0]?.lastCheck
            ? second(props.data[0].lastCheck)
            : second(props.data[0].createdAt)}
        </Text>
        {props.data[0].ico ? (
          <CeeDetailPdfItem title="Výsledek vyhledávání právnické osoby">
            {props.data?.[0].fullname || 'podle IČ'}
          </CeeDetailPdfItem>
        ) : (
          <CeeDetailPdfItem title="Výsledek vyhledávání fyzické osoby">
            {props.data?.[0].fullname ||
              (props.data[0].rc ? 'podle RČ' : 'podle jména a data narození')}
          </CeeDetailPdfItem>
        )}
        <CeeDetailPdfItem conditional title="IČ">
          {props.data[0].ico && props.data[0].ico}
        </CeeDetailPdfItem>
        <CeeDetailPdfItem conditional title="Jméno a datum narození">
          {props.data[0].birthDate && props.data[0].fullname && (
            <Text>
              {props.data[0].fullname} ({day(props.data[0].birthDate)})
            </Text>
          )}
        </CeeDetailPdfItem>
        <CeeDetailPdfItem conditional title="Rodné číslo">
          {props.data[0].rc &&
            props.data[0].rc.length > 6 &&
            props.data[0].rc.slice(0, 6) + '/' + props.data[0].rc.slice(6)}
        </CeeDetailPdfItem>
        <CeeDetailPdfItem conditional title="Datum narození">
          {day(props.data[0].birthDate)}
        </CeeDetailPdfItem>
        <CeeDetailPdfItem title="Počet nalezených exekučních titulů">
          {props.data[0].spzn ? props.data.length : 0}
        </CeeDetailPdfItem>
        {props.data[0].spzn ? (
          <View style={styles.tableHeader}>
            <Text style={styles.datumKauzy}>Dat. kauzy</Text>
            <Text style={styles.spznColumn}>Spisová značka</Text>
            <Text style={styles.povinnaOsoba}>Povinná osoba</Text>
            <Text style={styles.addressColumn}>Adresa</Text>
          </View>
        ) : (
          <View style={styles.notFound}>
            <Text>Subjekt nebyl nalezen v centrální evidenci exekucí.</Text>
          </View>
        )}
        {props.data.map((item, index) => (
          <View
            key={index}
            style={{
              fontSize: 10,
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              columnGap: 5,
              backgroundColor: index % 2 ? '#f0f0f0' : 'white',
            }}
          >
            <Text style={styles.datumKauzy}>{day(item.executionDate)}</Text>
            <Text style={styles.spznColumn}>{item.spzn}</Text>
            <Text style={styles.povinnaOsoba}>{item.fullname}</Text>
            <Text style={styles.addressColumn}>{item.address}</Text>
          </View>
        ))}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  )
}
