import React, { FunctionComponent } from 'react'

import { CuzkLvXmlType } from '../../../common/cuzkLvTypes'
import { LvPdfStyle } from '../../../common/pdfHelpers'
import { CuzkLvHtmlBody } from '../CuzkLvHtmlBody/CuzkLvHtmlBody'
export interface CuzkLvHtmlLayoutProps {
  data?: CuzkLvXmlType
  active?: boolean
  isPdf?: boolean
}

export const CuzkLvHtmlLayout: FunctionComponent<CuzkLvHtmlLayoutProps> =
  props => {
    const lvData = props.data?.VypisZKatastruNemovitosti

    if (!lvData) {
      return null
    }
    if (props.active)
      return (
        <>
          <style>
            {LvPdfStyle +
              '.lvBody{font-size:13px;line-height:1.5em;font-family:courier,courier new,serif}'}
          </style>
          <CuzkLvHtmlBody data={lvData} active isPdf={props.isPdf} />
        </>
      )

    return (
      <html lang="cs">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>Výpis z katastru nemovitostí - list vlastnictví</title>
          <style>{LvPdfStyle}</style>
        </head>
        <CuzkLvHtmlBody
          data={lvData}
          active={props.active}
          isPdf={props.isPdf}
        />
      </html>
    )
  }
