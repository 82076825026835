export const enum ARES_SERVICE {
  default = '',
  rs = 'rs',
  vr = 'vr',
  rzp = 'rzp',
  res = 'res',
  ceu = 'ceu',
  szr = 'szr',
  nrpzs = 'nrpzs',
  rcns = 'rcns',
  rpsh = 'rpsh',
  notifikace = 'notifikace',
}
export interface AresEkonomickySubjekt {
  ico?: string
  obchodniJmeno?: string
  sidlo?: AresAddress
  datumVzniku?: string
  datumZaniku?: string
  datumAktualizace?: string
  dic?: string
}

export interface AresByFilterResponse {
  pocetCelkem: number
  ekonomickeSubjekty: AresEkonomickySubjekt[]
}

export interface AresFilterInput {
  start?: number
  pocet?: number
  razeni?: string[]
  ico?: string[] // 8 digits
  obchodniJmeno?: string
  sidlo?: AresAddress
  pravniForma?: string[] // 3 digits
  financniUrad?: string[] // 3 digits
  czNace?: string[]
}

export interface AresAddress {
  kodStatu?: string // 3 chars
  kodKraje?: number // max 3 digits
  kodOkresu?: number // max 4 digits
  kodObce?: number // max 6 digits
  kodSpravnihoObvodu?: number // max 3 digits
  kodMestskehoObvodu?: number // max 3 digits
  kodMestskeCastiObvodu?: number // max 6 digits
  kodCastiObce?: number // max 6 digits
  kodUlice?: number // max 7 digits
  kodAdresnihoMista?: number // max 9 digits
  nazevStatu?: string
  nazevKraje?: string
  nazevOkresu?: string
  nazevObce?: string
  nazevSpravnihoObvodu?: string
  nazevMestskehoObvodu?: string
  nazevMestskeCastiObvodu?: string
  nazevUlice?: string
  cisloDomovni?: number // max 4 digits
  doplnekAdresy?: string
  cisloOrientacni?: number // max 3 digits
  cisloOrientacniPismeno?: string // 1 char
  nazevCastiObce?: string
  psc?: number | string
  textovaAdresa?: string
  cisloDoAdresy?: string
  typCisloDomovni?: string
  standardizaceAdresy?: true
  pscTxt?: string
}
