import React, { FunctionComponent, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { DrmJednotkaResponse, PROPERTY_TYPES } from '../../../common/drmTypes'
import { RuianUnitResponse } from '../../../common/ruianTypes'
import { useAppSelector } from '../../../redux/hooks'
import { propertyDetailPath } from '../../utils/paths'
import { copyAddressButton } from '../../utils/propertyDetailHelper'
import { CopyButton } from '../CopyButton/CopyButton'
import { Money } from '../Money/Money'
import { PriceEstimateLayoutLvDataRow as Item } from '../PriceEstimateLayoutLvDataRow/PriceEstimateLayoutLvDataRow'
import { PropertyDetailSidebarRowCopyableItem as CopyItem } from '../PropertyDetailSidebarRowCopyableItem/PropertyDetailSidebarRowCopyableItem'

export const PropertyDetailSidebarRuianUnit: FunctionComponent = () => {
  const ruian = useAppSelector(
    state => state.property.ruian.data as RuianUnitResponse
  )
  const drmDetail = useAppSelector(
    state => state.property.drm.data as DrmJednotkaResponse
  )

  const unitPart = useMemo(() => {
    if (!drmDetail?.podilJednotkyNaSpolecnychCastech) return 0
    const parts = drmDetail?.podilJednotkyNaSpolecnychCastech
      ?.replace(/\s+/g, '')
      .split('/')
    return (parseFloat(parts[0]) / parseFloat(parts[1] || '1')) * 100
  }, [drmDetail?.podilJednotkyNaSpolecnychCastech])

  return (
    <>
      <Item title="Jednotka">{ruian?.nazev}</Item>

      <Item condition={!!ruian?.vBudove?.cislaDomovni} title="V budově">
        <Link
          title="Přejít na detail budovy"
          to={propertyDetailPath(PROPERTY_TYPES.BUILDING, ruian?.vBudove?.id)}
        >
          {ruian?.vBudove?.cislaDomovni}
        </Link>
      </Item>

      <Item title="Městská část">{ruian?.vBudove?.mestskaCast?.nazev}</Item>

      <CopyItem title="GPS souřadnice">
        {`${ruian?.definicniBod?.lat.toFixed(
          5
        )}, ${ruian?.definicniBod?.long.toFixed(5)}`}
      </CopyItem>

      <Item
        title={ruian?.adresniMista?.length === 1 ? 'Adresa' : 'Adresy v budově'}
        condition={!!ruian?.adresniMista?.length}
      >
        {ruian?.adresniMista?.map((adresa, index) => (
          <div key={index} className="flex justify-between group">
            {adresa.formatovanaAdresa}
            <CopyButton
              copyText={adresa.formatovanaAdresa}
              {...copyAddressButton}
            />
          </div>
        ))}
      </Item>

      <Item condition={!!unitPart} title="Podíl jednotky na spol. částech">
        <Money
          symbol="%"
          decimalNumbers={unitPart < 0.01 ? 3 : 2}
          amount={unitPart}
        />
      </Item>

      <Item title="Druh konstrukce">{ruian?.druhKonstrukce?.nazev}</Item>
      <Item title="Způsob vytápění">{ruian?.zpusobVytapeni?.nazev}</Item>
    </>
  )
}
