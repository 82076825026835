import Button from 'antd/es/button'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { CeeMonitorTable } from '../components/CeeMonitorTable/CeeMonitorTable'
import { PageLayout } from '../components/PageLayout/PageLayout'
import { ceeMonitorCreatePath } from '../utils/paths'

const monitoringCeeFilter = {
  monitoring: { equalTo: false },
}
const newSearch = (
  <Link to={ceeMonitorCreatePath()}>
    <Button type="primary">Vyhledat subjekt</Button>
  </Link>
)

export const CeeCheck: FunctionComponent = () => {
  return (
    <PageLayout
      title="Vyhledávání v Centrální evidenci exekucí"
      extra={newSearch}
    >
      <CeeMonitorTable monitoringCeeFilter={monitoringCeeFilter} />
    </PageLayout>
  )
}

export default CeeCheck
