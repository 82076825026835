import { Table } from 'antd'
import React, { FunctionComponent } from 'react'

import { useDeclension } from '../../utils/useDeclension'

export interface PropertyDetailSubjectsTableSummaryProps {
  allSubjectsCount: number
  currentSubjectsCount: number
}

export const PropertyDetailSubjectsTableSummary: FunctionComponent<PropertyDetailSubjectsTableSummaryProps> =
  props => {
    const decline = useDeclension()

    return props.allSubjectsCount ? (
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={2} index={0} className="!border-b-0" />
        <Table.Summary.Cell colSpan={10} index={1} className="!border-b-0">
          <div className="text-gray-400">
            <span>
              Celkem: {decline(props.allSubjectsCount, 'record', true)}
            </span>
            {props.allSubjectsCount !== props.currentSubjectsCount && (
              <>
                <span className="text-gray-300 mx-3">|</span>
                <span>Aktuální: {props.currentSubjectsCount}</span>
                <span className="text-gray-300 mx-3">|</span>
                <span>
                  Archivní:{' '}
                  {props.allSubjectsCount - props.currentSubjectsCount}
                </span>
              </>
            )}
          </div>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    ) : null
  }
