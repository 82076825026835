import { DownOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

export interface RegularPaymentCollapseProps {
  text?: string | null
}

export const RegularPaymentCollapse: FunctionComponent<RegularPaymentCollapseProps> =
  props => {
    const items = useMemo(() => {
      if (!props.text) return []
      if (props.text.includes('\n')) return props.text.split('\n')

      const parseSemicolon = props.text.split('; ')
      const parseSpaceComa = props.text.split(', ')
      return parseSemicolon.length > 2
        ? parseSemicolon
        : parseSpaceComa.length > 2
        ? parseSpaceComa
        : [props.text]
    }, [props.text])

    const [isCollapsed, setCollapsed] = useState(items.length > 1)

    const handleCollapse = useCallback(() => {
      setCollapsed(!isCollapsed)
    }, [isCollapsed])

    const ButtonContent = useMemo(
      () => (
        <>
          <DownOutlined
            style={{ fontSize: 10 }}
            className={`${
              isCollapsed ? 'group-hover:rotate-0' : 'group-hover:-rotate-180'
            } -rotate-90 transition-all mr-1`}
          />
          {isCollapsed ? 'Rozbalit' : 'Skrýt'}
        </>
      ),
      [isCollapsed]
    )

    return (
      <div className="max-w-sm">
        <div>
          {items[0]}
          {isCollapsed && items.length > 1 && (
            <>
              <span className="pr-2">...</span>
              <Button
                className="group !p-0"
                size="small"
                onClick={handleCollapse}
                type="link"
              >
                {ButtonContent}
              </Button>
            </>
          )}
        </div>
        <div
          className={`${
            isCollapsed
              ? 'max-h-0 opacity-0 overflow-hidden'
              : 'max-h-[3000px] opacity-100'
          } transition-all`}
        >
          {items.map((item: string, index: number) => {
            if (index === 0) return
            return <div key={index}>{item}</div>
          })}
        </div>
        {!isCollapsed && (
          <Button
            className="group !p-0 mt-1"
            size="small"
            onClick={handleCollapse}
            type="link"
          >
            {ButtonContent}
          </Button>
        )}
      </div>
    )
  }
