import { useCallback, useMemo, useState } from 'react'

import {
  HeritageMonitoringFilterOnSubmit,
  HeritageMonitoringFilterState,
} from '../../common/heritageMonitoringTypes'

export const useHeritageMonitoringFilter = () => {
  const [filter, setFilter] = useState<HeritageMonitoringFilterState>({
    pracoviste: null,
    stranka: 1,
  })

  const filterQuery = useMemo(() => {
    const stranka = `&stranka=${filter.stranka}`

    const pracoviste = filter.pracoviste
      ? `&katastralniPracoviste=${filter.pracoviste}`
      : ''

    const filterQuery = `${pracoviste}${stranka}`

    return filterQuery
  }, [filter.stranka, filter.pracoviste])

  const handleChange = useCallback<HeritageMonitoringFilterOnSubmit>(
    updates => {
      setFilter(prevFilter => ({ ...prevFilter, stranka: 1, ...updates }))
    },
    []
  )

  return {
    filter,
    filterQuery,
    handleChange,
  }
}
