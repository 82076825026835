interface PersonType {
  firstName: string
  lastName: string
  middleName?: string
  lastNameAtBirth?: string
}

interface NameType {
  person?: PersonType
  tradeName?: string
}

interface AddressType {
  code?: number
  city?: string
  district?: string
  street?: string
  cp?: string
  co?: string
  ce?: string
  zip?: string
  region?: string
  addressPoint?: number
  state?: string
  fullAddress?: string
}

interface HierarchyType {
  isMaster: string
  masterId: string
}

export interface DataBox {
  id?: string
  type?: string
  subtype?: number
  name: NameType
  ico?: string
  address: AddressType
  pdz?: string
  ovm?: string
  hierarchy: HierarchyType
  idOVM?: string
}

export interface DataBoxList {
  list: {
    box?: DataBox[]
    $: {
      'source-system': string
    }
  }
}

export enum DataBoxSubjectType {
  OVM = 'Orgán veřejné moci',
  OVM_MAIN = 'Orgán veřejné moci - Hlavní schránka',
  OVM_REQ = 'Orgán veřejné moci - Ostatní',
  OVM_FO = 'Orgán veřejné moci - Fyzická osoba',
  OVM_PFO = 'Orgán veřejné moci - Podnikající fyzická osoba',
  OVM_PO = 'Orgán veřejné moci - Právnická osoba',
  PO = 'Právnická osoba',
  PO_REQ = 'Právnická osoba - na žádost',
  PFO = 'Podnikající fyzická osoba',
  PFO_ADVOK = 'Advokát',
  PFO_DANPOR = 'Daňový poradce',
  PFO_AUDITOR = 'Auditor',
  PFO_ZNALEC = 'Znalec',
  PFO_TLUMOCNIK = 'Tlumočník',
  FO = 'Fyzická osoba',
  PFO_REQ = 'Podnikající fyzická osoba - na žádost',
}
