import React, { FunctionComponent, useMemo } from 'react'

import { LvGeometryApiResponse, Nemovitosti } from '../../../common/lvTypes'
import { PropertyDetailAreaChartModalButton } from '../PropertyDetailAreaChartModalButton/PropertyDetailAreaChartModalButton'
import { PropertyDetailLvAreaTable } from '../PropertyDetailLvAreaTable/PropertyDetailLvAreaTable'
import { PropertyDetailLvBuildingTable } from '../PropertyDetailLvBuildingTable/PropertyDetailLvBuildingTable'
import { PropertyDetailLvUnitTable } from '../PropertyDetailLvUnitTable/PropertyDetailLvUnitTable'
import { PropertyDetailOwnershipPropertiesTitle as RowTitle } from '../PropertyDetailOwnershipPropertiesTitle/PropertyDetailOwnershipPropertiesTitle'

export interface LvDetailOwnershipPropertiesProps {
  properties: Nemovitosti
  propertiesGeometry: LvGeometryApiResponse
}

export const LvDetailOwnershipProperties: FunctionComponent<LvDetailOwnershipPropertiesProps> =
  props => {
    const parcely = useMemo(() => {
      const lvParcelyMap = new Map(
        props.propertiesGeometry?.parcely?.map(parcela => [parcela.id, parcela])
      )

      return props.properties?.parcely?.map(parcela => {
        const lvParcela = lvParcelyMap.get(parcela.id)
        return {
          ...parcela,
          vymera: lvParcela?.vymera ?? parcela.vymera,
        }
      })
    }, [props.properties?.parcely, props.propertiesGeometry?.parcely])

    const budovy = useMemo(() => {
      const lvBudovyMap = new Map(
        props.propertiesGeometry?.budovy?.map(budova => [budova.id, budova])
      )

      return props.properties?.budovy?.map(budova => {
        const lvBudova = lvBudovyMap.get(budova.id)
        return {
          ...budova,
          zastavenaPlocha: lvBudova?.zastavenaPlocha ?? budova.zastavenaPlocha,
        }
      })
    }, [props.propertiesGeometry?.budovy, props.properties?.budovy])

    return (
      <div>
        <RowTitle rootClassName="flex items-baseline space-x-8">
          {parcely.length > 1 && (
            <PropertyDetailAreaChartModalButton
              parcely={parcely}
              geometryIncluded
            />
          )}
        </RowTitle>

        <div className="w-full space-y-4">
          {!!props.properties.jednotky.length && (
            <PropertyDetailLvUnitTable
              propertyId={''}
              jednotky={props.properties.jednotky}
            />
          )}
          {!!budovy.length && (
            <PropertyDetailLvBuildingTable
              propertyId={''}
              budovy={budovy}
              parcely={parcely}
            />
          )}
          {!!parcely.length && (
            <PropertyDetailLvAreaTable
              propertyId={''}
              parcely={parcely}
              budovy={budovy}
            />
          )}
        </div>
      </div>
    )
  }
