import { Button, Form, Input, message, Modal } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useMutation } from 'urql'

import { formItemLayout } from '../../utils/layoutConst'
import { CheckFrequencySelectInput } from '../CheckFrequencySelectInput/CheckFrequencySelectInput'
import { RcTableItem } from '../CuzkMonitorRcTable/CuzkMonitorRcTable'

export interface RcEditTableItemProps {
  item: RcTableItem
  onSave: (item?: RcTableItem) => void
}

export const CuzkMonitorRcTableItemEdit: FunctionComponent<RcEditTableItemProps> =
  props => {
    const [form] = Form.useForm()
    const [open, setOpen] = useState(false)

    const initialValues = useMemo(
      () => ({
        note: props.item.description,
        ico: props.item.ico,
        firstname: props.item.firstname,
        lastname: props.item.lastname,
        birthDate: props.item.birthDate,
        rc: props.item.rc,
        checkFrequency: props.item.checkFrequency,
      }),
      [
        props.item.description,
        props.item.ico,
        props.item.firstname,
        props.item.lastname,
        props.item.birthDate,
        props.item.rc,
        props.item.checkFrequency,
      ]
    )
    const updateMonitoredSubjectQuery = `
	mutation(
		$description: String,
		$checkFrequency: Int,
		$id: UUID!) {
		  updateMonitoringCuzkRcById(
				input: {monitoringCuzkRcPatch: {description: $description, checkFrequency: $checkFrequency}, id: $id}
			){clientMutationId}
		}
`

    const [, updateMonitoredSubject] = useMutation(updateMonitoredSubjectQuery)

    const showModal = useCallback(() => {
      setOpen(true)
    }, [])

    const hideModal = useCallback(() => {
      setOpen(false)
    }, [])

    const onFinish = useCallback(
      (values: {
        note: string
        rc: string
        checkFrequency: number
        label: string[] | []
      }) => {
        updateMonitoredSubject({
          id: props.item.id,
          description: values.note,
          checkFrequency: values.checkFrequency,
        }).then(() => {
          setOpen(false)
          message.success('Záznam byl uložen.')
          props.onSave()
        })
      },
      [updateMonitoredSubject, props]
    )

    return (
      <>
        <Button className="w-36" type="link" onClick={showModal}>
          Editovat
        </Button>
        {/* 'open' is here for better performance*/}
        {open && (
          <Modal
            title="Editace záznamu"
            open={open}
            onCancel={hideModal}
            onOk={form.submit}
            okText="Uložit"
            cancelText="Zrušit"
          >
            <div className="mt-4">
              <Form
                {...formItemLayout}
                form={form}
                initialValues={initialValues}
                onFinish={onFinish}
              >
                {props.item.rc && (
                  <Form.Item name="rc" label="Rodné číslo">
                    <Input disabled placeholder="Zadejte rodné číslo" />
                  </Form.Item>
                )}
                {props.item.firstname && (
                  <Form.Item name="firstname" label="Křestní jméno">
                    <Input disabled placeholder="Křestní jméno" />
                  </Form.Item>
                )}
                {props.item.lastname && (
                  <Form.Item name="lastname" label="Příjmení">
                    <Input disabled placeholder="Příjmení" />
                  </Form.Item>
                )}
                {props.item.birthDate && (
                  <Form.Item name="birthDate" label="Datum narození">
                    <Input disabled placeholder="Datum narození" />
                  </Form.Item>
                )}
                {props.item.ico && (
                  <Form.Item name="ico" label="IČ">
                    <Input disabled placeholder="IČ" />
                  </Form.Item>
                )}

                <Form.Item name="note" label="Poznámka">
                  <Input.TextArea placeholder="Napište poznámku k danému RČ" />
                </Form.Item>

                <Form.Item name="checkFrequency" label="Frekvence kontroly">
                  <CheckFrequencySelectInput
                    onChange={console.log}
                    initialValue={props.item.checkFrequency}
                    canPause
                  />
                </Form.Item>
              </Form>
            </div>
          </Modal>
        )}
      </>
    )
  }
