import { Button, Tabs } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { ColorPalleteKey } from '../../utils/collorPallete'
import { IconPalleteKey } from '../../utils/iconPallete'
import { ColorPalette } from '../ColorPalette/ColorPalette'
import { IconPallete } from '../IconPallete/IconPallete'
import { Label } from '../Label/Label'

export interface LabelSettingButtonPopoverProps {
  handleClose: () => void
  onStyleChange: (style: {
    colorKey?: ColorPalleteKey
    iconKey?: IconPalleteKey
  }) => void
  initialColor: ColorPalleteKey
  initialIcon: IconPalleteKey
  labelName?: string
  labelDescription?: string
}

export const LabelSettingButtonPopover: FunctionComponent<LabelSettingButtonPopoverProps> =
  props => {
    const [pickedIcon, setPickedIcon] = useState<IconPalleteKey>(
      props.initialIcon
    )
    const [pickedColor, setPickedColor] = useState<ColorPalleteKey>(
      props.initialColor
    )

    const saveLabelStyle = useCallback(() => {
      props.onStyleChange({ colorKey: pickedColor, iconKey: pickedIcon })
      props.handleClose()
    }, [pickedColor, pickedIcon, props])

    const popOverTitle = useMemo(
      () => (
        <>
          <div className="flex justify-between ">
            Nastavení štítku
            <Label
              name={props.labelName}
              description={props.labelDescription}
              colorKey={pickedColor}
              iconKey={pickedIcon}
            />
          </div>
          <hr className="my-3" />
        </>
      ),
      [pickedColor, pickedIcon, props.labelDescription, props.labelName]
    )

    const items = useMemo(
      () => [
        {
          label: 'Barva',
          key: 'color',
          children: (
            <ColorPalette
              pickColor={setPickedColor}
              pickedColor={pickedColor}
            />
          ),
        },
        {
          label: 'Ikona',
          key: 'icon',
          children: (
            <IconPallete
              pickedColor={pickedColor}
              pickIcon={setPickedIcon}
              pickedIcon={pickedIcon}
            />
          ),
        },
        {
          label: 'Oprávnění',
          key: 'permission',
          children: 'Oprávnění v přípravě',
          disabled: true,
        },
      ],
      [pickedColor, pickedIcon]
    )

    return (
      <div className="w-64">
        {popOverTitle}
        <Tabs defaultActiveKey="color" items={items} />
        <hr className="my-3" />
        <div className="justify-end flex space-x-2">
          <Button onClick={props.handleClose}>Zrušit</Button>
          <Button
            disabled={!pickedColor}
            type="primary"
            onClick={saveLabelStyle}
          >
            Potvrdit
          </Button>
        </div>
      </div>
    )
  }
