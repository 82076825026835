import { Form, Select, Spin } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { usePostServiceRecipientListTableQuery } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { Label } from '../Label/Label'

export interface PostServiceRecipientListSelectProps {
  handleChange?: (value: string) => void
  initialId?: string
  isFormFragment?: boolean
  showEmpty?: boolean
}

export const PostServiceRecipientListSelect: FunctionComponent<PostServiceRecipientListSelectProps> =
  props => {
    const { Option } = Select
    const form = Form.useFormInstance()
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const [queryResult] = usePostServiceRecipientListTableQuery({
      variables: { accountId: tenantId },
    })

    const option = useMemo(() => {
      const data = props.showEmpty
        ? queryResult.data?.allPostserviceLists?.nodes
        : queryResult.data?.allPostserviceLists?.nodes.filter(
            item => !!item?.recipients?.totalCount
          )
      const fieldRef = form?.getFieldInstance('recipientListId')
      fieldRef?.focus()

      return data?.map(item => (
        <Option
          key={item?.id}
          value={item?.id}
          label={item?.listName + ' (' + item?.recipients.totalCount + ' adr.)'}
        >
          <div className="whitespace-normal">
            <div className="w-full flex justify-between">
              <span>{item?.listName}</span>
              <span className="text-gray-400">{` ${item?.recipients.totalCount} adr. `}</span>
            </div>

            {item?.description && (
              <span
                title={
                  item.description.length > 80 ? item.description : undefined
                }
                className="text-gray-400 inline-block mr-2"
              >
                {item.description.trim().slice(0, 80)}
                {item.description.length > 80 && '...'}
              </span>
            )}
            {!!item?.labels?.nodes.length && (
              <div className="scale-75 transform origin-left inline-block">
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                {item?.labels?.nodes.map((label: any) => (
                  <Label
                    key={label?.labelByLabelId?.id || ''}
                    colorKey={label?.labelByLabelId?.colorKey || ''}
                    iconKey={label?.labelByLabelId?.iconKey || ''}
                    name={label?.labelByLabelId?.name || ''}
                    description={label?.labelByLabelId?.description || ''}
                  />
                ))}
              </div>
            )}
          </div>
        </Option>
      ))
    }, [
      Option,
      form,
      props.showEmpty,
      queryResult.data?.allPostserviceLists?.nodes,
    ])

    if (props.isFormFragment)
      return (
        <Form.Item
          name="recipientListId"
          label="Seznam adresátů"
          rules={[{ required: true }]}
        >
          <Select
            className="w-full"
            placeholder="Vyberte seznam adresátů"
            optionLabelProp="label"
            defaultValue={props.initialId}
            loading={queryResult.fetching}
            allowClear
            disabled={!!props.initialId}
            autoFocus
          >
            {option}
          </Select>
        </Form.Item>
      )

    if (queryResult.fetching)
      return (
        <div className="w-full flex justify-center">
          <Spin />
        </div>
      )

    return (
      <Select
        className="w-full"
        placeholder="Vyberte seznam adresátů"
        onChange={props.handleChange}
        optionLabelProp="label"
        defaultValue={props.initialId}
      >
        {option}
      </Select>
    )
  }
