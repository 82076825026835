import { Modal } from 'antd'
import React, { FunctionComponent } from 'react'

import { Rizeni } from '../../../common/proceedingTypes'
import { useFormattedProceeding } from '../../utils/useProceedingString'
import { useToggleState } from '../../utils/useToggleState'
import { ProceedingDetailModalContent } from '../ProceedingDetailModalContent/ProceedingDetailModalContent'

export interface ProceedingDetailModalLinkProps {
  children?: React.ReactNode
  className?: string
  proceeding?: Rizeni | null
}

export const ProceedingDetailModalLink: FunctionComponent<ProceedingDetailModalLinkProps> =
  props => {
    const [open, handleOpen] = useToggleState()
    const { proceedingTitle } = useFormattedProceeding(props.proceeding)

    if (!props.proceeding?.cislo) return <span>{props.children}</span>

    return (
      <>
        <span
          title="Zobrazit detail řízení"
          onClick={handleOpen}
          className={
            'cursor-pointer text-primary hover:text-primaryHover ' +
            (props.className || '')
          }
        >
          {props.children || proceedingTitle || 'Detail řízení'}
        </span>

        {open && (
          <Modal
            title={`Detail řízení ${proceedingTitle}`}
            width={1400}
            open={open}
            onCancel={handleOpen}
            footer={null}
            destroyOnClose
          >
            <ProceedingDetailModalContent proceeding={props.proceeding} />
          </Modal>
        )}
      </>
    )
  }
