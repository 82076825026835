import { CheckOutlined, WarningOutlined } from '@ant-design/icons'
import { Breakpoint, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Maybe } from 'graphql/jsutils/Maybe'
import React, { FunctionComponent, useMemo } from 'react'

import { PostServiceRecipient } from '../PostServiceImportRecipientModalButton/PostServiceImportRecipientModalButton'

export interface PostServiceImportRecipientPreviewTableProps {
  data?: PostServiceRecipient[]
}

export const PostServiceImportRecipientPreviewTable: FunctionComponent<PostServiceImportRecipientPreviewTableProps> =
  props => {
    const showVariable = useMemo(() => {
      const variables: { [key: string]: Breakpoint[] | undefined } = {}
      for (let i = 2; i <= 10; i++) {
        variables[`variable${i}`] = props.data?.some(
          item => item?.[`variable${i}` as keyof Maybe<PostServiceRecipient>]
        )
          ? undefined
          : ['' as Breakpoint]
      }

      return variables
    }, [props.data])

    const scroll = useMemo(() => {
      const countVariables = Object.values(showVariable).filter(
        item => item === undefined
      ).length
      return { x: 1000 + countVariables * 200 }
    }, [showVariable])

    const columns = useMemo<ColumnsType<PostServiceRecipient>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          responsive: ['' as Breakpoint],
        },

        {
          title: 'Data',
          dataIndex: 'error',
          key: 'error',
          className: 'w-10 text-center',
          render: error =>
            error?.length > 0 ? (
              <Tooltip title={'Chybná data: ' + error?.join(', ')}>
                <WarningOutlined className="text-red-500" />
              </Tooltip>
            ) : (
              <CheckOutlined className="text-green-500" />
            ),
        },

        {
          title: 'Společnost',
          dataIndex: 'companyName',
          key: 'companyName',
          sorter: {
            compare: (a, b) =>
              a.companyName && b.companyName
                ? a.companyName.localeCompare(b.companyName)
                : -1,
          },
        },
        {
          title: 'Jméno',
          dataIndex: 'recipientName',
          key: 'recipientName',
          sorter: {
            compare: (a, b) => a.lastname.localeCompare(b.lastname),
          },
          render: (_, record) => (
            <span>
              <span className={record?.firstname ? '' : 'text-red-500'}>
                {record?.firstname || '<křestní jm.>'}
              </span>
              <span className={record?.lastname ? 'pl-1' : 'text-red-500'}>
                {record?.lastname || '<příjmení>'}
              </span>
            </span>
          ),
        },
        {
          title: 'Pohlaví',
          dataIndex: 'gender',
          key: 'gender',
          sorter: {
            compare: (a, b) =>
              a.gender?.toString()?.localeCompare(b.gender?.toString() || '') ||
              -1,
          },
          render: (_, record) =>
            record?.gender === 'm'
              ? 'Muž'
              : record?.gender === 'f'
              ? 'Žena'
              : '',
        },
        {
          title: 'Adresa',
          dataIndex: 'address',
          key: 'address',
          sorter: {
            compare: (a, b) => a.street.localeCompare(b.street),
          },
          render: (_, record) => {
            return (
              <span>
                <span className={record?.street ? '' : 'text-red-500'}>
                  {record?.street || '<název ulice>'}
                </span>
                <span className={record?.houseNumber ? 'pl-1' : 'text-red-500'}>
                  {record?.houseNumber || '<číslo domu>'}
                </span>
                <span>
                  {record?.orientationNumber
                    ? '/' + record?.orientationNumber
                    : ''}
                </span>
                <span className={record?.city ? 'pl-1' : 'text-red-500'}>
                  {record?.city || '<obec>'}
                </span>
                <span className={record?.zipCode ? 'pl-1' : 'text-red-500'}>
                  {record?.zipCode || '<PSČ>'}
                </span>
                <span className={record?.country ? 'pl-1' : 'text-red-500'}>
                  {record?.country || '<kód země>'}
                </span>
              </span>
            )
          },
        },
        {
          title: 'Prom #1',
          dataIndex: 'variable1',
          key: 'variable1',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
          render: (_, record) => <>{record?.variable1}</>,
        },
        {
          title: 'Prom #2',
          responsive: showVariable.variable2,
          dataIndex: 'variable2',
          key: 'variable2',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
        },
        {
          title: 'Prom #3',
          responsive: showVariable.variable3,
          dataIndex: 'variable3',
          key: 'variable3',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
        },
        {
          title: 'Prom #4',
          responsive: showVariable.variable4,
          dataIndex: 'variable4',
          key: 'variable4',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
        },
        {
          title: 'Prom #5',
          responsive: showVariable.variable5,
          dataIndex: 'variable5',
          key: 'typ5',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
        },
        {
          title: 'Prom #6',
          responsive: showVariable.variable6,
          dataIndex: 'variable6',
          key: 'typ6',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
        },
        {
          title: 'Prom #7',
          responsive: showVariable.variable7,
          dataIndex: 'variable7',
          key: 'typ7',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
        },
        {
          title: 'Prom #8',
          responsive: showVariable.variable8,
          dataIndex: 'variable8',
          key: 'typ8',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
        },
        {
          title: 'Prom #9',
          responsive: showVariable.variable9,
          dataIndex: 'variable9',
          key: 'typ9',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
        },
        {
          title: 'Prom #10',
          responsive: showVariable.variable10,
          dataIndex: 'variable10',
          key: 'typ10',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
        },
      ],
      [showVariable]
    )

    return (
      <Table
        dataSource={props.data}
        columns={columns}
        rowKey="id"
        size="small"
        rowClassName="bg-[#fafafa]"
        className="mb-6"
        scroll={scroll}
      />
    )
  }
