import { Alert, Spin } from 'antd'
import React, { FunctionComponent, useEffect, useState } from 'react'

import { BASE_URL } from '../../../../ini.json'
import { useAppSelector } from '../../../redux/hooks'

export interface CuzkEpoHtmlDocumentTabProps {
  epoId: string
}

export const CuzkEpoHtmlDocumentTab: FunctionComponent<CuzkEpoHtmlDocumentTabProps> =
  props => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dataSource, setDataSource] = useState<string>()

    useEffect(() => {
      if (dataSource) return
      setLoading(true)
      fetch(`${BASE_URL}/download/${tenantId}/${props.epoId}.html`)
        .then(response => response.text())
        .then(data => {
          setLoading(false)
          setDataSource(data)
        })
        .catch(() => {
          setLoading(false)
          setError(true)
        })
    }, [dataSource, props.epoId, tenantId])

    if (error)
      return (
        <Alert
          message="Chyba dat"
          description="Nepodařilo se načíst data z ČÚZK."
          type="error"
          className="my-8"
          showIcon
        />
      )
    if (loading)
      return (
        <div className="my-8 flex flex-col justify-center align-middle bg-white p-4 pt-8">
          <Spin spinning />
          <div className="mb-2 mt-2 text-center text-gray-400">
            Načítají se data z ČÚZK...
          </div>
        </div>
      )
    return (
      <div className="flex justify-center min-h-[500px] bg-white p-4 pt-8 pb-12">
        {loading && <Spin size="large" />}
        {typeof dataSource === 'string' && (
          <div
            className="border shadow-lg p-8"
            dangerouslySetInnerHTML={{ __html: dataSource }}
          />
        )}
      </div>
    )
  }
