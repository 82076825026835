import { Checkbox, Col, Form, Row } from 'antd'
import React, { FunctionComponent } from 'react'

export interface MultiCheckboxSelectOptions {
  label: string
  value: number | string
  hidden?: boolean
}

export interface MultiCheckboxSelectProps {
  name: string | string[]
  label?: string
  inline?: boolean
  options: MultiCheckboxSelectOptions[]
}

export const MultiCheckboxSelect: FunctionComponent<MultiCheckboxSelectProps> =
  props => {
    return (
      <Form.Item name={props.name} label={props.label}>
        <Checkbox.Group className="w-full">
          <Row gutter={12}>
            {props.options.map(option => (
              <Col
                span={props.inline ? 'auto' : 12}
                key={option.value}
                hidden={option.hidden}
              >
                <Checkbox key={option.value} value={option.value}>
                  {option.label}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>
    )
  }
