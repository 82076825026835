import { message } from 'antd'
import axios from 'axios'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { BASE_URL } from '../../../ini.json'
import {
  RestrictionCheckboxData,
  RestrictionCheckDescription as Description,
  RestrictionChecType,
} from '../../common/epoTypes'
import {
  RestrictionCheckState,
  RestrictionCheckState as State,
} from '../../graphql/generated'
import { useAppSelector } from '../../redux/hooks'
import { CadastralWorkplace } from './cuzkHelpers'
import { pricelist } from './pricelist'

export function useEpoRestrictionCheckboxData(
  epoId: string,
  type: RestrictionChecType.restriction | RestrictionChecType.lien,
  disabled?: boolean
) {
  const tenantId = useAppSelector(state => state.myProfile.tenantId)
  const userId = useAppSelector(state => state.myProfile.userId)
  const token = useAppSelector(state => state.myProfile.token)

  const [loading, setLoading] = useState(true)
  const [inProgress, setInProgress] = useState<
    | {
        id: string
        katastralniPracovisteNazev: string
        lvKeKontrolePocet: number
        katastralniUradKod: string
        katastralniUradNazev: string
        katastralniPracovisteKod: string
        cuzkEpoLiensByRestrictionCheckId: {
          totalCount: number
        }
        state: State
        boughtAt: string
        checkedBy: string
      }[]
    | null
  >(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await axios.post(
          `${BASE_URL}/api/cuzk/epo/checkRestrictionInProgress`,
          {
            tenantId,
            userId,
            id: epoId,
            token,
          }
        )
        setInProgress(response.data)
      } catch (error) {
        setInProgress([])
        message.error('Nepodařilo se načíst všechny informace.')
      } finally {
        setLoading(false)
      }
    }
    if (!inProgress && !disabled) fetchData()
  }, [inProgress, tenantId, token, userId, epoId, disabled])

  const refetch = useCallback(() => {
    setInProgress(null)
  }, [])

  const dataProps = useMemo(() => {
    let positiveLv = 0
    let checkedSum = 0
    let notCheckedSum = 0
    let allLvBought = !!inProgress?.length
    let isAllCheckedBy = true
    let isChecking = false
    let isAllCreated = true
    let isSomeChecked = false

    inProgress?.forEach(item => {
      positiveLv += item.cuzkEpoLiensByRestrictionCheckId.totalCount

      allLvBought =
        allLvBought &&
        (item.cuzkEpoLiensByRestrictionCheckId.totalCount === 0 ||
          item.boughtAt !== null ||
          item.state === RestrictionCheckState.Created ||
          item.state === RestrictionCheckState.Processing)
      isAllCheckedBy = isAllCheckedBy && !!item.checkedBy
      isChecking = isChecking || item.state === RestrictionCheckState.Processing
      isAllCreated =
        isAllCreated && item.state === RestrictionCheckState.Created
      isSomeChecked =
        isSomeChecked || item.state === RestrictionCheckState.Checked

      if (item.state === RestrictionCheckState.Checked) {
        checkedSum += item.lvKeKontrolePocet
      } else {
        notCheckedSum += item.lvKeKontrolePocet
      }
    })

    const checkedPercentage =
      checkedSum + notCheckedSum > 0
        ? (checkedSum / (checkedSum + notCheckedSum)) * 100
        : 0

    return {
      positiveLv,
      checkedPercentage,
      allLvBought,
      isAllCheckedBy,
      isChecking,
      isAllCreated,
      isSomeChecked,
    }
  }, [inProgress])

  return useMemo<RestrictionCheckboxData>(() => {
    if (!inProgress)
      return { data: [], loading: false, inProgress: [], refetch, ...dataProps }

    const transformedData: {
      [katastralniUradKod: string]: {
        kod: number
        nazev: string
        pracoviste: CadastralWorkplace[]
        disabled?: boolean
        state: State
      }
    } = {}

    for (const item of inProgress) {
      if (
        !Object.prototype.hasOwnProperty.call(
          transformedData,
          item.katastralniUradKod
        )
      ) {
        transformedData[item.katastralniUradKod] = {
          kod: parseInt(item.katastralniUradKod),
          nazev: item.katastralniUradNazev || '',
          state: item.state,
          pracoviste: [],
        }
      }
      const liensCount = item.cuzkEpoLiensByRestrictionCheckId.totalCount
      const commonProperties = {
        kod: parseInt(item.katastralniPracovisteKod),
        nazev: item.katastralniPracovisteNazev || '',
        state: item.state,
        disabled: !!item.boughtAt || item.state !== State.Checked,
        boughtAt: item.boughtAt,
      }

      if (
        type === RestrictionChecType.lien &&
        (liensCount || (!liensCount && item.state !== State.Checked))
      ) {
        transformedData[item.katastralniUradKod].pracoviste.push({
          ...commonProperties,
          pocetLV: liensCount,
          cena: pricelist.EPO_LV_CHECK * liensCount,
          description:
            item.state === State.Checked && !!item.boughtAt
              ? Description.Bought
              : item.state === State.Processing
              ? Description.Processing
              : item.state,
        })
      }

      if (type === RestrictionChecType.restriction) {
        transformedData[item.katastralniUradKod].pracoviste.push({
          ...commonProperties,
          pocetLV: item.lvKeKontrolePocet,
          cena: pricelist.EPO_RESTRICTION_CHECK,
          description:
            item.state === State.Checked
              ? Description.Checked
              : item.state === State.Processing
              ? Description.Processing
              : item.state,
          boughtAt: item.checkedBy,
          disabled:
            !!item.boughtAt ||
            item.state === State.Processing ||
            item.state === State.Checked,
        })
      }
    }

    for (const key in transformedData) {
      transformedData[key].disabled =
        transformedData[key].state !== State.Processing &&
        transformedData[key].pracoviste.every(
          pracoviste => !!pracoviste.disabled
        )
    }

    const result = Object.values(transformedData)

    return { data: result, loading, inProgress, refetch, ...dataProps }
  }, [dataProps, inProgress, loading, refetch, type])
}
