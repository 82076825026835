import { Space } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { Nemovitosti, OmezeniVlPrav } from '../../../common/lvTypes'
import { useToggleState } from '../../utils/useToggleState'
import { Fade } from '../Fade/Fade'
import { LvDetailSidebarRestrictionDetail } from '../LvDetailSidebarRestrictionDetail/LvDetailSidebarRestrictionDetail'
import { PropertyDetailSidebarContainer } from '../PropertyDetailSidebarContainer/PropertyDetailSidebarContainer'
import {
  RestrictionBadge,
  restrictionMapping,
  RestrictionType,
} from '../RestrictionBadge/RestrictionBadge'

export interface LvDetailSidebarRestrictionProps {
  properties: Nemovitosti
  isDrawer?: boolean
}

export const LvDetailSidebarRestriction: FunctionComponent<LvDetailSidebarRestrictionProps> =
  props => {
    const [open, handleOpen] = useToggleState(false)

    const allProperties = useMemo(
      () => [
        ...(props.properties.budovy || []),
        ...(props.properties.parcely || []),
        ...(props.properties.jednotky || []),
      ],
      [props.properties]
    )

    const checkRestriction = useCallback(
      (omezeni: RestrictionType, omezeniPartly: RestrictionType) => {
        const totalCount = allProperties.length
        const restrictedCount = allProperties.filter(
          item =>
            item.omezeniVlPrav &&
            item.omezeniVlPrav[omezeni as keyof OmezeniVlPrav]
        ).length

        return {
          type: totalCount === restrictedCount ? omezeni : omezeniPartly,
          show: !!restrictedCount,
          hoverEffect: totalCount !== restrictedCount,
          onClick: totalCount !== restrictedCount ? handleOpen : undefined,
          restrictedCount,
          name: restrictionMapping[omezeni].label,
        }
      },
      [allProperties, handleOpen]
    )

    const checkNoRestriction = useCallback(() => {
      const totalCount = allProperties.length
      const restrictedCount = allProperties.filter(
        item =>
          item.omezeniVlPrav &&
          !item.omezeniVlPrav[RestrictionType.Execution] &&
          !item.omezeniVlPrav[RestrictionType.Insolvency] &&
          !item.omezeniVlPrav[RestrictionType.VoluntaryLien] &&
          !item.omezeniVlPrav[RestrictionType.InvoluntaryLien] &&
          !item.omezeniVlPrav[RestrictionType.PreemptionRight]
      ).length

      return {
        type: RestrictionType.NoRestrictionLong,
        show: restrictedCount === totalCount,
        restrictedCount,
        name: 'Bez omezení',
      }
    }, [allProperties])

    const restrictions = useMemo(
      () => [
        checkRestriction(
          RestrictionType.Execution,
          RestrictionType.ExecutionPartly
        ),
        checkRestriction(
          RestrictionType.Insolvency,
          RestrictionType.InsolvencyPartly
        ),
        checkRestriction(
          RestrictionType.VoluntaryLien,
          RestrictionType.VoluntaryLienPartly
        ),
        checkRestriction(
          RestrictionType.InvoluntaryLien,
          RestrictionType.InvoluntaryLienPartly
        ),
        checkRestriction(
          RestrictionType.PreemptionRight,
          RestrictionType.PreemptionRightPartly
        ),
        checkNoRestriction(),
      ],
      [checkNoRestriction, checkRestriction]
    )

    return (
      <PropertyDetailSidebarContainer narrow isDrawer={props.isDrawer}>
        <Space className="flex-wrap">
          {restrictions.map((restriction, key) => (
            <RestrictionBadge key={key} {...restriction} />
          ))}
        </Space>

        <Fade show={open} className="mt-2 pl-1 pr-2">
          <LvDetailSidebarRestrictionDetail
            restrictions={restrictions}
            totalCount={allProperties.length}
            handleOpen={handleOpen}
          />
        </Fade>
      </PropertyDetailSidebarContainer>
    )
  }
