import React, { FunctionComponent } from 'react'

import { D1_UPOZORNENI, MaybeArray } from '../../../common/cuzkLvTypes'
import { notEmpty, safeMap } from '../../../common/pdfHelpers'
import { CuzkLvHtmlBezZapisuFragment } from '../CuzkLvHtmlBezZapisuFragment/CuzkLvHtmlBezZapisuFragment'
import { CuzkLvHtmlJednotkaIdentFragment } from '../CuzkLvHtmlJednotkaIdentFragment/CuzkLvHtmlJednotkaIdentFragment'
import { CuzkLvHtmlParcelaIdentFragment } from '../CuzkLvHtmlParcelaIdentFragment/CuzkLvHtmlParcelaIdentFragment'
import { CuzkLvHtmlPravoStavbyIdentFragment } from '../CuzkLvHtmlPravoStavbyIdentFragment/CuzkLvHtmlPravoStavbyIdentFragment'
import { CuzkLvHtmlRizeniFragment } from '../CuzkLvHtmlRizeniFragment/CuzkLvHtmlRizeniFragment'
import { CuzkLvHtmlStavbaIdentFragment } from '../CuzkLvHtmlStavbaIdentFragment/CuzkLvHtmlStavbaIdentFragment'

export interface CuzkLvHtmlSekceD1FragmentProps {
  d1Upozorneni?: MaybeArray<D1_UPOZORNENI>
  active?: boolean
}

export const CuzkLvHtmlSekceD1Fragment: FunctionComponent<CuzkLvHtmlSekceD1FragmentProps> =
  props => {
    const D1_UPOZORNENI = props.d1Upozorneni
    const isEmpty = !notEmpty(D1_UPOZORNENI)

    return (
      <>
        <div className="row">
          <div className="col-xs-12">
            <i>Plomby a upozornění</i>
            {isEmpty && <CuzkLvHtmlBezZapisuFragment />}
          </div>
        </div>
        {!isEmpty && (
          <>
            <div className="row">
              <div className="col-xs-1">&nbsp;</div>
              <div className="col-xs-3 pr-30">
                <i>Číslo řízení</i>
              </div>
              <div className="col-xs-1">&nbsp;</div>
              <div className="col-xs-6">
                <i>Vztah k</i>
              </div>
            </div>
            <hr className="mt-20 mb-0" />
            <ul className="circleList">
              {safeMap(D1_UPOZORNENI, (UPOZORNENI, index) => (
                <li key={index}>
                  <div className="row">
                    <strong>{UPOZORNENI?.NAZEV}</strong>
                  </div>
                  {safeMap(
                    UPOZORNENI?.SEZNAM_RIZENI,
                    (SEZNAM_RIZENI, index) => (
                      <div key={index}>
                        <div className="row">
                          <div className="col-xs-1">&nbsp;</div>
                          <div className="col-xs-4">
                            <strong>
                              <CuzkLvHtmlRizeniFragment
                                rizeni={SEZNAM_RIZENI?.IDENT_RIZENI}
                                active={props.active}
                              />
                            </strong>
                          </div>
                          <div className="col-xs-7">
                            <strong>
                              {safeMap(
                                SEZNAM_RIZENI?.NEMOVITOSTI.NEMOVITOST,
                                (NEMOVITOST, index) => (
                                  <div key={index}>
                                    {safeMap(
                                      NEMOVITOST.parcela,
                                      (parcela, index) => (
                                        <div key={index}>
                                          <CuzkLvHtmlParcelaIdentFragment
                                            active={props.active}
                                            parcelaIdent={parcela}
                                          />
                                        </div>
                                      )
                                    )}
                                    {safeMap(
                                      NEMOVITOST.stavba,
                                      (stavba, index) => (
                                        <div key={index}>
                                          <CuzkLvHtmlStavbaIdentFragment
                                            active={props.active}
                                            stavbaIdent={stavba}
                                          />
                                        </div>
                                      )
                                    )}
                                    {safeMap(
                                      NEMOVITOST.jednotka,
                                      (jednotka, index) => (
                                        <div key={index}>
                                          <CuzkLvHtmlJednotkaIdentFragment
                                            jednotkaIdent={jednotka}
                                          />
                                        </div>
                                      )
                                    )}
                                    {safeMap(
                                      NEMOVITOST.pravo_stavby,
                                      (pravo_stavby, index) => (
                                        <div key={index}>
                                          <CuzkLvHtmlPravoStavbyIdentFragment
                                            pravoStavbyIdent={pravo_stavby}
                                          />
                                        </div>
                                      )
                                    )}
                                  </div>
                                )
                              )}
                            </strong>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </li>
              ))}
            </ul>
          </>
        )}
      </>
    )
  }
