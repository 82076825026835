import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { usePropertyDetailAllCuzkLvsQuery } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { clickTrigger } from '../../utils/layoutConst'
import { useToggleState } from '../../utils/useToggleState'
import { CuzkLvModalButton } from '../CuzkLvModalButton/CuzkLvModalButton'
import { PropertyDetailLvDownloadedModalButton } from '../PropertyDetailLvDownloadedModalButton/PropertyDetailLvDownloadedModalButton'

export interface PropertyDetailLvButtonProps {
  linkStyle?: boolean
  lvNumber?: number | string
  lvCode?: number | string
}

export const PropertyDetailLvButton: FunctionComponent<PropertyDetailLvButtonProps> =
  props => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const lvNumber = useAppSelector(
      state => `${props.lvNumber || state.property.lv.data?.lv || ''}`
    )
    const lvCode = useAppSelector(
      state =>
        `${props.lvCode || state.property.lv.data?.katastralniUzemi?.kod || ''}`
    )

    const [isOpen, toggleIsOpen] = useToggleState(false)
    const [queryResult, refetch] = usePropertyDetailAllCuzkLvsQuery({
      pause: !(tenantId && lvCode && lvNumber),
      requestPolicy: 'cache-first',
      variables: {
        accountId: tenantId,
        katastralniUzemiKod: lvCode,
        lv: lvNumber,
      },
    })

    const idFilter = useMemo(
      () =>
        queryResult.data?.allCuzkLvs?.totalCount
          ? { in: queryResult.data?.allCuzkLvs?.nodes?.map(lv => lv?.id || '') }
          : false,
      [
        queryResult.data?.allCuzkLvs?.nodes,
        queryResult.data?.allCuzkLvs?.totalCount,
      ]
    )

    const handleRefetch = useCallback(
      (value?: boolean) => {
        refetch({ requestPolicy: 'network-only' })
        value && toggleIsOpen()
      },
      [refetch, toggleIsOpen]
    )

    const downloadButtonProps = useMemo(
      () => ({
        initialValues: {
          lvCislo: lvNumber,
          katastralniUzemiKod: lvCode,
        },
        buttonText: 'Stáhnout LV',
        defaultClose: true,
        refetch: handleRefetch,
        buttonProps: {
          size: props.linkStyle ? 'small' : 'middle',
          type: props.linkStyle ? 'link' : 'primary',
        } as const,
      }),
      [handleRefetch, lvCode, lvNumber, props.linkStyle]
    )

    const dropdownRender = useCallback(
      () => (
        <CuzkLvModalButton
          refetch={handleRefetch}
          initialValues={downloadButtonProps.initialValues}
          buttonText="Stáhnout nové LV"
          defaultClose
        />
      ),
      [downloadButtonProps.initialValues, handleRefetch]
    )

    if (idFilter)
      return (
        <Button.Group>
          <PropertyDetailLvDownloadedModalButton
            idFilter={idFilter}
            linkStyle={props.linkStyle}
            refetch={handleRefetch}
            open={isOpen}
            setOpen={toggleIsOpen}
          />
          <Dropdown
            dropdownRender={dropdownRender}
            placement="bottomRight"
            trigger={clickTrigger}
          >
            <Button
              type={props.linkStyle ? 'link' : 'primary'}
              size={props.linkStyle ? 'small' : 'middle'}
              className={props.linkStyle ? 'hover:bg-gray-50' : ''}
            >
              <DownOutlined />
            </Button>
          </Dropdown>
        </Button.Group>
      )
    return <CuzkLvModalButton {...downloadButtonProps} />
  }
