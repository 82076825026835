import { FileProtectOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { FunctionComponent, memo, useMemo } from 'react'

import { BASE_URL } from '../../../../ini.json'
import { useAppSelector } from '../../../redux/hooks'

export interface PostServiceSentTableTrackingNumberProps {
  trackingNumber?: string
  postserviceId?: string
  sentAt?: Date | null
}

export const PostServiceSentTableTrackingNumber: FunctionComponent<PostServiceSentTableTrackingNumberProps> =
  memo(props => {
    const { tenantId, token } = useAppSelector(state => state.myProfile)

    const tracking = useMemo(() => {
      const title = props.trackingNumber
        ? 'Track&Trace na stránkách pošty'
        : props.sentAt
        ? 'Pro tento druh zásilky pošta neuvádí podací číslo'
        : 'Zásilka nebyla odeslána'

      const content = !props.trackingNumber ? (
        '---'
      ) : (
        <a
          target="_blank"
          href={`https://www.postaonline.cz/trackandtrace/-/zasilka/cislo?parcelNumbers=${props.trackingNumber}`}
          rel="noreferrer"
        >
          {props.trackingNumber}
        </a>
      )
      return { title, content }
    }, [props.sentAt, props.trackingNumber])

    if (!props.sentAt) return <div className="text-gray-400">Neodesláno</div>

    return (
      <div className="flex space-x-2">
        <Tooltip title="Stáhnout podací lístek české pošty">
          <a
            download
            target="_blank"
            href={`${BASE_URL}/api/postService/receipt/${tenantId}/${props.postserviceId}/${token}`}
            rel="noreferrer"
          >
            <FileProtectOutlined />
          </a>
        </Tooltip>
        <Tooltip title={tracking.title}>{tracking.content}</Tooltip>
      </div>
    )
  })
