import React, { FunctionComponent, useMemo } from 'react'
import Timeline, {
  DateHeader,
  ReactCalendarItemRendererProps,
  TimelineHeaders,
  TimelineItemBase,
} from 'react-calendar-timeline'

import { useAppSelector } from '../../../redux/hooks'
import { getTypeInfo } from '../../utils/propertyRestrictionHelpers'
import {
  getTimelineDatePoint,
  propertyTimelineGroups,
  propertyTimelinePrimaryHeader,
  propertyTimelineProps,
  propertyTimelineRestrictionTitle,
  propertyTimelineSecondaryHeader,
} from '../../utils/propertyTimelineHelpers'
import { PropertyDetailTimelineMarkers } from '../PropertyDetailTimelineMarkers/PropertyDetailTimelineMarkers'
import { PropertyDetailTimelineTitle } from '../PropertyDetailTimelineTitle/PropertyDetailTimelineTitle'
import { PropertyTimelineItem } from '../PropertyTimelineItem/PropertyTimelineItem'

export interface PropertyDetailTimelineRestrictionProps {
  visibleTimeStart: number
  visibleTimeEnd: number
  onTimeChange: (start: number, end: number) => void
}

type Item = ReactCalendarItemRendererProps<TimelineItemBase<number>>

const propertyTimelineItemRender = (item: Item) => (
  <PropertyTimelineItem getItemProps={item.getItemProps} item={item.item} />
)

export const PropertyDetailTimelineRestriction: FunctionComponent<PropertyDetailTimelineRestrictionProps> =
  props => {
    const timelineData = useAppSelector(state => state.property.timeline.data)

    const restrictionItems = useMemo(() => {
      if (!timelineData?.ovp?.length) return []
      return timelineData.ovp.map((ovp, index) => ({
        id: index,
        group: 'restriction',
        title: propertyTimelineRestrictionTitle(ovp),
        start_time: getTimelineDatePoint(
          ovp.datumOd || timelineData?.sledovanoOd
        ),
        end_time: getTimelineDatePoint(ovp.datumDo),
        className: getTypeInfo(ovp.nazev || '').bg,
      }))
    }, [timelineData?.ovp, timelineData?.sledovanoOd])

    return (
      <>
        <PropertyDetailTimelineTitle title="Omezení a jiné zápisy" />
        <Timeline
          {...propertyTimelineProps}
          groups={propertyTimelineGroups.restriction}
          items={restrictionItems}
          visibleTimeStart={props.visibleTimeStart}
          visibleTimeEnd={props.visibleTimeEnd}
          onTimeChange={props.onTimeChange}
          itemRenderer={propertyTimelineItemRender}
        >
          <TimelineHeaders>
            <DateHeader {...propertyTimelineSecondaryHeader} />
            <DateHeader {...propertyTimelinePrimaryHeader} />
          </TimelineHeaders>
          <PropertyDetailTimelineMarkers />
        </Timeline>
      </>
    )
  }
