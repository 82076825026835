import { Input, InputRef, Tooltip } from 'antd'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { CadastralOffice } from '../../utils/cuzkHelpers'
import { useConst } from '../../utils/useConst'

export interface CadastralSelectionSearchBarProps {
  checkboxData: CadastralOffice[]
  handleCheckCodes: (matchCodes: number[]) => void
  handleMatching: (matchCodes: number[]) => void
  setSearchTerm: (value: string) => void
}

export const CadastralSelectionSearchBar: FunctionComponent<CadastralSelectionSearchBarProps> =
  ({ checkboxData, handleCheckCodes, handleMatching, setSearchTerm }) => {
    const [tooltipContent, setTooltipContent] = useState<string>('')
    const [value, setValue] = useState<string>('')
    const inputRef = useRef<InputRef>(null)

    const findMatches = useCallback(
      (data: CadastralOffice[], searchTerm?: string) => {
        const searchTermLowerCase = (searchTerm || value).toLowerCase()
        return data.filter(
          office =>
            (!office.disabled &&
              office.nazev.toLowerCase().includes(searchTermLowerCase)) ||
            office.pracoviste.some(
              workplace =>
                workplace.nazev.toLowerCase().includes(searchTermLowerCase) &&
                !workplace.disabled
            )
        )
      },
      [value]
    )

    const dataSource = useConst(checkboxData)

    const handleSearch = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const matchingOffices = findMatches(
          dataSource,
          e.target.value.toLowerCase()
        )
        if (matchingOffices.length === 1) {
          const matchingOffice = matchingOffices[0]
          const matchingWorkplaces = matchingOffice.pracoviste.filter(
            workplace =>
              workplace.nazev
                .toLowerCase()
                .includes(e.target.value.toLowerCase())
          )
          if (matchingWorkplaces.length === 1) {
            setTooltipContent(
              `Enterem vyberete pracoviště ${matchingWorkplaces[0].nazev}`
            )
          } else {
            setTooltipContent(`Enterem vyberete celý ${matchingOffice.nazev}`)
          }
        } else {
          setTooltipContent('')
        }
        const matchSource = e.target.value === '' ? dataSource : matchingOffices

        handleMatching(
          matchSource
            .map(office => office.pracoviste.map(workplace => workplace.kod))
            .flat()
        )
        setSearchTerm(e.target.value)
        setValue(e.target.value)
      },
      [dataSource, findMatches, handleMatching, setSearchTerm]
    )

    const handleFocus = useCallback(() => {
      inputRef.current?.select()
    }, [])

    useEffect(() => {
      if (inputRef.current && !value) {
        inputRef.current.select()
      }
    }, [value])

    const handleEnterKeyDown = useCallback(() => {
      const matchingOffices = findMatches(dataSource)

      if (matchingOffices.length !== 1) return
      let matchCodes: number[] = []

      const matchingOffice = matchingOffices[0]
      const matchingWorkplaces = matchingOffice.pracoviste.filter(workplace =>
        workplace.nazev.toLowerCase().includes(value.toLowerCase())
      )
      if (matchingWorkplaces.length === 1) {
        matchCodes.push(matchingWorkplaces[0].kod)
      } else {
        matchCodes = [
          matchingOffice.kod,
          ...(matchCodes.concat(
            matchingOffice.pracoviste.map(workplace => workplace.kod)
          ) || []),
        ]
      }

      handleCheckCodes(matchCodes)
      setSearchTerm('')
      setValue('')
    }, [dataSource, findMatches, handleCheckCodes, setSearchTerm, value])

    return (
      <Tooltip
        placement="topLeft"
        overlayClassName="!max-w-xl"
        title={tooltipContent}
        open={!!tooltipContent && !!value}
      >
        <Input
          ref={inputRef}
          allowClear
          placeholder="Vyhledat katastrální úřad nebo pracoviště"
          onChange={handleSearch}
          onFocus={handleFocus}
          onPressEnter={handleEnterKeyDown}
          value={value}
        />
      </Tooltip>
    )
  }
