import { Icon } from '@iconify/react'
import { Button, Tooltip } from 'antd'
import React, { FunctionComponent } from 'react'

import { BASE_URL } from '../../../../ini.json'
import { useAppSelector } from '../../../redux/hooks'
import { isHugeSubjectLimit } from '../../utils/layoutConst'
import { CuzkEpoXlsxButton } from '../CuzkEpoXlsxButton/CuzkEpoXlsxButton'
import { formatCurrencyNumber } from '../Money/Money'

export interface CuzkEpoDownloadButtonProps {
  isHugeSubject?: boolean
  id: string
  type: 'xml' | 'pdf' | 'html' | 'xlsx' | 'json'
}

export const CuzkEpoDownloadButton: FunctionComponent<CuzkEpoDownloadButtonProps> =
  props => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)

    const button =
      (props.type === 'xml' && (
        <Button
          type="primary"
          ghost
          icon={<Icon className="inline mr-2" icon="bi:file-earmark-code" />}
        >
          XML
        </Button>
      )) ||
      (props.type === 'html' && (
        <Button
          type="primary"
          ghost
          disabled={props.isHugeSubject}
          icon={<Icon className="inline mr-2" icon="bi:filetype-html" />}
        >
          HTML
        </Button>
      )) ||
      (props.type === 'pdf' && (
        <Button
          type="primary"
          ghost
          disabled={props.isHugeSubject}
          icon={<Icon className="inline mr-2" icon="bi:file-earmark-pdf" />}
        >
          PDF
        </Button>
      )) ||
      (props.type === 'json' && (
        <Button
          type="primary"
          ghost
          icon={<Icon className="inline mr-2" icon="bi:filetype-json" />}
        >
          JSON
        </Button>
      ))

    if (props.type === 'xlsx') return <CuzkEpoXlsxButton id={props.id} />
    if (props.isHugeSubject) {
      return (
        <Tooltip
          title={`Pro subjekty, jejichž práva se vztahují k více než ${formatCurrencyNumber(
            isHugeSubjectLimit,
            0
          )} LV, negenerujeme ${props.type.toUpperCase()} dokumenty, data si můžete stáhnout ve formátu xlsx, xml nebo json.`}
        >
          {button}
        </Tooltip>
      )
    }
    return (
      <a
        href={`${BASE_URL}/download/${tenantId}/${props.id}.${props.type}`}
        download
      >
        {button}
      </a>
    )
  }
