import React, { FunctionComponent, memo } from 'react'

import { CadastralImportBox } from '../CadastralImportBox/CadastralImportBox'
import { PropertyDetailLastNote } from '../PropertyDetailLastNote/PropertyDetailLastNote'
import { PropertyDetailOwnershipRights } from '../PropertyDetailOwnershipRights/PropertyDetailOwnershipRights'
import { PropertyDetailSidebarLv } from '../PropertyDetailSidebarLv/PropertyDetailSidebarLv'
import { PropertyDetailSidebarOwnershipRestriction } from '../PropertyDetailSidebarOwnershipRestriction/PropertyDetailSidebarOwnershipRestriction'
import { PropertyDetailSidebarRuian } from '../PropertyDetailSidebarRuian/PropertyDetailSidebarRuian'

export interface DetailDrawerPropertyContentProps {
  propertyId: string
}

export const DetailDrawerPropertyContent: FunctionComponent<DetailDrawerPropertyContentProps> =
  memo(props => {
    return (
      <div className="flex flex-col space-y-8 mb-4">
        <PropertyDetailSidebarOwnershipRestriction
          propertyId={props.propertyId}
          isDrawer
        />
        <PropertyDetailLastNote isDrawer />
        <CadastralImportBox className="mt-2" entityType="property" />
        <PropertyDetailSidebarLv isDrawer />
        <PropertyDetailSidebarRuian isDrawer />
        <PropertyDetailOwnershipRights isDrawer />
      </div>
    )
  })
