import { message } from 'antd'

import { BASE_URL } from '../../../ini.json'
import { CeeMonitorItem } from '../components/CeeMonitorTableExpandedRow/CeeMonitorTableExpandedRow'

export const checkCeeDetail = async (
  record: CeeMonitorItem[],
  tenantId: string,
  userId: string
) => {
  if (!tenantId || !userId) {
    message.error(
      'Kvůli dlouhé neaktivitě došlo k odhlášení. Prosím přihlašte se znovu.'
    )
    return false
  }

  const ceeResponse = await fetch(`${BASE_URL}/api/cee/detail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      monitoringCeeData: record,
      accountId: tenantId,
      userId: userId,
    }),
  })
  if (!ceeResponse.ok) {
    message.error('Nepodařilo se získat detail exekuce')
    return false
  }
  if (ceeResponse.status === 200) {
    message.success('Detail exekuce úspěšně stažen')
    return true
  }
  return false
}
