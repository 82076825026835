export enum PropertyDetailSubjectsTableKey {
  HQ = 'Headquarter',
  WP = 'Workplace',
  R = 'Residence',
}

export const PropertyDetailSubjectsTableEmptyText = {
  [PropertyDetailSubjectsTableKey.HQ]:
    'Neevidujeme žádné subjekty se sídlem na této adrese.',
  [PropertyDetailSubjectsTableKey.WP]:
    'Neevidujeme žádné subjekty s provozovnou na této adrese.',
  [PropertyDetailSubjectsTableKey.R]:
    'Neevidujeme žádné subjekty s trvalým bydlištěm na této adrese.',
}

export const PropertyDetailSubjectsTableTitle = {
  [PropertyDetailSubjectsTableKey.HQ]: 'Subjekty se sídlem na adrese.',
  [PropertyDetailSubjectsTableKey.WP]: 'Subjekty s provozovnou na adrese.',
  [PropertyDetailSubjectsTableKey.R]: 'Subjekty s trvalým bydlištěm na adrese',
}

export const PropertyDetailSubjectsTableAddressChanged = {
  [PropertyDetailSubjectsTableKey.HQ]: 'Sídlo odstraněno',
  [PropertyDetailSubjectsTableKey.WP]: 'Provozovna odstraněna',
  [PropertyDetailSubjectsTableKey.R]: 'Platné k datu',
}
