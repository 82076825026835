import { Button, Checkbox } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import { CadastreMapAuctionLayerControlFilterModalButton } from '../../../CadastreMapAuctionLayerControlFilterModalButton/CadastreMapAuctionLayerControlFilterModalButton'
import { CadastreMapAuctionLayerControlStats } from '../../../CadastreMapAuctionLayerControlStats/CadastreMapAuctionLayerControlStats'
import { Overlay, OverlayProps } from '../CadastreMapOverlay/CadastreMapOverlay'

export interface CadastreMapAuctionLayerControlProps extends OverlayProps {
  enabled: boolean
  onEnabledChange: React.Dispatch<React.SetStateAction<boolean>>

  filter: any
  onFilterChange: React.Dispatch<React.SetStateAction<any>>
}

export const CadastreMapAuctionLayerControl: FunctionComponent<CadastreMapAuctionLayerControlProps> =
  props => {
    const toggleEnabled = useCallback(
      () => props.onEnabledChange(prev => !prev),
      [props]
    )
    return (
      <Overlay position={props.position}>
        {() => (
          <div className="flex space-x-2">
            <Button
              onClick={toggleEnabled}
              className="hover:!bg-white"
              title="Zobrazit vybrané dražby"
              icon={<Checkbox checked={props.enabled} rootClassName="!mr-2" />}
            >
              Dražby
            </Button>
            <CadastreMapAuctionLayerControlFilterModalButton
              filter={props.filter}
              onFilterChange={props.onFilterChange}
            />
            {props.enabled && <CadastreMapAuctionLayerControlStats />}
          </div>
        )}
      </Overlay>
    )
  }
