import React, { FunctionComponent, useMemo } from 'react'

import { useProceedingIdsWithDocumentQuery } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { PageLayoutTabContainer } from '../PageLayoutTabContainer/PageLayoutTabContainer'
import { PropertyDetailProceedingTable } from '../PropertyDetailProceedingTable/PropertyDetailProceedingTable'

export const PropertyDetailProceeding: FunctionComponent = () => {
  const tenantId = useAppSelector(state => state.myProfile.tenantId)
  const data = useAppSelector(state => state.property.timeline.data?.rizeni)

  const proceedings = useMemo(
    () =>
      data?.map(
        ({ typ, cislo, rok, pracovisteKod }) =>
          `${typ}-${cislo}-${rok}-${pracovisteKod}`
      ) ?? [],
    [data]
  )

  const [queryResult] = useProceedingIdsWithDocumentQuery({
    pause: proceedings.length === 0,
    variables: { accountId: tenantId, proceedings },
  })

  const proceedingsWithDocument = useMemo(() => {
    const { totalCount, nodes } =
      queryResult.data?.allCuzkDocumentCollections ?? {}

    return totalCount ? nodes?.map(node => node?.rizeni || '') : []
  }, [queryResult.data?.allCuzkDocumentCollections])

  return (
    <PageLayoutTabContainer>
      <PropertyDetailProceedingTable withDocument={proceedingsWithDocument} />
    </PageLayoutTabContainer>
  )
}
