import { Tooltip } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { PARCELA } from '../../../common/cuzkLvTypes'
import { PROPERTY_TYPES } from '../../../common/drmTypes'
import {
  appendComa,
  appendSpace,
  notEmpty,
  safeJoin,
  safeMap,
} from '../../../common/pdfHelpers'
import { useAppSelector } from '../../../redux/hooks'
import { lvDetailPath, propertyDetailPath } from '../../utils/paths'
import { CuzkLvHtmlIdentifikatorFragment } from '../CuzkLvHtmlIdentifikatorFragment/CuzkLvHtmlIdentifikatorFragment'
import { CuzkLvHtmlJednotkaIdentFragment } from '../CuzkLvHtmlJednotkaIdentFragment/CuzkLvHtmlJednotkaIdentFragment'
import { CuzkLvHtmlOpravSubjektFragment } from '../CuzkLvHtmlOpravSubjektFragment/CuzkLvHtmlOpravSubjektFragment'
import { CuzkLvHtmlParcelaIdentFragment } from '../CuzkLvHtmlParcelaIdentFragment/CuzkLvHtmlParcelaIdentFragment'
import { CuzkLvHtmlPodilFragment } from '../CuzkLvHtmlPodilFragment/CuzkLvHtmlPodilFragment'

export interface CuzkLvHtmlParcelaInlineFragmentProps {
  parcela?: PARCELA
  active?: boolean
}

export const CuzkLvHtmlParcelaInlineFragment: FunctionComponent<CuzkLvHtmlParcelaInlineFragmentProps> =
  props => {
    const PARCELA = props.parcela

    const nemovitosti = useAppSelector(state => state.lvDetail.nemovitosti)
    const katastralniUzemiKod = useAppSelector(
      state => state.lvDetail.katastralniUzemi.kod
    )
    const findBuilding = useCallback(
      (buildingNumber: string, text: string) => {
        if (props.active) {
          for (const budova of nemovitosti.budovy) {
            if (
              budova.cislaDomovni?.match(/\d+/g)?.join('') ===
              buildingNumber.match(/\d+/g)?.join('')
            ) {
              return (
                <Tooltip
                  key={budova?.id}
                  title="Zobrazit detail budovy v novém okně"
                >
                  <Link
                    target="_blank"
                    to={propertyDetailPath(PROPERTY_TYPES.BUILDING, budova.id)}
                  >
                    {text}
                  </Link>
                </Tooltip>
              )
            }
          }
        }
        return text
      },
      [nemovitosti.budovy, props.active]
    )

    const naLvTitle = useCallback(
      (lvNumber: string) => {
        if (props.active) {
          return (
            <Tooltip title="Zobrazit detail LV v novém okně">
              <Link
                target="_blank"
                to={lvDetailPath(katastralniUzemiKod, lvNumber)}
              >
                {lvNumber}
              </Link>
            </Tooltip>
          )
        }

        return lvNumber
      },
      [katastralniUzemiKod, props.active]
    )

    if (!PARCELA) {
      return null
    }

    return (
      <>
        <div className="row bold mt-10">
          <div className="col-xs-1">
            {PARCELA?.PLOMBA_PARCELY ? PARCELA?.PLOMBA_PARCELY + ' ' : ''}
            {safeMap(PARCELA?.PAR_IDENT, (ident, index, array) => (
              <React.Fragment key={index}>
                <CuzkLvHtmlParcelaIdentFragment
                  active={props.active}
                  parcelaIdent={ident.parcela}
                  noLabel
                  key={index}
                />
                {index < array.length - 1 && ', '}
              </React.Fragment>
            ))}
          </div>
          <div className="col-xs-2 text-right">
            <span className="pr-30">{PARCELA.VYMERA_PARCELY}</span>
          </div>
          <div className="col-xs-3">{PARCELA.DRUH_POZ}</div>
          <div className="col-xs-3">{PARCELA.ZP_VYUZITI}&nbsp;</div>
          <div className="col-xs-3 breakAll">
            {safeJoin(PARCELA?.ZP_OCHRANY?.zpochr, ', ')}&nbsp;
          </div>
        </div>
        {!!PARCELA.SOUCASTI_STAVBA?.stavba && (
          <div className="row">
            <div className="col-xs-1">&nbsp;</div>
            <div className="col-xs-4">
              <i>Součástí je stavba: </i>
            </div>
            <div className="col-xs-7">
              {safeMap(PARCELA.SOUCASTI_STAVBA?.stavba, (stavba, index) => (
                <strong key={index}>
                  {findBuilding(
                    stavba?.cisla_domovni || '',
                    appendComa(stavba?.caobce) +
                      appendSpace(stavba?.typbud_zkr) +
                      appendComa(stavba?.cisla_domovni)
                  )}
                  {/*     {appendComa(stavba?.caobce)}
                  {appendSpace(stavba?.typbud_zkr)}
                  {appendComa(stavba?.cisla_domovni)} */}
                  {appendComa(stavba?.vyuziti_zkr)}
                  {/* {stavba.tel_id} */}
                  {appendComa(stavba?.bez_lv)}
                  {/* {stavba.cislo_tel} */}
                  {appendComa(stavba?.ku_nazev)}
                  {stavba?.docasna}
                  {/* Pokud je třeba, zde můžeš vypsat i data z 'par_ident' */}
                </strong>
              ))}
            </div>
          </div>
        )}

        {!!PARCELA.STAVBA_NA_PARCELE?.stavba && (
          <div className="row">
            <div className="col-xs-1">&nbsp;</div>
            <div className="col-xs-4">
              <i>Stavba stojí na pozemku p.č.: </i>
            </div>
            <div className="col-xs-7">
              <strong>
                {appendComa(PARCELA.STAVBA_NA_PARCELE?.stavba?.caobce)}
                {appendComa(PARCELA.STAVBA_NA_PARCELE?.stavba?.typbud_zkr)}
                {appendComa(PARCELA.STAVBA_NA_PARCELE?.stavba?.cisla_domovni)}
                {appendComa(PARCELA.STAVBA_NA_PARCELE?.stavba?.vyuziti_zkr)}
                {/* {PARCELA.STAVBA_NA_PARCELE?.stavba.tel_id} */}
                {appendComa(PARCELA.STAVBA_NA_PARCELE?.stavba?.bez_lv)}
                {/* {PARCELA.STAVBA_NA_PARCELE?.stavba.cislo_tel} */}
                {appendComa(PARCELA.STAVBA_NA_PARCELE?.stavba?.ku_nazev)}
                {PARCELA.STAVBA_NA_PARCELE?.stavba?.docasna}
                {/* Pokud je třeba, zde můžeš vypsat i data z 'par_ident' */}
              </strong>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-xs-1"></div>
          {Array.isArray(PARCELA.STAVBA_NA_VICE_PARCELACH?.parcela)
            ? PARCELA.STAVBA_NA_VICE_PARCELACH?.parcela?.length &&
              PARCELA.STAVBA_NA_VICE_PARCELACH?.parcela?.length > 1 && (
                <div className="col-xs-4">
                  <i>Stavba na více parcelách: </i>
                </div>
              )
            : PARCELA.STAVBA_NA_VICE_PARCELACH?.parcela && (
                <div className="col-xs-4">
                  <i>Stavba stojí na pozemku p.č.: </i>
                </div>
              )}

          <div className="col-xs-7">
            {safeMap(
              PARCELA.STAVBA_NA_VICE_PARCELACH?.parcela,
              (parcela, index, array) => (
                <strong key={index}>
                  <CuzkLvHtmlParcelaIdentFragment
                    active={props.active}
                    parcelaIdent={parcela}
                  />
                  {index < array.length - 1 && ', '}
                </strong>
              )
            )}
          </div>
        </div>

        {safeMap(
          PARCELA?.DALSI_UDAJE?.stavba_soucasti_pozemku,
          (stavba_soucasti_pozemku, index) => (
            <div key={index} className="row">
              <div className="col-xs-1">&nbsp;</div>
              <div className="col-xs-4">
                <i>Stavba součástí pozemku: </i>
              </div>
              <div className="col-xs-7">
                <strong>
                  <CuzkLvHtmlParcelaIdentFragment
                    active={props.active}
                    parcelaIdent={stavba_soucasti_pozemku?.parcela}
                  />
                </strong>
              </div>
            </div>
          )
        )}

        {PARCELA?.DALSI_UDAJE?.stavba_soucasti_prava_stavby && (
          <div className="row">
            <div className="col-xs-1">&nbsp;</div>
            <div className="col-xs-4">
              <i>Stavba součástí práva stavby: </i>
            </div>
            <div className="col-xs-7">
              {safeMap(
                PARCELA?.DALSI_UDAJE?.stavba_soucasti_prava_stavby,
                (stavba_soucasti_prava_stavby, index) => (
                  <strong key={index}>
                    LV {stavba_soucasti_prava_stavby?.cislo_tel}
                  </strong>
                )
              )}
            </div>
          </div>
        )}
        {notEmpty(PARCELA?.JEDNOTKY?.JEDNOTKA) && (
          <>
            <div className="mb-50"></div>
            <div className="row">
              <div className="col-xs-2">
                <i>
                  Č.p./
                  <br />
                  Č.jednotky
                </i>
              </div>
              <div className="col-xs-2 verticalBottom">
                <i>Způsob využití</i>
              </div>
              <div className="col-xs-1 verticalBottom">
                <i>na LV</i>
              </div>
              <div className="col-xs-2 verticalBottom">
                <i>Typ jednotky</i>
              </div>
              <div className="col-xs-3 verticalBottom text-right">
                <i>Podíl na společných částech domu a pozemku</i>
              </div>
              <div className="col-xs-2 text-right flex-right verticalBottom">
                <i>Podíl na jednotce</i>
              </div>
            </div>
            <hr className="mt-0 mb-0" />
          </>
        )}

        {safeMap(PARCELA?.JEDNOTKY?.JEDNOTKA, (JEDNOTKA, index) => (
          <div key={index}>
            <div key={index} className="row bold">
              <div className="col-xs-2">
                <CuzkLvHtmlJednotkaIdentFragment
                  noLabel
                  jednotkaIdent={JEDNOTKA.CISLO_JEDNOTKY.jednotka}
                />
              </div>
              <div className="col-xs-2">{JEDNOTKA?.JED_ZP_VYUZITI}</div>
              <div className="col-xs-1">
                {naLvTitle(JEDNOTKA?.NA_LV?.cislo_tel)}
              </div>
              <div className="col-xs-2">{JEDNOTKA.TYP_JEDNOTKY?._value}</div>
              <div className="col-xs-3 text-right">
                <CuzkLvHtmlPodilFragment
                  active={props.active}
                  podil={JEDNOTKA.PODIL_BA}
                />
              </div>
              <div className="col-xs-2 text-right"></div>
            </div>
            <div className="row">
              <div className="col-xs-3 text-right">
                <i>Spoluvlastníci</i>
              </div>
              <div className="col-xs-9 pl-50">
                {safeMap(JEDNOTKA.VLASTNICTVI, (VLASTNICTVI, index) => (
                  <div className="row bold" key={index}>
                    <div className="col-xs-9">
                      <CuzkLvHtmlIdentifikatorFragment
                        active={props.active}
                        key={index}
                        opravSubjekt={VLASTNICTVI.OPSUB_IDENT}
                        vlaIdent={VLASTNICTVI?.VLA_IDENT?.oprav_subjekt}
                      />
                      {VLASTNICTVI.OPSUB_IDENT ? '; ' : ''}
                      <CuzkLvHtmlOpravSubjektFragment
                        active={props.active}
                        opravSubjekt={VLASTNICTVI.OPSUB_NAZEV}
                      />
                    </div>
                    <div className="col-xs-3 text-right">
                      <CuzkLvHtmlPodilFragment
                        key={index}
                        active={props.active}
                        podil={VLASTNICTVI.PODIL}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </>
    )
  }
