import axios from 'axios'
import { Feature, GeoJsonProperties, Polygon } from 'geojson'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { useAppSelector } from '../../../redux/hooks'
import { requestGetParcelaIdPath } from '../../utils/paths'
import { useFetchPropertyDetail } from '../../utils/useFetchPropertyDetail'
import { CadastreMapDetailDrawerFetchError } from '../CadastreMapDetailDrawerFetchError/CadastreMapDetailDrawerFetchError'
import { DetailDrawerPropertyContent } from '../DetailDrawerPropertyContent/DetailDrawerPropertyContent'
import { DetailDrawerPropertyImage } from '../DetailDrawerPropertyImage/DetailDrawerPropertyImage'
import { DetailDrawerPropertySkeleton } from '../DetailDrawerPropertySkeleton/DetailDrawerPropertySkeleton'
import { DetailDrawerPropertyTitle } from '../DetailDrawerPropertyTitle/DetailDrawerPropertyTitle'

export interface DetailDrawerAreaProps {
  cuzkData?: Feature<Polygon | null, GeoJsonProperties>
}

export const DetailDrawerArea: FunctionComponent<DetailDrawerAreaProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)
    const [error, setError] = useState(false)
    const [propertyId, setPropertyId] = useState(null)

    useFetchPropertyDetail(propertyId || '', PROPERTY_TYPES.AREA)

    const getAreaIdByRuianData = useCallback(async () => {
      try {
        const ruianData = props.cuzkData?.properties
        const response = await axios.post(requestGetParcelaIdPath(), {
          token,
          katastralniUzemiKod: ruianData?.katastralniuzemi,
          kmenoveCislo: ruianData?.kmenovecislo,
          pododdeleniCisla: ruianData?.poddelenicisla,
          druhCislovaniKod: ruianData?.druhcislovanikod,
        })
        if (response.data) setPropertyId(response.data)
        else setError(true)
      } catch (error) {
        setError(true)
      }
    }, [props.cuzkData, token])

    useEffect(() => {
      if (props.cuzkData && propertyId === null) getAreaIdByRuianData()
    }, [getAreaIdByRuianData, propertyId, props.cuzkData])

    if (error) return <CadastreMapDetailDrawerFetchError />
    if (!props.cuzkData?.geometry || !propertyId)
      return <DetailDrawerPropertySkeleton />

    return (
      <>
        <DetailDrawerPropertyTitle
          propertyId={propertyId}
          propertyType={PROPERTY_TYPES.AREA}
        />

        <DetailDrawerPropertyImage
          propertyId={propertyId}
          propertyType={PROPERTY_TYPES.AREA}
          rings={props.cuzkData?.geometry?.coordinates}
        />

        <DetailDrawerPropertyContent propertyId={propertyId} />
      </>
    )
  }
