import { Button, Checkbox, Form, Input, Select } from 'antd'
import React, { FunctionComponent, useEffect, useMemo } from 'react'

import { useAllSenderAddressesQuery } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { itemRequired, onlyNumbers } from '../../utils/formHelpers'
import { useToggleState } from '../../utils/useToggleState'

export const PostServiceSenderCreateFormFragment: FunctionComponent = () => {
  const form = Form.useFormInstance()
  const tenantId = useAppSelector(state => state.myProfile.tenantId)
  const [showMore, toggleShowMore, openMore] = useToggleState()

  const [queryResult] = useAllSenderAddressesQuery({
    variables: { accountId: tenantId },
  })

  useEffect(() => {
    if (
      !queryResult.fetching &&
      !queryResult.data?.allSenderAddresses?.nodes?.length
    ) {
      openMore()
    }
  }, [
    openMore,
    queryResult.data?.allSenderAddresses?.nodes?.length,
    queryResult.fetching,
  ])

  const savedAddresses = useMemo(() => {
    if (!queryResult.data?.allSenderAddresses?.nodes?.length) return []
    return queryResult.data.allSenderAddresses.nodes.map(address => {
      return {
        id: address?.id,
        senderName: address?.senderName,
        street: {
          streetName: address?.street,
          orientationNumber: address?.orientationNumber,
          houseNumber: address?.houseNumber,
        },
        city: address?.city,
        zipCode: address?.zipCode,
        templateName: address?.templateName,
      }
    })
  }, [queryResult.data?.allSenderAddresses?.nodes])

  const handleAddressSelect = (selectedId: string) => {
    const selectedAddress = savedAddresses.find(
      address => address.id === selectedId
    )
    form.setFieldsValue(selectedAddress)
  }

  return (
    <>
      <Form.Item
        label="Uložené adresy"
        name="savedAddresses"
        className={!showMore ? '!mb-0' : ''}
      >
        <Select
          placeholder={
            savedAddresses.length
              ? 'Vyberte uloženou adresu'
              : 'Nemáte žádnou uloženou adresu'
          }
          onChange={handleAddressSelect}
          disabled={savedAddresses.length === 0}
        >
          {savedAddresses.map(address => {
            const title = `${
              (address?.templateName && address.senderName) || ''
            } - ${address.street.streetName || ''} ${
              address.street.houseNumber ||
              address.street.orientationNumber ||
              ''
            }, ${address.city || ''}, ${address.zipCode || ''}`

            return (
              <Select.Option value={address.id} key={address.id}>
                {address.templateName || address.senderName || ''}{' '}
                <span className="text-gray-400" title={title}>
                  {title}
                </span>
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>

      {!showMore && (
        <Form.Item label colon={false} className="text-right">
          <Button type="link" size="small" onClick={toggleShowMore}>
            Zadat novou adresu
          </Button>
        </Form.Item>
      )}
      <Form.Item noStyle hidden={!showMore}>
        <Form.Item
          label="Jméno odesílatele"
          name="senderName"
          rules={itemRequired('Název odesílatele je povinný')}
        >
          <Input placeholder="Jméno/název odesílatele" />
        </Form.Item>
        <Form.Item label="Ulice" required>
          <div className="flex">
            <Form.Item
              name={['street', 'streetName']}
              noStyle
              rules={itemRequired('Uveďte název ulice')}
            >
              <Input placeholder="Název ulice" style={{ marginRight: '8px' }} />
            </Form.Item>

            <Form.Item
              name={['street', 'houseNumber']}
              noStyle
              rules={[
                {
                  validator: async (_, value) => {
                    const orientationNumber = form.getFieldValue([
                      'street',
                      'orientationNumber',
                    ])
                    if (!value && !orientationNumber) {
                      throw new Error('Zadejte číslo popisné či orientační')
                    }
                  },
                },
              ]}
            >
              <Input placeholder="ČP" style={{ width: '25%' }} />
            </Form.Item>

            <div className="self-center mx-1 text-gray-400">{'/'}</div>

            <Form.Item name={['street', 'orientationNumber']} noStyle>
              <Input placeholder="ČO" style={{ width: '25%' }} />
            </Form.Item>
          </div>
        </Form.Item>
        <Form.Item
          label="Obec"
          name="city"
          rules={itemRequired('Zadejte název obce')}
        >
          <Input placeholder="Obec" />
        </Form.Item>
        <Form.Item
          label="PSČ"
          name="zipCode"
          rules={[
            {
              required: true,
              message: 'PSČ je povinné',
            },
            {
              validator: async (_, value) => {
                if (value.length !== 5) {
                  throw new Error()
                }
              },
              message: 'PSČ musí obsahovat právě 5 číslic',
            },
          ]}
          normalize={onlyNumbers}
        >
          <Input placeholder="PSČ" />
        </Form.Item>
        <Form.Item
          name="saveAddress"
          valuePropName="checked"
          label
          colon={false}
        >
          <Checkbox>Uložit adresu</Checkbox>
        </Form.Item>

        <Form.Item
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.saveAddress !== currentValues.saveAddress
          }
          noStyle
        >
          {({ getFieldsValue }) => {
            const fieldsValue = getFieldsValue()

            if (fieldsValue?.saveAddress) {
              form.setFieldValue('templateName', fieldsValue.senderName || '')

              return (
                <Form.Item
                  label="Název nové adresy"
                  name="templateName"
                  rules={itemRequired(
                    'Zadejte název nově ukládané adresy odesílatele'
                  )}
                >
                  <Input autoFocus placeholder="Název nově ukládané adresy" />
                </Form.Item>
              )
            }
            return null
          }}
        </Form.Item>
      </Form.Item>
    </>
  )
}
