import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { PROPERTY_TYPES } from '../../common/drmTypes'
import { setPropertyDrawer } from '../../redux/drawerSlice'

export const useSetAreaDrawer = () => {
  const dispatch = useDispatch()

  return useCallback(
    (id: string) =>
      dispatch(setPropertyDrawer({ id, propertyType: PROPERTY_TYPES.AREA })),
    [dispatch]
  )
}

export const useSetBuildingDrawer = () => {
  const dispatch = useDispatch()

  return useCallback(
    (id: string) =>
      dispatch(
        setPropertyDrawer({ id, propertyType: PROPERTY_TYPES.BUILDING })
      ),
    [dispatch]
  )
}

export const useSetUnitDrawer = () => {
  const dispatch = useDispatch()

  return useCallback(
    (id: string) =>
      dispatch(setPropertyDrawer({ id, propertyType: PROPERTY_TYPES.UNIT })),
    [dispatch]
  )
}

export const useSetPropertyDrawer = () => {
  const dispatch = useDispatch()

  return useCallback(
    (id: string, propertyType: PROPERTY_TYPES) =>
      dispatch(setPropertyDrawer({ id, propertyType })),
    [dispatch]
  )
}
