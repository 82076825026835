import { Document, Font, Page, PDFViewer } from '@react-pdf/renderer'
import { Button } from 'antd'
import React, { FunctionComponent } from 'react'
import Html from 'react-pdf-html'

import emithaScript from '../../../common/fonts/Emitha-Script.ttf'
import openSansBold from '../../../common/fonts/OpenSans-Bold.ttf'
import openSansItalic from '../../../common/fonts/OpenSans-Italic.ttf'
import openSans from '../../../common/fonts/OpenSans-Regular.ttf'
import { pdfStyles } from '../../../common/pdfHelpers'
import { styleSupSub } from '../../../common/postServicePdf'
import { Fade } from '../Fade/Fade'
export interface PostServicePdfViewerProps {
  isPdfActive: boolean
  handleUpdatePdf: () => void
  pdfData: string
}

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: openSans },
    { src: openSansBold, fontWeight: 800 },
    { src: openSansItalic, fontStyle: 'italic' },
  ],
})
Font.register({
  family: 'emitha-script',
  fonts: [{ src: emithaScript }],
})

export const PostServicePdfViewer: FunctionComponent<PostServicePdfViewerProps> =
  props => {
    return (
      <>
        <Fade
          show={props.isPdfActive}
          className="w-full h-full absolute bg-white/75 flex justify-center items-center"
        >
          <Button onClick={props.handleUpdatePdf} type="primary">
            Aktualizovat náhled PDF
          </Button>
        </Fade>
        <PDFViewer
          className="h-full min-h-[400px] border-b border-gray-300"
          width="100%"
        >
          <Document>
            <Page style={pdfStyles.postServiceFrontPage} size="A4">
              <Html resetStyles stylesheet={styleSupSub}>
                {props.pdfData}
              </Html>
            </Page>
          </Document>
        </PDFViewer>
      </>
    )
  }
