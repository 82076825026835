import { SyncOutlined } from '@ant-design/icons'
import { Tag, Tooltip } from 'antd'
import React, { FunctionComponent } from 'react'

import { CeeTableItem } from '../../../common/ceeTypes'
import { CeeMonitorTableCuzkStatusFoundModal } from '../CeeMonitorTableCuzkStatusFoundModal/CeeMonitorTableCuzkStatusFoundModal'
import { CuzkMonitorAddSubjectModal } from '../CuzkMonitorAddSubjectModal/CuzkMonitorAddSubjectModal'
import { Min } from '../DateFormat/DateFormat'

interface CeeMonitorTableCuzkStatusCellProps {
  state: boolean | undefined
  ceeRecord: CeeTableItem
  refetch: () => void
  record?: {
    id?: string
    checkFrequency?: number
    lastCheck?: string
    found?: boolean
    cuzkOsId?: string
  }
}

export const CeeMonitorTableCuzkStatusCell: FunctionComponent<CeeMonitorTableCuzkStatusCellProps> =
  ({ state, record, ceeRecord, refetch }) => {
    if (!record?.id)
      return (
        <CuzkMonitorAddSubjectModal
          buttonProps={{
            className: '!text-xs',
            size: 'small',
            type: 'link',
          }}
          buttonText="Ověřit v ČÚZK"
          noRedirect
          refetch={refetch}
          dataSource={[
            {
              id: ceeRecord.id,
              key: 1,
              description: ceeRecord.note,
              rc: ceeRecord.rc,
              ico: ceeRecord.ico,
              firstname: ceeRecord.firstname,
              lastname: ceeRecord.lastname,
              birthDate: ceeRecord.birthDate?.toString(),
              typed: ceeRecord.typed,
              validatedCode: ceeRecord.validatedCode,
              monitoringCeeId: ceeRecord.id,
            },
          ]}
        />
      )

    switch (true) {
      case state:
        return (
          <Tooltip title="Kliknutím zobrazíte detail">
            <CeeMonitorTableCuzkStatusFoundModal id={record.cuzkOsId} />
          </Tooltip>
        )

      case state === null:
        return typeof record.checkFrequency === 'number' ? (
          <Tooltip title="Záznam nebyl prozatím zkontrolován, ke kontrole dojde v nejbližším možném termínu (zhruba do 10 minut).">
            <Tag color="blue" icon={<SyncOutlined spin />}>
              Ve frontě
            </Tag>
          </Tooltip>
        ) : (
          <Tooltip title="Kontrola byla pozastavena ještě před ověřením v registru.">
            <Tag className="!opacity-40">Pozastaveno</Tag>
          </Tooltip>
        )

      default:
        return (
          <Tooltip
            title={
              <div>
                <span>Při poslední kontrole </span>
                <Min>{record.lastCheck}</Min>
                <span> nebylo RČ evidováno v ČÚZK.</span>
              </div>
            }
          >
            <Tag>Nenalezeno</Tag>
          </Tooltip>
        )
    }
  }
