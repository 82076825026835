import React, { FunctionComponent, useMemo } from 'react'
import Timeline, {
  DateHeader,
  ReactCalendarItemRendererProps,
  TimelineHeaders,
  TimelineItemBase,
} from 'react-calendar-timeline'

import { useAppSelector } from '../../../redux/hooks'
import {
  getTimelineDatePoint,
  propertyTimelineEnd,
  propertyTimelineGroups,
  propertyTimelinePrimaryHeader,
  propertyTimelineProps,
  propertyTimelineSecondaryHeader,
  yearInMilliseconds,
} from '../../utils/propertyTimelineHelpers'
import { PropertyDetailTimelineMarkers } from '../PropertyDetailTimelineMarkers/PropertyDetailTimelineMarkers'
import { PropertyDetailTimelineTitle } from '../PropertyDetailTimelineTitle/PropertyDetailTimelineTitle'
import { PropertyTimelineProceedingItem } from '../PropertyTimelineProceedingItem/PropertyTimelineProceedingItem'
import { PropertyTimelineProceedingTitle } from '../PropertyTimelineProceedingTitle/PropertyTimelineProceedingTitle'

export interface PropertyDetailTimelineProceedingProps {
  visibleTimeStart: number
  visibleTimeEnd: number
  onTimeChange: (start: number, end: number) => void
  showAll: boolean
}

const propertyTimelineProceedingRender = (
  item: ReactCalendarItemRendererProps<TimelineItemBase<number>>
) => (
  <PropertyTimelineProceedingItem
    getItemProps={item.getItemProps}
    item={item.item}
    itemContext={item.itemContext}
  />
)

export const PropertyDetailTimelineProceeding: FunctionComponent<PropertyDetailTimelineProceedingProps> =
  props => {
    const timeline = useAppSelector(state => state.property.timeline)
    const proceedingItems = useMemo(() => {
      if (!timeline.data?.rizeni?.length) return []
      const filtered = props.showAll
        ? timeline.data.rizeni
        : timeline.data.rizeni.filter(
            proceeding =>
              getTimelineDatePoint(proceeding.datumPrijeti) >
              propertyTimelineEnd - yearInMilliseconds * 7.5
          )

      return filtered.map((rizeni, index) => {
        const start = getTimelineDatePoint(
          rizeni.datumPrijeti || rizeni.datumUkonceni
        )
        return {
          id: index,
          group: 'proceeding',
          title: <PropertyTimelineProceedingTitle rizeni={rizeni} />,
          start_time: start,
          end_time: start + yearInMilliseconds,
        }
      })
    }, [props.showAll, timeline.data?.rizeni])

    return (
      <>
        <PropertyDetailTimelineTitle title="Řízení" />
        <Timeline
          {...propertyTimelineProps}
          groups={propertyTimelineGroups.proceeding}
          items={proceedingItems}
          lineHeight={82}
          itemHeightRatio={0.9}
          visibleTimeStart={props.visibleTimeStart}
          visibleTimeEnd={props.visibleTimeEnd}
          onTimeChange={props.onTimeChange}
          itemRenderer={propertyTimelineProceedingRender}
        >
          <TimelineHeaders>
            <DateHeader {...propertyTimelineSecondaryHeader} />
            <DateHeader {...propertyTimelinePrimaryHeader} />
          </TimelineHeaders>
          <PropertyDetailTimelineMarkers />
        </Timeline>
      </>
    )
  }
