import { createSlice } from '@reduxjs/toolkit'

import { RzpWebSubjectsResponse } from '../common/rzpWebSubjectsTypes'
import handleAsyncActions, {
  AsyncState,
  initialFetchState,
  PropertyDataTypes,
} from './fetchAction'

export const rzpSubjectsSlice = createSlice({
  name: PropertyDataTypes.RZP_SUBJECTS,
  initialState: initialFetchState as AsyncState<RzpWebSubjectsResponse>,
  reducers: {},
  extraReducers: builder => {
    handleAsyncActions<RzpWebSubjectsResponse>(
      builder,
      PropertyDataTypes.RZP_SUBJECTS
    )
  },
})

export default rzpSubjectsSlice.reducer
