import { Icon } from '@iconify/react'
import { message, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/es/table/interface'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import axios from 'axios'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  CollectionType,
  DocumentCollectionItem,
} from '../../../common/documentCollectionTypes'
import {
  ProceedingDocumentCollectionItem,
  ProceedingDocumentDataFetch,
} from '../../../common/proceedingTypes'
import { useProceedingDetailDocumentCollectionTableQuery } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { checkSeznamSestav } from '../../utils/checkSeznamSestav'
import { cuzkDocumentCollectionState as state } from '../../utils/handleResponseStatus'
import { tablePaginationHiding } from '../../utils/layoutConst'
import { requestnajdiListinuRizeniPath } from '../../utils/paths'
import { CuzkDocumentPrice } from '../CuzkDocumentPrice/CuzkDocumentPrice'
import { CuzkDocumentState } from '../CuzkDocumentState/CuzkDocumentState'
import { Min } from '../DateFormat/DateFormat'
import { ProceedingDetailDocumentCollectionAction } from '../ProceedingDetailDocumentCollectionAction/ProceedingDetailDocumentCollectionAction'
import { PropertyDetailFetchError } from '../PropertyDetailFetchError/PropertyDetailFetchError'
import { TableCellLabels } from '../TableCellLabels/TableCellLabels'

export interface ProceedingDetailDocumentCollectionTableProps {
  proceedingString: string
}
const { Paragraph } = Typography
const paragraphProps = {
  className: 'lg:cursor-help',
  style: { marginBottom: 0 },
  ellipsis: {
    rows: 3,
    tooltip: true,
  },
}
const tableId = 'proceedingDocumentCollection-table'

export const ProceedingDetailDocumentCollectionTable: FunctionComponent<ProceedingDetailDocumentCollectionTableProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)
    const tenantId = useAppSelector(state => state.myProfile.tenantId)

    const [dataSource, setDataSource] = useState<
      ProceedingDocumentCollectionItem[]
    >([])

    const [cuzkResult, setCuzkResult] = useState<ProceedingDocumentDataFetch>({
      data: undefined,
      fetching: true,
      error: false,
    })

    const [queryResult, refetch] =
      useProceedingDetailDocumentCollectionTableQuery({
        variables: {
          accountId: tenantId,
          rizeni: props.proceedingString,
        },
      })

    const storedDocuments =
      !!queryResult.data?.allCuzkDocumentCollections?.nodes?.length &&
      (queryResult.data.allCuzkDocumentCollections
        .nodes as DocumentCollectionItem[])

    const handleUnavailableDocuments = useCallback(
      (documents: ProceedingDocumentCollectionItem[], info: string) => {
        if (!documents.length && info?.startsWith('https://')) {
          documents.push({
            nazev:
              'Požadovaná listina ze Sbírky listin není pro poskytnutí dálkovým přístupem k dispozici.',
            id: info,
            stav: state.naVyzadani,
            url: info,
          })
        }
        return documents
      },
      []
    )

    const fetchDataFromCuzk = useCallback(async () => {
      try {
        const response = await axios.post(requestnajdiListinuRizeniPath(), {
          token,
          tenantId,
          proceeding: props.proceedingString,
        })

        const documents = response.data?.ListinaList ?? []
        return handleUnavailableDocuments(documents, response.data?.informace)
      } catch (error) {
        message.error(
          'Nepodařilo se načíst informace k listinám ze serveru ČÚZK.'
        )
        throw error
      }
    }, [handleUnavailableDocuments, props.proceedingString, tenantId, token])

    const synchronizeDocuments = useCallback(
      (documents: ProceedingDocumentCollectionItem[]) => {
        if (!storedDocuments) return documents
        const documentsMap = new Map(
          storedDocuments.map(doc => [doc.cuzkDocumentId, doc])
        )

        return documents.map(doc => ({
          ...doc,
          ...documentsMap.get(doc.id || ''),
        }))
      },
      [storedDocuments]
    )

    const refreshData = useCallback(() => {
      if (!cuzkResult.data?.length || !storedDocuments) return
      const synchronizedDocuments = synchronizeDocuments(cuzkResult.data)
      setDataSource(synchronizedDocuments)
    }, [cuzkResult.data, storedDocuments, synchronizeDocuments])

    const checkItemsState = useCallback(async () => {
      if (queryResult.fetching || !storedDocuments) return

      const itemsToCheck = storedDocuments.filter(item =>
        [state.ceka, state.podepisujeSe, state.vytvariSe].includes(item.stav)
      )

      let shouldRefetch = false
      for (const item of itemsToCheck) {
        const checked = await checkSeznamSestav({
          cuzkCollectionId: item.cuzkCollectionId,
          id: item.id,
          stav: item.stav,
          tenantId: tenantId,
          collectionType: CollectionType.SL,
        })

        if (checked) {
          shouldRefetch = true
        }
      }

      if (shouldRefetch) {
        refetch()
        return true
      }
      return false
    }, [storedDocuments, queryResult.fetching, refetch, tenantId])

    useEffect(() => {
      const initFetchAndSync = async () => {
        if (
          cuzkResult.error ||
          !!cuzkResult.data?.length ||
          queryResult.fetching
        )
          return

        setCuzkResult(prev => ({ ...prev, fetching: true }))
        try {
          const apiDocuments = await fetchDataFromCuzk()
          const synchronizedDocuments = synchronizeDocuments(apiDocuments)
          setDataSource(synchronizedDocuments)
          setCuzkResult(prev => ({ ...prev, fetching: false, error: false }))
        } catch (error) {
          setCuzkResult({ data: [], fetching: false, error: true })
        }
      }

      initFetchAndSync()
    }, [
      cuzkResult.data,
      cuzkResult.error,
      fetchDataFromCuzk,
      queryResult.fetching,
      synchronizeDocuments,
    ])

    useEffect(() => {
      let intervalId: NodeJS.Timer | null = null

      if (
        storedDocuments &&
        storedDocuments?.some(item =>
          [state.ceka, state.podepisujeSe, state.vytvariSe].includes(item.stav)
        )
      ) {
        const period = 2000
        intervalId = setInterval(checkItemsState, period)
      }

      return () => {
        if (intervalId) {
          clearInterval(intervalId)
          refreshData()
        }
      }
    }, [storedDocuments, checkItemsState, refreshData])

    const columns = useMemo<ColumnsType<ProceedingDocumentCollectionItem>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Vytvořeno',
          dataIndex: 'createdAt',
          responsive: storedDocuments ? undefined : ['' as Breakpoint],
          render: (createdAt, record) =>
            record.datumvytvoreni && (
              <Min>
                {record.selected && record.datumvytvoreni
                  ? record.datumvytvoreni
                  : createdAt}
              </Min>
            ),
        },
        {
          title: 'Štítek',
          dataIndex: 'labels',
          responsive: storedDocuments ? undefined : ['' as Breakpoint],
          render: (labels, record) =>
            record.pocetstran && (
              <TableCellLabels
                labels={labels}
                labelEntityType="cuzkDocumentCollectionId"
                recordId={record.id}
                refetch={refetch}
                modalTitle={record.rizeni}
              />
            ),
        },
        {
          title: 'Název přiložené listiny',
          dataIndex: 'nazev',
          render: (nazev, record) => (
            <Paragraph {...paragraphProps}>
              {record.stav === state.naVyzadani && (
                <Icon
                  className="inline-block h-4 w-4 mr-2 -mb-px"
                  icon="circum:file-off"
                />
              )}
              {nazev}
            </Paragraph>
          ),
        },
        {
          title: 'Cena',
          dataIndex: 'cena',
          className: 'w-44',
          render: (cena, record) => (
            <CuzkDocumentPrice state={record.stav} price={cena} />
          ),
        },
        {
          title: 'Strany',
          dataIndex: 'pocetstran',
          responsive: storedDocuments ? undefined : ['' as Breakpoint],
          render: pocetstran =>
            pocetstran || <span className="text-gray-400">---</span>,
        },
        {
          title: 'Stav',
          dataIndex: 'stav',
          render: stav => <CuzkDocumentState state={stav} />,
        },
        {
          title: 'Akce',
          dataIndex: 'actions',
          className: '!text-right items-center',
          render: (_, record) => (
            <ProceedingDetailDocumentCollectionAction
              proceedingString={props.proceedingString}
              record={record}
              refetch={refetch}
            />
          ),
        },
      ],
      [props.proceedingString, refetch, storedDocuments]
    )

    const locale = useMemo(
      () => ({
        emptyText: cuzkResult.error ? (
          <PropertyDetailFetchError
            message="Nelze načíst listiny řízení"
            description="Selhala komunikace se serverem ČÚZK"
          />
        ) : (
          !cuzkResult.fetching && 'K řízení nebyly nalezeny žádné listiny.'
        ),
      }),
      [cuzkResult.error, cuzkResult.fetching]
    )

    return (
      <Table
        id={tableId}
        rowKey="id"
        size="small"
        dataSource={[...dataSource]}
        columns={columns}
        loading={cuzkResult.fetching}
        locale={locale}
        pagination={tablePaginationHiding}
      />
    )
  }
