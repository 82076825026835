import { Button, ButtonProps, message, Popconfirm } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import { BASE_URL } from '../../../../ini.json'
import { CollectionType } from '../../../common/documentCollectionTypes'
import { useAppSelector } from '../../../redux/hooks'

export interface CuzkDocumentCollectionCancelButtonProps {
  id: string
  refetch: () => void
  collectionType?: CollectionType
  buttonProps?: ButtonProps
}

const okButtonProps = { danger: true }
const title = (
  <>
    <div>Požadavek bude stornován, nebude vám</div>
    <div>účtován poplatek ČÚZK a listina bude</div>
    <div>smazána z tohoto seznamu.</div>
  </>
)

export const CuzkDocumentCollectionCancelButton: FunctionComponent<CuzkDocumentCollectionCancelButtonProps> =
  props => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)

    const cancelDocument = useCallback(async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/cuzk/smazSestavu`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            tenantId: tenantId,
            id: props.id,
            collectionType: props.collectionType,
          }),
        })

        if (response.status === 200) {
          message.success('Požadavek byl odebrán.')
          return props.refetch()
        }
        if (response.status === 401) {
          message.success('Nejste přihlášen.')
          return props.refetch()
        }
        return false
      } catch (error) {
        message.error(
          'Nepodařilo se odebrat požadavek, zkuste to prosím později.'
        )
      }
      return false
    }, [props, tenantId])

    return (
      <Popconfirm
        okText="Odebrat"
        cancelText="Zpět"
        okButtonProps={okButtonProps}
        title={title}
        onConfirm={cancelDocument}
      >
        <Button type="primary" danger ghost {...props.buttonProps}>
          Zrušit
        </Button>
      </Popconfirm>
    )
  }
