import React, { FunctionComponent } from 'react'

export interface PriceEstimateLayoutLvDataRowProps {
  title: string
  children: React.ReactNode
  visible?: boolean
  condition?: boolean
  suffix?: string
}

export const PriceEstimateLayoutLvDataRow: FunctionComponent<PriceEstimateLayoutLvDataRowProps> =
  props => {
    if (
      typeof props.condition === 'boolean'
        ? props.condition
        : !!props.children || props.visible
    )
      return (
        <div className="flex">
          <div className="w-1/3">{props.title}:</div>
          <div className="w-2/3 font-semibold">
            {props.children} {props.suffix}
          </div>
        </div>
      )
    return null
  }
