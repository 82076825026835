import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { CeeMonitorItem } from '../CeeMonitorTableExpandedRow/CeeMonitorTableExpandedRow'
import { Money } from '../Money/Money'

export interface CeeMonitorTableAmountSummaryProps {
  ceeData?: CeeMonitorItem[]
}

export const CeeMonitorTableAmountSummary: FunctionComponent<CeeMonitorTableAmountSummaryProps> =
  props => {
    const counts = useMemo(() => {
      if (!props.ceeData) return { totalAmount: 0, detailsCount: 0 }
      let total = 0

      const detailsCount = props.ceeData.filter(execution => {
        const amount = Number(execution?.ceeDetails?.nodes?.[0]?.castka)
        !!amount && (total += amount)
        return !!amount
      }).length

      return { totalAmount: total, detailsCount }
    }, [props.ceeData])

    if (!props.ceeData)
      return (
        <Tooltip
          className="text-gray-400 cursor-help"
          title="Pro výpočet ceny musíte stáhnout detaily exekucí"
        >
          <span>
            ---
            <QuestionCircleOutlined className="ml-2" />
          </span>
        </Tooltip>
      )

    if (counts.detailsCount === props.ceeData.length)
      return (
        <div>
          <Money amount={counts.totalAmount} symbol="Kč" />
        </div>
      )

    return (
      <Tooltip
        className="text-gray-400 cursor-help"
        title="Nemáte staženy všechny detaily exekucí. Celkovou výši proto nelze vypočítat přesně."
      >
        {'>'} <Money amount={counts.totalAmount} symbol="Kč" />
        <QuestionCircleOutlined className="ml-2" />
      </Tooltip>
    )
  }
