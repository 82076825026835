import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { fetchDataInlineError } from '../DateFormat/DateFormat'

export interface MoneyProps {
  amount?: string | number | null
  symbol?: true | JSX.Element | string | null
  className?: string
  noData?: string | JSX.Element | null
  error?: string | JSX.Element | null
  grayscale?: boolean
  decimalNumbers?: number
}

export const formatCurrencyNumber = (
  amount?: number | string | null,
  decimal?: number
) => {
  let fixedAmount = ''

  if (typeof amount === 'number') {
    fixedAmount = amount.toFixed(typeof decimal === 'number' ? decimal : 2)
  }
  if (typeof amount === 'string') {
    fixedAmount = parseInt(amount).toFixed(
      typeof decimal === 'number' ? decimal : 2
    )
  }
  return fixedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',')
}

export const Money: FunctionComponent<MoneyProps> = props => {
  const formatNumber = useCallback(
    (num?: string | number | null) => {
      if (typeof num === 'number') {
        num = num.toFixed(
          typeof props.decimalNumbers === 'number' ? props.decimalNumbers : 2
        )
      } else if (typeof num === 'string') {
        num = parseFloat(num).toFixed(
          typeof props.decimalNumbers === 'number' ? props.decimalNumbers : 2
        )
      } else num = ''

      return num.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',')
    },
    [props.decimalNumbers]
  )

  const dateResult = useMemo(
    () =>
      props.amount && (
        <span>
          <span>{formatNumber(props.amount)}</span>{' '}
          <span className={props.grayscale ? 'text-gray-400' : ''}>
            {props.symbol}
          </span>
        </span>
      ),
    [props.amount, props.grayscale, props.symbol, formatNumber]
  )
  const amount = useMemo(() => {
    if (props.amount === 0) return dateResult
    if (!props.amount && !props.noData)
      return props.error || fetchDataInlineError
    if (!props.amount) return props.noData || ''
    if (typeof props.amount === 'number' || typeof props.amount === 'string')
      return dateResult
    return fetchDataInlineError
  }, [dateResult, props.error, props.noData, props.amount])

  if (!props.amount && props.noData === null) return null
  return <span className={props.className}>{amount}</span>
}
