import { Button, Form, Input } from 'antd'
import React, { FunctionComponent } from 'react'

import { postServiceFormHelp } from '../../utils/cuzkMonitorRcFormHelp'
import { useToggleState } from '../../utils/useToggleState'
import { Fade } from '../Fade/Fade'

interface PostServiceAddRecipientFormVariablesFragmentProps {
  showHelp: boolean
}

export const PostServiceAddRecipientFormVariablesFragment: FunctionComponent<PostServiceAddRecipientFormVariablesFragmentProps> =
  ({ showHelp }) => {
    const [showVariables, toggleVariables] = useToggleState()

    return (
      <>
        <Form.Item label="Proměnná 1" name="variable1">
          <Input placeholder="Proměnná #1" />
        </Form.Item>

        <Fade show={showVariables}>
          {[2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => (
            <Form.Item
              key={num}
              label={`Proměnná ${num}`}
              name={`variable${num}`}
            >
              <Input placeholder={`Proměnná #${num}`} />
            </Form.Item>
          ))}
        </Fade>

        <Form.Item
          label
          colon={false}
          className="!-mt-4"
          help={(showHelp && postServiceFormHelp.variables) || undefined}
        >
          <Button type="link" size="small" onClick={toggleVariables}>
            {showVariables ? 'Skrýt proměnné' : 'Zobrazit zbylé proměnné'}
          </Button>
        </Form.Item>
      </>
    )
  }
