export const companyKeywords = [
  'a.s.',
  'r.o.',
  'v.o.s.',
  'o.p.s.',
  'k.s.',
  'z.s.',
  'z.ú.',
  'k.s.',
  's.r.o.',
  's.p.',
  'spol.',
  'společnost',
  'organizace',
  'sdružení',
  'federace',
  'unie',
  'bytové',
  'družstvo',
  'společenství',
  'ípodnik',
  'vlikvidaci',
  'konkursu',
  'inc.',
  'ltd.',
  'gmbh',
  'corp.',
  'international',
  'enterprises',
  'industries',
  'global',
  'holdings',
]

export const isCompanyName = (name: string) => {
  const cleanName = name.toLowerCase().replace(/[\s]/g, '')
  const hasSpecialCharsOrNumbers = /[&,@,":§°#~+?%*<>(){}[]!€$\d]/.test(
    cleanName
  )
  const hasCompanyKeyword = companyKeywords.some(keyword =>
    cleanName.includes(keyword)
  )
  return hasCompanyKeyword || hasSpecialCharsOrNumbers
}
