import { Button, Checkbox } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import { CadastreMapInsolvencyFilterFormOutput } from '../../../../../common/cadastreMapInsolvencyTypes'
import { CadastreMapInsolvencyLayerControlModalButton } from '../../../CadastreMapInsolvencyLayerControlModalButton/CadastreMapInsolvencyLayerControlModalButton'
import { Overlay, OverlayProps } from '../CadastreMapOverlay/CadastreMapOverlay'

export interface CadastreMapInsolvencyLayerControlProps extends OverlayProps {
  enabled: boolean
  onEnabledChange: React.Dispatch<React.SetStateAction<boolean>>
  onFilterParams: (where: CadastreMapInsolvencyFilterFormOutput) => void
}

export const CadastreMapInsolvencyLayerControl: FunctionComponent<CadastreMapInsolvencyLayerControlProps> =
  props => {
    const toggleEnabled = useCallback(
      () => props.onEnabledChange(prev => !prev),
      [props]
    )

    return (
      <Overlay position={props.position}>
        <div className="flex space-x-2">
          <Button
            onClick={toggleEnabled}
            className="hover:!bg-white"
            title="Zobrazit vybrané budovy"
            icon={<Checkbox checked={props.enabled} rootClassName="!mr-2" />}
          >
            Insolvence
          </Button>

          <CadastreMapInsolvencyLayerControlModalButton
            onFilterParams={props.onFilterParams}
          />
        </div>
      </Overlay>
    )
  }
