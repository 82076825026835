import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  Maybe,
  usePostserviceRecipientTableQuery,
} from '../../../graphql/generated'
import { defaultPagination } from '../../utils/layoutConst'
import { Min } from '../DateFormat/DateFormat'
import { PostServiceAddRecipientModalButton } from '../PostServiceAddRecipientModalButton/PostServiceAddRecipientModalButton'
import { PostServiceImportRecipientModalButton } from '../PostServiceImportRecipientModalButton/PostServiceImportRecipientModalButton'
import { PostServiceRecipientListDeleteButton } from '../PostServiceRecipientListDeleteButton/PostServiceRecipientListDeleteButton'

export interface PostServiceRecipient {
  id: string
  createdAt: Date
  companyName?: string
  firstname: string
  lastname: string
  callName?: string
  gender?: 'f' | 'm' | 0
  street: string
  houseNumber: string
  orientationNumber?: string
  city: string
  country: string
  senderName?: string
  zipCode: string
  uniquePersonKey?: string
  addressId?: string
  variable1?: string
  variable2?: string
  variable3?: string
  variable4?: string
  variable5?: string
  variable6?: string
  variable7?: string
  variable8?: string
  variable9?: string
  variable10?: string
}

export interface PostServiceRecipientListTableExpandedRow {
  listId: string
  listName: string
  refetch: () => void
}

const buttonProps = { type: 'primary' as const, ghost: true }

export const PostServiceRecipientListTableExpandedRow: FunctionComponent<PostServiceRecipientListTableExpandedRow> =
  props => {
    const [data, setData] = useState<PostServiceRecipient[] | undefined>()

    const [queryResult, refetch] = usePostserviceRecipientTableQuery({
      variables: { id: props.listId },
    })

    useEffect(() => {
      if (
        queryResult.data?.postserviceListById
          ?.postserviceRecipientsByPostserviceListId?.nodes?.length
      ) {
        setData(
          queryResult.data?.postserviceListById
            ?.postserviceRecipientsByPostserviceListId
            ?.nodes as PostServiceRecipient[]
        )
      }
    }, [
      queryResult.data?.postserviceListById
        ?.postserviceRecipientsByPostserviceListId?.nodes,
    ])

    const showVariable = useMemo(() => {
      const variables: { [key: string]: Breakpoint[] | undefined } = {}
      for (let i = 2; i <= 10; i++) {
        variables[`variable${i}`] = data?.some(
          item => item?.[`variable${i}` as keyof Maybe<PostServiceRecipient>]
        )
          ? undefined
          : ['' as Breakpoint]
      }
      return variables
    }, [data])

    const reexecuteQuery = useCallback(() => {
      refetch()
      props.refetch()
    }, [props, refetch])

    const columns = useMemo<ColumnsType<PostServiceRecipient>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Vytvořeno',
          dataIndex: 'createdAt',
          key: 'createdAt',
          sorter: {
            compare: (a, b) =>
              a.createdAt.toString().localeCompare(b.createdAt.toString()),
          },
          render: createdAt => <Min>{createdAt}</Min>,
        },
        {
          title: 'Společnost',
          dataIndex: 'companyName',
          key: 'companyName',
          sorter: {
            compare: (a, b) =>
              a.companyName && b.companyName
                ? a.companyName.localeCompare(b.companyName)
                : -1,
          },
        },
        {
          title: 'Jméno',
          dataIndex: 'recipientName',
          key: 'recipientName',
          sorter: {
            compare: (a, b) => a.lastname.localeCompare(b.lastname),
          },
          render: (_, record) => record?.firstname + ' ' + record?.lastname,
        },
        {
          title: 'Adresa',
          dataIndex: 'address',
          key: 'address',
          sorter: {
            compare: (a, b) => a.street.localeCompare(b.street),
          },
          render: (_, record) => {
            const streetComponents = [
              record.street,
              record.houseNumber,
              record.orientationNumber ? '/' + record.orientationNumber : '',
            ]
            const street = streetComponents.join(' ').trim()
            const fullAddress =
              street +
              ' ' +
              record?.city +
              ' ' +
              record?.zipCode +
              ' ' +
              record?.country
            return fullAddress
          },
        },
        {
          title: 'Prom #1',
          dataIndex: 'variable1',
          key: 'variable1',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
          render: (_, record) => <>{record?.variable1}</>,
        },
        {
          title: 'Prom #2',
          responsive: showVariable.variable2,
          dataIndex: 'variable2',
          key: 'variable2',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
        },
        {
          title: 'Prom #3',
          responsive: showVariable.variable3,
          dataIndex: 'variable3',
          key: 'variable3',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
        },
        {
          title: 'Prom #4',
          responsive: showVariable.variable4,
          dataIndex: 'variable4',
          key: 'variable4',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
        },
        {
          title: 'Prom #5',
          responsive: showVariable.variable5,
          dataIndex: 'variable5',
          key: 'typ5',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
        },
        {
          title: 'Prom #6',
          responsive: showVariable.variable6,
          dataIndex: 'variable6',
          key: 'typ6',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
        },
        {
          title: 'Prom #7',
          responsive: showVariable.variable7,
          dataIndex: 'variable7',
          key: 'typ7',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
        },
        {
          title: 'Prom #8',
          responsive: showVariable.variable8,
          dataIndex: 'variable8',
          key: 'typ8',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
        },
        {
          title: 'Prom #9',
          responsive: showVariable.variable9,
          dataIndex: 'variable9',
          key: 'typ9',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
        },
        {
          title: 'Prom #10',
          responsive: showVariable.variable10,
          dataIndex: 'variable10',
          key: 'typ10',
          sorter: {
            compare: (a, b) =>
              a.variable1 && b.variable1
                ? a.variable1.localeCompare(b.variable1)
                : -1,
          },
        },
        {
          title: 'Akce',
          dataIndex: 'action',
          key: 'action',
          className: 'w-36',
          render: (_, record) => (
            <div className="flex space-x-2">
              <PostServiceAddRecipientModalButton
                listId={props.listId}
                listName={props.listName}
                record={record}
                reexecuteQuery={reexecuteQuery}
                buttonProps={{
                  type: 'primary',
                  size: 'small',
                  ghost: true,
                }}
                buttonText="Editovat"
              />
              <PostServiceRecipientListDeleteButton
                record={record}
                reexecuteQuery={reexecuteQuery}
              />
            </div>
          ),
        },
      ],
      [props.listId, props.listName, reexecuteQuery, showVariable]
    )

    return (
      <div className="mb-6 text-right">
        <Table
          pagination={defaultPagination}
          dataSource={data}
          columns={columns}
          rowKey="id"
          size="small"
          rowClassName="bg-[#fafafa]"
          className="mb-6"
        />
        <div className="flex justify-end space-x-2">
          <PostServiceImportRecipientModalButton
            buttonProps={buttonProps}
            listId={props.listId}
          />
          <PostServiceAddRecipientModalButton
            listId={props.listId}
            listName={props.listName}
          />
        </div>
      </div>
    )
  }
