import { Button, message } from 'antd'
import { BaseButtonProps } from 'antd/lib/button/button'
import React, { FunctionComponent, useMemo } from 'react'

import { CeeTableItem } from '../../../common/ceeTypes'
import { useCeeMonitorTableExpandedRowQuery } from '../../../graphql/generated'
import { CeeSummaryPdf } from '../CeeSummaryPdf/CeeSummaryPdf'
import { PdfDrawerPreview } from '../PdfDrawerPreview/PdfDrawerPreview'

export interface CeeSummaryPdfButtonProps {
  buttonProps?: BaseButtonProps
  id: string
}

export const CeeSummaryPdfButton: FunctionComponent<CeeSummaryPdfButtonProps> =
  React.memo(props => {
    const [pdfDataResponse, refetch] = useCeeMonitorTableExpandedRowQuery({
      variables: { monitoringCeeId: props.id },
      pause: true,
    })

    const data = pdfDataResponse.data?.allCeeExecutions
      ?.nodes as unknown as CeeTableItem[]
    const person = data?.[0]

    const fileName = useMemo(
      () =>
        (person?.firstname || '') +
        (person?.lastname || '') +
        (person?.rc || '') +
        ' CEE_REGESTA_DATA',
      [person?.firstname, person?.lastname, person?.rc]
    )

    if (pdfDataResponse.error) {
      message.error('Nepodařilo se načíst detail lustrace')
      return (
        <Button
          title="Nepodařilo se načíst historická data"
          type="primary"
          disabled
          {...props.buttonProps}
        >
          Zobrazit PDF
        </Button>
      )
    }
    if (!pdfDataResponse.data && !pdfDataResponse.fetching)
      return (
        <Button type="primary" onMouseEnter={refetch} {...props.buttonProps}>
          Zobrazit PDF
        </Button>
      )

    return (
      <PdfDrawerPreview
        buttonProps={props.buttonProps}
        drawerTitle="Náhled PDF - výsledky vyhledávání v CEE"
        fileName={fileName}
      >
        <CeeSummaryPdf data={data} />
      </PdfDrawerPreview>
    )
  })
