import { Button, Form, Input, message, Select } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useRef } from 'react'

import { Proceeding } from '../../../common/proceedingTypes'
import { useProceedingSearchBarQuery } from '../../../graphql/generated'
import { onlyNumbers } from '../../utils/formHelpers'
import {
  codeRules,
  filterOption,
  handleUnderscoreClick,
  officeCodeRules,
  serialNumberRules,
  typeOptions,
  typeRules,
  yearOptions,
  yearRules,
} from '../../utils/proceedingSearchHelpers'

export interface ProceedingSearchBarProps {
  setProceeding: (proceeding: Proceeding) => void
  initialValues?: string | null
}

interface ProceedingSearchFormValues {
  code?: string
  type?: string
  serialNumber?: string
  year?: string
  officeCode?: string
}

export const ProceedingSearchBar: FunctionComponent<ProceedingSearchBarProps> =
  props => {
    const [form] = Form.useForm<ProceedingSearchFormValues>()
    const [workplaces] = useProceedingSearchBarQuery()

    const isUpdatingFieldsRef = useRef(false)
    const isUpdatingCodeRef = useRef(false)

    const handleCodeChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const codeValue = e.target.value
        form.setFieldsValue({ code: codeValue })

        if (!isUpdatingFieldsRef.current) {
          isUpdatingFieldsRef.current = true
          const codePattern =
            /^(\p{L}{1,3})-([0-9]{1,8})\/([0-9]{4})-([0-9]{2,3})$/u
          const match = codeValue.match(codePattern)

          if (match) {
            const [, typeValue, serialNumberValue, yearValue, officeCodeValue] =
              match

            form.setFieldsValue({
              type: typeValue,
              serialNumber: serialNumberValue,
              year: yearValue,
              officeCode: officeCodeValue,
            })
          } else {
            const parts = codeValue.replaceAll(' ', '').split(/[-/]/)
            form.setFieldsValue({
              type: parts[0],
              serialNumber: parts[1],
              year: parts[2],
              officeCode: parts[3],
            })
          }
          isUpdatingFieldsRef.current = false
        }
      },
      [form]
    )

    const handleValuesChange = useCallback(
      (
        changedValues: Partial<ProceedingSearchFormValues>,
        allValues: ProceedingSearchFormValues
      ) => {
        if (isUpdatingFieldsRef.current || changedValues.code) {
          return
        }

        if (!isUpdatingCodeRef.current) {
          isUpdatingCodeRef.current = true

          const { type, serialNumber, year, officeCode } = allValues
          const newCode = `${type || '_'}-${serialNumber || '____'}/${
            year || '____'
          }-${officeCode || '___'}`

          form.setFieldsValue({ code: newCode })
          isUpdatingCodeRef.current = false
        }
      },
      [form]
    )

    const validateOnEvent = useCallback(
      (
        event?:
          | React.ClipboardEvent<HTMLInputElement>
          | React.FocusEvent<HTMLInputElement>
      ) => {
        if (event?.type === 'blur') {
          if (event.target instanceof HTMLInputElement && event.target.value) {
            form.validateFields()
          }
          return
        }
        setTimeout(() => {
          form.validateFields()
        }, 0)
      },
      [form]
    )

    const handleSearch = useCallback(async () => {
      try {
        const values = await form.validateFields()
        const proceeding = {
          rok: parseInt(values.year || '0'),
          cislo: parseInt(values.serialNumber || '0'),
          typ: values.type || '',
          pracovisteKod: parseInt(values.officeCode || '0'),
        }
        props.setProceeding(proceeding)
      } catch (error) {
        message.error(
          'Nastala chyba při vyhledávání řízení, ověřte správnost zadaných údajů.',
          5
        )
      }
    }, [form, props])

    const officeOptions = useMemo(() => {
      if (!workplaces.data?.allKuWorkplaces?.nodes) return []
      return workplaces.data.allKuWorkplaces.nodes.map(node => ({
        value: node?.kod || '',
        label: `${node?.kod} - ${node?.nazevZkraceny}`,
      }))
    }, [workplaces.data?.allKuWorkplaces?.nodes])

    const getFocus = useMemo(
      () => ({
        serialNumber: () => form.getFieldInstance('serialNumber').focus(),
        officeCode: () => form.getFieldInstance('officeCode').focus(),
        submit: () => form.getFieldInstance('submit').focus(),
      }),
      [form]
    )

    return (
      <Form<ProceedingSearchFormValues>
        form={form}
        initialValues={{ code: props.initialValues }}
        onValuesChange={handleValuesChange}
        onFinish={handleSearch}
        className="flex flex-col lg:flex-row lg:space-x-2 xl:space-x-4 lg:items-baseline"
        size="large"
      >
        <Form.Item
          name="code"
          className="min-w-[186px] w-full"
          rules={codeRules}
        >
          <Input
            autoFocus
            placeholder="Př.: V-20301/2024-712"
            onChange={handleCodeChange}
            onClick={handleUnderscoreClick}
            onPaste={validateOnEvent}
            onBlur={validateOnEvent}
          />
        </Form.Item>

        <div className="w-full lg:w-auto mb-2 lg:mb-0 text-gray-400 text-center">
          nebo
        </div>

        <Form.Item
          name="type"
          className="w-full lg:max-w-[130px] 2xl:max-w-full"
          rules={typeRules}
          validateTrigger="onBlur"
          requiredMark
        >
          <Select
            showSearch
            placeholder="Typ řízení"
            options={typeOptions}
            filterOption={filterOption}
            onSelect={getFocus.serialNumber}
          />
        </Form.Item>

        <div className="hidden lg:block text-gray-400">-</div>

        <Form.Item
          name="serialNumber"
          className="w-full lg:max-w-[130px] 2xl:max-w-full"
          normalize={onlyNumbers}
          rules={serialNumberRules}
        >
          <Input placeholder="Číslo pořadové" />
        </Form.Item>

        <div className="hidden lg:block text-gray-400">/</div>

        <Form.Item
          name="year"
          className="w-full lg:max-w-[130px] 2xl:max-w-full"
          normalize={onlyNumbers}
          rules={yearRules}
          validateTrigger="onBlur"
        >
          <Select
            showSearch
            placeholder="Rok"
            options={yearOptions}
            filterOption={filterOption}
            onSelect={getFocus.officeCode}
          />
        </Form.Item>

        <div className="hidden lg:block text-gray-400">-</div>

        <Form.Item
          name="officeCode"
          className="w-full lg:max-w-[200px] 2xl:max-w-full"
          rules={officeCodeRules}
        >
          <Select
            showSearch
            placeholder="Kód kat. pracoviště"
            options={officeOptions}
            filterOption={filterOption}
            onSelect={getFocus.submit}
          />
        </Form.Item>

        <Form.Item name="submit" className="text-right">
          <Button type="primary" htmlType="submit">
            <span>
              Vyhledat
              <span className="inline lg:hidden xl:inline"> řízení</span>
            </span>
          </Button>
        </Form.Item>
      </Form>
    )
  }
