import { Alert, Button } from 'antd'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { CadastrePostSentTable } from '../components/CadastrePostSentTable/CadastrePostSentTable'
import { PageLayout } from '../components/PageLayout/PageLayout'
import { postServiceSentPath } from '../utils/paths'

export const CadastrePostSent: FunctionComponent = () => {
  return (
    <PageLayout
      title="Katastrální pošta"
      isNew
      extra={
        <Button disabled type="primary">
          Vytvořit novou korespondenci
        </Button>
      }
    >
      <Alert
        className="!mb-4"
        description={
          <div>
            Zároveň se spuštěním nového modulu{' '}
            <Link to={postServiceSentPath()}>Hromadná pošta</Link> byl původní
            modul katastrální pošty deaktivován. Tato stránka zůstává k
            dispozici pouze pro účely archivace již rozeslané korespondence.
            Nová <Link to={postServiceSentPath()}>Hromadná pošta</Link>{' '}
            zachovává všechny původní funkcionality a zároveň přináší i řadu
            nových rozšíření.
          </div>
        }
        message="Archivováno"
        type="warning"
        showIcon
      />
      <CadastrePostSentTable />
    </PageLayout>
  )
}

export default CadastrePostSent
