import { FilterOutlined } from '@ant-design/icons'
import { Button, Form, Modal } from 'antd'
import React, { FunctionComponent } from 'react'

import { CadastreMapInsolvencyFilterFormOutput } from '../../../common/cadastreMapInsolvencyTypes'
import { useToggleState } from '../../utils/useToggleState'
import { CadastreMapInsolvencyFilterForm } from '../CadastreMap/components/CadastreMapInsolvencyFilterForm/CadastreMapInsolvencyFilterForm'

export interface CadastreMapInsolvencyLayerControlModalButtonProps {
  onFilterParams: (where: CadastreMapInsolvencyFilterFormOutput) => void
}

const style = { paddingTop: 20 }

export const CadastreMapInsolvencyLayerControlModalButton: FunctionComponent<CadastreMapInsolvencyLayerControlModalButtonProps> =
  props => {
    const [form] = Form.useForm()
    const [open, toggleOpen] = useToggleState()

    return (
      <div>
        <Button
          className="hover:!bg-white"
          icon={<FilterOutlined />}
          onClick={toggleOpen}
          title="Filtrovat nemovitostí s insolvencí"
        />

        <Modal
          title="Filtr zobrazení nemovitostí"
          open={open}
          onCancel={toggleOpen}
          footer={null}
          width={750}
          bodyStyle={style}
        >
          <CadastreMapInsolvencyFilterForm
            form={form}
            onSubmit={props.onFilterParams}
            onClose={toggleOpen}
          />
        </Modal>
      </div>
    )
  }
