import { subMilliseconds } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'

import {
  useCreateUserActivityLogMutation,
  UserActivityActionType,
  UserActivityEntityType,
  useUpdateUserActivityLogMutation,
  useUserActivityLogByEntityIdQuery,
} from '../../graphql/generated'
import { useAppSelector } from '../../redux/hooks'

export const useUpdateUserActivityLog = () => {
  const [, createActivityLog] = useCreateUserActivityLogMutation()
  const [, updateActivityLog] = useUpdateUserActivityLogMutation()
  const { userId, tenantId } = useAppSelector(state => state.myProfile)

  const [params, setParams] = useState<{
    entityId: string | null
    entityType: UserActivityEntityType | null
    actionType: UserActivityActionType | null
  }>({ entityId: null, entityType: null, actionType: null })

  const [{ data }] = useUserActivityLogByEntityIdQuery({
    pause: !params.entityId || !userId,
    variables: { accountUserId: userId, entityId: params.entityId || '' },
  })

  useEffect(() => {
    const updateLog = async () => {
      if (
        !params.entityType ||
        !params.actionType ||
        !params.entityId ||
        !data?.allUserActivityLogs
      )
        return

      setParams({ entityId: null, entityType: null, actionType: null })
      const log = data?.allUserActivityLogs?.nodes?.[0]
      const now = new Date()
      const threeHoursAgo = subMilliseconds(now, 3 * 60 * 60 * 1000)

      if (log) {
        const logDate = new Date(log.editedAt)
        if (logDate < threeHoursAgo) {
          await updateActivityLog({
            nodeId: log.nodeId,
            actionType: params.actionType,
            editedAt: now,
          })
        }
      } else {
        await createActivityLog({
          accountUserId: userId,
          accountId: tenantId,
          actionType: params.actionType,
          entityId: params.entityId,
          entityType: params.entityType,
        })
      }
    }

    updateLog()
  }, [
    createActivityLog,
    data?.allUserActivityLogs,
    params.actionType,
    params.entityId,
    params.entityType,
    tenantId,
    updateActivityLog,
    userId,
  ])

  const updateUserActivityLog = useCallback(
    (
      entityId: string,
      entityType: UserActivityEntityType,
      actionType: UserActivityActionType
    ) => {
      if (!entityId || !entityType || !actionType) return
      if (
        entityId !== params.entityId ||
        entityType !== params.entityType ||
        actionType !== params.actionType
      ) {
        setParams({ entityId, entityType, actionType })
      }
    },
    [params.actionType, params.entityId, params.entityType]
  )

  return updateUserActivityLog
}
