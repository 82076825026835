import React, { FunctionComponent } from 'react'

import { F_BONITY } from '../../../common/cuzkLvTypes'
import { notEmpty, safeMap } from '../../../common/pdfHelpers'
import { CuzkLvHtmlBezZapisuFragment } from '../CuzkLvHtmlBezZapisuFragment/CuzkLvHtmlBezZapisuFragment'
import { CuzkLvHtmlFBonityActiveFragment } from '../CuzkLvHtmlFBonityActiveFragment/CuzkLvHtmlFBonityActiveFragment'
import { CuzkLvHtmlParcelaIdentFragment } from '../CuzkLvHtmlParcelaIdentFragment/CuzkLvHtmlParcelaIdentFragment'

export interface CuzkLvHtmlSekceFFragmentProps {
  fBonity?: F_BONITY
  active?: boolean
}

export const CuzkLvHtmlSekceFFragment: FunctionComponent<CuzkLvHtmlSekceFFragmentProps> =
  props => {
    const F_BONITY = props.fBonity
    const isEmpty = !notEmpty(F_BONITY?.F_BONITA)

    return (
      <>
        <div className="row">
          <div className="col col-xs-12">
            F{' '}
            <i>
              vztah bonitovaných půdně ekologických jednotek (BPEJ) k parcelám
            </i>
            {isEmpty && <CuzkLvHtmlBezZapisuFragment />}
          </div>
        </div>
        {!isEmpty &&
          (props.active ? (
            <CuzkLvHtmlFBonityActiveFragment
              FBonity={F_BONITY}
              active={props.active}
            />
          ) : (
            <>
              <div className="row">
                <div className="col col-xs-1">&nbsp;</div>
                <div className="col col-xs-4">
                  &nbsp;&nbsp;<i>Parcela</i>
                </div>
                <div className="col col-xs-2">
                  <i>BPEJ</i>
                </div>
                <div className="col col-xs-3 text-right">
                  <i>Výměra [m2]</i>
                </div>
                <div className="col">&nbsp;</div>
              </div>
              <hr className="mt-0 mb-0" />

              {safeMap(F_BONITY?.F_BONITA, (F_BONITA, index) => (
                <div key={index} className="row bold">
                  <div className="col col-xs-1">&nbsp;</div>
                  <div className="col col-xs-4">
                    <CuzkLvHtmlParcelaIdentFragment
                      breakLine
                      parcelaIdent={F_BONITA?.BON_PARCELNI_CISLO}
                      active={props.active}
                      noLabel
                    />
                  </div>
                  <div className="col col-xs-2">
                    {safeMap(F_BONITA?.BONITY_BPEJ?.BPEJ, (BPEJ, index) => (
                      <div key={index}>{BPEJ.BPEJ_KOD}</div>
                    ))}
                  </div>
                  <div className="col col-xs-3 text-right">
                    {safeMap(F_BONITA?.BONITY_BPEJ?.BPEJ, (BPEJ, index) => (
                      <div key={index}>{BPEJ.BPEJ_VYMERA}</div>
                    ))}
                  </div>
                  <div className="col">&nbsp;</div>
                </div>
              ))}
              <div className="row">
                <i>
                  Pokud je výměra bonitních dílů parcel menší než výměra
                  parcely, zbytek parcely není bonitován
                </i>
              </div>
            </>
          ))}
      </>
    )
  }
