import React, { FunctionComponent } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { PageLayout } from '../components/PageLayout/PageLayout'
import { PostServiceLetterCreateModalButton } from '../components/PostServiceLetterCreateModalButton/PostServiceLetterCreateModalButton'
import { PostServiceSentTable } from '../components/PostServiceSentTable/PostServiceSentTable'

export const PostServiceSent: FunctionComponent = () => {
  const { params } = useRouteMatch<{ create: string }>()

  return (
    <PageLayout
      title="Hromadná pošta"
      isNew
      extra={
        <PostServiceLetterCreateModalButton
          open={!!params.create}
          buttonText="Vytvořit novou hromadnou poštu"
        />
      }
    >
      <PostServiceSentTable />
    </PageLayout>
  )
}

export default PostServiceSent
