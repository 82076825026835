import { Alert, Spin } from 'antd'
import React, { FunctionComponent, memo } from 'react'

import { useAppSelector } from '../../../redux/hooks'
import { CadastralImportBox } from '../CadastralImportBox/CadastralImportBox'
import { PageLayoutTabContainer } from '../PageLayoutTabContainer/PageLayoutTabContainer'
import { PropertyDetailOwnershipProperties } from '../PropertyDetailOwnershipProperties/PropertyDetailOwnershipProperties'
import { PropertyDetailOwnershipRestriction } from '../PropertyDetailOwnershipRestriction/PropertyDetailOwnershipRestriction'
import { PropertyDetailOwnershipRights } from '../PropertyDetailOwnershipRights/PropertyDetailOwnershipRights'

export interface PropertyDetailOwnershipProps {
  propertyId: string
  notFound: boolean
}

export const PropertyDetailOwnership: FunctionComponent<PropertyDetailOwnershipProps> =
  memo(props => {
    const loadingTimeline = useAppSelector(
      state => state.property.timeline.fetching
    )
    const loadingLv = useAppSelector(state => state.property.lv.fetching)
    const crmDrmData = useAppSelector(state => state.property.crm.data)

    if (props.notFound) {
      return (
        <PageLayoutTabContainer>
          <Alert
            showIcon
            type="error"
            description="Je nám líto, ale požadovaná nemovitost nebyla nalezena. Opakujte prosím akci později, nebo se obraťte na technickou podporu."
            message="Nemovitost nebyla nalezena"
          />
        </PageLayoutTabContainer>
      )
    }

    if (crmDrmData === false) {
      return (
        <PageLayoutTabContainer>
          <CadastralImportBox
            size="large"
            entityType="property"
            classNameInner="!py-20 mb-4"
            className="px-2"
          />
        </PageLayoutTabContainer>
      )
    }

    return (
      <PageLayoutTabContainer className="space-y-14">
        <Spin spinning={loadingTimeline}>
          <PropertyDetailOwnershipRestriction />
        </Spin>

        <Spin spinning={loadingLv}>
          <PropertyDetailOwnershipRights />
        </Spin>

        <Spin spinning={loadingLv}>
          <PropertyDetailOwnershipProperties propertyId={props.propertyId} />
        </Spin>
      </PageLayoutTabContainer>
    )
  })
