import { BASE_URL } from '../../../ini.json'
import { UpdateDocumentCollectionRequest } from '../../common/documentCollectionTypes'

export const checkSeznamSestav = async (
  input: UpdateDocumentCollectionRequest
) => {
  try {
    const response = await fetch(`${BASE_URL}/api/cuzk/checkSeznamSestav`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tenantId: input.tenantId,
        cuzkCollectionId: input.cuzkCollectionId,
        id: input.id,
        stav: input.stav,
        collectionType: input.collectionType,
      }),
    })

    if (response.status === 200) {
      return true
    }
    return false
  } catch (error) {
    console.log('checkSeznamSestav.error')
  }
  return false
}
