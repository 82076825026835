export type ColorPalleteKey = keyof typeof ColorPalleteOptions
export const ColorPalleteDeafultOption: ColorPalleteKey = 'g0'

export const ColorPalleteOptions = {
  //here is the reason why not to generate colors dynamically https://tailwindcss.com/docs/content-configuration#dynamic-class-names
  g0: 'bg-white text-gray-500 border-gray-300',
  g1: 'bg-gray-50 text-gray-500 border-gray-200',
  g2: 'bg-gray-100 text-gray-700 border-gray-200',
  g3: 'bg-gray-200 text-gray-700 border-gray-300',
  g4: 'bg-gray-300 text-gray-900 border-gray-400',
  g5: 'bg-gray-400 text-white border-gray-500',
  g6: 'bg-gray-500 text-white border-gray-700',

  r0: 'bg-white text-red-400 border-red-200',
  r1: 'bg-red-50 text-red-500 border-red-200',
  r2: 'bg-red-100 text-red-700 border-red-200',
  r3: 'bg-red-200 text-red-700 border-red-300',
  r4: 'bg-red-300 text-red-900 border-red-400',
  r5: 'bg-red-400 text-white border-red-500',
  r6: 'bg-red-500 text-white border-red-700',

  y0: 'bg-white text-yellow-600 border-yellow-200',
  y1: 'bg-yellow-50 text-yellow-600 border-yellow-200',
  y2: 'bg-yellow-100 text-yellow-700 border-yellow-200',
  y3: 'bg-yellow-200 text-yellow-700 border-yellow-300',
  y4: 'bg-yellow-300 text-yellow-900 border-yellow-400',
  y5: 'bg-yellow-400 text-white border-yellow-500',
  y6: 'bg-yellow-500 text-white border-yellow-600',

  l0: 'bg-white text-lime-600 border-lime-200',
  l1: 'bg-lime-50 text-lime-600 border-lime-200',
  l2: 'bg-lime-100 text-lime-700 border-lime-200',
  l3: 'bg-lime-200 text-lime-700 border-lime-300',
  l4: 'bg-lime-300 text-lime-900 border-lime-400',
  l5: 'bg-lime-400 text-white border-lime-500',
  l6: 'bg-lime-500 text-white border-lime-600',

  b0: 'bg-white text-blue-400 border-blue-200',
  b1: 'bg-blue-50 text-blue-500 border-blue-200',
  b2: 'bg-blue-100 text-blue-700 border-blue-200',
  b3: 'bg-blue-200 text-blue-700 border-blue-300',
  b4: 'bg-blue-300 text-blue-900 border-blue-400',
  b5: 'bg-blue-400 text-white border-blue-500',
  b6: 'bg-blue-500 text-white border-blue-700',

  e0: 'bg-white text-emerald-500 border-emerald-200',
  e1: 'bg-emerald-50 text-emerald-500 border-emerald-200',
  e2: 'bg-emerald-100 text-emerald-700 border-emerald-200',
  e3: 'bg-emerald-200 text-emerald-700 border-emerald-300',
  e4: 'bg-emerald-300 text-emerald-900 border-emerald-400',
  e5: 'bg-emerald-400 text-white border-emerald-500',
  e6: 'bg-emerald-500 text-white border-emerald-600',

  c0: 'bg-white text-cyan-500 border-cyan-200',
  c1: 'bg-cyan-50 text-cyan-500 border-cyan-200',
  c2: 'bg-cyan-100 text-cyan-700 border-cyan-200',
  c3: 'bg-cyan-200 text-cyan-700 border-cyan-300',
  c4: 'bg-cyan-300 text-cyan-900 border-cyan-400',
  c5: 'bg-cyan-400 text-white border-cyan-500',
  c6: 'bg-cyan-500 text-white border-cyan-700',

  p0: 'bg-white text-pink-500 border-pink-200',
  p1: 'bg-pink-50 text-pink-500 border-pink-200',
  p2: 'bg-pink-100 text-pink-700 border-pink-200',
  p3: 'bg-pink-200 text-pink-700 border-pink-300',
  p4: 'bg-pink-300 text-pink-900 border-pink-400',
  p5: 'bg-pink-400 text-white border-pink-500',
  p6: 'bg-pink-500 text-white border-pink-700',
}
export const PieChartColorPalette = [
  '#4a9899',
  '#4a7999',
  '#4a5a99',
  '#594a99',
  '#784a99',
  '#974a99',
  '#994a8a',
  '#994a6b',
  '#994a4d',
  '#994c4a',
  '#996b4a',
  '#99894a',
]
