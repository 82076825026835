import { Spin, Tooltip } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

export interface TabLabelProps {
  title: string
  tooltip?: React.ReactNode
  icon?: React.ReactNode
  loading?: boolean
  onClick?: () => void
  className?: string
}

export const TabLabel: FunctionComponent<TabLabelProps> = props => {
  const style = useMemo(() => props.className || 'py-1', [props.className])

  if (props.tooltip && !props.loading)
    return (
      <Tooltip className={style} title={props.tooltip}>
        {props.icon}
        <span>{props.title}</span>
      </Tooltip>
    )
  return (
    <Spin spinning={!!props.loading}>
      <span onClick={props?.onClick} className={style}>
        {props.icon}
        <span>{props.title}</span>
      </span>
    </Spin>
  )
}
