import React, { FunctionComponent, useMemo, useState } from 'react'
import { Redirect } from 'react-router-dom'

import { Maybe } from '../../../graphql/generated'
import { cuzkEpoPath } from '../../utils/paths'
import { useCheckClaims } from '../../utils/useCheckClaims'
import { useRcNumberData } from '../../utils/useRcNumberData'
import { AddRecipientModalButton } from '../AddRecipientModalButton/AddRecipientModalButton'
import {
  CUZK_PERSON_TYPES,
  CuzkEpoModalButton,
} from '../CuzkEpoModalButton/CuzkEpoModalButton'
import { cuzkOs } from '../CuzkMonitorRcTable/CuzkMonitorRcTable'
export interface CuzkMonitorRcTableCuzkOsProps {
  cuzkOs?: Maybe<cuzkOs>
}

export const CuzkMonitorRcTableCuzkOs: FunctionComponent<CuzkMonitorRcTableCuzkOsProps> =
  props => {
    const os = props.cuzkOs
    const tester = useCheckClaims('ApiVyvojar')

    const [epoSubmited, setEpoSubmited] = useState(false)
    const { birthDate } = useRcNumberData(os?.rc)

    const regestaSubjectUrl = useMemo(() => {
      const addressString =
        (os?.psc ? os.psc : '') + ' ' + (os?.obecnazev ? os.obecnazev : '')
      const nameString = os?.nazev
        ? os.nazev
        : (os?.jmeno ? os.jmeno : '') + ' ' + (os?.prijmeni ? os.prijmeni : '')
      return `https://data.regesta.cz/Vyhledavani/Subjekty?nazev=${nameString}&adresa=${addressString}&vcetneHistorie=false`
    }, [os])

    const buttonProps = useMemo(
      () => ({
        buttonProps: {
          size: 'small' as const,
          ghost: true,
          title: 'Vyhledat evidenci práv pro osobu',
        },
        buttonText: 'Vyhledat EPO',
        initialValues: {
          type: os?.ico ? CUZK_PERSON_TYPES.OPO : CUZK_PERSON_TYPES.OFO,
          ico: os?.ico || '',
          birthDate: os?.rc || os?.rczkracene || '',
          firstName: os?.jmeno || '',
          lastName: os?.prijmeni || '',
          name: os?.nazev || '',
        },
        refetch: setEpoSubmited,
      }),
      [os?.ico, os?.jmeno, os?.nazev, os?.prijmeni, os?.rc, os?.rczkracene]
    )

    const postServiceInitialValues = useMemo(
      () => ({
        firstname: os?.jmeno || '',
        lastname: os?.prijmeni || '',
        companyName: os?.nazev || '',
        street: os?.ulice || '',
        houseNumber: os?.cislodomovni ? os.cislodomovni.toString() : '',
        orientationNumber: os?.cisloorientacni || '',
        city: os?.obecnazev || '',
        zipCode: os?.psc || '',
        country: os?.statnazev ? '' : 'CZ',
      }),
      [
        os?.cislodomovni,
        os?.cisloorientacni,
        os?.jmeno,
        os?.nazev,
        os?.obecnazev,
        os?.prijmeni,
        os?.psc,
        os?.statnazev,
        os?.ulice,
      ]
    )

    if (epoSubmited) return <Redirect to={cuzkEpoPath()} />
    if (!os) return null
    return (
      <div className="grid grid-cols-1 grid-rows-[1fr,auto]">
        <div className="grid content-start grid-cols-[auto,1fr] gap-x-4 mt-1">
          <div className="font-semibold">{os.nazev ? 'Název:' : 'Jméno:'}</div>
          <div>
            <a href={regestaSubjectUrl}>
              {os.titulpred} {os.jmeno} {os.prijmeni} {os.titulza} {os.nazev}
            </a>
          </div>

          <div className="font-semibold">
            {os.nazev ? 'Sídlo:' : 'Bydliště:'}
          </div>
          <div>
            {os.ulice || os.obecnazev} {os.cislodomovni}
            {os.cisloorientacni ? '/' + os.cisloorientacni : ''} {os.obecnazev}{' '}
            {/*  {os.mestskacastnazev} {os.mestskyobvodnazev} {os.castobcenazev}{' '} */}
            {os.psc} {os.statnazev}
          </div>

          {birthDate && (
            <>
              <div className="font-semibold">Dat. narození:</div>
              <div>{birthDate}</div>
            </>
          )}

          {os?.ico && (
            <>
              <div className="font-semibold">IČO:</div>
              <div>{os.ico}</div>
            </>
          )}
        </div>
        <div className="mt-2 flex items-center space-x-2">
          {tester && (
            <AddRecipientModalButton record={postServiceInitialValues} />
          )}
          <CuzkEpoModalButton {...buttonProps} />
        </div>
      </div>
    )
  }
