import { Tooltip } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { StavbaType } from '../../../common/cuzkLvTypes'
import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { appendSpace } from '../../../common/pdfHelpers'
import { useAppSelector } from '../../../redux/hooks'
import { propertyDetailPath } from '../../utils/paths'

export interface CuzkLvHtmlStavbaIdentFragmentProps {
  stavbaIdent?: StavbaType
  active?: boolean
  breakLine?: boolean
  noLabel?: boolean
}

export const CuzkLvHtmlStavbaIdentFragment: FunctionComponent<CuzkLvHtmlStavbaIdentFragmentProps> =
  props => {
    const stavba = props.stavbaIdent

    const nemovitosti = useAppSelector(state => state.lvDetail.nemovitosti)
    const findBuilding = useCallback(
      (building: string) => {
        if (props.active) {
          for (const budova of nemovitosti.budovy) {
            if (
              budova.cislaDomovni?.match(/\d+/g)?.join('') ===
              building.match(/\d+/g)?.join('')
            ) {
              return (
                <Tooltip title="Zobrazit detail budovy v novém okně">
                  <Link
                    target="_blank"
                    to={propertyDetailPath(PROPERTY_TYPES.BUILDING, budova.id)}
                  >
                    {appendSpace(stavba?.caobce)}
                    {building}
                  </Link>
                </Tooltip>
              )
            }
          }
        }
        return appendSpace(stavba?.caobce) + ' ' + building
      },
      [nemovitosti.budovy, props.active, stavba?.caobce]
    )

    return (
      <span className={props.breakLine ? 'breakLine' : ''}>
        {props.noLabel ? '' : 'Stavba: '}
        {/*         {stavba?.id}
        {stavba?.bez_lv}
        {stavba?.typbud_zkr}
        {stavba?.cislo_tel}
        {stavba?.docasna}
        {stavba?.vyuziti_zkr}
      */}

        {findBuilding(stavba?.cisla_domovni || '')}
        {', '}
      </span>
    )
  }
