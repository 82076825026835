import { Tooltip } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { F_BONITY } from '../../../common/cuzkLvTypes'
import { safeMap } from '../../../common/pdfHelpers'
import { useBpejByCodeQuery } from '../../../graphql/generated'
import { tooltipHelpUnderline } from '../../utils/layoutConst'
import { CuzkLvHtmlAreaPriceEstimateButton } from '../CuzkLvHtmlAreaPriceEstimateButton/CuzkLvHtmlAreaPriceEstimateButton'
import { CuzkLvHtmlParcelaIdentFragment } from '../CuzkLvHtmlParcelaIdentFragment/CuzkLvHtmlParcelaIdentFragment'
import { Money } from '../Money/Money'

export interface CuzkLvHtmlFBonityActiveFragmentProps {
  FBonity?: F_BONITY
  active?: boolean
}

export const CuzkLvHtmlFBonityActiveFragment: FunctionComponent<CuzkLvHtmlFBonityActiveFragmentProps> =
  props => {
    const allCodes = useMemo(() => {
      if (!props.FBonity?.F_BONITA) return []
      const codes = safeMap(props.FBonity.F_BONITA, fBonita =>
        safeMap(fBonita.BONITY_BPEJ?.BPEJ, bpej => bpej.BPEJ_KOD)
      )
      if (!codes) return []
      return [...new Set(codes.flatMap(c => c))]
    }, [props.FBonity?.F_BONITA])

    const [queryResult] = useBpejByCodeQuery({
      variables: { in: allCodes },
    })

    const bpejTotal = useMemo(() => {
      if (!props.FBonity?.F_BONITA) return 0
      const fBonita = Array.isArray(props.FBonity.F_BONITA)
        ? props.FBonity.F_BONITA
        : [props.FBonity.F_BONITA]

      let totalAcreage = 0
      let totalPrice = 0

      fBonita.forEach(bonita => {
        const BPEJs = Array.isArray(bonita.BONITY_BPEJ?.BPEJ)
          ? bonita.BONITY_BPEJ?.BPEJ
          : bonita.BONITY_BPEJ?.BPEJ
          ? [bonita.BONITY_BPEJ?.BPEJ]
          : []

        BPEJs?.forEach(bpej => {
          const area = Number(bpej.BPEJ_VYMERA)
          const bpejInfo = queryResult.data?.allBpejs?.nodes?.find(
            code => code?.kod === bpej.BPEJ_KOD
          )

          totalAcreage += area
          totalPrice += (bpejInfo?.cena || 0) * area
        })
      })

      const title = (
        <div>
          <div>
            Celková výměra BPEJ:{' '}
            <Money
              className="font-bold"
              amount={totalAcreage}
              symbol={
                <span>
                  m<sup>2</sup>
                </span>
              }
              decimalNumbers={0}
            />
          </div>
          <div>
            Celková cena:{' '}
            <Money
              className="font-bold"
              amount={totalPrice}
              symbol="Kč"
              decimalNumbers={0}
            />
          </div>
          <div className="opacity-50">
            (Cena je vypočítána dle jednotlivých typů BPEJ, jejich výměry a
            cenových údajů Ministerstva zemědělství)
          </div>
        </div>
      )
      return (
        <Tooltip title={title} className="text-primary cursor-pointer">
          <i>Výměra [m2]</i>
        </Tooltip>
      )
    }, [props.FBonity?.F_BONITA, queryResult.data?.allBpejs?.nodes])

    const bpejAcreage = useCallback(
      (vymera: string | number, bpejKod: string, parcela: string) => {
        if (props.active) {
          const bpej = queryResult.data?.allBpejs?.nodes?.find(
            code => code?.kod === bpejKod
          )
          const title = (
            <div>
              <div className="font-bold">
                Cena:{' '}
                <Money
                  amount={(bpej?.cena || 0) * Number(vymera)}
                  symbol="Kč"
                  decimalNumbers={0}
                />
              </div>
              <div className="opacity-50 mb-2">
                (vypočítáno dle výměry parcely, typu BPEJ a průměrné ceny{' '}
                <Money amount={bpej?.cena} symbol="Kč" />
                /m<sup>2</sup> uváděné Ministerstvem zemědělství).
              </div>
              <div className="mb-1">
                Pokud chcete získat lepší představu o reálné ceně pozemku,
                udělejte si náš cenový odhad.
              </div>
              <div>
                <CuzkLvHtmlAreaPriceEstimateButton area={parcela} />
              </div>
            </div>
          )
          return (
            <Tooltip
              title={title}
              trigger="click"
              className={tooltipHelpUnderline}
            >
              {vymera}
            </Tooltip>
          )
        }
        return vymera
      },
      [props.active, queryResult.data?.allBpejs?.nodes]
    )

    const bpejTitle = useCallback(
      (bpejKod: string) => {
        const bpej = queryResult.data?.allBpejs?.nodes?.find(
          code => code?.kod === bpejKod
        )
        return (
          <div>
            <div>
              Kliknutím zobrazíte detilní informace o bonitované půdně
              ekologické jednotce (BPEJ) v novém okně.
            </div>
            {bpej?.cena && (
              <div className="opacity-50">
                Průměrná cena za m<sup>2</sup> dle MZe -{' '}
                <Money amount={bpej?.cena} symbol="Kč" />
              </div>
            )}
          </div>
        )
      },
      [queryResult.data?.allBpejs?.nodes]
    )

    return (
      <>
        <div className="row">
          <div className="col col-xs-1">&nbsp;</div>
          <div className="col col-xs-4">
            &nbsp;&nbsp;<i>Parcela</i>
          </div>
          <div className="col col-xs-2">
            <i>BPEJ</i>
          </div>
          <div className="col col-xs-3 text-right">{bpejTotal}</div>
          <div className="col">&nbsp;</div>
        </div>
        <hr className="mt-0 mb-0" />
        {safeMap(props.FBonity?.F_BONITA, (F_BONITA, index) => (
          <div key={index} className="row bold">
            <div className="col col-xs-1">&nbsp;</div>
            <div className="col col-xs-4">
              <CuzkLvHtmlParcelaIdentFragment
                breakLine
                parcelaIdent={F_BONITA?.BON_PARCELNI_CISLO}
                active={props.active}
                noLabel
              />
            </div>
            <div className="col col-xs-2">
              {safeMap(F_BONITA?.BONITY_BPEJ?.BPEJ, (BPEJ, index) => (
                <div key={index}>
                  <Tooltip title={bpejTitle(BPEJ.BPEJ_KOD)}>
                    <a
                      target="_blank"
                      href={`https://bpej.vumop.cz/${BPEJ.BPEJ_KOD}`}
                      rel="noreferrer"
                    >
                      {BPEJ.BPEJ_KOD}
                    </a>
                  </Tooltip>
                </div>
              ))}
            </div>
            <div className="col col-xs-3 text-right">
              {safeMap(F_BONITA?.BONITY_BPEJ?.BPEJ, (BPEJ, index) => (
                <div key={index}>
                  {bpejAcreage(
                    BPEJ.BPEJ_VYMERA,
                    BPEJ.BPEJ_KOD,
                    F_BONITA.BON_PARCELNI_CISLO.par_cis +
                      (F_BONITA.BON_PARCELNI_CISLO.podd_cis
                        ? '/' + F_BONITA.BON_PARCELNI_CISLO.podd_cis
                        : '')
                  )}
                </div>
              ))}
            </div>
            <div className="col">&nbsp;</div>
          </div>
        ))}
        <div className="row">
          <i>
            Pokud je výměra bonitních dílů parcel menší než výměra parcely,
            zbytek parcely není bonitován
          </i>
        </div>
      </>
    )
  }
