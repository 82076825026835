import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

import {
  InsolvencyMonitoringCompactTableItem,
  InsolvencyMonitoringRecord,
} from '../../common/insolvencyMonitoringTypes'

const getUniqueValues = (items: { zpusobVyuziti: { nazev: string } }[]) => {
  const uniqueValues = new Set(
    items.map(item => item?.zpusobVyuziti?.nazev).filter(Boolean)
  )
  return Array.from(uniqueValues)
}

const getAllSameValue = (
  items: InsolvencyMonitoringRecord[],
  key: keyof InsolvencyMonitoringRecord
) => {
  const values = new Set(items.map(item => item[key]))
  return values.size === 1 ? !!items[0][key] : null
}

const getShareValue = (items: InsolvencyMonitoringRecord[]) => {
  const podily = items.map(item => item.podil)
  return {
    od: Math.min(...podily),
    do: Math.max(...podily),
  }
}

export const useInsolvencyMonitoringCompactData = (
  data: InsolvencyMonitoringRecord[] | null | undefined
) => {
  return useMemo(() => {
    const result: InsolvencyMonitoringCompactTableItem[] = []
    if (!data) return result

    const groupedByInsolvency = {} as {
      [key: string]: InsolvencyMonitoringRecord[]
    }

    data.forEach(item => {
      const insNumber = item.insVec.spisovaZnacka
      if (!groupedByInsolvency[insNumber]) {
        groupedByInsolvency[insNumber] = []
      }
      groupedByInsolvency[insNumber].push(item)
    })

    for (const writingMark in groupedByInsolvency) {
      const items = groupedByInsolvency[writingMark]
      const mainItem = {
        insVec: items[0].insVec,
        subjekt: items[0].subjekt,
        id: uuidv4(),
        pocetNemovitosti: items.length,
        zpusobVyuziti: getUniqueValues(items),
        zastavySmluvni: getAllSameValue(items, 'zastavySmluvni'),
        zastavyNedobrovolne: getAllSameValue(items, 'zastavyNedobrovolne'),
        podil: getShareValue(items),
        pracoviste: items[0].pracoviste,
        items: items,
      }

      result.push(mainItem)
    }

    return result
  }, [data])
}
