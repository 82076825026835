import { SyncOutlined } from '@ant-design/icons'
import { Tag, Tooltip } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { cuzkDocumentCollectionState as state } from '../../utils/handleResponseStatus'

export interface CuzkDocumentStateProps {
  state: string
}

export const CuzkDocumentState: FunctionComponent<CuzkDocumentStateProps> =
  props => {
    const stateTag = useMemo(() => {
      switch (props.state) {
        case state.ceka:
        case state.vytvariSe:
        case state.podepisujeSe:
          return (
            <Tooltip title="ČÚZK zpracovává požadovaný dokument, čas zpracování je závislý na vytížení ČÚZK a velikosti souboru.">
              <Tag color="blue" icon={<SyncOutlined spin />}>
                {props.state === state.ceka ? 'Zpracovává se' : props.state}
              </Tag>
            </Tooltip>
          )
        case state.zpracovan:
          return (
            <Tooltip title="Dokument je připrven ke stažení. Potvrďte, nebo zrušte akci na zaúčtování.">
              <Tag color="orange">Vyžaduje akci</Tag>
            </Tooltip>
          )
        case state.kDispozici:
        case state.zauctovan:
          return (
            <Tooltip title="Dokument máte k dispozici ke stažení">
              <Tag color="success">{props.state}</Tag>
            </Tooltip>
          )

        case state.chybaPriVytvareni:
        case state.chybaPriZpracovani:
          return (
            <Tooltip title="Nastala chyba na straně ČÚZK. Zkuste zadat nový požadavek na vytvoření sestavy později.">
              <Tag color="error">{props.state}</Tag>
            </Tooltip>
          )

        case state.zrusen:
          return (
            <Tooltip title="Sestava byla ve vašem ČÚZK účtě označena za smazanou.">
              <Tag color="warning">{props.state}</Tag>
            </Tooltip>
          )

        case state.info:
          return (
            <Tooltip title="K dispozici je prozatím pouze název dokumentu, stisknutím 'Načíst listinu' budou bližší informace o listině doplněny ze serveru ČÚZK - tato akce je bezúplatná.">
              <Tag color="default">{props.state}</Tag>
            </Tooltip>
          )

        case state.naVyzadani:
          return (
            <Tooltip title="Listina v tuto chvíli není k dispozici v elektronické formě, kliknutím na tlačítko 'Zažádat o listinu' budete přesměrování na stránky ČÚZK, kde vznesete požadavek o zpřístupnění.">
              <Tag color="default">{props.state}</Tag>
            </Tooltip>
          )

        default:
          return <Tag color="default">{props.state}</Tag>
      }
    }, [props.state])

    return <>{stateTag}</>
  }
