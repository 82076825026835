import { Watermark } from 'antd'
import React, { FunctionComponent, useMemo, useState } from 'react'

import { MarketplaceItem as MarketplaceItemInterface } from '../../../common/marketplaceTypes'
import { MarketplaceItemContact } from '../MarketplaceItemContact/MarketplaceItemContact'
import { MarketplaceItemFooter } from '../MarketplaceItemFooter/MarketplaceItemFooter'
import { MarketplaceItemHeader } from '../MarketplaceItemHeader/MarketplaceItemHeader'
import { MarketplaceItemInfo } from '../MarketplaceItemInfo/MarketplaceItemInfo'

export interface MarketplaceContactData {
  name: string
  phone: string
  email: string
  description?: string
  owner?: boolean
  loading?: boolean
}

export interface MarketplaceItemProps {
  data: MarketplaceItemInterface
  refetch: () => void
  noGrayscale?: boolean
}

export const MarketplaceItem: FunctionComponent<MarketplaceItemProps> =
  props => {
    const bought = !!(
      props.data?.contact &&
      props.data?.contact?.name &&
      !props.data?.owner
    )
    const [contentType, setContentType] = useState<string | number>(
      bought ? 'contact' : 'info'
    )
    const watermarkProps = useMemo(() => {
      if (props.data?.state !== 'ACTIVE') {
        return {
          font: { color: 'red' },
          content: ['EXPIROVÁNO'],
          gap: [30, 30] as [number, number],
        }
      }
      return {}
    }, [props.data?.state])

    return (
      <div className="flex flex-col justify-between rounded-t-lg rounded-b-sm shadow-lg bg-white">
        <Watermark {...watermarkProps}>
          <MarketplaceItemHeader
            images={props.data?.images}
            id={props.data.id}
            offerType={props.data?.offerType}
            bought={bought}
            owner={props.data?.owner}
            noGrayscale={props.noGrayscale}
          />
        </Watermark>
        <div className="flex flex-col justify-between grow mt-4">
          <div className="relative overflow-hidden">
            <MarketplaceItemInfo data={props.data} content={contentType} />
            <MarketplaceItemContact
              contactData={props.data.contact}
              content={contentType}
              title={props.data.title}
            />
          </div>
        </div>
        <MarketplaceItemFooter
          id={props.data.id}
          setContent={setContentType}
          refetch={props.refetch}
          state={props.data.state}
          bought={bought}
          owner={!!props.data?.owner}
        />
      </div>
    )
  }
