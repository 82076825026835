import { Table } from 'antd'
import {
  ColumnsType,
  ExpandableConfig,
  SorterResult,
} from 'antd/es/table/interface'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import { addDays, format } from 'date-fns'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import csLocale from 'date-fns/locale/cs'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  Maybe,
  MonitoringCuzkRcFilter,
  useAllMonitoringCuzkRcsQuery,
} from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { CheckFrequency } from '../../utils/checkFrequency'
import { czDateFormatDay } from '../../utils/dateFormat'
import { Identifier, RegisterGroupMap } from '../../utils/dynamicSearchTooltip'
import {
  numberSorter,
  stringSorter,
  utcDateSorter,
  utcDescDateSorter,
} from '../../utils/generalTableHelpers'
import { LabelType } from '../../utils/labelsTypes'
import { tablePagination } from '../../utils/layoutConst'
import { cuzkMonitorRcCreatePath } from '../../utils/paths'
import { useHandleExpandRow } from '../../utils/useHandleExpandRow'
import { CuzkMonitorRcTableAction } from '../CuzkMonitorRcTableAction/CuzkMonitorRcTableAction'
import { CuzkMonitorRcTableExpandedRow } from '../CuzkMonitorRcTableExpandedRow/CuzkMonitorRcTableExpandedRow'
import { CuzkMonitorRcTableStatusCell } from '../CuzkMonitorRcTableStatusCell/CuzkMonitorRcTableStatusCell'
import { Min } from '../DateFormat/DateFormat'
import { DynamicSearchTooltip } from '../DynamicSearchTooltip/DynamicSearchTooltip'
import { ExpandColumnTitle } from '../ExpandColumnTitle/ExpandColumnTitle'
import { ExpandIcon } from '../ExpandIcon/ExpandIcon'
import { RcInputError } from '../RcInputError/RcInputError'
import { RcNumberData } from '../RcNumberData/RcNumberData'
import { TableBulkEditPanel } from '../TableBulkEditPanel/TableBulkEditPanel'
import { TableCellLabels } from '../TableCellLabels/TableCellLabels'
import { TableEmptyText } from '../TableEmptyText/TableEmptyText'
import { TableSummaryExport } from '../TableSummaryExport/TableSummaryExport'

export interface cuzkOs {
  id: string
  castobcenazev?: string | null
  cislodomovni?: number | null
  cisloorientacni?: string | null
  charostype?: number | null
  doplnekico?: string | null
  ico?: string | null
  jmeno?: string | null
  lastCheck?: Date | null
  mestskacastnazev?: string | null
  mestskyobvodnazev?: string | null
  nazev?: string | null
  obecnazev?: string | null
  okresnazev?: string | null
  prijmeni?: string | null
  psc?: string | null
  rc?: string | null
  rczkracene?: string | null
  statnazev?: string | null
  titulpred?: string | null
  titulza?: string | null
  typcpce?: string | null
  ulice?: string | null
  vlastniktyp?: string | null
}

export interface RcTableItem {
  id: string
  description?: string
  rc?: string
  ico?: string
  firstname?: string
  lastname?: string
  birthDate?: string
  typed?: string
  validatedCode?: number
  checkFrequency?: number
  lastCheck?: string
  nextCheck?: string
  found?: boolean
  labels?: { nodes: { id: string; labelByLabelId: LabelType }[] }
  cuzkOByCuzkOsId?: Maybe<cuzkOs>
  createdAt: Date
}

export interface CuzkMonitorRcTableProps {
  MonitoringCuzkRcFilter?: MonitoringCuzkRcFilter
}

const tableId = 'cuzk-subjekty-table'
const scroll = { x: '1200' }

export const CuzkMonitorRcTable: FunctionComponent<CuzkMonitorRcTableProps> =
  props => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)

    const [, setSortedInfo] = useState<SorterResult<RcTableItem>>({})
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [selectedRow, setSelectedRow] = useState<RcTableItem[]>([])
    const [loadingId, setLoadingId] = useState<string[]>([])

    const [queryResult, reexecuteQuery] = useAllMonitoringCuzkRcsQuery({
      variables: { accountId: tenantId, filter: props.MonitoringCuzkRcFilter },
    })

    const [dataSource, setDataSource] = useState<RcTableItem[]>([])
    const handleExpandRow = useHandleExpandRow(tableId)
    const expandedRowKeys = useAppSelector(
      state => state.expandedRow.tables?.[tableId] || []
    )

    useEffect(() => {
      if (queryResult?.data?.allMonitoringCuzkRcs?.nodes) {
        setDataSource(
          queryResult.data.allMonitoringCuzkRcs.nodes as RcTableItem[]
        )
        setSortedInfo({
          order: 'ascend',
          columnKey: 'validatedCode',
        })
      }
    }, [queryResult?.data?.allMonitoringCuzkRcs?.nodes])

    const columns = useMemo<ColumnsType<RcTableItem>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Přidáno',
          dataIndex: 'createdAt',
          render: createdAt => <Min>{createdAt}</Min>,
          defaultSortOrder: 'descend',
          sorter: utcDateSorter('createdAt'),
        },
        {
          title: 'Štítek',
          dataIndex: 'labels',
          render: (labels, record) => (
            <TableCellLabels
              labels={labels}
              labelEntityType="monitoringCuzkRcId"
              recordId={record.id}
              refetch={reexecuteQuery}
              modalTitle={record.lastname}
            />
          ),
        },
        {
          title: 'Poznámka',
          dataIndex: 'description',
          sorter: stringSorter('description'),
        },
        {
          title: 'RČ / IČO',
          dataIndex: 'rc',
          render: (_, record) =>
            record.rc ? (
              <DynamicSearchTooltip
                identifier={{
                  [Identifier.RC]: record.rc,
                  [Identifier.FIRST_NAME]:
                    record.firstname ||
                    record.cuzkOByCuzkOsId?.jmeno ||
                    undefined,
                  [Identifier.LAST_NAME]:
                    record.lastname ||
                    record.cuzkOByCuzkOsId?.prijmeni ||
                    undefined,
                  [Identifier.DESCRIPTION]: record.description,
                }}
                identifierType={Identifier.RC}
                excludedRegisters={RegisterGroupMap.ČÚZK}
              >
                {record.rc}
              </DynamicSearchTooltip>
            ) : record.ico ? (
              <DynamicSearchTooltip
                identifier={{
                  [Identifier.ICO]: record.ico,
                  [Identifier.FIRST_NAME]:
                    record.firstname ||
                    record.cuzkOByCuzkOsId?.jmeno ||
                    undefined,
                  [Identifier.LAST_NAME]:
                    record.lastname ||
                    record.cuzkOByCuzkOsId?.prijmeni ||
                    undefined,
                  [Identifier.COMPANY_NAME]:
                    record.cuzkOByCuzkOsId?.nazev || undefined,

                  [Identifier.DESCRIPTION]: record.description,
                }}
                identifierType={Identifier.ICO}
                excludedRegisters={RegisterGroupMap.ČÚZK}
              >
                {record.ico}
              </DynamicSearchTooltip>
            ) : (
              <div className="text-gray-400 w-full text-center">---</div>
            ),
          sorter: {
            compare: (a, b) =>
              a.rc && b.rc
                ? a.rc.toString().localeCompare(b.rc.toString())
                : a.ico && b.ico
                ? a.ico.toString().localeCompare(b.ico.toString())
                : 0,
          },
        },
        {
          title: 'Jméno',
          dataIndex: 'fullname',
          render: (_, record) =>
            record.firstname && record.lastname ? (
              record.firstname + ' ' + record.lastname
            ) : record.cuzkOByCuzkOsId?.jmeno &&
              record.cuzkOByCuzkOsId.prijmeni ? (
              record.cuzkOByCuzkOsId?.jmeno +
              ' ' +
              record.cuzkOByCuzkOsId.prijmeni
            ) : record.cuzkOByCuzkOsId?.nazev ? (
              record.cuzkOByCuzkOsId?.nazev
            ) : (
              <div className="text-gray-400 w-full text-center">---</div>
            ),
          sorter: {
            compare: (a, b) =>
              (a.lastname || a.cuzkOByCuzkOsId?.prijmeni || '').localeCompare(
                b.lastname || b.cuzkOByCuzkOsId?.prijmeni || ''
              ),
          },
        },
        {
          title: 'Datum nar.',
          dataIndex: 'birthDate',
          render: (birthDate, record) =>
            birthDate ? (
              format(new Date(birthDate), czDateFormatDay)
            ) : record.rc ? (
              <RcNumberData
                getBirthdate
                rcNumber={record.rc}
                className="text-gray-700"
              />
            ) : (
              <div className="text-gray-400 w-full text-center">---</div>
            ),
          sorter: {
            compare: (a, b) =>
              a.rc && b.rc ? a.rc.toString().localeCompare(b.rc.toString()) : 0,
          },
        },
        {
          title: 'Validace RČ',
          dataIndex: 'validated',
          render: (_, record) =>
            record.ico ? (
              <div className="text-gray-400 text-center">IČO</div>
            ) : record.validatedCode === 30 ? (
              <RcNumberData
                icon
                rcNumber={record.rc}
                className="text-gray-700"
              />
            ) : (
              <RcInputError error={record.validatedCode || 0} />
            ),
        },
        {
          title: 'Frekvence kontroly',
          dataIndex: 'checkFrequency',
          sorter: numberSorter('checkFrequency'),
          render: checkFrequency => {
            return typeof checkFrequency === 'number' ? (
              CheckFrequency[checkFrequency]
            ) : (
              <div className="text-gray-400">Pozastaveno</div>
            )
          },
        },
        {
          title: 'Nadcházející kontrola',
          dataIndex: 'nextCheck',
          sorter: stringSorter('nextCheck'),
          render: (_, record) => {
            if (record.checkFrequency === 0 && !record.lastCheck) {
              return <div className="text-gray-400">Do hodiny</div>
            }
            if (record.checkFrequency) {
              return formatDistanceToNow(
                addDays(
                  new Date(record.lastCheck || record.createdAt),
                  record.checkFrequency
                ),
                {
                  locale: csLocale,
                  addSuffix: true,
                }
              )
            }
            return <div className="text-gray-400">Pozastaveno</div>
          },
        },
        {
          title: 'Poslední kontrola',
          dataIndex: 'lastCheck',
          sorter: utcDescDateSorter('lastCheck'),
          render: (date: string) => {
            if (!date)
              return <span className="text-gray-400">Nezkontrolováno</span>
            return formatDistanceToNow(new Date(date), {
              locale: csLocale,
              addSuffix: true,
            })
          },
        },
        {
          title: 'Stav',
          dataIndex: 'found',
          sorter: {
            compare: (a, b) => {
              if (a.found === true && b.found !== true) {
                return 1
              } else if (a.found === false && b.found === undefined) {
                return 1
              } else if (a.found === false && b.found === true) {
                return -1
              } else if (a.found === undefined && b.found !== undefined) {
                return -1
              } else {
                return 0
              }
            },
          },
          render: (state: boolean | undefined, record: RcTableItem) => (
            <CuzkMonitorRcTableStatusCell
              state={state}
              record={record}
              loadingId={loadingId}
              handleExpandRow={handleExpandRow}
            />
          ),
        },
        {
          title: 'Kód validace',
          dataIndex: 'validatedCode',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Akce',
          dataIndex: 'actions',
          render: (_: string, record: RcTableItem) => (
            <CuzkMonitorRcTableAction
              record={record}
              reexecuteQuery={reexecuteQuery}
              setLoadingId={setLoadingId}
            />
          ),
        },
      ],
      [handleExpandRow, loadingId, reexecuteQuery]
    )

    const locale = useMemo(() => {
      return {
        emptyText: !queryResult.fetching && (
          <TableEmptyText
            buttonText="Vyhledat subjekt"
            buttonLink={cuzkMonitorRcCreatePath()}
            text="Zatím jste nevyhledali žádné subjekty v ČÚZK."
          />
        ),
      }
    }, [queryResult.fetching])

    const resetSelection = useCallback(() => {
      setSelectedRowKeys([])
      setSelectedRow([])
    }, [])

    const onBulkEdit = useCallback(() => {
      resetSelection()
      reexecuteQuery()
    }, [reexecuteQuery, resetSelection])

    const expandable = useMemo<ExpandableConfig<RcTableItem>>(() => {
      return {
        expandedRowRender: (record: RcTableItem) => (
          <CuzkMonitorRcTableExpandedRow
            className="pl-12"
            cuzkOs={record.cuzkOByCuzkOsId}
          />
        ),
        expandedRowKeys,
        columnTitle: (
          <ExpandColumnTitle
            allKeys={dataSource
              .filter(record => !!record.found)
              .map(record => record.id)}
            tableId={tableId}
          />
        ),
        expandIcon: ({ expanded, record }) => (
          <ExpandIcon
            expanded={expanded}
            recordId={record.id}
            tableId={tableId}
            expandable={!!record.found}
          />
        ),
      }
    }, [dataSource, expandedRowKeys])

    const rowSelection = useMemo(
      () => ({
        selectedRowKeys,
        onChange: (
          newSelectedRowKeys: React.Key[],
          selectedRows: RcTableItem[]
        ) => {
          setSelectedRowKeys(newSelectedRowKeys)
          setSelectedRow(selectedRows)
        },
      }),
      [selectedRowKeys]
    )

    const summary = useCallback(
      () => (
        <TableSummaryExport
          total={dataSource?.length || 0}
          colSpan={13}
          url="cuzk/cuzkOsXlsx"
          fileName="CUZK-osoby"
          dataSource={dataSource || []}
          icon
        />
      ),
      [dataSource]
    )

    return (
      <>
        <TableBulkEditPanel
          setLoadingId={setLoadingId}
          refetch={onBulkEdit}
          selectedRows={selectedRow}
          resetSelection={resetSelection}
        />
        <Table
          rowKey="id"
          rowClassName="animate-fadeIn"
          dataSource={[...dataSource]}
          columns={columns}
          loading={queryResult.fetching}
          expandable={expandable}
          locale={locale}
          rowSelection={rowSelection}
          size="small"
          summary={summary}
          pagination={tablePagination}
          scroll={scroll}
        />
      </>
    )
  }
