import { Form, Input } from 'antd'
import React, { FunctionComponent } from 'react'

import { cuzkEpoFormHelp } from '../../utils/cuzkMonitorRcFormHelp'
import { onlyNumbers } from '../../utils/formHelpers'

export interface CuzkEpoFormOfoFragmentProps {
  showHelp?: boolean
}

export const CuzkEpoFormOfoFragment: FunctionComponent<CuzkEpoFormOfoFragmentProps> =
  props => {
    return (
      <>
        <Form.Item
          label="Datum narození / RČ"
          name="birthDate"
          help={(props.showHelp && cuzkEpoFormHelp.datumNarozeni) || undefined}
          normalize={onlyNumbers}
          rules={[
            {
              validator: async (_, value) => {
                if (
                  value.length < 6 ||
                  value.length === 7 ||
                  value.length === 8 ||
                  value.length > 10
                ) {
                  throw new Error()
                }
              },
              required: true,
              message:
                'Zadejte dat. narození ve tvaru RRMMDD nebo RČ ve tvaru RRMMDDČČČČ.',
            },
          ]}
        >
          <Input
            placeholder="Ve tvaru RRMMDD / RRMMDDČČČČ"
            maxLength={11}
            autoFocus
          />
        </Form.Item>
        <Form.Item
          label="Jméno"
          name="firstname"
          rules={[
            {
              required: true,
              message: 'Zadejte křestní jméno hledané osoby',
            },
          ]}
        >
          <Input placeholder="Křestní jméno" />
        </Form.Item>
        <Form.Item
          label="Příjmení"
          name="lastname"
          rules={[
            {
              required: true,
              message: 'Zadejte příjmení hledané osoby',
            },
          ]}
        >
          <Input placeholder="Příjmení" />
        </Form.Item>
      </>
    )
  }
