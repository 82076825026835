import { Form, InputNumber, Radio } from 'antd'
import React, { FunctionComponent } from 'react'

import {
  MultiCheckboxSelect,
  MultiCheckboxSelectOptions,
} from '../MultiCheckboxSelect/MultiCheckboxSelect'

export const PriceEstimateAreaFragment: FunctionComponent = () => {
  return (
    <>
      <Form.Item
        name="typ_pozemku"
        label="Typ pozemku"
        rules={[
          {
            required: true,
            message: 'Vyberte typ pozemku',
          },
        ]}
      >
        <Radio.Group>
          <Radio value={0}>Stavební</Radio>
          <Radio value={1}>Orná půda</Radio>
          <Radio value={4}>Zahrada</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="pl_celkova"
        label="Celková plocha"
        rules={[
          {
            required: true,
            message: 'Celková plocha pozemku musí být vyplněna',
          },
        ]}
      >
        <InputNumber className="w-full" addonAfter="m²" min={1} />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prev, curr) => prev.typ_pozemku !== curr.typ_pozemku}
      >
        {({ getFieldValue }) => {
          const options: MultiCheckboxSelectOptions[] = [
            { label: 'Veřejné kanalizace', value: 0x0008 },
          ]
          const typ_pozemku = getFieldValue('typ_pozemku')
          if (typ_pozemku === 0) {
            options.push(
              { label: 'Samota/Polosamota', value: 0x0010 },
              { label: 'Přípojka plynu', value: 0x0004 }
            )
          } else if (typ_pozemku === 1) {
            options.push(
              { label: 'Přípojka elektřiny', value: 0x0002 },
              { label: 'Přípojka vodovodu', value: 0x0001 }
            )
          } else if (typ_pozemku === 4) {
            options.push(
              { label: 'Přípojka elektřiny', value: 0x0002 },
              { label: 'Přípojka plynu', value: 0x0004 }
            )
          }

          return (
            <MultiCheckboxSelect
              inline
              options={options}
              label="Vlastnosti pozemku"
              name={'reduced_flags'}
            />
          )
        }}
      </Form.Item>
    </>
  )
}
