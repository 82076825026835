import { UserOutlined } from '@ant-design/icons'
import { Icon } from '@iconify/react'
import React, { FunctionComponent, memo, useMemo } from 'react'

import { isCompanyName } from '../../utils/subjectHelpers'

export interface PropertyTimelineOwnershipTitleIconProps {
  subjectName: string
  sjmPartner?: boolean
}

const sjmIcon = (
  <div
    className="flex justify-center self-center mr-1 -ml-0.5"
    title="Společné jmění manželů"
  >
    <Icon icon="la:male" className="text-base" />
    <Icon icon="la:female" className="text-base -ml-2" />
  </div>
)
const companyIcon = (
  <span title="Právnická osoba" className="mr-1.5 pt-0.5 text-sm">
    <Icon
      icon="fluent:building-factory-16-regular"
      className="-mt-0.5 inline"
    />
  </span>
)
const personIcon = (
  <UserOutlined className="text-sm mr-1" title="Fyzická osoba" />
)

export const PropertyTimelineOwnershipTitleIcon: FunctionComponent<PropertyTimelineOwnershipTitleIconProps> =
  memo(props => {
    const icon = useMemo(() => {
      if (props.sjmPartner) return sjmIcon
      if (isCompanyName(props.subjectName)) return companyIcon
      return personIcon
    }, [props.sjmPartner, props.subjectName])

    return icon
  })
