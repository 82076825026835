import { Checkbox } from 'antd'
import React, { FunctionComponent } from 'react'

import { PropertyUse } from '../../../common/foreclosureMonitoringTypes'
import { NoResultBox } from '../NoResultBox/NoResultBox'
import { PropertyUseSelectionFilterCheckbox } from '../PropertyUseSelectionFilterCheckbox/PropertyUseSelectionFilterCheckbox'

export interface PropertyUseSelectionFilterProps {
  filteredUseTypes: PropertyUse[]
  disabledVisible?: boolean
  setCheckedValues: (value: React.SetStateAction<number[]>) => void
  checkedValues: number[]
  searchTerm: string
}

export const PropertyUseSelectionFilter: FunctionComponent<PropertyUseSelectionFilterProps> =
  props => {
    const groupClassName =
      props.filteredUseTypes?.length && props.filteredUseTypes.length > 10
        ? '!block columns-1 md:columns-2 2xl:columns-3 gap-8'
        : '!block columns-1 gap-8'

    if (!props.filteredUseTypes?.length) {
      return <NoResultBox />
    }

    return (
      <Checkbox.Group
        className={groupClassName}
        value={props.checkedValues}
        onChange={props.setCheckedValues as (value: unknown) => void}
      >
        {props.filteredUseTypes.map(useType => (
          <PropertyUseSelectionFilterCheckbox
            useType={useType}
            searchTerm={props.searchTerm}
            key={useType.kod}
          />
        ))}
      </Checkbox.Group>
    )
  }
