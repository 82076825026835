export const metrageProps = {
  grayscale: true,
  noData: '---',
  symbol: 'm²',
  decimalNumbers: 0,
}

export const copyAddressButton = {
  paragraphClassName:
    'opacity-0 group-hover:opacity-100 transition-opacity h-5 flex items-center',
  buttonsProps: {
    size: 'small' as const,
    type: 'link' as const,
    ghost: false,
    title: 'Kopírovat',
  },
  copyButtonText: ' ',
  copiedButtonText: ' ',
}
