import { Button, Space } from 'antd'
import React, { FunctionComponent, memo } from 'react'

import { PropertyMonitoringFilterResetButton } from '../PropertyMonitoringFilterResetButton/PropertyMonitoringFilterResetButton'

export interface ForeclosureMonitoringFilterSelectionButtonProps {
  selectedCount: number
  totalCount: number
  className?: string
  toggleOpen: () => void
  handleReset: () => void
}

export const PropertyMonitoringFilterSelectionButton: FunctionComponent<ForeclosureMonitoringFilterSelectionButtonProps> =
  memo(({ selectedCount, totalCount, className, handleReset, toggleOpen }) => {
    const isFiltered = selectedCount !== totalCount

    const text = `Vybráno ${
      isFiltered ? selectedCount : totalCount
    } z ${totalCount}`

    const style = `${className || ''} ${
      isFiltered
        ? '!bg-green-50 !text-green-900 hover:!text-primaryHover w-full md:w-auto'
        : 'w-full'
    }`
    return (
      <Space.Compact className="w-full md:w-40">
        <Button onClick={toggleOpen} className={style}>
          {text}
        </Button>

        {isFiltered && (
          <PropertyMonitoringFilterResetButton onClick={handleReset} />
        )}
      </Space.Compact>
    )
  })
