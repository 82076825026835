import Typography from 'antd/es/typography'
import React, { FunctionComponent, useMemo } from 'react'

export interface PageLayoutTitleProps {
  title?: React.ReactNode | string
  extra?: React.ReactNode
}

export const PageLayoutTitle: FunctionComponent<PageLayoutTitleProps> =
  props => {
    const title = useMemo(() => {
      return typeof props.title !== 'string' ? (
        props.title
      ) : (
        <Typography.Title style={{ marginBottom: 0 }} level={3}>
          {props.title}
        </Typography.Title>
      )
    }, [props.title])

    if (!props.title && !props.extra) {
      return null
    }

    return (
      <div className="flex flex-wrap justify-between mb-3">
        {title}
        {props.extra}
      </div>
    )
  }
