import { useCallback, useMemo } from 'react'

import { SubjectsInPostService } from '../../common/postServiceTypes'
import {
  RecipientsByUniquePersonKeysQuery,
  useRecipientsByUniquePersonKeysQuery,
} from '../../graphql/generated'
import { useAppSelector } from '../../redux/hooks'

export const useSubjectsInPostService = (uniquePersonKeys: string[]) => {
  const accountId = useAppSelector(state => state.myProfile.tenantId)
  const [queryResult, refetch] = useRecipientsByUniquePersonKeysQuery({
    variables: {
      accountId,
      uniquePersonKeys,
    },
  })

  const transformData = useCallback(
    (data: RecipientsByUniquePersonKeysQuery) => {
      if (
        !data?.allPostserviceRecipients?.nodes &&
        !data?.allPostserviceSentRecipients?.nodes
      ) {
        return {}
      }

      const transformedData = {} as Record<string, SubjectsInPostService>

      data.allPostserviceRecipients?.nodes?.forEach(node => {
        const uniquePersonKey = node?.uniquePersonKey
        if (uniquePersonKey && !transformedData[uniquePersonKey]) {
          transformedData[uniquePersonKey] = {
            list: [],
            letter: [],
          }
        }

        const list = node?.postserviceListByPostserviceListId
        if (list && uniquePersonKey) {
          const listData = {
            id: list.id || '',
            listName: list.listName || '',
            description: list.description || '',
            recipientId: node.id || '',
            labels: list.labels || [],
          }
          if (
            !transformedData[uniquePersonKey].list.some(
              l => l.id === listData.id
            )
          ) {
            transformedData[uniquePersonKey].list.push(listData)
          }
        }
      })

      data.allPostserviceSentRecipients?.nodes?.forEach(node => {
        const uniquePersonKey = node?.uniquePersonKey
        if (uniquePersonKey && !transformedData[uniquePersonKey]) {
          transformedData[uniquePersonKey] = {
            list: [],
            letter: [],
          }
        }

        const letter = node?.postserviceSentLetterBySentLetterId
        if (letter && !letter.deletedAt && uniquePersonKey) {
          const letterData = {
            id: letter.id || '',
            name: letter.name || '',
            description: letter.description || '',
            sentAt: letter.sentAt || undefined,
            postserviceType: letter.postserviceType || undefined,
            trackingNumber: node.postserviceTrackingNumber || undefined,
            postserviceId: node.postserviceId || undefined,
            sentRecipientId: node.id || '',
          }
          if (
            !transformedData[uniquePersonKey].letter.some(
              l => l.id === letterData.id
            )
          ) {
            transformedData[uniquePersonKey].letter.push(letterData)
          }
        }
      })

      return transformedData
    },
    []
  )

  const subjectPostService = useMemo(
    () => (queryResult.data ? transformData(queryResult.data) : null),
    [queryResult.data, transformData]
  )

  return {
    subjectPostService,
    refetch,
    loading: queryResult.fetching,
    error: queryResult.error,
  }
}
