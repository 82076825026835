import 'react-calendar-timeline/lib/Timeline.css'

import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { useAppSelector } from '../../../redux/hooks'
import {
  propertyTimelineEnd,
  propertyTimelineStart,
} from '../../utils/propertyTimelineHelpers'
import { useToggleState } from '../../utils/useToggleState'
import { PageLayoutTabContainer } from '../PageLayoutTabContainer/PageLayoutTabContainer'
import { PropertyDetailTimelineAlert } from '../PropertyDetailTimelineAlert/PropertyDetailTimelineAlert'
import { PropertyDetailTimelineControllPanel } from '../PropertyDetailTimelineControllPanel/PropertyDetailTimelineControllPanel'
import { PropertyDetailTimelineOwnership } from '../PropertyDetailTimelineOwnership/PropertyDetailTimelineOwnership'
import { PropertyDetailTimelineProceeding } from '../PropertyDetailTimelineProceeding/PropertyDetailTimelineProceeding'
import { PropertyDetailTimelineRestriction } from '../PropertyDetailTimelineRestriction/PropertyDetailTimelineRestriction'

export const PropertyDetailTimeline: FunctionComponent = () => {
  const rizeni = useAppSelector(state => state.property.timeline.data?.rizeni)
  const vlastnictvi = useAppSelector(
    state => state.property.timeline.data?.vlastnictvi
  )

  const [filtered, toggleFiltered] = useToggleState(true)
  const [[visibleTimeStart, visibleTimeEnd], setVisibleTime] = useState([
    propertyTimelineStart,
    propertyTimelineEnd,
  ])

  const count = useMemo(
    () => (vlastnictvi?.length || 0) + (rizeni?.length || 0),
    [vlastnictvi, rizeni]
  )

  const handleTimeChange = useCallback((start: number, end: number) => {
    setVisibleTime([start, end])
  }, [])

  return (
    <PageLayoutTabContainer>
      <div id="propertyTimeline" className="w-full">
        {count > 600 && (
          <PropertyDetailTimelineAlert
            filtered={filtered}
            toggleFiltered={toggleFiltered}
          />
        )}

        <PropertyDetailTimelineOwnership
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
          onTimeChange={handleTimeChange}
          showAll={!filtered || count < 600}
        />

        <PropertyDetailTimelineRestriction
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
          onTimeChange={handleTimeChange}
        />

        <PropertyDetailTimelineProceeding
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
          onTimeChange={handleTimeChange}
          showAll={!filtered || count < 600}
        />

        <PropertyDetailTimelineControllPanel
          visibleTimeEnd={visibleTimeEnd}
          visibleTimeStart={visibleTimeStart}
          setVisibleTime={setVisibleTime}
        />
      </div>
    </PageLayoutTabContainer>
  )
}
