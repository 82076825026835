import { Button, Space } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { DrmJednotkaResponse } from '../../../common/drmTypes'
import { LvApiResponse } from '../../../common/lvTypes'
import { useToggleState } from '../../utils/useToggleState'
import { Day } from '../DateFormat/DateFormat'

export interface PriceEstimateLayoutOwnershipRestrictionProps {
  lvData?: LvApiResponse & DrmJednotkaResponse & { error?: boolean }
  propertyId: string
}

export const PriceEstimateLayoutOwnershipRestriction: FunctionComponent<PriceEstimateLayoutOwnershipRestrictionProps> =
  props => {
    const [open, toggleOpen] = useToggleState(false)

    const property = useMemo(
      () =>
        [
          ...(props.lvData?.nemovitosti?.jednotky || []),
          ...(props.lvData?.nemovitosti?.budovy || []),
          ...(props.lvData?.nemovitosti?.parcely || []),
        ].find(nemovitost => nemovitost.id === props.propertyId),
      [props.lvData?.nemovitosti, props.propertyId]
    )

    if (!property) return null
    return (
      <>
        <div className="flex">
          <div className="w-1/3">Omezení vlastníckých práv:</div>
          <div className="w-2/3 font-semibold">
            {!property.omezeniVlPrav.exekuce &&
            !property.omezeniVlPrav.insolvence &&
            !property.omezeniVlPrav.zastavyNedobrovolne &&
            !property.omezeniVlPrav.zastavySmluvni ? (
              <div className="border text-gray-400 text-xs rounded-full px-2 py-0.5 inline-block">
                Bez omezení
              </div>
            ) : (
              <div className="flex justify-between">
                <Space>
                  {property.omezeniVlPrav.exekuce && (
                    <div className="bg-red-500 text-white text-xs rounded-full px-2 py-0.5">
                      Exekuce
                    </div>
                  )}
                  {property.omezeniVlPrav.insolvence && (
                    <div className="bg-red-500 text-white text-xs rounded-full px-2 py-0.5">
                      Insolvence
                    </div>
                  )}
                  {property.omezeniVlPrav.zastavyNedobrovolne && (
                    <div className="bg-red-500 text-white text-xs rounded-full px-2 py-0.5 whitespace-nowrap">
                      Zástavní právo nedobrovolné
                    </div>
                  )}
                  {property.omezeniVlPrav.zastavySmluvni && (
                    <div className="bg-amber-500 text-white text-xs rounded-full px-2 py-0.5 whitespace-nowrap">
                      Zástava smluvní
                    </div>
                  )}
                </Space>
                <Button type="link" onClick={toggleOpen}>
                  {open ? 'Skrýt' : 'Zobrazit více'}
                </Button>
              </div>
            )}

            {!!props.lvData?.omezeniVlastnickehoPrava?.length && open && (
              <div className="mt-2">
                {props.lvData?.omezeniVlastnickehoPrava.map(
                  (omezeni, index) => (
                    <div key={index} className="flex justify-between">
                      <span>{omezeni.omezeni}</span>
                      <span className="font-normal text-gray-400">
                        {' '}
                        Platí od: <Day>{omezeni.platiOd}</Day>
                      </span>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
        {props.lvData?.jineZapisy && open && (
          <div className="flex">
            <div className="w-1/3">Jiné zápisy:</div>
            <div className="w-2/3 font-semibold whitespace-pre">
              {props.lvData?.jineZapisy}
            </div>
          </div>
        )}

        {!!props.lvData?.plombyRizeni.length && (
          <div className="flex">
            <div className="w-1/3">Jednotka dotčena změnou pr. vztahů:</div>
            <div className="w-2/3 font-semibold">
              {props.lvData?.plombyRizeni.map((plomba, index) => (
                <span key={index} className="pr-2">
                  <a
                    target="_blank"
                    title="Otevřít řízení v novém okně"
                    href={`https://data2.regesta.cz/vyhledavani/rizeni/${plomba.replace(
                      '/',
                      '-'
                    )}`}
                    rel="noreferrer"
                  >
                    {plomba}
                  </a>
                </span>
              ))}
            </div>
          </div>
        )}
      </>
    )
  }
