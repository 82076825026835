import React, { FunctionComponent } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { TABLE_VIEW } from '../../../common/foreclosureMonitoringTypes'
import { MonitoringInsolvenciFetchResult } from '../../../common/insolvencyMonitoringTypes'
import { useFetchInsolvencyMonitoring } from '../../utils/useFetchInsolvencyMonitoring'
import { useInsolvencyMonitoringFilter } from '../../utils/useInsolvencyMonitoringFilter'
import { InsolvencyMonitoringContent } from '../InsolvencyMonitoringContent/InsolvencyMonitoringContent'
import { InsolvencyMonitoringFilter } from '../InsolvencyMonitoringFilter/InsolvencyMonitoringFilter'
import { PageLayoutTabContainer } from '../PageLayoutTabContainer/PageLayoutTabContainer'
import { PropertyMonitoringTablePagination } from '../PropertyMonitoringTablePagination/PropertyMonitoringTablePagination'

export interface InsolvencyMonitoringTabProps {
  viewType: TABLE_VIEW
  propertyType: PROPERTY_TYPES
}

export const InsolvencyMonitoringTab: FunctionComponent<InsolvencyMonitoringTabProps> =
  props => {
    const { filter, filterQuery, handleChange } = useInsolvencyMonitoringFilter(
      props.propertyType
    )

    const dataSource = useFetchInsolvencyMonitoring(
      filterQuery
    ) as MonitoringInsolvenciFetchResult

    return (
      <PageLayoutTabContainer>
        <InsolvencyMonitoringFilter
          propertyType={props.propertyType}
          filter={filter}
          handleChange={handleChange}
        />

        <InsolvencyMonitoringContent
          dataSource={dataSource}
          viewType={props.viewType}
          propertyType={props.propertyType}
        />

        <PropertyMonitoringTablePagination
          changePage={handleChange}
          currentPage={filter.stranka}
          hasNextPage={!!dataSource.data?.dalsiStranka}
        />
      </PageLayoutTabContainer>
    )
  }
