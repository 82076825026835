import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import axios from 'axios'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { Rizeni } from '../../../common/proceedingTypes'
import {
  WatchdogChangeDetail,
  WatchdogChangeDetailProcessed,
  WatchdogChangesApiResponse,
} from '../../../common/watchdogTypes'
import { UserActivityActionType } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { utcDateSorter } from '../../utils/generalTableHelpers'
import { parseProceedingString } from '../../utils/proceedingDetailHelpers'
import { useUserActivityLogCheck } from '../../utils/useCheckUserActivityLog'
import {
  activityEntityType,
  seenRowClassName,
} from '../../utils/userActivityLogHelpers'
import { useSetPropertyDrawer } from '../../utils/useSetPropertyDrawer'
import { useUpdateUserActivityLog } from '../../utils/useUpdateUserActivityLog'
import { watchdogParsedChanges } from '../../utils/watchdogHelpers'
import { Day } from '../DateFormat/DateFormat'
import { ProceedingDetailModalLink } from '../ProceedingDetailModalLink/ProceedingDetailModalLink'
import { PropertyDetailFetchError } from '../PropertyDetailFetchError/PropertyDetailFetchError'

export interface WatchdogPropertyTableExpandedRowProps {
  propertyId: string
  propertyType: PROPERTY_TYPES
}

export const WatchdogPropertyTableExpandedRow: FunctionComponent<WatchdogPropertyTableExpandedRowProps> =
  props => {
    const setDrawer = useSetPropertyDrawer()
    const propertiesActivity = useUserActivityLogCheck([props.propertyId])
    const updateUserActivityLog = useUpdateUserActivityLog()
    const token = useAppSelector(state => state.myProfile.token)

    const [data, setData] = useState<WatchdogChangeDetail[] | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState(false)

    const isFetched = useRef<boolean>(false)
    const lastCheck = useRef<undefined | Date>()

    useEffect(() => {
      const fetchData = async () => {
        if (!isFetched.current) {
          isFetched.current = true
          setLoading(true)
          try {
            const response = await axios.get<WatchdogChangesApiResponse>(
              `https://data.regesta.cz/DrmApi/api/v2.0/Hlidani/InfoServis?access_token=${token}&nemovitostId=${props.propertyId}&pouzeNeprectene=false`
            )
            setData(response.data.data)
            lastCheck.current =
              propertiesActivity?.[props.propertyId]?.editedAt || new Date()

            updateUserActivityLog(
              props.propertyId,
              activityEntityType[props.propertyType],
              UserActivityActionType.SeenDetail
            )
          } catch (error) {
            setError(true)
          } finally {
            setLoading(false)
          }
        }
      }

      fetchData()
    }, [
      propertiesActivity,
      props.propertyId,
      props.propertyType,
      token,
      updateUserActivityLog,
    ])

    const mergedData = useMemo(() => {
      if (!data) return []
      return watchdogParsedChanges(data)
    }, [data])

    const columns = useMemo<ColumnsType<WatchdogChangeDetailProcessed>>(
      () => [
        {
          title: 'Datum',
          dataIndex: 'utcVytvoreno',
          className: 'w-24',
          sorter: utcDateSorter('utcVytvoreno'),
          render: utcVytvoreno => <Day>{utcVytvoreno}</Day>,
        },
        {
          title: 'Nemovitost',
          dataIndex: ['nemovitost', 'nazev'],
          className: 'lg:w-40 first-letter:uppercase',
          render: (name, record) => (
            <span
              className="text-primary hover:text-primaryHover cursor-pointer lg:whitespace-nowrap"
              title="Zobrazit detail nemovitosti"
              onClick={() =>
                setDrawer(
                  record.nemovitost.id,
                  record.nemovitost.typ.toLowerCase() as PROPERTY_TYPES
                )
              }
            >
              {name}
            </span>
          ),
        },
        {
          title: 'Řízení',
          dataIndex: 'proceeding',
          className: ' lg:w-36',
          render: (proceedings: (string | null)[]) => {
            return proceedings.map((proceeding, index) => (
              <span key={index}>
                {proceeding ? (
                  <ProceedingDetailModalLink
                    proceeding={parseProceedingString(proceeding) as Rizeni}
                  >
                    {proceeding}
                  </ProceedingDetailModalLink>
                ) : (
                  ''
                )}
                {index < proceedings.length - 1 && <br />}
              </span>
            ))
          },
        },
        {
          title: 'Událost',
          dataIndex: 'title',
          className: 'lg:w-36',
          render: (titles: (string | null)[]) =>
            titles.map((title, index) => (
              <span key={index} className="whitespace-nowrap">
                {title || ''}
                {index < titles.length - 1 && <br />}
              </span>
            )),
        },
        {
          title: 'Popis',
          dataIndex: 'description',
          render: (descriptions: (string | null)[]) =>
            descriptions.map((text, index) => {
              return (
                <span key={index}>
                  {text}
                  {index < descriptions.length - 1 && <br />}
                </span>
              )
            }),
        },
      ],
      [setDrawer]
    )

    const rowClassName = useCallback(
      (record: WatchdogChangeDetailProcessed) =>
        seenRowClassName(lastCheck.current, record.utcVytvoreno) +
        ' align-baseline',
      [lastCheck]
    )

    if (error)
      return (
        <PropertyDetailFetchError
          description="Je nám líto, ale nepodařilo se načíst údaje z hlídače katastru. Opakujte prosím akci později."
          message="Chyba při načítání dat"
        />
      )

    if (!mergedData.length && !loading)
      return (
        <div className="text-center p-4 my-4 text-gray-400">
          Od vložení do hlídače neproběhly na nemovitosti žádné změny.
        </div>
      )

    return (
      <Table
        rowKey="id"
        size="small"
        className="mb-4"
        rowClassName={rowClassName}
        columns={columns}
        dataSource={mergedData}
        loading={loading}
        pagination={false}
      />
    )
  }
