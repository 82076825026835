import { Button, Form, message } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { safeJsonParsing } from '../../../common/safeJsonParsing'
import { useUpdatePropertyNoteByIdMutation } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import {
  PropertyDetailNote,
  PropertyNoteFormValues,
  validateUrl,
} from '../../utils/propertyDetailNoteHelpers'
import { useToggleState } from '../../utils/useToggleState'
import { NoteIconSettingButton } from '../NoteIconSettingButton/NoteIconSettingButton'
import { PropertyNoteFormBaseFragment } from '../PropertyNoteFormBaseFragment/PropertyNoteFormBaseFragment'

export interface PropertyDetailNoteEditProps {
  refetch?: () => void
  onCancel?: () => void
  initialValues: PropertyDetailNote & { id: string }
}

export const PropertyDetailNoteEdit: FunctionComponent<PropertyDetailNoteEditProps> =
  props => {
    const accountUserId = useAppSelector(state => state.myProfile.userId)

    const [, updateNote] = useUpdatePropertyNoteByIdMutation()
    const [loading, , startLoading, stopLoading] = useToggleState()

    const [form] = Form.useForm()
    const iconStyle = Form.useWatch('iconKey', form)
    const colorStyle = Form.useWatch('colorKey', form)

    const initialValues = useMemo(() => {
      return {
        ...props.initialValues,
        links: safeJsonParsing<{ title?: string; url: string }[]>(
          props.initialValues.links,
          []
        ),
      }
    }, [props.initialValues])

    const onStyleChange = useCallback(
      (style: { colorKey?: string; iconKey?: string }) => {
        form.setFieldsValue({
          colorKey: style.colorKey,
          iconKey: style.iconKey,
        })
      },
      [form]
    )

    const onFinish = useCallback(
      async (formValues: PropertyNoteFormValues) => {
        startLoading()
        try {
          const result = await updateNote({
            id: props.initialValues.id,
            colorKey: formValues.colorKey,
            iconKey: formValues.iconKey,
            note: formValues.note,
            links: validateUrl(formValues.links),
          })
          const success = result.data?.updatePropertyNoteById?.propertyNote?.id

          if (!success) {
            stopLoading()
            return message.error('Nepodařilo se upravit poznámku')
          }
        } catch (error) {
          message.error('Při úpravě poznámky nastala chyba.')
        } finally {
          message.success('Poznámka upravena.')
          form.resetFields()
          props.onCancel?.()
          props.refetch?.()
          stopLoading()
        }
        return
      },
      [startLoading, updateNote, props, stopLoading, form]
    )

    if (
      !props.initialValues ||
      props.initialValues.accountUserId !== accountUserId
    )
      return null

    return (
      <div className="w-full flex relative">
        <div className="relative">
          <div className="absolute -right-8 top-1 w-16 flex justify-center bg-white">
            <NoteIconSettingButton
              onStyleChange={onStyleChange}
              pickedColor={colorStyle}
              pickedIcon={iconStyle}
            />
          </div>
        </div>
        <div className="pl-8 w-full">
          <Form<PropertyNoteFormValues>
            onFinish={onFinish}
            layout="vertical"
            form={form}
            className="mt-4"
            initialValues={initialValues}
          >
            <PropertyNoteFormBaseFragment submit={form.submit} />

            <Form.Item className="flex !mb-0 !mt-6 justify-end">
              <Button
                className="mr-2"
                type="primary"
                ghost
                onClick={props.onCancel}
              >
                Zavřít
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Uložit změny
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    )
  }
