import { Space } from 'antd'
import React, { FunctionComponent, memo } from 'react'

import { OmezeniVlPrav } from '../../../common/lvTypes'
import {
  RestrictionBadge,
  RestrictionType,
} from '../RestrictionBadge/RestrictionBadge'

export interface PropertyDetailOwnershipRestrictionCellProps {
  restriction: OmezeniVlPrav
}

export const PropertyDetailOwnershipRestrictionCell: FunctionComponent<PropertyDetailOwnershipRestrictionCellProps> =
  memo(({ restriction }) => {
    return (
      <Space wrap>
        <RestrictionBadge
          type={RestrictionType.Execution}
          show={restriction.exekuce}
        />
        <RestrictionBadge
          type={RestrictionType.Insolvency}
          show={restriction.insolvence}
        />
        <RestrictionBadge
          type={RestrictionType.InvoluntaryLien}
          show={restriction.zastavyNedobrovolne}
        />
        <RestrictionBadge
          type={RestrictionType.VoluntaryLien}
          show={restriction.zastavySmluvni}
        />
        <RestrictionBadge
          type={RestrictionType.PreemptionRight}
          show={restriction.predkupniPravo}
        />
        <RestrictionBadge
          type={RestrictionType.NoRestriction}
          show={
            !restriction.exekuce &&
            !restriction.insolvence &&
            !restriction.zastavyNedobrovolne &&
            !restriction.zastavySmluvni &&
            !restriction.predkupniPravo
          }
        />
      </Space>
    )
  })
