import { AimOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { FunctionComponent } from 'react'

import { Money } from '../Money/Money'

export interface AddressDistanceProps {
  distance?: number | null
  name?: string
  tooltipTitle?: React.ReactNode
  hereTooltip?: React.ReactNode
}

export const AddressDistance: FunctionComponent<AddressDistanceProps> =
  props => {
    if (typeof props.distance !== 'number') return null
    if (props.distance === 0) {
      return (
        <Tooltip
          title={
            props.hereTooltip ||
            `${props.name} má zapsáno sídlo na adrese budovy, kterou si právě prohlížíte.`
          }
        >
          <AimOutlined className="text-gray-400" />
        </Tooltip>
      )
    }

    const isShortDistance = props.distance < 100
    const formattedDistance = isShortDistance
      ? props.distance
      : props.distance / 1000
    const symbol = isShortDistance ? 'm' : 'km'
    const decimalNumbers = isShortDistance ? 0 : 1
    const distanceTooltipText =
      props.tooltipTitle ||
      `${props.name} aktuálně sídlí na adrese, která je ${
        isShortDistance
          ? `vzdálena ${formattedDistance}${symbol}`
          : `vzdálena ${formattedDistance.toFixed(1)}${symbol}`
      }.`

    return (
      <Tooltip title={distanceTooltipText}>
        <span className="text-gray-400">
          <Money
            amount={formattedDistance}
            decimalNumbers={decimalNumbers}
            symbol={symbol}
          />
        </span>
      </Tooltip>
    )
  }
