import { Switch, Tooltip } from 'antd'
import React, { FunctionComponent } from 'react'

export interface ShowHistorySwitchProps {
  showHistory: boolean
  setShowHistory: (showHistory: boolean) => void
  disabled?: boolean
}

const disabledTooltipProps = {
  className: '!opacity-40 flex items-center space-x-2',
  title: 'Neevidujeme žádné dodatečné historické údaje.',
}
const tooltipProps = {
  className: 'flex items-center space-x-2',
  title: 'Umožňuje zobrazit historická data, která již nejsou nadále platná.',
}

export const ShowHistorySwitch: FunctionComponent<ShowHistorySwitchProps> =
  props => {
    return (
      <Tooltip {...(props.disabled ? disabledTooltipProps : tooltipProps)}>
        <span>Zobrazit historii:</span>
        <Switch
          size="small"
          className="!flex"
          disabled={props.disabled}
          checked={props.showHistory}
          onChange={props.setShowHistory}
        />
      </Tooltip>
    )
  }
