import React from 'react'

import { PROPERTY_TYPES } from '../../common/drmTypes'
import {
  WatchdogChangeDetail,
  WatchdogPropertyFilter,
} from '../../common/watchdogTypes'
import { WatchdogButton } from '../components/WatchdogButton/WatchdogButton'

const watchdogColumn = {
  dataIndex: 'id',
  key: 'watchdog',
  className: 'w-8 !p-0',
}

export const watchdogBuildingColumn = {
  ...watchdogColumn,
  render: (id: string) => (
    <WatchdogButton propertyType={PROPERTY_TYPES.BUILDING} propertyId={id} />
  ),
}

export const watchdogAreaColumn = {
  ...watchdogColumn,
  render: (id: string) => (
    <WatchdogButton propertyType={PROPERTY_TYPES.AREA} propertyId={id} />
  ),
}

export const watchdogUnitColumn = {
  ...watchdogColumn,
  render: (id: string) => (
    <WatchdogButton propertyType={PROPERTY_TYPES.UNIT} propertyId={id} />
  ),
}

export const watchdogFormRules = {
  email: [
    {
      type: 'email' as const,
      message: 'Nevalidní formát e-mailu.',
    },
    {
      required: true,
      message: 'Zadejte e-mailovou adresu.',
    },
  ],
  frequency: [
    {
      type: 'number' as const,
      max: 365,
      message: '365 dní je maximální přípustná frekvence.',
    },
    {
      required: true,
      message: 'Zadejte frekvenci notifikací.',
    },
  ],
}

export const watchdogFormHelp = {
  email: 'E-mailová adresa, na kterou budou zasílány notifikace o změnách.',
  allowEmpty:
    'Zasílat e-maily i v případě, že u hlídaných nemovitostí nedošlo k žádným změnám.',
  frequency:
    'Frekvence, jak často chcete dostávat e-mail s přehledem nových změn u nemovitostí.',
}

export const watchdogBaseChannel = {
  allChannelId: '_allChannelId_',
  noChannelId: '_noChannelId_',
}

export const watchdogFilterDefaultValues: WatchdogPropertyFilter = {
  channelId: '_allChannelId_',
  propertyType: [
    PROPERTY_TYPES.AREA,
    PROPERTY_TYPES.BUILDING,
    PROPERTY_TYPES.UNIT,
  ],
}
export const watchdogFilterTypeOptions = [
  {
    value: PROPERTY_TYPES.AREA,
    label: 'Parcely',
  },
  {
    value: PROPERTY_TYPES.BUILDING,
    label: 'Budovy',
  },
  {
    value: PROPERTY_TYPES.UNIT,
    label: 'Jednotky',
  },
]

export const watchdogParsedChanges = (data: WatchdogChangeDetail[]) =>
  data.map(item => {
    const lines =
      item.text?.split('\r\n').filter(line => line.trim() !== '') || []

    const proceedings: (string | null | undefined)[] = []
    const titles: (string | null)[] = []
    const descriptions: (string | null)[] = []

    lines.forEach(line => {
      const rizeniPattern = /\p{L}{1,3}-[0-9]{1,8}\/[0-9]{4}-[0-9]{2,3}/gu
      const rizeniMatches = line.match(rizeniPattern) || []
      const proceeding = rizeniMatches.length > 0 ? rizeniMatches[0] : null

      const [titlePart, ...descriptionParts] = line.split(':')
      const description = descriptionParts.join(':').trim()

      titles.push(titlePart.trim() || null)
      descriptions.push(description || null)
      proceedings.push(proceeding)
    })

    return {
      ...item,
      proceeding: proceedings,
      title: titles,
      description: descriptions,
    }
  })
