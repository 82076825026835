import React, { FunctionComponent, useState } from 'react'

import { CuzkEpoModalButton } from '../components/CuzkEpoModalButton/CuzkEpoModalButton'
import { CuzkEpoTable } from '../components/CuzkEpoTable/CuzkEpoTable'
import { PageLayout } from '../components/PageLayout/PageLayout'

export const CuzkEpo: FunctionComponent = () => {
  const [refetchState, setRefetchState] = useState(false)

  return (
    <PageLayout
      isNew
      title="Evidence práv pro osobu"
      extra={<CuzkEpoModalButton refetch={setRefetchState} />}
    >
      <CuzkEpoTable
        refetchState={refetchState}
        setRefetchState={setRefetchState}
      />
    </PageLayout>
  )
}

export default CuzkEpo
