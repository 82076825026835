import React, { FunctionComponent } from 'react'

import { RestrictionChecType } from '../../../common/epoTypes'
import { CadastralOffice } from '../../utils/cuzkHelpers'
import { CadastralSelectionModalButton } from '../CadastralSelectionModalButton/CadastralSelectionModalButton'

export interface CuzkEpoRestrictionCheckPanelSearchLienProps {
  epoId: string
  refetch: () => void
  checkboxData: CadastralOffice[]
}

export const CuzkEpoRestrictionCheckPanelSearchLien: FunctionComponent<CuzkEpoRestrictionCheckPanelSearchLienProps> =
  props => {
    return (
      <>
        <div className="mb-2 mt-2 text-center text-base font-bold">
          Zobrazení LV s právy 3. stran
        </div>
        <div className="mb-2 mt-2 text-center text-gray-400">
          Vyberte LV, pro které chcete načíst detailní informace o právech 3.
          stran.
        </div>
        <CadastralSelectionModalButton
          epoId={props.epoId}
          checkboxData={props.checkboxData}
          refetch={props.refetch}
          type={RestrictionChecType.lien}
        />
      </>
    )
  }
