import React, { FunctionComponent } from 'react'

import { OneLineHelp } from '../OneLineHelp/OneLineHelp'

export const PostServiceLetterImageHelp: FunctionComponent = () => {
  return (
    <OneLineHelp
      titleClose="Důležité informace k nastavení obrázku...&nbsp;"
      classNameClose="w-max"
    >
      <ul className="ml-4 list-disc">
        <li>
          <span className="font-semibold">Šířka obrázku</span> se nastavuje jako
          procentuální poměr vůči šířce tiskové plochy papíru, zatímco výška se
          nastaví automaticky tak, aby byl zachován původní poměr stran.
        </li>
        <li>Maximální šířka je 100 % tiskové plochy.</li>
        <li>
          Pokud zvolíte možnost <span className="font-semibold">pozadí</span>,
          budete moct upravovat pozici obrázku prostřednictvím odsazení od
          původního umístění v dopise.
        </li>
        <li>
          Odsazení pozadí se udává v cm, lze zadávat desetinné hodnoty i záporná
          čísla (nezadávejte příliš vysoké hodnoty, abyste předešli odsazení
          mimo tisknutelnou plochu)
        </li>
        <li>Pozadí lze překrýt textem či jiným obsahem.</li>
        <li>
          Doporučujeme nahrávat obrázky v{' '}
          <span className="font-semibold">dostatečném rozlišení</span>, aby při
          tisku nedošlo ke ztrátě kvality.
        </li>
        <li>
          Velikost nesmí přesáhnout <span className="font-semibold">5 MB</span>{' '}
          kvůli datovému omezení české pošty.
        </li>
      </ul>
    </OneLineHelp>
  )
}
