import Button from 'antd/es/button'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { CuzkMonitorRcTable } from '../components/CuzkMonitorRcTable/CuzkMonitorRcTable'
import { PageLayout } from '../components/PageLayout/PageLayout'
import { cuzkMonitorRcCreatePath } from '../utils/paths'

export const CuzkMonitorRc: FunctionComponent = () => {
  return (
    <PageLayout
      title="Vyhledání subjektů v evidenci ČÚZK"
      extra={
        <Link to={cuzkMonitorRcCreatePath()}>
          <Button type="primary">Vyhledat subjekt</Button>
        </Link>
      }
    >
      <CuzkMonitorRcTable />
    </PageLayout>
  )
}

export default CuzkMonitorRc
