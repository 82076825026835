import { useMemo } from 'react'

import { GenderType } from '../../common/cuzkMonitorTypes'

export const useRcNumberData = (rcNumber?: string | null) => {
  return useMemo(() => {
    if (!rcNumber)
      return {
        gender: undefined,
        age: undefined,
        birthDate: undefined,
        birthYear: undefined,
      }

    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth() + 1
    const currentDay = currentDate.getDate()

    const birthYearShort = parseInt(rcNumber.slice(0, 2), 10)
    let birthMonth = parseInt(rcNumber.slice(2, 4), 10)
    const birthDay = parseInt(rcNumber.slice(4, 6), 10)

    let gender = ''
    if (birthMonth >= 1 && birthMonth <= 12) {
      gender = GenderType.M
    } else if (birthMonth >= 21 && birthMonth <= 32) {
      gender = GenderType.M
      birthMonth -= 20
    } else if (birthMonth >= 51 && birthMonth <= 62) {
      gender = GenderType.W
      birthMonth -= 50
    } else if (birthMonth >= 71 && birthMonth <= 82) {
      gender = GenderType.W
      birthMonth -= 70
    }

    let age = currentYear - (1900 + birthYearShort)
    let birthYear = 1900 + birthYearShort

    if (
      birthMonth > currentMonth ||
      (birthMonth === currentMonth && birthDay > currentDay)
    ) {
      age--
    }

    if (currentYear - birthYear >= 100) {
      birthYear += 100
      age -= 100
    }

    const birthDate = `${birthDay < 10 ? '0' + birthDay : birthDay}.${
      birthMonth < 10 ? '0' + birthMonth : birthMonth
    }.${birthYear}`

    return {
      birthDate,
      birthYear,
      age,
      gender,
    }
  }, [rcNumber])
}
