import {
  MonitDecompData,
  object_flags_values,
  other_flags,
  prislusenstvi_fl_values,
  reduced_flags_values,
} from '../../common/monitTypes'
import { formatCurrencyNumber } from '../components/Money/Money'

export const getMonitValue = <T>(
  map: T,
  key: keyof T
): T[keyof T] | undefined => map[key]

export const getMonitFlags = (
  value: number,
  flagsList: {
    [key: number]: string
  }
) => {
  const list: string[] = []
  for (const bit in flagsList) {
    if (value & parseInt(bit)) {
      list.push(flagsList[bit])
    }
  }
  return list.join(', ')
}

export const priceCoefficient = (decomp?: MonitDecompData[]): number =>
  decomp?.reduce(
    (acc, { tag, mask, value }) => (tag || mask ? acc * value : acc),
    1
  ) || 1

export const multipliersList = (
  decomp?: MonitDecompData[]
): Record<string, string> | null => {
  if (!decomp) return null

  const multipliers: Record<string, string> = {}

  decomp?.forEach(item => {
    if (item.value !== 1) {
      if (!item.tag) {
        if (!item.mask) {
          multipliers['základní cena'] = formatCurrencyNumber(item.value, 0)
        }
      } else {
        let tagValue
        switch (item.tag) {
          case 'reduced_flags':
            tagValue =
              reduced_flags_values[
                item.mask as keyof typeof reduced_flags_values
              ]
            break
          case 'prislusenstvi_fl':
            tagValue =
              prislusenstvi_fl_values[
                item.mask as keyof typeof prislusenstvi_fl_values
              ]
            break
          case 'object_flags':
            tagValue =
              object_flags_values[item.mask as keyof typeof object_flags_values]
            break
          default:
            tagValue = other_flags[item.tag as keyof typeof other_flags]
            break
        }
        if (tagValue) {
          multipliers[tagValue] = item.value.toFixed(4)
        } else if (item.tag && !item.mask) {
          multipliers[item.tag] = item.value.toFixed(4)
        }
      }
    }
  })

  return multipliers
}
