import { DatePicker, Form, InputNumber, Select } from 'antd'
import locale from 'antd/es/date-picker/locale/cs_CZ'
import React, { FunctionComponent } from 'react'

import { czDateFormatDatePickerDay } from '../../utils/dateFormat'

export const datePickerCsLocale = {
  ...locale,
  lang: {
    ...locale?.lang,
    shortWeekDays: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
    shortMonths: [
      'Led',
      'Úno',
      'Bře',
      'Dub',
      'Kvě',
      'Čer',
      'Čvc',
      'Srp',
      'Zář',
      'Říj',
      'Lis',
      'Pro',
    ],
  },
}

export const PriceEstimateBaseFragment: FunctionComponent = () => {
  const { Option } = Select

  return (
    <>
      <Form.Item name="kod_obce" label="Kód obce" required hidden>
        <InputNumber className="w-full" disabled />
      </Form.Item>
      <Form.Item name="lat" label="Lat" required hidden>
        <InputNumber className="w-full" disabled />
      </Form.Item>
      <Form.Item name="lon" label="Lon" required hidden>
        <InputNumber className="w-full" disabled />
      </Form.Item>
      <Form.Item name="typ_nemovitosti" label="Typ nemovitosti" required hidden>
        <Select className="w-full" disabled>
          <Option value={0}>Pozemek</Option>
          <Option value={1}>Dům</Option>
          <Option value={2}>Byt</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="datum"
        label="K datu"
        rules={[
          {
            required: true,
            message: 'Datum musí být vyplněno',
          },
        ]}
      >
        <DatePicker
          disabledDate={current => current.isAfter(new Date())}
          format={czDateFormatDatePickerDay}
          allowClear={false}
          className="w-full"
          locale={datePickerCsLocale}
        />
      </Form.Item>
    </>
  )
}
