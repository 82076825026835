import {
  DoubleLeftOutlined,
  PlusCircleOutlined,
  StarFilled,
} from '@ant-design/icons'
import { Col, Row, Timeline } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { PageLayout } from '../components/PageLayout/PageLayout'
import { timelineData } from '../utils/timelineData'

export const Dashboard: FunctionComponent = () => {
  const data = useMemo(
    () =>
      timelineData.map((item, index) => {
        if (index === 0)
          return {
            dot: (
              <DoubleLeftOutlined
                className="animate-pulse text-gray-400"
                rotate={90}
              />
            ),

            children: (
              <div className="text-gray-400 bg-clip-text text-transparent bg-gradient-to-b from-white to-gray-400 pt-4">
                <div className="font-semibold absolute -left-36 w-28 pr-2 text-right bg-clip-text text-transparent bg-gradient-to-b from-white to-gray-400">
                  Připravujeme
                  <div className="font-normal">({item.date})</div>
                </div>
                <div className="font-semibold">{item.title}</div>
                <div className="text-justify">{item.description}</div>
              </div>
            ),
          }
        return {
          dot: item.dot ? (
            item.dot
          ) : item.module ? (
            <StarFilled />
          ) : (
            <PlusCircleOutlined />
          ),
          children: (
            <div>
              <div className="font-semibold absolute -left-36 w-28 pr-2 text-right">
                {item.date}
              </div>
              <div className="font-semibold">{item.title}</div>
              <div className="text-gray-400 text-justify">
                {item.description}
              </div>
            </div>
          ),
        }
      }),
    []
  )
  return (
    <PageLayout title="">
      <Row justify="center" className="w-full" gutter={24}>
        <Col span={12} className="px-8">
          <div className="flex justify-center mt-10">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              viewBox="0 0 43 45"
              className="w-16 fill-white p-2 bg-primaryHover rounded-md"
            >
              <path
                fillRule="evenodd"
                d="M0 0v12h7l1-2V8h19a7 7 0 0 1 2 13l2 3 3 3v-1c3-2 5-6 5-9A15 15 0 0 0 27 0H0m0 18v27a228 228 0 0 0 16-16 134 134 0 0 1 12 11l5 5h10L16 18a124 124 0 0 0-8 9v-9H0m13 20v2l2 1h1v-3h-3m4 0v3h1l2-1v-2h-3m-4 4v3h3v-3h-3m4 3h3v-3h-3"
              />
            </svg>
          </div>
          <div className="text-center uppercase mt-4 font-extrabold text-gray-400 text-3xl">
            REGESTA DATA 2.0
          </div>
          <div className="text-center text-gray-400 mb-14">
            nová etapa naší aplikace
          </div>
          <div className="ext-center pl-48 bg-gradient-to-b">
            <Timeline mode="left" items={data} />
          </div>
        </Col>
      </Row>
    </PageLayout>
  )
}

export default Dashboard
