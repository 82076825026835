import React, { FunctionComponent } from 'react'

import { useAppSelector } from '../../../redux/hooks'
import { TabLabel } from '../TabLabel/TabLabel'

export const TabLabelProceeding: FunctionComponent = () => {
  const loading = useAppSelector(state => state.property.timeline.fetching)
  const proceeding = useAppSelector(
    state => state.property.timeline.data?.rizeni?.length
  )

  return (
    <TabLabel
      loading={loading}
      tooltip={proceeding === 0 && 'Neevidujeme žádná katastrální řízení.'}
      title="Řízení"
    />
  )
}
