import { Breadcrumb } from 'antd'
import React, { FunctionComponent, useEffect, useMemo } from 'react'
import { Link, matchPath, useRouteMatch } from 'react-router-dom'

import { BASE_URL } from '../../../../ini.json'
import { paths } from '../../utils/paths'
import { CopyButton } from '../CopyButton/CopyButton'

export interface AntBreadcrumbProps {
  currentPath: string
}

export const AntBreadcrumb: FunctionComponent<AntBreadcrumbProps> = props => {
  const {
    params: { id: id, urlQuery: urlQuery },
    url,
  } = useRouteMatch<{ id: string; urlQuery: string }>()

  const CopyButtonProps = useMemo(
    () => ({
      copyText: BASE_URL + url,
      paragraphStyle: { marginLeft: '4px' },
      paragraphClassName:
        'group opacity-50 hover:opacity-100 transition-opacity',
      buttonsTextClassName:
        'opacity-0 group-hover:opacity-100 transition-opacity',
      buttonsProps: {
        size: 'small' as const,
        type: 'link' as const,
        ghost: false,
      },
      copyButtonText: 'Kopírovat URL',
    }),
    [url]
  )

  const breadcrumbData = useMemo(() => {
    return paths
      .map(path =>
        matchPath(props.currentPath, {
          path: path.path,
        })
          ? path
          : null
      )
      .filter(path => path?.name)
  }, [props.currentPath])

  useEffect(() => {
    if (breadcrumbData[breadcrumbData.length - 1]?.title) {
      document.title =
        breadcrumbData[breadcrumbData.length - 1]?.title + ' - REGESTA DATA 2.0'
    }
    return
  }, [breadcrumbData])

  return (
    <div className="flex">
      <Breadcrumb className="my-4 print:hidden">
        {breadcrumbData.map((item, index) => {
          const pathId = item?.guid ? '/' + (id || urlQuery) : ''
          if (item?.guid && !id && !urlQuery) return
          return (
            <Breadcrumb.Item className="text-gray-500" key={item?.name}>
              {item?.disabled || index === breadcrumbData.length - 1 ? (
                <span>{(item?.guid && urlQuery) || item?.name}</span>
              ) : (
                <Link to={item?.path.toString() + pathId || '#'}>
                  {item?.name}
                </Link>
              )}
            </Breadcrumb.Item>
          )
        })}
      </Breadcrumb>
      <CopyButton {...CopyButtonProps} />
    </div>
  )
}
