import axios from 'axios'
import { useMemo } from 'react'

import { PROPERTY_TYPES } from '../../common/drmTypes'
import {
  RuianAreaResponse,
  RuianBuildingResponse,
  RuianUnitResponse,
} from '../../common/ruianTypes'
import { useAppSelector } from '../../redux/hooks'
import { requestPropertyRuianPath } from './paths'

function handleUnit(ruian: RuianUnitResponse) {
  const propertyType = ruian.zpusobVyuziti?.nazev || 'Jednotka'
  const unitNumber = ruian.cisloJednotky?.split('/')?.[1]

  if (ruian.adresniMista?.[0]?.formatovanaAdresa) {
    return (
      propertyType +
      (unitNumber ? ' č. ' + unitNumber : '') +
      ' - ' +
      ruian.adresniMista?.[0]?.formatovanaAdresa
    )
  }

  if (ruian.obec?.nazev)
    return propertyType + ' (bez čp/če) - ' + ruian.obec.nazev
  return 'Detail nemovitosti'
}

function handleBuilding(ruian: RuianBuildingResponse) {
  const propertyType = ruian.zpusobVyuziti?.nazev || 'Budova'
  if (ruian.adresniMista?.[0]?.formatovanaAdresa) {
    return propertyType + ' - ' + ruian.adresniMista?.[0]?.formatovanaAdresa
  }
  if (ruian.obec?.nazev)
    return propertyType + ' (bez čp/če) - ' + ruian.obec.nazev
  return 'Detail nemovitosti'
}

function handleArea(ruian: RuianAreaResponse) {
  const propertyType = ruian.zpusobVyuziti?.nazev || 'Parcela'
  const location =
    ruian.obec.nazev === ruian.katastralniUzemi.nazev
      ? ruian.obec.nazev
      : ruian.obec.nazev + ' - ' + ruian.katastralniUzemi.nazev

  if (ruian.obec?.nazev) {
    return (
      propertyType +
      ' - ' +
      ruian.druhPozemku.nazev +
      ', par.č. ' +
      ruian.parcelniCislo +
      ' - ' +
      location
    )
  }
  if (ruian.obec?.nazev)
    return propertyType + ' (bez čp/če) - ' + ruian.obec.nazev
  return 'Detail nemovitosti'
}

export const usePropertyDetailTitle = () => {
  const ruianData = useAppSelector(state => state.property.ruian.data)

  return useMemo(() => {
    if (!ruianData) return 'Detail nemovitosti'

    switch (ruianData.type) {
      case PROPERTY_TYPES.UNIT:
        return handleUnit(ruianData as RuianUnitResponse)
      case PROPERTY_TYPES.BUILDING:
        return handleBuilding(ruianData as RuianBuildingResponse)
      case PROPERTY_TYPES.AREA:
        return handleArea(ruianData as RuianAreaResponse)
      default:
        return 'Detail nemovitosti'
    }
  }, [ruianData])
}

export const getPropertyDetailTitleById = async (
  propertyId: string,
  propertyType: PROPERTY_TYPES,
  token: string
) => {
  try {
    const response = await axios.get(
      requestPropertyRuianPath(propertyType, token, propertyId)
    )

    const data = response.data
    if (!data) return 'Detail nemovitosti'

    switch (propertyType) {
      case PROPERTY_TYPES.UNIT:
        return handleUnit(data as RuianUnitResponse)
      case PROPERTY_TYPES.BUILDING:
        return handleBuilding(data?.[0] as RuianBuildingResponse)
      case PROPERTY_TYPES.AREA:
        return handleArea(data as RuianAreaResponse)
      default:
        return 'Detail nemovitosti'
    }
  } catch (error) {
    console.error('Error fetching property detail title:', error)
    return 'Detail nemovitosti'
  }
}
