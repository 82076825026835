import { Button, Drawer } from 'antd'
import { BaseButtonProps } from 'antd/lib/button/button'
import React, { FunctionComponent, ReactElement, useMemo } from 'react'

import { useToggleState } from '../../utils/useToggleState'

export interface LocalSavedPdfDrawerPreviewProps {
  buttonProps?: BaseButtonProps
  buttonText?: string
  children: ReactElement
  fileName?: string
  path?: string
  drawerTitle?: string
}

const bodyStyle = {
  display: 'flex',
  padding: 0,
  position: 'relative' as const,
}

export const LocalSavedPdfDrawerPreview: FunctionComponent<LocalSavedPdfDrawerPreviewProps> =
  props => {
    const [isDrawerOpen, toggleOpen] = useToggleState(false)

    const extra = useMemo(
      () => (
        <div className="space-x-2">
          <Button onClick={toggleOpen}>Zavřít</Button>

          <a
            href={props.path}
            download={props.fileName || 'PDF-REGESTA-DATA'}
            target="_blank"
            rel="noreferrer"
          >
            <Button type="primary" onClick={toggleOpen}>
              Stáhnout PDF
            </Button>
          </a>
        </div>
      ),
      [toggleOpen, props.fileName, props.path]
    )

    return (
      <>
        <Button type="primary" onClick={toggleOpen} {...props.buttonProps}>
          {props.buttonText || 'Zobrazit PDF'}
        </Button>
        <Drawer
          title={props.drawerTitle || 'Náhled PDF dokumentu'}
          placement="right"
          size="large"
          onClose={toggleOpen}
          open={isDrawerOpen}
          bodyStyle={bodyStyle}
          extra={extra}
          destroyOnClose
        >
          <div className="flex flex-col w-full h-full bg-gray-400 animate-pulse absolute" />
          <div className="flex flex-col z-10 w-full h-full">
            {props.children}
          </div>
        </Drawer>
      </>
    )
  }
