import { createSlice } from '@reduxjs/toolkit'

import { RuianDetailResponse } from '../common/ruianTypes'
import handleAsyncActions, {
  AsyncState,
  initialFetchState,
  PropertyDataTypes,
} from './fetchAction'

export const ruianSlice = createSlice({
  name: PropertyDataTypes.RUIAN,
  initialState: initialFetchState as AsyncState<RuianDetailResponse>,
  reducers: {},
  extraReducers: builder => {
    handleAsyncActions<RuianDetailResponse>(builder, PropertyDataTypes.RUIAN)
  },
})

export default ruianSlice.reducer
