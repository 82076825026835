import { Icon } from '@iconify/react'
import { Spin, Timeline } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { ProceedingDetailResponse } from '../../../common/proceedingTypes'
import {
  proceedingDetailTimelineNoDetails,
  proceedingDetailTimelineSkeletonItems,
} from '../../utils/proceedingDetailHelpers'
import { Day, Min } from '../DateFormat/DateFormat'

export interface ProceedingDetailTimelineProps {
  data: ProceedingDetailResponse | null
  loading?: boolean
}

export const ProceedingDetailTimeline: FunctionComponent<ProceedingDetailTimelineProps> =
  ({ data, loading }) => {
    const timelineItems = useMemo(() => {
      if (loading) return proceedingDetailTimelineSkeletonItems

      const inProgress = data?.operace?.length
        ? data.operace?.map(operace => ({
            label: <Day className="mr-2">{operace?.datum}</Day>,
            children: operace?.nazev,
          }))
        : proceedingDetailTimelineNoDetails

      const result = {
        label: <span className="mr-2">Aktuální stav řízení</span>,
        children: <span className="font-semibold">{data?.stav}</span>,
        dot: <Icon icon="ri:progress-8-line" width="16" height="16" />,
      }

      const recived = {
        label: (
          <Min noData=" " className="mr-2">
            {data?.datumPrijeti}
          </Min>
        ),
        children: <span>Datum přijetí</span>,
        dot: (
          <Icon icon="simple-line-icons:envelope-open" width="16" height="16" />
        ),
      }
      return [recived, ...inProgress, result]
    }, [data, loading])

    return (
      <div className="lg:flex-1 flex-auto w-full">
        <div className="flex justify-center items-center mb-4 p-2 bg-[#fafafa] font-semibold">
          Průběh řízení
        </div>
        <Spin spinning={loading}>
          <Timeline mode="left" items={timelineItems} />
        </Spin>
      </div>
    )
  }
