import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Form, FormListFieldData, Input, Space } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { textAreaAutoSize } from '../../utils/layoutConst'

export interface PropertyNoteFormBaseFragmentProps {
  submit: () => void
  disabled?: boolean
}

const rules = {
  note: [{ required: true, message: 'Zadejte prosím text poznámky' }],
  url: [{ required: true, message: 'Zadejte URL, nebo odeberte odkaz.' }],
  urlTitle: [
    { max: 200, message: 'Název odkazu nemůže být delší než 200 znaků' },
  ],
}

export const PropertyNoteFormBaseFragment: FunctionComponent<PropertyNoteFormBaseFragmentProps> =
  props => {
    const handleKeyDown = useCallback(
      (event: { ctrlKey: boolean; key: string }) => {
        if (event.ctrlKey && event.key === 'Enter') {
          props.submit()
        }
      },
      [props]
    )

    const textAreaExtra = useMemo(
      () => (
        <Form.List name="links">
          {(fields, { add }) =>
            fields.length !== 50 && (
              <Button
                size="small"
                type="link"
                onClick={() => add()}
                icon={<PlusCircleOutlined />}
              >
                {fields.length === 0
                  ? 'Přidat URL odkaz'
                  : 'Přidat další odkaz'}
              </Button>
            )
          }
        </Form.List>
      ),
      []
    )

    const renderUrlFields = useCallback(
      (
        fields: FormListFieldData[],
        { remove }: { remove: (index: number | number[]) => void }
      ) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space key={key} className="!flex" align="baseline">
              <Form.Item
                {...restField}
                className="!mb-2"
                name={[name, 'title']}
                rules={rules.urlTitle}
              >
                <Input placeholder="Název" />
              </Form.Item>
              <Form.Item
                {...restField}
                className="!mb-2"
                name={[name, 'url']}
                rules={rules.url}
              >
                <Input placeholder="URL adresa" />
              </Form.Item>
              <Button
                size="small"
                type="link"
                danger
                onClick={() => remove(name)}
                icon={<MinusCircleOutlined />}
              >
                Odebrat
              </Button>
            </Space>
          ))}
        </>
      ),
      []
    )

    return (
      <>
        <Form.Item name="iconKey" hidden>
          <Input />
        </Form.Item>

        <Form.Item name="colorKey" hidden>
          <Input />
        </Form.Item>

        <Form.Item
          name="note"
          className="!mb-2"
          rules={rules.note}
          required
          extra={textAreaExtra}
          /*label={<span className="text-base font-semibold">Jakub Haladěj</span>} //TODO: replace by user name when its possible*/
        >
          <Input.TextArea
            placeholder="Začněte psát poznámku..."
            showCount
            maxLength={5000}
            autoFocus
            autoSize={textAreaAutoSize}
            onKeyDown={handleKeyDown}
            disabled={props.disabled}
          />
        </Form.Item>

        <Form.List name="links">{renderUrlFields}</Form.List>
      </>
    )
  }
