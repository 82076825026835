import React, { FunctionComponent, useMemo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { ForclosureMonitoringFilterOnSubmit } from '../../../common/foreclosureMonitoringTypes'
import { useFetchAreaTypeEnums } from '../../utils/useFetchAreaTypeEnums'
import { useFetchPropertyUseEnums } from '../../utils/useFetchPropertyUseEnums'
import { PropertyMonitoringFilterAreaUseModalButton } from '../PropertyMonitoringFilterAreaUseModalButton/PropertyMonitoringFilterAreaUseModalButton'
import { PropertyMonitoringFilterError } from '../PropertyMonitoringFilterError/PropertyMonitoringFilterError'
import { PropertyMonitoringFilterItem } from '../PropertyMonitoringFilterItem/PropertyMonitoringFilterItem'

export interface PropertyMonitoringFilterAreaUseProps {
  selectedAreaUse?: number[] | null
  selectedAreaType?: number[] | null
  onChange: ForclosureMonitoringFilterOnSubmit
}

export const PropertyMonitoringFilterAreaUse: FunctionComponent<PropertyMonitoringFilterAreaUseProps> =
  props => {
    const { error: areaTypeError, data: areaTypeData } = useFetchAreaTypeEnums()
    const { error: areaUseError, data: areaUseData } = useFetchPropertyUseEnums(
      PROPERTY_TYPES.AREA
    )

    const checkboxData = useMemo(
      () => ({
        areaUse: areaUseData || [],
        areaType: areaTypeData || [],
      }),
      [areaTypeData, areaUseData]
    )

    const selectedValues = useMemo(
      () => ({
        areaUse:
          props.selectedAreaUse || areaUseData?.map(item => item.kod) || [],
        areaType:
          props.selectedAreaType || areaTypeData?.map(item => item.kod) || [],
      }),
      [areaTypeData, areaUseData, props.selectedAreaType, props.selectedAreaUse]
    )

    if (areaUseError || areaTypeError)
      return <PropertyMonitoringFilterError filterType="propertyUse" />

    if (!areaUseData?.length || !areaTypeData?.length) return null

    return (
      <PropertyMonitoringFilterItem label="Druh a způsob využití">
        <PropertyMonitoringFilterAreaUseModalButton
          checkboxData={checkboxData}
          selectedValues={selectedValues}
          onSubmit={props.onChange}
        />
      </PropertyMonitoringFilterItem>
    )
  }
