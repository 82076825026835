import { InfoCircleOutlined } from '@ant-design/icons'
import { Modal, Tooltip } from 'antd'
import React, { FunctionComponent, memo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { ColorPalleteKey } from '../../utils/collorPallete'
import { IconPalleteKey } from '../../utils/iconPallete'
import { PropertyTableNoteItem } from '../../utils/propertyDetailNoteHelpers'
import { useToggleState } from '../../utils/useToggleState'
import { NoteIcon } from '../NoteIcon/NoteIcon'
import { PropertyNoteModalContent } from '../PropertyNoteModalContent/PropertyNoteModalContent'

export interface PropertyNoteModalButton {
  note?: PropertyTableNoteItem | null
  className?: string
}

export const PropertyNoteModalButton: FunctionComponent<PropertyNoteModalButton> =
  memo(props => {
    const [open, toggleModal] = useToggleState()

    if (!props.note) return null

    return (
      <>
        <Tooltip
          title={
            <div>
              <div>{props.note?.note}</div>
              <div className="mt-2 pt-2 border-t border-gray-500 text-gray-400">
                <InfoCircleOutlined className="mr-2" />
                Kliknutím zobrazíte všechny poznámky s možností odpovědět.
              </div>
            </div>
          }
          className={props.className || 'truncate inline-block w-32'}
        >
          <div onClick={toggleModal} className="flex space-x-2 cursor-pointer">
            {(props.note.colorKey || props.note.iconKey) && (
              <div className="flex justify-center scale-75">
                <NoteIcon
                  colorKey={props.note.colorKey as ColorPalleteKey}
                  iconKey={props.note.iconKey as IconPalleteKey}
                  className="!p-1"
                />
              </div>
            )}
            <div className="truncate border-b border-dashed border-gray-800">
              {props.note?.note}
            </div>
          </div>
        </Tooltip>

        {open && (
          <Modal
            title="Poznámky"
            open={open}
            onCancel={toggleModal}
            footer={null}
            width={1000}
          >
            <div className="text-gray-400">
              <InfoCircleOutlined className="mr-2" />
              Zobrazují se také ostatní poznámky u LV, pod které tato nemovitost
              spadá.
            </div>
            <PropertyNoteModalContent
              target="_blank"
              propertyId={props.note.propertyId || ''}
              propertyCadastralCode={props.note?.propertyCadastralCode || ''}
              propertyLv={props.note?.propertyLv || ''}
              propertyType={props.note?.propertyType || PROPERTY_TYPES.AREA}
            />
          </Modal>
        )}
      </>
    )
  })
