import { Form, Input } from 'antd'
import React, { FunctionComponent } from 'react'

export const MarketplaceFormContactFragment: FunctionComponent = () => {
  return (
    <>
      <Form.Item
        label="Kontaktní osoba"
        name="contactName"
        rules={[
          {
            required: true,
            message: 'Zadejte jméno kontaktní osoby',
          },
        ]}
      >
        <Input autoFocus placeholder="Jméno kontaktní osoby" />
      </Form.Item>
      <Form.Item
        label="E-mail"
        name="contactEmail"
        rules={[
          {
            required: true,
            message: 'Zadejte název kontaktní e-mail',
          },
        ]}
      >
        <Input placeholder="Kontaktní e-mail" />
      </Form.Item>
      <Form.Item
        label="Telefon"
        name="contactPhone"
        rules={[
          {
            required: true,
            message: 'Zadejte kontaktní telefonní číslo',
          },
        ]}
      >
        <Input placeholder="Kontaktní telefonní číslo" />
      </Form.Item>
      <Form.Item
        label="Dodatečné kontaktní informace"
        name="contactDescription"
      >
        <Input.TextArea
          showCount
          maxLength={1000}
          placeholder="Tento popis se zobrazí zájemcům až po zakoupení kontaktu"
        />
      </Form.Item>
    </>
  )
}
