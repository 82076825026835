import React, { FunctionComponent } from 'react'

import { RuianAreaResponse } from '../../../common/ruianTypes'
import { useAppSelector } from '../../../redux/hooks'
import { BpejTooltip } from '../BpejTooltip/BpejTooltip'
import { Money } from '../Money/Money'
import { PriceEstimateLayoutLvDataRow as Item } from '../PriceEstimateLayoutLvDataRow/PriceEstimateLayoutLvDataRow'
import { PropertyDetailSidebarRowCopyableItem as CopyItem } from '../PropertyDetailSidebarRowCopyableItem/PropertyDetailSidebarRowCopyableItem'

export const PropertyDetailSidebarRuianArea: FunctionComponent = () => {
  const ruian = useAppSelector(
    state => state.property.ruian.data as RuianAreaResponse
  )

  return (
    <>
      <CopyItem title="Parcela">{ruian?.nazev}</CopyItem>

      <Item title="Způsob využití">{ruian?.zpusobVyuziti?.nazev}</Item>

      <CopyItem title="GPS souřadnice">
        {`${ruian?.definicniBod?.lat.toFixed(
          5
        )}, ${ruian?.definicniBod?.long.toFixed(5)}`}
      </CopyItem>

      <Item title="Výměra" condition={!!ruian?.vymera}>
        <Money amount={ruian?.vymera} symbol="m²" decimalNumbers={0} />
      </Item>

      <Item title="BPEJ" condition={!!ruian?.bonitovaneJednotky?.length}>
        {ruian?.bonitovaneJednotky?.map((jednotka, index) => (
          <div key={index}>
            <BpejTooltip
              code={jednotka.kod.toString()}
              areaSize={jednotka.vymera}
            >
              {jednotka.kod}
              {' - '}
              <Money
                className="text-gray-400"
                amount={jednotka?.vymera}
                symbol="m²"
                decimalNumbers={0}
              />
            </BpejTooltip>
          </div>
        ))}
      </Item>
    </>
  )
}
