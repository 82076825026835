import { Transition, TransitionClasses } from '@headlessui/react'
import React, { FunctionComponent, useMemo } from 'react'

export interface FadeProps {
  show: boolean | undefined | null
  className?: string
  transition?: TransitionClasses
  children: React.ReactNode
}

export const Fade: FunctionComponent<FadeProps> = props => {
  const show = useMemo(() => {
    if (!props.show) return false
    return true
  }, [props.show])
  return (
    <Transition
      show={show}
      enter="transition ease-out duration-300"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-300"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
      className={props.className}
      {...props.transition}
    >
      {props.children}
    </Transition>
  )
}
