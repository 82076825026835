import { Icon } from '@iconify/react'
import { Alert, Modal } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { PieChart, pieChartDefaultProps } from 'react-minimal-pie-chart'

import { propertyChartProps } from '../../../common/chartHelpers'
import { VlastnickePravo } from '../../../common/lvTypes'
import { PieChartColorPalette } from '../../utils/collorPallete'
import { sharePercentage } from '../../utils/ownershipRightsTableHelpers'
import { useToggleState } from '../../utils/useToggleState'
import { CuzkLvHtmlTooManyOwnersInfo } from '../CuzkLvHtmlTooManyOwnersInfo/CuzkLvHtmlTooManyOwnersInfo'
import { PropertyDetailOwnershipRightsChartSelectedBox } from '../PropertyDetailOwnershipRightsChartSelectedBox/PropertyDetailOwnershipRightsChartSelectedBox'

type SegmentData = {
  index: number
  title: string
  value: number
  color?: string
}
type SortedData = {
  title: string
  value: number
  color: string
  subject?: VlastnickePravo
}
type SelectedData = {
  segment: SegmentData | null
  subject: VlastnickePravo | undefined
}

export interface PropertyDetailOwnershipRightsChartProps {
  rights?: VlastnickePravo[]
}

export const PropertyDetailOwnershipRightsChart: FunctionComponent<PropertyDetailOwnershipRightsChartProps> =
  ({ rights }) => {
    const { subjects, tooManyOwners, singleOwner } = useMemo(
      () => ({
        subjects: rights || [],
        tooManyOwners: rights?.length && rights.length > 10,
        singleOwner: rights?.length === 1 && rights[0],
      }),
      [rights]
    )

    const [isModalOpen, handleModal] = useToggleState(false)
    const [selected, setSelected] = useState<SelectedData>(
      propertyChartProps.notSelected
    )

    const prepareOwnerData = useMemo(() => {
      const sortedData: SortedData[] =
        subjects
          ?.map((subj, index) => {
            const share = sharePercentage(
              subj?.podilCitatel,
              subj?.podilJmenovatel
            ) as number

            const name = subj?.sjmPartner?.jmeno
              ? `${subj?.subjekt?.jmeno} \n ${subj?.sjmPartner?.jmeno}`
              : `${subj?.subjekt?.jmeno || 'Neznámý' + index + 1}`

            return {
              title: name,
              value: share,
              color: '',
              subject: subj,
            }
          })
          .sort((a, b) => b.value - a.value) || []

      let othersSum = 0
      if (tooManyOwners) {
        othersSum = sortedData
          .slice(10)
          .reduce((acc, curr) => acc + curr.value, 0)
      }

      const pieChartData = [...sortedData]

      pieChartData.forEach((data, index) => {
        if (index >= 10) {
          data.title =
            index === Math.round(sortedData.length / 2)
              ? `Ostatní (${othersSum.toFixed(2)}%)`
              : ''
          data.color = '#ddd'
        } else {
          data.color = PieChartColorPalette[index % PieChartColorPalette.length]
        }
      })

      return {
        modalTitle: `Celkem ${subjects.length} vlastnických podílů`,
        pieChartData,
      }
    }, [subjects, tooManyOwners])

    const onMouseOverHandler = useCallback(
      (_: unknown, index: number) => {
        const hoveredData = prepareOwnerData.pieChartData[index]
        setSelected({
          segment: { ...hoveredData, index },
          subject: hoveredData.subject,
        })
      },
      [prepareOwnerData.pieChartData]
    )

    const pieChartProp = useMemo(
      () => ({
        center: (tooManyOwners ? [75, 60] : [100, 60]) as [number, number],
        data: prepareOwnerData.pieChartData,
        radius: pieChartDefaultProps.radius - 6,
        segmentsStyle: (index: number) => ({
          transition: 'stroke .5s',
          cursor: 'pointer',
          opacity: index === selected.segment?.index ? 0.5 : 1,
        }),
        onMouseOver: onMouseOverHandler,
        label: ({
          dataEntry: { title, value },
        }: {
          dataEntry: {
            title: string
            percentage: number
            value: number
          }
        }) =>
          title.startsWith('Ostatní')
            ? title
            : title
            ? `${title} (${value.toFixed(2)} %)`
            : '',
        ...propertyChartProps.pieChartProp,
      }),
      [
        onMouseOverHandler,
        prepareOwnerData.pieChartData,
        selected.segment?.index,
        tooManyOwners,
      ]
    )

    const footer = useMemo(
      () =>
        selected.subject?.subjekt?.id ? (
          <Alert
            message={
              <PropertyDetailOwnershipRightsChartSelectedBox
                subject={selected.subject}
                share={selected.segment?.value || 0}
              />
            }
            type="success"
            onClose={() => setSelected(propertyChartProps.notSelected)}
            closable
          />
        ) : undefined,
      [selected.segment?.value, selected.subject]
    )

    if (!rights || singleOwner) {
      return null
    }

    return (
      <>
        <span
          className="text-primary cursor-pointer flex items-center space-x-2"
          onClick={handleModal}
        >
          <Icon icon="ion:bar-chart" width="12" />
          <span>Podíly v grafu</span>
        </span>

        <Modal
          title={prepareOwnerData.modalTitle}
          open={isModalOpen}
          onCancel={handleModal}
          footer={footer}
          {...propertyChartProps.modalProps}
        >
          <div className="border-y">
            {tooManyOwners && <CuzkLvHtmlTooManyOwnersInfo />}
            <div className="max-w-xl mx-auto">
              <PieChart {...pieChartProp} />
            </div>
          </div>
        </Modal>
      </>
    )
  }
