import React, { FunctionComponent, useMemo } from 'react'

import {
  Identifier,
  IdentifierValues,
  Register,
} from '../../utils/dynamicSearchTooltip'
import {
  CUZK_PERSON_TYPES,
  CuzkEpoModalButton,
  CuzkEpoModalButtonProps,
} from '../CuzkEpoModalButton/CuzkEpoModalButton'

export interface DynamicSearchTooltipEpoButtonProps {
  register: Register
  identifier: IdentifierValues
}

export const DynamicSearchTooltipEpoButton: FunctionComponent<DynamicSearchTooltipEpoButtonProps> =
  ({ identifier }) => {
    const epoButtonProps = useMemo<CuzkEpoModalButtonProps>(() => {
      const isCompany = !!identifier?.[Identifier.ICO]
      const initialValues = isCompany
        ? {
            type: CUZK_PERSON_TYPES.OPO,
            ico: identifier?.[Identifier.ICO],
            name: identifier?.[Identifier.COMPANY_NAME],
          }
        : identifier?.[Identifier.RC]
        ? {
            type: CUZK_PERSON_TYPES.OFO,
            birthDate: identifier?.[Identifier.RC],
            firstname: identifier?.[Identifier.FIRST_NAME],
            lastname: identifier?.[Identifier.LAST_NAME],
          }
        : {
            type: CUZK_PERSON_TYPES.OFO,
            firstname: identifier?.[Identifier.FIRST_NAME],
            lastname: identifier?.[Identifier.LAST_NAME],
            birthDate: identifier?.[Identifier.BIRTH_DATE],
          }

      return {
        buttonProps: {
          title: 'Vyhledat evidenci práv pro osobu',
          type: 'link',
          className: '!p-0',
        },
        className: 'inline',
        buttonText: 'EPO',
        initialValues,
      }
    }, [identifier])

    return <CuzkEpoModalButton {...epoButtonProps} />
  }
