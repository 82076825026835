import { Popover } from 'antd'
import React, { FunctionComponent } from 'react'

import { ColorPalleteKey } from '../../utils/collorPallete'
import { IconPalleteKey } from '../../utils/iconPallete'
import { useToggleState } from '../../utils/useToggleState'
import { NoteIcon } from '../NoteIcon/NoteIcon'
import { NoteIconSettingButtonPopover } from '../NoteIconSettingButtonPopover/NoteIconSettingButtonPopover'

export interface NoteIconSettingButtonProps {
  pickedColor?: ColorPalleteKey
  pickedIcon?: ColorPalleteKey
  onStyleChange: (style: {
    colorKey?: ColorPalleteKey
    iconKey?: IconPalleteKey
  }) => void
  suffix?: React.ReactNode
}

export const NoteIconSettingButton: FunctionComponent<NoteIconSettingButtonProps> =
  props => {
    const [open, handleOpen] = useToggleState()

    return (
      <Popover
        placement="top"
        autoAdjustOverflow
        open={open}
        content={
          <NoteIconSettingButtonPopover
            handleOpen={handleOpen}
            onStyleChange={props.onStyleChange}
            initialColor={props.pickedColor}
            initialIcon={props.pickedIcon}
          />
        }
      >
        <div
          onClick={handleOpen}
          className="flex space-x-2 w-min items-center cursor-pointer text-primary hover:text-primaryHover"
        >
          <NoteIcon colorKey={props.pickedColor} iconKey={props.pickedIcon} />
          {props.suffix}
        </div>
      </Popover>
    )
  }
