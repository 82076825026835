import React, { FunctionComponent } from 'react'

import { PRAVA_STAVBY } from '../../../common/cuzkLvTypes'
import { notEmpty, safeJoin, safeMap } from '../../../common/pdfHelpers'
import { CuzkLvHtmlParcelaIdentFragment } from '../CuzkLvHtmlParcelaIdentFragment/CuzkLvHtmlParcelaIdentFragment'

export interface CuzkLvHtmlSekcePravaStavbyFragmentProps {
  pravaStavby?: PRAVA_STAVBY
  static?: boolean
}

export const CuzkLvHtmlSekcePravaStavbyFragment: FunctionComponent<CuzkLvHtmlSekcePravaStavbyFragmentProps> =
  props => {
    if (!props.pravaStavby || !notEmpty(props.pravaStavby.PRAVO_STAVBY)) {
      return null
    }
    return (
      <>
        <div className="mb-50"></div>
        <div className="row">
          <div className="col-xs-9 pl-50">Práva stavby</div>
          <div className="col-xs-3">&nbsp;</div>
        </div>
        <div className="row">
          <div className="col-xs-2">&nbsp;</div>
          <div className="col-xs-4 pr-30">
            <i>Účel</i>
          </div>
          <div className="col-xs-4">
            <i>Způsob ochrany</i>
          </div>
          <div className="col-xs-2">
            <i>Platnost do</i>
          </div>
        </div>
        <hr className="mt-0 mb-0" />
        {safeMap(props.pravaStavby?.PRAVO_STAVBY, (PRAVO_STAVBY, index) => (
          <div key={index}>
            <div className="row bold">
              <div className="col-xs-2">
                {PRAVO_STAVBY?.PLOMBA_PRAVA_STAVBY
                  ? PRAVO_STAVBY.PLOMBA_PRAVA_STAVBY + ' '
                  : ''}
                <strong>Právo stavby</strong>
              </div>
              <div className="col-xs-4 pr-30">
                {safeJoin(PRAVO_STAVBY.UCELY_PRAVA_STAVBY?.UCEL, ', ')}
              </div>
              <div className="col-xs-4 breakAll">
                {safeJoin(PRAVO_STAVBY.ZP_OCHRANY?.zpochr, ', ')}
              </div>
              <div className="col-xs-2">{PRAVO_STAVBY.PLATNOST_DO}</div>
            </div>
            <div className="row">
              <div className="col-xs-3 pl-50">
                <i>k pozemku p.č.:</i>
              </div>
              <div className="col-xs-7">
                {safeMap(
                  PRAVO_STAVBY?.PARCELY?.PAR_IDENT,
                  (PAR_IDENT, index) => (
                    <div key={index} className="bold">
                      <CuzkLvHtmlParcelaIdentFragment
                        noLabel
                        parcelaIdent={PAR_IDENT?.parcela}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
            {safeMap(PRAVO_STAVBY?.SOUCASTI_STAVBA?.stavba, (stavba, index) => (
              <div key={index}>
                <div className="row">
                  <div className="col-xs-3 pl-50">
                    <i>Součástí je stavba:</i>
                  </div>
                  <div className="col-xs-9">
                    <strong>
                      {stavba?.caobce},{stavba?.typbud_zkr}
                      {stavba?.cisla_domovni},{stavba?.vyuziti_zkr}
                      {stavba?.bez_lv}
                      {stavba?.cislo_tel && ', LV'}
                      {stavba?.cislo_tel}
                      {stavba?.ku_nazev}
                      {stavba?.docasna}
                    </strong>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </>
    )
  }
