import { InputNumber, Space } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import { InsolvencyMonitoringFilterOnSubmit } from '../../../common/insolvencyMonitoringTypes'
import { insolvencyMonitoringFilterLabels } from '../../utils/propertyMonitoringHelpers'
import { PropertyMonitoringFilterItem } from '../PropertyMonitoringFilterItem/PropertyMonitoringFilterItem'
import { PropertyMonitoringFilterResetButton } from '../PropertyMonitoringFilterResetButton/PropertyMonitoringFilterResetButton'

export interface InsolvencyMonitoringFilterMinimalShareProps {
  value?: number | null
  onChange: InsolvencyMonitoringFilterOnSubmit
}

export const InsolvencyMonitoringFilterMinimalShare: FunctionComponent<InsolvencyMonitoringFilterMinimalShareProps> =
  props => {
    const handleChange = useCallback(
      (value: number | null) => {
        props.onChange({ podilOd: value })
      },
      [props]
    )

    const handleReset = useCallback(() => {
      handleChange(null)
    }, [handleChange])

    return (
      <PropertyMonitoringFilterItem
        label={insolvencyMonitoringFilterLabels['podilOd']}
        className="col-span-2 sm:col-span-1 md:w-36"
      >
        <Space.Compact>
          <InputNumber
            controls={false}
            value={props.value}
            onChange={handleChange}
            className={props.value ? 'w-full bg-green-50' : 'w-full'}
            addonBefore="Min"
            placeholder="0"
            min={1}
            max={100}
          />
          {!!props.value && (
            <PropertyMonitoringFilterResetButton onClick={handleReset} />
          )}
        </Space.Compact>
      </PropertyMonitoringFilterItem>
    )
  }
