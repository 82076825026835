import { Button, ButtonProps, Modal } from 'antd'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'

import { useAppSelector } from '../../../redux/hooks'
import { CallName, getCallName } from '../../utils/getCallName'
import { useToggleState } from '../../utils/useToggleState'
import { AddRecipientModalButtonContent } from '../AddRecipientModalButtonContent/AddRecipientModalButtonContent'
import { AddRecipientModalButtonIcon } from '../AddRecipientModalButtonIcon/AddRecipientModalButtonIcon'
import { PostServiceRecipient } from '../PostServiceRecipientListTableExpandedRow/PostServiceRecipientListTableExpandedRow'

export interface AddRecipientModalButton {
  buttonProps?: ButtonProps
  buttonText?: React.ReactNode
  record?: Partial<PostServiceRecipient>
  message?: React.ReactNode
  refetch?: () => void
}

export const AddRecipientModalButton: FunctionComponent<AddRecipientModalButton> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)

    const [open, toggleOpen] = useToggleState()
    const [callName, setCallName] = useState<CallName | undefined>()

    useEffect(() => {
      const fetchCallName = async () => {
        await getCallName(props.record?.lastname, token)
          .then(res => {
            setCallName(res)
          })
          .catch(() => setCallName(null))
      }

      if (props.record?.lastname && open && typeof callName === 'undefined') {
        fetchCallName()
      }
    }, [props.record?.lastname, open, token, callName])

    const initialValues = useMemo(() => {
      if (!props.record)
        return {
          country: 'CZ',
          gender: 0 as const,
        }
      return {
        ...props.record,
        callName: callName?.man || callName?.female || '',
        gender:
          props.record?.gender || callName?.man
            ? ('m' as const)
            : callName?.female
            ? ('f' as const)
            : (0 as const),
        street: {
          streetName: props.record?.street || '',
          houseNumber: props.record?.houseNumber || '',
          orientationNumber: props.record?.orientationNumber || '',
        },
        addressId: props.record?.addressId,
      }
    }, [callName, props.record])

    return (
      <>
        {props.buttonProps || props.buttonText ? (
          <Button
            type="primary"
            onClick={toggleOpen}
            size="small"
            className="sm:w-auto w-full"
            {...props.buttonProps}
          >
            {props.buttonText || 'Přidat adresáta do seznamu'}
          </Button>
        ) : (
          <a className="flex" onClick={toggleOpen}>
            <AddRecipientModalButtonIcon />
          </a>
        )}
        <Modal
          title="Přidat subjekt do vybraného seznamu adresátů"
          open={open}
          onCancel={toggleOpen}
          footer={null}
          width={600}
        >
          {open && (
            <AddRecipientModalButtonContent
              initialValues={initialValues}
              message={props.message}
              refetch={props.refetch}
              toggleOpen={toggleOpen}
            />
          )}
        </Modal>
      </>
    )
  }
