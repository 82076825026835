import { Typography } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import { PROPERTY_TYPES } from '../../common/drmTypes'
import { PROPERTY_TAB_KEYS } from '../../common/propertyDetailTypes'
import { PageLayout } from '../components/PageLayout/PageLayout'
import { PriceEstimateArea } from '../components/PriceEstimateArea/PriceEstimateArea'
import { PriceEstimateBuilding } from '../components/PriceEstimateBuilding/PriceEstimateBuilding'
import { PriceEstimateUnit } from '../components/PriceEstimateUnit/PriceEstimateUnit'
import { propertyDetailPath } from '../utils/paths'

const titleStyle = { marginBottom: 0 }

export const PriceEstimateDetail: FunctionComponent = () => {
  const { params } = useRouteMatch<{ id: string; type: PROPERTY_TYPES }>()
  const [title, setTitle] = useState<string>('')

  return (
    <PageLayout
      title={
        <Typography.Title style={titleStyle} level={3}>
          <span>Cenový odhad</span>
          <Link
            title="Zobrazit detail nemovitoti"
            to={propertyDetailPath(
              params.type,
              params.id,
              PROPERTY_TAB_KEYS.PRICE_ESTIMATE
            )}
          >
            {title}
          </Link>
        </Typography.Title>
      }
    >
      {params.type === PROPERTY_TYPES.UNIT && (
        <PriceEstimateUnit setTitle={setTitle} propertyId={params.id} />
      )}
      {params.type === PROPERTY_TYPES.BUILDING && (
        <PriceEstimateBuilding setTitle={setTitle} propertyId={params.id} />
      )}
      {params.type === PROPERTY_TYPES.AREA && (
        <PriceEstimateArea setTitle={setTitle} propertyId={params.id} />
      )}
    </PageLayout>
  )
}

export default PriceEstimateDetail
