import React, { FunctionComponent, useMemo } from 'react'

import {
  ForclosureMonitoringFilterOnSubmit,
  UzemniOmezeniFetchResult,
} from '../../../common/foreclosureMonitoringTypes'
import { CadastralCheckedValues } from '../../utils/cuzkHelpers'
import { useFetchForeclosureMonitoring } from '../../utils/useFetchForeclosureMonitoring'
import { PropertyMonitoringFilterCadastralModalButton } from '../PropertyMonitoringFilterCadastralModalButton/PropertyMonitoringFilterCadastralModalButton'
import { PropertyMonitoringFilterError } from '../PropertyMonitoringFilterError/PropertyMonitoringFilterError'
import { PropertyMonitoringFilterItem } from '../PropertyMonitoringFilterItem/PropertyMonitoringFilterItem'

export interface PropertyMonitoringFilterCadastreProps {
  selected?: number[] | null
  onChange: ForclosureMonitoringFilterOnSubmit
  className?: string
}

export const PropertyMonitoringFilterCadastre: FunctionComponent<PropertyMonitoringFilterCadastreProps> =
  props => {
    const { fetching, error, data } = useFetchForeclosureMonitoring(
      'UzemniOmezeni?'
    ) as UzemniOmezeniFetchResult

    const checkboxData = useMemo(() => {
      if (fetching || error || !data) return []
      return data?.map(item => ({
        ...item.katastralniUrad,
        pracoviste: item.pracoviste,
      }))
    }, [data, error, fetching])

    const selectedValues = useMemo(() => {
      return checkboxData.reduce<CadastralCheckedValues>((result, office) => {
        const filteredWorkplaces = props.selected?.length
          ? office.pracoviste
              .filter(workplace => props.selected?.includes(workplace.kod))
              .map(workplace => workplace.kod)
          : office.pracoviste.map(workplace => workplace.kod)

        if (filteredWorkplaces.length > 0) {
          result[office.kod] = filteredWorkplaces
        }

        return result
      }, {})
    }, [checkboxData, props.selected])

    if (error)
      return <PropertyMonitoringFilterError filterType="cadastralWorkplace" />

    if (!checkboxData.length) return null

    return (
      <PropertyMonitoringFilterItem
        label="Katastrální pracoviště"
        className={props.className}
      >
        <PropertyMonitoringFilterCadastralModalButton
          checkboxData={checkboxData}
          selectedValues={selectedValues}
          selectedCount={props.selected?.length}
          onSubmit={props.onChange}
        />
      </PropertyMonitoringFilterItem>
    )
  }
