import { Button, Modal } from 'antd'
import React, { FunctionComponent, useCallback, useState } from 'react'

import { AddNewLabel } from '../AddNewLabel/AddNewLabel'

export interface AddNewLabelButtonProps {
  refetch?: () => void
}

export const AddNewLabelButton: FunctionComponent<AddNewLabelButtonProps> =
  props => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const toggleModalVisibility = useCallback(() => {
      setIsModalVisible(prev => !prev)
    }, [])

    const handleAddLabel = useCallback(() => {
      toggleModalVisibility()
      props.refetch?.()
    }, [props, toggleModalVisibility])

    return (
      <div>
        <Button type="primary" onClick={toggleModalVisibility}>
          Vytvořit nový štítek
        </Button>
        <Modal
          title="Vytvořit nový štítek"
          open={isModalVisible}
          onCancel={toggleModalVisibility}
          footer={false}
        >
          <AddNewLabel refetch={handleAddLabel} />
        </Modal>
      </div>
    )
  }
