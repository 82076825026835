import { Text } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types/style'
import React, { FunctionComponent } from 'react'

export interface PdfViewerFormItemProps {
  data?: string | number
  isLabel?: boolean
  suffix?: string
  prefix?: string
  children?: React.ReactNode
  style?: Style | Style[]
}

export const PdfViewerFormItem: FunctionComponent<PdfViewerFormItemProps> =
  props => {
    return props.data ? (
      <Text style={props.style}>
        {props.children}
        {props.prefix}
        {!props.isLabel && props.data}
        {props.suffix}
      </Text>
    ) : null
  }
