import React, { FunctionComponent, useMemo } from 'react'

import aerialSrc from '../../images/aerialthumbnail.jpg'
import mapSrc from '../../images/mapthumbnail.jpg'
import streetSrc from '../../images/streetviewthumbnail.jpg'

export enum MAP_TYPE {
  SW = 'streetView',
  MAP = 'map',
  AERIAL = 'aerial',
}

export interface MapTypeButtonProps {
  type: MAP_TYPE
  imageState: [MAP_TYPE, React.Dispatch<React.SetStateAction<MAP_TYPE>>]
  disabled?: boolean
}

const typeProps = {
  [MAP_TYPE.SW]: {
    style: {
      backgroundImage: `url(${streetSrc})`,
    },
    title: 'Zobrazit fotografii nemovitosti',
  },
  [MAP_TYPE.MAP]: {
    style: {
      backgroundImage: `url(${mapSrc})`,
    },
    title: 'Zobrazit umístění v mapě',
  },
  [MAP_TYPE.AERIAL]: {
    style: {
      backgroundImage: `url(${aerialSrc})`,
    },
    title: 'Zobrazit letecký snímek',
  },
}

export const MapTypeButton: FunctionComponent<MapTypeButtonProps> = props => {
  const buttonProps = useMemo(
    () =>
      props.disabled
        ? {
            className:
              'w-full h-full rounded-lg border-2 border-white bg-cover grayscale opacity-70 cursor-not-allowed flex items-start justify-center font-light text-5xl leading-7 xl:leading-8 text-gray-800',
            style: typeProps[props.type].style,
            title: 'Není k dispozici',
          }
        : {
            className: `w-full h-full rounded-lg border-2 border-white bg-cover transition-all text-transparent ${
              props.type == props.imageState[0]
                ? 'grayscale opacity-70'
                : 'hover:border-primaryHover cursor-pointer hover:opacity-80'
            }`,
            onClick: () => props.imageState[1](props.type),
            style: typeProps[props.type].style,
            title: typeProps[props.type].title,
          },
    [props]
  )

  return (
    <div className="bg-white w-10 h-10 xl:w-12 xl:h-12 rounded-lg">
      <div {...buttonProps}>×</div>
    </div>
  )
}
