import { CheckCircleFilled } from '@ant-design/icons'
import React, { FunctionComponent } from 'react'

export interface IcoNumberDataProps {
  rcNumber: string
  icon?: boolean
  className?: string
}

export const IcoNumberData: FunctionComponent<IcoNumberDataProps> = props => {
  return (
    <div className={props.className ? props.className : 'text-green-500'}>
      {props.icon && <CheckCircleFilled className="mr-2" />} Validní
    </div>
  )
}
