import { Image } from 'antd'
import React, { FunctionComponent, useState } from 'react'

import { LvGeometryApiResponse } from '../../../common/lvTypes'
import { imageSkeleton } from '../../utils/layoutConst'
import { useLvDetailImage } from '../../utils/usePropertyDetailImage'
import { usePropertyImagePreviewProps } from '../../utils/usePropertyImagePreviewProps'
import { MAP_TYPE, MapTypeButton } from '../MapTypeButton/MapTypeButton'
import { PropertyDetailSidebarContainer } from '../PropertyDetailSidebarContainer/PropertyDetailSidebarContainer'

interface LvDetailSidebarImageProps {
  geometryData: LvGeometryApiResponse
}

export const LvDetailSidebarImage: FunctionComponent<LvDetailSidebarImageProps> =
  props => {
    const imageState = useState(MAP_TYPE.MAP)
    const mapImage = useLvDetailImage(props.geometryData)
    const previewProps = usePropertyImagePreviewProps(imageState, mapImage)

    return (
      <PropertyDetailSidebarContainer narrow className="relative flex">
        <Image
          preview={previewProps}
          fallback={imageSkeleton}
          src={mapImage[imageState[0]].src || mapImage[MAP_TYPE.AERIAL].src}
          alt="Fotografie / mapa nemovitosti"
          className="rounded-sm object-cover !h-72 lg:!h-auto"
          wrapperClassName="w-full"
        />
        <div className="absolute bottom-2 left-2 p-2 flex space-x-2">
          <MapTypeButton type={MAP_TYPE.AERIAL} imageState={imageState} />
          <MapTypeButton type={MAP_TYPE.MAP} imageState={imageState} />
        </div>
      </PropertyDetailSidebarContainer>
    )
  }
