import { Spin, Tooltip } from 'antd'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { PROPERTY_TAB_KEYS } from '../../../common/propertyDetailTypes'
import { usePropertyDetailLastNoteQuery } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { ColorPalleteKey } from '../../utils/collorPallete'
import { IconPalleteKey } from '../../utils/iconPallete'
import { propertyDetailPath } from '../../utils/paths'
import { Min } from '../DateFormat/DateFormat'
import { NoteIcon } from '../NoteIcon/NoteIcon'
import { PropertyDetailFetchError } from '../PropertyDetailFetchError/PropertyDetailFetchError'
import { PropertyDetailNoteLinks } from '../PropertyDetailNoteLinks/PropertyDetailNoteLinks'
import { PropertyDetailSidebarContainer } from '../PropertyDetailSidebarContainer/PropertyDetailSidebarContainer'
import { PropertyTabLink } from '../PropertyTabLink/PropertyTabLink'

export interface PropertyDetailLastNoteProps {
  isDrawer?: boolean
  lvNumber?: number | string
  lvCode?: number | string
  tabLink?: boolean
}

export const PropertyDetailLastNote: FunctionComponent<PropertyDetailLastNoteProps> =
  ({ isDrawer, lvCode, lvNumber, tabLink }) => {
    const accountId = useAppSelector(state => state.myProfile.tenantId)
    const propertyLv = useAppSelector(
      state => `${lvNumber || state.property.drm.data?.lv || ''}`
    )
    const propertyCadastralCode = useAppSelector(
      state =>
        `${lvCode || state.property.drm.data?.katastralniUzemi?.kod || ''}`
    )

    const [queryResult] = usePropertyDetailLastNoteQuery({
      variables: { accountId, propertyCadastralCode, propertyLv },
    })
    const note = queryResult.data?.allPropertyNotes?.nodes?.[0]

    if (queryResult.error)
      return (
        <PropertyDetailFetchError
          description="Je nám líto, ale nepodařilo se ověřit zda existují poznámky k této nemovitosti."
          message="Chyba při načítání poznámek"
        />
      )

    if (!note)
      return (
        <PropertyDetailSidebarContainer>
          {queryResult.fetching && <Spin>Poznámky...</Spin>}
        </PropertyDetailSidebarContainer>
      )

    return (
      <PropertyDetailSidebarContainer narrow isDrawer={isDrawer}>
        <div className="border-primaryHover bg-teal-50 border rounded-sm p-3">
          <div className="flex justify-between items-center">
            <div>
              <NoteIcon
                colorKey={note.colorKey as ColorPalleteKey}
                iconKey={note.iconKey as IconPalleteKey}
                className="inline-flex !outline-0"
              />
              <Min className="ml-4">{note.createdAt}</Min>
            </div>
            {tabLink ? (
              <PropertyTabLink
                title="Přejít na záložku Poznámky"
                to={PROPERTY_TAB_KEYS.NOTE}
              >
                Poznámky ({queryResult.data?.allPropertyNotes?.totalCount})
              </PropertyTabLink>
            ) : (
              <Link
                title="Přejít na detail a záložku Poznámky"
                to={propertyDetailPath(
                  note.propertyType as PROPERTY_TYPES,
                  note.propertyId,
                  PROPERTY_TAB_KEYS.NOTE
                )}
              >
                Poznámky ({queryResult.data?.allPropertyNotes?.totalCount})
              </Link>
            )}
          </div>
          <div className="pt-2">
            <div className="text-justify whitespace-pre-line">{note.note}</div>
            <PropertyDetailNoteLinks links={note.links} />
            <Tooltip
              title={
                <div>
                  <div>
                    Poslední poznámku k LV přidal uživatel #
                    {note.accountUserId?.slice(-6)} při prohlížení{' '}
                    {note.propertyName}
                  </div>
                  <div className="text-gray-400 italic">
                    {/* TODO: replace by user name when its possible */}V další
                    aktualizaci aplikace bude kód uživatele nahrazen za jméno
                    autora poznámky.
                  </div>
                </div>
              }
            >
              <div className="flex text-gray-400 border-gray-400 border-dashed border-t mt-1 pt-1">
                <span className="truncate max-w-full inline-block whitespace-nowrap">
                  #{note.accountUserId?.slice(-6)} u{' '}
                  {/* TODO: replace by user name when its possible */}
                  <Link
                    className="!text-gray-400 hover:!text-primaryHover"
                    to={propertyDetailPath(
                      note.propertyType as PROPERTY_TYPES,
                      note.propertyId
                    )}
                  >
                    {note.propertyName}
                  </Link>
                </span>
              </div>
            </Tooltip>
          </div>
        </div>
      </PropertyDetailSidebarContainer>
    )
  }
