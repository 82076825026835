import React, { FunctionComponent, useMemo } from 'react'

import { useDeclension } from '../../utils/useDeclension'

export interface TableItemsCountProps {
  total: number
  prefix?: 'Celkem' | 'Vybráno'
  separator?: boolean
}

export const TableItemsCount: FunctionComponent<TableItemsCountProps> =
  props => {
    const decline = useDeclension()

    const style = props.separator
      ? 'pl-6 ml-6 border-l text-gray-400'
      : 'text-gray-400'

    const text = useMemo(
      () =>
        `${
          props.prefix === 'Vybráno'
            ? decline(props.total, 'selected')
            : 'Celkem'
        } ${props.total} ${decline(props.total, 'record')}`,
      [decline, props.prefix, props.total]
    )

    return <div className={style}>{text}</div>
  }
