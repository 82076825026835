import { Button, Tooltip } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { PropertyDetailSubjectsTableItem } from '../../../common/propertyDetailTypes'

export interface SubjectsTableBulkPostServiceProps {
  selectedRows: PropertyDetailSubjectsTableItem[]
}

const buttonProps = {
  type: 'link' as const,
  className: 'flex items-center',
}

export const SubjectsTableBulkPostService: FunctionComponent<SubjectsTableBulkPostServiceProps> =
  props => {
    const notFoundItemsId = useMemo(
      () =>
        props.selectedRows.filter(item => item.aktualni).map(item => item.key),
      [props.selectedRows]
    )

    if (!notFoundItemsId.length)
      return (
        <Tooltip title="Pro všechny vybrané záznamy již byly datové schránky vyhledány.">
          <Button disabled {...buttonProps}>
            Přidat do hromadné pošty
          </Button>
        </Tooltip>
      )

    return <Button {...buttonProps}>Přidat do hromadné pošty</Button>
  }
