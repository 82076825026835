import { MenuOutlined } from '@ant-design/icons'
import { Button, Dropdown, MenuProps } from 'antd'
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useMemo,
} from 'react'
import { Link } from 'react-router-dom'

import { clickTrigger } from '../../utils/layoutConst'
import { postServiceLetterPath } from '../../utils/paths'
import { PostServiceAddRecipientModalButton } from '../PostServiceAddRecipientModalButton/PostServiceAddRecipientModalButton'
import { PostServiceCreateRecipientListModalButton } from '../PostServiceCreateRecipientListModalButton/PostServiceCreateRecipientListModalButton'
import { PostServiceImportRecipientModalButton } from '../PostServiceImportRecipientModalButton/PostServiceImportRecipientModalButton'
import { PostServiceRecipientListItem } from '../PostServiceRecipientListTable/PostServiceRecipientListTable'
import { PostServiceRecipientListTableSendDisabled } from '../PostServiceRecipientListTableSendDisabled/PostServiceRecipientListTableSendDisabled'

export interface PostServiceRecipientListTableActionProps {
  reexecuteQuery: () => void
  record: PostServiceRecipientListItem
}

const menuButtonProps = {
  size: 'middle' as const,
  type: 'link' as const,
  className: 'w-full',
}

export const PostServiceRecipientListTableAction: FunctionComponent<PostServiceRecipientListTableActionProps> =
  props => {
    const menuProps = useMemo<MenuProps>(
      () => ({
        items: [
          {
            key: 'editList',
            className: '!p-0',
            label: (
              <PostServiceCreateRecipientListModalButton
                initialValues={{
                  ...props.record,
                  listName: props.record?.listName,
                }}
                buttonProps={menuButtonProps}
                buttonText="Editovat seznam"
                id={props.record.id}
                reexecuteQuery={props.reexecuteQuery}
              />
            ),
          },
          {
            key: 'addRecipient',
            className: '!p-0',
            label: (
              <PostServiceAddRecipientModalButton
                buttonProps={menuButtonProps}
                buttonText="Přidat adresáta"
                listName={props.record.listName}
                listId={props.record.id}
                reexecuteQuery={props.reexecuteQuery}
              />
            ),
          },
          {
            key: 'importRecipient',
            className: '!p-0',
            label: (
              <PostServiceImportRecipientModalButton
                buttonProps={menuButtonProps}
                buttonText="Importovat adresáty"
                listName={props.record.listName}
                listId={props.record.id}
                reexecuteQuery={props.reexecuteQuery}
              />
            ),
          },
          {
            key: 'delete',
            className: '!p-0',
            label: (
              <Button
                title="Smazat seznam adresátů"
                size="middle"
                type="link"
                danger
                className="w-full"
              >
                Odstranit
              </Button>
            ),
          },
        ],
      }),
      [props.record, props.reexecuteQuery]
    )

    const actionButtons = useMemo(
      () =>
        props.record.recipients.totalCount === 0 ? (
          <PostServiceRecipientListTableSendDisabled
            listName={props.record.listName}
            listId={props.record.id}
            reexecuteQuery={props.reexecuteQuery}
          />
        ) : (
          <Link to={postServiceLetterPath(props.record.id)}>
            <Button
              type="link"
              size="small"
              title="Vybrat seznam adresátů a pokračovat k tvorbě dopisu"
              className="!pr-1"
            >
              Obeslat adresáty
            </Button>
          </Link>
        ),
      [
        props.record.id,
        props.record.listName,
        props.record.recipients.totalCount,
        props.reexecuteQuery,
      ]
    )

    const buttonsRender = useCallback(
      (buttons: ReactNode[]) => [actionButtons, buttons[1]],
      [actionButtons]
    )

    return (
      <Dropdown.Button
        icon={<MenuOutlined />}
        menu={menuProps}
        arrow
        type="link"
        trigger={clickTrigger}
        className="!flex justify-end items-end space-x-1"
        buttonsRender={buttonsRender}
      />
    )
  }
