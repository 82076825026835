import { Typography } from 'antd'
import React, { FunctionComponent } from 'react'

export interface PropertyDetailOwnershipPropertiesTitleProps {
  className?: string
  rootClassName?: string
  children?: React.ReactNode
}

const { Title } = Typography

export const PropertyDetailOwnershipPropertiesTitle: FunctionComponent<PropertyDetailOwnershipPropertiesTitleProps> =
  props => {
    return (
      <div className={props.rootClassName}>
        <Title level={4}>Všechny nemovitosti na LV</Title>
        <div className={props.className}>{props.children} </div>
      </div>
    )
  }
