import { Icon } from '@iconify/react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import {
  AuctionMonitoringFilterState,
  AuctionMonitoringRecord,
} from '../../../common/auctionMonitoringTypes'
import {
  AUCTION_STATES,
  AUCTION_TYPES,
} from '../../../common/auctionScrapeTypes'
import { UserActivityLog } from '../../../graphql/generated'
import { setAuctionDrawer } from '../../../redux/drawerSlice'
import { useAppDispatch } from '../../../redux/hooks'
import {
  numberSorter,
  stringSorter,
  utcDescDateSorter,
} from '../../utils/generalTableHelpers'
import { sparsePagination } from '../../utils/layoutConst'
import { seenRowClassName } from '../../utils/userActivityLogHelpers'
import { Min } from '../DateFormat/DateFormat'
import { Money } from '../Money/Money'
import { UserActivityLogBadge } from '../UserActivityLogBadge/UserActivityLogBadge'

export interface AuctionMonitoringTableProps {
  data?: AuctionMonitoringRecord[] | null
  filter: AuctionMonitoringFilterState
}

const tableId = 'auction-monitoring'
const scrollX = { x: 1000 }

const rowClassName = (record: AuctionMonitoringRecord) =>
  seenRowClassName(
    record.activityLogs?.nodes?.[0]?.editedAt,
    record.updatedAt || record.createdAt
  )

export const AuctionMonitoringTable: FunctionComponent<AuctionMonitoringTableProps> =
  ({ filter, data }) => {
    const dispatch = useAppDispatch()

    const setDrawer = useCallback(
      (id: string, type: AUCTION_TYPES) =>
        dispatch(setAuctionDrawer({ id: id, auctionType: type })),
      [dispatch]
    )

    const columns: ColumnsType<AuctionMonitoringRecord> = useMemo(
      () => [
        {
          title: 'Datum dražby',
          dataIndex: 'startDate',
          className: 'w-32',
          sorter: utcDescDateSorter('startDate'),
          render: (startDate: string) => startDate && <Min>{startDate}</Min>,
        },
        ...(filter?.state?.includes(AUCTION_STATES.ENDED)
          ? [
              {
                title: 'Datum ukončení',
                dataIndex: 'endDate',
                className: 'w-36',
                sorter: utcDescDateSorter('endDate'),
                render: (endDate: string) =>
                  endDate ? (
                    <Min>{endDate}</Min>
                  ) : (
                    <span className="text-gray-400">---</span>
                  ),
              },
              {
                title: 'Cena',
                dataIndex: 'price',
                className: 'w-32 !text-right',
                sorter: numberSorter('price'),
                render: (price: number) =>
                  price > 0 ? (
                    <Money
                      grayscale
                      amount={price}
                      decimalNumbers={0}
                      symbol="Kč"
                    />
                  ) : (
                    <span className="text-gray-400">---</span>
                  ),
              },
            ]
          : []),
        {
          title: 'Předmět dražby',
          dataIndex: 'title',
          sorter: stringSorter('title'),
          render: (title: string, record) => (
            <a
              onClick={() => setDrawer(record.id, record.type)}
              title="Zobrazit náhled dražby"
            >
              {title}
            </a>
          ),
        },
        {
          title: 'Odkaz do dražby',
          dataIndex: 'auctionedOn',
          sorter: stringSorter('auctionedOn'),
          render: (auctionedOn: string, record) => (
            <a
              className="flex items-center space-x-1"
              href={record.detailUrl}
              target="_blank"
              rel="noreferrer"
              title="Zobrazit detail dražby v novém okně"
            >
              <Icon
                icon="quill:link-out"
                className="-mb-0.5"
                width="12"
                height="12"
              />
              <span>{auctionedOn}</span>
            </a>
          ),
        },
        {
          title: 'Dražební vyhláška',
          dataIndex: 'auctionDecreeUrl',
          sorter: stringSorter('auctionDecreeUrl'),
          render: auctionDecreeUrl =>
            auctionDecreeUrl ? (
              <a
                className="flex items-center space-x-1"
                href={auctionDecreeUrl}
                target="_blank"
                rel="noreferrer"
                title="Zobrazit dražební vyhlášku v novém okně"
              >
                <Icon
                  icon="ph:file-text-thin"
                  className="-mb-0.5"
                  width="16"
                  height="16"
                />
                <span>vyhláška</span>
              </a>
            ) : (
              <span className="text-gray-400">---</span>
            ),
        },
        {
          title: 'Stav',
          dataIndex: 'state',
          sorter: stringSorter('state'),
          render: state =>
            state === AUCTION_STATES.UPCOMING ? 'Připravovaná' : 'Ukončená',
        },
        {
          title: 'Info',
          dataIndex: 'id',
          key: 'info',
          width: 110,
          render: (_, record) => (
            <UserActivityLogBadge
              userActivityLog={
                record.activityLogs?.nodes?.[0] as UserActivityLog
              }
              alertDateTo={
                (record.updatedAt as string) || (record.createdAt as string)
              }
            />
          ),
        },
      ],
      [filter?.state, setDrawer]
    )

    return (
      <Table
        rowKey="id"
        rowClassName={rowClassName}
        className="border-t border-gray-200"
        size="small"
        id={tableId}
        columns={columns}
        dataSource={data || []}
        pagination={sparsePagination}
        scroll={scrollX}
      />
    )
  }
