import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, message, Modal } from 'antd'
import { BaseButtonProps } from 'antd/es/button/button'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import {
  CreateLabelConnectionMutationVariables,
  useCreateLabelConnectionMutation,
  useDeleteLabelConnectionMutation,
} from '../../../graphql/generated'
import { LabelSelectInput } from '../LabelSelectInput/LabelSelectInput'

export interface TableCellEditLabelModalProps {
  labels: {
    nodes:
      | {
          id: string
          labelByLabelId: {
            id: string
          }
        }[]
      | []
  }
  refetch?: () => void
  modalTitle?: string
  buttonText?: React.ReactNode
  buttonProps?: BaseButtonProps
  recordId?: string
  labelEntityType: keyof CreateLabelConnectionMutationVariables
}

export const TableCellEditLabelModal: FunctionComponent<TableCellEditLabelModalProps> =
  props => {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [currentLabels, setCurrentLabels] = useState<string[]>()

    const [, addLabelConnection] = useCreateLabelConnectionMutation()
    const [, deleteLabelConnection] = useDeleteLabelConnectionMutation()

    const initialLabels = useMemo(
      () => props.labels?.nodes?.map(label => label?.labelByLabelId?.id) || [],
      [props.labels?.nodes]
    )

    const handleModalToggle = useCallback(() => {
      setOpen(prevOpen => !prevOpen)
    }, [])

    const getAddedLabels = useCallback(
      () =>
        currentLabels?.filter(label => !initialLabels.includes(label)) || [],
      [currentLabels, initialLabels]
    )

    const getLabelsToDelete = useCallback(
      () =>
        props.labels.nodes
          .filter(label => !currentLabels?.includes(label.labelByLabelId.id))
          .map(label => label.id),
      [currentLabels, props.labels.nodes]
    )

    const onSave = useCallback(async () => {
      setLoading(true)

      const addedLabels = getAddedLabels()
      const deleteConnectionId = getLabelsToDelete()

      for (const label of addedLabels) {
        await addLabelConnection({
          [props.labelEntityType]: props.recordId,
          labelId: label,
        })
      }

      for (const id of deleteConnectionId) {
        if (id) {
          await deleteLabelConnection({ id })
        }
      }

      setLoading(false)
      message.success('Štítky byly upraveny.')

      props.refetch?.()
      handleModalToggle()
    }, [
      addLabelConnection,
      deleteLabelConnection,
      getAddedLabels,
      getLabelsToDelete,
      handleModalToggle,
      props,
    ])

    return (
      <>
        <Button
          onClick={handleModalToggle}
          type="link"
          size="small"
          className="opacity-40 hover:opacity-100"
          title="Editovat štítky"
          icon={
            props.labels.nodes.length ? (
              <EditOutlined />
            ) : (
              <PlusOutlined className="!text-xs" />
            )
          }
          {...props.buttonProps}
        >
          {props.buttonText}
        </Button>
        {open && (
          <Modal
            title={
              props.modalTitle
                ? 'Editace štítků pro ' + props.modalTitle
                : 'Editace štítků'
            }
            open={open}
            onCancel={handleModalToggle}
            onOk={onSave}
            okText="Uložit"
            cancelText="Zrušit"
            confirmLoading={loading}
            width={600}
          >
            <div className="mt-4">
              <LabelSelectInput
                initialValue={initialLabels}
                onChange={setCurrentLabels}
              />
            </div>
          </Modal>
        )}
      </>
    )
  }
