import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import axios from 'axios'
import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { Rizeni } from '../../../common/proceedingTypes'
import {
  WatchdogChangeDetail,
  WatchdogChangeDetailProcessed,
  WatchdogChangesApiResponse,
} from '../../../common/watchdogTypes'
import { useAppSelector } from '../../../redux/hooks'
import { utcDateSorter } from '../../utils/generalTableHelpers'
import { parseProceedingString } from '../../utils/proceedingDetailHelpers'
import { watchdogParsedChanges } from '../../utils/watchdogHelpers'
import { Day } from '../DateFormat/DateFormat'
import { PageLayoutTabContainer } from '../PageLayoutTabContainer/PageLayoutTabContainer'
import { ProceedingDetailModalLink } from '../ProceedingDetailModalLink/ProceedingDetailModalLink'
import { PropertyDetailFetchError } from '../PropertyDetailFetchError/PropertyDetailFetchError'

export interface PropertyDetailWatchdogProps {
  propertyId: string
}

const columns: ColumnsType<WatchdogChangeDetailProcessed> = [
  {
    title: 'Datum',
    dataIndex: 'utcVytvoreno',
    className: 'w-24',
    sorter: utcDateSorter('utcVytvoreno'),
    render: utcVytvoreno => <Day>{utcVytvoreno}</Day>,
  },
  {
    title: 'Řízení',
    dataIndex: 'proceeding',
    className: ' lg:w-48',
    render: (proceedings: (string | null)[]) => {
      return proceedings.map((proceeding, index) => (
        <span key={index}>
          {proceeding ? (
            <ProceedingDetailModalLink
              proceeding={parseProceedingString(proceeding) as Rizeni}
            >
              {proceeding}
            </ProceedingDetailModalLink>
          ) : (
            ''
          )}
          {index < proceedings.length - 1 && <br />}
        </span>
      ))
    },
  },
  {
    title: 'Událost',
    dataIndex: 'title',
    className: 'lg:w-48',
    render: (titles: (string | null)[]) =>
      titles.map((title, index) => (
        <span key={index} className="whitespace-nowrap">
          {title || ''}
          {index < titles.length - 1 && <br />}
        </span>
      )),
  },
  {
    title: 'Popis',
    dataIndex: 'description',
    render: (descriptions: (string | null)[]) =>
      descriptions.map((text, index) => {
        return (
          <span key={index}>
            {text}
            {index < descriptions.length - 1 && <br />}
          </span>
        )
      }),
  },
]

export const PropertyDetailWatchdog: FunctionComponent<PropertyDetailWatchdogProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)

    const [data, setData] = useState<WatchdogChangeDetail[] | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState(false)

    const isFetched = useRef<boolean>(false)

    useEffect(() => {
      const fetchData = async () => {
        if (!isFetched.current) {
          isFetched.current = true
          setLoading(true)
          try {
            const response = await axios.get<WatchdogChangesApiResponse>(
              `https://data.regesta.cz/DrmApi/api/v2.0/Hlidani/InfoServis?access_token=${token}&nemovitostId=${props.propertyId}&pouzeNeprectene=false`
            )
            setData(response.data.data)
          } catch (error) {
            setError(true)
          } finally {
            setLoading(false)
          }
        }
      }

      fetchData()
    }, [props.propertyId, token])

    const mergedData = useMemo(() => {
      if (!data) return []
      return watchdogParsedChanges(data)
    }, [data])

    if (error)
      return (
        <PageLayoutTabContainer>
          <PropertyDetailFetchError
            description="Je nám líto, ale nepodařilo se načíst údaje z hlídače katastru. Opakujte prosím akci později."
            message="Chyba při načítání dat"
          />
        </PageLayoutTabContainer>
      )

    if (!mergedData.length && !loading)
      return (
        <PageLayoutTabContainer>
          <div className="text-center p-4 my-4 text-gray-400">
            Od vložení do hlídače neproběhly na nemovitosti žádné změny.
          </div>
        </PageLayoutTabContainer>
      )

    return (
      <PageLayoutTabContainer>
        <Table
          rowKey="id"
          size="small"
          className="mb-4"
          columns={columns}
          dataSource={mergedData}
          loading={loading}
          pagination={false}
        />
      </PageLayoutTabContainer>
    )
  }
