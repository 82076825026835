import React, { FunctionComponent } from 'react'

import { AddressSearchBar } from '../components/AddressSearchBar/AddressSearchBar'
import { PageLayout } from '../components/PageLayout/PageLayout'

export const Address: FunctionComponent = () => {
  return (
    <PageLayout title="Vyhledat adresu">
      <AddressSearchBar />
    </PageLayout>
  )
}

export default Address
