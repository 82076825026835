type HasOptionalNumber<K extends string> = {
  [P in K]?: number | null
}
type HasOptionalString<K extends string> = {
  [P in K]?: string | null
}
type HasOptionalDate<K extends string> = {
  [P in K]?: Date | string | null
}
type HasOptionalBoolean<K extends string> = {
  [P in K]?: boolean | null
}

export const numberSorter =
  <T extends HasOptionalNumber<K>, K extends string>(key: K) =>
  (a: T, b: T): number => {
    const valueA = a[key] ?? 0
    const valueB = b[key] ?? 0
    return valueA - valueB
  }

export const stringSorter =
  <T extends HasOptionalString<K>, K extends string>(key: K) =>
  (a: T, b: T): number => {
    const valueA = a[key] ?? ''
    const valueB = b[key] ?? ''
    return valueA.localeCompare(valueB)
  }

export const utcDateSorter =
  <T extends HasOptionalDate<K>, K extends string>(key: K) =>
  (a: T, b: T): number => {
    const valueA = a[key] ?? ''
    const valueB = b[key] ?? ''
    return valueA.toString().localeCompare(valueB.toString())
  }

export const utcDescDateSorter =
  <T extends HasOptionalDate<K>, K extends string>(key: K) =>
  (a: T, b: T): number => {
    const valueA = a[key] ?? ''
    const valueB = b[key] ?? ''
    return valueB.toString().localeCompare(valueA.toString())
  }

export const booleanSorter =
  <T extends HasOptionalBoolean<K>, K extends string>(key: K) =>
  (a: T, b: T): number => {
    const valueA = a[key] ? 1 : 0
    const valueB = b[key] ? 1 : 0
    return valueA - valueB
  }

export const areaNumberSorter =
  <T extends HasOptionalString<K>, K extends string>(key: K) =>
  (a: T, b: T): number =>
    compareParcelNumbers(a[key] ?? '', b[key] ?? '')

type HasNestedString<K extends string, N extends string> = {
  [P in K]?:
    | {
        [Q in N]?: string | null
      }
    | null
}

export const nestedStringSorter =
  <T extends HasNestedString<K, N>, K extends string, N extends string>(
    parentKey: K,
    childKey: N
  ) =>
  (a: T, b: T): number => {
    const valueA = a[parentKey]?.[childKey] ?? ''
    const valueB = b[parentKey]?.[childKey] ?? ''
    return valueA.localeCompare(valueB)
  }

const parseParcelNumber = (
  parcelNumber: string
): {
  isBuilding: boolean
  mainNumber: number
  subNumber: number | null
} => {
  let isBuilding = false
  let mainNumber = 0
  let subNumber: number | null = null

  const normalized = parcelNumber.toLowerCase().trim()
  if (normalized.startsWith('st.')) isBuilding = true

  const numberPart = isBuilding ? normalized.substring(3).trim() : normalized
  const parts = numberPart.split('/')

  mainNumber = parseInt(parts[0], 10)
  if (parts.length > 1) subNumber = parseInt(parts[1], 10)

  return {
    isBuilding,
    mainNumber,
    subNumber,
  }
}

export const compareParcelNumbers = (a: string, b: string): number => {
  const parsedA = parseParcelNumber(a || '')
  const parsedB = parseParcelNumber(b || '')

  if (parsedA.isBuilding !== parsedB.isBuilding) {
    return parsedA.isBuilding ? -1 : 1
  }

  if (parsedA.mainNumber !== parsedB.mainNumber) {
    return parsedA.mainNumber - parsedB.mainNumber
  }

  if (parsedA.subNumber === null && parsedB.subNumber !== null) return -1
  if (parsedA.subNumber !== null && parsedB.subNumber === null) return 1
  if (parsedA.subNumber !== null && parsedB.subNumber !== null) {
    return parsedA.subNumber - parsedB.subNumber
  }

  return 0
}
