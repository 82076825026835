import { Content } from 'antd/es/layout/layout'
import axios from 'axios'
import React, { FunctionComponent, useCallback, useEffect, useRef } from 'react'
import { Route, RouteProps } from 'react-router'

import { BASE_URL } from '../../../../ini.json'
import { ProfileDataResponse } from '../../../common/myProfileTypes'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { setMyProfile } from '../../../redux/myProfileSlice'
import { AntBreadcrumb } from '../AntBreadcrumb/AntBreadcrumb'
import { PageSkeleton } from '../PageSkeleton/PageSkeleton'

export type PrivateRouteProps = RouteProps

const loginRedirect = () =>
  window.location.replace(
    'https://data.regesta.cz/UzivatelskyUcet/Prihlaseni?ReturnUrl='
  )

const fetchProfile = async (token: string) => {
  const response = await axios.get<ProfileDataResponse>(
    `${BASE_URL}/api/profileData/${token}`
  )
  return response.data
}

const fetchToken = async (tenantId: string) => {
  const response = await axios.get<{ access_token: string }>(
    `${BASE_URL}/api/profileToken/${tenantId}`,
    {
      withCredentials: true,
    }
  )
  return response.data
}

export const PrivateRoute: FunctionComponent<
  RouteProps & { message?: string; hideBreadcrumb?: boolean }
> = ({ component: Component, hideBreadcrumb, ...props }) => {
  const dispatch = useAppDispatch()

  const { token: myProfileToken, tenantId: myProfileTenandId } = useAppSelector(
    state => state.myProfile
  )
  const isVerifying = useRef<boolean>(false)

  const currentUrl = window.location.href
  const index = currentUrl.indexOf('/?t=')
  const urlToken = index !== -1 ? currentUrl.slice(index + 4) : ''

  const token = myProfileToken || localStorage.getItem('token') || ''
  const tenantId = myProfileTenandId || localStorage.getItem('firmaId') || ''

  const currentPath = typeof props.path === 'string' ? props.path : ''
  const hasStoredData = !!(myProfileTenandId && token && tenantId)

  const setProfileData = useCallback(
    (data: ProfileDataResponse, newToken: string) => {
      dispatch(
        setMyProfile({
          userId: data.id,
          token: newToken,
          tenantId: data.firma.id,
          firstName: data.jmeno || '',
          lastName: data.prijmeni || '',
          companyName: data.firma.nazev || '',
          claim: data.role,
          autoImport: data.autoImport || false,
          autoWindowOpen: data.automatickyOteviratNahlizeni || false,
          regestaCrm: data.regestaCrm || '',
        })
      )
      localStorage.setItem('token', newToken)
      localStorage.setItem('firmaId', data.firma.id)
      isVerifying.current = false
    },
    [dispatch]
  )

  useEffect(() => {
    const verifyToken = async () => {
      isVerifying.current = true

      if (urlToken) {
        try {
          const profileData = await fetchProfile(urlToken)
          setProfileData(profileData, urlToken)
        } catch (error) {
          loginRedirect()
        }
        return
      }

      if (!token || !tenantId) {
        return loginRedirect()
      }

      try {
        const profileData = await fetchProfile(token)
        setProfileData(profileData, token)
      } catch (error) {
        try {
          const newTokenData = await fetchToken(tenantId)
          const newToken = newTokenData.access_token
          const profileData = await fetchProfile(newToken)
          setProfileData(profileData, newToken)
        } catch (error) {
          loginRedirect()
        }
      }
    }

    if (!isVerifying.current) verifyToken()
  }, [token, tenantId, props.path, urlToken, setProfileData]) // props.path is needed to trigger on route change

  if (!Component) return null

  return (
    <Route
      exact
      {...props}
      render={routeProps => (
        <Content className="flex flex-col max-w-full">
          {!hideBreadcrumb && <AntBreadcrumb currentPath={currentPath} />}
          {hasStoredData ? <Component {...routeProps} /> : <PageSkeleton />}
        </Content>
      )}
    />
  )
}
