import { Skeleton } from 'antd'
import React, { FunctionComponent } from 'react'

export interface PropertyDetailProceedingTableExpandedRowItemProps {
  isTitle?: boolean
  label?: React.ReactNode
  children?: React.ReactNode
  conditional?: boolean
}

const getRandomWidth = () => {
  const width = [
    'w-2/5',
    'w-2/3',
    'w-6/12',
    'w-7/12',
    'w-8/12',
    'w-9/12',
    'w-10/12',
    'w-11/12',
  ]
  const randomIndex = Math.floor(Math.random() * 8)
  return width[randomIndex]
}
const skeletonProps = {
  active: true,
  paragraph: false,
  className: 'pt-1 pb-0.5',
}

export const PropertyDetailProceedingTableExpandedRowItem: FunctionComponent<PropertyDetailProceedingTableExpandedRowItemProps> =
  props => {
    if (props.conditional && !props?.children) return null
    if (!props?.label)
      return (
        <div className="flex p-2 space-x-2">
          <div className="flex-1">
            <div className={getRandomWidth()}>
              <Skeleton {...skeletonProps} />
            </div>
          </div>
          <div className="flex-1">
            <div className={getRandomWidth()}>
              <Skeleton {...skeletonProps} />
            </div>
          </div>
        </div>
      )

    return (
      <div
        className={
          'flex p-2 space-x-2' +
          (props.isTitle ? ' bg-[#fafafa] font-semibold' : '')
        }
      >
        <div className="flex-1">{props.label}</div>
        <div className="flex-1">{props.children}</div>
      </div>
    )
  }
