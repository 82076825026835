import { InfoCircleOutlined } from '@ant-design/icons'
import { Alert } from 'antd'
import React, { FunctionComponent, memo } from 'react'

import { LvApiResponse, LvGeometryApiResponse } from '../../../common/lvTypes'
import { LvDetailSidebarImage } from '../LvDetailSidebarImage/LvDetailSidebarImage'
import { LvDetailSidebarLv } from '../LvDetailSidebarLv/LvDetailSidebarLv'
import { LvDetailSidebarRestriction } from '../LvDetailSidebarRestriction/LvDetailSidebarRestriction'
import { PropertyDetailLastNote } from '../PropertyDetailLastNote/PropertyDetailLastNote'

export interface LvDetailSidebarProps {
  lvData: LvApiResponse
  geometryData: LvGeometryApiResponse
}

export const LvDetailSidebar: FunctionComponent<LvDetailSidebarProps> = memo(
  props => {
    return (
      <div className="flex flex-col space-y-3 mb-4">
        <LvDetailSidebarImage geometryData={props.geometryData} />

        <LvDetailSidebarRestriction properties={props.lvData?.nemovitosti} />

        <PropertyDetailLastNote
          lvCode={props.lvData?.katastralniUzemi?.kod}
          lvNumber={props.lvData?.lv}
        />
        <LvDetailSidebarLv
          lvNumber={props.lvData?.lv}
          cadastralArea={props.lvData?.katastralniUzemi}
        />
        <Alert
          showIcon
          icon={<InfoCircleOutlined />}
          message="Pro zobrazení bližších informací vyberte konkrétní nemovitost v tabulce."
          type="success"
          className="grayscale opacity-50"
        />
      </div>
    )
  }
)
