import { Alert, Spin } from 'antd'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { BASE_URL } from '../../../../ini.json'
import { LvApiResponse } from '../../../common/lvTypes'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { setLvDetail } from '../../../redux/lvDetailSlice'

export interface LvDetailReduxSetterProps {
  lv: string
  katastrUzemiKod: string
}

export const LvDetailReduxSetter: FunctionComponent<LvDetailReduxSetterProps> =
  props => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const token = useAppSelector(state => state.myProfile.token)

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [dataSource, setDataSource] = useState(false)
    const dispatch = useAppDispatch()

    const fetchLvData = useCallback(
      async (data: LvApiResponse) => {
        dispatch(
          setLvDetail({
            katastralniUzemi: data.katastralniUzemi,
            lv: data.lv,
            nemovitosti: data.nemovitosti,
            pravniVztahy: data.pravniVztahy,
          })
        )
      },
      [dispatch]
    )

    useEffect(() => {
      if (dataSource) return

      fetch(
        `${BASE_URL}/api/lv/${tenantId}/${token}/${props.katastrUzemiKod}/${props.lv}/true`
      )
        .then(response => response.json())
        .then(async (data: LvApiResponse) => {
          await fetchLvData(data)
          setDataSource(true)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
          setError(true)
        })
    }, [
      dataSource,
      fetchLvData,
      props.katastrUzemiKod,
      props.lv,
      tenantId,
      token,
    ])

    if (error)
      return (
        <Alert
          message="Nepodařilo se načíst některé interaktivní prvky."
          banner
          closable
          className="!mb-4"
        />
      )
    if (loading)
      return (
        <div className="mb-4 flex flex-col justify-center align-middle">
          <Spin spinning />
          <div className="mb-2 mt-2 text-center text-gray-400">
            Načítají se interaktivní prvky...
          </div>
        </div>
      )
    return null
  }
