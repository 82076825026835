import React, { FunctionComponent } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { useAppSelector } from '../../../redux/hooks'
import { TabLabel } from '../TabLabel/TabLabel'
import { TabLabelSubjectTooltip } from '../TabLabelSubjectTooltip/TabLabelSubjectTooltip'

export interface TabLabelSubjectProps {
  addressId?: number
}

export const TabLabelSubject: FunctionComponent<TabLabelSubjectProps> =
  props => {
    const loading = useAppSelector(state => state.property.ruian.fetching)
    const propertyType = useAppSelector(
      state => state.property.ruian.data?.type
    )
    const showTooltip =
      !loading && (!props.addressId || propertyType !== PROPERTY_TYPES.BUILDING)

    return (
      <TabLabel
        loading={loading}
        title="Subjekty na adrese"
        tooltip={showTooltip ? <TabLabelSubjectTooltip /> : undefined}
      />
    )
  }
