import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AUCTION_TYPES } from '../common/auctionScrapeTypes'
import { PROPERTY_TYPES } from '../common/drmTypes'

export enum DRAWER_TYPE {
  PROPERTY = 'property',
  AUCTION = 'auction',
}

interface DrawerState {
  [DRAWER_TYPE.PROPERTY]: {
    propertyType: PROPERTY_TYPES
    id: string
  } | null
  [DRAWER_TYPE.AUCTION]: {
    auctionType: AUCTION_TYPES
    id: string
  } | null
}

const initialState: DrawerState = {
  [DRAWER_TYPE.PROPERTY]: null,
  [DRAWER_TYPE.AUCTION]: null,
}

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setPropertyDrawer: (
      state,
      action: PayloadAction<{ id: string; propertyType: PROPERTY_TYPES }>
    ) => {
      state[DRAWER_TYPE.PROPERTY] = {
        propertyType: action.payload.propertyType,
        id: action.payload.id,
      }
    },
    resetPropertyDrawer: state => {
      state[DRAWER_TYPE.PROPERTY] = null
    },
    setAuctionDrawer: (
      state,
      action: PayloadAction<{ id: string; auctionType: AUCTION_TYPES }>
    ) => {
      state[DRAWER_TYPE.AUCTION] = {
        auctionType: action.payload.auctionType,
        id: action.payload.id,
      }
    },
    resetAuctionDrawer: state => {
      state[DRAWER_TYPE.AUCTION] = null
    },
  },
})

export const {
  resetPropertyDrawer,
  setPropertyDrawer,
  setAuctionDrawer,
  resetAuctionDrawer,
} = drawerSlice.actions
export default drawerSlice.reducer
