import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import { ForclosureMonitoringFilterOnSubmit } from '../../../common/foreclosureMonitoringTypes'

export interface ForeclosureMonitoringTablePaginationProps {
  changePage: ForclosureMonitoringFilterOnSubmit
  currentPage: number
  hasNextPage: boolean
}

export const PropertyMonitoringTablePagination: FunctionComponent<ForeclosureMonitoringTablePaginationProps> =
  props => {
    const incrementPage = useCallback(() => {
      props.changePage({ stranka: props.currentPage + 1 })
    }, [props])

    const decrementPage = useCallback(() => {
      props.changePage({ stranka: props.currentPage - 1 })
    }, [props])

    return (
      <div className="flex justify-center mb-4 mt-8">
        <Button
          onClick={decrementPage}
          disabled={props.currentPage === 1}
          className="w-44 !rounded-r-none"
          type="primary"
          ghost
        >
          <LeftOutlined /> Předchozí stránka
        </Button>

        <div className="w-16 flex justify-center items-center border-gray-300 border-y">
          {props.currentPage}
        </div>

        <Button
          onClick={incrementPage}
          disabled={!props.hasNextPage}
          className="w-44 !rounded-l-none"
          type="primary"
          ghost
        >
          Další stránka <RightOutlined />
        </Button>
      </div>
    )
  }
