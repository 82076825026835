import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { MyProfileData } from '../common/myProfileTypes'

export const myProfileSlice = createSlice({
  name: 'myProfile',
  initialState: {
    userId: '',
    token: '',
    tenantId: '',
    claim: [''],
    firstName: '',
    lastName: '',
    companyName: '',
    autoImport: false,
    autoWindowOpen: false,
    regestaCrm: '',
  },
  reducers: {
    setMyProfile: (
      state: MyProfileData,
      action: PayloadAction<MyProfileData>
    ) => {
      state.userId = action.payload.userId
      state.token = action.payload.token
      state.tenantId = action.payload.tenantId
      state.claim = action.payload.claim
      state.firstName = action.payload.firstName
      state.lastName = action.payload.lastName
      state.companyName = action.payload.companyName
      state.autoImport = action.payload.autoImport
      state.autoWindowOpen = action.payload.autoWindowOpen
      state.regestaCrm = action.payload.regestaCrm
    },
    setCrmAutoImport: (
      state: MyProfileData,
      action: PayloadAction<{ autoImport: boolean }>
    ) => {
      state.autoImport = action.payload.autoImport
    },
  },
})

export const { setMyProfile, setCrmAutoImport } = myProfileSlice.actions

export default myProfileSlice.reducer
