import { Form, Input } from 'antd'
import { RuleObject } from 'antd/es/form'
import { StoreValue } from 'antd/es/form/interface'
import React, { FunctionComponent, useMemo } from 'react'

import { postServiceFormHelp } from '../../utils/cuzkMonitorRcFormHelp'
import { itemRequired } from '../../utils/formHelpers'

export interface PostServiceAddRecipientFormStreetFragmentProps {
  showHelp?: boolean
}

const styles = {
  margin: { marginRight: '8px' },
  width: { width: '25%' },
}
const name = {
  streetName: ['street', 'streetName'],
  houseNumber: ['street', 'houseNumber'],
  orientationNumber: ['street', 'orientationNumber'],
}

export const PostServiceAddRecipientFormStreetFragment: FunctionComponent<PostServiceAddRecipientFormStreetFragmentProps> =
  props => {
    const form = Form.useFormInstance()

    const validateRules = useMemo(
      () => [
        {
          validator: async (_: RuleObject, value: StoreValue) => {
            const orientationNumber = form.getFieldValue(name.orientationNumber)
            if (!value && !orientationNumber) {
              throw new Error('Zadejte číslo popisné či orientační')
            }
          },
        },
      ],
      [form]
    )

    return (
      <Form.Item
        label="Ulice"
        required
        help={props.showHelp ? postServiceFormHelp.street : undefined}
      >
        <div className="flex">
          <Form.Item
            name={name.streetName}
            noStyle
            rules={itemRequired('Uveďte název ulice')}
          >
            <Input placeholder="Název ulice" style={styles.margin} />
          </Form.Item>

          <Form.Item name={name.houseNumber} noStyle rules={validateRules}>
            <Input placeholder="ČP" style={styles.width} />
          </Form.Item>

          <div className="self-center mx-1 text-gray-400">{'/'}</div>

          <Form.Item name={name.orientationNumber} noStyle>
            <Input placeholder="ČO" style={styles.width} />
          </Form.Item>
        </div>
      </Form.Item>
    )
  }
