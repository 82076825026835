import { Button, message, Popconfirm } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { useDeleteMonitoringCuzkRcMutation } from '../../../graphql/generated'
import { RcTableItem } from '../CuzkMonitorRcTable/CuzkMonitorRcTable'

export interface CuzkMonitorRcBulkDeleteProps {
  items: RcTableItem[]
  refetch: () => void
  buttonText?: string
  buttonProps?: ButtonProps
}

export const CuzkMonitorRcBulkDelete: FunctionComponent<CuzkMonitorRcBulkDeleteProps> =
  props => {
    const [, deleteMonitoredSubject] = useDeleteMonitoringCuzkRcMutation()
    const [loading, setLoading] = useState(false)

    const singleDelete = props.items.length === 1

    const deleteItems = useCallback(async () => {
      setLoading(true)
      const promises = props.items.map(async item => {
        try {
          await deleteMonitoredSubject({
            input: { id: item.id },
          })
        } catch (error) {
          // TODO: handle error
        }
      })

      try {
        await Promise.all(promises)
        message.success(
          singleDelete ? 'Záznam byl odebrán.' : 'Záznamy byly odebrány.'
        )
      } catch (error) {
        // TODO: handle error
      } finally {
        setLoading(false)
        props.refetch()
      }
    }, [props, deleteMonitoredSubject, singleDelete])

    const deleteTitle = useMemo(() => {
      if (!props.items?.[0]) return undefined
      if (props.items[0].firstname && props.items[0].lastname)
        return props.items[0].firstname + ' ' + props.items[0].lastname
      if (props.items[0].ico) return 'IČO: ' + props.items[0].ico
      if (props.items[0].rc) return 'RČ: ' + props.items[0].rc
      return undefined
    }, [props.items])

    return (
      <Popconfirm
        title={
          singleDelete
            ? deleteTitle
            : 'Opravdu chcete odebrat vybrané subjekty?'
        }
        onConfirm={deleteItems}
        description={
          singleDelete ? 'Opravdu chcete odebrat vybraný subjekt?' : undefined
        }
        okText="Smazat"
        cancelText="Zrušit"
        placement="topRight"
        arrow={false}
      >
        <Button type="link" danger loading={loading} {...props.buttonProps}>
          {props.buttonText || 'Smazat'}
        </Button>
      </Popconfirm>
    )
  }
