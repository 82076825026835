import { useCallback } from 'react'

export const declensionDictionary = {
  notValid: { singular: 'nevalidní', few: 'nevalidní', other: 'nevalidních' },
  all: { singular: 'všechny', few: 'všechny', other: 'všech' },
  current: { singular: 'aktuální', few: 'aktuální', other: 'aktuálních' },
  historical: {
    singular: 'historický',
    few: 'historické',
    other: 'historických',
  },

  area: { singular: 'parcela', few: 'parcely', other: 'parcel' },
  building: { singular: 'budova', few: 'budovy', other: 'budov' },
  unit: { singular: 'jednotka', few: 'jednotky', other: 'jednotek' },

  data: { singular: 'údaj', few: 'údaje', other: 'údajů' },
  item: { singular: 'položka', few: 'položky', other: 'položek' },
  record: { singular: 'záznam', few: 'záznamy', other: 'záznamů' },
  request: { singular: 'požadavek', few: 'požadavky', other: 'požadavků' },
  document: { singular: 'dokument', few: 'dokumenty', other: 'dokumentů' },
  letter: { singular: 'dopis', few: 'dopisy', other: 'dopisů' },
  channel: { singular: 'kanál', few: 'kanály', other: 'kanálů' },

  selected: { singular: 'Vybrán', few: 'Vybrány', other: 'Vybráno' },
  sent: { singular: 'rozeslaný', few: 'rozeslané', other: 'rozeslaných' },

  person: { singular: 'osoba', few: 'osoby', other: 'osob' },
  subject: { singular: 'subjekt', few: 'subjekty', other: 'subjektů' },
  recipient: { singular: 'adresát', few: 'adresáty', other: 'adresátů' },

  day: { singular: 'den', few: 'dny', other: 'dní' },

  beenFound: {
    singular: 'byl nalezen',
    few: 'byly nalezeny',
    other: 'bylo nalezeno',
  },
  notValidOut: {
    singular: 'nevalidní údaj byl ze seznamu vyřazen',
    few: 'nevalidní údaje byly ze seznamu vyřazeny',
    other: 'nevalidních údajů bylo ze seznamu vyřazeno',
  },
  willCheck: {
    singular: 'Zkontrolován bude',
    few: 'Zkontrolovány budou celkem',
    other: 'Zkontrolováno bude celkem',
  },
  willNotCheck: {
    singular: 'nebude kontrolován',
    few: 'nebudou kontrolovány',
    other: 'nebude kontrolováno',
  },
  selectedItems: {
    singular: 'vybraný záznam',
    few: 'vybrané záznamy',
    other: 'vybraných záznamů',
  },
}
export const useDeclension = () => {
  const decline = useCallback(
    (
      number: number,
      word: keyof typeof declensionDictionary,
      prefix?: boolean
    ): string => {
      const forms = declensionDictionary[word]
      if (!forms) return ''

      let result: string
      if (number === 1) {
        result = forms.singular
      } else if (number > 1 && number < 5) {
        result = forms.few
      } else {
        result = forms.other
      }

      return prefix ? `${number} ${result}` : result
    },
    []
  )
  return decline
}
