import { useEffect, useState } from 'react'

import {
  useCreateUserActivityLogMutation,
  UserActivityActionType,
  UserActivityEntityType,
  useUpdateUserActivityLogMutation,
} from '../../graphql/generated'
import { useAppSelector } from '../../redux/hooks'

interface AuctionListingById {
  id: string
  userActivityLogsByAuctionListingId?: {
    nodes?: Array<{
      nodeId: string
    } | null> | null
  } | null
}

export const useAuctionActivityLog = (
  auctionListing?: AuctionListingById | null
) => {
  const [, createActivityLog] = useCreateUserActivityLogMutation()
  const [, updateActivityLog] = useUpdateUserActivityLogMutation()
  const { userId, tenantId } = useAppSelector(state => state.myProfile)
  const [hasLoggedActivity, setHasLoggedActivity] = useState(false)

  useEffect(() => {
    if (!auctionListing?.id || hasLoggedActivity) return

    const nodeId =
      auctionListing.userActivityLogsByAuctionListingId?.nodes?.[0]?.nodeId
    const now = new Date()

    if (nodeId) {
      updateActivityLog({
        nodeId,
        actionType: UserActivityActionType.SeenDetail,
        editedAt: now,
      })
    } else {
      createActivityLog({
        accountUserId: userId,
        accountId: tenantId,
        actionType: UserActivityActionType.SeenDetail,
        entityType: UserActivityEntityType.Auction,
        entityId: auctionListing.id,
        auctionListingId: auctionListing.id,
      })
    }

    setHasLoggedActivity(true)
  }, [
    auctionListing?.id,
    auctionListing?.userActivityLogsByAuctionListingId?.nodes,
    userId,
    tenantId,
    createActivityLog,
    updateActivityLog,
    hasLoggedActivity,
  ])

  // Reset if auction ID changed
  useEffect(() => {
    setHasLoggedActivity(false)
  }, [auctionListing?.id])
}
