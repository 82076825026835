import { Badge, Carousel, Image } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { BASE_URL } from '../../../../ini.json'
import { MarketplaceItemImage } from '../MarketplaceItemImage/MarketplaceItemImage'

export interface MarketplaceItemHeaderProps {
  images?: string[]
  offerType?: string
  id: string
  bought?: boolean
  owner?: boolean
  noGrayscale?: boolean
}

export const MarketplaceItemHeader: FunctionComponent<MarketplaceItemHeaderProps> =
  props => {
    const [imageIndex, setImageIndex] = useState(0)
    const [visible, setVisible] = useState(false)

    const setImage = useCallback((index: number) => {
      setImageIndex(index)
      setVisible(true)
    }, [])

    const imagesData = useMemo(() => {
      return (
        props.images?.map(
          (image: string) =>
            `${BASE_URL}/download/marketplace/${props.id}/${image.replace(
              '.',
              '_thumb.'
            )}`
        ) || []
      )
    }, [props.id, props.images])

    const ImageGrid = useMemo(() => {
      const rows = []

      for (let i = 1; i < imagesData.length; i += 3) {
        const imageSlice = imagesData.slice(i, i + 3)
        rows.push(
          <div key={i} className="!grid grid-cols-3 gap-1 mt-1 max-w-lg">
            {imageSlice.map((img: string, j: number) => (
              <MarketplaceItemImage
                grayscale={props.bought && !props.noGrayscale}
                key={i + j}
                index={i + j}
                setImage={setImage}
                src={img}
              />
            ))}
          </div>
        )
      }
      return rows
    }, [imagesData, props.bought, props.noGrayscale, setImage])

    const previewProps = useMemo(
      () => ({
        current: imageIndex,
        visible,
        keyboard: true,
        onVisibleChange: setVisible,
      }),
      [imageIndex, visible]
    )

    const ribbonText = useMemo(() => {
      if (props.owner) return `${props.offerType} - VAŠE NABÍDKA`
      if (props?.bought) {
        return `${props.offerType} - ZAKOUPENO  `
      } else {
        return props.offerType
      }
    }, [props?.bought, props.offerType, props.owner])

    return (
      <>
        <div>
          <Badge.Ribbon
            text={ribbonText}
            placement="start"
            className="!text-base !h-auto !pl-4 !py-1 !shadow-sm"
          >
            <div
              className={`relative w-full pb-full ${
                props.bought && !props.noGrayscale ? 'grayscale opacity-70' : ''
              }`}
            >
              <MarketplaceItemImage
                key={0}
                index={0}
                setImage={setImage}
                src={imagesData[0]}
                height="h-60"
              />
            </div>
          </Badge.Ribbon>
          <Carousel
            className="flex max-w-lg"
            draggable
            dots={{
              className:
                'w-10/12 mx-auto mt-4 h-4 [&_button]:!h-0 [&_button]:!p-0.5 [&_button]:!bg-[#5a74be] !-bottom-4 [&_li]:!w-1/3 [&_button]:!border-solid [&_button]:!border-white [&_button]:!border-b-8 [&_button]:!border-t-8',
            }}
          >
            {ImageGrid}
          </Carousel>
        </div>
        <div className="hidden">
          <Image.PreviewGroup preview={previewProps}>
            {props.images?.map((imageData: string) => (
              <Image
                key={imageData}
                src={`${BASE_URL}/download/marketplace/${props.id}/${imageData}`}
              />
            ))}
          </Image.PreviewGroup>
        </div>
      </>
    )
  }
