import { Button } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import { useAppSelector } from '../../../redux/hooks'
import { checkCuzkSubject } from '../../utils/checkCuzkSubject'
import { bulkEditTransition } from '../../utils/layoutConst'
import { pricelist } from '../../utils/pricelist'
import { CuzkMonitorRcBulkDelete } from '../CuzkMonitorRcBulkDelete/CuzkMonitorRcBulkDelete'
import { CuzkMonitorRcBulkEdit } from '../CuzkMonitorRcBulkEdit/CuzkMonitorRcBulkEdit'
import { RcTableItem } from '../CuzkMonitorRcTable/CuzkMonitorRcTable'
import { ExportTableXlsxButton } from '../ExportTableXlsxButton/ExportTableXlsxButton'
import { Fade } from '../Fade/Fade'
import { PricePopconfirm } from '../PricePopconfirm/PricePopconfirm'
import { TableItemsCount } from '../TableItemsCount/TableItemsCount'

export interface TableBulkEditPanelProps {
  selectedRows: RcTableItem[]
  setLoadingId: (id: string[]) => void
  refetch: () => void
  resetSelection: () => void
}

export const TableBulkEditPanel: FunctionComponent<TableBulkEditPanelProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)
    const selectedCount = props.selectedRows?.length || 0

    const checkNow = useCallback(async () => {
      const ids = props.selectedRows.map(item => item.id)
      props.setLoadingId(ids)
      props.resetSelection()
      const check = await checkCuzkSubject(ids, token)
      if (check) props.refetch()
      return props.setLoadingId([])
    }, [props, token])

    return (
      <Fade
        transition={bulkEditTransition}
        show={!!selectedCount}
        className="flex space-x-4 items-center mb-4"
      >
        <PricePopconfirm
          onConfirm={checkNow}
          itemPrice={pricelist.CUZK}
          itemCount={selectedCount}
          message={`Kontrola v evidenci ČÚZK je zpoplatněna ${pricelist.CUZK} Kr.`}
        >
          <Button type="primary">Zkontrolovat vybrané záznamy</Button>
        </PricePopconfirm>
        <CuzkMonitorRcBulkEdit
          refetch={props.refetch}
          items={props.selectedRows}
        />
        <ExportTableXlsxButton
          url="cuzk/cuzkOsXlsx"
          fileName="CUZK-osoby"
          dataSource={props.selectedRows || []}
        />
        <CuzkMonitorRcBulkDelete
          items={props.selectedRows}
          refetch={props.refetch}
          buttonText="Smazat vybrané"
        />
        <TableItemsCount prefix="Vybráno" separator total={selectedCount} />
      </Fade>
    )
  }
