import React, { FunctionComponent, memo } from 'react'

export interface MonitoringTilesItemRowProps {
  label: string
  value: React.ReactNode
  condition?: boolean
}

export const PropertyMonitoringTilesItemRow: FunctionComponent<MonitoringTilesItemRowProps> =
  memo(({ label, value, condition = true }) => {
    if (!condition) return null

    return (
      <div className="pb-1 mb-1.5 border-b border-solid border-gray-100 flex justify-between">
        <div>{label}</div>
        <div>{value}</div>
      </div>
    )
  })
