import { Skeleton } from 'antd'
import React, { FunctionComponent, memo } from 'react'

import { PropertyDetailFetchError } from '../PropertyDetailFetchError/PropertyDetailFetchError'

export const CadastreMapDetailDrawerFetchError: FunctionComponent = memo(() => {
  return (
    <>
      <Skeleton.Image className="!w-full !h-72 mb-4" />
      <PropertyDetailFetchError
        message="Nemovitost nebyla nalezena"
        description="Je nám líto, ale nepodařilo se nalézt detailní informace k vybrané nemovitosti."
      />
    </>
  )
})
