import { Icon } from '@iconify/react'
import { Button, InputNumber } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import {
  letterImageButtonStyles as style,
  LetterImageProps,
} from '../../../common/postServiceTypes'

export interface PostServiceLetterImagePositionInputProps {
  type: 'left' | 'top' | 'right' | 'bottom'
  handleOk: () => void
  imageProps: LetterImageProps
  setImageProps: React.Dispatch<React.SetStateAction<LetterImageProps>>
}

const typeProps = {
  left: { rotate: 45, placeholder: 'Zleva', after: 'after:content-["zleva"]' },
  top: { rotate: 90, placeholder: 'Shora', after: 'after:content-["shora"]' },
  bottom: { rotate: 0, placeholder: 'Zdola', after: 'after:content-["zdola"]' },
  right: {
    rotate: 135,
    placeholder: 'Zprava',
    after: 'after:content-["zprava"]',
  },
}

export const PostServiceLetterImagePositionInput: FunctionComponent<PostServiceLetterImagePositionInputProps> =
  props => {
    const icon = useMemo(
      () => (
        <Icon
          icon="mingcute:align-arrow-up-fill"
          className="text-gray-400 mx-1"
          width="13"
          height="13"
          rotate={typeProps[props.type].rotate}
        />
      ),
      [props.type]
    )

    const disable = useMemo(
      () =>
        (props.type === 'left' && !!props.imageProps.right) ||
        (props.type === 'top' && !!props.imageProps.bottom) ||
        (props.type === 'right' && !!props.imageProps.left) ||
        (props.type === 'bottom' && !!props.imageProps.top),
      [
        props.imageProps.bottom,
        props.imageProps.left,
        props.imageProps.right,
        props.imageProps.top,
        props.type,
      ]
    )

    const handleChange = useCallback(
      (value: number | null) => {
        if (typeof value === 'number') {
          props.setImageProps(prev => ({ ...prev, [props.type]: value }))
        } else props.setImageProps(prev => ({ ...prev, [props.type]: 0 }))
      },
      [props]
    )

    return (
      <>
        <Button type="primary" disabled style={style.label} icon={icon} />
        <InputNumber
          value={props.imageProps[props.type]}
          placeholder={typeProps[props.type].placeholder}
          onChange={handleChange}
          onPressEnter={props.handleOk}
          decimalSeparator=","
          disabled={disable}
          className={
            'after:text-gray-400 after:absolute after:ml-2 hover:after:opacity-100 after:opacity-0 after:transition-opacity ' +
            typeProps[props.type].after
          }
        />
      </>
    )
  }
