import { Page, Text, View } from '@react-pdf/renderer'
import React, { FunctionComponent, useMemo } from 'react'

import {
  RisyData,
  SmsItem,
  StatisticalDataItem,
} from '../../../common/risyTypes'
import { monitPdfCss } from '../../utils/monitPdfCss'
import { PriceEstimatePdfTableRow as Row } from '../PriceEstimatePdfTableRow/PriceEstimatePdfTableRow'

export interface PriceEstimatePdfRisyFragmentProps {
  risyData?: RisyData & { area?: string }
}

export const PriceEstimatePdfRisyFragment: FunctionComponent<PriceEstimatePdfRisyFragmentProps> =
  React.memo(props => {
    const cityObj = useMemo(() => {
      const data = [
        ...(props.risyData?.city || []),
        ...(props.risyData?.education || []),
        ...(props.risyData?.civicAmenities || []),
        ...(props.risyData?.socialSector || []),
      ] as StatisticalDataItem[]
      return data?.reduce<{
        [key: string]: StatisticalDataItem
      }>((acc, item) => {
        acc[item.key] = item
        return acc
      }, {} as { [key: string]: StatisticalDataItem })
    }, [props.risyData])

    const sms = useMemo(() => {
      const data = [...(props.risyData?.sms || [])] as SmsItem[]
      return data?.reduce<{
        [question: string]: SmsItem
      }>((acc, item) => {
        acc[item.question] = item
        return acc
      }, {} as { [key: string]: SmsItem })
    }, [props.risyData])

    if (!props.risyData) return <View></View>
    return (
      <Page size="A4" style={monitPdfCss.page}>
        <Text style={monitPdfCss.header} fixed>
          Vytvořeno v REGESTA DATA 2.0 | zdroj statistických dat: risy.cz
        </Text>
        <View wrap={false}>
          <View style={monitPdfCss.pageTitle}>
            <Text>
              INFORMACE O OBCI{' '}
              {props.risyData?.area ? ' - ' + props.risyData.area : ''}
            </Text>
          </View>

          <Row dataItem={cityObj?.['10300']} suffix=" ha" formatedNumber />
          <Row dataItem={cityObj?.['110900']} formatedNumber />
          <Row dataItem={cityObj?.['10200']} suffix=" m.n.m." formatedNumber />
          <Row dataItem={cityObj?.['10100']} prefix="v roce " formatedNumber />
        </View>
        <View>
          <Text style={monitPdfCss.tableHeader}>
            {!!props.risyData?.education?.length && 'Vzdělání'}
          </Text>

          <Row dataItem={cityObj?.['40300']} />
          <Row dataItem={cityObj?.['40400']} />
          <Row dataItem={cityObj?.['40500']} />
          <Row dataItem={cityObj?.['40540']} />
          <Row dataItem={cityObj?.['40600']} />
          <Row dataItem={cityObj?.['40731']} />
          <Row dataItem={cityObj?.['40741']} />
          <Row dataItem={cityObj?.['40751']} />
          <Row dataItem={cityObj?.['40761']} />
          <Row dataItem={cityObj?.['40771']} />
          <Row dataItem={cityObj?.['40800']} />
          <Row dataItem={cityObj?.['40830']} />
          <Row dataItem={cityObj?.['40900']} />
          <Row dataItem={cityObj?.['41000']} />
          <Row dataItem={cityObj?.['41200']} />
          <Row dataItem={cityObj?.['41300']} />
          <Row dataItem={cityObj?.['41400']} />
          <Row dataItem={cityObj?.['41430']} />
        </View>

        <View>
          <Text style={monitPdfCss.tableHeader}>
            {!!props.risyData?.civicAmenities?.length &&
              'Technická vybavenost obce'}
          </Text>

          <Row boolean dataItem={cityObj?.['600101']} />
          <Row boolean dataItem={cityObj?.['600201']} />
          <Row boolean dataItem={cityObj?.['600301']} />
          <Row boolean dataItem={cityObj?.['600401']} />
          <Row formatedNumber suffix=" km" dataItem={cityObj?.['600501']} />
          <Row boolean dataItem={cityObj?.['600601']} />
          <Row boolean dataItem={cityObj?.['600701']} />
          <Row boolean dataItem={cityObj?.['600801']} />
          <Row boolean dataItem={cityObj?.['600802']} />
          <Row boolean dataItem={cityObj?.['600803']} />
          <Row boolean dataItem={cityObj?.['600804']} />
          <Row boolean dataItem={cityObj?.['600805']} />
          <Row boolean dataItem={cityObj?.['600806']} />
          <Row boolean dataItem={cityObj?.['600807']} />
          <Row boolean dataItem={cityObj?.['600808']} />
          <Row boolean dataItem={cityObj?.['600809']} />
          <Row boolean dataItem={cityObj?.['600810']} />
          <Row boolean dataItem={cityObj?.['600811']} />
        </View>

        <View>
          <Text style={monitPdfCss.tableHeader}>
            {!!props.risyData?.sms?.length &&
              'Údaje dle sdružení místních samospráv ČR'}
          </Text>

          <Row smsItem={sms?.['Bankomat']} />
          <Row smsItem={sms?.['Brownfield pouze mapování']} />
          <Row smsItem={sms?.['ČOV']} />
          <Row smsItem={sms?.['Dům pro seniory']} />
          <Row smsItem={sms?.['Dům s pečovatelskou službou']} />
          <Row smsItem={sms?.['Hasičská zbrojnice']} />
          <Row smsItem={sms?.['Herna']} />
          <Row smsItem={sms?.['Hospic']} />
          <Row smsItem={sms?.['Hřbitov']} />
          <Row smsItem={sms?.['Infocentrum']} />
          <Row smsItem={sms?.['Kulturní objekt']} />
          <Row smsItem={sms?.['LDN']} />
          <Row smsItem={sms?.['Lekárna']} />
          {/* <Row styles={styles} smsItem={sms?.['most']} /> */}
          <Row smsItem={sms?.['Obecní úřad']} />
          <Row smsItem={sms?.['Obchod s potravinami']} />
          <Row smsItem={sms?.['Ordinace lékaře']} />
          <Row smsItem={sms?.['Parkoviště']} />
          <Row smsItem={sms?.['Policejní stanice']} />
          <Row smsItem={sms?.['Pošta']} />
          <Row smsItem={sms?.['Restaurace']} />
          <Row smsItem={sms?.['Rybníky, vodní nádrže (body + polygóny)']} />
          <Row smsItem={sms?.['Sběr recyklovatelného odpadu']} />
          <Row smsItem={sms?.['Sběrný dvůr']} />
          <Row smsItem={sms?.['Skladový areál']} />
          <Row smsItem={sms?.['Sportoviště']} />
          <Row smsItem={sms?.['Škola']} />
          <Row smsItem={sms?.['Trafostanice']} />
          <Row smsItem={sms?.['Vodojem']} />
        </View>

        <View>
          <Text style={monitPdfCss.tableHeader}>
            {!!props.risyData?.socialSector?.length && 'Sociální oblast'}
          </Text>
          <Row dataItem={cityObj?.['150100']} />
          <Row dataItem={cityObj?.['150121']} />
          <Row dataItem={cityObj?.['150133']} />
          <Row dataItem={cityObj?.['150137']} />
          <Row dataItem={cityObj?.['150139']} />
          <Row dataItem={cityObj?.['150141']} />
          <Row dataItem={cityObj?.['150143']} />
          <Row dataItem={cityObj?.['150145']} />
          <Row dataItem={cityObj?.['150151']} />
          <Row dataItem={cityObj?.['150157']} />
          <Row dataItem={cityObj?.['150159']} />
          <Row dataItem={cityObj?.['150161']} />
          <Row dataItem={cityObj?.['150163']} />
          <Row dataItem={cityObj?.['150165']} />
          <Row dataItem={cityObj?.['150167']} />
          <Row dataItem={cityObj?.['150169']} />
          <Row dataItem={cityObj?.['150173']} />
          <Row dataItem={cityObj?.['150175']} />
          <Row dataItem={cityObj?.['150177']} />
          <Row dataItem={cityObj?.['150179']} />
          <Row dataItem={cityObj?.['150181']} />
          <Row dataItem={cityObj?.['150183']} />
          <Row dataItem={cityObj?.['150200']} />
          <Row dataItem={cityObj?.['150300']} />
        </View>
      </Page>
    )
  })
