import React, { FunctionComponent } from 'react'

import { MAPBOX_ACCESS_TOKEN } from '../../../ini.json'
import { CadastreMap } from '../components/CadastreMap/CadastreMap'
import { CadastreMapProvider } from '../components/CadastreMap/CadastreMapProvider'

export const InsolvencyMap: FunctionComponent = () => {
  return (
    <div className="relative flex flex-1 flex-col -m-4">
      <CadastreMapProvider>
        <CadastreMap showInsolvency mapboxAccessToken={MAPBOX_ACCESS_TOKEN} />
      </CadastreMapProvider>
    </div>
  )
}
