import './globals.css'

import { StyleProvider } from '@ant-design/cssinjs'
import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import store from '../redux/store'
import App from './App'

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
  <StrictMode>
    <StyleProvider hashPriority="high">
      <Provider store={store}>
        <App />
      </Provider>
    </StyleProvider>
  </StrictMode>
)
