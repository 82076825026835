import { Alert } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useLabelOverviewQuery } from '../../graphql/generated'
import { LabelDetailBaseInfo } from '../components/LabelDetailBaseInfo/LabelDetailBaseInfo'
import { LabelDetailPageExtra } from '../components/LabelDetailPageExtra/LabelDetailPageExtra'
import { LabelDetailPageTitle } from '../components/LabelDetailPageTitle/LabelDetailPageTitle'
import { LabelOverview } from '../components/LabelOverview/LabelOverview'
import { PageLayout } from '../components/PageLayout/PageLayout'
import { ColorPalleteKey } from '../utils/collorPallete'

export const LabelDetail: FunctionComponent = () => {
  const {
    params: { id: id },
  } = useRouteMatch<{ id: ColorPalleteKey }>()

  const [queryResult, refetch] = useLabelOverviewQuery({
    variables: { labelId: id },
  })

  const label = useMemo(
    () => queryResult.data?.labelById,
    [queryResult.data?.labelById]
  )

  const connections = useMemo(() => {
    const result: { [key: string]: string[] } = {}
    const connections = label?.labelConnectionsByLabelId.nodes
    if (!connections?.length) return result

    for (const connection of connections) {
      for (const [key, value] of Object.entries(connection || {})) {
        if (value) {
          if (!result[key]) {
            result[key] = []
          }
          if (!result[key].includes(value)) {
            result[key].push(value)
          }
        }
      }
    }

    return result
  }, [label?.labelConnectionsByLabelId.nodes])

  if (queryResult.error) {
    return (
      <PageLayout className="p-0 bg-inherit">
        <Alert
          message="Chyba při načítání štítku"
          description="Je nám líto, ale nepodařilo se najít detailní informace k požadovanému štítku."
          type="error"
          showIcon
        />
      </PageLayout>
    )
  }

  if (!label && !queryResult.fetching) {
    return (
      <PageLayout className="p-0 bg-inherit">
        <Alert
          message="Štítek nenalezen"
          description="Požadovaný štítek nebyl nalezen."
          type="warning"
          showIcon
        />
      </PageLayout>
    )
  }

  return (
    <PageLayout
      className="p-0 bg-inherit"
      title={<LabelDetailPageTitle label={label} />}
      extra={<LabelDetailPageExtra refetch={refetch} label={label} />}
    >
      <LabelDetailBaseInfo label={label} />
      <LabelOverview
        connections={connections}
        labelId={id}
        loading={queryResult.fetching}
      />
    </PageLayout>
  )
}

export default LabelDetail
