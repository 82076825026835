import { Icon } from '@iconify/react'
import { Badge } from 'antd'
import React, { FunctionComponent, memo } from 'react'

const offset = [-5, 5] as [number, number]
const count = (
  <div className="transition-colors bg-primary text-white text-xs font-bold flex items-center justify-center pb-[3px] rounded-2xl w-3.5 h-3.5 border border-white group-hover:bg-primaryHover">
    +
  </div>
)

export const AddRecipientModalButtonIcon: FunctionComponent = memo(() => {
  return (
    <Badge size="small" className="group" count={count} offset={offset}>
      <Icon
        icon="uiw:mail-o"
        className="transition-colors w-auto h-5 text-primary group-hover:text-primaryHover"
      />
    </Badge>
  )
})
