import React, { FunctionComponent } from 'react'

import { useLabelTableQuery } from '../../graphql/generated'
import { useAppSelector } from '../../redux/hooks'
import { AddNewLabelButton } from '../components/AddNewLabelButton/AddNewLabelButton'
import { LabelTable, LabelTableItem } from '../components/LabelTable/LabelTable'
import { PageLayout } from '../components/PageLayout/PageLayout'

export const Label: FunctionComponent = () => {
  const tenantId = useAppSelector(state => state.myProfile.tenantId)

  const [queryResult, refetch] = useLabelTableQuery({
    variables: {
      accountId: tenantId,
    },
  })

  return (
    <PageLayout
      extra={<AddNewLabelButton refetch={refetch} />}
      className="p-0 bg-inherit"
      title="Štítky"
    >
      <LabelTable
        data={queryResult.data?.allLabels?.nodes as LabelTableItem[]}
        refetch={refetch}
      />
    </PageLayout>
  )
}

export default Label
