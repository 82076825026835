import { Button, Space } from 'antd'
import React, { FunctionComponent, memo, useCallback } from 'react'

import { AUCTION_TYPES } from '../../../common/auctionScrapeTypes'
import { setAuctionDrawer } from '../../../redux/drawerSlice'
import { useAppDispatch } from '../../../redux/hooks'

export interface AuctionMonitoringTilesItemFooterProps {
  id: string
  auctionType: AUCTION_TYPES
  auctionUrl: string
}

export const AuctionMonitoringTilesItemFooter: FunctionComponent<AuctionMonitoringTilesItemFooterProps> =
  memo(props => {
    const dispatch = useAppDispatch()
    const setDrawer = useCallback(
      () =>
        dispatch(
          setAuctionDrawer({ id: props.id, auctionType: props.auctionType })
        ),
      [dispatch, props.id, props.auctionType]
    )

    return (
      <div className="h-14 flex flex-col">
        <div className="transition-all h-4 group-hover:h-0" />
        <Space.Compact block className="border-t h-full">
          <Button
            className="w-full !h-full !border-0 !shadow-none"
            onClick={setDrawer}
            title="Otevřít detail nemovitosti v novém okně"
          >
            Zobrazit náhled
          </Button>
          <div className="border w-0.5 h-1/2 self-center" />
          <a
            className="w-full"
            href={props.auctionUrl}
            target="_blank"
            rel="noreferrer"
            title="Zobrazit detail dražby v novém okně"
          >
            <Button className="w-full !h-full !border-0 !shadow-none">
              Přejít do dražby
            </Button>
          </a>
        </Space.Compact>
      </div>
    )
  })
