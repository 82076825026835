import React, { FunctionComponent, memo } from 'react'

import { Jednotka } from '../../../common/drmTypes'
import { TransformedPropertyLabelData } from '../../../common/propertyDetailTypes'
import { PropertyDetailBuildingUnitsCell } from '../PropertyDetailBuildingUnitsCell/PropertyDetailBuildingUnitsCell'

interface PropertyDetailBuildingUnitsColumnProps {
  units: Jednotka[]
  propertyLabels: TransformedPropertyLabelData | null
}

export const PropertyDetailBuildingUnitsColumn: FunctionComponent<PropertyDetailBuildingUnitsColumnProps> =
  memo(({ units, propertyLabels }) => (
    <div className="flex flex-wrap">
      {units.map(({ id, cisloJednotky }) => (
        <PropertyDetailBuildingUnitsCell
          key={id}
          id={id}
          unitNumber={cisloJednotky || 'X'}
          labelConnection={
            propertyLabels?.[id]?.labelConnectionsByPropertyLabelId?.nodes
          }
        />
      ))}
    </div>
  ))
