import { format } from 'date-fns'

export const czDateFormatYear = 'yyyy'
export const czDateFormatMonth = 'MM yyyy'
export const czDateFormatDay = 'dd.MM.yyyy'
export const czDateFormatMinute = 'dd.MM.yyyy HH:mm'
export const czDateFormatSecond = 'dd.MM.yyyy HH:mm:ss'

export const year = (date?: string | Date | null) => {
  if (!date) return
  if (date instanceof Date) return format(date, czDateFormatYear)
  return format(new Date(date), czDateFormatYear)
}
export const month = (date?: string | Date | null) => {
  if (!date) return
  if (date instanceof Date) return format(date, czDateFormatMonth)
  return format(new Date(date), czDateFormatMonth)
}
export const day = (date?: string | Date | null) => {
  if (!date) return
  if (date instanceof Date) return format(date, czDateFormatDay)
  return format(new Date(date), czDateFormatDay)
}
export const minute = (date?: string | Date | null) => {
  if (!date) return
  if (date instanceof Date) return format(date, czDateFormatMinute)
  return format(new Date(date), czDateFormatMinute)
}
export const second = (date?: string | Date | null) => {
  if (!date) return
  if (date instanceof Date) return format(date, czDateFormatSecond)
  return format(new Date(date), czDateFormatSecond)
}

export const formatDateInText = (
  text?: string | null,
  defaultText?: string
) => {
  if (!text) return defaultText || undefined
  const regex = /(\d{4})-(\d{1,2})-(\d{1,2})/g
  const dates = text.match(regex)
  if (dates === null) return text

  for (let i = 0; i < dates.length; i++) {
    const dateParts = dates[i].split('-')
    const day = dateParts[2].padStart(2, '0')
    const month = dateParts[1].padStart(2, '0')
    const year = dateParts[0]
    const formattedDate = `${day}.${month}.${year}`
    text = text.replace(dates[i], formattedDate)
  }
  return text
}

export const czDateFormatDatePickerDay = 'DD.MM.YYYY'
export const czDateFormatDatePickerMinute = 'DD.MM.YYYY HH:mm'
export const czDateFormatDatePickerSecond = 'DD.MM.YYYY HH:mm:ss'
