import { Alert } from 'antd'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { BookmarkDetailItem } from '../../common/bookmarkTypes'
import { safeJsonParsing } from '../../common/safeJsonParsing'
import { usePostServiceTableQuery } from '../../graphql/generated'
import { CreateRecipientListButton } from '../components/CreateRecipientListButton/CreateRecipientListButton'
import { PageLayout } from '../components/PageLayout/PageLayout'
import { PageSkeleton } from '../components/PageSkeleton/PageSkeleton'
import { PostServiceTable } from '../components/PostServiceTable/PostServiceTable'

const alertStyle = { marginBottom: 16 }

export const PostService: FunctionComponent = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>()

  const [recipientList, setRecipientList] = useState<BookmarkDetailItem[]>([])
  const [queryResult] = usePostServiceTableQuery({
    variables: { id },
  })
  const data = queryResult.data?.recipientListById

  useEffect(() => {
    const recipients = async () => {
      if (data?.temporaryRecipientsJson) {
        const parsed = safeJsonParsing<BookmarkDetailItem[]>(
          data.temporaryRecipientsJson,
          []
        )
        setRecipientList(parsed)
      }
    }
    recipients()
  }, [data?.temporaryRecipientsJson])

  if (queryResult.fetching) return <PageSkeleton />

  return (
    <PageLayout
      title="Hromadné rozesílání pošty"
      isNew
      extra={
        <CreateRecipientListButton
          id={id}
          recipientList={recipientList}
          name={data?.nazev || ''}
        />
      }
    >
      {!recipientList?.length && !!data?.createdAt && (
        <Alert
          style={alertStyle}
          message="Vybraný seznam neobsahuje žádné adresáty, není možné jej použít pro hromadné rozesílání pošty."
          type="warning"
        />
      )}
      <PostServiceTable
        data={recipientList}
        setRecipientList={setRecipientList}
      />
    </PageLayout>
  )
}
export default PostService
