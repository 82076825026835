import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { PropertyDetailSubjectsTableItem as SubjectItem } from '../../../common/propertyDetailTypes'
import { AngazovanaOsoba, Zivnost } from '../../../common/rzpTypes'
import {
  FyzickaOsoba,
  Osoba,
  PravnickaOsoba,
  Spolecnici,
  StatutarniOrgany,
} from '../../../common/vrTypes'
import { useAppSelector } from '../../../redux/hooks'
import { getUniquePersonKey } from '../../utils/getUniquePersonKey'
import { PropertyDetailSubjectsTableKey as TableId } from '../../utils/propertyDetailSubjectsHelper'
import { PageLayoutTabContainer } from '../PageLayoutTabContainer/PageLayoutTabContainer'
import { PropertyDetailSubjectsTable } from '../PropertyDetailSubjectsTable/PropertyDetailSubjectsTable'

/* TODO: This component is a total mess, probably the worst of all, sorry about that. But it works. */

export interface PropertyDetailSubjectsProps {
  addressId: number
}
export const PropertyDetailSubjects: FunctionComponent<PropertyDetailSubjectsProps> =
  props => {
    const {
      ares,
      rzpSubjects,
      lv: lvDetail,
      drmSubjects,
    } = useAppSelector(state => state.property)

    const getNameKey = useCallback(
      (
        person: {
          prijmeni?: string
          jmeno?: string
          titulPredJmenem?: string
          titulZaJmenem?: string
          nazev?: string
          obchodniJmeno?: string
        },
        addressId: string | undefined,
        address?: string
      ) =>
        getUniquePersonKey(
          [
            person.titulPredJmenem,
            person.jmeno,
            person.prijmeni,
            person.titulZaJmenem,
          ]
            .filter(Boolean)
            .join(' ')
            .trim() ||
            person.nazev ||
            person.obchodniJmeno ||
            '',
          addressId || props.addressId.toString(),
          address
        ),
      [props.addressId]
    )

    const owners = useMemo(
      () =>
        lvDetail.data?.pravniVztahy.flatMap(pravniVztah =>
          pravniVztah.vlastnickaPrava.map(right => ({
            active: right.platiDo === null,
            rightName: pravniVztah.nazev,
            subjectId: right.subjekt.id,
            uniquePersonKey: getUniquePersonKey(
              right.subjekt.jmeno,
              right.subjekt.adresniMistoKod,
              right.subjekt.adresa
            ),
            sjmPartnerId: right.sjmPartner?.id,
            sjmUniquePersonKey: right.sjmPartner
              ? getUniquePersonKey(
                  right.sjmPartner.jmeno,
                  right.sjmPartner.adresniMistoKod,
                  right.sjmPartner.adresa
                )
              : undefined,
          }))
        ),
      [lvDetail.data?.pravniVztahy]
    )

    const matchWithOwners = useCallback(
      (uniquePersonKey: string) => {
        const matchOwner =
          owners?.some(
            owner =>
              owner.active &&
              (uniquePersonKey === owner.uniquePersonKey ||
                uniquePersonKey === owner.sjmUniquePersonKey)
          ) && 'owner'

        const matchPreviousOwner =
          owners?.some(
            owner =>
              !owner.active &&
              (uniquePersonKey === owner.uniquePersonKey ||
                uniquePersonKey === owner.sjmUniquePersonKey)
          ) && 'previousOwner'

        return matchOwner || matchPreviousOwner || 'no-match'
      },
      [owners]
    )

    const drmResidents = useMemo(
      () =>
        drmSubjects.data?.map(subject => {
          const nameKey = getUniquePersonKey(
            subject.nazev || '',
            subject.adresniMistoKod,
            subject.adresa
          )

          return {
            id: subject.id,
            aktualni: !subject.historicky,
            typ: 'F',
            adresa: subject.adresa,
            vzdalenost: 0,
            key: uuidv4(),
            uniquePersonKey: nameKey,
            match: matchWithOwners(nameKey),
            nazev: subject.nazev,
          }
        }) || [],
      [drmSubjects.data, matchWithOwners]
    )

    const handleSubPersonsAndResidence = useCallback(
      (ico: string) => {
        const uniquePersons: {
          [key: string]: SubjectItem
        } = {}
        const residencePersons: SubjectItem[] = []
        const source = [
          ...(ares.data?.vr?.[ico]?.spolecnici || []),
          ...(ares.data?.vr?.[ico]?.statutarniOrgany || []),
          ...(ares.data?.vr?.[ico]?.ostatniOrgany || []),
          ...(ares.data?.rzp?.[ico]?.zivnosti || []),
        ] as Org[]

        type Org = Partial<Spolecnici> &
          Partial<StatutarniOrgany> &
          Partial<Zivnost>
        type Member = Partial<AngazovanaOsoba> & Partial<Osoba>
        type Person = Partial<Member> &
          Partial<FyzickaOsoba> &
          Partial<PravnickaOsoba>

        source.forEach(org => {
          const members = (org.clenoveOrganu ||
            org.odpovedniZastupci ||
            org.spolecnik?.map(spolecnik => spolecnik.osoba) ||
            []) as Member[]
          members.forEach(member => {
            const person = (member.fyzickaOsoba ||
              member.pravnickaOsoba ||
              member) as Person

            if (!person) return

            const uniquePersonKey = getNameKey(
              person,
              person.adresa?.kodAdresnihoMista?.toString(),
              person.adresa?.textovaAdresa
            )

            if (!uniquePersons[uniquePersonKey]) {
              uniquePersons[uniquePersonKey] = {
                datumOd: member.datumZapisu || member.platnostOd,
                datumDo: member.datumVymazu || member.platnostDo,
                aktualni: !member.datumVymazu && !member.platnostDo,
                vzdalenost: 0,
                angazovaneOsoby: [],
                angazma: [],
                key: uuidv4(),
                uniquePersonKey: uniquePersonKey,
                nazev:
                  (person.titulPredJmenem ? person.titulPredJmenem + ' ' : '') +
                    (person.jmeno ? person.jmeno + ' ' : '') +
                    (person.prijmeni ? person.prijmeni + ' ' : '') +
                    (person.titulZaJmenem ? person.titulZaJmenem : '') ||
                  person.obchodniJmeno ||
                  '',
                match: matchWithOwners(uniquePersonKey),
                ico: person?.ico || '',
                datumNarozeni: person?.datumNarozeni,
                nalezenaAdresa:
                  person?.adresa?.kodAdresnihoMista === props.addressId
                    ? {
                        adresaTyp: ['S'],
                        text: person?.adresa?.textovaAdresa || '',
                        platnostDo:
                          member.datumVymazu || member.platnostDo || null,
                        platnostOd: member.datumZapisu || member.platnostOd,
                        vzdalenost: 0,
                      }
                    : null,
                sidlo: person?.adresa?.textovaAdresa || null,
                typ: person.prijmeni ? 'F' : 'P',
              }
              if (person?.adresa?.kodAdresnihoMista === props.addressId) {
                residencePersons.push(uniquePersons[uniquePersonKey])
              }
            }

            uniquePersons[uniquePersonKey]?.angazma?.push({
              datumOd: member.datumZapisu || member.platnostOd,
              datumDo: member.datumVymazu || member.platnostDo || null,
              nazevAngazma:
                member?.clenstvi?.funkce?.nazev ||
                (org?.predmetPodnikani && 'Odpovědný zástupce') ||
                member.nazevAngazma,
              predmetPodnikani: org.predmetPodnikani,
              /* velikostPodilu: member.podil
                ? parseFloat(member.podil.velikostPodilu.replace(';', '.'))
                : 0, */
            })
          })
        })

        return {
          persons: Object.values(uniquePersons),
          residencePersons: residencePersons,
        }
      },
      [
        ares.data?.vr,
        ares.data?.rzp,
        getNameKey,
        matchWithOwners,
        props.addressId,
      ]
    )

    const dataSource = useMemo(() => {
      const withHeadquarters: SubjectItem[] = []
      const withWorkplace: SubjectItem[] = []
      const withResidence = [...drmResidents] as SubjectItem[]

      const rzpWebSubjects =
        rzpSubjects.data?.subjekty.map(subject => {
          const rzpRecord = ares.data?.rzp?.[subject.ico]
          const person = rzpRecord?.osobaPodnikatel
          const kodAdresnihoMista =
            rzpRecord?.adresySubjektu?.[0]?.kodAdresnihoMista || props.addressId

          const uniquePersons = person
            ? getNameKey(
                person,
                kodAdresnihoMista.toString(),
                subject.nalezenaAdresa.text
              )
            : getUniquePersonKey(
                subject.nazev,
                kodAdresnihoMista,
                subject.nalezenaAdresa.text
              )
          const angazovaneOsoby = subject.ico
            ? handleSubPersonsAndResidence(subject.ico)
            : undefined

          if (angazovaneOsoby?.residencePersons.length) {
            angazovaneOsoby?.residencePersons.forEach(person => {
              if (
                !withResidence.some(
                  p => p.uniquePersonKey === person.uniquePersonKey
                )
              ) {
                withResidence.push(person)
              }
            })
          }

          return {
            ...subject,
            kodAdresnihoMista: kodAdresnihoMista,
            datumNarozeni: rzpRecord?.osobaPodnikatel?.datumNarozeni,
            vzdalenost: subject.vzdalenost || 0,
            sidlo: rzpRecord?.adresySubjektu?.[0]?.textovaAdresa,
            aktualni:
              rzpRecord?.adresySubjektu?.[0]?.kodAdresnihoMista ===
                props.addressId || !subject.nalezenaAdresa.platnostDo,
            key: uuidv4(),
            match: matchWithOwners(uniquePersons),
            uniquePersonKey: uniquePersons,
            angazovaneOsoby: angazovaneOsoby?.persons,
          }
        }) || []

      rzpWebSubjects?.forEach(subject => {
        if (
          subject.nalezenaAdresa.adresaTyp.includes('S') ||
          subject.nalezenaAdresa.adresaTyp.includes('M')
        ) {
          withHeadquarters.push(subject)
        } else if (subject.nalezenaAdresa.adresaTyp.includes('P')) {
          withWorkplace.push(subject)
        } else {
          withResidence
        }
      })

      return { withHeadquarters, withWorkplace, withResidence }
    }, [
      ares.data?.rzp,
      drmResidents,
      getNameKey,
      handleSubPersonsAndResidence,
      matchWithOwners,
      props.addressId,
      rzpSubjects.data?.subjekty,
    ])

    return (
      <PageLayoutTabContainer className="space-y-12">
        <PropertyDetailSubjectsTable
          tableKey={TableId.R}
          dataSource={dataSource.withResidence}
          addressId={props.addressId}
        />
        <PropertyDetailSubjectsTable
          tableKey={TableId.HQ}
          dataSource={dataSource.withHeadquarters}
          addressId={props.addressId}
        />
        <PropertyDetailSubjectsTable
          tableKey={TableId.WP}
          dataSource={dataSource.withWorkplace}
          addressId={props.addressId}
        />
      </PageLayoutTabContainer>
    )
  }
