import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Divider, Spin, Tooltip } from 'antd'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { DrmJednotkaResponse, PROPERTY_TYPES } from '../../../common/drmTypes'
import { LvApiResponse } from '../../../common/lvTypes'
import { cuzkLvDownloadPath } from '../../utils/paths'
import { KuWorkplaceTooltip } from '../KuWorkplaceTooltip/KuWorkplaceTooltip'
import { ExtendedAreaRuian } from '../PriceEstimateArea/PriceEstimateArea'
import { ExtendedBuildingRuian } from '../PriceEstimateBuilding/PriceEstimateBuilding'
import { PriceEstimateLayoutAreaLv } from '../PriceEstimateLayoutAreaLv/PriceEstimateLayoutAreaLv'
import { PriceEstimateLayoutBuildingLv } from '../PriceEstimateLayoutBuildingLv/PriceEstimateLayoutBuildingLv'
import { PriceEstimateLayoutLvDataRow as Item } from '../PriceEstimateLayoutLvDataRow/PriceEstimateLayoutLvDataRow'
import { PriceEstimateLayoutLvProperty } from '../PriceEstimateLayoutLvProperty/PriceEstimateLayoutLvProperty'
import { PriceEstimateLayoutOwnershipRestriction } from '../PriceEstimateLayoutOwnershipRestriction/PriceEstimateLayoutOwnershipRestriction'
import { PriceEstimateLayoutUnitLv } from '../PriceEstimateLayoutUnitLv/PriceEstimateLayoutUnitLv'
import { ExtendedUnitRuian } from '../PriceEstimateUnit/PriceEstimateUnit'

export interface PriceEstimateLayoutLvDataProps {
  ruianData?: ExtendedUnitRuian | ExtendedBuildingRuian | ExtendedAreaRuian
  lvData?: LvApiResponse & DrmJednotkaResponse & { error?: boolean }
  propertyId: string
  propertyType?: PROPERTY_TYPES
}

export const PriceEstimateLayoutLvData: FunctionComponent<PriceEstimateLayoutLvDataProps> =
  props => {
    if (!props.lvData)
      return (
        <div className="w-full h-48 flex items-center justify-center">
          <Spin />
        </div>
      )
    return (
      <div className="flex flex-col space-y-2 animate-fadeIn">
        <div className="flex">
          <div className="w-1/3">List vlastnictví:</div>
          <div className="w-2/3 font-semibold">
            <Tooltip
              overlayClassName="!max-w-full"
              title={
                <>
                  <div className="whitespace-nowrap mb-2">
                    <InfoCircleOutlined className="mr-2" />
                    Kliknutím otevřete detail LV v novém okně
                  </div>
                  <Link
                    target="_blank"
                    to={`${cuzkLvDownloadPath(
                      props.lvData?.katastralniUzemi?.kod.toString() || '',
                      props.lvData?.lv.toString() || ''
                    )}`}
                  >
                    <Button type="primary" className="w-full">
                      Stáhnout LV z dálkového přístupu
                    </Button>
                  </Link>
                </>
              }
            >
              <a
                target="_blank"
                title="Otevřít detail LV v novém okně"
                href={`https://data.regesta.cz/Vyhledavani/ListVlastnictvi/${props.lvData?.katastralniUzemi?.kod}/${props.lvData?.lv}`}
                rel="noreferrer"
              >
                {props.lvData?.katastralniUzemi?.nazev} - {props.lvData?.lv}
              </a>
            </Tooltip>
          </div>
        </div>

        <div className="flex">
          <div className="w-1/3">Katastralní pracoviště:</div>
          <div className="w-2/3 font-semibold">
            <KuWorkplaceTooltip
              kod={props.lvData?.katastralniPracoviste?.kod.toString()}
            >
              {props.lvData?.katastralniPracoviste?.nazev}
            </KuWorkplaceTooltip>
          </div>
        </div>

        {props.propertyType === PROPERTY_TYPES.UNIT && (
          <PriceEstimateLayoutUnitLv
            propertyId={props.propertyId}
            lvData={props.lvData}
            ruianData={props.ruianData}
          />
        )}

        {props.propertyType === PROPERTY_TYPES.BUILDING && (
          <PriceEstimateLayoutBuildingLv
            propertyId={props.propertyId}
            ruianData={props.ruianData as ExtendedBuildingRuian}
          />
        )}

        {props.propertyType === PROPERTY_TYPES.AREA && (
          <PriceEstimateLayoutAreaLv
            propertyId={props.propertyId}
            ruianData={props.ruianData as ExtendedAreaRuian}
          />
        )}

        <Item title="Způsob ochrany">{props.lvData?.zpusobOchrany}</Item>

        <PriceEstimateLayoutOwnershipRestriction
          propertyId={props.propertyId}
          lvData={props.lvData}
        />

        <Divider plain className="!mt-6">
          <div className="text-gray-500">Další nemovitosti na LV</div>
        </Divider>
        <PriceEstimateLayoutLvProperty
          lvData={props.lvData}
          propertyId={props.propertyId}
        />
      </div>
    )
  }
