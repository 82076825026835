import { Skeleton } from 'antd'
import React, { FunctionComponent } from 'react'

const skeletonIcon = {
  shape: 'circle' as const,
  active: true,
}

const skeletonTime = {
  active: true,
  title: false,
  paragraph: {
    rows: 1,
    className: 'flex justify-end',
  },
}

const skeletonNote = {
  active: true,
  title: false,
  paragraph: {
    rows: 3,
  },
}

const skeletonFooter = {
  active: true,
  title: false,
  paragraph: {
    rows: 1,
    className: 'w-3/4 opacity-50',
  },
}

export const PropertyDetailNoteTimelineSkeleton: FunctionComponent = () => {
  return (
    <div className="flex">
      <div className="relative text-right pr-8 w-32 h-8 md:w-1/5">
        <Skeleton {...skeletonTime} />
        <div className="absolute -right-8 -top-1 w-16 flex justify-center bg-white pb-4">
          <Skeleton.Avatar {...skeletonIcon} />
        </div>
      </div>
      <div className="pl-8 pb-14 border-gray-300 border-l w-full border-dashed">
        <Skeleton {...skeletonNote} />
        <div className="flex justify-between text-gray-400 border-gray-400 border-dashed border-t mt-2 pt-2">
          <Skeleton {...skeletonFooter} />
        </div>
      </div>
    </div>
  )
}
