import { Select } from 'antd'
import React, { FunctionComponent } from 'react'

export interface CheckFrequencySelectInputProps {
  canPause?: boolean
  canPreserve?: boolean
  initialValue?: number | null
  onChange?: (value: number | null) => void
  disabled?: boolean
}

export const CheckFrequencySelectInput: FunctionComponent<CheckFrequencySelectInputProps> =
  props => {
    const initialValue =
      props.initialValue !== undefined ? props.initialValue : 0
    const { Option } = Select

    return (
      <Select
        className="w-full"
        defaultValue={initialValue}
        onChange={props.onChange}
        disabled={props.disabled}
      >
        {props.canPreserve && <Option value={-1}>Zachovat nastavení</Option>}
        {props.canPause && <Option value={null}>Pozastaveno</Option>}
        <Option value={0}>Jednorázově</Option>
        <Option value={1}>Denně</Option>
        <Option value={7}>Týdně</Option>
        <Option value={30}>Měsíčně</Option>
      </Select>
    )
  }
