import {
  CastObce,
  DruhPozemku,
  KatastralniPracoviste,
  KatastralniUzemi,
  VBudove,
} from './ruianTypes'

export interface Jednotka {
  id: string
  cisloJednotky?: string | null
}

interface omezeniVlastnickehoPrava {
  omezeni: string
  platiOd?: string
}

export enum PROPERTY_TYPES {
  AREA = 'parcela',
  BUILDING = 'budova',
  UNIT = 'jednotka',
}

export interface DrmCommonResponse {
  id: string
  lv: number
  katastralniUzemi: KatastralniUzemi
  katastralniPracoviste: KatastralniPracoviste
  zpusobOchrany: string | null
  omezeniVlastnickehoPrava: omezeniVlastnickehoPrava[] | []
  utcAktualizace: string | null
  aktualizoval: string | null
  jineZapisy: string | null
  plombyRizeni: string[] | []
  zpusobVyuziti?: string | null
}

export interface DrmJednotkaResponse extends DrmCommonResponse {
  vBudove?: VBudove
  cisloJednotky?: string | null
  podilJednotkyNaSpolecnychCastech?: string
}

export interface DrmBudovaResponse extends DrmCommonResponse {
  jednotky?: Jednotka[]
  cislaDomovni?: string | null
  castObce: CastObce
}

export interface DrmParcelaResponse extends DrmCommonResponse {
  parcelniCislo?: string
  druhPozemku?: DruhPozemku
}

export type DrmDetailResponse = (
  | DrmJednotkaResponse
  | DrmBudovaResponse
  | DrmParcelaResponse
) & {
  type?: PROPERTY_TYPES
}

export type CrmDrmResponse = DrmDetailResponse & {
  aktualizoval?: string | null
}
export type CrmDrmDataCheck = boolean | null
