import { Style } from '@react-pdf/types/style'

import { pdfStyles } from '../../common/pdfHelpers'

export const monitPdfCss: { [key: string]: Style } = {
  page: pdfStyles.priceEstimatePage,
  header: {
    fontSize: 8,
    marginBottom: 5,
    textAlign: 'center',
    color: 'grey',
    flexWrap: 'nowrap',
  },
  title: {
    flexDirection: 'column',
    padding: 14,
    fontSize: 12,
    backgroundColor: '#53afb0',
    textAlign: 'center',
    fontStyle: 'bold',
    color: 'white',
    textTransform: 'uppercase',
  },
  decompInfo: {
    marginBottom: 10,
    textAlign: 'justify',
    color: 'grey',
  },
  priceInfo: {
    textAlign: 'justify',
    borderTopWidth: 1,
    borderTopColor: '#dddddd',
    paddingTop: 2,
  },
  metaDataInfo: {
    textAlign: 'center',
    borderTopWidth: 1,
    borderTopColor: '#dddddd',
    paddingTop: 2,
  },
  bottomInfoContainer: {
    fontSize: 8,
    color: 'grey',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  graphTitle: {
    fontSize: 10,
    color: 'grey',
    backgroundColor: '#ffffff',
    fontStyle: 'italic',
    textAlign: 'right',
    padding: 1,
  },
  graphTitleContainer: {
    position: 'absolute',
    bottom: 17,
    right: 0,
  },
  imgSection: {
    flexDirection: 'row',
    marginBottom: 10,
    marginTop: 10,
  },
  priceEstimate: {
    flexDirection: 'column',
    padding: 10,
    paddingHorizontal: 15,
    color: 'black',
    backgroundColor: '#f7f7f7',
    borderColor: '#dddddd',
    borderWidth: 1,
  },
  pageTitle: {
    flexDirection: 'column',
    backgroundColor: '#53afb0',
    padding: 10,
    marginBottom: 10,
    fontSize: 12,
    textAlign: 'center',
    fontStyle: 'bold',
    color: 'white',
    textTransform: 'uppercase',
  },
  twoColumns: {
    flexDirection: 'row',
    fontSize: 10,
    columnGap: 20,
    lineHeight: 1.6,
  },
  decompPriceValue: {
    width: '33%',
    textAlign: 'right',
  },
  priceDivider: {
    width: 1,
    borderLeftWidth: 1,
    borderColor: '#dddddd',
    backgroundColor: '#dddddd',
  },
  priceEstimateColumns: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 12.5,
  },
  priceEstimateColumnsBig: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 13.5,
    fontStyle: 'bold',
  },
  mb10: {
    marginBottom: 10,
  },
  mt10: {
    marginTop: 10,
  },
  mb5: {
    marginBottom: 5,
  },
  w10p: {
    width: '10px',
  },
  w33: {
    width: '33%',
  },
  w40: {
    width: '40%',
  },
  w50: {
    width: '50%',
  },
  w60: {
    width: '60%',
  },
  w25: {
    width: '25%',
    fontSize: 12,
    lineHeight: 1.75,
    color: '#53afb0',
    fontWeight: 'bold',
  },
  w75: {
    width: '75%',
    fontSize: 12,
    lineHeight: 1.75,
    fontWeight: 'bold',
  },
  relative: {
    position: 'relative',
  },
  tableRow: {
    lineHeight: 1.5,
    marginTop: 0.5,
    paddingBottom: 0.1,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#eeeeee',
  },
  tableRowTitle: {
    lineHeight: 1.5,
    marginTop: 0.5,
    paddingBottom: 0.1,
    fontWeight: 600,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#eeeeee',
  },
  tableCellHeader: {
    width: '70%',
  },
  propertyCellHeader: {
    width: '30%',
  },
  tableCellYear: {
    width: '15%',
    color: '#dddddd',
    paddingLeft: 5,
  },
  tableCellValue: {
    width: '15%',
    fontWeight: 'bold',
    textAlign: 'right',
  },
  propertyCellValue: {
    width: '70%',
    fontWeight: 600,
    textAlign: 'left',
  },
  tableHeader: {
    color: '#53afb0',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 12,
    marginTop: 15,
    marginBottom: 5,
  },
} as const
