import { Button } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { isHugeSubjectLimit } from '../../utils/layoutConst'
import { CuzkEpoDownloadButton } from '../CuzkEpoDownloadButton/CuzkEpoDownloadButton'
import { formatCurrencyNumber } from '../Money/Money'

export interface CuzkEpoDetailStopFetchingProps {
  setStopFetching: (stopFetching: boolean) => void
  epoId: string
  lvCount?: number | null
}

export const CuzkEpoDetailStopFetching: FunctionComponent<CuzkEpoDetailStopFetchingProps> =
  props => {
    const continueFetching = useCallback(() => {
      props.setStopFetching(false)
    }, [props])

    const description = useMemo(
      () => (
        <>
          V evidenci práv se pro tento subjekt vyskytuje{' '}
          {formatCurrencyNumber(props?.lvCount || 0, 0)} záznamů, což znamená,
          že se bude muset stáhnout a načíst soubor o velikosti ~
          {formatCurrencyNumber(
            (props?.lvCount || isHugeSubjectLimit) * 0.000283, // approx. size of 1 record in MB
            0
          )}{' '}
          MB. To by mohlo mít za následek zpomalení prohlížeče. Pokud si přesto
          přejete data zobrazit, klikněte na tlačítko níže, nebo si stáhněte
          data ve formátu .xlsx a poté si soubor otevřte v MS Excel.
        </>
      ),
      [props?.lvCount]
    )

    return (
      <div className="my-8 mx-auto flex flex-col max-w-lg justify-center align-middle bg-white">
        <div className="mb-2 mt-2 text-center text-base font-bold">
          Velké množství dat
        </div>
        <div className="mb-2 mt-2 text-center text-gray-400">{description}</div>
        <div className="mb-2 mt-2 text-center text-base font-bold flex justify-center space-x-2">
          <Button type="primary" onClick={continueFetching}>
            Načíst data
          </Button>
          <CuzkEpoDownloadButton type="xlsx" id={props.epoId} />
        </div>
      </div>
    )
  }
