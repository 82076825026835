import React, { FunctionComponent } from 'react'

import {
  deliveryMethodList,
  SubjectsInPostServiceLetter,
} from '../../../common/postServiceTypes'
import { Day } from '../DateFormat/DateFormat'
import { PostServiceSentLetterPreviewButton } from '../PostServiceSentLetterPreviewButton/PostServiceSentLetterPreviewButton'
import { PostServiceSentTableTrackingNumber } from '../PostServiceSentTableTrackingNumber/PostServiceSentTableTrackingNumber'
import { PriceEstimateLayoutLvDataRow as Item } from '../PriceEstimateLayoutLvDataRow/PriceEstimateLayoutLvDataRow'

export interface SubjectTableCellRecipientLetterTableExpandedRowProps {
  record: SubjectsInPostServiceLetter
  closeModal: () => void
}

export const SubjectTableCellRecipientLetterTableExpandedRow: FunctionComponent<SubjectTableCellRecipientLetterTableExpandedRowProps> =
  props => {
    return (
      <div className="flex flex-col space-y-2 animate-fadeIn">
        <Item title="Popis">{props.record.description}</Item>
        <Item title="Obsah dopisu">
          <PostServiceSentLetterPreviewButton
            letterId={props.record.id}
            sentRecipientId={props.record.sentRecipientId}
            onLoaded={props.closeModal}
          />
        </Item>
        {props.record.sentAt ? (
          <>
            <Item title="Odesláno">
              <Day>{props.record.sentAt}</Day>
            </Item>

            <Item title="Typ zásilky">
              {props.record.postserviceType &&
                deliveryMethodList[props.record.postserviceType].name}
            </Item>

            <Item title="Podací číslo">
              <PostServiceSentTableTrackingNumber
                postserviceId={props.record.postserviceId}
                trackingNumber={props.record.trackingNumber}
                sentAt={props.record.sentAt}
              />
            </Item>
          </>
        ) : (
          <Item title="Status">Připraveno k odeslání</Item>
        )}
      </div>
    )
  }
