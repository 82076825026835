import { Button, Tooltip } from 'antd'
import React, { FunctionComponent } from 'react'

import { PostServiceAddRecipientModalButton } from '../PostServiceAddRecipientModalButton/PostServiceAddRecipientModalButton'
import { PostServiceImportRecipientModalButton } from '../PostServiceImportRecipientModalButton/PostServiceImportRecipientModalButton'

export interface PostServiceRecipientListTableSendDisabledProps {
  listName: string
  listId: string
  reexecuteQuery: () => void
}

const menuButtonProps = {
  size: 'middle' as const,
  type: 'primary' as const,
  ghost: true,
  className: 'w-full',
}

export const PostServiceRecipientListTableSendDisabled: FunctionComponent<PostServiceRecipientListTableSendDisabledProps> =
  props => {
    return (
      <Tooltip
        title={
          <div className="space-y-2">
            <div>
              Poštu nelze rozeslat, nejdříve musíte do seznamu přidat adresáty.
            </div>
            <PostServiceAddRecipientModalButton
              buttonProps={menuButtonProps}
              buttonText="Přidat adresáta"
              listName={props.listName}
              listId={props.listId}
              reexecuteQuery={props.reexecuteQuery}
            />
            <PostServiceImportRecipientModalButton
              buttonProps={menuButtonProps}
              buttonText="Importovat adresáty"
              listName={props.listName}
              listId={props.listId}
              reexecuteQuery={props.reexecuteQuery}
            />
          </div>
        }
      >
        <span>
          {/* span is needed because antd disabled Button bug */}
          <Button type="link" size="small" className="!pr-1" disabled>
            Obeslat adresáty
          </Button>
        </span>
      </Tooltip>
    )
  }
