import { Button, Form, Input, message } from 'antd'
import dayjs from 'dayjs'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { BASE_URL } from '../../../../ini.json'
import { DrmJednotkaResponse } from '../../../common/drmTypes'
import { LvApiResponse } from '../../../common/lvTypes'
import { MonitEstimateResponse } from '../../../common/monitTypes'
import { RisyData } from '../../../common/risyTypes'
import { useAppSelector } from '../../../redux/hooks'
import {
  formItemLayout,
  submitButtonLayoutProps,
  validateTrigger,
} from '../../utils/layoutConst'
import { pricelist } from '../../utils/pricelist'
import { useAssignLabels } from '../../utils/useAssignLabels'
import { LabelSelectInput } from '../LabelSelectInput/LabelSelectInput'
import { PriceEstimateAreaFragment } from '../PriceEstimateAreaFragment/PriceEstimateAreaFragment'
import { PriceEstimateBaseFragment } from '../PriceEstimateBaseFragment/PriceEstimateBaseFragment'
import {
  BUILDING_AREA_TYPE,
  PriceEstimateBuildingFragment,
} from '../PriceEstimateBuildingFragment/PriceEstimateBuildingFragment'
import { PriceEstimateUnitFragment } from '../PriceEstimateUnitFragment/PriceEstimateUnitFragment'
import { PricePopconfirm } from '../PricePopconfirm/PricePopconfirm'

export interface PriceEstimateFormValues {
  datum: dayjs.Dayjs
  typ_nemovitosti: number
  kod_obce: number
  lat: number
  lon: number
  polygon?: string
  typ_pozemku?: number
  typ_objektu?: number
  dispozice_mistnosti?: number
  druh_konstrukce?: number
  druh_vlastnictvi?: number
  pl_balkonu?: number
  pl_uzitna?: number
  pl_celkova?: number
  pl_pozemku?: number
  nadzemni_podlazi?: number
  pocet_bytu?: number
  poloha_domu?: number
  enb_trida?: number
  podlazi_umisteni?: number
  prislusenstvi_fl?: number[]
  reduced_flags?: number[]
  object_flags_gully?: number
  object_flags_heating?: number
  object_flags?: number[]
  reduced_stav?: number
  umisteni_nemovitosti?: number
  zarizeno?: number
  note?: string
  publicNote?: string
  title?: string
}

export interface PriceEstimateFormProps {
  initialValues: PriceEstimateFormValues
  lvData?: LvApiResponse & DrmJednotkaResponse & { error?: boolean }
  risyData?: RisyData
  propertyId: string
  setEstimateResponse: (estimatePrices: MonitEstimateResponse) => void
}

export const PriceEstimateForm: FunctionComponent<PriceEstimateFormProps> =
  props => {
    const { tenantId, userId } = useAppSelector(state => state.myProfile)
    const { assignLabels } = useAssignLabels()

    const [labelsId, setLabelsId] = useState<string[] | []>([])
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const getBitNumber = useCallback((numbers?: number[]) => {
      if (!numbers || !numbers.length) return 0
      return numbers.reduce((prev, value) => prev | value, 0)
    }, [])

    const onFinish = useCallback(
      async (formValues: PriceEstimateFormValues) => {
        setLoading(true)

        const {
          lat,
          lon,
          note,
          object_flags_gully,
          object_flags_heating,
          ...monitObject
        } = formValues

        const allPrices =
          monitObject?.typ_pozemku === 1 ||
          monitObject?.typ_pozemku === 4 ||
          monitObject?.typ_objektu === 4 ||
          monitObject?.typ_objektu === 5 ||
          monitObject?.typ_objektu === 255
            ? 0x0080
            : 0x0080 | 0x0100

        const monitInput = {
          monit_object: {
            ...monitObject,
            geo_loc: { lat, lon },
            datum: monitObject.datum.format('YYYY-MM-DD'),
            prislusenstvi_fl: getBitNumber(monitObject.prislusenstvi_fl),
            object_flags:
              getBitNumber([
                object_flags_heating || 0,
                object_flags_gully || 0,
              ]) | getBitNumber(monitObject.object_flags),
            reduced_flags: allPrices | getBitNumber(monitObject.reduced_flags),
          },
          options: {
            // req_time_series: true,
            req_price_decomp: true,
            req_time_series_graph: {
              width: 1200,
              height: 600,
              font: { size: 16, char_width: 8 },
            },
          },
          note: note,
          result_quantile: 0,
        }

        const monitResponse = await fetch(
          `${BASE_URL}/api/monit/${tenantId}/${props.propertyId}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              monitInput: monitInput,
              title: props.initialValues.title,
              polygon: props.initialValues.polygon,
              risyData: props.risyData,
              lvData: props.lvData,
              accountUserId: userId,
              publicNote: formValues.publicNote,
            }),
          }
        )

        if (!monitResponse.ok) {
          setLoading(false)
          return message.error('Nepodařilo se vytvořit cenový odhad')
        }

        const estimatePrices = await monitResponse?.json()

        if (estimatePrices) {
          await assignLabels({
            monitId: estimatePrices.id,
            labelsId,
          })

          setLoading(false)
          props.setEstimateResponse(estimatePrices)
          return message.success('Cenový odhad byl vytvořen')
        }

        setLoading(false)
        return message.error('Nepodařilo se vytvořit cenový odhad')
      },
      [assignLabels, getBitNumber, labelsId, props, tenantId, userId]
    )

    const popconfrimProps = useMemo(
      () => ({ okButtonProps: { loading: loading } }),
      [loading]
    )

    const initialValues = useMemo(() => {
      return {
        datum: dayjs(),
        dispozice_mistnosti: 6,
        druh_konstrukce: props.initialValues.druh_konstrukce,
        druh_vlastnictvi: 0,
        kod_obce: props.initialValues.kod_obce,
        lat: props.initialValues.lat,
        lon: props.initialValues.lon,
        pl_balkonu: 0,
        podlazi_umisteni: props.initialValues.podlazi_umisteni,
        reduced_stav: 0,
        typ_nemovitosti: props.initialValues.typ_nemovitosti,
        umisteni_nemovitosti: 0,
        poloha_domu: 0,
        zarizeno: 2,
        enb_trida: 6,
        nadzemni_podlazi: props.initialValues?.nadzemni_podlazi,
        pocet_bytu: props.initialValues?.pocet_bytu,
        typ_objektu: props.initialValues?.typ_objektu,
        object_flags_gully: props.initialValues?.object_flags_gully,
        object_flags_heating: props.initialValues?.object_flags_heating,
        object_flags: props.initialValues?.object_flags,
        typ_pozemku: props.initialValues?.typ_pozemku,
        pl_celkova: props.initialValues?.pl_celkova,
        pl_pozemku: props.initialValues?.pl_pozemku,
        pl_uzitna: props.initialValues?.pl_uzitna,
      }
    }, [props.initialValues])

    return (
      <>
        <Form<PriceEstimateFormValues>
          {...formItemLayout}
          form={form}
          initialValues={initialValues}
          validateTrigger={validateTrigger}
          onFinish={onFinish}
          scrollToFirstError
        >
          <PriceEstimateBaseFragment />
          {initialValues.typ_nemovitosti === 2 && <PriceEstimateUnitFragment />}
          {initialValues.typ_nemovitosti === 1 && (
            <PriceEstimateBuildingFragment
              buildingAreaType={
                initialValues.pl_uzitna
                  ? BUILDING_AREA_TYPE.UZITNA
                  : BUILDING_AREA_TYPE.CELKOVA
              }
            />
          )}
          {initialValues.typ_nemovitosti === 0 && <PriceEstimateAreaFragment />}

          <Form.Item name="publicNote" label="Doplňující text k odhadu">
            <Input.TextArea placeholder="Text bude zobrazen v PDF dokumentu" />
          </Form.Item>

          <Form.Item name="note" label="Soukromá poznámka">
            <Input.TextArea placeholder="Napište libovolnou poznámku" />
          </Form.Item>

          <Form.Item name="label" label="Štítek">
            <LabelSelectInput disabled={loading} onChange={setLabelsId} />
          </Form.Item>

          <Form.Item
            {...submitButtonLayoutProps}
            className="text-right print:hidden"
          >
            <PricePopconfirm
              popconfrimProps={popconfrimProps}
              onConfirm={form.submit}
              itemPrice={pricelist.MONIT}
              message={`Vytvoření cenového odhadu je zpoplatněno ${pricelist.MONIT} Kr.`}
            >
              <Button type="primary" loading={loading}>
                Vypočítat
              </Button>
            </PricePopconfirm>
          </Form.Item>
        </Form>
      </>
    )
  }
