import { Button, Space } from 'antd'
import React, { FunctionComponent, memo, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { setPropertyDrawer } from '../../../redux/drawerSlice'
import { useAppDispatch } from '../../../redux/hooks'
import { propertyDetailPath } from '../../utils/paths'

export interface MonitoringTilesItemFooterProps {
  id: string
  propertyType: PROPERTY_TYPES
}

export const PropertyMonitoringTilesItemFooter: FunctionComponent<MonitoringTilesItemFooterProps> =
  memo(props => {
    const dispatch = useAppDispatch()
    const setDrawer = useCallback(
      () =>
        dispatch(
          setPropertyDrawer({ id: props.id, propertyType: props.propertyType })
        ),
      [dispatch, props.id, props.propertyType]
    )

    return (
      <div className="h-14 flex flex-col">
        <div className="transition-all h-4 group-hover:h-0" />
        <Space.Compact block className="border-t h-full">
          <Button
            className="w-full !h-full !border-0 !shadow-none"
            onClick={setDrawer}
            title="Otevřít detail nemovitosti v novém okně"
          >
            Zobrazit náhled
          </Button>
          <div className="border w-0.5 h-1/2 self-center" />
          <Link
            className="w-full h-full"
            to={propertyDetailPath(props.propertyType, props.id)}
          >
            <Button className="w-full !h-full !border-0 !shadow-none">
              Přejít na detail
            </Button>
          </Link>
        </Space.Compact>
      </div>
    )
  })
