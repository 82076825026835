import { SaveOutlined } from '@ant-design/icons'
import { Button, ButtonProps, Input, message, Popconfirm, Tooltip } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { useCreatePostserviceTemplateMutation } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'

export interface PostServiceSaveTemplateButtonProps {
  data: () => { sample?: string; html: string }
  disabled: boolean
  buttonProps?: ButtonProps
}

const styles = { width: 300 }

export const PostServiceSaveTemplateButton: FunctionComponent<PostServiceSaveTemplateButtonProps> =
  props => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const userID = useAppSelector(state => state.myProfile.userId)
    const [name, setName] = useState<string | undefined>()
    const [disabled, setDisabled] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [, createPostserviceTemplate] = useCreatePostserviceTemplateMutation()

    const createTemplate = useCallback(async () => {
      const data = props.data()

      if (!data.sample) return message.error('Nepodařilo se uložit šablonu')

      const content = data.html
      const description = data.sample.substring(0, 200)

      try {
        await createPostserviceTemplate({
          name: name || 'šablona',
          description: description,
          content: content,
          accountId: tenantId,
          accountUserId: userID,
        })
        message.success('Šablona byla uložena')
      } catch (error) {
        message.error('Nepodařilo se uložit šablonu')
      } finally {
        setConfirmLoading(false)
      }
      return
    }, [createPostserviceTemplate, name, props, tenantId, userID])

    const handleChange = useCallback(
      (event: { target: { value?: string } }) => {
        setName(event.target.value)
      },
      []
    )

    const onOpenChange = useCallback(
      (open: boolean) => {
        const data = props.data()
        const dataLength = data?.sample?.length || 0

        if (open && !dataLength) setDisabled(true)
        else setDisabled(false)
      },
      [props]
    )

    const content = useMemo(
      () => (
        <div className="-ml-4">
          <Input
            onChange={handleChange}
            autoFocus
            placeholder="Název šablony"
            disabled={disabled}
          />

          {disabled ? (
            <div className="text-red-500">Nelze uložit šablonu bez obsahu</div>
          ) : (
            !name && (
              <div className="text-red-500 animate-fadeIn">
                Nelze uložit šablonu bez názvu
              </div>
            )
          )}
        </div>
      ),
      [disabled, handleChange, name]
    )

    const okButtonProps = useMemo(
      () => ({
        loading: confirmLoading,
        disabled: !name,
      }),
      [confirmLoading, name]
    )

    return (
      <Tooltip
        title="Aktualizujte prvně náhled PDF"
        trigger={props.disabled ? 'hover' : ''}
      >
        <Popconfirm
          title="Uložit šablonu dopisu"
          description={content}
          onConfirm={createTemplate}
          onOpenChange={onOpenChange}
          okButtonProps={okButtonProps}
          icon={null}
          overlayInnerStyle={styles}
          okText="Uložit"
          disabled={props.disabled}
        >
          <Button
            disabled={props.disabled}
            type="primary"
            ghost
            {...props.buttonProps}
          >
            Uložit jako šablonu
            <SaveOutlined className="ml-2" />
          </Button>
        </Popconfirm>
      </Tooltip>
    )
  }
