import React, { FunctionComponent, ReactNode } from 'react'

export interface ColumnGridItemProps {
  label: string
  value?: ReactNode | null
  conditional?: boolean
}

export const ColumnGridItem: FunctionComponent<ColumnGridItemProps> = props => {
  if (props.conditional && !props.value) {
    return null
  }

  return (
    <div className="flex">
      <div className="w-1/4 text-right">
        <div className="w-full py-1 pr-2 text-gray-500">{props.label}</div>
      </div>
      <div className="w-3/4 px-1">
        <div className="w-full pl-4 py-1">
          <span className="font-semibold">{props.value}</span>
        </div>
      </div>
    </div>
  )
}
