import { MenuProps } from 'antd'
import React from 'react'

import { MainMenuItemTitle as Label } from '../components/MainMenuItemTitle/MainMenuItemTitle'

export const menuDivider = (text: string) => (
  <div className="pt-1 text-xs text-gray-500 truncate">{text}</div>
)

export type MenuItem = Required<MenuProps>['items'][number]
export const getItem = (
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[]
) => {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem
}

export const getSub = (
  label: string,
  key: string,
  tag?: 'new' | 'dev' | 'archive'
) => {
  return {
    key,
    label: (
      <Label
        sub
        title={label}
        link={key}
        new={tag === 'new'}
        dev={tag === 'dev'}
      />
    ),
  }
}

export const getSubExt = (
  label: string,
  key: string,
  tag?: 'new' | 'dev' | 'archive'
) => {
  return {
    key,
    label: (
      <Label
        sub
        ext
        title={label}
        link={`https://data.regesta.cz${key}`}
        new={tag === 'new'}
        dev={tag === 'dev'}
      />
    ),
  }
}
