import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import React, { FunctionComponent, useCallback } from 'react'

import { setExpandedRow } from '../../../redux/expandedRowSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'

export interface ExpandColumnTitleProps {
  tableId: string
  allKeys: string[]
}

export const ExpandColumnTitle: FunctionComponent<ExpandColumnTitleProps> =
  props => {
    const expandedRow = useAppSelector(
      state => state.expandedRow.tables?.[props.tableId]
    )
    const dispatch = useAppDispatch()
    const setExpandedNone = useCallback(
      () =>
        dispatch(
          setExpandedRow({
            tables: { [props.tableId]: [] },
          })
        ),
      [dispatch, props.tableId]
    )
    const setExpandedAll = useCallback(
      () =>
        dispatch(
          setExpandedRow({
            tables: { [props.tableId]: props.allKeys },
          })
        ),
      [dispatch, props]
    )

    return (
      <div className="w-full text-center">
        {expandedRow?.length ? (
          <MinusOutlined
            className="text-xs text-gray-400 hover:text-primaryHover border border-gray-300 rounded-sm p-px cursor-pointer bg-white"
            onClick={setExpandedNone}
            title="Skrýt vše"
          />
        ) : (
          <PlusOutlined
            className={`text-xs border border-gray-300 rounded-sm p-px ${
              props.allKeys.length
                ? 'text-gray-400 hover:text-primaryHover bg-white cursor-pointer'
                : 'text-gray-300 hover:cursor-not-allowed'
            }`}
            title={props.allKeys.length ? 'Rozbalit vše' : 'Není co rozbalit'}
            onClick={setExpandedAll}
          />
        )}
      </div>
    )
  }
