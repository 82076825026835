import React from 'react'

import {
  CadastreMapInsolvencyFilterFormOutput,
  InsolvencyFilterFormValues,
} from '../../common/cadastreMapInsolvencyTypes'

export const insolvencyHighlightOptions = [
  { value: 1, label: 'Den' },
  { value: 7, label: 'Týden' },
  { value: 30, label: 'Měsíc' },
  { value: 0, label: 'Nezvýrazňovat' },
]

export const insolvencyUnitUsageOptions = [
  { value: '1', label: 'Byt' },
  { value: '2', label: 'Ateliér' },
  { value: '3', label: 'Garáž' },
  { value: '4', label: 'Dílna nebo provozovna' },
  { value: '5', label: 'Jiný nebytový prostor' },
  { value: '6', label: 'Rozestavěná jednotka' },
  { value: '7', label: 'Skupina bytů' },
  { value: '8', label: 'Skupina nebytových prostorů' },
  { value: '9', label: 'Skupina bytů a nebytových prostorů' },
]

export const insolvencyBooleanOptions = [
  { label: 'Ano', value: 'true' },
  { label: 'Ne', value: 'false' },
  { value: '', label: 'Nefiltrovat' },
]

export const insolvencyInsolvencyOptions = [
  {
    label: (
      <span>
        <span className="text-[#3B8F54]">■ </span>Ano
      </span>
    ),
    value: 'true',
  },
  { label: 'Ne', value: 'false' },
  { value: '', label: 'Nefiltrovat' },
]

export const insolvencyForeclosureOptions = [
  {
    label: (
      <span>
        <span className="text-[#E0909C]">■ </span>Ano
      </span>
    ),
    value: 'true',
  },
  { label: 'Ne', value: 'false' },
  { value: '', label: 'Nefiltrovat' },
]

export const getInsolvencyFilterOutput = (
  values: InsolvencyFilterFormValues
) => {
  const output: Partial<CadastreMapInsolvencyFilterFormOutput> = {}

  // Budovy
  if (values.building.enabled) {
    if (values.building.usage.length === 0) delete output.zpusobyVyuzitiBudov
    else {
      output.zpusobyVyuzitiBudov = values.building.usage.join(',')
    }
  } else {
    output.zpusobyVyuzitiBudov = '[]'
  }

  // Parcely/Pozemek
  if (values.parcel.enabled) {
    if (values.parcel.usage.length === 0) delete output.druhyPozemku
    else output.druhyPozemku = values.parcel.usage.join(',')
  } else output.druhyPozemku = '[]'

  // Jednotky
  if (values.housingUnit.enabled) {
    if (values.housingUnit.usage.length === 0)
      delete output.zpusobyVyuzitiJednotek
    else output.zpusobyVyuzitiJednotek = values.housingUnit.usage.join(',')
  } else output.zpusobyVyuzitiJednotek = '[]'

  // Vlastnictví
  if (values.ownership.from) {
    output.vlastnikuOd = values.ownership.from
  }
  if (values.ownership.to) {
    output.vlastnikuDo = values.ownership.to
  }

  // Zástavy
  if (values.voluntaryLien) {
    output.zastavySmluvni = values.voluntaryLien
  }
  if (values.involuntaryLien) {
    output.zastavyNedobrovolne = values.involuntaryLien
  }

  // Zvýraznění
  if (values.highlight) {
    output.highlight = values.highlight
  }

  return output
}

export const insolvencyFormHelp = {
  exekuce: (
    <div className="mb-2">
      <div>&quot;Ano&quot; = nemovitost musí být zatížená exekucí.</div>
      <div>&quot;Ne&quot; = nemovitost nesmí být zatížená exekucí.</div>
      <div>
        &quot;Nefiltrovat&quot; = Budou zobrazeny nemovitosti bez ohledu na
        exekuci.
      </div>
      <div>
        Exekuce jsou v mapě podbarveny{' '}
        <span className="text-[#E0909C]">■ </span>.
      </div>
    </div>
  ),

  insolvence: (
    <div className="mb-2">
      <div>&quot;Ano&quot; = nemovitost musí být zatížená insolvencí.</div>
      <div>&quot;Ne&quot; = nemovitost nesmí být zatížená insolvencí.</div>
      <div>
        &quot;Nefiltrovat&quot; = Budou zobrazeny nemovitosti bez ohledu na
        insolvenci.
      </div>
      <div>
        Insolvence jsou v mapě podbarveny{' '}
        <span className="text-[#3B8F54]">■ </span>.
      </div>
      <div>Podbarvení exekucí má prioritu.</div>
    </div>
  ),

  voluntaryLien: (
    <div className="mb-2">
      <div>
        &quot;Ano&quot; = nemovitost musí být zatížená dobrovolným zástavním
        právem.
      </div>
      <div>
        &quot;Ne&quot; = nemovitost nesmí být zatížená dobrovolným zástavním
        právem.
      </div>
      <div>
        &quot;Nefiltrovat&quot; = Budou zobrazeny nemovitosti bez ohledu na
        dobrovolné zást. právo.
      </div>
    </div>
  ),

  involuntaryLien: (
    <div className="mb-2">
      <div>
        &quot;Ano&quot; = nemovitost musí být zatížená nedobrovolným zástavním
        právem.
      </div>
      <div>
        &quot;Ne&quot; = nemovitost nesmí být zatížená nedobrovolným zástavním
        právem.
      </div>
      <div>
        &quot;Nefiltrovat&quot; = Budou zobrazeny nemovitosti bez ohledu na
        nedobrovolné zást. právo.
      </div>
    </div>
  ),

  ownership: (
    <div className="mb-2">
      <div>Od = Minimální počet vlastnických podílů</div>
      <div>Do = Maximální počet vlastnických podílů</div>
      <div>
        Lze filtrovat jednotlivě, v kombinaci &quot;Od - Do&quot; či vůbec
      </div>
      <div>Manželé v SJM se považují za jednoho vlastníka</div>
      <div>
        Pokud např. chcete filtrovat nemovitosti právě s 2 majiteli, zadejte od:
        2 do: 2
      </div>
      <div>
        Výše uvedené filtry se kombinují, zobrazí se tedy pouze nemovitosti,
        které odpovídají všem nastaveným paramaterům.
      </div>
    </div>
  ),

  highlight: (
    <div className="mb-2">
      Body v mapě budou zvýrazněny, pokud u nemovitosti proběhla změna ve
      vybraném časovém úseku.
    </div>
  ),

  building: (
    <div className="mb-2">Vyberte, zda chcete v mapě zobrazit budovy.</div>
  ),

  buildingUse: (
    <div className="mb-2">
      <div>
        Můžete upřesnit požadované budovy dle evidovaného způsobu využití v kat.
        nemovitostí.
      </div>
      <div>
        Pokud vyberete více možností, budou zobrazeny budovy odpovídající
        alespoň jedné z nich.
      </div>
    </div>
  ),

  parcel: (
    <div className="mb-2">Vyberte, zda chcete v mapě zobrazit pozemky.</div>
  ),

  parcelUse: (
    <div className="mb-2">
      <div>
        Můžete upřesnit požadované parcely dle evidovaného způsobu využití v
        kat. nemovitostí.
      </div>
      <div>
        Pokud vyberete více možností, budou zobrazeny parcely odpovídající
        alespoň jedné z nich.
      </div>
    </div>
  ),

  parcelSize: (
    <div className="mb-2">
      <div>Od = Minimální výměra pozemku</div>
      <div>Do = Maximální výměra pozemku</div>
      <div>
        Lze filtrovat jednotlivě, v kombinaci &quot;Od - Do&quot; či vůbec
      </div>
    </div>
  ),

  housingUnit: (
    <div className="mb-2">Vyberte, zda chcete v mapě zobrazit jednotky.</div>
  ),

  unitUse: (
    <div className="mb-2">
      <div>
        Můžete upřesnit požadované jednotky dle evidovaného způsobu využití v
        kat. nemovitostí.
      </div>
      <div>
        Pokud vyberete více možností, budou zobrazeny jednotky odpovídající
        alespoň jedné z nich.
      </div>
    </div>
  ),
}
