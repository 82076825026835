import { CalculatorOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Alert, Divider, Segmented } from 'antd'
import axios from 'axios'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { BASE_URL } from '../../../../ini.json'
import { DrmJednotkaResponse, PROPERTY_TYPES } from '../../../common/drmTypes'
import { LvApiResponse } from '../../../common/lvTypes'
import { MonitEstimateResponse } from '../../../common/monitTypes'
import { removeDiacritics } from '../../../common/pdfHelpers'
import { RisyData } from '../../../common/risyTypes'
import { usePriceEstimateLayoutQuery } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { useConst } from '../../utils/useConst'
import { useMapImage } from '../../utils/useMapImage'
import { EstimatePricesBox } from '../EstimatePricesBox/EstimatePricesBox'
import { Fade } from '../Fade/Fade'
import { PriceEstimateForm } from '../PriceEstimateForm/PriceEstimateForm'
import { PriceEstimateGraph } from '../PriceEstimateGraph/PriceEstimateGraph'
import { PriceEstimateHistory } from '../PriceEstimateHistory/PriceEstimateHistory'
import { PriceEstimateLayoutLvData } from '../PriceEstimateLayoutLvData/PriceEstimateLayoutLvData'
import { PriceEstimateLayoutStateAlert } from '../PriceEstimateLayoutStateAlert/PriceEstimateLayoutStateAlert'
import { PriceEstimateMapBox } from '../PriceEstimateMapBox/PriceEstimateMapBox'
import { PriceEstimatePdfButton } from '../PriceEstimatePdfButton/PriceEstimatePdfButton'
import { ExtendedUnitRuian } from '../PriceEstimateUnit/PriceEstimateUnit'

enum TAB_TYPES {
  INFO = 'info',
  MAP = 'map',
}

export enum FETCH_ERROR_TYPES {
  RUIAN = 'ruian',
  LV = 'lv',
  DRM = 'drm',
}

export interface PriceEstimateLayoutProps {
  ruianData?: ExtendedUnitRuian & { ruian?: { obec?: { nazev?: string } } }
  lvData?: LvApiResponse & DrmJednotkaResponse
  propertyId: string
  propertyType?: PROPERTY_TYPES
  fetchError?: FETCH_ERROR_TYPES
}

export const PriceEstimateLayout: FunctionComponent<PriceEstimateLayoutProps> =
  props => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)

    const [historyEstimateResponse] = usePriceEstimateLayoutQuery({
      variables: { accountId: tenantId, propertyId: props.propertyId },
    })
    const historyData = !!historyEstimateResponse.data?.allMonits?.nodes?.length

    const [risy, setRisy] = useState<RisyData>()
    const [estimateResponse, setEstimateResponse] =
      useState<MonitEstimateResponse | null>(null)
    const [tab, setTab] = useState<TAB_TYPES.INFO | TAB_TYPES.MAP>(
      TAB_TYPES.INFO
    )

    useEffect(() => {
      if (historyData) {
        setTab(TAB_TYPES.MAP)
      }
    }, [historyData])

    const imgSrc = useMapImage(
      props.ruianData?.lat,
      props.ruianData?.lon,
      props.ruianData?.typ_nemovitosti,
      props.ruianData?.polygon
    )

    useEffect(() => {
      const fetchData = async () => {
        const risyCode = `${props.ruianData?.kod_obce}${
          props.ruianData?.ruian?.obec?.nazev
            ? '-' +
              removeDiacritics(props.ruianData?.ruian?.obec?.nazev, true, true)
            : ''
        }`
        try {
          const response = await axios.post(`${BASE_URL}/api/risy/area`, {
            risyCode: encodeURIComponent(risyCode),
          })
          if (response.status === 200) {
            setRisy(response.data)
          }
          return
        } catch (error) {
          console.error('API call failed: ', error)
        }
      }

      if (props.ruianData?.kod_obce && !risy) fetchData()
    }, [props.ruianData, risy])

    const handleOnChangeTab = useCallback(
      (value: string) => setTab(value as TAB_TYPES.INFO | TAB_TYPES.MAP),
      []
    )

    const segmentedOptions = useConst([
      {
        label: 'Informace z LV',
        value: TAB_TYPES.INFO,
      },
      {
        label: 'Mapa / Foto',
        value: TAB_TYPES.MAP,
      },
    ])

    return (
      <>
        {/* TODO: Delete this alert after testing */}
        <Alert
          style={{ backgroundColor: '#fff2c838' }}
          message={
            <span>
              <InfoCircleOutlined className="mr-2" />
              <span className="font-semibold">09.07.2024: </span>
              <span className="text-gray-500">
                Právě probíhá testování nových funkcionalit u cenových odhadů.
                Pokud by se vyskytly potíže, neváhejte kontaktovat technickou
                podporu. Děkujeme za pochopení.
              </span>
            </span>
          }
          type="warning"
        />
        <div className="grid md:grid-cols-2 gap-8 print:gap-2">
          <div className="order-2 md:order-1 col-span-2 md:col-span-1 print:col-span-2 print:order-2">
            <Divider plain>
              <div className="text-gray-500">Doplňující údaje</div>
            </Divider>

            <PriceEstimateLayoutStateAlert
              loading={!props.ruianData}
              fetchError={props.fetchError}
            >
              {props.ruianData && (
                <PriceEstimateForm
                  initialValues={props.ruianData}
                  propertyId={props.propertyId}
                  lvData={props.lvData}
                  risyData={risy}
                  setEstimateResponse={setEstimateResponse}
                />
              )}
            </PriceEstimateLayoutStateAlert>
          </div>
          <div className="order-1 xl:order-2 space-y-8 print:col-span-2 print:space-y-2 print:order-1">
            <div>
              <Divider plain>
                <Segmented
                  size="small"
                  onChange={
                    handleOnChangeTab as (value: string | number) => void
                  }
                  value={tab}
                  options={segmentedOptions}
                />
              </Divider>
              {tab === TAB_TYPES.INFO ? (
                <PriceEstimateLayoutStateAlert
                  loading={!props.ruianData}
                  fetchError={props.fetchError}
                >
                  <PriceEstimateLayoutLvData
                    propertyId={props.propertyId}
                    propertyType={props.propertyType}
                    lvData={props.lvData}
                    ruianData={props.ruianData}
                  />
                </PriceEstimateLayoutStateAlert>
              ) : (
                <PriceEstimateMapBox imgSrc={imgSrc} />
              )}
            </div>
            <Fade show={!!estimateResponse}>
              <Alert
                className="w-full print:!hidden"
                message={
                  <div className="flex justify-between">
                    <div className="text-lg">
                      <CalculatorOutlined className="mr-2" />
                      <span>Odhadní ceny</span>
                    </div>
                    <div>
                      {estimateResponse?.id && (
                        <PriceEstimatePdfButton
                          imgSrc={imgSrc}
                          id={estimateResponse.id}
                        />
                      )}
                    </div>
                  </div>
                }
                description={
                  <>
                    <EstimatePricesBox estimateResponse={estimateResponse} />
                    <PriceEstimateGraph
                      realPrice={!!estimateResponse?.cena_real}
                      timeSeriesGraph={estimateResponse?.time_series_graph}
                      className="mt-4"
                    />
                  </>
                }
                type="success"
              />
            </Fade>
            <PriceEstimateHistory
              imgSrc={imgSrc}
              propertyId={props.propertyId}
            />
          </div>
        </div>
        {estimateResponse && (
          <div className="hidden print:block">
            <Divider plain>
              <div className="text-gray-500">Odhadní ceny</div>
            </Divider>
            <EstimatePricesBox estimateResponse={estimateResponse} />
          </div>
        )}
      </>
    )
  }
