import { Button, Input, message, Table } from 'antd'
import { ColumnsType, SorterResult, SortOrder } from 'antd/es/table/interface'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { MonitoringInputType } from '../../../common/cuzkMonitorTypes'
import { getRcValidateCode } from '../../utils/getRcValidateCode'
import { monitoringInputLimit } from '../../utils/layoutConst'
import { CeeMonitorAddSubjectModal } from '../CeeMonitorAddSubjectModal/CeeMonitorAddSubjectModal'
import { CuzkMonitorAddSubjectModal } from '../CuzkMonitorAddSubjectModal/CuzkMonitorAddSubjectModal'
import { RcTableItem } from '../CuzkMonitorRcTable/CuzkMonitorRcTable'
import { Fade } from '../Fade/Fade'
import { RcInputError } from '../RcInputError/RcInputError'
import { RcNumberData } from '../RcNumberData/RcNumberData'
import { TabbleCellEditNoteModal } from '../TabbleCellEditNoteModal/TabbleCellEditNoteModal'

export interface RcTableItemConcept extends Omit<RcTableItem, 'createdAt'> {
  key: number
  monitoringCeeId?: string
}

export interface RcInputProps {
  type: MonitoringInputType
  buttonRef: React.MutableRefObject<null>
  inputRef: React.MutableRefObject<null>
  monitoring?: boolean
}

const locale = {
  emptyText: 'Zadejte nebo vložte RČ do textového pole.',
}

export const RcInput: FunctionComponent<RcInputProps> = props => {
  const [inputValue, setInputValue] = useState('')
  const [, setSortedInfo] = useState<SorterResult<RcTableItemConcept>>({})

  const [dataSource, setDataSource] = useState<RcTableItemConcept[]>([])

  const deleteItem = useCallback(
    (key: number) => {
      setDataSource(dataSource.filter(obj => obj.key !== key))
      message.success('Položka byla odebrána.')
    },
    [dataSource, setDataSource]
  )

  const editNote = useCallback(
    (id: number, note?: string) => {
      setDataSource(prevDataSource => {
        const index = prevDataSource.findIndex(item => item.key === id)
        if (index === -1) return prevDataSource
        const newDataSource = [...prevDataSource]
        newDataSource[index] = { ...newDataSource[index], description: note }
        return newDataSource
      })
    },
    [setDataSource]
  )

  const columns = useMemo<ColumnsType<RcTableItemConcept>>(
    () => [
      {
        title: 'ID',
        dataIndex: 'key',
        key: 'key',
        sorter: {
          compare: (a: { key: number }, b: { key: number }) => a.key - b.key,
          multiple: 1,
        },
        defaultSortOrder: 'descend' as SortOrder,
      },
      {
        title: 'Poznámka',
        dataIndex: 'description',
        key: 'description',
        className: 'text-left',
        render: (text: string, record) => (
          <TabbleCellEditNoteModal
            onSave={editNote}
            initialNote={text}
            recordId={record.key}
          />
        ),
      },
      {
        title: 'Zadané údaje',
        dataIndex: 'typed',
        key: 'typed',
      },
      {
        title: 'Převedeno do tvaru',
        dataIndex: 'rc',
        key: 'rc',
      },
      {
        title: 'Kód validace',
        dataIndex: 'validatedCode',
        key: 'validatedCode',
        responsive: ['' as Breakpoint],
      },
      {
        title: 'Validace RČ',
        dataIndex: 'validated',
        key: 'validated',
        sorter: {
          compare: (
            a: { validatedCode?: number },
            b: { validatedCode?: number }
          ) => (a.validatedCode || 0) - (b.validatedCode || 0),
          multiple: 2,
        },
        defaultSortOrder: 'ascend' as SortOrder,
        render: (
          _: string,
          record: { validatedCode?: number; rc?: string }
        ) => {
          return record.validatedCode === 30 ? (
            <RcNumberData icon rcNumber={record?.rc} />
          ) : (
            <RcInputError error={record.validatedCode || 30} />
          )
        },
      },
      {
        title: 'Akce',
        dataIndex: 'actions',
        key: 'actions',
        render: (_: string, record) => (
          <>
            <Button type="link" danger onClick={() => deleteItem(record.key)}>
              Odebrat
            </Button>
          </>
        ),
      },
    ],
    [deleteItem, editNote]
  )

  const handleChange = useCallback(
    (event: { target: { value: string } }) => {
      const value = event.target.value

      setInputValue(value)
      if (/[,;\n]+|\d{6}\/\d{4}$|\d{10}$/.test(value)) {
        const lines = value
          .replace(/[,;"']+/g, '\n')
          .split(/[\n]+/)
          .filter((line: string) => line !== '')
          .map((line: string) => line.trim())

        const datos = dataSource
        lines.filter((value: string) => {
          let notice = ''
          let validatedCode = 30
          let line = value
          if (value.includes(':')) {
            const split = value.split(':')
            line = split[1]
            notice = split[0]
          }

          line = line.replace(/[/ \s]+/g, '')

          validatedCode = getRcValidateCode(line)
          if (datos.findIndex(row => row.rc === line) !== -1) {
            validatedCode = 5
          }
          datos.push({
            id: value,
            key: datos.length ? (datos[datos.length - 1]?.key || 0) + 1 : 1,
            rc: line,
            description: notice,
            typed: value,
            validatedCode: validatedCode,
          })
          setInputValue('')
        })
        setDataSource(datos)
        setSortedInfo({
          order: 'ascend',
          columnKey: 'validatedCode',
        })
        lines.length > 1
          ? message.success('Přidáno ' + lines.length + ' záznamů')
          : message.success('Záznam přidán')
      }
    },
    [dataSource]
  )

  return (
    <>
      <div className="flex space-y-2 lg:space-x-2 lg:space-y-0 flex-wrap lg:flex-nowrap justify-end">
        <div className="w-full" ref={props.inputRef}>
          <Input.TextArea
            placeholder="Zadejte nebo vložte rodná čísla, která chcete zkontrolovat a potvrďte enterem"
            onChange={handleChange}
            value={inputValue}
            maxLength={monitoringInputLimit}
            autoSize
            size="large"
            autoFocus
            className="placeholder:truncate"
          />
        </div>
        <div ref={props.buttonRef}>
          {props.type === MonitoringInputType.CuzkRc ? (
            <CuzkMonitorAddSubjectModal
              monitoring={props.monitoring}
              dataSource={[...dataSource]}
            />
          ) : (
            <CeeMonitorAddSubjectModal
              monitoring={props.monitoring}
              dataSource={[...dataSource]}
            />
          )}
        </div>
      </div>
      <Fade show={dataSource.length > 0}>
        <Table
          className="mt-4"
          dataSource={[...dataSource]}
          columns={columns}
          bordered
          size="small"
          rowClassName="animate-fadeIn"
          locale={locale}
        />
      </Fade>
    </>
  )
}
