import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { FunctionComponent, memo, useMemo } from 'react'

import { AreaFilterUse } from '../../../common/foreclosureMonitoringTypes'
import { useAreaTypeRelations } from '../../utils/useAreaTypeRelations'

export interface AreaTypeSelectionFilterCheckboxTooltipProps {
  areaTypeCode: number
  checkboxData: AreaFilterUse
}
const overlayInnerStyle = { width: 'min-content' }

export const AreaTypeSelectionFilterCheckboxTooltip: FunctionComponent<AreaTypeSelectionFilterCheckboxTooltipProps> =
  memo(props => {
    const { areaTypeName, linkedAreaUseNames } = useAreaTypeRelations(
      props.areaTypeCode,
      props.checkboxData
    )

    const TooltipContent = useMemo(
      () => (
        <div className="space-y-2">
          <div className="whitespace-nowrap">
            <span className="font-bold">{areaTypeName}</span> můžě mít
            následující způsoby využití:
          </div>
          <div>
            {linkedAreaUseNames.map((useName, index) => (
              <div key={index}>- {useName}</div>
            ))}
          </div>
          {props.areaTypeCode !== 11 && props.areaTypeCode !== 14 && (
            <div className="text-gray-400">
              Katastr nemovitostí u tohoto druhu pozemku nemusí evidovat způsob
              využití.
            </div>
          )}
        </div>
      ),
      [areaTypeName, linkedAreaUseNames, props.areaTypeCode]
    )

    return (
      <div className="flex justify-between items">
        <span>{areaTypeName}</span>
        <Tooltip title={TooltipContent} overlayInnerStyle={overlayInnerStyle}>
          <QuestionCircleOutlined className="text-gray-400 cursor-help px-2" />
        </Tooltip>
      </div>
    )
  })
