export const safeJsonParsing = <T>(
  jsonString: string | undefined | null,
  defaultValue: T
): T => {
  if (!jsonString) {
    return defaultValue
  }

  try {
    return JSON.parse(jsonString) as T
  } catch (error) {
    return defaultValue
  }
}
