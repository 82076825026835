import { useCallback, useMemo, useState } from 'react'

import { PROPERTY_TYPES } from '../../common/drmTypes'
import {
  ForclosureMonitoringFilterOnSubmit,
  ForclosureMonitoringFilterState,
} from '../../common/foreclosureMonitoringTypes'

export const useForeclosureMonitoringFilter = (
  propertyType: PROPERTY_TYPES
) => {
  const [filter, setFilter] = useState<ForclosureMonitoringFilterState>({
    zpusobyVyuzitiBudov: null,
    zpusobyVyuzitiJednotek: null,
    zpusobyVyuzitiPozemku: null,
    druhyPozemku: null,
    exekuce: 'true',
    insolvence: null,
    zastavySmluvni: null,
    zastavyNedobrovolne: null,
    vymeraDo: null,
    vymeraOd: null,
    vlastnikuOd: null,
    vlastnikuDo: null,
    pracoviste: null,
    stranka: 1,
  })

  const filterQuery = useMemo(() => {
    const stranka = `&stranka=${filter.stranka}`
    const druhyPozemku = filter.druhyPozemku
      ? `&druhyPozemku=${filter.druhyPozemku}`
      : ''
    const zpusobyVyuzitiBudov = filter.zpusobyVyuzitiBudov
      ? `&zpusobyVyuzitiBudov=${filter.zpusobyVyuzitiBudov}`
      : ''
    const zpusobyVyuzitiJednotek = filter.zpusobyVyuzitiJednotek
      ? `&zpusobyVyuzitiJednotek=${filter.zpusobyVyuzitiJednotek}`
      : ''
    const zpusobyVyuzitiPozemku = filter.zpusobyVyuzitiPozemku
      ? `&zpusobyVyuzitiPozemku=${filter.zpusobyVyuzitiPozemku}`
      : ''
    const exekuce = filter.exekuce ? `&exekuce=${filter.exekuce}` : ''
    const insolvence = filter.insolvence
      ? `&insolvence=${filter.insolvence}`
      : ''
    const zastavySmluvni = filter.zastavySmluvni
      ? `&zastavySmluvni=${filter.zastavySmluvni}`
      : ''
    const zastavyNedobrovolne = filter.zastavyNedobrovolne
      ? `&zastavyNedobrovolne=${filter.zastavyNedobrovolne}`
      : ''
    const vymeraDo = filter.vymeraDo ? `&vymeraDo=${filter.vymeraDo}` : ''
    const vymeraOd = filter.vymeraOd ? `&vymeraOd=${filter.vymeraOd}` : ''
    const vlastnikuOd = filter.vlastnikuOd
      ? `&vlastnikuOd=${filter.vlastnikuOd}`
      : ''
    const vlastnikuDo = filter.vlastnikuDo
      ? `&vlastnikuDo=${filter.vlastnikuDo}`
      : ''
    const pracoviste = filter.pracoviste
      ? `&katastralniPracoviste=${filter.pracoviste}`
      : ''
    const typNemovitosti =
      propertyType === PROPERTY_TYPES.BUILDING
        ? 'Budovy?'
        : propertyType === PROPERTY_TYPES.UNIT
        ? 'Jednotky?'
        : 'Parcely?'

    const filterQuery = `${typNemovitosti}${exekuce}${insolvence}${zastavySmluvni}${zastavyNedobrovolne}${vymeraDo}${vymeraOd}${vlastnikuOd}${vlastnikuDo}${pracoviste}${zpusobyVyuzitiBudov}${zpusobyVyuzitiJednotek}${zpusobyVyuzitiPozemku}${druhyPozemku}${stranka}`

    return filterQuery
  }, [
    filter.druhyPozemku,
    filter.exekuce,
    filter.insolvence,
    filter.pracoviste,
    filter.stranka,
    filter.vlastnikuDo,
    filter.vlastnikuOd,
    filter.vymeraDo,
    filter.vymeraOd,
    filter.zastavyNedobrovolne,
    filter.zastavySmluvni,
    filter.zpusobyVyuzitiBudov,
    filter.zpusobyVyuzitiJednotek,
    filter.zpusobyVyuzitiPozemku,
    propertyType,
  ])

  const handleChange = useCallback<ForclosureMonitoringFilterOnSubmit>(
    updates => {
      setFilter(prevFilter => ({ ...prevFilter, stranka: 1, ...updates }))
    },
    []
  )

  return {
    filter,
    filterQuery,
    handleChange,
  }
}
