import { Button, Popover, PopoverProps } from 'antd'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { addressPath, areaPath } from '../../utils/paths'

const popoverProps: PopoverProps = {
  placement: 'left',
  overlayInnerStyle: {
    padding: '1rem',
    width: 'min-content',
    display: 'flex',
  },
  trigger: ['click'],
  className: 'flex p-4',
}

export const CreatePriceEstimateButton: FunctionComponent = () => {
  return (
    <Popover
      {...popoverProps}
      title={
        <div className="space-y-4 w-auto">
          <div>Pro jaký typ nemovitosti chcete vytvořit nový cenový odhad?</div>
          <div className="flex space-x-2">
            <Link to={addressPath()}>
              <Button type="primary">Byt</Button>
            </Link>
            <Link to={addressPath()}>
              <Button type="primary">Budova</Button>
            </Link>
            <Link to={areaPath()}>
              <Button type="primary">Parcela</Button>
            </Link>
          </div>
        </div>
      }
    >
      <Button type="primary">Vytvořit nový odhad</Button>
    </Popover>
  )
}
