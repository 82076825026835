import { Icon } from '@iconify/react'
import React, { FunctionComponent, useMemo } from 'react'

import { PageLayoutTitle } from '../PageLayoutTitle/PageLayoutTitle'

export interface PageLayoutProps {
  title?: React.ReactNode | string
  extra?: React.ReactNode
  children?: React.ReactNode
  isNew?: boolean
  isNewTitle?: string
  className?: string
  isContainer?: boolean
}

export const PageLayout: FunctionComponent<PageLayoutProps> = props => {
  const bodyStyle = useMemo(() => {
    if (props.isContainer) {
      return props.className ?? ''
    }
    return 'p-6 bg-white print:p-0 ' + props.className
  }, [props.isContainer, props.className])

  return (
    <>
      <PageLayoutTitle title={props.title} extra={props.extra} />
      <div className={bodyStyle}>{props.children}</div>
      {props.isNew && (
        <div className="flex justify-end mt-3">
          <div className="flex justify-center items-center w-full text-gray-500 space-x-2 p-2">
            <Icon icon="bi:fire" className="text-orange-400 " />
            <span className="font-semibold">NEW:</span>
            <div>
              {props.isNewTitle || props.title}
              {
                ' je nová funkce, pokud byste zaznamenali jakýkoliv problém, neváhejte nás prosím kontaktovat na '
              }
              <a href="mailto:info@regesta.cz">info@regesta.cz</a>
              {' nebo na tel. '}
              <a href="tel:+420601201998">601 201 998</a>
              {'. Děkujeme.'}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
