import { Modal } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import {
  AreaFilterUse,
  AreaFilterUseCodes,
  ForclosureMonitoringFilterOnSubmit,
} from '../../../common/foreclosureMonitoringTypes'
import { useToggleState } from '../../utils/useToggleState'
import { AreaUseSelection } from '../AreaUseSelection/AreaUseSelection'
import { PropertyMonitoringFilterSelectionButton } from '../PropertyMonitoringFilterSelectionButton/PropertyMonitoringFilterSelectionButton'

export interface PropertyMonitoringFilterAreaUseModalButtonProps {
  checkboxData: AreaFilterUse
  selectedValues: AreaFilterUseCodes | null
  onSubmit: ForclosureMonitoringFilterOnSubmit
}

export const PropertyMonitoringFilterAreaUseModalButton: FunctionComponent<PropertyMonitoringFilterAreaUseModalButtonProps> =
  props => {
    const [open, toggleOpen] = useToggleState(false)

    const handleOnSubmit = useCallback(
      (selected: AreaFilterUseCodes | null) => {
        props.onSubmit({
          zpusobyVyuzitiPozemku: selected?.areaUse || null,
          druhyPozemku: selected?.areaType || null,
        })
      },
      [props]
    )
    const handleReset = useCallback(() => {
      handleOnSubmit(null)
    }, [handleOnSubmit])

    const [selectedCount, totalCount] = useMemo(
      () => [
        (props.selectedValues?.areaType?.length || 0) +
          (props.selectedValues?.areaUse?.length || 0),
        (props.checkboxData?.areaUse?.length || 0) +
          (props.checkboxData?.areaType?.length || 0),
      ],
      [
        props.checkboxData?.areaType?.length,
        props.checkboxData?.areaUse?.length,
        props.selectedValues?.areaType?.length,
        props.selectedValues?.areaUse?.length,
      ]
    )

    return (
      <div>
        <PropertyMonitoringFilterSelectionButton
          toggleOpen={toggleOpen}
          handleReset={handleReset}
          selectedCount={selectedCount}
          totalCount={totalCount}
        />
        <Modal
          title="Výběr druhu parcely a způsobu využití"
          open={open}
          onCancel={toggleOpen}
          footer={null}
          width={1400}
        >
          <AreaUseSelection
            checkboxData={props.checkboxData}
            selectedValues={props.selectedValues}
            toggleModalVisibility={toggleOpen}
            onSubmit={handleOnSubmit}
          />
        </Modal>
      </div>
    )
  }
