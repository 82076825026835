import axios from 'axios'
import { useCallback } from 'react'

import { PropertyDataTypes } from '../../redux/fetchAction'
import { useAppSelector } from '../../redux/hooks'
import { requestLvPath } from './paths'
import { useDispatchFetch } from './useDispatchFetch'

export const useFetchPropertyLv = () => {
  const { token, tenantId } = useAppSelector(state => state.myProfile)
  const { dispatchError, dispatchFetching, dispatchSuccess } =
    useDispatchFetch()

  const fetchLv = useCallback(
    async (cadastralCode?: string | number, lv?: string | number) => {
      if (!token || !tenantId || !cadastralCode || !lv)
        return dispatchError(PropertyDataTypes.LV)

      dispatchFetching(PropertyDataTypes.LV)

      try {
        const response = await axios.get(
          `${requestLvPath()}${tenantId}/${token}/${cadastralCode}/${lv}/true`
        )
        dispatchSuccess(PropertyDataTypes.LV, response.data)
      } catch (error) {
        dispatchError(PropertyDataTypes.LV)
      }
    },
    [dispatchError, dispatchFetching, dispatchSuccess, tenantId, token]
  )

  return fetchLv
}
