import { AlertProps } from 'antd/es/alert'

interface RcErrorDescription {
  errorCodeType: string
  title: string
  description: string
  type: AlertProps['type']
}

export const rcErrorDescription: { [key: number]: RcErrorDescription } = {
  1: {
    errorCodeType: 'int',
    title: 'Nejedná se o číslo',
    description:
      'Zadaná hodnota obsahuje znaky, které nejsou číslice ani lomítko.',
    type: 'error',
  },
  2: {
    errorCodeType: 'length',
    title: 'RČ nemá správnou délku',
    description:
      'Rodné číslo osoby narozené do roku 1954 musí obsahovat 9 číslic, rodná čísla od roku 1954 musí obsahovat 10 číslic.',
    type: 'error',
  },
  3: {
    errorCodeType: 'thirdDigit',
    title: 'Na třetí pozici je špatná číslice',
    description:
      'Prvních 6 číslic rodného čísla je datum narození ve tvaru RRMMDD. První číslice měsíce může být 0 či 1, u žen 5 či 6. Ve vzácných případech, kdy se v daný den narodilo více dětí, než bylo platných kombinací rodných čísel, se přičte k měsíci 20, čili  V těchto ojedinělých případech může být třetí číslice také 2, 3, 7 či 8, nikdy však 4 nebo 9.',
    type: 'error',
  },
  4: {
    errorCodeType: 'fifthDigit',
    title: 'Špatný den narození v RČ',
    description:
      'Pátá a šestá číslice rodného čísla značí den narození v rozmezí 01 až 31, na této pozici se nemůže vyskytovat 00 nebo číslo větší než 31.',
    type: 'error',
  },
  5: {
    errorCodeType: 'duplicate',
    title: 'Duplicitní záznam',
    description: 'Toto RČ jste zadali vícekrát, zpracováno bude pouze jednou.',
    type: 'error',
  },
  10: {
    errorCodeType: 'divisible',
    title: 'RČ není dělitelné 11',
    description:
      'Rodná čísla od roku 1954 jsou dělitelná 11 beze zbytku, avšak podle ČSÚ se historicky vyskytlo několik výjimek (max. jednotky tisíc osob), kdy byla udělena rodná čísla, která tomuto pravidlu neodpovídají.',
    type: 'warning',
  },
  11: {
    errorCodeType: 'unusualMonth',
    title: 'Nezvyklé číslo měsíce',
    description:
      'Prvních 6 číslic rodného čísla je datum narození ve tvaru RRMMDD, přičemž ženám je přičteno k měsíci 50, třetí číslice tedy musí být 0, 1, 5 nebo 6, avšak pokud se v daný den narodilo více dětí, než bylo platných kombinací rodných čísel, přičte se k měsíci ještě 20. Pouze v těchto ojedinělých případech může být třetí číslice 2, 3, 7 či 8.',
    type: 'warning',
  },
  30: {
    errorCodeType: 'unknown',
    title: 'Neznámá chyba',
    description: 'Vyskytla se neznámá chyba při ověření platnosti RČ',
    type: 'error',
  },
}
