import { Breakpoint, Spin, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { Budova, Parcela } from '../../../common/lvTypes'
import { useAppSelector } from '../../../redux/hooks'
import {
  areaNumberSorter,
  nestedStringSorter,
  numberSorter,
  stringSorter,
} from '../../utils/generalTableHelpers'
import {
  priceEstimateSummaryStyle,
  propertyDetailTableProps,
} from '../../utils/layoutConst'
import { metrageProps } from '../../utils/propertyDetailHelper'
import { useDeclension } from '../../utils/useDeclension'
import { usePropertiesLabels } from '../../utils/usePropertiesLabels'
import { watchdogAreaColumn } from '../../utils/watchdogHelpers'
import { Money } from '../Money/Money'
import { PropertyDetailLink } from '../PropertyDetailLink/PropertyDetailLink'
import { PropertyDetailOwnershipRestrictionCell as RestrictionCell } from '../PropertyDetailOwnershipRestrictionCell/PropertyDetailOwnershipRestrictionCell'
import { PropertyTableItemLabel } from '../PropertyTableItemLabel/PropertyTableItemLabel'

export interface PropertyDetailLvAreaTableProps {
  parcely: Parcela[]
  budovy?: Budova[]
  propertyId: string
  showCadastralArea?: boolean
  hideMetrage?: boolean
  drawerTarget?: boolean
  target?: React.HTMLAttributeAnchorTarget
}

export const PropertyDetailLvAreaTable: FunctionComponent<PropertyDetailLvAreaTableProps> =
  props => {
    const decline = useDeclension()
    const lvGeometry = useAppSelector(state => state.property.lvGeometry)

    const areasIds = useMemo(
      () => props.parcely.map(parcela => parcela.id),
      [props.parcely]
    )

    const {
      propertyLabels,
      refetch,
      loading: labelsLoading,
    } = usePropertiesLabels(areasIds, PROPERTY_TYPES.AREA)

    const dataSource = useMemo(() => {
      if (lvGeometry.fetching || !lvGeometry.data?.parcely) {
        return props.parcely
      }
      const lvParcelyMap = new Map(
        lvGeometry.data.parcely.map(parcela => [parcela.id, parcela])
      )

      return props.parcely.map(parcela => {
        const lvParcela = lvParcelyMap.get(parcela.id)
        return {
          ...parcela,
          vymera: lvParcela?.vymera ?? parcela.vymera,
        }
      })
    }, [lvGeometry.data?.parcely, lvGeometry.fetching, props.parcely])

    const buildingRenderer = useCallback(
      (id: string) => {
        const building = props.budovy?.find(
          budova => budova?.idetifikacniParcela?.id === id
        )
        if (!building) return null
        return (
          <PropertyDetailLink
            text={building.cislaDomovni || 'Bez č.p.'}
            propertyId={building?.id}
            propertyType={PROPERTY_TYPES.BUILDING}
            isActive={building?.id === props.propertyId}
            drawerTarget={props.drawerTarget}
            target={props.target}
          />
        )
      },
      [props.budovy, props.drawerTarget, props.propertyId, props.target]
    )

    const areaRenderer = useCallback(
      (area: string, record: Parcela) => (
        <PropertyDetailLink
          text={area}
          propertyId={record.id}
          propertyType={PROPERTY_TYPES.AREA}
          isActive={record.id === props.propertyId}
          drawerTarget={props.drawerTarget}
          target={props.target}
        />
      ),
      [props.drawerTarget, props.propertyId, props.target]
    )

    const labelSorter = useCallback(
      (a: Parcela, b: Parcela) =>
        (
          propertyLabels?.[b.id]?.labelConnectionsByPropertyLabelId?.nodes?.[0]
            ?.labelByLabelId?.name || ''
        ).localeCompare(
          propertyLabels?.[a.id]?.labelConnectionsByPropertyLabelId?.nodes?.[0]
            ?.labelByLabelId?.name || ''
        ),
      [propertyLabels]
    )

    const labelRenderer = useCallback(
      (propertyId: string) =>
        labelsLoading ? (
          <Spin size="small" />
        ) : (
          <PropertyTableItemLabel
            propertyId={propertyId}
            propertyType={PROPERTY_TYPES.AREA}
            refetch={refetch}
            propertyLabel={propertyLabels?.[propertyId]}
          />
        ),
      [labelsLoading, propertyLabels, refetch]
    )

    const areaColumns: ColumnsType<Parcela> = useMemo(
      () => [
        {
          title: 'Parcela',
          dataIndex: 'druhPozemku',
          sorter: stringSorter('druhPozemku'),
          render: areaRenderer,
        },
        {
          title: 'Štítky',
          dataIndex: 'id',
          key: 'labels',
          sorter: labelSorter,
          render: labelRenderer,
        },
        {
          title: 'Kat. území',
          dataIndex: ['katastralniUzemi', 'nazev'],
          className: 'w-32',
          responsive: props.showCadastralArea ? undefined : ['' as Breakpoint],
          sorter: nestedStringSorter('katastralniUzemi', 'nazev'),
        },
        {
          title: 'Parcelní číslo',
          dataIndex: 'parcelniCislo',
          ellipsis: true,
          className: 'w-32',
          render: parcelniCislo => parcelniCislo || 'Bez č.p.',
          sorter: areaNumberSorter('parcelniCislo'),
        },
        {
          title: 'Pod budovou',
          dataIndex: 'id',
          key: 'idetifikacniParcela',
          className: 'w-32',
          ellipsis: true,
          render: buildingRenderer,
        },
        {
          title: 'Omezení vl. práv',
          dataIndex: 'omezeniVlPrav',
          className: 'max-w-max',
          render: omezeni => <RestrictionCell restriction={omezeni} />,
        },
        {
          title: 'Výměra',
          dataIndex: 'vymera',
          className: 'w-28 !text-right',
          responsive: props.hideMetrage ? ['' as Breakpoint] : undefined,
          sorter: numberSorter('vymera'),
          render: vymera => <Money amount={vymera} {...metrageProps} />,
        },
        watchdogAreaColumn,
      ],
      [
        areaRenderer,
        labelSorter,
        labelRenderer,
        props.showCadastralArea,
        props.hideMetrage,
        buildingRenderer,
      ]
    )

    const summary = useCallback(
      () =>
        dataSource.length > 1 && (
          <Table.Summary.Row>
            <Table.Summary.Cell
              className="text-gray-400 !border-b-0 border-gray-100 border-t"
              index={1}
              colSpan={3}
            >
              Celkem: {decline(dataSource.length, 'area', true)}
            </Table.Summary.Cell>
            {props.hideMetrage ? null : (
              <Table.Summary.Cell
                className={priceEstimateSummaryStyle}
                index={2}
                colSpan={3}
              >
                Celkem:{' '}
                <Money
                  {...metrageProps}
                  amount={dataSource.reduce((a, b) => a + Number(b.vymera), 0)}
                />
              </Table.Summary.Cell>
            )}
          </Table.Summary.Row>
        ),
      [dataSource, decline, props.hideMetrage]
    )

    const rowClassName = useCallback(
      (record: Parcela) =>
        record.id === props.propertyId ? 'text-gray-400' : '',
      [props.propertyId]
    )

    return (
      <Table
        dataSource={dataSource}
        columns={areaColumns}
        rowClassName={rowClassName}
        rowKey="id"
        summary={summary}
        {...propertyDetailTableProps}
      />
    )
  }
