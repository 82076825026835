import { Button, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { ExpandableConfig, TableRowSelection } from 'antd/es/table/interface'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import {
  CeeExecutionDetailsConnection,
  CeeOffice,
  Maybe,
  MonitoringCee,
  useCeeMonitorTableExpandedRowQuery,
} from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { checkCeeDetail } from '../../utils/checkCeeDetail'
import { defaultPagination } from '../../utils/layoutConst'
import { pricelist } from '../../utils/pricelist'
import { CeeDetailPdf } from '../CeeDetailPdf/CeeDetailPdf'
import { CeeExecutionTableBulkEdit } from '../CeeExecutionTableBulkEdit/CeeExecutionTableBulkEdit'
import { CeeMonitorTableDetailExpanded } from '../CeeMonitorTableDetailExpanded/CeeMonitorTableDetailExpanded'
import { CeeMonitorTableIdentificationPanel } from '../CeeMonitorTableIdentificationPanel/CeeMonitorTableIdentificationPanel'
import { CeeOfficeTooltip } from '../CeeOfficeTooltip/CeeOfficeTooltip'
import { Day } from '../DateFormat/DateFormat'
import { ExpandColumnTitle } from '../ExpandColumnTitle/ExpandColumnTitle'
import { ExpandIcon } from '../ExpandIcon/ExpandIcon'
import { PdfDrawerPreview } from '../PdfDrawerPreview/PdfDrawerPreview'
import { PricePopconfirm } from '../PricePopconfirm/PricePopconfirm'

export interface CeeMonitorTableExpandedRowProps {
  monitoringCeeId: string
}

export interface CeeMonitorItem {
  address: string
  birthDate?: Maybe<Date>
  ceeEvent?: Maybe<string>
  ceeDetails?: Maybe<Partial<CeeExecutionDetailsConnection>>
  ceeOfficeByExecutorCode?: Maybe<Partial<CeeOffice>>
  createdAt?: Maybe<Date>
  lastCheck?: Maybe<string>
  detailCode?: Maybe<string>
  executionDate?: Maybe<Date>
  executorCode?: Maybe<string>
  executorName?: Maybe<string>
  fullname: string
  ico?: Maybe<string>
  id: string
  identificationCode?: Maybe<string>
  monitoringCeeByMonitoringCeeId?: Maybe<Partial<MonitoringCee>>
  monitoringCeeId?: Maybe<string>
  rc?: Maybe<string>
  spzn?: Maybe<string>
  updateDate?: Maybe<Date>
}

export const CeeMonitorTableExpandedRow: FunctionComponent<CeeMonitorTableExpandedRowProps> =
  props => {
    const tableId = 'cee-' + props.monitoringCeeId
    const [queryResult, refetch] = useCeeMonitorTableExpandedRowQuery({
      variables: { monitoringCeeId: props.monitoringCeeId },
    })
    const [loading, setLoading] = useState<boolean | string>(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [selectedRow, setSelectedRow] = useState<CeeMonitorItem[]>([])
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const userId = useAppSelector(state => state.myProfile.userId)
    const expandedRowKeys = useAppSelector(
      state => state.expandedRow.tables?.[tableId]
    )

    const handleCheck = useCallback(
      async (record: CeeMonitorItem[]) => {
        record.length ? setLoading(true) : setLoading(record?.[0]?.id || true)
        const check = await checkCeeDetail(record, tenantId, userId)
        if (check) refetch()
        return setLoading(false)
      },
      [tenantId, userId, refetch]
    )

    const dataSource = useMemo<CeeMonitorItem[] | []>(
      () =>
        queryResult.data?.allCeeExecutions?.nodes?.length
          ? queryResult.data?.allCeeExecutions?.nodes.map(item => ({
              address: item?.address || '',
              birthDate: item?.birthDate,
              ceeEvent: item?.ceeEvent || '',
              ceeDetails: item?.ceeDetails,
              ceeOfficeByExecutorCode: item?.ceeOfficeByExecutorCode,
              detailCode: item?.detailCode || '',
              executionDate: item?.executionDate,
              fullname: item?.fullname || 'Povinná osoba',
              ico: item?.ico,
              identificationCode: item?.identificationCode || '',
              rc: item?.rc,
              spzn: item?.spzn || '',
              updateDate: item?.updateDate,
              id: item?.id || 'id',
              createdAt: item?.createdAt,
              key: item?.id || item?.spzn || '',
            }))
          : [],
      [queryResult.data?.allCeeExecutions?.nodes]
    )

    const checkable = useMemo(
      () => dataSource?.filter(item => !item?.ceeDetails?.nodes?.length),
      [dataSource]
    )
    const columns = useMemo<ColumnsType<CeeMonitorItem>>(
      () => [
        {
          title: 'Poslední aktualizace',
          dataIndex: 'updateDate',
          key: 'updateDate',
          sorter: {
            compare: (a, b) =>
              a.updateDate && b.updateDate
                ? a.updateDate.toString().localeCompare(b.updateDate.toString())
                : 0,
          },
          render: (updateDate: Date) => {
            return <Day>{updateDate}</Day>
          },
        },
        {
          title: 'Exekutor',
          dataIndex: 'ceeOfficeByExecutorCode',
          key: 'ceeOfficeByExecutorCode',
          sorter: {
            compare: (a, b) =>
              a.ceeOfficeByExecutorCode?.name && b.ceeOfficeByExecutorCode?.name
                ? a.ceeOfficeByExecutorCode?.name.localeCompare(
                    b.ceeOfficeByExecutorCode?.name
                  )
                : 0,
          },
          render: (ceeOfficeByExecutorCode: CeeOffice) => {
            return <CeeOfficeTooltip user={ceeOfficeByExecutorCode} thumbnail />
          },
        },
        {
          title: 'Spisová značka',
          dataIndex: 'spzn',
          key: 'spzn',
          sorter: {
            compare: (a, b) =>
              a.spzn && b.spzn ? a.spzn.localeCompare(b.spzn) : 1,
          },
        },
        {
          title: 'Jméno',
          dataIndex: 'fullname',
          key: 'fullname',
          sorter: {
            compare: (a, b) => a.fullname.localeCompare(b.fullname),
          },
        },
        {
          title: 'Adresa',
          dataIndex: 'address',
          key: 'address',
          sorter: (a: { address: string }, b: { address: string }) =>
            a.address.length - b.address.length,
        },
        {
          title: (
            <div className="flex justify-between">
              <span>Akce</span>
              {checkable?.length && (
                <PricePopconfirm
                  itemPrice={pricelist.CEE}
                  onConfirm={() => handleCheck(checkable)}
                  message={`Zobrazení částky a detailu exekuce je zpoplatněno ${pricelist.CEE} Kr.`}
                  itemCount={checkable.length}
                  popconfrimProps={{ placement: 'topRight' }}
                >
                  <Button type="primary" size="small">
                    Zobrazit všechny částky
                  </Button>
                </PricePopconfirm>
              )}
            </div>
          ),
          dataIndex: 'action',
          key: 'action',
          className: 'text-right',
          render: (_: string, record) => {
            return record?.ceeDetails?.nodes?.length ? (
              <PdfDrawerPreview
                buttonProps={{ ghost: true, size: 'small' }}
                drawerTitle="Náhled PDF - detail exekuce"
                fileName={
                  record?.fullname || record?.rc + ' CEE_DETAIL_REGESTA_DATA'
                }
              >
                <CeeDetailPdf data={record} />
              </PdfDrawerPreview>
            ) : (
              <PricePopconfirm
                itemPrice={pricelist.CEE}
                onConfirm={() => handleCheck([record])}
                message={`Zobrazení částky a detailu exekuce je zpoplatněno ${pricelist.CEE} Kr.`}
                popconfrimProps={{ placement: 'topRight' }}
              >
                <Button
                  type="primary"
                  size="small"
                  loading={loading === record.id || loading === true}
                >
                  Zobrazit částku
                </Button>
              </PricePopconfirm>
            )
          },
        },
      ],
      [checkable, handleCheck, loading]
    )

    const expandable = useMemo<ExpandableConfig<CeeMonitorItem>>(
      () => ({
        expandedRowKeys,
        columnTitle: (
          <ExpandColumnTitle
            allKeys={dataSource
              .filter(record => record?.ceeDetails?.nodes?.length)
              .map(record => record.id)}
            tableId={tableId}
          />
        ),
        expandedRowRender: record => (
          <CeeMonitorTableDetailExpanded
            ceeDetail={record?.ceeDetails?.nodes?.[0]}
          />
        ),
        expandIcon: ({ expanded, record }) => (
          <ExpandIcon
            expanded={expanded}
            recordId={record.id}
            tableId={tableId}
            expandable={!!record?.ceeDetails?.nodes?.length}
          />
        ),
      }),
      [dataSource, expandedRowKeys, tableId]
    )

    const rowSelection = useMemo<TableRowSelection<CeeMonitorItem>>(
      () => ({
        selectedRowKeys,
        onChange: (newSelectedRowKeys, selectedRows) => {
          setSelectedRowKeys(newSelectedRowKeys)
          setSelectedRow(selectedRows)
        },
      }),
      [selectedRowKeys]
    )
    return (
      <>
        <CeeMonitorTableIdentificationPanel
          ceeData={dataSource}
          loading={queryResult.fetching}
        />
        <CeeExecutionTableBulkEdit
          refetch={refetch}
          selectedRows={selectedRow}
        />
        <Table
          pagination={defaultPagination}
          dataSource={dataSource}
          columns={columns}
          loading={queryResult.fetching}
          size="small"
          rowSelection={rowSelection}
          expandable={expandable}
          className="pb-10 mb-6 bg-white rounded border-b-2 border-gray-200"
        />
      </>
    )
  }
