import { Button } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import { AreaFilterUseCodes } from '../../../common/foreclosureMonitoringTypes'
import { PropertyUseSelectionFooterCounter } from '../PropertyUseSelectionFooterCounter/PropertyUseSelectionFooterCounter'

export interface AreaUseSelectionFooterProps {
  count: { total: number; selected: number }
  checkedValues: AreaFilterUseCodes
  toggleModalVisibility: () => void
  onSubmit: (selected: AreaFilterUseCodes | null) => void
}

export const AreaUseSelectionFooter: FunctionComponent<AreaUseSelectionFooterProps> =
  ({ count, checkedValues, onSubmit, toggleModalVisibility }) => {
    const handleSubmit = useCallback(() => {
      onSubmit(count.total === count.selected ? null : checkedValues)
      toggleModalVisibility()
    }, [
      onSubmit,
      count.total,
      count.selected,
      checkedValues,
      toggleModalVisibility,
    ])

    return (
      <div className="flex justify-end items-center space-x-2">
        <PropertyUseSelectionFooterCounter
          selectedCount={count.selected}
          totalCount={count.total}
          text="Vybraných parametrů"
        />

        <Button onClick={toggleModalVisibility}>Zrušit</Button>
        <Button
          onClick={handleSubmit}
          type="primary"
          disabled={!count.selected}
        >
          Uložit nastavení
        </Button>
      </div>
    )
  }
