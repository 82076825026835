import { createSlice } from '@reduxjs/toolkit'

import { PropertyTimeline } from '../common/propertyTimelineTypes'
import handleAsyncActions, {
  AsyncState,
  initialFetchState,
  PropertyDataTypes,
} from './fetchAction'

export const propertyTimelineSlice = createSlice({
  name: PropertyDataTypes.TIMELINE,
  initialState: initialFetchState as AsyncState<PropertyTimeline>,
  reducers: {},
  extraReducers: builder => {
    handleAsyncActions<PropertyTimeline>(builder, PropertyDataTypes.TIMELINE)
  },
})

export default propertyTimelineSlice.reducer
