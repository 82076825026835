import { createSlice } from '@reduxjs/toolkit'

import { WatchdogProperty } from '../common/watchdogTypes'
import handleAsyncActions, {
  AsyncState,
  initialFetchState,
  WatchDogDataTypes,
} from './fetchAction'

const watchdogPropertySlice = createSlice({
  name: WatchDogDataTypes.PROPERTY,
  initialState: initialFetchState as AsyncState<WatchdogProperty>,
  reducers: {},
  extraReducers: builder => {
    handleAsyncActions<WatchdogProperty>(builder, WatchDogDataTypes.PROPERTY)
  },
})

export default watchdogPropertySlice.reducer
