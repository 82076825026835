import { Button, Divider } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { RestrictionChecType } from '../../../common/epoTypes'
import {
  CadastralCheckedValues,
  CadastralOffice,
  cuzkKatastralniUrady,
} from '../../utils/cuzkHelpers'
import { CadastralSelectionModalFilter } from '../CadastralSelectionModalFilter/CadastralSelectionModalFilter'
import { CadastralSelectionModalFooter } from '../CadastralSelectionModalFooter/CadastralSelectionModalFooter'
import { CadastralSelectionSearchBar } from '../CadastralSelectionSearchBar/CadastralSelectionSearchBar'
import { CadastralSelectionSelectAllButon } from '../CadastralSelectionSelectAllButon/CadastralSelectionSelectAllButon'

export interface CadastralSelectionModalContentProps {
  checkboxData: CadastralOffice[]
  toggleModalVisibility: () => void
  refetch: () => void
  epoId: string
  type: RestrictionChecType
}

export const CadastralSelectionModalContent: FunctionComponent<CadastralSelectionModalContentProps> =
  ({
    type,
    epoId,
    toggleModalVisibility,
    checkboxData = cuzkKatastralniUrady,
    refetch,
  }) => {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [filteredRegions, setFilteredRegions] = useState(checkboxData)
    const [boughtVisible, setBoughtVisible] = useState(false)
    const [checkedValues, setCheckedValues] = useState<CadastralCheckedValues>(
      {}
    )
    const findOffice = useCallback(
      (codes: number[]) =>
        checkboxData.filter(
          office =>
            codes.includes(office.kod) ||
            office.pracoviste.some(workplace => codes.includes(workplace.kod))
        ),
      [checkboxData]
    )

    const toggleBoughtVisible = useCallback(
      () => setBoughtVisible(prev => !prev),
      []
    )

    const handleMatching = useCallback(
      (matchCodes: number[]) => {
        setFilteredRegions(findOffice(matchCodes))
      },
      [findOffice]
    )

    const boughtToggle = useMemo(() => {
      const count = checkboxData.reduce(
        (acc, office) =>
          acc +
          office.pracoviste.filter(workplace => workplace.boughtAt).length,
        0
      )
      const checkType =
        type === RestrictionChecType.lien ? 'zakoupené' : 'ověřené'
      const text =
        (boughtVisible ? `Skrýt ${checkType}` : `Zobrazit i ${checkType}`) +
        ' (' +
        count +
        ')'
      return { text, count }
    }, [boughtVisible, checkboxData, type])

    const checkFilteredCodes = useCallback(
      (filteredCodes: number[]) => {
        const matchingOffices = findOffice(filteredCodes)

        if (matchingOffices.length === 1) {
          const matchingOffice = matchingOffices[0]
          const matchingWorkplaces = matchingOffice.pracoviste.filter(
            workplace => filteredCodes.includes(workplace.kod)
          )
          if (matchingWorkplaces.length === 1) {
            setCheckedValues(prevValues => ({
              ...prevValues,
              [matchingOffice.kod]: Array.from(
                new Set([
                  ...(prevValues[matchingOffice.kod] || []),
                  matchingWorkplaces[0].kod,
                ])
              ),
            }))
          } else {
            setCheckedValues(prevValues => ({
              ...prevValues,
              [matchingOffice.kod]: matchingOffice.pracoviste.map(
                workplace => workplace.kod
              ),
            }))
          }

          setSearchTerm('')
          setFilteredRegions(checkboxData)
        }
      },
      [findOffice, checkboxData]
    )

    return (
      <>
        <div className="flex space-x-4 mb-8">
          <CadastralSelectionSearchBar
            handleCheckCodes={checkFilteredCodes}
            checkboxData={checkboxData}
            handleMatching={handleMatching}
            setSearchTerm={setSearchTerm}
          />
          <Button disabled={!boughtToggle.count} onClick={toggleBoughtVisible}>
            {boughtToggle.text}
          </Button>
          <CadastralSelectionSelectAllButon
            checkedValues={checkedValues}
            filteredRegions={filteredRegions}
            setCheckedValues={setCheckedValues}
          />
        </div>
        <Divider />
        <CadastralSelectionModalFilter
          filteredRegions={filteredRegions}
          boughtVisible={boughtVisible}
          setCheckedValues={setCheckedValues}
          checkedValues={checkedValues}
          searchTerm={searchTerm}
        />
        <Divider />
        <CadastralSelectionModalFooter
          type={type}
          checkboxData={checkboxData}
          checkedValues={checkedValues}
          toggleModalVisibility={toggleModalVisibility}
          refetch={refetch}
          epoId={epoId}
        />
      </>
    )
  }
