import { Select, Spin } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useDataBoxSearchBarQuery } from '../../../graphql/generated'

interface FilterInput {
  [Key: string]:
    | {
        includesInsensitive?: string
        equalTo?: string
      }
    | undefined
}

type Filter =
  | {
      or: Array<FilterInput>
    }
  | FilterInput
  | undefined

interface QueryVariables {
  first?: number
  filter?: Filter
}

export interface DataBoxSearchBarProps {
  setDataBoxId: (dataBoxId: string) => void
}

export const DataBoxSearchBar: FunctionComponent<DataBoxSearchBarProps> =
  props => {
    const {
      params: { urlQuery },
    } = useRouteMatch<{ urlQuery: string }>()

    const [touched, setTouched] = useState<boolean>(false)
    const [debounceTimeout, setDebounceTimeout] =
      useState<null | NodeJS.Timeout>(null)

    const urlQueryVariables = useMemo(
      () => ({
        first: 1,
        filter: {
          or: [
            { ico: { equalTo: urlQuery } },
            { databoxid: { equalTo: urlQuery } },
            { masterid: { equalTo: urlQuery } },
            { fullname: { includesInsensitive: urlQuery } },
          ],
        },
      }),
      [urlQuery]
    )

    const [variables, setVariables] = useState<QueryVariables>(
      urlQuery
        ? urlQueryVariables
        : {
            first: 0,
          }
    )
    const [databoxResponse] = useDataBoxSearchBarQuery({
      variables,
    })

    const allDataboxes = databoxResponse.data?.allDataboxes?.nodes

    const initialValue = useMemo(
      () =>
        urlQuery && allDataboxes?.[0]?.id && allDataboxes?.[0]?.fullname
          ? {
              key: allDataboxes?.[0]?.id,
              label: allDataboxes?.[0]?.fullname,
              value: allDataboxes?.[0]?.id,
            }
          : undefined,
      [allDataboxes, urlQuery]
    )

    const options = useMemo(() => {
      const options: DefaultOptionType[] | undefined = allDataboxes?.map(
        dataBox => ({
          label: dataBox?.fullname || 'Název není k dispozici',
          value: dataBox?.id || 'Není k dispozici',
        })
      )
      if (databoxResponse.fetching)
        return [
          {
            label: (
              <div className="space-x-2 animate-pulse">
                <span className="gray-800">Vyhledávání </span>
                <Spin size="small" />
              </div>
            ),
            value: 'loading',
            disabled: true,
          },
          ...(options || []),
        ]
      return options
    }, [allDataboxes, databoxResponse.fetching])

    useEffect(() => {
      if (
        !touched &&
        urlQuery &&
        allDataboxes?.length &&
        allDataboxes?.[0]?.id
      ) {
        props.setDataBoxId(allDataboxes?.[0]?.id)
        setTouched(true)
      }
    }, [allDataboxes, props, touched, urlQuery])

    const debounceFetcher = useCallback(
      (query: string) => {
        if (debounceTimeout) {
          clearTimeout(debounceTimeout)
        }

        const newTimeout = setTimeout(
          () => {
            const first = query.length < 5 ? 10 : 5
            let filter: Filter = {
              fullname: { includesInsensitive: query },
            }

            if (query.length === 7) {
              filter = {
                or: [
                  { databoxid: { equalTo: query } },
                  { fullname: { includesInsensitive: query } },
                ],
              }
            }

            if (query.length === 8) {
              filter = /^\d+$/.test(query)
                ? { ico: { equalTo: query } }
                : { fullname: { includesInsensitive: query } }
            }

            setVariables({ filter, first })
          },
          query.length > 4 ? 800 : 0
        )

        setDebounceTimeout(newTimeout)
      },
      [debounceTimeout]
    )

    const handleSelect = useCallback(
      (item: { value: string }) => {
        props.setDataBoxId(item.value)
        !touched && setTouched(true)
      },
      [props, touched]
    )

    return (
      <Select
        showSearch
        allowClear
        loading
        autoFocus
        placeholder="Vyhledávejte podle názvu, IČ nebo datové schránky"
        filterOption={false}
        value={initialValue}
        onSearch={debounceFetcher}
        notFoundContent={
          databoxResponse.fetching ? <Spin size="small" /> : undefined
        }
        labelInValue
        onSelect={handleSelect}
        options={options}
        size="large"
        className="w-full"
      />
    )
  }
