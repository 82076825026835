import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { HeritageMonitoringRecord } from '../../../common/heritageMonitoringTypes'
import { Rizeni } from '../../../common/proceedingTypes'
import {
  participantColumns,
  propertyCountColumn,
} from '../../utils/heritageMonitoringHelpers'
import { parseProceedingString } from '../../utils/proceedingDetailHelpers'
import { useUserActivityLogCheck } from '../../utils/useCheckUserActivityLog'
import { seenRowClassName } from '../../utils/userActivityLogHelpers'
import { Day } from '../DateFormat/DateFormat'
import { ProceedingDetailModalLink } from '../ProceedingDetailModalLink/ProceedingDetailModalLink'
import { UserActivityLogBadge } from '../UserActivityLogBadge/UserActivityLogBadge'

export interface HeritageMonitoringTableProps {
  data?: HeritageMonitoringRecord[] | null
}

const tableId = 'heritage-monitoring'
const scrollX = { x: 1200 }

const cisloRizeniRenderer = (
  cisloRizeni: string,
  record: HeritageMonitoringRecord
) => (
  <ProceedingDetailModalLink
    proceeding={
      parseProceedingString(cisloRizeni + '-' + record.pracoviste.kod) as Rizeni
    }
  >
    {cisloRizeni}
  </ProceedingDetailModalLink>
)

export const HeritageMonitoringTable: FunctionComponent<HeritageMonitoringTableProps> =
  ({ data }) => {
    const propertyIds = useMemo(
      () =>
        data?.map(item => item.cisloRizeni + '-' + item.pracoviste.kod) || [],
      [data]
    )
    const userActivityLogs = useUserActivityLogCheck(propertyIds)

    const columns: ColumnsType<HeritageMonitoringRecord> = useMemo(
      () => [
        {
          title: (
            <span className="text-gray-400">
              Základní informace o dědickém řízení
            </span>
          ),
          className: 'border-r border-gray-300',
          children: [
            {
              title: 'Číslo řízení',
              width: 105,
              dataIndex: 'cisloRizeni',
              sorter: (a, b) => a.cisloRizeni.localeCompare(b.cisloRizeni),
              render: cisloRizeniRenderer,
            },
            {
              title: 'Katastrální pracoviště',
              dataIndex: ['pracoviste', 'nazev'],
              ellipsis: true,
              sorter: (a, b) =>
                a.pracoviste.nazev.localeCompare(b.pracoviste.nazev),
            },
            {
              title: 'Přijetí',
              dataIndex: 'datumPrijeti',
              width: 90,
              render: (datumPrijeti: string) => <Day>{datumPrijeti}</Day>,
              sorter: (a, b) => b.datumPrijeti.localeCompare(a.datumPrijeti),
            },
            {
              title: 'Provedení',
              dataIndex: 'datumProvedeni',
              width: 90,
              render: (datumProvedeni: string) => <Day>{datumProvedeni}</Day>,
              sorter: (a, b) =>
                b.datumProvedeni.localeCompare(a.datumProvedeni),
            },
            {
              title: 'Další předměty',
              dataIndex: 'predmety',
              className: 'max-w-[150px]',
              render: (predmety: string[]) =>
                predmety
                  .filter(predmet => predmet !== 'Vlastnictví')
                  .join(', '),
              sorter: (a, b) =>
                a.predmety.join().localeCompare(b.predmety.join()),
            },
          ],
        },
        {
          title: <span className="text-gray-400">Osoby v dědictví</span>,
          className: 'border-r border-gray-300',
          children: [participantColumns.prevodce, participantColumns.nabyvatel],
        },
        {
          title: <span className="text-gray-400">Ostatní účastníci</span>,
          className: 'border-r border-gray-300',
          children: [
            participantColumns.ostatniTyp,
            participantColumns.ostatniJmeno,
          ],
        },
        {
          title: (
            <span className="text-gray-400">Počet nemovitostí v říz.</span>
          ),
          className: 'border-r border-gray-300',
          children: [
            propertyCountColumn[PROPERTY_TYPES.BUILDING],
            propertyCountColumn[PROPERTY_TYPES.UNIT],
            propertyCountColumn[PROPERTY_TYPES.AREA],
          ],
        },
        {
          className: '!border-0',
          children: [
            {
              title: 'Info',
              dataIndex: ['pracoviste', 'kod'],
              key: 'info',
              width: 110,
              render: (kod, record) => (
                <UserActivityLogBadge
                  userActivityLog={
                    userActivityLogs?.[record.cisloRizeni + '-' + kod]
                  }
                  alertDateTo={record.datumProvedeni}
                />
              ),
            },
          ],
        },
      ],
      [userActivityLogs]
    )

    const rowClassName = useCallback(
      (record: HeritageMonitoringRecord) =>
        seenRowClassName(
          userActivityLogs?.[record.cisloRizeni + '-' + record.pracoviste.kod]
            ?.editedAt,
          record.datumProvedeni
        ),
      [userActivityLogs]
    )

    return (
      <Table
        rowKey="cisloRizeni"
        rowClassName={rowClassName}
        size="small"
        id={tableId}
        columns={columns}
        dataSource={data || []}
        pagination={false}
        bordered
        scroll={scrollX}
      />
    )
  }
