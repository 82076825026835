import React, { FunctionComponent } from 'react'
import { OperationContext } from 'urql'

import { ColorPalleteKey } from '../../utils/collorPallete'
import { IconPalleteKey } from '../../utils/iconPallete'
import { PropertyDetailNote } from '../../utils/propertyDetailNoteHelpers'
import { Min } from '../DateFormat/DateFormat'
import { NoteIcon } from '../NoteIcon/NoteIcon'
import { PropertyDetailNoteFooter } from '../PropertyDetailNoteFooter/PropertyDetailNoteFooter'
import { PropertyDetailNoteLinks } from '../PropertyDetailNoteLinks/PropertyDetailNoteLinks'

export interface PropertyDetailNoteTimelineProps {
  refetch: (opts?: Partial<OperationContext> | undefined) => void
  notes: PropertyDetailNote[]
  target?: React.HTMLAttributeAnchorTarget
}

export const PropertyDetailNoteTimeline: FunctionComponent<PropertyDetailNoteTimelineProps> =
  props => {
    return (
      <>
        {props.notes.map((note: PropertyDetailNote, index: number) => (
          <div key={note.id} className="flex">
            <div className="relative text-right pr-8 w-32 md:w-1/5">
              <Min className="block">{note.createdAt}</Min>
              <div className="absolute -right-8 -top-1 w-16 flex justify-center bg-white">
                <NoteIcon
                  colorKey={note.colorKey as ColorPalleteKey}
                  iconKey={note.iconKey as IconPalleteKey}
                />
              </div>
            </div>

            <div
              className={`pl-8 pb-14 border-gray-300 border-l w-full ${
                props.notes.length === index + 1
                  ? 'border-dashed'
                  : 'border-solid'
              }`}
            >
              {/* <div className="font-semibold text-base leading-5 mb-1">TODO: Jméno autora</div> */}
              <div className="whitespace-pre-line text-justify">
                {note.note}
              </div>
              <PropertyDetailNoteLinks links={note.links} />
              <PropertyDetailNoteFooter
                note={note}
                refetch={props.refetch}
                target={props.target}
              />
            </div>
          </div>
        ))}
      </>
    )
  }
