import axios from 'axios'
import { useEffect, useState } from 'react'

import { DrmBudovaResponse, PROPERTY_TYPES } from '../../common/drmTypes'
import { PropertyLabelInputData } from '../../common/propertyDetailTypes'
import { useAppSelector } from '../../redux/hooks'
import { requestPropertyDrmPath } from './paths'
import { getPropertyDetailTitleById } from './usePropertyDetailTitle'

export const usePropertyDataForNewLabel = (
  propertyId: string,
  propertyType: PROPERTY_TYPES,
  skip?: boolean
) => {
  const { token, userId, tenantId } = useAppSelector(state => state.myProfile)

  const [propertyData, setPropertyData] = useState<
    PropertyLabelInputData | undefined
  >()

  useEffect(() => {
    const getPropertyData = async () => {
      try {
        const propertyTitle = await getPropertyDetailTitleById(
          propertyId,
          propertyType,
          token
        )
        const response = await axios.get(
          requestPropertyDrmPath(propertyType, token, propertyId)
        )
        const data: DrmBudovaResponse = response.data

        setPropertyData({
          propertyType,
          propertyId,
          propertyCadastralCode: data.katastralniUzemi?.kod?.toString(),
          propertyCadastralName: data.katastralniUzemi?.nazev,
          propertyLv: data.lv?.toString(),
          propertyName: propertyTitle || 'Detail nemovitosti4',
          accountId: tenantId,
          accountUserId: userId,
        })
      } catch (error) {
        return
      }
    }

    if (!skip && !propertyData) {
      getPropertyData()
    }
  }, [propertyData, propertyId, propertyType, skip, tenantId, token, userId])

  return propertyData
}
