import { Tooltip } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'
import { Link } from 'react-router-dom'

import {
  JPV_LISTINA,
  JPV_PRO_OS,
  MaybeArray,
  RizeniType,
  SEKCE_LIST,
} from '../../../common/cuzkLvTypes'
import { notEmpty, safeJoin, safeMap } from '../../../common/pdfHelpers'
import { cuzkDocumentCollectionFetchPath } from '../../utils/paths'
import { CuzkLvHtmlBezZapisuFragment } from '../CuzkLvHtmlBezZapisuFragment/CuzkLvHtmlBezZapisuFragment'
import { CuzkLvHtmlOsNemIdentFragment } from '../CuzkLvHtmlOsNemIdentFragment/CuzkLvHtmlOsNemIdentFragment'
import { CuzkLvHtmlPopisPravnihoVztahuFragment } from '../CuzkLvHtmlPopisPravnihoVztahuFragment/CuzkLvHtmlPopisPravnihoVztahuFragment'
import { CuzkLvHtmlRizeniFragment } from '../CuzkLvHtmlRizeniFragment/CuzkLvHtmlRizeniFragment'

export interface CuzkLvHtmlSekceListFragmentProps {
  sekceList?: SEKCE_LIST[] | []
  active?: boolean
  title: string
}

export const CuzkLvHtmlSekceListFragment: FunctionComponent<CuzkLvHtmlSekceListFragmentProps> =
  props => {
    const SEKCE_LIST = props.sekceList
    const isEmpty = !notEmpty(SEKCE_LIST?.[0]?.JPV_LIST?.JPV)

    const rizeniPath = useCallback(
      (listina: RizeniType) =>
        listina.typriz_kod +
        '-' +
        listina.poradove_cislo +
        '-' +
        listina.rok +
        '-' +
        listina.prares_kod,
      []
    )

    const listinaDownload = useCallback(
      (listina: JPV_LISTINA) => {
        const listinaTitle = [
          listina.NAZEV_LIST?.tlist,
          listina.NAZEV_LIST?.dalsi_udaje,
          listina.NAZEV_LIST?.por_cis_zhot,
          listina.NAZEV_LIST?.popis,
          listina.NAZEV_LIST?.vystav_txt,
          listina.NAZEV_LIST?.prav_moc_txt,
          listina.NAZEV_LIST?.podani_txt,
          listina.NAZEV_LIST?.vykonatelnost_txt,
          listina.NAZEV_LIST?.podani_z_txt,
          listina.NAZEV_LIST?.zplatneni_txt,
          listina.NAZEV_LIST?.prares_nazev,
        ]
          .filter(Boolean)
          .join(' ')

        if (props.active)
          return (
            <Tooltip title="Stáhnout listinu v novém okně">
              <Link
                target="_blank"
                to={`${cuzkDocumentCollectionFetchPath(
                  rizeniPath(listina.IDENT_RIZENI),
                  listina?.LISTIN_ID?.toString() || ''
                )}`}
              >
                {listinaTitle}
              </Link>
            </Tooltip>
          )
        return listinaTitle
      },
      [props.active, rizeniPath]
    )

    const getIdent = (item: JPV_PRO_OS, key: keyof JPV_PRO_OS): string => {
      const value = item[key]
      if (Array.isArray(value)) {
        return value.join('|')
      }
      return (value as string) || ''
    }

    const removeDuplicates = (
      arr: MaybeArray<JPV_PRO_OS>,
      key: keyof JPV_PRO_OS
    ): JPV_PRO_OS[] => {
      const seen = new Set()
      const normalizedArray = Array.isArray(arr) ? arr : arr ? [arr] : []
      return normalizedArray.filter(item => {
        const identifier = getIdent(item, key)
        if (!seen.has(identifier)) {
          seen.add(identifier)
          return true
        }
        return false
      })
    }

    return (
      <>
        <div className="row">
          <div className="col-xs-12">
            {props.title}
            {isEmpty && <CuzkLvHtmlBezZapisuFragment />}
          </div>
        </div>
        {!isEmpty && (
          <>
            <div className="row">
              &nbsp;&nbsp;<i>Typ vztahu</i>
            </div>
            <hr className="mt-0 mb-0" />
            {safeMap(SEKCE_LIST, (sekce, index) => (
              <div className="row" key={index}>
                <ul className="pl-80 circleList">
                  {safeMap(sekce?.JPV_LIST?.JPV, (JPV, index) => (
                    <div key={index}>
                      <i>{JPV.NADRIZ_HJPV_ID && 'Související zápisy'}</i>
                      <li
                        className={
                          JPV.NADRIZ_HJPV_ID
                            ? 'noListStyle pl-50 col-xs-12 mb-50'
                            : 'col-xs-12 mb-50'
                        }
                      >
                        <strong>{JPV.TYP_PRAVNIHO_VZTAHU}</strong>
                        <br />
                        {safeMap(JPV.JPV_POPISY?.JPV_POPIS, (popis, index) => (
                          <div className="col-xs-12 bold pl-50" key={index}>
                            <CuzkLvHtmlPopisPravnihoVztahuFragment
                              popis={popis.POPIS_PRAVNIHO_VZTAHU}
                            />
                            {safeMap(
                              popis.JPV_RIZENI?.JPV_RIZ,
                              (rizeni, index) => (
                                <div key={index}>
                                  <div className="text-right">
                                    <CuzkLvHtmlRizeniFragment
                                      rizeni={rizeni.IDENT_RIZENI}
                                      active={props.active}
                                    />
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        ))}

                        {(notEmpty(JPV.JPV_OPRAV_PRO_NEM?.JPV_PRO_NEM) ||
                          notEmpty(JPV.JPV_OPRAV_PRO_OS?.JPV_PRO_OS)) && (
                          <div className="row">
                            <div className="col-xs-12 pl-100 noBreakAfter">
                              <i>Oprávnění pro</i>
                            </div>
                            <div className="row">
                              <div
                                className="col-xs-12 bold pl-150"
                                key={index}
                              >
                                {safeMap(
                                  JPV.JPV_OPRAV_PRO_NEM?.JPV_PRO_NEM,
                                  (povinnost, index, array) => (
                                    <span key={index}>
                                      <CuzkLvHtmlOsNemIdentFragment
                                        active={props.active}
                                        osNemIdent={povinnost.PRO_IDENT_NEM}
                                      />
                                      {index < array.length - 1 && ', '}
                                    </span>
                                  )
                                )}
                                {safeMap(
                                  removeDuplicates(
                                    JPV.JPV_OPRAV_PRO_OS?.JPV_PRO_OS || [],
                                    'PRO_IDENT_OS.oprav_subjekt.id' as keyof JPV_PRO_OS
                                  ),
                                  (povinnost, index) => (
                                    <div key={index}>
                                      <CuzkLvHtmlOsNemIdentFragment
                                        active={props.active}
                                        osNemIdent={povinnost.PRO_IDENT_OS}
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {(notEmpty(JPV.JPV_POVIN_K_NEM?.JPV_K_NEM) ||
                          notEmpty(JPV.JPV_POVIN_K_OS?.JPV_K_OS)) && (
                          <div className="row">
                            <div className="col-xs-12 pl-100 noBreakAfter">
                              <i>Povinnost k</i>
                            </div>
                            <div className="col-xs-12 bold pl-150">
                              {safeMap(
                                JPV.JPV_POVIN_K_NEM?.JPV_K_NEM,
                                (povinnost, index, array) => (
                                  <span key={index}>
                                    {safeMap(
                                      povinnost?.K_IDENT_NEM,
                                      (ident, index, array) => (
                                        <React.Fragment key={index}>
                                          <CuzkLvHtmlOsNemIdentFragment
                                            active={props.active}
                                            key={index}
                                            osNemIdent={ident}
                                          />
                                          {index < array.length - 1 && ', '}
                                        </React.Fragment>
                                      )
                                    )}
                                    {index < array.length - 1 && ', '}
                                  </span>
                                )
                              )}
                              {safeMap(
                                JPV.JPV_POVIN_K_OS?.JPV_K_OS,
                                (povinnost, index, array) => (
                                  <span key={index}>
                                    {safeMap(
                                      povinnost?.K_IDENT_OS,
                                      (ident, index, array) => (
                                        <div key={index}>
                                          <CuzkLvHtmlOsNemIdentFragment
                                            active={props.active}
                                            osNemIdent={ident}
                                          />
                                          {index < array.length - 1 && ', '}
                                        </div>
                                      )
                                    )}
                                    {index < array.length - 1 && ', '}
                                  </span>
                                )
                              )}
                            </div>
                          </div>
                        )}

                        {safeMap(
                          JPV?.JPV_LISTINY?.JPV_LISTINA,
                          (listiny, index) => (
                            <div key={index} className="row pl-50 mt-30 ">
                              <div className="mr-40 noBreakAfter">
                                <i>Listina</i>
                              </div>
                              <div key={index} className="row flexWrap">
                                <div className="bold">
                                  {listinaDownload(listiny)}{' '}
                                </div>
                                <div className="row bold">
                                  <div className="col-xs-5 col"></div>
                                  <div className="col-xs-3 bold">
                                    {listiny?.POPIS_LIST as string}
                                  </div>
                                  <div className="col-xs-4 bold">
                                    <CuzkLvHtmlRizeniFragment
                                      rizeni={listiny.IDENT_RIZENI}
                                      active={props.active}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}

                        {JPV?.PORADI_K?.datum?.length ||
                        JPV?.PORADI_K?.text_o_prednostnim_poradi?.length ? (
                          <div key={index} className="row flexWrap">
                            <div className="mr-40 noShrink noBreakAfter">
                              <i>Pořadí k</i>
                            </div>
                            <div className="bold">
                              {safeJoin(JPV?.PORADI_K?.datum, ', ')}{' '}
                              {safeJoin(
                                JPV?.PORADI_K?.text_o_prednostnim_poradi,
                                ', '
                              )}
                            </div>
                          </div>
                        ) : null}
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
            ))}
          </>
        )}
      </>
    )
  }
