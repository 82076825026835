import JSZip from 'jszip'
import Papa from 'papaparse'
import { useEffect, useState } from 'react'
import * as windows1250 from 'windows-1250'

async function fetchCsvData<T>(url: string): Promise<T[]> {
  try {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const arrayBuffer = await response.arrayBuffer()
    const csvText = await unzipCsv(arrayBuffer)
    return await parseCsv<T>(csvText)
  } catch (error) {
    console.error('Failed to fetch and parse CSV data:', error)
    throw error
  }

  async function unzipCsv(arrayBuffer: ArrayBuffer): Promise<string> {
    const jsZip = new JSZip()
    const zip = await jsZip.loadAsync(arrayBuffer)
    const filenames = Object.keys(zip.files)
    const [firstFilename] = filenames
    const csvBuffer = await zip.files[firstFilename].async('arraybuffer')

    const uint8Array = new Uint8Array(csvBuffer)
    return windows1250.decode(uint8Array)
  }

  async function parseCsv<T>(csvText: string): Promise<T[]> {
    const results = Papa.parse<T>(csvText, {
      header: true,
      delimiter: ';',
      skipEmptyLines: true,
      dynamicTyping: true,
    })
    if (results.errors.length > 0) {
      throw new Error(
        'Errors parsing CSV: ' + results.errors.map(e => e.message).join('; ')
      )
    }
    return results.data
  }
}

export function useCsvZip<T>(url: string): [boolean, T[]] {
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<T[]>([])

  useEffect(() => {
    fetch()
    async function fetch() {
      try {
        setLoading(true)
        setResult(await fetchCsvData<T>(url))
      } catch (error) {
        console.error(error)
        setResult([])
      } finally {
        setLoading(false)
      }
    }
  }, [url])

  return [loading, result]
}
