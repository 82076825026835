import React, { FunctionComponent, memo } from 'react'

import { KatastralniPracoviste } from '../../../common/ruianTypes'
import { LvDetailSidebarLvWorkplace } from '../LvDetailSidebarLvWorkplace/LvDetailSidebarLvWorkplace'
import { PriceEstimateLayoutLvDataRow as Item } from '../PriceEstimateLayoutLvDataRow/PriceEstimateLayoutLvDataRow'
import { PropertyDetailLvButton } from '../PropertyDetailLvButton/PropertyDetailLvButton'
import { PropertyDetailSidebarContainer } from '../PropertyDetailSidebarContainer/PropertyDetailSidebarContainer'

export interface LvDetailSidebarLvProps {
  isDrawer?: boolean
  lvNumber?: number | string
  cadastralArea?: KatastralniPracoviste
  cadastralWorkplace?: KatastralniPracoviste
}

export const LvDetailSidebarLv: FunctionComponent<LvDetailSidebarLvProps> =
  memo(props => {
    return (
      <PropertyDetailSidebarContainer isDrawer={props.isDrawer}>
        <div className="flex flex-col space-y-2 animate-fadeIn">
          <Item title="List vlastnictví">
            <div className="flex justify-between">
              {props.lvNumber}
              <PropertyDetailLvButton
                lvCode={props.cadastralArea?.kod}
                lvNumber={props.lvNumber}
                linkStyle
              />
            </div>
          </Item>

          <Item title="Katastralní území">
            {props.cadastralArea?.nazev} ({props.cadastralArea?.kod})
          </Item>

          <Item title="Katastralní pracoviště">
            <LvDetailSidebarLvWorkplace
              cadastralAreaCode={props.cadastralArea?.kod}
            />
          </Item>
        </div>
      </PropertyDetailSidebarContainer>
    )
  })
