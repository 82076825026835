import React, { FunctionComponent } from 'react'

import { VlastnickePravo } from '../../../common/lvTypes'
import { AddressLink } from '../AddressLink/AddressLink'
import { Money } from '../Money/Money'
import { SubjectLink } from '../SubjectLink/SubjectLink'

export interface PropertyDetailOwnershipRightsChartSelectedBoxProps {
  subject: VlastnickePravo
  share: number
}

export const PropertyDetailOwnershipRightsChartSelectedBox: FunctionComponent<PropertyDetailOwnershipRightsChartSelectedBoxProps> =
  props => {
    return (
      <div className="text-left flex items-center">
        <Money className="font-bold mr-2" amount={props.share} symbol="%" />
        <div>
          <div className="table-row">
            <SubjectLink
              subjectId={props.subject.subjekt?.id}
              className="table-cell pl-2"
            >
              {props.subject.subjekt?.jmeno}
            </SubjectLink>
            <span className="table-cell px-2">-</span>
            <AddressLink
              className="table-cell"
              addressId={props.subject.subjekt?.adresniMistoKod}
            >
              {props.subject.subjekt?.adresa}
            </AddressLink>
          </div>
          {props.subject.sjmPartner && (
            <div className="table-row">
              <SubjectLink
                subjectId={props.subject.sjmPartner?.id}
                className="table-cell pl-2"
              >
                {props.subject.sjmPartner?.jmeno}
              </SubjectLink>
              <span className="table-cell px-2">-</span>
              <AddressLink
                className="table-cell"
                addressId={props.subject.sjmPartner?.adresniMistoKod}
              >
                {props.subject.sjmPartner?.adresa}
              </AddressLink>
            </div>
          )}
        </div>
      </div>
    )
  }
