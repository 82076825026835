import 'mapbox-gl/dist/mapbox-gl.css'

import * as turf from '@turf/turf'
import { Feature, Geometry, Point } from 'geojson'
import React, {
  CSSProperties,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import Map, {
  FullscreenControl,
  GeoJSONSource,
  GeolocateControl,
  Layer,
  LayerProps,
  MapProps,
  MapRef,
  Source,
} from 'react-map-gl'

import { CadastreMapDebtFilterFormOutput } from '../../../common/cadastreMapDebtTypes'
import { CadastreMapInsolvencyFilterFormOutput } from '../../../common/cadastreMapInsolvencyTypes'
import { useAppSelector } from '../../../redux/hooks'
import { useConst } from '../../utils/useConst'
import { CadastreMapAuctionDisplay } from '../CadastreMapAuctionDisplay/CadastreMapAuctionDisplay'
import { GeocoderControl } from '../CadastreMapGeocoderControl/CadastreMapGeocoderControl'
import { CadastreMapAdministrationDisplay } from './CadastreMapAdministrationDisplay'
import { CadastreMapBaseParcelRasterDisplay } from './CadastreMapBaseParcelRasterDisplay'
import { CadastreMapBuildingLayers } from './CadastreMapBuildingLayers'
import { CadastreMapDebtLayers } from './CadastreMapDebtLayers'
import { CadastreMapDebtSource } from './CadastreMapDebtSource'
import { CadastreMapDetailDrawer } from './CadastreMapDetailDrawer'
import { CadastreMapInsolvencyLayers } from './CadastreMapInsolvencyLayers'
import {
  CadastreMapInsolvencySource,
  DrmMonitorItemDetail,
} from './CadastreMapInsolvencySource'
import { CadastreMapParcelLayers } from './CadastreMapParcelLayers'
import { CadastreMapRadonDisplay } from './CadastreMapRadonDisplay'
import { CadastreMapRuianArcGisSource } from './CadastreMapRuianArcGisSource'
import { CadastreMapSelectedFeatureDisplay } from './CadastreMapSelectedFeatureDisplay'
import { CadastreMapSpin } from './CadastreMapSpin'
import { CadastreMapBasemapControl } from './controlls/CadastreMapBasemapControl/CadastreMapBasemapControl'
import { CadastreMapBuildingLayerControl } from './controlls/CadastreMapBuildingLayerControl/CadastreMapBuildingLayerControl'
import { CadastreMapDebtLayerControl } from './controlls/CadastreMapDebtLayerControl/CadastreMapDebtLayerControl'
import { CadastreMapInsolvencyLayerControl } from './controlls/CadastreMapInsolvencyLayerControl/CadastreMapInsolvencyLayerControl'
import { CadastreMapParcelLayerControl } from './controlls/CadastreMapParcelLayerControl/CadastreMapParcelLayerControl'
import { useBuildingPoints } from './hooks/useBuildingPoints'
import { useParcelPoints } from './hooks/useParcelPoints'
import { RuianLayer } from './types/RuianLayers'
import { addIcons } from './util/icons'
import { RuianQuery } from './util/RuianQuery'

export interface CadastreMapProps {
  mapboxAccessToken: string

  showCadastre?: boolean
  showDebt?: boolean
  showInsolvency?: boolean
  showAuctions?: boolean

  longitude?: number
  latitude?: number
  zoom?: number

  onFeaturesSelection?: (
    features: Feature<
      Geometry,
      { objectid: string; layerId: string; layerName: string }
    >[]
  ) => void
}

export const CadastreMap: FunctionComponent<CadastreMapProps> = props => {
  const mapRef = useRef<MapRef>(null)
  const mapRefCallback = useCallback((map: MapRef | null) => {
    if (map === null) return
    // @ts-expect-error This is soonest we can get the map object
    mapRef.current = map
    addIcons(map, 24, [
      { name: 'default-budova', path: 'fluent/building-32-filled' },
      { name: 'default-parcela', path: 'mdi/land-plots-marker' },
      { name: 'default-jednotka', path: 'bi/building-fill-add' },
      { name: 'garaz', path: 'game-icons/home-garage' },
      { name: 'chata', path: 'fa6-solid/house-flood-water' },
      { name: 'zahrada', path: 'material-symbols/yard' },
      { name: 'pole', path: 'mdi/tractor' },
      { name: 'louka', path: 'carbon/soil-moisture-field' },
      { name: 'lesy', path: 'ic/round-forest' },
      { name: 'rybnik', path: 'ic/sharp-water' },
      { name: 'sady', path: 'uil/trees' },
      { name: 'notdef', path: 'fa-solid/question' },
    ])
  }, [])

  const [debtEnabled, setDebtEnabled] = useState(true)
  const [debtFilterParams, setDebtFilterParams] = useState<
    CadastreMapDebtFilterFormOutput | undefined
  >(undefined)

  const [insolvencyEnabled, setInsolvencyEnabled] = useState(true)
  const [insolvencyFilterParams, setInsolvencyFilterParams] = useState<
    CadastreMapInsolvencyFilterFormOutput | undefined
  >(undefined)

  const [parcelsEnabled, setParcelsEnabled] = useState(false)
  const [parcelsClustering, setParcelsClustering] = useState(false)
  const [parcelsWhere, setParcelsWhere] = useState<string>('1=0')

  const [buildingsEnabled, setBuildingsEnabled] = useState(false)
  const [buildingsClustering, setBuildingsClustering] = useState(false)
  const [buildingsWhere, setbuildingsWhere] = useState<string>('1=0')

  const [radonEnabled, setRadonEnabled] = useState(false)

  const [baseMapRaster, setBaseMapRaster] = useState<string>('')

  const [loadingParacelPoints, parcelPoints] = useParcelPoints(
    parcelsWhere,
    !!parcelsEnabled
  )
  const [loadingBuildingPoints, buildingPoints] = useBuildingPoints(
    buildingsWhere,
    !!buildingsEnabled
  )

  const [highlightedFeatures, setHighlightedFeatures] = useState(
    turf.featureCollection([])
  )

  const token = useAppSelector(state => state.myProfile.token)
  const { onFeaturesSelection } = props
  // TODO: Refactor into smaller functions, user either clicked on interactive layer or map or we are figuring out what was clicked using identify
  const onClick = useCallback<NonNullable<MapProps['onClick']>>(
    async event => {
      const { current: map } = mapRef
      if (!map) return

      // Interactive layer was clicked (icons, etc.)
      if (event?.features?.length) {
        // Due to how mapbox works there could be duplicate features, they have similar code in their official examples
        const features = event.features.reduce<
          Array<
            Feature<Point & { cluster_id: number | undefined }> & {
              layer: LayerProps
              source: string
            }
          >
        >((acc, feature) => {
          if (acc.some(f => f.id === feature.id)) return acc
          return [...acc, feature]
        }, [])
        if (!features.length) return

        const [clickedFeature] = features

        // Cluster was clicked
        if (clickedFeature.properties?.cluster_id) {
          const clusterId = clickedFeature.properties.cluster_id
          const sourceName = clickedFeature.source
          const source = map.getSource<GeoJSONSource>(sourceName)
          if (!source) return
          const clusterPoint = clickedFeature.geometry.coordinates as [
            number,
            number
          ]
          source.getClusterExpansionZoom(clusterId, (error, zoom) => {
            if (error || !zoom) return
            map.easeTo({ center: clusterPoint, zoom })
          })
          return
        } else {
          // Special case
          if (clickedFeature.layer.id === 'debt-circle') {
            const drmFeature = clickedFeature.properties as DrmMonitorItemDetail
            if (drmFeature.typ !== 'Jednotka') return
            const drmUnitId = drmFeature.id
            const { unitId, buildingId } = await fetch(
              `https://data.regesta.cz/DrmApi/api/v1.0/Nemovitosti/Jednotka/${drmUnitId}/Ruian?access_token=${token}`
            )
              .then(x => x.json())
              .then(data => ({
                unitId: data.cisloJednotky,
                buildingId: data.vBudove.isknBudovaId,
              }))
              .catch(() => ({ unitId: null, buildingId: null }))
            if (!unitId || !buildingId) return
            // disabled window.open until the browser plugin is functional
            const url = new URL(
              'https://nahlizenidokn.cuzk.cz/ZobrazObjekt.aspx'
            )
            url.search = new URLSearchParams({
              typ: 'budova',
              id: buildingId,
              otevritJednotku: unitId,
            }).toString()
            // const drawerWidth = Math.min(500, window.innerWidth * 0.75)
            // window.open(
            //   url,
            //   'oknonahlizeni',
            //   `width=${drawerWidth},height=${window.outerHeight},left=${
            //     window.innerWidth - drawerWidth
            //   },top=0`
            // )
          } /* Point was clicked */ else {
            map.easeTo({
              center: clickedFeature.geometry.coordinates as [number, number],
              zoom: 18,
            })
            return
          }
        }
        return
      }

      // Map was clicked
      const point = event.lngLat.toArray() as [number, number]

      if (highlightedFeatures.features.length > 0) {
        setHighlightedFeatures(turf.featureCollection([]))
        return
      }

      if (map.getZoom() < 16) return

      const bounds = map.getBounds()
      if (!bounds) return
      const [[west, south], [east, north]] = bounds.toArray()
      const { width, height } = map.getCanvas()
      const identifiedFeatures = await RuianQuery.identify(
        [RuianLayer.STAVEBNI_OBJEKT, RuianLayer.PARCELA],
        [west, south, east, north],
        point,
        [width, height]
      )

      const [firstFeature] = identifiedFeatures.features

      if (firstFeature && firstFeature.properties?.layerId) {
        const url = new URL('https://nahlizenidokn.cuzk.cz/ZobrazObjekt.aspx')
        switch (firstFeature.properties.layerId) {
          case RuianLayer.PARCELA:
            url.searchParams.set('typ', 'parcela')
            url.searchParams.set('id', firstFeature.properties.id)
            break
          case RuianLayer.STAVEBNI_OBJEKT:
            url.searchParams.set('typ', 'budova')
            url.searchParams.set('id', firstFeature.properties.isknbudovaid)
            break
        }
        // disabled window.open until the browser plugin is functional
        /*
        const drawerWidth = Math.min(500, window.innerWidth * 0.75)
        const leftPosition = window.innerWidth - drawerWidth - 800
        window.open(
          url,
          'oknonahlizeni',
          `width=800,height=${window.outerHeight},left=${leftPosition},top=0`
        )
        */
      }

      setHighlightedFeatures(identifiedFeatures)
      onFeaturesSelection?.(
        identifiedFeatures.features as Feature<
          Geometry,
          {
            objectid: string
            layerId: string
            layerName: string
          }
        >[]
      )
    },
    [highlightedFeatures, onFeaturesSelection, token]
  )

  const initialViewState = useConst({
    longitude: props?.longitude ?? 15.440327473659693,
    latitude: props?.latitude ?? 49.82376171768291,
    zoom: props?.zoom ?? 7.36778985715334,
    bearing: 0,
    pitch: 0,
  })

  useEffect(() => {
    if (!mapRef.current) return
    if (!props.longitude || !props.latitude) return
    mapRef.current?.flyTo({
      center: [props.longitude, props.latitude],
      zoom: props.zoom,
    })
  }, [props.longitude, props.latitude, props.zoom])

  const cssStyle = useConst<CSSProperties>({
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  })

  const interactiveLayerIds = useConst([
    'debt-circle',
    'auctions-cluster-circle',
    'auctions-non-clustered-point',
  ])

  return (
    <Map
      id="cadastreMap"
      initialViewState={initialViewState}
      style={cssStyle}
      pitchWithRotate={false}
      maxZoom={21}
      minZoom={7.5}
      touchZoomRotate={false}
      dragRotate={false}
      mapStyle="mapbox://styles/kolpav/clyopvz7f001w01ph85m10qns"
      mapboxAccessToken={props.mapboxAccessToken}
      onClick={onClick}
      ref={mapRefCallback}
      refreshExpiredTiles={false}
      cursor="pointer"
      interactiveLayerIds={interactiveLayerIds}
    >
      <Source id="parcel-points" data={parcelPoints} type="geojson" />
      <Source
        id="parcel-points-clustered"
        data={parcelPoints}
        type="geojson"
        cluster={true}
        clusterMaxZoom={14}
        clusterRadius={50}
      />
      <Source id="building-points" data={buildingPoints} type="geojson" />
      <Source
        id="building-points-clustered"
        data={buildingPoints}
        type="geojson"
        cluster={true}
        clusterMaxZoom={14}
        clusterRadius={50}
      />

      <CadastreMapRuianArcGisSource
        id="parcel-lines-unfiltered"
        ruianLayerId={RuianLayer.PARCELA}
        useStaticZoomLevel={false}
        minZoom={15}
      />
      <CadastreMapRuianArcGisSource
        id="building-lines-unfiltered"
        ruianLayerId={RuianLayer.STAVEBNI_OBJEKT}
        useStaticZoomLevel={false}
        minZoom={15}
      />

      <CadastreMapRuianArcGisSource
        id="parcel-fill-filtered"
        ruianLayerId={RuianLayer.PARCELA}
        where={parcelsEnabled ? parcelsWhere : '1=0'}
        useStaticZoomLevel={false}
        minZoom={15}
      />
      <CadastreMapRuianArcGisSource
        id="building-fill-filtered"
        ruianLayerId={RuianLayer.STAVEBNI_OBJEKT}
        where={buildingsEnabled ? buildingsWhere : '1=0'}
        useStaticZoomLevel={false}
        minZoom={15}
      />

      {props.showDebt && (
        <CadastreMapDebtSource id="debt" filterParams={debtFilterParams} />
      )}

      {props.showInsolvency && (
        <CadastreMapInsolvencySource
          id="insolvency"
          filterParams={insolvencyFilterParams}
        />
      )}

      <Source
        id="selected-features"
        type="geojson"
        data={highlightedFeatures}
      />

      {baseMapRaster && (
        <Source
          id="basemap-raster"
          type="raster"
          tiles={[baseMapRaster]}
          tileSize={256}
          attribution='&copy; <a href="https://cuzk.cz">ČÚZK</a>'
          maxzoom={20}
        >
          <Layer
            type="raster"
            source="basemap-raster"
            beforeId="road-path-trail"
            minzoom={0}
            maxzoom={22}
            paint={{
              'raster-brightness-min': 0,
              'raster-brightness-max': 0.8,
              'raster-contrast': 0.2,
              'raster-saturation': -0.2,
            }}
          />
        </Source>
      )}

      <CadastreMapBaseParcelRasterDisplay
        mode={baseMapRaster ? 'light' : 'dark'}
      />

      {radonEnabled && <CadastreMapRadonDisplay />}

      <CadastreMapAdministrationDisplay />

      <CadastreMapParcelLayers
        clustering={parcelsClustering}
        enabled={parcelsEnabled}
        pointsSource="parcel-points"
        clusterSource="parcel-points-clustered"
        filteredSource="parcel-fill-filtered"
        unfilteredSource="parcel-lines-unfiltered"
      />

      <CadastreMapBuildingLayers
        clustering={buildingsClustering}
        enabled={buildingsEnabled}
        pointsSource="building-points"
        clusterSource="building-points-clustered"
        filteredSource="building-fill-filtered"
        unfilteredSource="building-lines-unfiltered"
      />
      <CadastreMapSelectedFeatureDisplay source="selected-features" />

      {props.showDebt && (
        <CadastreMapDebtLayers enabled={debtEnabled} source="debt" />
      )}

      {props.showInsolvency && (
        <CadastreMapInsolvencyLayers
          enabled={insolvencyEnabled}
          source="insolvency"
        />
      )}

      {props.showAuctions && (
        <CadastreMapAuctionDisplay initialViewState={initialViewState} />
      )}

      {props.showCadastre && (
        <>
          <CadastreMapParcelLayerControl
            initialViewState={initialViewState}
            onWhereChange={where => {
              setParcelsWhere(where)
              setParcelsEnabled(true)
            }}
            enabled={parcelsEnabled}
            onEnabledChange={setParcelsEnabled}
            cluster={parcelsClustering}
            onClusterChange={setParcelsClustering}
            loading={loadingParacelPoints}
          />
          <CadastreMapBuildingLayerControl
            initialViewState={initialViewState}
            onWhereChange={where => {
              setbuildingsWhere(where)
              setBuildingsEnabled(true)
            }}
            enabled={buildingsEnabled}
            onEnabledChange={setBuildingsEnabled}
            cluster={buildingsClustering}
            onClusterChange={setBuildingsClustering}
            loading={loadingBuildingPoints}
          />
        </>
      )}

      {props.showDebt && (
        <CadastreMapDebtLayerControl
          enabled={debtEnabled}
          onEnabledChange={enabled => setDebtEnabled(enabled)}
          onFilterParams={filterParams => {
            setDebtEnabled(true)
            setDebtFilterParams(filterParams)
          }}
        />
      )}

      {props.showInsolvency && (
        <CadastreMapInsolvencyLayerControl
          enabled={insolvencyEnabled}
          onEnabledChange={enabled => setInsolvencyEnabled(enabled)}
          onFilterParams={filterParams => {
            setInsolvencyEnabled(true)
            setInsolvencyFilterParams(filterParams)
          }}
        />
      )}

      <CadastreMapBasemapControl
        position="bottom-right"
        onRasterTileSource={source => {
          setBaseMapRaster(baseMapRaster === source ? '' : source)
        }}
        radonEnabled={radonEnabled}
        onRadonEnabledChange={enabled => setRadonEnabled(enabled)}
      />

      {(loadingBuildingPoints || loadingParacelPoints) && <CadastreMapSpin />}

      <CadastreMapDetailDrawer
        open={!!highlightedFeatures.features.length}
        onClose={() => setHighlightedFeatures(turf.featureCollection([]))}
        features={highlightedFeatures.features}
      />

      <GeocoderControl position="top-right" />
      <FullscreenControl position="bottom-left" />
      <GeolocateControl position="bottom-left" />
    </Map>
  )
}
