import { Form, FormItemProps, Select, Skeleton } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { useRuianField } from '../../hooks/useRuianField'
import { RuianLayer } from '../../types/RuianLayers'
import { RuianParcela } from '../../types/RuianParcela'
import { RuianParcelaDefinicniBod } from '../../types/RuianParcelaDefinicniBod'
import { RuianStavebnihoObjektuDefinicniBod } from '../../types/RuianStavebnihoObjektuDefinicniBod'
import { RuianStavebniObjekt } from '../../types/RuianStavebniObjekt'
import { InputNumberRange } from '../CadastreMapInputRange/CadastreMapInputRange'

export type RuianInputProps =
  | {
      ruianLayerId: RuianLayer.STAVEBNI_OBJEKT
      fieldName: keyof RuianStavebniObjekt
    }
  | {
      ruianLayerId: RuianLayer.STAVEBNIHO_OBJEKTU_DEFINICNI_BOD
      fieldName: keyof RuianStavebnihoObjektuDefinicniBod
    }
  | {
      ruianLayerId: RuianLayer.PARCELA
      fieldName: keyof RuianParcela
    }
  | {
      ruianLayerId: RuianLayer.PARCELA_DEFINICNI_BOD
      fieldName: keyof RuianParcelaDefinicniBod
    }

export const RuianInput: FunctionComponent<
  RuianInputProps & {
    formItemProps?: Partial<FormItemProps>
    forceComponentType?: string
    validValues?: (string | number)[] | null
  }
> = ({
  fieldName,
  ruianLayerId: layerId,
  formItemProps = {},
  forceComponentType,
  validValues,
}) => {
  const [fieldData, ruianComponentType] = useRuianField(layerId, fieldName)

  const componentType = forceComponentType || ruianComponentType

  const component = useMemo(() => {
    if (!componentType || !fieldData) return null
    switch (componentType) {
      case 'InputNumber':
        return <InputNumberRange />
      case 'Select':
      case 'MultiSelect':
        return (
          <Select
            mode={componentType === 'MultiSelect' ? 'multiple' : undefined}
            allowClear
            placeholder={fieldData.alias}
            options={fieldData.domain?.codedValues
              .filter(value =>
                layerId === RuianLayer.STAVEBNI_OBJEKT &&
                fieldName === 'zpusobvyuzitikod'
                  ? value.code < 21
                  : true
              )
              .map(value => ({
                label: value.name,
                value: value.code,
                disabled: validValues
                  ? !validValues.some(
                      v => v.toString() === value.code.toString()
                    )
                  : false,
              }))}
          />
        )
      case 'Input':
      case 'Checkbox':
      case 'InputDate':
      default:
        return <></>
    }
  }, [componentType, fieldData, layerId, fieldName, validValues])

  if (!componentType)
    return (
      <Form.Item
        label={
          <Skeleton.Input
            style={{
              height: '22px',
            }}
            block
            size="small"
          />
        }
      >
        <Skeleton.Input block size="small" />
      </Form.Item>
    )
  if (!fieldData) return <></>

  return (
    <Form.Item
      name={fieldData.name}
      label={fieldData.alias}
      getValueProps={value => value?.value}
      getValueFromEvent={value => ({
        value,
        field: fieldData,
        componentType,
      })}
      {...formItemProps}
    >
      {component}
    </Form.Item>
  )
}
