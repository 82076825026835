import React, { FunctionComponent, useState } from 'react'

import { DrmJednotkaResponse, PROPERTY_TYPES } from '../../../common/drmTypes'
import { LvApiResponse } from '../../../common/lvTypes'
import { MonitEstimateResponse } from '../../../common/monitTypes'
import { useAppSelector } from '../../../redux/hooks'
import { useMapImage } from '../../utils/useMapImage'
import {
  PriceEstimateForm,
  PriceEstimateFormValues,
} from '../PriceEstimateForm/PriceEstimateForm'
import { PriceEstimateHistory } from '../PriceEstimateHistory/PriceEstimateHistory'
import { PriceEstimateLayoutStateAlert } from '../PriceEstimateLayoutStateAlert/PriceEstimateLayoutStateAlert'
import { PriceEstimateResponse } from '../PriceEstimateResponse/PriceEstimateResponse'

export enum FETCH_ERROR_TYPES {
  RUIAN = 'ruian',
  LV = 'lv',
  DRM = 'drm',
}

export interface PropertyDetailPriceEstimateLayoutProps {
  initialValues?: PriceEstimateFormValues
  // TODO: fix any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lvData?: (LvApiResponse & DrmJednotkaResponse) | any
  propertyId: string
  propertyType?: PROPERTY_TYPES
  fetchError?: FETCH_ERROR_TYPES
}

export const PropertyDetailPriceEstimateLayout: FunctionComponent<PropertyDetailPriceEstimateLayoutProps> =
  props => {
    const risy = useAppSelector(state => state.property.risy.data)
    const [estimateResponse, setEstimateResponse] =
      useState<MonitEstimateResponse | null>(null)

    const imgSrc = useMapImage(
      props.initialValues?.lat,
      props.initialValues?.lon,
      props.initialValues?.typ_nemovitosti,
      props.initialValues?.polygon
    )

    return (
      <div className="grid xl:grid-cols-2 gap-8 w-full">
        <div className="order-2 xl:order-1 col-span-2 xl:col-span-1">
          <div className="text-lg mb-2">Vytvořit nový cenový odhad</div>
          <PriceEstimateLayoutStateAlert
            loading={!props.initialValues}
            fetchError={props.fetchError}
          >
            {props.initialValues && (
              <PriceEstimateForm
                initialValues={props.initialValues}
                propertyId={props.propertyId}
                lvData={props.lvData}
                risyData={risy || undefined}
                setEstimateResponse={setEstimateResponse}
              />
            )}
          </PriceEstimateLayoutStateAlert>
        </div>
        <div className="order-1 xl:order-2 space-y-8">
          <PriceEstimateResponse
            imgSrc={imgSrc}
            estimateResponse={estimateResponse}
          />
          <PriceEstimateHistory imgSrc={imgSrc} propertyId={props.propertyId} />
        </div>
      </div>
    )
  }
