import axios from 'axios'

type Coordinates = { x: number; y: number }

interface Group {
  id: string
  addressCode?: number | string | null
  coordinates?: Coordinates | null
}

interface GetDistanceInput {
  group: Group[]
  referenceAddressCode?: number | string | null
  referenceCoordinates?: Coordinates | null
}

interface DistanceResult {
  [id: string]: number | null
}

const cuzkCoordinationsApiUrl =
  'https://ags.cuzk.cz/arcgis/rest/services/RUIAN/Vyhledavaci_sluzba_nad_daty_RUIAN/MapServer/1/query?'
const cuzkDistanceApiUrl =
  'https://ags.cuzk.cz/arcgis/rest/services/Utilities/Geometry/GeometryServer/distance?'

export const fetchGeoDataByAddressCode = async (
  addressCode: number | string
): Promise<Coordinates | null> => {
  const response = await axios.get(cuzkCoordinationsApiUrl, {
    params: {
      f: 'geojson',
      where: `KOD = ${addressCode}`,
      returnGeometry: 'true',
      outSR: '4326',
    },
  })

  const features = response.data.features
  if (features && features.length > 0) {
    const coordinates = features[0].geometry.coordinates
    return { x: coordinates[1], y: coordinates[0] }
  }
  return null
}

export const calculateDistance = async (
  referencePoint: Coordinates,
  memberCoordinates: Coordinates
): Promise<number> => {
  const response = await axios.get(cuzkDistanceApiUrl, {
    params: {
      f: 'json',
      sr: '4326',
      distanceUnit: '9036',
      geodesic: 'true',
      geometry1: JSON.stringify({
        geometryType: 'esriGeometryPoint',
        geometry: referencePoint,
      }),
      geometry2: JSON.stringify({
        geometryType: 'esriGeometryPoint',
        geometry: memberCoordinates,
      }),
    },
  })

  return response.data.distance ?? null
}

export const getDistances = async (
  input: GetDistanceInput
): Promise<DistanceResult[]> => {
  if (input.group.length === 0) return []

  const referencePoint =
    input.referenceCoordinates ||
    (input.referenceAddressCode &&
      (await fetchGeoDataByAddressCode(input.referenceAddressCode)))

  return Promise.all(
    input.group.map(async member => {
      const memberCoordinates =
        member.coordinates ||
        (member.addressCode &&
          (await fetchGeoDataByAddressCode(member.addressCode)))

      const distance =
        memberCoordinates && referencePoint
          ? await calculateDistance(referencePoint, memberCoordinates)
          : null

      return { [member.id]: distance }
    })
  )
}
