import { Icon } from '@iconify/react'
import { Spin, Tooltip } from 'antd'
import React, { FunctionComponent, ReactNode, useMemo } from 'react'

import { appendComa, appendSpace } from '../../../common/pdfHelpers'
import {
  KuWorkplace,
  useKuWorkplaceByKodQuery,
} from '../../../graphql/generated'
import { tooltipHelpUnderline } from '../../utils/layoutConst'
import { CopyButton } from '../CopyButton/CopyButton'

export interface KuWorkplaceTooltipProps {
  children: ReactNode
  workplace?: Partial<KuWorkplace> | undefined | null
  kod?: string
}

export const KuWorkplaceTooltip: FunctionComponent<KuWorkplaceTooltipProps> =
  props => {
    const [queryResult] = useKuWorkplaceByKodQuery({
      pause: !props.kod || !!props.workplace,
      variables: { kod: props.kod || '' },
      requestPolicy: 'cache-first',
    })

    const workplace = props.workplace || queryResult.data?.kuWorkplaceByKod

    const addressText = useMemo(
      () =>
        [
          appendComa(workplace?.nazevUlice || ''),
          workplace?.cisloDomovni,
          workplace?.cisloOrientacni
            ? '/' + appendComa(workplace?.cisloOrientacni)
            : ', ',
          appendSpace(workplace?.psc || ''),
          appendSpace(workplace?.obec || ''),
        ]
          .filter(Boolean)
          .join(''),
      [workplace]
    )

    const copyButtonProps = useMemo(
      () => ({
        copyText: `${workplace?.nazev || ''}\n${addressText + '\n' || ''}\n${
          workplace?.ico ? 'IČ: ' + workplace?.ico + '\n' : ''
        }${workplace?.telefon ? 'Tel: ' + workplace?.telefon + '\n' : ''}${
          workplace?.email ? 'E-mail: ' + workplace?.email + '\n' : ''
        }${
          workplace?.web
            ? 'Web: https://www.cuzk.cz/Urady/Katastralni-urady/Katastralni-urady' +
              workplace?.web +
              '\n'
            : ''
        }`,
        buttonsProps: {
          size: 'small' as const,
          className: 'mt-2 w-full',
        },
        copyButtonText: 'Kopírovat údaje',
      }),
      [addressText, workplace]
    )

    const title = useMemo(() => {
      if (!workplace) {
        return null
      }
      return (
        <div className="p-3">
          <div className="flex space-x-4">
            <div className="flex flex-col">
              <div className="mb-2">
                <div className="font-semibold pb-2 mb-1 border-b border-dashed border-gray-500 max-w-xs">
                  {workplace?.nazev}
                </div>
                {addressText && (
                  <div>
                    {addressText}
                    <a
                      href={'https://www.google.com/maps/search/' + addressText}
                      target="_blank"
                      rel="noreferrer"
                      title="Otevřít mapu v novém panelu"
                    >
                      <Icon
                        className="ml-1 inline"
                        icon="tabler:map-pin"
                        width="13"
                      />
                    </a>
                  </div>
                )}
                {workplace?.ico && <div>IČ: {workplace.ico}</div>}
              </div>
              <div>
                {workplace?.telefon && <div>Tel: {workplace.telefon}</div>}
                {workplace?.email && <div>E-mail: {workplace.email}</div>}
                {workplace?.web && (
                  <div>
                    Web:{' '}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      title="Odkaz se otevře v novém panelu"
                      href={
                        'https://www.cuzk.cz/Urady/Katastralni-urady/Katastralni-urady' +
                        workplace.web
                      }
                    >
                      www.cuzk.cz/urady/katastralni-ura...
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="text-center mt-1 w-full relative">
            <CopyButton {...copyButtonProps} />
          </div>
        </div>
      )
    }, [addressText, workplace, copyButtonProps])

    return (
      <Tooltip
        destroyTooltipOnHide
        placement="top"
        overlayClassName="py-4 min-w-min !max-w-none"
        trigger="click"
        zIndex={1000}
        title={
          queryResult.fetching ? (
            <div className="p-3">
              <Spin />
            </div>
          ) : (
            title
          )
        }
        className={tooltipHelpUnderline}
      >
        {props.children}
      </Tooltip>
    )
  }
