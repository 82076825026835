import { CheckCircleOutlined, CopyOutlined } from '@ant-design/icons'
import { Button, ButtonProps, Typography } from 'antd'
import React, { CSSProperties, FunctionComponent } from 'react'

interface CopyButtonProps {
  copyText: string
  paragraphStyle?: CSSProperties
  paragraphClassName?: string
  buttonsProps?: ButtonProps
  buttonsTextClassName?: string
  copyButtonProps?: ButtonProps
  copyButtonText?: string
  copiedButtonProps?: ButtonProps
  copiedButtonText?: string
}

export const CopyButton: FunctionComponent<CopyButtonProps> = ({
  copyText,
  paragraphStyle,
  paragraphClassName,
  buttonsProps,
  buttonsTextClassName,
  copyButtonProps,
  copyButtonText,
  copiedButtonProps,
  copiedButtonText,
}) => {
  const { Paragraph } = Typography

  return (
    <Paragraph
      className={paragraphClassName}
      style={{ margin: 0, ...paragraphStyle }}
      copyable={{
        text: copyText,
        icon: [
          <Button
            ghost
            type="primary"
            key="copy"
            icon={<CopyOutlined />}
            {...buttonsProps}
            {...copyButtonProps}
          >
            <span className={buttonsTextClassName}>
              {copyButtonText || 'Kopírovat'}
            </span>
          </Button>,
          <Button
            ghost
            type="primary"
            key="copied"
            icon={<CheckCircleOutlined />}
            {...buttonsProps}
            {...copiedButtonProps}
          >
            <span className={buttonsTextClassName}>
              {copiedButtonText || 'Zkopírováno'}
            </span>
          </Button>,
        ],
        tooltips: [false, false],
      }}
    ></Paragraph>
  )
}
