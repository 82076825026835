import React, { FunctionComponent } from 'react'
import { Layer, Source } from 'react-map-gl'

const darkTiles = [
  'https://services.cuzk.cz/wms/wms.asp?service=WMS&request=GetMap&layers=KN&styles=&format=image/png&transparent=true&version=1.3.0&width=512&height=512&crs=EPSG:3857&bbox={bbox-epsg-3857}',
]
const lightTiles = [
  'https://services.cuzk.cz/wms/wms.asp?service=WMS&request=GetMap&layers=KN_I&styles=&format=image/png&transparent=true&version=1.3.0&width=512&height=512&crs=EPSG:3857&bbox={bbox-epsg-3857}',
]

export interface CadastreMapBaseParcelRasterDisplayProps {
  mode: 'light' | 'dark'
}

export const CadastreMapBaseParcelRasterDisplay: FunctionComponent<CadastreMapBaseParcelRasterDisplayProps> =
  ({ mode }) => {
    return (
      <Source
        type="raster"
        tiles={mode === 'light' ? lightTiles : darkTiles}
        tileSize={512}
        attribution='&copy; <a href="https://cuzk.cz">ČÚZK</a>'
      >
        <Layer type="raster" minzoom={0} maxzoom={22} />
      </Source>
    )
  }
