import { useCallback } from 'react'

import { ARES_SERVICE, AresFilterInput } from '../../common/aresTypes'
import { AresApiVrResponse, RzpZaznam } from '../../common/rzpTypes'
import { PropertyDataTypes } from '../../redux/fetchAction'
import { fetchAresByFilter } from './fetchAresByIco'
import { useDispatchFetch } from './useDispatchFetch'

export const useFetchAddressAres = () => {
  const { dispatchError, dispatchFetching, dispatchSuccess } =
    useDispatchFetch()

  const fetchAres = useCallback(
    async (aresFilter?: AresFilterInput) => {
      if (!aresFilter?.ico) return dispatchError(PropertyDataTypes.ARES)
      if (aresFilter.ico.length === 0)
        return dispatchSuccess(PropertyDataTypes.ARES, [])

      dispatchFetching(PropertyDataTypes.ARES)

      const rzpResponse: AresApiVrResponse = await fetchAresByFilter(
        aresFilter,
        ARES_SERVICE.rzp
      )
      const vrResponse: AresApiVrResponse = await fetchAresByFilter(
        aresFilter,
        ARES_SERVICE.vr
      )

      if (!vrResponse && !rzpResponse)
        return dispatchError(PropertyDataTypes.ARES)

      dispatchSuccess(PropertyDataTypes.ARES, {
        rzp: rzpResponse.ekonomickeSubjekty.reduce((acc, subjekt) => {
          acc[subjekt.icoId] = subjekt.zaznamy[0]
          return acc
        }, {} as Record<string, RzpZaznam>),
        vr: vrResponse.ekonomickeSubjekty.reduce((acc, subjekt) => {
          acc[subjekt.icoId] = subjekt.zaznamy[0]
          return acc
        }, {} as Record<string, RzpZaznam>),
      })
    },
    [dispatchError, dispatchFetching, dispatchSuccess]
  )

  return fetchAres
}
