import { CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Tag, Typography } from 'antd'
import React, { FunctionComponent } from 'react'

import { useToggleState } from '../../utils/useToggleState'

export const PropertyDetailTimelineHelpButton: FunctionComponent = () => {
  const [open, handleOpen] = useToggleState()

  return (
    <>
      <Button
        size="large"
        type="link"
        className="z-[201] !bg-white"
        icon={open ? <CloseCircleOutlined /> : <QuestionCircleOutlined />}
        onClick={handleOpen}
      />
      <div
        className={`${
          open ? 'w-80 p-2' : 'w-0 p-0'
        } absolute overflow-hidden bg-white z-[200] bottom-0 rounded rounded-br-none right-[98%] h-28 shadow-md flex flex-wrap items-center transition-width duration-300`}
      >
        <div className="flex space-x-2 whitespace-nowrap">
          <div className="w-12">
            <Tag>
              <code>alt</code>
            </Tag>
          </div>
          <div>+</div>
          <Typography.Text keyboard>kolečko myši</Typography.Text>
          <div>= Zoom</div>
        </div>
        <div className="flex space-x-2 whitespace-nowrap">
          <div className="w-12">
            <Tag>
              <code>ctrl</code>
            </Tag>
          </div>
          <div>+</div>
          <Typography.Text keyboard>kolečko myši</Typography.Text>
          <div>= Rychlý zoom</div>
        </div>
        <div className="flex space-x-2 whitespace-nowrap">
          <div className="w-12">
            <Tag>
              <code>shift</code>
            </Tag>
          </div>
          <div>+</div>
          <Typography.Text keyboard>kolečko myši</Typography.Text>
          <div>= Pohyb po ose</div>
        </div>
      </div>
    </>
  )
}
