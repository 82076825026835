import { Typography } from 'antd'
import React, { FunctionComponent } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { usePropertyDetailTitle } from '../../utils/usePropertyDetailTitle'
import { CadastralViewButton } from '../CadastralViewButton/CadastralViewButton'
import { PropertyDetailLabel } from '../PropertyDetailLabel/PropertyDetailLabel'
import { PropertyDetailLvButton } from '../PropertyDetailLvButton/PropertyDetailLvButton'
import { WatchdogButton } from '../WatchdogButton/WatchdogButton'

export interface PropertyDetailPageTitleProps {
  propertyId: string
  propertyType: PROPERTY_TYPES
}

const { Title } = Typography
const titleStyle = { marginBottom: 0 }

export const PropertyDetailPageTitle: FunctionComponent<PropertyDetailPageTitleProps> =
  props => {
    const propertyName = usePropertyDetailTitle()

    return (
      <div className="gap-y-2 flex flex-wrap justify-between w-full">
        <div className="space-x-2 flex flex-wrap items-center">
          <Title
            level={3}
            style={titleStyle}
            className="first-letter:uppercase"
          >
            {propertyName}
          </Title>

          <WatchdogButton
            propertyId={props.propertyId}
            propertyType={props.propertyType}
            iconSize="text-xl"
          />

          <PropertyDetailLabel
            propertyType={props.propertyType}
            propertyName={propertyName}
            propertyId={props.propertyId}
          />
        </div>

        <div className="space-x-2 flex flex-1 justify-end items-center">
          <CadastralViewButton />
          <PropertyDetailLvButton />
        </div>
      </div>
    )
  }
