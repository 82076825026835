import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Link } from 'react-router-dom'

import { BASE_URL } from '../../../../ini.json'
import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { useAppSelector } from '../../../redux/hooks'
import { stringSorter } from '../../utils/generalTableHelpers'
import { statisticPagination } from '../../utils/layoutConst'
import {
  areaPriceEstimateTitlePrefix,
  buildingPriceEstimateTitlePrefix,
  unitPriceEstimateTitlePrefix,
} from '../../utils/layoutConst'
import { propertyDetailPath } from '../../utils/paths'
import { StatisticCompany } from '../../utils/statisticHelpers'
import { Day, Min } from '../DateFormat/DateFormat'
import { Money } from '../Money/Money'

interface MarkeplaceStatisticsItem {
  accountId: string
  cenaM2Nab: string | number
  cenaM2Real: string | number
  cenaNab: string | number
  cenaReal: string | number
  createdAt: Date
  estimateToDate: Date
  id: string
  propertyId: string
  propertyType: number
  title: string
}

const getType = (propertyType: number) => {
  return propertyType === 0
    ? PROPERTY_TYPES.AREA
    : propertyType === 1
    ? PROPERTY_TYPES.BUILDING
    : PROPERTY_TYPES.UNIT
}
export interface MonitStatisticsTableProps {
  companies?: StatisticCompany
}

export const MonitStatisticsTable: FunctionComponent<MonitStatisticsTableProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)
    const [loading, setLoading] = useState<boolean | undefined>()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [tableData, setTableData] = useState<any>()

    const fetchData = useCallback(async () => {
      setLoading(true)

      fetch(`${BASE_URL}/api/monit/admin/statistics/${token}`)
        .then(response => response.json())
        .then(data => {
          setTableData(data)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
          setTableData([])
        })
    }, [token])

    useEffect(() => {
      if (tableData !== undefined) return
      fetchData()
    }, [tableData, fetchData])

    const columns: ColumnsType<MarkeplaceStatisticsItem> = useMemo(
      () => [
        {
          title: '#',
          key: 'index',
          className: 'w-8',
          render: (_, __, index) => index + 1,
        },
        {
          title: 'id',
          dataIndex: 'id',
          ellipsis: { showTitle: true },
          className: 'w-14 cursor-help',
        },
        {
          title: 'Vytvořeno',
          dataIndex: 'createdAt',
          defaultSortOrder: 'descend',
          className: 'w-32',
          sorter: {
            compare: (a, b) =>
              a.createdAt.toString().localeCompare(b.createdAt.toString()),
          },
          render: createdAt => <Min>{createdAt}</Min>,
        },
        {
          title: 'Klient',
          dataIndex: 'accountId',
          ellipsis: { showTitle: true },
          sorter: stringSorter('accountId'),
          showSorterTooltip: { title: 'Řadí podle ID, ne názvu' },
          render: id => (
            <a
              className="!text-primary"
              href={`https://data.regesta.cz/PortalAdmin/DetailFirmy/${id}`}
              target="_blank"
              rel="noreferrer"
            >
              {props.companies?.[id] || id}
            </a>
          ),
        },
        {
          title: 'Odhad k datu',
          dataIndex: 'estimateToDate',
          className: 'w-28',
          render: estimateToDate => <Day>{estimateToDate}</Day>,
        },
        {
          title: 'Oceňovaná nemoviost',
          dataIndex: 'title',
          sorter: {
            compare: (a, b) => a.title.localeCompare(b.title),
          },
          render: (title, record) => {
            const text = title
              ?.replace(buildingPriceEstimateTitlePrefix, '')
              .replace(areaPriceEstimateTitlePrefix, '')
              .replace(unitPriceEstimateTitlePrefix, '')
            const type = getType(record.propertyType)
            return (
              <Link
                to={propertyDetailPath(
                  type as PROPERTY_TYPES,
                  record.propertyId
                )}
              >
                {text}
              </Link>
            )
          },
        },
        {
          title: 'Typ',
          dataIndex: 'propertyType',
          sorter: {
            compare: (a, b) => a.propertyType - b.propertyType,
          },
          render: propertyType => getType(propertyType),
        },
        {
          title: (
            <span>
              Nabídková cena m<sup>2</sup>
            </span>
          ),
          dataIndex: 'cenaM2Nab',
          className: 'text-right',
          sorter: {
            compare: (a, b) => Number(a.cenaM2Nab) - Number(b.cenaM2Nab),
          },
          render: cenaM2Nab => (
            <Money amount={cenaM2Nab} symbol={'Kč'} decimalNumbers={0} />
          ),
        },
        {
          title: (
            <span>
              Prodejní cena m<sup>2</sup>
            </span>
          ),
          dataIndex: 'cenaM2Real',
          className: 'text-right',
          sorter: {
            compare: (a, b) => Number(a.cenaM2Real) - Number(b.cenaM2Real),
          },
          render: cenaM2Real => (
            <Money
              noData="---"
              amount={cenaM2Real}
              symbol={'Kč'}
              decimalNumbers={0}
            />
          ),
        },
        {
          title: 'Nabídková cena',
          dataIndex: 'cenaNab',
          className: 'text-right',
          sorter: {
            compare: (a, b) => Number(a.cenaNab) - Number(b.cenaNab),
          },
          render: cenaNab => (
            <Money amount={cenaNab} symbol={'Kč'} decimalNumbers={0} />
          ),
        },
        {
          title: 'Prodejní cena',
          dataIndex: 'cenaReal',
          className: 'text-right',
          sorter: {
            compare: (a, b) => Number(a.cenaReal) - Number(b.cenaReal),
          },
          render: cenaReal => (
            <Money
              noData="---"
              amount={cenaReal}
              symbol={'Kč'}
              decimalNumbers={0}
            />
          ),
        },
      ],
      [props.companies]
    )

    return (
      <Table
        pagination={statisticPagination}
        dataSource={tableData}
        columns={columns}
        loading={loading}
        rowKey="id"
        size="small"
      />
    )
  }
