export const itemRequired = (errorText: string) => [
  {
    required: true,
    message: errorText,
  },
]

export const itemHelp = (
  show: string | boolean | undefined | null,
  helpText: string | React.ReactNode
) => {
  if (!show) return undefined
  return helpText
}

export const onlyNumbers = (input: string) => input.replace(/\D/g, '')
