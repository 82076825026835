import React, { FunctionComponent } from 'react'

import { RcTableItem } from '../CuzkMonitorRcTable/CuzkMonitorRcTable'
import { CuzkMonitorRcTableCuzkOs } from '../CuzkMonitorRcTableCuzkOs/CuzkMonitorRcTableCuzkOs'
import { CuzkMonitorRcTableCuzkOsBsm } from '../CuzkMonitorRcTableCuzkOsBsm/CuzkMonitorRcTableCuzkOsBsm'
import { Min } from '../DateFormat/DateFormat'

export interface CuzkMonitorRcTableExpandedRowProps {
  cuzkOs: RcTableItem['cuzkOByCuzkOsId']
  className?: string
}

export const CuzkMonitorRcTableExpandedRow: FunctionComponent<CuzkMonitorRcTableExpandedRowProps> =
  props => {
    if (!props?.cuzkOs) return null
    return (
      <>
        <div
          className={`flex flex-wrap space-y-8 space-x-0 md:flex-nowrap md:space-y-0 md:space-x-8 ${
            props.className || ''
          }`}
        >
          <div>
            <span className="text-gray-400">Nalezený subjekt:</span>
            <CuzkMonitorRcTableCuzkOs cuzkOs={props.cuzkOs} />
          </div>
          <div>
            <CuzkMonitorRcTableCuzkOsBsm id={props.cuzkOs.id} />
          </div>
        </div>
        <div className="text-gray-300 italic mt-2">
          Údaje z ČÚZK: <Min>{props.cuzkOs.lastCheck}</Min>
        </div>
      </>
    )
  }
