import { Button, Table } from 'antd'
import { ColumnsType, SorterResult } from 'antd/es/table/interface'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Link } from 'react-router-dom'

import {
  CollectionType,
  DocumentCollectionItem,
} from '../../../common/documentCollectionTypes'
import { Rizeni } from '../../../common/proceedingTypes'
import {
  useCuzkDocumentCollectionTableQuery,
  UuidFilter,
} from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { checkSeznamSestav } from '../../utils/checkSeznamSestav'
import {
  numberSorter,
  stringSorter,
  utcDateSorter,
} from '../../utils/generalTableHelpers'
import { cuzkDocumentCollectionState } from '../../utils/handleResponseStatus'
import { tablePagination } from '../../utils/layoutConst'
import { proceedingPath } from '../../utils/paths'
import { parseProceedingString } from '../../utils/proceedingDetailHelpers'
import { useConst } from '../../utils/useConst'
import { CuzkDocumentCollectionAction } from '../CuzkDocumentCollectionAction/CuzkDocumentCollectionAction'
import { CuzkDocumentPrice } from '../CuzkDocumentPrice/CuzkDocumentPrice'
import { CuzkDocumentState } from '../CuzkDocumentState/CuzkDocumentState'
import { Min } from '../DateFormat/DateFormat'
import { ProceedingDetailModalLink } from '../ProceedingDetailModalLink/ProceedingDetailModalLink'
import { TableCellLabels } from '../TableCellLabels/TableCellLabels'
import { TableSummaryExport } from '../TableSummaryExport/TableSummaryExport'

export interface CuzkDocumentCollectionTableProps {
  documentCollectionId?: string
  idFilter?: UuidFilter
}

export const CuzkDocumentCollectionTable: FunctionComponent<CuzkDocumentCollectionTableProps> =
  props => {
    const tableId = 'documentCollection-table'
    const tenantId = useAppSelector(state => state.myProfile.tenantId)

    const [, setSortedInfo] = useState<SorterResult<DocumentCollectionItem>>({})
    const [dataSource, setDataSource] = useState<DocumentCollectionItem[]>([])
    const [timeoutTime, setTimeoutTime] = useState<number | null>(2)
    const oneDayAgo = useConst(new Date(new Date().getTime() - 86400000))
    const [queryResult, refetch] = useCuzkDocumentCollectionTableQuery({
      variables: {
        accountId: tenantId,
        oneDayAgo: oneDayAgo,
        idFilter: props.idFilter,
      },
    })

    useEffect(() => {
      if (queryResult?.data?.allCuzkDocumentCollections?.nodes.length) {
        const newDataSource = queryResult.data.allCuzkDocumentCollections
          .nodes as DocumentCollectionItem[]

        if (props.documentCollectionId) {
          const index = newDataSource.findIndex(
            item => item.id === props.documentCollectionId
          )
          if (index > -1) {
            const [selectedItem] = newDataSource.splice(index, 1)
            newDataSource.unshift({
              ...selectedItem,
              selected: true,
              createdAt: new Date().toISOString() as unknown as Date,
            })
          }
        }

        setDataSource(
          queryResult.data.allCuzkDocumentCollections
            .nodes as DocumentCollectionItem[]
        )
        setSortedInfo({
          order: 'ascend',
          columnKey: 'validatedCode',
        })
      }
    }, [
      props.documentCollectionId,
      queryResult.data?.allCuzkDocumentCollections?.nodes,
      setSortedInfo,
    ])

    useEffect(() => {
      if (props.documentCollectionId) {
        refetch({ accountId: tenantId })
      }
    }, [props.documentCollectionId, refetch, tenantId])

    useEffect(() => {
      let timeoutId = null as NodeJS.Timeout | null

      const checkItems = async () => {
        if (!queryResult.fetching && dataSource.length > 0) {
          const itemsToCheck = dataSource.filter(
            item =>
              item.stav === cuzkDocumentCollectionState.ceka ||
              item.stav === cuzkDocumentCollectionState.podepisujeSe ||
              item.stav === cuzkDocumentCollectionState.vytvariSe
          )

          let shouldRefetch = false
          for (const item of itemsToCheck) {
            const checked = await checkSeznamSestav({
              cuzkCollectionId: item.cuzkCollectionId,
              id: item.id,
              stav: item.stav,
              tenantId: tenantId,
              collectionType: CollectionType.SL,
            })

            if (checked) {
              shouldRefetch = true
            }
          }

          if (shouldRefetch) {
            refetch()
            setTimeoutTime(prevTime => (prevTime || 2) * 2)
          } else {
            setTimeoutTime(null)
          }
        }
      }

      if (timeoutTime) {
        timeoutId = setTimeout(checkItems, timeoutTime * 1000)
      }

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId)
        }
      }
    }, [dataSource, queryResult.fetching, refetch, tenantId, timeoutTime])

    const columns = useMemo<ColumnsType<DocumentCollectionItem>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Vytvořeno',
          dataIndex: 'createdAt',
          defaultSortOrder: 'descend',
          sorter: utcDateSorter('createdAt'),
          render: (createdAt, record) => (
            <Min>
              {record.selected && record.datumvytvoreni
                ? record.datumvytvoreni
                : createdAt}
            </Min>
          ),
        },
        {
          title: 'Řízení',
          dataIndex: 'rizeni',
          sorter: stringSorter('rizeni'),
          render: rizeni => (
            <ProceedingDetailModalLink
              proceeding={
                parseProceedingString(
                  rizeni.replace(/^(\p{L}{1,3}-\d{1,8})-/u, '$1/')
                ) as Rizeni
              }
            >
              {rizeni}
            </ProceedingDetailModalLink>
          ),
        },
        {
          title: 'Štítek',
          dataIndex: 'labels',
          render: (labels, record) => (
            <TableCellLabels
              labels={labels}
              labelEntityType="cuzkDocumentCollectionId"
              recordId={record.id}
              refetch={refetch}
              modalTitle={record.rizeni}
            />
          ),
        },
        {
          title: 'Název',
          dataIndex: 'nazev',
          sorter: stringSorter('nazev'),
        },
        {
          title: 'Cena',
          dataIndex: 'cena',
          className: 'w-44',
          sorter: numberSorter('cena'),
          render: (cena, record) => (
            <CuzkDocumentPrice state={record.stav} price={cena} />
          ),
        },
        {
          title: 'Počet stran',
          dataIndex: 'pocetstran',
          sorter: numberSorter('pocetstran'),
          render: pocetstran =>
            pocetstran ? (
              pocetstran
            ) : (
              <span className="text-gray-400">Vypočítává se</span>
            ),
        },
        {
          title: 'Stav',
          dataIndex: 'stav',
          sorter: stringSorter('stav'),
          render: stav => <CuzkDocumentState state={stav} />,
        },
        {
          title: 'Akce',
          dataIndex: 'actions',
          render: (_, record) => (
            <CuzkDocumentCollectionAction
              price={record.cena}
              state={record.stav}
              id={record.id}
              cuzkCollectionId={record.cuzkCollectionId}
              refetch={refetch}
              collectionType={CollectionType.SL}
            />
          ),
        },
      ],
      [refetch]
    )

    const locale = useMemo(
      () => ({
        emptyText: !queryResult.fetching && (
          <div className="my-8">
            <div className="mb-2 text-gray-400">
              Zatím jste si nestáhli žádnou katastrální listinu.
            </div>
            <Link to={proceedingPath()}>
              <Button type="primary">Vyhledat listinu</Button>
            </Link>
          </div>
        ),
      }),
      [queryResult.fetching]
    )

    const summary = useCallback(
      () => (
        <TableSummaryExport
          total={dataSource?.length || 0}
          colSpan={7}
          url="cuzk/documentCollectionXlsx"
          fileName="CUZK-listiny"
          dataSource={dataSource || []}
          icon
        />
      ),
      [dataSource]
    )

    const rowClassName = useCallback(
      (record: DocumentCollectionItem) =>
        record.id === props.documentCollectionId ? 'bg-green-50' : '',
      [props.documentCollectionId]
    )

    return (
      <>
        <Table
          id={tableId}
          rowKey="id"
          size="small"
          rowClassName={rowClassName}
          dataSource={[...dataSource]}
          columns={columns}
          loading={queryResult.fetching}
          locale={locale}
          bordered
          summary={summary}
          pagination={tablePagination}
        />
      </>
    )
  }
