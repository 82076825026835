import React, { FunctionComponent, useMemo } from 'react'

import { InsolvencyMonitoringFilterOnSubmit } from '../../../common/insolvencyMonitoringTypes'
import { useFetchInsolvencyEnums } from '../../utils/useFetchInsolvencyEnums'
import { InsolvencyMonitoringFilterProceedingStateModalButton } from '../InsolvencyMonitoringFilterProceedingStateModalButton/InsolvencyMonitoringFilterProceedingStateModalButton'
import { PropertyMonitoringFilterError } from '../PropertyMonitoringFilterError/PropertyMonitoringFilterError'
import { PropertyMonitoringFilterItem } from '../PropertyMonitoringFilterItem/PropertyMonitoringFilterItem'

export interface InsolvencyMonitoringFilterProceedingStateProps {
  selected?: number[] | null
  onChange: InsolvencyMonitoringFilterOnSubmit
}

export const InsolvencyMonitoringFilterProceedingState: FunctionComponent<InsolvencyMonitoringFilterProceedingStateProps> =
  props => {
    const { error, data } = useFetchInsolvencyEnums()

    const selectedValues = useMemo(() => {
      if (props.selected) return props.selected
      if (!data?.length) return []
      return data.map(item => item.kod)
    }, [data, props.selected])

    if (error) return <PropertyMonitoringFilterError filterType="propertyUse" />

    if (!data?.length) return null

    return (
      <PropertyMonitoringFilterItem label="Stav insolvence">
        <InsolvencyMonitoringFilterProceedingStateModalButton
          checkboxData={data}
          selectedValues={selectedValues}
          onSubmit={props.onChange}
        />
      </PropertyMonitoringFilterItem>
    )
  }
