import { BaseButtonProps } from 'antd/es/button/button'
import React, { FunctionComponent, useMemo } from 'react'

import { CreateLabelConnectionMutationVariables } from '../../../graphql/generated'
import { LabelType } from '../../utils/labelsTypes'
import { InlineLabelsGroup } from '../InlineLabelsGroup/InlineLabelsGroup'
import { TableCellEditLabelModal } from '../TableCellEditLabelModal/TableCellEditLabelModal'

export interface TableCellLabelsProps {
  labels: {
    nodes:
      | {
          id: string
          labelByLabelId: LabelType
        }[]
      | []
  }
  refetch?: () => void
  modalTitle?: string
  buttonText?: React.ReactNode
  buttonProps?: BaseButtonProps
  recordId: string
  labelEntityType: keyof CreateLabelConnectionMutationVariables
}

export const TableCellLabels: FunctionComponent<TableCellLabelsProps> =
  props => {
    const label = useMemo(
      () =>
        props.labels?.nodes.map(
          (label: { labelByLabelId: LabelType }) => label.labelByLabelId
        ),
      [props.labels]
    )

    return (
      <div className="flex space-x-2">
        <InlineLabelsGroup labels={label} />
        <TableCellEditLabelModal
          buttonText={props.buttonText}
          buttonProps={props.buttonProps}
          labels={props.labels}
          refetch={props?.refetch}
          modalTitle={props.modalTitle}
          recordId={props.recordId}
          labelEntityType={props.labelEntityType}
        />
      </div>
    )
  }
