import React, { FunctionComponent } from 'react'

import { useAuctionListingsStatsQuery } from '../../../graphql/generated'

const now = new Date()
const newTreshold = new Date(now.getTime() - 24 * 60 * 60 * 1000)

export const CadastreMapAuctionLayerControlStats: FunctionComponent = () => {
  const [{ data }] = useAuctionListingsStatsQuery({
    variables: {
      now,
      newTreshold,
    },
  })
  const newCount = data?.new?.totalCount
  const upcomingCount = data?.upcoming?.totalCount
  if (typeof newCount === 'undefined' || typeof upcomingCount === 'undefined')
    return null
  return (
    <div className="text-xs">
      <div>Nově přidané: {newCount}</div>
      <div>Připravované: {upcomingCount}</div>
    </div>
  )
}
