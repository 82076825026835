import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { WatchdogChannelTableItem } from '../../../common/watchdogTypes'
import { useAppSelector } from '../../../redux/hooks'
import {
  booleanSorter,
  numberSorter,
  stringSorter,
} from '../../utils/generalTableHelpers'
import { watchdogPropertyPath } from '../../utils/paths'
import { useDeclension } from '../../utils/useDeclension'
import { WatchdogChannelDeleteButton } from '../WatchdogChannelDeleteButton/WatchdogChannelDeleteButton'
import { WatchdogChannelModalButton } from '../WatchdogChannelModalButton/WatchdogChannelModalButton'

const buttonProps = {
  type: 'link',
  size: 'small',
} as const

export const WatchdogChannelTable: FunctionComponent = () => {
  const decline = useDeclension()

  const property = useAppSelector(state => state.watchdog.property)
  const channel = useAppSelector(state => state.watchdog.channel)

  const channelsWithCounts = useMemo(() => {
    if (!channel.data || !property.data) return []

    const allProperties = Object.values(property.data)

    return channel.data.map(channel => {
      const count = allProperties.filter(
        property => property.channelId === channel.id
      ).length
      return {
        ...channel,
        watchedProperties: count,
      }
    })
  }, [channel.data, property.data])

  const columns = useMemo<ColumnsType<WatchdogChannelTableItem>>(
    () => [
      {
        title: 'Název',
        dataIndex: 'nazev',
        sorter: stringSorter('nazev'),
        render: (name, record) => (
          <Link to={watchdogPropertyPath(record.id)}>{name}</Link>
        ),
      },
      {
        title: 'Hlídaných nemovitostí',
        dataIndex: 'watchedProperties',
        className: '!text-right',
        sorter: numberSorter('watchedProperties'),
        render: watchedProperties => (
          <span className={!watchedProperties ? 'text-gray-400' : ''}>
            {watchedProperties}
          </span>
        ),
      },
      {
        title: 'Email pro notifikace',
        dataIndex: 'emaily',
        sorter: stringSorter('emaily'),
      },
      {
        title: 'Interval notifikací',
        dataIndex: 'intervalObesilani',
        className: '!text-right w-40',
        sorter: numberSorter('intervalObesilani'),
        render: interval => (
          <span>
            <span>{interval}</span>
            <span className="text-gray-400 pl-1.5">
              {decline(interval, 'day')}
            </span>
          </span>
        ),
      },
      {
        title: 'Zasílat i prázdné',
        dataIndex: 'prazdnyEmail',
        className: '!pl-4',
        sorter: booleanSorter('prazdnyEmail'),
        render: prazdnyEmail => (prazdnyEmail ? 'Ano' : 'Ne'),
      },
      {
        title: 'Akce',
        dataIndex: 'id',
        key: 'action',
        className: '!text-right w-40',
        render: (id, record) => (
          <div className="space-x-2">
            <WatchdogChannelModalButton
              buttonProps={buttonProps}
              buttonText="Upravit"
              channelId={id}
            />
            <WatchdogChannelDeleteButton channel={record} />
          </div>
        ),
      },
    ],
    [decline]
  )

  const summary = useCallback(
    () =>
      channel.data &&
      channel.data?.length > 1 && (
        <Table.Summary.Row>
          <Table.Summary.Cell
            className="text-gray-400 !border-b-0 border-gray-100 border-t"
            index={1}
            colSpan={2}
          >
            Celkem: {decline(channel.data.length, 'channel', true)}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      ),
    [channel.data, decline]
  )

  return (
    <Table
      rowKey="id"
      size="small"
      className="mb-4"
      columns={columns}
      dataSource={channelsWithCounts}
      loading={property.fetching || channel.fetching}
      pagination={false}
      summary={summary}
    />
  )
}
