import { Button, message, Popconfirm } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useCallback } from 'react'

import { BASE_URL } from '../../../../ini.json'
import { WatchdogChannelTableItem } from '../../../common/watchdogTypes'
import { useAppSelector } from '../../../redux/hooks'
import { useFetchWatchdogProperty } from '../../utils/useFetchWatchdog'

export interface WatchdogChannelDeleteButtonProps {
  channel: WatchdogChannelTableItem
}

const okButtonProps = { danger: true }

export const WatchdogChannelDeleteButton: FunctionComponent<WatchdogChannelDeleteButtonProps> =
  props => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const refetch = useFetchWatchdogProperty()

    const handleSubmit = useCallback(async () => {
      try {
        await axios.post(
          `${BASE_URL}/api/watchdog/channel/delete/${tenantId}`,
          {
            channelId: props.channel.id,
          }
        )
        refetch()
        message.success('Kanál hlídače smazán.')
      } catch (error) {
        message.error('Nepodařilo se smazat kanál hlídače.')
      }
    }, [props.channel.id, refetch, tenantId])

    return (
      <Popconfirm
        placement="leftTop"
        title={`Opravdu smazat kanál ${props.channel.nazev}?`}
        description={
          !!props.channel.watchedProperties && (
            <div className="max-w-xs">
              Hlídané nemovitosti zařazené do tohoto kanálu nebudou odebrány z
              hlídače, ale zůstanou bez přiřazeného kanálu, což znamená, že o
              případných změnách nebudete informováni e-mailem.
            </div>
          )
        }
        okText="Smazat"
        okButtonProps={okButtonProps}
        onConfirm={handleSubmit}
        cancelText="Zrušit"
      >
        <Button size="small" type="link" danger>
          Smazat
        </Button>
      </Popconfirm>
    )
  }
