import axios from 'axios'
import { useCallback } from 'react'

import { DrmSubject } from '../../common/drmSubjectsTypes'
import { PropertyDataTypes } from '../../redux/fetchAction'
import { useAppSelector } from '../../redux/hooks'
import { requestDrmSubjectsPath } from './paths'
import { isCompanyName } from './subjectHelpers'
import { useDispatchFetch } from './useDispatchFetch'

const noCompanySubjects = (drmSubjects: DrmSubject[]) =>
  drmSubjects.filter(subject => !isCompanyName(subject.nazev || '')) || []

export const useFetchDrmSubjects = () => {
  const token = useAppSelector(state => state.myProfile.token)
  const { dispatchError, dispatchFetching, dispatchSuccess } =
    useDispatchFetch()

  const fetchDrmSubjects = useCallback(
    async (addressId?: string | number) => {
      if (!token || !addressId)
        return dispatchError(PropertyDataTypes.DRM_SUBJECTS)
      dispatchFetching(PropertyDataTypes.DRM_SUBJECTS)

      try {
        const response = await axios.get(
          `${requestDrmSubjectsPath()}${token}/${addressId}`
        )
        dispatchSuccess(
          PropertyDataTypes.DRM_SUBJECTS,
          noCompanySubjects(response.data)
        )
      } catch (error) {
        dispatchError(PropertyDataTypes.DRM_SUBJECTS)
      }
    },
    [dispatchError, dispatchFetching, dispatchSuccess, token]
  )

  return fetchDrmSubjects
}
