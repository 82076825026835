import React, { FunctionComponent } from 'react'

import { useCuzkMonitorRcTableExpandedRowQuery } from '../../../graphql/generated'
import { CuzkMonitorRcTableCuzkOs } from '../CuzkMonitorRcTableCuzkOs/CuzkMonitorRcTableCuzkOs'

export interface CuzkMonitorRcTableCuzkOsBsmProps {
  id?: string
}

export const CuzkMonitorRcTableCuzkOsBsm: FunctionComponent<CuzkMonitorRcTableCuzkOsBsmProps> =
  props => {
    const [sjm] = useCuzkMonitorRcTableExpandedRowQuery({
      variables: { partnerId: props?.id },
    })

    const os = sjm.data?.allCuzkOs?.nodes[0]
    if (!os?.cuzkOByPartner1 || !os.cuzkOByPartner2) return null
    return (
      <div className="md:pl-8 md:border-l md:border-gray-300">
        <span className="text-gray-400">Subjekt je součástí SJM:</span>
        <div className="flex space-x-8">
          <CuzkMonitorRcTableCuzkOs cuzkOs={os.cuzkOByPartner1} />
          <CuzkMonitorRcTableCuzkOs cuzkOs={os.cuzkOByPartner2} />
        </div>
      </div>
    )
  }
