import { MenuOutlined } from '@ant-design/icons'
import { Button, Dropdown, MenuProps } from 'antd'
import { format, parseISO } from 'date-fns'
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useMemo,
} from 'react'

import { CeeTableItem } from '../../../common/ceeTypes'
import { clickTrigger } from '../../utils/layoutConst'
import { pricelist } from '../../utils/pricelist'
import { CeeMonitorBulkEdit } from '../CeeMonitorBulkEdit/CeeMonitorBulkEdit'
import { CeeMonitorDelete } from '../CeeMonitorDelete/CeeMonitorDelete'
import { CeeSummaryPdf } from '../CeeSummaryPdf/CeeSummaryPdf'
import { CeeSummaryPdfButton } from '../CeeSummaryPdfButton/CeeSummaryPdfButton'
import {
  CUZK_PERSON_TYPES,
  CuzkEpoModalButton,
} from '../CuzkEpoModalButton/CuzkEpoModalButton'
import { CuzkMonitorAddSubjectModal } from '../CuzkMonitorAddSubjectModal/CuzkMonitorAddSubjectModal'
import { PdfDrawerPreview } from '../PdfDrawerPreview/PdfDrawerPreview'
import { PricePopconfirm } from '../PricePopconfirm/PricePopconfirm'

export interface CeeMonitorTableActionProps {
  reexecuteQuery: () => void
  setLoadingId: (id: string[]) => void
  loadingId: string[]
  checkNow: (id: string[]) => void
  record: CeeTableItem
  handleExpandRow: (id: string) => void
  monitoring?: boolean
}

const buttonProps = {
  type: 'primary',
  ghost: true,
  size: 'small',
  className: '!flex items-center !rounded-sm',
} as const

export const CeeMonitorTableAction: FunctionComponent<CeeMonitorTableActionProps> =
  props => {
    const epoButtonProps = useMemo(
      () => ({
        buttonProps: {
          size: 'middle' as const,
          type: 'link' as const,
          title: 'Vyhledat evidenci práv pro osobu',
          className: 'w-full',
        },
        buttonText: 'Vyhledat EPO',
        initialValues: {
          type: props.record.ico
            ? CUZK_PERSON_TYPES.OPO
            : CUZK_PERSON_TYPES.OFO,
          rc: props.record.rc,
          ico: props.record.ico,
          firstname: props.record.firstname,
          lastname: props.record.lastname,
          birthDate: props.record?.birthDate
            ? format(parseISO(props.record.birthDate.toString()), 'yyMMdd')
            : props.record?.rc ?? undefined,
        },
      }),
      [
        props.record.birthDate,
        props.record.firstname,
        props.record.ico,
        props.record.lastname,
        props.record.rc,
      ]
    )
    const menuProps = useMemo<MenuProps>(
      () => ({
        items: [
          {
            key: 'edit',
            className: '!p-0',
            label: (
              <CeeMonitorBulkEdit
                refetch={props.reexecuteQuery}
                items={[props.record]}
                monitoring={props.monitoring}
                buttonText="Editovat"
                initialValues={{
                  note: props.record.note,
                  checkFrequency: props.record.checkFrequency,
                }}
                buttonProps={{
                  size: 'middle',
                  type: 'link',
                  className: 'w-full',
                }}
              />
            ),
          },
          {
            key: 'searchCuzk',
            className: '!p-0',
            label: (
              <CuzkMonitorAddSubjectModal
                buttonProps={{ size: 'middle', type: 'link' }}
                buttonText="Ověřit v ČÚZK"
                noRedirect
                refetch={props.reexecuteQuery}
                dataSource={[
                  {
                    id: props.record.id,
                    key: 1,
                    description: props.record.note,
                    rc: props.record.rc,
                    ico: props.record.ico,
                    firstname: props.record.firstname,
                    lastname: props.record.lastname,
                    birthDate: props.record.birthDate?.toString(),
                    typed: props.record.typed,
                    validatedCode: props.record.validatedCode,
                    monitoringCeeId: props.record.id,
                  },
                ]}
              />
            ),
          },
          {
            key: 'searchEpo',
            className: '!p-0',
            label: <CuzkEpoModalButton {...epoButtonProps} />,
          },
          {
            key: 'delete',
            className: '!p-0',
            label: (
              <CeeMonitorDelete
                items={[props.record]}
                refetch={props.reexecuteQuery}
                buttonProps={{
                  size: 'middle',
                  type: 'link',
                  className: 'w-full',
                }}
              />
            ),
          },
        ],
      }),
      [epoButtonProps, props.monitoring, props.record, props.reexecuteQuery]
    )

    const popconfrimProps = useMemo(
      () => ({
        okButtonProps: {
          loading: props.loadingId.includes(props.record.id),
        },
      }),
      [props]
    )

    const onConfirm = useCallback(
      () => props.checkNow([props.record.id]),
      [props]
    )

    const onDetailClick = useCallback(
      () => props.handleExpandRow(props.record.id),
      [props]
    )

    const actionButtons = useMemo(() => {
      switch (props.record.found) {
        case undefined:
        case null:
          return props.record.errorState ? (
            <CeeMonitorDelete
              items={[props.record]}
              refetch={props.reexecuteQuery}
              buttonProps={{
                size: 'middle',
                type: 'link',
              }}
            />
          ) : (
            <PricePopconfirm
              onConfirm={onConfirm}
              itemPrice={pricelist.CEE}
              message={`Vyhledání subjektu v centrální evidenci exekucí je zpoplatněno ${pricelist.CEE} Kr.`}
              popconfrimProps={popconfrimProps}
            >
              <Button
                type="link"
                size="small"
                title="Zkontrolovat subjekt okamžitě"
                className="!pr-1"
              >
                Zkontrolovat hned
              </Button>
            </PricePopconfirm>
          )
        case false:
          return (
            <PdfDrawerPreview
              buttonText="Zobrazit PDF"
              buttonProps={buttonProps}
              drawerTitle="Náhled PDF - potvrzení"
              fileName={
                props.record?.rc ||
                props.record?.ico ||
                props.record?.lastname + ' CEE_DETAIL_REGESTA_DATA'
              }
            >
              <CeeSummaryPdf data={[props.record]} />
            </PdfDrawerPreview>
          )
        case true:
          return [
            <Button
              key="detail"
              onClick={onDetailClick}
              type="link"
              size="small"
              className="!flex items-center !rounded-sm"
            >
              Zobrazit detail
            </Button>,
            <CeeSummaryPdfButton
              key="pdf"
              buttonProps={buttonProps}
              id={props.record.id}
            />,
          ]
        default:
          return <div />
      }
    }, [
      props.record,
      props.reexecuteQuery,
      onConfirm,
      popconfrimProps,
      onDetailClick,
    ])

    const buttonsRender = useCallback(
      (buttons: ReactNode[]) => [actionButtons, buttons[1]],
      [actionButtons]
    )

    return (
      <Dropdown.Button
        icon={<MenuOutlined />}
        menu={menuProps}
        arrow
        type="link"
        className="!flex justify-end items-end space-x-1"
        size="small"
        trigger={clickTrigger}
        buttonsRender={buttonsRender}
      />
    )
  }
