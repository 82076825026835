import { Icon } from '@iconify/react'
import React, { FunctionComponent } from 'react'

import {
  ColorPalleteDeafultOption,
  ColorPalleteKey,
  ColorPalleteOptions,
} from '../../utils/collorPallete'
import { IconPalleteKey, IconPalleteOptions } from '../../utils/iconPallete'

export interface IconPalleteProps {
  pickIcon: (icon: IconPalleteKey) => void
  pickedIcon?: IconPalleteKey
  pickedColor?: ColorPalleteKey
}

export const IconPallete: FunctionComponent<IconPalleteProps> = props => {
  return (
    <div className="flex flex-wrap w-64">
      {Object.keys(IconPalleteOptions).map((icon, index) => {
        const iconKey = icon as IconPalleteKey
        return (
          <div key={index} className="flex items-center justify-center p-1">
            <div
              onClick={() => props.pickIcon(iconKey)}
              className={`${
                ColorPalleteOptions[
                  props.pickedColor || ColorPalleteDeafultOption
                ]
              } w-7 h-7 leading-7 flex justify-center items-center text-center rounded-sm border 
                ${
                  props.pickedIcon === iconKey
                    ? ' opacity-50 border-2'
                    : ' cursor-pointer hover:shadow'
                }`}
            >
              <Icon icon={IconPalleteOptions[iconKey]} width="12" />
            </div>
          </div>
        )
      })}
    </div>
  )
}
