import { InfoCircleOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
} from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { WatchdogChannelFormValues } from '../../../common/watchdogTypes'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { setWatchdogLastChannel } from '../../../redux/watchdogLastChannelSlice'
import { itemHelp, itemRequired } from '../../utils/formHelpers'
import {
  formItemLayout,
  fullItemLayoutProps,
  validateTrigger,
} from '../../utils/layoutConst'
import { useFetchWatchdogProperty } from '../../utils/useFetchWatchdog'
import { useToggleState } from '../../utils/useToggleState'
import {
  watchdogFormHelp,
  watchdogFormRules,
} from '../../utils/watchdogHelpers'

export interface PostServiceCreateRecipientListFormValues {
  listName: string
  description?: string
}

export interface WatchdogChannelModalFormProps {
  channelId?: string
  handleOpen: () => void
}

export const WatchdogChannelModalForm: FunctionComponent<WatchdogChannelModalFormProps> =
  props => {
    const [form] = Form.useForm()
    const dispatch = useAppDispatch()
    const refetch = useFetchWatchdogProperty()

    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const channelData = useAppSelector(state => state.watchdog.channel.data)

    const [showHelp, setShowHelp] = useToggleState(false)

    const initialValues = useMemo(() => {
      const channel = channelData?.find(
        channel => channel.id === props.channelId
      )
      return {
        name: channel?.nazev,
        email: channel?.emaily,
        frequency: channel?.intervalObesilani || 7,
        allowEmpty: channel?.prazdnyEmail || false,
      }
    }, [channelData, props.channelId])

    const handleCreate = useCallback(
      async (formValues: WatchdogChannelFormValues) => {
        try {
          await axios.post(
            `/api/watchdog/channel/create/${tenantId}`,
            {
              name: formValues.name,
              email: formValues.email,
              frequency: formValues.frequency,
              allowEmpty: formValues.allowEmpty,
            },
            {
              withCredentials: true,
            }
          )

          refetch()
          message.success('Nový kanál vytvořen.')
        } catch (error) {
          message.error('Nepodařilo se vytvořit kanál.')
        } finally {
          props.handleOpen()
        }
      },
      [props, refetch, tenantId]
    )

    const handleUpdate = useCallback(
      async (formValues: WatchdogChannelFormValues) => {
        try {
          await axios.post(`/api/watchdog/channel/update/${tenantId}`, {
            channelId: props.channelId,
            name: formValues.name,
            email: formValues.email,
            frequency: formValues.frequency,
            allowEmpty: formValues.allowEmpty,
          })

          dispatch(
            setWatchdogLastChannel({
              id: props.channelId || null,
              name: formValues.name,
            })
          )
          refetch()
          message.success('Kanál byl upraven.')
        } catch (error) {
          message.error('Nepodařilo se upravit kanál.')
        } finally {
          props.handleOpen()
        }
      },
      [dispatch, props, refetch, tenantId]
    )

    return (
      <Form<WatchdogChannelFormValues>
        {...formItemLayout}
        layout="horizontal"
        form={form}
        initialValues={initialValues}
        validateTrigger={validateTrigger}
        onFinish={props.channelId ? handleUpdate : handleCreate}
        className="!mt-4"
      >
        <Form.Item
          label="Název kanálu"
          name="name"
          required
          rules={itemRequired('Název je povinný')}
        >
          <Input autoFocus placeholder="Zadejte název kanálu" />
        </Form.Item>

        <Form.Item
          label="E-mail pro notifikace"
          name="email"
          required
          rules={watchdogFormRules.email}
          help={itemHelp(showHelp, watchdogFormHelp.email)}
        >
          <Input placeholder="E-mail pro notifikace" />
        </Form.Item>

        <Form.Item
          label=" "
          colon={false}
          name="allowEmpty"
          help={itemHelp(showHelp, watchdogFormHelp.allowEmpty)}
          valuePropName="checked"
        >
          <Checkbox>Zasílat prázdné notifikace</Checkbox>
        </Form.Item>

        <Form.Item
          label="Frekvence notifikací"
          name="frequency"
          rules={watchdogFormRules.frequency}
          help={itemHelp(showHelp, watchdogFormHelp.frequency)}
        >
          <InputNumber
            max={999}
            min={0}
            addonAfter="Dní"
            placeholder="Zadejte frekvenci notifikací"
            className="w-full"
          />
        </Form.Item>

        <Divider />

        <Form.Item className="!mb-0" {...fullItemLayoutProps}>
          <div className="flex justify-between">
            <Button
              icon={<InfoCircleOutlined />}
              type="link"
              onClick={setShowHelp}
            >
              {showHelp ? 'Skrýt nápovědu' : 'Zobrazit nápovědu'}
            </Button>
            <div className="space-x-2">
              <Button onClick={props.handleOpen}>Zrušit</Button>

              <Button type="primary" htmlType="submit">
                {props.channelId ? 'Uložit' : 'Vytvořit'}
              </Button>
            </div>
          </div>
        </Form.Item>
      </Form>
    )
  }
