import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import Title from 'antd/es/typography/Title'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import {
  CadastralCheckedValues,
  CadastralOffice,
} from '../../utils/cuzkHelpers'
import { Money } from '../Money/Money'

export interface CadastralWorkplaceSelectionFilterOfficeCheckboxProps {
  office: CadastralOffice
  checkedValues: CadastralCheckedValues
  checkboxData: CadastralOffice[]
  setCheckedValues: (checkedValues: CadastralCheckedValues) => void
}

export const CadastralWorkplaceSelectionFilterOfficeCheckbox: FunctionComponent<CadastralWorkplaceSelectionFilterOfficeCheckboxProps> =
  ({ office, checkedValues, checkboxData, setCheckedValues }) => {
    const handleCheckAllChange = useCallback(
      (regionCode: number, e: CheckboxChangeEvent) => {
        const regionWorkplacesCodes =
          checkboxData
            .find(region => region.kod === regionCode)
            ?.pracoviste.filter(workplace => !workplace.disabled)
            ?.map(workplace => workplace.kod) || []

        const newCheckedValues = {
          ...checkedValues,
          [regionCode]: e.target.checked ? regionWorkplacesCodes : [],
        }
        setCheckedValues(newCheckedValues)
      },
      [checkboxData, checkedValues, setCheckedValues]
    )

    const [totalPrice, enabledWorkplaces] = useMemo(() => {
      const count: [number, number] = [0, 0]
      office.pracoviste.map(workplace => {
        count[0] += workplace.disabled ? 0 : workplace.cena || 0
        count[1] += workplace.disabled ? 0 : 1
      })
      return count
    }, [office.pracoviste])

    return (
      <Checkbox
        rootClassName="[&_span:nth-child(2)]:w-full w-full group"
        indeterminate={
          checkedValues[office.kod]?.length > 0 &&
          checkedValues[office.kod]?.length < enabledWorkplaces
        }
        onChange={e => handleCheckAllChange(office.kod, e)}
        checked={
          !!enabledWorkplaces &&
          checkedValues[office.kod]?.length === enabledWorkplaces
        }
        disabled={!!office.disabled}
      >
        <div className="flex justify-between">
          <Title level={5}>{office.nazev}</Title>
          {!!totalPrice && (
            <div className="flex justify-between space-x-3">
              <div className="font-bold">
                <Money amount={totalPrice} symbol="Kr." decimalNumbers={0} />
              </div>
            </div>
          )}
        </div>
      </Checkbox>
    )
  }
