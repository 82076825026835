import { message } from 'antd'
import { useCallback } from 'react'

import {
  CreateLabelConnectionMutationVariables,
  useCreateLabelConnectionMutation,
} from '../../graphql/generated'

type LabelKeys = Exclude<
  keyof CreateLabelConnectionMutationVariables,
  'labelId'
>
type IdsMap = { [K in LabelKeys]?: string }
type AssignLabelsProps = IdsMap & { labelsId: string[] }

export const useAssignLabels = () => {
  const [, addLabelConnection] = useCreateLabelConnectionMutation()

  const assignLabels = useCallback(
    async (props: AssignLabelsProps) => {
      const { labelsId, ...ids } = props
      if (labelsId.length > 0) {
        try {
          await Promise.all(
            labelsId.map(labelId => addLabelConnection({ labelId, ...ids }))
          )
        } catch (error) {
          message.error('Nepodařilo se přiřadit štítky k záznamům.')
        }
      }
    },
    [addLabelConnection]
  )

  return { assignLabels }
}
