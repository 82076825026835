import { useCallback } from 'react'

import { setExpandedRow } from '../../redux/expandedRowSlice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'

export const useHandleExpandRow = (tableId: string) => {
  const expandedRow = useAppSelector(
    state => state.expandedRow.tables?.[tableId]
  )
  const dispatch = useAppDispatch()

  const handleExpandRow = useCallback(
    (recordId: string) => {
      const newExpandedRowKeys = expandedRow ? [...expandedRow] : []

      const index = newExpandedRowKeys.indexOf(recordId)
      if (index === -1) {
        newExpandedRowKeys.push(recordId)
      } else {
        newExpandedRowKeys.splice(index, 1)
      }
      dispatch(
        setExpandedRow({
          tables: { [tableId]: newExpandedRowKeys },
        })
      )
    },
    [dispatch, expandedRow, tableId]
  )

  return handleExpandRow
}
