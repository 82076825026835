import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

export enum PropertyDataTypes {
  LV = 'lv',
  DRM = 'drm',
  CRM = 'crm',
  RISY = 'risy',
  ARES = 'ares',
  RUIAN = 'ruian',
  TIMELINE = 'timeline',
  LV_GEOMETRY = 'lvGeometry',
  RZP_SUBJECTS = 'rzpSubjects',
  DRM_SUBJECTS = 'drmSubjects',
}

export enum WatchDogDataTypes {
  PROPERTY = 'property',
  CHANNEL = 'channel',
  LAST_CHANNEL = 'lastChannel',
}

export enum FetchStatus {
  FETCHING = 'fetching',
  ERROR = 'error',
  SUCCESS = 'success',
}

export const initialFetchState = {
  fetching: false,
  data: null,
  error: false,
}

export interface AsyncState<T> {
  fetching: boolean
  data: T | null
  error: boolean
}

function handleAsyncActions<T>(
  builder: ActionReducerMapBuilder<{
    data: T | null
    fetching: boolean
    error: boolean
  }>,
  actionType: string
) {
  builder
    .addCase(`${actionType}/${FetchStatus.FETCHING}`, state => {
      state.fetching = true
    })
    .addCase(`${actionType}/${FetchStatus.ERROR}`, state => {
      state.fetching = false
      state.data = null
      state.error = true
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .addCase(`${actionType}/${FetchStatus.SUCCESS}`, (state, action: any) => {
      state.fetching = false
      state.data = action.payload
      state.error = false
    })
}

export default handleAsyncActions
