import React, { FunctionComponent, useMemo, useState } from 'react'

import { useCeeOfficeByNameQuery } from '../../../graphql/generated'
import { CeeOfficeTooltip } from '../CeeOfficeTooltip/CeeOfficeTooltip'

export interface CuzkLvHtmlPopisPravnihoVztahuFragmentProps {
  popis?: string
}

export const CuzkLvHtmlPopisPravnihoVztahuFragment: FunctionComponent<CuzkLvHtmlPopisPravnihoVztahuFragmentProps> =
  props => {
    const [textParts, setTextParts] = useState<{
      beforeName: string
      afterName: string
      name: string
    }>({
      beforeName: '',
      name: '',
      afterName: '',
    })

    const [queryResult] = useCeeOfficeByNameQuery({
      pause: !textParts.name,
      variables: { startsWith: textParts.name },
    })

    const findExecutor = useMemo(() => {
      if (!props.popis) return null
      if (props.popis.includes('exekutor')) {
        const titles = ['JUDr.', 'Mgr.', 'Ing.']
        for (const title of titles) {
          const position = props.popis.indexOf(title)
          if (position !== -1) {
            // Rozdělíme řetězec podle mezer a vezmeme dvě slova za titulem
            const words = props.popis.slice(position).split(' ')
            if (words.length >= 3) {
              const firstWord = words[1].replace(/[.,]/g, '') // Odstraníme tečky a čárky
              const secondWord = words[2].replace(/[.,]/g, '') // Odstraníme tečky a čárky
              // Nahradíme původní titul a jméno za <Tooltip>...</Tooltip>
              const replacement = `${title} ${secondWord} ${firstWord}`
              const beforeTitle = props.popis.slice(0, position)
              const afterName = props.popis.slice(
                position +
                  title.length +
                  firstWord.length +
                  secondWord.length +
                  2
              )
              setTextParts({
                beforeName: beforeTitle,
                name: secondWord + ' ' + firstWord,
                afterName: afterName,
              })
              return (
                <>
                  <span>{beforeTitle}</span>
                  <span className="text-primary">{replacement}</span>
                  <span>{afterName}</span>
                </>
              )
            }
          }
        }
      }
      return props.popis // Pokud nenajde slovo "exekutor" nebo žádný z titulů
    }, [props.popis])

    const executorTitle = useMemo(() => {
      if (queryResult.data?.allCeeOffices?.nodes.length !== 1) return null

      return (
        <>
          <span>{textParts.beforeName}</span>
          <span>
            <CeeOfficeTooltip
              user={queryResult.data?.allCeeOffices?.nodes[0]}
              thumbnail
            />
          </span>
          <span>{textParts.afterName}</span>
        </>
      )
    }, [
      queryResult.data?.allCeeOffices?.nodes,
      textParts.afterName,
      textParts.beforeName,
    ])

    return <span>{executorTitle || findExecutor}</span>
  }
