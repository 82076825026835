import { Icon } from '@iconify/react'
import { Form, Segmented } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import {
  CUZK_PERSON_TYPES,
  CuzkEpoModalFormValues,
} from '../CuzkEpoModalButton/CuzkEpoModalButton'

export interface CuzkEpoFormPersonTypeFragmentProps {
  personType: CuzkEpoModalFormValues['type']
  handleSwitch: (value: string | number) => void
}

export const CuzkEpoFormPersonTypeFragment: FunctionComponent<CuzkEpoFormPersonTypeFragmentProps> =
  props => {
    const options = useMemo(
      () => [
        {
          label: (
            <div
              className={`flex flex-col justify-between items-center p-4 ${
                props.personType === CUZK_PERSON_TYPES.OPO
                  ? ' opacity-50 hover:opacity-80'
                  : ''
              }`}
            >
              <div className="w-9 h-9 rounded-full flex justify-center items-center bg-primaryHover text-white">
                <Icon icon="ant-design:user-outlined" className="text-lg" />
              </div>
              <div>Fyzická osoba</div>
            </div>
          ),
          value: CUZK_PERSON_TYPES.OFO,
        },
        {
          label: (
            <div
              className={`flex flex-col justify-between items-center p-4 ${
                props.personType === CUZK_PERSON_TYPES.OFO
                  ? ' opacity-50 hover:opacity-80'
                  : ''
              }`}
            >
              <div className="w-9 h-9 rounded-full flex justify-center items-center bg-primaryHover text-white">
                <Icon
                  icon="fluent:building-factory-16-regular"
                  className="text-lg"
                />
              </div>
              <div>Právnická osoba</div>
            </div>
          ),
          value: CUZK_PERSON_TYPES.OPO,
        },
      ],
      [props.personType]
    )

    return (
      <Form.Item name="type">
        <Segmented
          onChange={props.handleSwitch}
          className="w-full flex justify-between"
          block
          options={options}
        />
      </Form.Item>
    )
  }
