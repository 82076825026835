function encodeNumber(num: number): string {
  num = num < 0 ? ~(num << 1) : num << 1
  let result = ''
  while (num >= 0x20) {
    result += String.fromCharCode((0x20 | (num & 0x1f)) + 63)
    num >>= 5
  }
  result += String.fromCharCode(num + 63)
  return result
}

function compressPolygon(coordinates: string): string {
  const points = coordinates.split(',').map(coord => {
    const [lat, lng] = coord.split(' ').map(Number)
    return [lat, lng]
  })

  let result = ''
  let lat = 0
  let lng = 0

  for (const point of points) {
    const latNew = Math.round(point[0] * 1e5)
    const lngNew = Math.round(point[1] * 1e5)

    result += encodeNumber(latNew - lat)
    result += encodeNumber(lngNew - lng)

    lat = latNew
    lng = lngNew
  }

  return encodeURIComponent(result)
}

export const formatAndCompressPolygon = (exterior?: string) =>
  exterior ? compressPolygon(exterior) : ''

export const formatPolygon = (exterior?: string) =>
  exterior ? exterior.replace(/,/g, '|').replace(/ /g, ',') : ''
