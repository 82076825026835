import { Icon } from '@iconify/react'
import { Alert, Button, message } from 'antd'
import { BaseButtonProps } from 'antd/es/button/button'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { BASE_URL } from '../../../../ini.json'
import { deliveryMethodList } from '../../../common/postServiceTypes'
import { PostserviceLetterType } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { pricelist } from '../../utils/pricelist'
import { useDeclension } from '../../utils/useDeclension'
import { IncreasingNumber } from '../IncreasingNumber/IncreasingNumber'
import { PricePopconfirm } from '../PricePopconfirm/PricePopconfirm'

export interface PostServiceSendModalFooterProps {
  refetch: () => void
  handleModal: () => void
  recipientsCount: number
  deliveryMethod: PostserviceLetterType
  letterId: string
  buttonProps?: BaseButtonProps
  buttonText?: string
  allowAbroad?: boolean
}

export const PostServiceSendModalFooter: FunctionComponent<PostServiceSendModalFooterProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const userId = useAppSelector(state => state.myProfile.userId)
    const decline = useDeclension()

    const [loading, setLoading] = useState(false)

    const buttonProps = useMemo(
      () => ({
        ok: { disabled: loading, loading: loading },
        cancel: { disabled: loading },
      }),
      [loading]
    )

    const price = useMemo(
      () => ({
        unit: pricelist.postService[props.deliveryMethod] || 0,
        total:
          pricelist.postService[props.deliveryMethod] * props.recipientsCount,
      }),
      [props.recipientsCount, props.deliveryMethod]
    )

    const popConfirmMessage = useMemo(
      () => (
        <span>
          Celková cena za {props.recipientsCount}{' '}
          {decline(props.recipientsCount, 'letter')}{' '}
          {decline(props.recipientsCount, 'sent')} jako <br />
          {deliveryMethodList?.[
            props.deliveryMethod
          ]?.name?.toLowerCase()} je {price.total} Kr.
        </span>
      ),
      [props.recipientsCount, props.deliveryMethod, decline, price.total]
    )

    const handleSendOut = useCallback(async () => {
      setLoading(true)
      try {
        const response = await fetch(
          `${BASE_URL}/api/postService/sendOut/${token}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              tenantId: tenantId,
              userId: userId,
              letterId: props.letterId,
              deliveryMethod: props.deliveryMethod,
            }),
          }
        )

        if (response.status === 200) {
          message.success('Hromadná pošta byla odeslána.')
          props.refetch()
        }
        if (response.status === 401) {
          message.warning('Nejste přihlášen.')
        }
        if (response.status === 402) {
          message.warning('Nemáte dostatek kreditu.')
        }
      } catch (error) {
        message.error('Nepodařilo se odeslat hromadnou poštu.')
      }
      props.handleModal()
      return setLoading(false)
    }, [props, tenantId, token, userId])

    return (
      <div className="flex justify-between items-center">
        <Alert
          className={`!px-2 !py-1`}
          message={
            <div>
              <span className="mr-2">
                Počet adresátů: {props.recipientsCount}
              </span>

              <span className="mr-2">
                Celková cena:{' '}
                <IncreasingNumber
                  className="font-bold"
                  finalNumber={price.total}
                />{' '}
                Kr.
              </span>
            </div>
          }
          type="success"
          showIcon
          icon={<Icon icon="ph:shopping-cart" width="18" />}
        />
        <div className="flex space-x-2">
          <Button
            onClick={props.handleModal}
            className="sm:w-auto w-full"
            {...buttonProps.cancel}
          >
            Zavřít
          </Button>
          <PricePopconfirm
            onConfirm={handleSendOut}
            itemPrice={price.unit}
            totalPrice={price.total}
            popconfrimProps={buttonProps.ok}
            message={popConfirmMessage}
          >
            <Button
              type="primary"
              className="sm:w-auto w-full"
              disabled={!price.total}
              loading={loading}
            >
              Souhlasím a rozeslat
            </Button>
          </PricePopconfirm>
        </div>
      </div>
    )
  }
