import { Drawer, DrawerProps } from 'antd'
import { Feature, GeoJsonProperties, Geometry, Polygon } from 'geojson'
import React, { FunctionComponent, useMemo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { propertyDrawerWrapperStyle } from '../../utils/layoutConst'
import { CadastreMapDetailDrawerFetchError } from '../CadastreMapDetailDrawerFetchError/CadastreMapDetailDrawerFetchError'
import { DetailDrawerArea } from '../DetailDrawerArea/DetailDrawerArea'
import { DetailDrawerBuilding } from '../DetailDrawerBuilding/DetailDrawerBuilding'
import { DetailDrawerPropertyHeader } from '../DetailDrawerPropertyHeader/DetailDrawerPropertyHeader'

export interface CadastreMapDetailDrawerProps {
  open: boolean
  onClose: NonNullable<DrawerProps['onClose']>
  features?: Feature<Geometry, GeoJsonProperties>[]
}

export const CadastreMapDetailDrawer: FunctionComponent<CadastreMapDetailDrawerProps> =
  ({ open, onClose, features }) => {
    const cuzkData = useMemo(
      () => features?.[0] as Feature<Polygon | null, GeoJsonProperties>,
      [features]
    )

    const propertyType = useMemo(() => {
      if (cuzkData?.properties?.layerName === 'Parcela')
        return PROPERTY_TYPES.AREA
      if (cuzkData?.properties?.layerName === 'StavebniObjekt')
        return PROPERTY_TYPES.BUILDING
      return null
    }, [cuzkData])

    return (
      <Drawer
        contentWrapperStyle={propertyDrawerWrapperStyle}
        title={<DetailDrawerPropertyHeader />}
        open={open}
        onClose={onClose}
        destroyOnClose={true}
      >
        {propertyType === PROPERTY_TYPES.AREA ? (
          <DetailDrawerArea cuzkData={cuzkData} />
        ) : propertyType === PROPERTY_TYPES.BUILDING ? (
          <DetailDrawerBuilding cuzkData={cuzkData} />
        ) : (
          <CadastreMapDetailDrawerFetchError />
        )}
      </Drawer>
    )
  }
