import { useEffect, useState } from 'react'

import { useAppSelector } from '../../redux/hooks'

export function useCheckClaims(claimName: string) {
  const claim = useAppSelector(state => state.myProfile.claim)
  const [approved, setApproved] = useState(false)

  useEffect(() => {
    setApproved(!!claim && claim.includes(claimName))
  }, [claim, claimName])

  return approved
}
