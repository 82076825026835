import { Spin } from 'antd'
import React, { FunctionComponent } from 'react'

import { useAppSelector } from '../../../redux/hooks'
import { PropertyDetailAreaChartModalButton } from '../PropertyDetailAreaChartModalButton/PropertyDetailAreaChartModalButton'
import { PropertyDetailFetchError } from '../PropertyDetailFetchError/PropertyDetailFetchError'
import { PropertyDetailLvAreaTable } from '../PropertyDetailLvAreaTable/PropertyDetailLvAreaTable'
import { PropertyDetailLvBuildingTable } from '../PropertyDetailLvBuildingTable/PropertyDetailLvBuildingTable'
import { PropertyDetailLvUnitTable } from '../PropertyDetailLvUnitTable/PropertyDetailLvUnitTable'
import { PropertyDetailOwnershipPropertiesTitle as RowTitle } from '../PropertyDetailOwnershipPropertiesTitle/PropertyDetailOwnershipPropertiesTitle'

export interface PropertyDetailOwnershipProps {
  propertyId: string
}

export const PropertyDetailOwnershipProperties: FunctionComponent<PropertyDetailOwnershipProps> =
  props => {
    const { jednotky, budovy, parcely } = useAppSelector(
      state =>
        state.property.lv.data?.nemovitosti || {
          jednotky: [],
          budovy: [],
          parcely: [],
        }
    )
    const { error, data } = useAppSelector(state => state.property.lv)

    if (error)
      return (
        <RowTitle>
          <PropertyDetailFetchError
            description="Je nám líto, ale nepodařilo se dohledat informace o nemovitostech na listu vlastnictví."
            message="Informace k listu vlastnictví nenalezeny"
          />
        </RowTitle>
      )

    if (data === null)
      return (
        <RowTitle className="text-center text-gray-400 mb-4">
          <Spin
            className="!text-gray-400 w-full animate-pulse"
            tip="Nemovitosti na LV..."
          />
        </RowTitle>
      )

    return (
      <div>
        <RowTitle rootClassName="flex items-baseline space-x-8">
          {parcely.length > 1 && (
            <PropertyDetailAreaChartModalButton parcely={parcely} />
          )}
        </RowTitle>

        <div className="w-full space-y-4">
          {!!jednotky.length && (
            <PropertyDetailLvUnitTable
              propertyId={props.propertyId}
              jednotky={jednotky}
            />
          )}
          {!!budovy.length && (
            <PropertyDetailLvBuildingTable
              propertyId={props.propertyId}
              budovy={budovy}
              parcely={parcely}
            />
          )}
          {!!parcely.length && (
            <PropertyDetailLvAreaTable
              propertyId={props.propertyId}
              parcely={parcely}
              budovy={budovy}
            />
          )}
        </div>
      </div>
    )
  }
