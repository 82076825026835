import { BgColorsOutlined } from '@ant-design/icons'
import { Button, Popover, PopoverProps } from 'antd'
import React, { FunctionComponent } from 'react'

import {
  ColorPalleteDeafultOption,
  ColorPalleteKey,
} from '../../utils/collorPallete'
import {
  IconPalleteDeafultOption,
  IconPalleteKey,
} from '../../utils/iconPallete'
import { useToggleState } from '../../utils/useToggleState'
import { LabelSettingButtonBadge } from '../LabelSettingButtonBadge/LabelSettingButtonBadge'
import { LabelSettingButtonPopover } from '../LabelSettingButtonPopover/LabelSettingButtonPopover'

export interface LabelSettingButtonProps {
  pickedColor?: ColorPalleteKey
  pickedIcon?: IconPalleteKey
  labelName?: string
  labelDescription?: string
  popoverProps: PopoverProps
  onStyleChange: (style: {
    colorKey?: ColorPalleteKey
    iconKey?: IconPalleteKey
  }) => void
}

export const LabelSettingButton: FunctionComponent<LabelSettingButtonProps> =
  props => {
    const [isOpen, , openPopover, closePopover] = useToggleState()

    const initialColor = props.pickedColor || ColorPalleteDeafultOption
    const initialIcon = props.pickedIcon || IconPalleteDeafultOption

    return (
      <Popover
        placement="top"
        open={isOpen}
        content={
          <LabelSettingButtonPopover
            handleClose={closePopover}
            onStyleChange={props.onStyleChange}
            initialColor={initialColor}
            initialIcon={initialIcon}
            labelName={props.labelName}
            labelDescription={props.labelDescription}
          />
        }
        {...props.popoverProps}
      >
        <LabelSettingButtonBadge colorKey={initialColor} iconKey={initialIcon}>
          <Button
            onClick={openPopover}
            type="primary"
            ghost
            icon={<BgColorsOutlined />}
          />
        </LabelSettingButtonBadge>
      </Popover>
    )
  }
