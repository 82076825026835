import { AlertProps } from 'antd'

export interface IcoErrorDescription {
  errorCodeType: string
  title: string
  description: string
  message: string
  type: AlertProps['type']
}

export enum MonitoringInputType {
  CuzkRc = 'ČÚZK - RČ',
  CuzkIco = 'ČÚZK - IČ',
  CuzkName = 'ČÚZK - jméno',
  CeeRc = 'CEE - RČ',
  CeeIco = 'CEE - IČ',
  CeeName = 'CEE - jméno',
}

export enum MonitoringTabType {
  Rc = 'RČ tab',
  Ico = 'IČ tab',
  Name = 'Jméno tab',
}

export enum GenderType {
  M = 'Muž',
  W = 'Žena',
}
