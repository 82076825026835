import { Form, FormInstance, FormProps, message } from 'antd'
import React, { FunctionComponent, useCallback, useState } from 'react'

import { formItemLayout } from '../../../../utils/layoutConst'
import { RuianFilterFormFooter } from '../../../RuianFilterFormFooter/RuianFilterFormFooter'
import { RuianQuery } from '../../util/RuianQuery'

export interface RuianFilterFormProps {
  form: FormInstance
  ruianLayerId: string
  onWhereChange: (where: string) => void
  toggleOpen: () => void
  children: React.ReactNode
  onConfirm: () => void
}

export const RuianFilterForm: FunctionComponent<RuianFilterFormProps> = ({
  form,
  ruianLayerId,
  onWhereChange,
  toggleOpen,
  onConfirm,
  children,
}) => {
  const [where, setWhere] = useState<string | null>(null)
  const [counting, setCounting] = useState(false)
  const [resultCount, setResultCount] = useState<number | null>(null)

  const handleOnValuesChange = useCallback<
    NonNullable<FormProps['onValuesChange']>
  >((_, formValues) => {
    const whereClauses = Object.entries(formValues)
      .filter(([, fieldValue]) => fieldValue !== undefined)
      .map(([fieldName, fieldValue]) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { value, componentType }: any = fieldValue
        switch (componentType) {
          case 'InputNumber':
            if (Array.isArray(value)) {
              const [from, to] = value
              if (from && !to) return `${fieldName} >= ${from}`
              else if (!from && to) return `${fieldName} <= ${to}`
              else if (from && to)
                return `${fieldName} BETWEEN ${from} AND ${to}`
              else return ''
            } else if (value) {
              return `${fieldName} = ${value}`
            }
            break
          case 'Select':
            if (value) {
              return `${fieldName} = ${value}`
            }
            break
          case 'MultiSelect':
          case 'TreeSelect':
            if (value && value.length) {
              return `${fieldName} IN (${value.join(',')})`
            }
            break
        }
        return ''
      })
      .filter(clause => clause !== '')
    setWhere(whereClauses.length > 0 ? whereClauses.join(' AND ') : null)
    setResultCount(null)
  }, [])

  const fetchCount = useCallback(async () => {
    if (where === null) {
      setResultCount(null)
      return
    }
    try {
      setCounting(true)
      const count = await new RuianQuery(ruianLayerId).where(where).count()
      setResultCount(count)
    } catch (error) {
      message.error('Nepodařilo se získat počet výsledků')
    } finally {
      setCounting(false)
    }
  }, [ruianLayerId, where])

  const handleSubmit = useCallback(() => {
    form.validateFields().then(() => {
      onWhereChange(where ?? '')
      toggleOpen()
      onConfirm()
    })
  }, [form, onWhereChange, where, toggleOpen, onConfirm])

  return (
    <Form {...formItemLayout} form={form} onValuesChange={handleOnValuesChange}>
      {children}

      <RuianFilterFormFooter
        counting={counting}
        resultCount={resultCount}
        isFormTouched={form.isFieldsTouched()}
        onCancel={toggleOpen}
        onCalculate={fetchCount}
        onSubmit={handleSubmit}
      />
    </Form>
  )
}
