import axios from 'axios'
import { useCallback } from 'react'

import { PROPERTY_TYPES } from '../../common/drmTypes'
import { PropertyDataTypes } from '../../redux/fetchAction'
import { useAppSelector } from '../../redux/hooks'
import { requestPropertyTimelinePath } from './paths'
import { useDispatchFetch } from './useDispatchFetch'

export const useFetchPropertyTimeline = () => {
  const token = useAppSelector(state => state.myProfile.token)
  const { dispatchError, dispatchFetching, dispatchSuccess } =
    useDispatchFetch()

  const fetchTimeline = useCallback(
    async (propertyId: string, propertyType?: PROPERTY_TYPES) => {
      if (!token || !propertyId || !propertyType)
        return dispatchError(PropertyDataTypes.TIMELINE)

      const requestPath = requestPropertyTimelinePath(
        propertyType,
        token,
        propertyId
      )

      dispatchFetching(PropertyDataTypes.TIMELINE)

      const response = await axios.get(requestPath)
      if (response.status !== 200)
        return dispatchError(PropertyDataTypes.TIMELINE)

      const data = await response.data
      dispatchSuccess(PropertyDataTypes.TIMELINE, {
        ...data,
        type: propertyType,
      })
    },
    [dispatchError, dispatchFetching, dispatchSuccess, token]
  )

  return fetchTimeline
}
