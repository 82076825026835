import React, { FunctionComponent, memo } from 'react'

export interface SubjectTableCellRecipientIconCountProps {
  count?: number
}

export const SubjectTableCellRecipientIconCount: FunctionComponent<SubjectTableCellRecipientIconCountProps> =
  memo(props => {
    return props.count && props.count > 1 ? (
      <span className="transition-colors bg-gray-400 text-white text-xs font-bold absolute -right-1 -top-0.5 flex items-center justify-center pb-[3px] rounded-2xl w-3.5 h-3.5 border border-white group-hover:bg-primaryHover">
        {props.count}
      </span>
    ) : null
  })
