import { useCallback, useMemo, useState } from 'react'

import {
  AuctionMonitoringFilterOnSubmit,
  AuctionMonitoringFilterState,
} from '../../common/auctionMonitoringTypes'
import { AUCTION_STATES, AUCTION_TYPES } from '../../common/auctionScrapeTypes'
import { AuctionListingFilter } from '../../graphql/generated'

export const useAuctionMonitoringFilter = (auctionType: AUCTION_TYPES) => {
  const [filter, setFilter] = useState<AuctionMonitoringFilterState>({
    type: [auctionType],
    state: [AUCTION_STATES.UPCOMING],
    priceFrom: 0,
    priceTo: undefined,
  })

  const handleChange = useCallback<AuctionMonitoringFilterOnSubmit>(updates => {
    setFilter(prevFilter => ({ ...prevFilter, ...updates }))
  }, [])

  const filterQuery = useMemo<AuctionListingFilter>(() => {
    const filterFormConditions: AuctionListingFilter['and'] = [
      { type: { in: filter.type } },
      { state: { in: filter.state } },
      { startDate: { isNull: false } },
    ]
    if (typeof filter.priceFrom === 'number') {
      filterFormConditions.push({
        price: { greaterThanOrEqualTo: filter.priceFrom },
      })
    }
    if (typeof filter.priceTo === 'number') {
      filterFormConditions.push({
        price: { lessThanOrEqualTo: filter.priceTo },
      })
    }
    return { and: filterFormConditions }
  }, [filter])

  return {
    filterQuery,
    filter,
    handleChange,
  }
}
