import { Button, Table } from 'antd'
import { ColumnsType } from 'antd/es/table/interface'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  usePostserviceTemplateTableQuery,
  UuidFilter,
} from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { tablePagination } from '../../utils/layoutConst'
import { postServiceLetterPath } from '../../utils/paths'
import { Min } from '../DateFormat/DateFormat'
import { PostServiceTemplateTableAction } from '../PostServiceTemplateTableAction/PostServiceTemplateTableAction'

export interface PostServiceTemplateTableItem {
  createdAt: Date
  description?: string
  name: string
  id: string
  content: string
}

export interface PostServiceTemplateTableProps {
  idFilter?: UuidFilter
}

export const PostServiceTemplateTable: FunctionComponent = () => {
  const tableId = 'postServiceTemplate-table'
  const tenantId = useAppSelector(state => state.myProfile.tenantId)

  const [dataSource, setDataSource] = useState<PostServiceTemplateTableItem[]>(
    []
  )

  const [queryResult, refetch] = usePostserviceTemplateTableQuery({
    variables: { accountId: tenantId },
  })

  useEffect(() => {
    if (queryResult.data?.allPostserviceTemplates?.nodes?.[0]?.id) {
      setDataSource(
        queryResult.data?.allPostserviceTemplates
          ?.nodes as PostServiceTemplateTableItem[]
      )
    }
  }, [queryResult.data?.allPostserviceTemplates?.nodes])

  const columns = useMemo<ColumnsType<PostServiceTemplateTableItem>>(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        responsive: ['' as Breakpoint],
      },
      {
        title: 'Vytvořeno',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: createdAt => <Min>{createdAt}</Min>,
        defaultSortOrder: 'descend',
        className: 'w-36',
        sorter: {
          compare: (a, b) =>
            a.createdAt.toString().localeCompare(b.createdAt.toString()),
        },
      },
      {
        title: 'Název',
        dataIndex: 'name',
        key: 'name',
        className: 'w-1/5',
        sorter: {
          compare: (a, b) => a.name.localeCompare(b.name),
        },
      },
      {
        title: 'Popis',
        dataIndex: 'description',
        key: 'description',
        ellipsis: true,
        sorter: {
          compare: (a, b) =>
            a.description && b.description
              ? a.description.localeCompare(b.description)
              : -1,
        },
      },
      {
        title: 'Akce',
        dataIndex: 'action',
        key: 'action',
        className: 'w-48',

        render: (_, record) => {
          return (
            <PostServiceTemplateTableAction
              reexecuteQuery={refetch}
              record={record}
            />
          )
        },
      },
    ],
    [refetch]
  )

  /* const expandable = useMemo<
      ExpandableConfig<PostServiceRecipientListItem>
    >(() => {
      return {
        expandedRowKeys,
        rowExpandable: record => record.recipients.totalCount > 0,
        expandedRowRender: record => (
          <PostServiceRecipientListTableExpandedRow
            listId={record.id}
            name={record.name}
            refetch={refetch}
          />
        ),
        columnTitle: (
          <ExpandColumnTitle
            allKeys={dataSource.map(record => record.id.toString())}
            tableId={tableId}
          />
        ),
        expandIcon: ({ expanded, record }) =>
          record.recipients.totalCount > 0 ? (
            <ExpandIcon
              expanded={expanded}
              recordId={record.id.toString()}
              tableId={tableId}
              expandable={!!record.id}
            />
          ) : null,
      }
    }, [dataSource, expandedRowKeys, refetch]) */

  const locale = useMemo(
    () => ({
      emptyText: !queryResult.fetching && (
        <div className="my-8">
          <div className="mb-2 text-gray-400">
            Zatím nemáte vytvořené žádné šablony pro hromadnou poštu.
          </div>
          <Link to={postServiceLetterPath()}>
            <Button type="primary">Vytvořit první šablonu dopisu</Button>
          </Link>
        </div>
      ),
    }),
    [queryResult.fetching]
  )

  return (
    <Table
      locale={locale}
      id={tableId}
      dataSource={dataSource}
      columns={columns}
      loading={queryResult.fetching}
      bordered
      pagination={tablePagination}
    />
  )
}
