import { Form, InputNumber } from 'antd'
import React, { FunctionComponent } from 'react'

export interface CadastreMapInsolvencyFilterFormRangeFragmentProps {
  type: 'parcel' | 'ownership'
}

export const CadastreMapInsolvencyFilterFormRangeFragment: FunctionComponent<CadastreMapInsolvencyFilterFormRangeFragmentProps> =
  props => {
    return (
      <div className="grid grid-cols-2 gap-x-2">
        <Form.Item name={[props.type, 'from']} className="!mb-0">
          <InputNumber
            addonBefore="Od"
            placeholder="0"
            min={0}
            className="w-full"
          />
        </Form.Item>

        <Form.Item
          name={[props.type, 'to']}
          dependencies={[[props.type, 'from']]}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !value ||
                  (getFieldValue([props.type, 'from']) || 0) <= value
                ) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error('Hodnota musí být vyšší než "Od"')
                )
              },
            }),
          ]}
          className="!mb-0"
        >
          <InputNumber
            min={0}
            addonBefore="Do"
            placeholder="∞"
            className="w-full"
          />
        </Form.Item>
      </div>
    )
  }
