import { Tooltip } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import {
  Identifier,
  IdentifierValues,
  Register,
  RegisterGroup,
  RegisterGroupMap,
  RegisterIdentifierMap,
} from '../../utils/dynamicSearchTooltip'
import { tooltipHelpUnderline } from '../../utils/layoutConst'
import { DynamicSearchTooltipEpoButton } from '../DynamicSearchTooltipEpoButton/DynamicSearchTooltipEpoButton'
import { DynamicSearchTooltipMonitorSubject } from '../DynamicSearchTooltipMonitorSubject/DynamicSearchTooltipMonitorSubject'
import { SearchOnWeb } from '../SearchOnWeb/SearchOnWeb'

interface DynamicSearchTooltipProps {
  identifierType: Identifier
  identifier: IdentifierValues
  excludedRegisters?: Register[] | typeof RegisterGroupMap[RegisterGroup]
  children: React.ReactNode
}

export const DynamicSearchTooltip: FunctionComponent<DynamicSearchTooltipProps> =
  ({ identifierType, identifier, excludedRegisters = [], children }) => {
    const [open, setOpen] = useState<boolean | undefined>(undefined)
    const handleClose = useCallback(() => setOpen(false), [])
    const handleReset = useCallback(() => setOpen(undefined), [])

    const registersToShow = useMemo(() => {
      const registersByIdentifier = Object.entries(RegisterIdentifierMap)
        .filter(([, id]) => id === identifierType)
        .map(([register]) => register as Register)

      return registersByIdentifier.filter(
        register => !excludedRegisters.includes(register)
      )
    }, [identifierType, excludedRegisters])

    const renderButtons = useMemo(() => {
      return registersToShow.map((register, index) => (
        <span key={register} onClick={handleClose}>
          {(RegisterGroupMap[RegisterGroup.CUZK].includes(register) ||
            RegisterGroupMap[RegisterGroup.CEE].includes(register)) && (
            <DynamicSearchTooltipMonitorSubject
              identifier={identifier}
              register={register}
            />
          )}
          {RegisterGroupMap[RegisterGroup.EPO].includes(register) && (
            <DynamicSearchTooltipEpoButton
              identifier={identifier}
              register={register}
            />
          )}
          {(register === Register.IsirIco ||
            register === Register.IsirRc ||
            register === Register.AresIco) && (
            <SearchOnWeb identifierValues={identifier} register={register} />
          )}
          {register === Register.Google && (
            <SearchOnWeb identifierValues={identifier} register={register}>
              GOOGLE
            </SearchOnWeb>
          )}
          {index !== registersToShow.length - 1 && (
            <span className="text-gray-500 mx-2">|</span>
          )}
        </span>
      ))
    }, [handleClose, identifier, registersToShow])

    return (
      <Tooltip
        trigger="click"
        open={open}
        title={renderButtons}
        className={tooltipHelpUnderline}
      >
        <span onClick={handleReset} title="Kliknutím zobrazíte možnosti">
          {children}
        </span>
      </Tooltip>
    )
  }
