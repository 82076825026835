import { Form, Select } from 'antd'
import React, { FunctionComponent } from 'react'

import { europeCountries } from '../../utils/countriesList'
import { postServiceFormHelp } from '../../utils/cuzkMonitorRcFormHelp'

interface PostServiceAddRecipientFormCountryFragmentProps {
  showHelp: boolean
}

const rules = [{ required: true, message: 'Zadejte název státu' }]

const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

const options =
  europeCountries?.map(country => ({
    label: country.cs,
    value: country.iso,
  })) || []

export const PostServiceAddRecipientFormCountryFragment: FunctionComponent<PostServiceAddRecipientFormCountryFragmentProps> =
  ({ showHelp }) => {
    return (
      <Form.Item
        name="country"
        label="Stát"
        rules={rules}
        help={(showHelp && postServiceFormHelp.country) || undefined}
      >
        <Select
          placeholder="Vyberte stát ze seznamu"
          allowClear
          filterOption={filterOption}
          showSearch
          options={options}
        />
      </Form.Item>
    )
  }
