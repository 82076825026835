import { message } from 'antd'
import dayjs from 'dayjs'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { DrmJednotkaResponse, PROPERTY_TYPES } from '../../../common/drmTypes'
import { LvApiResponse } from '../../../common/lvTypes'
import { monitUnitTitleName } from '../../../common/monitTypes'
import { RuianUnitResponse } from '../../../common/ruianTypes'
import { safeJsonParsing } from '../../../common/safeJsonParsing'
import { useAppSelector } from '../../../redux/hooks'
import { requestPath, requestPropertyRuianPath } from '../../utils/paths'
import { PriceEstimateFormValues } from '../PriceEstimateForm/PriceEstimateForm'
import {
  FETCH_ERROR_TYPES,
  PriceEstimateLayout,
} from '../PriceEstimateLayout/PriceEstimateLayout'

export interface PriceEstimateUnitProps {
  setTitle: (title: string) => void
  propertyId: string
}

export interface ExtendedUnitRuian extends PriceEstimateFormValues {
  nazev?: string
  druhKonstrukce?: string
  zpusobVytapeni?: string
  vBudove?: RuianUnitResponse['vBudove']
}

export const PriceEstimateUnit: FunctionComponent<PriceEstimateUnitProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const [ruianData, setRuianData] = useState<ExtendedUnitRuian>()
    const [lvData, setLvData] = useState<LvApiResponse & DrmJednotkaResponse>()
    const [fetchError, setFetchError] = useState<FETCH_ERROR_TYPES>()

    const getUnitNumber = useCallback((cisloJednotky: string) => {
      const slashIndex = cisloJednotky.indexOf('/')
      return slashIndex !== -1
        ? parseInt(cisloJednotky.substring(slashIndex + 1))
        : undefined
    }, [])

    const fetchData = useCallback(async () => {
      try {
        const response = await fetch(
          requestPropertyRuianPath(PROPERTY_TYPES.UNIT, token, props.propertyId)
        )
        const data: RuianUnitResponse = await response?.json()
        const unitNumber = getUnitNumber(data?.cisloJednotky || '')

        const unitTitle = `${
          data.adresniMista?.[0]?.formatovanaAdresa
            ? monitUnitTitleName[
                (data?.zpusobVyuziti?.kod ||
                  0) as keyof typeof monitUnitTitleName
              ] +
              (unitNumber ? ' č.' + unitNumber + ' - ' : '') +
              data.adresniMista[0]?.formatovanaAdresa
            : data.nazev
        }`

        setRuianData({
          datum: dayjs(),
          kod_obce: data.obec.kod,
          typ_nemovitosti: 2,
          title: `Cenový odhad ${unitTitle}`,
          druh_konstrukce: data.druhKonstrukce?.kod === 4 ? 1 : 0, // 1 = panel, 0 = ostatni
          podlazi_umisteni: (unitNumber || 0) > 3 ? 2 : 1, // 1 = přízemí, 2 = není přízemí
          lat: data.definicniBod?.lat,
          lon: data.definicniBod?.long,
          nazev: data.nazev,
          zpusobVytapeni: data.zpusobVytapeni?.nazev,
          druhKonstrukce: data.druhKonstrukce?.nazev,
          vBudove: data.vBudove,
        })
        props.setTitle(` ${unitTitle}`)
      } catch (error) {
        setFetchError(FETCH_ERROR_TYPES.RUIAN)
        message.error('Došlo k chybě při zpracování požadavku.')
      }
      try {
        const drmResponse = await fetch(
          requestPropertyRuianPath(PROPERTY_TYPES.UNIT, token, props.propertyId)
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const drmData: any = await drmResponse?.json()
        const lv = drmData?.lv
        const katastralniUzemiKod = drmData?.katastralniUzemi?.kod

        const lvResponse = await fetch(
          `${requestPath()}/lv/${tenantId}/${token}/${katastralniUzemiKod}/${lv}`
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const lvData: any = await lvResponse?.json()

        const lvGeometryResponse = await fetch(
          `${requestPath()}/lvGeometry/${tenantId}/${token}/${katastralniUzemiKod}/${lv}`
        )
        const lvGeometryTextResponse = await lvGeometryResponse.text()
        const lvGeometryData =
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          safeJsonParsing<any>(lvGeometryTextResponse, []) ||
          lvGeometryTextResponse

        Object.keys(lvData?.nemovitosti).forEach(key => {
          if (lvGeometryData[key]) {
            lvData.nemovitosti[key] = lvData?.nemovitosti[key].map(
              (nemovitost: { id: string }) => {
                const vymera = lvGeometryData[key].find(
                  (item: { id: string }) => item.id === nemovitost.id
                )
                return vymera ? { ...nemovitost, ...vymera } : nemovitost
              }
            )
          }
        })

        setLvData({ ...lvData, ...drmData, ...lvGeometryData })
      } catch (error) {
        setFetchError(FETCH_ERROR_TYPES.LV)
        message.error('Došlo k chybě při načítání dat.')
      }
    }, [getUnitNumber, props, tenantId, token])

    useEffect(() => {
      if (!ruianData) {
        fetchData()
      }
    }, [ruianData, fetchData])

    return (
      <PriceEstimateLayout
        lvData={lvData}
        ruianData={ruianData}
        propertyId={props.propertyId}
        propertyType={PROPERTY_TYPES.UNIT}
        fetchError={fetchError}
      />
    )
  }
