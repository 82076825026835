import { SnippetsOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import React, { FunctionComponent, useCallback, useState } from 'react'

import { PostServiceTemplateSelect } from '../PostServiceTemplateSelect/PostServiceTemplateSelect'

export interface PostServiceTemplateButtonProps {
  setContent: (content: string) => void
}

export const PostServiceTemplateButton: FunctionComponent<PostServiceTemplateButtonProps> =
  props => {
    const [open, setOpen] = useState(false)

    const handleModal = useCallback(() => {
      setOpen(prev => !prev)
    }, [])

    const handleChange = useCallback(
      (content: string) => {
        props.setContent(content)
        setOpen(false)
      },
      [props]
    )

    return (
      <>
        <Button type="primary" ghost onClick={handleModal}>
          Použít šablonu
          <SnippetsOutlined className="ml-2" />
        </Button>
        <Modal
          width={800}
          title="Výběr šablony hromadné korespondence"
          open={open}
          onCancel={handleModal}
          okText="Vybrat šablonu"
        >
          {open && <PostServiceTemplateSelect handleChange={handleChange} />}
        </Modal>
      </>
    )
  }
