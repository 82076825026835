import { Divider } from 'antd'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import {
  CadastralCheckedValues,
  CadastralOffice,
} from '../../utils/cuzkHelpers'
import { CadastralSelectionSearchBar } from '../CadastralSelectionSearchBar/CadastralSelectionSearchBar'
import { CadastralSelectionSelectAllButon } from '../CadastralSelectionSelectAllButon/CadastralSelectionSelectAllButon'
import { CadastralWorkplaceSelectionFilter } from '../CadastralWorkplaceSelectionFilter/CadastralWorkplaceSelectionFilter'
import { CadastralWorkplaceSelectionFooter } from '../CadastralWorkplaceSelectionFooter/CadastralWorkplaceSelectionFooter'

export interface CadastralWorkplaceSelectionProps {
  checkboxData: CadastralOffice[]
  selectedValues: CadastralCheckedValues
  toggleModalVisibility: () => void
  onSubmit: (selected: number[] | null) => void
}

export const CadastralWorkplaceSelection: FunctionComponent<CadastralWorkplaceSelectionProps> =
  ({ toggleModalVisibility, checkboxData, selectedValues, onSubmit }) => {
    //const [disabledVisible, toggleDisabledVisible] = useToggleState(false) // ready for the future license order form
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [filteredRegions, setFilteredRegions] = useState(checkboxData)
    const [checkedValues, setCheckedValues] =
      useState<CadastralCheckedValues>(selectedValues)

    useEffect(() => {
      setCheckedValues(selectedValues)
    }, [selectedValues])

    const findOffice = useCallback(
      (codes: number[]) =>
        checkboxData.filter(
          office =>
            codes.includes(office.kod) ||
            office.pracoviste.some(workplace => codes.includes(workplace.kod))
        ),
      [checkboxData]
    )

    const handleMatching = useCallback(
      (matchCodes: number[]) => {
        setFilteredRegions(findOffice(matchCodes))
      },
      [findOffice]
    )

    const checkFilteredCodes = useCallback(
      (filteredCodes: number[]) => {
        const matchingOffices = findOffice(filteredCodes)

        if (matchingOffices.length === 1) {
          const matchingOffice = matchingOffices[0]
          const matchingWorkplaces = matchingOffice.pracoviste.filter(
            workplace => filteredCodes.includes(workplace.kod)
          )
          if (matchingWorkplaces.length === 1) {
            setCheckedValues(prevValues => ({
              ...prevValues,
              [matchingOffice.kod]: Array.from(
                new Set([
                  ...(prevValues[matchingOffice.kod] || []),
                  matchingWorkplaces[0].kod,
                ])
              ),
            }))
          } else {
            setCheckedValues(prevValues => ({
              ...prevValues,
              [matchingOffice.kod]: matchingOffice.pracoviste.map(
                workplace => workplace.kod
              ),
            }))
          }

          setSearchTerm('')
          setFilteredRegions(checkboxData)
        }
      },
      [findOffice, checkboxData]
    )

    return (
      <>
        <div className="flex space-x-4 mb-8">
          <CadastralSelectionSearchBar
            handleCheckCodes={checkFilteredCodes}
            checkboxData={checkboxData}
            handleMatching={handleMatching}
            setSearchTerm={setSearchTerm}
          />
          {/* 
          <Button disabled={!disabled.count} onClick={toggleDisabledVisible}> // ready for the future license order form
            {disabled.text}
          </Button>
           */}
          <CadastralSelectionSelectAllButon
            checkedValues={checkedValues}
            filteredRegions={filteredRegions}
            setCheckedValues={setCheckedValues}
          />
        </div>
        <Divider />
        <CadastralWorkplaceSelectionFilter
          // disabledVisible={disabledVisible} // ready for the future license order form
          filteredRegions={filteredRegions}
          setCheckedValues={setCheckedValues}
          checkedValues={checkedValues}
          searchTerm={searchTerm}
        />
        <Divider />
        <CadastralWorkplaceSelectionFooter
          checkboxData={checkboxData}
          checkedValues={checkedValues}
          toggleModalVisibility={toggleModalVisibility}
          onSubmit={onSubmit}
        />
      </>
    )
  }
