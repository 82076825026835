import { Button, Tabs } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { ColorPalleteKey } from '../../utils/collorPallete'
import { IconPalleteKey } from '../../utils/iconPallete'
import { ColorPalette } from '../ColorPalette/ColorPalette'
import { IconPallete } from '../IconPallete/IconPallete'
import { NoteIcon } from '../NoteIcon/NoteIcon'

export interface NoteIconSettingButtonPopoverProps {
  handleOpen: () => void
  onStyleChange: (style: {
    colorKey?: ColorPalleteKey
    iconKey?: IconPalleteKey
  }) => void
  initialIcon?: IconPalleteKey
  initialColor?: ColorPalleteKey
}

export const NoteIconSettingButtonPopover: FunctionComponent<NoteIconSettingButtonPopoverProps> =
  props => {
    const [pickedIcon, setPickedIcon] = useState<undefined | ColorPalleteKey>(
      props.initialIcon
    )
    const [pickedColor, setPickedColor] = useState<undefined | ColorPalleteKey>(
      props.initialColor
    )

    const saveLabelStyle = useCallback(() => {
      props.onStyleChange({ colorKey: pickedColor, iconKey: pickedIcon })
      props.handleOpen()
    }, [pickedColor, pickedIcon, props])

    const items = useMemo(() => {
      return [
        {
          label: 'Barva',
          key: '1',
          children: (
            <ColorPalette
              pickColor={setPickedColor}
              pickedColor={pickedColor}
            />
          ),
        },
        {
          label: 'Ikona',
          key: '2',
          children: (
            <IconPallete
              pickedColor={pickedColor}
              pickIcon={setPickedIcon}
              pickedIcon={pickedIcon}
            />
          ),
        },
      ]
    }, [pickedColor, pickedIcon])

    return (
      <div className="w-64">
        <Tabs defaultActiveKey="1" items={items} />
        <hr className="my-3" />
        <div className="justify-end items-center flex space-x-2 pl-1 pr-2 pb-0.5">
          <div className="flex w-full">
            <NoteIcon colorKey={pickedColor} iconKey={pickedIcon} />
          </div>
          <Button onClick={props.handleOpen}>Zrušit</Button>
          <Button
            disabled={!pickedColor && !pickedIcon}
            type="primary"
            onClick={saveLabelStyle}
          >
            Potvrdit
          </Button>
        </div>
      </div>
    )
  }
