import axios from 'axios'
import { Feature, GeoJsonProperties, Polygon } from 'geojson'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { RuianBuildingResponse } from '../../../common/ruianTypes'
import { useAppSelector } from '../../../redux/hooks'
import { requestPropertyRuianPath } from '../../utils/paths'
import { useFetchPropertyDetail } from '../../utils/useFetchPropertyDetail'
import { CadastreMapDetailDrawerFetchError } from '../CadastreMapDetailDrawerFetchError/CadastreMapDetailDrawerFetchError'
import { DetailDrawerPropertyContent } from '../DetailDrawerPropertyContent/DetailDrawerPropertyContent'
import { DetailDrawerPropertyImage } from '../DetailDrawerPropertyImage/DetailDrawerPropertyImage'
import { DetailDrawerPropertySkeleton } from '../DetailDrawerPropertySkeleton/DetailDrawerPropertySkeleton'
import { DetailDrawerPropertyTitle } from '../DetailDrawerPropertyTitle/DetailDrawerPropertyTitle'

export interface DetailDrawerBuildingProps {
  cuzkData?: Feature<Polygon | null, GeoJsonProperties>
}

export const DetailDrawerBuilding: FunctionComponent<DetailDrawerBuildingProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)
    const [error, setError] = useState(false)
    const [propertyId, setPropertyId] = useState<string | null>(null)

    useFetchPropertyDetail(propertyId || '', PROPERTY_TYPES.BUILDING)

    const getBuildingIdByRuianData = useCallback(async () => {
      try {
        const response = await axios.get<RuianBuildingResponse>(
          requestPropertyRuianPath(
            PROPERTY_TYPES.BUILDING,
            token,
            props.cuzkData?.properties?.kod
          )
        )

        if (response.data) setPropertyId(response.data?.id || null)
        else setError(true)
      } catch (error) {
        setError(true)
      }
    }, [props.cuzkData, token])

    useEffect(() => {
      if (props.cuzkData && propertyId === null) getBuildingIdByRuianData()
    }, [getBuildingIdByRuianData, propertyId, props.cuzkData])

    if (error) return <CadastreMapDetailDrawerFetchError />
    if (!props.cuzkData?.properties?.kod || !propertyId) {
      return <DetailDrawerPropertySkeleton />
    }

    return (
      <>
        <DetailDrawerPropertyTitle
          propertyId={propertyId}
          propertyType={PROPERTY_TYPES.BUILDING}
        />

        <DetailDrawerPropertyImage
          propertyId={propertyId}
          propertyType={PROPERTY_TYPES.BUILDING}
          rings={props.cuzkData?.geometry?.coordinates}
        />

        <DetailDrawerPropertyContent propertyId={propertyId} />
      </>
    )
  }
