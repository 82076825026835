import { FilterOutlined } from '@ant-design/icons'
import { Button, Divider, Form, Modal } from 'antd'
import React, { FunctionComponent, useEffect, useState } from 'react'

import { useToggleState } from '../../utils/useToggleState'
import { RuianFilterForm } from '../CadastreMap/components/CadastreMapRuianFilterForm/CadastreMapRuianFilterForm'
import { RuianInput } from '../CadastreMap/components/CadastreMapRuianInput/CadastreMapRuianInput'
import { useCsvZip } from '../CadastreMap/hooks/useCsvZip'
import { RuianLayer } from '../CadastreMap/types/RuianLayers'

export interface CadastreMapBuildingLayerControlFilterModalButtonProps {
  onWhereChange: (where: string) => void
  onConfirm: () => void
}

type EnumRelationship = {
  ZPVYBU_KOD: number
  TYPBUD_KOD: number
}

const ruianLayerId = RuianLayer.STAVEBNI_OBJEKT

export const CadastreMapBuildingLayerControlFilterModalButton: FunctionComponent<CadastreMapBuildingLayerControlFilterModalButtonProps> =
  props => {
    const [form] = Form.useForm()
    const typStavbyKod = Form.useWatch('typstavebnihoobjektukod', form)
    const zpusobVyuzitiStavby = Form.useWatch('zpusobvyuzitikod', form)

    const [connectionsIsLoading, connections] = useCsvZip<EnumRelationship>(
      'https://services.cuzk.cz/sestavy/cis/SC_TYPB_ZPVYB.zip'
    )

    const [open, toggleOpen] = useToggleState()

    const [validTypStavbyKod, setValidTypStavbyKod] = useState<number[] | null>(
      null
    )
    const [validZpusobVyuzitiStavby, setValidZpusobVyuzitiStavby] = useState<
      number[] | null
    >(null)

    useEffect(() => {
      if (connectionsIsLoading) return
      if (!typStavbyKod?.value) setValidZpusobVyuzitiStavby(null)
      if (!zpusobVyuzitiStavby?.value) setValidTypStavbyKod(null)

      if (typStavbyKod?.value) {
        const validZpusobVyuzitiStavby = connections
          .filter(conn => conn.TYPBUD_KOD === typStavbyKod.value)
          .map(conn => conn.ZPVYBU_KOD)
        setValidZpusobVyuzitiStavby(validZpusobVyuzitiStavby)
      } else if (zpusobVyuzitiStavby?.value) {
        const validTypStavby = connections
          .filter(conn => conn.ZPVYBU_KOD === zpusobVyuzitiStavby.value)
          .map(conn => conn.TYPBUD_KOD)
        setValidTypStavbyKod(validTypStavby)
      }
    }, [connectionsIsLoading, connections, typStavbyKod, zpusobVyuzitiStavby])

    return (
      <div>
        <Button
          className="hover:!bg-white"
          icon={<FilterOutlined />}
          onClick={toggleOpen}
          title="Filtrovat výběr budov"
        />

        <Modal
          title="Filtr zobrazení budov"
          open={open}
          onCancel={toggleOpen}
          footer={null}
          width={875}
        >
          <RuianFilterForm
            form={form}
            onWhereChange={props.onWhereChange}
            ruianLayerId={ruianLayerId}
            toggleOpen={toggleOpen}
            onConfirm={props.onConfirm}
          >
            <RuianInput
              ruianLayerId={ruianLayerId}
              fieldName="typstavebnihoobjektukod"
              forceComponentType="Select"
              validValues={validTypStavbyKod}
            />
            <RuianInput
              ruianLayerId={ruianLayerId}
              fieldName="zpusobvyuzitikod"
              forceComponentType="Select"
              validValues={validZpusobVyuzitiStavby}
            />
            <Divider />
            <RuianInput
              ruianLayerId={ruianLayerId}
              fieldName="zastavenaplocha"
            />
            <RuianInput
              ruianLayerId={ruianLayerId}
              fieldName="podlahovaplocha"
            />
            <RuianInput
              ruianLayerId={ruianLayerId}
              fieldName="obestavenyprostor"
            />
            <Divider />
            <RuianInput ruianLayerId={ruianLayerId} fieldName="pocetpodlazi" />
            <RuianInput ruianLayerId={ruianLayerId} fieldName="pocetbytu" />
            <Divider />
            <RuianInput
              ruianLayerId={ruianLayerId}
              fieldName="druhkonstrukcekod"
            />
            <RuianInput
              ruianLayerId={ruianLayerId}
              fieldName="zpusobvytapenikod"
            />
            <RuianInput
              ruianLayerId={ruianLayerId}
              fieldName="pripojenikanalizacekod"
            />
            <RuianInput
              ruianLayerId={ruianLayerId}
              fieldName="pripojeniplynkod"
            />
            <RuianInput
              ruianLayerId={ruianLayerId}
              fieldName="pripojenivodovodkod"
            />
            <RuianInput
              ruianLayerId={ruianLayerId}
              fieldName="vybavenivytahemkod"
            />
          </RuianFilterForm>
        </Modal>
      </div>
    )
  }
