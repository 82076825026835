import { Button, ButtonProps, Modal } from 'antd'
import React, { FunctionComponent } from 'react'

import { useToggleState } from '../../utils/useToggleState'
import { WatchdogChannelModalForm } from '../WatchdogChannelModalForm/WatchdogChannelModalForm'

export interface WatchdogChannelModalButton {
  buttonProps?: ButtonProps
  buttonText?: string
  channelId?: string
}

export const WatchdogChannelModalButton: FunctionComponent<WatchdogChannelModalButton> =
  props => {
    const [open, toggleModal] = useToggleState()

    return (
      <>
        <Button
          type="primary"
          onClick={toggleModal}
          className="sm:w-auto w-full"
          {...props.buttonProps}
        >
          {props.buttonText || 'Vytvořit nový kanál'}
        </Button>
        <Modal
          title={props.channelId ? 'Editovat kanál' : 'Vytvořit nový kanál'}
          open={open}
          onCancel={toggleModal}
          footer={null}
          width={600}
        >
          <WatchdogChannelModalForm
            channelId={props?.channelId}
            handleOpen={toggleModal}
          />
        </Modal>
      </>
    )
  }
