import { InfoCircleOutlined } from '@ant-design/icons'
import { Alert, Button } from 'antd'
import React, { FunctionComponent, memo } from 'react'

import { useToggleState } from '../../utils/useToggleState'
import { Fade } from '../Fade/Fade'

export interface AreaUseSelectionAlertProps {
  showAlert: boolean
}

export const AreaUseSelectionAlert: FunctionComponent<AreaUseSelectionAlertProps> =
  memo(props => {
    const [help, toggleHelp] = useToggleState()
    return (
      <div className="min-h-[42px]">
        <Fade show={props.showAlert}>
          <Alert
            type="info"
            className="!bg-teal-50/50"
            message={
              <div>
                <div className="flex justify-between items-center">
                  <div>
                    <InfoCircleOutlined className="text-primary mr-2" />
                    Výběr konkrétních způsobů využití odstraní z výsledků
                    pozemky, u kterých katastr nemovitostí zp. využití
                    neeviduje.
                  </div>
                  <Button size="small" type="link" onClick={toggleHelp}>
                    {help ? 'Skrýt nápovědu' : 'Zobrazit nápovědu'}
                  </Button>
                </div>
                {help && (
                  <div className="mt-2 animate-fadeIn">
                    <div className="flex space-x-2">
                      <div className="font-semibold w-16">Info:</div>Pouze u
                      druhů &quot;vodní plocha&quot; a &quot;ostatní
                      plocha&quot; eviduje katastr vždy také způsob využití, u
                      ostatních druhů pozemků nemusí být způsob využití uveden.
                    </div>
                    <div className="flex space-x-2">
                      <div className="font-semibold w-16">Pozor:</div>Pokud ve
                      filtru vyberete jen určité zp. využití, bude tento
                      parametr brán jako povinný a pozemky bez zp. využití se
                      nezobrazí.
                    </div>
                    <div className="flex space-x-2">
                      <div className="font-semibold w-16">Příklad:</div>Pokud
                      vyberete zároveň druh pozemku &quot;orná půda&quot; a
                      &quot;ostatní plocha&quot; + způsob využití
                      &quot;zeleň&quot;, ve výsledcích se nevrátí žádné pozemky
                      orné půdy, neboť orná půda nemůže mít způsob využití
                      &quot;zeleň&quot;.
                    </div>
                    <div className="flex space-x-2">
                      <div className="font-semibold w-16">Tip:</div>Pokud
                      vyberete všechny způsoby využití (nebo nevyberete žádný),
                      budou se zobrazovat všechny pozemky včetně těch, které
                      nemají způsob využití evidovaný.
                    </div>
                  </div>
                )}
              </div>
            }
          />
        </Fade>
      </div>
    )
  })
