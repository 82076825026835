import { AimOutlined, CloseOutlined } from '@ant-design/icons'
import { Spin, Tooltip } from 'antd'
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { AdresniMista, DefinicniBod } from '../../../common/ruianTypes'
import { getDistances } from '../../utils/getDistance'
import { CUZK_PERSON_TYPES } from '../CuzkEpoModalButton/CuzkEpoModalButton'
import { formatCurrencyNumber, Money } from '../Money/Money'

export interface DistanceTableCellProps {
  addressCode?: number
  addressPoint?: DefinicniBod
  referenceCodes?: AdresniMista[]
  referencePoint?: DefinicniBod
  personType?: keyof typeof CUZK_PERSON_TYPES
}

export const DistanceTableCell = memo<DistanceTableCellProps>(props => {
  const [distance, setDistance] = useState<number | undefined | null>(null)
  const isFetching = useRef(false)

  const checkSameAddress = useMemo(() => {
    return props.referenceCodes?.some(
      adresniMisto => adresniMisto.kod === props.addressCode
    )
  }, [props.referenceCodes, props.addressCode])

  const fetchDistance = useCallback(async () => {
    if (!props.addressCode || isFetching.current) return

    isFetching.current = true
    const fetchedDistance = await getDistances({
      referenceAddressCode: props.referenceCodes?.[0]?.kod,
      referenceCoordinates: props.referencePoint
        ? {
            x: props.referencePoint.lat,
            y: props.referencePoint.long,
          }
        : undefined,
      group: [
        {
          id: props.addressCode.toString(),
          addressCode: props.addressCode,
          coordinates: props.addressPoint
            ? {
                x: props.addressPoint.lat,
                y: props.addressPoint.long,
              }
            : undefined,
        },
      ],
    })
    return fetchedDistance[0][props.addressCode]
  }, [
    props.addressCode,
    props.addressPoint,
    props.referenceCodes,
    props.referencePoint,
  ])

  useEffect(() => {
    if (distance !== null) return
    if (!props.addressCode) {
      setDistance(undefined)
    } else if (checkSameAddress) {
      setDistance(0)
    } else {
      fetchDistance()
        .then(distanceValue => {
          setDistance(distanceValue || undefined)
        })
        .catch(() => {
          console.error('Chyba při měření vzdálenosti')
          setDistance(undefined)
        })
    }
  }, [props.addressCode, checkSameAddress, fetchDistance, distance])

  const decimals = useMemo(() => {
    if (!distance || distance > 10) return 0
    if (distance > 0.1) return 1
    if (distance > 0.01) return 2
    return 3
  }, [distance])

  const tooltipProps = useMemo(() => {
    const addressType = !props.personType
      ? 'trvalé bydliště/sídlo'
      : props.personType === CUZK_PERSON_TYPES.OPO
      ? 'sídlo'
      : 'trvalé bydliště'

    if (distance === 0) {
      return {
        className: 'cursor-help text-gray-400',
        title: `Subjekt má zapsáno ${addressType} na adrese budovy, kterou si právě prohlížíte.`,
      }
    } else if (!distance) {
      return {
        className: 'cursor-help text-gray-400',
        title: `Nepodařilo se zjistit vzdálenost pro ${addressType} subjektu od nemovitosti, kterou si právě prohlížíte.`,
      }
    } else {
      return {
        className: 'cursor-help text-gray-400',
        title: `${addressType} subjektu je vzdálené ${formatCurrencyNumber(
          distance,
          decimals + 1
        )} km od nemovitosti, kterou si právě prohlížíte.`,
      }
    }
  }, [props.personType, distance, decimals])

  if (distance === null) return <Spin spinning size="small" className="!flex" />

  if (distance === 0)
    return (
      <Tooltip {...tooltipProps}>
        <AimOutlined />
      </Tooltip>
    )

  if (!distance)
    return (
      <Tooltip {...tooltipProps}>
        <CloseOutlined />
      </Tooltip>
    )

  return (
    <Tooltip {...tooltipProps}>
      <div>
        <Money
          className="whitespace-nowrap"
          amount={distance}
          symbol="km"
          decimalNumbers={decimals}
        />
      </div>
    </Tooltip>
  )
})
