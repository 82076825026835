import { Button, Spin } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useEffect, useState } from 'react'

import {
  Proceeding,
  ProceedingDetailResponse,
  Rizeni,
} from '../../../common/proceedingTypes'
import {
  UserActivityActionType,
  UserActivityEntityType,
} from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { requestProceedingDetailPath } from '../../utils/paths'
import { useFormattedProceeding } from '../../utils/useProceedingString'
import { useUpdateUserActivityLog } from '../../utils/useUpdateUserActivityLog'
import { ProceedingDetailDocumentCollectionTable } from '../ProceedingDetailDocumentCollectionTable/ProceedingDetailDocumentCollectionTable'
import { ProceedingDetailInformation } from '../ProceedingDetailInformation/ProceedingDetailInformation'
import { PropertyDetailDrawer } from '../PropertyDetailDrawer/PropertyDetailDrawer'
import { PropertyDetailFetchError } from '../PropertyDetailFetchError/PropertyDetailFetchError'
import { PropertyDetailLvAreaTable } from '../PropertyDetailLvAreaTable/PropertyDetailLvAreaTable'
import { PropertyDetailLvBuildingTable } from '../PropertyDetailLvBuildingTable/PropertyDetailLvBuildingTable'
import { PropertyDetailLvUnitTable } from '../PropertyDetailLvUnitTable/PropertyDetailLvUnitTable'

interface FetchState {
  data: ProceedingDetailResponse | null
  fetching: boolean
  error: boolean
}

export interface ProceedingDetailProps {
  proceeding?: Proceeding
  setProceeding: (proceeding: Proceeding | undefined) => void
}

export const ProceedingDetail: FunctionComponent<ProceedingDetailProps> = ({
  proceeding,
  setProceeding,
}) => {
  const token = useAppSelector(state => state.myProfile.token)
  const updateUserActivityLog = useUpdateUserActivityLog()

  const { proceedingKey, proceedingUrlPath, proceedingTitle } =
    useFormattedProceeding(proceeding)

  const [{ data, fetching, error }, setData] = useState<FetchState>({
    data: null,
    fetching: false,
    error: false,
  })

  useEffect(() => {
    const fetchProceedingDetail = async () => {
      setData(prev => ({ ...prev, fetching: true }))
      try {
        const response = await axios.get(
          requestProceedingDetailPath() + token + '/' + proceedingUrlPath
        )
        setData({ data: response.data, fetching: false, error: false })
        proceedingTitle &&
          updateUserActivityLog(
            proceedingTitle,
            UserActivityEntityType.Proceeding,
            UserActivityActionType.SeenDetail
          )
      } catch (error) {
        setData({ data: null, fetching: false, error: true })
      }
    }
    if (!error && !data && !fetching) fetchProceedingDetail()
  }, [
    data,
    error,
    fetching,
    proceedingTitle,
    proceedingUrlPath,
    token,
    updateUserActivityLog,
  ])

  if (error)
    return (
      <PropertyDetailFetchError
        message={`Řízení ${proceedingTitle || ''} nebylo nalezeno`}
        description={
          <div className="flex flex-col space-y-2">
            <div>
              Je nám líto, ale dle zadaných údajů se nám nepodařilo najít žádné
              řízení.
            </div>
            <div>
              <Button onClick={() => setProceeding(undefined)} type="primary">
                Vyhledat nové řízení
              </Button>
            </div>
          </div>
        }
      />
    )

  return (
    <div>
      {fetching || !proceedingKey ? (
        <Spin
          spinning
          className="w-full h-36 flex items-center justify-center"
        />
      ) : (
        <ProceedingDetailDocumentCollectionTable
          proceedingString={proceedingKey || ''}
        />
      )}

      <ProceedingDetailInformation
        record={proceeding as Rizeni}
        data={data}
        loading={fetching}
        error={error}
      />

      <div className="w-full space-y-4 mt-8">
        {!!data?.nemovitosti?.jednotky.length && (
          <PropertyDetailLvUnitTable
            showCity
            hideMetrage
            drawerTarget
            propertyId=""
            jednotky={data.nemovitosti.jednotky}
          />
        )}
        {!!data?.nemovitosti?.budovy.length && (
          <PropertyDetailLvBuildingTable
            showCity
            hideMetrage
            drawerTarget
            propertyId=""
            budovy={data.nemovitosti.budovy}
            parcely={data.nemovitosti.parcely}
          />
        )}
        {!!data?.nemovitosti?.parcely.length && (
          <PropertyDetailLvAreaTable
            showCadastralArea
            hideMetrage
            drawerTarget
            propertyId=""
            parcely={data.nemovitosti.parcely}
            budovy={data.nemovitosti.budovy}
          />
        )}
      </div>
      <PropertyDetailDrawer />
    </div>
  )
}
