import React, { FunctionComponent } from 'react'

import { ColorPalleteKey, ColorPalleteOptions } from '../../utils/collorPallete'

export interface ColorPaletteProps {
  pickColor: (color: ColorPalleteKey) => void
  pickedColor?: ColorPalleteKey
}

export const ColorPalette: FunctionComponent<ColorPaletteProps> = props => {
  return (
    <div className="flex flex-wrap w-64">
      {Object.keys(ColorPalleteOptions).map((color, index) => {
        const colorKey = color as ColorPalleteKey
        return (
          <div key={index} className="flex items-center justify-center p-1">
            <div
              onClick={() => props.pickColor(colorKey)}
              className={`${
                ColorPalleteOptions[colorKey]
              } w-7 h-7 leading-7 text-center rounded-sm border 
                ${
                  props.pickedColor === color
                    ? 'after:content-["✓"] font-bold'
                    : 'hover:after:content-["◉"] after:content-["#"] cursor-pointer'
                }`}
            />
          </div>
        )
      })}
    </div>
  )
}
