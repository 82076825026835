import { DatePicker, Form, Input, Segmented } from 'antd'
import { addDays } from 'date-fns'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { czDateFormatDatePickerDay } from '../../utils/dateFormat'
import { datePickerCsLocale } from '../PriceEstimateBaseFragment/PriceEstimateBaseFragment'

export const MARKETPLACE_OFFER_TYPES = {
  zapujcka: 'Zápůjčka',
  vykup: 'Výkup',
  pohledavka: 'Pohledávka',
}

export const MarketplaceFormDescriptionFragment: FunctionComponent = () => {
  const [offerType, setOfferType] = useState(MARKETPLACE_OFFER_TYPES.zapujcka)
  const handleSwitch = useCallback((value: string | number) => {
    setOfferType(value as string)
  }, [])

  const options = useMemo(
    () => [
      {
        label: (
          <div className="flex items-center justify-center">
            {offerType === MARKETPLACE_OFFER_TYPES.zapujcka && (
              <div className="bg-[#5a74be] h-2 w-2 rounded-full mr-2 inline-block mt-0.5"></div>
            )}
            <span>{MARKETPLACE_OFFER_TYPES.zapujcka}</span>
          </div>
        ),
        value: MARKETPLACE_OFFER_TYPES.zapujcka,
      },
      {
        label: (
          <div className="flex items-center justify-center">
            {offerType === MARKETPLACE_OFFER_TYPES.pohledavka && (
              <div className="bg-[#5a74be] h-2 w-2 rounded-full mr-2 inline-block mt-0.5"></div>
            )}
            <span>{MARKETPLACE_OFFER_TYPES.pohledavka}</span>
          </div>
        ),
        value: MARKETPLACE_OFFER_TYPES.pohledavka,
      },
      {
        label: (
          <div className="flex items-center justify-center">
            {offerType === MARKETPLACE_OFFER_TYPES.vykup && (
              <div className="bg-[#5a74be] h-2 w-2 rounded-full mr-2 inline-block mt-0.5"></div>
            )}
            <span>{MARKETPLACE_OFFER_TYPES.vykup}</span>
          </div>
        ),
        value: MARKETPLACE_OFFER_TYPES.vykup,
      },
    ],
    [offerType]
  )
  const today = new Date()
  const tomorrow = addDays(today, 1)
  const inTwoWeeks = addDays(today, 15)

  return (
    <>
      <Form.Item name="type" label="Typ investice">
        <Segmented
          onChange={handleSwitch}
          className="w-full flex justify-between"
          block
          options={options}
        />
      </Form.Item>
      <Form.Item
        label="Název nabídky"
        name="title"
        rules={[
          {
            required: true,
            message: 'Zadejte název nabídky',
          },
        ]}
      >
        <Input autoFocus placeholder="Veřejný název nabídky" />
      </Form.Item>
      <Form.Item
        label="Umístění nemovitosti"
        name="location"
        rules={[
          {
            required: true,
            message: 'Zadejte orientační umístění nemovitosti',
          },
        ]}
      >
        <Input placeholder="Orientační umístění nemovitosti" />
      </Form.Item>

      <Form.Item
        name="expirationDate"
        label="Platnost nabídky do"
        rules={[
          {
            required: true,
            message: 'Datum musí být vyplněno',
          },
        ]}
      >
        <DatePicker
          disabledDate={current =>
            current.isBefore(tomorrow) || current.isAfter(inTwoWeeks)
          }
          format={czDateFormatDatePickerDay}
          locale={datePickerCsLocale}
          allowClear={false}
          className="w-full"
        />
      </Form.Item>
      <Form.Item label="Popis nabídky" name="description">
        <Input.TextArea
          showCount
          maxLength={1000}
          placeholder="Do popisu neuvádějte osobní ani kontaktní údaje"
        />
      </Form.Item>
    </>
  )
}
