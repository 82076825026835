import {
  DefinicniBod,
  DruhPozemku,
  KatastralniPracoviste,
  KodNazev,
  ZpusobVyuziti,
} from './ruianTypes'

export interface FetchingState<T> {
  fetching: boolean
  data: T | null
  error: boolean
}

export interface MonitoringExekuciApiResponse {
  dalsiStranka: boolean
  data: ForclosureMonitoringRecord[]
}

export interface UzemniOmezeniApiResponse {
  katastralniUrad: KatastralniUrad
  pracoviste: KatastralniPracoviste[]
}

export interface BudovaZpusobVyuzitiApiResponse {
  katastralniUrad: KatastralniUrad
  pracoviste: KatastralniPracoviste[]
}

export type ParcelaDruhFetchResult = FetchingState<KodNazev[]>
export type BudovaZpusobVyuzitiFetchResult = FetchingState<KodNazev[]>
export type UzemniOmezeniFetchResult = FetchingState<UzemniOmezeniApiResponse[]>
export type MonitoringExekuciFetchResult =
  FetchingState<MonitoringExekuciApiResponse>

export interface ForclosureMonitoringRecord {
  id: string
  vymera: number | null
  druhPozemku: DruhPozemku | null
  zpusobVyuziti: ZpusobVyuziti
  exekuce: boolean
  insolvence: boolean
  zastavySmluvni: boolean
  zastavyNedobrovolne: boolean
  souradnice: DefinicniBod
  posledniVkladZapis: string
  pocetVlastniku: number
  pracoviste: KatastralniPracoviste
}

export interface ForclosureMonitoringFilterState {
  vymeraOd: number | null
  vymeraDo: number | null
  vlastnikuOd: number | null
  vlastnikuDo: number | null
  druhyPozemku: number[] | null
  zpusobyVyuzitiBudov: number[] | null
  zpusobyVyuzitiJednotek: number[] | null
  zpusobyVyuzitiPozemku: number[] | null
  exekuce: string | null
  insolvence: string | null
  zastavySmluvni: string | null
  zastavyNedobrovolne: string | null
  pracoviste: number[] | null
  stranka: number
}

export interface ForclosureMonitoringFilterOnSubmit {
  (payload: Partial<ForclosureMonitoringFilterState>): void
}

export interface ForeclosureMonitoringPage {
  current: number
  hasNext: boolean
}

export interface PropertyUse extends KodNazev {
  disabled?: boolean | null
}

export interface AreaFilterUse {
  areaUse: PropertyUse[]
  areaType: PropertyUse[]
}

export interface AreaFilterUseCodes {
  areaUse: number[]
  areaType: number[]
}

export type KatastralniUrad = KodNazev

export enum TABLE_VIEW {
  TABLE = 'table',
  COMPACT_TABLE = 'compactTable',
  TILES = 'tiles',
}
