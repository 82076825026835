import { CadastralOffice } from '../app/utils/cuzkHelpers'
import { RestrictionCheckState } from '../graphql/generated'
import { OpsubNazevType } from './cuzkLvTypes'

export interface Address {
  adresni_misto: string
  nazev_ulice: string
  cp_ce: string
  cislo_domovni: string
  cislo_orientacni: string
  cast_obce: string
  obec: string
  mestska_cast: object
  mestsky_obvod: object
  psc: string
  dod_posta: string
  stat: object
}

interface CharOs {
  kod: string
  zkratka: object
  zkratka_alv: object
}

export interface Ident {
  id: string
  rc6?: string
  rc7?: string
  ico?: string
}

export interface OpravSubjekt {
  NAZEV: OpsubNazevType
  IDENT: Ident | { '#': Ident[] }
  ADRESA: {
    adresa: Address
  }
  CHAROS: {
    char_os: CharOs
  }
  PRAV_VZTAHY: PRAV_VZTAHY
}

interface Vlastnictvi {
  ODPOVIDA_IDENTIFIKACI: string
  OPRAV_SUBJEKT: OpravSubjekt | { '#': OpravSubjekt[] }
  TEXT: string
}

export interface OPRAV_SUBJEKTY {
  OPSUB: OpravSubjekt
}

interface EvidencePravProOsobu {
  ZADANE_UDAJE: {
    TYP_OS: string
    NAZEV_OS: string
    ICO: string
    PRIJMENI: string
    JMENO: string
    RC: object
  }
  UPLNA_SHODA?: {
    VLASTNICTVI: Vlastnictvi
  }
  CASTECNA_SHODA?: {
    TEXT: string
    OPRAV_SUBJEKTY: OPRAV_SUBJEKTY | OPRAV_SUBJEKTY[]
  }
  NESHODA?: {
    TEXT: string
  }
  PLATNOST: string
  VYHOTOVIL: string
  BEZPL_PRISTUP: object
  VYHOTOVENO: string
}

interface PRAV_VZTAHY {
  PRAV_VZTAHY: PRAV_VZTAHY
}

interface PRAV_VZTAHY {
  TYPRAV: TYPRAVItem[]
}

export interface TYPRAVItem {
  TYPRAV_NAZEV: string
  OKRESY: OKRESY
}

interface OKRESY {
  OKRES: OKRESItem[]
}

export interface OKRESItem {
  OKRES: OKRES
  OBCE: OBCE[]
}

interface OKRES {
  nuts4: string
  nazev: string
}

export interface OBCE {
  OBEC: OBECItem[]
}

export interface OBECItem {
  OBEC: OBEC
  KATASTRY: KATASTRY
}

export interface OBEC {
  kod: string
  nazev: string
}

export interface KATASTRY {
  KATASTR: KATASTRItem[]
}

export interface KATASTRItem {
  KATUZE: KATUZE
  TELESA: TELESO[]
}

interface KATUZE {
  kod: string
  nazev: string
}

export interface TELESO {
  TELESO: TELESOItem[]
}

export interface TELESOItem {
  LV: object
  JPV_LV: string
  TEL_ID: string
  PRSV_IDENT: PRSV_IDENT | PRSV_IDENT[]
  RIZENI: RIZENIType
  PODIL: PODILType
}

export interface RIZENIType {
  typriz_kod: string
  poradove_cislo: string
  rok: string
  prares_kod: string
}

interface PODILType {
  citatel: number
  jmenovatel: number
}

interface PRSV_IDENT {
  parcela: object
  stavba: object
  jednotka: object
  pravo_stavby: object
}

export interface EpoTableItem {
  TYPRAV_NAZEV?: string
  OKRES?: string
  OBEC?: string
  KATUZE?: KATUZE
  RIZENI?: RIZENIType[]
  LV?: string
  JPV_LV?: string
  PODIL?: PODILType
}

export interface EpoRestrictionItem {
  id: string
  okres?: string
  obec?: string
  katastralniUradNazev: string
  katastralniPracovisteNazev: string
  katastralniUzemiNazev: string
  katastralniUzemiKod: string
  rizeni: string[]
  lv: string
  vlastniciPocet: number
  budovyPocet: number
  parcelyPocet: number
  jednotkyPocet: number
  exekuce: boolean
  insolvence: boolean
  zastavyNedobrovolne: boolean
  predkupniPravo: boolean
}

export interface EPOResponse {
  EvidencePravProOsobu: EvidencePravProOsobu
}

export interface EpoRestrictionCheckRequest {
  id: string
  token: string
  userId: string
  tenantId: string
  buy?: boolean
}

export interface EpoRestrictionCheckByCodesRequest {
  id: string
  token: string
  userId: string
  tenantId: string
  codes: string[]
}

export interface EpoRestrictionCheck {
  id: string
  cuzk_epo_id: string
  account_id: string
  created_at: Date
  katastralni_pracoviste_nazev?: string
  katastralni_pracoviste_kod: string
  katastralni_urad_nazev?: string
  katastralni_urad_kod: string
  lv_ke_kontrole_pocet: number
  bought_at?: Date
  bought_by?: string
  state: RestrictionCheckState
}

export enum RestrictionCheckDescription {
  Checked = 'OVĚŘENO',
  Bought = 'ZAKOUPENO',
  Created = 'NEOVĚŘENO',
  Error = 'CHYBA KONTROLY',
  Processing = 'KONTROLUJE SE',
}

export enum RestrictionChecType {
  lien = 'lien',
  restriction = 'restriction',
}

export interface RestrictionCheckboxData {
  data: CadastralOffice[]
  positiveLv: number
  checkedPercentage: number
  allLvBought: boolean
  isAllCheckedBy: boolean
  isChecking: boolean
  isAllCreated: boolean
  isSomeChecked: boolean
  loading: boolean
  refetch: () => void
  inProgress:
    | {
        id: string
        katastralniPracovisteNazev: string
        lvKeKontrolePocet: number
        katastralniUradKod: string
        katastralniUradNazev: string
        katastralniPracovisteKod: string
        cuzkEpoLiensByRestrictionCheckId: {
          totalCount: number
        }
        state: RestrictionCheckState
        boughtAt: string
        checkedBy?: string
      }[]
    | null
}
