import { Button, Tooltip } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { useFirstBpejByCodeQuery } from '../../../graphql/generated'
import { tooltipHelpUnderline } from '../../utils/layoutConst'
import { Money } from '../Money/Money'

export interface BpejTooltipProps {
  code: string
  areaSize?: number
  children?: React.ReactNode
}

export const BpejTooltip: FunctionComponent<BpejTooltipProps> = props => {
  const [queryResult] = useFirstBpejByCodeQuery({
    variables: { kod: props.code },
  })

  const bpej = queryResult.data?.allBpejs?.nodes?.length
    ? queryResult.data.allBpejs.nodes[0]
    : { cena: 0 }

  const bpejTitle = useMemo(() => {
    return (
      <div>
        {bpej?.cena && props.areaSize && (
          <div className="flex justify-between">
            <span>Cena celkem: </span>
            <Money
              className="font-semibold"
              amount={bpej?.cena * props.areaSize}
              symbol="Kč"
            />
          </div>
        )}
        {bpej?.cena && (
          <div className="flex justify-between">
            <span>Cena za m²: </span>
            <Money className="font-semibold" amount={bpej?.cena} symbol="Kč" />
          </div>
        )}
        {props.areaSize && (
          <div className="flex justify-between">
            <span>Výměra pozemku: </span>
            <Money
              className="font-semibold"
              amount={props.areaSize}
              symbol="m²"
              decimalNumbers={0}
            />
          </div>
        )}
        <div className="text-gray-500 my-1">
          Výpočet dle průměrné ceny za BPEJ-{props.code} uváděné Ministerstvem
          zemědělství
        </div>{' '}
        <a
          target="_blank"
          href={`https://bpej.vumop.cz/${props.code}`}
          rel="noreferrer"
        >
          <Button type="primary" className="w-full">
            Detailní informace o BPEJ
          </Button>
        </a>
      </div>
    )
  }, [bpej?.cena, props.areaSize, props.code])

  return (
    <Tooltip title={bpejTitle} className={tooltipHelpUnderline} trigger="click">
      {props.children || props.code}
    </Tooltip>
  )
}
