import React, { FunctionComponent } from 'react'

import { AUCTION_TYPES } from '../../../common/auctionScrapeTypes'
import { TABLE_VIEW } from '../../../common/foreclosureMonitoringTypes'
import { useAuctionMonitoringTabQuery } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { useAuctionMonitoringFilter } from '../../utils/useAuctionMonitoringFilter'
import { AuctionMonitoringContent } from '../AuctionMonitoringContent/AuctionMonitoringContent'
import { AuctionMonitoringFilter } from '../AuctionMonitoringFilter/AuctionMonitoringFilter'
import { PageLayoutTabContainer } from '../PageLayoutTabContainer/PageLayoutTabContainer'

export interface AuctionMonitoringTabProps {
  viewType: TABLE_VIEW
  auctionType: AUCTION_TYPES
}

export const AuctionMonitoringTab: FunctionComponent<AuctionMonitoringTabProps> =
  props => {
    const userId = useAppSelector(state => state.myProfile.userId)

    const { filterQuery, filter, handleChange } = useAuctionMonitoringFilter(
      props.auctionType
    )

    const [dataSource] = useAuctionMonitoringTabQuery({
      variables: { filter: filterQuery, accountUserId: userId },
    })

    return (
      <PageLayoutTabContainer>
        <AuctionMonitoringFilter filter={filter} handleChange={handleChange} />
        <AuctionMonitoringContent
          filter={filter}
          dataSource={dataSource}
          viewType={props.viewType}
        />
      </PageLayoutTabContainer>
    )
  }
