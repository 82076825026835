import { Spin } from 'antd'
import React, { FunctionComponent, useMemo, useState } from 'react'

import { RuianBuildingResponse } from '../../../common/ruianTypes'
import { useAppSelector } from '../../../redux/hooks'
import { getPriceEstimateInitialValues } from '../../utils/propertyPriceEstimateHelpers'
import { usePropertyDetailTitle } from '../../utils/usePropertyDetailTitle'
import { PageLayoutTabContainer } from '../PageLayoutTabContainer/PageLayoutTabContainer'
import { FETCH_ERROR_TYPES } from '../PriceEstimateLayout/PriceEstimateLayout'
import { PropertyDetailPriceEstimateLayout } from '../PropertyDetailPriceEstimateLayout/PropertyDetailPriceEstimateLayout'

export interface PropertyDetailPriceEstimateProps {
  propertyId: string
}

export const PropertyDetailPriceEstimate: FunctionComponent<PropertyDetailPriceEstimateProps> =
  props => {
    const [fetchError] = useState<FETCH_ERROR_TYPES>()

    const ruian = useAppSelector(state => state.property.ruian)
    const drm = useAppSelector(state => state.property.drm)
    const lv = useAppSelector(state => state.property.lv)
    const lvGeometry = useAppSelector(state => state.property.lvGeometry)

    const title = usePropertyDetailTitle()
    const fetching = ruian.fetching || lv.fetching || lvGeometry.fetching

    const lvDataSource = useMemo(
      () => ({ ...lv.data, ...drm.data }),
      [drm.data, lv.data]
    )

    const initialValues = useMemo(() => {
      const values = getPriceEstimateInitialValues(
        ruian.data as RuianBuildingResponse,
        lvGeometry.data
      )
      return values ? { ...values, title } : undefined
    }, [lvGeometry.data, ruian.data, title])

    return (
      <PageLayoutTabContainer>
        {fetching ? (
          <Spin className="!flex items-center justify-center w-full h-96" />
        ) : (
          <PropertyDetailPriceEstimateLayout
            initialValues={initialValues}
            lvData={lvDataSource}
            propertyId={props.propertyId}
            propertyType={ruian.data?.type}
            fetchError={fetchError}
          />
        )}
      </PageLayoutTabContainer>
    )
  }
