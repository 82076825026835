import { Divider } from 'antd'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  AreaFilterUse,
  AreaFilterUseCodes,
} from '../../../common/foreclosureMonitoringTypes'
import { useMultipleAreaTypeRelations } from '../../utils/useAreaTypeRelations'
import { AreaUseSelectionAlert } from '../AreaUseSelectionAlert/AreaUseSelectionAlert'
import { AreaUseSelectionFilter } from '../AreaUseSelectionFilter/AreaUseSelectionFilter'
import { AreaUseSelectionFooter } from '../AreaUseSelectionFooter/AreaUseSelectionFooter'
import { AreaUseSelectionSearchBar } from '../AreaUseSelectionSearchBar/AreaUseSelectionSearchBar'
import { AreaUseSelectionSelectAllButon } from '../AreaUseSelectionSelectAllButon/AreaUseSelectionSelectAllButon'

export interface AreaUseSelectionProps {
  checkboxData: AreaFilterUse
  selectedValues: AreaFilterUseCodes | null
  toggleModalVisibility: () => void
  onSubmit: (selected: AreaFilterUseCodes | null) => void
}

export const AreaUseSelection: FunctionComponent<AreaUseSelectionProps> = ({
  toggleModalVisibility,
  checkboxData,
  selectedValues,
  onSubmit,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filteredValue, setFilteredValue] = useState(checkboxData)
  const [checkedValues, setCheckedValues] = useState<AreaFilterUseCodes>({
    areaUse: selectedValues?.areaUse || [],
    areaType: selectedValues?.areaType || [],
  })

  useEffect(() => {
    setCheckedValues({
      areaUse: selectedValues?.areaUse || [],
      areaType: selectedValues?.areaType || [],
    })
  }, [checkboxData, selectedValues])

  const rele = useMultipleAreaTypeRelations(checkedValues.areaType)

  const validatedCheckedValues = useMemo(
    () => ({
      areaUse: checkedValues.areaUse.filter(areaUseCode =>
        rele.includes(areaUseCode)
      ),
      areaType: checkedValues.areaType,
    }),
    [checkedValues.areaType, checkedValues.areaUse, rele]
  )

  const count = useMemo(
    () => ({
      selected:
        (checkedValues.areaType.length || 0) +
        (validatedCheckedValues.areaUse.length || 0),
      total:
        (checkboxData.areaType?.length || 0) +
        (checkboxData.areaUse?.length || 0),
      showAlert:
        !!validatedCheckedValues.areaUse?.length &&
        validatedCheckedValues.areaUse.length !== rele.length,
    }),
    [
      checkboxData.areaType?.length,
      checkboxData.areaUse?.length,
      checkedValues.areaType.length,
      validatedCheckedValues.areaUse.length,
      rele.length,
    ]
  )

  const findUseType = useCallback(
    (codes: AreaFilterUseCodes) => ({
      areaUse: checkboxData.areaUse.filter(useType =>
        codes.areaUse.includes(useType.kod)
      ),
      areaType: checkboxData.areaType.filter(areaType =>
        codes.areaType.includes(areaType.kod)
      ),
    }),
    [checkboxData.areaType, checkboxData.areaUse]
  )

  const handleMatching = useCallback(
    (matchCodes: AreaFilterUseCodes) => {
      setFilteredValue(findUseType(matchCodes))
    },
    [findUseType]
  )

  const checkFilteredCodes = useCallback(
    (filteredCodes: AreaFilterUseCodes) => {
      setCheckedValues(prevValues => {
        const newValues = { ...prevValues }
        filteredCodes.areaUse.forEach(code => {
          const index = newValues.areaUse.indexOf(code)
          if (index === -1) {
            newValues.areaUse.push(code)
          } else {
            newValues.areaUse.splice(index, 1)
          }
        })

        filteredCodes.areaType.forEach(code => {
          const index = newValues.areaType.indexOf(code)
          if (index === -1) {
            newValues.areaType.push(code)
          } else {
            newValues.areaType.splice(index, 1)
          }
        })
        return newValues
      })
      setSearchTerm('')
      setFilteredValue(checkboxData)
    },
    [checkboxData]
  )

  return (
    <>
      <div className="flex space-x-4 mb-4">
        <AreaUseSelectionSearchBar
          handleCheckCodes={checkFilteredCodes}
          checkboxData={checkboxData}
          handleMatching={handleMatching}
          setSearchTerm={setSearchTerm}
        />
        <AreaUseSelectionSelectAllButon
          checkedValues={checkedValues}
          filteredValue={filteredValue}
          setCheckedValues={setCheckedValues}
        />
      </div>

      <AreaUseSelectionAlert showAlert={count.showAlert} />

      <Divider className="!mt-0" />

      <AreaUseSelectionFilter
        checkboxData={checkboxData}
        filteredValues={filteredValue}
        setCheckedValues={setCheckedValues}
        checkedValues={checkedValues}
        searchTerm={searchTerm}
      />

      <Divider />

      <AreaUseSelectionFooter
        count={count}
        checkedValues={validatedCheckedValues}
        toggleModalVisibility={toggleModalVisibility}
        onSubmit={onSubmit}
      />
    </>
  )
}
