import React, { FunctionComponent, useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'

import {
  RecipientFragment,
  usePostserviceRecipientsByListIdQuery,
  usePostserviceTemplateByIdQuery,
} from '../../graphql/generated'
import { PageLayout } from '../components/PageLayout/PageLayout'
import { PageSkeleton } from '../components/PageSkeleton/PageSkeleton'
import { PostServiceLetterCreate } from '../components/PostServiceLetterCreate/PostServiceLetterCreate'

export const PostServiceLetter: FunctionComponent = () => {
  const { params } =
    useRouteMatch<{ recipientListId: string; templateId: string }>()

  const [recipientsResult] = usePostserviceRecipientsByListIdQuery({
    pause: !params?.recipientListId,
    variables: { postserviceListId: params?.recipientListId },
  })

  const [templateResult] = usePostserviceTemplateByIdQuery({
    pause: !params?.templateId,
    variables: { id: params?.templateId },
  })

  const content = useMemo(() => {
    if (recipientsResult.error) return <PageSkeleton />
    if (!params?.recipientListId)
      return (
        <PostServiceLetterCreate
          recipients={[]}
          listId={''}
          templateContent={templateResult?.data?.template?.content}
          templateId={params.templateId}
          templateName={templateResult?.data?.template?.name}
        />
      )
    if (recipientsResult.data?.recipients?.nodes?.length)
      return (
        <PostServiceLetterCreate
          recipients={
            recipientsResult.data?.recipients?.nodes as RecipientFragment[]
          }
          listId={params.recipientListId}
          templateContent={templateResult?.data?.template?.content}
          templateId={params.templateId}
        />
      )
    return <PageSkeleton />
  }, [
    params.recipientListId,
    params.templateId,
    recipientsResult.data?.recipients?.nodes,
    recipientsResult.error,
    templateResult?.data?.template?.content,
    templateResult?.data?.template?.name,
  ])

  return (
    <PageLayout
      className="flex grow"
      title={
        params?.recipientListId
          ? 'Tvorba obsahu dopisu'
          : params?.templateId
          ? `Editace šablony "${templateResult?.data?.template?.name || ''}"`
          : 'Tvorba šablony dopisu'
      }
    >
      {content}
    </PageLayout>
  )
}

export default PostServiceLetter
