import { CloudDownloadOutlined } from '@ant-design/icons'
import { Button, Spin } from 'antd'
import React, { FunctionComponent } from 'react'

import { CadastralImportEntity } from '../../../common/CadastralImportTypes'
import { useAppSelector } from '../../../redux/hooks'
import { ImportBoxStyle } from '../../utils/cadastralImportHelpers'
import { useCadastralWindow } from '../../utils/useCadastralWindow'
import { CadastralAutoImportControllButton } from '../CadastralAutoImportControllButton/CadastralAutoImportControllButton'
import { CadastralImportBoxHelpModalButton } from '../CadastralImportBoxHelpModalButton/CadastralImportBoxHelpModalButton'

export interface CadastralImportBoxProps {
  entityType: CadastralImportEntity
  className?: string
  classNameInner?: string
  size?: 'small' | 'middle' | 'large'
  message?: string
  autoOpenAvailable?: boolean
}

export const CadastralImportBox: FunctionComponent<CadastralImportBoxProps> =
  props => {
    const crmDrmData = useAppSelector(state => state.property.crm.data)
    const loading = useAppSelector(state => state.property.crm.fetching)
    const ruianData = useAppSelector(state => state.property.ruian.data)
    const autoImport =
      useAppSelector(state => state.myProfile.autoImport) &&
      !!props.autoOpenAvailable

    const cadastreWindow = useCadastralWindow(ruianData, autoImport, autoImport)

    const styles = props.size
      ? ImportBoxStyle[props.size]
      : ImportBoxStyle.small

    if (crmDrmData !== false) return null

    if (props.size === 'middle')
      return (
        <div className={props.className}>
          <div
            className={
              props.classNameInner +
              ' bg-primaryHover/5 border border-primaryHover/50 flex w-full justify-between items-center p-5'
            }
          >
            <div className={styles.icon}>
              <CloudDownloadOutlined />
            </div>
            <div className="flex-1">
              <h2 className={styles.headline}>Importujte údáje</h2>
              <div className="text-gray-400">
                {props.message ||
                  'Pro zobrazení detailních údajů o nemovitosti je potřeba importovat aktuální data.'}
                <CadastralImportBoxHelpModalButton
                  entityType={props.entityType}
                />
              </div>
            </div>
            <div className={styles.button}>
              <Button
                size={props.size}
                type="primary"
                loading={cadastreWindow.isOpen}
                onClick={cadastreWindow.openWindow}
              >
                Importovat údaje
              </Button>
              <CadastralAutoImportControllButton elementType="checkbox" />
            </div>
          </div>
        </div>
      )

    return (
      <Spin spinning={loading}>
        <div className={props.className}>
          <div
            className={
              props.classNameInner +
              ' bg-primaryHover/5 border border-primaryHover/50 flex flex-col w-full justify-between items-center px-3 py-4'
            }
          >
            <div className={styles.icon}>
              <CloudDownloadOutlined />
            </div>
            <h2 className={styles.headline}>Importujte údáje</h2>
            <div className="text-gray-400 text-center max-w-[350px]">
              Pro zobrazení detailních údajů o nemovitosti je potřeba importovat
              aktuální data.
              <CadastralImportBoxHelpModalButton
                entityType={props.entityType}
              />
            </div>
            <div className={styles.button}>
              <Button
                size={props.size}
                type="primary"
                loading={cadastreWindow.isOpen}
                onClick={cadastreWindow.openWindow}
              >
                Importovat údaje
              </Button>
            </div>
            <CadastralAutoImportControllButton elementType="checkbox" />
          </div>
        </div>
      </Spin>
    )
  }
