import { BASE_URL } from '../../../ini.json'

export const checkExistingFile = async (input: {
  id?: string
  tenantId: string
  token: string
  collectionType?: string
  filename: string
}) => {
  try {
    const response = await fetch(`${BASE_URL}/api/checkExistingFile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tenantId: input.tenantId,
        token: input.token,
        id: input.id,
        collectionType: input.collectionType,
        filename: input.filename,
      }),
    })

    if (response.status === 200) {
      const fileSize = await response.json()
      return fileSize
    }
    return false
  } catch (error) {
    console.log('no file found')
  }
  return false
}
