import { Spin } from 'antd'
import React, { FunctionComponent } from 'react'

import { MonitoringDedictviFetchResult } from '../../common/heritageMonitoringTypes'
import { HeritageMonitoringFilter } from '../components/HeritageMonitoringFilter/HeritageMonitoringFilter'
import { HeritageMonitoringTable } from '../components/HeritageMonitoringTable/HeritageMonitoringTable'
import { PageLayout } from '../components/PageLayout/PageLayout'
import { PropertyMonitoringFetchError } from '../components/PropertyMonitoringFetchError/PropertyMonitoringFetchError'
import { PropertyMonitoringTablePagination } from '../components/PropertyMonitoringTablePagination/PropertyMonitoringTablePagination'
import { useFetchHeritageMonitoring } from '../utils/useFetchHeritageMonitoring'
import { useHeritageMonitoringFilter } from '../utils/useHeritageMonitoringFilter'

export const HeritageMonitoring: FunctionComponent = () => {
  const { filter, filterQuery, handleChange } = useHeritageMonitoringFilter()

  const dataSource = useFetchHeritageMonitoring(
    filterQuery
  ) as MonitoringDedictviFetchResult

  return (
    <PageLayout title="Monitoring dědictví - výpis řízení" isNew>
      <HeritageMonitoringFilter filter={filter} handleChange={handleChange} />

      {dataSource?.error ? (
        <PropertyMonitoringFetchError type="dědictví" />
      ) : (
        <Spin spinning={dataSource?.fetching}>
          <HeritageMonitoringTable data={dataSource?.data?.data} />
        </Spin>
      )}

      <PropertyMonitoringTablePagination
        changePage={handleChange}
        currentPage={filter.stranka}
        hasNextPage={!!dataSource.data?.dalsiStranka}
      />
    </PageLayout>
  )
}

export default HeritageMonitoring
