import { HistoryOutlined } from '@ant-design/icons'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { compareDesc } from 'date-fns'
import React, { FunctionComponent, ReactElement, useMemo } from 'react'

import { Maybe, usePriceEstimateLayoutQuery } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { ImgSrc } from '../../utils/useMapImage'
import { Day, Min } from '../DateFormat/DateFormat'
import { EstimatePricesBox } from '../EstimatePricesBox/EstimatePricesBox'
import { PriceEstimateGraph } from '../PriceEstimateGraph/PriceEstimateGraph'
import { PriceEstimatePdfButton } from '../PriceEstimatePdfButton/PriceEstimatePdfButton'
import { TableCellLabels } from '../TableCellLabels/TableCellLabels'

export interface priceEstimateTableItem {
  key: string | undefined
  date?: string | Date
  estimateToDate: JSX.Element
  note: Maybe<string> | undefined
  cenaNabM2: number
  cenaNab: number
  cenaRealM2: number
  cenaReal: number
  decompNab?: Maybe<string>
  decompReal?: Maybe<string>
  timeSeriesGraph?: Maybe<string>
  action?: ReactElement | string
}

export interface PriceEstimateHistoryProps {
  propertyId: string
  imgSrc?: ImgSrc
}

const buttonProps = {
  type: 'link' as const,
  size: 'small' as const,
}

export const PriceEstimateHistory: FunctionComponent<PriceEstimateHistoryProps> =
  props => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)

    const [historyEstimateResponse, refetch] = usePriceEstimateLayoutQuery({
      variables: { accountId: tenantId, propertyId: props.propertyId },
    })
    const historyData = historyEstimateResponse.data?.allMonits?.nodes

    const historyEstimates = useMemo<priceEstimateTableItem[]>(
      () =>
        historyData?.map(item => ({
          key: item?.id,
          date: item?.createdAt,
          estimateToDate: <Day>{item?.estimateToDate}</Day>,
          note: item?.note,
          cenaNabM2: item?.cenaM2Nab || 0,
          cenaNab: item?.cenaNab || 0,
          cenaRealM2: item?.cenaM2Real || 0,
          cenaReal: item?.cenaReal || 0,
          action: item?.id,
          labels: item?.labels,
          decompNab: item?.decompNab,
          decompReal: item?.decompReal,
          timeSeriesGraph: item?.timeSeriesGraph,
        })) || [],
      [historyData]
    )

    const columns: ColumnsType<priceEstimateTableItem> = useMemo(
      () => [
        {
          title: 'Datum operace',
          dataIndex: 'date',
          key: 'date',
          className: 'w-32',
          defaultSortOrder: 'ascend',
          sorter: (a, b) =>
            a.date && b.date
              ? compareDesc(new Date(a.date), new Date(b.date))
              : 0,
          render: (date: string) => <Min>{date}</Min>,
        },
        {
          title: 'Odhad k datu',
          dataIndex: 'estimateToDate',
          key: 'estimateToDate',
          className: 'w-28',
        },
        {
          title: 'Štítek',
          dataIndex: 'labels',
          key: 'labels',
          render: (labels, record) => (
            <TableCellLabels
              labels={labels}
              labelEntityType="monitId"
              recordId={record?.key || ''}
              refetch={refetch}
              modalTitle="cenový odhad"
            />
          ),
        },
        {
          title: 'Poznámka',
          dataIndex: 'note',
          key: 'note',
        },
        {
          title: 'Akce',
          dataIndex: 'action',
          key: 'action',
          className: 'text-right w-28',
          render: id => (
            <PriceEstimatePdfButton
              imgSrc={props.imgSrc}
              buttonProps={buttonProps}
              id={id}
            />
          ),
        },
      ],
      [props.imgSrc, refetch]
    )

    const expandableRow = useMemo(
      () => ({
        expandedRowRender: (record: priceEstimateTableItem) => (
          <>
            <div className="max-w-fit pl-12">
              <EstimatePricesBox
                estimateResponse={{
                  cena_m2_nab: record.cenaNabM2,
                  cena_nab: record.cenaNab || 0,
                  cena_m2_real: record.cenaRealM2 || 0,
                  cena_real: record.cenaReal || 0,
                }}
              />
            </div>
            <div className="my-2 flex flex-wrap pl-12 pr-2">
              <PriceEstimateGraph
                timeSeriesGraph={record.timeSeriesGraph}
                realPrice={!!record.cenaReal}
              />
            </div>
          </>
        ),
        rowExpandable: () => true,
      }),
      []
    )

    if (!historyData?.length) return null
    return (
      <div className="w-full print:!hidden">
        <div className="text-lg mb-4">
          <HistoryOutlined className="mr-2" />
          <span>Historie odhadů</span>
          <span className="text-sm pl-4 text-gray-400">
            U této nemovitosti jste již cenový odhad prováděli, níže naleznete
            detaily.
          </span>
        </div>
        <div>
          <Table
            dataSource={historyEstimates}
            columns={columns}
            expandable={expandableRow}
            size="small"
          />
        </div>
      </div>
    )
  }
