import { Button, Table } from 'antd'
import { ColumnsType, ExpandableConfig } from 'antd/es/table/interface'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import { compareDesc } from 'date-fns'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Link } from 'react-router-dom'

import { SenderAddress } from '../../../common/postServiceTypes'
import {
  Maybe,
  usePostServiceSentTableQuery,
  UuidFilter,
} from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { tablePagination } from '../../utils/layoutConst'
import { postServiceCreatePath } from '../../utils/paths'
import { CadastrePostLetterDetailPdf } from '../CadastrePostLetterDetailPdf/CadastrePostLetterDetailPdf'
import { CadastrePostSentTableAction } from '../CadastrePostSentTableAction/CadastrePostSentTableAction'
import { CadastrePostSentTableExpandedRow } from '../CadastrePostSentTableExpandedRow/CadastrePostSentTableExpandedRow'
import { Min } from '../DateFormat/DateFormat'
import { ExpandColumnTitle } from '../ExpandColumnTitle/ExpandColumnTitle'
import { ExpandIcon } from '../ExpandIcon/ExpandIcon'
import { PdfDrawerPreview } from '../PdfDrawerPreview/PdfDrawerPreview'
import { TableCellLabels } from '../TableCellLabels/TableCellLabels'
import { TableSummaryExport } from '../TableSummaryExport/TableSummaryExport'

export interface SentTableItem {
  createdAt: Date
  description?: string
  dispatchDate?: Maybe<Date>
  id: string
  letterText: string
  nazev: string
  recipients: { totalCount: number }
  senderAddress: SenderAddress
}

export interface CadastrePostSentTableProps {
  idFilter?: UuidFilter
}

export const CadastrePostSentTable: FunctionComponent<CadastrePostSentTableProps> =
  props => {
    const tableId = 'cadastrePostSent-table'
    const tenantId = useAppSelector(state => state.myProfile.tenantId)

    const [dataSource, setDataSource] = useState<SentTableItem[]>([])
    const expandedRowKeys = useAppSelector(
      state => state.expandedRow.tables?.[tableId]
    )

    const [queryResult, refetch] = usePostServiceSentTableQuery({
      variables: { accountId: tenantId, idFilter: props.idFilter },
    })

    useEffect(() => {
      if (queryResult.data?.allRecipientLists?.nodes?.[0]?.id) {
        setDataSource(
          queryResult.data?.allRecipientLists?.nodes as SentTableItem[]
        )
      }
    }, [queryResult.data?.allRecipientLists?.nodes])

    const columns = useMemo<ColumnsType<SentTableItem>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Počet adresátů',
          dataIndex: 'recipientsCount',
          key: 'recipientsCount',
          className: 'w-32',
          sorter: {
            compare: (a, b) =>
              a.recipients.totalCount - b.recipients.totalCount,
          },
          render: (_, record) => record.recipients.totalCount || 0,
        },
        {
          title: 'Štítek',
          dataIndex: 'labels',
          key: 'labels',
          render: (labels, record) => (
            <TableCellLabels
              labels={labels}
              labelEntityType="recipientListId"
              recordId={record.id}
              refetch={refetch}
              modalTitle={record.nazev}
            />
          ),
        },
        {
          title: 'Název',
          dataIndex: 'nazev',
          key: 'nazev',
          sorter: {
            compare: (a, b) => a.nazev.localeCompare(b.nazev),
          },
        },
        {
          title: 'Popis',
          dataIndex: 'description',
          key: 'description',
          sorter: {
            compare: (a, b) =>
              a.description && b.description
                ? a.description.localeCompare(b.description)
                : -1,
          },
        },
        {
          title: 'Odesílatel',
          dataIndex: 'senderAddress',
          key: 'senderAddress',
          sorter: {
            compare: (a, b) =>
              a.senderAddress && b.senderAddress
                ? a.senderAddress
                    .toString()
                    .localeCompare(b.senderAddress.toString())
                : -1,
          },
          render: (_, record) => (
            <>
              {record.senderAddress.senderName} - {record.senderAddress.street}{' '}
              {record.senderAddress.houseNumber}
              {record.senderAddress.houseNumber &&
                record.senderAddress.orientationNumber &&
                '/'}
              {record.senderAddress.orientationNumber},{' '}
              {record.senderAddress.city}, {record.senderAddress.zipCode}
            </>
          ),
        },
        {
          title: 'Odesláno',
          dataIndex: 'dispatchDate',
          key: 'dispatchDate',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => {
            const dateA = a.dispatchDate ? new Date(a.dispatchDate) : new Date()
            const dateB = b.dispatchDate ? new Date(b.dispatchDate) : new Date()
            return compareDesc(dateA, dateB)
          },

          render: (dispatchDate, record) => {
            if (dispatchDate) return <Min>{dispatchDate}</Min>
            return (
              <CadastrePostSentTableAction
                recipientsCount={record.recipients.totalCount}
                listId={record.id}
                refetch={refetch}
              />
            )
          },
        },
        {
          title: 'PDF',
          dataIndex: 'actions',
          key: 'actions',
          className: 'w-36',
          render: (_, record) => (
            <PdfDrawerPreview
              buttonProps={{ ghost: true }}
              buttonText="Šablona dopisu"
              drawerTitle="Náhled šablony dopisu"
              fileName={record.nazev + '_HROMADNY_DOPIS_REGESTA_DATA'}
            >
              <CadastrePostLetterDetailPdf
                showTemplate
                letterText={record.letterText}
              />
            </PdfDrawerPreview>
          ),
        },
      ],
      [refetch]
    )

    const expandable = useMemo<ExpandableConfig<SentTableItem>>(() => {
      return {
        expandedRowKeys,
        expandedRowRender: record => (
          <CadastrePostSentTableExpandedRow listId={record.id} />
        ),
        columnTitle: (
          <ExpandColumnTitle
            allKeys={dataSource.map(record => record.id.toString())}
            tableId={tableId}
          />
        ),
        expandIcon: ({ expanded, record }) => (
          <ExpandIcon
            expanded={expanded}
            recordId={record.id.toString()}
            tableId={tableId}
            expandable={!!record.id}
          />
        ),
      }
    }, [dataSource, expandedRowKeys])

    const locale = useMemo(
      () => ({
        emptyText: !queryResult.fetching && (
          <div className="my-8">
            <div className="mb-2 text-gray-400">
              Zatím jste nerozeslali žádnou hromadnou poštu.
            </div>
            <Link to={postServiceCreatePath('novy')}>
              <Button type="primary">Vytvořit novou korespondenci</Button>
            </Link>
          </div>
        ),
      }),
      [queryResult.fetching]
    )

    const summary = useCallback(
      () => (
        <TableSummaryExport
          total={dataSource?.length || 0}
          colSpan={7}
          url="postService/sentXlsx"
          fileName="odeslana-posta"
          dataSource={dataSource || []}
          icon
        />
      ),
      [dataSource]
    )

    return (
      <Table
        rowKey="id"
        rowClassName="animate-fadeIn"
        locale={locale}
        id={tableId}
        dataSource={dataSource}
        columns={columns}
        loading={queryResult.fetching}
        expandable={expandable}
        bordered
        summary={summary}
        pagination={tablePagination}
      />
    )
  }
