import { Button } from 'antd'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { PropertyUse } from '../../../common/foreclosureMonitoringTypes'

export interface AreaUseSelectionSelectAllButonProps {
  setCheckedValues: (value: { areaUse: number[]; areaType: number[] }) => void
  checkedValues: {
    areaUse: number[]
    areaType: number[]
  }
  filteredValue: {
    areaUse: PropertyUse[]
    areaType: PropertyUse[]
  }
}

export const AreaUseSelectionSelectAllButon: FunctionComponent<AreaUseSelectionSelectAllButonProps> =
  props => {
    const enabledItems = useMemo(
      () => ({
        areaUse: props.filteredValue.areaUse
          .filter(item => !item.disabled)
          .map(item => item.kod),
        areaType: props.filteredValue.areaType
          .filter(item => !item.disabled)
          .map(item => item.kod),
      }),
      [props.filteredValue]
    )

    const enabledItemsCount = useMemo(
      () => [...enabledItems.areaType, ...enabledItems.areaUse].length,
      [enabledItems.areaType, enabledItems.areaUse]
    )

    const { areAllChecked, areAllDisabled } = useMemo(() => {
      const areAllChecked =
        [...props.checkedValues.areaType, ...props.checkedValues.areaUse]
          .length === enabledItemsCount

      const areAllDisabled = [
        ...props.filteredValue.areaType,
        ...props.filteredValue.areaUse,
      ].every(item => !!item.disabled)

      return { areAllChecked, areAllDisabled }
    }, [
      enabledItemsCount,
      props.checkedValues.areaType,
      props.checkedValues.areaUse,
      props.filteredValue.areaType,
      props.filteredValue.areaUse,
    ])

    const handleSelectAll = useCallback(() => {
      props.setCheckedValues(enabledItems)
    }, [enabledItems, props])

    const handleDeselectAll = useCallback(() => {
      props.setCheckedValues({ areaUse: [], areaType: [] })
    }, [props])

    return (
      <Button
        type="primary"
        onClick={areAllChecked ? handleDeselectAll : handleSelectAll}
        ghost={areAllChecked}
        disabled={areAllDisabled}
      >
        {areAllChecked ? 'Odebrat vše' : 'Vybrat vše'}
      </Button>
    )
  }
