import { Icon } from '@iconify/react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table/interface'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { CollectionType } from '../../../common/documentCollectionTypes'
import { useCuzkEpoTableQuery, UuidFilter } from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { checkExistingFile } from '../../utils/checkExistingFile'
import { checkSeznamSestav } from '../../utils/checkSeznamSestav'
import { Identifier, RegisterGroupMap } from '../../utils/dynamicSearchTooltip'
import {
  numberSorter,
  stringSorter,
  utcDateSorter,
} from '../../utils/generalTableHelpers'
import { cuzkDocumentCollectionState } from '../../utils/handleResponseStatus'
import { isHugeSubjectLimit, tablePagination } from '../../utils/layoutConst'
import { useConst } from '../../utils/useConst'
import { CuzkDocumentCollectionAction } from '../CuzkDocumentCollectionAction/CuzkDocumentCollectionAction'
import { CuzkDocumentPrice } from '../CuzkDocumentPrice/CuzkDocumentPrice'
import { CuzkDocumentState } from '../CuzkDocumentState/CuzkDocumentState'
import {
  CUZK_PERSON_TYPES,
  CuzkEpoModalButton,
} from '../CuzkEpoModalButton/CuzkEpoModalButton'
import { Min } from '../DateFormat/DateFormat'
import { DynamicSearchTooltip } from '../DynamicSearchTooltip/DynamicSearchTooltip'
import { formatCurrencyNumber } from '../Money/Money'
import { TableCellLabels } from '../TableCellLabels/TableCellLabels'
import { TableSummaryExport } from '../TableSummaryExport/TableSummaryExport'

export interface EpoTableItem {
  id: string
  typ: string
  createdAt: Date
  nazev: string
  rcIco: string
  stav: string
  cena?: number
  pocetstran?: string
  pocetLv: number
  cuzkCollectionId: string
}

export interface CuzkEpoTableProps {
  refetchState: boolean
  setRefetchState: (state: boolean) => void
  idFilter?: UuidFilter
}

export const CuzkEpoTable: FunctionComponent<CuzkEpoTableProps> = props => {
  const tableId = 'cuzkEpo-table'
  const tenantId = useAppSelector(state => state.myProfile.tenantId)
  const token = useAppSelector(state => state.myProfile.token)
  const oneDayAgo = useConst(new Date(new Date().getTime() - 86400000))

  const [timeoutTime, setTimeoutTime] = useState<number | null>(2)
  const [queryResult, refetch] = useCuzkEpoTableQuery({
    variables: {
      accountId: tenantId,
      oneDayAgo: oneDayAgo,
      idFilter: props.idFilter,
    },
  })
  const [dataSource, setDataSource] = useState<EpoTableItem[]>([])

  useEffect(() => {
    if (queryResult?.data?.allCuzkEpos?.nodes.length) {
      setDataSource(queryResult.data.allCuzkEpos.nodes as EpoTableItem[])
    }
  }, [queryResult.data?.allCuzkEpos?.nodes])

  useEffect(() => {
    if (props.refetchState) {
      refetch()
      props.setRefetchState(false)
      setTimeoutTime(2)
    }
  }, [props, refetch])

  useEffect(() => {
    let timeoutId = null as NodeJS.Timeout | null

    const checkItems = async () => {
      if (!queryResult.fetching && dataSource.length > 0) {
        const itemsToCheck = dataSource.filter(
          item =>
            item.stav === cuzkDocumentCollectionState.ceka ||
            item.stav === cuzkDocumentCollectionState.podepisujeSe ||
            item.stav === cuzkDocumentCollectionState.vytvariSe
        )
        const filesToCheck = dataSource.filter(
          item => item.stav === cuzkDocumentCollectionState.generujeSePdf
        )

        let shouldRefetch = false
        for (const item of filesToCheck) {
          const checked = await checkExistingFile({
            id: item.id,
            filename: item.id + '.pdf',
            tenantId: tenantId,
            token: token,
            collectionType: CollectionType.EPO,
          })

          if (checked) {
            shouldRefetch = true
          }
        }
        for (const item of itemsToCheck) {
          const checked = await checkSeznamSestav({
            cuzkCollectionId: item.cuzkCollectionId,
            id: item.id,
            stav: item.stav,
            tenantId: tenantId,
            collectionType: CollectionType.EPO,
          })

          if (checked) {
            shouldRefetch = true
          }
        }

        if (shouldRefetch) {
          refetch()
          setTimeoutTime(prevTime => (prevTime || 2) * 2)
        } else {
          setTimeoutTime(null)
        }
      }
    }

    if (timeoutTime) {
      timeoutId = setTimeout(checkItems, timeoutTime * 1000)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [dataSource, queryResult.fetching, refetch, tenantId, timeoutTime, token])

  const columns = useMemo<ColumnsType<EpoTableItem>>(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        responsive: ['' as Breakpoint],
      },
      {
        title: 'Vytvořeno',
        dataIndex: 'createdAt',
        className: 'w-32',
        defaultSortOrder: 'descend',
        sorter: utcDateSorter('createdAt'),
        render: createdAt => <Min>{createdAt}</Min>,
      },
      {
        title: 'Typ',
        dataIndex: 'typ',
        className: 'w-10 !text-center',
        sorter: stringSorter('typ'),
        render: typ =>
          typ === CUZK_PERSON_TYPES.OFO ? (
            <Icon icon="ant-design:user-outlined" className="text-lg inline" />
          ) : (
            <Icon
              icon="fluent:building-factory-16-regular"
              className="text-lg inline"
            />
          ),
      },
      {
        title: 'Jméno/název',
        dataIndex: 'nazev',
        sorter: stringSorter('nazev'),
      },
      {
        title: 'RČ/IČO',
        dataIndex: 'rcIco',
        sorter: stringSorter('rcIco'),
        render: (rcIco, record) =>
          record.typ === CUZK_PERSON_TYPES.OFO ? (
            <DynamicSearchTooltip
              identifier={{
                [Identifier.RC]: rcIco,
                [Identifier.DESCRIPTION]: record.nazev,
              }}
              identifierType={Identifier.RC}
              excludedRegisters={[
                ...RegisterGroupMap.EPO,
                ...RegisterGroupMap.ČÚZK,
              ]}
            >
              {record.rcIco}
            </DynamicSearchTooltip>
          ) : (
            <DynamicSearchTooltip
              identifier={{
                [Identifier.ICO]: record.rcIco,
                [Identifier.COMPANY_NAME]: record.nazev,
                [Identifier.DESCRIPTION]: record.nazev,
              }}
              identifierType={Identifier.ICO}
              excludedRegisters={[
                ...RegisterGroupMap.EPO,
                ...RegisterGroupMap.ČÚZK,
              ]}
            >
              {record.rcIco}
            </DynamicSearchTooltip>
          ),
      },
      {
        title: 'Štítek',
        dataIndex: 'labels',
        render: (labels, record) => (
          <TableCellLabels
            labels={labels}
            labelEntityType="cuzkEpoId"
            recordId={record.id}
            refetch={refetch}
            modalTitle={record.nazev}
          />
        ),
      },
      {
        title: 'ČÚZK cena',
        dataIndex: 'cena',
        className: 'w-44',
        sorter: numberSorter('cena'),
        render: (cena, record) => (
          <CuzkDocumentPrice state={record.stav} price={cena} />
        ),
      },
      {
        title: 'Dotčených LV',
        dataIndex: 'pocetLv',
        className: 'text-right',
        sorter: numberSorter('pocetLv'),
        render: pocetLv =>
          typeof pocetLv === 'number' ? (
            <span className={pocetLv ? '' : 'text-gray-400'}>
              {formatCurrencyNumber(pocetLv, 0)}
            </span>
          ) : (
            <span className="text-gray-400">---</span>
          ),
      },
      {
        title: 'Stav',
        dataIndex: 'stav',
        sorter: stringSorter('stav'),
        render: stav => <CuzkDocumentState state={stav} />,
      },
      {
        title: 'Akce',
        dataIndex: 'actions',
        className: 'w-52',
        render: (_, record) => (
          <CuzkDocumentCollectionAction
            price={record.cena}
            state={record.stav}
            id={record.id}
            cuzkCollectionId={record.cuzkCollectionId}
            refetch={refetch}
            collectionType={CollectionType.EPO}
            disablePdf={record.pocetLv > isHugeSubjectLimit}
            disableDetail={record.pocetLv === 0}
          />
        ),
      },
    ],
    [refetch]
  )

  const locale = useMemo(
    () => ({
      emptyText: !false && (
        <div className="my-8">
          <div className="mb-2 text-gray-400">
            Zatím jste nevyhledali žádnou evidenci práv pro osobu.
          </div>
          <CuzkEpoModalButton />
        </div>
      ),
    }),
    []
  )

  const summary = useCallback(
    () => (
      <TableSummaryExport
        total={dataSource?.length || 0}
        colSpan={8}
        url="epo/epoTableXlsx"
        fileName="evidence-prav-osob"
        dataSource={dataSource || []}
        align="text-left"
      />
    ),
    [dataSource]
  )

  return (
    <Table
      id={tableId}
      rowKey="id"
      size="small"
      dataSource={[...dataSource]}
      columns={columns}
      locale={locale}
      bordered
      summary={summary}
      pagination={tablePagination}
    />
  )
}
