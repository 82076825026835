import { PROPERTY_TYPES } from '../../common/drmTypes'
import {
  RuianAreaResponse,
  RuianBuildingResponse,
  RuianDetailResponse,
  RuianUnitResponse,
} from '../../common/ruianTypes'

export const isRuianBuilding = (
  data: RuianDetailResponse | null
): data is RuianBuildingResponse => {
  return data?.type === PROPERTY_TYPES.BUILDING
}

export const isRuianArea = (
  data: RuianDetailResponse | null
): data is RuianAreaResponse => {
  return data?.type === PROPERTY_TYPES.AREA
}

export const isRuianUnit = (
  data: RuianDetailResponse | null
): data is RuianUnitResponse => {
  return data?.type === PROPERTY_TYPES.UNIT
}

export const drmPropertyType = {
  [PROPERTY_TYPES.BUILDING]: 'Budova',
  [PROPERTY_TYPES.AREA]: 'Parcela',
  [PROPERTY_TYPES.UNIT]: 'Jednotka',
} as const
