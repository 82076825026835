import { BASE_URL } from '../../../ini.json'
import { StatusNespolehlivyPlatceRozsirenyResponse } from '../../common/vatPayerTypes'

export const checkVatPAyer = async (dic: string, businessName: string) => {
  try {
    const response = await fetch(`${BASE_URL}/api/vatPayer/${dic}`)

    if (response.status === 200) {
      const data: StatusNespolehlivyPlatceRozsirenyResponse =
        await response.json()
      console.log(
        data.statusPlatceDPH[0].nazevSubjektu,
        businessName,
        ' checkSeznamSestav.data'
      )
      if (
        data.statusPlatceDPH[0].nazevSubjektu?.toLowerCase() ===
        businessName.toLowerCase()
      )
        return data.statusPlatceDPH[0]
    }
    return false
  } catch (error) {
    console.log(error, ' checkSeznamSestav.error')
  }
  return false
}
