import React, { Fragment, FunctionComponent } from 'react'

import { OpsubIdentType } from '../../../common/cuzkLvTypes'
import {
  Identifier,
  RegisterGroup,
  RegisterGroupMap,
} from '../../utils/dynamicSearchTooltip'
import { DynamicSearchTooltip } from '../DynamicSearchTooltip/DynamicSearchTooltip'

export interface CuzkLvHtmlIdentifikatorFragmentProps {
  opravSubjekt?: OpsubIdentType
  vlaIdent?: OpsubIdentType
  active?: boolean
}
export const CuzkLvHtmlIdentifikatorFragment: FunctionComponent<CuzkLvHtmlIdentifikatorFragmentProps> =
  props => {
    const opsub = props.opravSubjekt

    const renderPairs = (
      leftSide?: (string | undefined)[] | string,
      rightSide?: (string | undefined)[] | string,
      separator?: string
    ): string[] => {
      const leftArray = Array.isArray(leftSide) ? leftSide : [leftSide]
      const rightArray = Array.isArray(rightSide) ? rightSide : [rightSide]
      const length = Math.max(leftArray.length, rightArray.length)

      return Array.from(
        { length },
        (_, i) =>
          `${leftArray[i] || ''}${
            rightArray[i] ? (separator || '') + rightArray[i] : ''
          }`
      )
    }

    if (!opsub) {
      return null
    }

    const getName = (
      key:
        | 'bsm2_jmeno'
        | 'bsm1_jmeno'
        | 'jmeno'
        | 'bsm2_prijmeni'
        | 'bsm1_prijmeni'
        | 'prijmeni',
      index: number
    ) =>
      Array.isArray(props.vlaIdent?.[key])
        ? props.vlaIdent?.[key]?.[index] || ''
        : props.vlaIdent?.[key]?.toString() || ''

    const icoPairs = renderPairs(opsub?.ico, opsub?.ico_doplnek, '-')
    const rcPairs = renderPairs(opsub?.rc6, opsub?.rc7, '/')

    return (
      <strong>
        {icoPairs.map((item, index) => (
          <Fragment key={index}>
            {props.active ? (
              <DynamicSearchTooltip
                identifier={{
                  [Identifier.ICO]: item,
                  [Identifier.COMPANY_NAME]: Array.isArray(
                    props.vlaIdent?.nazev
                  )
                    ? props.vlaIdent?.nazev[index] || ''
                    : props.vlaIdent?.nazev || '',
                }}
                identifierType={Identifier.ICO}
                excludedRegisters={RegisterGroupMap.ČÚZK}
              >
                {item}
              </DynamicSearchTooltip>
            ) : (
              item
            )}
            {index < icoPairs.length - 1 && `\n\n`}
          </Fragment>
        ))}
        {rcPairs.map((item, index) => (
          <Fragment key={index}>
            {props.active ? (
              <DynamicSearchTooltip
                identifier={{
                  [Identifier.FIRST_NAME]:
                    rcPairs.length > 1
                      ? index > 0
                        ? getName('bsm2_jmeno', index)
                        : getName('bsm1_jmeno', index)
                      : getName('jmeno', index),
                  [Identifier.LAST_NAME]:
                    rcPairs.length > 1
                      ? index > 0
                        ? getName('bsm2_prijmeni', index)
                        : getName('bsm1_prijmeni', index)
                      : getName('prijmeni', index),
                  [Identifier.RC]: item.replace('/', ''),
                }}
                identifierType={Identifier.RC}
                excludedRegisters={RegisterGroupMap[RegisterGroup.CUZK]}
              >
                {item}
              </DynamicSearchTooltip>
            ) : (
              item
            )}
            {index < rcPairs.length - 1 && `\n\n`}
          </Fragment>
        ))}
      </strong>
    )
  }
