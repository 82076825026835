import { message } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useCallback, useState } from 'react'

import {
  AreaSearchApiResponse,
  AreaSearchForm,
} from '../../common/areaSearchTypes'
import { useAppSelector } from '../../redux/hooks'
import { AreaSearchBar } from '../components/AreaSearchBar/AreaSearchBar'
import { AreaSearchTable } from '../components/AreaSearchTable/AreaSearchTable'
import { Fade } from '../components/Fade/Fade'
import { PageLayout } from '../components/PageLayout/PageLayout'
import { PropertyDetailDrawer } from '../components/PropertyDetailDrawer/PropertyDetailDrawer'
import { PropertyMonitoringTablePagination } from '../components/PropertyMonitoringTablePagination/PropertyMonitoringTablePagination'
import { useInitialFetchWatchdog } from '../utils/useFetchWatchdog'

const url = 'https://data.regesta.cz/DrmApi/api/v1.0/Nemovitosti/Parcela'

export const Area: FunctionComponent = () => {
  useInitialFetchWatchdog()
  const token = useAppSelector(state => state.myProfile.token)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<AreaSearchApiResponse>()
  const [fetchInput, setFetchInput] = useState<AreaSearchForm>()

  const handleSearch = useCallback(
    async (data: AreaSearchForm) => {
      setFetchInput(data)
      setLoading(true)
      try {
        const response = await axios.get(url, {
          params: { ...data, access_token: token },
        })

        setData(response?.data || [])
      } catch (error) {
        message.error('Chyba při vyhledávání parcely')
      } finally {
        setLoading(false)
      }
    },
    [token]
  )

  const handlePagination = useCallback(
    (page: { stranka?: number }) => {
      handleSearch({ ...fetchInput, ...page })
    },
    [fetchInput, handleSearch]
  )

  return (
    <>
      <PageLayout title="Vyhledávání parcel">
        <AreaSearchBar onSubmit={handleSearch} />

        <Fade show={!!data} className="mt-6">
          <AreaSearchTable data={data?.data || []} loading={loading} />

          <PropertyMonitoringTablePagination
            changePage={handlePagination}
            currentPage={fetchInput?.stranka || 1}
            hasNextPage={data?.dalsiStranka || false}
          />
        </Fade>
      </PageLayout>
      <PropertyDetailDrawer />
    </>
  )
}

export default Area
