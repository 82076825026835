import axios from 'axios'
import { useCallback } from 'react'

import { RzpWebSubject } from '../../common/rzpWebSubjectsTypes'
import { PropertyDataTypes } from '../../redux/fetchAction'
import { useAppSelector } from '../../redux/hooks'
import { requestRzpSubjectsPath } from './paths'
import { useDispatchFetch } from './useDispatchFetch'

export const useFetchRzpSubjects = () => {
  const { token, tenantId } = useAppSelector(state => state.myProfile)
  const { dispatchError, dispatchFetching, dispatchSuccess } =
    useDispatchFetch()

  const fetchRzpSubjects = useCallback(
    async (addressId?: string | number) => {
      if (!token || !addressId)
        return dispatchError(PropertyDataTypes.RZP_SUBJECTS)

      dispatchFetching(PropertyDataTypes.RZP_SUBJECTS)

      try {
        const response = await axios.get(
          `${requestRzpSubjectsPath()}/${token}/${addressId}/${tenantId}`
        )
        const data = {
          icoList: response.data.subjekty
            .map((rzp: RzpWebSubject) => rzp.ico)
            .filter(Boolean),
          ...response.data,
        }

        dispatchSuccess(PropertyDataTypes.RZP_SUBJECTS, data)
        return data
      } catch (error) {
        dispatchError(PropertyDataTypes.RZP_SUBJECTS)
      }
    },
    [dispatchError, dispatchFetching, dispatchSuccess, tenantId, token]
  )

  return fetchRzpSubjects
}
