import { Button } from 'antd'
import React, { FunctionComponent } from 'react'

export interface AuctionDrawerPropertyHeaderProps {
  auctionUrl?: string
}

export const AuctionDrawerPropertyHeader: FunctionComponent<AuctionDrawerPropertyHeaderProps> =
  ({ auctionUrl }) => {
    if (!auctionUrl) {
      return null
    }
    return (
      <div className="flex justify-between items-center">
        <div>Náhled dražby</div>
        <a
          href={auctionUrl}
          target="_blank"
          title="Přejít na dražbu v novém okně"
          rel="noreferrer"
        >
          <Button type="primary">Přejít na dražbu</Button>
        </a>
      </div>
    )
  }
