import React, { FunctionComponent } from 'react'

import { PageLayout } from '../components/PageLayout/PageLayout'
import { PostServiceCreateRecipientListModalButton } from '../components/PostServiceCreateRecipientListModalButton/PostServiceCreateRecipientListModalButton'
import { PostServiceRecipientListTable } from '../components/PostServiceRecipientListTable/PostServiceRecipientListTable'

export const PostServiceRecipientList: FunctionComponent = () => {
  return (
    <PageLayout
      title="Hromadná pošta - seznam adresátů"
      isNew
      extra={<PostServiceCreateRecipientListModalButton />}
    >
      <PostServiceRecipientListTable />
    </PageLayout>
  )
}

export default PostServiceRecipientList
