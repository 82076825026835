import { Alert, Table, TableProps } from 'antd'
import { ColumnsType } from 'antd/es/table/interface'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import React, { FunctionComponent, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { PropertyLabel } from '../../../common/propertyDetailTypes'
import { usePropertyLabelTableQuery } from '../../../graphql/generated'
import { stringSorter, utcDateSorter } from '../../utils/generalTableHelpers'
import { LabelType } from '../../utils/labelsTypes'
import { tablePagination } from '../../utils/layoutConst'
import { lvDetailPath, propertyDetailPath } from '../../utils/paths'
import { Min } from '../DateFormat/DateFormat'
import { TableCellLabels } from '../TableCellLabels/TableCellLabels'

export interface PropertyLabelTableProps {
  labelId: string
  onActionRefetch?: () => void
  tableProps?: TableProps<PropertyLabel>
}

const tableId = 'propertyLabel-table'

export const PropertyLabelTable: FunctionComponent<PropertyLabelTableProps> =
  props => {
    const [queryResult, refetch] = usePropertyLabelTableQuery({
      variables: { labelId: props.labelId },
    })

    const dataSource = useMemo(
      () =>
        queryResult.data?.allLabelConnections?.nodes
          .map(item => item?.propertyLabelByPropertyLabelId)
          .filter(Boolean),
      [queryResult.data?.allLabelConnections?.nodes]
    ) as unknown as (PropertyLabel & { labels?: LabelType })[]

    const columns = useMemo<
      ColumnsType<PropertyLabel & { labels?: LabelType }>
    >(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Přidáno',
          dataIndex: 'createdAt',
          defaultSortOrder: 'descend',
          sorter: utcDateSorter('createdAt'),
          render: createdAt => <Min>{createdAt}</Min>,
        },
        {
          title: 'Štítek',
          dataIndex: 'labels',
          render: (labels, record) => (
            <TableCellLabels
              labels={labels}
              labelEntityType="propertyLabelId"
              recordId={record.id}
              refetch={refetch}
              modalTitle={record.propertyName}
            />
          ),
        },
        {
          title: 'Typ nemovitosti',
          dataIndex: 'propertyType',
          sorter: stringSorter('propertyType'),
        },
        {
          title: 'Název nemovitosti',
          dataIndex: 'propertyName',
          sorter: stringSorter('propertyName'),
          render: (name, record) => (
            <Link
              title="Přejít na detail budovy"
              to={propertyDetailPath(record.propertyType, record.propertyId)}
            >
              {name}
            </Link>
          ),
        },
        {
          title: 'Katastrální území',
          dataIndex: 'propertyCadastralName',
          sorter: stringSorter('propertyCadastralName'),
        },
        {
          title: 'LV',
          dataIndex: 'propertyLv',
          sorter: stringSorter('propertyLv'),
          render: (propertyLv, record) => (
            <Link
              title="Otevřít detail listu vlastnictví v novém okně"
              target="_blank"
              to={lvDetailPath(record.propertyCadastralCode, record.propertyLv)}
            >
              {propertyLv}
            </Link>
          ),
        },
      ],
      [refetch]
    )
    if (queryResult.error)
      return (
        <Alert
          message="Chyba při načítání nemovitostí"
          description="Je nám líto, ale nepodařilo se najít detaily nemovitostí s přiřazeným štítkem."
          type="error"
          showIcon
        />
      )

    return (
      <Table
        rowKey="id"
        size="small"
        id={tableId}
        dataSource={dataSource}
        columns={columns}
        loading={queryResult.fetching}
        pagination={tablePagination}
        {...props.tableProps}
      />
    )
  }
